import { Flex } from '@chakra-ui/react';
import Typography from 'src/common/components/Typography';

export function SelectionPreview({
	typeString,
	startString,
	endString,
}: {
	typeString: string;
	startString: string;
	endString: string;
}) {
	return (
		<Flex paddingTop="12px" gap="4px" color="gray.700">
			<Typography variant="Paragraph12R">{typeString + ', '}</Typography>
			<Typography variant="Disclaimer12SB">{startString}</Typography>
			<Typography variant="Paragraph12R">{'to'}</Typography>
			<Typography variant="Disclaimer12SB">{endString}</Typography>
		</Flex>
	);
}
