import { Box } from '@chakra-ui/react';
import useOutsideClick from '@hooks/interaction/useOutsideClick';
import { ReactNode, useMemo, useRef, useState, RefObject } from 'react';
import ListItem from '../ListItem';
import { Search } from '../Search';
import Typography from '../Typography';
import classes from './SearchDropdown.module.scss';
import colors from 'src/style/colors';
import { EmptyState } from '@components/SearchDropdown/EmptyState';
import { Option } from '@components/SearchDropdown/types';

export const SearchDropdown = ({
	options,
	onOptionClick,
	placeholder = 'Search',
	optionsTitle,
	bottomButton,
	initialFocusRef,
	onInputChangeCallback,
}: {
	options: Option[] | void;
	onOptionClick: (el: Option) => void;
	placeholder?: string;
	optionsTitle?: string;
	bottomButton?: ReactNode;
	initialFocusRef?: RefObject<{ focus(): void }>;
	onInputChangeCallback?: (value: string) => void;
}) => {
	const { dropdownMenu, menuSearch, optionsList } = classes;
	const [searchValue, setSearchValue] = useState('');
	const [selectedOption, setSelectedOption] = useState<Option | null>(null);
	const inputRef = useRef<HTMLDivElement>(null);
	useOutsideClick(inputRef, () => closeMenu());

	const closeMenu = () => {
		setSearchValue('');
	};

	const optionArray = useMemo(() => {
		const filteredArray =
			options
				?.filter((el) => el.title !== selectedOption?.title)
				?.filter((el) => el.title?.toLocaleLowerCase().includes(searchValue.toLowerCase())) || [];
		return selectedOption && !searchValue ? [selectedOption, ...filteredArray] : filteredArray;
	}, [options, searchValue, selectedOption]);

	const onItemClick = (option: Option) => {
		setSelectedOption(option);
		onOptionClick?.(option);
		closeMenu();
	};

	return (
		<Box className={dropdownMenu}>
			<Box className={menuSearch}>
				<Search
					height="44px"
					isDeBounced={false}
					initialValue={searchValue}
					isAutoFocused
					onChange={(value) => {
						setSearchValue(value);
						onInputChangeCallback?.(value);
					}}
					isTransparent
					width="100%"
					placeholder={placeholder}
					fontSize={'14px'}
					initialFocusRef={initialFocusRef}
				/>
			</Box>

			<Box className={optionsList}>
				{!!optionsTitle && (
					<Typography variant={'DesktopH10Regular'} color={'gray.700'} mb={'8px'}>
						{optionsTitle}
					</Typography>
				)}
				{!optionArray?.length ? (
					<EmptyState />
				) : (
					optionArray.map((el, i) => (
						<ListItem
							hoverColor="blue.100"
							color="gray.1000"
							state={el.title === selectedOption?.title ? 'selected' : 'enabled'}
							key={i}
							size={'sm'}
							label={el.title ?? el.id}
							onClick={(event) => {
								event?.stopPropagation();
								onItemClick(el);
							}}
							hasRoundedCorners
						/>
					))
				)}
			</Box>
			{!!bottomButton && (
				<Box
					backgroundColor={'gray.200'}
					borderTop={`1px solid ${colors.gray[300]}`}
					borderBottomLeftRadius={'8px'}
					borderBottomRightRadius={'8px'}
				>
					{bottomButton}
				</Box>
			)}
		</Box>
	);
};
