import { gql } from '@apollo/client';

export const GetApplyOverrideTasks = gql`
	subscription GetApplyOverrideTasks($my_id: uuid!, $last_sync: timestamptz!) {
		tasks(
			where: { task_name: { _eq: "apply-overrides" }, runner_id: { _eq: $my_id }, created_at: { _gt: $last_sync } }
			order_by: [{ created_at: desc }]
		) {
			parameters(path: "metricName")
			status
			created_at
			started_at
		}
	}
`;

export const GetLastSync = gql`
	subscription GetLastSync {
		tenants {
			last_etl_synced
		}
	}
`;

export const ApplyOverridesToMetric = gql`
	mutation ApplyOverridesToMetric($metric_name: String!) {
		apply_overrides(metric_name: $metric_name) {
			msg
			task_id
		}
	}
`;

export const CanApplyOverridesToMetricOverrides = gql`
	subscription CanApplyOverridesToMetricOverrides($done_overrides_status: String = "Done") {
		pending_overrides: data_overrides_aggregate(where: { status: { _neq: $done_overrides_status } }) {
			aggregate {
				max {
					updated_at
				}
			}
		}
	}
`;

export const CanApplyOverridesToMetricTasks = gql`
	subscription CanApplyOverridesToMetricTasks($metric_name: String!, $last_sync: timestamptz!) {
		metric_tasks: tasks_aggregate(
			where: {
				parameters: { _contains: { metricName: $metric_name } }
				created_at: { _gt: $last_sync }
				status: { _neq: "Failed" }
				task_name: { _eq: "apply-overrides" }
			}
		) {
			aggregate {
				max {
					created_at
				}
			}
		}
	}
`;
