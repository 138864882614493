import 'src/common/components/Table/Table.scss';
import { Filter } from 'src/generated/graphql';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { Badge } from '@chakra-ui/react';
import { GridTableHeaderActionFilter } from '@components/GridTable/GridTableHeaderAction';

export type FilterColumnsActionProps = {
	onClick: VoidFunction;
	filter: Filter[];
};
export function FilterColumnsAction({ onClick, filter }: FilterColumnsActionProps) {
	return (
		<Flex position={'relative'}>
			<GridTableHeaderActionFilter onClick={onClick} />
			{filter.length > 0 && (
				<Badge
					style={{
						gap: '10px',
						position: 'absolute',
						border: '1.5px solid white',
						padding: '1px 5px 0px 5px',
						borderRadius: '20px',
						width: '20px',
						height: '18px',
						right: -6,
						top: -2,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					colorScheme="blue"
					backgroundColor={'blue.600'}
				>
					<Typography variant={'Paragraph12M'} color={'white'}>
						{filter.length}
					</Typography>
				</Badge>
			)}
		</Flex>
	);
}
