import { Box, Flex } from '@chakra-ui/react';
import { useMemo, useRef } from 'react';
import Button from 'src/common/components/Button';
import Divider from 'src/common/components/Divider';
import { Dislike16, DuplicateSolid16, Like16 } from 'src/common/components/Icons';
import Markdown from 'src/common/components/Markdown';
import LoadingText from '@components/LoadingText';
import { PromptActions } from 'src/common/components/MetricView/InteractiveMetricChat/normalizeHistory';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import { useOnCopy } from 'src/common/hooks/interaction/useOnCopy';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import colors from 'src/style/colors';
import { formatQuery } from '../utilities/formatQuery';
import { ActionIcon } from './ActionIcon';
import classes from './ChatCard.module.scss';
const maxDescriptionLines = 2;

export function ChatCard({
	query,
	response,
	action,
	isSelected,
	isError,
	isLoading,
	isLast,
	onDislikeClick,
	onLikeClick,
	onCopyClick,
}: {
	action?: PromptActions;
	query?: string;
	response: string;
	isSelected?: boolean;
	isLoading: boolean;
	isLast: boolean;
	isError: boolean;
	onDislikeClick: () => void;
	onLikeClick: () => void;
	onCopyClick: () => void;
}) {
	const backgroundColor = isSelected ? 'white' : 'transparent';
	const dislikeHoverStyle = { color: colors.red[700], backgroundColor: colors.red[100] };
	const likeHoverStyle = { color: colors.green[700], backgroundColor: colors.green[100] };

	const descriptionRef = useRef(null);

	const { onCopyText } = useOnCopy();
	const onCopyClicked = () => {
		onCopyClick();
		onCopyText({ contentToCopy: response, message: 'Copied to clipboard', variant: 'ok' });
	};

	const formattedQuery = useMemo(() => query && formatQuery(query), [query]);
	const isOverflowingDescription = useOnOverflow(descriptionRef, [formattedQuery], undefined, false);

	return (
		<Box
			mb="8px"
			className={classes.chatCard}
			backgroundColor={backgroundColor}
			border="1px solid"
			borderColor={'gray.300'}
			borderRadius="4px"
		>
			<Flex direction={'column'} gap="12px" padding="16px">
				<Flex gap="8px" direction={'row'} alignItems="center">
					<ActionIcon isFallbackAvatar={true} action={action} />
					<Tooltip
						size="md"
						variant="fluid"
						label={isOverflowingDescription && formattedQuery}
						placement={'top'}
						margin={'0px 0px 8px 0px'}
					>
						<Typography
							ref={descriptionRef}
							color="gray.700"
							variant="DesktopH10Regular"
							noOfLines={maxDescriptionLines}
						>
							{formattedQuery}
						</Typography>
					</Tooltip>
				</Flex>

				<Typography variant="Paragraph14R">
					{isLast ? (
						<LoadingText
							component={(loadedText) => <Markdown message={loadedText} />}
							isLoading={isLoading}
							text={response}
						/>
					) : (
						<Markdown message={response} />
					)}
				</Typography>
				{!isLoading && !isError && (
					<Flex direction="row" justifyContent="end" alignItems="center" className={classes.actionIcons}>
						<Tooltip size="md" label="Copy to clipboard">
							<Button colorScheme="gray" variant="ghost" isIconOnly onClick={onCopyClicked} size={'xs'} border="none">
								<DuplicateSolid16 />
							</Button>
						</Tooltip>
						<Divider color="gray.300" direction="vertical" paddingY="10px" marginX="4px" />
						<Tooltip size="md" label="Mark as not useful">
							<Button
								colorScheme="gray"
								variant="ghost"
								isIconOnly
								_hover={dislikeHoverStyle}
								onClick={onDislikeClick}
								size={'xs'}
								border="none"
							>
								<Dislike16 />
							</Button>
						</Tooltip>
						<Tooltip size="md" label="Mark as useful">
							<Button
								colorScheme="gray"
								variant="ghost"
								isIconOnly
								_hover={likeHoverStyle}
								onClick={onLikeClick}
								border="none"
							>
								<Like16 />
							</Button>
						</Tooltip>
					</Flex>
				)}
			</Flex>
		</Box>
	);
}
