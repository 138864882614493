import {
	Box,
	Flex,
	PlacementWithLogical,
	Popover,
	PopoverArrow,
	PopoverCloseButton,
	PopoverContent,
	PopoverTrigger,
	Portal,
} from '@chakra-ui/react';
import * as CSS from 'csstype';
import { ReactNode } from 'react';
import Typography from '../Typography';
import { TypographyVariant } from '../Typography/types';

export type ActionTooltipSize = 'xs' | 'sm' | 'lg';
export type ActionTooltipProps = {
	testId?: string;
	size?: ActionTooltipSize;
	placement?: PlacementWithLogical;
	label?: string | ReactNode;
	labelHeadline?: string | ReactNode;
	children: React.ReactElement;
	hasArrow?: boolean;
	isDismissible?: boolean;
	buttonLabel?: string;
	marginTop?: CSS.Property.MarginTop;
	marginLeft?: CSS.Property.MarginLeft;
	marginRight?: CSS.Property.MarginRight;
	isWrappingInButton?: boolean;
	onClick?: (() => void) | null;
	title?: string;
	isLinkButton?: boolean;
	isBlack?: boolean;
};

function ActionTooltip({
	children,
	label,
	labelHeadline,
	hasArrow = false,
	isDismissible = false,
	placement = 'bottom',
	buttonLabel = 'Learn more',
	onClick,
	testId,
	marginTop = '12px',
	marginLeft,
	marginRight,
	isWrappingInButton = true,
	size = 'lg',
	title,
	isLinkButton = false,
	isBlack = false,
}: ActionTooltipProps) {
	if (!label) return isWrappingInButton ? <button>{children}</button> : children;
	return (
		<Popover
			placement={placement}
			data-testid={testId}
			variant="solid"
			arrowPadding={16}
			closeOnBlur={true}
			trigger="hover"
			isLazy
		>
			{({ onClose }) => (
				<>
					<PopoverTrigger>{isWrappingInButton ? <button>{children}</button> : children}</PopoverTrigger>
					<Portal>
						<PopoverContent
							backgroundColor={isBlack ? 'gray.1100' : 'white'}
							marginTop={marginTop}
							marginLeft={marginLeft}
							marginRight={marginRight}
						>
							{hasArrow && (
								<PopoverArrow backgroundColor={isBlack ? 'gray.1100' : 'white'} height={'12px'} width={'24px'} />
							)}
							{isDismissible && <PopoverCloseButton />}
							<Flex
								flexDirection="column"
								alignItems="start"
								width={size === 'xs' ? '300px' : '355px'}
								padding={paddingBySize[size]}
							>
								<Flex flexDirection="column" alignItems="start">
									{title && (
										<Typography variant={variantBySize[size]} textAlign="start">
											{title}
										</Typography>
									)}
									{labelHeadline && (
										<Box paddingBottom="8px">
											<Typography variant={variantBySize[size]} textAlign="start">
												{labelHeadline}
											</Typography>
										</Box>
									)}
									<Typography
										color={isBlack ? 'white' : 'inherit'}
										variant={labelVariantBySize[size]}
										textAlign="start"
									>
										{label}&nbsp;
										{isLinkButton && (
											<Typography
												cursor={'pointer'}
												textDecoration={'underline'}
												display={'inline'}
												variant={variantBySize[size]}
												color={'blue.600'}
												_hover={{ color: 'blue.600' }}
												onClick={() => {
													onClose?.();
													onClick?.();
												}}
											>
												{buttonLabel}
											</Typography>
										)}
									</Typography>
								</Flex>
								{onClick && !isLinkButton && (
									<Typography
										paddingTop="12px"
										variant={variantBySize[size]}
										color={'blue.700'}
										_hover={{ color: 'blue.600' }}
										onClick={() => {
											onClose();
											onClick();
										}}
										as={'button'}
									>
										{buttonLabel}
									</Typography>
								)}
							</Flex>
						</PopoverContent>
					</Portal>
				</>
			)}
		</Popover>
	);
}

const paddingBySize: { [key in ActionTooltipSize]: CSS.Property.Padding } = {
	xs: '10px 12px',
	sm: '16px',
	lg: '20px',
};

const variantBySize: { [key in ActionTooltipSize]: TypographyVariant } = {
	xs: 'DesktopH8Regular',
	sm: 'Paragraph16SB',
	lg: 'Paragraph16SB',
};

const labelVariantBySize: { [key in ActionTooltipSize]: TypographyVariant } = {
	xs: 'DesktopH8Regular',
	sm: 'Paragraph16R',
	lg: 'Paragraph16R',
};

export default ActionTooltip;
