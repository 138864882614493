import * as CSS from 'csstype';
import colors from 'src/style/colors';

type InputModesStyleProps = {
	color?: CSS.Property.Color;
	border?: CSS.Property.Border;
	bg?: CSS.Property.BackgroundColor;
};

type InputMode = 'success' | 'error' | 'disabled';

const inputModesStyleProps: {
	[key in InputMode]: {
		formControlStyle?: InputModesStyleProps;
		inputComponentStyle?: InputModesStyleProps & {
			_hover?: unknown;
			_focus?: unknown;
			_placeholder?: unknown;
			_focusVisible?: unknown;
		};
		borderRightColor?: CSS.Property.Color;
	};
} = {
	disabled: {
		formControlStyle: {
			color: colors.gray['500'],
		},
		inputComponentStyle: {
			bg: colors.gray['100'],
			color: colors.gray['500'],
			border: `1px solid ${colors.gray['400']}`,
		},
		borderRightColor: colors.gray['400'],
	},
	error: {
		formControlStyle: {
			color: colors.red['800'],
		},
		inputComponentStyle: {
			bg: colors.red['100'],
			color: colors.red['800'],
			border: `1px solid ${colors.red['400']}`,
			_hover: {
				borderColor: colors.red['500'],
			},
			_placeholder: {
				color: colors.red['600'],
			},
			_focus: {
				_placeholder: {
					color: colors.red['500'],
				},
				borderColor: colors.red['600'],
			},
			_focusVisible: {
				boxShadow: 'none',
				borderColor: colors.red['600'],
			},
		},
		borderRightColor: colors.red['400'],
	},
	success: {
		inputComponentStyle: {
			border: `1px solid ${colors.green['800']}`,
		},
	},
};

export const getModeStyleProps = (flags: { [key in InputMode]: boolean }) => {
	const mode = calculateInputMode(flags);
	if (!mode) return {};

	return inputModesStyleProps[mode];
};

export const calculateInputMode = (flags: { [key in InputMode]: boolean }): InputMode | '' => {
	const orderedInputModes = ['disabled', 'error', 'success'] as const;

	return orderedInputModes.find((mode) => flags[mode]) ?? '';
};
