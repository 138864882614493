import { useCallback } from 'react';
import Tooltip from 'src/common/components/Tooltip';
import { PrimaryKeys, useSourceInfo } from 'src/common/hooks/useSourceInfo';
import { GetRecordProfileByIdQuery } from 'src/generated/graphql';
import Button from '../../common/components/Button';
import Flex from '../../common/components/Flex';
import { CloseLarge16, Link16, NewTabSolid16, Search16 } from '../../common/components/Icons';
import Input from '../../common/components/Input';
import Typography from '../../common/components/Typography';
import useToast from '../../common/hooks/ui/useToast';
import useNavigation, { buildEntityProfileUrl } from '../../services/useNavigation';
import colors from '../../style/colors';
import { useEntityProfileReportEvent } from '../EntityProfileDrawer/hooks';
import { getRecordsEntityName } from './utils';

type RecordProfile = GetRecordProfileByIdQuery['recordProfile'];

export type RecordProfileHeaderProps = {
	recordProfile: RecordProfile;
	showXRow?: boolean;
	searchValue: string;
	onSearchValueChange: (searchValue: string) => void;
	onClose?: () => void;
};

const getPrimaryKeysValues: (recordProfile: RecordProfile) => PrimaryKeys = (recordProfile) => {
	const primaryKeys = recordProfile.entityInfo.primaryKeys;
	return primaryKeys.reduce(
		(acc, primaryKey) => ({
			...acc,
			[primaryKey]: recordProfile.dimensions.find((dim) => dim.name === primaryKey)?.value,
		}),
		{}
	);
};

const getPrimaryKeyValueForCopy = (recordProfile: RecordProfile) => {
	const primaryKeysValues = getPrimaryKeysValues(recordProfile);
	const primaryKeys = Object.keys(primaryKeysValues);

	if (primaryKeys.includes('id')) return primaryKeysValues['id'];
	else if (primaryKeys.length == 1) return primaryKeysValues[primaryKeys[0]];

	return undefined;
};

function CopySourceId({ recordProfile }: { recordProfile: RecordProfile }) {
	const { wrapWithReport } = useEntityProfileReportEvent('drawer-header');
	const sourceInfo = useSourceInfo({
		entityName: recordProfile.entityInfo.name,
	});
	const toast = useToast();

	const recordSourceId = getPrimaryKeyValueForCopy(recordProfile);
	const shouldCopyBeAvailable = !!recordSourceId;

	const CopySourceIdTypography = useCallback(() => {
		return (
			<Typography
				variant="DesktopH8Regular"
				color={shouldCopyBeAvailable ? 'gray.700' : 'gray.500'}
				as={'a'}
				cursor={shouldCopyBeAvailable ? 'pointer' : undefined}
				_hover={{ color: shouldCopyBeAvailable ? 'gray.1000' : 'gray.500' }}
				onClick={
					shouldCopyBeAvailable
						? wrapWithReport(
								() => {
									navigator.clipboard.writeText(recordSourceId);
									toast({ variant: 'ok', message: 'ID copied to clipboard' });
								},
								'click-copy-source-id',
								{ sourceId: recordSourceId, sightId: recordProfile.sightId }
						  )
						: undefined
				}
			>
				Copy {sourceInfo?.name ?? 'source'} ID
			</Typography>
		);
	}, [recordProfile.sightId, recordSourceId, shouldCopyBeAvailable, sourceInfo?.name, toast, wrapWithReport]);

	const CopySourceIdComponent = useCallback(
		() =>
			shouldCopyBeAvailable ? (
				<CopySourceIdTypography />
			) : (
				<Tooltip label="There are multiple keys, create id dimension" size="md" variant="primary" marginTop="8px">
					<CopySourceIdTypography />
				</Tooltip>
			),
		[CopySourceIdTypography, shouldCopyBeAvailable]
	);

	return <CopySourceIdComponent />;
}

function OpenInSource({ recordProfile }: { recordProfile: RecordProfile }) {
	const { reportEvent } = useEntityProfileReportEvent('drawer-header');

	const primaryKeys = getPrimaryKeysValues(recordProfile);
	const sourceInfo = useSourceInfo({
		entityName: recordProfile.entityInfo.name,
		primaryKeys,
	});

	return (
		<>
			{sourceInfo && sourceInfo.Logo && sourceInfo.url && (
				<>
					<Typography variant="DesktopH8Regular" color="gray.700">
						·
					</Typography>
					<Flex alignItems={'center'} gap={'8px'}>
						<sourceInfo.Logo />
						<Typography
							variant="DesktopH8Regular"
							color="gray.700"
							as={'a'}
							cursor={'pointer'}
							target="_blank"
							_hover={{ color: 'gray.1000' }}
							href={sourceInfo.url}
							onClick={() =>
								reportEvent({
									event: 'click-open-in-source',
									metaData: { primaryKeys, sightId: recordProfile.sightId },
								})
							}
						>
							Open in {sourceInfo.name}
						</Typography>
					</Flex>
				</>
			)}
		</>
	);
}

export function RecordProfileHeader({
	recordProfile,
	showXRow,
	searchValue,
	onSearchValueChange,
	onClose,
}: RecordProfileHeaderProps) {
	const { wrapWithReport } = useEntityProfileReportEvent('drawer-header');
	const toast = useToast();
	const { navigate } = useNavigation();

	return (
		<Flex
			maxWidth={'700px'}
			backgroundColor={colors.white}
			flexDir={'column'}
			alignItems={'flex-start'}
			alignSelf={'stretch'}
			justifyContent={'space-between'}
		>
			{showXRow && (
				<Flex padding="12px 16px">
					<Button
						isIconOnly
						onClick={onClose}
						variant="outline"
						size="inline"
						colorScheme="lightGray"
						data-intercom-area={'main'}
						data-intercom-type={'main'}
						data-intercom-target={'entity-drawer-close'}
					>
						<CloseLarge16 />
					</Button>
				</Flex>
			)}
			<Flex flexDir="column" gap="20px" padding={'12px 24px 24px 24px'} alignSelf={'stretch'}>
				<Flex
					alignItems={'flex-start'}
					justifyContent={'space-between'}
					alignSelf={'stretch'}
					gap={'24px'}
					maxWidth={'700px'}
				>
					<Flex flexDir={'column'} alignItems={'flex-start'} gap={'8px'} minW={0}>
						<Typography variant="DesktopH5" color="gray.1000" noOfLines={1} width={'100%'}>
							{recordProfile.name ?? recordProfile.sightId}
						</Typography>
						<Flex alignItems={'center'} gap={'8px'}>
							<Typography variant="DesktopH8Regular" color="gray.700">
								{getRecordsEntityName(recordProfile)}
							</Typography>
							<OpenInSource recordProfile={recordProfile} />
							<Typography variant="DesktopH8Regular" color="gray.700">
								·
							</Typography>
							<CopySourceId recordProfile={recordProfile} />
						</Flex>
					</Flex>
					<Flex>
						<Button
							isIconOnly
							size="small"
							variant="outline"
							colorScheme="gray"
							onClick={wrapWithReport(
								() => {
									navigator.clipboard.writeText(buildEntityProfileUrl(recordProfile.sightId, true, true));
									toast({ variant: 'ok', message: 'Link copied to clipboard' });
								},
								'click-copy-record-profile-link',
								{ id: recordProfile.sightId }
							)}
						>
							<Link16 />
						</Button>
						<Button
							isIconOnly
							size="small"
							variant="outline"
							colorScheme="gray"
							onClick={wrapWithReport(
								() => {
									navigate({ path: buildEntityProfileUrl(recordProfile.sightId, false, true), isNewTab: true });
								},
								'open-in-new-tab-record-profile',
								{ id: recordProfile.sightId }
							)}
						>
							<NewTabSolid16 />
						</Button>
					</Flex>
				</Flex>
				<Input
					size="md"
					leftComponent={<Search16 />}
					placeholder={'Search'}
					value={searchValue}
					autoFocus
					onChange={onSearchValueChange}
					onKeyDown={(e) => {
						if (e.key === 'Escape') {
							onSearchValueChange('');
							e.stopPropagation();
							e.preventDefault();
						}
					}}
				/>
			</Flex>
		</Flex>
	);
}
