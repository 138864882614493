import { atom, useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useGetSemanticsStatusLazyQuery } from 'src/generated/graphql';
import useFeatureFlag from './stores/useFeatureFlag';

type SemanticsStatus = { metricsFullyDefined: boolean; ontologyFullyDefined: boolean };
const semanticsStatusAtom = atom<SemanticsStatus>({ metricsFullyDefined: true, ontologyFullyDefined: true });

export function useSemanticsStatus(fetchOnce: boolean = false) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const [semanticsStatus, setSemanticsStatus] = useAtom(semanticsStatusAtom);
	const [alreadyFetched, setAlreadyFetched] = useState(false);

	const [, { data, loading: isSemanticsStatusLoading, refetch: refetchSemanticsStatus }] =
		useGetSemanticsStatusLazyQuery({
			fetchPolicy: 'no-cache',
		});

	const conditionalRefetchSemanticsStatus = useCallback(async () => {
		if (!isSightfull2) return;
		return refetchSemanticsStatus();
	}, [refetchSemanticsStatus, isSightfull2]);

	useEffect(() => {
		if (!alreadyFetched && fetchOnce) {
			conditionalRefetchSemanticsStatus();
			setAlreadyFetched(true);
		}
	}, [alreadyFetched, fetchOnce, refetchSemanticsStatus, setAlreadyFetched, conditionalRefetchSemanticsStatus]);

	useEffect(() => {
		if (data && !isSemanticsStatusLoading) {
			setSemanticsStatus({
				metricsFullyDefined: data?.getSemanticsStatus?.metricsFullyDefined ?? true,
				ontologyFullyDefined: data?.getSemanticsStatus?.ontologyFullyDefined ?? true,
			});
		}
	}, [data, isSemanticsStatusLoading, setSemanticsStatus]);

	return [
		{
			metricsFullyDefined: semanticsStatus.metricsFullyDefined,
			ontologyFullyDefined: semanticsStatus.ontologyFullyDefined,
			isSemanticsStatusLoading,
		},
		{ refetchSemanticsStatus: conditionalRefetchSemanticsStatus },
	];
}
