import { gql } from '@apollo/client';

export const DataIssueFields = gql`
	fragment DataIssueFields on data_issues {
		tid
		created_at
		detection_status
		dimension
		identifying_job
		is_issue_graced
		issue_class
		issue_extra_message
		issue_grace_expiration
		issue_group_name
		issue_key
		last_detected
		latest_action_ts
		latest_action_user_id
		object_current_value
		object_datasource
		object_id
		object_id_on_graph
		object_last_modified
		object_last_modified_by
		object_name
		object_source_url
		object_type
		severity
		updated_at
		user_action
		job {
			name
		}
		user {
			first_name
			last_name
		}
	}
`;

export const UpdateIssue = gql`
	${DataIssueFields}
	mutation UpdateIssue(
		$identifying_job: String = "Babelfish"
		$detection_status: String!
		$issue_key: String!
		$object_id: String!
		$tid: Int!
	) {
		update_data_issues_by_pk(
			pk_columns: { identifying_job: $identifying_job, issue_key: $issue_key, object_id: $object_id, tid: $tid }
			_set: { user_action: $detection_status, detection_status: $detection_status }
		) {
			detection_status
		}
	}
`;

export const DataIssuesGetAll = gql`
	${DataIssueFields}
	query DataIssuesGetAll {
		# For now we hide issues we don't want to show in severity 4
		data_issues(
			limit: 600
			# TODO: Add object type here / get objects as parameter when adding new object support
			where: { severity: { _in: [1, 2, 3] }, object_type: { _in: ["Opportunity", "Account", "OpportunityLineItem"] } }
			order_by: { last_detected: desc }
		) {
			...DataIssueFields
		}
	}
`;

export const DataIssuesGetAllExtended = gql`
	${DataIssueFields}
	# Subscription causes performance issues with the SFDataGrid in hygiene_view
	query DataIssuesGetAllExtended {
		# Show hidden severity [4, 5], is Feature-flagged
		data_issues(
			limit: 600
			# TODO: Add object type here / get objects as parameter when adding new object support
			where: {
				severity: { _in: [1, 2, 3, 4, 5] }
				object_type: { _in: ["Opportunity", "Account", "OpportunityLineItem"] }
			}
			order_by: { last_detected: desc }
		) {
			...DataIssueFields
		}
	}
`;

export const CountOpenDataIssues = gql`
	${DataIssueFields}
	subscription CountOpenDataIssues {
		data_issues_aggregate(
			limit: 600
			where: {
				severity: { _in: [1, 2, 3] }
				detection_status: { _eq: "Open" }
				# TODO: Add object type here / get objects as parameter when adding new object support
				object_type: { _in: ["Opportunity", "Account", "OpportunityLineItem"] }
				_and: [
					# Hack because (NULL _neq: "Dismissed") is false
					{ _or: [{ user_action: { _is_null: true } }, { user_action: { _neq: "Dismissed" } }] }
					{ _or: [{ is_issue_graced: { _is_null: true } }, { is_issue_graced: { _eq: false } }] }
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
`;
