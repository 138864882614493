import {
	AbsolutePeriodRange,
	BuiltInPeriodRangePresets,
	PeriodRange,
	RelativePeriodRange,
} from '@sightfull/period-ranges';
import { useCallback } from 'react';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { useMetricDerivedState } from './useMetricDerivedState';
import { useMetricPageSearchParams } from './useMetricPageSearchParams';
import { normalizeSelectedXAxisElements } from './useSelectedXAxisElement';

export type UsePeriodRangeStateReturnType = [PeriodRange, (periodRange: PeriodRange) => void];
export function usePeriodRange(): UsePeriodRangeStateReturnType {
	const { periodRange, selectedXAxisElements } = useMetricDerivedState();
	const { searchParams, setSearchParams } = useMetricPageSearchParams();
	const setPeriodRange = (periodRange: PeriodRange) => {
		const newSelectedXAxisElements = periodRange.isSinglePeriod
			? normalizeSelectedXAxisElements([periodRange.asAbsoluteRange.startPeriod])
			: selectedXAxisElements.length != 0
			? 'all'
			: undefined;

		if (periodRange instanceof RelativePeriodRange) {
			setSearchParams({
				...searchParams,
				relativePeriod: BuiltInPeriodRangePresets.byRelativePeriodRange(periodRange, fiscalYearOffset())?.key,
				from: undefined,
				to: undefined,
				selectedXAxisElements: newSelectedXAxisElements,
				tableColumnState: undefined,
				...periodRange.toLegacyRelativeParams(),
				...periodRange.toUrlParams(),
			});
		} else if (periodRange instanceof AbsolutePeriodRange) {
			setSearchParams({
				...searchParams,
				from: periodRange.startPeriod.id,
				to: periodRange.endPeriod.id,
				relativePeriod: undefined,
				selectedXAxisElements: newSelectedXAxisElements,
				tableColumnState: undefined,
				...periodRange.toUrlParams(),
			});
		} else {
			throw Error(
				'Error setting the periodRange for the URL. This type of PeriodRange is not supported: ' + String(periodRange)
			);
		}
	};
	const setPeriodRangeCallback = useCallback(setPeriodRange, [setSearchParams, searchParams, selectedXAxisElements]);

	return [periodRange, setPeriodRangeCallback];
}
