const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';

const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';

const DocumentMentionsRegex = {
	NAME,
	PUNCTUATION,
};

const MENTION_TRIGGER = '@';

const VALID_CHARS = '[^' + MENTION_TRIGGER + '\\s]';

export const CapitalizedNameMentionsRegex = new RegExp('(^|[^#])((?:' + DocumentMentionsRegex.NAME + '{' + 1 + ',})$)');

const LENGTH_LIMIT = 75;

export const AtSignMentionsRegex = new RegExp(
	'(^|\\s|\\()(' + '[' + MENTION_TRIGGER + ']' + '((?:' + VALID_CHARS + '){0,' + LENGTH_LIMIT + '})' + ')$'
);

const ALIAS_LENGTH_LIMIT = 50;

export const AtSignMentionsRegexAliasRegex = new RegExp(
	'(^|\\s|\\()(' + '[' + MENTION_TRIGGER + ']' + '((?:' + VALID_CHARS + '){0,' + ALIAS_LENGTH_LIMIT + '})' + ')$'
);

export const SUGGESTION_LIST_LENGTH_LIMIT = 5;
