import Box from '@components/Box';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import { useModal } from '@hooks/ui/useModal';
import { AddSolid16 } from '@icons/index';
import { AccessController } from 'src/common/components/AccessController';
import Typography from 'src/common/components/Typography';
import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { Permissions } from 'src/types/environment';
import classes from './NavigationDrawer.module.scss';
import { TestIDs } from 'src/common/types/test-ids';

type NavigationDrawerFooterProps = {
	testId?: string;
	collectionType: string;
};

function NavigationDrawerFooter({ testId, collectionType }: NavigationDrawerFooterProps) {
	const { isOpen, onOpen, onClose } = useModal();

	return (
		<AccessController permission={Permissions.createCollection}>
			<>
				<Box
					className={classes.navigationDrawerFooter}
					data-testid={testId}
					position="absolute"
					bottom="56px"
					width={'100%'}
					background="white"
				>
					<Divider direction="horizontal" />
					<Box
						py="16px"
						w="100%"
						cursor="pointer"
						onClick={onOpen}
						data-testid={TestIDs.SIDEBAR_FOOTER_ADD_NEW_BUTTON(collectionType)}
					>
						<Flex alignItems="center" justifyContent="flex-start" paddingLeft="28px" gap="12px">
							<AddSolid16 className={classes.addNew}></AddSolid16>
							<Typography className={classes.addNew} variant={'Paragraph14R'}>
								Add new
							</Typography>
						</Flex>
					</Box>
				</Box>
				<CollectionUpsertModal isOpen={isOpen} onClose={onClose} collectionType={collectionType} />
			</>
		</AccessController>
	);
}

export default NavigationDrawerFooter;
