import { Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { getInitials } from 'src/common/utils/format';
import borderRadiuses from 'src/style/borderRadiuses';
import Typography from '../Typography';
import { TypographyVariant } from '../Typography/types';

type BadgeProps = {
	name?: string;
	fontVariant?: TypographyVariant;
	size: number | string;
	textColor?: string;
	bgColor: string;
	border?: string;
	opacity?: number;
	shape?: keyof typeof borderRadiuses;
};

export function InitialsBadge({
	name = '',
	fontVariant = 'Paragraph14R',
	size,
	textColor = 'white',
	bgColor,
	border,
	opacity,
	shape = 'round',
}: BadgeProps) {
	const firstLetters = useMemo(() => (name ? getInitials(name) : ''), [name]);

	return (
		<Flex
			borderRadius={borderRadiuses[shape]}
			backgroundColor={bgColor}
			color={textColor}
			justifyContent={'center'}
			alignItems={'center'}
			w={size}
			maxW={size}
			maxH={size}
			minWidth={size}
			h={size}
			opacity={opacity}
			border={border}
		>
			<Typography cursor={'pointer'} variant={fontVariant} textAlign="start">
				{firstLetters}
			</Typography>
		</Flex>
	);
}
