import { useCallback, useMemo } from 'react';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';

export function useMetricSQLQueryView() {
	const { searchParams, setPartialSearchParams } = useMetricPageSearchParams();

	const showSqlQueryView = useMemo(() => searchParams.showSQLQueryView ?? false, [searchParams.showSQLQueryView]);

	const setShowSqlQueryView = useCallback(
		(showSQLQueryView: boolean) => {
			setPartialSearchParams({
				showSQLQueryView,
			});
			return showSQLQueryView;
		},
		[setPartialSearchParams]
	);

	const toggleShowSqlQueryView = useCallback(
		() => setShowSqlQueryView(!showSqlQueryView),
		[setShowSqlQueryView, showSqlQueryView]
	);

	return { showSqlQueryView, setShowSqlQueryView, toggleShowSqlQueryView };
}
