import { FronteggThemeOptions } from '@frontegg/types/ThemeOptions';

const HideField = {
	appearance: 'hidden',
} as const;

export const SightfullFronteggThemeOptions: FronteggThemeOptions = {
	adminPortal: {
		palette: {
			background: {
				default: 'white',
			},
		},
		typographyStyleOptions: {
			color: '#28293D', // GRAY 800
		},
		navigation: {
			headerColor: 'white',
			groupTitleColor: '#C7C9D9', // GRAY 500
			subHeaderColor: '#C7C9D9', // GRAY 500
			background: '#1C1C28', // GRAY 900
			default: {
				color: 'white',
			},
			hover: {
				background: '#555770', // GRAY 700
			},
			selected: {
				background: 'white',
				color: '#28293D', // GRAY 800
			},
		},
		pages: {
			profile: {
				fieldsProperties: {
					jobTitle: HideField,
					phoneNumber: HideField,
					address: HideField,
				},
			},
			privacy: {
				fieldsProperties: {
					mfa: HideField,
				},
			},
			account: {
				fieldsProperties: {
					address: HideField,
					website: HideField,
					currency: HideField,
					timezone: HideField,
				},
			},
		},
	},
} as const;
