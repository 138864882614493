export const getValue = (value: any, defaultValue: any) => value || defaultValue;

export const compareFlags = (a: Record<string, any>, b: Record<string, any>) => {
	if (a.user_id > b.user_id) return 1;
	if (a.user_id < b.user_id) return -1;
	return 0;
};

export function isDefined<T>(value: T | undefined | null): value is T {
	return value != undefined;
}

type ObjectWithNameProp = {
	name: string;
	displayName?: string;
};

export function sortByNumericNames(arr: ObjectWithNameProp[]) {
	return arr.sort((a, b) => {
		const nameA = a.displayName || a.name;
		const nameB = b.displayName || b.name;
		return nameA.localeCompare(nameB, undefined, { numeric: true });
	});
}
