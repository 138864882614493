import { CircularProgress } from '@chakra-ui/react';
import { BuiltInPeriodRangePresets } from '@sightfull/period-ranges';
import { useMemo } from 'react';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import classes from '../../../GridLayout/GridLayout.module.scss';
import { WidgetHeader } from '../../WidgetHeader/WidgetHeader';

export type SignalWidgetHeaderProps = {
	isClickable?: boolean;
	isEditMode: boolean;
	title?: string;
	metricTitle: string;
	isLoading: boolean;
	menu?: React.ReactNode;
	signalDescription?: string;
	onClick?: VoidFunction;
	closeOnBlur?: boolean;
};
export function SignalWidgetHeader({
	isClickable = true,
	isEditMode,
	title,
	metricTitle,
	isLoading,
	menu,
	signalDescription,
	onClick,
	closeOnBlur = true,
}: SignalWidgetHeaderProps) {
	const {
		filters,
		breakdowns,
		parameters,
		periodRange,
		metricDisplayName,
		isLoading: isMetricLoading,
		errorMessage,
	} = useMetricDerivedState();

	const [metadata] = useMemo(() => {
		if (!periodRange.isRelativeSupported) {
			return [periodRange.prettyString, periodRange.actualDatesString];
		}
		return [
			BuiltInPeriodRangePresets.byRelativePeriodRange(periodRange.asRelativeRange, fiscalYearOffset())?.description ||
				periodRange.asRelativeRange.prettyString,
			periodRange.actualDatesString,
		];
	}, [periodRange]);

	return (
		<WidgetHeader
			isClickable={isClickable}
			onClickHandler={onClick}
			description={signalDescription}
			isEditMode={isEditMode}
			title={title || metricDisplayName}
			titleTooltip={metricTitle}
			filters={filters}
			breakdowns={breakdowns}
			parameters={parameters}
			metadata={isMetricLoading ? '' : metadata}
			hasBottomPadding={false}
			changeOnHover={false}
			actionItems={
				isLoading
					? [
							<CircularProgress
								isIndeterminate
								color="black"
								size="20px"
								padding="6px"
								key="text-signal-loading-indicator"
							/>,
					  ]
					: undefined
			}
			dragHandleClassName={classes.draggableHandle}
			menu={isLoading ? undefined : menu}
			errorMessage={errorMessage}
			closeOnBlur={closeOnBlur}
		/>
	);
}
