import difference from 'lodash/difference';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { StatisticalOperation } from 'src/types/metric';
import { STATS_SERIES_NAMES } from '../statisticOperations/constants';
import { RulesInput } from '../utils';

export function calcDisplayedLegendItems( // TODO: currently, hidden legends, statistics and targets are all seperated by named strings. fix this! PUL-2333
	{
		chartOptions,
		metricDisplayName,
		metricOperator,
		availableTargets,
	}: Required<Pick<MetricDerivedState, 'chartOptions' | 'metricOperator' | 'metricDisplayName' | 'availableTargets'>> &
		Partial<Pick<MetricDerivedState, 'displayedLegendItems'>>,
	{ searchParams: { hiddenLegendNames } }: Pick<RulesInput, 'searchParams'>
): Pick<MetricDerivedState, 'displayedLegendItems'> {
	const shouldDisplayMainMetricLegend = metricOperator.isConcatable;
	const mainLabels = shouldDisplayMainMetricLegend ? availableTargets : [...availableTargets, metricDisplayName];

	const componentsLegends = chartOptions.series.map((c) => c.name) ?? [];

	const filteredComponentsLegends =
		componentsLegends
			.map((name) => name)
			.filter((s) => !mainLabels.includes(s) && !STATS_SERIES_NAMES.includes(s as StatisticalOperation)) ?? [];

	const allAvailableLegends = [...filteredComponentsLegends, ...mainLabels];

	return {
		displayedLegendItems: {
			selectedValues: difference(allAvailableLegends, hiddenLegendNames ?? []),
			optionalValues: allAvailableLegends,
		},
	};
}
