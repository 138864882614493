import { Box } from '@chakra-ui/react';
import colors from 'src/style/colors';
import { IndicatorProps } from './types';

export const Indicator = ({ left, width }: IndicatorProps) => {
	return (
		<Box
			zIndex={1}
			left={left}
			width={width}
			position={'relative'}
			borderRadius={'10px'}
			transition={'all 0.25s ease'}
			borderBottom={`2px solid ${colors.gray['800']}`}
		/>
	);
};
