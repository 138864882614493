import { editor, Position } from 'monaco-editor';
import { CST } from 'yaml';
import { CompletionProvider } from '../completionProvider';
import { EnrichedSemanticDefinitions } from '../semanticTypes';
import { AUTO_COMPLETE_YAML_KEYS } from '../types';
import { getWordRangeAtPosition, rangeFromPosition } from '../utils/utils';
import { getContextCompletionTooltip } from '../widgetBuilder/contextTooltip';

export const MonacoHoverProviderBuilder =
	(semanticDefinitions: EnrichedSemanticDefinitions) => (model: editor.ITextModel, position: Position) => {
		const rangeFromPositionValue = rangeFromPosition(position);
		const partRange =
			getWordRangeAtPosition(model, position, CompletionProvider.EXPRESSION_PART) ?? rangeFromPositionValue;
		const part = model.getValueInRange(partRange);
		const expressionRange =
			getWordRangeAtPosition(model, position, CompletionProvider.EXPRESSION) ?? rangeFromPositionValue;
		const expression = expressionRange ? model.getValueInRange(expressionRange) : '';

		const completion = new CompletionProvider(semanticDefinitions);

		const tokenPath = completion.getTokenPath(model.getValue(), model.getOffsetAt(position));
		if (!tokenPath) {
			console.error('Failed to get document path', expression);
			return;
		}
		const lastYamlKey = tokenPath[tokenPath.length - 2];
		const isYamlKeySuitableForAutocomplete =
			CST.isScalar(lastYamlKey) && AUTO_COMPLETE_YAML_KEYS.includes(lastYamlKey.source);
		if (!isYamlKeySuitableForAutocomplete) {
			console.info('Refusing to hover based on key', lastYamlKey, 'path', tokenPath);
			return;
		}

		const isIncludingMetricsInSuggestions = !completion.isOntologyContext(model.getValue());

		const contexts = completion.createStartingContext(tokenPath, model.getValue());

		const hoverContexts = completion.walkContextForCompletions(
			contexts,
			expression,
			partRange.getEndPosition().column - expressionRange.getStartPosition().column,
			isIncludingMetricsInSuggestions
		);
		const hoveredContext = hoverContexts.find((c) => c.keyword === part);

		if (!hoveredContext) {
			return { contents: [], range: partRange };
		}

		const tooltip = getContextCompletionTooltip(hoveredContext, `$${part}`);

		return {
			contents: tooltip ? [tooltip] : [],
			range: partRange,
		};
	};
