import { Flex } from '@chakra-ui/react';
import { CrownSimple10, Lock16 } from 'src/common/components/Icons';
import { Label } from 'src/common/components/Labels';
import Typography from 'src/common/components/Typography';
import colors from 'src/style/colors';

export const PremiumLabel = (
	<Label variant="filled" colorScheme={'primary'} size="small">
		<Flex direction={'row'} alignItems="center" justifyContent={'space-between'} gap="6px">
			<CrownSimple10 />
			<Typography variant="Paragraph12M">Premium</Typography>
		</Flex>
	</Label>
);

export const ComingSoonLabel = (
	<Label variant="filled" colorScheme={'neutral'} size="small">
		<Typography variant="Paragraph12M" color={colors.gray[700]}>
			Coming soon
		</Typography>
	</Label>
);

export const AdminOnlyLabel = (
	<Label variant="filled" colorScheme={'gray'} size="small">
		<Flex direction={'row'} alignItems="center" justifyContent={'space-between'} gap="6px">
			<Lock16 />
			<Typography variant="Paragraph12M">Admin only</Typography>
		</Flex>
	</Label>
);
