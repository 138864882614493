import { MetricPeriod, PeriodRange } from '@sightfull/period-ranges';
import { ColumnState, ValueFormatterParams } from 'ag-grid-community';
import difference from 'lodash/difference';
import { getErrorMessage } from 'src/common/utils/format';
import { formatValue } from 'src/common/utils/valueFormatting';
import { formatNull, isNull } from 'src/common/utils/valueFormatting/formatters';
import {
	CalculateDetailedMetricCoreReaderLazyQueryHookResult,
	CalculateDetailedMetricCoreReaderQuery,
	InputMaybe,
	Scalars,
} from 'src/generated/graphql';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import {
	addPrefixDollarSignIfNeeded,
	buildFilters,
	removeDollarSigns,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { Breakdowns, MetricDerivedState, PulseColDef } from 'src/pages/MetricPage/utils/state.types';
import { DataLabelFormatConfig } from '../../statisticOperations/types';
import { calcMetricTable } from '../calcTable';

const DETAILED_TABLE_MAX_ROWS_SERVER_SIDE_HANDLING = 500;
const DETAILED_TABLE_MAX_ROWS_CLIENT_SIDE_HANDLING = 10000;

function doesTableHaveRowGrouping(tableColumnState: MetricDerivedState['tableColumnState']) {
	return tableColumnState?.some((column) => column?.rowGroup ?? false);
}

const shouldSortServerSide = (
	tableType: MetricDerivedState['tableType'],
	breakdowns: MetricDerivedState['breakdowns'],
	tableColumnState: MetricDerivedState['tableColumnState']
) =>
	breakdowns.values.length == 0 &&
	tableType &&
	['DetailedTable', 'MultiPeriodDetailedTable'].includes(tableType) &&
	!doesTableHaveRowGrouping(tableColumnState);

const clientSideSortComparator = (
	tableType: MetricDerivedState['tableType'],
	breakdowns: MetricDerivedState['breakdowns'],
	tableColumnState: MetricDerivedState['tableColumnState']
) =>
	tableType === 'DetailedTable' && shouldSortServerSide(tableType, breakdowns, tableColumnState) ? () => 0 : undefined;

export const getDetailedTableMaxRows = (
	tableType: MetricDerivedState['tableType'],
	breakdowns: MetricDerivedState['breakdowns'],
	tableColumnState: MetricDerivedState['tableColumnState']
) => {
	return shouldSortServerSide(tableType, breakdowns, tableColumnState)
		? DETAILED_TABLE_MAX_ROWS_SERVER_SIDE_HANDLING
		: DETAILED_TABLE_MAX_ROWS_CLIENT_SIDE_HANDLING;
};

export const isDetailedTable = (tableType: MetricDerivedState['tableType']) =>
	tableType == 'DetailedTable' || tableType == 'MultiPeriodDetailedTable';

export async function calcCoreTable(
	metricNameAfterOverrides: string,
	derivedState: Pick<
		MetricDerivedState,
		| 'metricNameWithFlavor'
		| 'metricNameWithoutFlavor'
		| 'selectedXAxisElements'
		| 'collectedProps'
		| 'chartOptions'
		| 'filters'
		| 'breakdowns'
		| 'metricOperator'
		| 'periodRange'
		| 'metricDisplayName'
		| 'flavor'
		| 'metricInfo'
		| 'tableType'
		| 'availableTargets'
		| 'displayedLegendItems'
		| 'statisticsOperations'
		| 'objectsTypes'
		| 'decimalDigits'
	> &
		Partial<Pick<MetricDerivedState, 'tableColDefs' | 'tableRowsData'>>,
	calculateDetailedMetricCoreReader: CalculateDetailedMetricCoreReaderLazyQueryHookResult[0],
	formatConfig: DataLabelFormatConfig,
	metricYamlEditorMetricDefinitionJson: InputMaybe<Scalars['JSON']['output']>
): Promise<Pick<MetricDerivedState, 'tableColDefs' | 'tableRowsData'>> {
	const { tableType } = derivedState;
	if (tableType == 'MetricTable') return calcMetricTable(derivedState, formatConfig);

	if (isDetailedTable(tableType)) {
		return await calcCoreDetailedTable(
			metricNameAfterOverrides,
			derivedState,
			calculateDetailedMetricCoreReader,
			metricYamlEditorMetricDefinitionJson
		);
	}

	return { tableColDefs: [], tableRowsData: [] };
}

function getBreakdownDisplayNames(breakdowns: Breakdowns, colDefs: { colName: string; colDisplayName: string }[]) {
	const breakdownKeys = breakdowns.values.map((breakdown) => breakdown.key);
	const breakdownDisplayNames = colDefs
		.filter((col) => breakdownKeys.includes(col.colName))
		.map((col) => col.colDisplayName);

	return breakdownDisplayNames;
}

export const prepareDetailedTableParams = (
	metricName: string,
	selectedPeriodRange: PeriodRange,
	filters: MetricDerivedState['filters'],
	breakdowns: MetricDerivedState['breakdowns'],
	collectedProps: MetricDerivedState['collectedProps'],
	tableColumnState: MetricDerivedState['tableColumnState'],
	tableType: MetricDerivedState['tableType'],
	metricYamlEditorMetricDefinitionJson?: InputMaybe<Scalars['JSON']>
) => {
	const additionalColumns = Array.from(
		new Set([
			...breakdowns.values.map((breakdown) => breakdown.key),
			...collectedProps.map(addPrefixDollarSignIfNeeded),
		])
	);

	return {
		metricName,
		start: selectedPeriodRange.asAbsoluteRange.startPeriod.id,
		end: selectedPeriodRange.asAbsoluteRange.endPeriod.id,
		filterBy: buildFilters(filters),
		additionalColumns,
		orderBy: getServerSideOrderBy(metricName, tableColumnState, tableType, breakdowns),
		userMetricDefinitions: metricYamlEditorMetricDefinitionJson,
	};
};

async function calcCoreDetailedTable(
	metricNameAfterOverrides: string,
	{
		selectedXAxisElements,
		collectedProps,
		chartOptions,
		displayedLegendItems,
		filters,
		breakdowns,
		decimalDigits,
		tableColumnState,
		tableType,
	}: Pick<
		MetricDerivedState,
		| 'selectedXAxisElements'
		| 'collectedProps'
		| 'chartOptions'
		| 'filters'
		| 'breakdowns'
		| 'displayedLegendItems'
		| 'decimalDigits'
		| 'tableColumnState'
		| 'tableType'
	> &
		Partial<Pick<MetricDerivedState, 'tableColDefs' | 'tableRowsData'>>,
	calculateDetailedMetricCoreReader: CalculateDetailedMetricCoreReaderLazyQueryHookResult[0],
	metricYamlEditorMetricDefinitionJson: InputMaybe<Scalars['JSON']['output']>
): Promise<Pick<MetricDerivedState, 'tableColDefs' | 'tableRowsData'>> {
	const selectedPeriodRange: PeriodRange | null = getSelectedPeriodRange(selectedXAxisElements);
	if (!selectedPeriodRange) return { tableColDefs: [], tableRowsData: [] };

	const { data, error } = await calculateDetailedMetricCoreReader({
		variables: {
			...prepareDetailedTableParams(
				metricNameAfterOverrides,
				selectedPeriodRange,
				filters,
				breakdowns,
				collectedProps,
				tableColumnState,
				tableType,
				metricYamlEditorMetricDefinitionJson
			),
			itemsPerPage: getDetailedTableMaxRows(tableType, breakdowns, tableColumnState),
		},
	});

	if (!data) {
		const dataObject: { tableColDefs: any[]; tableRowsData: any[]; detailedTableError?: string } = {
			tableColDefs: [],
			tableRowsData: [],
		};

		if (error?.message) {
			dataObject['detailedTableError'] = getErrorMessage(error.message);
		}

		return dataObject;
	}

	const legendItemsToHide = difference(displayedLegendItems.optionalValues, displayedLegendItems.selectedValues);

	const breakdownDisplayNames = getBreakdownDisplayNames(breakdowns, data.calcDetailedTable.cols);

	const sortedColumns = getSortedColumns(data, tableColumnState);

	const tableColDefs: PulseColDef[] = sortedColumns.map((col) => {
		const columnState = tableColumnState?.find((column) => column.colId == col.colName);

		const isName = col.colName == '$name'; // TODO: better logic here
		const isBreakdown = breakdownDisplayNames.includes(col.colDisplayName);
		const hasAggFunc = col.aggregationFunction != 'unknown';
		return {
			field: col.colName,
			headerName: isBreakdown ? breakdownDisplayNames.join(', ') : col.colDisplayName,
			aggFunc: hasAggFunc ? col.aggregationFunction : null,
			headerComponentParams: { appliedParameters: col.appliedParameters },
			pinned: isBreakdown,
			hide: isBreakdown || (!isName && legendItemsToHide.includes(col.colDisplayName)) || (columnState?.hide ?? false),
			valueGetter: (params) => {
				return params.data?.cols?.[col.colName];
			},
			valueFormatter: (params: ValueFormatterParams) => {
				const seriesFormatterKey = col.seriesFormatterKey;
				if (!seriesFormatterKey) return formatValue(col.colName, params.value, { decimalDigits });

				const series = chartOptions.series.find((series) => series.custom.rawName == seriesFormatterKey);
				const formatter = series?.custom?.seriesDataPointYFormatter;

				if (!formatter) return formatValue(col.colName, params.value, { decimalDigits });
				return formatter(params.value);
			},
			filterKey:
				collectedProps.includes(removeDollarSigns(col.colName || '')) || isName ? removeDollarSigns(col.colName) : '',
			expanded: isBreakdown,
			rowGroup: isBreakdown || (columnState?.rowGroup ?? false),
			keyCreator: ({ value }) => (isNull(value) ? formatNull() : value),
			comparator: clientSideSortComparator(tableType, breakdowns, tableColumnState),
		};
	});

	return {
		tableColDefs,
		tableRowsData: data.calcDetailedTable.rows,
	};
}

function getSortedColumns(queryResponse: CalculateDetailedMetricCoreReaderQuery, tableColumnState?: ColumnState[]) {
	const colIndex = (col: { colName: string }) =>
		tableColumnState?.findIndex((column) => column.colId == col.colName) ?? -1;

	return tableColumnState
		? [...queryResponse.calcDetailedTable.cols]
				.filter((col) => {
					const columnIndex = colIndex(col);
					const isColVisible = !tableColumnState[columnIndex]?.hide;
					const isColumnPinned = tableColumnState[columnIndex]?.pinned;
					const isColumnGrouped = tableColumnState[columnIndex]?.rowGroup;

					return isColVisible || isColumnPinned || isColumnGrouped;
				})
				.sort((c1, c2) => {
					const c1Index = colIndex(c1);
					const c2Index = colIndex(c2);

					if (c1Index < 0 || c2Index < 0) return 0;

					return c1Index - c2Index;
				})
		: queryResponse.calcDetailedTable.cols;
}

export function getSelectedPeriodRange(selectedXAxis: MetricDerivedState['selectedXAxisElements']): PeriodRange | null {
	if (!(selectedXAxis?.[0] instanceof MetricPeriod)) return null;
	const startPeriodId = selectedXAxis[0].id;

	if (selectedXAxis.length == 1) return PeriodRange.fromIdStrings(startPeriodId, startPeriodId, fiscalYearOffset());

	const lastPeriod = selectedXAxis[selectedXAxis.length - 1];
	if (!(lastPeriod instanceof MetricPeriod)) return null;

	return PeriodRange.fromIdStrings(startPeriodId, lastPeriod.id, fiscalYearOffset());
}

function getServerSideOrderBy(
	metricName: string,
	tableColumnState: MetricDerivedState['tableColumnState'],
	tableType: MetricDerivedState['tableType'],
	breakdowns: MetricDerivedState['breakdowns']
) {
	const columnToSort = tableColumnState?.filter((column) => column.sort)?.[0];
	if (!columnToSort || !shouldSortServerSide(tableType, breakdowns, tableColumnState)) return undefined;

	let isPeriod = false;
	if (tableType === 'MultiPeriodDetailedTable') {
		isPeriod = !!MetricPeriod.tryFromIdString(columnToSort.colId, fiscalYearOffset());
	}

	const periodOrderBy = isPeriod ? [{ column: 'period', direction: columnToSort.sort ?? '' }] : [];

	return [
		...periodOrderBy,
		{
			column: isPeriod ? metricName : columnToSort.colId,
			direction: columnToSort.sort ?? '',
		},
	];
}
