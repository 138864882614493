import Box from '@components/Box';
import Flex from '@components/Flex';
import { Search } from '@components/Search';
import Typography from '@components/Typography';
import useBreakpoint from '@hooks/ui/useBreakpoint';
import colors from 'src/style/colors';

type Props = {
	searchTerm: string;
	onSearchTermChange: (newSearchTerm: string) => void;
	onHeaderDoubleClick: () => void;
	paddingHorizontal?: string;
	minWidth?: string;
};

export function MetricCatalogHeader({
	searchTerm,
	onSearchTermChange,
	onHeaderDoubleClick,
	paddingHorizontal = '0px',
	minWidth = '0px',
}: Props) {
	const pageTitle = 'Metric catalog';
	const breakpoint = useBreakpoint();
	const isSmallView = !breakpoint || breakpoint === 'sm' || breakpoint === 'base';

	const pageTitleTypography = (
		<Typography color={'gray.1000'} variant="Paragraph20S">
			<span onClick={(e) => e.detail == 2 && onHeaderDoubleClick()}>{pageTitle}</span>
		</Typography>
	);

	return (
		<Flex
			justifyContent={'space-between'}
			bg={colors.white}
			minH="74px"
			minWidth={minWidth}
			w="100%"
			alignItems="center"
			pl={paddingHorizontal}
			pr={paddingHorizontal}
			boxShadow="inset 0px -1px 0px rgba(228, 228, 235, 0.6)"
			borderRadius={'16px 16px 0 0'}
		>
			<Box flexGrow={1} mr="16px">
				{pageTitleTypography}
			</Box>
			<Flex flexGrow={1} alignItems={'center'} h="100%" justifyContent={'center'} maxWidth={'424px'}>
				<Search width="100%" initialValue={searchTerm} placeholder="Search for metrics" onChange={onSearchTermChange} />
			</Flex>
			{!isSmallView && (
				<Box flexGrow={1} ml="16px" visibility={'hidden'}>
					{pageTitleTypography}
				</Box>
			)}
		</Flex>
	);
}
