import ToggleButton from '@components/ToggleButton';
import { useEffect, useState } from 'react';

export const BooleanField = ({
	value,
	isDisabled,
	onChange,
	testId,
}: {
	testId?: string;
	value: boolean;
	onChange: (newValue: boolean) => void;
	isDisabled?: boolean;
}) => {
	const [indexValue, setIndexValue] = useState(0);

	useEffect(() => {
		setIndexValue(value ? 0 : 1);
	}, [value]);

	return (
		<ToggleButton
			testId={testId}
			shouldFlexContent
			options={['True', 'False']}
			isDisabled={isDisabled}
			selectedIndex={indexValue}
			selectedColor="gray"
			onChildClicked={(index) => {
				onChange(!index);
				setIndexValue(index);
			}}
		/>
	);
};
