import { CircularProgress } from '@chakra-ui/react';
import Divider from 'src/common/components/Divider';
import { BinSolid16, DuplicateSolid16 } from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import classes from '../../../GridLayout/GridLayout.module.scss';
import { WidgetHeader } from '../../WidgetHeader/WidgetHeader';

export type TextWidgetHeaderProps = {
	isEditMode: boolean;
	isLoading: boolean;
	onDuplicate?: VoidFunction;
	onDelete?: VoidFunction;
};
export function TextWidgetHeader({ isEditMode, isLoading, onDuplicate, onDelete }: TextWidgetHeaderProps) {
	return (
		<WidgetHeader
			isEditMode={isEditMode}
			title={'Text widget'}
			dragHandleClassName={classes.draggableHandle}
			actionItems={
				isLoading
					? [
							<CircularProgress
								isIndeterminate
								color="black"
								size="20px"
								padding="6px"
								key="text-signal-loading-indicator"
							/>,
					  ]
					: undefined
			}
			menu={
				isLoading || (!onDuplicate && !onDelete) ? null : (
					<>
						{onDuplicate && (
							<ListItem label="Duplicate" size="sm" prefixIcon={<DuplicateSolid16 />} onClick={onDuplicate} />
						)}
						{onDelete && (
							<>
								<Divider direction={'horizontal'} marginY="8px" />
								<ListItem label="Delete" size="sm" prefixIcon={<BinSolid16 />} onClick={onDelete} />
							</>
						)}
					</>
				)
			}
		/>
	);
}
