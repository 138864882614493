import { ColDef, ShouldRowBeSkippedParams } from 'ag-grid-community';
import { useAtomValue } from 'jotai';
import isEqual from 'lodash/isEqual';
import 'src/common/components/Table/Table.scss';
import useTenantState from 'src/common/hooks/ui/useTenantState';
import { Filter } from 'src/generated/graphql';
import FiltersAndBreakdownsModal from 'src/pages/MetricPage/components/FiltersAndBreakdown/FiltersAndBreakdownsModal';
import {
	convertModalResultToValidCoreValues,
	useCoreNodeScheme,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { COLUMN_MODEL_TYPE, FILTER_MODEL_TYPE } from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import { FiltersAndBreakdownResponseType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import useFiltersAndBreakdown from 'src/pages/MetricPage/components/FiltersAndBreakdown/useFiltersAndBreakdown';
import { useReportEvent } from 'src/services/analytics';
import { OntologyStateAtomDerived } from '../../atoms/OntologyState';
import { ColumnsAndFilters } from '../../utils/types';
import {
	GridTable,
	GridTableBody,
	GridTableHeader,
	GridTableApi,
	useIsGridTableV2Enabled,
} from '@components/GridTable';
import {
	GridTableHeaderActionAddColumn,
	GridTableHeaderActionExport,
	GridTableHeaderActions,
} from '@components/GridTable/GridTableHeaderAction';
import { FilterColumnsAction } from './FilterColumnsAction';
import { FilterBadgesArray } from '@components/FilterBadgesArray';
import { ErrorButton } from '@components/ErrorButton';
import OntologyTable from './OntologyTable';
import { useCallback } from 'react';
import { useMenuItemsParams } from '../../../../common/components/Table/config';
import { MAX_ITEMS_TO_FETCH } from '../../utils/consts';

const TRIAL_LIMIT = 25;
const LIMITED_EXPORT_MSG =
	'Export is limited to 25 lines during the trial period. Please upgrade to access the full data.';

export type OntologyTableV2Params = {
	data: Record<string, any>[];
	columnDefs?: ColDef[];
	isErrorActive?: boolean;
	errorMessage?: string;
	columnsAndFilters: ColumnsAndFilters;
	setColumnsAndFilters: (val: ColumnsAndFilters) => void;
	entityName: string;
	onRowClicked?: (recordId: string) => void;
};

export function OntologyGridTable({
	data,
	columnDefs,
	isErrorActive,
	errorMessage,
	columnsAndFilters,
	setColumnsAndFilters,
	entityName,
	onRowClicked,
}: {
	data: Record<string, any>[];
	columnDefs?: ColDef[];
	isErrorActive?: boolean;
	errorMessage?: string;
	columnsAndFilters: ColumnsAndFilters;
	setColumnsAndFilters: (val: ColumnsAndFilters) => void;
	entityName: string;
	onRowClicked?: (recordId: string) => void;
}) {
	const isGridTableV2 = useIsGridTableV2Enabled();

	return isGridTableV2 ? (
		<OntologyTableV2
			entityName={entityName}
			columnsAndFilters={columnsAndFilters}
			setColumnsAndFilters={setColumnsAndFilters}
			errorMessage={errorMessage}
			isErrorActive={isErrorActive}
			data={data}
			columnDefs={columnDefs}
			onRowClicked={onRowClicked}
		/>
	) : (
		<OntologyTable
			entityName={entityName}
			columnsAndFilters={columnsAndFilters}
			setColumnsAndFilters={setColumnsAndFilters}
			errorMessage={errorMessage}
			isErrorActive={isErrorActive}
			data={data}
			columnDefs={columnDefs}
			onRowClicked={onRowClicked}
		/>
	);
}

export function OntologyTableV2({
	data,
	columnDefs,
	isErrorActive,
	errorMessage,
	columnsAndFilters,
	setColumnsAndFilters,
	entityName,
	onRowClicked,
}: OntologyTableV2Params) {
	const { reportEvent } = useReportEvent();
	const [modalState, modalActions] = useFiltersAndBreakdown();
	const { additionalColumns, filter } = columnsAndFilters;

	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const { shouldLimitExports } = useTenantState();
	const exportTableDataAsCSV = (gridApi: GridTableApi) => {
		const shouldRowBeSkipped = shouldLimitExports
			? (params: ShouldRowBeSkippedParams) => !!params.node.rowIndex && params.node.rowIndex >= TRIAL_LIMIT
			: undefined;

		gridApi.api?.exportDataAsCsv?.({
			shouldRowBeSkipped,
			appendContent: shouldLimitExports ? LIMITED_EXPORT_MSG : undefined,
		});
	};

	const getColumns = useCallback(
		(result: FiltersAndBreakdownResponseType) =>
			convertModalResultToValidCoreValues(result, entityName).map((e) => e.key),
		[entityName]
	);

	const handleAddColumns = useCallback(
		(result: FiltersAndBreakdownResponseType) => {
			if (!result?.items?.length) return;
			const columns = getColumns(result);
			if (isEqual(additionalColumns, columns)) return;
			setColumnsAndFilters({ ...columnsAndFilters, additionalColumns: [...additionalColumns, ...columns] });
		},
		[additionalColumns, columnsAndFilters, getColumns, setColumnsAndFilters]
	);

	const handleAddFilters = useCallback(
		(result: FiltersAndBreakdownResponseType) => {
			const { items } = result;
			if (!items.length) return;
			const columns = getColumns(result);
			const newFilter: Filter = {
				key: columns?.[0],
				values: items.map((i) => `${i.value}`),
			};
			if (filter.some((element) => element.key === newFilter.key)) {
				setColumnsAndFilters({
					...columnsAndFilters,
					filter: [...filter.filter((el) => el.key !== newFilter.key), newFilter],
				});
			} else {
				setColumnsAndFilters({ ...columnsAndFilters, filter: [...filter, newFilter] });
			}
		},
		[columnsAndFilters, filter, getColumns, setColumnsAndFilters]
	);

	const contextMenuItems = useMenuItemsParams(filter, (filterObj: { key: string; value: string }) => {
		reportEvent({
			event: 'add-filter-from-table',
			metaData: {
				key: filterObj?.key,
				value: filterObj?.value,
				entity: entityName,
			},
		});
		return setColumnsAndFilters({
			...columnsAndFilters,
			filter: [...filter, { key: filterObj?.key, values: [filterObj?.value] }],
		});
	});

	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes: ontologyState.loading ? [] : [ontologyState.entityName],
		schemeType: 'global',
		fetchPolicy: 'no-cache',
		readyToFetch: !ontologyState.loading,
	});

	return (
		<GridTable padding={'0 32px 22px 32px'}>
			<GridTableHeader
				title={`Showing ${data.length === MAX_ITEMS_TO_FETCH ? 'first ' : ''}${data.length} record${
					data.length !== 1 ? 's' : ''
				}`}
				tableActions={
					<GridTableHeaderActions>
						{isErrorActive && (
							<ErrorButton
								errorMessage={errorMessage}
								reportEventData={{ event: 'entity-edit-error-show', feature: 'Ontology Editor' }}
							/>
						)}
						<GridTableHeaderActionExport onClick={exportTableDataAsCSV} />
						<GridTableHeaderActionAddColumn
							onClick={() => {
								reportEvent({
									event: 'entity-add-column-clicked',
									metaData: { feature: 'Entity table', entity: entityName },
								});
								modalActions.onOpen(COLUMN_MODEL_TYPE, handleAddColumns);
							}}
						/>
						<FilterColumnsAction
							filter={filter}
							onClick={() => {
								reportEvent({
									event: 'entity-filters-clicked',
									metaData: { feature: 'Entity table', entity: entityName },
								});
								modalActions.onOpen(FILTER_MODEL_TYPE, handleAddFilters);
							}}
						/>
					</GridTableHeaderActions>
				}
			>
				<FilterBadgesArray
					onClose={(key: string) => {
						setColumnsAndFilters({
							...columnsAndFilters,
							filter: filter.filter((el) => el.key !== key),
						});
					}}
					filters={filter}
				/>
			</GridTableHeader>
			<GridTableBody
				data={data}
				columnDefs={columnDefs}
				onRowClicked={(event) => {
					onRowClicked?.(event?.data?._row_id);
				}}
				contextMenuItems={contextMenuItems}
			/>
			<FiltersAndBreakdownsModal
				type={modalState.type}
				isOpen={modalState.isOpen}
				onClose={modalActions.onClose}
				onAddItems={modalActions.onAddItems}
				nodeScheme={coreNodeScheme}
			/>
		</GridTable>
	);
}
