import { useMemo } from 'react';
import { tenantConfigSelectors, useTenantConfigStore } from 'src/stores/tenantConfig';

const limitedExportStates: string[] = ['Configuration', 'POV'];
export function useTenantState() {
	const trialStore = useTenantConfigStore(tenantConfigSelectors.getAll);
	const shouldLimitExports = useMemo(() => {
		if (trialStore.tenantState == null) {
			return false;
		}
		return limitedExportStates.includes(trialStore.tenantState);
	}, [trialStore.tenantState]);

	return { shouldLimitExports };
}
export default useTenantState;
