import { useAtom } from 'jotai';
import { useEffect, useCallback } from 'react';
import { useGenerativeDiscoveryPerformSuspenseQuery } from 'src/generated/graphql';
import { AskAIChatCardProps, AskAIChatMessage } from '../types';
import { useAskAIBusyState } from './useAskAIQueryRunState';

export function useCachedPerformDiscovery({
	chatMessageAtom,
}: Pick<AskAIChatCardProps, 'chatMessageAtom'>): [AskAIChatMessage, () => void] {
	const [, setAskAIBusyState] = useAskAIBusyState();
	const [chatMessage, setChatMessage] = useAtom(chatMessageAtom);
	const { userPrompt, messageKey, result: cachedResult, error: cachedError } = chatMessage;
	const { data, error } = useGenerativeDiscoveryPerformSuspenseQuery({
		variables: { userPrompt },
		fetchPolicy: 'network-only',
		queryKey: messageKey,
		skip: !!(cachedResult || cachedError),
	});

	useEffect(() => {
		if (cachedResult || cachedError) return;
		if (data || error)
			setChatMessage((prev) => ({ ...prev, result: data?.generative_discovery_perform?.response, error: !!error }));
	}, [cachedError, cachedResult, data, error, setChatMessage]);

	const onQueryDone = useCallback(() => {
		setChatMessage((prev) => ({ ...prev, completionTime: Date.now() }));
		setAskAIBusyState(false);
	}, [setChatMessage, setAskAIBusyState]);

	return [chatMessage, onQueryDone];
}
