import { Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import Typography from 'src/common/components/Typography';
import classes from './ThankYouPage.module.scss';
import Logo from './img/logo-horizontal.svg';
import MessageBox from './img/message.svg';

function HubspotContactForm() {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<Flex justifyContent={'center'} alignItems={'center'} flexGrow={1} className={classes.calendar}>
			<div
				className="meetings-iframe-container"
				data-src="https://meetings-eu1.hubspot.com/sightfull/book-a-demo?embed=true"
			></div>
		</Flex>
	);
}

export default function ThankYouPage() {
	return (
		<Flex h="100vh" flexDir={'row'}>
			<Flex w="56%" position={'relative'} paddingX={'136px'} paddingY={'275px'}>
				<img src={Logo} alt="logo" className={classes.logo} />
				<Flex flexDir={'column'} justifyContent={'center'} className={classes.text} position={'relative'} gap={'52px'}>
					<img src={MessageBox} alt="message" className={classes.message} />
					<Flex flexDir={'column'} gap={'28px'}>
						<h1>
							Thank You
							<br />
							for signing up!
						</h1>
						<Typography variant="DesktopH4Medium">Your access is coming soon! Stay tuned.</Typography>
					</Flex>
					<Typography variant="Paragraph20R">
						We are currently in the process of reviewing your registration and will be in touch with you shortly, or if
						you prefer, you can schedule a time with us here --&#62;
					</Typography>
				</Flex>
			</Flex>
			<HubspotContactForm />
		</Flex>
	);
}
