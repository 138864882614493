import { SelectOption } from '@components/Select/types';
import { EntitySearchState, EntitySearchStore } from 'src/types/entitySearch';
import { create } from 'zustand';

export const categoryDefaultValue = { label: '', value: '' };

const initialState = {
	value: '',
	selectedCategory: categoryDefaultValue,
};

export const useEntitySearchStore = create<EntitySearchStore>((set) => ({
	...initialState,
	setValue: (value: string) => set(() => ({ value: value })),
	setCategory: (selectedCategory: SelectOption) => set(() => ({ selectedCategory })),
	reset: () => set(initialState),
}));

export const selectors = {
	getState: (state: EntitySearchStore): EntitySearchState => ({
		value: state.value,
		selectedCategory: state.selectedCategory,
	}),
	setValue: (state: EntitySearchStore) => state.setValue,
	setCategory: (state: EntitySearchStore) => state.setCategory,
	reset: (state: EntitySearchStore) => state.reset,
};
