// This Page is just redirecting to support links to signals generated in Flutter
// TAF: Reevaluate this functionality

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useNavigation from 'src/services/useNavigation';
import { WorkspacePath } from '.';

export function RedirectSignalPage() {
	const { workspaceId, signalId } = useParams();
	const { navigate } = useNavigation();

	useEffect(() => {
		navigate({ path: `${WorkspacePath}/${workspaceId}`, anchor: signalId });
	}, [navigate, signalId, workspaceId]);

	return <></>;
}
