import { allPossiblePresetsByType, PeriodUnit } from '@sightfull/period-ranges';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export function calcAvailableRangePresets(
	{ availablePeriodRanges }: Pick<MetricDerivedState, 'availablePeriodRanges'>,
	omitPresets: PeriodUnit[] = []
): Pick<MetricDerivedState, 'availableRangePresets'> {
	const allPresets = allPossiblePresetsByType(fiscalYearOffset());
	const supportedPresets = Object.fromEntries(
		Object.entries(allPresets)
			.filter(([unitKey, presetsOfUnit]) => {
				const availablePeriodRange = availablePeriodRanges[unitKey as PeriodUnit];
				return presetsOfUnit && availablePeriodRange && !omitPresets.includes(unitKey as PeriodUnit);
			})
			.map(([unitKey, presetsOfUnit]) => {
				presetsOfUnit = Object.fromEntries(
					Object.entries(presetsOfUnit).filter(([, preset]) => {
						const availablePeriodRange = availablePeriodRanges[preset.periodUnit];
						const isInRange =
							availablePeriodRange?.withinRange(preset.asAbsoluteRange.startPeriod) &&
							availablePeriodRange?.withinRange(preset.asAbsoluteRange.endPeriod);
						return isInRange;
					})
				);
				return [unitKey, presetsOfUnit];
			})
			.filter(([, supportedPresets]) => Object.keys(supportedPresets).length > 0)
	);

	return { availableRangePresets: supportedPresets };
}
