import { CalculateMetricCoreReaderQuery } from 'src/generated/graphql';
import { DimensionType, MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export function calcCoreBreakdowns(
	breakdownsFromQuery: NonNullable<CalculateMetricCoreReaderQuery['calcMetricV2']['chartOptions']>['breakdowns']
): Pick<MetricDerivedState, 'breakdowns'> {
	const breakdowns = breakdownsFromQuery.map(({ key, label }) => ({
		type: 'text' as DimensionType,
		key,
		label,
	}));

	return { breakdowns: { isDisabled: false, values: breakdowns ?? [] } };
}
