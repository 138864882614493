import Box from '@components/Box';
import Flex from '@components/Flex';
import { useMemo } from 'react';
import { AdvancedSelect } from 'src/common/components/AdvancedSelect';
import Divider from 'src/common/components/Divider';
import { ManyToOne16, OneToMany16 } from 'src/common/components/Icons';
import { SelectOption } from 'src/common/components/Select/types';
import Typography from 'src/common/components/Typography';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import { relationshipTypeToNames } from 'src/lib/completions/types';
import { getEntity } from 'src/lib/completions/utils/utils';
import { MonacoTooltipComponent } from 'src/lib/completions/widgetBuilder/MonacoTooltipComponent';
import { getEntityCssCompletionTooltip } from 'src/lib/completions/widgetBuilder/entityCompletionItem';
import { SelectLabel, TooltipIcon } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';
import { KeysType } from 'src/pages/OntologyPage/hooks/useDimensionBuilderDerivedState';
import { OntologyRelationshipInfo } from 'src/pages/OntologyPage/utils/normalizeYaml';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import DescriptionField from '../../DescriptionField';
import { JoinKeysField } from '../../JoinKeys/JoinKeysField';
import { ReferencedEntityTooltip } from '../../Tooltips/ReferencedEntityTooltip';
import { TypeTooltip } from '../../Tooltips/TypeTooltip';

export function RelationshipsForm({
	entityName,
	propertyDefinitionValue,
	isEditable = true,
	isEditRelationshipFlow = false,
	entitiesOptions,
	upsertYAMLProperty,
	upsertYAMLProperties,
}: {
	entityName: string;
	propertyDefinitionValue: OntologyRelationshipInfo;
	isEditable?: boolean;
	isEditRelationshipFlow?: boolean;
	entitiesOptions: SelectOption[] | void;
	upsertYAMLProperty: (key: KeysType[0]['key'], value: KeysType[0]['value']) => void;
	upsertYAMLProperties: (keys: KeysType) => void;
}) {
	const { referenced_entity, type, meta, on, name } = propertyDefinitionValue;
	const { description, display_name } = meta || {};
	const { semanticDefinitions } = useSemanticDefinitions();

	const { reportEvent } = useReportEvent({
		objectType: 'relationships',
		objectName: display_name || name,
		parentEntity: entityName,
		flow: isEditRelationshipFlow ? 'edit' : 'create',
	});

	const referencedEntityControlledValue = useMemo(
		() =>
			referenced_entity
				? entitiesOptions?.find((el) => el.value === referenced_entity) || {
						value: referenced_entity,
						label: referenced_entity,
				  }
				: undefined,
		[entitiesOptions, referenced_entity]
	);

	const fromEntityControlledValue = useMemo(
		() => entitiesOptions?.find((el) => el.value === entityName),
		[entitiesOptions, entityName]
	);

	const typeOptions = useMemo(
		() =>
			Object.entries(relationshipTypeToNames).map(([value, label]) => ({
				label,
				value,
				icon: value === 'one_to_many' ? <OneToMany16 /> : <ManyToOne16 />,
			})),
		[]
	);

	const typeControlledValue = useMemo(() => typeOptions?.find((el) => el.value === type), [typeOptions, type]);

	return (
		<Box width={'100%'}>
			<Flex flexDirection={'column'}>
				<Flex marginBottom={'8px'}>
					<SelectLabel
						color={'gray.1100'}
						text="From entity"
						trailingIcon={
							<TooltipIcon
								tooltipBody={
									<Box maxW={'260px'}>
										<Typography color={'white'} variant="Paragraph14R">
											The original entity, in which the relationship is defined.
										</Typography>
									</Box>
								}
							/>
						}
					/>
				</Flex>
				<AdvancedSelect
					entityName={entityName}
					onReportEvent={(input) =>
						reportEvent({
							event: 'ontology-object-edit-UI-input-provided',
							metaData: {
								fieldname: 'From entity',
								input,
							},
						})
					}
					isDisabled
					onChange={() => 0}
					placeholder="Select from entity"
					options={entitiesOptions}
					controlledValue={fromEntityControlledValue}
					dataIntercomTarget="from_entity"
				/>
			</Flex>
			<Flex marginTop={'24px'} flexDirection={'column'}>
				<Flex marginBottom={'8px'}>
					<SelectLabel
						color={'gray.1100'}
						text="Referenced entity"
						trailingIcon={
							<TooltipIcon
								tooltipBody={
									<ReferencedEntityTooltip
										onHelpClicked={() =>
											reportEvent({
												event: 'ontology-object-help-clicked',
												metaData: {
													component: 'Entity',
												},
											})
										}
									/>
								}
							/>
						}
					/>
				</Flex>
				<AdvancedSelect
					label="Referenced entity"
					entityName={entityName}
					onReportEvent={(input) =>
						reportEvent({
							event: 'ontology-object-edit-UI-input-provided',
							metaData: {
								fieldname: 'referenced entity',
								input,
							},
						})
					}
					isWarningModalEnabled={!!on}
					isDisabled={!isEditable}
					onChange={(newValue) => {
						upsertYAMLProperties([
							{ key: 'referenced_entity', value: newValue.value },
							{ key: 'on', value: '' },
						]);
					}}
					placeholder="Select entity"
					options={entitiesOptions}
					controlledValue={referencedEntityControlledValue}
					dataIntercomTarget="referenced_entity"
					hoverTooltipBuilder={(entity) => {
						if (!semanticDefinitions || !entity) return;
						const enrichedEntity = getEntity(semanticDefinitions, entity.value);
						if (!enrichedEntity) return;
						return (
							<MonacoTooltipComponent
								tooltipMarkdownString={getEntityCssCompletionTooltip(enrichedEntity, enrichedEntity.name)}
							/>
						);
					}}
				/>
			</Flex>
			<Flex marginTop={'24px'} flexDirection={'column'}>
				<Flex marginBottom={'8px'}>
					<SelectLabel color={'gray.1100'} text="Type" trailingIcon={<TooltipIcon tooltipBody={<TypeTooltip />} />} />
				</Flex>
				<AdvancedSelect
					entityName={entityName}
					onReportEvent={(input) =>
						reportEvent({
							event: 'ontology-object-edit-UI-input-provided',
							metaData: {
								fieldname: 'type',
								input,
							},
						})
					}
					isDisabled={!isEditable}
					onChange={(newValue) => upsertYAMLProperty('type', newValue.value)}
					placeholder="Select type"
					options={typeOptions}
					controlledValue={typeControlledValue}
					dataIntercomTarget="type"
				/>
			</Flex>
			<Box marginTop={'24px'}>
				<DescriptionField
					placeholder={'Describe this relationship'}
					onChange={(description: string) =>
						upsertYAMLProperty('meta', {
							display_name,
							description: description || undefined,
						})
					}
					onBlur={() =>
						reportEvent({
							event: 'ontology-object-edit-UI-input-provided',
							metaData: {
								fieldname: 'description',
								input: description,
							},
						})
					}
					value={description}
					isDisabled={!isEditable}
				/>
			</Box>
			<Box margin={'24px 0px'} padding={'4px 0px'}>
				<Divider direction="horizontal" color={colors.gray[300]} />
			</Box>
			<JoinKeysField
				relationshipName={name}
				referencedEntity={referenced_entity}
				isEditable={isEditable && !!referenced_entity}
				value={on}
				onChange={upsertYAMLProperty}
				isEditRelationshipFlow={isEditRelationshipFlow}
			/>
		</Box>
	);
}
