import Box from '@components/Box';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable as LexicalContentEditable } from '@lexical/react/LexicalContentEditable';
import { useCallback } from 'react';
import classes from '../styles/TextEditor.module.scss';

const ReadOnlyContentEditable = (): JSX.Element => {
	const [editor] = useLexicalComposerContext();

	const ref = useCallback(
		(rootElement: null | HTMLElement) => {
			editor.setRootElement(rootElement);
		},
		[editor]
	);

	return <Box contentEditable={false} className={classes.editorViewContainer} ref={ref} />;
};

export const LEXICAL_CONTENT_EDITABLE_ID = 'lexical-content-editable';
const ContentEditable = ({ isEditMode }: { isEditMode: boolean }) => {
	return isEditMode ? (
		<LexicalContentEditable className={classes.editorInput} id={LEXICAL_CONTENT_EDITABLE_ID} />
	) : (
		<ReadOnlyContentEditable />
	);
};

export default ContentEditable;
