import Box from '@components/Box';
import Button from 'src/common/components/Button';
import { Link16 } from 'src/common/components/Icons';
import Tooltip from 'src/common/components/Tooltip';
import { useOnCopy } from 'src/common/hooks/interaction/useOnCopy';

export function CopyLinkButton({ onClick, dataIntercomArea }: { onClick: () => void; dataIntercomArea?: string }) {
	const { onCopyText } = useOnCopy();

	const onCopy = () => {
		const currentURL = window.location.href;
		onCopyText({ contentToCopy: currentURL });
		onClick();
	};

	return (
		<Tooltip label="Copy URL" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
			<Box data-intercom-area={dataIntercomArea} data-intercom-type={'button'} data-intercom-target={'Link'}>
				<Button isIconOnly variant="outline" onClick={onCopy} size="inline" colorScheme="black">
					<Link16 />
				</Button>
			</Box>
		</Tooltip>
	);
}
