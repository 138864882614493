import Flex from '@components/Flex';
import Box from '@components/Box';
import Avatar from '@components/Avatar/Avatar';
import Typography from '@components/Typography';
import Tooltip from '@components/Tooltip';

import { useRef } from 'react';
import useUser from '@hooks/stores/useUser';
import { useOnOverflow } from '@hooks/ui/useOnOverflow';
import { Memo16 } from '@components/Icons';

export type AskAIChatCardLayoutProps = {
	children: JSX.Element[] | JSX.Element;
	userPrompt: string;
	footer?: JSX.Element;
	borderColor: string;
	backgroundColor?: string;
};

function CardAvatar() {
	const [{ picture }] = useUser();

	if (picture) {
		return <Avatar width={'24px'} height={'24px'} imageUrl={picture} shape="round" />;
	}
	return <Memo16 />;
}

export function ChatCardHeader({ userPrompt }: { userPrompt: string }) {
	const descriptionRef = useRef(null);
	const isOverflowingDescription = useOnOverflow(descriptionRef, [userPrompt], undefined, false);

	return (
		<Flex gap="8px" direction={'row'} alignItems="center" padding={'16px 16px 0 16px'}>
			<CardAvatar />
			<Tooltip
				size="md"
				variant="fluid"
				label={isOverflowingDescription && userPrompt}
				placement={'top'}
				margin={'0px 0px 8px 0px'}
			>
				<Typography ref={descriptionRef} color={'gray.800'} variant="DesktopH10Regular" noOfLines={2}>
					{userPrompt}
				</Typography>
			</Tooltip>
		</Flex>
	);
}

export function AskAIChatCardLayout({
	children,
	userPrompt,
	footer,
	borderColor,
	backgroundColor,
}: AskAIChatCardLayoutProps) {
	return (
		<Box margin={'12px'}>
			<Box border={'1px solid'} borderColor={borderColor} backgroundColor={backgroundColor} borderRadius={'8px'}>
				<Flex direction={'column'} gap={'12px'}>
					<ChatCardHeader userPrompt={userPrompt ?? ''} />
					{children}
				</Flex>
			</Box>
			{footer && <Box marginTop={'10px'}>{footer}</Box>}
		</Box>
	);
}
