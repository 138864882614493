import Box from '@components/Box';
import Flex from '@components/Flex';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import {
	$getSelection,
	$isRangeSelection,
	GridSelection,
	LexicalEditor,
	NodeSelection,
	RangeSelection,
	SELECTION_CHANGE_COMMAND,
} from 'lexical';
import Button from 'src/common/components/Button';
import { useCallback, useEffect, useRef, useState } from 'react';
import Input from 'src/common/components/Input';
import Tooltip from 'src/common/components/Tooltip';
import Divider from '../../Divider';
import { EditFull16, NewTabSolid16 } from 'src/common/components/Icons';
import { Unlink16 } from '../icons';
import { LowPriority } from '../consts';
import { getSelectedNode, positionEditorElement } from '../utils';
import ToolbarButton from './ToolbarButton';
import classes from '../styles/TextEditor.module.scss';
import { REMOVE_TEXT_SELECTION } from '../commands';

function FloatingLinkEditor({ editor, onEdit }: { editor: LexicalEditor; onEdit: () => void }) {
	const editorRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const mouseDownRef = useRef(false);
	const [linkUrl, setLinkUrl] = useState('');
	const [isInsertionMode, setInsertionMode] = useState(true);
	const [lastSelection, setLastSelection] = useState<RangeSelection | NodeSelection | GridSelection | null>(null);

	const updateURL = (URL?: string) => {
		if (!URL) {
			setLinkUrl('');
			setInsertionMode(false);
		} else {
			setLinkUrl(URL);
			setInsertionMode(true);
		}
	};

	const updateSelectedLink = useCallback(() => {
		const selection = $getSelection();

		if ($isRangeSelection(selection)) {
			const node = getSelectedNode(selection);
			const parent = node.getParent();
			if ($isLinkNode(parent)) {
				const URL = parent.getURL();
				updateURL(URL);
			} else if ($isLinkNode(node)) {
				const URL = node.getURL();
				updateURL(URL);
			} else {
				setLinkUrl('');
			}
		}
		const editorElem = editorRef.current;
		const nativeSelection = window.getSelection();
		const activeElement = document.activeElement;

		if (!editorElem) {
			return;
		}

		const rootElement = editor.getRootElement();
		if (selection && rootElement && rootElement.contains(nativeSelection?.anchorNode || null)) {
			const domRange = nativeSelection?.getRangeAt(0);
			let rect: DOMRect | undefined;
			if (nativeSelection?.anchorNode === rootElement) {
				let inner: Element = rootElement;
				while (inner.firstElementChild != null) {
					inner = inner.firstElementChild;
				}
				rect = inner.getBoundingClientRect();
			} else {
				rect = domRange?.getBoundingClientRect();
			}

			if (!mouseDownRef.current) {
				positionEditorElement(editorElem, rect);
			}
			setLastSelection(selection);
		} else if (!activeElement) {
			positionEditorElement(editorElem);

			setLastSelection(null);
			setLinkUrl('');
		}

		return true;
	}, [editor]);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateSelectedLink();
				});
			}),

			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				() => {
					updateSelectedLink();
					return true;
				},
				LowPriority
			)
		);
	}, [editor, updateSelectedLink]);

	useEffect(() => {
		inputRef.current?.focus();
	}, [isInsertionMode]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			handleEdit();
		}
	};

	const handleEdit = () => {
		if (lastSelection) {
			if (linkUrl) {
				editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
			}
		}

		setTimeout(
			() => {
				onEdit();
				editor.dispatchCommand(REMOVE_TEXT_SELECTION, '');
			},

			0
		);
	};

	const handleUnlink = () => editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);

	return (
		<Box ref={editorRef} className={classes.linkEditorContainer}>
			{isInsertionMode ? (
				<>
					<ToolbarButton onClick={() => window.open(linkUrl)} tooltipLabel="Open link">
						<NewTabSolid16 />
					</ToolbarButton>

					<Divider direction="vertical" />

					<ToolbarButton onClick={() => setInsertionMode(false)} tooltipLabel="Edit">
						<EditFull16 />
					</ToolbarButton>

					<ToolbarButton onClick={handleUnlink} tooltipLabel="Disconnect">
						<Unlink16 />
					</ToolbarButton>
				</>
			) : (
				<Flex alignItems="center">
					<Box position="relative">
						<Box onClick={handleUnlink} position="absolute" right="12px" top="8px" zIndex="2" cursor="pointer">
							<Tooltip size="sm" label="Disconnect" marginTop="20px">
								<Unlink16 />
							</Tooltip>
						</Box>
						<Input
							_ref={inputRef}
							value={linkUrl}
							placeholder="Add link or URL"
							onChange={setLinkUrl}
							onKeyDown={handleKeyDown}
						/>
					</Box>

					<Button size="small" onClick={handleEdit} variant="outline" colorScheme="gray" ml="8px">
						Update
					</Button>
				</Flex>
			)}
		</Box>
	);
}

export default FloatingLinkEditor;
