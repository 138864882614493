import Box from '@components/Box';
import { useMonacoProviders } from 'src/lib/completions/hooks/useMonacoProviders';
import { BorderedMonacoYamlEditor } from 'src/pages/MetricPage/components/MonacoEditor/BorderedMonacoEditor';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';

export function ModalYAMLEditor({
	entityYAMLValue,
	onChange,
	hasUnsavedChanges,
	setHasErrors,
	entityName,
}: {
	entityYAMLValue: string;
	onChange: (val: string) => void;
	hasUnsavedChanges: boolean;
	setHasErrors: (val: boolean) => void;
	entityName: string;
}) {
	const hasWritePermission = usePermissionCheck().isHavingPermission(Permissions.writeEntities);
	const { reportEvent } = useReportEvent({ feature: 'Upsert entity modal' });
	useMonacoProviders();

	return (
		<Box height={'428px'} position={'relative'}>
			<BorderedMonacoYamlEditor
				onErrorsChange={setHasErrors}
				value={entityYAMLValue}
				schemaType={'base-entity'}
				isEditable={hasWritePermission}
				onChange={onChange}
				hasUnsavedChanges={hasUnsavedChanges}
				onFocus={() =>
					reportEvent({
						event: 'ontology-object-yaml-focus-clicked',
						metaData: {
							objectType: 'full entity',
							objectName: entityName,
						},
					})
				}
			/>
		</Box>
	);
}
