import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import Popover from '@components/Popover';
import { ExploreMenu } from '@components/TopNavigation/ExploreMenu';
import { useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import shadows from 'src/style/shadows';
import Box from '../Box';
import Flex from '../Flex';
import { ChevronDownSmall16, ChevronUpSmall16, Ellipse14 } from '../Icons';
import { useObserveElementWidth } from '../MetricView/MetricChart/MetricChart';
import { NavigationTab } from './NavigationTab';
import classes from './TopNavigation.module.scss';

type ButtonConfigType = {
	path: string;
	ref: React.RefObject<HTMLDivElement>;
	name: 'dashboard' | 'metrics' | 'workspace' | 'explore';
	isDefault?: boolean;
};

export const NavigationTabBar = ({
	isSemanticsStatusLoading,
	metricsFullyDefined,
}: {
	isSemanticsStatusLoading: boolean;
	metricsFullyDefined: boolean;
}) => {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const location = useLocation();
	const [currentElementWidth, setCurrentElementWidth] = useState<{
		elementLeft: number;
		elementWidth: number;
		selectedElement: 'dashboard' | 'metrics' | 'workspace' | 'explore';
	} | null>(null);

	const { width, ref } = useObserveElementWidth<HTMLDivElement>();
	const refDashboardButton = useRef<HTMLDivElement>(null);
	const refMetricButton = useRef<HTMLDivElement>(null);
	const refWorkSpaceButton = useRef<HTMLDivElement>(null);
	const refExploreButton = useRef<HTMLDivElement>(null);

	const { isOpen, onClose, onOpen } = useDisclosure();

	useLayoutEffect(() => {
		const buttonConfigs: ButtonConfigType[] = [
			{ path: 'metric', ref: refMetricButton, name: 'metrics' },
			{ path: 'dashboard', ref: refDashboardButton, name: 'dashboard', isDefault: true },
			{ path: 'workspace', ref: refWorkSpaceButton, name: 'workspace' },
			{ path: 'explore', ref: refExploreButton, name: 'explore' },
		];

		const config = buttonConfigs.find(
			({ path, isDefault }) => (isDefault && location.pathname === '/') || location.pathname.includes(path)
		);

		if (config?.ref) {
			setCurrentElementWidth({
				elementLeft: config.ref.current?.offsetLeft || 0,
				elementWidth: config.ref.current?.offsetWidth || 0,
				selectedElement: config.name,
			});
		} else {
			setCurrentElementWidth(null);
		}
	}, [location, refDashboardButton, refMetricButton, refWorkSpaceButton, width]);

	const MetricsMissingDot = () => (
		<Box position={'absolute'} alignSelf={'flex-end'}>
			<Box position={'relative'} marginTop={'7px'} marginRight={'7px'} color={'yellow.700'}>
				<Ellipse14 />
			</Box>
		</Box>
	);

	return (
		<Flex ref={ref} className={classes.navigationTabBar} position="relative" gap={'4px'}>
			<Box ref={refDashboardButton}>
				<NavigationTab
					label="Dashboards"
					isSelected={
						currentElementWidth?.selectedElement === 'dashboard' || currentElementWidth?.selectedElement === 'workspace'
					}
					path={'dashboard'}
				/>
			</Box>

			<Flex direction={'column'}>
				<Box ref={refMetricButton}>
					<NavigationTab
						label="Metrics"
						isSelected={currentElementWidth?.selectedElement === 'metrics'}
						path={'metrics'}
					/>
				</Box>
				{!isSemanticsStatusLoading && !metricsFullyDefined && <MetricsMissingDot />}
			</Flex>

			{isSightfull2 && (
				<Popover
					triggerElement={
						<Box ref={refWorkSpaceButton}>
							<NavigationTab
								label="Explore"
								isSelected={isOpen || currentElementWidth?.selectedElement === 'explore'}
								path={'explore'}
								onClick={onOpen}
								rightIcon={isOpen ? <ChevronUpSmall16 /> : <ChevronDownSmall16 />}
							/>
						</Box>
					}
					isOpen={isOpen}
					placement={'bottom-start'}
					onClose={onClose}
					popoverContentProps={{
						boxShadow: shadows['a-22'],
					}}
				>
					<PopoverContent>
						<ExploreMenu onClose={onClose} />
					</PopoverContent>
				</Popover>
			)}
		</Flex>
	);
};
