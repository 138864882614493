import { useCallback, useMemo } from 'react';
import Tooltip from '@components/Tooltip';
import Flex from '@components/Flex';
import Button from '@components/Button';
import ResizableIcon from '@components/ResizableIcon';
import { useReportEvent } from '@services/analytics';
import { DotsVertical16, SlidersVertical16, ArrowUp16, ArrowDown16 } from '@icons/index';
import { IHeaderParams } from 'ag-grid-community';
import { AppliedParameterValue } from '@components/Chart/types';
import { ComponentNameWithParamsTooltipContent } from '@pages/MetricPage/components/Parameters/ComponentNameWithParamsTooltip';
import { As } from '@chakra-ui/react';

type SortDirection = null | 'asc' | 'desc';

function ColumnTooltip({ content, children }: { content: React.ReactNode; children: React.ReactNode }) {
	return (
		<Tooltip label={content} placement={'top'} size={'md'} closeDelay={0}>
			{children}
		</Tooltip>
	);
}

function SortIcon({ direction }: { direction: SortDirection }) {
	switch (direction) {
		case 'asc':
			return (
				<ColumnTooltip content={'Sorted ascending'}>
					<ResizableIcon icon={ArrowUp16} size={'10px'} />
				</ColumnTooltip>
			);
		case 'desc':
			return (
				<ColumnTooltip content={'Sorted descending'}>
					<ResizableIcon icon={ArrowDown16} size={'10px'} />
				</ColumnTooltip>
			);
	}
	return (
		<ColumnTooltip content={'Sort column'}>
			<ResizableIcon icon={ArrowUp16} size={'10px'} color={'gray.500'} className={'ag-grid-custom-header-action'} />
		</ColumnTooltip>
	);
}

function ColumnButton({ onClick, icon, tooltip }: { tooltip: string; icon: As; onClick?: (...args: any[]) => void }) {
	return (
		<ColumnTooltip content={tooltip}>
			<Button
				isIconOnly={true}
				size={'xxs'}
				colorScheme={'gray'}
				variant={'outline'}
				onClick={onClick}
				className={'ag-grid-custom-header-action'}
			>
				<ResizableIcon icon={icon} size={'12px'} />
			</Button>
		</ColumnTooltip>
	);
}

export type GridTableColumnHeaderRendererProps = {
	appliedParameters?: AppliedParameterValue[];
	removable?: boolean;
	onRemoveColumn?: (colId: string) => void;
};

export function GridTableColumnHeaderRenderer({
	displayName,
	column,
	progressSort,
	api,
	appliedParameters,
}: IHeaderParams & GridTableColumnHeaderRendererProps) {
	const { reportEvent } = useReportEvent();

	// this is not memoized on purpose to ensure re-rendering of the sort icon
	const sortDirection = column.getSort() ?? null;

	const hasAppliedParameters = useMemo(() => appliedParameters && appliedParameters.length > 0, [appliedParameters]);

	const onOpenMenu = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.preventDefault();
			api.showColumnMenuAfterMouseClick(column, event.nativeEvent);
		},
		[api, column]
	);

	const onSort = useCallback(() => {
		progressSort();
		reportEvent({
			event: 'sort-column-grid-table',
			metaData: {
				columnName: displayName,
				nextSortType: column.getSort(),
			},
		});
	}, [column, displayName, progressSort, reportEvent]);

	return (
		<Flex alignItems={'center'} width={'100%'} className={'ag-grid-custom-header'}>
			<Flex gap={'8px'} onClick={onSort} flex={1}>
				<ColumnTooltip
					content={<ComponentNameWithParamsTooltipContent component={{ label: displayName, appliedParameters }} />}
				>
					{displayName}
				</ColumnTooltip>
				{hasAppliedParameters && (
					<ColumnTooltip content={<ComponentNameWithParamsTooltipContent component={{ appliedParameters }} />}>
						<ResizableIcon icon={SlidersVertical16} size={'12px'} />
					</ColumnTooltip>
				)}
				<SortIcon direction={sortDirection} />
			</Flex>
			<Flex>
				<ColumnButton tooltip={'More options'} onClick={onOpenMenu} icon={DotsVertical16} />
			</Flex>
		</Flex>
	);
}
