import { Box } from '@chakra-ui/react';
import Flex from '@components/Flex';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import Skeleton from 'src/common/components/Skeleton';
import { useDefaultColDef } from 'src/common/components/Table/config';
import useTenantConfig from 'src/common/hooks/stores/useTenantConfig';
import { formatValue } from 'src/common/utils/valueFormatting';
import { AdditionalColumn, GetRecordProfileByIdQuery, useGetRecordProfileByIdQuery } from 'src/generated/graphql';
import FiltersAndBreakdownsModal from 'src/pages/MetricPage/components/FiltersAndBreakdown/FiltersAndBreakdownsModal';
import {
	convertModalResultToValidCoreValues,
	useCoreNodeScheme,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { FiltersAndBreakdownResponseType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import useFiltersAndBreakdown from 'src/pages/MetricPage/components/FiltersAndBreakdown/useFiltersAndBreakdown';
import { HEADER_HEIGHT, ROW_HEIGHT } from 'src/pages/SearchResultsPage/consts';
import { useReportEvent } from 'src/services/analytics';
import { useEntityProfileDrawer, useEntityProfileReportEvent } from '../EntityProfileDrawer/hooks';
import classes from './RecordProfileBody.module.scss';
import { RecordProfileSectionHeader } from './RecordProfileSectionHeader';

const RELATION_PAGE_SIZE = 6;

type RecordProfileRelationTableProps = {
	headerName: string;
	relationsRows: GetRecordProfileByIdQuery['recordProfile']['relations'][0]['rows'];
	searchValue: string;
	entityName: string;
	relationName: string;
	currentRecordId: string;
};
export function RecordProfileRelationTable({
	headerName,
	relationsRows: relationsRowsFromParent,
	searchValue,
	entityName,
	relationName,
	currentRecordId,
}: RecordProfileRelationTableProps) {
	const tenantConfig = useTenantConfig();
	const { wrapWithReport } = useReportEvent();
	const tableRef = useRef<AgGridReact>(null);
	const [modalState, { onOpen, onAddItems, onClose }] = useFiltersAndBreakdown();
	const [additionalColumns, setAdditionalColumns] = useState<AdditionalColumn[]>([]);
	const { data, loading, error } = useGetRecordProfileByIdQuery({
		variables: { id: currentRecordId, additionalColumns },
		skip: additionalColumns.length === 0,
	});
	const relationsRows = useMemo(() => {
		if (loading || error) return [];
		if (!data) return relationsRowsFromParent;

		return data?.recordProfile?.relations.find((relation) => relation.name === relationName)?.rows ?? [];
	}, [data, error, loading, relationName, relationsRowsFromParent]);
	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes: [entityName],
		schemeType: 'global',
		fetchPolicy: 'no-cache',
	});

	const defaultColDef = useDefaultColDef(true, true);
	const hasNameColumn = useMemo(() => !!relationsRows.find((row) => row.name), [relationsRows]);

	const colDefs: ColDef[] = useMemo(() => {
		return [
			...(hasNameColumn ? [{ field: 'name' }] : []),
			...(relationsRows[0]?.additionalColumns?.map((column) => ({
				field: column.key,
				valueFormatter: (params: ValueFormatterParams) => formatValue(column.key, params.value, tenantConfig),
			})) ?? []),
		];
	}, [hasNameColumn, relationsRows, tenantConfig]);

	const rowData = useMemo(() => {
		return relationsRows
			.map((row) => {
				return {
					...(hasNameColumn ? { name: row.name } : {}),
					...(row.additionalColumns
						?.map((column) => ({ [column.key]: column.value }))
						.reduce((acc, column) => ({ ...acc, ...column })) ?? {}),
					_row_id: row.sightId,
				};
			})
			.filter((row) => JSON.stringify(row).toLowerCase().includes(searchValue.toLowerCase()));
	}, [hasNameColumn, relationsRows, searchValue]);

	const onBtNext = useCallback(() => {
		tableRef?.current?.api.paginationGoToNextPage();
	}, []);
	const onBtPrevious = useCallback(() => {
		tableRef?.current?.api.paginationGoToPreviousPage();
	}, []);

	const resizeColumns = () => {
		tableRef?.current?.api.sizeColumnsToFit();
	};

	const { reportOpenEntityDrawer } = useEntityProfileReportEvent();
	const { pushEntity } = useEntityProfileDrawer();

	const onRowClicked = (event: any) => {
		const recordId = event?.data?._row_id;

		reportOpenEntityDrawer({
			origin: 'record-profile-one-to-many',
			id: recordId,
		});

		pushEntity(recordId);
	};

	const onCloseAddColumnModal = useCallback(
		(result: FiltersAndBreakdownResponseType) => {
			if (!result?.items?.length) return;
			const columns = convertModalResultToValidCoreValues(result, entityName).map((e) => e.key);

			setAdditionalColumns((prev) => {
				const isRelationHavePreviousAddedColumns = prev.find((e) => e.relationshipName === relationName);
				if (isRelationHavePreviousAddedColumns) {
					return prev.map((e) =>
						e.relationshipName === relationName ? { ...e, tokens: e.tokens.concat(columns) } : e
					);
				}
				return prev.concat({
					relationshipName: relationName,
					tokens: columns,
				});
			});
		},
		[entityName, relationName, setAdditionalColumns]
	);

	return (
		<Flex flexDir={'column'} alignItems={'flex-start'} gap={'12px'} alignSelf={'stretch'}>
			<Flex justifyContent={'space-between'} alignItems={'center'} alignSelf={'stretch'}>
				<RecordProfileSectionHeader
					suffix="rows"
					headerName={headerName}
					totalCount={rowData.length}
					limit={RELATION_PAGE_SIZE}
					onPrevious={onBtPrevious}
					onNext={onBtNext}
					onAddColumn={wrapWithReport(() => onOpen('column', onCloseAddColumnModal), 'record-profile-click-add-cols', {
						feature: 'Entity Drawer',
						headerName,
						entityName,
						relationName,
					})}
				/>
			</Flex>
			<Box className="tableContainer">
				<Box paddingBottom={0} flexGrow={1} width="100%" className="tableWrapper">
					{loading ? (
						<Skeleton className={classes.skeletonTable} />
					) : (
						rowData.length != 0 && (
							<AgGridReact
								ref={tableRef}
								columnDefs={colDefs}
								className="ag-theme-alpine"
								rowData={rowData}
								headerHeight={HEADER_HEIGHT}
								rowHeight={ROW_HEIGHT}
								defaultColDef={defaultColDef}
								rowSelection="multiple"
								pagination={true}
								paginationPageSize={RELATION_PAGE_SIZE}
								suppressPaginationPanel={true}
								domLayout="autoHeight"
								onRowClicked={onRowClicked}
								onFirstDataRendered={resizeColumns}
							/>
						)
					)}
				</Box>
			</Box>
			<FiltersAndBreakdownsModal
				type={modalState.type}
				isOpen={modalState.isOpen}
				onClose={onClose}
				onAddItems={onAddItems}
				nodeScheme={coreNodeScheme}
			/>
		</Flex>
	);
}
