import { IField, IFreetext, IModal, IMonthInYear, INumber, ITabs } from '../interfaces';

const normalizeNumber = (field: IField): INumber | null => {
	if (field.type != 'number') {
		return null;
	}

	return field;
};
const normalizeTabs = (field: IField): ITabs | null => {
	if (field.type != 'tab') {
		return null;
	}

	return field;
};

const normalizeFreeTextField = (field: IField): IFreetext | null => {
	if (field.type != 'freeText') {
		return null;
	}

	return field;
};

const normalizeMonthInYearField = (field: IField): IMonthInYear | null => {
	if (field.type != 'monthInYear') {
		return null;
	}

	return field;
};

const normalizeModalField = (field: IField): IModal | null => {
	if (field.type != 'modal') {
		return null;
	}

	return field;
};

export const normalizeField = {
	tab: normalizeTabs,
	number: normalizeNumber,
	freeText: normalizeFreeTextField,
	monthInYear: normalizeMonthInYearField,
	modal: normalizeModalField,
};
