import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import useToast from '@hooks/ui/useToast';
import { AccessController } from 'src/common/components/AccessController';
import CopyLinkButton from 'src/common/components/CopyLinkButton';
import Divider from 'src/common/components/Divider';
import useMutation from 'src/common/hooks/fetching/useMutation';
import useUser from 'src/common/hooks/stores/useUser';
import { useModal } from 'src/common/hooks/ui/useModal';
import {
	UpdateCollectionMutation,
	UpdateCollectionMutationVariables,
	WorkspaceGetSingleQuery,
} from 'src/generated/graphql';
import { TitleAndDescription } from 'src/pages/Spaces/common/TitleAndDescription';
import { UpdateCollection } from 'src/queries/collections';
import { WorkspaceGetSingle } from 'src/queries/workspaces';
import { useReportEvent } from 'src/services/analytics';
import shadows from 'src/style/shadows';
import { Permissions } from 'src/types/environment';
import { CollectionMetadata } from 'src/types/spaces';
import { ActionsMenu } from './ActionsMenu';
import { AddWidgetButton } from './AddWidgetButton';

type HeaderProps = {
	workspace: WorkspaceGetSingleQuery['workspaces'][0];
	isTiny: boolean;
};

export default function Header({ workspace, isTiny }: HeaderProps) {
	const { reportEvent } = useReportEvent();
	const { name: title, description, id: workspaceId } = workspace;

	const [collectionMutate] = useMutation<UpdateCollectionMutation, UpdateCollectionMutationVariables>(UpdateCollection);
	const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useModal();

	const [{ id: activeUserId }] = useUser();
	const toast = useToast();

	const updateCollectionMetadata = (name: string, description?: string) => {
		reportEvent({
			event: 'workspace-metadata-update',
			metaData: { titleLength: title.length, descriptionLength: description?.length ?? 0, workspaceId: workspaceId },
		});

		collectionMutate({
			variables: { id: workspaceId, name: name, description: description, order: workspace.order },
			optimisticResponse: {
				update_workspaces_by_pk: {
					name: name,
					description: description,
					id: workspaceId,
					__typename: 'workspaces',
				},
			},
			update: (cache) => {
				const workspaceFromCache = cache.readQuery<WorkspaceGetSingleQuery>({
					query: WorkspaceGetSingle,
					variables: {
						id: workspaceId,
						my_id: activeUserId,
					},
				});

				if (workspaceFromCache)
					cache.writeQuery({
						query: WorkspaceGetSingle,
						data: { workspaces: [{ ...workspaceFromCache?.workspaces[0], name, description }] },
					});
			},
		}).catch(
			(error) =>
				error.message.includes('Uniqueness violation') &&
				toast({ variant: 'error', message: 'Title must be unique. Please choose a different title and try again.' })
		);
	};
	const WorkspaceTitleAndDescription: React.FC<{ isEditBlocked?: boolean }> = ({ isEditBlocked }) => (
		<TitleAndDescription
			isEditBlocked={isEditBlocked || false}
			isTiny={isTiny}
			isWorkspaceTitle
			title={title}
			description={description ?? ''}
			onSaved={({ title: savedName, description: savedDescription }: CollectionMetadata) => {
				if (savedName != title || savedDescription != description)
					updateCollectionMetadata(savedName, savedDescription);
			}}
			collectionType="workspace"
		/>
	);

	return (
		<Grid
			gridTemplateColumns="repeat(12, [col-start] 1fr)"
			columnGap="16px"
			paddingX={'32px'}
			margin="0"
			boxShadow={shadows.borderBottom}
		>
			<GridItem colSpan={12}>
				<Flex
					textAlign="start"
					borderRadius={'4px'}
					transition={'all 0.2s'}
					alignItems="center"
					gap="21px"
					flexDirection={'row'}
					minWidth={'292px'}
					width="100%"
					justifyContent="space-between"
					color={'transparent'}
					_hover={{ color: 'black' }}
				>
					<Flex maxWidth={'60%'} alignItems="start" gap="16px" flexDirection="row">
						<AccessController
							permission={Permissions.updateCollection}
							noAccessChild={
								<Flex
									textAlign="start"
									borderRadius={'4px'}
									alignItems="center"
									gap="21px"
									flexDirection={'row'}
									justifyContent="space-between"
									paddingRight="12px"
									paddingY="6px"
								>
									<WorkspaceTitleAndDescription isEditBlocked />
								</Flex>
							}
						>
							<Flex
								as={'button'}
								textAlign="start"
								borderRadius={'4px'}
								alignItems="center"
								gap="21px"
								flexDirection={'row'}
								minWidth={'292px'}
								width="100%"
								justifyContent="space-between"
								color="transparent"
								transition={'all 0.2s'}
								_hover={{ color: 'gray.800' }}
							>
								<WorkspaceTitleAndDescription />
							</Flex>
						</AccessController>
					</Flex>
					<Flex padding={'14px 0px'} alignItems={'center'}>
						<AccessController permission={Permissions.crudMySignals}>
							<Box marginRight={'12px'}>
								<AddWidgetButton isTiny={isTiny} workspaceId={workspaceId} />
							</Box>
						</AccessController>
						<CopyLinkButton
							onClick={() =>
								reportEvent({
									event: 'copy-link-clicked',
									metaData: { workspaceId: workspaceId },
								})
							}
							dataIntercomArea={'workspace'}
						/>
						<AccessController permission={Permissions.deleteCollection}>
							<Box height="24px" marginX={'12px'}>
								<Divider direction="vertical" />
							</Box>
							<ActionsMenu
								isDeleteModalOpen={isDeleteModalOpen}
								workspaceId={workspaceId}
								onDeleteModalOpen={onDeleteModalOpen}
								onDeleteModalClose={onDeleteModalClose}
								workspace={workspace}
							/>
						</AccessController>
					</Flex>
				</Flex>
			</GridItem>
		</Grid>
	);
}
