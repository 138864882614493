export const nodeSchema = {
	nodes: {
		Account: {
			title: 'Name',
			cards: [
				{
					name: 'Latest Contract Information',
					properties: [
						{
							field: 'Latest Contract End (before grace)',
							display_name: 'Latest Contract End (before grace)',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Latest Contract End',
							display_name: 'Latest Contract End',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Latest Contract End Month',
							display_name: 'Latest Contract End Month',
						},
						{
							field: 'Cohort',
						},
					],
				},
				{
					name: 'Account Information',
					show_all: true,
					properties: [
						{
							field: 'Current ARR',
							display_name: 'Current ARR',
							formatter: '$',
						},
						{
							field: 'Average TCV per Year',
							display_name: 'Average TCV per Year',
							formatter: '$',
						},
						{
							field: 'Average TCV per Year (Buckets)',
							display_name: 'Average TCV per Year (Buckets)',
						},
						{
							field: 'Type',
							display_name: 'Type',
						},
						{
							field: 'Industry',
							display_name: 'Industry',
						},
						{
							field: 'Segment',
							display_name: 'Segment',
						},
						{
							field: 'Billing Country',
							display_name: 'Billing Country',
						},
						{
							field: 'Region',
							display_name: 'Region',
						},
						{
							field: 'Account Source',
							display_name: 'Account Source',
						},
						{
							field: 'Number Of Employees',
							display_name: 'Number Of Employees',
						},
						{
							field: 'Number of Employees Distribution',
							display_name: 'Number of Employees Distribution',
						},
						{
							field: 'First Close Date for Account',
							display_name: 'First Close Date for Account',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Cohort',
							display_name: 'Cohort',
						},
						{
							field: 'Cohort Quarter (FY)',
							display_name: 'Cohort Quarter (FY)',
						},
						{
							field: 'Cohort Quarter (Calendar)',
							display_name: 'Cohort Quarter (Calendar)',
						},
						{
							field: 'Cohort Year (FY)',
							display_name: 'Cohort Year (FY)',
						},
						{
							field: 'Cohort Year (calendar)',
							display_name: 'Cohort Year (calendar)',
						},
					],
				},
				{
					name: 'Creation/Modification Information',
					properties: [
						{
							field: 'id',
							display_name: 'Salesforce ID',
						},
						{
							field: 'CreatedDate',
							display_name: 'Created Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							relationship: 'Owner>User',
							field: 'Username',
							display_name: 'Owner User',
						},
						{
							relationship: 'CreatedBy>User',
							field: 'Username',
							display_name: 'Created By User',
						},
						{
							relationship: 'LastModifiedBy>User',
							field: 'Username',
							display_name: 'Last Modified By User',
						},
						{
							field: 'Last Modified',
							display_name: 'Last Modified Date',
							parser: 'date',
							formatter: 'date',
						},
					],
				},
			],
			curated_relationships: [
				'Account<Opportunity',
				'SynthesizedFrom<SightfullFinancialUnit',
				'ConvertedAccount<Lead',
				'RelatedAccount<Task',
			],
			metrics: [
				{
					periods: 'months',
					period_preset: '8Q',
					description:
						'### **Rolling ARR**\nWhat is the ARR associated with the Opportunities that are related to this Account?\n',
					metric_template_url:
						'metric/Rolling ARR [Opportunities]?groupBy={"Opportunity.Name":null}&collectProps={"Opportunity.Account>Account.Name":null,"Opportunity.ContractStart":null,"Opportunity.ContractEnd":null}',
					node_id_filters: ['Opportunity.Account>Account.id'],
				},
			],
		},
		Opportunity: {
			title: 'Name',
			cards: [
				{
					name: 'Contract Information',
					properties: [
						{
							field: 'ContractCommitStart',
							display_name: 'Commit Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'StartContractDate',
							display_name: 'Contract Start',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'ContractStart',
							display_name: 'Contract Start',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'EndContractDate',
							display_name: 'Contract End',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'ContractEnd',
							display_name: 'Contract End',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'ContractLength',
							display_name: 'Contract Length (Months)',
						},
						{
							field: 'Amount',
							display_name: 'Amount',
							formatter: '$',
						},
						{
							field: 'Annualized Amount',
							display_name: 'Annualized Amount',
							formatter: '$',
						},
					],
				},
				{
					name: 'Opportunity Information',
					show_all: true,
					properties: [
						{
							relationship: 'Account>Account',
							field: 'Name',
							display_name: 'Related Account',
						},
						{
							relationship: 'Lead>Lead',
							field: 'Name',
							display_name: 'Related Lead',
						},
						{
							relationship: 'ConvertedFromLead>Lead',
							field: 'Name',
							display_name: 'Converted Lead',
						},
						{
							field: 'StageName',
							display_name: 'Stage Name',
						},
						{
							field: 'Type',
							display_name: 'Opportunity Type',
						},
						{
							field: 'CloseDate',
							display_name: 'Close Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Sales Cycle Entry Date',
							display_name: 'Sales Cycle Entry Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'IsWon',
							display_name: 'Won Opportunity?',
						},
						{
							field: 'Preceding CloseDate',
							display_name: 'Preceding Close Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'IsClosed',
							display_name: 'Closed Opportunity?',
						},
						{
							field: 'Type Category',
							display_name: 'Opportunity Type Category',
						},
						{
							field: 'Amount in PO Currency',
							display_name: 'Amount in PO Currency',
							formatter: '$',
						},
						{
							field: 'Sales Cycle Duration (days)',
							display_name: 'Sales Cycle Duration (days)',
						},
						{
							field: 'TCV Distribution',
							display_name: 'TCV Distribution',
						},
						{
							field: 'Forecast Category',
							display_name: 'Forecast Category',
						},
						{
							field: 'Previous Stage',
							display_name: 'Previous Stage',
						},
						{
							field: 'Last Stage before Closed',
							display_name: 'Last Stage before Closed',
						},
						{
							field: 'Lead Source',
							display_name: 'Lead Source',
						},
						{
							field: 'Lead Source Category',
							display_name: 'Lead Source Category',
						},
						{
							field: 'HasRecurring',
							display_name: 'Has a recurring opportunity?',
						},
					],
				},
				{
					name: 'Creation/Modification Information',
					properties: [
						{
							field: 'id',
							display_name: 'Salesforce ID',
						},
						{
							field: 'CreatedDate',
							display_name: 'Created Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							relationship: 'Owner>Owner',
							field: 'Name',
							display_name: 'Owner User',
						},
						{
							relationship: 'Owner>User',
							field: 'Name',
							display_name: 'Owner User',
						},
						{
							relationship: 'CreatedBy>User',
							field: 'Name',
							display_name: 'Created By User',
						},
						{
							relationship: 'LastModifiedBy>User',
							field: 'Name',
							display_name: 'Last Modified By User',
						},
						{
							field: 'Last Modified',
							display_name: 'Last Modified Date',
							parser: 'date',
							formatter: 'date',
						},
					],
				},
				{
					name: 'Grace Information',
					properties: [
						{
							field: 'ContractEndOriginal',
							display_name: 'Contract End (Before Grace)',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'GapGraceResult',
							display_name: 'Gracing Result',
						},
						{
							field: 'ContractEndGraced',
							display_name: 'Contract End Graced',
							parser: 'date',
							formatter: 'date',
						},
					],
				},
				{
					name: 'Campaign Influence',
					properties: [
						{
							relationship: 'FirstTouch>CampaignMember',
							field: 'Name',
							display_name: 'First Campaign Touch',
						},
						{
							relationship: 'LastTouch>CampaignMember',
							field: 'Name',
							display_name: 'Last Campaign Touch',
						},
						{
							field: 'Touch Count',
							display_name: 'Campaign Touchpoints',
						},
						{
							field: 'Lead Count',
							display_name: 'Leads',
						},
					],
				},
			],
			curated_relationships: [
				'Opportunity<OpportunityLineItem',
				'Opportunity<OpportunityHistory',
				'Opportunity<SightfullOpportunityProduct',
				'Opportunity<EntityHistory',
				'RelatedOpportunity<Task',
				'ConvertedOpportunity<Lead',
				'CampaignTouch<CampaignMember',
			],
			metrics: [
				{
					periods: 'quarters',
					period_preset: '8Q',
					description: '### **Active ARR**\n How is it split between different products?\n',
					metric_template_url: 'metric/Active ARR [by Opportunity Products]?groupBy={"OpportunityLineItem.Name":null}',
					node_id_filters: ['OpportunityLineItem.Opportunity>Opportunity.id'],
				},
			],
		},
		Task: {
			title: 'Name',
			cards: [
				{
					name: 'Task Information',
					show_all: true,
					properties: [
						{
							relationship: 'Owner>User',
							field: 'Name',
							display_name: 'Owner User',
						},
						{
							relationship: 'CreatedBy>User',
							field: 'Name',
							display_name: 'Created By User',
						},
						{
							relationship: 'RelatedAccount>Account',
							field: 'Name',
							display_name: 'Account',
						},
						{
							relationship: 'RelatedOpportunity>Opportunity',
							field: 'Name',
							display_name: 'Opportunity',
						},
					],
				},
			],
		},
		OpportunityStage: {
			title: 'Name',
			cards: [
				{
					name: 'Stage Information',
					show_all: true,
					properties: [
						{
							field: 'Forecast Category Name',
							display_name: 'Forecast Category Name',
						},
					],
				},
			],
		},
		TransactionLines: {
			title: 'Transaction Line',
			cards: [
				{
					name: 'Transaction Line Information',
					properties: [
						{
							field: 'Normalized Amount',
							display_name: 'Amount',
							formatter: '$',
						},
						{
							relationship: 'TransactionId>Transactions',
							field: 'Name',
							display_name: 'Parent Transaction',
						},
						{
							field: 'TransactionDate',
							display_name: 'Transaction Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							relationship: 'AccountId>Accounts',
							field: 'Full Name',
							display_name: 'Account',
						},
						{
							field: 'Account Type',
							display_name: 'Account Type',
						},
						{
							relationship: 'DepartmentId>Departments',
							field: 'Full Name',
							display_name: 'Department',
						},
					],
				},
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							field: 'id',
							display_name: 'ID',
						},
					],
				},
			],
			curated_relationships: [
				'Opportunity<OpportunityHistory',
				'Opportunity<OpportunityLineItem',
				'Opportunity<EntityHistory',
			],
		},
		Transactions: {
			title: 'Transaction',
			cards: [
				{
					name: 'Transaction Information',
					properties: [
						{
							field: 'Transaction Type',
							display_name: 'Type',
						},
						{
							field: 'Status',
							display_name: 'Status',
						},
						{
							field: 'TransactionDate',
							display_name: 'Transaction Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							relationship: 'EntityId>Entity',
							field: 'Full Name',
							display_name: 'Entity',
						},
						{
							field: 'Transaction ID',
							display_name: 'Transaction ID',
						},
					],
				},
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							field: 'id',
							display_name: 'ID',
						},
					],
				},
			],
			curated_relationships: ['TransactionId<TransactionLines'],
		},
		Accounts: {
			title: 'Account',
			cards: [
				{
					name: 'Account Information',
					properties: [
						{
							field: 'Type Name',
							display_name: 'Account Type',
						},
						{
							field: 'Account Number',
							display_name: 'Account Number',
						},
						{
							field: 'Full Name',
							display_name: 'Full Name',
						},
					],
				},
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							field: 'id',
							display_name: 'ID',
						},
					],
				},
			],
			curated_relationships: ['AccountId<TransactionLines', 'TransactionId<TransactionLines', 'Parent<Account'],
		},
		OpportunityHistory: {
			title: 'id',
			cards: [
				{
					name: 'Opportunity History Information',
					show_all: true,
					properties: [
						{
							relationship: 'Opportunity>Opportunity',
							field: 'Name',
							display_name: 'Opportunity Name',
						},
						{
							field: 'CreatedDate',
							display_name: 'Change Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Amount in PO Currency',
						},
						{
							field: 'Amount',
							formatter: '$',
						},
						{
							field: 'Annualized Amount',
							formatter: '$',
						},
						{
							field: 'StageName',
							display_name: 'Stage Name',
						},
						{
							field: 'ForecastCategory',
							display_name: 'Forecast Category',
						},
						{
							field: 'CloseDate',
							display_name: 'Close Date',
							parser: 'date',
							formatter: 'date',
						},
					],
				},
				{
					name: 'Creation/Modification Information',
					properties: [
						{
							field: 'id',
							display_name: 'Salesforce ID',
						},
						{
							field: 'CreatedDate',
							display_name: 'Created Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							relationship: 'Createdby>User',
							field: 'Name',
							display_name: 'Created By',
						},
						{
							relationship: 'Createdby>User',
							field: 'Username',
							display_name: 'Changed By',
						},
						{
							relationship: 'Lastmodifiedby>User',
							field: 'Name',
							display_name: 'Last Modified By',
						},
						{
							field: 'Last Modified',
							display_name: 'Last Modified Date',
							parser: 'date',
							formatter: 'date',
						},
					],
				},
			],
		},
		OpportunityLineItem: {
			title: 'Name',
			cards: [
				{
					name: 'Contract Information',
					properties: [
						{
							field: 'ContractCommitStart',
							display_name: 'Commit Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'StartContractDate',
							display_name: 'Contract Start',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'ContractStart',
							display_name: 'Contract Start',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'EndContractDate',
							display_name: 'Contract End',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'ContractEnd',
							display_name: 'Contract End',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'ContractLength',
							display_name: 'Contract Length (Months)',
						},
						{
							field: 'Total Price',
							display_name: 'Total Price',
							formatter: '$',
						},
						{
							field: 'Amount',
							display_name: 'Amount',
							formatter: '$',
						},
						{
							field: 'Amount in PO Currency',
							display_name: 'Amount in PO Currency',
							formatter: '$',
						},
					],
				},
				{
					name: 'Opportunity Line Item Information',
					show_all: true,
					properties: [
						{
							relationship: 'Opportunity>Opportunity',
							field: 'Name',
							display_name: 'Related Opportunity',
						},
						{
							relationship: 'Product2>Product2',
							field: 'Name',
							display_name: 'Product Name',
						},
						{
							relationship: 'Product2>Product2',
							field: 'Family',
							display_name: 'Product Family',
						},
						{
							field: 'Product Code',
						},
						{
							field: 'Quantity',
						},
						{
							field: 'List Price',
							formatter: '$',
						},
						{
							field: 'Discount',
							display_name: 'Discount',
						},
						{
							field: 'IsWon',
							display_name: 'Won Opportunity?',
						},
						{
							field: 'HasRecurring',
							display_name: 'Has a recurring opportunity?',
						},
						{
							field: 'Synthesized',
							display_name: 'Synthesized Opportunity Line Item?',
						},
						{
							field: 'ContractEnd (before grace)',
							display_name: 'ContractEnd (before grace)',
							parser: 'date',
							formatter: 'date',
						},
					],
				},
				{
					name: 'Creation/Modification Information',
					properties: [
						{
							field: 'id',
							display_name: 'Salesforce ID',
						},
						{
							field: 'CreatedDate',
							display_name: 'Created Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							relationship: 'Createdby>User',
							field: 'Name',
							display_name: 'Created By',
						},
						{
							relationship: 'Lastmodifiedby>User',
							field: 'Name',
							display_name: 'Last Modified By',
						},
						{
							field: 'Last Modified',
							display_name: 'Last Modified Date',
							parser: 'date',
							formatter: 'date',
						},
					],
				},
			],
		},
		EntityHistory: {
			title: 'id',
			cards: [
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							field: 'id',
							display_name: 'Salesforce ID',
						},
						{
							relationship: 'Opportunity>Opportunity',
							field: 'Name',
							display_name: 'Opportunity Name',
						},
					],
				},
			],
		},
		User: {
			title: 'Username',
			cards: [
				{
					name: 'User Information',
					show_all: true,
					properties: [
						{
							relationship: 'Account>Account',
							field: 'Name',
							display_name: 'Account',
						},
						{
							relationship: 'Manager>User',
							field: 'Name',
							display_name: 'Manager',
						},
					],
				},
				{
					name: 'Creation/Modification Information',
					properties: [
						{
							field: 'id',
							display_name: 'Salesforce ID',
						},
						{
							field: 'CreatedDate',
							display_name: 'Created Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							relationship: 'CreatedBy>User',
							field: 'Username',
							display_name: 'Created By',
						},
						{
							relationship: 'CreatedBy>User',
							field: 'Name',
							display_name: 'Created By',
						},
						{
							relationship: 'LastModifiedBy>User',
							field: 'Username',
							display_name: 'Last Modified By',
						},
						{
							relationship: 'LastModifiedBy>User',
							field: 'Name',
							display_name: 'Last Modified By',
						},
						{
							field: 'Last Modified',
							display_name: 'Last Modified Date',
							parser: 'date',
							formatter: 'date',
						},
					],
				},
			],
			metrics: [
				{
					periods: 'months',
					period_preset: '8Q',
					description:
						'### **Bookings (ARR)**\nWhat is the Bookings (ARR) associated with this User and how is it split between different Opportunity types?\n',
					metric_template_url: 'metric/Bookings (ARR)?groupBy={"Opportunity.Type+Category":null}',
					node_id_filters: ['Opportunity.Owner>User.id'],
				},
			],
			curated_relationships: ['Owner<Opportunity', 'NameC<ForecastCall'],
		},
		Case: {
			title: 'Subject',
			cards: [
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							field: 'id',
							display_name: 'Salesforce ID',
						},
						{
							field: 'Subject',
							display_name: 'Case Subject',
						},
						{
							relationship: 'Account>Account',
							field: 'Name',
							display_name: 'Account',
						},
						{
							field: 'Status',
							display_name: 'Case Status',
						},
						{
							field: 'Type',
							display_name: 'Case Type',
						},
						{
							field: 'CreatedDate',
							display_name: 'Created Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Origin',
							display_name: 'Origin',
						},
						{
							relationship: 'Owner>User',
							field: 'Username',
							display_name: 'Account Owner',
						},
						{
							relationship: 'Createdby>User',
							field: 'Username',
							display_name: 'Created By',
						},
						{
							relationship: 'Lastmodifiedby>User',
							field: 'Username',
							display_name: 'Last Modified By',
						},
					],
				},
			],
		},
		Contact: {
			title: 'Name',
			cards: [
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							field: 'ID',
							display_name: 'Salesforce ID',
						},
						{
							field: 'CreatedDate',
							display_name: 'Created Date',
						},
						{
							field: 'Email',
							display_name: 'Email',
						},
						{
							field: 'Phone',
							display_name: 'Phone',
						},
						{
							field: 'Department',
							display_name: 'Department',
						},
						{
							field: 'Role',
							display_name: 'Role',
						},
						{
							field: 'Title',
							display_name: 'Title',
						},
					],
				},
			],
			curated_relationships: ['Contact<Opportunity', 'Contact<CampaignMember', 'Contact<EntityHistory'],
		},
		Metric: {
			title: 'name',
			cards: [
				{
					name: 'name',
					show_all: true,
					properties: [
						{
							field: 'name',
						},
						{
							field: 'id',
						},
						{
							field: 'fquarter',
						},
						{
							field: 'op',
						},
						{
							field: 'category',
						},
						{
							field: 'start',
						},
						{
							field: 'duration',
						},
						{
							field: 'unit',
						},
						{
							field: 'month',
						},
						{
							field: 'end',
						},
					],
				},
			],
		},
		SightfullFinancialUnit: {
			title: 'Sightfull Financial Unit',
			cards: [
				{
					name: 'Sightfull Financial Unit Information',
					properties: [
						{
							relationship: 'SynthesizedFrom>Accounts',
							field: 'Full Name',
							display_name: 'Financial Account',
						},
						{
							relationship: 'SynthesizedFrom>Departments',
							field: 'Full Name',
							display_name: 'Department',
						},
						{
							relationship: 'SynthesizedFrom>Entity',
							field: 'Full Name',
							display_name: 'Entity',
						},
						{
							field: 'Account Type',
							display_name: 'Account Type',
						},
						{
							field: 'Parent Department',
							display_name: 'Parent Department',
						},
					],
				},
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							field: 'Account Type Category',
							display_name: 'Account Type Category',
						},
						{
							field: 'Department Name',
							display_name: 'Department Name',
						},
						{
							field: 'Entity Name',
							display_name: 'Entity Name',
						},
						{
							field: 'id',
							display_name: 'ID',
						},
					],
				},
			],
			curated_relationships: ['SFUTransactionLine<TransactionLines'],
		},
		Departments: {
			title: 'Department',
			cards: [
				{
					name: 'Department Information',
					properties: [
						{
							field: 'Full Name',
							display_name: 'Department Name',
						},
						{
							field: 'Department Category',
							display_name: 'Department Category',
						},
						{
							field: 'Parent Department',
							display_name: 'Parent Department',
						},
					],
				},
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							field: 'id',
							display_name: 'ID',
						},
					],
				},
			],
			curated_relationships: ['SynthesizedFrom<SightfullFinancialUnit'],
		},
		Entity: {
			title: 'Entity',
			cards: [
				{
					name: 'Entity Information',
					properties: [
						{
							field: 'Full Name',
							display_name: 'Entity Name',
						},
						{
							field: 'Entity Type',
							display_name: 'Type',
						},
						{
							field: 'Country',
							display_name: 'Entity Country',
						},
					],
				},
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							field: 'id',
							display_name: 'ID',
						},
					],
				},
			],
			curated_relationships: ['SynthesizedFrom<SightfullFinancialUnit', 'EntityId<Transactions'],
		},
		Lead: {
			title: 'Name',
			cards: [
				{
					name: 'Lead Information',
					properties: [
						{
							relationship: 'ConvertedOpportunity>Opportunity',
							field: 'Name',
							display_name: 'Converted to Opportunity',
						},
						{
							relationship: 'ConvertedAccount>Account',
							field: 'Name',
							display_name: 'Converted to Account',
						},
						{
							field: 'Name',
							display_name: 'Lead Name',
						},
						{
							field: 'Lead Source',
							display_name: 'Lead Source',
						},
						{
							field: 'Region',
							display_name: 'Region',
						},
						{
							field: 'Company',
							display_name: 'Company',
						},
						{
							field: 'Annual Company Revenue',
							display_name: 'Annual Company Revenue',
							formatter: '$',
						},
						{
							field: 'Industry',
							display_name: 'Industry',
						},
						{
							field: 'Lead Stage',
							display_name: 'Lead Stage',
						},
					],
				},
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							field: 'id',
							display_name: 'Salesforce ID',
						},
					],
				},
				{
					name: 'Creation/Modification',
					properties: [
						{
							field: 'CreatedDate',
							display_name: 'Created Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							relationship: 'Owner>User',
							field: 'Name',
							display_name: 'Owner User',
						},
						{
							relationship: 'CreatedBy>User',
							field: 'Name',
							display_name: 'Created By User',
						},
						{
							relationship: 'LastModifiedBy>User',
							field: 'Name',
							display_name: 'Last Modified By User',
						},
						{
							field: 'Last Modified',
							display_name: 'Last Modified',
							parser: 'date',
							formatter: 'date',
						},
					],
				},
			],
			curated_relationships: ['Lead<CampaignMember', 'Lead<Opportunity'],
		},
		Campaign: {
			title: 'Name',
			cards: [
				{
					name: 'Campaign Information',
					properties: [
						{
							relationship: 'Parent>Campaign',
							field: 'Name',
							display_name: 'Parent Campaign',
						},
						{
							field: 'Name',
							display_name: 'Campaign Name',
						},
						{
							field: 'Start Date',
							display_name: 'Start Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'End Date',
							display_name: 'End Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Campaign Type',
							display_name: 'Campaign Type',
						},
						{
							field: 'Campaign Type Category',
							display_name: 'Campaign Type Category',
						},
						{
							field: 'Status',
							display_name: 'Status',
						},
						{
							field: 'Region',
							display_name: 'Region',
						},
					],
				},
				{
					name: 'Campaign Attribution',
					properties: [
						{
							field: 'Campaign Touch Count',
							display_name: 'Touch Count',
						},
						{
							field: 'Number of Leads',
							display_name: 'Leads',
						},
						{
							field: 'Number of Contacts',
							display_name: 'Contacts',
						},
						{
							field: 'Number of Responses',
							display_name: 'Responses',
						},
						{
							field: 'Number of Converted Leads',
							display_name: 'Converted Leads',
						},
						{
							field: 'Number of Opportunities',
							display_name: 'Opportunities',
						},
						{
							field: 'Number of Won Opportunities',
							display_name: 'Won Opportunities',
						},
						{
							field: 'Amount All Opportunities',
							display_name: 'Amount All Opportunities',
							formatter: '$',
						},
						{
							field: 'Amount Won Opportunities',
							display_name: 'Amount Won Opportunities',
							formatter: '$',
						},
						{
							field: 'ARR All Opportunities',
							display_name: 'ARR All Opportunities',
							formatter: '$',
						},
						{
							field: 'ARR Won Opportunities',
							display_name: 'ARR Won Opportunities',
							formatter: '$',
						},
						{
							field: 'Budgeted Cost',
							display_name: 'Budgeted Cost',
							formatter: '$',
						},
						{
							field: 'Actual Cost',
							display_name: 'Actual Cost',
							formatter: '$',
						},
						{
							field: 'Cost per Lead',
							display_name: 'Cost per Lead',
							formatter: '$',
						},
						{
							field: 'Cost per Converted Lead',
							display_name: 'Cost per Converted Lead',
							formatter: '$',
						},
					],
				},
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							field: 'id',
							display_name: 'ID',
						},
						{
							field: 'source',
							display_name: 'Source',
						},
					],
				},
				{
					name: 'Creation/Modification',
					properties: [
						{
							field: 'id',
							display_name: 'Salesforce ID',
						},
						{
							field: 'CreatedDate',
							display_name: 'Created Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'CreatedDate Month',
							display_name: 'Created Date Month',
						},
						{
							field: 'CreatedDate Quarter (FY)',
							display_name: 'Created Date Quarter',
						},
						{
							relationship: 'Owner>User',
							field: 'Name',
							display_name: 'Owner User',
						},
						{
							relationship: 'CreatedBy>User',
							field: 'Name',
							display_name: 'Created By User',
						},
						{
							relationship: 'LastModifiedBy>User',
							field: 'Name',
							display_name: 'Last Modified By User',
						},
						{
							field: 'Last Modified',
							display_name: 'Last Modified',
							parser: 'date',
							formatter: 'date',
						},
					],
				},
			],
			curated_relationships: ['Campaign<CampaignMember', 'Campaign<Opportunity', 'Parent<Campaign'],
		},
		CampaignMember: {
			title: 'Name',
			cards: [
				{
					name: 'Campaign Member Information',
					properties: [
						{
							relationship: 'Campaign>Campaign',
							field: 'Name',
							display_name: 'Campaign',
						},
						{
							field: 'Campaign Type',
							display_name: 'Campaign Type',
						},
						{
							relationship: 'CampaignTouch>Opportunity',
							field: 'Name',
							display_name: 'Opportunity',
						},
						{
							relationship: 'Lead>Lead',
							field: 'Name',
							display_name: 'Lead',
						},
						{
							relationship: 'Contact>Contact',
							field: 'Name',
							display_name: 'Contact',
						},
						{
							relationship: 'Owner>User',
							field: 'Name',
							display_name: 'Owner',
						},
						{
							field: 'Title',
							display_name: 'Title',
						},
						{
							field: 'Member Type',
							display_name: 'Member Type',
						},
						{
							field: 'Company',
							display_name: 'Company',
						},
						{
							field: 'Industry',
							display_name: 'Industry',
						},
						{
							field: 'Country',
							display_name: 'Country',
						},
						{
							field: 'Status',
							display_name: 'Status',
						},
					],
				},
				{
					name: 'Campaign Member Attribution',
					properties: [
						{
							field: 'Linked to Opportunity',
							display_name: 'Converted Opportunity',
						},
						{
							field: 'Opportunity Won',
							display_name: 'Opportunity Won',
						},
						{
							field: 'Opportunity Amount',
							display_name: 'Opportunity TCV',
						},
						{
							field: 'Opportunity ARR',
							display_name: 'Opportunity Annualized Amount',
						},
						{
							field: 'Touch Type',
							display_name: 'Touch Type',
						},
					],
				},
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							relationship: 'LeadOrContactOwner>User',
							field: 'Name',
							display_name: 'Contact Owner',
						},
						{
							relationship: 'CreatedBy>User',
							field: 'Name',
							display_name: 'Created By',
						},
						{
							field: 'First Touch Attribution',
							display_name: 'First Touch Attribution',
							formatter: '$',
						},
						{
							field: 'Conversion Touch Attribution',
							display_name: 'Conversion Touch Attribution',
							formatter: '$',
						},
						{
							field: 'Last Touch Attribution',
							display_name: 'Last Touch Attribution',
							formatter: '$',
						},
						{
							field: 'Last Indirect Touch Attribution',
							display_name: 'Last Indirect Touch Attribution',
							formatter: '$',
						},
						{
							field: 'Linear TCV',
							display_name: 'Linear TCV',
							formatter: '$',
						},
						{
							field: 'Linear ARR',
							display_name: 'Linear ARR',
							formatter: '$',
						},
						{
							field: 'U-Shaped TCV',
							display_name: 'U-Shaped TCV',
							formatter: '$',
						},
						{
							field: 'U-Shaped ARR',
							display_name: 'U-Shaped ARR',
							formatter: '$',
						},
						{
							field: 'W-Shaped TCV',
							display_name: 'W-Shaped TCV',
							formatter: '$',
						},
						{
							field: 'W-Shaped ARR',
							display_name: 'W-Shaped ARR',
							formatter: '$',
						},
						{
							field: 'source',
							display_name: 'Source',
						},
					],
				},
				{
					name: 'Creation/Modification',
					properties: [
						{
							field: 'id',
							display_name: 'Salesforce ID',
						},
						{
							field: 'CreatedDate',
							display_name: 'Created Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Response Date',
							display_name: 'Response Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Touch Date',
							display_name: 'Touch Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							relationship: 'Owner>User',
							field: 'Name',
							display_name: 'Owner User',
						},
						{
							relationship: 'CreatedBy>User',
							field: 'Name',
							display_name: 'Created By User',
						},
						{
							relationship: 'LastModifiedBy>User',
							field: 'Name',
							display_name: 'Last Modified By User',
						},
						{
							field: 'Last Modified',
							display_name: 'Last Modified',
							parser: 'date',
							formatter: 'date',
						},
					],
				},
			],
		},
		ForecastCall: {
			title: 'Name',
			cards: [
				{
					name: 'Forecast Call Details',
					properties: [
						{
							relationship: 'NameC>User',
							field: 'Name',
							display_name: 'Forecast Owner',
						},
						{
							field: 'Period',
							display_name: 'Period',
						},
						{
							field: 'Category',
							display_name: 'Category',
						},
						{
							field: 'Value',
							display_name: 'Value',
						},
						{
							field: 'Participation',
							display_name: 'Participation',
						},
						{
							field: 'Manager',
							display_name: 'Manager',
						},
					],
				},
				{
					name: 'All Dimensions',
					show_all: true,
					properties: [
						{
							field: 'Name',
							display_name: 'Call Name',
						},
					],
				},
				{
					name: 'Creation/Modification',
					properties: [
						{
							field: 'id',
							display_name: 'Salesforce ID',
						},
						{
							field: 'CreatedDate',
							display_name: 'Created Date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Response Date',
						},
						{
							relationship: 'Owner>User',
							field: 'Name',
							display_name: 'Owner User',
						},
						{
							relationship: 'CreatedBy>User',
							field: 'Name',
							display_name: 'Created By User',
						},
						{
							relationship: 'LastModifiedBy>User',
							field: 'Name',
							display_name: 'Last Modified By User',
						},
						{
							field: 'Last Modified',
							display_name: 'Last Modified',
							parser: 'date',
							formatter: 'date',
						},
					],
				},
			],
		},
	},
	default_node: {
		title: 'Name',
		cards: [
			{
				name: 'All Dimensions',
				show_all: true,
				properties: [
					{
						field: 'Name',
						display_name: 'Name',
					},
					{
						field: 'id',
						display_name: 'id',
					},
				],
			},
		],
	},
	relationships: [
		{
			for: '<Opportunity<OpportunityHistory',
			title: 'Opportunity Change History',
		},
		{
			for: '<Opportunity<EntityHistory',
			title: 'Entity History',
		},
		{
			for: '<Opportunity<OpportunityLineItem',
			title: 'Related Opportunity Products',
		},
		{
			for: '<Opportunity<SightfullOpportunityProduct',
			title: 'Related Synthetic Opportunity Products',
		},
		{
			for: '<RelatedOpportunity<Task',
			title: 'Related Activities',
		},
		{
			for: '<RelatedAccount<Task',
			title: 'Related Activities',
		},
		{
			for: '<Account<Opportunity',
			title: 'Related Opportunities',
		},
		{
			for: '<CreatedBy<Account',
			title: 'Created Accounts',
		},
		{
			for: '<CreatedBy<Opportunity',
			title: 'Created Opportunities',
		},
		{
			for: '<CreatedBy<OpportunityHistory',
			title: 'Created Opportunity Changes',
		},
		{
			for: '<Owner<Opportunity',
			title: 'Owned Opportunities',
		},
		{
			for: '<Account<User',
			title: 'Related Users',
		},
		{
			for: '<TransactionId<TransactionLines',
			title: 'Related Transaction Lines',
		},
		{
			for: '<AccountId<TransactionLines',
			title: 'Related Transaction Lines',
		},
		{
			for: 'Metric<Affects<',
			title: 'Metric is influenced by',
		},
		{
			for: 'Metric<Affects<Metric',
			title: 'Sub-metrics',
		},
		{
			for: '<SFUTransactionLine<TransactionLines',
			title: 'Related Transaction Lines',
		},
		{
			for: '<SynthesizedFrom<SightfullFinancialUnit',
			title: 'Related Sightfull Financial Units',
		},
		{
			for: '<DepartmentId<TransactionLines',
			title: 'Related Transaction Lines',
		},
		{
			for: '<DepartmentId<Employees',
			title: 'Related Employees',
		},
		{
			for: '<EntityId<Transactions',
			title: 'Related Transactions',
		},
		{
			for: '<Campaign<Opportunity',
			title: 'Related Opportunities',
		},
		{
			for: '<Parent<Account',
			title: 'Associated Accounts',
		},
		{
			for: '<Parent<Campaign',
			title: 'Associated Campaigns',
		},
		{
			for: '<ConvertedAccount<Lead',
			title: 'Related Leads',
		},
		{
			for: '<Lead<Campaign',
			title: 'Related Leads',
		},
		{
			for: '<Contact<Opportunity',
			title: 'Related Opportunities',
		},
		{
			for: '<Contact<EntityHistory',
			title: 'Contact History',
		},
		{
			for: '<ConvertedOpportunity<Lead',
			title: 'Related Leads',
		},
		{
			for: '<Campaign<CampaignMember',
			title: 'Campaign Members',
		},
		{
			for: '<Lead<CampaignMember',
			title: 'Campaign Touchpoints',
		},
		{
			for: '<Lead<Opportunity',
			title: 'Opportunity',
		},
		{
			for: '<Contact<CampaignMember',
			title: 'Campaign Touchpoints',
		},
		{
			for: '<LeadOrContact<CampaignMember',
			title: 'Campaign Touchpoints',
		},
		{
			for: '<CampaignTouch<CampaignMember',
			title: 'Campaign Touchpoints',
		},
		{
			for: '<NameC<ForecastCall',
			title: 'Forecast Calls',
		},
	],
	node_display: [
		{
			for: '.Account',
			search: {
				searchable: true,
				pretty: 'Accounts',
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 3,
					title: 'Name',
					subtitles: [
						{
							field: 'Cohort',
							prefix: 'Cohort',
						},
						{
							field: 'Region',
							prefix: 'Region',
						},
						{
							field: 'Segment',
							prefix: 'Segment',
						},
					],
				},
			},
		},
		{
			for: '.Opportunity',
			search: {
				searchable: true,
				pretty: 'Opportunities',
			},
			filter: {
				sort: {
					parser: 'date',
					field: 'CloseDate',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 3,
					title: 'Name',
					subtitles: [
						{
							field: 'CloseDate',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
							prefix: 'Close Date',
						},
						{
							field: 'Amount',
							icon: 'money',
							formatter: '$',
							prefix: 'TCV',
						},
						{
							field: 'Annualized Amount',
							icon: 'money',
							formatter: '$',
							prefix: 'ARR',
						},
					],
					status: {
						field: 'StageName',
						displayName: 'Stage',
						default: 'neutral',
						good: ['Closed Won', 'Closed: Won', 'Won!', 'Scaled - Phase 1', 'Scaled - Phase 2'],
						bad: [
							'Closed Lost',
							'Closed: Lost',
							'LOST - Bad Fit',
							'LOST - Try Other Avenues',
							'LOST - Stalled - Nurture',
							'Churned',
						],
					},
					link: null,
				},
			},
		},
		{
			for: '.OpportunityHistory',
			filter: {
				sort: {
					field: 'CreatedDate',
					parser: 'date',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 3,
					title: 'Name',
					subtitles: [
						{
							field: 'CreatedDate',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
							prefix: 'Change Date',
						},
						{
							field: 'CloseDate',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
							prefix: 'Close Date',
						},
						{
							field: 'Amount',
							icon: 'money',
							formatter: '$',
							prefix: 'TCV',
						},
						{
							field: 'Annualized Amount',
							icon: 'money',
							formatter: '$',
							prefix: 'ARR',
						},
					],
					status: {
						field: 'StageName',
						displayName: 'Stage',
						default: 'neutral',
						good: ['Closed Won', 'Closed: Won'],
						bad: ['Closed Lost', 'Closed: Lost'],
					},
					link: null,
				},
			},
		},
		{
			for: '.Product2',
			search: {
				searchable: true,
				pretty: 'Products',
			},
			display: {
				config: {
					first: 3,
					title: 'Name',
					subtitles: [
						{
							field: 'Product Code',
							prefix: 'Product Code',
						},
						{
							field: 'Family',
							prefix: 'Family',
						},
						{
							field: 'Recurring',
							prefix: 'Recurring',
						},
					],
				},
			},
		},
		{
			for: '.EntityHistory',
			filter: {
				sort: {
					field: 'CreatedDate',
					parser: 'date',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 3,
					title: 'Name',
					subtitles: [
						{
							field: 'CreatedDate',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
							prefix: 'Change Date',
						},
					],
					status: {
						field: 'Status',
						displayName: 'Status',
						default: 'neutral',
						good: ['SQL', 'Converted', 'Opportunity', 'Customer'],
						bad: ['Disqualified', 'Nurture', 'Recycle'],
					},
				},
			},
		},
		{
			for: '.OpportunityLineItem',
			search: {
				searchable: true,
				pretty: 'Opportunity Line Items',
			},
			filter: {
				sort: {
					parser: 'float',
					field: 'Total Price',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 5,
					title: 'Name',
					subtitles: [
						{
							field: 'ContractStart',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
							prefix: 'Start Date',
						},
						{
							field: 'Total Price',
							icon: 'money',
							formatter: '$',
							prefix: 'TCV',
						},
						{
							field: 'Annualized Amount',
							icon: 'money',
							formatter: '$',
							prefix: 'ARR',
						},
					],
				},
			},
		},
		{
			for: '.SightfullOpportunityProduct',
			filter: {
				sort: {
					parser: 'float',
					field: 'Total Price',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 5,
					title: 'Name',
					subtitles: [
						{
							field: 'CreatedDate',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Total Price',
							icon: 'money',
							formatter: '$',
							prefix: 'TCV',
						},
						{
							field: 'Annualized Amount',
							icon: 'money',
							formatter: '$',
							prefix: 'ARR',
						},
					],
				},
			},
		},
		{
			for: '.User',
			search: {
				searchable: true,
				pretty: 'Users',
			},
			display: {
				type: 'CupertinoTable',
				config: {
					title: 'Name',
					subtitles: [
						{
							field: 'Username',
						},
						{
							field: 'Title',
							prefix: 'Title',
						},
						{
							field: 'Region',
							prefix: 'Region',
						},
						{
							field: 'Created Date',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
							prefix: 'Created Date',
						},
					],
				},
			},
		},
		{
			for: '.Case',
			display: {
				type: 'CupertinoTable',
				config: {
					title: 'Subject',
				},
			},
		},
		{
			for: '.Contact',
			display: {
				type: 'CupertinoTable',
				config: {
					title: 'Email',
				},
			},
		},
		{
			for: '.TransactionLines',
			search: {
				searchable: true,
				pretty: 'Transaction Lines',
			},
			filter: {
				sort: {
					parser: 'float',
					field: 'Normalized Amount',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 10,
					title: 'Name',
					subtitles: [
						{
							field: 'TransactionDate',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
							prefix: 'Transaction Date',
						},
						{
							field: 'Normalized Amount',
							icon: 'money',
							formatter: '$',
							prefix: 'Normalized Amount',
						},
					],
				},
			},
		},
		{
			for: '.SightfullFinancialAccounts',
			filter: {
				sort: {
					parser: 'float',
					field: 'Account Number',
					direction: 'asc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					title: 'Name',
					subtitles: [
						{
							field: 'Account Number',
						},
						{
							field: 'Account Type Category',
							prefix: 'Acount Type',
						},
					],
				},
			},
		},
		{
			for: '.Departments',
			search: {
				searchable: true,
				pretty: 'Departments',
			},
			display: {
				type: 'CupertinoTable',
				config: {
					title: 'Name',
					subtitles: [
						{
							field: 'Department Category',
							prefix: 'Department Category',
						},
						{
							field: 'Parent Department',
							prefix: 'Parent Department',
						},
					],
				},
			},
		},
		{
			for: '.Transactions',
			search: {
				searchable: true,
				pretty: 'Transactions',
			},
			filter: {
				sort: {
					parser: 'date',
					field: 'TransactionDate',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 5,
					title: 'Name',
					subtitles: [
						{
							field: 'TransactionDate',
							parser: 'date',
							formatter: 'date',
							icon: 'date',
							prefix: 'Transaction Date',
						},
						{
							field: 'Transaction Type',
							prefix: 'Transaction Ty',
						},
					],
				},
			},
		},
		{
			for: '.Entity',
			search: {
				searchable: true,
				pretty: 'Entities',
			},
			display: {
				type: 'CupertinoTable',
				config: {
					title: 'Name',
					subtitles: [
						{
							field: 'Entity Type',
							prefix: 'Entity Type',
						},
						{
							field: 'Country',
							prefix: 'Country',
						},
					],
				},
			},
		},
		{
			for: '.SightfullFinancialUnit',
			search: {
				searchable: true,
				pretty: 'Financial Units',
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 3,
					title: 'Name',
					subtitles: [
						{
							field: 'Account Type',
							prefix: 'Account Type',
						},
					],
				},
			},
		},
		{
			for: '.Case',
			display: {
				type: 'CupertinoTable',
				config: {
					title: 'Subject',
				},
			},
		},
		{
			for: '.Contact',
			display: {
				type: 'CupertinoTable',
				config: {
					title: 'Email',
				},
			},
		},
		{
			for: '.Metric',
			filter: {
				sort: {
					parser: 'date',
					field: 'start',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 3,
					title: 'id',
					subtitles: [
						{
							field: '$amount',
							formatter: '$',
							icon: 'money',
						},
					],
					link: null,
				},
			},
		},
		{
			for: '.Campaign',
			search: {
				searchable: true,
				pretty: 'Campaigns',
			},
			filter: {
				sort: {
					parser: 'date',
					field: 'Start Date',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 3,
					title: 'Name',
					subtitles: [
						{
							field: 'Start Date',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Campaign Type',
							prefix: 'Campaign Type',
						},
						{
							field: 'Number of Touchpoints',
							prefix: 'Touchpoints',
						},
						{
							field: 'Number of Converted Leads',
							prefix: 'Converted Leads',
						},
						{
							field: 'Number of Won Opportunities',
							prefix: 'Won Opportunities',
						},
					],
				},
			},
		},
		{
			for: '.Lead',
			search: {
				searchable: true,
				pretty: 'Leads',
			},
			filter: {
				sort: {
					parser: 'date',
					field: 'CreatedDate',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 3,
					title: 'Name',
					subtitles: [
						{
							field: 'Created Date',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
						},
						{
							field: 'Role Level',
							prefix: 'Role',
						},
						{
							field: 'Lead Source',
							prefix: 'Lead Source',
						},
						{
							field: 'Lead Stage',
							prefix: 'Stage',
						},
					],
				},
			},
		},
		{
			for: '.CampaignMember',
			search: {
				searchable: true,
				pretty: 'Campaign Touchpoints',
			},
			filter: {
				sort: {
					parser: 'date',
					field: 'CreatedDate',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 5,
					title: 'Name',
					subtitles: [
						{
							field: 'CreatedDate',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
							prefix: 'Date',
						},
						{
							field: 'Touch Type',
							prefix: 'Type',
						},
						{
							field: 'Status',
							prefix: 'Status',
						},
						{
							field: 'Opportunity Amount',
							prefix: 'Opp Amount',
							formatter: '$',
						},
					],
				},
			},
		},
		{
			for: '.ForecastCall',
			search: {
				searchable: true,
				pretty: 'Forecast Calls',
			},
			filter: {
				sort: {
					parser: 'date',
					field: 'CreatedDate',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 5,
					title: 'Period',
					subtitles: [
						{
							field: 'CreatedDate',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
							prefix: 'Date',
						},
						{
							field: 'Week',
							prefix: 'Week',
						},
						{
							field: 'Participation',
							prefix: 'Participation',
						},
						{
							field: 'Value',
							prefix: 'Value',
							formatter: '$',
						},
					],
				},
			},
		},
		{
			for: '.Task',
			search: {
				searchable: true,
				pretty: 'Activities',
			},
			filter: {
				sort: {
					parser: 'date',
					field: 'CreatedDate',
					direction: 'desc',
				},
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 3,
					title: 'Name',
					subtitles: [
						{
							field: 'CreatedDate',
							icon: 'date',
							parser: 'date',
							formatter: 'date',
							prefix: 'Created Date',
						},
						{
							field: 'Owner User',
							prefix: 'Owner',
						},
						{
							field: 'Task Subtype',
							prefix: 'Type',
						},
						{
							field: 'Priority',
							prefix: 'Priority',
						},
					],
				},
			},
		},
		{
			for: '.OpportunityStage',
			search: {
				searchable: true,
				pretty: 'Opportunity Stages',
			},
			display: {
				type: 'CupertinoTable',
				config: {
					first: 3,
					title: 'Name',
				},
			},
		},
	],
	default_relationship: {
		display: {
			type: 'CupertinoTable',
			config: {
				first: 3,
				title: 'id',
			},
		},
	},
} as const;

export type NodeSchema = typeof nodeSchema;
