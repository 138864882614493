import Box from '@components/Box';
import * as CSS from 'csstype';
import { ReactNode } from 'react';

export type BadgeProps = {
	icon: ReactNode;
	color: CSS.Property.Color;
	hoverColor?: CSS.Property.Color;
	iconColor: CSS.Property.Color;
	hoverIconColor?: CSS.Property.Color;
	transitionDuration?: CSS.Property.TransitionDuration;
	isIcon16Px?: boolean;
};
export function Badge({
	icon,
	color,
	iconColor,
	hoverColor,
	hoverIconColor,
	transitionDuration = '200ms',
	isIcon16Px = false,
}: BadgeProps) {
	return (
		<Box
			backgroundColor={color}
			color={iconColor}
			borderRadius="2px"
			height="20px"
			width="20px"
			padding={isIcon16Px ? '2px' : '4px'}
			transitionDuration={transitionDuration}
			_hover={{ backgroundColor: hoverColor, color: hoverIconColor }}
		>
			{icon}
		</Box>
	);
}
