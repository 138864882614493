import { Flex } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import Loader from 'src/common/components/Loader';
import useMutation from 'src/common/hooks/fetching/useMutation';
import { useHasuraSubscriptionWithCache } from 'src/common/hooks/fetching/useSubscription';
import useToast from 'src/common/hooks/ui/useToast';
import { DataIssuesGetAllQuery, UpdateIssueMutation } from 'src/generated/graphql';
import Page from 'src/layout/Page';
import { DataIssuesGetAll, UpdateIssue } from 'src/queries/data-hygiene';
import { useReportEvent } from 'src/services/analytics';
import { DataHygieneTable } from './components/DataHygieneTable';
import { Header } from './components/Header';
import { filterOptions, severitiesOptions, tabsStatuses } from './constants';
import { ActionData } from './types';
import { filterIssues, getFormattedIssues, getFormattedTabs } from './utilities/formatters';

export default function DataHygienePage() {
	return (
		<Page page="Data Hygiene">
			<DataHygienePageGuard />
		</Page>
	);
}

export function DataHygienePageGuard() {
	const { reportEvent } = useReportEvent();
	const { data: allIssues, loading: isLoadingAllIssues } = useHasuraSubscriptionWithCache(DataIssuesGetAll);

	const [mutateIssue] = useMutation<UpdateIssueMutation>(UpdateIssue);
	const toast = useToast();
	const [selectedSeverity, setSelectedSeverity] = useState(severitiesOptions[0].value);
	const [tabIndex, setTabIndex] = useState(0);
	const [selectedFilters, setSelectedFilters] = useState<number[]>([]);

	const createSuccessToast = useCallback(
		(
			updateIssueResult: UpdateIssueMutation | null | undefined,
			object_source_url: string,
			object_datasource: string
		) => {
			const { update_data_issues_by_pk } = updateIssueResult ?? {};
			if (!update_data_issues_by_pk || !allIssues) {
				return;
			}

			const { detection_status } = update_data_issues_by_pk;
			const newStatus = detection_status == 'Open' ? 'Reopened' : detection_status;

			toast({
				variant: 'ok',
				message: `Issue ${newStatus}`,
				link: `View on ${object_datasource}`,
				onClick: () => {
					reportEvent({
						event: 'data-hygiene-go-to-issue-object-url-toast',
						metaData: { object_datasource, object_source_url },
					});
					if (object_source_url) window?.open(object_source_url);
				},
			});
		},
		[allIssues, reportEvent, toast]
	);

	const updateIssue = useCallback(
		(variables: ActionData, object_source_url: string, object_datasource: string) =>
			mutateIssue({
				variables,
				optimisticResponse: {
					update_data_issues_by_pk: variables,
				},

				update: (cache) => {
					const issuesFromCache = cache.readQuery<DataIssuesGetAllQuery>({
						query: DataIssuesGetAll,
					});

					if (!issuesFromCache) {
						return;
					}

					const { issue_key, object_id, tid } = variables;
					const compareIssues = (issue: DataIssuesGetAllQuery['data_issues']['0']) =>
						issue_key == issue.issue_key && object_id == issue.object_id && tid == issue.tid;

					const data_issues = issuesFromCache.data_issues.map((issue) =>
						compareIssues(issue) ? { ...issue, detection_status: variables.detection_status } : issue
					);

					cache.writeQuery<DataIssuesGetAllQuery>({
						query: DataIssuesGetAll,
						data: { data_issues },
					});
				},
			})
				.then(({ data }) => createSuccessToast(data, object_source_url, object_datasource))
				.catch(() => toast({ variant: 'error', message: `Failed :/ Try again` })),
		[createSuccessToast, mutateIssue, toast]
	);

	const normalizedDataIssuesByStatus = useMemo(
		() => allIssues && getFormattedIssues(allIssues.data_issues, updateIssue),
		[allIssues, updateIssue]
	);
	const tabs = useMemo(() => getFormattedTabs(allIssues?.data_issues), [allIssues]);

	const filteredIssues = useMemo(
		() =>
			normalizedDataIssuesByStatus &&
			filterIssues({
				normalizedDataIssuesInStatus: normalizedDataIssuesByStatus[tabsStatuses[tabIndex]],
				selectedSeverity,
				selectedFilters,
			}),
		[normalizedDataIssuesByStatus, selectedSeverity, selectedFilters, tabIndex]
	);

	const onChangeFilter = (index: number) => {
		const filterOptionsSet = new Set(selectedFilters);
		if (filterOptionsSet.has(index)) filterOptionsSet.delete(index);
		else filterOptionsSet.add(index);
		setSelectedFilters([...filterOptionsSet]);

		const filterOptionsLabels = [...filterOptionsSet].map((curOption) => filterOptions[curOption]);

		reportEvent({
			event: 'data-hygiene-filter-changed',
			metaData: { filter: filterOptionsLabels },
		});
	};

	return (
		<>
			{isLoadingAllIssues ? (
				<Loader />
			) : (
				<Flex direction={'column'} height="100%" pb={'40px'}>
					<Header
						tabs={tabs}
						tabIndex={tabIndex}
						onChangeTab={(value) => {
							reportEvent({
								event: 'data-hygiene-status-filter-changed',
								metaData: { status: tabsStatuses[value] },
							});
							setTabIndex(value);
						}}
						filterOptions={filterOptions}
						filterIndexValues={selectedFilters}
						onChangeFilter={onChangeFilter}
						selectOptions={severitiesOptions}
						selectedSeverity={selectedSeverity}
						onChangeSelect={({ value }) => {
							const severityLabel = (severitiesOptions.find((opt) => opt.value == value) ?? severitiesOptions[0]).label;

							reportEvent({
								event: 'data-hygiene-severity-filter-changed',
								metaData: { severity: severityLabel },
							});
							setSelectedSeverity(value);
						}}
					/>
					{filteredIssues && <DataHygieneTable rowsData={filteredIssues} />}
				</Flex>
			)}
		</>
	);
}
