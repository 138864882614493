import { ChartOptions, ChartSeries, SeriesDataPointYFormatter } from 'src/common/components/Chart/types';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { DataLabelFormatConfig } from '../statisticOperations/types';
import { buildDollarFormatter, numberFormatter, percentageFormatter } from '../valueFormatters';

export function calcFormatting(
	prevChartOptions: ChartOptions,
	formatConfig?: DataLabelFormatConfig
): Pick<MetricDerivedState, 'chartOptions'> {
	// TODO: Unimplemented, use as reference to implement this: from lib/data/sources/api/metrics.dart:295

	const getFormatter = (unit?: string): SeriesDataPointYFormatter => {
		switch (unit) {
			case '$':
				return buildDollarFormatter(formatConfig?.decimalDigits);
			case '%':
				return (value) => percentageFormatter(value, formatConfig?.decimalDigits);
			default:
				return (value) => numberFormatter(value, formatConfig?.decimalDigits);
		}
	};

	const formattedSeries: ChartSeries[] = prevChartOptions.series.map(
		(seriesBeforeFormatting): ChartSeries => ({
			...seriesBeforeFormatting,
			custom: {
				...seriesBeforeFormatting.custom,
				seriesDataPointYFormatter: getFormatter(seriesBeforeFormatting.custom?.unit),
			},
		})
	);
	return { chartOptions: { ...prevChartOptions, series: formattedSeries } };
}
