import { Box, Flex } from '@chakra-ui/react';
import { Editor } from '@monaco-editor/react';
import * as monacoEditor from 'monaco-editor';
import { useEffect, useRef, useState } from 'react';
import Button from 'src/common/components/Button';
import { HelpCircleOutline16 } from 'src/common/components/Icons';
import { Label } from 'src/common/components/Labels';
import Tooltip from 'src/common/components/Tooltip';
import { sleep } from 'src/common/utils/utils';
import { useMonacoProviders } from 'src/lib/completions/hooks/useMonacoProviders';
import GITHUB_MONACO_THEME from 'src/theme/monaco-theme.json';
import { SIGHTFULL_MONACO_THEME_NAME, editorOptions, useInitMonacoEditor } from '.';
import { EditorHelpTooltip } from './EditorHelpTooltip';
import { EditorSkeleton } from './EditorSkeleton';
import './MonacoEditor.scss';
import { MonacoError } from './types';

type OldSchemaType = 'metric_file'; // TODO: delete after builder is finished
export type SchemaType =
	| 'normalization'
	| 'normalization_dimension'
	| 'normalization_raw_dimension'
	| 'normalization_relationship'
	| 'metric_agg'
	| 'metric_formula'
	| 'base-entity'
	| 'parameters'
	| OldSchemaType;

export function MonacoYamlEditor({
	value,
	onHelpClicked,
	onChange,
	isEditable,
	hasUnsavedChanges,
	onErrorsChange,
	schemaType,
	onFocus,
	editorLoading,
	footer,
	onBlur,
	border,
	borderColor,
	borderRadius,
}: {
	value: string;
	onHelpClicked: VoidFunction;
	onChange?: (value: string) => void;
	isEditable?: boolean;
	hasUnsavedChanges?: boolean;
	onErrorsChange?: (errors: MonacoError[]) => void;
	onFocus?: (hasError?: boolean) => void;
	schemaType: SchemaType;
	editorLoading?: boolean;
	footer?: React.ReactNode;
	onBlur?: () => void;
	border?: string;
	borderColor?: string;
	borderRadius?: string;
}) {
	useInitMonacoEditor();
	const [, setHasFocus] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);

	const editorRef = useRef<null | monacoEditor.editor.IStandaloneCodeEditor>(null);
	const editorKeyCode = monacoEditor.KeyCode;
	const AutoSuggestTriggerKeys = [editorKeyCode.Space, editorKeyCode.Backspace, editorKeyCode.Tab];

	const checkEditorKeyCode = (code: monacoEditor.KeyCode) => AutoSuggestTriggerKeys.includes(code);

	useEffect(() => {
		if (editorRef.current?.getValue() !== value) editorRef.current?.setValue(value);
	}, [value, editorRef]);
	useMonacoProviders();

	const onMount = (editor: monacoEditor.editor.IStandaloneCodeEditor, monacoInstance: typeof monacoEditor) => {
		editorRef.current = editor;
		sleep(100).then(() => {
			editor.trigger('', 'editor.action.forceRetokenize', '');
		});

		monacoEditor.editor.defineTheme(SIGHTFULL_MONACO_THEME_NAME, GITHUB_MONACO_THEME as any);
		monacoEditor.editor.setTheme(SIGHTFULL_MONACO_THEME_NAME);
		editor.onDidFocusEditorText(() => {
			const hasErrors = monacoInstance.editor.getModelMarkers({}).length > 0;
			setHasFocus(true);
			onFocus?.(hasErrors);
		});
		editor.onDidBlurEditorText(() => {
			setHasFocus(false);
			onBlur?.();
		});
		editor.onKeyUp((e) => {
			const position = editor.getPosition();
			const model = editor.getModel();
			if (!position || !model) return;
			const text = model.getLineContent(position.lineNumber).trim();
			if (checkEditorKeyCode(e.keyCode) && (!text || text.includes('entity:'))) {
				editor.trigger('', 'editor.action.triggerSuggest', { auto: true });
			}
		});
		monacoInstance.editor.onDidChangeMarkers(() => {
			const errors = monacoInstance.editor.getModelMarkers({});
			if (errors.length === 0 || !errors[0]?.resource) {
				setHasErrors(false);
				onErrorsChange?.([]);
				return;
			}

			const model = monacoInstance.editor.getModel(errors[0]?.resource);
			if (!model) {
				setHasErrors(false);
				onErrorsChange?.([]);
				return;
			}

			const parsedErrors: MonacoError[] = errors.map((error) => ({
				content: model?.getLineContent(error.startLineNumber) ?? '',
				lineNumber: error.startLineNumber,
				message: error.message,
			}));
			const hasErrors = errors.length > 0;
			setHasErrors(hasErrors);
			onErrorsChange?.(parsedErrors);
		});
	};

	return (
		<>
			<Flex
				backgroundColor={isEditable ? 'white' : 'gray.100'}
				height="0"
				minHeight={'100%'}
				direction="column"
				position={'relative'}
				border={border}
				borderColor={borderColor}
				borderRadius={borderRadius}
			>
				{editorLoading && <EditorSkeleton />}
				<Flex paddingX="20px" alignItems={'center'} paddingY="16px" justifyContent={'space-between'}>
					<Flex gap={'8px'} flexWrap="wrap" dir="row">
						<Label colorScheme="neutral" variant="filled" size="small">
							YAML
						</Label>
						{hasUnsavedChanges && !hasErrors && (
							<Label
								colorScheme="neutral"
								variant="filled"
								size="small"
								startIcon={<Box width="8px" height={'8px'} backgroundColor="gray.700" borderRadius={'100%'} />}
							>
								Unsaved changes
							</Label>
						)}
						{hasUnsavedChanges && hasErrors && (
							<Label
								colorScheme="error"
								variant="filled"
								size="small"
								startIcon={<Box width="8px" height={'8px'} backgroundColor="red.700" borderRadius={'100%'} />}
							>
								Error
							</Label>
						)}
					</Flex>
					<Tooltip
						pointerEvents={'all'}
						width="264px"
						closeDelay={1000}
						label={<EditorHelpTooltip schemaType={schemaType} onClick={onHelpClicked} isReadonly={!isEditable} />}
						size="md"
					>
						<Button size="xxs" colorScheme="lightGray" variant="outline" isIconOnly>
							<HelpCircleOutline16 />
						</Button>
					</Tooltip>
				</Flex>
				<Box
					padding={'0 0 0 20px'}
					border={'none'}
					borderRadius={'8px'}
					flex={1}
					height="0"
					className={'monaco-with-css-tooltips'}
				>
					<Editor
						path={`file:///${schemaType}.yaml`}
						language="yaml"
						options={{ ...editorOptions, readOnly: !isEditable }}
						onChange={(value, ev) => {
							if (ev.isFlush) return;
							if (value) onChange?.(value);
						}}
						onMount={onMount}
						defaultValue={value}
					/>
				</Box>
				{footer}
			</Flex>
		</>
	);
}
