import { Flex } from '@chakra-ui/react';
import { SearchInput } from 'src/common/components/SearchInput/SearchInput';
import Typography from 'src/common/components/Typography';
import { labelPretty } from 'src/common/utils/searchEntity';
import { EntityType } from 'src/layout/EntityProfileDrawer/types';
import { useReportEvent } from 'src/services/analytics';
import { resultsPageMetadata } from '../utils';

export function EntityTableHeader({
	entityType,
	isAllSearch,
	isAllChosen,
	pageSize,
	shownRowsCount,
	allRowsCount,
	onViewAllClick,
	searchTerm,
	setSearchTerm,
}: {
	entityType: EntityType;
	isAllSearch: boolean;
	isAllChosen: boolean;
	pageSize: number;
	shownRowsCount: number;
	allRowsCount: number;
	onViewAllClick: () => void;
	searchTerm: string;
	setSearchTerm: (term: string) => void;
}) {
	const { reportEvent, wrapWithReport } = useReportEvent();

	return (
		<Flex justifyContent="space-between" alignSelf="stretch" alignItems="center">
			<Typography variant="DesktopH7Medium" color="gray.800">
				{`${labelPretty(entityType)}` + (isAllChosen ? ` (${pageSize} of ${allRowsCount})` : ` (${shownRowsCount})`)}
			</Typography>
			{isAllChosen ? (
				<Typography
					variant="DesktopH8Medium"
					color="gray.800"
					onClick={wrapWithReport(onViewAllClick, 'view-all-clicked', {
						...resultsPageMetadata(isAllSearch),
						postState: labelPretty(entityType),
					})}
					_hover={{ cursor: 'pointer', textDecoration: 'underline' }}
				>
					View all
				</Typography>
			) : (
				<SearchInput
					value={searchTerm}
					onChange={(newValue) => {
						reportEvent({
							event: 'entity-inner-search',
							metaData: {
								...resultsPageMetadata(isAllSearch),
								tableName: labelPretty(entityType),
								searchTerm: newValue,
							},
						});
						setSearchTerm(newValue);
					}}
				/>
			)}
		</Flex>
	);
}
