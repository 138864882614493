import Tooltip from '@components/Tooltip';
import { IHeaderParams } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import Box from '@components/Box';
import Typography from '@components/Typography';
import { mapSortingIcons, possibleSortOptions } from 'src/common/components/Table/sortOptions';
import { RemoveSolid12 } from 'src/common/components/Icons';
import { DetailedTableIdCollectedProps } from 'src/common/utils/bizapiParsers';

const ColumnHeader = ({
	columnObj,
	setAdditionalColumns,
	additionalColumns,
}: {
	columnObj: IHeaderParams;
	setAdditionalColumns: (val: string[]) => void;
	additionalColumns: string[];
}) => {
	const [sortingIndex, setSortingIndex] = useState(0);
	const shouldAddRemoveOption = additionalColumns.includes(columnObj.column.getColDef().field || '');
	const sortType = columnObj.column.getSort();

	const openMenu = (event: React.MouseEvent<HTMLElement>) => {
		event?.preventDefault();
		const colId = columnObj.column.getColId();
		const { api } = columnObj;
		api.showColumnMenuAfterMouseClick(colId, event.nativeEvent);
	};

	const removeColumn = () => {
		const fieldName = columnObj.column.getColDef().field?.split(`${DetailedTableIdCollectedProps}.`).pop();
		setAdditionalColumns(additionalColumns.filter((prop) => prop !== fieldName));
	};

	const removeIconComponent = shouldAddRemoveOption && (
		<Tooltip label="Remove column" placement="top" size="sm">
			<Box onClick={removeColumn} unselectable="on" role="presentation">
				<RemoveSolid12 />
			</Box>
		</Tooltip>
	);

	const setAndGetCurrentIndex = () => {
		let currentIndex = sortingIndex + 1;

		if (currentIndex === possibleSortOptions.length) {
			setSortingIndex(0);
			currentIndex = 0;
		} else {
			setSortingIndex(currentIndex);
		}

		return currentIndex;
	};

	const onSortRequested = () => {
		const currentIndex = setAndGetCurrentIndex();
		columnObj.setSort(possibleSortOptions[currentIndex]);
	};

	useEffect(() => {
		if (!sortType) {
			setSortingIndex(0);
		}
	}, [sortType]);

	return (
		<Box className="columnHeader">
			<Box display="flex" alignItems="center" justifyContent="start" pr="40px" w="100%" onClick={onSortRequested}>
				<Tooltip size="sm" variant="primary" label={columnObj.displayName} placement={'top'}>
					<Typography variant="Disclaimer12SB" color="gray.700" textOverflow="ellipsis" overflow="hidden">
						{columnObj.displayName}
					</Typography>
				</Tooltip>
				<Box pl="8px">{sortType && mapSortingIcons[sortType]}</Box>
			</Box>

			<Box className="columnButtons">
				{removeIconComponent}
				<Box
					onClick={openMenu}
					className="columnHeader ag-icon ag-icon-menu"
					unselectable="on"
					role="presentation"
					pl="8px"
				/>
			</Box>
		</Box>
	);
};
export default ColumnHeader;
