import { DisplayUnit, StatisticOperation } from 'src/pages/MetricPage/utils/state.types';
import colors from 'src/style/colors';
import { StatisticalOperation } from 'src/types/metric';

export const SeriesSinglePeriodPlaceholderName = 'Single Period Placeholder - Reserved';

const Growth: StatisticOperation = {
	name: 'Growth',
	color: colors.teal['900'],
	isChecked: false,
	options: [],
	selectedOptionIndex: 1,
	operationInfoKey: 'comparison_duration',
};

const Average: StatisticOperation = {
	color: colors.lime['600'],
	isChecked: false,
	options: [],
	selectedOptionIndex: 1,
	name: 'Average',
	operationInfoKey: 'periods_count',
};

const Delta: StatisticOperation = {
	name: 'Delta',
	color: colors.gray['600'],
	isChecked: false,
	options: [],
	selectedOptionIndex: 1,
	operationInfoKey: 'comparison_duration',
};

export const CountStatistic = {
	name: 'Count',
	key: DisplayUnit.count,
	color: 'white',
};

export const PercentagePreviousStatistic = {
	name: 'Percentage prev',
	key: DisplayUnit.percentagePrev,
	color: 'white',
};

export const PercentageFirstStatistic = {
	name: 'Percentage first',
	key: DisplayUnit.percentageFirst,
	color: 'white',
};

export const PercentageStatistic = {
	name: 'Percentage',
	key: DisplayUnit.percentage,
	color: 'white',
};

export const STATS_SERIES_NAMES = [Average.name];

export const ORDERED_STATISTIC_OPERATIONS: { [key in StatisticalOperation]: StatisticOperation } = {
	Growth,
	Delta,
	Average,
};

export const MainSeriesColors = [colors.blue['600'], colors.sky[500], colors.violet[500]];
export const ComponentsSeriesColors = [
	colors.emerald['600'],
	'#FF7A45', // orange
	colors.yellow['700'],
	colors.cyan['600'],
	colors.rose['600'],
	'#C6BAAF', // zinc 400
	colors.sky['600'],
	colors.indigo['600'],
	colors.indigo['700'],
	colors.pink['600'],
	'#E0BB7F', // gold 700
];
