import { PeriodRange } from '@sightfull/period-ranges';
import { ChartOptions } from 'src/common/components/Chart/types';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { xAxisFormatter } from '../utils';

export function calcAxes(
	chartOptions: ChartOptions,
	periodRange: PeriodRange
): Required<Pick<MetricDerivedState, 'chartOptions'>> {
	const yAxisIds: string[] = [
		...new Set(chartOptions?.series.map((series) => series.yAxis || '').filter((yAxis) => !!yAxis)),
	];

	const xAxis = {
		plotBands: [],
		values: periodRange.asAbsoluteRange.toMetricPeriods(),
		formatter: xAxisFormatter,
	};

	const yAxis = yAxisIds.map((yAxisId) => ({
		visible: false,
		id: yAxisId,
	}));

	return {
		chartOptions: {
			...chartOptions,
			xAxis,
			yAxis,
		},
	};
}
