import Flex from '@components/Flex';
import Box from '@components/Box';
import Button from '@components/Button';
import shadows from 'src/style/shadows';
import Typography from 'src/common/components/Typography';
import { Play16 } from 'src/common/components/Icons';

const DEFAULT_BAR_HEIGHT = 60;

export const ReadOnlyBar = ({
	isReadOnlyBarShown,
	readOnlyBarHeight,
	isPreviewButtonVisible,
	onClick,
	metricName,
	isEnabled = true,
}: {
	isReadOnlyBarShown: boolean;
	readOnlyBarHeight?: number;
	isPreviewButtonVisible: boolean;
	onClick: VoidFunction;
	metricName?: string;
	isEnabled?: boolean;
}) => (
	<Flex
		height={isReadOnlyBarShown ? `${readOnlyBarHeight || DEFAULT_BAR_HEIGHT}px` : '0'}
		padding={isReadOnlyBarShown ? '12px 32px' : 0}
		overflow={'hidden'}
		transition={'padding 150ms, height 0.5s ease'}
		width={'100%'}
		position={'fixed'}
		bottom={'0'}
		zIndex={2}
		backgroundColor={'gray.200'}
		justifyContent={'space-between'}
		alignItems={'center'}
		boxShadow={shadows.borderTop}
	>
		<Flex alignItems={'center'}>
			<Box mr={'8px'}>🚀</Box>
			<Typography variant={'DesktopH8Medium'} color={'gray.950'}>
				You are in view only mode.&nbsp;
			</Typography>
			<Typography variant={'DesktopH8Regular'} color={'gray.950'}>
				Any changes made here won&apos;t be saved.
			</Typography>
		</Flex>
		{isPreviewButtonVisible && (
			<Button
				leftIcon={<Play16 />}
				onClick={onClick}
				eventName="metric-edit-preview-clicked"
				eventMetadata={{ feature: 'Metric Builder', metricName: metricName }}
				colorScheme={!isEnabled ? 'black' : 'blue'}
				variant={'solid'}
				size={'small'}
				isDisabled={!isEnabled}
			>
				Preview
			</Button>
		)}
	</Flex>
);
