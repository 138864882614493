import { DocumentNode, useLazyQuery as GQLQuery, TypedDocumentNode } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core';
import { LazyQueryExecFunction, LazyQueryHookOptions, QueryResult } from '@apollo/client/react/types/types';
import { isProductionMode } from 'src/config';

export function useLazyQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
	query: DocumentNode | TypedDocumentNode<TData, TVariables>,
	options?: LazyQueryHookOptions<TData, TVariables>
): [LazyQueryExecFunction<TData, TVariables>, QueryResult<TData, TVariables>] {
	const [GQLLazyQuery, queryResult] = GQLQuery<TData, TVariables>(query, options);
	const { error } = queryResult;
	if (error) {
		if (!isProductionMode) {
			// TODO: implement error snackbar or something
			console.log('an error occurred');
			console.dir(error);
		}
	}
	return [GQLLazyQuery, queryResult];
}

export default useLazyQuery;
