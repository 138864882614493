import AICard, { AIErrorCard } from '@components/MetricView/MetricDataOverview/AICard';
import { useEffect, useMemo, useState } from 'react';
import { useGenerativeExplain } from 'src/common/components/MetricView/MetricDataOverview/hooks/useGenerativeExplain';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useReportEvent } from 'src/services/analytics';
import { usePersistentState } from '@hooks/usePersistentState';

type MetricDataOverviewProps = {
	toggleGenAIPopover: VoidFunction;
};

export function MetricDataOverview({ toggleGenAIPopover }: MetricDataOverviewProps) {
	const [isDataOverviewOpen, setIsDataOverviewOpen] = usePersistentState('metricPage.openDataOverview', true);
	const { wrapWithReport } = useReportEvent();
	const { metricNameWithFlavor, filters, displayedLegendItems, statisticsOperations, periodRange, metricOperator } =
		useMetricDerivedState();
	const [, { loading: explainLoading, error: explainError, data: aiResponse }] = useGenerativeExplain();

	const [currentMessageId, setCurrentMessageId] = useState(0);
	const isOpenPeriod = useMemo(() => periodRange.asAbsoluteRange.endPeriod.isOpen, [periodRange]);
	const hasError = explainError || (!explainLoading && (aiResponse === null || aiResponse?.messages.length === 0));
	const analyticsMetadata = {
		feature: 'Generative data overview',
		metricName: metricNameWithFlavor,
		periodRange: periodRange.toUrlParams(),
		isSinglePeriod: periodRange.isSinglePeriod,
		displayedLegendItems,
		statisticsOperations: statisticsOperations.filter((op) => op.isChecked),
		filters,
		isOpenPeriod,
		metricOperator,
		hasError,
	};

	const currentMessage = useMemo(
		() => aiResponse?.messages?.at(currentMessageId),
		[aiResponse?.messages, currentMessageId]
	);
	const messagesLength = useMemo(() => aiResponse?.messages?.length ?? 0, [aiResponse?.messages]);

	useEffect(() => {
		setCurrentMessageId(0);
	}, [aiResponse]);

	const onExpandCollapseClickHandle = () => {
		setIsDataOverviewOpen(!isDataOverviewOpen);
	};

	const onPrevMessage = () => {
		if (currentMessageId == 0) setCurrentMessageId(messagesLength - 1);
		else setCurrentMessageId(currentMessageId - 1);
	};
	const onNextMessage = () => {
		if (currentMessageId == messagesLength - 1) setCurrentMessageId(0);
		else setCurrentMessageId(currentMessageId + 1);
	};

	const curMessageIndex = currentMessageId + 1;

	if (hasError) return <AIErrorCard />;

	return (
		<AICard
			isDataOverviewOpen={isDataOverviewOpen}
			isLoading={explainLoading || !!explainError || !aiResponse}
			currentMessage={{
				position: curMessageIndex,
				text: currentMessage,
			}}
			messagesLength={messagesLength}
			onExpandCollapseClick={wrapWithReport(onExpandCollapseClickHandle, 'generative-data-overview-button-toggle', {
				...analyticsMetadata,
				prevState: isDataOverviewOpen,
				nextState: !isDataOverviewOpen,
			})}
			onPrevMessage={wrapWithReport(onPrevMessage, 'generative-data-overview-navigate-message', {
				...analyticsMetadata,
				kind: 'prev',
				prevMessageIndex: curMessageIndex,
				nextMessageIndex: curMessageIndex == 0 ? messagesLength : curMessageIndex - 1,
			})}
			onNextMessage={wrapWithReport(onNextMessage, 'generative-data-overview-navigate-message', {
				...analyticsMetadata,
				kind: 'next',
				prevMessageIndex: curMessageIndex,
				nextMessageIndex: curMessageIndex == messagesLength ? 0 : curMessageIndex + 1,
			})}
			toggleGenAIPopover={toggleGenAIPopover}
		/>
	);
}
