import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import Avatar from 'src/common/components/Avatar/Avatar';

import useMutation from 'src/common/hooks/fetching/useMutation';
import useUser from 'src/common/hooks/stores/useUser';
import { CommentInsertMutation, CommentInsertMutationVariables } from 'src/generated/graphql';
import { CommentInsert } from 'src/queries/signals';

import 'src/common/components/TextEditor/styles/global.scss';
import { useReportEvent } from 'src/services/analytics';
import { FeedSignal } from 'src/types/spaces';
import { CommentTextEditor } from './CommentTextEditor';

export function CreateCommentTextArea({ signal }: { signal: FeedSignal }) {
	const { reportEvent } = useReportEvent();
	const { signal_id: signalId, feed_id: workspaceId } = signal;
	const [inputText, setInputText] = useState('');

	const [{ picture, firstName, lastName }] = useUser();

	const [insertComment, { loading: isInsertLoading }] = useMutation<
		CommentInsertMutation,
		CommentInsertMutationVariables
	>(CommentInsert);

	async function onSubmit() {
		if (!inputText?.replaceAll('\\', '')?.trim()) return;

		reportEvent({
			event: 'comment-add',
			metaData: { workspaceId: workspaceId, signalId: signalId, commentLength: inputText.length ?? 0 },
		});

		const insertVars: CommentInsertMutationVariables = { signal_id: signalId, message: inputText };
		await insertComment({ variables: insertVars });
	}

	return (
		<CommentTextEditor
			prefixComponent={
				<Box pt="12px" pl="12px">
					<Avatar imageUrl={picture} name={`${firstName} ${lastName}`} shape={'round'} height="24px" width="24px" />
				</Box>
			}
			value={inputText}
			isEditMode={true}
			onChange={setInputText}
			isInsertLoading={isInsertLoading}
			onSubmit={onSubmit}
			isClearingOnSubmit
		/>
	);
}
