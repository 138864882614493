import { ColDef } from 'ag-grid-community';
import { useCallback, useMemo, useState } from 'react';
import { AdditionalColumn, GetRecordProfileByIdQuery, useGetRecordProfileByIdQuery } from 'src/generated/graphql';
import FiltersAndBreakdownsModal from 'src/pages/MetricPage/components/FiltersAndBreakdown/FiltersAndBreakdownsModal';
import {
	convertModalResultToValidCoreValues,
	useCoreNodeScheme,
} from '@pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { FiltersAndBreakdownResponseType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import useFiltersAndBreakdown from 'src/pages/MetricPage/components/FiltersAndBreakdown/useFiltersAndBreakdown';
import { useReportEvent } from 'src/services/analytics';
import { useEntityProfileDrawer, useEntityProfileReportEvent } from '../EntityProfileDrawer/hooks';
import { GridTable } from '@components/GridTable/GridTable';
import { GridTableBody } from '@components/GridTable/GridTableBody';
import { GridTableHeader } from '@components/GridTable/GridTableHeader';
import { GridTableHeaderActions, GridTableHeaderActionAddColumn } from '@components/GridTable/GridTableHeaderAction';
import Divider from '../../common/components/Divider';

type RecordProfileRelationTableV2Props = {
	headerName: string;
	relationsRows: GetRecordProfileByIdQuery['recordProfile']['relations'][0]['rows'];
	searchValue: string;
	entityName: string;
	relationName: string;
	currentRecordId: string;
};
export function RecordProfileRelationTableV2({
	headerName,
	relationsRows: relationsRowsFromParent,
	searchValue,
	entityName,
	relationName,
	currentRecordId,
}: RecordProfileRelationTableV2Props) {
	const { wrapWithReport } = useReportEvent();
	const [modalState, { onOpen, onAddItems, onClose }] = useFiltersAndBreakdown();
	const [additionalColumns, setAdditionalColumns] = useState<AdditionalColumn[]>([]);
	const { data, loading, error } = useGetRecordProfileByIdQuery({
		variables: { id: currentRecordId, additionalColumns },
		skip: additionalColumns.length === 0,
	});
	const relationsRows = useMemo(() => {
		if (loading || error) return [];
		if (!data) return relationsRowsFromParent;

		return data?.recordProfile?.relations.find((relation) => relation.name === relationName)?.rows ?? [];
	}, [data, error, loading, relationName, relationsRowsFromParent]);
	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes: [entityName],
		schemeType: 'global',
		fetchPolicy: 'no-cache',
	});

	const hasNameColumn = useMemo(() => !!relationsRows.find((row) => row.name), [relationsRows]);

	const colDefs: ColDef[] = useMemo(() => {
		return [
			...(hasNameColumn ? [{ field: 'name' }] : []),
			...(relationsRows[0]?.additionalColumns?.map((column) => ({
				field: column.key,
			})) ?? []),
		];
	}, [hasNameColumn, relationsRows]);

	const rowData = useMemo(() => {
		return relationsRows
			.map((row) => {
				return {
					...(hasNameColumn ? { name: row.name } : {}),
					...(row.additionalColumns
						?.map((column) => ({ [column.key]: column.value }))
						.reduce((acc, column) => ({ ...acc, ...column })) ?? {}),
					_row_id: row.sightId,
				};
			})
			.filter((row) => JSON.stringify(row).toLowerCase().includes(searchValue.toLowerCase()));
	}, [hasNameColumn, relationsRows, searchValue]);

	const { reportOpenEntityDrawer } = useEntityProfileReportEvent();
	const { pushEntity } = useEntityProfileDrawer();

	const onRowClicked = (event: any) => {
		const recordId = event?.data?._row_id;

		reportOpenEntityDrawer({
			origin: 'record-profile-one-to-many',
			id: recordId,
		});

		pushEntity(recordId);
	};

	const onCloseAddColumnModal = useCallback(
		(result: FiltersAndBreakdownResponseType) => {
			if (!result?.items?.length) return;
			const columns = convertModalResultToValidCoreValues(result, entityName).map((e) => e.key);

			setAdditionalColumns((prev) => {
				const isRelationHavePreviousAddedColumns = prev.find((e) => e.relationshipName === relationName);
				if (isRelationHavePreviousAddedColumns) {
					return prev.map((e) =>
						e.relationshipName === relationName ? { ...e, tokens: e.tokens.concat(columns) } : e
					);
				}
				return prev.concat({
					relationshipName: relationName,
					tokens: columns,
				});
			});
		},
		[entityName, relationName, setAdditionalColumns]
	);

	return (
		<GridTable>
			<Divider direction={'horizontal'} color={'gray.300'} />
			<GridTableHeader
				title={headerName}
				subtitle={`Showing ${rowData.length} record${rowData.length !== 1 ? 's' : ''}`}
				tableActions={
					<GridTableHeaderActions>
						<GridTableHeaderActionAddColumn
							onClick={wrapWithReport(() => onOpen('column', onCloseAddColumnModal), 'record-profile-click-add-cols', {
								feature: 'Entity Drawer',
								headerName,
								entityName,
								relationName,
							})}
						/>
					</GridTableHeaderActions>
				}
			/>
			<GridTableBody data={rowData} columnDefs={colDefs} onRowClicked={onRowClicked} maxRowsToShow={6} />
			<FiltersAndBreakdownsModal
				type={modalState.type}
				isOpen={modalState.isOpen}
				onClose={onClose}
				onAddItems={onAddItems}
				nodeScheme={coreNodeScheme}
			/>
		</GridTable>
	);
}
