import Link from '@components/Link';

export type LearnMoreProps = {
	link?: string;
	onClick: () => void;
};

export function LearnMore({ link, onClick }: LearnMoreProps) {
	return (
		<Link onClick={onClick} color={'blue.600'} target={'_blank'} href={link}>
			Learn more
		</Link>
	);
}
