const theme = {
	ltr: 'ltr',
	rtl: 'rtl',
	placeholder: 'text-editor-placeholder',
	paragraph: 'text-editor-paragraph',
	quote: 'text-editor-quote',
	table: 'text-editor-table',
	heading: {
		h1: 'text-editor-heading-h1',
		h2: 'text-editor-heading-h2',
		h3: 'text-editor-heading-h3',
		h4: 'text-editor-heading-h4',
		h5: 'text-editor-heading-h5',
	},
	list: {
		nested: {
			listitem: 'text-editor-nested-listitem',
		},
		ol: 'text-editor-list-ol',
		ul: 'text-editor-list-ul',
		listitem: 'text-editor-listitem',
	},
	image: 'text-editor-image',
	link: 'text-editor-link',
	text: {
		bold: 'text-editor-bold',
		italic: 'text-editor-italic',
		overflowed: 'text-editor-overflowed',
		hashtag: 'text-editor-hashtag',
		underline: 'text-editor-underline',
		strikethrough: 'text-editor-strikethrough',
		underlineStrikethrough: 'text-editor-underlineStrikethrough',
		code: 'text-editor-code',
	},
	code: 'text-editor-code',
	codeHighlight: {
		atrule: 'text-editor-tokenAttr',
		attr: 'text-editor-tokenAttr',
		boolean: 'text-editor-tokenProperty',
		builtin: 'text-editor-tokenSelector',
		cdata: 'text-editor-tokenComment',
		char: 'text-editor-tokenSelector',
		class: 'text-editor-tokenFunction',
		'class-name': 'text-editor-tokenFunction',
		comment: 'text-editor-tokenComment',
		constant: 'text-editor-tokenProperty',
		deleted: 'text-editor-tokenProperty',
		doctype: 'text-editor-tokenComment',
		entity: 'text-editor-tokenOperator',
		function: 'text-editor-tokenFunction',
		important: 'text-editor-tokenVariable',
		inserted: 'text-editor-tokenSelector',
		keyword: 'text-editor-tokenAttr',
		namespace: 'text-editor-tokenVariable',
		number: 'text-editor-tokenProperty',
		operator: 'text-editor-tokenOperator',
		prolog: 'text-editor-tokenComment',
		property: 'text-editor-tokenProperty',
		punctuation: 'text-editor-tokenPunctuation',
		regex: 'text-editor-tokenVariable',
		selector: 'text-editor-tokenSelector',
		string: 'text-editor-tokenSelector',
		symbol: 'text-editor-tokenProperty',
		tag: 'text-editor-tokenProperty',
		url: 'text-editor-tokenOperator',
		variable: 'text-editor-tokenVariable',
	},
};

export default theme;
