export const datesSorting = (dateA: string, dateB: string) => {
	const dateAParsed = new Date(dateA);
	const dateBParsed = new Date(dateB);

	if (!dateA) return 1;
	if (!dateB) return -1;

	return dateAParsed.getTime() - dateBParsed.getTime();
};

export const numbersSorting = (numberA: number, numberB: number) => {
	return numberA - numberB;
};

export const textSorting = (stringA?: string, stringB?: string) => {
	if (stringA === stringB) return 0;

	if (!stringA) return 1;
	if (!stringB) return -1;

	return stringA < stringB ? -1 : 1;
};
