import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';
import { formErrorAnatomy as parts } from '@chakra-ui/anatomy';

const baseStyleText: SystemStyleFunction = (props) => ({
	color: mode('red.800', 'red.500')(props),
});

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
	text: baseStyleText(props),
});

export default { baseStyle };
