import Box from '../Box';
import Flex from '../Flex';
import classes from './ButtonBar.module.scss';
import { ButtonBarProps } from './types';

export function ButtonBar({ children, selectedIndexes, onChildClicked, transitionDuration = '120ms' }: ButtonBarProps) {
	const buildChildCells = () =>
		children.map((child, i) => {
			const isSelected = selectedIndexes.includes(i);
			const isNextSelected = selectedIndexes.includes(i + 1);
			const isFirst = i == 0;
			const isLast = i == children.length - 1;

			const classNames = [classes.cell];
			if (isFirst) classNames.push(classes.first);
			if (isLast) classNames.push(classes.last);
			if (isSelected) classNames.push(classes.selected);

			return (
				<Flex key={`buttonbar-item-${i}`}>
					<Flex
						className={classNames.join(' ')}
						key={`buttonbar-child-${i}`}
						height="100%"
						alignItems={'center'}
						onClick={() => onChildClicked(i)}
						as="button"
						overflow="hidden"
						transitionDuration={transitionDuration}
					>
						{child}
					</Flex>
					{!isLast && (
						<Box
							key={`buttonbar-divider-${i}`}
							width="1px"
							height="inherit"
							backgroundColor={isSelected || isNextSelected ? 'blue.600' : 'gray.400'}
						/>
					)}
				</Flex>
			);
		});

	return (
		<Box display="inline-block">
			<Flex height="36px" alignItems="center">
				{buildChildCells()}
			</Flex>
		</Box>
	);
}
