import { Signal } from 'src/types/signal';

export type Sentiment = 'info' | 'success' | 'error' | 'none';

export type FeedSignal = {
	signal_id: string;
	feed_id: string;
	signal: Signal;
	order: number;
	resolved: boolean;
	reason?: string | string[];
	signal_height?: number;
	signal_width?: number;
	updated_at?: string;
	display_options?: { sentiment?: Sentiment };
};

export type CollectionMetadata = {
	description?: string;
	title: string;
};

export type CollectionNames = {
	workspace: string;
	dashboard: string;
};

export const collectionNames: CollectionNames = {
	workspace: 'Workspace',
	dashboard: 'Dashboard',
};
