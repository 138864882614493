import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { GridTableHeaderActions } from './GridTableHeaderAction';

export type GridTableHeaderProps = {
	title?: string;
	subtitle?: React.ReactNode;
	tableActions?: GridTableHeaderActions;
	titleActions?: GridTableHeaderActions;
	children?: React.ReactNode | React.ReactNode[];
};

export function GridTableHeader({ title, subtitle, titleActions, tableActions, children }: GridTableHeaderProps) {
	return (
		<Flex margin={'16px 0 8px 0'} gap={'6px'} direction={'column'}>
			<Flex justifyContent={'space-between'} alignItems={'center'} grow={1}>
				<Flex justifyContent={'flex-start'} alignItems={'center'} grow={1} gap={'6px'}>
					{titleActions && (
						<Flex alignItems={'center'} justifyContent={'flex-start'}>
							{titleActions}
						</Flex>
					)}
					<Typography variant={'DesktopH8Medium'} color={'gray.1000'}>
						{title}
					</Typography>
					<Typography variant={'DesktopH8Regular'} color={'gray.1000'}>
						{subtitle}
					</Typography>
				</Flex>
				{tableActions}
			</Flex>
			<Flex>{children}</Flex>
		</Flex>
	);
}
