import Input from '@components/Input';
import { Calendar216 } from '@icons/index';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import * as DateFns from 'date-fns';
import { format } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { useState } from 'react';
import classes from './DatePicker.module.scss';
import './DatePicker.scss';

const dateFnsOriginFormat = dateFnsGenerateConfig.locale.format;

function fixAntdDialogQuarterRepresentation(local: string, date: Date, format: string) {
	if (format == '[Q]Q') {
		return dateFnsOriginFormat(local, date, 'qqq');
	}
	if (format.includes("'FY'")) {
		// issue with antd formatting of 'FY' strings
		return DateFns.format(date, format);
	}
	return dateFnsOriginFormat(local, date, format);
}

const RawDatePicker = generatePicker<Date>({
	...dateFnsGenerateConfig,
	locale: {
		...dateFnsGenerateConfig.locale,
		format: fixAntdDialogQuarterRepresentation,
	},
});

export const { RangePicker } = RawDatePicker;

export type DatePickerProps = {
	value?: Date;
	isDisabled?: boolean;
	onChange?: (date: string) => void;
	testId?: string;
	onMouseLeave?: VoidFunction;
	onMouseEnter?: VoidFunction;
	onFocus?: VoidFunction;
	onBlur?: VoidFunction;
};
export function DatePicker({
	onChange,
	value,
	isDisabled,
	testId,
	onMouseEnter,
	onMouseLeave,
	onFocus,
	onBlur,
}: DatePickerProps) {
	const [isPanelVisible, setIsPanelVisible] = useState(false);

	const isCorrectDate = (): boolean => value instanceof Date && isFinite(value.getTime());

	return (
		<>
			<Input
				testId={testId}
				size={'md'}
				isDisabled={isDisabled}
				leftComponent={<Calendar216 color={'gray.800'} />}
				onFocus={() => setIsPanelVisible(true)}
				onClick={() => setIsPanelVisible(true)}
				value={isCorrectDate() && value ? format(value, 'MMM dd, yyyy') : ''}
			/>
			<RawDatePicker
				open={isPanelVisible}
				onOpenChange={setIsPanelVisible}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				onFocus={onFocus}
				onBlur={onBlur}
				onChange={(date) => date && onChange?.(DateFns.format(date, 'dd MMM yyyy'))}
				picker="date"
				style={{
					height: '0',
					width: '0',
					overflow: 'hidden',
					padding: '0',
					border: 'none',
					margin: 'none',
					top: '-16px',
				}}
				popupClassName={classes.dropdown}
			/>
		</>
	);
}
