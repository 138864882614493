import Box from '@components/Box';
import { useState } from 'react';
import {
	PropertyDetailsModal,
	PropertyTable,
	RelatedEntitiesTable,
	Thumbnail,
} from 'src/layout/EntityProfileDrawer/components';
import { NestedDrawerV1 } from 'src/layout/EntityProfileDrawer/EntityProfileDrawer';

import { EntityProfile, Property } from 'src/layout/EntityProfileDrawer/types';

interface EntityProfileInnerProps {
	currentEntityId: string;
	rest: string[];
	hasPropertyModalPermission: boolean;
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	node: EntityProfile;
}

export function EntityProfileInner({
	currentEntityId,
	hasPropertyModalPermission,
	isOpen,
	onOpen,
	onClose,
	node,
	rest,
}: EntityProfileInnerProps) {
	const [modalProperty, setModalProperty] = useState<Omit<Property, 'relationshipProperties'>>();
	const handlePropertyDetailsModal = (property: Omit<Property, 'relationshipProperties'>) => {
		setModalProperty(property);
		onOpen();
	};

	return (
		<>
			<Box>
				<Box>
					{node?.propertyCards?.map(({ header, properties }) => {
						return (
							<PropertyTable
								nodeObjectName={node.header.entityType}
								key={`propertyCard-${currentEntityId}-${header}`}
								header={header}
								properties={properties}
								currentEntity={currentEntityId}
								onClick={handlePropertyDetailsModal}
							/>
						);
					})}

					{node.relatedEntitiesTable?.map(({ columns, entityType, relatedEntities, relationshipDisplayName }) => {
						return (
							relationshipDisplayName && (
								<RelatedEntitiesTable
									nodeObjectName={node.header.entityType}
									key={`relatedEntity-${currentEntityId}-${entityType}`}
									header={relationshipDisplayName}
									relatedEntities={relatedEntities}
									columns={columns}
									currentEntity={currentEntityId}
									entityType={entityType}
								/>
							)
						);
					})}

					{rest.length > 0 && (
						<NestedDrawerV1 entityIds={rest} hasPropertyModalPermission={hasPropertyModalPermission} />
					)}
					{node?.metrics?.map((metric) => {
						return (
							<Thumbnail
								key={`metric-${node?.header?.originId}`}
								metric={metric}
								nodeId={String(node?.header?.originId)}
							/>
						);
					})}
				</Box>
			</Box>

			{hasPropertyModalPermission && modalProperty && (
				<PropertyDetailsModal
					isOpen={isOpen}
					onClose={onClose}
					property={modalProperty}
					source={node.source}
					objectId={node?.header.originId}
					nodeObjectName={node.header.entityType}
				/>
			)}
		</>
	);
}
