import colors from 'src/style/colors';
import Button from '../Button';
import Flex from '../Flex';
import { SwitchOff16, SwitchOn16 } from '../Icons';
import Typography from '../Typography';

type Props = {
	onClick?: (newValue: boolean) => void;
	isEnabled: boolean;
	text?: string;
};
export function TinySwitch({ isEnabled, onClick, text }: Props) {
	const icon = isEnabled ? <SwitchOn16 /> : <SwitchOff16 />;
	const fillStyle = isEnabled ? undefined : { path: { fill: colors.gray[500] } };

	return (
		<Flex
			onClick={(e) => {
				onClick?.(!isEnabled);
				e.stopPropagation();
			}}
			as={'button'}
			direction={'row'}
			gap={'6px'}
			alignItems={'center'}
			cursor={'pointer'}
			color={isEnabled ? 'gray.1000' : 'gray.800'}
			_hover={{
				path: { fill: isEnabled ? colors.blue[700] : colors.gray[800] },
			}}
		>
			<Button
				as={'span'}
				isIconOnly
				colorScheme={isEnabled ? 'blue' : 'lightGray'}
				css={fillStyle}
				rightIcon={icon}
				size="xxs"
				variant="link"
			/>
			{text && <Typography variant={'DesktopH8Regular'}>{text}</Typography>}
		</Flex>
	);
}
