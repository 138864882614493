import { ApolloError } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import {
	GenerativeMetricOverviewQueryVariables,
	useGenerativeExplainBetaLazyQuery,
	useGenerativeExplainBetaV2LazyQuery,
	useGenerativeMetricOverviewLazyQuery,
} from 'src/generated/graphql';
import { calcMetricTable } from 'src/lib/metricRules/DerivedStateCalculators';
import { DataLabelFormatConfig } from 'src/lib/metricRules/statisticOperations/types';
import { getFiltersForQuery } from 'src/lib/metricRules/utils';
import {
	addPrefixDollarSignIfNeeded,
	buildFilters,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';

export type ExplainBetaResponse = {
	requestId: string;
	messages: string[];
} | null;

type GenerativeExplain = [
	VoidFunction,
	{
		data?: ExplainBetaResponse;
		loading: boolean;
		error?: ApolloError;
	}
];

export function useGenerativeExplain(): GenerativeExplain {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const isGenerativeV2Enabled = useFeatureFlag('pulse.generative.dataOverview.v2');
	const tableRowsData = useOverviewMetricTable();
	const explainV1 = useGenerativeExplainV1();
	const [getExplainV1] = explainV1;
	const explainV2 = useGenerativeExplainV2();
	const [getExplainV2] = explainV2;
	const metricOverview = useMetricOverview();
	const [getMetricOverview] = metricOverview;

	const hasData = useMemo(() => tableRowsData && tableRowsData.length > 0, [tableRowsData]);

	useEffect(() => {
		if (!hasData) return;

		if (isSightfull2) {
			getMetricOverview();
			return;
		}

		if (isGenerativeV2Enabled) {
			getExplainV2();
		} else {
			getExplainV1();
		}
	}, [getExplainV1, getExplainV2, getMetricOverview, hasData, isGenerativeV2Enabled, isSightfull2]);

	if (isSightfull2) {
		return metricOverview;
	}

	return isGenerativeV2Enabled ? explainV2 : explainV1;
}

function useGenerativeExplainV1(): GenerativeExplain {
	const [getExplain, { data, loading, error }] = useGenerativeExplainBetaLazyQuery();
	const {
		metricNameWithFlavor,
		metricExplanationOneliner,
		chartOptions: { xAxis },
		filters,
		metricDisplayName,
		periodRange,
	} = useMetricDerivedState();
	const isOpenPeriod = useMemo(() => periodRange.asAbsoluteRange.endPeriod.isOpen, [periodRange]);
	const tableRowsData = useOverviewMetricTable();

	const getExplainCallback = useCallback(() => {
		getExplain({
			variables: {
				metricData: {
					metricFilters: filters.length > 0 ? getFiltersForQuery(filters) : undefined,
					metricOpenPeriod: isOpenPeriod ? xAxis.formatter(periodRange.asAbsoluteRange.endPeriod) : undefined,
					metricName: metricNameWithFlavor,
					metricDisplayName: metricDisplayName,
					metricDataTable: tableRowsData,
					metricOneLiner: metricExplanationOneliner,
				},
			},
		});
	}, [
		filters,
		getExplain,
		isOpenPeriod,
		metricDisplayName,
		metricExplanationOneliner,
		metricNameWithFlavor,
		periodRange,
		tableRowsData,
		xAxis,
	]);
	return [getExplainCallback, { data: data?.generative_explain_beta?.response, loading, error }];
}
function useGenerativeExplainV2(): GenerativeExplain {
	const [getExplain, { data, loading, error }] = useGenerativeExplainBetaV2LazyQuery();
	const {
		metricNameWithoutFlavor,
		flavor,
		metricExplanationOneliner,
		chartOptions: { xAxis },
		filters,
		breakdowns,
		metricDisplayName,
		periodRange,
	} = useMetricDerivedState();
	const getExplainInternalCallback = useCallback(getExplain, [getExplain]);
	const isOpenPeriod = useMemo(() => periodRange.asAbsoluteRange.endPeriod.isOpen, [periodRange]);

	const getExplainCallback = useCallback(() => {
		const selectedFlavor = flavor?.selectedValue;
		const periods = periodRange.asAbsoluteRange.toMetricPeriods().map((period) => period.id);
		const filterBy = filters.length > 0 ? getFiltersForQuery(filters) : undefined;
		const groupBy = breakdowns.values.length > 0 ? breakdowns.values.map((breakdown) => breakdown.key) : undefined;
		const openPeriod = isOpenPeriod ? xAxis.formatter(periodRange.asAbsoluteRange.endPeriod) : undefined;

		getExplainInternalCallback({
			variables: {
				metricData: {
					metricName: metricNameWithoutFlavor,
					flavor: selectedFlavor,
					periods,
					filterBy,
					groupBy,
					openPeriod,
					metricDisplayName: metricDisplayName,
					oneLiner: metricExplanationOneliner,
				},
			},
		});
	}, [
		getExplainInternalCallback,
		breakdowns.values,
		filters,
		flavor?.selectedValue,
		isOpenPeriod,
		metricDisplayName,
		metricExplanationOneliner,
		metricNameWithoutFlavor,
		periodRange,
		xAxis,
	]);
	return [getExplainCallback, { data: data?.generative_explain_beta_v2?.response, loading, error }];
}

function useMetricOverview(): GenerativeExplain {
	const [getExplain, { data, loading, error }] = useGenerativeMetricOverviewLazyQuery();
	const { metricNameWithFlavor, breakdowns, periodRange, filters } = useMetricDerivedState();

	const getExplainInternalCallback = useCallback(getExplain, [getExplain]);
	const openPeriod = useMemo(
		() => (periodRange.asAbsoluteRange.endPeriod.isOpen ? periodRange.asAbsoluteRange.endPeriod.id : undefined),
		[periodRange]
	);

	const getExplainCallback = useCallback(() => {
		const groupBy = breakdowns.values.map((breakdown) => addPrefixDollarSignIfNeeded(breakdown.key));
		const filterBy = buildFilters(filters);

		const variables: GenerativeMetricOverviewQueryVariables = {
			metricData: {
				openPeriod,
				metricName: metricNameWithFlavor,
				start: periodRange.asAbsoluteRange.startPeriod.id,
				end: periodRange.asAbsoluteRange.endPeriod.id,
				groupBy,
				filterBy,
			},
		};

		getExplainInternalCallback({
			variables,
		});
	}, [getExplainInternalCallback, breakdowns.values, filters, openPeriod, periodRange, metricNameWithFlavor]);
	return [getExplainCallback, { data: data?.generative_metric_overview?.response, loading, error }];
}

function useOverviewMetricTable() {
	const { displayUnits, chartOptions, displayedLegendItems, statisticsOperations, metricDisplayName } =
		useMetricDerivedState();

	const tableRowsData = useMemo(() => {
		const formatConfig: DataLabelFormatConfig = {
			decimalDigits: 2,
			displayUnits,
		};

		const { tableRowsData } = calcMetricTable(
			{ chartOptions, displayedLegendItems, statisticsOperations, metricDisplayName },
			formatConfig
		);
		// TODO: For guy to test raw infos to chat gpt
		if (sessionStorage.getItem('generativeAI.debugMode') == 'true') {
			console.log({
				tableRowsData,
			});
		}
		return tableRowsData;
	}, [chartOptions, displayUnits, displayedLegendItems, metricDisplayName, statisticsOperations]);
	return tableRowsData;
}
