import Box from '@components/Box';
import Flex from '@components/Flex';
import { Enter16 } from '@components/Icons';
import Spacer from '@components/Spacer';
import * as CSS from 'csstype';
import { ReactNode, useCallback } from 'react';
import Typography from '../Typography';

export function AskAIModalItem({
	selected = false,
	selectable = false,
	selectableIndex,
	borderTop,
	borderBottom,
	header,
	onHover,
	onClick,
	backgroundColor,
	padding,
	children,
}: {
	selected?: boolean;
	selectable?: boolean;
	selectableIndex?: number;
	borderTop?: boolean;
	borderBottom?: boolean;
	header?: string;
	padding?: string;
	onHover?: (index?: number) => void;
	onClick?: () => void;
	backgroundColor?: CSS.Property.Color;
	children: ReactNode;
}) {
	const selectedProps = {
		borderRadius: '4px',
		backgroundColor: 'blue.100',
	};

	const onMouseOver = useCallback(() => {
		if (selectable) onHover?.(selectableIndex);
	}, [onHover, selectable, selectableIndex]);

	const onMouseClick = useCallback(() => {
		if (selectable) onClick?.();
	}, [onClick, selectable]);

	return (
		<Box
			as={selectable ? 'button' : undefined}
			alignContent={'start'}
			flex={1}
			padding={padding ?? '8px'}
			minHeight={selectable ? '56px' : undefined}
			borderTop={borderTop ? '1px solid' : undefined}
			borderBottom={borderBottom ? '1px solid' : undefined}
			borderColor={borderTop || borderBottom ? 'gray.300' : undefined}
			backgroundColor={backgroundColor}
			onMouseOver={onMouseOver}
			onClick={onMouseClick}
		>
			<Flex
				padding={selectable ? '8px 16px 8px 16px' : undefined}
				{...(selected ? selectedProps : undefined)}
				alignItems={'center'}
			>
				{header ? (
					<Flex direction={'column'}>
						<Typography variant={'DesktopH10Regular'} textAlign="start" padding={'0 0 8px 0'}>
							{header}
						</Typography>
						{children}
					</Flex>
				) : (
					children
				)}
				{selected && (
					<>
						<Spacer />
						<Box color={'gray.700'}>
							<Enter16 />
						</Box>
					</>
				)}
			</Flex>
		</Box>
	);
}
