import Box from '@components/Box';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import React from 'react';
import NavigationDrawerFooter from 'src/layout/Menu/NavigationDrawer/NavDrawerFooter';
import NavigationDrawerHeader from 'src/layout/Menu/NavigationDrawer/NavDrawerHeader';
import { getIsNavDrawerViewCollection, NavDrawerView } from 'src/stores/utils';
import classes from './NavigationDrawer.module.scss';
import { TestIDs } from 'src/common/types/test-ids';

type NavigationDrawerInnerProps = {
	currentDrawerView?: string;
	navigationDrawerView: NavDrawerView;
	collectionItems: React.ReactNode;
	isVisible?: boolean;
};

const titleBySpaceType: Record<NavDrawerView, { title: string }> = {
	metrics: { title: 'Categories' },
	dashboard: { title: 'Dashboards' },
	workspace: { title: 'Workspaces' },
};

function NavigationDrawerInner({
	currentDrawerView,
	navigationDrawerView,
	collectionItems,
	isVisible = true,
}: NavigationDrawerInnerProps) {
	const title = titleBySpaceType[navigationDrawerView]?.title;

	return (
		<>
			<Flex
				data-testid={currentDrawerView}
				h="100%"
				justifyContent="flex-start"
				className={`${classes.navigationDrawer} ${isVisible ? classes.isVisible : ''}`}
				flexDir="column"
				minHeight="100%"
				overflow="hidden"
				zIndex="1"
			>
				<NavigationDrawerHeader>
					<Typography variant="Paragraph14SB" color="gray.700">
						{title}
					</Typography>
				</NavigationDrawerHeader>
				<Box
					overflowX="hidden"
					overflowY="auto"
					pl="8px"
					pb="55px"
					data-testid={TestIDs.SIDEBAR_ITEMS(currentDrawerView)}
				>
					{collectionItems}
				</Box>
				{getIsNavDrawerViewCollection(navigationDrawerView) ? (
					<NavigationDrawerFooter collectionType={navigationDrawerView} />
				) : null}
			</Flex>
		</>
	);
}

export default NavigationDrawerInner;
