import * as monaco from 'monaco-editor';
import { useEffect, useRef } from 'react';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import { MonacoRelationshipCompletionItemProviderBuilder } from '../completionProvider/monacoRelationshipCompletionProvider';
import { OntologyBuilderYamlCompletionItemProviderBuilder } from '../completionProvider/ontologyBuilderYamlCompletionProvider';
import { OntologyBuilderYamlHoverProviderBuilder } from '../hoverProvider/ontologyBuilderYamlHoverProvider';

export function useOntologyBuilderYamlProviders(entity: string) {
	const completionProviderRef = useRef<null | monaco.IDisposable>(null);
	const hoverProviderRef = useRef<null | monaco.IDisposable>(null);
	const { semanticDefinitions } = useSemanticDefinitions();

	useEffect(() => {
		if (!semanticDefinitions) return;
		completionProviderRef.current?.dispose();
		completionProviderRef.current = monaco.languages.registerCompletionItemProvider(
			'yaml',
			OntologyBuilderYamlCompletionItemProviderBuilder(semanticDefinitions, entity)
		);
		hoverProviderRef.current?.dispose();
		hoverProviderRef.current = monaco.languages.registerHoverProvider('yaml', {
			provideHover: OntologyBuilderYamlHoverProviderBuilder(semanticDefinitions, entity),
		});
		return () => {
			completionProviderRef.current?.dispose();
			hoverProviderRef.current?.dispose();
		};
	}, [entity, semanticDefinitions]);
}

export function useRelationshipBuilderYamlProviders(entity: string) {
	const completionProviderRef = useRef<null | monaco.IDisposable>(null);
	const hoverProviderRef = useRef<null | monaco.IDisposable>(null);
	const { semanticDefinitions } = useSemanticDefinitions();

	useEffect(() => {
		if (!semanticDefinitions) return;
		completionProviderRef.current?.dispose();
		completionProviderRef.current = monaco.languages.registerCompletionItemProvider(
			'yaml',
			MonacoRelationshipCompletionItemProviderBuilder(semanticDefinitions, entity)
		);
		hoverProviderRef.current?.dispose();
		hoverProviderRef.current = monaco.languages.registerHoverProvider('yaml', {
			provideHover: OntologyBuilderYamlHoverProviderBuilder(semanticDefinitions, entity),
		});
		return () => {
			completionProviderRef.current?.dispose();
			hoverProviderRef.current?.dispose();
		};
	}, [entity, semanticDefinitions]);
}
