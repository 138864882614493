import { Box } from '@chakra-ui/react';
import { AccessController } from 'src/common/components/AccessController';
import Divider from 'src/common/components/Divider';
import {
	BinSolid16,
	DashboardSpace16,
	DuplicateSolid16,
	PenOutline16,
	ArrowUpRight,
} from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import useUser from 'src/common/hooks/stores/useUser';
import { Permission, Permissions } from 'src/types/environment';
import { useCollectionsSubscriptions } from '@hooks/fetching/useCollectionsSubscriptions';
import { SearchDropdown } from '@components/SearchDropdown';
import Popover from '@components/Popover';
import { FeedSignal } from 'src/types/spaces';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { useRef, RefObject, useMemo } from 'react';

export function SignalWidgetMenu({
	authorId,
	onEdit,
	onDuplicate,
	onRename,
	onDelete,
	canExplore,
	onMove,
	isAdditionalMenuOpen,
	setIsAdditionalMenuOpen,
	feedSignal,
	onAdditionalMenuButtonClick,
}: {
	authorId?: string;
	onEdit?: VoidFunction;
	onDuplicate?: VoidFunction;
	onRename?: VoidFunction;
	onDelete?: VoidFunction;
	canExplore: boolean;
	onMove?: ({ title, id }: { title: string; id: string }) => void;
	isAdditionalMenuOpen: boolean;
	setIsAdditionalMenuOpen: (state: boolean) => void;
	feedSignal?: FeedSignal;
	onAdditionalMenuButtonClick?: VoidFunction;
}) {
	const [{ id: userId }] = useUser();

	const permissionsForCrud: Permission[] = [Permissions.updateAllSignals];
	if (authorId == userId) permissionsForCrud.push(Permissions.crudMySignals);
	const { rawDashboardsList } = useCollectionsSubscriptions();
	const initialFocusRef = useRef() as RefObject<{ focus(): void }>;
	const filteredDashboards = useMemo(() => {
		return rawDashboardsList?.workspaces
			?.filter(({ id }) => id !== feedSignal?.feed_id)
			.map(({ name, id }) => ({ title: name, id }));
	}, [feedSignal?.feed_id, rawDashboardsList?.workspaces]);

	return (
		<>
			{canExplore && (
				<Box>
					<ListItem
						color="gray.1000"
						hoverColor={'blue.100'}
						label="Explore Signal"
						size="sm"
						prefixIcon={<DashboardSpace16 />}
						onClick={onEdit}
					/>
				</Box>
			)}
			<AccessController permission={Permissions.crudMySignals}>
				<Popover
					triggerElement={
						<ListItem
							color="gray.1000"
							hoverColor={'blue.100'}
							label="Move to..."
							size="sm"
							prefixIcon={<ArrowUpRight />}
							onClick={() => setIsAdditionalMenuOpen(true)}
							state={isAdditionalMenuOpen ? 'selected' : 'enabled'}
						/>
					}
					isOpen={isAdditionalMenuOpen}
					placement={'right-start'}
					onClose={() => setIsAdditionalMenuOpen(false)}
					popoverContentProps={{
						borderRadius: '8px',
						bottom: '45px',
					}}
					initialFocusRef={initialFocusRef}
				>
					<SearchDropdown
						options={filteredDashboards}
						onOptionClick={(option) => onMove?.(option)}
						placeholder={'Search destination dashboard'}
						bottomButton={
							<Flex
								height={'44px'}
								justifyContent={'center'}
								alignItems={'center'}
								backgroundColor={'gray.200'}
								cursor={'pointer'}
								_hover={{ backgroundColor: 'gray.300' }}
								onClick={onAdditionalMenuButtonClick}
								borderBottomLeftRadius={'7px'}
								borderBottomRightRadius={'7px'}
							>
								<Typography variant={'DesktopH8Medium'} color={'gray.1000'} cursor={'pointer'}>
									Create new dashboard
								</Typography>
							</Flex>
						}
						initialFocusRef={initialFocusRef}
					/>
				</Popover>
			</AccessController>
			<Divider direction={'horizontal'} marginY="8px" />
			<AccessController permission={permissionsForCrud} logic="OR">
				<ListItem
					color="gray.1000"
					hoverColor={'blue.100'}
					label="Rename"
					size="sm"
					prefixIcon={<PenOutline16 />}
					onClick={onRename}
				/>
			</AccessController>
			<AccessController permission={Permissions.crudMySignals}>
				<ListItem
					color="gray.1000"
					hoverColor={'blue.100'}
					label="Duplicate"
					size="sm"
					prefixIcon={<DuplicateSolid16 />}
					onClick={onDuplicate}
				/>
			</AccessController>
			<AccessController permission={permissionsForCrud} logic="OR">
				<Divider direction={'horizontal'} marginY="8px" />
				<ListItem
					color="gray.1000"
					hoverColor={'blue.100'}
					label="Delete"
					size="sm"
					prefixIcon={<BinSolid16 />}
					onClick={onDelete}
				/>
			</AccessController>
		</>
	);
}
