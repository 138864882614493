import { CST, parseDocument, Parser } from 'yaml';
import {
	AvailableParameter,
	EnrichedFormulaMetric,
	EnrichedMetric,
	EnrichedSemanticDefinitions,
	EntityWithMetrics,
	isFormulaMetric,
	LoadedDimension,
	LoadedRelationship,
} from './semanticTypes';
import { DimensionType } from './semanticTypes/normalization.schema';
import { getEntity, getMetricByNameFromList } from './utils/utils';

// As long as we keep this file without any references to Monaco, we can use it in VSCode as is
// Perhaps we should move it (with the types) to a shared library and share it with core?

export class CompletionProvider {
	public static readonly EXPRESSION = /[.\w_]+/;
	public static readonly EXPRESSION_PART = /[\w_]+/;

	constructor(private semanticDefinitions: EnrichedSemanticDefinitions) {}

	public getTokenPath(documentText: string, offset: number): CST.Token[] | undefined {
		const parser = new Parser().parse(documentText);
		// Build us a path to the current offset in the document,
		// so we can figure out what completions to generate
		return this.findCurrentTokenPath(parser, offset);
	}

	public isOntologyContext(documentText: string): boolean {
		return parseDocument(documentText).has('data_source');
	}

	public createStartingContext(path: CST.Token[], documentText: string): Context[] {
		if (this.isOntologyContext(documentText)) {
			const entityName = this.findDocumentValueByKey(path, 'name');
			if (!entityName) {
				return [];
			}
			const entity = getEntity(this.semanticDefinitions, entityName);
			if (!entity) {
				return [];
			}
			return this.createContexts({ entity, includingTypes: [...DimensionContextTypes, 'relationship'] });
		}

		const metricName = this.findDocumentValueByKey(path, 'name');
		const metricEntity = this.findDocumentValueByKey(path, 'entity');
		if (metricName && metricEntity) {
			const hasKeyFormula = this.findDocumentValueByKey(path, 'formula') != undefined;
			const hasKeyOperation = this.findDocumentValueByKey(path, 'operation') != undefined;

			if (hasKeyOperation && !hasKeyFormula) {
				return this.createMetricStartContext(path, metricName, metricEntity);
			}

			if (hasKeyFormula && !hasKeyOperation) {
				return this.createFormulaMertricStartContext(metricName, metricEntity);
			}
		}

		console.debug('Failed to find context for path', path);
		return [];
	}
	createFormulaMertricStartContext(formulaMetricName: string, metricEntityName: string): Context[] {
		const metric = getMetricByNameFromList(this.semanticDefinitions.formula_metrics, formulaMetricName);
		const metricEntity = getEntity(this.semanticDefinitions, metricEntityName);
		if (metricEntity) {
			return this.createContexts({
				entity: metricEntity,
				metric,
				includingTypes: [
					...DimensionContextTypes,
					...MetricContextTypes,
					'relationship',
					'parameter',
					'parameter_store',
				],
			});
		}
		return [];
	}
	createMetricStartContext(path: CST.Token[], metricName: string, metricEntityName: string): Context[] {
		const metric = getMetricByNameFromList(this.semanticDefinitions.metrics, metricName);
		const metricEntity = getEntity(this.semanticDefinitions, metricEntityName);
		const selectName = this.findValueByParentOfChild(path, 'joins', 'from');
		const lastYamlKey = path[path.length - 2];
		if (!metricEntity) {
			console.error('Failed to find metric entity', metricName);
			return [];
		}

		if (CST.isScalar(lastYamlKey) && ['select', 'sql'].includes(lastYamlKey.source) && selectName) {
			// This is a select: and we have the name
			// PUL-3842: Current YAML may have different metric relationships than the semantic definitions
			const referenced_entity_name = metricEntity.relationships?.find(
				(rel) => rel.name === selectName
			)?.referenced_entity;
			if (!referenced_entity_name) {
				console.error('Failed to find relationship', selectName, 'on', metricEntity.name);
				return [];
			}

			const entity = getEntity(this.semanticDefinitions, referenced_entity_name);
			if (!entity) return [];
			return this.createContexts({
				entity,
				metric,
				includingTypes: [
					...DimensionContextTypes,
					...MetricContextTypes,
					'relationship',
					'parameter',
					'parameter_store',
				],
			});
		}

		if (metricEntity) {
			return this.createContexts({
				entity: metricEntity,
				metric,
				includingTypes: [
					...DimensionContextTypes,
					...MetricContextTypes,
					'relationship',
					'parameter',
					'parameter_store',
				],
			});
		}
		console.info('Failed to create context for metric', metricName);
		return [];
	}

	private findValueByParentOfChild(path: CST.Token[], parentKey: string, childKey: string): string | undefined {
		const parentTokenIndex = path.findIndex(
			(token) => CST.isScalar(token) && CST.resolveAsScalar(token)?.value === parentKey
		);

		if (parentTokenIndex == -1) {
			return;
		}

		const childMapBlock = path[parentTokenIndex + 2];
		if (childMapBlock.type != 'block-map') {
			return;
		}

		const childItem = childMapBlock.items.find((item) => CST.resolveAsScalar(item.key)?.value === childKey);
		if (!childItem) return;

		return CST.resolveAsScalar(childItem.value)?.value;
	}

	findDocumentValueByKey(path: CST.Token[], key: string): string | undefined {
		const blockPath = path.find((item) => item.type == 'block-map');
		if (!blockPath || blockPath.type != 'block-map') return;
		const keyScalar = blockPath.items?.find((k) => CST.isScalar(k.key) && k.key.source == key)?.value;
		const keyValue = CST.resolveAsScalar(keyScalar)?.value;

		return keyValue;
	}

	findDocumentValueByKeyFirstLevel(document: string, key: string): string | undefined {
		const documentTokens = parseDocument(document);
		if (documentTokens.has(key)) return `${documentTokens.get(key)}`;
	}

	public walkContextForCompletions(
		context: Context[],
		expression: string,
		distance: number,
		isIncludingMetrics: boolean,
		isIncludingMultitraversal: boolean = false
	): Context[] {
		const firstExpressionPart = expression.match(CompletionProvider.EXPRESSION_PART)?.[0];

		const isLastExpressionPart = !firstExpressionPart || firstExpressionPart.length >= distance;
		if (isLastExpressionPart) {
			return context;
		}
		const nextContext = context.find((context) => {
			return context.keyword === firstExpressionPart;
		});

		const isParameterStoreContext = nextContext?.type === 'parameter_store';
		const isRelationshipContext = nextContext?.type === 'relationship';

		if (nextContext?.entity || isParameterStoreContext) {
			const getNextContextsTypes = (): ContextType[] | undefined => {
				if (isRelationshipContext) {
					const includedTypes: ContextType[] = [...DimensionContextTypes];
					if (isIncludingMultitraversal) includedTypes.push('relationship');
					if (isIncludingMetrics) includedTypes.push(...MetricContextTypes);
					return includedTypes;
				}
				const includedTypes: ContextType[] = [...DimensionContextTypes, 'relationship', 'parameter'];
				if (isIncludingMetrics) includedTypes.push(...MetricContextTypes);
				if (!isParameterStoreContext) includedTypes.push('parameter_store');
				return includedTypes;
			};
			return this.walkContextForCompletions(
				this.createContexts({
					entity: nextContext.entity,
					metric: nextContext.metric,
					includingTypes: getNextContextsTypes(),
				}),
				expression.substring(firstExpressionPart.length + 1), // Past the next dot
				distance - firstExpressionPart.length - 1, // Minus the dot
				isIncludingMetrics,
				isIncludingMultitraversal
			);
		}

		return [];
	}

	public walkContextForHover(
		contexts: Context[],
		expression: string,
		distance: number,
		isIncludingMetrics: boolean
	): Context[] {
		const firstExpressionPart = expression.match(CompletionProvider.EXPRESSION_PART)?.[0];
		if (!firstExpressionPart || firstExpressionPart.length >= distance) {
			return contexts;
		}
		const nextExpressionPart = expression.substring(firstExpressionPart.length + 1);
		const hasNextExpressionPart = nextExpressionPart.match(CompletionProvider.EXPRESSION_PART)?.[0];

		if (!hasNextExpressionPart) {
			return contexts;
		}
		const nextContext = contexts.find((context) => {
			return context.keyword === firstExpressionPart;
		});

		if (!nextContext?.entity) {
			return [];
		}

		return this.walkContextForHover(
			this.createContexts({
				entity: nextContext.entity,
				metric: nextContext.metric,
				includingTypes: isIncludingMetrics
					? undefined
					: [...DimensionContextTypes, 'parameter', 'parameter_store', 'function', 'relationship', 'join'],
			}),
			nextExpressionPart,
			distance - firstExpressionPart.length - 1,
			isIncludingMetrics
		);
	}

	public walkContextToDefinition(context: Context[], expression: string, distance: number): Context | undefined {
		const firstExpressionPart = expression.match(CompletionProvider.EXPRESSION_PART)?.[0];
		if (!firstExpressionPart) {
			return;
		}

		const nextContext = context.find((context) => {
			return context.keyword === firstExpressionPart;
		});
		const isLastExpressionPart = firstExpressionPart.length >= distance;

		if (!nextContext?.entity || isLastExpressionPart) {
			return nextContext;
		}

		return this.walkContextToDefinition(
			this.createContexts({ entity: nextContext.entity, metric: nextContext.metric }),
			expression.substring(firstExpressionPart.length + 1), // Past the next dot
			distance - firstExpressionPart.length - 1 // Minus the dot
		);
	}

	public createContexts({
		entity,
		metric,
		includingTypes = [...AllContextTypes],
	}: {
		entity?: EntityWithMetrics;
		metric?: EnrichedMetric | EnrichedFormulaMetric;
		includingTypes?: ContextType[];
	}): Context[] {
		const contexts: Context[] = [];

		contexts.push(
			...(entity?.dimensions.map((dimension) => ({
				type: contextTypeFromDimensionType[dimension.type],
				name: dimension.name,
				keyword: dimension.name,
				location: dimension,
				display_name: dimension.meta?.display_name,
				description: dimension.meta?.description,
				parent_entity: entity,
			})) ?? [])
		);

		contexts.push(
			...(entity?.relationships?.map((relationship) => {
				const referenced_entity = getEntity(this.semanticDefinitions, relationship.referenced_entity);
				return {
					type: 'relationship',
					name: relationship.name,
					keyword: relationship.name,
					entity: referenced_entity,
					location: relationship,
					display_name: relationship.meta?.display_name,
					description: relationship.meta?.description,
					parent_entity: entity,
				} as Context;
			}) ?? [])
		);

		contexts.push(
			...(entity?.metrics
				.filter((entity_metric) => entity_metric.name !== metric?.name)
				.map(
					(entity_metric): Context => ({
						type: 'metric',
						name: entity_metric.name,
						keyword: 'metric__' + entity_metric.name,
						metric: entity_metric,
						entity: entity,
						location: entity_metric,
						display_name: entity_metric.meta?.display_name,
						description: entity_metric.meta?.description,
						parent_entity: entity,
					})
				) ?? [])
		);

		contexts.push(
			...(entity?.formula_metrics
				.filter((entity_metric) => entity_metric.name !== metric?.name)
				.map(
					(entity_metric): Context => ({
						type: 'formula_metric',
						name: entity_metric.name,
						keyword: 'metric__' + entity_metric.name,
						metric: entity_metric,
						entity: entity,
						location: entity_metric,
						display_name: entity_metric.meta?.display_name,
						description: entity_metric.meta?.description,
						parent_entity: entity,
					})
				) ?? [])
		);

		const parameterStoreContext: ParameterStoreContext = {
			type: 'parameter_store',
			name: 'parameter',
			keyword: 'parameter',
		};
		contexts.push(parameterStoreContext);
		const { parameters } = this.semanticDefinitions;
		contexts.push(
			...parameters.map(
				(parameter): ParameterContext => ({
					type: 'parameter',
					name: parameter.name,
					keyword: includingTypes.includes('parameter_store') ? 'parameter.' + parameter.name : parameter.name,
					location: parameter,
					description: parameter.description,
					display_name: parameter.label ?? parameter.name,
				})
			)
		);

		contexts.push(
			...this.semanticDefinitions.entities.map(
				(e): EntityContext => ({
					keyword: e.name,
					type: 'entity',
					name: e.name,
					entity: e,
				})
			)
		);

		if (metric === undefined || isFormulaMetric(metric)) {
			return contexts.filter((context) => includingTypes.includes(context.type));
		}

		contexts.push(
			...(metric.joins ?? []).flatMap((join): Context[] => {
				const referencedEntityName = entity?.relationships?.find((rel) => rel.name === join.from)?.referenced_entity;
				if (!referencedEntityName) {
					console.error('Failed to resolve entity for join', join.from, entity);
					return [];
				}
				const referencedEntity = getEntity(this.semanticDefinitions, referencedEntityName);
				return [
					{
						keyword: join.alias,
						name: join.alias,
						type: 'join',
						entity: referencedEntity,
						parent_entity: referencedEntity,
						description: join.join_type + ' selecting ' + join.select,
					},
				];
			})
		);

		return contexts.filter((context) => includingTypes.includes(context.type));
	}

	public findCurrentTokenPath(
		root: Generator<CST.Token, void, unknown>,
		targetOffset: number
	): CST.Token[] | undefined {
		for (const rootToken of root) {
			const results = this.investigateToken(targetOffset, [rootToken]);
			if (results) {
				return results;
			}
		}
	}

	public investigateToken(targetOffset: number, tokenPath: CST.Token[]): CST.Token[] | undefined {
		const currentToken = tokenPath[tokenPath.length - 1];

		if (currentToken.offset >= targetOffset) {
			return tokenPath;
		}

		if (currentToken.type == 'document' && currentToken.value) {
			return this.investigateToken(targetOffset, [...tokenPath, currentToken.value]);
		} else if (CST.isCollection(currentToken)) {
			for (const item of currentToken.items) {
				const newPath = [...tokenPath, ...(item.key ? [item.key] : []), ...(item.value ? [item.value] : [])];
				const results = this.investigateToken(targetOffset, newPath);
				if (results) {
					return results;
				}
			}
		} else if (CST.isScalar(currentToken)) {
			const scalar = CST.resolveAsScalar(currentToken);
			const isScalarOutOfRange = scalar.range[2] > targetOffset;
			if (isScalarOutOfRange) {
				return tokenPath;
			}
		} else {
			console.error('Unknown token', currentToken);
		}
	}
}

export function createMarkdownForDefinition(context: Context): string {
	return `
	${context.display_name ?? context.name}
	<br />
	${context.type}
	<br />
	${context.keyword}
	<br />
	${context.description}
	<hr />
	`;
}
export const contextTypeFromDimensionType: { [key in DimensionType]: DimensionContext['type'] } = {
	string: 'dimension',
	number: 'dimension_numeric',
	bool: 'dimension_boolean',
	boolean: 'dimension_boolean',
	date: 'dimension_date',
	timestamp: 'dimension_date',
	timestampz: 'dimension_date',
	timestamptz: 'dimension_date',
};

export const DimensionContextTypes = ['dimension', 'dimension_numeric', 'dimension_date', 'dimension_boolean'] as const;
interface BaseContext {
	type: string;
	name: string;
	keyword: string;
	display_name?: string;
	description?: string;
	parent_entity?: EntityWithMetrics;
	entity?: EntityWithMetrics;
	metric?: EnrichedFormulaMetric | EnrichedMetric;
	location?: EnrichedFormulaMetric | EnrichedMetric | LoadedDimension | LoadedRelationship | AvailableParameter;
}
export const MetricContextTypes = ['metric', 'formula_metric'] as const;

interface MetricContext extends BaseContext {
	type: typeof MetricContextTypes[number];
	location: EnrichedFormulaMetric | EnrichedMetric;
	metric: EnrichedFormulaMetric | EnrichedMetric;
}
export interface DimensionContext extends BaseContext {
	type: typeof DimensionContextTypes[number];
	location?: LoadedDimension;
	parent_entity?: EntityWithMetrics;
}
export interface RelationshipContext extends BaseContext {
	type: 'relationship';
	location?: LoadedRelationship;
	parent_entity: EntityWithMetrics;
	entity: EntityWithMetrics;
}
export interface EntityContext extends BaseContext {
	type: 'entity';
	entity: EntityWithMetrics;
}
export interface ParameterContext extends BaseContext {
	type: 'parameter';
	location: AvailableParameter;
}
interface ParameterStoreContext extends BaseContext {
	type: 'parameter_store';
}
interface OtherContexts extends BaseContext {
	type: 'join' | 'function';
}
export type Context =
	| MetricContext
	| DimensionContext
	| RelationshipContext
	| EntityContext
	| ParameterContext
	| ParameterStoreContext
	| OtherContexts;
export type ContextType = Context['type'];
export const AllContextTypes = [
	...MetricContextTypes,
	...DimensionContextTypes,
	'relationship',
	'entity',
	'parameter',
	'parameter_store',
	'join',
	'function',
] as const;

export const functionContexts: Context[] = [
	{
		type: 'function',
		name: 'sum',
		keyword: 'sum',
		display_name: 'SUM',
		description: 'Sums the `measure` values',
	},
	{
		type: 'function',
		name: 'min',
		keyword: 'min',
		display_name: 'MIN',
		description: 'Finds the minimum value.',
	},
	{
		type: 'function',
		name: 'max',
		keyword: 'max',
		display_name: 'MAX',
		description: 'Finds the maximum value.',
	},
	{
		type: 'function',
		name: 'count',
		keyword: 'count',
		display_name: 'COUNT',
		description: 'Counts the number of `entities`.',
	},
	{
		type: 'function',
		name: 'period_in',
		keyword: 'period_in',
		display_name: 'Period In',
		description: 'A single date `dimension` of the `entity` is within the `period`.',
	},
	{
		type: 'function',
		name: 'null_if',
		keyword: 'null_if',
		display_name: 'Null If',
		description:
			'The NULLIF() functions returns NULL if two expressions are equal, otherwise it returns the first expression.',
	},
	{
		type: 'function',
		name: 'DATE_PART',
		keyword: 'DATE_PART',
		display_name: 'Date Part',
		description:
			'The DATEPART() function returns a specified part of a date. This function returns the result as an integer value.',
	},
];

export const functionContextsWithInvisible: Context[] = [
	...functionContexts,
	{
		type: 'function',
		name: 'macros',
		keyword: 'macros',
		display_name: 'macros',
		description: 'macros',
	},
	{
		type: 'function',
		name: 'PERIOD_IN',
		keyword: 'PERIOD_IN',
		display_name: 'PERIOD_IN',
		description: 'PERIOD_IN',
	},
	{
		type: 'function',
		name: 'period',
		keyword: 'period',
		display_name: 'period',
		description: 'period',
	},
	{
		type: 'function',
		name: 'end',
		keyword: 'end',
		display_name: 'end',
		description: 'end',
	},
	{
		type: 'function',
		name: 'start',
		keyword: 'start',
		display_name: 'start',
		description: 'start',
	},
];
