import Typography from 'src/common/components/Typography';
import { ExampleText, LearnMore } from './common';

const MEASURE_LINK = 'https://docs.sightfull.com/core/metrics/syntax/measure';

export function MeasureTooltip({ onHelpClicked }: { onHelpClicked: () => void }) {
	return (
		<Typography color={'white'} variant="Paragraph14R">
			A single row&apos;s contribution to the metric, for each entity that meets the metric criteria (filters).&nbsp;
			<LearnMore onClick={onHelpClicked} link={MEASURE_LINK} />.
			<ExampleText marginTop={'16px'} text={'$amount'} />
			<ExampleText marginTop={'8px'} marginBottom={'4px'} text={'$amount * $probability'} />
			<ExampleText marginTop={'8px'} marginBottom={'4px'} text={'SUM($line_items.amount)'} />
		</Typography>
	);
}
