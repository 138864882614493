import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import classes from './Markdown.module.scss';

export function Markdown({ message }: { message: string }) {
	return (
		<ReactMarkdown remarkPlugins={[remarkGfm]} className={classes.markdown}>
			{message}
		</ReactMarkdown>
	);
}
