import { Grid, GridItem, useDisclosure } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import Divider from 'src/common/components/Divider';
import { ConfigurationPermissions, IField, IFields, IFieldValue } from '../../interfaces';
import { getIsDisabledPerField } from '../../utilities';
import BaseField from '../BaseField/BaseField';
import { EditModeFieldComponents } from '../EditModeFieldComponents/EditModeFieldComponents';
import LongDescriptionModal from '../LongDescriptionModal/LongDescriptionModal';
import { ViewModeFieldComponents } from '../ViewModeFieldComponents/ViewModeFieldComponents';

const objectTypeToComponent = {
	Edit: EditModeFieldComponents,
	View: ViewModeFieldComponents,
} as const;

export type FieldsProps = {
	configName: string;
	fields: IFields;
	isHasEditPermission: ConfigurationPermissions;
	values: { [key: string]: Partial<IField> } | null;
	onChange: (key: string, value: IFieldValue | undefined) => void;
};

export type LongDescriptionProps = {
	title: string;
	longDescription: string;
};

const Fields = ({ fields, values, onChange, isHasEditPermission, configName }: FieldsProps) => {
	const [longDescriptionData, setLongDescriptionData] = useState<LongDescriptionProps>({
		title: '',
		longDescription: '',
	});
	const {
		isOpen: isOpenLongDescription,
		onOpen: onOpenLongDescription,
		onClose: onCloseLongDescription,
	} = useDisclosure();

	const sortedFields = useMemo(
		() =>
			Object.entries(fields).sort(
				([, { displayOrder }], [, { displayOrder: displayOrder2 }]) => displayOrder - displayOrder2
			),
		[fields]
	);

	const isDisabledPerField: { [key: string]: boolean } | null = useMemo(() => {
		if (!values || !fields) {
			return null;
		}

		return getIsDisabledPerField(fields, values);
	}, [fields, values]);

	const onOpen = (longDescriptionProps: LongDescriptionProps) => {
		setLongDescriptionData(longDescriptionProps);
		onOpenLongDescription();
	};

	return (
		<>
			{sortedFields.map(([key, fieldData], index) => {
				const FieldComponent = objectTypeToComponent[isHasEditPermission][fieldData.type];

				return (
					<Grid pt={'24px'} key={key} gridTemplateColumns="repeat(12, [col-start] 1fr)">
						<GridItem colSpan={{ base: 12, sm: 6 }}>
							<BaseField
								configName={configName}
								onOpenLongDescription={() =>
									onOpen({ title: fieldData.title, longDescription: fieldData.longDescription ?? '' })
								}
								isDisabled={(isHasEditPermission === 'Edit' && isDisabledPerField?.[key]) ?? false}
								field={fieldData}
							/>
						</GridItem>
						<GridItem
							display={'flex'}
							alignItems={isHasEditPermission === 'Edit' ? 'center' : 'start'}
							justifyContent={{
								base: 'flex-start',
								sm: 'flex-end',
							}}
							pt={{
								base: '16px',
								sm: '0px',
							}}
							colSpan={{ base: 12, sm: 6 }}
						>
							<FieldComponent
								fieldKey={key}
								field={fieldData}
								handleChange={onChange}
								value={values?.[key]?.selectedValue ?? ''}
								isDisabled={isDisabledPerField?.[key] ?? true}
							/>
						</GridItem>

						{index != sortedFields.length - 1 && (
							<GridItem pt={'24px'} colSpan={12}>
								<Divider direction={'horizontal'} />
							</GridItem>
						)}
					</Grid>
				);
			})}
			<LongDescriptionModal
				title={longDescriptionData.title}
				description={longDescriptionData.longDescription}
				isOpen={isOpenLongDescription}
				onClose={onCloseLongDescription}
			/>
		</>
	);
};

export default Fields;
