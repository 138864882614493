import { useCallback, useEffect } from 'react';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import {
	GetSpecificMetricDefinitionLazyQueryHookResult,
	useGetSpecificMetricDefinitionLazyQuery,
} from 'src/generated/graphql';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricEditorState } from 'src/pages/MetricPage/hooks/useMetricEditorState';
import { MetricType } from 'src/common/types/common';
import { MetricDefinitionState } from 'src/pages/MetricPage/utils/editor.types';

export const UNTITLED_METRIC_NAME = 'untitled';
export const UNTITLED_METRIC_DISPLAY = 'Untitled';
const BASE_METRIC_EMPTY_YAML = [`name: ${UNTITLED_METRIC_NAME}`, 'entity: ""'] as const;
const BASE_METRIC_META = ['meta:', `  display_name: ${UNTITLED_METRIC_DISPLAY}`] as const;
export const AGGREGATE_METRIC_EMPTY_YAML = [
	...BASE_METRIC_EMPTY_YAML,
	'operation: count',
	...BASE_METRIC_META,
] as const;
export const FORMULA_METRIC_EMPTY_YAML = [...BASE_METRIC_EMPTY_YAML, 'formula: ""', ...BASE_METRIC_META] as const;

export function useMetricBuilder({
	emptyMetricKind,
}: {
	emptyMetricKind?: MetricType;
} = {}) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	const { metricNameWithFlavor } = useMetricDerivedState();

	const { setMetricEditorState } = useMetricEditorState();

	const [getSpecificMetricDefinition] = useGetSpecificMetricDefinitionLazyQuery({
		variables: { metricName: metricNameWithFlavor },
	});

	const loadMetricDefinition = useCallback(
		(res: GetSpecificMetricDefinitionLazyQueryHookResult['1']) => {
			const editorValue = res?.data?.getSpecificMetricDefinition?.metricDefinition;
			const metricType = res?.data?.getSpecificMetricDefinition?.metricType;
			if (!isMetricType(metricType)) return;
			const value = editorValue ? editorValue?.join('\n') : '';
			setMetricEditorState({
				isLoading: false,
				previewValue: '',
				savedValue: value,
				kind: metricType,
			});
		},
		[setMetricEditorState]
	);

	const loadEmptyMetricState = useCallback(() => {
		if (!emptyMetricKind) return;
		const emptyMetric = emptyMetricKind === 'formula' ? FORMULA_METRIC_EMPTY_YAML : AGGREGATE_METRIC_EMPTY_YAML;
		const savedValue = emptyMetric.join('\n');
		setMetricEditorState({
			isLoading: false,
			previewValue: '',
			savedValue,
			kind: emptyMetricKind,
		});
	}, [setMetricEditorState, emptyMetricKind]);

	useEffect(() => {
		if (!isSightfull2) return;
		if (emptyMetricKind) return;
		setMetricEditorState({ isLoading: true });
		if (metricNameWithFlavor) {
			getSpecificMetricDefinition().then(loadMetricDefinition);
		}
	}, [
		metricNameWithFlavor,
		loadMetricDefinition,
		getSpecificMetricDefinition,
		setMetricEditorState,
		loadEmptyMetricState,
		emptyMetricKind,
		isSightfull2,
	]);

	useEffect(() => {
		if (!emptyMetricKind) return;
		setMetricEditorState({ isLoading: true });
		loadEmptyMetricState();
	}, [emptyMetricKind, loadEmptyMetricState, setMetricEditorState]);
}

function isMetricType(value?: string): value is MetricType {
	return value === 'formula' || value === 'aggregate';
}

export function isUntitledMetric(state?: Partial<Pick<MetricDefinitionState, 'name' | 'meta'>> | null) {
	const { name, meta } = state ?? {};
	return name === UNTITLED_METRIC_NAME || meta?.display_name === UNTITLED_METRIC_DISPLAY;
}
