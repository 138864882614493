import colors from 'src/style/colors';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { AICPA44, ISO44, Key24, ReadonlyEye24, ShieldCheck16 } from '@icons/index';

export function DataConnectorsModalSecurityInfo() {
	return (
		<Flex
			paddingX={'24px'}
			paddingY={'20px'}
			justifyContent={'space-between'}
			alignItems={'center'}
			alignSelf={'stretch'}
			borderRadius={'8px'}
			backgroundColor={colors.blue['100']}
		>
			<Flex display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gap={'12px'}>
				<Typography variant={'DesktopH7Medium'} color={colors.gray['1000']}>
					We are commited to security.
				</Typography>
				<Flex width={'579px'} height={'20px'} alignItems={'flex-start'} gap={'20px'}>
					<Flex justifyContent={'center'} alignItems={'center'} gap={'8px'}>
						<Key24 color={colors.gray['1000']} />
						<Typography variant={'DesktopH8Regular'} color={colors.gray['1000']} textAlign={'center'}>
							End-to-end encryption
						</Typography>
					</Flex>
					<Flex justifyContent={'center'} alignItems={'center'} gap={'8px'}>
						<ReadonlyEye24 color={colors.gray['1000']} />
						<Typography variant={'DesktopH8Regular'} color={colors.gray['1000']} textAlign={'center'}>
							Read-only access
						</Typography>
					</Flex>
					<Flex justifyContent={'center'} alignItems={'center'} gap={'8px'}>
						<ShieldCheck16 color={colors.gray['1000']} />
						<Typography variant={'DesktopH8Regular'} color={colors.gray['1000']} textAlign={'center'}>
							Strict control policies
						</Typography>
					</Flex>
				</Flex>
			</Flex>
			<Flex alignItems={'center'} gap={'10px'}>
				<ISO44 />
				<AICPA44 />
			</Flex>
		</Flex>
	);
}
