import { useCallback } from 'react';
import { Layout } from 'react-grid-layout';
import { Signals_Insert_Input, useSignalsInsertMutation } from 'src/generated/graphql';
import { DashboardQueryResult } from 'src/pages/DashboardPage/types/DashboardQueryResult';
import { InsertCollectionAndFeed } from 'src/queries/collections';
import { UpdateDashboardLayout } from 'src/queries/dashboards';
import { FeedSignal } from 'src/types/spaces';
import { v4 as uuidv4 } from 'uuid';
import useMutation from './fetching/useMutation';
import useUser from './stores/useUser';

export function useDuplicateDashboard(): [
	(dashboard: DashboardQueryResult, newDashboardName: string) => Promise<string>,
	boolean
] {
	const [insertCollectionMutation, { loading: isInsertCollectionMutationLoading }] =
		useMutation(InsertCollectionAndFeed);
	const [insertSignalsMutation, { loading: isInsertSignalsLoading }] = useSignalsInsertMutation();
	const [updateDashboardLayout, { loading: isUpdateDashboardLoading }] = useMutation(UpdateDashboardLayout);
	const [{ id: my_id }] = useUser();

	const duplicateDashboard = async (dashboard: DashboardQueryResult, newDashboardName: string): Promise<string> => {
		const insertCollectionResult = await insertCollectionMutation({
			variables: { name: newDashboardName, collection_type: 'dashboard' },
		});
		const newDashboardId = insertCollectionResult.data?.insert_workspaces_one?.id;

		const feedSignals =
			dashboard.feed?.feed_signals.map((signal): [string, { newSignalId: string; signal: FeedSignal }] => [
				signal.signal_id,
				{ newSignalId: uuidv4(), signal: signal as FeedSignal },
			]) ?? [];

		const feed_insert_objects = feedSignals.map(([, { newSignalId, signal }]) => ({
			signal_id: newSignalId,
			feed_id: newDashboardId,
			display_options: signal.display_options,
		}));

		const signals = feedSignals.map(
			([, { newSignalId, signal }]): Signals_Insert_Input => ({
				title: signal.signal.title,
				message: signal.signal.message,
				attachment: signal.signal.attachment,
				widget_type: signal.signal.widget_type,
				id: newSignalId,
			})
		);

		await insertSignalsMutation({
			variables: {
				feed_insert_objects,
				my_id,
				signals,
			},
		});

		const newLayout = (dashboard.layout as Layout[]).map((layout) => ({
			...layout,
			i: Object.fromEntries(feedSignals)[layout.i].newSignalId,
		}));

		await updateDashboardLayout({
			variables: { layout: newLayout, id: newDashboardId },
		});

		return newDashboardId;
	};

	const duplicateDashboardCallback = useCallback(duplicateDashboard, [
		insertCollectionMutation,
		insertSignalsMutation,
		my_id,
		updateDashboardLayout,
	]);

	const isLoading = isInsertCollectionMutationLoading || isInsertSignalsLoading || isUpdateDashboardLoading;
	return [duplicateDashboardCallback, isLoading];
}
