import { Flex } from '@chakra-ui/react';
import { formatDistance } from 'date-fns';
import { useMemo } from 'react';
import Avatar from 'src/common/components/Avatar/Avatar';
import Typography from 'src/common/components/Typography';
import useMutation from 'src/common/hooks/fetching/useMutation';
import useUser from 'src/common/hooks/stores/useUser';
import {
	DeleteSignalMutationResult,
	DeleteSignalMutationVariables,
	SignalUpsertMutation,
	SignalUpsertMutationVariables,
} from 'src/generated/graphql';
import { migrateSignalTitle } from 'src/pages/WorkspacePage/SignalWidget/Header/utils';
import { DeleteSignal, SignalUpsert } from 'src/queries/signals';
import { useReportEvent } from 'src/services/analytics';
import { FeedSignal } from 'src/types/spaces';
import { v4 as uuidv4 } from 'uuid';
import { SignalActions } from './SignalActions';

export function SignalHeader({
	signal,
	onCommentClick,
	hasEditActions,
}: {
	signal: FeedSignal;
	onCommentClick: VoidFunction;
	hasEditActions?: boolean;
}) {
	const { reportEvent } = useReportEvent();
	const {
		author: { picture = '', first_name: firstName, last_name: lastName },
		title,
		message,
		created_at: createdAt,
		edited_at: editedAt,
	} = signal.signal;
	const [{ id: userId }] = useUser();
	const fullName = `${firstName ?? ''} ${lastName ?? ''}`;
	const isEdited = editedAt !== null && editedAt !== undefined;
	const timeAgo = formatDistance(new Date(isEdited ? editedAt : createdAt), new Date(), { addSuffix: true });

	const [upsertSignal] = useMutation<SignalUpsertMutation, SignalUpsertMutationVariables>(SignalUpsert);

	const signalInteractionMessage = isEdited ? `Edited ${timeAgo}` : `Published ${timeAgo}`;
	const signalSubheader = `${firstName}${lastName ? ` ${lastName}` : ''}, ${signalInteractionMessage}`;

	const signalTitle = useMemo(() => (title ? title : migrateSignalTitle({ title, message })), [title, message]);

	const duplicateSignal = () => {
		if (!signal.feed_id) return;

		//TODO: fix mention
		reportEvent({
			event: 'signal-duplicate',
			metaData: {
				workspaceId: signal.feed_id,
				signalId: signal.signal_id,
				messageLength: signal.signal.message?.length ?? 0,
				hasMention: signal.signal.message?.includes('@') ?? false,
			},
		});
		const signalId = uuidv4();
		upsertSignal({
			variables: {
				attachment: signal.signal.attachment,
				message: signal.signal.message,
				my_id: userId,
				title: signal.signal.title,
				signal_id: signalId,
				feed_insert_objects: [{ feed_id: signal.feed_id, signal_id: signalId }],
				feed_delete_ids: [],
			},
		});
	};

	const [deleteSelf] = useMutation<DeleteSignalMutationResult, DeleteSignalMutationVariables>(DeleteSignal);

	const deleteSignal = () => {
		reportEvent({
			event: 'signal-delete',
			metaData: {
				workspaceId: signal.feed_id,
				signalId: signal.signal_id,
			},
		});

		deleteSelf({
			variables: {
				feed_id: signal.feed_id,
				signal_id: signal.signal_id,
				my_id: userId,
			},
		});
	};

	return (
		<Flex width="100%" direction="row" p="24px" pb="0px" flex="1" justifyContent={'space-between'}>
			<Flex overflow="hidden" direction="column" gap="8px" zIndex={'2'}>
				<Typography
					width={'100%'}
					overflow="clip"
					variant="DesktopH6Medium"
					whiteSpace={'nowrap'}
					textOverflow="ellipsis"
				>
					{signalTitle || 'Untitled insight'}
				</Typography>
				<Flex direction="row" gap="8px">
					<Avatar imageUrl={picture} name={fullName} width="24px" height="24px" shape="round" />
					<Typography variant="Paragraph14R" color={'gray.700'}>
						{signalSubheader}
					</Typography>
				</Flex>
			</Flex>
			<SignalActions
				signal={signal}
				duplicateSignal={hasEditActions ? duplicateSignal : undefined}
				deleteSignal={hasEditActions ? deleteSignal : undefined}
				onCommentClick={onCommentClick}
			/>
		</Flex>
	);
}
