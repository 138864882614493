import { Badge, Box, Flex, useDisclosure } from '@chakra-ui/react';
import Tooltip from '@components/Tooltip';
import Button from 'src/common/components/Button';
import { ErrorButton } from 'src/common/components/ErrorButton';
import { FilterBadgesArray } from 'src/common/components/FilterBadgesArray';
import { AddColumn16, DownloadSolid16, FilterOutline16 } from 'src/common/components/Icons';
import Pagination from 'src/common/components/Pagination';
import Typography from 'src/common/components/Typography';
import useToast from 'src/common/hooks/ui/useToast';
import { Filter } from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import { TABLE_LIMIT } from '../../utils/consts';

export function TableHeader({
	exportTableDataAsCSV,
	onAddColumn,
	totalCount,
	onNext,
	onPrevious,
	isErrorActive,
	errorMessage,
	onAddFilter,
	onRemoveFilter,
	filter,
}: {
	exportTableDataAsCSV: VoidFunction;
	onAddColumn: VoidFunction;
	totalCount: number;
	onNext: () => void;
	onPrevious: () => void;
	isErrorActive?: boolean;
	errorMessage?: string;
	onAddFilter: VoidFunction;
	onRemoveFilter: (key: string) => void;
	filter: Filter[];
}) {
	const { wrapWithReport } = useReportEvent();

	const toast = useToast();

	const { isOpen, onOpen, onClose } = useDisclosure();

	const onExportCSV = () => {
		exportTableDataAsCSV();
		toast({ variant: 'ok', message: 'Download successfully started.', isCloseable: false });
	};

	return (
		<>
			<Flex justifyContent="space-between" alignItems={'center'} marginBottom="8px" height={'32px'} marginTop="20px">
				<Box>
					<Pagination onNext={onNext} onPrevious={onPrevious} limit={TABLE_LIMIT} totalCount={totalCount} />
				</Box>

				<Flex alignItems="center" justifyContent="flex-end" paddingLeft={'16px'}>
					{isErrorActive && (
						<ErrorButton
							errorMessage={errorMessage}
							reportEventData={{ event: 'entity-edit-error-show', feature: 'Ontology Editor' }}
						/>
					)}

					<Tooltip label="Export table" size="md" variant="fluid" hasArrow={true} marginTop="12px">
						<Button
							onClick={wrapWithReport(onExportCSV, 'export-icon-clicked')}
							variant="outline"
							isIconOnly
							colorScheme="gray"
							size={'small'}
						>
							<DownloadSolid16 />
						</Button>
					</Tooltip>

					<Tooltip size="md" label="Add column" marginTop="12px">
						<Box marginLeft={'4px'}>
							<Button variant="outline" isIconOnly onClick={onAddColumn} size="small" colorScheme="gray">
								<AddColumn16 />
							</Button>
						</Box>
					</Tooltip>

					<Flex
						onMouseLeave={() => onClose()}
						onMouseEnter={() => filter.length > 0 && onOpen()}
						position={'relative'}
						marginLeft={'4px'}
					>
						<Tooltip size="md" label={'Filter columns'} marginTop="12px">
							<Button
								isActive={isOpen}
								variant="outline"
								isIconOnly
								onClick={onAddFilter}
								size="small"
								colorScheme="gray"
							>
								<FilterOutline16 />
							</Button>
						</Tooltip>
						{filter.length > 0 && (
							<Badge
								style={{
									gap: '10px',
									position: 'absolute',
									border: '1.5px solid white',
									padding: '1px 5px 0px 5px',
									borderRadius: '20px',
									width: '20px',
									height: '18px',
									right: -6,
									top: -2,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
								colorScheme="blue"
								backgroundColor={'blue.600'}
							>
								<Typography variant={'Paragraph12M'} color={'white'}>
									{filter.length}
								</Typography>
							</Badge>
						)}
					</Flex>
				</Flex>
			</Flex>
			<FilterBadgesArray onClose={onRemoveFilter} filters={filter} />
		</>
	);
}
