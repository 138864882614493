import { SelectOption } from '@components/Select/types';
import Checkbox from 'src/common/components/Checkbox';
import ListItem from 'src/common/components/ListItem';
import Select from 'src/common/components/Select';
import { StatisticOperation } from 'src/pages/MetricPage/utils/state.types';

export type LegendStatsProps = {
	stat: StatisticOperation;
	onStatModifierChange: (newVal: SelectOption) => void;
	onCheckChange: () => void;
};

export function LegendStats({ stat, onStatModifierChange, onCheckChange }: LegendStatsProps) {
	return (
		<ListItem
			hasRoundedCorners
			onClick={onCheckChange}
			prefixComponent={
				<Checkbox
					isChecked={stat.isChecked}
					label={stat.name}
					checkboxColor={stat.color}
					size="sm"
					onChange={onCheckChange}
				/>
			}
			suffixComponent={
				stat.isChecked && (
					<Select
						width={'70px'}
						menuPlacement="bottom"
						paddingLeft="8px"
						menuStyles={{
							maxWidth: '120px',
						}}
						onChange={onStatModifierChange}
						selectedOption={stat.options[stat.selectedOptionIndex]}
						size="inline"
						options={stat.options}
						backgroundColor="transparent"
					/>
				)
			}
			label=""
			size={'sm'}
		/>
	);
}
