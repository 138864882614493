export function removeMacroPeriodIn(value: string) {
	return value.replace(PeriodInMacroRegEx, '$1');
}
export function isMacroPeriodIn(value: string) {
	return PeriodInMacroRegEx.test(value.trim());
}
export function addMacroPeriodIn(value: string) {
	return `${PeriodInMacro}(${value})`;
}
const PeriodInMacroRegEx = /^\$macros.PERIOD_IN\((.*)\)$/;
const PeriodInMacro = '$macros.PERIOD_IN';
