import useParams from 'src/common/hooks/navigation/useParams';
import useSearchParams from 'src/common/hooks/navigation/useSearchParams';

export function useExportMetricSearchParams() {
	const { metricName } = useParams();
	const [searchParams] = useSearchParams();

	//TODO: Generic param formatting
	const { from, to, filterByText, groupByText, collectPropsText } = {
		from: searchParams.get('from') ?? undefined,
		to: searchParams.get('to') ?? undefined,
		filterByText: searchParams.get('filterBy') ?? undefined,
		groupByText: searchParams.get('groupBy') ?? undefined,
		collectPropsText: searchParams.get('collectProps') ?? undefined,
	};

	if (!metricName) throw Error('No metricName');
	if (!from) throw Error('No from period');

	return {
		metricName,
		from,
		to,
		filterByText,
		groupByText,
		collectPropsText,
	};
}
