import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import useParams from 'src/common/hooks/navigation/useParams';
import { labelPretty } from 'src/common/utils/searchEntity';
import {
	GetNodesByTextQuery,
	GetNodesByTextQueryVariables,
	useGetRecordSearchResultsQuery,
} from 'src/generated/graphql';
import { RelatedEntity } from 'src/layout/EntityProfileDrawer/types';
import { GetNodesByText } from 'src/queries/nodes';
import { SearchQueryResult, SearchResultsHookReturnType } from '../types';

const ENTITIES_LIMIT_PER_QUERY = 100;

export function useSearchResultsV1(): SearchResultsHookReturnType {
	const { searchText, objects } = useParams();

	const searchObjects = useMemo(() => objects?.split(',') ?? [], [objects]);
	const isAllSearch = (searchObjects.length ?? 1) > 1;

	const { loading: isLoading, data } = useQuery<GetNodesByTextQuery, GetNodesByTextQueryVariables>(GetNodesByText, {
		variables: { query: searchText ?? '', limit: ENTITIES_LIMIT_PER_QUERY, labels: searchObjects },
		fetchPolicy: 'no-cache',
	});

	const queryResult: SearchQueryResult = data?.nodesTextSearch?.[0].result;

	const resultObjects = useMemo(() => {
		return isLoading ? [] : searchObjects.filter((object) => Object.keys(queryResult).includes(object));
	}, [isLoading, searchObjects, queryResult]);

	const prettyResultsObjects = useMemo(() => {
		return isLoading
			? []
			: resultObjects.map((label) => {
					return labelPretty(label);
			  });
	}, [isLoading, resultObjects]);

	const tabsNames = isAllSearch ? ['All', ...prettyResultsObjects] : prettyResultsObjects;

	const noResultsFound = useMemo(() => {
		return !isLoading && Object.keys(queryResult).length == 0;
	}, [isLoading, queryResult]);

	const shouldShowTabs = !isLoading && !noResultsFound && isAllSearch;

	return {
		searchText,
		searchObjects,
		isAllSearch,
		isLoading,
		queryResult,
		resultObjects,
		tabsNames,
		noResultsFound,
		shouldShowTabs,
	};
}

export function useSearchResultsV2(): SearchResultsHookReturnType {
	const { searchText, objects } = useParams();

	const searchObjects = useMemo(() => objects?.split(',') ?? [], [objects]);

	const isAllSearch = searchObjects.length > 1;

	const { loading: isLoading, data } = useGetRecordSearchResultsQuery({
		variables: {
			searchTerm: searchText ?? '',
			entityNames: searchObjects,
		},
	});

	const searchResults = useMemo(
		() => (data?.getRecordSearchResults?.searchResults ?? []).filter((result) => result.records.length > 0),
		[data?.getRecordSearchResults?.searchResults]
	);

	const queryResult: SearchQueryResult = Object.fromEntries(
		searchResults.map((result) => [
			result.entityName,
			result.records.map((record: RelatedEntity) => {
				return { node: record };
			}),
		])
	);

	const resultObjects = useMemo(() => {
		return isLoading ? [] : searchObjects.filter((object) => Object.keys(queryResult).includes(object));
	}, [isLoading, searchObjects, queryResult]);

	const entityNameToDisplay = useMemo(() => {
		return isLoading
			? {}
			: Object.fromEntries(searchResults.map((result) => [result.entityName, result.entityDisplayName]));
	}, [searchResults, isLoading]);

	const entityDisplayNames = useMemo(() => {
		return Object.values(entityNameToDisplay).sort((d1, d2) => d1.localeCompare(d2));
	}, [entityNameToDisplay]);
	const tabsNames = isAllSearch ? ['All', ...entityDisplayNames] : entityDisplayNames;

	const noResultsFound = useMemo(() => {
		return !isLoading && !searchResults.some((result) => result.records.length > 0);
	}, [searchResults, isLoading]);

	const shouldShowTabs = !isLoading && !noResultsFound && isAllSearch;

	return {
		searchText,
		searchObjects,
		isAllSearch,
		isLoading,
		queryResult,
		resultObjects,
		tabsNames,
		noResultsFound,
		shouldShowTabs,
		entityNameToDisplay,
	};
}
