import { Flex } from '@chakra-ui/react';
import Divider from '@components/Divider';
import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonTable.module.scss';
import { SkeletonTableLayout } from 'src/common/components/Table/SkeletonTableLayout/SkeletonTableLayout';

const { titleSkeleton, buttonSkeleton, bodySkeleton, descriptionSkeleton, labelSkeleton } = classes;

export function SkeletonHeader() {
	return (
		<>
			<Flex padding={'14px 16px'} alignItems={'center'} justifyContent={'space-between'}>
				<Flex flexDirection={'column'}>
					<Skeleton className={titleSkeleton} />
					<Skeleton className={descriptionSkeleton} />
				</Flex>
				<Flex gap={'16px'}>
					<Skeleton className={labelSkeleton} />
					<Skeleton className={labelSkeleton} />
					<Skeleton className={buttonSkeleton} />
				</Flex>
			</Flex>
			<Divider direction={'horizontal'} />
		</>
	);
}

function SkeletonBody() {
	return <Skeleton className={bodySkeleton} />;
}

export function SkeletonTable({ withHeader = false }: { withHeader?: boolean }) {
	return (
		<>
			{withHeader && <SkeletonHeader />}
			<Flex width={'100%'} marginTop={'-12px'} flexDirection={'column'} height={'100%'} paddingX={'32px'}>
				<SkeletonTableLayout />
				<Flex flex={1}>
					<SkeletonBody />
				</Flex>
			</Flex>
		</>
	);
}
