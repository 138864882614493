import Box from '@components/Box';
import { Document20 } from 'src/common/components/Icons';

export function WorkspaceBadge() {
	return (
		<Box cursor={'default'} marginRight={'8px'} borderRadius="4px">
			<Document20 />
		</Box>
	);
}
