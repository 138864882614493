import { Flex } from '@chakra-ui/react';
import Skeleton from 'src/common/components/Skeleton';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { useReportEvent } from 'src/services/analytics';
import shadows from 'src/style/shadows';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { useMetricEditorState } from '../../hooks/useMetricEditorState';
import { MetricButton } from '../Header/MetricButton';
import { MonacoYamlEditor } from '../MonacoEditor/MonacoYamlEditor';
import { MonacoError } from '../MonacoEditor/types';
import classes from './MetricYamlEditor.module.scss';

type MetricYamlEditorProps = {
	hasEditPermission: boolean;
	onChangeValue: (editorValue: string) => void;
	setErrors?: (hasErrors: MonacoError[]) => void;
	isPreviewEnabled?: boolean;
	onPreview?: () => void;
	shouldUseFormula?: boolean;
};

export function MetricYamlEditor({
	hasEditPermission,
	onChangeValue,
	setErrors,
	isPreviewEnabled,
	onPreview,
	shouldUseFormula,
}: MetricYamlEditorProps) {
	const { metricNameWithFlavor: metricName, isFormula } = useMetricDerivedState();
	const { reportEvent } = useReportEvent({
		feature: 'Metric Builder',
		metricName,
	});
	const { isCalculatingPreview } = useBuilderDerivedState();
	const { metricEditorState, hasChangesToPreview, latestEditorValue } = useMetricEditorState();
	if (metricEditorState.isLoading) {
		return <Skeleton className={classes.editorSkeleton} />;
	}

	return (
		<MonacoYamlEditor
			footer={
				hasEditPermission ? (
					<Flex boxShadow={shadows.borderTop} direction="row" justifyContent={'end'} padding="16px">
						<MetricButton
							isDisabled={!isPreviewEnabled}
							onClick={onPreview}
							eventName="metric-edit-preview-clicked"
							eventMetadata={{ feature: 'Metric Builder', metricName: metricName }}
						>
							Preview
						</MetricButton>
					</Flex>
				) : null
			}
			onHelpClicked={() => {
				reportEvent({
					event: 'metric-edit-YAML-help-clicked',
				});
			}}
			isEditable={!isCalculatingPreview && !metricEditorState.isLoading}
			editorLoading={metricEditorState.isLoading}
			schemaType={isFormula || shouldUseFormula ? 'metric_formula' : 'metric_agg'}
			hasUnsavedChanges={hasChangesToPreview}
			value={latestEditorValue ?? ''}
			onChange={onChangeValue}
			onErrorsChange={setErrors}
			onFocus={(hasError) => {
				reportEvent({
					event: 'metric-edit-YAML-focus-clicked',
					metaData: { hasError },
				});
			}}
		/>
	);
}
