import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Modal from 'src/common/components/Modal';
import ModalFooter from 'src/common/components/ModalFooter';
import ModalHeader from 'src/common/components/ModalHeader';
import { useShareSignalApi } from 'src/common/hooks/useShareSignalApi';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { Signal } from 'src/types/signal';
import { WorkspaceAttachment } from '../../SignalWidget/WorkspaceAttachment';
import TextEditor from './TextEditor';

export function EditWorkspaceSignalModal({
	isOpen,
	onClose,
	signal,
	workspaceId,
	intialTitle = '',
	modalHeaderTitle = 'Edit insight',
}: {
	isOpen: boolean;
	onClose: VoidFunction;
	signal: Signal;
	workspaceId: string;
	intialTitle?: string;
	modalHeaderTitle?: string;
}) {
	const { reportEvent } = useReportEvent();
	const [message, setMessage] = useState(signal.message);
	const [title, setTitle] = useState(intialTitle);
	const [shareSignal, { isLoading }] = useShareSignalApi(signal);

	useEffect(() => {
		setMessage(signal.message);
	}, [signal.message, isOpen]);
	useEffect(() => {
		setTitle(intialTitle);
	}, [intialTitle, isOpen]);

	const saveSignal = () => {
		const isNewSignal = !signal.id;

		reportEvent({
			event: isNewSignal ? 'workspace-signal-create' : 'workspace-signal-edit',
			metaData: { workspaceId: workspaceId, signalId: signal.id, messageLength: message.length ?? 0 },
		});
		shareSignal({
			message,
			collectionToPin: isNewSignal ? [workspaceId] : [],
			collectionToUnPin: [],
			title,
			signalId: signal.id,
			attachmentContentURL: signal.attachment,
		}).then(onClose);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<Flex direction={'column'}>
				<ModalHeader size={'md'} title={modalHeaderTitle} onClose={onClose} />
				<Flex direction={'column'} maxHeight={'72vh'} overflowY="auto" display={'block'}>
					<Box minHeight="230px" position="relative">
						{isOpen && <TextEditor value={message} onChange={setMessage} title={title} onTitleChange={setTitle} />}
					</Box>
					{signal.attachment && (
						<Box
							margin="8px 24px 24px 24px"
							border={`1px solid ${colors.gray[300]}`}
							borderRadius="4px"
							overflow={'clip'}
						>
							<WorkspaceAttachment chartHeight={'250px'} />
						</Box>
					)}
				</Flex>
				<Box boxShadow={shadows.borderTop}>
					<ModalFooter
						size={'md'}
						primaryButtonLabel={'Save'}
						onCancel={onClose}
						onPrimaryClick={saveSignal}
						isPrimaryLoading={isLoading}
						isPrimaryEnabled={!!title}
					/>
				</Box>
			</Flex>
		</Modal>
	);
}
