import Typography from 'src/common/components/Typography';
import { IField, INumber, ITabs } from '../../interfaces';
import { IFieldValue } from '../../interfaces/fields';
import { ModalField } from '../ModalField/ModalField';

const ViewField = ({ label }: { label: string }) => <Typography variant={'DesktopH7Medium'}>{label}</Typography>;

const NumberField = ({ value, field }: { value: IFieldValue; field: IField }) => {
	const { label, labelSide } = field as INumber;

	const finalLabelSide = labelSide ?? 'right';
	const leftLabel = finalLabelSide == 'left' ? label : '';
	const rightLabel = finalLabelSide == 'right' ? label : '';

	return <ViewField label={`${leftLabel}${String(value)} ${rightLabel}`} />;
};

const ViewOnlyModalField = ({
	field,
	value,
	fieldKey,
	isDisabled,
	handleChange,
}: {
	field: IField;
	fieldKey: string;
	isDisabled: boolean;
	value: IFieldValue;
	handleChange: (key: string, value: IFieldValue) => void;
}) => {
	return (
		<ModalField
			field={field}
			value={value}
			fieldKey={fieldKey}
			isDisabled={isDisabled}
			handleChange={handleChange}
			isEditable={false}
		/>
	);
};

export const ViewModeFieldComponents = {
	tab: ({ value, field }: { value: IFieldValue; field: IField }) => {
		const { options } = field as ITabs;
		const label = String(options?.[value as number]?.displayName) ?? '';

		return <ViewField label={label} />;
	},
	number: NumberField,
	freeText: ({ value }: { value: IFieldValue }) => <ViewField label={String(value)} />,
	monthInYear: ({ value }: { value: IFieldValue }) => <ViewField label={String(value)} />,
	modal: ViewOnlyModalField,
} as const;
