import { Radio as ChakraRadio } from '@chakra-ui/react';
import Typography from '@components/Typography';
import './radio.scss';
import { RadioProps } from './types';

function Radio({ value, isChecked, children, isDisabled = false }: RadioProps) {
	return (
		<ChakraRadio value={value?.toString()} isChecked={isChecked} isDisabled={isDisabled}>
			<Typography variant={'Paragraph16R'}>{children}</Typography>
		</ChakraRadio>
	);
}

export default Radio;
