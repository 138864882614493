import Flex from '@components/Flex';
import Typography from '@components/Typography';
import colors from 'src/style/colors';
import { SupportLink } from '@pages/MetricCatalog/TemplateOnboardingModal/components/SupportLink';
import { Warning16 } from '@icons/index';

export function ChartRenderErrorPlaceHolder() {
	return (
		<Flex
			justifyContent={'center'}
			alignItems={'center'}
			alignSelf={'stretch'}
			height={'100%'}
			gap={'16px'}
			direction={'column'}
			flexGrow={1}
		>
			<Warning16 color={'white'} fill={colors.red['700']} />
			<Flex gap={'8px'} alignItems={'center'} justifyContent={'center'} direction={'column'}>
				<Typography variant={'DesktopH7SemiBold'} color={colors.gray['1000']}>
					Something went wrong.
				</Typography>
				<Typography variant={'Paragraph12R'} color={colors.gray['1000']} textAlign={'center'}>
					This metric is currently unavailable. <br /> Please <SupportLink /> for further assistance.
				</Typography>
			</Flex>
		</Flex>
	);
}
