import { useEffect, useMemo, useState } from 'react';
import Comment from 'src/common/components/Comment';
import useMutation from 'src/common/hooks/fetching/useMutation';
import useUser from 'src/common/hooks/stores/useUser';
import {
	CommentDeleteMutation,
	CommentDeleteMutationVariables,
	CommentEditMutation,
	CommentEditMutationVariables,
} from 'src/generated/graphql';
import { CommentDelete, CommentEdit } from 'src/queries/signals';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { Comment as SignalComment } from 'src/types/signal';
import { FeedSignal } from 'src/types/spaces';
import { UserType } from 'src/types/user';

export function WorkspaceComment({ signal, comment }: { signal: FeedSignal; comment: SignalComment }) {
	const { reportEvent } = useReportEvent();
	const author: Pick<UserType, 'firstName' | 'lastName' | 'picture'> = {
		firstName: comment.author.first_name ?? '',
		lastName: comment.author.last_name ?? '',
		picture: comment.author.picture ?? 'https://i.pravatar.cc/150?img=8',
	};
	const [isEditMode, setIsEditMode] = useState(false);

	const { isHavingPermission } = usePermissionCheck();

	const [user] = useUser();
	const isOwner = comment.author_id == user.id;
	const [deleteComment, { loading: isDeleteLoading }] = useMutation<
		CommentDeleteMutation,
		CommentDeleteMutationVariables
	>(CommentDelete);

	const hasDeletePermissions = useMemo(() => isHavingPermission(Permissions.deleteAllComments), [isHavingPermission]);
	const hasCRUDPermissions = useMemo(() => isHavingPermission(Permissions.crudMyComments), [isHavingPermission]);

	const canDelete = hasDeletePermissions || (isOwner && hasCRUDPermissions);
	const canEdit = hasCRUDPermissions && isOwner;

	const onDeleteComment = () => {
		reportEvent({
			event: 'comment-delete',
			metaData: { workspaceId: signal.feed_id, signalId: signal.signal_id, commentId: comment.id },
		});
		deleteComment({ variables: { comment_id: comment.id } });
	};

	const [inputText, setInputText] = useState(comment.message ?? '');
	useEffect(() => {
		setInputText(comment.message ?? '');
	}, [comment.message]);

	const [editComment, { loading: isEditLoading }] = useMutation<CommentEditMutation, CommentEditMutationVariables>(
		CommentEdit
	);

	async function onSubmit() {
		if (!inputText) return;

		reportEvent({
			event: 'comment-edit',
			metaData: {
				workspaceId: signal.feed_id,
				signalId: signal.signal_id,
				commentId: comment.id,
				commentLength: inputText.length ?? 0,
			},
		});
		editComment({ variables: { comment_id: comment.id, message: inputText } }).then(() => setIsEditMode(false));
	}

	if (isDeleteLoading) return null;

	return (
		<>
			<Comment
				author={author}
				createdAt={new Date(comment.created_at)}
				text={comment.message ?? ''}
				onDelete={canDelete ? onDeleteComment : undefined}
				onEditClick={canEdit ? () => setIsEditMode(true) : undefined}
				isEditMode={isEditMode}
				onTextChange={setInputText}
				onSubmit={onSubmit}
				isEditLoading={isEditLoading}
			/>
		</>
	);
}
