import { Box, Flex, Grid, GridItem, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useEffect } from 'react';
import Button from 'src/common/components/Button';
import IconButton from 'src/common/components/IconButton';
import { CloseLarge16 } from 'src/common/components/Icons';
import Modal from 'src/common/components/Modal';
import Typography from 'src/common/components/Typography';
import useMutation from 'src/common/hooks/fetching/useMutation';
import useToast from 'src/common/hooks/ui/useToast';
import { SaveConfigMutation, SaveConfigMutationVariables } from 'src/generated/graphql';
import { UpsertConfigByName } from 'src/queries/self-service-configs';
import { IField } from '../../interfaces';

const SaveModal = ({
	isOpen,
	isEnabled,
	onClose,
	configName,
	fieldsSelectedValues,
	onSaveDone,
}: {
	isOpen: boolean;
	isEnabled: boolean;
	onClose: () => void;
	configName: string;
	fieldsSelectedValues: {
		[key: string]: Partial<IField>;
	} | null;
	onSaveDone: () => void;
}) => {
	const toast = useToast();

	const [saveConfig, { data: saveResponse, error: saveError }] = useMutation<
		SaveConfigMutation,
		SaveConfigMutationVariables
	>(UpsertConfigByName);

	useEffect(() => {
		if (!saveError) {
			return;
		}

		toast({ variant: 'error', message: 'Save Failed' });
	}, [onClose, saveError, toast]);

	useEffect(() => {
		if (!saveResponse) {
			return;
		}

		if (saveResponse.save_config?.response === 'Done') onSaveDone();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveResponse]);

	const onSave = () => {
		if (configName?.length === 0 || !fieldsSelectedValues || Object.keys(fieldsSelectedValues).length === 0) {
			return;
		}

		onClose();
		saveConfig({ variables: { name: configName, config: { isEnabled, fields: fieldsSelectedValues } } });
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />

			<ModalContent minWidth={'500px'}>
				<Box p={'16px 0px 0px 16px'}>
					<IconButton
						icon={
							<Box color="gray.800">
								<CloseLarge16 width="16px" height="16px" />
							</Box>
						}
						onClick={onClose}
					/>
				</Box>

				<Box>
					<Grid pt="16px" gridTemplateColumns="repeat(12, [col-start] 1fr)" p={'8px 22px 48px 22px'} width={'500px'}>
						<GridItem colSpan={12} textAlign="center">
							<Typography variant="DesktopH5">Save changes?</Typography>
						</GridItem>
						<GridItem colSpan={12} textAlign="center" p={'16px 30px 32px 30px'}>
							<Typography variant="Paragraph14R">
								Changes here will affect all revenue metrics and may take up to 24 hours.
							</Typography>
						</GridItem>
						<GridItem colSpan={12}>
							<Flex justifyContent="center" alignItems="center" gap={'10px'}>
								<Button size={'medium'} variant="outline" colorScheme="gray" onClick={onClose}>
									Go Back
								</Button>
								<Button
									size={'medium'}
									variant="solid"
									colorScheme="gray"
									onClick={onSave}
									eventName="self-service-config-clicked-save-approved"
									eventMetadata={{ config: configName }}
								>
									Yes, I understand
								</Button>
							</Flex>
						</GridItem>
					</Grid>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default SaveModal;
