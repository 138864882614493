import { useCallback } from 'react';
import useUser from 'src/common/hooks/stores/useUser';
import {
	GetMetricInteractiveSessionQuery,
	SendMetricInteractivePromptMutationHookResult,
	SendMetricInteractivePromptMutationVariables,
	useSendMetricInteractivePromptMutation,
} from 'src/generated/graphql';
import { GetMetricInteractiveSession } from 'src/queries/genai';

export function useSendMetricInteractive(): [
	(variables: SendMetricInteractivePromptMutationVariables) => void,
	SendMetricInteractivePromptMutationHookResult[1]
] {
	const [sendMetricInteractivePrompt, mutationResult] = useSendMetricInteractivePromptMutation();
	const [{ id: userId }] = useUser();
	const sendMetricInteractivePromptOptimistic = useCallback(
		(variables: SendMetricInteractivePromptMutationVariables) => {
			sendMetricInteractivePrompt({
				variables,
				optimisticResponse: {
					generative_metric_interactive: {},
				},
				update: (cache) => {
					const cachedResult = cache.readQuery<GetMetricInteractiveSessionQuery>({
						query: GetMetricInteractiveSession,
						variables: { session_id: variables.request_id, user_id: userId },
					});
					const cachedCompletions = cachedResult?.generative_sessions[0].completions ?? [];

					const optimisticResponse = buildOptimisticResponse(cachedCompletions, variables);
					cache.writeQuery<GetMetricInteractiveSessionQuery>({
						query: GetMetricInteractiveSession,
						variables: { session_id: variables.request_id, user_id: userId },
						data: optimisticResponse,
					});
				},
			});
		},
		[sendMetricInteractivePrompt, userId]
	);

	return [sendMetricInteractivePromptOptimistic, mutationResult];
}
type Completion = { role: string; content: string };

function buildOptimisticResponse(
	cachedCompletions: Completion[],
	variables: { user_prompt: string; request_id: string }
) {
	return {
		generative_sessions: [
			{
				__typename: 'generative_sessions' as const,
				status: 'pending',
				completions: [
					...cachedCompletions,
					{ role: 'user', content: variables.user_prompt },
					{ role: 'assistant', content: '' },
				],
			},
		],
	};
}
