import { Box, Grid, GridItem } from '@chakra-ui/react';
import { ReactNode } from 'react';
import ButtonBar from 'src/common/components/ButtonBar';
import Checkbox from 'src/common/components/Checkbox';
import Divider from 'src/common/components/Divider';
import Flex from 'src/common/components/Flex';
import Typography from 'src/common/components/Typography';
import { useOptimisticSubscribe, useOptimisticUnsubscribe } from './hooks';
import { Insight, Subscription } from './types';

export function InsightRow({ insight, isLast }: { insight: Insight; isLast: boolean }) {
	const hasTimeframes = !!insight.timeframeOptions?.length;
	const enabledTimeframeIndexes =
		insight.timeframeOptions?.reduce<number[]>((enabledTimeframeIndexes, currentTimeframe, index) => {
			if (isSubscriptionEnabled(currentTimeframe.my_subscriptions)) enabledTimeframeIndexes.push(index);
			return enabledTimeframeIndexes;
		}, []) ?? [];
	const isInsightSubscribed =
		enabledTimeframeIndexes.length > 0 || isSubscriptionEnabled(insight?.insight_type?.my_subscriptions);

	const unsubscribeInsight = useOptimisticUnsubscribe();
	const subscribeInsight = useOptimisticSubscribe();

	const toggleInsightCheck = () => {
		if (!isInsightSubscribed) {
			const insightId = hasTimeframes ? insight.timeframeOptions?.[0]?.id : insight.insight_type?.id;
			if (insightId) subscribeInsight(insightId);
		} else {
			unsubscribe();
		}
	};
	const unsubscribe = () => {
		if (hasTimeframes) {
			insight.timeframeOptions?.forEach((element) => {
				unsubscribeInsight(element.id);
			});
		} else {
			const insightId = insight.insight_type?.id;
			if (insightId) unsubscribeInsight(insightId);
		}
	};

	return (
		<Flex gap="28px" direction={'column'} paddingTop={'24px'}>
			<Grid gridTemplateColumns="repeat(12, [col-start] 1fr)">
				<GridItem colSpan={{ base: 12, sm: 6 }}>
					<Flex alignItems={'start'} gap="12px" direction={'row'}>
						<Box paddingTop={'3px'}>
							<Checkbox isChecked={isInsightSubscribed} checkboxColor="white" size="sm" onChange={toggleInsightCheck} />
						</Box>
						<Flex direction={'column'} gap="4px">
							<Typography variant="DesktopH8Medium" color="gray.800" textAlign="start" onClick={toggleInsightCheck}>
								{insight.insight_type?.display_name}
							</Typography>

							{insight.insight_type?.description && (
								<Typography variant="Paragraph14R" textAlign="start" color="gray.600">
									{insight.insight_type.description}
								</Typography>
							)}
						</Flex>
					</Flex>
				</GridItem>
				{hasTimeframes && isInsightSubscribed && (
					<GridItem
						display={'flex'}
						alignItems={'center'}
						justifyContent={{
							base: 'flex-start',
							sm: 'flex-end',
						}}
						pt={{
							base: '16px',
							sm: '0px',
						}}
						colSpan={{ base: 12, sm: 6 }}
					>
						<ButtonBar
							selectedIndexes={enabledTimeframeIndexes}
							onChildClicked={function (index: number) {
								const isChecked = !enabledTimeframeIndexes.includes(index);
								const insightId = insight.timeframeOptions?.[index]?.id;
								if (!insightId) return;
								if (isChecked) {
									subscribeInsight(insightId);
								} else if (enabledTimeframeIndexes.length > 1) {
									unsubscribeInsight(insightId);
								}
							}}
						>
							{
								insight.timeframeOptions?.map((timeframe) => (
									<Box marginBottom={'2px'} key={timeframe.id}>
										<Typography variant="DesktopH8Medium" paddingX={'16px'}>
											{timeframe.name}
										</Typography>
									</Box>
								)) as ReactNode[]
							}
						</ButtonBar>
					</GridItem>
				)}
			</Grid>
			{!isLast && <Divider direction={'horizontal'} />}
		</Flex>
	);
}

function isSubscriptionEnabled(subscription?: Subscription): boolean {
	return !!subscription?.aggregate?.count;
}
