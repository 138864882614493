import { AgGridReact } from 'ag-grid-react';
import without from 'lodash/without';
import { useMemo } from 'react';
import Box from 'src/common/components/Box';
import Flex from 'src/common/components/Flex';
import { useDefaultColDef } from 'src/common/components/Table/config';
import { useEntityProfileDrawer, useEntityProfileReportEvent } from 'src/layout/EntityProfileDrawer/hooks';
import { HEADER_HEIGHT, PAGE_WIDTH, ROW_HEIGHT, SCROLL_BAR_WIDTH_CONSIDERATION } from '../consts';
import { SearchQueryResultEntity } from '../types';
import components from './components';

const nameDimension = 'name';

export function EntityTableRowsV2({
	entityName,
	entityDisplayName,
	resultEntities,
	startIndex,
	isAllSearch,
	isAllChosen,
	tablePaginationLimit,
}: {
	entityName: string;
	entityDisplayName: string;
	resultEntities: SearchQueryResultEntity[];
	startIndex: number;
	isAllSearch: boolean;
	isAllChosen: boolean;
	tablePaginationLimit: number;
}) {
	const defaultColDef = useDefaultColDef(true, true);

	const rowData = useMemo(
		() => resultEntities.map((entity) => entity.node).slice(startIndex, startIndex + tablePaginationLimit),
		[resultEntities, startIndex, tablePaginationLimit]
	);

	const dimensions = without(Object.keys(rowData[0]), 'sight_id');
	const hasNameDimension = dimensions.includes(nameDimension) && !!rowData.find((row) => !!row[nameDimension]);
	const dimensionsWithoutName = without(dimensions, nameDimension);
	const sortedDimensions = hasNameDimension ? [nameDimension, ...dimensionsWithoutName] : dimensionsWithoutName;

	const columnDefs = sortedDimensions.map((dimension) => {
		return {
			field: dimension,
			width: (PAGE_WIDTH - SCROLL_BAR_WIDTH_CONSIDERATION) / dimensions.length,
		};
	});

	const { pushEntity } = useEntityProfileDrawer();
	const { reportOpenEntityDrawer } = useEntityProfileReportEvent();

	const onRowClicked = (event: any) => {
		const recordId = event?.data?.sight_id;

		reportOpenEntityDrawer({
			origin: 'search-results',
			id: recordId,
			entity: entityName,
			metaData: {
				isSpecificObjectSearch: !isAllSearch,
				tab: isAllChosen ? 'All' : entityDisplayName,
				tableName: entityDisplayName,
			},
		});

		pushEntity(recordId);
	};

	return (
		<Flex flex={1} height="100%" width="100%">
			<Box className="tableContainer" width={`${PAGE_WIDTH}px`}>
				<Box paddingBottom={0} flexGrow={1} width="100%" className="tableWrapper">
					<AgGridReact
						columnDefs={columnDefs}
						className="ag-theme-alpine"
						rowData={rowData}
						headerHeight={HEADER_HEIGHT}
						rowHeight={ROW_HEIGHT}
						defaultColDef={defaultColDef}
						rowSelection="multiple"
						components={components}
						pagination={true}
						paginationPageSize={tablePaginationLimit}
						suppressPaginationPanel={true}
						domLayout="autoHeight"
						onRowClicked={onRowClicked}
					/>
				</Box>
			</Box>
		</Flex>
	);
}
