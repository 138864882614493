import Box from '@components/Box';
import Flex from '@components/Flex';
import { useCallback, useMemo } from 'react';
import BuilderTextInput from 'src/common/components/BuilderTextInput';
import { BuilderTextInputProps } from 'src/common/components/BuilderTextInput/BuilderTextInput';
import { CloseTiny16 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import { TableColumn } from 'src/generated/graphql';
import { CustomTextFieldCompletionItem } from 'src/lib/completions/completionProvider/textFieldCompletionProvider';
import { useCustomMonacoTextFieldProviders } from 'src/lib/completions/hooks/useMonacoTextFieldProviders';
import { Filters as FiltersType } from 'src/lib/completions/semanticTypes/metrics.schema';
import {
	LabelWithIcon,
	SelectLabel,
	TooltipIcon,
} from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';
import { NormalizedOntology } from 'src/pages/OntologyPage/hooks/useOntologyPageState';
import { KeysType } from 'src/pages/OntologyPage/hooks/useOntologyUpsertEntity';
import { useReportEvent } from 'src/services/analytics';
import { FilterTooltip } from '../../../Tooltips/FiltersTooltip';

const Filters = ({
	selectedOntology,
	isEditEntity = false,
	filters,
	isDisabled = false,
	onChangeValue,
	parsedDataSource,
	primaryKeysOptions,
	sourceSystem,
}: {
	isEditEntity: boolean;
	selectedOntology: NormalizedOntology;
	filters?: FiltersType;
	isDisabled?: boolean;
	onChangeValue: (keys: KeysType) => void;
	parsedDataSource: { key: string; value: string | FiltersType }[];
	primaryKeysOptions?: TableColumn[];
	sourceSystem?: string;
}) => {
	const { reportEvent } = useReportEvent({
		feature: 'Upsert Metric Modal',
	});

	const setFilters = useCallback(
		({ value, index }: { value: string; index: number }) => {
			reportEvent({
				event: 'ontology-object-edit-UI-input-provided',
				metaData: {
					objectType: 'entity',
					objectName: isEditEntity ? selectedOntology?.name : '',
					fieldname: 'filters',
					input: value,
					flow: isEditEntity ? 'edit' : 'create',
				},
			});

			const formattedValue = { sql: value.trim() };
			if ((!filters || !filters?.length) && value)
				onChangeValue([
					{ key: 'data_source', value: [...parsedDataSource, { key: 'filters', value: [formattedValue] }] },
				]);

			if (filters) {
				const dataSourceValue = [...parsedDataSource.filter((el) => el.key !== 'filters')];
				const changedFilters = filters
					.map((el, i) => (i === index ? { sql: value } : el))
					.filter((el) => !!el.sql && el.sql !== '');

				if (changedFilters.length) {
					dataSourceValue.push({
						key: 'filters',
						value: changedFilters,
					});
				}

				onChangeValue([
					{
						key: 'data_source',
						value: dataSourceValue,
					},
				]);
			}
		},
		[filters, onChangeValue, parsedDataSource, isEditEntity, reportEvent, selectedOntology?.name]
	);

	const providersArray = useMemo(
		() =>
			primaryKeysOptions?.map((el) => ({
				value: `src__${el.name}`,
				label: `src__${el.name}`,
				type: 'dimension' as const,
			})) ?? [],
		[primaryKeysOptions]
	);

	const onFiltersChange = useCallback(
		(inputText: string, index: number) => !isDisabled && setFilters({ value: inputText || '', index }),
		[isDisabled, setFilters]
	);

	const filtersArray = useMemo(() => {
		return filters?.length ? (
			filters?.map((el, index) => (
				<Box marginTop={'8px'} key={`filter-${index}`}>
					<Flex alignItems={'start'} width="100%">
						{index > 0 && (
							<Box marginTop={'6px'}>
								<Typography marginRight={'8px'} color={'gray.700'} variant="DesktopH10Medium">
									and
								</Typography>
							</Box>
						)}
						<FiltersTextInput
							initialState={el?.sql?.length || index === 0 ? 'Idle' : 'Focus'}
							providersArray={providersArray}
							source={sourceSystem}
							readOnly={isDisabled}
							fieldName={`filter-${index}`}
							placeholder={'Add filter'}
							validation={'None'}
							validationText={'Validation text'}
							contextSettings={undefined}
							text={el?.sql || ''}
							id={`filter-${index}`}
							onBlur={(inputText) => onFiltersChange(inputText || '', index)}
						/>
					</Flex>
				</Box>
			))
		) : (
			<Box marginTop={'8px'}>
				<FiltersTextInput
					providersArray={providersArray}
					source={sourceSystem}
					readOnly={isDisabled}
					fieldName="filter"
					placeholder={'Add filter'}
					validation={'None'}
					validationText={'Validation text'}
					contextSettings={undefined}
					onBlur={(inputText) => onFiltersChange(inputText || '', 0)}
					text={''}
					id={'filter'}
				/>
			</Box>
		);
	}, [filters, providersArray, sourceSystem, isDisabled, onFiltersChange]);

	const filterFields = useMemo(() => {
		const isWithoutEmptyValues = !isDisabled && !!filters?.length && !filters.some((val) => val.sql === '');
		return (
			<Box>
				{filtersArray}
				<Flex justifyContent={isWithoutEmptyValues ? 'space-between' : 'flex-end'} marginTop={'12px'}>
					{isWithoutEmptyValues && (
						<LabelWithIcon
							isFieldsDisabled={false}
							onClick={() =>
								onChangeValue([
									{
										key: 'data_source',
										value: [...parsedDataSource, { key: 'filters', value: [...filters, { sql: '' }] }],
									},
								])
							}
							text="Add filter"
						/>
					)}
					{filters && filters.length > 1 && (
						<LabelWithIcon
							icon={<CloseTiny16 />}
							isFieldsDisabled={false}
							onClick={() =>
								onChangeValue([
									{
										key: 'data_source',
										value: [...parsedDataSource, { key: 'filters', value: [...filters.slice(0, -1)] }],
									},
								])
							}
							text="Remove"
						/>
					)}
				</Flex>
			</Box>
		);
	}, [filters, filtersArray, onChangeValue, parsedDataSource, isDisabled]);

	return (
		<>
			<Flex gap={'6px'} alignItems={'center'}>
				<SelectLabel
					color={'gray.1100'}
					text="Filters"
					isOptional
					trailingIcon={
						<TooltipIcon
							tooltipBody={
								<FilterTooltip
									onHelpClicked={() =>
										reportEvent({
											event: 'ontology-object-help-clicked',
											metaData: {
												component: 'filters',
											},
										})
									}
								/>
							}
						/>
					}
				/>
			</Flex>
			{filterFields}
		</>
	);
};

export default Filters;

export function FiltersTextInput({
	providersArray,
	source: sourceSystem,
	id,
	...textInputProps
}: BuilderTextInputProps & { providersArray: CustomTextFieldCompletionItem[]; source?: string }) {
	useCustomMonacoTextFieldProviders(id, providersArray, sourceSystem ?? 'Unknown Source');
	return <BuilderTextInput id={id} {...textInputProps} />;
}
