import theme, { ChakraProvider } from 'src/style/theme';
import { ReactNode } from 'react';
import FontFaces from 'src/style/FontFaces';

type ChakraProps = {
	children: ReactNode;
};

const Chakra = ({ children }: ChakraProps) => (
	<ChakraProvider theme={theme}>
		<FontFaces />
		{children}
	</ChakraProvider>
);

export default Chakra;
