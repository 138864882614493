import { Box } from '@chakra-ui/react';
import Tooltip from '@components/Tooltip';
import { WarningFluid16 } from '@icons/index';
import Flex from '@components/Flex';
import colors from 'src/style/colors';
import Typography from '@components/Typography';

export function SignalWidgetError({ errorMessage }: { errorMessage: string }) {
	return (
		<Tooltip
			marginTop={'6px'}
			pointerEvents={'all'}
			borderRadius={'8px'}
			wordBreak={'break-all'}
			padding={'16px'}
			paddingTop={'10px'}
			maxHeight={'525px'}
			maxWidth={'272px'}
			backgroundColor={'gray.1000'}
			whiteSpace={'break-spaces'}
			label={
				<Box>
					<Flex justifyContent={'space-between'} alignItems={'center'} marginBottom={'8px'}>
						<Typography variant={'DesktopH10Bold'} color={'white'}>
							Error details
						</Typography>
					</Flex>

					<Typography variant={'DesktopH10Regular'} color={'white'}>
						{errorMessage}
					</Typography>
				</Box>
			}
			size="md"
		>
			<Flex
				alignItems={'center'}
				padding={'6px'}
				borderRadius={'4px'}
				_hover={{ backgroundColor: colors.gray[200], mixBlendMode: 'multiply' }}
				onClick={(e) => e.stopPropagation()}
			>
				<WarningFluid16 />
			</Flex>
		</Tooltip>
	);
}
