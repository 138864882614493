import { Link } from '@chakra-ui/react';
import Typography from 'src/common/components/Typography';

export const metricLink = 'https://docs.sightfull.com/core/metrics/first_metric';
export const ontologyLink = 'https://docs.sightfull.com/core/semantic_mapping/first_entity/';
export const dimensionLink = 'https://docs.sightfull.com/core/semantic_mapping/dimensions/';
export const relationshipLink = 'https://docs.sightfull.com/core/semantic_mapping/relationships/';
export const parametersLink = 'https://docs.sightfull.com/core/semantic_mapping/parameters/intro';

export function YAMLEditorDescription({ onClick, isEntity }: { onClick: VoidFunction; isEntity?: boolean }) {
	return (
		<Typography onClick={onClick} color={'gray.600'} variant="Paragraph12R">
			Edit your {isEntity ? 'entity' : 'metric'} in YAML format. For more information, please&nbsp;
			<Link
				textDecoration={'underline'}
				color={'blue.700'}
				target={'_blank'}
				href={isEntity ? ontologyLink : metricLink}
			>
				visit our help center
			</Link>
		</Typography>
	);
}
