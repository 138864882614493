import { Box, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useOnCopy } from 'src/common/hooks/interaction/useOnCopy';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import IconButton from '../../IconButton';
import { DuplicateSolid16, Ok16, Time16 } from '../../Icons';
import Tooltip from '../../Tooltip';
import classes from '../tableCell.module.scss';
import { SuffixIcon } from '../types';

const IconWrapper = ({ children, tooltipText }: { children: ReactNode; tooltipText?: string }) => {
	return (
		<Flex direction={'row'} justifyContent="center" alignItems="center" w={'24px'} h={'24px'}>
			<Tooltip label={tooltipText} placement="top" size="md" marginLeft="4px" hasArrow={false}>
				{children}
			</Tooltip>
		</Flex>
	);
};

const Copy = ({ contentToCopy }: { contentToCopy: string }) => {
	const { reportEvent } = useReportEvent();
	const { onCopyText } = useOnCopy();
	return (
		<IconWrapper tooltipText="Copy to clipboard">
			<Box className={classes.showWhenHover}>
				<IconButton
					size="xxs"
					icon={<DuplicateSolid16 />}
					colorScheme="lightGray"
					onClick={(e) => {
						onCopyText({ contentToCopy });
						reportEvent({ event: 'copy-cell-content', metaData: { contentToCopy } });
						e.stopPropagation();
					}}
					ariaLabel={'copy'}
				/>
			</Box>
		</IconWrapper>
	);
};

const Pending = () => {
	return (
		<IconWrapper tooltipText="Pending processing">
			<Time16 color={colors.orange['800']} aria-label={'pending'} />
		</IconWrapper>
	);
};

const Approved = () => {
	return (
		<IconWrapper>
			<Ok16 color={colors.green['700']} aria-label={'done'} />
		</IconWrapper>
	);
};

export const icons: { [key in SuffixIcon]: ({ contentToCopy }: { contentToCopy: string }) => JSX.Element } = {
	copy: Copy,
	pending: Pending,
	approved: Approved,
};
