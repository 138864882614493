import { MetricType } from 'src/common/types/common';
import { removeYAMLQuotes } from 'src/normalize';
import YAML from 'yaml';
import { isRecord } from '../MetricDefinition/normalizeMetricState';
import { KeysType } from 'src/pages/OntologyPage/hooks/useOntologyUpsertEntity';

export function upsertYAMLKey(
	yaml: string,
	key: string,
	value?: string | string[] | Record<string, unknown>[] | Record<string, unknown>
): string {
	const document = YAML.parseDocument(yaml);
	if (value === undefined) {
		document.delete(key);
	} else {
		document.set(key, value);
	}
	return removeYAMLQuotes(document.toString());
}

export function upsertYAMLObjectKey(
	yaml: string,
	key: string,
	value: string | { key: string; value: string | any[] }[]
): string {
	if (!Array.isArray(value)) {
		const document = YAML.parseDocument(yaml);
		document.set(key, value);
		return removeYAMLQuotes(document.toString());
	}
	const document = YAML.parseDocument(yaml);
	document.set(
		key,
		value.reduce((acc: { [key: string]: string | any }, { key, value }) => ({ ...acc, [key]: value }), {})
	);
	return document.toString();
}

export function singleMetricToFullYaml(metricDefinition: string, kind: MetricType) {
	const metricHeader = kind === 'formula' ? 'formula_metrics:\n' : 'metrics:\n';

	const wordRegex = /^(\w+):/;
	const metricDefinitionStart = metricDefinition.split('\n').findIndex((line) => line.trimStart().match(wordRegex));

	return (
		metricHeader +
		metricDefinition
			.split('\n')
			.map((line, i) => (metricDefinitionStart == i ? '  - ' + line : '    ' + line))
			.join('\n')
	);
}

export function removeEscaping(lines: string[]) {
	return lines.slice(1).map((line) => (line.startsWith('  - ') ? line.replace('  - ', '') : line.replace('    ', '')));
}

export function isSequenceExist(yamlString: string, sequenceName: string) {
	const doc = YAML.parseDocument(yamlString);
	return doc.has(sequenceName) && YAML.isSeq(doc.get(sequenceName));
}

export function mapNameIndexInSequence(yamlString: string, sequenceName: string, mapName: string) {
	const doc = YAML.parseDocument(yamlString);
	const sequence = doc.get(sequenceName);
	if (!(sequence && YAML.isSeq(sequence))) {
		throw new Error('Invalid sequence name');
	}

	return sequence.items.findIndex((item: any) => {
		return YAML.isMap(item) && item.has('name') && item.get('name') == mapName;
	});
}

export function isMapNameExistInSequence(yamlString: string, sequenceName: string, mapName: string) {
	return mapNameIndexInSequence(yamlString, sequenceName, mapName) !== -1;
}

export function replaceMapInSequence(yamlString: string, sequenceName: string, mapDefinition: string, mapName: string) {
	const doc = YAML.parseDocument(yamlString);
	const mapDocument = YAML.parseDocument(mapDefinition);

	const sequence = doc.get(sequenceName);
	if (!(sequence && YAML.isSeq(sequence))) {
		throw new Error('Invalid sequence name');
	}

	const index = mapNameIndexInSequence(yamlString, sequenceName, mapName);
	if (index == -1) {
		throw new Error(`Cant replace ${mapName} in ${sequenceName}, ${mapName} not found in ${sequenceName}`);
	}

	sequence.set(index, mapDocument);

	return doc.toString({ lineWidth: 0 });
}

export function deleteMapFromSequence(yamlString: string, sequenceName: string, mapName: string) {
	const doc = YAML.parseDocument(yamlString);
	const sequence = doc.get(sequenceName);
	if (!(sequence && YAML.isSeq(sequence))) {
		throw new Error('Invalid sequence name');
	}

	const index = mapNameIndexInSequence(yamlString, sequenceName, mapName);
	if (index === -1) {
		throw new Error(`Cannot delete ${mapName} from ${sequenceName}, ${mapName} not found in ${sequenceName}`);
	}

	sequence.delete(index);

	return doc.toString({ lineWidth: 0 });
}

export function insertMapInSequence(yamlString: string, sequenceName: string, mapDefinition: string) {
	const doc = YAML.parseDocument(yamlString);
	const mapDocument = YAML.parseDocument(mapDefinition);

	const sequence = doc.get(sequenceName);
	if (!(sequence && YAML.isSeq(sequence))) {
		doc.set(sequenceName, [mapDocument]);
	} else {
		doc.set(sequenceName, [...sequence.items, mapDocument]);
	}

	return doc.toString({ lineWidth: 0 });
}

export const transformObjectIntoKeysArray = (obj: unknown): { key: string; value: string | KeysType }[] => {
	if (!isRecord(obj)) return [];
	return Object.entries(obj).map(([key, value]) => ({
		key,
		value: Array.isArray(value)
			? value
			: typeof value === 'object' && value
			? transformObjectIntoKeysArray(value)
			: String(value),
	}));
};
