import Box from '@components/Box';
import Input from '@components/Input';
import { SelectOption } from 'src/common/components/Select/types';
import Breadcrumbs from './Breadcrumds';
import SubHeaderSection from './SubHeaderSection';
import { FiltersAndBreakdownItemType } from './types';

import { Search16 } from 'src/common/components/Icons';
import classes from './FiltersAndBreakdowns.module.scss';

type Props = {
	searchTerm: string;
	onSearchTermChange: (term: string) => void;
	path: FiltersAndBreakdownItemType[];
	traverseToSpecificPath: (item?: FiltersAndBreakdownItemType) => void;
	hasBaseNode: boolean;
	setNormalizedPropsSelectedOption: (option: SelectOption) => void;
	normalizedPropsSelectedOption: SelectOption;
	displayBackArrow?: boolean;
	displayShowNormalizedSelection?: boolean;
};

const HeaderSection = ({
	searchTerm,
	onSearchTermChange,
	path,
	traverseToSpecificPath,
	hasBaseNode,
	setNormalizedPropsSelectedOption,
	normalizedPropsSelectedOption,
	displayBackArrow = true,
	displayShowNormalizedSelection = true,
}: Props) => {
	return (
		<Box>
			<Box className={classes.headerSectionContainer}>
				<Breadcrumbs path={path} traverseToSpecificPath={traverseToSpecificPath} />
				<SubHeaderSection
					path={path}
					traverseToSpecificPath={traverseToSpecificPath}
					hasBaseNode={hasBaseNode}
					setNormalizedPropsSelectedOption={setNormalizedPropsSelectedOption}
					normalizedPropsSelectedOption={normalizedPropsSelectedOption}
					displayBackArrow={displayBackArrow}
					displayShowNormalizedSelection={displayShowNormalizedSelection}
				/>
			</Box>

			<Box className={classes.inputContainer}>
				<Input
					placeholder="Search"
					value={searchTerm}
					onChange={onSearchTermChange}
					leftComponent={<Search16 />}
					size="md"
					testId="modal-input"
					isErasable
				/>
			</Box>
		</Box>
	);
};

export default HeaderSection;
