import Box from '@components/Box';
import Flex from '@components/Flex';
import { ReactNode } from 'react';
import Typography from 'src/common/components/Typography';

export function AddWidgetListItem({
	icon,
	title,
	subtitle,
	onClick,
}: {
	icon: ReactNode;
	title: string;
	subtitle: string;
	onClick: VoidFunction;
}) {
	return (
		<Flex
			direction={'row'}
			gap="12px"
			paddingX="16px"
			paddingY="12px"
			justifyContent={'start'}
			_hover={{ backgroundColor: 'gray.200' }}
			as="button"
			onClick={onClick}
			textAlign="left"
			transition={'all 0.1s'}
		>
			<Box
				borderRadius="4px"
				height="32px"
				width="32px"
				border={'1px solid'}
				borderColor={'gray.400'}
				backgroundColor="white"
				padding="7px"
				color={'black'}
			>
				{icon}
			</Box>
			<Flex direction={'column'} gap="4px">
				<Typography variant="DesktopH8Bold" color="gray.800">
					{title}
				</Typography>
				<Typography variant="Paragraph12R" color={'gray.700'}>
					{subtitle}
				</Typography>
			</Flex>
		</Flex>
	);
}
