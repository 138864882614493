export const normalBaseSchema = {
	normalBackground: 'white',
	normalTextColor: 'gray.800',
	normalBorderColor: 'gray.400',
	normalPlaceholderColor: 'gray.600',
	disabledPlaceholderColor: 'gray.500',
	hoverBorderColor: 'gray.500',
	errorBorderColor: 'red.800',
	disabledTextColor: 'gray.500',
	disabledBorderColor: 'gray.400',
	disabledBackground: 'gray.100',
	focusBorderColor: 'gray.800',
};

const ghostBaseSchema = {
	normalBackground: 'white',
	normalTextColor: 'gray.800',
	normalBorderColor: 'gray.400',
	normalPlaceholderColor: 'gray.600',
	disabledPlaceholderColor: 'gray.500',
	hoverBorderColor: 'gray.500',
	errorBorderColor: 'red.800',
	disabledTextColor: 'gray.500',
	disabledBorderColor: 'gray.400',
	disabledBackground: 'gray.100',
	focusBorderColor: 'gray.800',
};

export const baseSchema: Record<string, any> = {
	normal: {
		regular: normalBaseSchema,
	},
	ghost: {
		regular: ghostBaseSchema,
	},
};
