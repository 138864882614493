import Box from '@components/Box';
import { $createHeadingNode } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import {
	$createParagraphNode,
	$createTextNode,
	$getRoot,
	$getSelection,
	$isElementNode,
	$isRangeSelection,
	$isRootNode,
	$isTextNode,
	LexicalEditor,
	TextNode,
} from 'lexical';
import ListItem from 'src/common/components/ListItem';
import { blockTypeToBlockName } from '../consts';
import { H1, H2, Paragraph } from '../icons';
import { BlockType } from '../types';

import classes from '../styles/TextEditor.module.scss';
import { getSelectedNode } from '../utils';

const { selectContainer } = classes;

function BlockOptionsDropdownList({
	editor,
	blockType,

	setShowBlockOptionsDropDown,
}: {
	editor: LexicalEditor;
	blockType: BlockType | '';
	setShowBlockOptionsDropDown: (show: boolean) => void;
}) {
	const formatParagraph = () => {
		if (blockType != 'paragraph' && blockType != 'root') {
			editor.update(() => {
				const selection = $getSelection();

				if ($isRangeSelection(selection)) {
					const node = getSelectedNode(selection) as TextNode;
					const focusOffset = node.__text.length + 1;
					selection.setTextNodeRange(node, 0, node, focusOffset);
					selection.formatText('bold');

					$wrapNodes(selection, () => $createParagraphNode());
				}
			});
		}
		setShowBlockOptionsDropDown(false);
	};

	const handleHeadingStyle = (headingType: 'h1' | 'h2') => {
		const selection = $getSelection();

		if ($isRangeSelection(selection)) {
			let node = getSelectedNode(selection);
			if (!$isTextNode(node)) {
				node = $createTextNode();
			}
			const headingNode = $createHeadingNode(headingType);
			const parentNode = node.getParent() ?? $getRoot();

			if ($isElementNode(parentNode) && !$isRootNode(parentNode)) {
				parentNode.replace(headingNode, true);
			} else {
				headingNode.append(node);
				parentNode.append(headingNode);
			}

			const focusOffset = node.__text?.length || 1;
			selection.setTextNodeRange(node, 0, node, focusOffset);

			if (!selection.hasFormat('bold')) {
				selection.formatText('bold');
			}
		}
	};

	const formatH1 = () => {
		if (blockType !== 'h1') {
			editor.update(() => handleHeadingStyle('h1'));
		}
		setShowBlockOptionsDropDown(false);
	};

	const formatH2 = () => {
		if (blockType !== 'h2') {
			editor.update(() => handleHeadingStyle('h2'));
		}
		setShowBlockOptionsDropDown(false);
	};

	const { h1, h2, paragraph } = blockTypeToBlockName;

	return (
		<Box className={selectContainer}>
			<ListItem key={paragraph} label={paragraph} prefixIcon={<Paragraph />} onClick={formatParagraph} size="sm" />
			<ListItem key={h1} label={h1} prefixIcon={<H1 />} onClick={formatH1} size="sm" />
			<ListItem key={h2} label={h2} prefixIcon={<H2 />} onClick={formatH2} size="sm" />
		</Box>
	);
}

export default BlockOptionsDropdownList;
