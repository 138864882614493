import { ElementTransformer } from '@lexical/markdown';
import { $createParagraphNode, $createTextNode, $isParagraphNode } from 'lexical';

// This is a due to Lexical not supporting the `\` character for creating a new line.
// This also allows us to support new lines in the editor based on the `\` character.

export const EOL_SLASH: ElementTransformer = {
	dependencies: [],
	regExp: /^\\$/,
	export: (node) => {
		if (($isParagraphNode(node) && node.getTextContent() === '') || node.getTextContent() === '\n') {
			return '\\';
		}
		return null;
	},
	replace: (parentNode, children, _match, isImport) => {
		if (isImport) {
			const paragraph = $createParagraphNode();
			paragraph.append($createTextNode(''), $createTextNode(''));
			parentNode.replace(paragraph);
		}
	},
	type: 'element',
};
