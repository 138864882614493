import {
	AGGREGATE_METRIC_OPERATIONS,
	Filter,
	JOIN_TYPES,
	Join,
	JoinType,
	MetricDefinitionState,
	MetricDefinitionStateAggregate,
	MetricDefinitionStateFormula,
} from 'src/pages/MetricPage/utils/editor.types';

export function normalizeMetricState(
	metricDataRaw: unknown,
	type: MetricDefinitionState['type']
): MetricDefinitionState {
	if (!isMetricData(metricDataRaw)) throw 'Invalid metric data';
	const metricData = {
		...metricDataRaw,
		type,
	};

	if (type === 'formula') {
		if (!isFormulaMetric(metricData)) throw 'Invalid formula metric data';
		const metricDataFormula: MetricDefinitionState = {
			type: 'formula',
			entity: metricData['entity'],
			name: metricData['name'],
			formula: metricData['formula'],
			meta: metricData['meta'],
		};

		return metricDataFormula;
	}

	if (type === 'aggregate') {
		if (!isAggregateMetric(metricData)) throw 'Invalid aggregate metric data';

		const metricDataAgg: MetricDefinitionState = {
			type: 'aggregate',
			entity: metricData['entity'],
			name: metricData['name'],
			meta: metricData['meta'],
			filters: metricData['filters'],
			joins: metricData['joins'],
			measure: metricData['measure'],
			x_axis: metricData['x_axis'],
			operation: metricData['operation'],
		};
		return metricDataAgg;
	}
	throw 'Invalid metric type';
}

export function isMetricData(data: unknown): data is MetricDefinitionState {
	if (!isRecord(data)) return false;
	if (!('entity' in data)) return false;
	if (!('name' in data)) return false;
	if (typeof data['entity'] !== 'string' && data['entity'] !== null) return false;
	if (typeof data['name'] !== 'string' && data['name'] !== null) return false;
	if ('meta' in data && !isValidMeta(data['meta'])) return false;

	return true;
}
export function isFormulaMetric(data: Record<string, unknown>): data is MetricDefinitionStateFormula {
	if (!isRecord(data)) return false;
	if (!isMetricData(data)) return false;
	if (!('formula' in data)) return false;
	if (typeof data['formula'] !== 'string' && data['formula'] !== null) return false;

	return true;
}
export function isAggregateMetric(data: Record<string, unknown>): data is MetricDefinitionStateAggregate {
	if (!isMetricData(data)) return false;
	if ('kind' in data && data['kind'] !== 'aggregate') return false;
	if (!('operation' in data)) return false;
	if ('measure' in data && typeof data['measure'] !== 'string') return false;
	if (!isValidAggOperation(data['operation'])) return false;

	if ('filters' in data) {
		if (!Array.isArray(data['filters']) || !data['filters'].every(isValidFilter)) return false;
	}
	if (!!data['joins'] && !isValidJoinsArray(data['joins'])) return false;
	if ('x_axis' in data) {
		if (data['x_axis']?.period?.length && !data['x_axis']?.period?.every(isValidXAxis)) return false;
	}

	return true;
}

function isValidMeta(meta: unknown): meta is MetricDefinitionState['meta'] {
	if (!isRecord(meta)) return false;
	if ('description' in meta && typeof meta['description'] !== 'string') return false;
	if ('display_name' in meta && typeof meta['display_name'] !== 'string') return false;
	return true;
}

function isValidAggOperation(operation: unknown): operation is MetricDefinitionStateAggregate['operation'] {
	if (typeof operation !== 'string') return false;
	return AGGREGATE_METRIC_OPERATIONS.some((s: string) => s === operation);
}

function isValidJoinType(join: unknown): join is JoinType {
	if (typeof join !== 'string') return false;
	return JOIN_TYPES.some((s: string) => s === join);
}

function isValidJoinsArray(joins: unknown): joins is MetricDefinitionStateAggregate['joins'] {
	if (!Array.isArray(joins)) return false;
	if (!joins.every(isValidJoin)) return false;
	return true;
}

export function isValidJoin(join: unknown): join is Join {
	if (!isRecord(join)) return false;
	if (!('select' in join) || typeof join['select'] !== 'string') return false;
	if (!('from' in join) || typeof join['from'] !== 'string') return false;
	if (!('alias' in join) || typeof join['alias'] !== 'string') return false;

	if ('filters' in join && !isValidJoinFilter(join['filters'])) return false;
	if ('join_type' in join && !isValidJoinType(join['join_type'])) return false;
	if ('join_type' in join && !isValidJoinType(join['join_type'])) return false;
	if ('meta' in join && !isValidMeta(join['meta'])) return false;

	return true;
}

function isValidJoinFilter(filter: unknown): filter is string[] {
	if (!Array.isArray(filter)) return false;
	if (!filter.every(isValidFilter)) return false;
	return true;
}

export function isValidFilter(filter: unknown): filter is Filter {
	if (!isRecord(filter)) return false;
	if (!('sql' in filter)) return false;
	if (typeof filter['sql'] !== 'string') return false;

	return true;
}

export function isValidXAxis(axis: unknown): axis is object {
	if (!isRecord(axis)) return false;
	if (!('sql' in axis)) return false;
	if (typeof axis['sql'] !== 'string') return false;
	return true;
}

export function isRecord(data: unknown): data is Record<string, unknown> {
	if (!data) return false;
	if (!(typeof data == 'object')) return false;
	if (Array.isArray(data)) return false;

	return true;
}
