import Flex from '@components/Flex';
import {
	Account24,
	Campaign24,
	Contact24,
	Departments24,
	Entity24,
	GenericEntity24,
	Lead24,
	Opportunity24,
	OpportunityHistory24,
	Product24,
	Transactions24,
} from '@icons/index';
import colors from 'src/style/colors';

export function Avatar({ entityType }: { entityType: string }) {
	return (
		<Flex
			bg={'gray.200'}
			p={'8px'}
			maxHeight={'40px'}
			maxWidth={'40px'}
			borderRadius={'40px'}
			color={colors.gray['800']}
		>
			{IconByEntityType[entityType] ?? <GenericEntity24 />}
		</Flex>
	);
}

const IconByEntityType: { [key: string]: JSX.Element } = {
	Account: <Account24 />,
	Campaign: <Campaign24 />,
	Contact: <Contact24 />,
	Departments: <Departments24 />,
	Entity: <Entity24 />,
	Lead: <Lead24 />,
	Opportunity: <Opportunity24 />,
	OpportunityHistory: <OpportunityHistory24 />,
	Product2: <Product24 />,
	Transactions: <Transactions24 />,
};
