import { Box, Flex, ModalContent, useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import Alert from 'src/common/components/Alert';
import Markdown from 'src/common/components/Markdown';
import Modal from 'src/common/components/Modal';
import ModalHeader from 'src/common/components/ModalHeader';
import Typography from 'src/common/components/Typography';
import useMutation from 'src/common/hooks/fetching/useMutation';
import useToast from 'src/common/hooks/ui/useToast';
import { UpsertDataOverrideMutation } from 'src/generated/graphql';
import { UpsertDataOverride } from 'src/queries/nodes';
import { usePermissionCheck } from 'src/stores/environment';
import shadows from 'src/style/shadows';
import { Permissions } from 'src/types/environment';
import { ApproveModal, CurrentPropertyLastChange, Fields, ModalFooter } from '..';
import { useEntityProfileReportEvent } from '../../hooks';
import { ChangeableContent, FieldType, LastChangeProps, ModalFieldValue, PropertyDetailsModalProps } from '../../types';
import { getLastChangeProps, inputValueByFormatter, isInputValueChanged } from '../../utilities';

export function PropertyDetailsModal(props: PropertyDetailsModalProps) {
	const isHasReadPermission = usePermissionCheck().isHavingPermission(Permissions.overrideRead);
	const isHasCrudPermission = usePermissionCheck().isHavingPermission(Permissions.crudOverride);
	const isHasEditPermission = usePermissionCheck().isHavingPermission(Permissions.overrideEdit);
	const isHasResetPermission = usePermissionCheck().isHavingPermission(Permissions.overrideReset);

	if (!isHasReadPermission && !isHasEditPermission && !isHasResetPermission && !isHasCrudPermission) return <></>;

	return (
		<PropertyDetailsModalGuarded
			{...props}
			isHasEditPermission={isHasCrudPermission || isHasEditPermission}
			isHasResetPermission={isHasCrudPermission || isHasResetPermission}
		/>
	);
}

export const PropertyDetailsModalGuarded = ({
	isOpen,
	onClose,
	isHasEditPermission,
	isHasResetPermission,
	property,
	objectId,
	source,
	testId,
	nodeObjectName,
}: PropertyDetailsModalProps & {
	isHasEditPermission: boolean;
	isHasResetPermission: boolean;
}) => {
	const { isOpen: isOpenApproveModal, onClose: onCloseApproveModal, onOpen: onOpenApproveModal } = useDisclosure();
	const [mutateProperty] = useMutation<UpsertDataOverrideMutation>(UpsertDataOverride);
	const toast = useToast();
	const { reportEvent } = useEntityProfileReportEvent('property-modal', nodeObjectName);

	const [newValue, setNewValue] = useState<ChangeableContent | { value: null }>({
		value: null,
	});

	useEffect(() => {
		if (property == null) {
			return;
		}

		const formattedValue = inputValueByFormatter(property, property?.modelDimensionProps?.type as FieldType);
		if (formattedValue != null) setNewValue(() => ({ value: formattedValue, comment: '' }));
	}, [property]);

	const onChange = (key: keyof ChangeableContent, value: ModalFieldValue) => {
		setNewValue((prev) => ({
			...prev,
			[key]: value,
		}));
		reportEvent({
			event: 'change-value-property-details-modal',
			metaData: { field: key, value },
		});
	};

	const saveChanges = (newValue: ChangeableContent) => {
		const comment = newValue?.comment ?? '';
		const dimension = property?.rawName;

		const variables = {
			comment,
			dimension,
			object_id: objectId,
			value: newValue.value,
		};

		reportEvent({ event: 'save-property-value', metaData: { objectId, newValue: newValue.value, dimension } });

		mutateProperty({
			variables,
		})
			.then(() => toast({ variant: 'ok', message: `Change saved` }))
			.catch(() => toast({ variant: 'error', message: `Failed :/ Try again` }));
		onClose();
	};

	const lastChangeProps = useMemo(
		() =>
			property &&
			getLastChangeProps(
				{ overrideProps: property?.overrideProps, value: property.value },
				property?.modelDimensionProps?.type as FieldType
			),
		[property]
	);

	const shouldAllowEditMode = !!property?.modelDimensionProps?.editable && isHasEditPermission;
	const shouldShowChangeAlert =
		property?.overrideProps?.status == 'Normalized' || property?.overrideProps?.status == 'Pending';

	const isChanged = useMemo(() => {
		return (
			newValue.value != null &&
			isInputValueChanged(
				newValue.value,
				lastChangeProps?.lastChangeValue,
				property?.modelDimensionProps?.type as FieldType
			)
		);
	}, [lastChangeProps, newValue.value, property?.modelDimensionProps?.type]);

	if (newValue.value == null) {
		return <></>;
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} isCentered data-testid={testId}>
				<ModalContent minWidth={'500px'} maxHeight="615px">
					<ModalHeader size="md" title="Property details" onClose={onClose} />
					<Box overflowY={'auto'}>
						{property?.modelDimensionProps?.description && (
							<Alert
								padding={'12px 16px'}
								status={'info'}
								DescriptionComponent={() => (
									<Typography variant="Paragraph14R">
										<Markdown message={property?.modelDimensionProps?.description ?? ''} />
									</Typography>
								)}
							/>
						)}
						<Flex p={'24px 32px 0px 24px'} direction={'column'} height={'100%'}>
							<Box flex={1}>
								<Fields
									isHasEditPermission={shouldAllowEditMode}
									fieldName={property?.displayName ?? ''}
									fieldType={(property?.modelDimensionProps?.type as FieldType) ?? 'string'}
									onChange={onChange}
									newValue={newValue}
								/>
								{lastChangeProps?.editedAt && (
									<CurrentPropertyLastChange
										fieldName={property?.displayName ?? ''}
										source={source}
										lastChangeProps={lastChangeProps as LastChangeProps}
										fieldType={(property?.modelDimensionProps?.type as FieldType) ?? 'string'}
										isHasResetPermission={isHasResetPermission}
										overrideProps={property?.overrideProps}
										onReset={() => saveChanges({ value: null, comment: newValue?.comment })}
									/>
								)}
							</Box>
						</Flex>
					</Box>
					<Box boxShadow={shadows.borderTop}>
						<ModalFooter
							onCancel={onClose}
							isDisabled={!isChanged}
							onSave={onOpenApproveModal}
							isEditMode={shouldAllowEditMode}
							shouldShowChangeAlert={shouldShowChangeAlert}
						/>
					</Box>
				</ModalContent>
			</Modal>
			<ApproveModal
				isOpen={isOpenApproveModal}
				onClose={onCloseApproveModal}
				onApprove={() => saveChanges(newValue)}
				description={
					'Editing this will affect data and metrics for all users. It will come into affect in the next 24 hours.'
				}
			/>
		</>
	);
};
