import { ChartOptions, SeriesDataPointObject } from './types';
import { Series, TooltipFormatterContextObject } from 'highcharts';
import { DisplayLegendItems } from 'src/pages/MetricPage/utils/state.types';
import { SeriesSinglePeriodPlaceholderName } from 'src/lib/metricRules/statisticOperations/constants';

export const extractStatValue = ({
	stat,
	instance,
}: {
	stat: Series;
	instance: TooltipFormatterContextObject;
}): string => {
	let value = '';

	if (stat.data.length === 1) {
		value = (stat.data[0] as any)?.custom?.value; //TODO: fix typing, I'm sorry!
	} else {
		value = (stat.data[instance.point.index] as any)?.custom?.value;
	}

	const formatter = stat.options.custom?.seriesDataPointYFormatter ?? ((x: any) => x);

	return formatter(value);
};

export const setVisibility = (series: SeriesDataPointObject, displayedLegendItems: DisplayLegendItems) => {
	const visible = displayedLegendItems.selectedValues.includes(series.name);
	return {
		...series,
		visible,
	};
};

const getFormattedValue = (instance: Record<string, any>, value: string | number | undefined | null) => {
	if (value == null) return value;

	const seriesDataPointYFormatter = instance.series?.options?.custom?.seriesDataPointYFormatter;
	return seriesDataPointYFormatter ? seriesDataPointYFormatter(value) : value;
};

export const getTooltipContent = (instance: TooltipFormatterContextObject, chartOptions: ChartOptions) => {
	const seriesInChartOptions = chartOptions.series.find((s) => [instance.key, instance.series.name].includes(s.name));
	const { name, value, xSeriesValue } =
		seriesInChartOptions?.chartType == 'attainment' // TODO: This will be changed in the future
			? {
					name: instance.point.name,
					value: seriesInChartOptions.data.find((dp) => dp.name == instance.point.name)?.custom?.label,
					xSeriesValue: instance.key,
			  }
			: getTooltipContentByType(instance);
	return { name, value, xSeriesValue, appliedParameters: seriesInChartOptions?.custom.appliedParameters };
};

export const getTooltipContentByType = (instance: TooltipFormatterContextObject) => {
	let value: string | undefined = '';
	let xSeriesValue: number | string | undefined = '';
	let name: string | undefined = '';

	const isSinglePeriod = instance.series.name == SeriesSinglePeriodPlaceholderName; // TODO: to figure out
	if (isSinglePeriod) {
		name = instance.key;
		value = getFormattedValue(instance, instance.y);
	} else {
		switch (instance.series.options.type) {
			case 'column':
			case 'line':
				name = instance.series.name;
				xSeriesValue = instance.key;
				value = getFormattedValue(instance, instance.y);
				break;

			case 'bubble': {
				//TODO: fix typing, I'm sorry!
				const point = instance.point as any;
				name = instance.series.name;
				xSeriesValue = point.id;
				value = getFormattedValue(instance, point.custom?.value);
				break;
			}
		}
	}

	return {
		name,
		value,
		xSeriesValue,
	};
};

export const getStatsElement = (series: Series[], id: string) => {
	return series.find((series: Series) => series.options.name === id);
};

export const shouldDisplayStatByCurrentSeries = ({
	stat,
	currentHoveredSeries,
}: {
	stat?: Series;
	currentHoveredSeries: Series;
}): boolean => !!stat && currentHoveredSeries.options.name !== stat.options.name;

const isObject = (obj: any) => obj && typeof obj === 'object';

export const mergeDeep = (...objects: Record<string, any>[]) => {
	return objects.reduce((prev, obj) => {
		Object.keys(obj).forEach((key) => {
			const pVal = prev[key];
			const oVal = obj[key];

			if (Array.isArray(pVal) && Array.isArray(oVal)) {
				prev[key] = pVal.concat(...oVal);
			} else if (isObject(pVal) && isObject(oVal)) {
				prev[key] = mergeDeep(pVal, oVal);
			} else {
				prev[key] = oVal;
			}
		});

		return prev;
	}, {});
};
