import { QueryMatch } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { useEffect, useState } from 'react';
import { Mention } from 'src/types/mention';
import { AtSignMentionsRegex, AtSignMentionsRegexAliasRegex, CapitalizedNameMentionsRegex } from './consts';
import { filterStringByTerm } from 'src/common/utils/utils';

export function mentionsLookup(mentionString: string, mentions: Mention[]) {
	return mentions.filter(
		(mention) => (mention.name != null && filterStringByTerm(mentionString, mention.name)) || false
	);
}

export function useMentionLookup(mentionString: string | null, mentions: Mention[]) {
	const [results, setResults] = useState<Mention[]>([]);

	useEffect(() => {
		const mentionStr = mentionString || '';

		const results = mentionsLookup(mentionStr, mentions);
		setResults(results);
	}, [mentionString, mentions]);

	return results;
}

export function checkForCapitalizedNameMentions(text: string, minMatchLength: number): QueryMatch | null {
	const match = CapitalizedNameMentionsRegex.exec(text);

	if (!match) {
		return null;
	}

	// The strategy ignores leading whitespace but we need to know it's
	// length to add it to the leadOffset
	const maybeLeadingWhitespace = match[1];
	const matchingString = match[2];

	if (matchingString != null && matchingString.length >= minMatchLength) {
		return {
			leadOffset: match.index + maybeLeadingWhitespace.length,
			matchingString,
			replaceableString: matchingString,
		};
	}

	return null;
}

export function checkForAtSignMentions(text: string): QueryMatch | null {
	let match = AtSignMentionsRegex.exec(text);

	if (match === null) {
		match = AtSignMentionsRegexAliasRegex.exec(text);
	}

	if (!match) {
		return null;
	}

	// The strategy ignores leading whitespace but we need to know it's
	// length to add it to the leadOffset
	const maybeLeadingWhitespace = match[1];
	const matchingString = match[3];
	if (typeof matchingString === 'string') {
		return {
			leadOffset: match.index + maybeLeadingWhitespace.length,
			matchingString,
			replaceableString: match[2],
		};
	}

	return null;
}

export function getPossibleQueryMatch(text: string): QueryMatch | null {
	const match = checkForAtSignMentions(text);
	return match || checkForCapitalizedNameMentions(text, 3);
}

export const transformMentions = (markdown: string, mentions: Mention[], chosenMentions: string[]) => {
	let clonedMarkdown = markdown.slice();

	chosenMentions.map((mentionName) => {
		if (clonedMarkdown.includes(mentionName)) {
			const mention = mentions.find((m) => m.name === mentionName);

			if (mention) {
				clonedMarkdown = clonedMarkdown.replaceAll(mentionName, `[@${mention.email}](${mention.id})`);
			}
		}
	});

	return clonedMarkdown;
};
