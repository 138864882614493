import { ModalHeader } from '@chakra-ui/react';
import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import Modal from '@components/Modal';
import Typography from '@components/Typography';
import { usePreventReload } from '@hooks/usePreventReload';
import { CloseLarge16 } from '@icons/index';
import { useCallback, useState } from 'react';
import useToast from 'src/common/hooks/ui/useToast';
import { useSemanticsStatus } from 'src/common/hooks/useSemanticsStatus';
import { InstallingStep } from 'src/pages/MetricCatalog/TemplateOnboardingModal/components/InstallingStep';
import { StartStep } from 'src/pages/MetricCatalog/TemplateOnboardingModal/components/StartStep';
import { TemplateAlreadyInstalledModal } from 'src/pages/MetricCatalog/TemplateOnboardingModal/components/TemplateAlreadyInstalledModal';
import { TemplateNotFoundModal } from 'src/pages/MetricCatalog/TemplateOnboardingModal/components/TemplateNotFoundModal';
import { ToastMessage } from 'src/pages/MetricCatalog/TemplateOnboardingModal/components/ToastMessage';
import { useReportEvent } from 'src/services/analytics';
import { useInvalidateCache } from 'src/services/apollo';
import useNavigation from 'src/services/useNavigation';
import shadows from 'src/style/shadows';
import classes from './TemplateOnboardingModal.module.scss';
import { useAddTemplate } from './hooks/useAddTemplate';
import { ModalProps, ProcessStatus } from './types';

export default function TemplateOnboardingModal({
	isOpen,
	onClose,
	referralPageName,
	templateName,
	templatesTag,
}: ModalProps) {
	const { navigate } = useNavigation();
	const { reportEvent, wrapWithReport } = useReportEvent();
	const eventMetaData = {
		feature: 'Template installation',
		template: templateName,
	};
	const [processStatus, setProcessStatus] = useState<ProcessStatus>('start');
	const [progressValue, setProgressValue] = useState(0);
	const [metricsAdded, setMetricsAdded] = useState(0);
	const isFirstStep = processStatus === 'start' || processStatus === 'alreadyInstalled';
	const toast = useToast();
	const { invalidateCache } = useInvalidateCache();
	const [, { refetchSemanticsStatus }] = useSemanticsStatus();

	usePreventReload({ dependency: processStatus === 'installing' });

	const onModalClose = useCallback(() => {
		onClose();

		if (processStatus === 'success') {
			invalidateCache();
		}

		navigate({
			path: 'metrics',
		});
	}, [onClose, processStatus, navigate, invalidateCache]);

	const [addTemplate] = useAddTemplate({ templateName, templatesTag });

	const getIsNextButtonDisabled = () => {
		return processStatus === 'installing' || processStatus === 'templateNotFound';
	};

	const nextStep = () => {
		switch (processStatus) {
			case 'start':
				return installTemplate();
			case 'success':
				return (() => {
					refetchSemanticsStatus?.();
					reportEvent({ event: 'template-finished-successfully', metaData: { ...eventMetaData, action: 'done' } });
					onModalClose();
					toast({ variant: 'ok', message: <ToastMessage metricsAdded={metricsAdded} />, duration: 5000 });
				})();
			case 'installationFailed':
				return (() => {
					reportEvent({ event: 'template-finished-error', metaData: { ...eventMetaData, action: 'close' } });
					onModalClose();
				})();
		}
	};

	const installTemplate = () => {
		reportEvent({ event: 'setup-template-click', metaData: { ...eventMetaData, action: 'install' } });
		setProcessStatus('installing');
		// TODO: think about adding time prop to Progress component
		const intervalId = setInterval(() => {
			// should come to 90% approximately in 20 secs
			setProgressValue((prev) => (prev < 90 ? prev + 0.5 : prev));
		}, 200);

		addTemplate()
			.then((res) => {
				clearInterval(intervalId);
				const addTemplateData = res?.data?.addTemplate;

				if (res.errors?.length) {
					throw res.errors[0];
				}

				if (addTemplateData?.status === 'fail') {
					throw new Error('Template installing fail');
				}

				if (addTemplateData?.status === 'template_already_exists') {
					return setProcessStatus('alreadyInstalled');
				}

				setMetricsAdded(addTemplateData?.metricsAdded || 0);
				setProgressValue(100);
				setProcessStatus('success');
			})
			.catch((error) => {
				console.log(error);
				setProcessStatus('installationFailed');
			});
	};

	const getNextStepButtonLabel = () => {
		switch (processStatus) {
			case 'start':
			case 'alreadyInstalled':
				return 'Install template';
			case 'installationFailed':
				return 'Close';
			default:
				return 'Done';
		}
	};

	const getBodyContent = () => {
		switch (processStatus) {
			case 'start':
			case 'alreadyInstalled':
				return (
					<StartStep
						referralPageName={referralPageName}
						templateName={templateName}
						templatesTag={templatesTag}
						setProcessStatus={setProcessStatus}
						processStatus={processStatus}
						onClose={onClose}
						onModalClose={onModalClose}
					/>
				);
			case 'installing':
			case 'success':
			case 'installationFailed':
				return <InstallingStep processStatus={processStatus} progressValue={progressValue} />;
		}
	};

	return (
		<>
			<Modal
				isCentered
				hasOverlay
				closeOnOverlayClick={false}
				isOpen={isOpen}
				onClose={() => onModalClose()}
				maxWidth={'800px'}
			>
				<ModalHeader p="16px" boxShadow={shadows.borderBottom} height={'64px'}>
					<Flex justifyContent={'center'} alignItems={'center'} className={classes.modalHeader}>
						{isFirstStep && (
							<Button
								variant="outline"
								isIconOnly
								onClick={wrapWithReport(onModalClose, 'setup-template-click', { ...eventMetaData, action: 'cancel' })}
								size="small"
								colorScheme="black"
								className={classes.modalCloseButton}
							>
								<CloseLarge16 />
							</Button>
						)}
						<Typography variant={'DesktopH7Medium'} color="gray.1000">
							{processStatus === 'start' ? 'Setup template' : 'Installing template'}
						</Typography>
					</Flex>
				</ModalHeader>
				<Box width={'800px'} p={'32px'}>
					<Flex flexDirection={'column'} height={'382px'}>
						{getBodyContent()}
					</Flex>
				</Box>
				<Flex
					boxShadow={shadows.borderTop}
					justifyContent={isFirstStep ? 'space-between' : 'flex-end'}
					padding={'19px 24px'}
				>
					{isFirstStep && (
						<Button
							variant="ghost"
							colorScheme="gray"
							onClick={wrapWithReport(onModalClose, 'setup-template-click', { ...eventMetaData, action: 'cancel' })}
						>
							Cancel
						</Button>
					)}

					<Button variant="solid" colorScheme="blue" onClick={() => nextStep()} isDisabled={getIsNextButtonDisabled()}>
						{getNextStepButtonLabel()}
					</Button>
				</Flex>
			</Modal>
			<TemplateAlreadyInstalledModal
				isOpen={isOpen && processStatus === 'alreadyInstalled'}
				onClose={onModalClose}
				templateName={templateName}
			/>
			<TemplateNotFoundModal isOpen={processStatus === 'templateNotFound'} templateName={templateName} />
		</>
	);
}
