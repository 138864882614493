import { SeriesDataPointY, SeriesDataPointYFormatter } from '@components/Chart/types';

export function percentageFormatter(
	yValue: SeriesDataPointY,
	decimalDigits?: number | null
): ReturnType<SeriesDataPointYFormatter> {
	const number = parseFloat(`${yValue}`) * 100;
	const isNotANumber = isNaN(number);
	if (isNotANumber) {
		return '0%';
	}

	if (Number.isSafeInteger(number)) {
		return `${number}%`;
	}

	if (decimalDigits == 0) {
		return `${Math.trunc(number)}%`;
	}

	return `${number.toFixed(decimalDigits == undefined || decimalDigits == null ? 1 : decimalDigits)}%`;
}

export function dollarFormatter(
	yValue: SeriesDataPointY,
	decimalDigits?: number
): ReturnType<SeriesDataPointYFormatter> {
	if (!yValue) return '0';

	const dollarFormatter = new Intl.NumberFormat('en-US', {
		notation: 'compact',
		style: 'currency',
		currency: 'usd',
		minimumFractionDigits: 0,
		maximumFractionDigits: decimalDigits ?? 1,
	});

	return dollarFormatter.format(yValue);
}

export function buildDollarFormatter(decimalDigits?: number | null): (value: SeriesDataPointY) => string {
	return (yValue: SeriesDataPointY) => dollarFormatter(yValue, decimalDigits ?? undefined);
}

export function numberFormatter(
	yValue: SeriesDataPointY,
	decimalDigits?: number | null
): ReturnType<SeriesDataPointYFormatter> {
	if (!yValue) return '0';

	const formatter = Intl.NumberFormat('en-US', {
		notation: 'compact',
		minimumFractionDigits: 0,
		maximumFractionDigits: decimalDigits ?? 1,
	});

	return formatter.format(yValue);
}
