import { languages, Range } from 'monaco-editor';
import { Context } from '../completionProvider';
import { AUTO_COMPLETE_KINDS, DESCRIPTION_BY_TYPE, SORT_ORDER } from '../types';
import { getContextCompletionTooltip } from './contextTooltip';

export function getContextCompletionItem(
	context: Context,
	replacementRange: Range,
	prefix: string,
	isAlwaysTriggeringSuggest: boolean
): languages.CompletionItem {
	const insertText = createInsertText(context, prefix);
	return {
		label: {
			label: context.name,
			detail: getLabelDetail(context),
			description: DESCRIPTION_BY_TYPE[context.type],
		},
		filterText: insertText,
		insertText,
		sortText: `${SORT_ORDER[context.type]}_${context.name}`,
		kind: AUTO_COMPLETE_KINDS[context.type] ?? undefined,
		documentation: getContextCompletionTooltip(context, insertText),
		range: replacementRange,
		command:
			context.type === 'relationship' || isAlwaysTriggeringSuggest
				? { title: 'trigger', id: 'editor.action.triggerSuggest' }
				: undefined,
	};
}
function getLabelDetail(context: Context) {
	if (context.display_name) {
		return ` ${context.display_name}`;
	} else if (context.type === 'relationship' && context.entity) {
		const displayName = context.entity.meta?.display_name ?? context.entity.name;
		return ` ${displayName}`;
	}
	return '';
}
function createInsertText(context: Context, prefix: string) {
	const suffix = context.type === 'relationship' || context.type == 'parameter_store' ? '.' : ' ';
	return `${prefix}${context.keyword}${suffix}`;
}
