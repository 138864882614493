import { ReactElement } from 'react';
import Button from 'src/common/components/Button';
import { ButtonProps } from 'src/common/components/Button/Button';
import { Play16 } from 'src/common/components/Icons';
import classes from './Header.module.scss';
import { ButtonSizeType } from 'src/common/components/Button/types';

export function MetricButton({
	onClick,
	isDisabled = false,
	eventName,
	eventMetadata,
	children,
	isTextOnly,
	isLoading,
	leftIcon,
	size,
}: {
	onClick?: () => void;
	isDisabled?: boolean;
	children: string;
	eventName?: string;
	eventMetadata?: Record<string, any>;
	isTextOnly?: boolean;
	isLoading?: boolean;
	leftIcon?: ReactElement;
	size?: ButtonSizeType;
}) {
	const buttonProps: ButtonProps = {
		colorScheme: isDisabled ? 'black' : undefined,
		marginLeft: '12px',
		paddingRight: '12px',
		isDisabled: isDisabled,
		variant: 'solid',
		onClick: onClick,
		size: size ? size : isTextOnly ? 'inline' : 'small',
		eventName: eventName,
		eventMetadata: eventMetadata,
		className: classes.blueButton,
	};

	return isTextOnly ? (
		<Button isLoading={isLoading} {...buttonProps}>
			{children}
		</Button>
	) : (
		<Button isLoading={isLoading} {...buttonProps} leftIcon={leftIcon || <Play16 />}>
			{children}
		</Button>
	);
}
