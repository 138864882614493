import { editor, languages, Position } from 'monaco-editor';
import { textFieldIdToFileUri } from 'src/common/components/BuilderTextInput/BuilderTextInputField';
import { CompletionProvider, DimensionContext, functionContexts } from '../completionProvider';
import { SuggestionsContextSettings } from '../hooks/useMonacoTextFieldProviders';
import { EnrichedSemanticDefinitions } from '../semanticTypes';
import { AUTO_COMPLETE_KINDS } from '../types';
import {
	getEntity,
	getMetricByNameFromList,
	getPartAndExpressionRanges,
	getWordRangeAtPosition,
	rangeFromPosition,
	rangeToPreviousCharacter,
} from '../utils/utils';
import { getContextCompletionItem } from '../widgetBuilder/completionContextTooltip';
import { getCssCompletionTooltip } from '../widgetBuilder/cssTooltip';
import { buildSourceFooter } from '../widgetBuilder/sourceFooter';

export const TextFieldCompletionItemProviderBuilder = (
	contextSettings: SuggestionsContextSettings,
	semanticDefinitions: EnrichedSemanticDefinitions,
	id: string
): languages.CompletionItemProvider => ({
	provideCompletionItems: buildTextFieldCompletionProvider(contextSettings, semanticDefinitions, id),
	triggerCharacters: ['.', '$', '('],
});

const buildTextFieldCompletionProvider =
	(
		{ entity, metric, includeTypes, isIncludingMultitraversal = false, resultsFilter }: SuggestionsContextSettings,
		semanticDefinitions: EnrichedSemanticDefinitions,
		id: string
	) =>
	(model: editor.ITextModel, position: Position) => {
		if (model.uri.toString() != textFieldIdToFileUri(id)) {
			return;
		}

		const { partRange, expressionRange, expression } = getPartAndExpressionRanges({ model, position });
		const hasDollarBeforeExpression = model.getValueInRange(rangeToPreviousCharacter(expressionRange)) == '$';

		const completion = new CompletionProvider(semanticDefinitions);

		const enrichedEntity = getEntity(semanticDefinitions, entity);
		const enrichedMetric = getMetricByNameFromList(semanticDefinitions.metrics, metric);
		const context = completion.createContexts({
			entity: enrichedEntity,
			metric: enrichedMetric,
		});

		const completionContexts = completion.walkContextForCompletions(
			context,
			expression,
			expression.length,
			true,
			isIncludingMultitraversal
		);
		const isIncludingParameterStore = completionContexts.some((context) => context.type === 'parameter_store');

		const completionItems = [...completionContexts, ...(isIncludingParameterStore ? functionContexts : [])]
			.filter((context) => !includeTypes || includeTypes.includes(context.type))
			.filter((context) => !resultsFilter || resultsFilter(context))
			.map((context) =>
				getContextCompletionItem(
					context,
					partRange,
					hasDollarBeforeExpression || context.type == 'function' ? '' : '$',
					true
				)
			);

		return {
			suggestions: completionItems,
		};
	};

export type CustomTextFieldCompletionItem = { label: string; value: string; type: DimensionContext['type'] };
export const CustomTextFieldCompletionItemProviderBuilder = (
	items: CustomTextFieldCompletionItem[],
	source: string,
	id: string
): languages.CompletionItemProvider => ({
	provideCompletionItems: buildCustomTextFieldCompletionProvider(items, source, id),
	triggerCharacters: ['.', '$', '('],
});

const buildCustomTextFieldCompletionProvider =
	(items: CustomTextFieldCompletionItem[], source: string, id: string) =>
	(model: editor.ITextModel, position: Position) => {
		if (model.uri.toString() != textFieldIdToFileUri(id)) {
			return;
		}

		const partRange =
			getWordRangeAtPosition(model, position, CompletionProvider.EXPRESSION_PART) ?? rangeFromPosition(position);
		const expressionRange =
			getWordRangeAtPosition(model, position, CompletionProvider.EXPRESSION) ?? rangeFromPosition(position);
		const hasDollarBeforeExpression = model.getValueInRange(rangeToPreviousCharacter(expressionRange)) == '$';

		const completionItems = items.map((item) => {
			return {
				label: {
					label: item.label,
					description: item.value,
				},
				insertText: hasDollarBeforeExpression ? item.value : `$${item.value}`,
				sortText: item.value,
				kind: AUTO_COMPLETE_KINDS[item.type],
				documentation: getCssCompletionTooltip(
					{
						image: {
							name: 'dimension',
							color: 'blue',
						},
						title: item.label,
						subtitle: 'Source dimension',
						description: 'This source dimension is obtained from the source system.',
						sourceFooter: buildSourceFooter({
							source,
							isWithSourceIcon: true,
						}),
					},
					item.value
				),
				range: partRange,
			};
		});

		return {
			suggestions: completionItems,
		};
	};
