import Box from '@components/Box';
import { Fragment, useMemo } from 'react';
import { SelectOption } from 'src/common/components/Select/types';
import { LabelWithIcon } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';
import { useReportEvent } from 'src/services/analytics';
import { KeysType } from '../../hooks/useDimensionBuilderDerivedState';
import { JoinKeysDropdowns } from './JoinKeysDropdowns';
import { DimensionsValues } from './JoinKeysField';

export function UIField({
	isLoading,
	isEditable,
	isEditRelationshipFlow,
	joinValuesArray,
	setDimensionsValues,
	onChange,
	fromKeyOptions,
	toKeyOptions,
	referencedEntity,
}: {
	isLoading: boolean;
	isEditable: boolean;
	isEditRelationshipFlow: boolean;
	joinValuesArray: string[];
	onChange: (key: KeysType[0]['key'], value: KeysType[0]['value']) => void;
	setDimensionsValues: React.Dispatch<React.SetStateAction<DimensionsValues>>;
	fromKeyOptions: SelectOption[];
	toKeyOptions: SelectOption[];
	referencedEntity: string;
}) {
	const { reportEvent } = useReportEvent();

	const isAddNewEnabled = useMemo(() => !!joinValuesArray.filter((el) => !el)?.length, [joinValuesArray]);

	return (
		<Fragment>
			<Box
				border={'1px solid'}
				borderColor={'gray.300'}
				padding={'12px'}
				paddingBottom={joinValuesArray.length > 1 ? '16px' : '12px'}
				borderRadius={'8px'}
				borderBottomLeftRadius={0}
				borderBottomRadius={0}
				borderBottom={0}
			>
				{joinValuesArray.map((joinValue, index) => (
					<Fragment key={index}>
						<JoinKeysDropdowns
							loading={isLoading}
							isMultiple={joinValuesArray.length > 1}
							onRemove={() => {
								const updatedValuesArray = joinValuesArray.filter((_, i) => i !== index);
								setDimensionsValues((prevState) => ({ ...prevState, joinValuesArray: updatedValuesArray }));
								onChange('on', updatedValuesArray.join(' and '));
							}}
							indexNumber={index}
							isEditRelationshipFlow={isEditRelationshipFlow}
							isEditable={isEditable}
							value={joinValue}
							fromKeyOptions={fromKeyOptions}
							toKeyOptions={toKeyOptions}
							referencedEntity={referencedEntity}
							onChange={(value: string) => {
								setDimensionsValues((prevState) => ({ ...prevState, isShouldCheckFormat: false }));
								const updatedValuesArray = joinValuesArray.map((item, i) => (i === index ? value : item));
								setDimensionsValues((prevState) => ({ ...prevState, joinValuesArray: updatedValuesArray }));
								onChange('on', updatedValuesArray.join(' and '));
							}}
						/>
					</Fragment>
				))}
			</Box>
			<Box
				paddingX={'12px'}
				paddingTop={'9px'}
				paddingBottom={'5px'}
				border={'1px solid'}
				borderColor={'gray.300'}
				borderBottomLeftRadius={'8px'}
				borderBottomRightRadius={'8px'}
				onClick={() => {
					reportEvent({
						event: 'relationship-edit-add-join-key',
					});
					if (!isAddNewEnabled)
						setDimensionsValues((prevState) => ({ ...prevState, joinValuesArray: [...joinValuesArray, ''] }));
				}}
			>
				<LabelWithIcon isFieldsDisabled={isAddNewEnabled} text="Add new key" />
			</Box>
		</Fragment>
	);
}
