import { Textarea } from '@chakra-ui/react';
import Box from '@components/Box';
import { SelectLabel } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';
import colors from 'src/style/colors';

type Props = {
	isDisabled?: boolean;
	value?: string;
	onBlur?: VoidFunction;
	onChange?: (value: string) => void;
	placeholder?: string;
};
const DescriptionField = ({
	isDisabled = false,
	value,
	onBlur,
	onChange,
	placeholder = 'Add a description',
}: Props) => {
	return (
		<Box>
			<SelectLabel marginBottom={'8px'} color={'gray.1100'} text="Description" />
			<Textarea
				isDisabled={isDisabled}
				_disabled={{ opacity: 1 }}
				borderRadius={'8px'}
				maxHeight="200px"
				paddingY="11px"
				paddingX={'12px'}
				minHeight={'120px'}
				_focusVisible={{ border: 'gray.900' }}
				border={'1px solid'}
				borderColor={'gray.400'}
				backgroundColor={isDisabled ? colors.gray[100] : colors.white}
				_hover={{ borderColor: 'gray.500' }}
				_focus={{ borderColor: 'gray.900' }}
				resize="none"
				color={'gray.1000'}
				value={value}
				fontSize={'14px'}
				placeholder={placeholder}
				onBlur={onBlur}
				onChange={(e) => onChange?.(e?.target?.value)}
			/>
		</Box>
	);
};

export default DescriptionField;
