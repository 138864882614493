import Flex from '@components/Flex';
import {
	AddTemplateTutorialStep1,
	AddTemplateTutorialStep2,
	AddTemplateTutorialStep3,
	ArrowRight16,
} from '@icons/index';
import Typography from '@components/Typography';

export const InstallingTutorial = () => {
	const getTutorialArrow = () => {
		return (
			<Flex alignItems={'center'} height={'62px'}>
				<ArrowRight16 />
			</Flex>
		);
	};

	const getTutorialSection = ({
		img,
		title,
		description,
	}: {
		img: React.ReactNode;
		title: string;
		description: string;
	}) => {
		return (
			<Flex flexDirection={'column'} gap={'8px'} alignItems={'center'} width={'184px'}>
				<Flex alignItems={'center'}>{img}</Flex>
				<Flex flexDirection={'column'} gap={'4px'} alignItems={'center'}>
					<Typography variant={'DesktopH10Medium'} color={'gray.1000'}>
						{title}
					</Typography>
					<Typography variant={'DesktopH10Regular'} color={'gray.700'} textAlign={'center'}>
						{description}
					</Typography>
				</Flex>
			</Flex>
		);
	};

	return (
		<Flex justifyContent={'space-around'} gap={'12px'} alignItems={'flex-start'}>
			{getTutorialSection({
				img: <AddTemplateTutorialStep1 />,
				title: 'Select',
				description: 'Pick a newly added metric from the "Pending setup" category.',
			})}
			{getTutorialArrow()}
			{getTutorialSection({
				img: <AddTemplateTutorialStep2 />,
				title: 'Edit',
				description: 'Review and adjust the metric calculation formula.',
			})}
			{getTutorialArrow()}
			{getTutorialSection({
				img: <AddTemplateTutorialStep3 />,
				title: 'Map',
				description: 'Map any missing dimensions or relationships in the Ontology.',
			})}
		</Flex>
	);
};
