import Hotjar from '@hotjar/browser';
import { isLocalDevelopment, isProductionEnv, isProductionMode } from 'src/config';
import { AnalyticsMetadata } from './analytics';

export const initHotjar = () => {
	const siteId = 2360950;
	const hotjarVersion = 6;
	Hotjar.init(siteId, hotjarVersion, { debug: isLocalDevelopment });
};

export const identifyHotjar = (metadata: AnalyticsMetadata) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { feRoles, ...userMetadata } = metadata;

	if (isProductionEnv)
		try {
			Hotjar.identify(metadata.id, userMetadata);
		} catch (e) {
			if (!isProductionMode) {
				console.log('error', 'hotjar identify failed', e);
			}
		}
};
