import { useOntologyBuilderYamlProviders } from 'src/lib/completions/hooks/useOntologyBuilderYamlProviders';
import { BorderedMonacoYamlEditor } from 'src/pages/MetricPage/components/MonacoEditor/BorderedMonacoEditor';

export function DimensionYAMLEditor({
	value,
	onChange,
	onErrorsChange,
	isEditable,
	hasUnsavedChanges,
	onFocus,
	entity,
	isRawSql,
}: {
	value: string;
	onChange: (val: string) => void;
	onErrorsChange: (val: boolean) => void;
	isEditable?: boolean;
	hasUnsavedChanges: boolean;
	onFocus: () => void;
	entity: string;
	isRawSql: boolean;
}) {
	useOntologyBuilderYamlProviders(entity);
	return (
		<BorderedMonacoYamlEditor
			value={value}
			schemaType={isRawSql ? 'normalization_raw_dimension' : 'normalization_dimension'}
			onChange={onChange}
			onErrorsChange={onErrorsChange}
			isEditable={isEditable}
			hasUnsavedChanges={hasUnsavedChanges}
			onFocus={onFocus}
		/>
	);
}
