import * as CSS from 'csstype';
import React, { MouseEvent, ReactNode } from 'react';
import NavigationItemFrame from './NavigationItemFrame';

export type NavigationIconVariants = 'header' | 'footer';

export interface NavigationIconProps {
	icon: ReactNode;
	tooltipLabel?: ReactNode;
	activeIcon?: ReactNode;
	overrideOnClick?: (event: React.MouseEvent) => void;
	isActiveOverride?: boolean;
	padding?: CSS.Property.Padding;
	borderRadius?: CSS.Property.BorderRadius;
	dataIntercomArea?: string;
	dataIntercomType?: string;
	dataIntercomTarget?: string;
	hasExternalIconStyles?: boolean;
}
function NavigationIcon(
	this: any,
	{
		icon,
		tooltipLabel,
		activeIcon,
		isActiveOverride: isActive,
		padding,
		borderRadius,
		overrideOnClick,
		dataIntercomArea,
		dataIntercomType,
		dataIntercomTarget,
		hasExternalIconStyles = false,
	}: NavigationIconProps
) {
	const shouldDisplayActiveIcon = !!(isActive && activeIcon);
	const displayedIcon = <span>{shouldDisplayActiveIcon ? activeIcon : icon} </span>;

	function onClick(event: MouseEvent) {
		event.preventDefault();
		overrideOnClick?.(event);
	}

	return (
		<NavigationItemFrame
			tooltipLabel={tooltipLabel}
			isActive={isActive}
			onClick={onClick}
			icon={displayedIcon}
			borderRadius={borderRadius}
			padding={padding}
			dataIntercomArea={dataIntercomArea}
			dataIntercomType={dataIntercomType}
			dataIntercomTarget={dataIntercomTarget}
			hasExternalIconStyles={hasExternalIconStyles}
		/>
	);
}

export default NavigationIcon;
