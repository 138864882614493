import styles from './Loader.module.scss';

type Props = {
	isCenter?: boolean;
};

function Loader({ isCenter = true }: Props) {
	return <span className={`${styles.loader} ${isCenter ? styles.loaderCenter : ''}`} />;
}

export default Loader;
