import { useEffect, useMemo } from 'react';
import { useEntityFetchApi } from 'src/common/hooks/fetching/useEntityFetchApi';
import useLazyQuery from 'src/common/hooks/fetching/useLazyQuery';
import { useHasuraSubscriptionWithCache } from 'src/common/hooks/fetching/useSubscription';
import { GetDataModelDimensionsQuery, GetDataModelDimensionsQueryVariables } from 'src/generated/graphql';
import { GetDataModelDimensions, NodeOverridesSubscription } from 'src/queries/nodes';
import { createDimensionOverridesMap, normalizeNode } from '../utilities';

export const useNormalizedData = (entityIds: string[], shouldShowRawData: boolean) => {
	const [currentEntityId, ...rest] = entityIds;
	const rawNode = useEntityFetchApi(currentEntityId);

	const { data: overridesData } = useHasuraSubscriptionWithCache(NodeOverridesSubscription, {
		variables: { object_id: rawNode?.properties.id ?? '' },
	});

	const dataOverridesMap = useMemo(
		() => createDimensionOverridesMap(overridesData?.data_overrides),
		[overridesData?.data_overrides]
	);

	const [getDataModelDimensions, { data: dataModelDimensions }] = useLazyQuery<
		GetDataModelDimensionsQuery,
		GetDataModelDimensionsQueryVariables
	>(GetDataModelDimensions);

	const node = useMemo(
		() =>
			rawNode &&
			normalizeNode({ rawNode, dataOverridesMap, dataModelDimensions: dataModelDimensions ?? null, shouldShowRawData }),
		[rawNode, dataOverridesMap, dataModelDimensions, shouldShowRawData]
	);

	useEffect(() => {
		getDataModelDimensions({ variables: { object: node?.header.entityType ?? '' } });
	}, [getDataModelDimensions, node]);

	return { currentEntityId, node, rawNode, rest };
};
