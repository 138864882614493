import { buildDiv, buildDivChildren, buildLink } from './baseBuilders';

type SourceFooterProps = {
	source: string;
	isWithSourceIcon: boolean;
	entityName?: string;
	entityLink?: string;
};

export function buildSourceFooter({ source, entityLink, entityName, isWithSourceIcon }: SourceFooterProps): string {
	const hasEntity = !!entityName && !!entityLink;
	const sourceIcon = isWithSourceIcon && buildDiv(`source-icon-${source.replaceAll(' ', '-')}`);
	const sourceName = buildDiv('source-name', source ?? 'Unknown Source');
	const sourceSlashDivider = hasEntity && buildDiv('source-slash', '/');
	const entityNameDiv = hasEntity && buildLink(entityLink, buildDiv('source-entity-name', entityName));
	return buildDivChildren('source-footer', sourceIcon, sourceName, sourceSlashDivider, entityNameDiv);
}
