import { forwardRef, Text as ChakraText, TextProps as ChakraTextProps } from '@chakra-ui/react';
import colors from 'src/style/colors';
import classes from './LoadingThreeDots/LoadingThreeDots.module.scss';
import { TypographyProps, TypographyVariant } from './types';

const Typography = forwardRef<TypographyProps, 'span'>(
	(
		{
			children,
			display = 'inline-block',
			variant,
			color,
			as = 'span',
			ml,
			mb,
			mt,
			w,
			testId,
			className,
			width,
			textOverflow,
			overflow,
			noOfLines,
			whiteSpace,
			minHeight,
			minLines,
			maxWidth,
			minWidth,
			textAlign,
			paddingTop,
			paddingLeft,
			onClick,
			cursor = 'inherit',
			_hover,
			paddingBottom,
			paddingRight,
			textDecoration,
			isLoadingAnimation,
			wordBreak,
			textDecorationStyle,
			textUnderlineOffset,
			...spaceProps
		},
		ref
	) => {
		return (
			<ChakraText
				textOverflow={textOverflow}
				whiteSpace={whiteSpace}
				overflow={overflow}
				{...styleByVariant[variant]}
				ref={ref}
				as={as}
				ml={ml}
				mb={mb}
				mt={mt}
				w={w}
				color={color}
				data-testid={testId}
				className={isLoadingAnimation ? [classes.loading, className].join(' ') : className}
				width={width}
				display={display}
				minHeight={minHeight ? minHeight : minLines && minLineHeight(minLines, variant)}
				noOfLines={noOfLines}
				maxWidth={maxWidth}
				minWidth={minWidth}
				textAlign={textAlign}
				paddingTop={paddingTop}
				paddingLeft={paddingLeft}
				paddingRight={paddingRight}
				paddingBottom={paddingBottom}
				onClick={onClick}
				cursor={cursor}
				textDecoration={textDecoration}
				_hover={_hover}
				wordBreak={wordBreak}
				textDecorationStyle={textDecorationStyle}
				textUnderlineOffset={textUnderlineOffset}
				{...spaceProps}
			>
				{children}
			</ChakraText>
		);
	}
);

function minLineHeight(minLines: number, variant: TypographyVariant) {
	const { lineHeight: lineHeightPx } = styleByVariant[variant];
	const lineHeight = lineHeightPx && parseInt(lineHeightPx.toString(), 10);
	return lineHeight && `${minLines * lineHeight}px`;
}
export const fontFamily = 'inter, helvetica';
export const styleByVariant: { [key in TypographyVariant]: Partial<ChakraTextProps> } = {
	Paragraph24M: { fontFamily, fontWeight: 500, fontSize: '24px', lineHeight: '40px' },
	Paragraph24R: { fontFamily, fontWeight: 400, fontSize: '24px', lineHeight: '34px' },
	Paragraph20R: { fontFamily, fontWeight: 400, fontSize: '20px', lineHeight: '36px' },
	Paragraph20S: { fontFamily, fontWeight: 500, fontSize: '20px', lineHeight: '24px' },
	Paragraph18R: { fontFamily, fontWeight: 400, fontSize: '18px', lineHeight: '28px' },
	Paragraph18S: { fontFamily, fontWeight: 500, fontSize: '18px', lineHeight: '24px' },
	Paragraph18SB: { fontFamily, fontWeight: 600, fontSize: '18px', lineHeight: '28px' },
	Paragraph16M: { fontFamily, fontWeight: 500, fontSize: '16px', lineHeight: '24px' },
	Paragraph16R: { fontFamily, fontWeight: 400, fontSize: '16px', lineHeight: '26px' },
	Paragraph16SB: { fontFamily, fontWeight: 600, fontSize: '16px', lineHeight: '24px' },
	Paragraph14RS: { fontFamily, fontWeight: 400, fontSize: '14px', lineHeight: '26px' },
	Paragraph14R: { fontFamily, fontWeight: 400, fontSize: '14px', lineHeight: '24px' },
	Paragraph14M: { fontFamily, fontWeight: 500, fontSize: '14px', lineHeight: '24px' },
	Paragraph14SB: { fontFamily, fontWeight: 600, fontSize: '14px', lineHeight: '24px' },
	Paragraph13SB: { fontFamily, fontWeight: 600, fontSize: '13px', lineHeight: '21px' },
	Paragraph13M: { fontFamily, fontWeight: 500, fontSize: '13px', lineHeight: '21px' },
	Paragraph13R: { fontFamily, fontWeight: 500, fontSize: '13px', lineHeight: '20px' },
	Paragraph12M: { fontFamily, fontWeight: 500, fontSize: '12px', lineHeight: '20px' },
	Paragraph12R: { fontFamily, fontWeight: 400, fontSize: '12px', lineHeight: '20px' },
	Paragraph12SB: { fontFamily, fontWeight: 600, fontSize: '12px', lineHeight: '20px' },
	Paragraph5M: { fontFamily, fontWeight: 500, fontSize: '14px', lineHeight: '24px' },
	Disclaimer12SB: { fontFamily, fontWeight: 600, fontSize: '12px', lineHeight: '20px' },
	DesktopH1: { fontFamily, fontWeight: 600, fontSize: '42px', lineHeight: '56px' },
	DesktopH2: { fontFamily, fontWeight: 600, fontSize: '32px', lineHeight: '36px' },
	DesktopH3: { fontFamily, fontWeight: 600, fontSize: '28px', lineHeight: '32px' },
	DesktopH4: { fontFamily, fontWeight: 600, fontSize: '24px', lineHeight: '28px' },
	DesktopH5: { fontFamily, fontWeight: 600, fontSize: '20px', lineHeight: '24px' },
	DesktopH5S: { fontFamily, fontWeight: 500, fontSize: '20px', lineHeight: '24px' },
	DesktopH6: { fontFamily, fontWeight: 600, fontSize: '18px', lineHeight: '24px' },
	DesktopH4Medium: { fontFamily, fontWeight: 500, fontSize: '24px', lineHeight: '28px' },
	DesktopH5SemiBold: { fontFamily, fontWeight: 600, fontSize: '20px', lineHeight: '24px' },
	DesktopH6Medium: { fontFamily, fontWeight: 500, fontSize: '18px', lineHeight: '24px' },
	DesktopH7Bold: { fontFamily, fontWeight: 600, fontSize: '16px', lineHeight: '20px' },
	DesktopH7SemiBold: { fontFamily, fontWeight: 600, fontSize: '16px', lineHeight: '20px' },
	DesktopH7Medium: { fontFamily, fontWeight: 500, fontSize: '16px', lineHeight: '20px' },
	DesktopH7Regular: { fontFamily, fontWeight: 400, fontSize: '16px', lineHeight: '20px' },
	DesktopH8Bold: { fontFamily, fontWeight: 600, fontSize: '14px', lineHeight: '20px' },
	DesktopH8Medium: { fontFamily, fontWeight: 500, fontSize: '14px', lineHeight: '20px' },
	DesktopH8Regular: { fontFamily, fontWeight: 400, fontSize: '14px', lineHeight: '20px' },
	DesktopH10Regular: { fontFamily, fontWeight: 400, fontSize: '12px', lineHeight: '16px' },
	DesktopH10Medium: { fontFamily, fontWeight: 500, fontSize: '12px', lineHeight: '16px' },
	DesktopH10Bold: { fontFamily, fontWeight: 600, fontSize: '12px', lineHeight: '16px' },
	Link16R: {
		fontFamily,
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '26px',
		textDecorationLine: 'underline',
		color: colors.blue['700'],
		as: 'a',
	},
	Link16SB: {
		fontFamily,
		fontWeight: 600,
		fontSize: '16px',
		lineHeight: '26px',
		textDecorationLine: 'underline',
		color: colors.blue['700'],
		as: 'a',
	},
	Link14R: {
		fontFamily,
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '24px',
		textDecorationLine: 'underline',
		color: colors.blue['700'],
		as: 'a',
	},
	Link14SB: {
		fontFamily,
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '24px',
		textDecorationLine: 'underline',
		color: colors.blue['700'],
		as: 'a',
	},
	Link12R: {
		fontFamily,
		fontWeight: 400,
		fontSize: '12px',
		lineHeight: '20px',
		textDecorationLine: 'underline',
		color: colors.blue['700'],
		as: 'a',
	},
	Tags10B: {
		fontFamily,
		fontWeight: 700,
		fontSize: '10px',
		lineHeight: '17px',
		color: colors.white,
		textTransform: 'uppercase',
		letterSpacing: '0.5px',
	},
	SmallTitle12SB: {
		fontFamily,
		fontWeight: 600,
		fontSize: '12px',
		lineHeight: '17px',
		letterSpacing: '0.5px',
		textTransform: 'uppercase',
	},
	SmallTitle12B: {
		fontFamily,
		fontWeight: 700,
		fontSize: '12px',
		lineHeight: '17px',
		letterSpacing: '0.5px',
		textTransform: 'uppercase',
	},
};
export default Typography;
