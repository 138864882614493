import { ReactNode, useEffect, useRef, useState } from 'react';
import Box from '@components/Box';
import classnames from 'classnames';
import classes from './LeftSidePanelV2.module.scss';
import Flex from '@components/Flex';
import { useReportEvent } from '@services/analytics';

export type RenderItem = (
	handleCollapsePanelClick: VoidFunction,
	isCollapsed: boolean,
	setIsPanelActingLikeOnHover: (val: boolean) => void
) => ReactNode;

export const LeftSidePanelV2 = ({ renderItem }: { renderItem: RenderItem }) => {
	const { reportEvent } = useReportEvent();
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [isHoverEnabled, setIsHoverEnabled] = useState(false);
	const timeoutForDisablingHoverRef = useRef<number | null>(null);

	const [isPanelActingLikeOnHover, setIsPanelActingLikeOnHover] = useState(false);

	useEffect(() => {
		return () => {
			if (timeoutForDisablingHoverRef.current) {
				clearTimeout(timeoutForDisablingHoverRef.current);
			}
		};
	}, []);

	const handleCollapsePanelClick = () => {
		reportEvent({ event: 'left-panel-expand-collapse-panel-button-clicked' });
		setIsCollapsed(!isCollapsed);
		setIsHoverEnabled(false);
		timeoutForDisablingHoverRef.current = window.setTimeout(() => setIsHoverEnabled(true));
	};

	return (
		<Box
			className={classnames(classes.leftSidePanelWrapper, {
				[classes.hoverEnabled]: isHoverEnabled,
				[classes.isCollapsed]: isCollapsed,
				[classes.onHover]: isPanelActingLikeOnHover,
			})}
		>
			<Box className={classes.leftSidePanel}>
				<Flex flexDirection={'column'} height={'100%'}>
					{renderItem(handleCollapsePanelClick, isCollapsed, setIsPanelActingLikeOnHover)}
				</Flex>
			</Box>
		</Box>
	);
};
