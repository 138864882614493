import { ReactNode } from 'react';
import Flex from 'src/common/components/Flex';
import Typography from 'src/common/components/Typography';
import Button from 'src/common/components/Button';

export interface ListHeaderProps {
	icon: ReactNode;
	title: string;
	description: string;
	buttonLabel?: string;
	onClick?: VoidFunction;
}

export default function NoResults({ icon, title, description, buttonLabel, onClick }: ListHeaderProps) {
	return (
		<Flex textAlign={'center'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
			{icon}
			<Typography marginTop={'18px'} variant="Paragraph18S" color={'gray.1000'}>
				{title}
			</Typography>
			<Typography maxWidth={'280px'} marginTop={'8px'} variant="Paragraph12R" color={'gray.800'}>
				{description}
			</Typography>
			{buttonLabel && (
				<Button marginTop={'28px'} variant="solid" onClick={onClick} size="small" colorScheme="blue">
					{buttonLabel}
				</Button>
			)}
		</Flex>
	);
}
