import { nodeSchema } from 'src/assets/NodeSchema';
import { RawNode, RawNodeRelationship } from 'src/common/hooks/fetching/useEntityFetchApi';
import { isDefined } from 'src/normalize/utils';
import {
	AddDot,
	NormalizedRelationshipTableColumns,
	Display,
	EntitySchema,
	EntityType,
	NonDescribedSchemaRelationships,
	RelatedEntities,
	RelationshipDisplayName,
	Search,
} from '../../types';

export function normalizeRelatedEntitiesForNode({
	entitySchema,
	rawNode,
}: {
	entitySchema: EntitySchema;
	rawNode: RawNode;
}):
	| {
			entityType: string;
			columns: NormalizedRelationshipTableColumns;
			relatedEntities: RelatedEntities;
			relationshipDisplayName: RelationshipDisplayName | undefined;
	  }[]
	| null {
	const curatedRelationshipsKeys =
		'curated_relationships' in entitySchema ? (entitySchema.curated_relationships as unknown as string[]) : [];

	const rawRelationships = curatedRelationshipsKeys
		.map((relationshipKey) =>
			rawNode.relationships.find((r) => r.relationship_label + r.direction + r.other_label == relationshipKey)
		)
		.filter(isDefined);

	const relatedEntitiesTable = rawRelationships.map(normalizeRelationship);
	return relatedEntitiesTable;
}

export function normalizeRelationship(rawRelation: RawNodeRelationship) {
	return {
		entityType: rawRelation.other_label,
		relatedEntities: rawRelation.nodes,
		columns: extractNodeDisplayColumns({ entityType: rawRelation.other_label as EntityType }),
		relationshipDisplayName: getRelationshipDisplayName(rawRelation),
	};
}

function getRelationshipDisplayName(
	rawRelation: RawNode['relationships'][number]
): RelationshipDisplayName | undefined {
	const curRelationship =
		rawRelation.direction + rawRelation.relationship_label + rawRelation.direction + rawRelation.other_label;

	return nodeSchema.relationships.find(({ for: relationshipInnerName }) => curRelationship == relationshipInnerName)
		?.title;
}

export function extractNodeDisplayColumns({ entityType }: { entityType: EntityType }) {
	const dotEntityType = ('.' + entityType) as AddDot<EntityType>;
	const rawColumns = nodeSchema.node_display.find((nodeDisplay) => nodeDisplay.for == dotEntityType);

	const searchProperties = normalizeToSearchType(rawColumns, entityType);
	const sort: NormalizedRelationshipTableColumns['sort'] =
		rawColumns != null && 'filter' in rawColumns ? rawColumns['filter']?.['sort'] : undefined;

	const columns: NormalizedRelationshipTableColumns = {
		entityType: entityType,
		for: rawColumns?.for as AddDot<EntityType | NonDescribedSchemaRelationships>,
		search: searchProperties,
		display: rawColumns?.display as Display,
		sort,
	};

	return columns;
}

const normalizeToSearchType = (rawColumns: any, entityType: EntityType): Search => {
	return !rawColumns || !('search' in rawColumns)
		? {
				searchable: true,
				pretty: entityType,
		  }
		: rawColumns.search;
};
