import useNavigation from 'src/services/useNavigation';
import colors from 'src/style/colors';
import EmptyPageImage from 'src/assets/images/empty-page.svg?component';
import Flex from 'src/common/components/Flex';
import Box from 'src/common/components/Box';
import Typography from 'src/common/components/Typography';
import Button from 'src/common/components/Button';

export const EmptyPage = ({ signalType = 'dashboard' }: { signalType?: string }) => {
	const { navigate } = useNavigation();

	return (
		<Flex alignItems="center" justifyContent="center" padding="130px 32px 60px 32px">
			<Flex alignItems="center" maxWidth="490px" flexDirection="column">
				<Box marginBottom="24px">
					<EmptyPageImage />
				</Box>
				<Typography color={colors.gray[800]} marginBottom="12px" variant="DesktopH4">
					Start by adding your first signal widget.
				</Typography>
				<Typography color={colors.gray[700]} marginBottom="24px" variant="DesktopH7Regular">
					Add a signal from the metric catalog to build up your {signalType}.
				</Typography>

				<Button
					onClick={() =>
						navigate({
							path: '/metrics',
						})
					}
					variant="ghost"
					colorScheme="gray"
					width="min-content"
					padding="7px 16px"
				>
					<Typography variant="DesktopH8Medium">Explore metric catalog</Typography>
				</Button>
			</Flex>
		</Flex>
	);
};
