import { ChartType } from 'src/common/components/Chart/types';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export function calcAvailableChartTypes(
	{
		periodRange,
		metricInfo,
		hasGroupBy,
		chartOptions,
	}: Pick<MetricDerivedState, 'periodRange' | 'metricInfo' | 'hasGroupBy' | 'chartOptions'>,
	urlChartType?: MetricSearchParams['chartType']
): Pick<MetricDerivedState, 'chartType' | 'isTableView'> {
	const availableChartTypes: ChartType[] = ['column'];

	if (periodRange.isSinglePeriod) {
		if (hasGroupBy || metricInfo?.op.isStackable) {
			availableChartTypes.push('waterfall', 'pie');
		}

		if (!metricInfo?.op.isConcatable && !hasGroupBy) {
			availableChartTypes.push('number');
		}

		if (metricInfo?.op.isConcatable) {
			// TODO: add when implementing funnel
			// availableChartTypes.push('funnel');
		}
	} else {
		availableChartTypes.push('stackedColumn', 'line');
	}

	const chartTypeFromVizRules =
		chartOptions.series.find((s) => s.custom.seriesType == 'component' && s.chartType)?.chartType ??
		chartOptions.series.at(0)?.chartType;
	if (!chartTypeFromVizRules) {
		console.warn('no chart type found, using column');
	}
	const chartTypeFromEngine = chartTypeFromVizRules ?? 'column';

	const isOverrideChartTypeAllowed =
		urlChartType && urlChartType != 'table' && availableChartTypes.includes(urlChartType);

	const isTableView = urlChartType == 'table';
	const selectedChartType = urlChartType && isOverrideChartTypeAllowed ? urlChartType : chartTypeFromEngine;

	return {
		chartType: { selectedValue: selectedChartType, optionalValues: [...new Set(availableChartTypes)] },
		isTableView,
	};
}
