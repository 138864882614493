import { Box, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Button from '../Button';
import Typography from '../Typography';
import classes from './BigBanner.module.scss';

type BannerProps = {
	bannerIcon?: ReactNode;
	title: string;
	description?: string;
	buttonText?: string;
	onClick?: VoidFunction;
};

export function BigBanner({ bannerIcon, title, description, buttonText, onClick }: BannerProps) {
	return (
		<Flex borderRadius={'8px'} backgroundColor={'cyan.100'} marginY={'32px'} paddingX={'32px'} paddingY={'24px'}>
			{bannerIcon && <Box marginRight={'40px'}>{bannerIcon}</Box>}
			<Flex flexWrap={'wrap'} alignItems={'center'}>
				<Flex marginRight={'12px'} flexDirection={'column'} color={'gray.900'}>
					<span className={classes.title}>{title}</span>
					<Typography marginTop={'12px'} variant="DesktopH8Regular">
						{description}
					</Typography>
				</Flex>
				{buttonText && (
					<Button
						marginTop={'44px'}
						cursor={'pointer'}
						onClick={onClick}
						borderRadius={'20px'}
						size="inline"
						variant="solid"
						colorScheme="gray"
					>
						<Typography margin={0} variant="DesktopH8Medium">
							{buttonText}
						</Typography>
					</Button>
				)}
			</Flex>
		</Flex>
	);
}
