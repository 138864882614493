import { Input } from '@chakra-ui/react';
import Box from '@components/Box';
import { useAtomValue } from 'jotai';
import { MutableRefObject, useEffect, useState } from 'react';
import { UNTITLED_METRIC_DISPLAY } from 'src/lib/metricRules/builder/useMetricBuilder';
import { SelectLabel } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';
import { OntologyPropertyType } from 'src/pages/OntologyPage/utils/types';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import { OntologyStateAtomDerived } from '../atoms/OntologyState';
import { removeUnderscoresAndCapitalize } from 'src/normalize';

export function NameField({
	inputRef,
	isEditable = true,
	onNameUpdate,
	propertyDefinitionValueName,
	value,
	propertyType,
	isEditEntityFlow,
	label = 'Name',
}: {
	inputRef?: MutableRefObject<HTMLInputElement | null>;
	isEditable?: boolean;
	onNameUpdate: (val: string) => void;
	propertyDefinitionValueName?: string;
	value?: string;
	propertyType: OntologyPropertyType | 'entity';
	isEditEntityFlow?: boolean;
	label?: string;
}) {
	const { reportEvent } = useReportEvent();
	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const [displayName, setDisplayName] = useState(
		value || removeUnderscoresAndCapitalize(propertyDefinitionValueName || UNTITLED_METRIC_DISPLAY)
	);

	useEffect(() => {
		if (value === '') setDisplayName(UNTITLED_METRIC_DISPLAY);
		if (value === undefined) {
			setDisplayName(removeUnderscoresAndCapitalize(propertyDefinitionValueName || UNTITLED_METRIC_DISPLAY));
			return;
		}
		setDisplayName(value);
	}, [propertyDefinitionValueName, value]);

	const onNameChange = (nameValue: string) => {
		onNameUpdate(nameValue);
		setDisplayName(nameValue);
	};

	return (
		<Box>
			<SelectLabel marginBottom={'8px'} color={'gray.1100'} text={label} />
			<Input
				ref={inputRef}
				_disabled={{ opacity: 1 }}
				isDisabled={!isEditable}
				fontSize="14px"
				onChange={(e) => onNameChange(e.target.value)}
				onBlur={() => {
					reportEvent({
						event: 'ontology-object-edit-UI-input-provided',
						metaData: {
							parentEntity: ontologyState.loading ? '' : ontologyState.entityName,
							objectType: propertyType,
							objectName: propertyDefinitionValueName,
							fieldname: `name`,
							input: value,
							flow: isEditEntityFlow ? 'edit' : 'create',
						},
					});
					if (!displayName?.trim()) onNameChange(UNTITLED_METRIC_DISPLAY);
				}}
				placeholder="Add name"
				color="gray.1000"
				size="md"
				value={displayName}
				_focusVisible={{ border: 'gray.900' }}
				border={'1px solid'}
				borderColor={'gray.400'}
				backgroundColor={!isEditable ? colors.gray[100] : colors.white}
				_hover={{ borderColor: 'gray.500' }}
				_focus={{ borderColor: 'gray.600' }}
				borderRadius={'8px'}
			/>
		</Box>
	);
}
