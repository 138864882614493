import TenantConfigType from 'src/types/tenantConfig';
import {
	formatArray,
	formatBoolean,
	formatDate,
	formatDatetime,
	formatNull,
	formatNumber,
	formatText,
	isArray,
	isBoolean,
	isDate,
	isDatetime,
	isNull,
	isNumber,
	isText,
} from './formatters';

type ValueFormatter = (value: any, tenantConfig: Pick<TenantConfigType, 'decimalDigits'>) => string;

const getValueFormatter: (value: any) => ValueFormatter = (value: any) => {
	if (isNull(value) || value === undefined) return formatNull;

	if (isBoolean(value)) return formatBoolean;

	if (isNumber(value)) return formatNumber;

	if (isArray(value)) return formatArray;

	if (isDate(value)) return formatDate;

	if (isDatetime(value)) return formatDatetime;

	if (isText(value)) return formatText;

	return formatText;
};

export const formatValueWithoutTitleConsideration = (
	value: any,
	tenantConfig: Pick<TenantConfigType, 'decimalDigits'>
) => {
	return getValueFormatter(value)(value, tenantConfig);
};

export const formatValue = (title: string, value: any, tenantConfig: Pick<TenantConfigType, 'decimalDigits'>) => {
	const containsIdRegex = /(:?\b|_)[iI][dD](:?\b|_)/;
	const containsId = containsIdRegex.test(title) && isText(value);

	return containsId ? formatText(value) : formatValueWithoutTitleConsideration(value, tenantConfig);
};
