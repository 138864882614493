import { Monaco } from '@monaco-editor/react';
import { wireTmGrammars } from 'monaco-editor-textmate';
import { Registry } from 'monaco-textmate'; // peer dependency
import { loadWASM } from 'onigasm'; // peer dependency of 'monaco-textmate'

export const registry = new Registry({
	async getGrammarDefinition(scopeName) {
		if (scopeName === 'source.yaml') {
			return {
				format: 'json',
				content: await (await fetch(`/assets/grammars/yaml.tmLanguage.json`)).text(),
			};
		} else if (scopeName === 'source.sql') {
			return {
				format: 'json',
				content: await (await fetch(`/assets/grammars/sql.tmLanguage.json`)).text(),
			};
		}

		return {
			format: 'json',
			content: '',
		};
	},
});

// map of monaco "language id's" to TextMate scopeNames
export const grammars = new Map();
grammars.set('yaml', 'source.yaml');
grammars.set('sql', 'source.sql');

export async function textmateInit(monaco: Monaco) {
	try {
		await loadWASM('/assets/onigasm.wasm');
		wireTmGrammars(monaco, registry, grammars);
	} catch (e) {
		console.error('Error initializing textmate', e);
	}
}
