import { useCallback } from 'react';
import { Layout } from 'react-grid-layout';
import useMutation from 'src/common/hooks/fetching/useMutation';
import { MigrateDashboardFeedSignalDisplayOptions, UpdateDashboardSignalWidgetType } from 'src/queries/dashboards';
import { FeedSignal, Sentiment } from 'src/types/spaces';
import {
	COLUMN_COUNT,
	DEFAULT_WIDGET_WIDTH,
	DIVIDER_WIDGET_H,
	HEIGHT_MIGRATION_FACTOR,
	INFO_MARKDOWN,
	LEGACY_DEFAULT_WIDGET_HEIGHT,
	SUCCESS_MARKDOWN,
	WARNING_MARKDOWN,
	WIDTH_TO_COLUMN_FACTOR,
} from '../constants';
import { findBottomOfLayout, implyWidgetType } from '../utils/utils';

export function useDashboardMigration(): [
	(feedSignal: FeedSignal) => void,
	(feedSignals: FeedSignal[], currentLayout: Layout[]) => Layout[],
	(feedSignal: FeedSignal) => void
] {
	return [useWidgetTypeMigration(), useLayoutMigration(), useMigrateSentiment()];
}

function useWidgetTypeMigration() {
	const [updateDashboardSignalWidgetType] = useMutation(UpdateDashboardSignalWidgetType);
	const fixWidgetType = useCallback(
		(feedSignal: FeedSignal) => {
			if (feedSignal.signal.widget_type) return;
			const impliedWidgetType = implyWidgetType(feedSignal);
			if (impliedWidgetType) {
				updateDashboardSignalWidgetType({
					variables: {
						id: feedSignal.signal_id,
						widget_type: impliedWidgetType,
					},
				});
			}
		},
		[updateDashboardSignalWidgetType]
	);
	return fixWidgetType;
}

function useLayoutMigration() {
	const migrateLayout = useCallback((feedSignals: FeedSignal[], currentLayout: Layout[]): Layout[] => {
		if (!currentLayout || currentLayout.length == 0) return calculateLayoutFromOrder(feedSignals);

		const feedSignalsWithoutLayoutPosition = feedSignals.filter((s) => !currentLayout.some((l) => s.signal_id == l.i));
		if (feedSignalsWithoutLayoutPosition.length == 0) return currentLayout;

		const layoutCopy = [...currentLayout];

		for (const feedSignal of feedSignalsWithoutLayoutPosition) {
			let width = 3;
			let height = 10;
			if (feedSignal.signal.widget_type == 'divider') {
				width = COLUMN_COUNT;
				height = DIVIDER_WIDGET_H;
			}
			layoutCopy.push({
				w: width,
				h: height,
				x: 0,
				y: findBottomOfLayout(layoutCopy),
				i: feedSignal.signal_id,
				moved: false,
				static: false,
			});
		}
		return layoutCopy;
	}, []);
	return migrateLayout;
}

function calculateLayoutFromOrder(feedSignals: FeedSignal[]) {
	let nextPosition = [0, 0];
	let biggestHeightLastRow = 0;

	return [...feedSignals]
		.sort((a, b) => a.order - b.order)
		.map((feedSignal) => {
			const width = feedSignal.signal_width ?? DEFAULT_WIDGET_WIDTH;
			const height = Math.floor((feedSignal.signal_height ?? LEGACY_DEFAULT_WIDGET_HEIGHT) * HEIGHT_MIGRATION_FACTOR);
			let [thisX, thisY] = nextPosition;
			if (thisX + width * WIDTH_TO_COLUMN_FACTOR > 12) {
				//open new row
				thisY += biggestHeightLastRow;
				thisX = 0;
				biggestHeightLastRow = 0;
			}
			biggestHeightLastRow = Math.max(height, biggestHeightLastRow);
			const layout = {
				w: width * WIDTH_TO_COLUMN_FACTOR,
				h: height,
				x: thisX,
				y: thisY,
				i: feedSignal.signal_id,
				moved: false,
				static: false,
			};
			nextPosition = [thisX + layout.w, thisY + layout.y];
			return layout;
		});
}

function useMigrateSentiment() {
	const [migrateDisplayOption] = useMutation(MigrateDashboardFeedSignalDisplayOptions);
	const migrateSentiment = useCallback(
		(feedSignal: FeedSignal) => {
			if (feedSignal.signal.widget_type != 'signal' || feedSignal.display_options?.sentiment) return;
			const [sentiment, message] = implySentimentAndAppendMessage(feedSignal);
			if (sentiment != 'none') {
				migrateDisplayOption({
					variables: {
						signal_id: feedSignal.signal_id,
						feed_id: feedSignal.feed_id,
						display_options: {
							sentiment: sentiment,
						},
						message: message,
					},
				});
			}
		},
		[migrateDisplayOption]
	);
	return migrateSentiment;
}
function implySentimentAndAppendMessage(feedSignal: FeedSignal): [Sentiment, string] {
	let inferredSentiment: Sentiment = 'none';
	const message = feedSignal.signal.message;
	if (message) {
		if (message.startsWith(WARNING_MARKDOWN)) {
			inferredSentiment = 'error';
		} else if (message.startsWith(SUCCESS_MARKDOWN)) {
			inferredSentiment = 'success';
		} else if (message.startsWith(INFO_MARKDOWN)) {
			inferredSentiment = 'info';
		}
	}
	return [inferredSentiment, message];
}
