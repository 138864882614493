import { Flex } from '@chakra-ui/react';
import IconButton from 'src/common/components/IconButton';
import { CloseMedium16 } from 'src/common/components/Icons';
import Popover from 'src/common/components/Popover';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import { NOT_AVAILABLE_VALUE_STRING } from 'src/common/utils/consts';
import { Filter } from 'src/pages/MetricPage/utils/state.types';
import colors from 'src/style/colors';
import { FilterTooltipItem } from '../../components/WidgetHeader/FiltersBadge';

export function FilterChip({ filter, onRemove }: { filter: Filter; onRemove: () => any }) {
	const values = filter.values.map((v) => v || NOT_AVAILABLE_VALUE_STRING);
	const maxValuesToDisplay = 2;
	const isShowingAllValues = values.length <= maxValuesToDisplay;

	return (
		<Flex
			backgroundColor={'gray.200'}
			borderRadius="70px"
			dir="row"
			height={'36px'}
			maxHeight="36px"
			padding="6px"
			alignItems={'center'}
			color={colors.gray[800]}
			background={colors.gray[200]}
		>
			<Popover
				triggerElement={
					<Flex dir="row" height={'36px'} maxHeight="36px" alignItems={'center'}>
						<Typography variant="DesktopH8Bold" marginLeft={'6px'} textOverflow={'visible'} whiteSpace="nowrap">
							{filter.label}&nbsp;
						</Typography>
						<Typography variant="DesktopH8Regular" textOverflow={'visible'} whiteSpace="nowrap">
							is&nbsp;
						</Typography>
					</Flex>
				}
				trigger="hover"
			>
				<Flex paddingX={'16px'} paddingY="10px" direction="column">
					<FilterTooltipItem filter={filter} includeValues={false} />
				</Flex>
			</Popover>

			<Tooltip
				size={'md'}
				label={isShowingAllValues ? undefined : createPlusMoreString(10, values)}
				variant="fluid"
				placement={'bottom'}
				marginTop="4px"
			>
				<Typography variant="DesktopH8Bold" marginRight={'8px'} textOverflow={'visible'} whiteSpace="nowrap">
					{createPlusMoreString(maxValuesToDisplay, values)}
				</Typography>
			</Tooltip>

			<IconButton icon={<CloseMedium16 />} colorScheme="gray" onClick={onRemove} size="xxs" isRound />
		</Flex>
	);
}

function createPlusMoreString(limit: number, values: string[]): string {
	const isShowingAllValues = values.length <= limit;
	const extraMissingValues = !isShowingAllValues ? `, +${values.length - limit} more` : '';
	const firstCommaSeparatedValues = values
		.slice(0, limit)
		.map((v) => v?.toString() || NOT_AVAILABLE_VALUE_STRING)
		.join(', ');
	return firstCommaSeparatedValues + extraMissingValues;
}
