import { IconButton as ChakraIconButton, IconButtonProps as ChakraIconButtonProps, SpaceProps } from '@chakra-ui/react';

import { MouseEventHandler } from 'react';

type IconButtonVariants = 'ghost' | 'regular';
type IconButtonColorSchemes =
	| 'lightGray'
	| 'mediumGray'
	| 'gray'
	| 'black'
	| 'blue'
	| 'green'
	| 'red'
	| 'lightGrayNoActive'
	| 'darkblue'
	| 'blue-filled';

export type IconButtonProps = {
	testId?: string;
	variant?: IconButtonVariants;
	colorScheme?: IconButtonColorSchemes;
	onClick?: MouseEventHandler;
	ariaLabel?: string;
	icon: React.ReactElement;
	isActive?: boolean;
} & Pick<
	ChakraIconButtonProps,
	| 'size'
	| 'height'
	| 'as'
	| 'isRound'
	| 'border'
	| 'boxShadow'
	| '_hover'
	| 'backgroundColor'
	| 'isDisabled'
	| 'isLoading'
	| 'className'
> &
	SpaceProps;

/**
 * @deprecated this is deprecated, use Button with Icon variant instead
 */
function IconButton({
	testId,
	variant = 'regular',
	onClick,
	ariaLabel = '',
	colorScheme = 'lightGray',
	icon,
	size = 'xs',
	height,
	as,
	isActive,
	isRound,
	border,
	boxShadow,
	_hover,
	backgroundColor,
	isDisabled,
	isLoading,
	className,
	...spaceProps
}: IconButtonProps) {
	const chakraVariant = `${variant}Icon`;
	return (
		<ChakraIconButton
			className={className}
			size={size}
			aria-label={ariaLabel}
			data-testid={testId}
			variant={chakraVariant}
			onClick={onClick}
			border={border}
			boxShadow={boxShadow}
			colorScheme={colorScheme}
			icon={icon}
			height={height}
			as={as}
			isActive={isActive}
			isRound={isRound}
			_hover={_hover}
			backgroundColor={backgroundColor}
			isDisabled={isDisabled}
			isLoading={isLoading}
			{...spaceProps}
		/>
	);
}

export default IconButton;
