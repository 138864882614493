import { gql } from '@apollo/client';
import { disableFragmentWarnings } from 'graphql-tag';
disableFragmentWarnings();

export const GetInsightTypeBySignalId = gql`
	query GetInsightTypeBySignalId($id: uuid!) {
		signals(where: { id: { _eq: $id } }) {
			insight_types {
				id
				display_name
				description
				visible
				tags {
					tag {
						users {
							user {
								first_name
								last_name
							}
						}
					}
				}
			}
		}
	}
`;

export const UpdateSignalPublishingState = gql`
	mutation UpdateSignalPublishingState($id: uuid!, $state: String!) {
		update_signals_by_pk(pk_columns: { id: $id }, _set: { state: $state }) {
			id
		}
	}
`;

// Subscription page stuff, don't use for super-user
export const InsightTypeFields = gql`
	fragment InsightTypeFields on insight_types {
		id
		display_name
		description
		my_subscriptions: tags_aggregate(where: { tag: { users: { user_id: { _eq: $my_id } } } }) {
			aggregate {
				count
			}
		}
	}
`;
export const TagFields = gql`
	${InsightTypeFields}
	fragment TagFields on tags {
		id
		insight_types(
			order_by: { insight_type: { display_name: asc } }
			where: { insight_type: { visible: { _eq: true } } }
		) {
			insight_type {
				...InsightTypeFields
			}
		}
	}
`;

export const GetAllInsightsByTags = gql`
	${TagFields}
	query GetAllInsightsByTags($my_id: uuid!) {
		tags(where: { is_insight: { _eq: false } }, order_by: { id: asc }) {
			...TagFields
		}
	}
`;

export const SubscribeMeToInsight = gql`
	${TagFields}
	mutation SubscribeMeToInsight($insight_id: String!, $my_id: uuid!) {
		insert_user_tags_one(object: { tag_id: $insight_id }) {
			tag {
				...TagFields
			}
		}
	}
`;

export const UnsubscribeMeToInsight = gql`
	${TagFields}
	mutation UnsubscribeMeToInsight($insight_id: String!, $my_id: uuid!) {
		delete_user_tags_by_pk(tag_id: $insight_id, user_id: $my_id) {
			tag {
				...TagFields
			}
		}
	}
`;
