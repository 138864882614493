import { createContext, ReactNode, useCallback, useContext } from 'react';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { isProductionMode } from 'src/config';
import { TenantSetting } from 'src/normalize';
import { chmInitUserInfo, chmlnTrack } from 'src/services/chameleon';
import { useEnvironmentStore } from 'src/stores/environment';
import { UserType } from 'src/types/user';
import { identifyCoralogixRum, trackCoralogixRumEvent } from './coralogix';
import { identifyHotjar, initHotjar } from './hotjar';
import { trackEvent as intercomTrackEvent, updateIntercom } from './intercom';
import {
	identifyMixpanel,
	initMixpanel,
	track as mixpanelTrack,
	registerSuperPropertiesMixpanel,
	setProfilePropertiesMixpanel,
} from './mixpanel';

type Props = {
	event?: string;
	metaData?: object | undefined;
};

export type AnalyticsMetadata = {
	email: string;
	company: string;
	name: string;
	id: string;
	tenant: string;
	role: string;
	apiUrl: string;
	'git.commit.id': string;
	'git.commit.date': string;
	tenantState?: string;
	feRoles: string[];
	activeRole: string;
};

export const initAnalytics = () => {
	if (!isProductionMode) return;
	initMixpanel();
	initHotjar();
};

export const initUserInfo = (user: UserType, tenantSettings: TenantSetting) => {
	if (!isProductionMode) return;
	const tenantConfig = useEnvironmentStore.getState().environment;

	function extractCompanyNameFromEmail(email: string) {
		return email.toLowerCase().includes('sightfull') ? 'Sightfull' : tenantSettings.companyName;
	}

	const metadata: AnalyticsMetadata = {
		email: user.email,
		feRoles: tenantConfig.feRoles,
		activeRole: tenantConfig.feRoles[0],
		name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
		id: user.id ?? 'unknown',
		tenant: tenantConfig.tenant.toString() ?? 'unknown',
		role: tenantConfig.role ?? 'unknown',
		apiUrl: `https://api.${tenantConfig.stamp}.sightfull.com`,
		'git.commit.id': import.meta.env.PULSE_GIT_COMMIT_ID,
		'git.commit.date': import.meta.env.PULSE_GIT_COMMIT_DATE,
		company: extractCompanyNameFromEmail(user.email),
		tenantState: tenantSettings.tenantState ?? 'unknown',
	};

	updateIntercom({ ...metadata, user_hash: user.intercomHMAC });
	identifyHotjar(metadata);
	identifyMixpanel(metadata.email);
	identifyCoralogixRum(metadata);

	// TODO: This should be moved to backend (hasuri for instance), to the user sign-up stage
	setProfilePropertiesMixpanel({
		$email: metadata.email,
		$name: metadata.name,
		Company: metadata.company,
		activeRole: metadata.activeRole,
	});
	registerSuperPropertiesMixpanel(metadata);
	chmInitUserInfo(user, metadata);
};

export function useReportEvent(extraMetadata?: Record<string, any>): {
	reportEvent: (props: Props) => void;
	wrapWithReport: <ArgsArray extends Array<any>, ReturnType>(
		innerFunction: ((...args: ArgsArray) => ReturnType) | undefined,
		event?: string,
		eventMetadata?: object
	) => (() => void) | ((...args: ArgsArray) => ReturnType);
} {
	const { reportEvent } = useContext(PageContext);
	const reportEventWithExtraMetadata: typeof reportEvent = useCallback(
		({ event, metaData }) => reportEvent({ event, metaData: { ...extraMetadata, ...metaData } }),
		[extraMetadata, reportEvent]
	);

	const wrapWithReport = useCallback(
		<ArgsArray extends Array<any>, ReturnType>(
			innerFunction: ((...args: ArgsArray) => ReturnType) | undefined,
			event?: string,
			eventMetadata: object = {}
		) => {
			if (!innerFunction) return () => reportEvent({ event, metaData: { ...eventMetadata, ...extraMetadata } });
			return (...args: ArgsArray): ReturnType => {
				reportEvent({ event, metaData: { ...eventMetadata, ...extraMetadata } });
				return innerFunction(...args);
			};
		},
		[extraMetadata, reportEvent]
	);

	return { reportEvent: reportEventWithExtraMetadata, wrapWithReport };
}

export function AnalyticsPageContext({ page, children }: { page: PageType; children: ReactNode }) {
	const isReportingVerbose = useFeatureFlag('pulse.analytics.verbose');
	const reportEvent = useCallback(
		({ event, metaData }: Props) => {
			if (!event) return;

			metaData = { ...metaData, page };

			if (!isProductionMode || isReportingVerbose) {
				console.log({ event, metaData });
				if (!isProductionMode) return;
			}
			intercomTrackEvent(event, metaData);
			mixpanelTrack(event, metaData);
			chmlnTrack(event);
			trackCoralogixRumEvent(event, metaData);
		},
		[page, isReportingVerbose]
	);

	return <PageContext.Provider value={{ reportEvent }}>{children}</PageContext.Provider>;
}

export type PageType =
	| 'Dashboard'
	| 'Metric Page'
	| 'Create New Metric Page'
	| 'Signal Page'
	| 'Workspace'
	| 'Metric Catalog'
	| 'Data Hygiene'
	| 'Configuration Page'
	| 'Not Found Page'
	| 'Feed Page'
	| 'Subscription Page'
	| 'Entity Profile Drawer'
	| 'Entity Profile Page'
	| 'Search Results Page'
	| 'Menu'
	| 'Ontology Page'
	| 'Unknown';
export const PageContext = createContext<{
	reportEvent: (props: Props) => void;
}>({
	reportEvent: () => void 0,
});
