import { useMemo } from 'react';

const DEFAULT_MAX_IN_FLIGHT = 5;

type PoolItem<T extends (...args: any) => any> = {
	resolve: (...args: any) => any | PromiseLike<(...args: any) => any>;
	reject: (reason?: any) => void;
	runner: T;
	args: Parameters<T>;
	cancelControl: CancelControl;
};

type WrappedCancellableRunner<T extends (...args: any) => any> = [
	(cancelControl: CancelControl) => {
		execute: (...args: Parameters<T>) => Promise<ReturnType<T>>;
	},
	(...args: Parameters<T>) => Promise<ReturnType<T>>
];

export class CancelledError extends Error {
	name = 'CancelledError';
	constructor() {
		super('cancelled');
	}
}

export class CancelControl {
	private _cancelled = false;

	cancel() {
		this._cancelled = true;
	}
	get cancelled() {
		return this._cancelled;
	}
}

class Pool {
	private waiting: PoolItem<(...args: any) => any>[] = [];
	private inFlight = 0;

	constructor(private maxInFlight: number) {}

	private _doRunNext() {
		if (this.inFlight >= this.maxInFlight) {
			return;
		}

		const item = this.waiting.shift();
		if (!item) return;
		const { cancelControl, resolve, reject, runner, args } = item;
		if (cancelControl.cancelled) {
			reject(new CancelledError());
			this.runNext();
			return;
		}

		++this.inFlight;
		runner(...args)
			.then(resolve)
			.catch(reject)
			.finally(() => {
				--this.inFlight;
				this.runNext();
			});
	}

	private runNext() {
		setTimeout(() => this._doRunNext());
	}

	wrap<T extends (...args: any) => any>(runner: T): WrappedCancellableRunner<T> {
		const _executeWithCancelControl = (
			cancelControl: CancelControl,
			...args: Parameters<T>
		): Promise<ReturnType<T>> => {
			const promise = new Promise<ReturnType<T>>((resolve, reject) => {
				this.waiting.push({ resolve, reject, runner, args, cancelControl });
				this.runNext();
			});
			return promise;
		};

		const cancellableExecute = (cancelControl: CancelControl) => {
			return {
				execute: (...args: Parameters<T>): Promise<ReturnType<T>> => {
					return _executeWithCancelControl(cancelControl, ...args);
				},
			};
		};

		const execute = (...args: Parameters<T>): Promise<ReturnType<T>> => {
			return cancellableExecute(new CancelControl()).execute(...args);
		};

		return [cancellableExecute, execute];
	}
}

const POOLS = new Map<string, Pool>();

export function getPoolByName(name: string, maxInFlight: number) {
	let pool = POOLS.get(name);
	if (!pool) {
		pool = new Pool(maxInFlight);
		POOLS.set(name, pool);
	}
	return pool;
}

export function useExecutionPool<T extends (...args: any) => any>(
	runner: T,
	options: { name?: string; maxInFlight?: number } = {}
) {
	const { name = runner.name, maxInFlight = DEFAULT_MAX_IN_FLIGHT } = options;
	const pool = useMemo(() => getPoolByName(name, maxInFlight), [maxInFlight, name]);

	const wrapped = useMemo(() => pool.wrap(runner), [pool, runner]);
	return wrapped;
}
