import { Box, Center } from '@chakra-ui/react';
import { BrokenThumbnail } from '@components/BrokenThumbnail';
import Flex from '@components/Flex';
import { forwardRef, Ref } from 'react';
import Chart from 'src/common/components/Chart';
import { ChartComponentRef } from 'src/common/components/Chart/Chart';
import { Code24, Funnel24, Prohibit24 } from 'src/common/components/Icons';
import Spinner from 'src/common/components/Spinner';
import { TableLayout } from 'src/pages/MetricPage/components/Table/TableLayout';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';
import colors from 'src/style/colors';
import { useMetricDerivedState } from './hooks/useMetricDerivedState';

export const MetricThumbnail = forwardRef(function (
	{ isDataUnavailableError = false }: { isDataUnavailableError?: boolean },
	chartRef: Ref<ChartComponentRef>
) {
	const { chartOptions, isLoading, errorMessage, doesMetricExist } = useMetricDerivedState();
	const {
		searchParams: { chartType },
	} = useMetricPageSearchParams();

	if (!isLoading && doesMetricExist && errorMessage && !isDataUnavailableError)
		return (
			<Center height="100%">
				<BrokenThumbnail
					title={'Broken metric'}
					subtitle={'Review the error message for more details.'}
					icon={<Code24 color={colors.gray[600]} />}
				/>
			</Center>
		);

	if (!isLoading && !doesMetricExist) {
		return (
			<Center height="100%">
				<BrokenThumbnail
					title={'Metric unavailable'}
					subtitle={'The metric was deleted or renamed.'}
					icon={<Prohibit24 color={colors.gray[500]} />}
				/>
			</Center>
		);
	}
	if (isDataUnavailableError) {
		return (
			<Center height="100%">
				<BrokenThumbnail
					title={'Data unavailable'}
					subtitle={'Try adjusting the period or filters.'}
					icon={<Funnel24 color={colors.gray[500]} />}
				/>
			</Center>
		);
	}

	return chartOptions && !isLoading ? (
		chartType == 'table' ? (
			<Box height={'100%'}>
				<TableLayout isThumbnail isLoading={false} onModalOpen={() => void 0} />
			</Box>
		) : (
			<Chart isEntityPage ref={chartRef} />
		)
	) : (
		<Flex height={'100%'} width="100%" justifyContent={'center'} alignItems={'center'}>
			<Spinner />
		</Flex>
	);
});
MetricThumbnail.displayName = 'MetricThumbnail';
