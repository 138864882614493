import Box from '@components/Box';
import Flex from '@components/Flex';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TinySwitch } from 'src/common/components/TinySwitch/TinySwitch';
import Typography from 'src/common/components/Typography';
import { SelectLabel, TooltipIcon } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';
import { KeysType } from 'src/pages/OntologyPage/hooks/useDimensionBuilderDerivedState';
import { useReportEvent } from 'src/services/analytics';

import { useAtomValue } from 'jotai';
import _ from 'lodash';
import BuilderTextInput from 'src/common/components/BuilderTextInput';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal';
import {
	CoreReaderDataModelDimension,
	useCoreReaderFiltersApi,
} from 'src/common/hooks/fetching/useCoreReaderFiltersApi';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useJoinKeyTextFieldProviders } from 'src/lib/completions/hooks/useMonacoTextFieldProviders';
import { OntologyStateAtomDerived } from '../../atoms/OntologyState';
import { extractJoinConditions, getIconByType, isJoinKeysFormat } from '../../utils/utils';
import { JoinKeysTooltip } from '../Tooltips/JoinKeysTooltip';
import { UIField } from './UIField';

export interface DimensionsValues {
	referencedDimensions: CoreReaderDataModelDimension[];
	dimensionsList: CoreReaderDataModelDimension[];
	isShouldCheckFormat: boolean;
	joinValuesArray: string[];
}

export function JoinKeysField({
	isEditRelationshipFlow = true,
	onChange,
	value,
	isEditable = true,
	referencedEntity,
	relationshipName,
}: {
	value: string;
	isEditRelationshipFlow?: boolean;
	onChange: (key: KeysType[0]['key'], value: KeysType[0]['value']) => void;
	isEditable?: boolean;
	referencedEntity: string;
	relationshipName?: string;
}) {
	const initialState: DimensionsValues = {
		referencedDimensions: [],
		dimensionsList: [],
		isShouldCheckFormat: true,
		joinValuesArray: value ? extractJoinConditions(value) : [''],
	};

	const [dimensionsValues, setDimensionsValues] = useState<DimensionsValues>(initialState);
	const [{ loading }, fetchRelationshipsAndDimensions] = useCoreReaderFiltersApi('cache-first');
	const { isOpen: isWarningModalOpen, onOpen: onWarningModalOpen, onClose: onWarningModalClose } = useModal();

	const { referencedDimensions, dimensionsList, isShouldCheckFormat, joinValuesArray } = dimensionsValues;

	useEffect(() => {
		if (!value) {
			setDimensionsValues((prevState) => ({
				...prevState,
				joinValuesArray: [''],
			}));
			return;
		}
		if (isJoinKeysFormat(value))
			setDimensionsValues((prevState) => ({
				...prevState,
				joinValuesArray: extractJoinConditions(value),
			}));

		if (isShouldCheckFormat) setIsFormula(!isJoinKeysFormat(value));
	}, [value, isShouldCheckFormat]);

	const fetchEntityDimensions = useCallback(
		async (enityName: string, type?: string) => {
			const response = await fetchRelationshipsAndDimensions([enityName]);
			if (response) {
				const sortedDimensions = _.sortBy(response?.[0]?.dimensions, (item: CoreReaderDataModelDimension) =>
					item.name.toLowerCase().trim()
				);
				if (type === 'referenced') {
					setDimensionsValues((prevState) => ({ ...prevState, referencedDimensions: sortedDimensions }));
				} else {
					setDimensionsValues((prevState) => ({ ...prevState, dimensionsList: sortedDimensions }));
				}
			}
		},
		[fetchRelationshipsAndDimensions]
	);

	const ontologyState = useAtomValue(OntologyStateAtomDerived);

	useEffect(() => {
		if (ontologyState.loading) return;
		if (referencedEntity) fetchEntityDimensions(referencedEntity, 'referenced');
		if (ontologyState.entityName) fetchEntityDimensions(ontologyState.entityName);
	}, [fetchEntityDimensions, referencedEntity, ontologyState]);

	const { wrapWithReport } = useReportEvent({
		entityName: isEditRelationshipFlow ? (ontologyState.loading ? '' : ontologyState.entityName) : '',
		feature: 'Join keys',
		objectType: 'relationships',
		objectName: isEditRelationshipFlow ? (ontologyState.loading ? '' : ontologyState.entityName) : '',
		parentEntity: ontologyState.loading ? '' : ontologyState.entityName,
		flow: isEditRelationshipFlow ? 'edit' : 'create',
	});

	const mapDimensions = useCallback(
		(dimensions: CoreReaderDataModelDimension[]) =>
			dimensions.map((el) => ({
				value: el.identifier,
				label: el.name,
				icon: getIconByType(el.type, 'small'),
			})),
		[]
	);

	const fromKeyOptions = useMemo(() => mapDimensions(dimensionsList), [dimensionsList, mapDimensions]);
	const toKeyOptions = useMemo(() => mapDimensions(referencedDimensions), [referencedDimensions, mapDimensions]);
	const isValidFormat = useMemo(() => isJoinKeysFormat(value), [value]);
	const [isFormula, setIsFormula] = useState(!isValidFormat);

	useJoinKeyTextFieldProviders('keysFormula', ontologyState.loading ? '' : ontologyState.entityName, referencedEntity);

	if (ontologyState.loading) return;

	const Formula = (
		<Flex direction={'column'}>
			<Flex marginBottom={'8px'}>
				<SelectLabel color={'gray.1100'} text="Formula" />
			</Flex>
			<BuilderTextInput
				reportFields={{
					name: 'ontology-object-edit-UI-input-provided',
					fields: {
						objectName: isEditRelationshipFlow ? ontologyState.entityName : '',
						objectType: 'relationships',
						parentEntity: ontologyState.entityName,
						fieldName: 'formula',
						type: 'text-input',
						flow: isEditRelationshipFlow ? 'edit' : 'create',
					},
				}}
				fieldName={'keysFormula'}
				placeholder={'Add formula'}
				validation={'None'}
				validationText={'Validation text'}
				readOnly={!isEditable}
				text={value || ''}
				id={'keysFormula'}
				onBlur={(inputText) => {
					setDimensionsValues((prevState) => ({
						...prevState,
						isShouldCheckFormat: false,
					}));
					onChange('on', inputText);
				}}
			/>
		</Flex>
	);

	return (
		<Box>
			<Flex marginBottom="16px" justifyContent={'space-between'} alignItems={'center'}>
				<Flex alignItems={'center'}>
					<Typography marginRight={'6px'} color={'gray.1000'} variant={'DesktopH8Medium'}>
						Join keys
					</Typography>
					<TooltipIcon tooltipBody={<JoinKeysTooltip />} />
				</Flex>
				<TinySwitch
					onClick={wrapWithReport(
						() => {
							if (!isFormula) {
								setIsFormula(!isFormula);
								return;
							}
							if (isValidFormat) {
								setIsFormula(!isFormula);
								return;
							}
							onWarningModalOpen();
						},
						'relationship-edit-formula-toggle',
						{
							objectType: 'relationship',
							objectName: isEditRelationshipFlow ? ontologyState.entityName : '',
							newState: !isFormula ? 'Formula' : 'UI',
						}
					)}
					isEnabled={isFormula}
					text="Formula"
				/>
			</Flex>
			{isFormula ? (
				Formula
			) : (
				<UIField
					isLoading={loading}
					isEditRelationshipFlow={isEditRelationshipFlow}
					isEditable={isEditable}
					fromKeyOptions={fromKeyOptions}
					toKeyOptions={toKeyOptions}
					onChange={onChange}
					referencedEntity={referencedEntity}
					joinValuesArray={joinValuesArray}
					setDimensionsValues={setDimensionsValues}
				/>
			)}
			<ConfirmationModal
				primaryButtonLabel="Leave"
				submitColorScheme="blue"
				isOpen={isWarningModalOpen}
				onSubmit={wrapWithReport(
					() => {
						const initialValue =
							ontologyState.parsedYaml.relationships.find((el) => el.name === relationshipName)?.on ?? '';
						onChange('on', isJoinKeysFormat(initialValue) ? initialValue : '');
						setIsFormula(false);
						onWarningModalClose();
						setDimensionsValues((prevState) => ({
							...prevState,
							joinValuesArray: isJoinKeysFormat(initialValue) ? [initialValue] : [''],
						}));
					},
					`relationship-edit-leave-period-modal`,
					{
						action: 'confirm',
						objectName: isEditRelationshipFlow ? ontologyState.entityName : '',
						objectType: 'relationships',
						parentEntity: ontologyState.entityName,
					}
				)}
				modalTitle="Leave formula mode"
				modalText={` Leaving the formula mode will clear your Formula input.`}
				onClose={wrapWithReport(onWarningModalClose, `relationship-edit-leave-period-modal`, {
					action: 'cancel',
					objectType: 'entity',
				})}
			/>
		</Box>
	);
}
