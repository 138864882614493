import * as monaco from 'monaco-editor';
import { CompletionProvider, Context, functionContextsWithInvisible } from '../completionProvider';
import { SuggestionsContextSettings } from '../hooks/useMonacoTextFieldProviders';
import { EnrichedSemanticDefinitions } from '../semanticTypes';
import { getEntity, getMetricByNameFromList } from '../utils/utils';

export const buildMonacoSemanticProvider =
	({ entity, metric }: SuggestionsContextSettings, semanticDefinitions: EnrichedSemanticDefinitions) =>
	(model: monaco.editor.ITextModel): monaco.languages.ProviderResult<monaco.languages.SemanticTokens> => {
		const outputTokenData: number[] = [];
		let prevLine = 0;
		let prevChar = 0;

		const appendData = (
			lineNumber: number,
			colPosition: number,
			length: number,
			tokenTypeIndex: number,
			modifierIndex: number
		) => {
			outputTokenData.push(lineNumber - prevLine, colPosition - prevChar, length, tokenTypeIndex, modifierIndex);

			prevLine = lineNumber;
			prevChar = colPosition;
		};

		const completionProvider = new CompletionProvider(semanticDefinitions);

		const enrichedEntity = getEntity(semanticDefinitions, entity);
		const enrichedMetric = getMetricByNameFromList(semanticDefinitions.metrics, metric);
		const initialContexts = completionProvider.createContexts({
			entity: enrichedEntity,
			metric: enrichedMetric,
		});

		model
			.getValue()
			.split('\n')
			.map((line, lineIndex) => {
				const newTokens = checkRestOfLineForNewTokens({
					completionProvider,
					initialContexts,
					line,
				});
				newTokens.forEach((token) => {
					appendData(lineIndex, token.tokenIndex, token.token.length, SemanticTokens.indexOf(token.tokenType), 0);
				});
			});

		return {
			data: new Uint32Array(outputTokenData),
		};
	};

const EXPRESSION_START_PART = /\$[.\w_]+/;

export function checkRestOfLineForNewTokens({
	completionProvider,
	initialContexts,
	line,
	indexOffset = 0,
	foundTokens = [],
}: {
	completionProvider: CompletionProvider;
	initialContexts: Context[];
	line: string;
	indexOffset?: number;
	foundTokens?: SemanticTokensDefinition[];
}): SemanticTokensDefinition[] {
	const token = line.match(EXPRESSION_START_PART)?.[0];

	if (token) {
		const tokenStartIndex = line.indexOf(token);
		const tokenEndIndex = tokenStartIndex + token.length;

		const subTokens: SemanticTokensDefinition[] = token.split('.').map((subToken, index, subTokens) => {
			const subTokensUntilIncludingCurrent = subTokens.slice(0, index + 1).join('.');
			const fullTokenWithoutDollar = subTokensUntilIncludingCurrent.replace('$', '');
			const contexts = completionProvider.walkContextForCompletions(
				initialContexts,
				fullTokenWithoutDollar,
				fullTokenWithoutDollar.length,
				true,
				true
			);

			const subTokenContext =
				contexts.find((c) => c.keyword === subToken.replace('$', '')) ??
				functionContextsWithInvisible.find((c) => c.keyword === subToken.replace('$', ''));

			const subTokenIndex = subTokensUntilIncludingCurrent.length - subToken.length;

			return {
				token: subToken,
				tokenIndex: indexOffset + tokenStartIndex + subTokenIndex,
				tokenType: subTokenContext ? ContextTypeToSemanticIndex[subTokenContext.type] : 'unknown',
			};
		});

		return checkRestOfLineForNewTokens({
			completionProvider,
			initialContexts,
			line: line.slice(tokenEndIndex),
			indexOffset: indexOffset + tokenEndIndex,
			foundTokens: [...foundTokens, ...subTokens],
		});
	}
	return foundTokens;
}

export const SemanticTokens = [
	'pulse-entity',
	'pulse-metric',
	'pulse-parameter',
	'pulse-dimension',
	'pulse-join',
	'pulse-function',
	'unknown',
];
export type SemanticTokensType = typeof SemanticTokens[number];
export const ContextTypeToSemanticIndex: { [key in Context['type']]: SemanticTokensType } = {
	relationship: 'pulse-entity',
	entity: 'pulse-entity',
	metric: 'pulse-metric',
	formula_metric: 'pulse-metric',
	dimension: 'pulse-dimension',
	dimension_numeric: 'pulse-dimension',
	parameter_store: 'pulse-parameter',
	parameter: 'pulse-parameter',
	join: 'pulse-join',
	function: 'pulse-function',
	dimension_date: 'pulse-dimension',
	dimension_boolean: 'pulse-dimension',
};

type SemanticTokensDefinition = { token: string; tokenIndex: number; tokenType: SemanticTokensType };
