import useTrial from '@hooks/ui/useTrial';
import TrialBar from 'src/layout/TrialBar/TrialBar';

function TrialBarContainer() {
	const { trialEnd } = useTrial();

	if (!trialEnd) return null;

	return <TrialBar trialEnd={new Date(trialEnd)} />;
}

export default TrialBarContainer;
