import { MetricPeriod } from '@sightfull/period-ranges';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export function calcSelectedXAxisElement(
	{ selectedXAxisElements, selectedDimensionName, selectedPeriod }: MetricSearchParams,
	{ availablePeriodRanges, chartOptions }: Pick<MetricDerivedState, 'availablePeriodRanges' | 'chartOptions'>
): Pick<MetricDerivedState, 'selectedXAxisElements'> {
	const mapAndFilterSelectedXAxisElements = (elements: string[]): MetricPeriod[] => {
		return elements
			.map((e) => {
				return MetricPeriod.tryFromIdString(e, fiscalYearOffset());
			})
			.filter((selectedMetricPeriod) => {
				return (
					selectedMetricPeriod && availablePeriodRanges[selectedMetricPeriod.unit]?.withinRange(selectedMetricPeriod)
				);
			}) as MetricPeriod[];
	};

	if (selectedXAxisElements) {
		if (Array.isArray(selectedXAxisElements)) {
			return { selectedXAxisElements: mapAndFilterSelectedXAxisElements(selectedXAxisElements) };
		}
		if (selectedXAxisElements == 'all') {
			return { selectedXAxisElements: chartOptions?.xAxis.values ?? [] };
		}
	}
	if (selectedPeriod) {
		const selectedMetricPeriod = MetricPeriod.tryFromIdString(selectedPeriod, fiscalYearOffset());
		if (selectedMetricPeriod && availablePeriodRanges[selectedMetricPeriod.unit]?.withinRange(selectedMetricPeriod))
			return { selectedXAxisElements: [selectedMetricPeriod] };
	}
	if (selectedDimensionName) {
		// TODO: when flippedAxis is enabled, set the selection here too
	}
	return { selectedXAxisElements: [] };
}
