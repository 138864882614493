import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { $convertFromMarkdownString } from '@lexical/markdown';
import { LexicalComposer as InnerLexicalComposer } from '@lexical/react/LexicalComposer';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { LineBreakNode } from 'lexical';
import { ReactNode } from 'react';
import { markdownTransformers } from 'src/common/components/TextEditor/consts';
import { MentionNode } from '../nodes/mention';
import theme from '../theme';

type LexicalComposerProps = {
	children: ReactNode;
	value: string;
};

function LexicalComposer({ value, children }: LexicalComposerProps) {
	const editorConfig = {
		namespace: 'Text editor',
		theme,
		editorState: () => {
			$convertFromMarkdownString(value, markdownTransformers);
		},
		onError(error: Error) {
			throw error;
		},
		nodes: [
			HeadingNode,
			ListNode,
			ListItemNode,
			QuoteNode,
			CodeNode,
			CodeHighlightNode,
			TableNode,
			TableCellNode,
			TableRowNode,
			AutoLinkNode,
			LinkNode,
			MentionNode,
			LineBreakNode,
		],
	};

	return (
		<InnerLexicalComposer initialConfig={editorConfig}>
			<>{children}</>
		</InnerLexicalComposer>
	);
}

export default LexicalComposer;
