import { useCallback, useMemo, useState } from 'react';
import useLazyQuery from 'src/common/hooks/fetching/useLazyQuery';
import { GetAllMetricsQuery, Metrics } from 'src/generated/graphql';
import { metricCategoriesFromGraphQL } from 'src/normalize';
import { GetAllMetrics } from 'src/queries/metrics';
import { Category } from 'src/types/metric';

export const useMetricCategories = (): [Category[], () => void, boolean] => {
	const [shouldDropUncategorized, setShouldDropUncategorized] = useState(true);
	const toggleShouldDropUncategorized = useCallback(
		() => setShouldDropUncategorized(!shouldDropUncategorized),
		[shouldDropUncategorized]
	);

	const [fetchMetrics, { data: rawMetrics, error: didMetricLoadFailed, loading: isLoadingMetrics }] =
		useLazyQuery<GetAllMetricsQuery>(GetAllMetrics);

	const getMetricCategories = useCallback(() => {
		if (rawMetrics?.metrics) {
			return metricCategoriesFromGraphQL(rawMetrics.metrics as Metrics[], { shouldDropUncategorized });
		}
		if (!didMetricLoadFailed && !isLoadingMetrics) {
			fetchMetrics();
		}

		return [];
	}, [rawMetrics, didMetricLoadFailed, isLoadingMetrics, fetchMetrics, shouldDropUncategorized]);

	const metricCategories = useMemo(() => getMetricCategories(), [getMetricCategories]);

	return [metricCategories, toggleShouldDropUncategorized, isLoadingMetrics];
};
