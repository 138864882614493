import { useEffect, useRef } from 'react';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useGetSemanticsLazyQuery } from 'src/generated/graphql';
import { EnrichedSemanticDefinitions } from 'src/lib/completions/semanticTypes';
import { create } from 'zustand';

type SemanticsStore = {
	semantics?: EnrichedSemanticDefinitions;
	setSemantics: (semantics: EnrichedSemanticDefinitions) => void;
};

export const useSemanticsStore = create<SemanticsStore>((set) => ({
	semantics: undefined,
	setSemantics: (semantics: EnrichedSemanticDefinitions) => set({ semantics }),
}));

export const selectors = {
	getSemantics: (state: SemanticsStore) => state.semantics,
	setSemantics: (state: SemanticsStore) => state.setSemantics,
};

export function useInitSemantics() {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const { setSemantics } = useSemanticsStore();
	const isAlreadyCalled = useRef<boolean>(false);
	const [fetchSemantics] = useGetSemanticsLazyQuery();

	useEffect(() => {
		if (!isSightfull2) return;
		if (isAlreadyCalled.current) {
			return;
		}

		isAlreadyCalled.current = true;

		fetchSemantics({}).then((result) => setSemantics(result.data?.getSemantics || []));
	}, [fetchSemantics, isSightfull2, setSemantics]);
	return setSemantics;
}
