import * as CSS from 'csstype';
import colors from 'src/style/colors';

type TabMode = 'selected' | 'disabled';

export const textColors: { [key in TabMode | 'normal']: CSS.Property.Color } = {
	selected: colors.gray['800'],
	disabled: colors.gray['400'],
	normal: colors.gray['600'],
};

export const getTextColor = ({ selected, disabled }: { [key in TabMode]: boolean }): CSS.Property.Color => {
	if (selected) return textColors.selected;
	if (disabled) return textColors.disabled;
	return textColors.normal;
};
