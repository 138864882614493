import { useMemo, useState } from 'react';
import Flex from 'src/common/components/Flex';
import { EntityType, RelatedEntity } from 'src/layout/EntityProfileDrawer/types';
import { normalizeRelatedEntitiesToDisplay } from 'src/layout/EntityProfileDrawer/utilities';
import { extractNodeDisplayColumns } from 'src/layout/EntityProfileDrawer/utilities/normalization/normalizeRelatedEntitiesForNode';
import { useReportEvent } from 'src/services/analytics';
import { SearchQueryResultEntity } from '../types';
import { getTablePaginationLimit, resultsPageMetadata } from '../utils';
import { EntityTableHeader } from './EntityTableHeader';
import { EntityTableHeaderV2 } from './EntityTableHeaderV2';
import { EntityTableRows } from './EntityTableRows';
import { EntityTableRowsV2 } from './EntityTableRowsV2';

const DEFAULT_ENTITIES_TO_SHOW = 3;

export function EntityTable({
	entityType,
	isAllSearch,
	isAllChosen,
	resultEntities,
	searchTerm,
	setSearchTerm,
	onViewAllClick,
}: {
	entityType: EntityType;
	isAllSearch: boolean;
	isAllChosen: boolean;
	resultEntities: SearchQueryResultEntity[];
	searchTerm: string;
	setSearchTerm: (term: string) => void;
	onViewAllClick: () => void;
}) {
	const columns = extractNodeDisplayColumns({ entityType: entityType as EntityType });

	const entities = resultEntities.map(({ node }: { node: RelatedEntity }) => node);
	const entitiesIds = Array.from(new Set(entities.map((entity) => entity['T.id'])));
	const uniqEntities = entitiesIds
		.map((entityId) => entities.find((entity) => entity['T.id'] == entityId))
		.map((entity) => (entity == null ? {} : entity));

	const allRowsCount = uniqEntities.length;
	const pageSize = isAllChosen
		? Math.min(columns?.display?.config?.first ?? DEFAULT_ENTITIES_TO_SHOW, allRowsCount)
		: allRowsCount;

	const entitiesBeforeSearchTerm = uniqEntities.slice(0, pageSize);
	const normalizedRows = normalizeRelatedEntitiesToDisplay(columns, searchTerm, entitiesBeforeSearchTerm);

	return (
		<Flex
			key={`search-results-object-${entityType}`}
			direction="column"
			alignItems="flex-start"
			gap="16px"
			alignSelf="stretch"
		>
			<EntityTableHeader
				entityType={entityType}
				isAllSearch={isAllSearch}
				isAllChosen={isAllChosen}
				pageSize={pageSize}
				shownRowsCount={normalizedRows.length}
				allRowsCount={allRowsCount}
				onViewAllClick={onViewAllClick}
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
			/>
			<EntityTableRows
				normalizedRows={normalizedRows}
				pageSize={pageSize}
				entityType={entityType}
				isAllSearch={isAllSearch}
				isAllChosen={isAllChosen}
			/>
		</Flex>
	);
}

export function EntityTableV2({
	entityName,
	entityDisplayName,
	resultEntities,
	isAllSearch,
	isAllChosen,
}: {
	entityName: string;
	entityDisplayName: string;
	resultEntities: SearchQueryResultEntity[];
	isAllSearch: boolean;
	isAllChosen: boolean;
}) {
	const { reportEvent } = useReportEvent();

	const totalRows = resultEntities.length;
	const [startIndex, setStartIndex] = useState(0);

	const commonPaginationEventMeta = {
		object: entityName,
		tab: isAllChosen ? 'All' : entityDisplayName,
		tableName: entityDisplayName,
		totalRows,
	};

	const tablePaginationLimit = useMemo(() => getTablePaginationLimit(isAllChosen), [isAllChosen]);

	const onNext = () => {
		if (totalRows <= startIndex + tablePaginationLimit) return;

		const oneBasedStartIndex = startIndex + 1;
		reportEvent({
			event: 'search-results-next-clicked',
			metaData: {
				...resultsPageMetadata(isAllSearch),
				...commonPaginationEventMeta,
				indexBefore: oneBasedStartIndex,
				indexAfter: oneBasedStartIndex + tablePaginationLimit,
			},
		});

		setStartIndex(startIndex + tablePaginationLimit);
	};

	const onPrevious = () => {
		if (0 > startIndex - tablePaginationLimit) return;

		const oneBasedStartIndex = startIndex + 1;
		reportEvent({
			event: 'search-results-previous-clicked',
			metaData: {
				...resultsPageMetadata(isAllSearch),
				...commonPaginationEventMeta,
				indexBefore: oneBasedStartIndex,
				indexAfter: oneBasedStartIndex - tablePaginationLimit,
			},
		});

		setStartIndex(startIndex - tablePaginationLimit);
	};

	return (
		<Flex height={'100%'} flexDirection="column" gap="12px">
			<EntityTableHeaderV2
				entityDisplayName={entityDisplayName}
				onNext={onNext}
				onPrevious={onPrevious}
				totalRows={totalRows}
				tablePaginationLimit={tablePaginationLimit}
			/>
			<EntityTableRowsV2
				entityName={entityName}
				entityDisplayName={entityDisplayName}
				resultEntities={resultEntities}
				startIndex={startIndex}
				isAllSearch={isAllSearch}
				isAllChosen={isAllChosen}
				tablePaginationLimit={tablePaginationLimit}
			/>
		</Flex>
	);
}
