import Box from '@components/Box';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import { LayoutOptions } from '@components/MetricView/MetricViewLayout';
import { DockBttm16, DockBttmFull16, DockCenter16, DockCenterFull16, DockTop16, DockTopFull16 } from '@icons/index';
import { SkeletonViewDivider } from 'src/pages/MetricPage/components/SkeletonComponents';
import Tooltip from '../Tooltip';
import classes from './MetricView.module.scss';

export type ViewDividerProps = {
	selectedLayout: LayoutOptions;
	onSelectLayout: (option: LayoutOptions) => void;
	isLoading: boolean;
};
export function ViewDivider({ selectedLayout: selectedLayoutOption, onSelectLayout, isLoading }: ViewDividerProps) {
	function getClassName(option: LayoutOptions) {
		return [classes.layoutOption, ...(option == selectedLayoutOption ? [classes.selected] : [])].join(' ');
	}

	const realViewDivider = (
		<Flex flexDirection="row" alignItems="center">
			<Flex grow={1}>
				<Box width="100%">
					<Divider direction="horizontal" color={'gray.300'} />
				</Box>
			</Flex>
			<Flex flexDirection="row">
				<Tooltip size={'sm'} label="Chart and Table" marginTop={'12px'}>
					<button onClick={() => onSelectLayout('split')} className={getClassName('split') + ' ' + classes.first}>
						{selectedLayoutOption == 'split' ? <DockCenterFull16 /> : <DockCenter16 />}
					</button>
				</Tooltip>
				<Tooltip size={'sm'} label="View Chart" marginTop={'12px'}>
					<button
						onClick={() => onSelectLayout('bottom-collapsed')}
						className={getClassName('bottom-collapsed') + ' ' + classes.center}
					>
						{selectedLayoutOption == 'bottom-collapsed' ? <DockTopFull16 /> : <DockTop16 />}
					</button>
				</Tooltip>
				<Tooltip size={'sm'} label="View Table" marginTop={'12px'}>
					<button
						onClick={() => onSelectLayout('top-collapsed')}
						className={getClassName('top-collapsed') + ' ' + classes.last}
					>
						{selectedLayoutOption == 'top-collapsed' ? <DockBttmFull16 /> : <DockBttm16 />}
					</button>
				</Tooltip>
			</Flex>
			<Flex grow={1}>
				<Box width="100%">
					<Divider direction="horizontal" color={'gray.300'} />
				</Box>
			</Flex>
		</Flex>
	);

	return !isLoading ? realViewDivider : <SkeletonViewDivider />;
}
