import Box from '@components/Box';
import Typography from '@components/Typography';
import { differenceInDays } from 'date-fns';
import React from 'react';
import Button from 'src/common/components/Button';
import NotificationBar from 'src/common/components/NotificationBar';
import { useReportEvent } from 'src/services/analytics';

export const trialUpgradeButtonTestId = 'trial-upgrade-button';

const SIGHFULL_PRICING_PAGE = 'https://www.sightfull.com/pricing/stripe/';

function generateTrialMessage(trialEnd: Date): JSX.Element {
	const daysLeftToTrial = differenceInDays(trialEnd, new Date()) + 1;
	let innerText: React.ReactNode;
	if (daysLeftToTrial <= 0) {
		innerText = 'Your trial with Sightfull has ended';
	} else {
		innerText = (
			<>
				You have&nbsp;
				<Typography as="span" color="white" variant="Paragraph14SB">
					{daysLeftToTrial} day{daysLeftToTrial > 1 ? 's' : ''}
				</Typography>
				&nbsp;left on your trial
			</>
		);
	}

	return (
		<Typography as="span" color="white" variant="Paragraph14R">
			{innerText}
		</Typography>
	);
}

function TrialBar({ trialEnd }: { trialEnd: Date }) {
	const { reportEvent } = useReportEvent();
	const message = generateTrialMessage(trialEnd);

	function onUpgradeClick() {
		reportEvent({ event: 'upgrade-trial-request' });
		window.open(SIGHFULL_PRICING_PAGE, '_blank');
	}

	return (
		<NotificationBar>
			<Box pl="8px" pr="16px">
				{message}
			</Box>

			<Button
				variant="ghost"
				colorScheme="white"
				color={'white'}
				onClick={onUpgradeClick}
				testId={trialUpgradeButtonTestId}
				size="small"
			>
				Upgrade now
			</Button>
		</NotificationBar>
	);
}

export default TrialBar;
