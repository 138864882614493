import Typography from 'src/common/components/Typography';
import { ExampleText, LearnMore } from './common';
import { LearnMoreProps } from './common/LearnMore';

const FILTERS_LINK = 'https://docs.sightfull.com/core/metrics/syntax/filters';

export function FiltersTooltip({ onHelpClicked }: { onHelpClicked: LearnMoreProps['onClick'] }) {
	return (
		<Typography color={'white'} variant="Paragraph14R">
			A set of criteria or logical conditions applied on the entity, allowing to select the subset of data used in the
			metric.&nbsp;
			<LearnMore onClick={onHelpClicked} link={FILTERS_LINK} />.
			<ExampleText marginTop={'16px'} text={'$is_won = true OR $amount > 0'} />
			<ExampleText marginTop={'8px'} marginBottom={'4px'} text={"$owner_user.region = 'APAC'"} />
			<ExampleText marginTop={'8px'} marginBottom={'4px'} text={"$name IN ('A', 'B', 'C')"} />
		</Typography>
	);
}
