import Flex from '@components/Flex';
import Box from '@components/Box';
import Typography from 'src/common/components/Typography';
import {
	LearnMore,
	LearnMoreProps,
} from 'src/pages/MetricPage/components/CalculatePanel/Builder/components/BuilderTooltips/common/LearnMore';
import { DIMENSIONS_SEMANTIC_LINK } from '../../utils/consts';
import { ArrowDown14 } from 'src/common/components/Icons';

export function DimensionsFormulaTooltip({ onHelpClicked }: { onHelpClicked: LearnMoreProps['onClick'] }) {
	return (
		<Typography color={'white'} variant="Paragraph14R">
			List of possible source dimensions or calculations.&nbsp;
			<LearnMore onClick={onHelpClicked} link={DIMENSIONS_SEMANTIC_LINK} />.
			<Box marginTop={'16px'} backgroundColor={'gray.1000'} padding={'2px 8px'} borderRadius={'2px'}>
				<Typography display={'block'} color={'lime.700'} variant="Paragraph12M">
					$src__contract_length
				</Typography>
				<Flex alignItems={'center'}>
					<ArrowDown14 />
					<Typography marginLeft={'8px'} display={'block'} color={'lime.700'} variant="Paragraph12M">
						(12)
					</Typography>
				</Flex>
			</Box>
			<Box
				marginTop={'8px'}
				marginBottom={'4px'}
				backgroundColor={'gray.1000'}
				padding={'2px 8px'}
				borderRadius={'2px'}
			>
				<Typography display={'block'} color={'lime.700'} variant="Paragraph12M">
					$src__probability
				</Typography>
				<Flex alignItems={'center'}>
					<ArrowDown14 />
					<Typography marginLeft={'8px'} display={'block'} color={'lime.700'} variant="Paragraph12M">
						$stage.src__default_probability
					</Typography>
				</Flex>
				<Flex alignItems={'center'}>
					<ArrowDown14 />
					<Typography marginLeft={'8px'} display={'block'} color={'lime.700'} variant="Paragraph12M">
						(50)
					</Typography>
				</Flex>
			</Box>
		</Typography>
	);
}
