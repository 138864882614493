import { gql } from '@apollo/client';

export const GetAllConfigs = gql`
	query GetAllConfig {
		get_all_configs {
			response
		}
	}
`;

export const GetConfigByName = gql`
	query GetConfigByName($name: String!) {
		get_config(name: $name) {
			response
		}
	}
`;

export const UpsertConfigByName = gql`
	mutation SaveConfig($name: String!, $config: jsonb) {
		save_config(name: $name, config: $config) {
			response
		}
	}
`;
