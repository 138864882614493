import { loader } from '@monaco-editor/react';
import * as monacoEditor from 'monaco-editor';
import { configureMonacoYaml } from 'monaco-yaml';
import { useLayoutEffect } from 'react';
import useTenantConfig from 'src/common/hooks/stores/useTenantConfig';
import './MonacoEditor.scss';
import { textmateInit } from './textmate';
import YamlWorker from './yaml.worker.js?worker';

(window as any).MonacoEnvironment = {
	getWorker(moduleId: any, label: any) {
		switch (label) {
			case 'yaml':
				return new YamlWorker();
			default:
				throw new Error(`Unknown label ${label}`);
		}
	},
};

export const SIGHTFULL_MONACO_THEME_NAME = 'sightfull-monaco-theme';
export const SIGHTFULL_TEXTBOX_MONACO_THEME_NAME = 'sightfull-textbox-monaco-theme';
const SCHEMA_BASE_URL = 'https://static.sightfull.com/schemas';
let isConfigured = false;

loader.config({ monaco: monacoEditor });

export function useInitMonacoEditor() {
	const { schemasVersion } = useTenantConfig();
	useLayoutEffect(() => {
		initMonacoEditorSchemas(schemasVersion);
	}, [schemasVersion]);
}

async function initMonacoEditor(monaco: typeof monacoEditor) {
	try {
		await textmateInit(monaco);
		monaco.editor.create(null as any, {}, overrideServices);
	} catch {
		return;
	}
}
export async function initMonacoEditorSchemas(schemasVersion?: string) {
	if (isConfigured) {
		return;
	}

	const schemaVersionRouting = schemasVersion ? `/${schemasVersion}` : '';
	const staticUrlPrefix = SCHEMA_BASE_URL + schemaVersionRouting;
	isConfigured = true;

	configureMonacoYaml(monacoEditor, {
		enableSchemaRequest: true,
		schemas: [
			{
				fileMatch: ['**/normalization.*'],
				uri: `${staticUrlPrefix}/normalization.schema.json#/definitions/Entity`,
			},
			{
				fileMatch: ['**/base-entity.*'],
				uri: `${staticUrlPrefix}/normalization.schema.json#/definitions/BaseEntity`,
			},
			{
				fileMatch: ['**/normalization_dimension.*'],
				uri: `${staticUrlPrefix}/normalization.schema.json#/definitions/ExplicitDimension`,
			},
			{
				// TODO: this is a stupid limitation. Somehow the language server does not like the "oneOf" at the root level of the schema
				fileMatch: ['**/normalization_raw_dimension.*'],
				uri: `${staticUrlPrefix}/normalization.schema.json#/definitions/RawDimension`,
			},
			{
				fileMatch: ['**/normalization_relationship.*'],
				uri: `${staticUrlPrefix}/normalization.schema.json#/definitions/Relationship`,
			},
			{
				fileMatch: ['**/metric_file.*'],
				uri: `${staticUrlPrefix}/metrics.schema.json`,
			},
			{
				fileMatch: ['**/metric_agg.*'],
				uri: `${staticUrlPrefix}/metrics.schema.json#/definitions/Metric`,
			},
			{
				fileMatch: ['**/metric_formula.*'],
				uri: `${staticUrlPrefix}/metrics.schema.json#/definitions/FormulaMetric`,
			},
			{
				fileMatch: ['**/base-entity.*'],
				uri: `${staticUrlPrefix}/normalization.schema.json#/definitions/BaseEntity`,
			},
			{
				fileMatch: ['**/parameters.*'],
				uri: `${staticUrlPrefix}/parameters.schema.json#/definitions/Parameter`,
			},
		],
	});
}

export const editorOptions: monacoEditor.editor.IStandaloneEditorConstructionOptions = {
	minimap: { enabled: false },
	automaticLayout: true,
	fontSize: 14,
	lineHeight: 24,
	fontWeight: 'normal',
	// fontFamily: 'Source Code Pro',
	scrollBeyondLastLine: false,
	lineDecorationsWidth: 17,
	glyphMargin: false,
	folding: false,
	lineNumbersMinChars: 2,
	fixedOverflowWidgets: true,
	scrollbar: {
		vertical: 'hidden',
	},
	renderLineHighlight: 'none',
	hideCursorInOverviewRuler: true,
	overviewRulerBorder: false,
	overviewRulerLanes: 0,
	tabSize: 2,
	wordWrap: 'on',
};

export const overrideServices: monacoEditor.editor.IEditorOverrideServices = {
	storageService: {
		onDidChangeValue: () => () => undefined,
		onDidChangeTarget: () => () => undefined,
		onWillSaveState: () => () => undefined,
		get: (key: string, scope: any, fallbackValue?: string) => fallbackValue,
		getBoolean: (key: string, scope: any, fallbackValue?: boolean) => {
			if (key == 'expandSuggestionDocs') return true;
			return fallbackValue;
		},
		getNumber: (key: string, scope: any, fallbackValue?: number) => {
			return fallbackValue;
		},
		getObject: <T extends object>(key: string, scope: any, fallbackValue?: T) => fallbackValue,
		store: () => undefined,
		storeAll: () => undefined,
		remove: () => undefined,
		keys: () => [],
		log: () => undefined,
		hasScope: () => false,
		switch: () => Promise.resolve(),
		isNew: () => false,
		optimize: () => Promise.resolve(),
		flush: () => Promise.resolve(),
	},
};

loader.init().then(() => initMonacoEditor(monacoEditor));
