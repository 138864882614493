import TenantConfigType from 'src/types/tenantConfig';
import { create } from 'zustand';

const initialState = {
	companyName: '',
	logoUrl: '',
	fiscalYearStart: '',
	trialEnd: '',
	decimalDigits: undefined,
	graphColors: [],
	schemasVersion: undefined,
	tenantState: undefined,
};

export const useTenantConfigStore = create<TenantConfigType>(() => initialState);

export const tenantConfigSelectors = {
	getAll: (state: TenantConfigType): TenantConfigType => state,
	isTrial: (state: TenantConfigType): boolean => !!state.trialEnd,
	getLastEtlSync: (state: TenantConfigType) => state.lastEtlSync,
};
