import { DimensionType } from 'src/lib/completions/semanticTypes/normalization.schema';

export const TABLE_LIMIT = 50;
export const MAX_ITEMS_TO_FETCH = 5000;
export const REQUEST_LOADER_SPEED = 390; //milliseconds;
export const METRIC_TYPES_LINK = 'https://docs.sightfull.com/core/semantic_mapping/entities';
export const DIMENSIONS_SEMANTIC_LINK = 'https://docs.sightfull.com/core/semantic_mapping/dimensions';
export const RELATIONSHIPS_SEMANTIC_LINK = 'https://docs.sightfull.com/core/semantic_mapping/relationships';
export const DIMENSION_TYPES: DimensionType[] = ['string', 'number', 'bool', 'date', 'timestamp', 'timestamptz'];
export const UNKNOWN_SAVE_AND_RUN_ERROR = 'Unknown error occurred, please review and try again';
