import { Box, Flex } from '@chakra-ui/react';
import { ICellRendererParams } from 'ag-grid-community';
import * as CSS from 'csstype';
import { ReactNode } from 'react';
import { Warning16 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import colors from 'src/style/colors';
import { NameFieldValueProps, NormalizedFields, SeverityStatuses, TableCellprops, TabsStatuses } from '../types';
import { ItemActions } from './ItemActions';

const TableCell = ({ title, subtitle, icon }: TableCellprops) => {
	return (
		<Flex alignItems={'center'}>
			{icon && <Box pr={'16px'}>{icon}</Box>}
			<Flex direction={'column'}>
				{title && (
					<Typography variant="DesktopH8Medium" color={colors.gray['800']}>
						{title}
					</Typography>
				)}
				{subtitle && (
					<Typography pt={'4px'} variant="Paragraph12R" color={colors.gray['700']}>
						{subtitle}
					</Typography>
				)}
			</Flex>
		</Flex>
	);
};

const IconWrapper = ({
	backgroundColor,
	children,
}: {
	backgroundColor: CSS.Property.BackgroundColor;
	children: ReactNode;
}) => {
	return (
		<Flex
			width={'32px'}
			height={'32px'}
			alignItems={'center'}
			justifyContent={'center'}
			borderRadius={'50px'}
			backgroundColor={backgroundColor}
		>
			{children}
		</Flex>
	);
};

const severityIcons: { [key in SeverityStatuses]: JSX.Element } = {
	1: (
		<IconWrapper backgroundColor={colors.red['100']}>
			<Warning16 color={colors.red['800']} />
		</IconWrapper>
	),
	2: (
		<IconWrapper backgroundColor={colors.orange['100']}>
			<Warning16 color={colors.orange['800']} />
		</IconWrapper>
	),
	3: (
		<IconWrapper backgroundColor={colors.yellow['100']}>
			<Warning16 color={colors.yellow['800']} />
		</IconWrapper>
	),
};

const NameField = ({ object_name, issue_group_name, severity }: NameFieldValueProps) => {
	return (
		<Box py={'16px'}>
			<TableCell
				title={object_name ?? ''}
				subtitle={issue_group_name ?? ''}
				icon={severityIcons[severity as SeverityStatuses]}
			/>
		</Box>
	);
};

const statusStyle: { [key in TabsStatuses]?: { backgroundColor: string; color: string } } = {
	Dismissed: {
		backgroundColor: colors.gray['200'],
		color: colors.gray['700'],
	},
	Resolved: {
		backgroundColor: colors.blue['100'],
		color: colors.blue['600'],
	},
	Fixed: {
		backgroundColor: colors.green['100'],
		color: colors.green['700'],
	},
};

const StatusField = ({ value }: ICellRendererParams) => {
	if (!value) {
		return;
	}

	const style = statusStyle[value as TabsStatuses];

	if (value == 'Open') {
		return <BaseField value={value} />;
	}

	return (
		<Flex py={'26px'} alignItems="center" justifyContent={'start'}>
			<Flex {...style} borderRadius={'4px'}>
				<Typography variant="Paragraph12M" p={'2px 8px'}>
					{value}
				</Typography>
			</Flex>
		</Flex>
	);
};

const BaseField = ({ value }: { value: string }) => {
	return (
		<Flex py={'28px'}>
			<Typography variant="DesktopH8Regular" color={colors.gray['700']}>
				{value}
			</Typography>
		</Flex>
	);
};

export const columnRenderer: {
	[key in keyof NormalizedFields | 'actions']: (props: ICellRendererParams) => JSX.Element | undefined;
} = {
	name: ({ value }: ICellRendererParams) => <NameField {...value} />,
	detection_status: StatusField,
	object_type: ({ value }: ICellRendererParams) => <BaseField value={value} />,
	dimension: ({ value }: ICellRendererParams) => <BaseField value={value} />,
	object_current_value: ({ value }: ICellRendererParams) => <BaseField value={value ?? 'None'} />,
	actions: ItemActions,
};
