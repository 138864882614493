import { Flex } from '@chakra-ui/react';
import { SearchInput } from 'src/common/components/SearchInput/SearchInput';
import Typography from 'src/common/components/Typography';
import { useEntityProfileReportEvent } from '../../hooks';
import { useCallback } from 'react';
import useDebouncedCallback from 'src/common/hooks/useDebouncedCallback';

export const TableHeader = ({
	header,
	searchValue,
	onChange,
	shouldAllowSearch,
}: {
	header: string;
	searchValue?: string;
	onChange?: (value: string) => void;
	shouldAllowSearch: boolean;
}) => {
	const { reportEvent } = useEntityProfileReportEvent('table-header');

	const onSearchType = useCallback(
		(value: string) => {
			reportEvent({
				event: 'entity-drawer-search',
				metaData: { searchTerm: value, searchHeader: header },
			});
		},
		[header, reportEvent]
	);

	const debounceSearch = useDebouncedCallback(onSearchType, 500);

	const onSearch = useCallback(
		(value: string) => {
			onChange?.(value);
			debounceSearch(value);
		},
		[debounceSearch, onChange]
	);

	return (
		<Flex
			justifyContent={'space-between'}
			alignItems={'center'}
			py="12px"
			px="24px"
			shadow={'borderBottom'}
			minHeight={'56px'}
		>
			<Typography variant="DesktopH7Medium">{header}</Typography>
			{shouldAllowSearch && <SearchInput value={searchValue} onChange={onSearch} size={'inline'} />}
		</Flex>
	);
};
