import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import { ChevronLeft16 } from '@components/Icons';
import Typography from '@components/Typography';

import { useAtomValue, useSetAtom } from 'jotai';
import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal';
import { SelectOption } from 'src/common/components/Select/types';
import { TinySwitch } from 'src/common/components/TinySwitch/TinySwitch';
import Tooltip from 'src/common/components/Tooltip';
import { useModal } from 'src/common/hooks/ui/useModal';
import useToast from 'src/common/hooks/ui/useToast';
import { isUntitledMetric } from 'src/lib/metricRules/builder/useMetricBuilder';
import { addUnderscoresAndToLowerCase, removeNonAlphabeticCharacters } from 'src/normalize';
import { useCreatePropertyBuilderState } from 'src/pages/OntologyPage/hooks/useDimensionBuilderDerivedState';
import { PropertyUpdate } from 'src/pages/OntologyPage/utils/updateYaml';
import {
	entityHasEmptyValues,
	isOntologyDimensionInfo,
	isOntologyRelationshipInfo,
} from 'src/pages/OntologyPage/utils/utils';
import { useReportEvent } from 'src/services/analytics';
import useNavigationBlock from 'src/services/useNavigationBlock';
import shadows from 'src/style/shadows';
import { OntologyStateAtomDerived, writePartialOntologyState } from '../../../atoms/OntologyState';
import { OntologyPropertyType } from '../../../utils/types';
import { NameField } from '../../NameField';
import { DimensionYAMLEditor } from './DimensionEditor';
import { DimensionsForm } from './DimensionsForm';
import { RelationshipYAMLEditor } from './RelationshipEditor';
import { RelationshipsForm } from './RelationshipsForm';

export default function CreatePropertyPanel({
	onMoveBack,
	onSubmit,
	propertyType,
	inputRef,
	isEditable = true,
	isAdvancedMode,
	setIsAdvancedMode,
	entitiesOptions,
}: {
	onMoveBack?: VoidFunction;
	onSubmit: (update: PropertyUpdate) => void;
	propertyType: OntologyPropertyType;
	inputRef: MutableRefObject<HTMLInputElement | null>;
	isEditable?: boolean;
	isAdvancedMode: boolean;
	setIsAdvancedMode: (val: boolean) => void;
	entitiesOptions: SelectOption[] | void;
}) {
	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const {
		propertyValue,
		hasUnsavedChanges,
		setPropertyValue,
		propertyDefinitionValue,
		upsertYAMLProperties,
		upsertYAMLProperty,
		resetInitialValue,
	} = useCreatePropertyBuilderState({
		propertyType,
	});
	const toast = useToast();
	const { reportEvent, wrapWithReport } = useReportEvent();
	const setPartialOntologyState = useSetAtom(writePartialOntologyState);
	const { isOpen: isWarningModalOpen, onOpen: onWarningModalOpen, onClose: onWarningModalClose } = useModal();

	const isWithRawSql = propertyValue?.includes('raw_sql') && !propertyValue?.includes('name: raw_sql');

	const { setIsNavigationBlocked } = useNavigationBlock();

	useEffect(
		() => setIsNavigationBlocked({ isBlocked: hasUnsavedChanges }),
		[hasUnsavedChanges, setIsNavigationBlocked]
	);

	useEffect(() => {
		if (propertyType == 'dimensions') setIsAdvancedMode(!!isWithRawSql);
	}, [propertyType, isWithRawSql, setIsAdvancedMode]);

	const onChange = useCallback((value: string) => setPropertyValue(value), [setPropertyValue]);

	const resetToDefault = () => {
		if (propertyType == 'dimensions') resetInitialValue();
	};

	const onNameUpdate = useMemo(
		() => (value: string) => {
			const valuesToUpdate = [
				{
					key: 'name',
					value: addUnderscoresAndToLowerCase(removeNonAlphabeticCharacters(value)),
				},
				{
					key: 'meta',
					value: {
						display_name: value,
						description: propertyDefinitionValue?.meta?.description,
					},
				},
			];
			return upsertYAMLProperties(valuesToUpdate);
		},
		[upsertYAMLProperties, propertyDefinitionValue?.meta?.description]
	);

	const hasEmptyValues = useCallback(
		() => entityHasEmptyValues(propertyType, propertyDefinitionValue),
		[propertyType, propertyDefinitionValue]
	);

	if (ontologyState.loading) return null;
	if (propertyValue == undefined || !propertyType) return <>Property not found</>;

	const isNameUntitled = isUntitledMetric({ name: propertyDefinitionValue?.name });
	const isSubmitDisabled =
		!hasUnsavedChanges ||
		ontologyState.hasYamlErrors ||
		!propertyDefinitionValue?.name ||
		isNameUntitled ||
		!propertyDefinitionValue?.type ||
		hasEmptyValues();

	const TopPanelSection = (
		<Flex boxShadow={shadows.borderBottom}>
			<Flex onClick={onMoveBack} alignItems={'center'} width={'100%'} padding={'12px'}>
				<Button onClick={onMoveBack} isIconOnly variant="outline" size="inline" colorScheme="black">
					<ChevronLeft16 />
				</Button>
				<Typography noOfLines={1} color={'gray.1000'} marginLeft={'4px'} variant="DesktopH8Medium">
					{`Create new ${propertyType}`}
				</Typography>
			</Flex>

			<Flex paddingX={'24px'}>
				<TinySwitch
					onClick={wrapWithReport(
						() => {
							if ((ontologyState.hasYamlErrors || isWithRawSql) && isAdvancedMode) {
								onWarningModalOpen();
							} else {
								setIsAdvancedMode(!isAdvancedMode);
							}
						},
						'ontology-object-edit-mode-toggle',
						{
							parentEntity: ontologyState.entityName,
							objectType: 'entity',
							objectName: propertyDefinitionValue?.meta?.display_name || propertyDefinitionValue?.name,
							newState: !isAdvancedMode ? 'YAML' : 'UI',
						}
					)}
					isEnabled={isAdvancedMode}
					text="Advanced"
				/>
			</Flex>
		</Flex>
	);

	const PanelBody = (
		<>
			{isAdvancedMode &&
				(propertyType === 'relationships' ? (
					<Box height={'100%'} marginTop={'-5px'}>
						<RelationshipYAMLEditor
							entity={ontologyState.entityName}
							value={propertyValue}
							onChange={onChange}
							onErrorsChange={(hasErrors) => {
								setPartialOntologyState({ hasYamlErrors: hasErrors });
							}}
							isEditable={isEditable}
							hasUnsavedChanges={hasUnsavedChanges}
							onFocus={() => {
								reportEvent({
									event: 'ontology-object-yaml-focus-clicked',
									metaData: {
										objectType: propertyType,
										parentEntity: ontologyState.entityName,
									},
								});
							}}
						/>
					</Box>
				) : (
					<Box height={'100%'} marginTop={'-5px'}>
						<DimensionYAMLEditor
							isRawSql={false}
							entity={ontologyState.entityName}
							value={propertyValue}
							onChange={onChange}
							onErrorsChange={(hasErrors) => {
								setPartialOntologyState({ hasYamlErrors: hasErrors });
							}}
							isEditable={isEditable}
							hasUnsavedChanges={hasUnsavedChanges}
							onFocus={() => {
								reportEvent({
									event: 'ontology-object-yaml-focus-clicked',
									metaData: {
										objectType: propertyType,
										parentEntity: ontologyState.entityName,
									},
								});
							}}
						/>
					</Box>
				))}
			{!isAdvancedMode &&
				(propertyType === 'relationships'
					? isOntologyRelationshipInfo(propertyDefinitionValue) && (
							<RelationshipsForm
								upsertYAMLProperty={upsertYAMLProperty}
								upsertYAMLProperties={upsertYAMLProperties}
								entitiesOptions={entitiesOptions}
								isEditable={isEditable}
								propertyDefinitionValue={propertyDefinitionValue}
								entityName={ontologyState.entityName}
							/>
					  )
					: isOntologyDimensionInfo(propertyDefinitionValue) && (
							<DimensionsForm
								isEditable={isEditable}
								onChange={upsertYAMLProperty}
								propertyDefinitionValue={propertyDefinitionValue}
								entityName={ontologyState.entityName}
							/>
					  ))}
		</>
	);

	return (
		<Flex overflow={'hidden'} flexDirection="column" height={'100%'}>
			{TopPanelSection}
			<Flex position={'relative'} overflowY={'auto'} flex={1} flexDirection={'column'}>
				<Flex flex={1} flexDirection={'column'} position={'relative'} padding={'24px'} paddingTop={0}>
					<Box paddingY={'24px'}>
						<NameField
							inputRef={inputRef}
							onNameUpdate={onNameUpdate}
							propertyDefinitionValueName={propertyDefinitionValue?.meta?.display_name || propertyDefinitionValue?.name}
							value={propertyDefinitionValue?.meta?.display_name}
							propertyType={propertyType || 'dimensions'}
						/>
					</Box>
					{PanelBody}
				</Flex>
				<Flex
					backgroundColor={'white'}
					bottom={'0'}
					left={'0'}
					position={'sticky'}
					zIndex={1}
					flexDir={'row'}
					justifyContent={'end'}
					width={'100%'}
					padding={'16px 24px'}
					gap="12px"
					borderTop={'1px solid'}
					borderColor={'gray.300'}
				>
					<Button
						colorScheme="black"
						variant="outline"
						size="small"
						onClick={() => {
							reportEvent({
								event: 'ontology-object-edit-cancel-clicked',
								metaData: {
									objectType: propertyType,
								},
							});
							onMoveBack?.();
						}}
					>
						Cancel
					</Button>
					{isEditable && (
						<Tooltip placement="left" size={'md'} label={isNameUntitled && 'Name cannot be untitled'}>
							<Button
								colorScheme={isSubmitDisabled ? 'gray' : 'blue'}
								variant="solid"
								size="small"
								isDisabled={isSubmitDisabled}
								onClick={() => {
									try {
										onSubmit({
											currentPropertyName: '',
											ontologyPropertyType: propertyType,
											propertyDefinition: propertyValue,
										});
									} catch (e) {
										if (e instanceof Error) {
											toast({ variant: 'error', message: e.message, duration: 5000 });
										}
									}
								}}
							>
								Create
							</Button>
						</Tooltip>
					)}
				</Flex>
			</Flex>
			<ConfirmationModal
				primaryButtonLabel="Leave"
				submitColorScheme="blue"
				isOpen={isWarningModalOpen}
				onSubmit={wrapWithReport(
					() => {
						resetToDefault();
						setIsAdvancedMode(false);
						onWarningModalClose();
					},
					`ontology-leave-YAML-with-${isWithRawSql ? 'rawsql' : 'error'}-modal`,
					{
						action: 'confirm',
						objectName: propertyDefinitionValue?.meta?.display_name,
						objectType: propertyType,
						parentEntity: ontologyState.entityName,
					}
				)}
				modalTitle="Leave advanced mode"
				modalText={`Leaving the advanced mode with errors will clear your${isWithRawSql ? ' raw_sql ' : ' '}input.`}
				onClose={wrapWithReport(
					onWarningModalClose,
					`ontology-leave-YAML-with-${isWithRawSql ? 'rawsql' : 'error'}-modal`,
					{
						action: 'cancel',
						objectName: propertyDefinitionValue?.meta?.display_name,
						objectType: propertyType,
						parentEntity: ontologyState.entityName,
					}
				)}
			/>
		</Flex>
	);
}
