import {
	ChartOptions,
	ChartSeries,
	SeriesDataPointY,
	SeriesDataPointYFormatter,
} from 'src/common/components/Chart/types';
import { NOT_AVAILABLE_VALUE_STRING } from 'src/common/utils/consts';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { DataLabelFormatConfig } from '../../statisticOperations/types';
const DEFAULT_DECIMAL_DIGITS = 1;

export function calcCoreFormatting(
	prevChartOptions: ChartOptions,
	formatConfig?: DataLabelFormatConfig
): Pick<MetricDerivedState, 'chartOptions'> {
	const getFormatter = (unit?: ChartSeries['custom']['unit']): ((value: number) => string) => {
		switch (unit) {
			case 'usd':
				return (value) => currencyFormatter('usd', value, formatConfig?.decimalDigits);
			case 'eur':
				return (value) => currencyFormatter('eur', value, formatConfig?.decimalDigits);
			case 'percentage':
				return (value) => percentageFormatter(value, formatConfig?.decimalDigits);
			case 'number':
			default:
				return (value) => numberFormatter(value, formatConfig?.decimalDigits);
		}
	};
	const formattedSeries: ChartSeries[] = prevChartOptions.series.map(
		(seriesBeforeFormatting): ChartSeries => ({
			...seriesBeforeFormatting,
			custom: {
				...seriesBeforeFormatting.custom,
				seriesDataPointYFormatter: (value: SeriesDataPointY) => {
					if (value == null) return NOT_AVAILABLE_VALUE_STRING;
					return getFormatter(seriesBeforeFormatting.custom?.unit)(value);
				},
			},
		})
	);
	return { chartOptions: { ...prevChartOptions, series: formattedSeries } };
}

export function percentageFormatter(yValue: number, decimalDigits?: number): ReturnType<SeriesDataPointYFormatter> {
	const number = yValue * 100;
	if (isNaN(number)) {
		return '0%';
	}

	const digits = decimalDigits ?? DEFAULT_DECIMAL_DIGITS;
	const factor = Math.pow(10, digits);
	const roundedNumber = Math.round(number * factor) / factor;

	return `${roundedNumber.toFixed(digits)}%`;
}

export function currencyFormatter(
	currency: string,
	yValue: number,
	decimalDigits?: number
): ReturnType<SeriesDataPointYFormatter> {
	const dollarFormatter = Intl.NumberFormat('en-US', {
		notation: 'compact',
		style: 'currency',
		currency: currency,
		minimumFractionDigits: 0,
		maximumFractionDigits: decimalDigits ?? 1,
	});

	return dollarFormatter.format(yValue);
}

export function numberFormatter(yValue: number, decimalDigits?: number): ReturnType<SeriesDataPointYFormatter> {
	const formatter = Intl.NumberFormat('en-US', {
		notation: 'compact',
		minimumFractionDigits: 0,
		maximumFractionDigits: decimalDigits ?? 1,
	});

	return formatter.format(yValue);
}
