import { PeriodRange, PeriodRangePreset } from '@sightfull/period-ranges';
import { useReportEvent } from 'src/services/analytics';
import { PeriodCell, PeriodCellSize } from './PeriodCell';

export function PresetsPeriodPicker({
	availablePresets,
	selectedPeriod,
	onSelectedPeriod,
	size,
	isEnding,
	isDisabled,
}: {
	availablePresets: { [key in string]: PeriodRangePreset };
	selectedPeriod?: PeriodRange;
	onSelectedPeriod: (range: PeriodRange) => void;
	size: PeriodCellSize;
	isEnding: boolean;
	isDisabled?: boolean;
}) {
	const { reportEvent } = useReportEvent();
	const periodCells = Object.entries(availablePresets).map((v, index, array) => {
		const [key, value] = v;
		if (!value) return <>NIL</>;
		return (
			<PeriodCell
				isDisabled={isDisabled}
				size={size}
				key={value.key}
				label={key}
				actualPeriodString={value.actualDatesString}
				periodName={value.description}
				isSelected={
					!!selectedPeriod &&
					value.startDate.getTime() == selectedPeriod.startDate.getTime() &&
					value.endDate.getTime() == selectedPeriod.endDate.getTime() &&
					value.periodUnit == selectedPeriod.periodUnit
				}
				isIncremental={false}
				isLast={isEnding && index == array.length - 1}
				onClick={() => {
					reportEvent({
						event: 'metric-period-preset-clicked',
						metaData: { key: value.key, prettyString: value.prettyString },
					});
					onSelectedPeriod(value);
				}}
			/>
		);
	});
	return <>{periodCells}</>;
}
