import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonMetricCatalogBody.module.scss';
import { Flex } from '@chakra-ui/react';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';

const { categorySkeleton, skeletonCards, skeletonBanner, skeletonCardsV2 } = classes;

function SkeletonCards({ isRunningSightfullV2 = false }: { isRunningSightfullV2?: boolean }) {
	return (
		<Flex flexWrap={'wrap'} gap={'16px'} flex={1}>
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
			<Skeleton className={isRunningSightfullV2 ? skeletonCardsV2 : skeletonCards} />
		</Flex>
	);
}

function SkeletonBanner() {
	return (
		<Flex>
			<Skeleton className={skeletonBanner} />
		</Flex>
	);
}

export function SkeletonMetricCatalogBody({ isRunningSightfullV2 = false }: { isRunningSightfullV2?: boolean }) {
	const withPreview = useFeatureFlag('pulse.react.metricCatalog.showCardsPreview');

	return (
		<>
			{isRunningSightfullV2 && <SkeletonBanner />}
			<Flex flexDirection={'column'}>
				<Skeleton className={categorySkeleton} />
				<SkeletonCards isRunningSightfullV2={isRunningSightfullV2 && withPreview} />
			</Flex>
			<Flex marginTop={'40px'} flexDirection={'column'}>
				<Skeleton className={categorySkeleton} />
				<SkeletonCards isRunningSightfullV2={isRunningSightfullV2 && withPreview} />
			</Flex>
		</>
	);
}
