import { UserStoreType, UserType } from 'src/types/user';
import { create } from 'zustand';

const user: UserType = {
	id: null,
	picture: '',
	email: '',
	firstName: '',
	lastName: '',
	timezone: '',
	feed: {
		id: null,
	},
	sub: '',
	intercomHMAC: '',
};

export const useUserStore = create<UserStoreType>((set) => ({
	user,
	reset: (newUser?: Partial<UserType>) =>
		set({
			user: {
				...user,
				...newUser,
			},
		}),
}));

export const selectors = {
	reset: (state: UserStoreType) => state.reset,
	getUser: (state: UserStoreType) => state.user,
};
