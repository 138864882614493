import { useEffect, useMemo } from 'react';
import { useGetRecordSearchEntitiesLazyQuery } from 'src/generated/graphql';

export function useSearchOptions(isOpen: boolean, allDataLabel = 'All') {
	const [getRecordSearchEntities, { data, loading, error }] = useGetRecordSearchEntitiesLazyQuery();

	useEffect(() => {
		if (isOpen) {
			getRecordSearchEntities();
		}
	}, [isOpen, getRecordSearchEntities]);

	const v2Options = useMemo(() => {
		const entities = data?.getRecordSearchEntities?.recordSearchEntities;
		return [
			{ label: allDataLabel, value: entities?.map((entity) => entity.entityName).join(',') ?? '' },
			...(entities?.map((entity) => ({
				label: entity.entityDisplayName ?? entity.entityName,
				value: entity.entityName,
			})) ?? []),
		];
	}, [data, allDataLabel]);

	return { v2Options, loading, error };
}
