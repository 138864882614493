import { AvailablePeriodRanges, BuiltInPeriodRangePresets, PeriodRange, PeriodUnit } from '@sightfull/period-ranges';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { parsePeriodRange } from '../periodRangeParser';
import { RawMetricMetadata, RulesInput } from '../utils';

export function calcPeriodRange(
	{ searchParams }: RulesInput,
	metricMetadata: Pick<RawMetricMetadata, 'default_relative_period'>,
	availablePeriodRanges: AvailablePeriodRanges
) {
	const { default_relative_period: defaultRelativePeriod } = metricMetadata;

	const periodRangeFromSearchParams = tryUsePeriodRangeFromSearchParams(searchParams, availablePeriodRanges);
	if (periodRangeFromSearchParams) return periodRangeFromSearchParams;

	const periodRangeFromDefaultRelativePeriod = tryUsePeriodRangeFromDefaultRelativePeriod(
		availablePeriodRanges,
		defaultRelativePeriod
	);
	if (periodRangeFromDefaultRelativePeriod) return periodRangeFromDefaultRelativePeriod;

	const periodRangeByRelevantPeriodRanges = calculateDefaultPeriodRange(availablePeriodRanges);
	return { periodRange: periodRangeByRelevantPeriodRanges };
}

export function trimUsingAvailablePeriodRange(periodRange: PeriodRange, availablePeriodRanges: AvailablePeriodRanges) {
	const availablePeriodRange = availablePeriodRanges[periodRange.periodUnit];
	return availablePeriodRange?.calculateBestFitRange(periodRange);
}

export function tryUsePeriodRangeFromSearchParams(
	searchParams: MetricSearchParams,
	availablePeriodRanges: AvailablePeriodRanges
) {
	const periodRangeFromSearchParams = parsePeriodRange(searchParams);
	if (periodRangeFromSearchParams) {
		const bestFitRange = trimUsingAvailablePeriodRange(periodRangeFromSearchParams, availablePeriodRanges);
		if (bestFitRange)
			return {
				periodRange: bestFitRange,
			};
	}
}

export function tryUsePeriodRangeFromDefaultRelativePeriod(
	availablePeriodRanges: AvailablePeriodRanges,
	defaultRelativePeriod: string | undefined | null
) {
	const periodRangeFromDefaultRelativePeriod =
		defaultRelativePeriod &&
		BuiltInPeriodRangePresets.isPresetKey(defaultRelativePeriod) &&
		BuiltInPeriodRangePresets.byKey(defaultRelativePeriod, fiscalYearOffset());
	if (periodRangeFromDefaultRelativePeriod) {
		const bestFitRange = trimUsingAvailablePeriodRange(periodRangeFromDefaultRelativePeriod, availablePeriodRanges);
		if (bestFitRange)
			return {
				periodRange: bestFitRange,
			};
	}
}

function calculateDefaultPeriodRange(availablePeriodRanges: AvailablePeriodRanges) {
	const defaultPeriodTypeOrder: PeriodUnit[] = ['month', 'fquarter', 'fyear', 'week', 'fweek'];
	const relevantPeriodRange = defaultPeriodTypeOrder
		.map((periodType) => availablePeriodRanges[periodType])
		.find((range) => range);

	if (!relevantPeriodRange) throw Error('no relevantPeriodRange for this metric');

	return relevantPeriodRange.sliceDefaultRange();
}
