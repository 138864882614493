import { Filter } from 'src/pages/MetricPage/utils/state.types';
import { FilterOutline16 } from '../../Icons';
import { DrilldownCard } from './DrillDownCard';

export function FilterDrilldownCard({
	filter,
	onRemove,
	onClick,
}: {
	filter: Filter;
	onRemove?: VoidFunction;
	onClick?: VoidFunction;
}) {
	const { label, values } = filter;
	const valuesSelectedCount = values?.length;
	const maxValuesToDisplay = 5;
	const extraMissingValues =
		valuesSelectedCount && valuesSelectedCount > maxValuesToDisplay
			? `, +${valuesSelectedCount - maxValuesToDisplay} more`
			: '';
	const firstCommaSeparatedValues = values
		?.slice(0, maxValuesToDisplay)
		.map((v) => v?.toString() || '')
		.join(', ');

	const subtitle = values && firstCommaSeparatedValues;
	const title = values ? `${label} (${values.length})` : label;

	return (
		<DrilldownCard
			icon={<FilterOutline16 />}
			onRemove={onRemove}
			removeTooltip={'Remove filter'}
			title={title}
			tooltip={subtitle ? `${firstCommaSeparatedValues}${extraMissingValues}` : label}
			value={subtitle}
			onClick={onClick}
		/>
	);
}
