import { Box, Flex } from '@chakra-ui/react';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import classes from './ResizePanel.module.scss';

type PanelProps = {
	minWidth: number;
	width: number;
	children: ReactNode;
	isResizeBlocked?: boolean;
	position?: 'left' | 'right';
};

export function ResizePanel({ minWidth, width, children, isResizeBlocked, position = 'right' }: PanelProps) {
	const sidebarRef = useRef<HTMLDivElement>(null);
	const [isResizing, setIsResizing] = useState(false);
	const [sidebarWidth, setSidebarWidth] = useState(width);
	const [isResizeHovered, setIsResizedHovered] = useState(false);

	const startResizing = useCallback(() => !isResizeBlocked && setIsResizing(true), [isResizeBlocked]);
	const stopResizing = useCallback(() => !isResizeBlocked && setIsResizing(false), [isResizeBlocked]);

	const resize = useCallback(
		(mouseMoveEvent: MouseEvent) => {
			if (isResizing && sidebarRef?.current) {
				setSidebarWidth(
					position === 'left'
						? sidebarRef.current.getBoundingClientRect().right - mouseMoveEvent.clientX
						: mouseMoveEvent.clientX - sidebarRef?.current?.getBoundingClientRect().left
				);
			}
		},
		[isResizing, position]
	);

	useEffect(() => setSidebarWidth(width), [width, isResizeBlocked]);

	useEffect(() => {
		if (isResizeBlocked) return;
		window.addEventListener('mousemove', resize);
		window.addEventListener('mouseup', stopResizing);
		return () => {
			window.removeEventListener('mousemove', resize);
			window.removeEventListener('mouseup', stopResizing);
		};
	}, [resize, stopResizing, isResizeBlocked]);

	const hoverArea = (
		<Box
			onMouseLeave={() => setIsResizedHovered(false)}
			onMouseEnter={() => setIsResizedHovered(true)}
			className={classes.hoverArea}
		/>
	);

	const resizer = (
		<>
			{!isResizeBlocked && hoverArea}
			<Box
				className={classNames(classes.appSidebarResizer, {
					[classes.resizeBlocked]: isResizeBlocked,
					[classes.activeResize]: isResizeHovered,
				})}
				onMouseDown={!isResizeBlocked ? startResizing : undefined}
			/>
			{!isResizeBlocked && hoverArea}
		</>
	);

	return (
		<Flex
			ref={sidebarRef}
			minWidth={`${minWidth}px`}
			className={classNames(classes.appSidebar, {
				[classes.isResizing]: isResizing,
				[classes.leftResize]: position === 'left',
			})}
			width={`${sidebarWidth}px`}
		>
			{position === 'left' && resizer}

			<Box className={classes.appSidebarContent}>{children}</Box>
			{position === 'right' && resizer}
		</Flex>
	);
}
