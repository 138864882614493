import { MetricComponent } from 'src/pages/MetricPage/components/LegendsPanel/types';
import { StatisticOperation } from 'src/pages/MetricPage/utils/state.types';

export function PngLegendItem({ legend }: { legend: MetricComponent | StatisticOperation }) {
	const legendCubeSize = '10px';
	return (
		<div
			key={legend.color}
			style={{
				display: 'flex',
				paddingRight: '12px',
				justifyContent: 'center',
				alignItems: 'center',
				fontSize: '12px',
			}}
		>
			<div
				style={{
					minWidth: legendCubeSize,
					minHeight: legendCubeSize,
					maxHeight: legendCubeSize,
					maxWidth: legendCubeSize,
					backgroundColor: legend.color,
					borderRadius: '2px',
					fontSize: '12px',
				}}
			/>
			<div
				style={{
					paddingLeft: '6px',
				}}
			>
				{('label' in legend && legend.label) ||
					('value' in legend && legend.value) ||
					('name' in legend &&
						`${legend.name} ${
							legend.options[legend.selectedOptionIndex]?.label ?? legend.options[legend.selectedOptionIndex]?.value
						}`)}
			</div>
		</div>
	);
}
