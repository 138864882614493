import { BuiltInPeriodRangePresets, PeriodRange } from '@sightfull/period-ranges';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';

export function parsePeriodRange({
	relativePeriodFrom,
	relativePeriodTo,
	relativePeriod,
	relativePeriodDuration,
	from,
	to,
	periodRange,
}: Pick<
	MetricSearchParams,
	| 'relativePeriodFrom'
	| 'relativePeriodTo'
	| 'periodRange'
	| 'relativePeriod'
	| 'relativePeriodDuration'
	| 'from'
	| 'to'
>): PeriodRange | undefined {
	if (periodRange) {
		return PeriodRange.fromURL(periodRange, fiscalYearOffset());
	}
	if (relativePeriod && BuiltInPeriodRangePresets.isPresetKey(relativePeriod)) {
		const presetByKey = BuiltInPeriodRangePresets.byKey(relativePeriod, fiscalYearOffset());
		if (presetByKey) return presetByKey;
	}
	const isLegacyRelativePeriod =
		relativePeriodDuration && relativePeriodFrom != undefined && relativePeriodTo != undefined;
	if (isLegacyRelativePeriod) {
		return PeriodRange.fromRelativeLegacy(
			relativePeriodDuration,
			relativePeriodFrom,
			relativePeriodTo,
			fiscalYearOffset()
		);
	}
	const isIdStringPeriod = from && to;
	if (isIdStringPeriod) {
		return PeriodRange.fromIdStrings(from, to, fiscalYearOffset());
	}
}
