import Box from '@components/Box';
import { LexicalEditor } from 'lexical';
import { useState } from 'react';
import Popover from '../../Popover';
import { H1, H2, Paragraph } from '../icons';
import { BlockType } from '../types';
import BlockOptionsDropdownList from './BlockOptionsDropdownList';
import ToolbarButton from './ToolbarButton';

type Props = {
	blockType: BlockType | '';
	editor: LexicalEditor;
	isDisabled: boolean;
};

function TextTypeSelector({ editor, blockType, isDisabled }: Props) {
	const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState<boolean>(false);

	const getBlockTypeIcon = () => {
		switch (blockType) {
			case 'h1': {
				return <H1 />;
			}
			case 'h2': {
				return <H2 />;
			}
			case 'paragraph':
			default: {
				return <Paragraph />;
			}
		}
	};

	const BlockTypeIcon = getBlockTypeIcon();

	return (
		<Box position="relative">
			<Popover
				isOpen={showBlockOptionsDropDown && !isDisabled}
				placement={'bottom-start'}
				onClose={() => setShowBlockOptionsDropDown(false)}
				closeOnBlur
				triggerElement={
					<ToolbarButton
						isActive={!!blockType}
						isSelected={showBlockOptionsDropDown}
						onClick={() => setShowBlockOptionsDropDown(!showBlockOptionsDropDown)}
						variant="select"
						tooltipLabel="Text styles"
					>
						{BlockTypeIcon}
					</ToolbarButton>
				}
			>
				<BlockOptionsDropdownList
					editor={editor}
					blockType={blockType}
					setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
				/>
			</Popover>
		</Box>
	);
}

export default TextTypeSelector;
