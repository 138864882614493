import Box from '@components/Box';
import Flex from '@components/Flex';
import Divider from 'src/common/components/Divider';
import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonTableLayout.module.scss';

const { mainTitleSkeleton, buttonSkeleton, tableSkeleton } = classes;

function Titles() {
	return (
		<Flex flexDirection="column" justifyContent="flex-start" marginBottom="16px">
			<Skeleton className={mainTitleSkeleton} />
		</Flex>
	);
}

function Buttons() {
	return (
		<Flex flexDirection="row" alignItems="flex-start" justifyItems="center">
			<Skeleton className={buttonSkeleton} />
			<Skeleton className={buttonSkeleton} />
			<Skeleton className={buttonSkeleton} />
			<Skeleton className={buttonSkeleton} />
			<Box height="32px">
				<Divider direction="vertical" mr="12px" />
			</Box>
			<Skeleton className={buttonSkeleton} />
		</Flex>
	);
}

function Table() {
	return <Skeleton className={tableSkeleton} />;
}

export function SkeletonTableLayout() {
	return (
		<Box marginTop={'16px'} width="100%">
			<Flex justifyContent="space-between" alignItems={'center'} marginBottom="16px" height={'32px'} marginTop="20px">
				<Titles />
				<Buttons />
			</Flex>
			<Table />
		</Box>
	);
}
