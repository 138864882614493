import { Box } from '@chakra-ui/react';
import { useRef } from 'react';
import { useBlocker } from 'react-router-dom';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal';
import Flex from 'src/common/components/Flex';
import { Warning32 } from 'src/common/components/Icons';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { useMetricMeta } from 'src/lib/metricRules/builder/useCachedBuilderProperties';
import { useReportEvent } from 'src/services/analytics';
import { UNTITLED_METRIC_DISPLAY } from 'src/lib/metricRules/builder/useMetricBuilder';
import useNavigationBlock from 'src/services/useNavigationBlock';
import { usePermissionCheck } from 'src/stores/environment';
import colors from 'src/style/colors';
import { Permissions } from 'src/types/environment';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { useMetricEditorState } from '../../hooks/useMetricEditorState';
import { MonacoError } from '../MonacoEditor/types';
import { BuilderPanel } from './Builder/BuilderPanel';
import { EditToggle } from './EditToggle';
import { MetricYamlEditor } from './MetricYamlEditor';
import { MetricBuilderSteps } from 'src/pages/CreateNewMetricPage/components/MetricBodyProgress';

export type EditorFeature = 'YAML Editor' | 'YAML Builder';

type PanelProps = {
	isUnloadModalVisible?: boolean;
	setIsUnloadModalVisible?: (val: boolean) => void;
	handleToggleChange?: VoidFunction;
	isPreviewEnabled?: boolean;
	setEditorErrors?: (errors: MonacoError[]) => void;
	isCreateNewPage?: boolean;
	selectedFeature: EditorFeature;
	setSelectedFeature?: (feature: EditorFeature) => void;
	shouldPreventToggle?: boolean;
	setDerivedActiveStep?: (val: MetricBuilderSteps) => void;
};

export function EditPanel({
	handleToggleChange,
	isPreviewEnabled,
	setEditorErrors,
	isCreateNewPage,
	selectedFeature,
	setSelectedFeature,
	shouldPreventToggle = false,
	isUnloadModalVisible = false,
	setIsUnloadModalVisible,
	setDerivedActiveStep,
}: PanelProps) {
	const { metricEditorState, setMetricEditorState, metricEditorLoadedState } = useMetricEditorState();
	const editorWrapperRef = useRef(null);
	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);

	const { resetYAMLValue, clearWithNameSave, onPreview } = useBuilderDerivedState();
	const { metricNameWithFlavor: metricName } = useMetricDerivedState();
	const { metricMeta } = useMetricMeta();

	const { isNavigationBlocked, setIsNavigationBlocked } = useNavigationBlock();

	const { reportEvent } = useReportEvent({
		feature: 'Metric Builder',
		metricName: metricName,
	});

	const onChangeValue = (editorValue: string) => {
		if (metricEditorState.isLoading) return;

		setMetricEditorState({ ...metricEditorState, userDefinedValue: editorValue });
	};

	const onSubmitLeave = () => {
		if (metricEditorState.isLoading) return;

		reportEvent({
			event: 'metric-edit-unsaved-leave-clicked',
		});
		blocker.proceed?.();
		setIsNavigationBlocked({ isBlocked: false });
		setIsUnloadModalVisible?.(false);
		handleToggleChange?.();
	};

	const clearFormulaOnSubmit = () => {
		const { name, display_name = UNTITLED_METRIC_DISPLAY } = metricMeta || {};
		if (isCreateNewPage) {
			resetYAMLValue({ name, display_name });
			return;
		}
		clearWithNameSave(name, display_name);
	};

	const isYAMLBuilderSelected = selectedFeature === 'YAML Builder';

	const blocker = useBlocker(
		({ currentLocation, nextLocation }) =>
			isNavigationBlocked.isBlocked && currentLocation.pathname !== nextLocation.pathname
	);

	return (
		<Flex ref={editorWrapperRef} direction={'column'} height="100%" minHeight={'100%'}>
			<EditToggle
				clearFormulaOnSubmit={clearFormulaOnSubmit}
				shouldPreventToggle={shouldPreventToggle}
				isToggleVisible
				setSelectedFeature={setSelectedFeature}
				metricName={metricName}
				selectedFeature={selectedFeature}
			/>

			<Box position={'relative'} flex={1}>
				{isYAMLBuilderSelected ? (
					<BuilderPanel selectedFeature={selectedFeature} />
				) : (
					<MetricYamlEditor
						onPreview={() => {
							onPreview();
							setDerivedActiveStep?.('preview');
						}}
						isPreviewEnabled={isPreviewEnabled}
						hasEditPermission={hasEditPermission}
						onChangeValue={onChangeValue}
						setErrors={setEditorErrors}
						shouldUseFormula={metricEditorLoadedState?.kind === 'formula'}
					/>
				)}
			</Box>
			<ConfirmationModal
				modalIcon={<Warning32 color={colors.gray[600]} />}
				isOpen={blocker.state === 'blocked' || isUnloadModalVisible}
				onSubmit={onSubmitLeave}
				modalTitle="Unsaved changes."
				primaryButtonLabel="Leave page"
				modalText="You are about to leave without saving? Any unsaved changes will be deleted."
				onClose={() => {
					reportEvent({
						event: 'metric-edit-unsaved-cancel-clicked',
					});
					blocker.reset?.();
					setIsUnloadModalVisible?.(false);
				}}
			/>
		</Flex>
	);
}
