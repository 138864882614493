import { forwardRef, Textarea, TextareaProps } from '@chakra-ui/react';
import omit from 'lodash/omit';
import ResizeTextarea from 'react-textarea-autosize';

type AutoResizeTextareaProps = Omit<TextareaProps, 'onSubmit'> & {
	onSubmit?: (value: string) => void;
};

export const AutoResizeTextarea = forwardRef<AutoResizeTextareaProps, 'textarea'>((props, ref) => {
	const autoResizeTextareaProps = omit(props, ['onSubmit']);
	return (
		<Textarea
			onKeyDown={(e) => {
				const { value, onSubmit } = props;
				const isSubmitEnter = e.key == 'Enter' && e.shiftKey == false;
				const isValidValue = value != undefined && typeof value == 'string';
				if (!onSubmit || !isSubmitEnter || !isValidValue) return;

				e.preventDefault();
				onSubmit(value);
			}}
			minH="unset"
			overflow="hidden"
			w="100%"
			resize="none"
			ref={ref}
			minRows={1}
			as={ResizeTextarea}
			{...autoResizeTextareaProps}
		/>
	);
});
