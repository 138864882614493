import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { DisplayUnit, displayUnits, MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export function calcDisplayUnits(
	rawDisplayUnit: MetricSearchParams['displayUnits'],
	currentState: Pick<MetricDerivedState, 'metricOperator' | 'chartType' | 'hasGroupBy'>
): Pick<MetricDerivedState, 'displayUnits'> {
	const entries = displayUnits.map((option) => {
		let isDisabled = false;

		if ([DisplayUnit.percentageFirst, DisplayUnit.percentagePrev].includes(option)) {
			isDisabled =
				!(currentState.metricOperator.op == 'funnel' || currentState.chartType.selectedValue == 'waterfall') ||
				currentState.hasGroupBy;
		}
		if (currentState.chartType.selectedValue === 'number' && option !== DisplayUnit.count) {
			isDisabled = true;
		}

		return [
			option,
			{
				isDisabled: isDisabled,
				value: !isDisabled && rawDisplayUnit?.includes(option),
			},
		];
	});

	return {
		displayUnits: Object.fromEntries(entries),
	};
}
