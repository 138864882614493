import { BuiltInPeriodRangePresets } from '@sightfull/period-ranges';
import { useEffect, useState } from 'react';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { usePeriodRange } from './usePeriodRange';
import useDimensionsState from '@pages/MetricPage/components/InvestigatePanel/useDimensionsState';
import { MetricPageSearchParams, MetricPageSearchParamsAtom } from '../atoms/MetricPageSearchParams';
import { useAtomValue } from 'jotai';

export type MetricSearchParamsBuilderProps = {
	period?: string;
	breakdown?: string;
};

export function useMetricSearchParamsBuilder({
	period,
	breakdown,
}: MetricSearchParamsBuilderProps): [boolean, MetricPageSearchParams] {
	const metricPageSearchParams = useAtomValue(MetricPageSearchParamsAtom);
	const [, setPeriodRange] = usePeriodRange();
	const [, { addDimensionsParamsByType }] = useDimensionsState();
	const [isDoneBuilding, setIsDoneBuilding] = useState(false);

	useEffect(() => {
		if (breakdown && !metricPageSearchParams.searchParams.has('groupBy')) {
			addDimensionsParamsByType('groupBy', [{ key: breakdown, value: '' }]);
		} else if (
			period &&
			BuiltInPeriodRangePresets.isPresetKey(period) &&
			!metricPageSearchParams.searchParams.has('relativePeriod')
		) {
			const periodRange = BuiltInPeriodRangePresets.byKey(period, fiscalYearOffset());
			setPeriodRange(periodRange);
		} else {
			setIsDoneBuilding(true);
		}
	}, [breakdown, addDimensionsParamsByType, metricPageSearchParams.searchParams, setPeriodRange, period]);

	return [isDoneBuilding, metricPageSearchParams];
}
