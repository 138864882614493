import Typography from 'src/common/components/Typography';
import { ExampleText, LearnMore } from './common';
import { LearnMoreProps } from './common/LearnMore';

const PERIOD_LINK = 'https://docs.sightfull.com/core/metrics/syntax/periods';

export function PeriodFormulaTooltip({ onHelpClicked }: { onHelpClicked: LearnMoreProps['onClick'] }) {
	return (
		<Typography color={'white'} variant="Paragraph14R">
			Determines how entities are anchored to the time series X-axis, controlling when they contribute to the
			metric.&nbsp;
			<LearnMore onClick={onHelpClicked} link={PERIOD_LINK} />.
			<ExampleText marginTop={'16px'} text={'$created_date >= $period.end'} />
			<ExampleText
				marginTop={'8px'}
				marginBottom={'4px'}
				text={'$created_date BETWEEN $period.start AND $period.end'}
			/>
		</Typography>
	);
}
