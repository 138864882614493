import { Box, Flex, PopoverContent } from '@chakra-ui/react';
import { useState } from 'react';
import Input from 'src/common/components/Input';
import ListItem from 'src/common/components/ListItem';
import Popover from 'src/common/components/Popover';
import Typography from 'src/common/components/Typography';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useReportEvent } from 'src/services/analytics';
import shadows from 'src/style/shadows';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { useMetricPageSearchParams } from '../../hooks/useMetricPageSearchParams';
import { LayersButton } from './LayersButton';

export function LayersModal({
	isDisabled,
	isDecimalPopoverOpen,
	onOpen,
	onClose,
}: {
	isDisabled?: boolean;
	isDecimalPopoverOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
}) {
	const { reportEvent } = useReportEvent();
	const { onClose: onModalClose } = useModal({
		onClose: () => {
			onClose();
			setHasError(false);
		},
	});

	const [hasError, setHasError] = useState(false);
	const { decimalDigits } = useMetricDerivedState();
	const { setPartialSearchParams } = useMetricPageSearchParams();

	const onInputChange = (value: string) => {
		setHasError(Number(value) >= 3);
		reportEvent({
			event: 'decimal-digit-change',
			metaData: { decimalDigitsAfter: Number(value), decimalDigitsBefore: decimalDigits },
		});
		setPartialSearchParams({ decimalDigits: Number(value) });
	};

	return (
		<Popover
			placement="bottom-start"
			variant="solid"
			isOpen={isDecimalPopoverOpen}
			closeOnBlur={true}
			isLazy
			onClose={onModalClose}
			onOpen={onOpen}
			triggerElement={() => (
				<Box>
					<LayersButton isDisabled={isDisabled} onClick={onOpen} isActive={isDecimalPopoverOpen} />
				</Box>
			)}
		>
			<PopoverContent
				width={'260px'}
				padding={'8px 0px'}
				flexDirection={'column'}
				alignItems={'flex-start'}
				gap={'12px'}
				boxShadow={shadows.lg}
				borderRadius={'4px'}
				border="1px solid"
				borderColor={'gray.300'}
				background={'white'}
			>
				<Flex flexDirection={'column'} alignItems={'center'} alignSelf={'stretch'}>
					<Flex
						padding={'4px 16px'}
						flexDirection={'column'}
						justifyContent={'center'}
						alignItems={'flex-start'}
						gap={'12px'}
						alignSelf={'stretch'}
						flex={'1 0 0'}
					>
						<Typography variant={'DesktopH10Medium'} color={'gray.600'}>
							Data format
						</Typography>
					</Flex>
					<ListItem
						size={'sm'}
						label={'Decimals'}
						suffixComponent={
							<Box width={'50px'}>
								<Input
									type="number"
									hasNumberSteppers={true}
									value={decimalDigits ?? 1}
									variant="ghost"
									size="inline"
									chakraNumberInputProps={{
										min: 0,
										max: 3,
										defaultValue: decimalDigits === null ? 1 : decimalDigits,
									}}
									onChange={onInputChange}
									backgroundColor={'transparent'}
								/>
							</Box>
						}
					/>
					{hasError && (
						<Flex paddingY={'4px'} paddingX={'16px'} justifyContent={'right'} direction={'row'}>
							<Typography variant="DesktopH10Regular" color={'gray.600'} textAlign={'right'}>
								You can use up to 3 decimal places
							</Typography>
						</Flex>
					)}
				</Flex>
			</PopoverContent>
		</Popover>
	);
}
