import { Box, Flex } from '@chakra-ui/react';
import { ConfigProvider, Pagination } from 'antd';
import { ReactNode, useCallback, useEffect } from 'react';
import { ArrowLeft16, ArrowRight16 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import { createMergedClassName } from 'src/common/utils/style';
import { useEntityProfileReportEvent } from '../../hooks';
import classes from './TableComponents.module.scss';

type ItemType = 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next';

export const TableFooter = ({
	currentPage = 1,
	totalItems,
	pageSize,
	onChange,
}: {
	currentPage: number;
	totalItems: number;
	pageSize: number;
	onChange: (newPage: number) => void;
}) => {
	const { reportEvent } = useEntityProfileReportEvent('table-footer');
	const numberOfPages = Math.ceil(totalItems / pageSize);

	const itemRender = useCallback(
		(page: number, type: ItemType, originalElement: ReactNode) => {
			if (type == 'jump-prev' || type == 'jump-next') {
				return originalElement;
			}

			const isChosen = type == 'page' && page == currentPage;
			const chosenClasses = isChosen ? [classes.chosen] : [];
			const itemClassName = createMergedClassName([classes.item, classes[type], ...chosenClasses]);

			const isArrowDisabled = (type == 'prev' && currentPage == 1) || (type == 'next' && currentPage == numberOfPages);
			const disabledClasses = isArrowDisabled ? [classes.disabled] : [];
			const arrowIconClassName = createMergedClassName([classes.arrowIcon, ...disabledClasses]);

			const innerItemByType = {
				prev: <ArrowLeft16 className={arrowIconClassName} />,
				next: <ArrowRight16 className={arrowIconClassName} />,
				page: (
					<Typography variant="DesktopH8Medium" _hover={!isChosen ? { cursor: 'pointer' } : undefined}>
						{page}
					</Typography>
				),
			};

			return <Box className={itemClassName}>{innerItemByType[type]}</Box>;
		},
		[currentPage, numberOfPages]
	);

	useEffect(() => {
		const isPageOutOfRange = currentPage > numberOfPages;
		if (isPageOutOfRange) {
			const newPage = Math.max(1, numberOfPages);
			onChange(newPage);
		}
	}, [currentPage, numberOfPages, onChange, reportEvent, totalItems]);

	return (
		<Flex justifyContent={'center'} alignItems={'center'} boxShadow={'borderTop'} p={'12px 16px'}>
			<ConfigProvider
				theme={{
					components: {
						Pagination: {
							colorPrimary: 'transperent',
							colorPrimaryHover: 'transperent',
							marginXS: 4,
							lineWidth: 0,
						},
					},
				}}
			>
				<Pagination
					current={currentPage}
					pageSize={pageSize}
					total={totalItems}
					onChange={onChange}
					itemRender={itemRender}
					className={classes.pagination}
				/>
			</ConfigProvider>
		</Flex>
	);
};
