import { RangeSelection } from 'lexical';
import { $isAtNodeEnd } from '@lexical/selection';

export function getSelectedNode(selection: RangeSelection) {
	const anchor = selection.anchor;
	const focus = selection.focus;
	const anchorNode = selection.anchor.getNode();
	const focusNode = selection.focus.getNode();
	if (anchorNode === focusNode) {
		return anchorNode;
	}
	const isBackward = selection.isBackward();
	if (isBackward) {
		return $isAtNodeEnd(focus) ? anchorNode : focusNode;
	} else {
		return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
	}
}

export function positionEditorElement(editor: HTMLDivElement, rect?: DOMRect) {
	if (!rect) {
		editor.style.opacity = '0';
		editor.style.top = '-1000px';
		editor.style.left = '-1000px';
	} else {
		editor.style.opacity = '1';
		editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
		editor.style.left = `${rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2}px`;
	}
}

export const transformEmptyLinks = (markdown: string) => {
	let clonedMarkdown = markdown.slice();
	const elements = clonedMarkdown.match(/\[.*?\)/g);

	if (elements != null && elements.length > 0) {
		for (const el of elements) {
			const txt = el.match(/\[(.*?)\]/)?.[1];
			const url = el.match(/\((.*?)\)/)?.[1];

			if (!url) {
				clonedMarkdown = clonedMarkdown.replace(el, txt as string);
			}
		}
	}

	return clonedMarkdown;
};
