import {
	Popover as ChakraPopover,
	PopoverProps as ChakraPopoverProps,
	PopoverContent,
	PopoverContentProps,
	PopoverTrigger,
	Portal,
} from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { PlacementType } from './types';

export type PopoverProps = {
	triggerElement?: ReactNode | ((isOpen: boolean) => ReactNode);
	placement?: PlacementType;
	children: ReactNode;
	popoverContentProps?: PopoverContentProps;
	onClose?: () => void;
	shouldHideOverflowText?: boolean;
	isWithPortal?: boolean;
} & Omit<ChakraPopoverProps, 'placement'>;

function Popover({
	triggerElement,
	placement,
	children,
	popoverContentProps = {},
	shouldHideOverflowText,
	onClose,
	isWithPortal = true,
	...popoverProps
}: PopoverProps) {
	const [isOpen, setIsOpen] = useState(false);
	const triggerComponent =
		typeof triggerElement == 'function'
			? triggerElement(popoverProps.isOpen === undefined ? isOpen : popoverProps?.isOpen)
			: triggerElement;

	return (
		<ChakraPopover
			placement={placement}
			variant="light"
			isLazy
			{...popoverProps}
			onOpen={() => setIsOpen(true)}
			onClose={() => {
				onClose?.();
				setIsOpen(false);
			}}
		>
			<PopoverTrigger>
				<div
					style={
						shouldHideOverflowText
							? {
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
							  }
							: undefined
					}
					className="popoverTriggerElement"
				>
					{triggerComponent}
				</div>
			</PopoverTrigger>
			{isWithPortal ? (
				<Portal>
					<PopoverContent overflow={'hidden'} {...popoverContentProps}>
						{children}
					</PopoverContent>
				</Portal>
			) : (
				<PopoverContent overflow={'hidden'} {...popoverContentProps}>
					{children}
				</PopoverContent>
			)}
		</ChakraPopover>
	);
}

Popover.id = 'Popover';

export default Popover;
