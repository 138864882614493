import { AvailablePeriodRanges, AvailableRangePresets, PeriodRange, PeriodUnit } from '@sightfull/period-ranges';

export type PeriodPickerOption = { label: string; value: PeriodUnit | 'custom' };
export const CustomPeriodPickerOption: PeriodPickerOption = {
	label: 'Custom',
	value: 'custom',
};
export const QuarterPeriodPickerOption: PeriodPickerOption = { label: 'Quarters', value: 'fquarter' };
export const defaultPeriodPickerOptions: PeriodPickerOption[] = [
	{ label: 'Weeks', value: 'week' },
	{ label: 'Months', value: 'month' },
	QuarterPeriodPickerOption,
	{ label: 'Quarters', value: 'fweek' },
	{ label: 'Years', value: 'fyear' },
	CustomPeriodPickerOption,
];

export type PeriodPickerProps = {
	availableRangePresets: AvailableRangePresets;
	availablePeriodRanges: AvailablePeriodRanges;
	selectedPeriodRange?: PeriodRange;
	onSelectPeriodRange: (periodRange?: PeriodRange) => void;
	size?: 'sm' | 'md';
	hasDefaultOption?: boolean;
	defaultPeriodPickerOption?: PeriodPickerOption;
	isDisabled?: boolean;
	isPeriodPickerOpen?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
};
