import { Progress as ChakraProgress, ProgressProps as ChakraProgressProps } from '@chakra-ui/react';
import * as CSS from 'csstype';

export type ProgressProps = {
	colorScheme?: CSS.Property.Color;
} & Omit<ChakraProgressProps, 'colorScheme'>;

function Progress(spinnerProps: ProgressProps) {
	return <ChakraProgress {...spinnerProps} />;
}

export default Progress;
