import * as CSS from 'csstype';
import { Box, ModalHeader, Spinner } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Input from '@components/Input';
import Typography from '@components/Typography';
import { CloseLarge16 } from '@icons/index';
import { ReactNode, useEffect, useState } from 'react';
import Button from 'src/common/components/Button';
import Modal from 'src/common/components/Modal';
import { ButtonColorSchemaType, ButtonSizeType } from '../Button/types';

type ConfirmationModallProps = {
	isOpen: boolean;
	onClose: VoidFunction;
	modalIcon?: ReactNode;
	primaryButtonLabel?: string;
	onSubmit: (val?: string) => void;
	modalText: string | ReactNode;
	modalTitle: string;
	isLoading?: boolean;
	isWaitingForContent?: boolean;
	submitColorScheme?: ButtonColorSchemaType;
	isWithoutCancel?: boolean;
	inputInitialValue?: string;
	buttonSize?: ButtonSizeType;
	isInputModalInvalid?: boolean;
	setIsInputModalInvalid?: (val: boolean) => void;
	minimumModalHeight?: string;
	testId?: string;
	contentMaxWidth?: CSS.Property.MaxWidth;
};

function ContentWaiter({ minimumModalHeight }: { minimumModalHeight?: string }) {
	return (
		<Box height={minimumModalHeight} alignItems={'center'} justifyContent={'center'}>
			<Flex justifyContent={'center'} alignItems={'center'} width="100%" height={'80%'}>
				<Spinner color="blue.600" size={'xl'} />
			</Flex>
		</Box>
	);
}

export function ConfirmationModal({
	isOpen,
	onClose,
	modalIcon,
	primaryButtonLabel = 'Confirm',
	onSubmit,
	modalText,
	modalTitle,
	isLoading = false,
	isWaitingForContent = false,
	submitColorScheme = 'black',
	isWithoutCancel,
	inputInitialValue,
	buttonSize = 'small',
	isInputModalInvalid = false,
	minimumModalHeight,
	setIsInputModalInvalid,
	testId,
	contentMaxWidth = '400px',
}: ConfirmationModallProps) {
	const [inputValue, setInputValue] = useState(inputInitialValue);

	useEffect(() => {
		if (!inputInitialValue) return;
		setInputValue(inputInitialValue);
	}, [inputInitialValue]);

	const isSubmitButtonDisabled = !!inputInitialValue && inputInitialValue === inputValue;

	return (
		<Modal
			data-testid={testId}
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={false}
			maxWidth="492px"
			isCentered
		>
			<>
				<ModalHeader color="gray.1000" padding={'16px 16px 0px 16px'}>
					<Flex justifyContent="start" alignItems="center">
						<Button variant="outline" isIconOnly onClick={() => onClose()} size="small" colorScheme="black">
							<CloseLarge16 />
						</Button>
					</Flex>
				</ModalHeader>

				{!isWaitingForContent && (
					<Flex
						justifyContent={'center'}
						flexDirection={'column'}
						alignItems={'center'}
						paddingX="32px"
						paddingBottom={'16px'}
					>
						{modalIcon}

						<Flex flexDirection={'column'} textAlign={'center'} maxWidth={contentMaxWidth}>
							<Typography marginTop={'24px'} marginBottom={'8px'} variant="DesktopH5" color="gray.1000">
								{modalTitle}
							</Typography>
							<Typography variant="Paragraph14R" color="gray.900">
								{modalText}
							</Typography>
						</Flex>
					</Flex>
				)}
				{isWaitingForContent && <ContentWaiter minimumModalHeight={minimumModalHeight} />}

				{inputInitialValue && (
					<Box padding={'0 52px'} paddingTop={'8px'} paddingBottom={'20px'}>
						<Typography marginBottom={'12px'} variant="DesktopH10Regular" color="gray.1000">
							Metric name
						</Typography>
						<Input
							color={isInputModalInvalid ? 'red.800' : 'gray.1000'}
							isInvalid={isInputModalInvalid}
							borderColor={!isInputModalInvalid ? 'gray.300' : ''}
							borderRadius="8px"
							size={'md'}
							placeholder={'Metric name'}
							value={inputValue}
							onChange={(val) => {
								setInputValue(val);
								setIsInputModalInvalid?.(false);
							}}
						/>
						{isInputModalInvalid && (
							<Typography marginTop={'8px'} variant="DesktopH8Regular" color={'red.800'}>
								The name is already exists.
							</Typography>
						)}
					</Box>
				)}

				{!isWaitingForContent && (
					<Box paddingBottom={'16px'}>
						<Flex flexDirection="row" paddingX="24px" paddingY="16px" justifyContent={'center'} alignItems="center">
							<Flex flexDirection="row" gap="12px">
								{!isWithoutCancel && !isLoading && (
									<Button size={buttonSize} variant="outline" colorScheme={'black'} onClick={onClose}>
										Cancel
									</Button>
								)}
								<Button
									isDisabled={isSubmitButtonDisabled}
									isLoading={isLoading}
									size={buttonSize}
									variant="solid"
									colorScheme={isSubmitButtonDisabled && !isLoading ? 'black' : submitColorScheme}
									onClick={() => onSubmit(inputValue)}
								>
									{primaryButtonLabel}
								</Button>
							</Flex>
						</Flex>
					</Box>
				)}
			</>
		</Modal>
	);
}
