import Box from '@components/Box';
import Flex from '@components/Flex';
import { Property as CSSProperty } from 'csstype';
import Button from '../Button';
import { ButtonColorSchemaType, ButtonSizeType } from '../Button/types';
import { SpaceProps } from '@chakra-ui/react';
import { TestIDs } from 'src/common/types/test-ids';

export type FooterSize = 'lg' | 'md' | 'sm';
export type ModalFooterProps = {
	size: FooterSize;
	onCancel?: () => void;
	onPrimaryClick?: () => void;
	onBack?: () => void;
	primaryButtonLabel: string;
	isPrimaryEnabled?: boolean;
	isPrimaryLoading?: boolean;
	placement?: string;
	color?: ButtonColorSchemaType;
	cancelButtonColor?: ButtonColorSchemaType;
	testId?: string;
} & SpaceProps;

export default function ModalFooter({
	size,
	onCancel,
	onPrimaryClick,
	onBack,
	primaryButtonLabel,
	isPrimaryEnabled = true,
	isPrimaryLoading = false,
	placement = 'space-between',
	color = 'gray',
	cancelButtonColor = color,
	testId,
	...props
}: ModalFooterProps) {
	return (
		<Flex
			flexDirection="row"
			paddingX="24px"
			paddingY="16px"
			justifyContent={placement}
			alignItems="center"
			height={heightByFooterSize[size]}
			{...props}
		>
			<Box>
				{onBack && (
					<Button
						size={buttonSizeByFooterSize[size]}
						variant="link"
						colorScheme={color}
						onClick={onBack}
						testId={TestIDs.MODAL_FOOTER_BACK_BUTTON(testId)}
					>
						Back
					</Button>
				)}
			</Box>
			<Flex flexDirection="row" gap="12px">
				<Button
					size={buttonSizeByFooterSize[size]}
					variant="outline"
					colorScheme={cancelButtonColor}
					onClick={onCancel}
					eventName="signal-draft-cancelled"
					testId={TestIDs.MODAL_FOOTER_CANCEL_BUTTON(testId)}
				>
					Cancel
				</Button>
				<Button
					size={buttonSizeByFooterSize[size]}
					variant="solid"
					colorScheme={color}
					onClick={onPrimaryClick}
					isDisabled={!isPrimaryEnabled}
					isLoading={isPrimaryLoading}
					eventName={`signal-draft-${primaryButtonLabel.toLowerCase()}d`}
					testId={TestIDs.MODAL_FOOTER_PRIMARY_BUTTON(testId)}
				>
					{primaryButtonLabel}
				</Button>
			</Flex>
		</Flex>
	);
}
const buttonSizeByFooterSize: { [key in FooterSize]: ButtonSizeType } = {
	lg: 'large',
	md: 'medium',
	sm: 'small',
};
const heightByFooterSize: { [key in FooterSize]: CSSProperty.Height } = {
	lg: '80px',
	md: '74px',
	sm: '68px',
};
