import { ChartType, chartTypeToInfo } from '@components/Chart/types';
import { SelectOption } from '@components/Select/types';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';
import { Selector } from 'src/pages/MetricPage/utils/state.types';
import { useMetricDerivedState } from './useMetricDerivedState';

export function useOverrideChartType(): readonly [Selector<SelectOption>, (chartType: ChartType) => void] {
	const { chartType } = useMetricDerivedState();
	const { setPartialSearchParams } = useMetricPageSearchParams();

	const chartTypesOptions: SelectOption[] = chartType.optionalValues.map((type) => ({
		value: type,
		label: chartTypeToInfo[type].label,
		icon: chartTypeToInfo[type].icon,
	}));
	const selectedChartType: SelectOption = {
		value: chartType.selectedValue,
		label: chartTypeToInfo[chartType.selectedValue].label,
		icon: chartTypeToInfo[chartType.selectedValue].icon,
	};

	function setChartType(chartType: ChartType) {
		setPartialSearchParams({
			chartType,
		});
	}

	return [{ optionalValues: chartTypesOptions, selectedValue: selectedChartType }, setChartType] as const;
}
