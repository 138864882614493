import Button from '@components/Button';
import { useAtomValue } from 'jotai';
import pluralize from 'pluralize';
import { useState } from 'react';
import { AccessController } from 'src/common/components/AccessController';
import Divider from 'src/common/components/Divider';
import Flex from 'src/common/components/Flex';
import { InfoRounded16, PenOutline16 } from 'src/common/components/Icons';
import { TinySwitch } from 'src/common/components/TinySwitch/TinySwitch';
import Typography from 'src/common/components/Typography';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { Permissions } from 'src/types/environment';
import { OntologyStateAtomDerived } from '../atoms/OntologyState';
import { NormalizedOntology, OntologyType } from '../hooks/useOntologyPageState';
import { useIsFullAdvancedMode } from '../hooks/useOntologySearchParams';
import DeleteItemsModal from './BuilderPanel/DeleteItemsModal';
import { AffectingMetricsTooltip } from './BuilderPanel/DimensionItem';
import ThreeDotsButton from './BuilderPanel/ThreeDotsButton';
import { HeaderBadge } from './HeaderBadge';
import { SkeletonHeader } from './SkeletonComponents/SkeletonTable/SkeletonTable';
import { UpsertEntityModal } from './UpsertEntityModal';

type Props = {
	isLoading?: boolean;
	ontology?: NormalizedOntology;
	isDisabled?: boolean;
	onButtonClick: () => void;
};

export function Header({ ontology, isDisabled, isLoading, onButtonClick }: Props) {
	const { displayName } = ontology || {};

	return (
		<Flex
			alignItems={'center'}
			justifyContent={'space-between'}
			shadow={shadows.borderBottom}
			paddingX={'24px'}
			paddingY={'12px'}
			width={'100%'}
		>
			<Typography variant="DesktopH7Medium" color={'gray.1000'}>
				{displayName}
			</Typography>
			<AccessController
				permission={Permissions.writeEntities}
				noAccessChild={
					<Flex alignItems={'center'} borderRadius={'2px'} padding={'2px 8px'} backgroundColor={colors.gray[200]}>
						<Typography color={colors.gray[700]} cursor={'inherit'} key={'builder'} variant="Paragraph12M">
							View only
						</Typography>
					</Flex>
				}
			>
				<Button
					isLoading={isLoading}
					isDisabled={isDisabled}
					onClick={onButtonClick}
					variant="solid"
					size="small"
					colorScheme={isDisabled && !isLoading ? 'black' : 'blue'}
				>
					Save & Run
				</Button>
			</AccessController>
		</Flex>
	);
}

type BuilderHeaderProps = {
	ontologyType: OntologyType;
	ontology: NormalizedOntology;
	onDelete?: (callback: VoidFunction) => void;
	deleteEntityInProgress?: boolean;
	isEditable?: boolean;
};

export function BuilderHeader({
	ontology,
	ontologyType,
	onDelete,
	deleteEntityInProgress = false,
	isEditable = true,
}: BuilderHeaderProps) {
	const { displayName, name, description, affectedItemsList = [], icon: Icon } = ontology ?? {};
	const [propertyToDelete, setPropertyToDelete] = useState<NormalizedOntology | undefined>();
	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const { isAdvancedMode, setIsAdvancedMode } = useIsFullAdvancedMode();
	const isUpsertEntityModalEnabled = useFeatureFlag('pulse.sightfull2.upsertEntityModal.enable');
	const hasWritePermission = usePermissionCheck().isHavingPermission(Permissions.writeEntities);
	const { isOpen, onOpen, onClose } = useModal();
	const { reportEvent } = useReportEvent();

	if (ontologyType == 'entity' && ontologyState.loading) return <SkeletonHeader />;

	return (
		<Flex
			minH={'76px'}
			alignItems={'center'}
			justifyContent={'space-between'}
			shadow={shadows.borderBottom}
			paddingX={'24px'}
			paddingY={'16px'}
			width={'100%'}
		>
			<Flex width={'50%'} alignItems="center" gap="12px">
				<Flex flexDirection={'column'}>
					<Flex marginBottom={'8px'} wrap={'wrap'} alignItems={'center'} gap={'8px'} color="gray.1000">
						{Icon && <Icon />}
						<Typography variant="DesktopH8Medium" color={'gray.1000'}>
							{displayName}
						</Typography>
						{!hasWritePermission && <HeaderBadge text="View only" />}
					</Flex>
					<Typography noOfLines={1} textOverflow={'ellipsis'} variant="DesktopH10Regular" color={'gray.800'}>
						{description}
					</Typography>
				</Flex>
			</Flex>
			<Flex alignItems={'center'} alignSelf="stretch">
				{ontologyType == 'entity' && (
					<>
						<AffectingMetricsTooltip affectedMetricsList={affectedItemsList ?? []}>
							<HeaderBadge
								text={
									affectedItemsList?.length > 0
										? `Affecting ${affectedItemsList?.length} ${pluralize('metric', affectedItemsList?.length)}`
										: 'No affected metrics'
								}
							/>
						</AffectingMetricsTooltip>
						<Flex marginLeft="16px" marginRight={'10px'} height={'24px'}>
							<Divider direction="vertical" />
						</Flex>
						<TinySwitch
							onClick={(value) => {
								reportEvent({
									event: 'ontology-entity-full-YAML-toggle',
									metaData: { objectName: name, newState: value },
								});
								setIsAdvancedMode('entity', value);
							}}
							isEnabled={isAdvancedMode}
							text="Edit entity (Advanced)"
						/>
						<Flex marginLeft={'16px'} gap={'4px'}>
							{isUpsertEntityModalEnabled && (
								<Button
									isDisabled={!isEditable}
									color={'gray.1000'}
									variant="outline"
									colorScheme="black"
									onClick={() => {
										reportEvent({
											event: 'ontology-entity-edit-clicked',
											metaData: { entityName: name },
										});
										onOpen();
									}}
									isIconOnly={true}
									size="xs"
								>
									{hasWritePermission ? <PenOutline16 /> : <InfoRounded16 />}
								</Button>
							)}
							<ThreeDotsButton
								isEditable={isEditable}
								size="xs"
								type="entity"
								onDelete={() => setPropertyToDelete(ontology)}
							/>
						</Flex>
					</>
				)}
				{ontologyType == 'parameter' && <HeaderBadge text="View only" />}
			</Flex>
			<DeleteItemsModal
				isLoading={deleteEntityInProgress}
				type={'entity'}
				onSubmit={() => onDelete?.(() => setPropertyToDelete(undefined))}
				propertyToDelete={propertyToDelete}
				affectedMetricsList={affectedItemsList}
				onClose={() => setPropertyToDelete(undefined)}
			/>
			{!ontologyState.loading && (
				<UpsertEntityModal
					ontologyEditorValue={ontologyState?.editorYaml}
					selectedOntology={ontology}
					isEditEntity
					isOpen={isOpen}
					onClose={onClose}
				/>
			)}
		</Flex>
	);
}
