import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { AddColumn16, ChevronLeftSolid16, DuplicateSolid16, GranularPeriod16 } from '@icons/index';
import { GridReadyEvent, RowClickedEvent } from 'ag-grid-community';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import sumBy from 'lodash/sumBy';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { sideBarConfig, useContextMenuItems, useDefaultColDef } from 'src/common/components/Table/config';
import { ExportIcon } from 'src/pages/MetricPage/components/Header/ExportTableModal/ExportIcon';
import components from './components';
import { useSetAtom } from 'jotai';
import Divider from 'src/common/components/Divider';
import Skeleton from 'src/common/components/Skeleton';
import 'src/common/components/Table/Table.scss';
import { TinySwitch } from 'src/common/components/TinySwitch/TinySwitch';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useToast from 'src/common/hooks/ui/useToast';
import { TableRefAtom } from 'src/pages/MetricPage/atoms/TableRef';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricSQLQueryView } from 'src/pages/MetricPage/hooks/useMetricSQLQueryView';
import { useReportEvent } from 'src/services/analytics';
import { useUpdateTableColumnState } from '../../../hooks/useUpdateTableColumnState';
import { MonacoSQLEditor } from '../../MonacoEditor/MonacoSQLEditor';

function TableHeader({
	onCloseClicked,
	title,
	subtitle,
	onSelectAllPeriodsClicked,
	exportTableDataAsCSV,
	onAddColumn,
}: {
	onCloseClicked: (() => void) | undefined;
	title: string;
	subtitle: React.ReactNode;
	onSelectAllPeriodsClicked: (() => void) | undefined;
	exportTableDataAsCSV: () => void;
	onAddColumn: (() => void) | undefined;
}) {
	const isSqlQueryViewFF = useFeatureFlag('pulse.metricPage.sqlQueryView.enable');
	const { showSqlQueryView, setShowSqlQueryView } = useMetricSQLQueryView();
	const { reportEvent } = useReportEvent();
	const {
		metricNameWithoutFlavor,
		breakdowns,
		filters,
		periodRange,
		isGranularDataForAllPeriodsButtonEnabled,
		sqlQuery,
	} = useMetricDerivedState();
	const metadata = useMemo(
		() => ({
			metricName: metricNameWithoutFlavor,
			breakdowns: breakdowns.values,
			filters: filters,
			startPeriod: periodRange.asAbsoluteRange.startPeriod.id,
			endPeriod: periodRange.asAbsoluteRange.endPeriod.id,
		}),
		[breakdowns, filters, metricNameWithoutFlavor, periodRange]
	);
	const toast = useToast();

	const onClick = useCallback(
		(newValue: boolean) => {
			setShowSqlQueryView(newValue);
			reportEvent({
				event: 'change-show-sql-query-view',
				metaData: { showQuery: newValue, ...metadata },
			});
		},
		[metadata, reportEvent, setShowSqlQueryView]
	);

	const shouldShowSelectAllPeriodButton = onSelectAllPeriodsClicked && isGranularDataForAllPeriodsButtonEnabled;
	return (
		<Flex justifyContent="space-between" alignItems={'center'} marginBottom="8px" height={'32px'} marginTop="16px">
			{showSqlQueryView ? (
				<Typography variant="DesktopH8Medium" color="gray.1000">
					Metric SQL Query
				</Typography>
			) : (
				<>
					{onCloseClicked && (
						<Box marginRight={'5px'}>
							<Button variant="outline" isIconOnly onClick={onCloseClicked} size="small" colorScheme="lightGray">
								<ChevronLeftSolid16 />
							</Button>
						</Box>
					)}
					<Flex flexDirection="row" justifyContent="flex-start" grow={1} gap="6px">
						<Typography variant="DesktopH8Medium" color="gray.1000">
							{title}
						</Typography>
						<Typography variant="DesktopH8Regular" color="gray.1000">
							{subtitle}
						</Typography>
					</Flex>
				</>
			)}
			<Flex alignItems="center" justifyContent="flex-end" paddingLeft={'16px'}>
				{showSqlQueryView ? (
					<Tooltip size="md" label="Copy SQL Query for this metric" marginTop="12px">
						<Button
							onClick={() => {
								if (!sqlQuery) return;

								navigator.clipboard
									.writeText(sqlQuery)
									.then(() => toast({ variant: 'ok', message: 'Copied to clipboard' }));
								reportEvent({ event: 'copy-to-clipboard-sql-query', metaData: metadata });
							}}
							variant="outline"
							size="xs"
							colorScheme="lightGray"
							disabled={!sqlQuery}
							leftIcon={<DuplicateSolid16 />}
						>
							Copy SQL
						</Button>
					</Tooltip>
				) : (
					<>
						{shouldShowSelectAllPeriodButton && (
							<Tooltip size="md" label="Granular data for all periods" marginTop="12px">
								<Button
									onClick={() => {
										reportEvent({ event: 'select-all-periods-in-table-clicked' });
										onSelectAllPeriodsClicked();
									}}
									variant="outline"
									isIconOnly
									size="small"
									colorScheme="lightGray"
								>
									<GranularPeriod16 />
								</Button>
							</Tooltip>
						)}
						<Box marginLeft={'4px'}>
							<ExportIcon exportTableDataAsCSV={exportTableDataAsCSV} />
						</Box>
						{onAddColumn && (
							<Tooltip size="md" label="Add column" marginTop="12px">
								<Box marginLeft={'4px'}>
									<Button variant="outline" isIconOnly onClick={onAddColumn} size="small" colorScheme="lightGray">
										<AddColumn16 />
									</Button>
								</Box>
							</Tooltip>
						)}
					</>
				)}
				{isSqlQueryViewFF && (
					<>
						<Divider color="gray.300" direction="vertical" paddingY="10px" marginX="8px" />
						<TinySwitch onClick={onClick} isEnabled={showSqlQueryView} text="SQL" />
					</>
				)}
			</Flex>
		</Flex>
	);
}

type Props = {
	onTableReady?: () => void;
	onAddColumn?: () => void;
	onCloseClicked?: () => void;
	onSelectAllPeriodsClicked?: () => void;
	onRowClicked?: (event: RowClickedEvent) => void;
	data: Record<string, any>[];
	columnDefs?: ColDef[];
	title: string;
	subtitle: React.ReactNode;
	isGroupable?: boolean;
	isThumbnail?: boolean;
	shouldSuppressDotNotation?: boolean;
	dataIntercomTarget?: string;
};

const Table = ({
	onTableReady,
	data,
	onAddColumn,
	onRowClicked,
	onCloseClicked,
	onSelectAllPeriodsClicked,
	columnDefs,
	title,
	subtitle,
	isGroupable = true,
	isThumbnail = false,
	shouldSuppressDotNotation = false,
	dataIntercomTarget = 'table',
}: Props) => {
	const tableRef = useRef<AgGridReact>(null);
	const tableWrapperRef = useRef<HTMLDivElement>(null);
	const setTableRefAtom = useSetAtom(TableRefAtom);
	const defaultColDef = useDefaultColDef(isGroupable, !!onRowClicked);
	const contextMenuItems = useContextMenuItems();
	const { tableColumnState, sqlQuery } = useMetricDerivedState();
	const { showSqlQueryView } = useMetricSQLQueryView();

	useUpdateTableColumnState();

	const tablePaddingX = isThumbnail ? '16px' : '32px';

	const [isScrollVisible, setIsScrollVisible] = useState<boolean>(false);

	const exportTableDataAsCSV = () => {
		tableRef.current?.api?.exportDataAsCsv?.();
	};

	const onGridReady = (gridEvent: GridReadyEvent) => {
		onTableReady?.();
		gridEvent.columnApi.applyColumnState({ state: tableColumnState, applyOrder: false });
		setTableRefAtom(gridEvent);
	};
	useEffect(() => {
		return () => {
			setTableRefAtom(null);
		};
	}, [setTableRefAtom]);

	const onGridSizeChange = (gridEvent: GridReadyEvent) => {
		checkHorizontalScrollVisible(gridEvent);
	};

	const checkHorizontalScrollVisible = (gridEvent: GridReadyEvent) => {
		if (tableWrapperRef && tableWrapperRef.current) {
			const tableWrapperWidth = tableWrapperRef.current.clientWidth;
			const actualTableWidth = sumBy(gridEvent.columnApi.getAllDisplayedColumns(), function (column) {
				return column.getActualWidth();
			});
			const isScrollVisible = actualTableWidth > tableWrapperWidth;
			setIsScrollVisible(isScrollVisible);

			if (!isScrollVisible) gridEvent.api.sizeColumnsToFit();
		}
	};

	const boxPaddingRight = isScrollVisible ? '0' : isThumbnail ? '16px' : '32px';

	return (
		<div
			className="tableContainer"
			data-intercom-area={'main'}
			data-intercom-type={'main'}
			data-intercom-target={dataIntercomTarget}
		>
			{!isThumbnail && (
				<Box paddingX={'32px'}>
					<TableHeader
						onCloseClicked={onCloseClicked}
						title={title}
						subtitle={subtitle}
						onSelectAllPeriodsClicked={onSelectAllPeriodsClicked}
						exportTableDataAsCSV={exportTableDataAsCSV}
						onAddColumn={onAddColumn}
					/>
				</Box>
			)}
			<Flex flexGrow={1} flexDir={'column'} px="32px" pb="32px" display={!showSqlQueryView ? 'none' : 'flex'}>
				{sqlQuery ? <MonacoSQLEditor value={sqlQuery} /> : <Skeleton height="200px" />}
			</Flex>
			<Box
				paddingBottom={0}
				paddingX={tablePaddingX}
				paddingRight={boxPaddingRight}
				ref={tableWrapperRef}
				flexGrow={1}
				className={`tableWrapper hoveredRow ${isScrollVisible ? 'pinnedCell' : ''}`}
				display={showSqlQueryView ? 'none' : 'block'}
			>
				<AgGridReact
					debounceVerticalScrollbar
					className="ag-theme-alpine"
					ref={tableRef}
					rowData={data}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					autoGroupColumnDef={columnDefs?.find((cd) => cd.rowGroup)}
					getContextMenuItems={contextMenuItems}
					onGridReady={onGridReady}
					onGridSizeChanged={onGridSizeChange}
					sideBar={sideBarConfig}
					onRowClicked={onRowClicked}
					rowSelection="multiple"
					components={components}
					suppressPaginationPanel={true}
					rowHeight={48}
					headerHeight={40}
					suppressFieldDotNotation={shouldSuppressDotNotation}
					maintainColumnOrder={true}
				/>
			</Box>
		</div>
	);
};

export default Table;
