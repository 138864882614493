import { useCallback } from 'react';
import { useReportEvent } from 'src/services/analytics';

export type AnyFunc = (args?: any) => unknown;

export type AskAIEventProps = {
	event: string;
	metaData?: Record<string, any>;
};

export function useReportAskAIEvent() {
	const { reportEvent } = useReportEvent();

	const reportAskAIEvent = useCallback(
		({ event, metaData }: AskAIEventProps) => {
			delete metaData?.result;
			const props = { event, metaData: { feature: 'Ask AI', ...metaData } };
			reportEvent(props);
		},
		[reportEvent]
	);

	return { reportAskAIEvent };
}
