import { useCallback } from 'react';
import { useMutation } from 'src/common/hooks/fetching/useMutation';
import useUser from 'src/common/hooks/stores/useUser';
import {
	GetAllInsightsByTagsQuery,
	SubscribeMeToInsightMutation,
	SubscribeMeToInsightMutationVariables,
	UnsubscribeMeToInsightMutation,
	UnsubscribeMeToInsightMutationVariables,
} from 'src/generated/graphql';
import { GetAllInsightsByTags, SubscribeMeToInsight, UnsubscribeMeToInsight } from 'src/queries/subscriptions';

export function useOptimisticUnsubscribe() {
	const [unsubscribeInsight] = useMutation<UnsubscribeMeToInsightMutation, UnsubscribeMeToInsightMutationVariables>(
		UnsubscribeMeToInsight
	);
	const [{ id: my_id }] = useUser();
	const optimisticUnsubscribe = (insightId: string) => {
		unsubscribeInsight({
			variables: { insight_id: insightId, my_id },
			optimisticResponse: {
				__typename: 'Mutation',
				delete_user_tags_by_pk: {
					__typename: 'user_tags',
					tag: null,
				},
			},
			update: (cache) => {
				const tagsFromCache = cache.readQuery<GetAllInsightsByTagsQuery>({
					query: GetAllInsightsByTags,
					variables: {
						my_id,
					},
				});

				if (!tagsFromCache) return;

				cache.writeQuery<GetAllInsightsByTagsQuery>({
					query: GetAllInsightsByTags,
					variables: { my_id },
					data: {
						tags: generateOptimisticResponse({ isSubscribeOperation: false, tagsFromCache, insightId }),
					},
				});
			},
		});
	};

	return useCallback(optimisticUnsubscribe, [my_id, unsubscribeInsight]);
}

export function useOptimisticSubscribe() {
	const [subscribeInsight] = useMutation<SubscribeMeToInsightMutation, SubscribeMeToInsightMutationVariables>(
		SubscribeMeToInsight
	);
	const [{ id: my_id }] = useUser();
	const optimisticSubscribe = (insightId: string) => {
		subscribeInsight({
			variables: { insight_id: insightId, my_id },
			optimisticResponse: {
				__typename: 'Mutation',
				insert_user_tags_one: {
					__typename: 'user_tags',
					tag: null,
				},
			},
			update: (cache) => {
				const tagsFromCache = cache.readQuery<GetAllInsightsByTagsQuery>({
					query: GetAllInsightsByTags,
					variables: {
						my_id,
					},
				});

				if (!tagsFromCache) return;

				cache.writeQuery<GetAllInsightsByTagsQuery>({
					query: GetAllInsightsByTags,
					variables: { my_id },
					data: {
						tags: generateOptimisticResponse({ isSubscribeOperation: true, tagsFromCache, insightId }),
					},
				});
			},
		});
	};

	return useCallback(optimisticSubscribe, [my_id, subscribeInsight]);
}

function generateOptimisticResponse({
	isSubscribeOperation,
	tagsFromCache,
	insightId,
}: {
	isSubscribeOperation: boolean;
	tagsFromCache: GetAllInsightsByTagsQuery;
	insightId: string;
}): any {
	const tagIndex = tagsFromCache.tags.findIndex((t) =>
		t.insight_types.some((insight) => insight.insight_type?.id == insightId)
	);
	const tag = tagsFromCache.tags[tagIndex];
	const insightIndex = tag?.insight_types.findIndex((insight) => insight.insight_type?.id == insightId);
	const insight = tag.insight_types[insightIndex];
	if (!insight || !insight.insight_type) return;

	const insights = [...tag.insight_types];
	insights[insightIndex] = {
		__typename: 'insight_type_tags',
		insight_type: {
			__typename: 'insight_types',
			id: insightId,
			display_name: insight.insight_type.display_name,
			description: insight.insight_type.description,
			my_subscriptions: {
				aggregate: {
					__typename: 'insight_type_tags_aggregate_fields',
					count: isSubscribeOperation ? 1 : 0,
				},
			},
		},
	};

	const tags = [...tagsFromCache.tags];
	tags[tagIndex] = {
		__typename: 'tags',
		id: tag?.id,
		insight_types: insights,
	};
	return tags;
}
