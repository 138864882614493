import { Box } from '@chakra-ui/react';
import { BuiltInPeriodRangePresets } from '@sightfull/period-ranges';
import { Provider } from 'jotai';
import { extractMetricNameAndSearchParams } from 'src/common/utils/signalUtils';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { getInitialDerivedStateAtom } from 'src/pages/MetricPage/atoms/DerivedState';
import {
	IsMetricPageURLBasedAtom,
	MetricPageSearchParamsAtom,
} from 'src/pages/MetricPage/atoms/MetricPageSearchParams';
import { TableRefAtom } from 'src/pages/MetricPage/atoms/TableRef';
import RulesEngineRunner from 'src/pages/MetricPage/components/RulesEngineRunner';
import { MetricPagePath } from 'src/pages/MetricPage/pageRoutesPaths';
import { WorkspaceAttachment } from 'src/pages/WorkspacePage/SignalWidget/WorkspaceAttachment';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import colors from 'src/style/colors';
import { EntityMetricMetadata } from '../types';

type ThumbnailProps = { metric: EntityMetricMetadata; nodeId: string };
export const Thumbnail = ({ metric, nodeId }: ThumbnailProps) => {
	const { reportEvent } = useReportEvent();
	const { navigate } = useNavigation();
	if (!metric) {
		return <></>;
	}

	const { metricName, searchParams } = extractMetricNameAndSearchParams(metric.metric_template_url);

	if (BuiltInPeriodRangePresets.isPresetKey(metric.period_preset)) {
		const period = BuiltInPeriodRangePresets.byKey(metric.period_preset, fiscalYearOffset());
		searchParams.set('periodRange', JSON.stringify(period?.toUrlParams().periodRange));
	}
	const filters = metric.node_id_filters.reduce((acc, filter) => ({ ...acc, [filter]: [nodeId] }), {});

	if (!filters) return null;
	searchParams.set('filterBy', JSON.stringify(filters));

	const navigateToMetricPage = () => {
		reportEvent({
			event: 'entity-profile-page-metric-clicked',
			metaData: {
				metricName,
				searchParams,
			},
		});

		navigate({ path: `/${MetricPagePath}/${metricName}`, additionalSearchParams: searchParams, isNewTab: true });
	};

	return (
		<Box>
			<Provider
				initialValues={[
					[MetricPageSearchParamsAtom, { metricName, searchParams }],
					getInitialDerivedStateAtom(),
					[IsMetricPageURLBasedAtom, false],
					[TableRefAtom, null],
				]}
			>
				<RulesEngineRunner>
					<Chart onHeaderClick={navigateToMetricPage} />
				</RulesEngineRunner>
			</Provider>
		</Box>
	);
};

export const Chart = ({ onHeaderClick }: { onHeaderClick: VoidFunction }) => {
	return (
		<Box border={`1px solid ${colors.gray[300]}`} borderRadius={'4px'} backgroundColor="white">
			<WorkspaceAttachment headerOnClickHandler={onHeaderClick} />
		</Box>
	);
};
