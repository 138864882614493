import { Switch as ChakraSwitch, SwitchProps as ChakraSwitchProps } from '@chakra-ui/react';

export type SwitchProps = {
	id?: string;
	size?: ChakraSwitchProps['size'];
	testId?: string;
	isChecked?: boolean;
	defaultChecked?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function Switch({ size = 'sm', testId, isChecked, onChange, ...props }: SwitchProps) {
	return <ChakraSwitch size={size} data-testid={testId} isChecked={isChecked} onChange={onChange} {...props} />;
}
