import Box from '@components/Box';
import Flex from '@components/Flex';
import ActionTooltip from 'src/common/components/ActionTooltip';
import { Info12 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import { useReportEvent } from 'src/services/analytics';
import { showIntercomArticle } from 'src/services/intercom';
import classes from './Header.module.scss';

export function MetricExplanation({
	metricName,
	metricTitle,
	oneliner,
	articleId,
}: {
	metricName: string;
	metricTitle: string;
	oneliner: string;
	articleId?: string;
}) {
	const { reportEvent } = useReportEvent();
	function onToggleFullExplanation() {
		reportEvent({
			event: 'explain-metric-request',
			metaData: {
				metricName: metricName,
				metricDisplayName: metricTitle,
				missingArticleId: !!articleId,
			},
		});

		if (articleId) {
			showIntercomArticle(articleId);
		}
	}

	return (
		<ActionTooltip
			title={metricTitle}
			label={oneliner}
			onClick={onToggleFullExplanation}
			placement="bottom-start"
			marginTop="10px"
			marginLeft="-2px"
		>
			<Flex flexDirection="row" className={classes.metricTitleContainer}>
				<Typography className={classes.metricTitle} variant="DesktopH8Medium">
					{metricTitle}
				</Typography>
				<Box className={classes.metricExplanationIcon}>
					<Info12 />
				</Box>
			</Flex>
		</ActionTooltip>
	);
}
