import Input from '@components/Input';
import { Search16 } from '@icons/index';
import Box from '@components/Box';
import classes from './SearchInput.module.scss';
import { InputProps } from '@components/Input/types';
import Flex from '@components/Flex';

export function SearchInput({ value, onChange, size }: Pick<InputProps, 'value' | 'onChange' | 'size'>) {
	return (
		<Flex className={classes.searchContainer} alignItems={'center'}>
			<Box className={classes.fullBox + (value && ' ' + classes.fullBoxExpanded)}>
				<Input
					size={size}
					leftComponent={<Search16 />}
					placeholder={'Search'}
					isErasable
					value={value}
					onChange={onChange}
				/>
			</Box>
			<Box className={classes.iconOnly + (value && ' ' + classes.iconOnlyExpanded)}>
				<Search16 />
			</Box>
		</Flex>
	);
}
