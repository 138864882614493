import { CircularProgress } from '@chakra-ui/react';
import { AccessController } from 'src/common/components/AccessController';
import IconButton from 'src/common/components/IconButton';
import { BinSolid16 } from 'src/common/components/Icons';
import useUser from 'src/common/hooks/stores/useUser';
import { INFO_MARKDOWN, SUCCESS_MARKDOWN, WARNING_MARKDOWN } from 'src/pages/DashboardPage/constants';
import { Permission, Permissions } from 'src/types/environment';
import { FeedSignal } from 'src/types/spaces';
import classes from '../../../GridLayout/GridLayout.module.scss';
import { WidgetHeader } from '../../WidgetHeader/WidgetHeader';

export function DividerWidgetHeader({
	isEditMode,
	onRemoveClicked,
	isLoading,
	feedSignal,
}: {
	isEditMode: boolean;
	onRemoveClicked: VoidFunction;
	isLoading: boolean;
	feedSignal: FeedSignal;
}) {
	const [{ id: userId }] = useUser();
	const isMyWidget = feedSignal?.signal.author_id == userId;

	const permissionsForDelete: Permission[] = [Permissions.updateAllSignals];
	if (isMyWidget) permissionsForDelete.push(Permissions.crudMySignals);

	return (
		<WidgetHeader
			description={feedSignal.signal.message
				.replaceAll(WARNING_MARKDOWN, '')
				.replaceAll(INFO_MARKDOWN, '')
				.replaceAll(SUCCESS_MARKDOWN, '')}
			hasBottomPadding={false}
			isEditMode={isEditMode}
			title="Divider"
			actionItems={
				isLoading
					? [
							<CircularProgress
								isIndeterminate
								color="black"
								size="20px"
								padding="6px"
								key="text-signal-loading-indicator"
							/>,
					  ]
					: [
							<AccessController permission={permissionsForDelete} key="delete-access-controller" logic="OR">
								<IconButton size="xs" icon={<BinSolid16 />} colorScheme="gray" key="delete" onClick={onRemoveClicked} />
							</AccessController>,
					  ]
			}
			dragHandleClassName={classes.draggableHandle}
		/>
	);
}
