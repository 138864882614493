import { YAxisOptions } from 'highcharts';
import omit from 'lodash/omit';
import { CalculateMetricCoreReaderQuery } from 'src/generated/graphql';
import { manualOrder, nameMainSeries, OrderNameToOrderFunc } from 'src/lib/metricRules/DerivedStateCalculators';

import { MetricPeriod } from '@sightfull/period-ranges';
import { percentageFormatter } from 'src/lib/metricRules/DerivedStateCalculators/CoreReader/calcCoreFormatting';
import { xAxisFormatter } from 'src/lib/metricRules/utils';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { isAutomaticSortOrder } from 'src/pages/MetricPage/components/LegendsPanel/types';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { CoreMetricUnit } from 'src/types/metric';
import {
	ChartOptions,
	ChartSeries,
	ChartType,
	SeriesCustomField,
	SeriesDataPoint,
	SeriesType,
} from '../components/Chart/types';

export function buildCoreReaderChartOptions(
	calcMetricResult: NonNullable<CalculateMetricCoreReaderQuery['calcMetricV2']['chartOptions']>,
	{
		metricDisplayName,
		displayUnits,
		sortOrder,
	}: Pick<MetricDerivedState, 'metricDisplayName' | 'displayUnits' | 'sortOrder'>,
	orderedComponents?: string[]
): ChartOptions {
	const chartSeries: ChartSeries[] = calcMetricResult.series?.map((series) => buildChartSeries(series, displayUnits));
	const withNamedMain = nameMainSeries(metricDisplayName, chartSeries);

	const setVisibility = (series: ChartSeries): ChartSeries => ({
		...series,
		visible: true,
	});
	const withVisibility = withNamedMain.map(setVisibility);

	const getOrderedSeries = (series: ChartSeries[]): ChartSeries[] => {
		const componentsSeries = series.filter((s) => s.custom.seriesType != 'main');
		const mainSeries = series.filter((s) => s.custom.seriesType == 'main');
		if (orderedComponents?.length) {
			return manualOrder(series, orderedComponents);
		}
		if (isAutomaticSortOrder(sortOrder.selectedValue)) {
			return OrderNameToOrderFunc[sortOrder.selectedValue](componentsSeries).concat(mainSeries);
		}

		return series;
	};

	const seriesWithSortOrder = getOrderedSeries(withVisibility).map((s, index) => {
		s.custom.seriesOrder = index;
		return s;
	});
	return {
		series: seriesWithSortOrder,
		xAxis: {
			values: calcMetricResult.xAxis.map((x) => MetricPeriod.fromIdString(x.id, fiscalYearOffset())) ?? [],
			formatter: xAxisFormatter,
			plotBands: [],
		},
		yAxis: buildYAxis(seriesWithSortOrder),
	};
}

function buildChartSeries(
	{
		name,
		custom: customSource,
		...series
	}: NonNullable<CalculateMetricCoreReaderQuery['calcMetricV2']['chartOptions']>['series'][0],
	displayUnits: MetricDerivedState['displayUnits']
): ChartSeries {
	const chartType = series.chartType as ChartType;
	const isTargetAttainment = chartType == 'attainment';
	const data: SeriesDataPoint[] = series.data.map(({ name, y, custom }): SeriesDataPoint => {
		const customData = Object.entries(displayUnits).reduce((acc, [unit, { value }]) => {
			if (!value) return omit(acc, unit);
			return acc;
		}, custom ?? {}) as SeriesCustomField;
		const formatLabelToPercentage = isTargetAttainment && y != undefined;
		return {
			name: MetricPeriod.fromIdString(name, fiscalYearOffset()).pretty,
			y: y ?? undefined,
			custom: {
				label: (formatLabelToPercentage ? percentageFormatter(y, 2) : y ?? undefined)?.toString(), // TODO: restructure the core reader response to return this as custom too, when doing this take target attainment into consideration
				...customData,
			},
		};
	});

	const custom: SeriesCustomField['custom'] = {
		rawName: customSource?.rawName ?? undefined,
		seriesType: customSource?.seriesType as SeriesType,
		unit: isTargetAttainment ? 'percentage' : (customSource?.unit as CoreMetricUnit) ?? undefined,
		value: customSource?.value ?? undefined,
		appliedParameters: customSource.appliedParameters ?? undefined,
	};

	return {
		yAxis: series.yAxis ?? undefined,
		chartType,
		name,
		data,
		custom,
	};
}

function buildYAxis(chartSeries: ChartSeries[]): YAxisOptions[] {
	const yAxisIds: string[] = [
		...new Set(chartSeries.map((series) => series.yAxis).filter((yAxis) => yAxis != undefined)),
	];

	return yAxisIds.map((yAxisId) => ({
		visible: false,
		id: yAxisId,
	}));
}
