import {
	ChartOptions,
	ChartSeries,
	ChartType,
	componentSeriesCustomField,
	mainSeriesCustomField,
	SeriesCustomField,
	SeriesDataPointY,
	SeriesDataPointYFormatter,
	statisticSeriesCustomField,
} from '@components/Chart/types';
import { MetricPeriod, PeriodUnit } from '@sightfull/period-ranges';
import { NOT_AVAILABLE_VALUE_STRING } from 'src/common/utils/consts';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { GetMetricByNameQuery } from 'src/generated/graphql';
import { MetricOperator } from 'src/models/MetricOperator';
import { DisplayUnitType, Filter, MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import {
	FilterRequestsOptions,
	MetricCalcPeriodResult,
	MetricCalcResult,
	MetricCalcSeriesResult,
	MetricResultSeriesType,
	MetricUnit,
	StatisticInfo,
	TargetInfo,
	TotalSeriesName,
} from 'src/types/metric';
import { DataLabelFormatConfig } from './statisticOperations/types';
import { percentageFormatter } from './valueFormatters';

export function transposeMetricData(metricData: MetricCalcResult) {
	const allSeries: MetricCalcSeriesResult[] = metricData.results.map((x) => x.series).flat();
	const allDistinctSeriesNames = [...new Set(allSeries.map((s) => s.name))];
	const allTargets: TargetInfo[] = allSeries
		.filter((s) => s.name == TotalSeriesName)
		.map((s) => s.targets ?? [])
		.flat();
	const allDistinctTargetNames = [...new Set(allTargets.map((x) => x.name))];

	const allStatistics: StatisticInfo[] = allSeries
		.filter((s) => s.name == TotalSeriesName)
		.map((s) => s.statistics ?? [])
		.flat();
	const allDistinctStatisticsNames = [...new Set(allStatistics.map((x) => x.name))];

	function getOverlaysForSeries(seriesName: string, overlayType: 'statistics' | 'targets') {
		const allDistinctOverlayNames = overlayType === 'statistics' ? allDistinctStatisticsNames : allDistinctTargetNames;

		const getOverlayResultsFromAllPeriods = (currentOverlay: string) => {
			const getOverlayValueByPeriod = (seriesInPeriod: MetricCalcPeriodResult) => {
				const currentSeries = seriesInPeriod.series.find((s) => s.name == seriesName);
				const seriesAllOverlays = currentSeries?.[overlayType];
				const matchingOverlay = seriesAllOverlays?.find((s) => s.name == currentOverlay);

				return {
					period: seriesInPeriod.period,
					value: matchingOverlay?.value,
				};
			};

			const allPeriodsOverlayValues = metricData.results.map(getOverlayValueByPeriod);

			return {
				name: currentOverlay,
				results: allPeriodsOverlayValues,
			};
		};
		return allDistinctOverlayNames.map(getOverlayResultsFromAllPeriods);
	}

	const defaultYAxis = metricData.info.op.op + metricData.info.unit;
	const orderedComponentsNames = (metricData.info.components ?? []).map((s) => s.legendName);
	const unorderedSeries = allDistinctSeriesNames.map((seriesName) => {
		const firstFittingSeries = allSeries.find((s) => s.name == seriesName) as MetricCalcSeriesResult;

		return {
			rawName: firstFittingSeries.rawName,
			name: seriesName || NOT_AVAILABLE_VALUE_STRING,
			type: firstFittingSeries.type,
			unit: firstFittingSeries.unit,
			op: firstFittingSeries.op,
			yAxis: firstFittingSeries.op ? firstFittingSeries.op?.op + (firstFittingSeries.unit ?? '') : defaultYAxis,
			seriesOrder: orderedComponentsNames.indexOf(seriesName),
			results: metricData.results.map((seriesInPeriod) => ({
				period: seriesInPeriod.period,
				value: seriesInPeriod.series.find((s) => s.name == seriesName)?.value,
				count: seriesInPeriod.series.find((s) => s.name == seriesName)?.count,
			})),
			statistics: getOverlaysForSeries(seriesName, 'statistics'),
			targets: getOverlaysForSeries(seriesName, 'targets'),
		};
	});

	return unorderedSeries.sort((seriesA, seriesB) => {
		if (seriesA.name == TotalSeriesName) return -1;
		if (seriesB.name == TotalSeriesName) return 1;

		if (seriesA.type == 'GROUP' && seriesB.type == 'GROUP') {
			return (seriesA.results[0].value ?? 0) - (seriesB.results[0].value ?? 0);
		}
		return 0;
	});
}

export type TransposedMetricData = ReturnType<typeof transposeMetricData>;

export type SeriesDataForChart = { unit?: MetricUnit; op?: MetricOperator } & TransposedMetricData[number] & {
		type: MetricResultSeriesType;
	};

const bizapiSeriesTypeToSeriesCustomField: { [key in MetricResultSeriesType]: SeriesCustomField } = {
	TOTAL: mainSeriesCustomField,
	COMPONENT: componentSeriesCustomField,
	GROUP: componentSeriesCustomField,
	STATISTIC: statisticSeriesCustomField,
};

export function buildChartSeriesFromData(
	series: SeriesDataForChart,
	chartType: ChartType,
	additionalChartOptions?: Partial<ChartSeries>
): ChartSeries {
	return {
		...additionalChartOptions,
		chartType: chartType,
		name: series.name,
		yAxis: series.yAxis,
		data: series.results.map((value) => ({
			name: value.period.pretty,
			y: value.value,
		})),
		custom: {
			unit: series.unit,
			op: series.op,
			seriesOrder: series.seriesOrder,
			rawName: series.rawName,
			...bizapiSeriesTypeToSeriesCustomField[series.type].custom,
		},
	};
}

export function extractFlavor(nameWithFlavor: string): [string, string | undefined] {
	const [, nameWithoutFlavor, , flavor] = nameWithFlavor.match(/^([^()]+)(\(([^)]+)\))?$/) ?? [];
	return [nameWithoutFlavor ?? nameWithFlavor, flavor];
}

export function formatMetricFullname(metricName: string, flavor?: string) {
	return flavor ? `${metricName} (${flavor})` : metricName;
}

export const getDimensionKey = (key: string): string => {
	const parsed = key.split('>');
	const newKey = parsed[parsed.length - 1];

	return newKey;
};

export function getFiltersForQuery(filters: Filter[]) {
	return filters.reduce((acc: FilterRequestsOptions, filter: Filter) => ({ ...acc, [filter.key]: filter.values }), {});
}
export function getSeriesInManualSortOrder(series?: ChartSeries[], orderedComponents?: string[]): ChartSeries[] {
	return series?.filter((s) => orderedComponents?.find((orderedComponent) => orderedComponent.includes(s.name))) ?? [];
}

export const xAxisFormatter = (xAxisLabel: MetricPeriod | string) => {
	if (typeof xAxisLabel === 'string') {
		return xAxisLabel;
	}

	return xAxisLabel.pretty;
};

export const getFormattingHandlerByName = (
	seriesName: string,
	chartOptions: ChartOptions,
	formatConfig: DataLabelFormatConfig
): SeriesDataPointYFormatter | undefined => {
	switch (seriesName) {
		case 'Average':
		case 'Delta': {
			const mainSeries = chartOptions.series.find((series) => series.custom.seriesType == 'main');
			return mainSeries?.custom?.seriesDataPointYFormatter;
		}
		case 'Growth':
			return (value: SeriesDataPointY) => percentageFormatter(value, formatConfig?.decimalDigits);

		default:
			break;
	}
};

export const getCheckedDisplayUnits = (displayUnits: MetricDerivedState['displayUnits']): DisplayUnitType[] => {
	return Object.entries(displayUnits)
		.filter((o) => !!o[1].value)
		.map((o) => o[0] as DisplayUnitType);
};

export type RawMetricMetadata = GetMetricByNameQuery['metrics'][number];
export type RawRelevantPeriodRanges = { [key in PeriodUnit]?: [string, string] };
export type ChartColoring = { mainSeriesColors: string[]; componentSeriesColors: string[] };
export interface RulesInput {
	metricNameWithoutFlavor: string;
	metricNameWithFlavor: string;
	metricFlavor?: string;
	searchParams: MetricSearchParams;
}
