import { Box, Flex } from '@chakra-ui/react';
import Progress from 'src/common/components/Progress';
import Typography from 'src/common/components/Typography';

export function EditorLoader({
	progress,
	maxWidth = 436,
	fullHeight,
}: {
	progress: number;
	maxWidth?: number;
	fullHeight?: boolean;
}) {
	return (
		<Flex height={fullHeight ? '100vh' : 'auto'} width={'100%'}>
			<Flex justifyContent={'center'} alignItems={'center'} width={'100%'} height={'80%'}>
				<Box maxW={maxWidth} width={'100%'}>
					<Typography marginBottom={'16px'} width={'100%'} color={'gray.1000'} variant="Paragraph20S" textAlign="start">
						Your changes are being applied...
					</Typography>
					<Progress colorScheme="blue.600" bgColor="gray.400" borderRadius="40px" h="8px" value={progress} />
				</Box>
			</Flex>
		</Flex>
	);
}
