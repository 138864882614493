import { Flex } from '@chakra-ui/react';
import * as CSS from 'csstype';
import Markdown from 'src/common/components/Markdown';
import Typography from 'src/common/components/Typography';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import { IBaseField } from '../../interfaces';

type Fields = {
	title?: { color?: CSS.Property.Color };
	subTitle?: { color?: CSS.Property.Color };
	longDescription?: { color?: CSS.Property.Color; _hover: { color: CSS.Property.Color } };
};

const disabledColor = colors.gray['500'];
const fieldCssProps: { true: Fields; false: Fields } = {
	true: {
		title: { color: disabledColor },
		subTitle: { color: disabledColor },
		longDescription: { color: disabledColor, _hover: { color: disabledColor } },
	},
	false: {
		title: { color: 'gray.800' },
		subTitle: { color: 'gray.700' },
		longDescription: { color: 'gray.700', _hover: { color: 'blue.600' } },
	},
};

export const BaseField = ({
	field,
	isDisabled,
	configName,
	onOpenLongDescription,
}: {
	configName: string;
	field: IBaseField;
	isDisabled: boolean;
	onOpenLongDescription: () => void;
}) => {
	const { wrapWithReport } = useReportEvent();
	const isDisabledString = String(isDisabled) as 'true' | 'false';
	const { title, subTitle, longDescription } = fieldCssProps[isDisabledString];

	return (
		<Flex direction="column" gap="4px">
			<Typography variant="DesktopH8Medium" textAlign="start" {...title}>
				{field.title}
			</Typography>
			<Typography variant="Paragraph12R" textAlign="start" maxWidth="560px" {...subTitle}>
				<Markdown message={field.subTitle ?? ''} />
				{field?.longDescription && (
					<Typography
						onClick={wrapWithReport(onOpenLongDescription, 'self-service-config-clicked-learn-more', {
							config: configName,
							field: field.title,
						})}
						pl={'4px'}
						variant="Link12R"
						as="a"
						{...longDescription}
						display="inline"
					>
						Learn more
					</Typography>
				)}
			</Typography>
		</Flex>
	);
};

export default BaseField;
