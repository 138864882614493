import Box from '@components/Box';
import { LEGEND_NAME_SQUARE_MARGIN, LEGEND_NAME_SQUARE_WIDTH } from '../../constants';

export function LegendColorSquare(props: { color: string | undefined }) {
	return (
		<Box
			minWidth={`${LEGEND_NAME_SQUARE_WIDTH}px`}
			height={`${LEGEND_NAME_SQUARE_WIDTH}px`}
			top="5px"
			backgroundColor={props.color}
			marginRight={`${LEGEND_NAME_SQUARE_MARGIN}px`}
			borderRadius="2px"
		/>
	);
}
