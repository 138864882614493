import { validate as uuidValidate } from 'uuid';

export const isTenantIDAutoGenerated = (tenantId: string | undefined) => {
	return uuidValidate(tenantId ?? '');
};

export const removeBadFronteggParamFromTenantID = (tenantId: string | undefined) => {
	// ?_tenant=1523645%3Fcode%3Dblablablabla%3D%3D
	const parsedTenantId = tenantId?.match(/^(.*?)\?/);
	return parsedTenantId ? parsedTenantId[1] : tenantId;
};
