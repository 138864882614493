import Flex from '@components/Flex';
import ResizableIcon from '@components/ResizableIcon';
import pluralize from 'pluralize';
import Badge from 'src/common/components/Badge';
import { ArrowTo16, SlidersVertical16 } from 'src/common/components/Icons';
import Popover from 'src/common/components/Popover';
import Typography from 'src/common/components/Typography';
import { useParameters } from '@services/useParameters';
import { Parameter } from '../../../../types/parameter';

function ParameterTooltipItem({ parameter }: { parameter: Parameter }) {
	const { getParameterDisplayName, getParameterDisplayValue } = useParameters();

	return (
		<Flex direction={'row'} gap="4px" alignItems={'center'}>
			<Typography variant="Paragraph12R" color="gray.700">
				{getParameterDisplayName(parameter.definition.name)}
			</Typography>
			<ArrowTo16 />
			<Typography variant="Disclaimer12SB" color="gray.800">
				{getParameterDisplayValue(parameter.definition.name)}
			</Typography>
		</Flex>
	);
}

export function ParametersBadge({ parameters }: { parameters: Parameter[] }) {
	const parameterTitle = `${pluralize('Parameter', parameters.length)} (${parameters.length}):`;
	return (
		<Popover
			triggerElement={
				<Badge
					icon={<ResizableIcon icon={SlidersVertical16} size={'12px'} />}
					iconColor="gray.700"
					color="transparent"
					hoverColor="blue.200"
					hoverIconColor="blue.700"
				/>
			}
			trigger="hover"
		>
			<Flex paddingX={'16px'} paddingY="10px" direction="column" gap="4px">
				<Typography variant="Paragraph12R" color={'gray.600'}>
					{parameterTitle}
				</Typography>
				<Flex direction={'column'} gap="2px">
					{parameters.map((parameter) => (
						<ParameterTooltipItem parameter={parameter} key={parameter.definition.name} />
					))}
				</Flex>
			</Flex>
		</Popover>
	);
}
