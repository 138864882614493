import { cssVar } from '@chakra-ui/react';

const $arrowBg = cssVar('popper-arrow-bg');

export default {
	baseStyle: {
		bg: 'gray.1100',
		// Bug with resolving color variables without `colors.` prefix.
		borderRadius: '4px',
		[$arrowBg.variable]: 'colors.gray.1100',
	},

	sizes: {
		sm: {
			px: '8px',
			py: '6px',
		},
		md: {
			px: '12px',
			py: '10px',
		},
	},
	variants: {
		primary: {
			maxWidth: '100vw',
		},
		fluid: {
			maxWidth: '256px',
		},
	},
};
