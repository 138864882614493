import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { InfoBanner } from 'src/common/components/MetricView/InteractiveMetricChat/InfoBanner';
import { ActionVariants, PromptActions } from 'src/common/components/MetricView/InteractiveMetricChat/normalizeHistory';
import { useSendMetricInteractive } from 'src/common/components/MetricView/InteractiveMetricChat/useSendMetricInteractive';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useToast from 'src/common/hooks/ui/useToast';
import { SendMetricInteractivePromptMutationVariables } from 'src/generated/graphql';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useReportEvent } from 'src/services/analytics';
import shadows from 'src/style/shadows';
import { ChatInput } from './ChatInput';
import { FloatingTriggerButton } from './components/FloatingTriggerButton';
import { Header } from './components/Header';
import { useInitiateMetricInteractiveSession } from './hooks/useInitiateMetricInteractiveSession';
import { useInitiateMetricInteractiveV2Session } from './hooks/useInitiateMetricInteractiveV2Session';
import { InteractiveChatMessages } from './InteractiveChatMessages';
import classes from './InteractiveMetricChat.module.scss';

type InteractiveMetricChatProps = {
	isGenAIPopoverOpen: boolean;
	setIsGenAIPopoverOpen: (value: boolean) => void;
	bottomBarGap?: number;
};

export default function InteractiveMetricChatGuard({
	isGenAIPopoverOpen,
	setIsGenAIPopoverOpen,
	bottomBarGap,
}: InteractiveMetricChatProps) {
	const isInteractiveMetricChatEnabled = useFeatureFlag('pulse.generative.interactiveMetric.enable');

	if (!isInteractiveMetricChatEnabled) return null;

	return (
		<InteractiveMetricChat
			isGenAIPopoverOpen={isGenAIPopoverOpen}
			setIsGenAIPopoverOpen={setIsGenAIPopoverOpen}
			bottomBarGap={bottomBarGap}
		/>
	);
}

function InteractiveMetricChat({
	isGenAIPopoverOpen,
	setIsGenAIPopoverOpen,
	bottomBarGap = 0,
}: InteractiveMetricChatProps) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	const { handleInitiateMetricInteractiveV2Session } = useInitiateMetricInteractiveV2Session();
	const { handleInitiateMetricInteractiveSession } = useInitiateMetricInteractiveSession();
	const {
		chartOptions: { xAxis },
		metricNameWithoutFlavor,
		flavor,
		breakdowns,
		periodRange,
		filters,
		metricDisplayName,
		metricExplanationOneliner,
	} = useMetricDerivedState();

	const [chatSessionId, setChatSessionId] = useState<string | undefined>(undefined);
	const [isSessionActive, setIsSessionActive] = useState(false);
	const inputRef = useRef<HTMLTextAreaElement>(null);
	const [sendMetricInteractivePrompt, { loading: isSendingPrompt }] = useSendMetricInteractive();
	const [inputValue, setInputValue] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	const isChatSessionActive = useMemo(() => {
		return !!chatSessionId;
	}, [chatSessionId]);

	const isReady = useMemo(
		() => isChatSessionActive && !isLoading && !isSendingPrompt,
		[isChatSessionActive, isLoading, isSendingPrompt]
	);

	const { reportEvent, wrapWithReport } = useReportEvent();
	const eventMetadata = useMemo(
		() => ({
			metric: metricNameWithoutFlavor,
			flavor: flavor?.selectedValue,
			breakdowns: breakdowns.values.map((e) => e.key),
			filterKeys: filters.map((e) => e.key),
			feature: 'Generative Virtual Assistant',
		}),
		[metricNameWithoutFlavor, flavor?.selectedValue, breakdowns.values, filters]
	);

	const breakdownsString = useMemo(() => breakdowns.values.map(({ key }) => key).join(','), [breakdowns.values]);
	const filtersString = useMemo(() => filters.map(({ key }) => key).join(','), [filters]);

	useEffect(() => {
		if (!isGenAIPopoverOpen || isChatSessionActive) {
			return;
		}

		const mutationHandler = isSightfull2
			? handleInitiateMetricInteractiveV2Session
			: handleInitiateMetricInteractiveSession;

		const fetchSessionId = async () => {
			try {
				const sessionId = await mutationHandler();
				setChatSessionId(sessionId);
			} catch (error) {
				console.error('Error initiating chat session:', error);
			}
		};

		fetchSessionId();
	}, [
		handleInitiateMetricInteractiveV2Session,
		handleInitiateMetricInteractiveSession,
		isChatSessionActive,
		isGenAIPopoverOpen,
		isSightfull2,
		breakdownsString,
		filtersString,
		flavor?.selectedValue,
		metricDisplayName,
		metricExplanationOneliner,
		metricNameWithoutFlavor,
		periodRange.asAbsoluteRange.actualDatesString,
	]);

	useEffect(() => {
		focusOnInput();
		const isRefreshingSession = chatSessionId && isGenAIPopoverOpen;
		if (isRefreshingSession) setIsLoading(true);
	}, [chatSessionId, isGenAIPopoverOpen]);

	useEffect(() => {
		setChatSessionId(undefined);
		setIsSessionActive(false);
	}, [
		periodRange.asAbsoluteRange.actualDatesString,
		breakdownsString,
		filtersString,
		xAxis,
		metricNameWithoutFlavor,
		flavor?.selectedValue,
		metricDisplayName,
		metricExplanationOneliner,
	]);
	const toast = useToast();

	const clearInput = () => setInputValue('');
	const onSubmit = (value: string) => {
		if (!isReady || value.trim() == '') return;

		setIsLoading(true);
		const variables: SendMetricInteractivePromptMutationVariables = {
			request_id: chatSessionId ?? '',
			user_prompt: value,
		};
		sendMetricInteractivePrompt(variables);
		clearInput();
		focusOnInput();
		setIsSessionActive(true);
	};

	const resetSession = () => {
		if (!isSessionActive) return;
		setChatSessionId(undefined);
		setIsSessionActive(false);
		setIsLoading(false);
	};

	const focusOnInput = () => inputRef?.current?.focus();

	const onTriggerClick = () => {
		setIsGenAIPopoverOpen(!isGenAIPopoverOpen);
	};

	const onClose = () => {
		setIsGenAIPopoverOpen(false);
		clearInput();
	};
	const onUpdateInputValue = (e: string) => {
		setInputValue(e);
	};

	if (!isGenAIPopoverOpen)
		return (
			<FloatingTriggerButton
				onClick={wrapWithReport(onTriggerClick, 'genai-chat-state-changed', { ...eventMetadata, action: 'open' })}
				bottomBarGap={bottomBarGap}
			/>
		);

	return (
		<Flex
			zIndex={2}
			className={[classes.gradientBackground, classes.chatShadows].join(' ')}
			borderRadius="4px"
			width="464px"
			position={'fixed'}
			bottom={`${32 + bottomBarGap}px`}
			right="32px"
			border="1px solid"
			borderColor="gray.300"
			direction={'column'}
			shadow={shadows['a-22']}
		>
			<Header
				onBackClick={
					isSessionActive
						? wrapWithReport(resetSession, 'genai-chat-state-changed', {
								...eventMetadata,
								action: 'reset-state',
								trigger: 'back-button',
						  })
						: undefined
				}
				onCloseClick={wrapWithReport(onClose, 'genai-chat-state-changed', { ...eventMetadata, action: 'close' })}
			/>
			{!isSessionActive && (
				<Box paddingX="12px">
					<InfoBanner
						onChipClick={(value: string, chipKind: ActionVariants, chipDisplayName: PromptActions) => {
							reportEvent({
								event: 'genai-chat-chip-click',
								metaData: { ...eventMetadata, chipKind, chipDisplayName },
							});
							onSubmit(value);
						}}
					/>
				</Box>
			)}
			{chatSessionId && (
				<ErrorBoundary FallbackComponent={() => <ErrorMessage />}>
					<InteractiveChatMessages
						onChipClick={(value: string, chipKind: ActionVariants, chipDisplayName: PromptActions) => {
							reportEvent({
								event: 'genai-chat-chip-click',
								metaData: { ...eventMetadata, chipKind, chipDisplayName },
							});
							onSubmit(value);
						}}
						onCopyClick={(index: number) =>
							reportEvent({ event: 'genai-chat-copy-click', metaData: { ...eventMetadata, indexFromLast: index } })
						}
						onDislikeClick={(index: number) => {
							reportEvent({
								event: 'genai-chat-feedback',
								metaData: {
									...eventMetadata,
									kind: 'negative',
									indexFromLast: index,
								},
							});
							toast({ variant: 'ok', message: 'Feedback sent' });
						}}
						onLikeClick={(index: number) => {
							reportEvent({
								event: 'genai-chat-feedback',
								metaData: {
									...eventMetadata,
									kind: 'positive',
									indexFromLast: index,
								},
							});
							toast({ variant: 'ok', message: 'Feedback sent' });
						}}
						setLoadingStatus={setIsLoading}
						chatSessionId={chatSessionId}
					/>
				</ErrorBoundary>
			)}
			<Flex margin="12px 12px 12px 12px" direction="column">
				<ChatInput
					inputRef={inputRef}
					inputValue={inputValue}
					onUpdateInputValue={onUpdateInputValue}
					onSubmit={(trigger) =>
						wrapWithReport(onSubmit, 'genai-chat-input-submit', {
							...eventMetadata,
							trigger,
							isButtonEnabled: isReady,
						})
					}
					isEnabled={isReady}
				/>
			</Flex>
		</Flex>
	);
}

function ErrorMessage() {
	return (
		<Box py="8px" px="16px">
			An error occurred, please retry
		</Box>
	);
}
