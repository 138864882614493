import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';
import Typography from '@components/Typography';
import * as CSS from 'csstype';
import { ReactNode } from 'react';
import { TypographyVariant } from '../Typography/types';
import { ResponsiveValue } from '@chakra-ui/styled-system';
import { CheckboxCheck10, CheckboxSquare16 } from '../Icons';

export type CheckboxSize = 'md' | 'sm';

export interface CheckboxProps {
	testId?: string;
	label?: string | ReactNode;
	isDisabled?: boolean;
	isChecked?: boolean; // providing this makes this controlled component! amazing
	defaultChecked?: boolean;
	isIntermediate?: boolean;
	isInvalid?: boolean;
	checkboxColor?: CSS.Property.Color;
	onChange?: (isChecked: boolean) => void;
	size?: CheckboxSize;
	mr?: CSS.Property.MarginRight;
	textAlign?: CSS.Property.TextAlign;
	pointerEvents?: ResponsiveValue<CSS.Property.PointerEvents>;
	isFluid?: boolean;
	labelColor?: CSS.Property.Color;
}

function CustomIcon({ isIntermediate, isChecked }: Pick<CheckboxProps, 'isChecked' | 'isIntermediate'>) {
	if (!isChecked && !isIntermediate) return <></>;

	return isIntermediate ? <CheckboxSquare16 /> : <CheckboxCheck10 />;
}

export function Checkbox({
	label,
	testId,
	isInvalid,
	isDisabled = false,
	isChecked = false,
	isIntermediate = false,
	onChange,
	checkboxColor = 'white',
	size = 'md',
	mr,
	textAlign,
	pointerEvents = 'auto',
	isFluid,
	labelColor,
}: CheckboxProps) {
	const textVariantBySize: { [key in CheckboxSize]: TypographyVariant } = {
		md: 'DesktopH7Regular',
		sm: 'DesktopH8Regular',
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();
		e.preventDefault();

		onChange?.(e.target.checked);
	};

	return (
		<ChakraCheckbox
			className={isFluid ? 'isFluid' : ''}
			isChecked={isChecked}
			isDisabled={isDisabled}
			isIndeterminate={isIntermediate}
			isInvalid={isInvalid}
			data-testid={testId}
			variant="primary"
			colorScheme={checkboxColor}
			spacing="8px"
			onChange={handleChange}
			size={size}
			iconSize="10px"
			mr={mr}
			textAlign={textAlign}
			pointerEvents={pointerEvents}
			icon={<CustomIcon isIntermediate={isIntermediate} isChecked={isChecked} />}
		>
			{label && (
				<Typography variant={textVariantBySize[size]} noOfLines={1} textOverflow="ellipsis" color={labelColor}>
					{label}
				</Typography>
			)}
		</ChakraCheckbox>
	);
}
