import Box from '@components/Box';
import Typography from 'src/common/components/Typography';

export function JoinKeysTooltip() {
	return (
		<Box>
			<Typography color={'white'} variant="Paragraph14R">
				Set up the logic to connect a row of the entity to a row of the referenced entity.
			</Typography>
		</Box>
	);
}
