import { Box, ModalHeader } from '@chakra-ui/react';
import Typography from '@components/Typography';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useMemo, useState } from 'react';
import Button from 'src/common/components/Button';
import Flex from 'src/common/components/Flex';
import { CloseLarge16 } from 'src/common/components/Icons';
import Modal from 'src/common/components/Modal';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import colors from 'src/style/colors';
import { CreateNewMetricPagePath } from '../CreateNewMetricPage';
import { CREATE_METRIC_PAGE, DEFAULT_METRIC_TYPE, METRIC_COPIES } from '../copy';
import { NewMetricCard, NewMetricType } from './index';

type ModalProps = {
	isOpen: boolean;
	onClose: VoidFunction;
};

export const CreateNewMetricModal = ({ isOpen, onClose }: ModalProps) => {
	const { navigate } = useNavigation();
	const [selectedMetric, setSelectedMetric] = useState<string>(DEFAULT_METRIC_TYPE);
	const [isSecondStep, setIsSecondStep] = useState(true);
	const { wrapWithReport } = useReportEvent({ metricType: selectedMetric, feature: 'Metric Builder' });
	const isCreateSightfullAIMetricOptionEnabled = useFeatureFlag(
		'pulse.sightfull2.createSightfullAIMetricOption.enable'
	);

	const closeModal = () => {
		onClose();
		setSelectedMetric(DEFAULT_METRIC_TYPE);
		// setIsSecondStep(false); TODO: enamble when add a template mode
	};

	const onSubmit = () =>
		navigate({
			path: `${CreateNewMetricPagePath}/${selectedMetric}`,
		});

	const MetricComponent = useMemo(() => {
		const metricOptions = isCreateSightfullAIMetricOptionEnabled ? METRIC_COPIES : [METRIC_COPIES[0], METRIC_COPIES[1]];

		return metricOptions.map((metric) => {
			const metricKind = metric.type;
			return (
				<NewMetricType
					// TODO: remove when AI page is enabled
					key={metricKind || 'ai'}
					onClick={() => metricKind && setSelectedMetric(metricKind)}
					isSelected={selectedMetric === metricKind}
					type={metricKind}
					isComingSoon={metric.isComingSoon}
				/>
			);
		});
	}, [selectedMetric, isCreateSightfullAIMetricOptionEnabled]);

	function onSubmitClick() {
		if (isSecondStep) {
			onSubmit();
			return;
		}
		setIsSecondStep(true);
	}

	return (
		<Modal
			maxWidth="640px"
			isOpen={isOpen}
			onClose={wrapWithReport(closeModal, 'create-new-metric-type-modal', { action: 'closed' })}
			closeOnOverlayClick={false}
			isCentered
		>
			<div data-intercom-area={'main'} data-intercom-type={'main'} data-intercom-target={'create-new-metric-modal'}>
				<ModalHeader boxShadow={'borderBottom'} p="16px">
					<Flex textAlign={'center'} justifyContent="space-between" alignItems="center">
						<Button
							isIconOnly
							variant="outline"
							onClick={wrapWithReport(closeModal, 'create-new-metric-type-modal', { action: 'x' })}
							size="inline"
							colorScheme="black"
						>
							<CloseLarge16 color={colors.gray[900]} />
						</Button>

						<Box margin={'0 auto'}>
							<Typography color={'gray.1100'} marginLeft={'-32px'} variant="DesktopH7Medium">
								Create new metric
							</Typography>
						</Box>
					</Flex>
				</ModalHeader>
				{isSecondStep ? (
					<Flex width={'100%'} gap={'12px'} flexDirection={'column'} boxShadow={'borderBottom'} padding={'24px'}>
						{MetricComponent}
					</Flex>
				) : (
					<Flex
						width={'100%'}
						gap={'16px'}
						justifyContent={'space-between'}
						boxShadow={'borderBottom'}
						padding={'24px'}
					>
						<NewMetricCard
							onClick={() => setSelectedMetric(CREATE_METRIC_PAGE[0])}
							isSelected={selectedMetric === CREATE_METRIC_PAGE[0]}
							type={CREATE_METRIC_PAGE[0]}
						/>
						<NewMetricCard
							isDisabled
							onClick={() => setSelectedMetric(CREATE_METRIC_PAGE[1])}
							isSelected={selectedMetric === CREATE_METRIC_PAGE[1]}
							type={CREATE_METRIC_PAGE[1]}
						/>
					</Flex>
				)}
				<Flex py="16px" px="24px" justifyContent={'space-between'}>
					<Button
						color={'gray.1000'}
						size={'medium'}
						colorScheme="lightGray"
						variant="ghost"
						onClick={wrapWithReport(closeModal, 'create-new-metric-type-modal', { action: 'cancel' })}
					>
						Cancel
					</Button>
					<Button
						data-intercom-area={'catalog'}
						data-intercom-type={'button'}
						data-intercom-target={'create-new-metric-button'}
						isDisabled={!selectedMetric}
						size={'medium'}
						variant="solid"
						colorScheme={selectedMetric ? 'blue' : 'black'}
						onClick={wrapWithReport(onSubmitClick, 'create-new-metric-type-modal', {
							action: isSecondStep ? 'create' : 'start',
						})}
					>
						{isSecondStep ? 'Create' : 'Start'}
					</Button>
				</Flex>
			</div>
		</Modal>
	);
};
