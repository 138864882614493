import concat from 'lodash/concat';
import difference from 'lodash/difference';
import { ChartOptions, ChartSeries, SeriesType } from 'src/common/components/Chart/types';
import { getSeriesInManualSortOrder } from 'src/lib/metricRules/utils';
import { AutomaticSortOrder, isAutomaticSortOrder } from 'src/pages/MetricPage/components/LegendsPanel/types';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { MetricCalcInfo, MetricCalcResult, TotalSeriesName } from 'src/types/metric';
import { MetricVizRulesInput, runVisualisationRules } from '../metricVisualisationRules';

export function calcChartOptionsSeries(
	metricData: MetricCalcResult,
	{
		metricOperator,
		periodRange,
		hasComponents,
		hasGroupBy,
		isAllComponentSameUnit,
		metricNameWithFlavor,
		metricDisplayName,
		sortOrder,
	}: Pick<
		MetricDerivedState,
		| 'metricOperator'
		| 'periodRange'
		| 'hasComponents'
		| 'hasGroupBy'
		| 'isAllComponentSameUnit'
		| 'metricNameWithFlavor'
		| 'metricDisplayName'
		| 'flavor'
		| 'sortOrder'
	>,
	orderedComponents?: string[]
): { chartOptions: ChartOptions } {
	const vizRulesInput: MetricVizRulesInput = {
		metricNameWithFlavor,
		hasComponents,
		hasGroupBy,
		op: metricOperator,
		isSinglePeriod: periodRange.isSinglePeriod,
		isAllComponentSameUnit: isAllComponentSameUnit,
		metricDisplayName,
	};

	const series = runVisualisationRules({ metricData, vizRulesInput });
	const withNamedMainSeries = nameMainSeries(metricDisplayName, series);

	const seriesWithVisibility = withNamedMainSeries.map(setSeriesVisibilityTrue);

	const getOrderedSeries = (series: ChartSeries[]): ChartSeries[] => {
		const componentsSeries = series.filter((s) => s.custom.seriesType != 'main');
		const mainSeries = series.filter((s) => s.custom.seriesType == 'main');
		if (orderedComponents?.length) {
			return manualOrder(series, orderedComponents);
		}
		if (isAutomaticSortOrder(sortOrder.selectedValue)) {
			return OrderNameToOrderFunc[sortOrder.selectedValue](componentsSeries).concat(mainSeries);
		}

		const componentsWithDefaultOrder = defaultComponentsSortOrder(componentsSeries, metricData.info);
		return componentsWithDefaultOrder.concat(mainSeries);
	};

	const seriesWithSortOrder = getOrderedSeries(seriesWithVisibility).map((s, index) => {
		s.custom.seriesOrder = index;
		return s;
	});

	return {
		chartOptions: {
			series: seriesWithSortOrder,
		} as ChartOptions,
	};
}

export function nameMainSeries(mainSeriesName: string, series: ChartSeries[]): ChartSeries[] {
	if (!series) return [];

	const nameMainSeries = (s: ChartSeries) =>
		s.name != TotalSeriesName
			? s
			: { ...s, name: mainSeriesName, custom: { ...s.custom, seriesType: 'main' as SeriesType } };

	return series.map(nameMainSeries);
}

const setSeriesVisibilityTrue = (series: ChartSeries): ChartSeries => ({
	...series,
	visible: true,
});

export function manualOrder(series: ChartSeries[], orderedComponents: string[]): ChartSeries[] {
	const sortByOrderedComponents = (a: ChartSeries, b: ChartSeries) => {
		const aIndex = orderedComponents.findIndex((comp) => comp == a.name);
		const bIndex = orderedComponents.findIndex((comp) => comp == b.name);
		return aIndex - bIndex;
	};
	const seriesInOrderedComponents = getSeriesInManualSortOrder(series, orderedComponents);
	const orderedSeries = seriesInOrderedComponents.sort(sortByOrderedComponents);

	const notInOrderedComponents = difference(series, seriesInOrderedComponents);
	return concat(orderedSeries, notInOrderedComponents);
}

function labelAToZOrder(series: ChartSeries[]): ChartSeries[] {
	return series.sort((a, b) => {
		return a.name.localeCompare(b.name);
	});
}

function labelZToAOrder(series: ChartSeries[]): ChartSeries[] {
	return labelAToZOrder(series).reverse();
}

function valueAscendingOrder(series: ChartSeries[]): ChartSeries[] {
	return series.sort((a, b) => {
		return (a.data[0]?.y ?? 0) - (b.data[0]?.y ?? 0);
	});
}
function valueDescendingOrder(series: ChartSeries[]): ChartSeries[] {
	return valueAscendingOrder(series).reverse();
}

export function defaultComponentsSortOrder(series: ChartSeries[], metricDataInfo: MetricCalcInfo): ChartSeries[] {
	const getSeriesIndexInDefaultSortOrder = (currentSeries: ChartSeries) => {
		return metricDataInfo.components?.findIndex((component) => component.legendName == currentSeries.name) ?? 0;
	};

	return series.sort((a, b) => {
		return getSeriesIndexInDefaultSortOrder(a) - getSeriesIndexInDefaultSortOrder(b);
	});
}

export const OrderNameToOrderFunc: { [key in AutomaticSortOrder]: (series: ChartSeries[]) => ChartSeries[] } = {
	'Label A to Z': labelAToZOrder,
	'Label Z to A': labelZToAOrder,
	'Value small to large': valueAscendingOrder,
	'Value large to small': valueDescendingOrder,
};
