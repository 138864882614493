import { useState } from 'react';
import Button from 'src/common/components/Button';
import { AddSmall16 } from 'src/common/components/Icons';
import { useShareDividerWidgetApi, useShareTextWidgetApi } from 'src/common/hooks/useShareSignalApi';
import { useReportEvent } from 'src/services/analytics';
import { WidgetType } from '../../types/DashboardFeedSignal';
import { HeaderProps } from '../types';
import { AddWidgetPopover } from './AddWidgetPopover';

export function AddWidgetButton({
	dashboard: { id },
	isTiny,
	onAddedWidget,
}: Pick<HeaderProps, 'isTiny' | 'onAddedWidget' | 'dashboard'>) {
	const { reportEvent } = useReportEvent();
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [shareTextWidget, { isLoading: isShareTextWidgetLoading }] = useShareTextWidgetApi();
	const [shareDividerWidget, { isLoading: isShareDividerWidgetLoading }] = useShareDividerWidgetApi();
	const isLoading = isShareDividerWidgetLoading || isShareTextWidgetLoading;

	const onToggleDialog = () => {
		setIsDialogOpen((s) => !s);
	};

	const upsertByWidgetType = (widgetType?: WidgetType): (() => Promise<void>) | undefined => {
		if (widgetType == 'text') {
			return addTextWidget;
		} else if (widgetType == 'divider') {
			return addDividerWidget;
		}
	};

	const onDialogClosed = (widgetType?: WidgetType) => {
		setIsDialogOpen(false);
		upsertByWidgetType(widgetType)?.()?.then(onAddedWidget);
	};

	const addTextWidget = async () => {
		reportEvent({ event: 'dashboard-add-text-widget', metaData: { 'dashboard-id': id } });
		return shareTextWidget({
			collectionToPin: [id],
			collectionToUnPin: [],
			message: '',
		});
	};
	const addDividerWidget = async () => {
		reportEvent({ event: 'dashboard-add-divider-widget', metaData: { 'dashboard-id': id } });
		return shareDividerWidget({
			collectionToPin: [id],
			collectionToUnPin: [],
		});
	};

	const trigger = isTiny ? (
		<Button isIconOnly variant="outline" onClick={onToggleDialog} size="inline" colorScheme="black">
			<AddSmall16 />
		</Button>
	) : (
		<Button
			colorScheme={'black'}
			variant="outline"
			size="inline"
			leftIcon={<AddSmall16 />}
			onClick={onToggleDialog}
			isLoading={isLoading}
			isActive={isDialogOpen}
		>
			Add Widget
		</Button>
	);

	return (
		<AddWidgetPopover isModalOpen={isDialogOpen} onCloseDialog={onDialogClosed}>
			{trigger}
		</AddWidgetPopover>
	);
}
