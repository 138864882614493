import { DataConnectorInfo } from '@components/TopNavigation/DataConnectorsModal/types';
import { DataConnectorCard } from '@components/TopNavigation/DataConnectorsModal/DataConnectorCard';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import colors from 'src/style/colors';
import { useMemo } from 'react';

export function DataConnectorsSection({
	title,
	dataConnectorInfos,
	selectedConnector,
	onSelectConnector,
}: {
	title: string;
	dataConnectorInfos: DataConnectorInfo[];
	selectedConnector: DataConnectorInfo | null;
	onSelectConnector: (connectorInfo: DataConnectorInfo) => void;
}) {
	const connectorsCards = useMemo(() => {
		return dataConnectorInfos.map((connectorInfo: DataConnectorInfo, index) => {
			return (
				<DataConnectorCard
					key={index}
					name={connectorInfo.name}
					icon={connectorInfo.icon}
					onClick={() => {
						onSelectConnector(connectorInfo);
					}}
					isSelected={selectedConnector?.name === connectorInfo.name}
				/>
			);
		});
	}, [dataConnectorInfos, onSelectConnector, selectedConnector]);

	const isWithTitle = title !== '';
	return (
		<Flex flexDirection={'column'} alignItems={'flex-start'} gap={'12px'}>
			{isWithTitle && (
				<Typography variant={'DesktopH8Medium'} textAlign={'center'} color={colors.gray['1000']}>
					{title}
				</Typography>
			)}
			<Flex alignItems={'center'} gap={'16px'} alignSelf={'stretch'} flexWrap={'wrap'}>
				{connectorsCards}
			</Flex>
		</Flex>
	);
}
