const MetricOperators = ['#', '+', '/', '*', 'avg', 'funnel', 'concat'] as const;
export type MetricOp = typeof MetricOperators[number];

export class MetricOperator {
	readonly op: MetricOp;

	constructor(op: MetricOp) {
		if (!MetricOperators.includes(op)) throw Error('MetricOperators does not include ' + JSON.stringify(op));
		this.op = op;
	}

	get isConcatable() {
		return this.op === 'concat' || this.op === 'funnel';
	}

	get isStackable() {
		return this.op === '#' || this.op === '+';
	}
}

export const CountOp = new MetricOperator('#');
export const SumOp = new MetricOperator('+');
export const DivideOp = new MetricOperator('/');
export const MultiplyOp = new MetricOperator('*');
export const AverageOp = new MetricOperator('avg');
export const FunnelOp = new MetricOperator('funnel');
export const ConcatOp = new MetricOperator('concat');
