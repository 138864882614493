import { Property } from '../../types';

export const formatNumber = (valueAsString: any): number => {
	const value = Number(valueAsString);
	const isInt = value % 1 === 0;
	return isInt ? value : Number(parseFloat(valueAsString).toFixed(2));
};

export const getLeadTextValue = ({
	value,
	overrideProps,
}: Pick<Property, 'overrideProps' | 'value'>): string | number => {
	const { source_value, value: overrideValue } = overrideProps || {};
	return overrideValue ?? source_value ?? value;
};

export const getSourceValue = ({ value, overrideProps }: Pick<Property, 'overrideProps' | 'value'>): string | number =>
	overrideProps?.source_value ?? value;
