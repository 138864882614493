import { Box } from '@chakra-ui/react';
import { ColDef, GridReadyEvent, IHeaderParams, ShouldRowBeSkippedParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAtomValue } from 'jotai';
import isEqual from 'lodash/isEqual';
import sumBy from 'lodash/sumBy';
import { useCallback, useRef, useState } from 'react';
import 'src/common/components/Table/Table.scss';
import { sideBarConfig, useDefaultColDef, useMenuItemsParams } from 'src/common/components/Table/config';
import useTenantState from 'src/common/hooks/ui/useTenantState';
import { Filter } from 'src/generated/graphql';
import FiltersAndBreakdownsModal from 'src/pages/MetricPage/components/FiltersAndBreakdown/FiltersAndBreakdownsModal';
import {
	convertModalResultToValidCoreValues,
	useCoreNodeScheme,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { COLUMN_MODEL_TYPE, FILTER_MODEL_TYPE } from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import { FiltersAndBreakdownResponseType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import useFiltersAndBreakdown from 'src/pages/MetricPage/components/FiltersAndBreakdown/useFiltersAndBreakdown';
import { useReportEvent } from 'src/services/analytics';
import { OntologyStateAtomDerived } from '../../atoms/OntologyState';
import { TABLE_LIMIT } from '../../utils/consts';
import { ColumnsAndFilters } from '../../utils/types';
import ColumnHeader from './ColumnHeader';
import { TableHeader } from './TableHeader';

const TRIAL_LIMIT = 25;
const LIMITED_EXPORT_MSG =
	'Export is limited to 25 lines during the trial period. Please upgrade to access the full data.';

type OntologyTableParams = {
	data: Record<string, any>[];
	columnDefs?: ColDef[];
	isErrorActive?: boolean;
	errorMessage?: string;
	columnsAndFilters: ColumnsAndFilters;
	setColumnsAndFilters: (val: ColumnsAndFilters) => void;
	entityName: string;
	onRowClicked?: (recordId: string) => void;
};

const OntologyTable = ({
	data,
	columnDefs,
	isErrorActive,
	errorMessage,
	columnsAndFilters,
	setColumnsAndFilters,
	entityName,
	onRowClicked,
}: OntologyTableParams) => {
	const tableRef = useRef<AgGridReact>(null);
	const tableWrapperRef = useRef<HTMLDivElement>(null);
	const defaultColDef = useDefaultColDef(true, true);
	const { reportEvent } = useReportEvent();
	const [isScrollVisible, setIsScrollVisible] = useState<boolean>(false);
	const [modalState, modalActions] = useFiltersAndBreakdown();
	const boxPaddingRight = isScrollVisible ? '0' : '24px';
	const { additionalColumns, filter } = columnsAndFilters;

	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const { shouldLimitExports } = useTenantState();
	const exportTableDataAsCSV = () => {
		const shouldRowBeSkipped = shouldLimitExports
			? (params: ShouldRowBeSkippedParams) => !!params.node.rowIndex && params.node.rowIndex >= TRIAL_LIMIT
			: undefined;

		tableRef.current?.api?.exportDataAsCsv?.({
			shouldRowBeSkipped,
			appendContent: shouldLimitExports ? LIMITED_EXPORT_MSG : undefined,
		});
	};

	const onGridSizeChange = (gridEvent: GridReadyEvent) => {
		checkHorizontalScrollVisible(gridEvent);
	};

	const checkHorizontalScrollVisible = (gridEvent: GridReadyEvent) => {
		if (tableWrapperRef && tableWrapperRef.current) {
			const tableWrapperWidth = tableWrapperRef.current.clientWidth;
			const actualTableWidth = sumBy(gridEvent.columnApi.getAllDisplayedColumns(), function (column) {
				return column.getActualWidth();
			});
			const isScrollVisible = actualTableWidth > tableWrapperWidth;
			setIsScrollVisible(isScrollVisible);

			if (!isScrollVisible) gridEvent.api.sizeColumnsToFit();
		}
	};

	const onBtNext = useCallback(() => {
		tableRef?.current?.api.paginationGoToNextPage();
	}, []);
	const onBtPrevious = useCallback(() => {
		tableRef?.current?.api.paginationGoToPreviousPage();
	}, []);

	const handleAddColumns = (result: FiltersAndBreakdownResponseType) => {
		if (!result?.items?.length) return;
		const columns = getColumns(result);
		if (isEqual(additionalColumns, columns)) return;
		setColumnsAndFilters({ ...columnsAndFilters, additionalColumns: [...additionalColumns, ...columns] });
	};

	const handleAddFilters = (result: FiltersAndBreakdownResponseType) => {
		const { items } = result;
		if (!items.length) return;
		const columns = getColumns(result);
		const newFilter: Filter = {
			key: columns?.[0],
			values: items.map((i) => `${i.value}`),
		};
		if (filter.some((element) => element.key === newFilter.key)) {
			setColumnsAndFilters({
				...columnsAndFilters,
				filter: [...filter.filter((el) => el.key !== newFilter.key), newFilter],
			});
		} else {
			setColumnsAndFilters({ ...columnsAndFilters, filter: [...filter, newFilter] });
		}
	};

	const getColumns = (result: FiltersAndBreakdownResponseType) =>
		convertModalResultToValidCoreValues(result, entityName).map((e) => e.key);

	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes: ontologyState.loading ? [] : [ontologyState.entityName],
		schemeType: 'global',
		fetchPolicy: 'no-cache',
		readyToFetch: !ontologyState.loading,
	});

	return (
		<Box className="tableContainer">
			<Box paddingX={'24px'}>
				<TableHeader
					errorMessage={errorMessage}
					isErrorActive={isErrorActive}
					onNext={onBtNext}
					onPrevious={onBtPrevious}
					totalCount={data.length}
					filter={filter}
					exportTableDataAsCSV={exportTableDataAsCSV}
					onAddColumn={() => {
						reportEvent({
							event: 'entity-add-column-clicked',
							metaData: { feature: 'Entity table', entity: entityName },
						});
						modalActions.onOpen(COLUMN_MODEL_TYPE, handleAddColumns);
					}}
					onAddFilter={() => {
						reportEvent({
							event: 'entity-filters-clicked',
							metaData: { feature: 'Entity table', entity: entityName },
						});
						modalActions.onOpen(FILTER_MODEL_TYPE, handleAddFilters);
					}}
					onRemoveFilter={(key: string) => {
						setColumnsAndFilters({
							...columnsAndFilters,
							filter: filter.filter((el) => el.key !== key),
						});
					}}
				/>
			</Box>

			<Box
				paddingBottom={0}
				paddingX={'24px'}
				paddingRight={boxPaddingRight}
				ref={tableWrapperRef}
				flexGrow={1}
				className={`tableWrapper hoveredRow ${isScrollVisible ? 'pinnedCell' : ''}`}
			>
				<AgGridReact
					debounceVerticalScrollbar
					className="ag-theme-alpine"
					ref={tableRef}
					rowHeight={48}
					headerHeight={40}
					rowData={data}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					autoGroupColumnDef={columnDefs?.find((cd) => cd.rowGroup)}
					onGridSizeChanged={onGridSizeChange}
					getContextMenuItems={useMenuItemsParams(filter, (filterObj: { key: string; value: string }) => {
						reportEvent({
							event: 'add-filter-from-table',
							metaData: {
								key: filterObj?.key,
								value: filterObj?.value,
								entity: entityName,
							},
						});
						return setColumnsAndFilters({
							...columnsAndFilters,
							filter: [...filter, { key: filterObj?.key, values: [filterObj?.value] }],
						});
					})}
					sideBar={sideBarConfig}
					rowSelection="multiple"
					suppressFieldDotNotation
					components={{
						agColumnHeader: (props: IHeaderParams) => (
							<ColumnHeader
								columnObj={props}
								setAdditionalColumns={(additionalColumns) =>
									setColumnsAndFilters({ ...columnsAndFilters, additionalColumns })
								}
								additionalColumns={additionalColumns}
							/>
						),
					}}
					pagination
					paginationPageSize={TABLE_LIMIT}
					suppressPaginationPanel
					onRowClicked={(event) => {
						onRowClicked?.(event?.data?._row_id);
					}}
				/>
			</Box>
			<FiltersAndBreakdownsModal
				type={modalState.type}
				isOpen={modalState.isOpen}
				onClose={modalActions.onClose}
				onAddItems={modalActions.onAddItems}
				nodeScheme={coreNodeScheme}
			/>
		</Box>
	);
};

export default OntologyTable;
