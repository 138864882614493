import { Flex } from '@chakra-ui/react';
import { Search32 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';

export function NoResultsSearch() {
	return (
		<Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'}>
			<Flex alignItems={'center'} flexDirection={'column'} width={'220px'}>
				<Search32 />
				<Typography
					marginTop={'16px'}
					marginBottom={'8px'}
					color={'gray.1000'}
					variant="Paragraph18S"
					textAlign="center"
				>
					No results found.
				</Typography>
				<Typography color={'gray.1000'} variant="Paragraph12R" textAlign="center">
					{"Try adjusting your search to find what you're looking for."}
				</Typography>
			</Flex>
		</Flex>
	);
}
