import Box from '@components/Box';
import Flex from '@components/Flex';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { useOnOverflow } from '@hooks/ui/useOnOverflow';
import { ReactNode, useRef, useState } from 'react';

export interface ListHeaderProps {
	title: string;
	description?: ReactNode;
	icon?: ReactNode;
	suffixIcon?: ReactNode;
	cardActions: ReactNode;
}

export default function ItemCard({ title, description, icon, suffixIcon, cardActions }: ListHeaderProps) {
	const titleRef = useRef(null);
	const descriptionRef = useRef(null);
	const isOverflowingDescription = useOnOverflow(descriptionRef, [description], undefined, false);
	const isOverflowingTitle = useOnOverflow(titleRef, [title], undefined, false);
	const [isHovered, setIsHovered] = useState(false);
	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);

	return (
		<Flex
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			minH={'78px'}
			color={'gray.1000'}
			padding={'16px'}
			paddingTop={'13px'}
			borderRadius={'8px'}
			marginBottom={'8px'}
			border={'1px solid'}
			borderColor={'gray.300'}
			alignItems={'center'}
			_hover={{ borderColor: 'gray.400' }}
			cursor={'pointer'}
			transition={'0.2s'}
		>
			<Box marginRight={'16px'}>{icon}</Box>
			<Flex width={'100%'} flexDirection={'column'}>
				<Flex
					marginBottom={'4px'}
					width={'100%'}
					alignContent={'center'}
					alignItems={'center'}
					justifyContent={'space-between'}
				>
					<Tooltip size="md" variant="fluid" label={isOverflowingTitle && title}>
						<Flex alignContent={'center'} alignItems={'center'}>
							<Typography
								wordBreak={'break-all'}
								cursor={'pointer'}
								noOfLines={1}
								variant="DesktopH8Medium"
								color={'gray.1000'}
							>
								<Box ref={titleRef}>{title}</Box>
							</Typography>
							<Box marginX={'10px'}>{suffixIcon}</Box>
						</Flex>
					</Tooltip>
					<Box transition={'0.2s'} opacity={isHovered ? '1' : '0'} visibility={isHovered ? 'visible' : 'hidden'}>
						{cardActions}
					</Box>
				</Flex>
				{description && (
					<Tooltip size="md" variant="fluid" label={isOverflowingDescription && description}>
						<Typography cursor={'pointer'} noOfLines={1} variant="DesktopH10Regular" color={'gray.900'}>
							<Box ref={descriptionRef}>{description}</Box>
						</Typography>
					</Tooltip>
				)}
			</Flex>
		</Flex>
	);
}
