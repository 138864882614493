import Flex from '@components/Flex';
import { LicenseManager } from 'ag-grid-enterprise';
import { Provider } from 'jotai';
import { GridTableAtom, GridTableScope } from './GridTableHooks';

LicenseManager.setLicenseKey(
	'CompanyName=Sightfull,LicensedApplication=Sightfull,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=1,AssetReference=AG-035571,SupportServicesEnd=7_December_2023_[v2]_MTcwMTkwNzIwMDAwMA==319ff73ff423768b5f03773a6a9f3e03'
);

export type IntercomData = {
	area: string;
	type: string;
	target: string;
};

export type GridTableProps = {
	padding?: string;
	children: React.ReactNode[] | React.ReactNode;
	intercom?: IntercomData;
};

export function GridTable({ padding = '0', intercom, children }: GridTableProps) {
	return (
		<Provider scope={GridTableScope} initialValues={[[GridTableAtom, {}]]}>
			<Flex flexGrow={1} padding={padding} flexDirection={'column'} width={'100%'} height={'100%'} {...intercom}>
				{children}
			</Flex>
		</Provider>
	);
}
