import { ChartType } from 'src/common/components/Chart/types';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export function calcCoreAvailableChartTypes(
	availableChartTypes: ChartType[],
	{ chartOptions }: Pick<MetricDerivedState, 'chartOptions'>,
	urlChartType?: MetricSearchParams['chartType']
): Pick<MetricDerivedState, 'chartType' | 'isTableView'> {
	const chartTypeFromVizRules =
		chartOptions.series.find((s) => s.custom.seriesType == 'component' && s.chartType)?.chartType ??
		chartOptions.series.at(0)?.chartType;
	if (!chartTypeFromVizRules) {
		console.warn('no chart type found, using column');
	}
	const chartTypeFromEngine = chartTypeFromVizRules ?? 'column';

	const isTableView = urlChartType == 'table';

	const isOverrideChartTypeAllowed = urlChartType && !isTableView && availableChartTypes.includes(urlChartType);

	const selectedChartType = urlChartType && isOverrideChartTypeAllowed ? urlChartType : chartTypeFromEngine;

	return {
		chartType: { selectedValue: selectedChartType, optionalValues: [...new Set(availableChartTypes)] },
		isTableView,
	};
}
