import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { getDimensionNameByKey } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/utils';
import { DimensionType, MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { getDimensionKey } from '../utils';

export function calcBreakdowns(
	{ groupBy }: MetricSearchParams,
	{ metricOperator }: Pick<MetricDerivedState, 'metricOperator'>
): Pick<MetricDerivedState, 'breakdowns'> {
	const isDisabled = metricOperator.isConcatable;

	if (isDisabled) {
		return { breakdowns: { isDisabled, values: [] } };
	}

	const breakdownsFromUrl = Object.keys(groupBy ?? {});
	const breakdowns = breakdownsFromUrl.map((breakdown) => ({
		type: 'text' as DimensionType,
		key: breakdown,
		label: getDimensionNameByKey(getDimensionKey(breakdown)),
	}));
	const firstBreakdown = breakdowns?.[0];

	return { breakdowns: { isDisabled, values: firstBreakdown ? [firstBreakdown] : [] } };
}
