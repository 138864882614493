import { MutableRefObject, useLayoutEffect, useState } from 'react';
import useWindowResize from './useWindowResize';

export function useOnOverflow(
	ref: MutableRefObject<HTMLDivElement | null>,
	dependencies?: unknown[],
	callback?: (hasOverflow: boolean) => void,
	isHorizontal?: boolean
) {
	const [isOverflow, setIsOverflow] = useState<boolean>(false);

	useLayoutEffect(() => {
		const { current } = ref;

		const hasOverflow = isHorizontal
			? !!current && current.scrollWidth > current.clientWidth
			: !!current && current.scrollHeight > current.clientHeight;

		if (hasOverflow !== isOverflow) setIsOverflow(hasOverflow);

		callback?.(hasOverflow);
	}, [callback, ref, dependencies, isHorizontal, isOverflow]);

	useWindowResize(() => {
		const { current } = ref;

		const hasOverflow = isHorizontal
			? !!current && current.scrollWidth > current.clientWidth
			: !!current && current.scrollHeight > current.clientHeight;

		if (hasOverflow !== isOverflow) setIsOverflow(hasOverflow);

		callback?.(hasOverflow);
	});

	return isOverflow;
}
