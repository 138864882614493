import { Box, Center, Flex, GridItem } from '@chakra-ui/react';

import { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Divider from 'src/common/components/Divider';
import { ResponsiveGrid } from 'src/common/components/ResponsiveGrid/ResponsiveGrid';
import Spinner from 'src/common/components/Spinner';
import { FeedSignal } from 'src/types/spaces';
import { INFINITE_SCROLL_BATCH_SIZE } from '../hooks/useFeed';
import { FeedItem } from './FeedItem';

const INFINITE_SCROLL_TARGET_ID = 'scrollableFeed';

export function FeedList({
	allLoadedSignalsCount,
	relevantItems,
	fetchMoreItems,
	areAllItemsFetched,
	onOpenSignalComment,
}: {
	allLoadedSignalsCount: number;
	relevantItems: FeedSignal[];
	fetchMoreItems: () => Promise<void>;
	areAllItemsFetched: boolean;
	onOpenSignalComment: (signalId: string) => void;
}) {
	const [shownFeedItemsCount, setShownFeedItemsCount] = useState(INFINITE_SCROLL_BATCH_SIZE);

	const showMoreItems = useCallback(() => {
		setShownFeedItemsCount((count) => count + INFINITE_SCROLL_BATCH_SIZE);
	}, []);

	const shownItems = useMemo(() => relevantItems.slice(0, shownFeedItemsCount), [relevantItems, shownFeedItemsCount]);

	useEffect(() => {
		if (relevantItems.length <= shownFeedItemsCount && !areAllItemsFetched) fetchMoreItems();
	}, [relevantItems, allLoadedSignalsCount, shownFeedItemsCount, fetchMoreItems, areAllItemsFetched]);

	return (
		<Flex width={'100%'} flexDir="row" mb="32px" height="0" minHeight="100%">
			<Box overflowY={'auto'} id={INFINITE_SCROLL_TARGET_ID} width="100%">
				<ResponsiveGrid>
					<GridItem
						colSpan={{
							base: 12,
							sm: 8,
							lg: 6,
							xl: 8,
						}}
						colStart={{
							base: 1,
							sm: 3,
							lg: 4,
							xl: 3,
						}}
					>
						<Flex flex={1} pt="32px" direction={'column'}>
							<InfiniteScroll
								dataLength={shownItems.length}
								next={showMoreItems}
								hasMore={!areAllItemsFetched || shownFeedItemsCount < relevantItems.length}
								loader={
									<Center height={'56px'}>
										<Spinner />
									</Center>
								}
								scrollableTarget={INFINITE_SCROLL_TARGET_ID}
							>
								{shownItems.map((signal) => (
									<Box key={`signal-widget-${signal.signal_id}`} id={signal.signal_id}>
										<FeedItem signal={signal} onOpenSignalComment={onOpenSignalComment} />
										<Divider direction="horizontal" color="gray.300" mt={'4px'} mb="4px" />
									</Box>
								))}
							</InfiniteScroll>
						</Flex>
					</GridItem>
				</ResponsiveGrid>
			</Box>
		</Flex>
	);
}
