import { useEnvironment } from 'src/stores/environment';
import { useFronteggAuth } from './useFronteggLogin';

function useRESTAuthInfo() {
	const { user } = useFronteggAuth();
	const { tenant, role, apiUrl } = useEnvironment();

	if (!user) {
		throw new Error('User is not authenticated');
	}

	return {
		accessToken: user.accessToken,
		tenantId: tenant,
		role,
		apiUrl,
	};
}

export default useRESTAuthInfo;
