import { Popover, PopoverAnchor, PopoverContent, Portal, theme } from '@chakra-ui/react';
import Box from '@components/Box';
import Flex from '@components/Flex';
import Input from '@components/Input';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useKeyPress } from 'src/common/hooks/interaction/useKeyPress';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useReportEvent } from 'src/services/analytics';
import { ConfirmationModal } from '../ConfirmationModal';
import { ChevronDown16, ChevronUp16, ErrorYellow16 } from '../Icons';
import ListItem from '../ListItem';
import { Search } from '../Search';
import { SelectOption } from '../Select/types';
import Typography from '../Typography';
import classes from './AdvancedSelect.module.scss';

const MAX_DROPDOWN_HEIGHT = 346;
const INDENT = 8;

const { advancedSelect, dropdownMenu, menuSearch, inputActive, warningIcon, optionsList, inputDisabled } = classes;

export const AdvancedSelect = ({
	options,
	placeholder,
	onChange,
	initialValue,
	isRequired = false,
	isWarningModalEnabled = false,
	isDisabled = false,
	controlledValue,
	dataIntercomTarget = 'selector',
	onBlur,
	label,
	onReportEvent,
	entityName = '',
	hoverTooltipBuilder,
}: {
	options: SelectOption[] | void;
	controlledValue?: SelectOption;
	placeholder: string;
	onChange: (el: SelectOption) => void;
	initialValue?: SelectOption;
	isRequired?: boolean;
	isWarningModalEnabled?: boolean;
	isDisabled?: boolean;
	dataIntercomTarget?: string;
	onBlur?: VoidFunction;
	label?: string;
	onReportEvent?: (val?: string) => void;
	entityName?: string;
	hoverTooltipBuilder?: (option: SelectOption) => ReactNode;
}) => {
	const [menuIsOpened, setMenuIsOpened] = useState(false);
	const [position, setPosition] = useState<{ top: number; left: number; width: number; height: number }>({
		top: 0,
		left: 0,
		width: 0,
		height: 0,
	});
	const [hoverIndex, setHoverIndex] = useState(0);
	const [searchValue, setSearchValue] = useState('');
	const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(initialValue);
	const [selectedWarningOption, setSelectedWarningOption] = useState<SelectOption | undefined>();
	const inputRef = useRef<HTMLDivElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const scrollableDivRef = useRef<HTMLDivElement>(null);
	const isWarningIconVisible = isRequired && !selectedOption?.label && !menuIsOpened;

	const { isOpen, onOpen, onClose } = useModal();
	const { wrapWithReport, reportEvent } = useReportEvent({ feature: 'Create New Metric' });

	const closeMenu = () => {
		setSearchValue('');
		setMenuIsOpened(false);
	};

	const setDropdownPlacement = () => {
		if (inputRef.current && dropdownRef.current) {
			const { top, bottom, left, width } = inputRef.current.getBoundingClientRect();
			const dropdownHeight = dropdownRef.current.getBoundingClientRect().height - INDENT;
			const dropdownPositionedHeight = dropdownHeight + INDENT;
			const topPosition =
				window.innerHeight - bottom >= dropdownPositionedHeight
					? bottom + window.scrollY
					: top + window.scrollY - dropdownPositionedHeight - INDENT;

			setPosition({
				top: topPosition,
				left: left + window.scrollX,
				width,
				height: dropdownHeight >= MAX_DROPDOWN_HEIGHT ? MAX_DROPDOWN_HEIGHT : dropdownHeight,
			});
		}
	};

	const checkForValue = useCallback(
		(value?: string) => value?.toLocaleLowerCase()?.includes(searchValue.toLowerCase()),
		[searchValue]
	);

	const optionArray = useMemo(() => {
		const filteredArray =
			options
				?.filter((el) => el.label !== selectedOption?.label)
				?.filter((el) => checkForValue(el.label || '') || checkForValue(el.value)) || [];
		return selectedOption && !searchValue ? [selectedOption, ...filteredArray] : filteredArray;
	}, [options, searchValue, selectedOption, checkForValue]);

	const isElementFullyVisible = (elem: HTMLElement) => {
		if (!scrollableDivRef.current) return false;

		const { top, bottom } = elem.getBoundingClientRect();
		const { top: containerTop, bottom: containerBottom } = scrollableDivRef.current.getBoundingClientRect();

		return top >= containerTop && bottom <= containerBottom;
	};
	const scrollToElement = useCallback((index: number, isScrollingUp: boolean) => {
		const activeElement = document.getElementById(`option-${index}`);
		if (activeElement && !isElementFullyVisible(activeElement)) {
			activeElement.scrollIntoView({ behavior: 'smooth', block: isScrollingUp ? 'start' : 'end' });
		}
	}, []);

	const onItemClick = useCallback(
		(option: SelectOption) => {
			onReportEvent?.(option.value);
			if (selectedOption?.value === option?.value) {
				closeMenu();
				return;
			}
			if (selectedOption && isWarningModalEnabled) {
				setMenuIsOpened(false);
				onOpen();
				setSelectedWarningOption(option);
				return;
			}
			setSelectedOption(option);
			onChange(option);
			closeMenu();
		},
		[isWarningModalEnabled, onChange, onOpen, onReportEvent, selectedOption]
	);

	const handleKeyDown = useCallback(
		(eventName: string) => {
			if (!menuIsOpened) return;
			if (eventName === 'ArrowUp') {
				const newHoverIndex = Math.max(hoverIndex - 1, 0);
				setHoverIndex(newHoverIndex);
				scrollToElement(newHoverIndex, true);
			} else if (eventName === 'ArrowDown') {
				const newHoverIndex = Math.min(hoverIndex + 1, optionArray.length - 1);
				setHoverIndex(newHoverIndex);
				scrollToElement(newHoverIndex, false);
			} else if (eventName === 'Enter') {
				onItemClick(optionArray[hoverIndex]);
			}
		},
		[menuIsOpened, hoverIndex, scrollToElement, optionArray, onItemClick]
	);

	useKeyPress(['ArrowUp'], () => handleKeyDown('ArrowUp'));
	useKeyPress(['ArrowDown'], () => handleKeyDown('ArrowDown'));
	useKeyPress(['Enter'], () => handleKeyDown('Enter'));

	useEffect(() => {
		if (selectedOption != controlledValue) setSelectedOption(controlledValue);
	}, [selectedOption, controlledValue]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				inputRef.current &&
				dropdownRef.current &&
				!inputRef.current.contains(event.target as Node) &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				onBlur?.();
				closeMenu();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onBlur]);

	const EmptyState = () => (
		<Flex flex={1} alignItems={'center'}>
			<Typography width={'100%'} padding={'12px 16px'} variant="DesktopH8Regular" textAlign="center">
				No options to show
			</Typography>
		</Flex>
	);

	const WarningIcon = () => (
		<Box className={warningIcon}>
			<ErrorYellow16 />
		</Box>
	);

	const onSubmit = () => {
		if (!selectedWarningOption) return;
		setSelectedOption(selectedWarningOption);
		onChange(selectedWarningOption);
		onClose();
		closeMenu();
	};

	const toggleDropdown = () => {
		if (isDisabled) return;
		setMenuIsOpened(!menuIsOpened);
		setHoverIndex(0);
		setTimeout(() => setDropdownPlacement());
	};

	const optionList = useMemo(
		() =>
			optionArray.map((el, i) => (
				<ListItem
					id={`option-${i}`}
					isHovered={i === hoverIndex}
					hoverColor="blue.100"
					color="gray.1000"
					state={el.label === selectedOption?.label ? 'selected' : 'enabled'}
					key={i}
					size={'sm'}
					label={el.label ?? el.value}
					prefixIcon={el.icon && <Box>{el.icon}</Box>}
					onClick={() => onItemClick(el)}
					onMouseEnter={() => setHoverIndex(i)}
					noOfLines={1}
				/>
			)),
		[hoverIndex, onItemClick, optionArray, selectedOption?.label]
	);

	const hoverTooltip = useMemo(() => {
		if (optionArray[hoverIndex] == undefined) return;
		return hoverTooltipBuilder?.(optionArray[hoverIndex]);
	}, [hoverIndex, hoverTooltipBuilder, optionArray]);

	const DropdownMenu = (
		<Portal>
			<Flex
				ref={dropdownRef}
				style={{ top: position.top, left: position.left, width: position.width }}
				position={'absolute'}
				zIndex={theme.zIndices.popover}
			>
				<Popover placement="left-start" isOpen={!!hoverTooltip} autoFocus={false}>
					<PopoverContent autoFocus={false} background={'0'} boxShadow={'0'}>
						{hoverTooltip}
					</PopoverContent>
					<PopoverAnchor>
						<Flex minHeight={position.height} className={dropdownMenu} width={position.width}>
							<Box className={menuSearch}>
								<Search
									height="44px"
									isDeBounced={false}
									initialValue={searchValue}
									isAutoFocused
									onChange={setSearchValue}
									isTransparent
									width="100%"
									placeholder="Search"
								/>
							</Box>
							{!optionArray?.length ? (
								<EmptyState />
							) : (
								<Box ref={scrollableDivRef} className={optionsList}>
									{optionList}
								</Box>
							)}
						</Flex>
					</PopoverAnchor>
				</Popover>
			</Flex>
		</Portal>
	);

	const valueToTest = initialValue || controlledValue;
	const isInvalidSelect = !!(valueToTest?.value && !options?.some((el) => el.value === valueToTest?.value));
	const isDataSource = label === 'Data source';

	return (
		<>
			<Box
				width={'100%'}
				cursor={isDisabled ? 'not-allowed' : 'pointer'}
				ref={inputRef}
				data-intercom-area={'metric'}
				data-intercom-type={'main'}
				data-intercom-target={`${dataIntercomTarget}-selector`}
			>
				<Box
					className={classNames(advancedSelect, {
						[inputActive]: menuIsOpened && !isInvalidSelect,
						[inputDisabled]: isDisabled && !isInvalidSelect,
					})}
				>
					<Input
						isInvalid={isInvalidSelect}
						onClick={toggleDropdown}
						size={'md'}
						rightComponent={
							menuIsOpened ? <ChevronUp16 onClick={toggleDropdown} /> : <ChevronDown16 onClick={toggleDropdown} />
						}
						leftComponent={selectedOption?.icon ? <Box>{selectedOption?.icon}</Box> : undefined}
						placeholder={placeholder}
						value={selectedOption?.label || ''}
					/>
					{isWarningIconVisible && <WarningIcon />}
				</Box>
				<ConfirmationModal
					submitColorScheme="blue"
					isOpen={isOpen}
					onSubmit={wrapWithReport(
						onSubmit,
						isDataSource ? 'ontology-entity-change-source-modal' : 'metric-edit-change-entity-modal',
						{
							feature: 'ontology',
							action: 'confirm',
							entityName: entityName,
						}
					)}
					onClose={() => {
						reportEvent({
							event: isDataSource ? 'ontology-entity-change-source-modal' : 'metric-edit-change-entity-modal',
							metaData: { action: 'cancel', entityName: entityName, feature: 'ontology' },
						});
						onClose();
					}}
					modalTitle={`Change ${label}.`}
					modalText={`Changing the ${label?.toLowerCase()} will clear all inputs.`}
				/>
			</Box>
			{menuIsOpened && DropdownMenu}
		</>
	);
};
