import { atom } from 'jotai';
import _ from 'lodash';
import YAML from 'yaml';
import { OntologyDimensionInfo, OntologyRelationshipInfo } from '../utils/normalizeYaml';
import { OntologyEditorState, OntologyPropertyType } from '../utils/types';
import { OntologyStateAtomDerived } from './OntologyState';
import { newDimensionYAML, newRelationshipYAML } from '../hooks/useDimensionBuilderDerivedState';
import { CreateNewDimensionPropertyName } from '../hooks/useOntologySearchParams';

export type PropertyStateAtom = {
	propertyName?: string;
	propertyValue?: string;
};

export const PropertyStateAtom = atom<PropertyStateAtom>({});

export const PropertyDefinition = atom((get) => {
	const ontologyState = get(OntologyStateAtomDerived);
	const name = get(PropertyStateAtom).propertyName;

	return getPropertyDefinition({ ontologyState, name });
});
export function getPropertyDefinition({ ontologyState, name }: { ontologyState: OntologyEditorState; name?: string }) {
	if (ontologyState.loading || !name) return { propertyDefinition: null, propertyType: null };
	const unifiedProperties = [
		...ontologyState.parsedYaml['dimensions'].map((value) => {
			return { ...value, propertyType: 'dimensions' as OntologyPropertyType };
		}),
		...ontologyState.parsedYaml['relationships'].map((value) => {
			return { ...value, propertyType: 'relationships' as OntologyPropertyType };
		}),
	];
	const propertyDefinition = unifiedProperties.find((item) => item.name === name);
	const propertyType = propertyDefinition?.propertyType;

	const propertyDefinitionWithoutType = _.omit(propertyDefinition, 'propertyType');

	return {
		propertyDefinition: propertyDefinitionWithoutType,
		propertyDefinitionString: YAML.stringify(
			_.isEmpty(propertyDefinitionWithoutType)
				? YAML.parse(
						name === CreateNewDimensionPropertyName ? newDimensionYAML.join('\n') : newRelationshipYAML.join('\n')
				  )
				: propertyDefinitionWithoutType
		),
		propertyType: propertyType,
	};
}

export const PropertyDerivedStateAtom = atom((get) => {
	const { propertyName, propertyValue } = get(PropertyStateAtom);
	if (!propertyName)
		return {
			hasUnsavedChanges: false,
			propertyValue: undefined,
			propertyName: undefined,
			propertyDefinitionString: undefined,
			propertyType: undefined,
		};

	const { propertyDefinitionString, propertyType } = get(PropertyDefinition);

	let propertyDefinitionValue: OntologyDimensionInfo | OntologyRelationshipInfo | null = null;
	if (propertyValue) {
		try {
			propertyDefinitionValue = YAML.parse(propertyValue);
		} catch (e) {
			console.error(e);
		}
	}

	const propertyValueString = YAML.stringify(propertyDefinitionValue);

	const hasUnsavedChanges = !!propertyDefinitionValue && propertyValueString !== propertyDefinitionString;

	return {
		hasUnsavedChanges,
		propertyValue,
		propertyDefinitionValue,
		propertyName,
		propertyDefinitionString,
		propertyType,
	};
});

export const updateSelectedProperty = atom(null, (get, set, update: { name: string }) => {
	const state = get(OntologyStateAtomDerived);
	if (state.loading) return;

	const result = getPropertyDefinition({ ontologyState: state, name: update.name });
	set(PropertyStateAtom, {
		propertyName: update.name,
		propertyValue: result.propertyDefinitionString,
	});
});

export const updatePropertyValue = atom(null, (get, set, update: { value: string }) => {
	const state = get(OntologyStateAtomDerived);
	const propertyState = get(PropertyStateAtom);
	if (state.loading) return;

	set(PropertyStateAtom, {
		...propertyState,
		propertyValue: update.value,
	});
});
