import Box from '@components/Box';
import { ArrowUp16, AskAIGray24, BouncingThreeDots, CRemove16 } from '@components/Icons';
import Input from '@components/Input';
import { InputProps } from '@components/Input/types';
import ResizableIcon from '@components/ResizableIcon';
import { useCallback, useMemo, useState } from 'react';
import Button from '../Button';
import classes from './AskAIInput.module.scss';
import { useAskAIBusyState } from './hooks/useAskAIQueryRunState';
import { useReportAskAIEvent } from './hooks/useReportAskAIEvent';

type AskAIInputProps = InputProps & {
	placeholder: string;
	hasBorder: boolean;
	onSubmit?: (value: string) => void;
	testId?: string;
	isErasable?: boolean;
};

function SubmitButton({ disabled, busy, onSubmit }: { disabled: boolean; busy: boolean; onSubmit: () => void }) {
	return (
		<Button
			variant={'solid'}
			size={'xs'}
			isIconOnly={true}
			colorScheme={'black'}
			border={'none'}
			isDisabled={disabled}
			onClick={onSubmit}
		>
			{busy ? <ResizableIcon size={'16px'} icon={BouncingThreeDots} /> : <ArrowUp16 />}
		</Button>
	);
}

export function AskAIInput({ placeholder, onSubmit, onChange, isErasable, hasBorder }: AskAIInputProps) {
	const { reportAskAIEvent } = useReportAskAIEvent();
	const [inputValue, setInputValue] = useState('');
	const [busy] = useAskAIBusyState();
	const isDisabled = useMemo(() => !inputValue || busy, [busy, inputValue]);

	const onInnerChange = useCallback(
		(value: string) => {
			setInputValue(value);
			onChange?.(value);
		},
		[onChange]
	);

	const onEnter = useCallback(
		(userPrompt: string) => {
			if (isDisabled || userPrompt.trim() == '') return;
			setInputValue('');
			reportAskAIEvent({
				event: 'ask-ai-chat-query',
				metaData: {
					userPrompt,
				},
			});
			onSubmit?.(userPrompt);
		},
		[isDisabled, onSubmit, reportAskAIEvent]
	);

	const onClickSubmit = useCallback(() => {
		onEnter(inputValue);
	}, [inputValue, onEnter]);

	return (
		<Box className={hasBorder ? classes['ask-ai-input'] : ''} flex={1}>
			<Input
				borderRadius={'8px'}
				placeholder={placeholder}
				color={'gray.1100'}
				size={'md'}
				shouldShowAutocomplete={false}
				onEnter={onEnter}
				isErasable={isErasable && !!inputValue}
				eraseIcon={<CRemove16 />}
				variant={'ghost'}
				leftComponent={<AskAIGray24 />}
				leftComponentPadding={'42px'}
				leftComponentLeftPadding={'12px'}
				leftComponentBorder={false}
				rightComponent={
					!isErasable ? <SubmitButton disabled={isDisabled} busy={busy} onSubmit={onClickSubmit} /> : undefined
				}
				rightComponentPadding={'10px'}
				onChange={onInnerChange}
				value={inputValue}
				testId={`ask-ai-input`}
				autoFocus={true}
			/>
		</Box>
	);
}
