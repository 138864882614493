import { AskAIChatCard } from './AskAIChatCard';
import Flex from '@components/Flex';
import Box from '@components/Box';
import { ErrorBoundary } from 'react-error-boundary';
import { AskAIErrorCard } from './AskAIErrorCard';
import { useAskAIChatMessages } from '../hooks/useAskAIChatMessages';
import { useCallback, useEffect, useRef } from 'react';

type OnErrorType = (
	error: Error,
	info: {
		componentStack: string;
	}
) => void;

export function AskAIChatCardsList() {
	const { chatMessages } = useAskAIChatMessages();
	const cardRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!cardRef.current?.scrollTo) return;
		cardRef.current.scrollTo({ top: 0, left: 0, behavior: 'auto' });
	}, [chatMessages]);

	const onError: OnErrorType = useCallback((error, info) => {
		console.log(error);
		console.log(info);
	}, []);

	return (
		<Flex
			direction={'column-reverse'}
			flex={1}
			padding={'0 8px 4px 8px'}
			overflowY={'auto'}
			data-testid={'ask-ai-chat-page-card-list'}
			ref={cardRef}
		>
			{chatMessages
				.map((chatMessage, index) => (
					<Box key={`${chatMessage}`}>
						<ErrorBoundary
							fallback={<AskAIErrorCard chatMessageAtom={chatMessage} chatIndex={index} />}
							onError={onError}
						>
							<AskAIChatCard chatMessageAtom={chatMessage} chatIndex={index} />
						</ErrorBoundary>
					</Box>
				))
				.reverse()}
		</Flex>
	);
}
