import * as DateFns from 'date-fns';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import Box from 'src/common/components/Box';
import { useIsVisible } from 'src/common/hooks/ui/useIsVisible';
import { useSignalResolveMutation } from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import { FeedSignal } from 'src/types/spaces';
import { DashboardPath } from '../../DashboardPage';
import { WorkspacePath } from '../../WorkspacePage';
import { WorkspaceSignalWidget } from '../../WorkspacePage/SignalWidget/WorkspaceSignalWidget';

import { useFindUserFromMention } from '../hooks/useFindUserFromMention';
import { useMyReasonsRegexp } from '../hooks/useMyReasonsRegexp';
import { isSignalANotification, isString } from '../utils';
import { Notification } from './Notification/Notification';

export function FeedItem({
	signal,
	onOpenSignalComment,
}: {
	signal: FeedSignal;
	onOpenSignalComment: (signalId: string) => void;
}) {
	const { reportEvent } = useReportEvent();
	const myReasonsRegexp = useMyReasonsRegexp();
	const isSignalAnInsight = !isSignalANotification(signal, new RegExp(myReasonsRegexp));

	const lastReason = useMemo(() => {
		const reasons = isString(signal.reason) ? [signal.reason] : signal.reason ?? [''];
		return reasons[reasons.length - 1];
	}, [signal.reason]);
	const { userName, avatarURL, mentionText } = useFindUserFromMention(lastReason);

	const linkedCollection = useMemo(
		() =>
			signal.signal.feed_signals.find(
				(feedSignal) => !!feedSignal?.feed?.workspace?.collection_type && !feedSignal.resolved
			)?.feed?.workspace,
		[signal.signal.feed_signals]
	);
	const hasLinkedCollection = !!linkedCollection;

	const containerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const isVisible = useIsVisible(containerRef);
	const [resolveSignalMutation] = useSignalResolveMutation();
	const resolveSignal = useCallback(() => {
		resolveSignalMutation({ variables: { feed_id: signal.feed_id, signal_id: signal.signal_id } });
	}, [resolveSignalMutation, signal.feed_id, signal.signal_id]);
	useEffect(() => {
		const isSignalAutoResolvable = isSignalAnInsight || !hasLinkedCollection;
		if (!isVisible || signal.resolved || !isSignalAutoResolvable) return;
		resolveSignal();
	}, [hasLinkedCollection, isSignalAnInsight, isVisible, resolveSignal, signal.resolved]);

	const { navigate } = useNavigation();
	const openLinkedCollection = () => {
		if (!hasLinkedCollection) return;

		reportEvent({
			event: 'open-linked-collection',
			metaData: { lastReason, isInsight: isSignalAnInsight, collectionType: linkedCollection.collection_type },
		});

		if (linkedCollection.collection_type == 'workspace') {
			navigate({ path: `${WorkspacePath}/${linkedCollection.id}`, anchor: signal.signal_id });
		} else if (linkedCollection.collection_type == 'dashboard') {
			navigate({ path: `${DashboardPath}/${linkedCollection.id}` });
		}
	};

	const feedItem = isSignalAnInsight ? (
		<Box
			_hover={{ borderColor: 'gray.300' }}
			border={'solid 1px transparent'}
			borderRadius={'4px'}
			transition={'all 200ms'}
			marginY="16px"
		>
			<WorkspaceSignalWidget
				signal={signal}
				onOpenSignalComment={onOpenSignalComment}
				hasBorder={false}
				hasEditActions={false}
				onAttachmentClick={openLinkedCollection}
			/>
		</Box>
	) : (
		<Box paddingY="4px">
			<Notification
				imageUrl={avatarURL || undefined}
				name={userName}
				subtitle={DateFns.formatDistanceToNow(new Date(signal.updated_at || signal.signal.created_at), {
					addSuffix: true,
				})}
				actionText={lastReason.replace(mentionText, '').trim()}
				isNew={!signal.resolved}
				onClick={
					linkedCollection &&
					(() => {
						resolveSignal();
						openLinkedCollection();
					})
				}
			/>
		</Box>
	);

	return <div ref={containerRef}>{feedItem}</div>;
}
