import shadows from 'src/style/shadows';
import { StyleProps } from 'src/style/types';

type PopoverVariants = 'light' | 'strong' | 'solid' | 'medium';
type PopoverStyleProps = {
	variant: PopoverVariants;
} & StyleProps;

export default {
	baseStyle: (props: PopoverStyleProps) => {
		const shadow = shadowByVariant[props.variant];

		return {
			popper: { zIndex: 'var(--chakra-zIndices-popover)' },
			content: {
				bg: 'white',
				width: 'auto',
				border: '0',
				borderRadius: '4px',
				boxShadow: shadow,
				zIndex: 'var(--chakra-zIndices-popover)',
				_focusVisible: {
					boxShadow: shadow,
				},
			},
		};
	},
};

const shadowByVariant: { [key in PopoverVariants]: string } = {
	light: shadows['g-03'],
	medium: shadows['z-03'],
	strong: shadows['z-05'],
	solid: shadows['a-22'],
};
