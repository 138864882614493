import get from 'lodash/get';
import { Command20, Control20 } from '@icons/index';

export const sleep = (time: number) => new Promise((res) => setTimeout(res, time, 'done'));

export const isEmailAddress = (emailAdress: string) =>
	/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;s@"]+\.{0,1})+([^<>().,;:\s@"]{2,}|[\d.]+))$/.test(
		emailAdress
	);

const userAgent = get(navigator, 'userAgent');
export const isMacOperatingSystem = /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/.test(userAgent);
export const keyByOperatingSystem = isMacOperatingSystem ? <Command20 /> : <Control20 />;

export const shouldLeftExpandCollapsePanelExpand = () => window.innerWidth >= 1159;

export const filterStringByTerm = (searchTerm: string, stringToFilter?: string): boolean => {
	return !!stringToFilter?.toLowerCase().includes(searchTerm.toLowerCase());
};
