import Box from '@components/Box';
import Flex from '@components/Flex';
import { useCallback, useMemo } from 'react';
import { AdvancedSelect } from 'src/common/components/AdvancedSelect';
import { CloseTiny16 } from 'src/common/components/Icons';
import { TableColumn } from 'src/generated/graphql';
import {
	LabelWithIcon,
	SelectLabel,
	TooltipIcon,
} from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';
import {
	addPrefixDollarSignIfNeeded,
	removeDollarSigns,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { NormalizedOntology } from 'src/pages/OntologyPage/hooks/useOntologyPageState';
import { KeysType } from 'src/pages/OntologyPage/hooks/useOntologyUpsertEntity';
import { getIconByType } from 'src/pages/OntologyPage/utils/utils';
import { useReportEvent } from 'src/services/analytics';
import { PrimaryKeysTooltip } from '../../../Tooltips/PrimaryKeysTooltip';

const PrimaryKeys = ({
	selectedOntology,
	isEditEntity = false,
	primary_keys,
	keysOptions,
	onChangeValue,
	isDisabled = false,
}: {
	selectedOntology: NormalizedOntology;
	isEditEntity: boolean;
	primary_keys?: string[];
	keysOptions?: TableColumn[];
	onChangeValue: (keys: KeysType) => void;
	isDisabled?: boolean;
}) => {
	const { reportEvent } = useReportEvent({
		feature: 'Upsert Metric Modal',
	});

	const primaryKeysOptions = useMemo(
		() =>
			keysOptions?.map((el) => ({
				value: `$src__${el.name}`,
				label: `$src__${el.name}`,
				icon: getIconByType(el.dataType, 'small'),
			})),
		[keysOptions]
	);

	const setPrimaryKeys = useCallback(
		({ value, index }: { value: string; index: number }) => {
			const formattedValue = removeDollarSigns(value);
			if (!primary_keys || index < 0 || index >= primary_keys?.length)
				onChangeValue([{ key: 'primary_keys', value: [formattedValue] }]);

			if (primary_keys)
				onChangeValue([
					{
						key: 'primary_keys',
						value: [...primary_keys.slice(0, index), formattedValue, ...primary_keys.slice(index + 1)],
					},
				]);
		},
		[primary_keys, onChangeValue]
	);

	const primaryKeysArray = useMemo(() => {
		return primary_keys?.map((el, i) => {
			const controlledValue = el
				? primaryKeysOptions?.find((option) => option?.value === `$${el}`) || {
						label: addPrefixDollarSignIfNeeded(el) || '',
						value: addPrefixDollarSignIfNeeded(el) || '',
						icon: null,
				  }
				: undefined;

			return (
				<Box marginTop={'8px'} key={i}>
					<AdvancedSelect
						onBlur={() =>
							primary_keys?.length > 1 &&
							!el &&
							onChangeValue([{ key: 'primary_keys', value: [...primary_keys.slice(0, -1)] }])
						}
						onReportEvent={() =>
							reportEvent({
								event: 'ontology-object-edit-UI-input-provided',
								metaData: {
									objectType: 'entity',
									objectName: isEditEntity ? selectedOntology?.name : '',
									fieldname: 'keys',
									input: controlledValue?.value,
									flow: isEditEntity ? 'edit' : 'create',
								},
							})
						}
						isDisabled={isDisabled}
						onChange={(option) => setPrimaryKeys({ value: option?.value, index: i })}
						placeholder="Select"
						options={primaryKeysOptions?.filter(
							(option) =>
								el === removeDollarSigns(option.value) || !primary_keys?.includes(removeDollarSigns(option.value))
						)}
						controlledValue={controlledValue}
						dataIntercomTarget="entity"
					/>
				</Box>
			);
		});
	}, [
		isDisabled,
		primaryKeysOptions,
		primary_keys,
		setPrimaryKeys,
		onChangeValue,
		isEditEntity,
		reportEvent,
		selectedOntology?.name,
	]);

	const primaryKeysFields = useMemo(() => {
		const isWithoutEmptyValues = !isDisabled && !!primary_keys?.length && !primary_keys?.some((val) => val === '');
		return (
			<Box>
				{primaryKeysArray}
				<Flex justifyContent={isWithoutEmptyValues ? 'space-between' : 'flex-end'} marginTop={'12px'}>
					{isWithoutEmptyValues && (
						<LabelWithIcon
							isFieldsDisabled={false}
							onClick={() => onChangeValue([{ key: 'primary_keys', value: [...primary_keys, ''] }])}
							text="Add key"
						/>
					)}
					{primary_keys && primary_keys?.length > 1 && (
						<LabelWithIcon
							icon={<CloseTiny16 />}
							isFieldsDisabled={false}
							onClick={() => onChangeValue([{ key: 'primary_keys', value: [...primary_keys.slice(0, -1)] }])}
							text="Remove"
						/>
					)}
				</Flex>
			</Box>
		);
	}, [primaryKeysArray, primary_keys, isDisabled, onChangeValue]);

	return (
		<>
			<Flex gap={'6px'} alignItems={'center'}>
				<SelectLabel
					color={'gray.1100'}
					text="Primary keys"
					trailingIcon={
						<TooltipIcon
							tooltipBody={
								<PrimaryKeysTooltip
									onHelpClicked={() =>
										reportEvent({
											event: 'ontology-object-help-clicked',
											metaData: {
												component: 'keys',
											},
										})
									}
								/>
							}
						/>
					}
				/>
			</Flex>
			{primaryKeysFields}
		</>
	);
};

export default PrimaryKeys;
