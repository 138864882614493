import { selectors, useFeatureFlagsStore } from 'src/stores/featureFlags';
import { FeatureFlagNameType } from 'src/types/featureFlags';

function useFeatureFlag(flagName: FeatureFlagNameType): boolean {
	const featureFlags = useFeatureFlagsStore(selectors.getAll);
	const flag = featureFlags[flagName];

	return flag;
}

export default useFeatureFlag;
