import { defaultFeatureFlags } from 'src/config';
import { FeatureFlagsStoreType, FeatureFlagsType } from 'src/types/featureFlags';
import { create } from 'zustand';

const initialStore = defaultFeatureFlags;

export const useFeatureFlagsStore = create<FeatureFlagsStoreType>((set) => ({
	...initialStore,
	setFeatureFlags: (newFeatureFlags: FeatureFlagsType) => set(newFeatureFlags as Partial<FeatureFlagsStoreType>),
	reset: () => set(defaultFeatureFlags),
}));

export const selectors = {
	getAll: (state: FeatureFlagsStoreType) => state,
	reset: (state: FeatureFlagsStoreType) => state.reset,
};
