import { useCallback } from 'react';
import { useSearchParams as useRouterSearchParams } from 'react-router-dom';

export const extractPrivateSearchParams = (rawSearchParams: URLSearchParams, cleanUnderscore = true) => {
	const sp = Array.from(rawSearchParams.entries());

	const extractedPrivateSearchParams = sp
		.filter(([key]) => key.startsWith('_'))
		.map(([key, value]) => [cleanUnderscore ? key.slice(1) : key, value]);

	return Object.fromEntries(extractedPrivateSearchParams);
};

type NavigateOptions = {
	replace?: boolean | undefined;
	state?: any;
};
type SetSearchParamsFunc = (nextInit: URLSearchParams, navigateOptions?: NavigateOptions) => void;
type useSearchReturnType = readonly [URLSearchParams, SetSearchParamsFunc];

function useSearchParams(): useSearchReturnType {
	const [rawSearchParams, setSearchParams]: useSearchReturnType = useRouterSearchParams();

	const safeSetSearchParams: SetSearchParamsFunc = useCallback<SetSearchParamsFunc>(
		(newSearchParams) => {
			const appPrivateSearchParams = extractPrivateSearchParams(rawSearchParams, false);
			setSearchParams(new URLSearchParams({ ...appPrivateSearchParams, ...Object.fromEntries(newSearchParams) }));
		},
		[rawSearchParams, setSearchParams]
	);

	return [rawSearchParams, safeSetSearchParams];
}

export default useSearchParams;
