import Button from 'src/common/components/Button';
import { ButtonVariantType } from 'src/common/components/Button/types';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';

export function CloseButton({ variant = 'outline' }: { variant?: ButtonVariantType }) {
	const { navigate } = useNavigation();
	const { reportEvent } = useReportEvent();

	function closeMetricPage() {
		reportEvent({
			event: 'metric-page-close-clicked',
			metaData: { feature: 'Metric header' },
		});
		navigate({ path: 'metrics' });
	}

	return (
		<Button
			color={'gray.1000'}
			variant={variant}
			colorScheme="gray"
			onClick={closeMetricPage}
			size="inline"
			data-intercom-area={'metric'}
			data-intercom-type={'button'}
			data-intercom-target={'Close'}
		>
			Close
		</Button>
	);
}
