import Modal from '@components/Modal';
import Flex from '@components/Flex';
import colors from 'src/style/colors';
import Typography from '@components/Typography';
import { useReportEvent } from '@services/analytics';
import useNavigation from '@services/useNavigation';
import Button from '@components/Button';
import { useCallback } from 'react';

export function StaleVersionModal({ isOpen, entityName }: { isOpen: boolean; entityName: string }) {
	const { reportEvent } = useReportEvent();
	const { refresh } = useNavigation();

	const onClick = useCallback(() => {
		reportEvent({
			event: 'stale-entity-refresh-clicked',
			metaData: { entityName },
		});
		refresh();
	}, [entityName, refresh, reportEvent]);

	return (
		<Modal isOpen={isOpen} onClose={() => {}} closeOnOverlayClick={false} isCentered={true} maxWidth={'fit-content'}>
			<Flex
				alignItems={'center'}
				direction={'row'}
				gap={'8px'}
				paddingRight={'12px'}
				paddingLeft={'24px'}
				paddingY={'12px'}
				height={'100%'}
			>
				<Flex paddingRight={'8px'}>
					<Typography variant={'DesktopH7Regular'} color={colors.gray['1000']}>
						A newer version of this entity is available with the latest changes.
					</Typography>
				</Flex>
				<Flex>
					<Button
						variant={'ghost'}
						colorScheme={'black'}
						onClick={onClick}
						size={'medium'}
						borderColor={colors.gray['300']}
					>
						Reload page
					</Button>
				</Flex>
			</Flex>
		</Modal>
	);
}
