import Typography from '../Typography';
import { Info16, OkCircle16, RemoveCircle, Warning16 } from '../Icons';
import { Alert as ChakraAlert, AlertTitle, Box, Button, Flex } from '@chakra-ui/react';
import * as CSS from 'csstype';
import colors from 'src/style/colors';
import { alertButtonStyles } from './alertButtonStyles';

export type AlertDirections = 'column' | 'row';
export type AlertStatueses = 'info' | 'warning' | 'success' | 'error' | 'neutral';

export type AlertProps = {
	id?: string;
	title?: string;
	testId?: string;
	padding?: CSS.Property.Padding;
	description?: string;
	DescriptionComponent?: () => JSX.Element;
	status?: AlertStatueses;
	children?: React.ReactNode;
	direction?: AlertDirections;
};

type AlertStyle = { [key in AlertStatueses]: { backgroundColor: string; color: string } };
export const alertStyle: AlertStyle = {
	success: { backgroundColor: colors.emerald['100'], color: colors.green['1000'] },
	info: { backgroundColor: colors.blue['100'], color: colors.blue['1000'] },
	warning: { backgroundColor: colors.orange['100'], color: colors.orange['1000'] },
	error: { backgroundColor: colors.red['100'], color: colors.red['1100'] },
	neutral: { backgroundColor: colors.gray['100'], color: colors.gray['1100'] },
};

export default function Alert({
	status = 'success',
	title,
	description,
	testId,
	direction = 'row',
	children,
	padding,
	DescriptionComponent,
}: AlertProps) {
	const contentProps = {
		status,
		title,
		description,
		DescriptionComponent,
	};
	const AlertContent = direction === 'row' ? AlertRow : AlertColumn;

	return (
		<ChakraAlert p={padding ?? '16px'} borderRadius={'4px'} data-testid={testId} {...alertStyle[status]}>
			<AlertContent {...contentProps}>{children}</AlertContent>
		</ChakraAlert>
	);
}

type AlertContentProps = {
	title?: string;
	description?: string;
	status: AlertStatueses;
	children?: React.ReactNode;
	DescriptionComponent?: () => JSX.Element;
};

const AlertRow = ({ status, title, description, children, DescriptionComponent }: AlertContentProps) => {
	return (
		<Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
			<Flex alignItems={'flex-start'} gap={'12px'}>
				<AlertIcon status={status} />
				<Box>
					{title && <AlertTitle> {title} </AlertTitle>}
					{description && <Typography variant="Paragraph16R"> {description} </Typography>}
					{DescriptionComponent && (
						<Box>
							<DescriptionComponent />
						</Box>
					)}
				</Box>
			</Flex>
			{children && <Box>{children}</Box>}
		</Flex>
	);
};

const AlertColumn = ({ status, title, description, children, DescriptionComponent }: AlertContentProps) => {
	return (
		<Flex width={'100%'} gap={'12px'}>
			<AlertIcon status={status} />
			<Box height={'fit-content'}>
				{title && (
					<Box pb={'2px'}>
						<AlertTitle> {title} </AlertTitle>
					</Box>
				)}
				{description && <Typography variant="Paragraph16R"> {description} </Typography>}

				{DescriptionComponent && (
					<Box>
						<DescriptionComponent />
					</Box>
				)}

				{children && <Box pt={'16px'}>{children} </Box>}
			</Box>
		</Flex>
	);
};

export type ButtonVariants = 'link' | 'solid' | 'outline';
type AlertButtonProps = {
	title?: string;
	onClick: () => void;
	status: AlertStatueses;
	variant: ButtonVariants;
	children?: React.ReactNode;
};
export type AlertFooterProps = {
	children: React.ReactNode;
};

const icons: { [key in AlertStatueses]: JSX.Element } = {
	success: <OkCircle16 />,
	neutral: <Info16 />,
	info: <Info16 />,
	warning: <Warning16 />,
	error: <RemoveCircle />,
};

const AlertIcon = ({ status }: { status: AlertStatueses }) => {
	return (
		<Box width={'fit-content'} pt={'4px'}>
			{icons[status]}
		</Box>
	);
};

export const AlertFooter = ({ children }: AlertFooterProps) => {
	return <Flex gap={'8px'}>{children}</Flex>;
};

export const AlertButton = ({ status = 'success', onClick, title, variant = 'solid', children }: AlertButtonProps) => {
	return (
		<Button variant={variant} onClick={onClick} borderRadius={'4px'} {...alertButtonStyles[status][variant]}>
			{children ?? <Typography variant={'DesktopH7Bold'}>{title}</Typography>}
		</Button>
	);
};
