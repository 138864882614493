import { Ok16, Time16 } from 'src/common/components/Icons';
import { SuffixIcon } from 'src/common/components/TableCell/types';
import colors from 'src/style/colors';
import { ChangeStatus } from '../types';

export const iconForStatus: { [key in ChangeStatus]: JSX.Element } = {
	Normalized: <Time16 color={colors.orange['700']} data-testid={'Pending'} aria-label={'Normalized'} />,
	Pending: <Time16 color={colors.orange['700']} data-testid={'Pending'} aria-label={'Pending'} />,
	Done: <Ok16 color={colors.green['700']} data-testid={'Done'} aria-label={'Done'} />,
};

export const chooseIconForTableCell = (status: ChangeStatus): SuffixIcon | undefined => {
	switch (status) {
		case 'Pending':
		case 'Normalized':
			return 'pending';
		case 'Done':
			return 'approved';
		default:
			return undefined;
	}
};
