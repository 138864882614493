export class TestIDs {
	static readonly TOP_NAVIGATION = 'top-navigation';
	static readonly AVATAR_MENU = 'avatar-menu';
	static readonly LOGOUT_BUTTON = 'log-out-button';
	static readonly METRIC_CATALOG_HEADER = 'metric-catalog-header';
	static readonly METRIC_PAGE_HEADER = 'metric-page-header';
	static readonly NEW_METRIC_BUTTON = 'new-metric-button';
	static readonly CREATE_NEW_METRIC_BUTTON = 'create-new-metric-button';
	static readonly CREATE_NEW_METRIC_HEADER = 'create-new-metric-header';
	static readonly PUBLISH_METRIC_BUTTON = 'publish-metric-button';
	static readonly VIEW_ONLY_BADGE = 'view-only-badge';
	static readonly METRIC_MORE_BUTTON = 'metric-more-button';
	static readonly METRIC_NAME_INPUT = 'metric-name-input';
	static readonly DELETE_METRIC_MODAL = 'delete-metric-modal';
	static readonly SAVE_METRIC_MODAL = 'save-metric-modal';
	static readonly BUILDER_MEASURE_INPUT = 'builder-measure-input';
	static readonly DASHBOARD_PAGE_HEADER = 'dashboard-page-header';
	static readonly SPACES_HEADER_TITLE = 'spaces-header-title';
	static readonly SPACES_HEADER_TITLE_INPUT = 'spaces-header-title-input';
	static readonly DASHBOARD_THREE_DOT_MENU = 'dashboard-three-dot-menu';
	static readonly DASHBOARD_THREE_DOT_MENU_DELETE = 'dashboard-three-dot-menu-delete';
	static readonly METRIC_TITLE_INPUT = 'title-input';
	static readonly METRIC_DESCRIPTION_INPUT = 'description-input';
	static readonly CHART_CONTAINER = 'chart-container';
	static readonly DASHBOARD_EMPTY_COLLECTION_PAGE_TITLE = TestIDs.EMPTY_COLLECTION_PAGE_TITLE('dashboard');
	static readonly DASHBOARD_SIDEBAR_ITEMS = TestIDs.SIDEBAR_ITEMS('dashboard');
	static readonly DASHBOARD_SIDEBAR_ADD_NEW_BUTTON = TestIDs.SIDEBAR_FOOTER_ADD_NEW_BUTTON('dashboard');
	static readonly DASHBOARD_CONFIRM_DELETE_BUTTON = TestIDs.CONFIRM_COLLECTION_DELETE_BUTTON('dashboard');
	static readonly DASHBOARD_SIDEBAR_ITEM_TEXT = TestIDs.SIDEBAR_ITEM_TEXT('dashboard');
	static readonly DASHBOARD_CREATION_NAME_INPUT = TestIDs.NEW_COLLECTION_NAME_INPUT('dashboard');
	static readonly DASHBOARD_CREATION_CREATE_BUTTON = TestIDs.MODAL_FOOTER_PRIMARY_BUTTON(
		TestIDs.NEW_COLLECTION_MODAL_FOOTER('dashboard')
	);

	static EMPTY_COLLECTION_PAGE_TITLE(collectionType: string) {
		return `${collectionType}-empty-collection-page-title`;
	}
	static SIDEBAR_ITEMS(collectionType?: string) {
		return `${collectionType}-navigation-drawer-sidebar-items`;
	}
	static SIDEBAR_FOOTER_ADD_NEW_BUTTON(collectionType: string) {
		return `${collectionType}-navigation-drawer-footer-add-new`;
	}
	static SIDEBAR_ITEM(collectionType: string) {
		return `${collectionType}-sidebar-item`;
	}
	static SIDEBAR_ITEM_TEXT(collectionType: string) {
		return `${TestIDs.SIDEBAR_ITEM(collectionType)}-text`;
	}
	static CONFIRM_COLLECTION_DELETE_BUTTON(collectionType: string) {
		return `${collectionType}-confirm-delete-button`;
	}
	static NEW_COLLECTION_NAME_INPUT(collectionType: string) {
		return `new-${collectionType}-name-input`;
	}
	static NEW_COLLECTION_MODAL_FOOTER(collectionType: string) {
		return `new-${collectionType}-modal-footer`;
	}
	static MODAL_FOOTER_BACK_BUTTON(parentId = 'modal-footer') {
		return `${parentId}-back`;
	}
	static MODAL_FOOTER_CANCEL_BUTTON(parentId = 'modal-footer') {
		return `${parentId}-cancel`;
	}
	static MODAL_FOOTER_PRIMARY_BUTTON(parentId = 'modal-footer') {
		return `${parentId}-primary`;
	}
}
