import Box from '@components/Box';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { RemoveSolid12, SlidersVertical16, SortUpArrow16 } from '@icons/index';
import { IHeaderParams } from 'ag-grid-community';
import classNames from 'classnames';
import { possibleSortOptions } from 'src/common/components/Table/sortOptions';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { DetailedTableIdCollectedProps } from 'src/common/utils/bizapiParsers';
import { useCollectedProps } from 'src/pages/MetricPage/hooks/useCollectedProps';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useReportEvent } from 'src/services/analytics';
import { removeDollarSigns } from '../../FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import classes from './ColumnHeader.module.scss';
import { AppliedParameterValue } from '../../../../../common/components/Chart/types';
import { ComponentNameWithParamsTooltipContent } from '../../Parameters/ComponentNameWithParamsTooltip';
import ResizableIcon from '../../../../../common/components/ResizableIcon';
import { useMemo } from 'react';

const { sortUpArrow, sortDownArrow, arrowActive, columnHeaderOnHover } = classes;

const ColumnHeader = (columnObj: IHeaderParams) => {
	const isRunningSightfullV2 = useFeatureFlag('pulse.sightfull2.enable');
	const field = columnObj.column.getColDef().field;
	const headerComponentParams: { appliedParameters?: AppliedParameterValue[] } | undefined =
		columnObj.column.getColDef().headerComponentParams;

	const hasAppliedParameters = useMemo(
		() => headerComponentParams?.appliedParameters && headerComponentParams?.appliedParameters?.length > 0,
		[headerComponentParams?.appliedParameters]
	);

	const { collectedProps, removeCollectedProps } = useCollectedProps();
	const shouldAddRemoveOption = isRunningSightfullV2
		? collectedProps.includes(removeDollarSigns(field || ''))
		: field?.includes(`${DetailedTableIdCollectedProps}.`);

	const { reportEvent } = useReportEvent();
	const { metricNameWithoutFlavor, tableType } = useMetricDerivedState();

	const removeColumn = () => {
		const fieldName = columnObj.column.getColDef().field?.split(`${DetailedTableIdCollectedProps}.`).pop() || '';
		removeCollectedProps(collectedProps.filter((prop) => prop !== removeDollarSigns(fieldName)));
	};
	const openMenu = (event: React.MouseEvent<HTMLElement>) => {
		event?.preventDefault();
		const colId = columnObj.column.getColId();
		const { api } = columnObj;
		api.showColumnMenuAfterMouseClick(colId, event.nativeEvent);
	};

	const removeIconComponent = shouldAddRemoveOption && (
		<Tooltip label="Remove column" placement="top" size="sm">
			<Box onClick={removeColumn} unselectable="on" role="presentation">
				<RemoveSolid12 />
			</Box>
		</Tooltip>
	);

	const sortType = columnObj.column.getSort();

	const getNextSortType = () => {
		const sortTypeIndex = possibleSortOptions.indexOf(sortType ?? null);
		return possibleSortOptions[(sortTypeIndex + 1) % possibleSortOptions.length];
	};

	const nextSortType = getNextSortType();

	const onSortRequested = () => {
		reportEvent({
			event: 'sort-column-in-metric-page',
			metaData: {
				metricName: metricNameWithoutFlavor,
				tableType,
				columnName: columnObj.displayName,
				nextSortType,
			},
		});
		columnObj.setSort(nextSortType);
	};

	const sortingIcons = {
		asc: (
			<div className={classNames(sortUpArrow, { [arrowActive]: sortType == 'asc' })}>
				<SortUpArrow16 />
			</div>
		),
		desc: (
			<div className={classNames(sortDownArrow, { [arrowActive]: sortType == 'desc' })}>
				<SortUpArrow16 />
			</div>
		),
	};

	return (
		<Box className={classNames('columnHeader', columnHeaderOnHover)}>
			<Box display="flex" justifyContent="start" pr="40px" w="100%" onClick={onSortRequested}>
				<Tooltip
					size="sm"
					variant="primary"
					label={
						<ComponentNameWithParamsTooltipContent
							component={{ value: columnObj.displayName, appliedParameters: headerComponentParams?.appliedParameters }}
						/>
					}
					placement={'top'}
				>
					<Typography variant="Disclaimer12SB" color="gray.700" textOverflow="ellipsis" overflow="hidden">
						{columnObj.displayName}
					</Typography>
				</Tooltip>
				<Box pl="8px">{sortType ? sortingIcons[sortType] : nextSortType && sortingIcons[nextSortType]}</Box>
			</Box>

			<Box className="columnButtons">
				{removeIconComponent}
				{hasAppliedParameters && (
					<Tooltip
						label={
							<ComponentNameWithParamsTooltipContent
								component={{ appliedParameters: headerComponentParams?.appliedParameters }}
							/>
						}
						placement="top"
						size="md"
					>
						<Box unselectable="on" role="presentation" pl={'4px'} color={'gray.1000'}>
							<ResizableIcon icon={SlidersVertical16} size={'12px'} />
						</Box>
					</Tooltip>
				)}
				<Box
					onClick={openMenu}
					className="columnHeader ag-icon ag-icon-menu"
					unselectable="on"
					role="presentation"
					pl="4px"
				/>
			</Box>
		</Box>
	);
};
export default ColumnHeader;
