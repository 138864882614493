import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import { Node, YAMLMap, isMap, isNode, isSeq, parseDocument } from 'yaml';

export function findYamlKey(documentText: string, cursorOffset: number): string | undefined {
	const doc = parseDocument(documentText);

	if (!isNode(doc.contents)) {
		console.warn('Document is not a node');
		return;
	}

	const keyPositions = extractKeyPositions(doc.contents);

	const sortedKeyPositions = sortBy(keyPositions, 'endOffset');

	const keyPositionsBeforeOffset = filter(sortedKeyPositions, (position) => position.startOffset <= cursorOffset);

	if (isEmpty(keyPositionsBeforeOffset)) {
		console.warn('No key positions before offset', sortedKeyPositions);
		return;
	}

	const lastKey = last(keyPositionsBeforeOffset);

	if (lastKey && lastKey.endOffset > cursorOffset) {
		console.warn('Last key is beyond offset', lastKey);
		return;
	}

	return lastKey?.key;
}

function extractKeyPositions(
	node: Node,
	path: string[] = []
): {
	key: string;
	startOffset: number;
	endOffset: number;
}[] {
	if (isMap(node)) {
		return extractKeyPositionsOfMap(node, path);
	}

	if (isSeq(node)) {
		return flatMap(node.items, (item) => (isNode(item) ? extractKeyPositions(item, path) : []));
	}

	return [];
}

function extractKeyPositionsOfMap(node: YAMLMap, path: string[] = []) {
	return flatMap(node.items, (item) => {
		const keyNode = item.key;
		const valueNode = item.value;
		const keyPositions = [];

		if (isNode(valueNode) && keyNode) {
			keyPositions.push(...extractKeyPositions(valueNode, path.concat(keyNode.toString())));
		}

		if (isNode(keyNode) && keyNode.range) {
			keyPositions.push({
				key: path.concat(keyNode.toString()).join('.'),
				startOffset: keyNode.range[0],
				endOffset: keyNode.range[1],
			});
		}

		return keyPositions;
	});
}
