import { useCallback, useMemo, useState } from 'react';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';
import { useReportEvent } from 'src/services/analytics';
import { Abc16, Boolean16, HashMark16, SlidersVertical16 } from '@components/Icons';
import { DrilldownCard } from './DrillDownCard';
import Input from '@components/Input';
import { AdvancedSelect } from '@components/AdvancedSelect';
import { SelectOption } from '@components/Select/types';
import { ParameterType, ParameterValues, ValueTypes } from 'src/generated/graphql';
import { Parameter } from '../../../../types/parameter';
import { useParameters } from '../../../../services/useParameters';

type ListValue = { label?: string | null; value: string };
type EditParameterProps = {
	parameter: Parameter;
	setSelectedValue: (value: string) => void;
	onCancelEdit: () => void;
};

function getListValues(values?: ParameterValues | null): ListValue[] | undefined {
	if (values?.__typename === 'ListValues') {
		return values?.listValues;
	}
}

function ParameterSelectOneFromList({ parameter, setSelectedValue, onCancelEdit }: EditParameterProps) {
	const parameterDef = parameter.definition;
	const value = parameter.value ?? parameterDef.default;
	const values = getListValues(parameterDef.values);
	const onSelectParameterValue = useCallback(
		({ value }: SelectOption) => {
			setSelectedValue(value);
		},
		[setSelectedValue]
	);

	const options = values?.map((value) => ({ label: value.label, value: value.value }));
	const initialValue = options?.find((option) => option.value === value);

	return (
		<AdvancedSelect
			options={options}
			initialValue={initialValue}
			onChange={onSelectParameterValue}
			placeholder={initialValue?.label ?? ''}
			onBlur={onCancelEdit}
		/>
	);
}

function ParameterInput({ parameter, setSelectedValue, onCancelEdit }: EditParameterProps) {
	const parameterDef = parameter.definition;
	const value = parameter.value ?? parameterDef.default;
	const [currenValue, setCurrentValue] = useState(value);

	const valuesType = parameterDef.values?.__typename;
	const range = valuesType === 'RangeValues' ? parameterDef.values?.range?.[0] : undefined;

	const onChange = useCallback((value: string) => {
		setCurrentValue(value);
	}, []);

	const onSubmitAny = useCallback(() => {
		setSelectedValue(currenValue);
	}, [currenValue, setSelectedValue]);

	const onSubmitRange = useCallback(() => {
		const { from, to } = range ?? { from: Number.NEGATIVE_INFINITY, to: Number.POSITIVE_INFINITY };
		const asNumber: number = parseFloat(currenValue);
		if (asNumber >= from && asNumber <= to) {
			setSelectedValue(currenValue);
		}
	}, [currenValue, range, setSelectedValue]);

	return (
		<Input
			size={'inline'}
			type={parameterDef.type === ParameterType.Number ? 'number' : 'text'}
			width={'100%'}
			value={currenValue}
			onChange={onChange}
			onEnter={range ? onSubmitRange : onSubmitAny}
			onBlur={onCancelEdit}
		/>
	);
}

function ParameterEditValueContent({
	parameter,
	setSelectedValue,
	onCancelEdit,
}: EditParameterProps & { parameter: Parameter }) {
	if (parameter.definition.value_types === ValueTypes.List) {
		return (
			<ParameterSelectOneFromList
				parameter={parameter}
				setSelectedValue={setSelectedValue}
				onCancelEdit={onCancelEdit}
			/>
		);
	}

	return <ParameterInput parameter={parameter} setSelectedValue={setSelectedValue} onCancelEdit={onCancelEdit} />;
}

function ParameterIcon({ parameter }: { parameter: Parameter }) {
	switch (parameter.definition.type) {
		case ParameterType.Bool:
			return <Boolean16 />;

		case ParameterType.Number:
			return <HashMark16 />;

		case ParameterType.String:
			return <Abc16 />;

		default:
			return <SlidersVertical16 />;
	}
}

export function ParameterDrilldownCard({ parameter }: { parameter: Parameter }) {
	const { reportEvent } = useReportEvent();
	const { searchParams, setPartialSearchParams } = useMetricPageSearchParams();
	const { getParameterDisplayValue } = useParameters();
	const [isEditing, setIsEditing] = useState(false);

	const parameterDef = parameter.definition;
	const title = parameterDef.label ?? parameterDef.name;
	const subtitle = getParameterDisplayValue(parameter.definition.name);

	const parameters = useMemo(() => searchParams.parameters ?? {}, [searchParams.parameters]);
	const isParameterSet = useMemo(() => parameters[parameterDef.name], [parameterDef, parameters]);

	const onRemove = useCallback(() => {
		delete parameters[parameterDef.name];
		setPartialSearchParams({ parameters: Object.keys(parameters).length > 0 ? parameters : undefined });
		reportEvent({
			event: 'edit-metric-parameters-value-changed',
			metaData: { parameter: parameterDef.name, value: null },
		});
	}, [parameterDef, parameters, reportEvent, setPartialSearchParams]);

	const onCancelEdit = useCallback(() => {
		setIsEditing(false);
	}, []);

	const setSelectedValue = useCallback(
		(selectedValue: string) => {
			setIsEditing(false);
			if (selectedValue) {
				const parameters = searchParams.parameters ?? {};
				parameters[parameterDef.name] = selectedValue;
				setPartialSearchParams({ parameters });
				reportEvent({
					event: 'edit-metric-parameters-value-changed',
					metaData: { parameter: parameterDef.name, value: selectedValue },
				});
			}
		},
		[parameterDef, reportEvent, searchParams, setPartialSearchParams]
	);

	const onEditParameter = useCallback(() => {
		setIsEditing(true);
		reportEvent({ event: 'edit-metric-parameters-clicked', metaData: { parameter: parameterDef.name } });
	}, [parameterDef.name, reportEvent]);

	return (
		<DrilldownCard
			title={title}
			value={!isEditing ? subtitle : undefined}
			valueEditor={
				isEditing && (
					<ParameterEditValueContent
						parameter={parameter}
						setSelectedValue={setSelectedValue}
						onCancelEdit={onCancelEdit}
					/>
				)
			}
			icon={<ParameterIcon parameter={parameter} />}
			removeTooltip={`Revert to default (${parameter.definition.default})`}
			onRemove={isParameterSet ? onRemove : undefined}
			onClick={onEditParameter}
		/>
	);
}
