import { Flex } from '@chakra-ui/react';
import { WavingHand16 } from 'src/common/components/Icons';
import { ActionVariants, PromptActions } from 'src/common/components/MetricView/InteractiveMetricChat/normalizeHistory';
import Typography from 'src/common/components/Typography';
import colors from 'src/style/colors';
import { ActionChips } from './ActionChips';

export function InfoBanner({
	onChipClick,
}: {
	onChipClick: (value: string, chipKind: ActionVariants, chipDisplayName: PromptActions) => void;
}) {
	return (
		<Flex
			background={colors.white}
			alignItems={'center'}
			marginTop="12px"
			padding="12px"
			borderRadius={'4px'}
			border="1px solid rgba(233, 239, 246, 1)"
		>
			<Flex direction="column" gap="12px">
				<Flex direction="row" gap="12px" alignItems={'center'} justifyContent="space-between" width={'fit-content'}>
					<WavingHand16 />
					<Typography variant="DesktopH8Medium">{"Hello, I'm your AI-assistant."}</Typography>
				</Flex>
				<Typography color={colors.gray[700]} variant="DesktopH10Regular">
					{'Ideas for you'}
				</Typography>
				<ActionChips
					variant="templates"
					onChipClick={(value: string, chipDisplayName: string) => onChipClick(value, 'templates', chipDisplayName)}
				/>
			</Flex>
		</Flex>
	);
}
