import Box from '@components/Box';
import Center from '@components/Center';
import Flex from '@components/Flex';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { AddSmall16, CloseMedium16 } from '@icons/index';
import classNames from 'classnames';
import { ListType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import colors from 'src/style/colors';
import classes from '../LeftExpandCollapsePanel.module.scss';

function Title({
	title,
	onRemove,
	removeTooltip,
	icon,
}: {
	title: string;
	onRemove?: VoidFunction;
	removeTooltip?: string;
	icon: React.ReactNode;
}) {
	return (
		<Flex justifyContent={'space-between'}>
			<Flex alignItems={'center'}>
				<Box paddingRight={'8px'} color={'emerald.500'}>
					{icon}
				</Box>
				<Typography variant={'DesktopH8Bold'} textOverflow="ellipsis" noOfLines={1} className={classes.titleWordWrap}>
					{title}
				</Typography>
			</Flex>
			{onRemove && (
				<Flex alignItems={'center'}>
					<Tooltip
						size={'md'}
						label={removeTooltip}
						variant="fluid"
						placement={'right'}
						marginLeft={'4px'}
						as={'div'}
						shouldWrapChildren={false}
					>
						<button
							onClick={(event) => {
								event.stopPropagation();
								onRemove?.();
							}}
							className={classes.closeButton}
						>
							<Center>
								<CloseMedium16 />
							</Center>
						</button>
					</Tooltip>
				</Flex>
			)}
		</Flex>
	);
}

function Subtitle({ subtitle, onClick }: { subtitle: string; onClick?: VoidFunction }) {
	return (
		<Box textAlign={'left'} height={'20px'} marginTop={'4px'} width={'100%'} onClick={onClick} cursor="pointer">
			<Typography
				variant={'Paragraph12R'}
				color={'gray.700'}
				textOverflow={'ellipsis'}
				whiteSpace={'nowrap'}
				overflow={'hidden'}
				as={'div'}
				width={'100%'}
			>
				{subtitle}
			</Typography>
		</Box>
	);
}

export function DrilldownCard({
	title,
	icon,
	value,
	valueEditor,
	tooltip,
	removeTooltip,
	onRemove,
	onClick,
}: {
	title: string;
	value?: string | null;
	valueEditor?: React.ReactNode;
	tooltip?: string;
	icon: React.ReactNode;
	removeTooltip?: string;
	onRemove?: VoidFunction;
	onClick?: VoidFunction;
}) {
	return (
		<Box paddingBottom={'8px'}>
			<Tooltip size={'md'} label={tooltip} variant="fluid" placement={'right'}>
				<Flex className={classes.card} p={'11px 11px 11px 15px'} direction={'column'} flex={1} overflow={'hidden'}>
					<Title removeTooltip={removeTooltip} icon={icon} title={title} onRemove={onRemove} />
					{value && <Subtitle onClick={onClick} subtitle={value} />}
					{valueEditor}
				</Flex>
			</Tooltip>
		</Box>
	);
}

export function AddDrillDownCard({
	type,
	onClick,
	isDisabled,
	dataIntercomTarget,
}: {
	type: ListType;
	onClick: (type: ListType) => void;
	isDisabled?: boolean;
	dataIntercomTarget?: string;
}) {
	return (
		<Flex
			className={classNames(classes.card, classes.addCard, {
				[classes.isDisabled]: isDisabled,
			})}
			p={'11px 11px 11px 15px'}
			alignItems={'center'}
			onClick={() => !isDisabled && onClick(type)}
			cursor={'pointer'}
			data-intercom-area={'metric'}
			data-intercom-type={'button'}
			data-intercom-target={dataIntercomTarget}
		>
			<AddSmall16 color={isDisabled ? colors.gray[300] : colors.gray[600]} />
			<Typography variant={'DesktopH8Bold'} paddingLeft={'8px'}>
				Add {type}
			</Typography>
		</Flex>
	);
}

export function DisabledBreakdownCard() {
	return (
		<Flex className={classes.disabledCard} p={'11px 11px 11px 15px'} alignItems={'center'} cursor={'default'}>
			<Typography variant={'Paragraph12R'} color={colors.gray['600']}>
				This metric doesn&apos;t provide breakdowns.
			</Typography>
		</Flex>
	);
}
