import { useEffect } from 'react';
import Center from '../Center';

export function PopupLoginSuccess() {
	useEffect(() => {
		setTimeout(() => {
			window.close();
		}, 800);
	}, []);

	return (
		<Center height="calc(100vh)" width="calc(100vw)" display={'flex'}>
			Thank you for logging in. You can close this window now.
		</Center>
	);
}
