import { editor, languages, Position } from 'monaco-editor';
import { CST } from 'yaml';
import { CompletionProvider } from '../completionProvider';
import { EnrichedSemanticDefinitions } from '../semanticTypes';
import { AUTO_COMPLETE_YAML_KEYS } from '../types';
import { getPartAndExpressionRanges, rangeToPreviousCharacter } from '../utils/utils';
import { getContextCompletionItem } from '../widgetBuilder/completionContextTooltip';

export const MonacoCompletionItemProviderBuilder = (
	semanticDefinitions: EnrichedSemanticDefinitions
): languages.CompletionItemProvider => ({
	provideCompletionItems: buildMonacoCompletionProvider(semanticDefinitions),
	triggerCharacters: ['.', '$', '('],
});

const buildMonacoCompletionProvider =
	(semanticDefinitions: EnrichedSemanticDefinitions) => (model: editor.ITextModel, position: Position) => {
		const { partRange, expressionRange, expression } = getPartAndExpressionRanges({ model, position });
		const hasDollarBeforeExpression = model.getValueInRange(rangeToPreviousCharacter(expressionRange)) == '$';

		const completion = new CompletionProvider(semanticDefinitions);

		const tokenPath = completion.getTokenPath(model.getValue(), model.getOffsetAt(position));
		if (!tokenPath) {
			console.error('Failed to get document path', expression);
			return;
		}
		const lastYamlKey = tokenPath[tokenPath.length - 2];
		const isYamlKeySuitableForAutocomplete =
			CST.isScalar(lastYamlKey) && AUTO_COMPLETE_YAML_KEYS.includes(lastYamlKey.source);
		if (!isYamlKeySuitableForAutocomplete) {
			console.info('Refusing to autocomplete based on key', lastYamlKey, 'path', tokenPath);
			return;
		}

		const isIncludingMetricsInSuggestions = !completion.isOntologyContext(model.getValue());

		const context = completion.createStartingContext(tokenPath, model.getValue());

		const completionContexts = completion.walkContextForCompletions(
			context,
			expression,
			expression.length,
			isIncludingMetricsInSuggestions,
			completion.isOntologyContext(model.getValue())
		);

		const completionItems = completionContexts.map((context) =>
			getContextCompletionItem(context, partRange, hasDollarBeforeExpression ? '' : '$', false)
		);

		return {
			suggestions: completionItems,
		};
	};
