import Flex from '@components/Flex';
import { PeriodRange } from '@sightfull/period-ranges';
import { useEffect, useState } from 'react';
import SightfullLoading from 'src/assets/gifs/SightfullLoading.gif';
import Typography from 'src/common/components/Typography';
import { Breakdown, Filter } from 'src/pages/MetricPage/utils/state.types';
import colors from 'src/style/colors';

type Props = {
	periodRange: PeriodRange;
	filters: Filter[];
	breakdowns: Breakdown[];
};

const timeBetweenPhrases = 5000;

export function MetricViewLoading({ periodRange, filters, breakdowns }: Props) {
	const [phraseIndex, setPhraseIndex] = useState<number>(0);

	const loadingPhrases = [`Collecting data for ${periodRange.durationWithUnitString}`];
	loadingPhrases.push('Aggregating your business metrics');

	if (filters.length !== 0) {
		loadingPhrases.push('Applying filters');
	}

	if (breakdowns.length !== 0) {
		loadingPhrases.push('Applying breakdowns');
	}

	loadingPhrases.push('Crunching your numbers', 'We are working on it', 'Your request will be ready in a few moments');

	useEffect(() => {
		if (phraseIndex == loadingPhrases.length - 1) {
			return;
		}
		const interval = setInterval(() => {
			setPhraseIndex(phraseIndex + 1);
		}, timeBetweenPhrases);
		return () => clearInterval(interval);
	});

	return (
		<Flex flexDirection="column" alignItems="center" width="0" minWidth="100%">
			<img src={SightfullLoading} alt="Sightfull Loading" width="50%" />
			<Typography variant="DesktopH6" paddingBottom="12px" marginTop={'-9%'}>
				We are preparing your data
			</Typography>

			<Typography color={colors.gray[800]} variant="DesktopH7Regular" isLoadingAnimation={true}>
				{loadingPhrases[phraseIndex]}
			</Typography>
		</Flex>
	);
}
