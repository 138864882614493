import Hubspot16 from 'src/assets/images/hubspot-16.svg';
import Salesforce16 from 'src/assets/images/salesforce-16.svg';
import { OkCircle16, RemoveCircleOutline16, Undo16 } from 'src/common/components/Icons';
import { SupportedDataSources } from './types';

export const tabsStatuses = ['Open', 'Dismissed', 'Resolved', 'Fixed'] as const;
export const filterOptions = ['Account', 'Opportunity', 'Opportunity line items'];
export const severitiesOptions = [
	{
		label: 'All severities',
		value: '-1',
	},
	{
		label: 'Low severity',
		value: '3',
	},
	{
		label: 'Medium severity',
		value: '2',
	},
	{
		label: 'High severity',
		value: '1',
	},
];

export const actionsInStatus = {
	Open: [
		{ label: 'Resolve issue', detection_status: 'Resolved', icon: <OkCircle16 /> },
		{ label: 'Dismiss issue', detection_status: 'Dismissed', icon: <RemoveCircleOutline16 /> },
	],
	Dismissed: [{ label: 'Reopen issue', detection_status: 'Open', icon: <Undo16 /> }],
	Resolved: [{ label: 'Reopen issue', detection_status: 'Open', icon: <Undo16 /> }],
	Fixed: [{ label: 'Reopen issue', detection_status: 'Open', icon: <Undo16 /> }],
};

export const dataSourceIcons: { [key in SupportedDataSources]: JSX.Element | null } = {
	Salesforce: <img src={Salesforce16} alt="Salesforce" />,
	Hubspot: <img src={Hubspot16} alt="Hubspot" />,
	Sightfull: null,
};
