import { AxiosPromise, AxiosRequestConfig } from 'axios';
import useAxios, { Options as AxiosOptions, ResponseValues } from 'axios-hooks';
import { useCallback } from 'react';

type UseRequestProps = {
	url?: string;
	method?: string;
	body?: Record<string, any>;
	headers?: Record<string, any>;
	baseURL?: string;
	options?: AxiosOptions;
	timeoutms?: number;
};

export type UseRequestReturnType<TResponse = any, TBody = any, TError = any> = [
	ResponseValues<TResponse, TBody, TError>,
	{
		refetch: (config?: AxiosRequestConfig<TBody>) => AxiosPromise<TResponse>;
		cancel: () => void;
	}
];

function useRequest<Response = any>({
	url,
	method,
	body,
	headers,
	baseURL,
	options,
	timeoutms = 30000,
}: UseRequestProps): UseRequestReturnType<Response> {
	const [{ data, loading, error }, refetch, cancel] = useAxios<Response>(
		{
			baseURL,
			url,
			method,
			data: body,
			timeout: timeoutms,
			responseType: 'json',
			headers: headers,
		},
		options
	);

	const refetchWithCache = useCallback(
		(config?: AxiosRequestConfig<any> | undefined) => refetch(config, { useCache: true }),
		[refetch]
	);
	return [
		{ data, loading, error },
		{ refetch: refetchWithCache, cancel },
	];
}

export default useRequest;
