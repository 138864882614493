import * as monaco from 'monaco-editor';
import { useEffect, useRef } from 'react';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import { MonacoCompletionItemProviderBuilder } from '../completionProvider/monacoCompletionProvider';
import { MonacoEntityCompletionItemProviderBuilder } from '../completionProvider/monacoEntityCompletionProvider';
import { MonacoHoverProviderBuilder } from '../hoverProvider/monacoHoverProvider';

export function useMonacoProviders() {
	const completionProviderRef = useRef<null | monaco.IDisposable>(null);
	const entityCompletionProviderRef = useRef<null | monaco.IDisposable>(null);
	const hoverProviderRef = useRef<null | monaco.IDisposable>(null);
	const { semanticDefinitions } = useSemanticDefinitions();

	useEffect(() => {
		if (!semanticDefinitions) return;
		completionProviderRef.current?.dispose();
		completionProviderRef.current = monaco.languages.registerCompletionItemProvider(
			'yaml',
			MonacoCompletionItemProviderBuilder(semanticDefinitions)
		);
		entityCompletionProviderRef.current?.dispose();
		entityCompletionProviderRef.current = monaco.languages.registerCompletionItemProvider(
			'yaml',
			MonacoEntityCompletionItemProviderBuilder(semanticDefinitions)
		);
		hoverProviderRef.current?.dispose();
		hoverProviderRef.current = monaco.languages.registerHoverProvider('yaml', {
			provideHover: MonacoHoverProviderBuilder(semanticDefinitions),
		});
		return () => {
			completionProviderRef.current?.dispose();
			hoverProviderRef.current?.dispose();
			entityCompletionProviderRef.current?.dispose();
		};
	}, [semanticDefinitions]);
}
