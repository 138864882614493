import Typography from '@components/Typography';
import { ICellRendererParams } from 'ag-grid-community';

//TODO: we need to improve the ICellRendererParams type (ICellRendererParams <****, Record<***, *** >>)
export function CellRenderer(clickable: boolean) {
	function Cell(params: ICellRendererParams) {
		let cellValue = params.valueFormatted || params.value;
		if (Array.isArray(cellValue)) cellValue = cellValue.join(', ');

		return (
			<Typography
				variant={'DesktopH10Regular'}
				color="gray.800"
				as={clickable ? 'button' : 'span'}
				_hover={{ textDecoration: clickable && 'underline' }}
				noOfLines={1}
				wordBreak="break-all"
			>
				{cellValue}
			</Typography>
		);
	}
	return Cell;
}
