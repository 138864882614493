import { RawNode, RawNodeRelationship } from 'src/common/hooks/fetching/useEntityFetchApi';
import { Property, RawEntitiesRelationshipTable } from '../../types';

type FormattedRawRelationships = {
	'>': Property[];
	'<': RawEntitiesRelationshipTable[];
};

const formatterByDirection: {
	'>': (rawNodeRelationship: RawNodeRelationship) => Property;
	'<': (rawNodeRelationship: RawNodeRelationship) => RawEntitiesRelationshipTable;
} = {
	'>': buildPropertyFromRelationship,
	'<': buildRelationshipEntitiesTable,
};

export function seperateRelationshipsByInOut({ rawNode }: { rawNode: RawNode }): {
	relatedEntities: RawEntitiesRelationshipTable[];
	properties: Property[];
} {
	const isObjectRelationship = ({ other_label, direction }: RawNodeRelationship) =>
		!(other_label == 'Metric' && direction == '>');

	const { '<': relatedEntities, '>': properties } = rawNode.relationships
		.filter(isObjectRelationship)
		.reduce<FormattedRawRelationships>(
			(acc, rawRelationship) => {
				const { direction } = rawRelationship;
				const formattedRelationship = formatterByDirection[direction](rawRelationship);

				const directionArr = acc[direction];
				const modifiedDirectionArr = [...directionArr, formattedRelationship];
				return { ...acc, [direction]: modifiedDirectionArr };
			},
			{
				'<': [],
				'>': [],
			}
		);

	return { relatedEntities, properties };
}

function buildPropertyFromRelationship(rawNodeRelationship: Omit<RawNodeRelationship, 'direction'>): Property {
	const node = rawNodeRelationship.nodes[0];
	const value = node.Name ?? node.id;

	return {
		value,
		rawName: rawNodeRelationship.other_label,
		displayName: displayNameFormatter(rawNodeRelationship.relationship_label),
		relationshipProperties: { sourceSystemId: String(node.id), sightfullId: String(node['T.id']) },
	};
}

export const columns = {
	search: {
		searchable: true,
		pretty: 'Campaign Touchpoints',
	},
	sort: {
		parser: 'string',
		field: 'id',
		direction: 'desc',
	},
	display: {
		type: 'CupertinoTable',
		config: {
			status: undefined,
			first: 3,
			title: 'id',
			subtitles: [
				{
					field: 'Name',
					prefix: 'Name',
				},
			],
		},
	},
} as const;

function buildRelationshipEntitiesTable(rawNodeRelationship: RawNodeRelationship): RawEntitiesRelationshipTable {
	return {
		relationshipDisplayName: displayNameFormatter(
			`${rawNodeRelationship.relationship_label} ${rawNodeRelationship.other_label}`
		),
		columns: { ...columns, entityType: rawNodeRelationship.other_label, for: `.${rawNodeRelationship.other_label}` },
		entityType: rawNodeRelationship.other_label,
		relatedEntities: rawNodeRelationship.nodes,
	};
}

function displayNameFormatter(rawDisplayName: string) {
	return rawDisplayName.replace(/([A-Z])|_/g, (_, capitalLetter) => (capitalLetter ? ` ${capitalLetter}` : ' ')).trim();
}
