import { ChartSeries, ChartType } from '@components/Chart/types';
import { buildChartSeriesFromData, TransposedMetricData, transposeMetricData } from 'src/lib/metricRules/utils';
import { AverageOp, DivideOp, MetricOperator, MultiplyOp } from 'src/models/MetricOperator';
import { MetricCalcResult } from 'src/types/metric';

export interface MetricVizRulesInput {
	op: MetricOperator;
	isSinglePeriod: boolean;
	hasComponents: boolean;
	hasGroupBy: boolean;
	isAllComponentSameUnit: boolean;
	metricNameWithFlavor: string;
	metricDisplayName: string;
}

interface VisualisationRuleFunctionOptions {
	transposedMetricData: TransposedMetricData;
	vizInput: MetricVizRulesInput;
}

type VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => ChartSeries[] | undefined;

export function runVisualisationRules({
	metricData,
	vizRulesInput,
}: {
	metricData: MetricCalcResult;
	vizRulesInput: MetricVizRulesInput;
}): ChartSeries[] {
	for (const currentRuleFunc of VisualisationRules) {
		const transposedMetricData = transposeMetricData(metricData);
		const result = currentRuleFunc({ transposedMetricData, vizInput: vizRulesInput });
		if (result) {
			return result;
		}
	}

	throw 'No Rule Found';
}

const ConcatRule: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	if (vizInput.hasGroupBy || !vizInput.op.isConcatable) {
		return;
	}

	const onlyChildren = transposedMetricData.filter((series) => series.type === 'COMPONENT');
	return onlyChildren.map((series) => buildChartSeriesFromData(series, 'column'));
};

const SinglePeriodComplexMetricNoGroupByWaterfallRule: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isSinglePeriodNoGroupBy = vizInput.isSinglePeriod && !vizInput.hasGroupBy;
	if (!isSinglePeriodNoGroupBy || !vizInput.hasComponents || !vizInput.op.isStackable) {
		return;
	}

	return transposedMetricData.map((series) => buildChartSeriesFromData(series, 'waterfall'));
};

const SinglePeriodSimpleMetricNoGroupByRule: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isSinglePeriodNoGroupBy = vizInput.isSinglePeriod && !vizInput.hasGroupBy;
	if (!isSinglePeriodNoGroupBy || vizInput.hasComponents || !vizInput.op.isStackable) {
		return;
	}

	return transposedMetricData.map((series) => buildChartSeriesFromData(series, 'column'));
};

const SinglePeriodComplexMetricNoGroupByChildDisabledRule: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isSinglePeriodNoGroupBy = vizInput.isSinglePeriod && !vizInput.hasGroupBy;
	if (!isSinglePeriodNoGroupBy || !vizInput.hasComponents || !(vizInput.op === MultiplyOp)) {
		return;
	}

	const onlyTotal = transposedMetricData.filter((series) => series.type === 'TOTAL');
	return onlyTotal.map((series) => buildChartSeriesFromData(series, 'column'));
};

const SinglePeriodComplexMetricNoGroupByAverageRule: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isSinglePeriodNoGroupBy = vizInput.isSinglePeriod && !vizInput.hasGroupBy;
	if (!isSinglePeriodNoGroupBy || !vizInput.hasComponents || !(vizInput.op === AverageOp)) {
		return;
	}

	return transposedMetricData.map((series) => buildChartSeriesFromData(series, 'column'));
};

const SinglePeriodSimpleMetricNoGroupBy: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isSinglePeriodNoGroupBy = vizInput.isSinglePeriod && !vizInput.hasGroupBy;
	if (!isSinglePeriodNoGroupBy || vizInput.hasComponents) {
		return;
	}

	return transposedMetricData.map((series) => buildChartSeriesFromData(series, 'column'));
};

const SinglePeriodComplexMetricWithGroupByDivisionRule: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isSinglePeriodWithGroupBy = !vizInput.isSinglePeriod || !vizInput.hasGroupBy;
	if (isSinglePeriodWithGroupBy || !vizInput.hasComponents || !(vizInput.op === DivideOp)) {
		return;
	}

	return transposedMetricData.map((series) => buildChartSeriesFromData(series, 'column'));
};

const SinglePeriodWithGroupBy: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isNotSinglePeriodWithGroupBy = !vizInput.isSinglePeriod || !vizInput.hasGroupBy;
	if (isNotSinglePeriodWithGroupBy) {
		return;
	}

	return transposedMetricData.map((series) => buildChartSeriesFromData(series, 'column'));
};

const MultiplePeriodStackableSimpleNoGroupBy: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isMultiplePeriodNoGroupBy = vizInput.isSinglePeriod || vizInput.hasGroupBy;
	if (isMultiplePeriodNoGroupBy || vizInput.hasComponents) {
		return;
	}

	return transposedMetricData.map((series) => buildChartSeriesFromData(series, 'column'));
};

const MultiplePeriodStackableComplexNoGroupBy: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isMultiplePeriodNoGroupBy = vizInput.isSinglePeriod || vizInput.hasGroupBy;
	if (isMultiplePeriodNoGroupBy || !vizInput.hasComponents || !vizInput.op.isStackable) {
		return;
	}

	return transposedMetricData.map((series) => {
		if (series.type === 'TOTAL') return buildChartSeriesFromData(series, 'line');
		return buildChartSeriesFromData(series, 'stackedColumn');
	});
};

const MultiplePeriodDivisionDiffUnitComplexNoGroupBy: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isMultiplePeriodNoGroupBy = vizInput.isSinglePeriod || vizInput.hasGroupBy;
	const isDivisionComponentDiffUnit =
		vizInput.hasComponents || !(vizInput.op === DivideOp) || vizInput.isAllComponentSameUnit;
	if (isMultiplePeriodNoGroupBy || isDivisionComponentDiffUnit) {
		return;
	}

	return transposedMetricData.map((series) => {
		if (series.type === 'TOTAL') return buildChartSeriesFromData(series, 'column');
		return buildChartSeriesFromData(series, 'line');
	});
};

const MultiplePeriodDivisionSameUnitComplexNoGroupBy: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isMultiplePeriodNoGroupBy = vizInput.isSinglePeriod || vizInput.hasGroupBy;
	const isDivisionComponentSameUnit =
		vizInput.hasComponents || !(vizInput.op === DivideOp) || !vizInput.isAllComponentSameUnit;
	if (isMultiplePeriodNoGroupBy || isDivisionComponentSameUnit) {
		return;
	}

	return transposedMetricData.map((series) => {
		if (series.type === 'TOTAL') return buildChartSeriesFromData(series, 'line');
		return buildChartSeriesFromData(series, 'column');
	});
};

const MultiplePeriodAverageComplexNoGroupBy: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isMultiplePeriodNoGroupBy = vizInput.isSinglePeriod || vizInput.hasGroupBy;
	if (isMultiplePeriodNoGroupBy || vizInput.hasComponents || !(vizInput.op === AverageOp)) {
		return;
	}

	return transposedMetricData.map((series) => buildChartSeriesFromData(series, 'line'));
};

const MultiplePeriodMultiplyComplexNoGroupBy: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isMultiplePeriodNoGroupBy = vizInput.isSinglePeriod || vizInput.hasGroupBy;
	if (isMultiplePeriodNoGroupBy || vizInput.hasComponents || !(vizInput.op === MultiplyOp)) {
		return;
	}

	// TODO: RETHINK THIS RULE, as this is supposed to use the child components op to know stuff
	return transposedMetricData.map((series) => buildChartSeriesFromData(series, 'column'));
};

const MultiplePeriodStackableSimpleWithGroupBy: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isMultiplePeriodWithGroupBy = vizInput.isSinglePeriod || !vizInput.hasGroupBy;
	if (isMultiplePeriodWithGroupBy || !vizInput.hasComponents || vizInput.op.isStackable) {
		return;
	}

	return transposedMetricData.map((series) => {
		if (series.type === 'TOTAL') return buildChartSeriesFromData(series, 'line');
		return buildChartSeriesFromData(series, 'column');
	});
};

const MultiplePeriodAverageSimpleWithGroupBy: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isMultiplePeriodWithGroupBy = vizInput.isSinglePeriod || !vizInput.hasGroupBy;
	if (isMultiplePeriodWithGroupBy || !vizInput.hasComponents || vizInput.op === AverageOp) {
		return;
	}

	return transposedMetricData.map((series) => buildChartSeriesFromData(series, 'column'));
};

const MultiplePeriodDivisionDiffUnitSimpleWithGroupBy: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isMultiplePeriodWithGroupBy = vizInput.isSinglePeriod || !vizInput.hasGroupBy;
	const isDivisionComponentDiffUnit =
		vizInput.hasComponents || !(vizInput.op === DivideOp) || vizInput.isAllComponentSameUnit;
	if (isMultiplePeriodWithGroupBy || isDivisionComponentDiffUnit) {
		return;
	}

	return transposedMetricData.map((series) => {
		if (series.type === 'TOTAL') return buildChartSeriesFromData(series, 'line');
		return buildChartSeriesFromData(series, 'column');
	});
};

const MultiplePeriodDivisionSameUnitSimpleWithGroupBy: VisualizationRuleFunction = ({
	transposedMetricData,
	vizInput,
}: VisualisationRuleFunctionOptions) => {
	const isMultiplePeriodWithGroupBy = vizInput.isSinglePeriod || !vizInput.hasGroupBy;
	const isDivisionComponentSameUnit =
		vizInput.hasComponents || !(vizInput.op === DivideOp) || !vizInput.isAllComponentSameUnit;
	if (isMultiplePeriodWithGroupBy || isDivisionComponentSameUnit) {
		return;
	}

	return transposedMetricData.map((series) => buildChartSeriesFromData(series, 'line'));
};

const CatchAll: VisualizationRuleFunction = ({ transposedMetricData, vizInput }: VisualisationRuleFunctionOptions) => {
	const chartType: ChartType = vizInput.op.isStackable && !vizInput.isSinglePeriod ? 'stackedColumn' : 'column';

	return transposedMetricData.map((series) => {
		if (series.type === 'TOTAL') return buildChartSeriesFromData(series, 'line');
		return buildChartSeriesFromData(series, chartType);
	});
};

const VisualisationRules: VisualizationRuleFunction[] = [
	ConcatRule,
	SinglePeriodComplexMetricNoGroupByWaterfallRule,
	SinglePeriodSimpleMetricNoGroupByRule,
	SinglePeriodComplexMetricNoGroupByChildDisabledRule,
	SinglePeriodComplexMetricNoGroupByAverageRule,
	SinglePeriodSimpleMetricNoGroupBy,
	SinglePeriodComplexMetricWithGroupByDivisionRule,
	SinglePeriodWithGroupBy,
	MultiplePeriodStackableSimpleNoGroupBy,
	MultiplePeriodStackableComplexNoGroupBy,
	MultiplePeriodDivisionDiffUnitComplexNoGroupBy,
	MultiplePeriodDivisionSameUnitComplexNoGroupBy,
	MultiplePeriodAverageComplexNoGroupBy,
	MultiplePeriodMultiplyComplexNoGroupBy,
	MultiplePeriodStackableSimpleWithGroupBy,
	MultiplePeriodAverageSimpleWithGroupBy,
	MultiplePeriodDivisionDiffUnitSimpleWithGroupBy,
	MultiplePeriodDivisionSameUnitSimpleWithGroupBy,
	CatchAll,
];
