import { useAtom } from 'jotai';
import { NavigationBlockAtom } from './atoms/useNavigationBlockAtom';

function useNavigationBlock() {
	const [isNavigationBlocked, setIsNavigationBlocked] = useAtom(NavigationBlockAtom);

	return { isNavigationBlocked, setIsNavigationBlocked };
}

export default useNavigationBlock;
