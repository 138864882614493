import { useCallback, useMemo, useState } from 'react';
import useSearchParams from 'src/common/hooks/navigation/useSearchParams';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { getDimensionKey } from 'src/lib/metricRules/utils';
import { NodeSchemeReturnType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/types';
import { useCoreNodeScheme } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { useGlobalNodeScheme } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useGlobalNodeScheme';
import { getDimensionNameByKey } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/utils';
import { FiltersAndBreakdownItemType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import useFiltersAndBreakdown, {
	FiltersAndBreakdownsActionsType,
	FiltersAndBreakdownsStateType,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/useFiltersAndBreakdown';
import { Filter } from 'src/pages/MetricPage/utils/state.types';
import { tryParseJson } from 'src/pages/MetricPage/utils/useNormalizedSearchParams';
import { useReportEvent } from 'src/services/analytics';

const GLOBAL_FILTERS_URL_PARAM = 'globalFilters';

function useGlobalFiltersUrl(): [Filter[], React.Dispatch<React.SetStateAction<Filter[]>>] {
	const [urlSearchParams, setUrlSearchParams] = useSearchParams();

	const globalFilters: Filter[] = useMemo(() => {
		const rawFilters = urlSearchParams.get(GLOBAL_FILTERS_URL_PARAM);
		if (!rawFilters) return [];

		const filters = tryParseJson(rawFilters);
		if (!Array.isArray(filters)) return [];

		const parsedFilters = filters.map((f) => ({
			label: f.l,
			key: f.k,
			values: f.v,
			type: 'text' as const,
		}));

		return parsedFilters;
	}, [urlSearchParams]);

	const setGlobalFilters = useCallback(
		(filters: Filter[] | ((prevState: Filter[]) => Filter[])) => {
			const newFilters = typeof filters === 'function' ? filters(globalFilters) : filters;
			const cleanedNewFilters = newFilters.map((f) => ({
				l: f.label,
				k: f.key,
				v: f.values,
			}));
			const filtersString = JSON.stringify(cleanedNewFilters);

			const newSearchParams = new URLSearchParams(urlSearchParams.toString());
			if (newFilters.length === 0) {
				newSearchParams.delete(GLOBAL_FILTERS_URL_PARAM);
			} else {
				newSearchParams.set(GLOBAL_FILTERS_URL_PARAM, filtersString);
			}

			setUrlSearchParams(newSearchParams);
		},
		[globalFilters, setUrlSearchParams, urlSearchParams]
	);

	return [globalFilters, setGlobalFilters];
}

export function useGlobalFilters(): [
	[FiltersAndBreakdownsStateType, FiltersAndBreakdownsActionsType, NodeSchemeReturnType, Set<string>, Filter[]],
	{
		onAddFilterObjects: (objectTypes: string[]) => void;
		resetGlobalFilters: ({ isResetingFilterNodeTypes }: { isResetingFilterNodeTypes: boolean }) => void;
		addGlobalFilter: (items: FiltersAndBreakdownItemType[]) => void;
		removeGlobalFilter: (key: string) => void;
	}
] {
	const { reportEvent } = useReportEvent();
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const [globalFilters, setGlobalFilters] = useGlobalFiltersUrl();
	const [modalState, modalActions] = useFiltersAndBreakdown();
	const [filterNodeTypes, setFilterNodeTypes] = useState(new Set<string>());
	const nodeScheme = useGlobalNodeScheme([...filterNodeTypes]);
	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes: [...filterNodeTypes],
		schemeType: 'global',
		isIncludingTypePrefix: true,
		/* TA1.0: pulse will send a new RelationshipsAndDimensions request for every new loaded widget. We only save the first [] one.
		 * 				Might be able to improve after filters-breakdowns migrations */
		readyToFetch: filterNodeTypes.size > 0,
	});

	const onAddFilterObjects = useCallback((objectTypes: string[]) => {
		setFilterNodeTypes((filterObjects) => new Set([...filterObjects, ...objectTypes]));
	}, []);

	const resetGlobalFilters = useCallback(
		({ isResetingFilterNodeTypes }: { isResetingFilterNodeTypes: boolean }) => {
			if (isResetingFilterNodeTypes) setFilterNodeTypes(new Set());
			setGlobalFilters([]);
		},
		[setGlobalFilters]
	);

	const addGlobalFilter = (items: FiltersAndBreakdownItemType[]) => {
		if (!items.length) {
			return;
		}

		const newFilter: Filter = {
			label: isSightfull2 ? items[0].key : getDimensionNameByKey(getDimensionKey(items[0].key)),
			key: items[0].key,
			values: items.map((i) => i.value),
			type: 'text',
		};
		setGlobalFilters((f) => [...f, newFilter]);

		reportEvent({
			event: 'dashboard-global-filter-apply',
			metaData: { filterKey: newFilter.key },
		});
	};

	const removeGlobalFilter = (key: string) => {
		setGlobalFilters((filters) => filters.filter((f) => f.key != key));
	};

	return [
		[modalState, modalActions, isSightfull2 ? coreNodeScheme : nodeScheme, filterNodeTypes, globalFilters],
		{ onAddFilterObjects, resetGlobalFilters, addGlobalFilter, removeGlobalFilter },
	];
}
