import { TabProps } from 'src/common/components/Tabs/types';
import { DataIssuesGetAllQuery } from 'src/generated/graphql';
import { actionsInStatus, dataSourceIcons, filterOptions } from '../constants';
import { ActionData, NormalizedFields, NormalizedIssuesByStats, SupportedDataSources, TabsStatuses } from '../types';

export const getFormattedTabs = (issues: DataIssuesGetAllQuery['data_issues'] | null | undefined): TabProps[] => {
	const defaultLabels = {
		Open: 0,
		Dismissed: 0,
		Resolved: 0,
		Fixed: 0,
	};

	const statusesCount = issues?.reduce<{ [n in TabsStatuses]: number }>((acc, issue) => {
		const { detection_status } = issue;
		const prevCount = acc?.[detection_status as TabsStatuses];
		return { ...acc, [detection_status]: prevCount + 1 };
	}, defaultLabels);

	return Object.entries(statusesCount ?? defaultLabels).map(([status, count]) => {
		return { text: `${status} (${count})`, isDisabled: count == 0 };
	});
};

export const getFormattedIssues = (
	issues: DataIssuesGetAllQuery['data_issues'] | null,
	updateIssue: (actionData: ActionData, object_source_url: string, object_datasource: string) => void
): NormalizedIssuesByStats | null => {
	if (!issues) {
		return null;
	}

	return issues.reduce<{ [n in TabsStatuses]: Array<NormalizedFields> }>(
		(acc, issue) => {
			const {
				tid,
				issue_key,
				object_id,
				object_name,
				severity,
				object_type,
				dimension,
				issue_group_name,
				detection_status,
				object_current_value,
				issue_grace_expiration,
				object_datasource,
				object_source_url,
				object_id_on_graph,
			} = issue;

			const name = { object_name, issue_group_name, severity };
			const actionData = { issue_key, object_id, tid, issue_grace_expiration };

			const issueActions = actionsInStatus[detection_status as TabsStatuses].map(
				({ detection_status, ...curAction }) => {
					return {
						...curAction,
						onClick: () =>
							updateIssue(
								{ ...actionData, detection_status: detection_status as TabsStatuses },
								object_source_url ?? '',
								object_datasource ?? ''
							),
					};
				}
			);

			const icon = dataSourceIcons?.[object_datasource as SupportedDataSources] || undefined;
			const objectSource = { object_datasource, object_source_url, icon };
			const formattedIssue = {
				name,
				object_type,
				dimension,
				object_current_value,
				detection_status,
				object_id_on_graph,
				actions: { issueActions, objectSource, detection_status },
			};

			const issuesWithStatus = acc[detection_status as TabsStatuses];
			return { ...acc, [detection_status]: [...issuesWithStatus, formattedIssue] };
		},
		{
			Open: [],
			Dismissed: [],
			Resolved: [],
			Fixed: [],
		}
	);
};

export const filterIssues = ({
	normalizedDataIssuesInStatus,
	selectedSeverity,
	selectedFilters,
}: {
	normalizedDataIssuesInStatus: NormalizedFields[] | null;
	selectedSeverity: string;
	selectedFilters: number[];
}) => {
	if (!normalizedDataIssuesInStatus) {
		return null;
	}

	const isInSevirityFilter = (severity: number) => {
		const isNoSeveritySelected = selectedSeverity == '-1';
		return isNoSeveritySelected || selectedSeverity == String(severity);
	};

	const isInTypeFilter = (object_type: string) => {
		const isNoFilterSelected = selectedFilters.length == 0;
		return isNoFilterSelected || selectedFilters.some((filter) => filterOptions[filter] == object_type);
	};
	return normalizedDataIssuesInStatus.filter(
		({ object_type, name: { severity } }) => isInTypeFilter(object_type) && isInSevirityFilter(severity)
	);
};
