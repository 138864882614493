import Flex from '@components/Flex';
import React from 'react';

type NotificationBarProps = {
	testId?: string;
	children: React.ReactNode;
};

export const NotificationBarHeight = 56;

function NotificationBar({ children, testId }: NotificationBarProps) {
	return (
		<Flex
			data-testid={testId}
			maxH={`${NotificationBarHeight}px`}
			py="10px"
			px="12px"
			bg="linear-gradient(77deg, #4A72FF 0%, #1B4DFF 98.16%);"
			alignItems="center"
			justifyContent="center"
		>
			{children}
		</Flex>
	);
}

export default NotificationBar;
