import { Badge } from 'antd';
import IconButton from 'src/common/components/IconButton';
import { AddComment16, Message16 } from 'src/common/components/Icons';
import Tooltip from 'src/common/components/Tooltip';
import colors from 'src/style/colors';

const badgeStyle: React.CSSProperties = { borderRadius: '4px' };

export function CommentsButton({ commentCount, onClick }: { commentCount: number; onClick: () => void }) {
	const hasComments = commentCount > 0;

	const icon = (
		<Tooltip bg="black" color={colors.gray[100]} variant="fluid" size="md" label="Open comments">
			<IconButton
				colorScheme="black"
				ariaLabel="Comments"
				onClick={onClick}
				icon={hasComments ? <Message16 /> : <AddComment16 />}
			/>
		</Tooltip>
	);
	if (!hasComments) return icon;

	return (
		<Badge offset={['-4px', '4px']} style={badgeStyle} color={colors.blue[600]} count={commentCount}>
			{icon}
		</Badge>
	);
}
