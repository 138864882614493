import { ChartType, SupportedChartTypes } from '@components/Chart/types';
import { PeriodRangeInUrl, RelativePeriodKey, RelativePeriodKeys } from '@sightfull/period-ranges';
import { useMemo } from 'react';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { SupportedSortOrders } from 'src/pages/MetricPage/components/LegendsPanel/types';

export function useNormalizedSearchParams({
	searchParams: rawSearchParams,
}: {
	searchParams: URLSearchParams;
}): MetricSearchParams {
	const rawSp: any = Object.fromEntries([...rawSearchParams]);

	const from = rawSp['from'];
	const to = rawSp['to'];
	const selectedPeriod = rawSp['selectedPeriod'];
	const relativePeriod = rawSp['relativePeriod'];

	const rawSelectedXAxisElements = rawSp['selectedXAxisElements'];
	const selectedXAxisElements = useMemo(
		() => parseSelectedXAxisElements(rawSelectedXAxisElements),
		[rawSelectedXAxisElements]
	);

	const selectedDimensionName = rawSp['selectedDimensionName'];

	const rawCollectProps = rawSp['collectProps'];
	const collectProps = useMemo(() => tryParseJson(rawCollectProps), [rawCollectProps]);

	const rawFilterBy = rawSp['filterBy'];
	const filterBy = useMemo(() => tryParseJson(rawFilterBy), [rawFilterBy]);

	const rawParameters = rawSp['parameters'];
	const parameters = useMemo(() => tryParseJson(rawParameters), [rawParameters]);

	const rawGroupBy = rawSp['groupBy'];
	const groupBy = useMemo(() => tryParseJson(rawGroupBy), [rawGroupBy]);

	const rawSpLegends = rawSp['hiddenLegendNames'];
	const hiddenLegendNames = useMemo(() => tryParseJson(rawSpLegends), [rawSpLegends]);

	const rawDisplayUnits = rawSp['displayUnits'];
	const displayUnits = useMemo(() => tryParseJson(rawDisplayUnits), [rawDisplayUnits]);

	const rawTopN = rawSp['topN'];
	const topN = useMemo(() => tryParseJson(rawTopN), [rawTopN]);

	const rawTopNIndices = rawSp['topNIndices'];
	const topNIndices = useMemo(() => tryParseJson(rawTopNIndices), [rawTopNIndices]);

	const rawChartType = rawSp['chartType'];
	const chartType = useMemo(() => parseChartType(rawChartType), [rawChartType]);

	const rawFlippedAxis = rawSp['flippedAxis'];
	const flippedAxis = useMemo(() => tryParseJson(rawFlippedAxis), [rawFlippedAxis]);

	const rawExecutiveView = rawSp['executiveView'];
	const executiveView = useMemo(() => tryParseJson(rawExecutiveView), [rawExecutiveView]);

	const rawShowTargets = rawSp['showTargets'];
	const showTargets = useMemo(() => tryParseJson(rawShowTargets), [rawShowTargets]);

	const rawSpStatisticsOperations = rawSp['statisticsOperations'];
	const statisticsOperations = useMemo(() => tryParseJson(rawSpStatisticsOperations), [rawSpStatisticsOperations]);

	const rawRelativePeriodDuration = rawSp['relativePeriodDuration'];
	const relativePeriodDuration = useMemo(
		() => parseRelativePeriodDuration(rawRelativePeriodDuration),
		[rawRelativePeriodDuration]
	);

	const relativePeriodFrom = tryParseJson(rawSp['relativePeriodFrom']);
	const relativePeriodTo = tryParseJson(rawSp['relativePeriodTo']);

	const rawPeriodRange = rawSp['periodRange'];
	const periodRange = useMemo(() => parsePeriodRange(rawPeriodRange), [rawPeriodRange]);

	const rawTableColumnState = rawSp['tableColumnState'];
	const tableColumnState = useMemo(() => tryParseJson(rawTableColumnState), [rawTableColumnState]);

	const rawOrderedComponents = rawSp['orderedComponents'];
	const orderedComponents = useMemo(() => tryParseJson(rawOrderedComponents), [rawOrderedComponents]);
	const rawSortOrder = rawSp['sortOrder'];
	const sortOrder = useMemo(() => parseSortOrder(rawSortOrder), [rawSortOrder]);

	const rawDecimalDigits = Number(rawSp['decimalDigits']);
	const decimalDigits = useMemo(
		() => (Number.isInteger(rawDecimalDigits) ? rawDecimalDigits : undefined),
		[rawDecimalDigits]
	);

	const rawYAxisConfig = rawSp['yAxisConfig'];
	const yAxisConfig = useMemo(() => tryParseJson(rawYAxisConfig), [rawYAxisConfig]);

	const rawPageMode = rawSp['pageMode'];
	const pageMode = useMemo(() => rawPageMode, [rawPageMode]);

	const rawShowSQLQueryView = rawSp['showSQLQueryView'] === 'true';
	const showSQLQueryView = useMemo(() => (rawShowSQLQueryView === true ? true : undefined), [rawShowSQLQueryView]);

	const normalizedSearchParams: MetricSearchParams = useMemo(
		() => ({
			from,
			to,
			selectedPeriod,
			relativePeriod,
			selectedXAxisElements,
			selectedDimensionName,
			collectProps,
			filterBy,
			parameters,
			groupBy,
			hiddenLegendNames,
			displayUnits,
			topN,
			topNIndices,
			chartType,
			flippedAxis,
			executiveView,
			showTargets,
			statisticsOperations,
			relativePeriodDuration,
			relativePeriodFrom,
			relativePeriodTo,
			periodRange,
			tableColumnState,
			decimalDigits,
			pageMode,
			orderedComponents,
			sortOrder,
			yAxisConfig,
			showSQLQueryView,
		}),
		[
			from,
			to,
			selectedPeriod,
			relativePeriod,
			selectedXAxisElements,
			selectedDimensionName,
			collectProps,
			filterBy,
			parameters,
			groupBy,
			hiddenLegendNames,
			displayUnits,
			topN,
			topNIndices,
			chartType,
			flippedAxis,
			executiveView,
			showTargets,
			statisticsOperations,
			relativePeriodDuration,
			relativePeriodFrom,
			relativePeriodTo,
			periodRange,
			tableColumnState,
			decimalDigits,
			pageMode,
			orderedComponents,
			sortOrder,
			yAxisConfig,
			showSQLQueryView,
		]
	);

	return normalizedSearchParams;
}

function parseChartType(chartType?: string): ChartType | 'table' | undefined {
	if (chartType?.toLowerCase() == 'table') return 'table';
	if (chartType) {
		return SupportedChartTypes.find((ct) => ct.toLowerCase() == chartType.toLowerCase());
	}
}

function parseRelativePeriodDuration(relativePeriodDuration?: string): RelativePeriodKey | undefined {
	if (!relativePeriodDuration || RelativePeriodKeys.indexOf(relativePeriodDuration as RelativePeriodKey) == -1)
		return undefined;
	return relativePeriodDuration as RelativePeriodKey;
}

function parsePeriodRange(periodRange?: string): PeriodRangeInUrl | undefined {
	if (!periodRange) return undefined;

	const parsedObject = JSON.parse(periodRange);
	if (parsedObject.unit && (parsedObject.rel || parsedObject.abs)) {
		return parsedObject as PeriodRangeInUrl;
	} else return undefined;
}

function parseSelectedXAxisElements(selectedXAxisElementsString?: string): string[] | 'all' | undefined {
	if (!selectedXAxisElementsString) return undefined;

	if (selectedXAxisElementsString == 'all') return 'all';

	return tryParseJson(selectedXAxisElementsString).filter((e: any) => typeof e == 'string');
}

function parseSortOrder(sortOrder: string) {
	if (sortOrder) {
		return SupportedSortOrders.find((so) => so == sortOrder);
	}
}
export function tryParseJson(str: string | undefined): any | null {
	if (!str) return null;

	try {
		return JSON.parse(str);
	} catch {
		return null;
	}
}
