import Box from '@components/Box';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { Center, Link } from '@chakra-ui/react';
import { ChevronLeftSmallBold, ChevronRightSmallBold, HelpCircleOutline16 } from '@icons/index';
import colors from 'src/style/colors';
import classnames from 'classnames';
import classes from '@pages/MetricCatalog/MetricCatalogBodyV2.module.scss';
import AliceCarousel from 'react-alice-carousel';
import { MetricCardV2 } from '@pages/MetricCatalog/MetricCardV2';
import Divider from '@components/Divider';
import { MetricMetadataV2 } from 'src/types/metric';
import { useReportEvent } from '@services/analytics';
import { useCarousel } from '@hooks/ui/useCarousel';

type PendingSetupCategoryProps = {
	pendingSetupMetrics: MetricMetadataV2[];
	getMetricHref: (metric: MetricMetadataV2) => string;
	handleMetricClick: (metric: MetricMetadataV2) => void;
};

export const PendingSetupCategory = ({
	pendingSetupMetrics,
	getMetricHref,
	handleMetricClick,
}: PendingSetupCategoryProps) => {
	const { reportEvent } = useReportEvent();
	const {
		carouselElementKey,
		isPrevSlideDisabled,
		isNextSlideDisabled,
		isOnlyOneSlide,
		carouselRef,
		goToNextSlide,
		goToPrevSlide,
		debouncedCarouselResize,
		setCarouselProps,
	} = useCarousel();

	const QuestionMark = () => {
		return (
			<Center
				height={'24px'}
				width={'24px'}
				cursor={'pointer'}
				onClick={() => {
					reportEvent({
						event: 'pending-setup-category-question-mark',
						metaData: { categoryName: 'pending-setup' },
					});
				}}
				data-intercom-area={'catalog'}
				data-intercom-type={'pending-setup-category'}
				data-intercom-target={'pending-setup-category-question-mark'}
			>
				<HelpCircleOutline16 color={colors.gray[800]} />
			</Center>
		);
	};

	const CarouselArrowButtons = () => {
		return (
			<Flex cursor={'pointer'} gap={'4px'} pr={'32px'}>
				<span
					className={classnames(classes.carouselButton, {
						[classes.isDisabled]: isPrevSlideDisabled,
					})}
					onClick={() => {
						reportEvent({
							event: 'catalog-pending-setup-carousel-arrow-button-click',
							metaData: { action: 'prevSlide' },
						});
						goToPrevSlide();
					}}
				>
					<ChevronLeftSmallBold />
				</span>
				<span
					className={classnames(classes.carouselButton, {
						[classes.isDisabled]: isNextSlideDisabled,
					})}
					onClick={() => {
						reportEvent({
							event: 'catalog-pending-setup-carousel-arrow-button-click',
							metaData: { action: 'nextSlide' },
						});
						goToNextSlide(pendingSetupMetrics);
					}}
				>
					<ChevronRightSmallBold />
				</span>
			</Flex>
		);
	};

	return (
		<>
			<Box
				id={'pending-setup'}
				data-intercom-area={'catalog'}
				data-intercom-type={'category'}
				data-intercom-target={'pending-setup'}
			>
				<Flex justifyContent={'space-between'} mb={'16px'}>
					<Flex alignItems={'center'} gap={'8px'}>
						<Typography variant={'DesktopH7Medium'} color="gray.1000">
							Pending setup ({pendingSetupMetrics.length})
						</Typography>
						<QuestionMark />
					</Flex>
					{!isOnlyOneSlide && <CarouselArrowButtons />}
				</Flex>
				<AliceCarousel
					key={carouselElementKey}
					ref={carouselRef}
					disableButtonsControls
					disableDotsControls
					responsive={{
						0: { items: 1 },
						900: { items: 2, itemsFit: 'contain' },
						1160: { items: 3, itemsFit: 'contain' },
						1400: { items: 4, itemsFit: 'contain' },
					}}
					onSlideChanged={setCarouselProps}
					onInitialized={setCarouselProps}
					onResized={() => debouncedCarouselResize('pending-setup')}
					paddingRight={16}
					mouseTracking
					items={pendingSetupMetrics.map((metric, index) => (
						<Link
							href={getMetricHref(metric)}
							_hover={{ textDecoration: 'none' }}
							w={'100%'}
							key={`pending-setup-${metric.name}-${metric.entity}`}
						>
							<Box mr={'16px'}>
								<MetricCardV2
									metricName={metric.name}
									categoryName={'pending-setup'}
									metricDisplayName={metric.displayName}
									metricDescription={metric?.description ?? ''}
									metricEntity={metric.entity ?? ''}
									metricSource={metric.source}
									onClick={() => {
										reportEvent({
											event: 'metric-navigate-to',
											metaData: { metric: metric.name, categoryName: 'pending-setup' },
										});
										handleMetricClick(metric);
									}}
									hidden={metric.hide}
									dataIntercomSequence={index + 1}
									isFullyDefined={metric.isFullyDefined}
									isPendingSetupCategory={true}
								/>
							</Box>
						</Link>
					))}
				/>
			</Box>
			<Divider direction="horizontal" mt="60px" mb="60px" width={'calc(100% - 32px)'} />
		</>
	);
};
