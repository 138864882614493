import { useEffect } from 'react';
import { showIntercomWithMessage } from 'src/services/intercom';
import useSearchParams from './navigation/useSearchParams';

export function useIntercomFromSearchParams() {
	const [rawSearchParams, setRawSearchParams] = useSearchParams();

	const intercomSearchParams = {
		shouldOpenIntercom: rawSearchParams.get('open_intercom') == 'true',
		intercomMessage: rawSearchParams.get('intercom_message'),
		shouldSendMessage: rawSearchParams.get('send_intercom_message') == 'true',
	};

	const { shouldOpenIntercom, intercomMessage, shouldSendMessage } = intercomSearchParams;

	useEffect(() => {
		// Keep intercom message so we will be able to sign on it when bot opens
		rawSearchParams.delete('open_intercom');
		rawSearchParams.delete('send_intercom_message');
		setRawSearchParams(rawSearchParams);

		if (!shouldOpenIntercom || !intercomMessage) return;

		showIntercomWithMessage({ message: intercomMessage, shouldSendMessage });
	}, [intercomMessage, rawSearchParams, setRawSearchParams, shouldOpenIntercom, shouldSendMessage]);
}
