import intersection from 'lodash/intersection';
import { ChartOptions, ChartSeries, ChartType } from 'src/common/components/Chart/types';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export function calcOverrideChartType({
	chartOptions,
	chartType,
}: Pick<MetricDerivedState, 'chartOptions' | 'chartType'>): {
	chartOptions: ChartOptions;
} {
	const overrideChartType = chartType.selectedValue;
	if (!overrideChartType) {
		return { chartOptions };
	}

	const shouldOverrideSeries = (series: ChartSeries, overrideToChartType: ChartType): boolean => {
		const isMain = series.custom.seriesType == 'main';
		const isComponents = chartOptions.series.length > 1;
		const isMainWithComponents = isMain && isComponents;
		const originalChartType = series.chartType;
		const shouldOverrideMainSeries =
			isMain && intersection([originalChartType, overrideToChartType], ['waterfall', 'pie']).length > 0;
		return !isMainWithComponents || shouldOverrideMainSeries;
	};

	const applyOverrideChartType = (series: ChartSeries): ChartSeries => {
		if (shouldOverrideSeries(series, overrideChartType)) return { ...series, chartType: overrideChartType };
		return series;
	};

	const overriddenChartOptions = {
		...chartOptions,
		series: chartOptions.series.map(applyOverrideChartType),
	};
	return { chartOptions: overriddenChartOptions };
}
