import { ModalProps } from 'src/pages/MetricCatalog/TemplateOnboardingModal/types';
import { useReportEvent } from 'src/services/analytics';
import Modal from '@components/Modal';
import { ModalHeader } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Button from '@components/Button';
import { CloseLarge16 } from '@icons/index';
import Box from '@components/Box';
import Typography from '@components/Typography';
import { SupportLink } from 'src/pages/MetricCatalog/TemplateOnboardingModal/components/SupportLink';

export const TemplateAlreadyInstalledModal = ({ isOpen, onClose, templateName }: ModalProps) => {
	const { wrapWithReport } = useReportEvent();
	const eventMetaData = {
		feature: 'Template installation',
		template: templateName,
	};

	return (
		<Modal isOpen={isOpen} onClose={() => onClose()} closeOnOverlayClick={false} maxWidth="492px" isCentered>
			<ModalHeader color="gray.1000" p="16px">
				<Flex justifyContent="start" alignItems="center">
					<Button
						variant="outline"
						isIconOnly
						onClick={wrapWithReport(onClose, 'template-already-installed', { ...eventMetaData, action: 'close' })}
						size="small"
						colorScheme="black"
					>
						<CloseLarge16 />
					</Button>
				</Flex>
			</ModalHeader>
			<Flex
				justifyContent={'center'}
				flexDirection={'column'}
				alignItems={'center'}
				paddingX="32px"
				paddingBottom={'16px'}
			>
				<Box textAlign={'center'} maxWidth={'400px'}>
					<Typography marginTop={'24px'} marginBottom={'8px'} variant="DesktopH5" color="gray.1000">
						Template already installed
					</Typography>
					<Typography variant="Paragraph14R" color="gray.800">
						The {templateName} template is already installed, please choose another template or <SupportLink />.
					</Typography>
				</Box>
			</Flex>
			<Box paddingBottom={'32px'}>
				<Flex flexDirection="row" paddingX="24px" paddingY="16px" justifyContent={'center'} alignItems="center">
					<Button
						size={'small'}
						variant="solid"
						colorScheme={'blue'}
						onClick={wrapWithReport(onClose, 'template-already-installed', { ...eventMetaData, action: 'close' })}
					>
						Close
					</Button>
				</Flex>
			</Box>
		</Modal>
	);
};
