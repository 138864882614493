import { RawNode, RawNodePropValue } from '@hooks/fetching/useEntityFetchApi';
import partition from 'lodash/partition';
import startCase from 'lodash/startCase';
import { nodeSchema } from 'src/assets/NodeSchema';
import { GetDataModelDimensionsQuery } from 'src/generated/graphql';
import {
	DataModelDimensionsMap,
	DataOverridesMap,
	EntityProfile,
	EntitySchema,
	NodeSchemaCard,
	NodeSchemaProp,
	Property,
	PropertyCard,
} from 'src/layout/EntityProfileDrawer/types';
import { isDefined } from 'src/normalize/utils';
import { SupportedDataSources } from 'src/pages/DataHygiene/types';
import { buildEntityProfileUrl } from 'src/services/useNavigation';
import { createDimensionModelMap } from '../mapCreators';
import { seperateRelationshipsByInOut } from './buildNormalizedRelationships';
import { normalizeRelatedEntitiesForNode } from './normalizeRelatedEntitiesForNode';

const internalProps = ['tenant', 'labels'];
const isNormalized = (propName: string) => !propName.startsWith('_');

const shouldIncludeProp = ([propName]: [string, any]) =>
	!propName.startsWith('T.') && !internalProps.includes(propName) && !propName.toLowerCase().includes('fivetran');

function calculateMissingFields({ entitySchema, rawNode }: { entitySchema: EntitySchema; rawNode: RawNode }): {
	missingNormalizedFields: { [key: string]: RawNodePropValue };
	missingNonNormalizedFields: { [key: string]: RawNodePropValue };
} {
	const fieldsInAllCards: string[] = entitySchema.cards.map((c) => c.properties.map((p) => p.field)).flat();
	const allRawFields = rawNode.properties;
	const fieldNotInAnyCard = ([key]: [string, any]) => !fieldsInAllCards.includes(key);

	const allFieldsThatAreNotInAnyCard = Object.entries(allRawFields).filter(shouldIncludeProp).filter(fieldNotInAnyCard);

	const [missingNormalizedFields, missingNonNormalizedFields] = partition(allFieldsThatAreNotInAnyCard, ([propName]) =>
		isNormalized(propName)
	).map(Object.fromEntries);

	return { missingNormalizedFields, missingNonNormalizedFields };
}

export function normalizeNode({
	rawNode,
	dataOverridesMap,
	dataModelDimensions,
	shouldShowRawData,
}: {
	rawNode: RawNode;
	dataOverridesMap: DataOverridesMap | null;
	dataModelDimensions: GetDataModelDimensionsQuery | null;
	shouldShowRawData: boolean;
}): EntityProfile | null {
	const entityType = rawNode.properties['T.label'];
	const entitySchema: EntitySchema = nodeSchema.nodes[entityType] ?? nodeSchema.default_node;
	const source = rawNode.properties.source;
	const labels = rawNode.properties.labels;
	const [sourceType, sightfullType] = Array.isArray(labels) ? labels : [labels, labels];
	const trimmedSightfullType = sightfullType.replaceAll('Sightfull', '');
	const originSourceEntityType = trimmedSightfullType != sourceType ? sourceType : undefined;

	const header: EntityProfile['header'] = {
		entityType,
		originSourceEntityType,
		originId: rawNode.properties.id.toString(),
		title: rawNode.properties[entitySchema.title]?.toString(),
		sourceLink: rawNode.properties?.source_url?.toString(),
		entityProfileFullLink: buildEntityProfileUrl(rawNode.properties.id, true),
		entityProfileLink: buildEntityProfileUrl(rawNode.properties.id, false),
	};

	const dataModelDimensionsMap = dataModelDimensions
		? createDimensionModelMap(dataModelDimensions.data_model_dimensions)
		: null;

	const { missingNormalizedFields, missingNonNormalizedFields } = calculateMissingFields({ entitySchema, rawNode });
	const normalizeCardByNode = (nodeSchemaCard: typeof entitySchema.cards[number]) =>
		normalizeCard({
			nodeSchemaCard,
			rawNode,
			dataOverridesMap,
			dataModelDimensionsMap,
			missingNormalizedFields,
		});

	const normalizedCards: PropertyCard[] = entitySchema.cards.map(normalizeCardByNode);

	const { properties: rawRelationshipsProperties, relatedEntities: rawRelationshipsRelatedEntities } = shouldShowRawData
		? seperateRelationshipsByInOut({ rawNode })
		: { properties: [], relatedEntities: [] };

	const rawFieldsCard: PropertyCard[] = shouldShowRawData
		? createRawFieldsCard({
				rawRelationshipsProperties,
				missingNonNormalizedFields,
				dataOverridesMap,
				source,
		  })
		: [];

	const normalizedRelatedEntities = normalizeRelatedEntitiesForNode({ entitySchema, rawNode });

	if (!normalizedRelatedEntities) {
		return null;
	}

	const relatedEntitiesTable = [...normalizedRelatedEntities, ...rawRelationshipsRelatedEntities];

	const metrics = 'metrics' in entitySchema ? entitySchema.metrics : undefined;

	const propertyCards = [...normalizedCards, ...rawFieldsCard];

	return {
		metrics,
		relatedEntitiesTable,
		propertyCards,
		source,
		header,
	};
}

function createRawFieldsCard({
	rawRelationshipsProperties,
	missingNonNormalizedFields,
	dataOverridesMap,
	source,
}: {
	rawRelationshipsProperties: Property[] | [];
	missingNonNormalizedFields: {
		[key: string]: RawNodePropValue;
	};
	source: SupportedDataSources;
	dataOverridesMap: DataOverridesMap | null;
}): PropertyCard[] {
	const propertiesFromRawProps = buildPropertiesFromRawProps(missingNonNormalizedFields, dataOverridesMap);

	return [
		{
			header: `Raw ${source} properties`,
			properties: [...rawRelationshipsProperties, ...propertiesFromRawProps],
		},
	];
}

function buildPropertiesFromRawProps(
	rawProps: { [p: string]: RawNodePropValue },
	dataOverridesMap: DataOverridesMap | null,
	dataModelDimensionsMap?: DataModelDimensionsMap | null
): Property[] {
	return Object.entries(rawProps).map<Property>(([key, value]) => {
		const displayName = startCase(key.replaceAll('_', ' ')?.trim());

		const overrideProps = dataOverridesMap?.get(key);
		const modelDimensionProps = dataModelDimensionsMap?.get(key);
		return {
			displayName,
			rawName: key,
			value: Array.isArray(value) ? JSON.stringify(value) : value,
			relationshipProperties: null,
			modelDimensionProps,
			overrideProps,
		};
	});
}

function normalizeCard({
	nodeSchemaCard,
	rawNode,
	dataModelDimensionsMap,
	dataOverridesMap,
	missingNormalizedFields,
}: {
	nodeSchemaCard: NodeSchemaCard;
	rawNode: RawNode;
	dataOverridesMap: DataOverridesMap | null;
	dataModelDimensionsMap: DataModelDimensionsMap | null;
	missingNormalizedFields: { [key: string]: RawNodePropValue };
}) {
	const isMain = 'show_all' in nodeSchemaCard ? nodeSchemaCard.show_all : false;

	const normalizeNodeSchemaProp = (nodeSchemaProp: NodeSchemaProp): Property | null => {
		const { field, ...schemaProp } = nodeSchemaProp;

		const displayName = 'display_name' in schemaProp ? schemaProp.display_name : field;
		const formatter = 'formatter' in schemaProp ? schemaProp.formatter : undefined;

		const overrideProps = dataOverridesMap?.get(field);

		const relationshipProperties = getRelationship(nodeSchemaProp, rawNode);
		if ('relationship' in schemaProp && !relationshipProperties) return null;

		const modalDimensionProps = dataModelDimensionsMap?.get(field);

		const value = relationshipProperties?.value ?? rawNode.properties[field];

		return {
			displayName,
			rawName: field,
			value: Array.isArray(value) ? value.toString() : value,
			formatter,
			overrideProps,
			modelDimensionProps: modalDimensionProps,
			relationshipProperties,
		};
	};
	const propertiesFromNodeSchema = nodeSchemaCard.properties.map(normalizeNodeSchemaProp).filter(isDefined);
	const missingPropsFromMainCard: Property[] = !isMain
		? []
		: buildPropertiesFromRawProps(missingNormalizedFields, dataOverridesMap, dataModelDimensionsMap);

	const card: EntityProfile['propertyCards'][number] = {
		header: nodeSchemaCard.name,
		properties: [...propertiesFromNodeSchema, ...missingPropsFromMainCard],
	};

	return card;
}

function getRelationship(
	schemaProp: NodeSchemaProp,
	rawNode: RawNode
): { sourceSystemId: string; sightfullId: string; value: string | number } | null {
	const relationship = 'relationship' in schemaProp ? schemaProp.relationship : null;
	if (!relationship) {
		return null;
	}

	const [relationShipLabel, otherLabel] = relationship.split('>');

	const rawNodeRelation = rawNode.relationships.find(
		(rawRelation) => rawRelation.other_label == otherLabel && rawRelation.relationship_label == relationShipLabel
	)?.nodes?.[0];

	if (!rawNodeRelation) return null;

	return {
		sourceSystemId: String(rawNodeRelation.id),
		sightfullId: String(rawNodeRelation['T.id']),
		value: rawNodeRelation[schemaProp.field],
	};
}
