import { FieldType, LastChangeProps, ModalFieldValue, Property } from '../../types';
import { formatBooleanToDisplay, formatDateToDisplay, formatStringToDisplay } from './displayStringFormatters';
import { formatNumber, getLeadTextValue, getSourceValue } from './formatters';

export const modalFormattersByType: { [key in FieldType]: (value: any) => string } = {
	string: formatStringToDisplay,
	bool: formatBooleanToDisplay,
	datetime: formatDateToDisplay,
	int: (value: any) => String(formatNumber(value)),
	float: (value: any) => String(formatNumber(value)),
};

export const modalInputFormattersByType: {
	[key in FieldType]: (value: any) => string | Date | boolean | number;
} = {
	bool: (value: any): boolean => !!value,
	string: (value: any): string => String(value),
	datetime: (value: any): Date => new Date(value),
	int: formatNumber,
	float: formatNumber,
};

export const inputValueByFormatter = (
	{ overrideProps, value }: Pick<Property, 'value' | 'overrideProps'>,
	fieldType?: FieldType
): string | Date | boolean | number | null => {
	const displayValue = getLeadTextValue({ overrideProps, value });

	const formatter = modalInputFormattersByType[fieldType ?? 'string'];
	return formatter(displayValue);
};

export const getLastChangeProps = (
	{ value, overrideProps }: Pick<Property, 'overrideProps' | 'value'>,
	fieldType?: FieldType
): LastChangeProps | null => {
	const { value: overrideValue, edited_at } = overrideProps || {};

	const sourceValue = getSourceValue({ overrideProps, value });
	const lastChangeValue = overrideValue ?? sourceValue;

	const formatter = modalFormattersByType[fieldType ?? 'string'];
	const formattedSourceValue = formatter(sourceValue);
	const formattedLastChangeValue = formatter(lastChangeValue);

	const editedAt = edited_at ? modalFormattersByType['datetime'](edited_at) : undefined;

	return { sourceValue: formattedSourceValue, lastChangeValue: formattedLastChangeValue, editedAt };
};

export const isInputValueChanged = (inputValue: ModalFieldValue, currentValue?: string, fieldType?: FieldType) => {
	const formatter = modalFormattersByType[fieldType ?? 'string'];
	const formattedValue = formatter(inputValue);
	return formattedValue !== currentValue;
};
