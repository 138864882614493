import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { ChartSeries } from '@components/Chart/types';
import { STATS_SERIES_NAMES } from 'src/lib/metricRules/statisticOperations/constants';

export function calcSeriesVisibility({
	displayedLegendItems,
	chartOptions,
}: Pick<MetricDerivedState, 'displayedLegendItems' | 'chartOptions'>): Pick<MetricDerivedState, 'chartOptions'> {
	if (!chartOptions) return { chartOptions };
	const setVisibility = (series: ChartSeries): ChartSeries => {
		const visible = displayedLegendItems.selectedValues.concat(STATS_SERIES_NAMES).includes(series.name);
		return {
			...series,
			visible,
		};
	};

	return {
		chartOptions: {
			...chartOptions,
			series: chartOptions.series.map(setVisibility),
		},
	};
}

export function calcPieSeriesVisibility({
	chartType,
	chartOptions,
}: Pick<MetricDerivedState, 'chartType' | 'chartOptions'>): Pick<MetricDerivedState, 'chartOptions'> {
	if (!chartOptions || chartType.selectedValue != 'pie') return { chartOptions };
	const setVisibility = (series: ChartSeries): ChartSeries => {
		const isJustMainSeriesShown =
			chartOptions.series.filter((s) => s.visible && s.custom.seriesType != 'main').length == 0;
		const visible = series.visible && (isJustMainSeriesShown || series.custom.seriesType != 'main');

		return {
			...series,
			visible,
		};
	};

	return {
		chartOptions: {
			...chartOptions,
			series: chartOptions.series.map(setVisibility),
		},
	};
}
