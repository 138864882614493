import { Box, Flex, GridItem } from '@chakra-ui/react';
import Button from 'src/common/components/Button';
import Divider from 'src/common/components/Divider';
import { ResponsiveGrid } from 'src/common/components/ResponsiveGrid/ResponsiveGrid';
import useNavigation from 'src/services/useNavigation';
import { ConfigurationPermissions } from '../../interfaces/fields';

export const Footer = ({
	handleSave,
	handleCancel,
	permission,
	isDisabled,
	configName,
}: {
	configName: string;
	isDisabled: boolean;
	permission: ConfigurationPermissions;
	handleSave: () => void;
	handleCancel: () => void;
}) => {
	const { goBack } = useNavigation();
	return (
		<Flex direction="column" pt="40px">
			<Divider direction={'horizontal'} />
			<ResponsiveGrid height={'100%'}>
				<GridItem colSpan={{ base: 2, xs: 12 }} colStart={0}>
					<Box width={'100%'} bg="white" flexShrink={1} height={'10%'}>
						<Flex py="16px" direction="row" justifyContent={'flex-end'} gap={'12px'}>
							{permission === 'Edit' ? (
								<>
									<Button
										variant="outline"
										colorScheme="gray"
										onClick={handleCancel}
										size="medium"
										isDisabled={isDisabled}
										eventName="self-service-config-clicked-cancel"
										eventMetadata={{ config: configName }}
									>
										Cancel
									</Button>
									<Button
										variant="solid"
										colorScheme="blue"
										onClick={handleSave}
										size="medium"
										isDisabled={isDisabled}
										eventName="self-service-config-clicked-saved"
										eventMetadata={{ config: configName }}
									>
										Confirm & Save
									</Button>
								</>
							) : (
								<Button
									onClick={goBack}
									variant="solid"
									colorScheme="gray"
									size="medium"
									eventName="self-service-config-clicked-close"
									eventMetadata={{ config: configName }}
								>
									Close
								</Button>
							)}
						</Flex>
					</Box>
				</GridItem>
			</ResponsiveGrid>
		</Flex>
	);
};

export default Footer;
