import { useEffect, useRef, useCallback } from 'react';

function useWindowResize(handleResizeCallback: (ev: UIEvent) => any, timeout = 100): void {
	const timeoutId = useRef<NodeJS.Timeout | undefined>();

	const resizeListener = useCallback(
		(e: UIEvent) => {
			clearTimeout(timeoutId.current);
			timeoutId.current = setTimeout(() => handleResizeCallback(e), timeout);
		},
		[handleResizeCallback, timeout]
	);

	useEffect(() => {
		window.addEventListener('resize', resizeListener);
		return () => window.removeEventListener('resize', resizeListener);
	}, [resizeListener]);
}

export default useWindowResize;
