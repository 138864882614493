export const waitForElementToAppear = (
	elementGetter: () => any,
	onAppear: (element: any) => void,
	timeoutMs: number
) => {
	const interval = 100;

	let time = 0;
	const intervalId = setInterval(() => {
		const element = elementGetter();

		if (element) {
			onAppear(element);
			clearInterval(intervalId);
			return;
		}

		time += interval;
		if (time >= timeoutMs) {
			clearInterval(intervalId);
			return;
		}
	}, interval);
};
