import Box from '@components/Box';
import InitialsBox from '@components/InitialsBox';
import Popover from '@components/Popover';
import { PlacementType } from '@components/Popover/types';
import Typography from '@components/Typography';
import { DotsSolid16 } from '@icons/index';
import { ReactNode } from 'react';
import Flex from '../Flex';

import Tooltip from '../Tooltip';
import classes from './MenuListItem.module.scss';
import Button from '../Button';

export type MenuListItemProps = {
	text?: string;
	menuTrigger?: ReactNode;
	menuPlacement?: PlacementType;
	menuItems?: ReactNode;
	onClick?: () => void;
	isSelected?: boolean;
	leftIcon?: ReactNode; // Placeholder for design, TODO: implement reordable with drag handle
	rightIcon?: ReactNode;
	withSideMenu?: boolean;
	testId?: string;
	rightIconTooltip?: string;
	initialsColor?: string;
	dataIntercomArea?: string;
	dataIntercomSequence?: number;
};

function MenuListItem({
	text,
	menuTrigger = (
		<Button
			isIconOnly
			size="inline"
			background={'transparent'}
			border={'none'}
			_focus={{ background: 'transparent' }}
			_hover={{ color: 'gray.600' }}
			color={'gray.600'}
		>
			<DotsSolid16 />
		</Button>
	),
	menuPlacement: placement = 'right-start',
	menuItems,
	onClick,
	isSelected = false,
	leftIcon,
	rightIcon,
	withSideMenu = true,
	testId,
	rightIconTooltip,
	initialsColor = 'blue.700',
	dataIntercomArea,
	dataIntercomSequence,
}: MenuListItemProps) {
	const menuListItemClass = `${classes.menuListItem} ${isSelected ? classes.selected : ''}`;
	const sideItem = <InitialsBox size="md" mr="12px" color="white" bgColor={initialsColor} text={text} />;

	return (
		<Box
			className={menuListItemClass}
			onClick={onClick}
			data-testid={testId}
			data-intercom-type={'button'}
			data-intercom-area={dataIntercomArea}
			data-intercom-target={text}
			data-intercom-sequence={dataIntercomSequence}
		>
			<Flex className={classes.menuLeftSide}>
				{
					<Flex data-testid={`leftIcon${testId}`} className={classes.menuLeftButton}>
						{leftIcon}
					</Flex>
				}
				<Flex className={classes.menuInitialsItem}>{sideItem}</Flex>
				<Typography className={classes.text} variant="Paragraph14R" mb="2px" data-testid={`${testId}-text`}>
					{text}
				</Typography>
			</Flex>
			<Flex className={classes.menuRightSide}>
				<Tooltip size="sm" label={rightIconTooltip}>
					<Flex data-testid={`rightIcon${testId}`}>{rightIcon}</Flex>
				</Tooltip>
				{withSideMenu && menuItems && (
					<Box data-testid={`menu${testId}`} className={classes.threeDotsMenu} onClick={(e) => e.stopPropagation()}>
						<Popover triggerElement={menuTrigger} placement={placement} popoverContentProps={{ mt: '-18px' }}>
							{menuItems}
						</Popover>
					</Box>
				)}
			</Flex>
		</Box>
	);
}

export default MenuListItem;
