import { ModalHeader } from '@chakra-ui/react';
import Box from '@components/Box';
import Button from '@components/Button';
import Flex from '@components/Flex';
import Modal from '@components/Modal';
import Typography from '@components/Typography';
import { CloseLarge16 } from '@icons/index';
import { useEffect, useState } from 'react';
import { SupportLink } from 'src/pages/MetricCatalog/TemplateOnboardingModal/components/SupportLink';
import { ModalProps } from 'src/pages/MetricCatalog/TemplateOnboardingModal/types';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';

export const TemplateNotFoundModal = ({ isOpen, templateName }: Omit<ModalProps, 'onClose'>) => {
	const { navigate } = useNavigation();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { wrapWithReport } = useReportEvent();
	const eventMetaData = {
		feature: 'Template installation',
		template: templateName,
	};
	const maxTemplateNameLength = 30;

	useEffect(() => {
		if (isOpen) setIsModalOpen(true);
	}, [isOpen]);

	const onClose = () => {
		setIsModalOpen(false);
		navigate({
			path: 'metrics',
		});
	};

	return (
		<Modal isOpen={isModalOpen} onClose={onClose} closeOnOverlayClick={false} maxWidth="492px" isCentered>
			<ModalHeader color="gray.1000" p="16px">
				<Flex justifyContent="start" alignItems="center">
					<Button
						variant="outline"
						isIconOnly
						onClick={wrapWithReport(onClose, 'template-not-found', { ...eventMetaData, action: 'close' })}
						size="small"
						colorScheme="black"
					>
						<CloseLarge16 />
					</Button>
				</Flex>
			</ModalHeader>
			<Flex
				justifyContent={'center'}
				flexDirection={'column'}
				alignItems={'center'}
				paddingX="32px"
				paddingBottom={'16px'}
			>
				<Box textAlign={'center'} maxWidth={'400px'}>
					<Typography marginTop={'24px'} marginBottom={'8px'} variant="DesktopH5" color="gray.1000">
						Template not found.
					</Typography>
					<Typography variant="Paragraph14R" color="gray.800">
						The&nbsp;
						{templateName.length > maxTemplateNameLength
							? `${templateName.slice(0, maxTemplateNameLength)}...`
							: templateName}
						&nbsp;template couldn&apos;t be installed, please <SupportLink />.
					</Typography>
				</Box>
			</Flex>
			<Box paddingBottom={'32px'}>
				<Flex flexDirection="row" paddingX="24px" paddingY="16px" justifyContent={'center'} alignItems="center">
					<Button
						size={'small'}
						variant="solid"
						colorScheme={'blue'}
						onClick={wrapWithReport(onClose, 'template-not-found', { ...eventMetaData, action: 'close' })}
					>
						Close
					</Button>
				</Flex>
			</Box>
		</Modal>
	);
};
