import { Box, GridItem } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import Input from 'src/common/components/Input';
import ToggleButton from 'src/common/components/ToggleButton';
import { IField } from '../../interfaces';
import { IFieldValue } from '../../interfaces/fields';
import { normalizeField } from '../../utilities';
import { ModalField } from '../ModalField/ModalField';

const NumberField = ({
	value,
	field,
	fieldKey,
	isDisabled,
	handleChange,
}: {
	field: IField;
	fieldKey: string;
	isDisabled: boolean;
	value: IFieldValue;
	handleChange: (key: string, value: IFieldValue) => void;
}) => {
	const normalizedField = normalizeField.number(field);
	const labelSide = normalizedField?.labelSide ?? 'right';

	const labelComponent = useMemo(() => {
		return normalizedField ? (
			<Box mt="-1px" pl={labelSide == 'right' ? '20px' : undefined}>
				<div>{normalizedField.label}</div>
			</Box>
		) : undefined;
	}, [normalizedField, labelSide]);

	return (
		<>
			{normalizedField && (
				<GridItem width={'105px'}>
					<Input
						isDisabled={isDisabled}
						value={value.toString()}
						type="number"
						size="sm"
						width={'inherit'}
						onChange={(newValue: string) => handleChange(fieldKey, Number(newValue))}
						chakraNumberInputProps={{ min: normalizedField.minValue, max: normalizedField.maxValue }}
						rightComponent={labelSide == 'right' ? labelComponent : undefined}
						leftComponent={labelSide == 'left' ? labelComponent : undefined}
					/>
				</GridItem>
			)}
		</>
	);
};

const TabField = ({
	field,
	value,
	fieldKey,
	isDisabled,
	handleChange,
}: {
	field: IField;
	fieldKey: string;
	isDisabled: boolean;
	value: IFieldValue;
	handleChange: (key: string, value: IFieldValue) => void;
}) => {
	const normalizedField = normalizeField.tab(field);
	const [indexValue, setIndexValue] = useState(0);

	const itemsInfo = useMemo(() => {
		if (!normalizedField) {
			return null;
		}

		const { options } = normalizedField;

		return Object.entries(options)
			.sort(([, { displayOrder }], [, { displayOrder: displayOrder2 }]) => displayOrder - displayOrder2)
			.map(([key, { displayName }]) => ({ key, displayName }));
	}, [normalizedField]);

	useEffect(() => {
		if (!itemsInfo) {
			return;
		}

		const index = Math.max(
			0,
			itemsInfo.findIndex(({ key }) => key === value)
		);

		setIndexValue(index);
	}, [itemsInfo, value]);

	const isShowToggle = normalizedField && itemsInfo?.length;

	return (
		<>
			{isShowToggle && (
				<ToggleButton
					options={itemsInfo.map(({ displayName }) => displayName)}
					isDisabled={isDisabled}
					selectedIndex={indexValue}
					selectedColor="blue"
					onChildClicked={(index) => {
						if (isDisabled) {
							return;
						}

						setIndexValue(index);
						handleChange(fieldKey, itemsInfo[index].key);
					}}
					size="sm"
				/>
			)}
		</>
	);
};

const EditableModalField = ({
	field,
	value,
	fieldKey,
	isDisabled,
	handleChange,
}: {
	field: IField;
	fieldKey: string;
	isDisabled: boolean;
	value: IFieldValue;
	handleChange: (key: string, value: IFieldValue) => void;
}) => {
	return (
		<ModalField
			field={field}
			value={value}
			fieldKey={fieldKey}
			isDisabled={isDisabled}
			handleChange={handleChange}
			isEditable={true}
		/>
	);
};

export const EditModeFieldComponents = {
	tab: TabField,
	number: NumberField,
	freeText: () => <></>,
	monthInYear: () => <></>,
	modal: EditableModalField,
} as const;
