import { Box, Flex } from '@chakra-ui/react';
import { Editor } from '@monaco-editor/react';
import * as monacoEditor from 'monaco-editor';
import { useEffect, useRef, useState } from 'react';
import Button from 'src/common/components/Button';
import { CQuestion14 } from 'src/common/components/Icons';
import { Label } from 'src/common/components/Labels';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import { sleep } from 'src/common/utils/utils';
import colors from 'src/style/colors';
import GITHUB_MONACO_THEME from 'src/theme/monaco-theme.json';
import { SIGHTFULL_MONACO_THEME_NAME, editorOptions, useInitMonacoEditor } from '..';
import { EditorHelpTooltip } from '../EditorHelpTooltip';
import { EditorSkeleton } from '../EditorSkeleton';
import '../MonacoEditor.scss';
import { SchemaType } from '../MonacoYamlEditor';

export function BorderedMonacoYamlEditor({
	value,
	onChange,
	isEditable,
	hasUnsavedChanges,
	onErrorsChange,
	schemaType,
	onFocus,
	editorLoading,
	footer,
	onBlur,
}: {
	value: string;
	onChange?: (value: string) => void;
	isEditable?: boolean;
	hasUnsavedChanges?: boolean;
	onErrorsChange?: (hasErrors: boolean) => void;
	onFocus?: (hasError?: boolean) => void;
	schemaType: SchemaType;
	editorLoading?: boolean;
	footer?: React.ReactNode;
	onBlur?: () => void;
}) {
	useInitMonacoEditor();
	const [hasFocus, setHasFocus] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const editorRef = useRef<null | monacoEditor.editor.IStandaloneCodeEditor>(null);
	const editorKeyCode = monacoEditor.KeyCode;
	const AutoSuggestTriggerKeys = [editorKeyCode.Space, editorKeyCode.Backspace, editorKeyCode.Tab];

	const checkEditorKeyCode = (code: monacoEditor.KeyCode) => AutoSuggestTriggerKeys.includes(code);

	useEffect(() => {
		if (editorRef.current?.getValue() !== value) editorRef.current?.setValue(value);
	}, [value, editorRef]);

	const onMount = (editor: monacoEditor.editor.IStandaloneCodeEditor, monacoInstance: typeof monacoEditor) => {
		editorRef.current = editor;
		sleep(100).then(() => {
			editor.trigger('', 'editor.action.forceRetokenize', '');
		});

		monacoEditor.editor.defineTheme(SIGHTFULL_MONACO_THEME_NAME, GITHUB_MONACO_THEME as any);
		monacoEditor.editor.setTheme(SIGHTFULL_MONACO_THEME_NAME);
		editor.setValue(value);
		editor.onDidFocusEditorText(() => {
			const hasErrors = monacoInstance.editor.getModelMarkers({}).length > 0;
			setHasFocus(true);
			onFocus?.(hasErrors);
		});
		editor.onDidBlurEditorText(() => {
			setHasFocus(false);
			onBlur?.();
		});
		editor.onKeyUp((e) => {
			const position = editor.getPosition();
			const model = editor.getModel();
			if (!position || !model) return;
			const text = model.getLineContent(position.lineNumber).trim();
			if (checkEditorKeyCode(e.keyCode) && !text) {
				editor.trigger('', 'editor.action.triggerSuggest', { auto: true });
			}
		});
		monacoInstance.editor.onDidChangeMarkers(() => {
			const hasErrors = monacoInstance.editor.getModelMarkers({}).length > 0;
			setHasErrors(hasErrors);
			onErrorsChange?.(hasErrors);
		});
	};

	const Badges = () => (
		<>
			{hasUnsavedChanges && !hasErrors && (
				<Label
					colorScheme="neutral"
					variant="filled"
					size="small"
					startIcon={<Box width="8px" height={'8px'} backgroundColor="gray.700" borderRadius={'100%'} />}
				>
					Unsaved changes
				</Label>
			)}
			{hasErrors && (
				<Label
					colorScheme="error"
					variant="filled"
					size="small"
					startIcon={<Box width="8px" height={'8px'} backgroundColor="red.700" borderRadius={'100%'} />}
				>
					Error
				</Label>
			)}
		</>
	);

	const EditorTopContent = () => (
		<Flex marginBottom={'10px'} alignItems={'center'} justifyContent={'space-between'}>
			<Flex width={'100%'} justifyContent={'space-between'} gap={'8px'} alignItems={'center'}>
				<Flex gap={'2px'} alignContent={'start'} alignItems={'center'}>
					<Typography pt={'2px'} color={'gray.1000'} variant={'DesktopH10Regular'} textAlign="start">
						Builder (YAML)
					</Typography>

					<Tooltip
						pointerEvents={'all'}
						width="264px"
						closeDelay={1000}
						label={<EditorHelpTooltip schemaType={schemaType} onClick={() => 0} />}
						size="md"
					>
						<Button size="xxs" colorScheme="lightGray" variant="outline" isIconOnly>
							<CQuestion14 />
						</Button>
					</Tooltip>
				</Flex>
				<Badges />
			</Flex>
		</Flex>
	);

	return (
		<>
			<Flex height="0" minHeight={'100%'} direction="column" position={'relative'}>
				{editorLoading && <EditorSkeleton />}
				<EditorTopContent />
				<Box
					backgroundColor={isEditable ? 'white' : 'gray.100'}
					padding={'16px'}
					border={`1px solid ${hasFocus ? colors.gray[900] : colors.gray[300]}`}
					_hover={{
						borderColor: hasFocus ? colors.gray[900] : colors.gray[400],
					}}
					borderRadius={'8px'}
					flex={1}
					height="0"
					className={'monaco-with-css-tooltips'}
				>
					<Editor
						path={`file:///${schemaType}.yaml`}
						language="yaml"
						options={{ ...editorOptions, readOnly: !isEditable }}
						onChange={(value, ev) => {
							if (ev.isFlush) return;
							if (value) onChange?.(value);
						}}
						onMount={onMount}
					/>
				</Box>
				{footer}
			</Flex>
		</>
	);
}
