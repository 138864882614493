import { FetchResult, WatchQueryFetchPolicy } from '@apollo/client';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import useToast from 'src/common/hooks/ui/useToast';
import {
	RunNormalizationMutation,
	useDeleteEntityMutation,
	useGetEntityDefinitionQuery,
	useRunNormalizationMutation,
	useUpsertEntityMutation,
	useValidateEntitiesLazyQuery,
} from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import { useInvalidateCache } from 'src/services/apollo';
import useNavigation from 'src/services/useNavigation';
import { OntologyPagePath } from '../OntologyBuilderPage';
import {
	OntologyStateAtomDerived,
	writeFullErrorOntologyState,
	writeFullSuccessOntologyState,
	writePartialOntologyState,
} from '../atoms/OntologyState';

export type OntologyEditorStateInput = {
	entityName?: string;
	onSaveAndRunNormalizationStart: () => void;
	onNormalizationSuccess: () => void;
	setColumns?: (val: string[]) => void;
};
const UNKNOWN_VALIDATION_ERROR = 'Unknown Validation Error Occurred';
const SAVE_FAILED_ERROR_MESSAGE = 'Save Failed, please review and try again';
const DELETE_FAILED_ERROR_MESSAGE = 'Delete Failed, please review and try again';

const POLLING_VERSION_INTERVAL_MILLISECONDS = 3000;
export default function useOntologyEditorState({
	entityName,
	onSaveAndRunNormalizationStart,
	onNormalizationSuccess,
	setColumns,
}: OntologyEditorStateInput) {
	const toast = useToast();
	const { navigate } = useNavigation();
	const { invalidateCache } = useInvalidateCache();
	const { reportEvent } = useReportEvent();
	const [upsertEntityMutation, { loading: upsertEntityInProgress }] = useUpsertEntityMutation();
	const [deleteEntityMutation, { loading: deleteEntityInProgress }] = useDeleteEntityMutation();
	const [runNormalizationMutation, { loading: normalizationInProgress }] = useRunNormalizationMutation();
	const [validateEntitiesQuery, { loading: validationInProgress }] = useValidateEntitiesLazyQuery({
		variables: { entityNames: [] },
		fetchPolicy: 'network-only',
	});
	const setPartialOntologyState = useSetAtom(writePartialOntologyState);
	const setSuccessOntologyState = useSetAtom(writeFullSuccessOntologyState);
	const setErrorOntologyState = useSetAtom(writeFullErrorOntologyState);
	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const shouldBlockPolling = useRef(false);

	useEffect(() => {
		shouldBlockPolling.current =
			(!ontologyState.loading && ontologyState.saveRunNormalizationValidationRunning) ||
			(!ontologyState.loading && ontologyState.hasStaleVersion) ||
			ontologyState.loading;
	}, [ontologyState]);

	const getEntityDefinitionBaseOptions: {
		variables: { entityName: string };
		skip: boolean;
		fetchPolicy: WatchQueryFetchPolicy;
	} = {
		variables: { entityName: entityName ?? '' },
		skip: entityName === undefined,
		fetchPolicy: 'no-cache',
	};

	const { loading: loadingFiles } = useGetEntityDefinitionQuery({
		...getEntityDefinitionBaseOptions,
		onCompleted: (data) => {
			const newValue = data?.getEntityDefinition.entityDefinition;
			const savedEntityVersion = data?.getEntityDefinition.entityDefinitionVersion.definitionHash;
			const value = newValue ? newValue?.join('\n') : '';
			setSuccessOntologyState({
				entityName: entityName ?? '',
				savedYaml: value,
				errorMessage: ontologyState.loading ? '' : ontologyState?.errorMessage || '',
				editorRequestMessageState: ontologyState.loading ? 'NONE' : ontologyState?.errorMessage ? 'ERROR' : 'NONE',
				savedEntityVersion,
				currentEntityVersion: savedEntityVersion,
			});
		},
		onError: (error) => {
			setErrorOntologyState({ errorMessage: error.message });
		},
	});
	useGetEntityDefinitionQuery({
		...getEntityDefinitionBaseOptions,
		pollInterval: POLLING_VERSION_INTERVAL_MILLISECONDS,
		notifyOnNetworkStatusChange: true,
		skipPollAttempt: () => shouldBlockPolling.current,
		onCompleted: (data) => {
			const savedEntityVersion = data?.getEntityDefinition.entityDefinitionVersion.definitionHash;
			if (!ontologyState.loading && savedEntityVersion != ontologyState.savedEntityVersion) {
				setPartialOntologyState({
					savedEntityVersion,
				});
			}
		},
	});

	// ******** ACTIONS *********

	const handleSaveError = useCallback(
		(errorMessage: string) => {
			if (ontologyState.loading) {
				return;
			}

			setPartialOntologyState({
				loading: false,
				entityName,
				hasYamlErrors: false,
				errorMessage,
				editorRequestMessageState: 'ERROR',
				saveInProgress: false,
			});
			toast({
				variant: 'error',
				message: SAVE_FAILED_ERROR_MESSAGE,
			});
			reportEvent({
				event: 'save-ontology-error',
				metaData: {
					feature: 'YAML Editor',
					entity: ontologyState.entityName,
				},
			});
		},
		[entityName, ontologyState, reportEvent, setPartialOntologyState, toast]
	);

	const handleNormalizationError = useCallback(
		(errorMessage: string) => {
			if (ontologyState.loading) {
				return;
			}
			setPartialOntologyState({
				loading: false,
				entityName,
				hasYamlErrors: false,
				errorMessage,
				editorRequestMessageState: 'ERROR',
				normalizationInProgress: false,
			});
			toast({
				variant: 'error',
				message: 'Failed, please review and try again',
			});
			reportEvent({
				event: 'run-ontology-error',
				metaData: {
					feature: 'YAML Editor',
					entity: ontologyState.entityName,
				},
			});
		},
		[entityName, ontologyState, reportEvent, setPartialOntologyState, toast]
	);

	const handleSaveSuccess = useCallback(
		(savedYaml: string[], savedEntityVersion: string) => {
			const savedYamlString = savedYaml.join('\n');
			setPartialOntologyState({
				currentEntityVersion: savedEntityVersion,
				savedEntityVersion,
				editorYaml: savedYamlString,
				savedYaml: savedYamlString,
				saveInProgress: false,
			});
		},
		[setPartialOntologyState]
	);

	const handleNormalizationSuccess = useCallback(
		({ additionalColumns }: { additionalColumns?: string[] }) => {
			setPartialOntologyState({ normalizationInProgress: false });
			invalidateCache();
			if (additionalColumns?.length) setColumns?.(additionalColumns);
			else onNormalizationSuccess();
		},
		[invalidateCache, onNormalizationSuccess, setPartialOntologyState, setColumns]
	);

	const handleValidationSuccess = useCallback(() => {
		setPartialOntologyState({
			editorRequestMessageState: 'SUCCESS',
			validationInProgress: false,
		});
		toast({
			variant: 'ok',
			message: 'Successfully saved',
		});
	}, [setPartialOntologyState, toast]);

	const handleValidationError = useCallback(
		(error: string) => {
			setPartialOntologyState({
				editorRequestMessageState: 'ERROR',
				errorMessage: error,
				validationInProgress: false,
			});
			toast({
				variant: 'error',
				message: error,
			});
		},
		[setPartialOntologyState, toast]
	);
	const validateEntities = useCallback(async () => {
		setPartialOntologyState({ validationInProgress: true });
		const validationResult = await validateEntitiesQuery();
		if (ontologyState.loading) {
			console.error('Cannot validate while loading');
			return;
		}

		const validationResponses = validationResult.data?.validateEntities;
		if (validationResponses == undefined) {
			handleValidationError(UNKNOWN_VALIDATION_ERROR);
			return;
		}

		const invalidEntitiesResponses = validationResponses.validateEntitiesSemanticsResponses.filter(
			(response) => !response.isValid
		);
		const hasValidationErrors = invalidEntitiesResponses.length > 0;
		if (!hasValidationErrors) {
			handleValidationSuccess();
			return;
		}

		const currentEntityErrorResponse = invalidEntitiesResponses.find(
			(response) => response.entityName == ontologyState.entityName
		);
		const hasErrorOnCurrentEntity = currentEntityErrorResponse != undefined;
		if (hasErrorOnCurrentEntity) {
			handleValidationError(currentEntityErrorResponse?.errors ?? UNKNOWN_VALIDATION_ERROR);
			return;
		}

		const firstErrorResponseWithErrors = invalidEntitiesResponses.find(
			(invalidResponse) => invalidResponse?.errors != null
		);
		const firstErrorResponse =
			firstErrorResponseWithErrors != undefined ? firstErrorResponseWithErrors : invalidEntitiesResponses[0];
		handleValidationError(firstErrorResponse?.errors ?? UNKNOWN_VALIDATION_ERROR);
	}, [handleValidationError, handleValidationSuccess, ontologyState, setPartialOntologyState, validateEntitiesQuery]);

	const hasNormalizationFailed = (runNormalizationResult: FetchResult<RunNormalizationMutation>) =>
		runNormalizationResult.data?.runCore.status && runNormalizationResult.data.runCore.status !== 'SUCCESS';

	const runNormalization = useCallback(async () => {
		try {
			const runNormalizationResult = await runNormalizationMutation();
			if (runNormalizationResult?.data && hasNormalizationFailed(runNormalizationResult)) {
				handleNormalizationError(runNormalizationResult?.data?.runCore.status);
				return false;
			}
			return true;
		} catch (error) {
			if (error instanceof Error) {
				handleNormalizationError(error.message);
			}
			return false;
		}
	}, [handleNormalizationError, runNormalizationMutation]);

	const onSubmit = useCallback(
		async (lines?: string[], additionalTableColumns?: string[]) => {
			if (ontologyState.loading) {
				console.error('Cannot save while loading');
				return;
			}

			// TODO: detect name change?
			setPartialOntologyState({ editorRequestMessageState: 'NONE', saveInProgress: true });
			onSaveAndRunNormalizationStart();
			const definitionLines = lines || ontologyState.editorYaml.split('\n');
			let isRenamed: boolean;
			let savedDefinitionHash: string;
			try {
				const upsertEntityResult = await upsertEntityMutation({
					variables: {
						entityName: ontologyState.entityName,
						entityDefinition: definitionLines,
						currentEntityVersion: ontologyState.currentEntityVersion,
					},
				});
				isRenamed = upsertEntityResult.data?.upsertEntity?.upsertEntityAction == 'Rename';
				if (!upsertEntityResult.data) {
					handleSaveError(SAVE_FAILED_ERROR_MESSAGE);
					return;
				}

				savedDefinitionHash = upsertEntityResult.data.upsertEntity.entityDefinitionVersion.definitionHash;
			} catch {
				handleSaveError(SAVE_FAILED_ERROR_MESSAGE);
				return;
			}

			handleSaveSuccess(definitionLines, savedDefinitionHash);

			setPartialOntologyState({ normalizationInProgress: true });

			const normalizationSuccess = await runNormalization();
			if (!normalizationSuccess) return;

			handleNormalizationSuccess({ additionalColumns: additionalTableColumns });

			await validateEntities();

			// TODO: The isRenamed redirect mechanism MUST be changed
			if (isRenamed) {
				throw new Error('Entity name has been changed, please refresh the page');
			}
		},
		[
			handleNormalizationSuccess,
			handleSaveError,
			handleSaveSuccess,
			onSaveAndRunNormalizationStart,
			ontologyState,
			setPartialOntologyState,
			upsertEntityMutation,
			validateEntities,
			runNormalization,
		]
	);

	const onDelete = useCallback(
		async (onErrorCallback?: VoidFunction, onSuccessCallback?: VoidFunction, entityToNavigate?: string) => {
			if (ontologyState.loading) {
				console.error('Cannot delete while loading');
				return;
			}
			const invalidateAndNavigate = () => {
				invalidateCache();
				setPartialOntologyState({ normalizationInProgress: false });
				if (entityToNavigate) setTimeout(() => navigate({ path: `${OntologyPagePath}/${entityToNavigate}` }), 200);
			};

			setPartialOntologyState({ editorRequestMessageState: 'NONE', saveInProgress: true });
			onSaveAndRunNormalizationStart();

			try {
				const { data: deleteData } = await deleteEntityMutation({
					variables: { entityName: ontologyState.entityName },
				});
				if (!deleteData) {
					handleSaveError(DELETE_FAILED_ERROR_MESSAGE);
					onErrorCallback?.();
					return;
				}
			} catch {
				handleSaveError(DELETE_FAILED_ERROR_MESSAGE);
				onErrorCallback?.();
			}
			onSuccessCallback?.();
			toast({ variant: 'ok', message: 'Successfully deleted' });
			setPartialOntologyState({ normalizationInProgress: true });
			runNormalization().then(invalidateAndNavigate);
		},
		[
			handleSaveError,
			onSaveAndRunNormalizationStart,
			ontologyState,
			setPartialOntologyState,
			deleteEntityMutation,
			invalidateCache,
			navigate,
			toast,
			runNormalization,
		]
	);

	const state = useMemo(() => {
		return [
			{
				ontologyState: ontologyState.loading === true ? null : ontologyState,
				normalizationInProgress,
				loadingFiles,
				validationInProgress,
				upsertEntityInProgress,
				deleteEntityInProgress,
			},
			{ onSubmit, onDelete },
		] as const;
	}, [
		loadingFiles,
		normalizationInProgress,
		onSubmit,
		onDelete,
		ontologyState,
		upsertEntityInProgress,
		validationInProgress,
		deleteEntityInProgress,
	]);

	return state;
}
