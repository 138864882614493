import Modal from '@components/Modal';
import Flex from '@components/Flex';
import { DataConnectorsModalHeader } from '@components/TopNavigation/DataConnectorsModal/DataConnectorsModalHeader';
import { DataConnectorsModalBody } from '@components/TopNavigation/DataConnectorsModal/DataConnectorsModalBody';
import shadows from 'src/style/shadows';
import { useCallback, useState } from 'react';
import { DataConnectorsModalFooter } from '@components/TopNavigation/DataConnectorsModal/DataConnectorsModalFooter';
import { DataConnectorInfo } from '@components/TopNavigation/DataConnectorsModal/types';
import { LazyQueryExecFunction } from '@apollo/client/react/types/types';
import { Exact, UserRequestSetupConnectorQuery } from 'src/generated/graphql';

export function DataConnectorsModal({
	isOpen,
	onClose,
	requestSetupConnectorLazyQuery,
	onNextButtonClick,
}: {
	isOpen: boolean;
	onClose: () => void;
	requestSetupConnectorLazyQuery: LazyQueryExecFunction<
		UserRequestSetupConnectorQuery,
		Exact<{ connectorName: string }>
	>;
	onNextButtonClick: () => void;
}) {
	const [selectedConnector, setSelectedConnector] = useState<DataConnectorInfo | null>(null);

	const onModalClose = useCallback(() => {
		setSelectedConnector(null);
		onClose();
	}, [onClose]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onModalClose}
			closeOnOverlayClick={true}
			maxWidth={'790px'}
			isCentered={true}
			borderRadius={'16px'}
			closeOnEsc={false}
		>
			<Flex width={'793px'} height={'680px'} direction={'column'} boxShadow={shadows.lg}>
				<DataConnectorsModalHeader onClose={onModalClose} />
				<DataConnectorsModalBody selectedConnector={selectedConnector} setSelectedConnector={setSelectedConnector} />
				<DataConnectorsModalFooter
					selectedConnector={selectedConnector}
					onModalClose={onModalClose}
					requestSetupConnectorLazyQuery={requestSetupConnectorLazyQuery}
					onNextButtonClick={onNextButtonClick}
				/>
			</Flex>
		</Modal>
	);
}
