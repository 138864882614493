import { SelectOption } from '@components/Select/types';
import { searchEntityOptions } from '../types/searchEntity';

export const possibleEntities = searchEntityOptions.map((option) => option.value);
export type EntityLabel = typeof possibleEntities[number];

export type PrettyEntityLabels = {
	[key in EntityLabel]: string;
};

export function labelPretty(label: EntityLabel) {
	const labelToPretty = Object.fromEntries(searchEntityOptions.map((p: SelectOption) => [p.value, p.label ?? p.value]));

	return labelToPretty[label];
}
