import useMetricApi from '@hooks/fetching/useMetricApi';
import { DataModelDimension, useGlobalFiltersApi } from 'src/common/hooks/fetching/useGlobalFiltersApi';
import { FiltersAndBreakdownItemType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import { FetchNodeArguments, GetFiltersAndBreakdownsReturnType, NodeSchemeReturnType } from './types';
import { normalizeValues, parseGlobalDimensions, parseGlobalRelationships, sortElements } from './utils';

export function useNodeScheme({
	metricName,
	periods,
	objectsTypes,
}: {
	metricName: string;
	periods: string[];
	objectsTypes: string[];
}): NodeSchemeReturnType {
	const { getDimensionValues } = useMetricApi();
	const [filtersGlobalState, fetchGlobal] = useGlobalFiltersApi();
	const [getDimensionValuesAPIState, fetchDimensionValues] = getDimensionValues;
	const isLoading = getDimensionValuesAPIState.loading || filtersGlobalState.loading;

	async function getFiltersAndBreakdowns({
		prefixPath,
		shouldIncludeNotNormalizedProps,
	}: FetchNodeArguments): GetFiltersAndBreakdownsReturnType {
		try {
			const filterObjects = prefixPath ? [prefixPath.split('.').reverse()[0].split('>').reverse()[0]] : objectsTypes;

			const response = await fetchGlobal(filterObjects);
			if (!response) {
				return null;
			}

			const relationships: FiltersAndBreakdownItemType[] = [];
			const dimensions: DataModelDimension[] = [];

			Object.entries(response).forEach(([objectType, value]) => {
				const parsedRelationships = parseGlobalRelationships({
					relationships: value.relationships.filter((r) => !r.is_deleted),
					type: objectType,
					shouldIncludeNotNormalizedProps,
					isIncludingTypePrefix: false,
				});
				relationships.push(...parsedRelationships);
				dimensions.push(...value.dimensions);
			});

			const parsedDimensions = parseGlobalDimensions({
				dimensions,
				shouldIncludeNotNormalizedProps,
				isIncludingTypePrefix: false,
			});

			return {
				prefixPath,
				relationships,
				dimensions: parsedDimensions,
				type: filterObjects[0],
			};
		} catch (e) {
			console.log('Error: getGlobalFiltersAndBreakdowns: ', e);
			return null;
		}
	}

	async function getAllDimensionValues({
		prefixPath,
		dimensionLabel = '',
	}: FetchNodeArguments): Promise<FiltersAndBreakdownItemType[]> {
		const response = await fetchDimensionValues(metricName, periods, dimensionLabel, prefixPath, true);
		const values = response.data?.dimensionValues[0]?.result?.values;

		return sortElements(normalizeValues(values));
	}

	const actions = {
		getFiltersAndBreakdowns,
		getAllDimensionValues,
	};

	return [{ isLoading, schemeType: 'regular' }, actions];
}
