import Box from '@components/Box';
import Flex from '@components/Flex';
import { ReactNode } from 'react';
import { Increase16 } from 'src/common/components/Icons';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import { TypographyVariant } from 'src/common/components/Typography/types';
import classes from './PeriodPicker.module.scss';

export type PeriodCellProps = {
	label?: string;
	onIncrease?: () => void;
	onDecrease?: () => void;
	onClick?: () => void;
	actualPeriodString: string;
	periodName: string;
	isSelected: boolean;
	isLast?: boolean;
	isIncremental?: boolean;
	showBorder?: boolean;
	prefixIcon?: ReactNode;
	children?: ReactNode;
	size: PeriodCellSize;
	isDisabled?: boolean;
};
export type PeriodCellSize = 'sm' | 'md';

export function PeriodCell({
	label,
	onIncrease,
	onDecrease,
	onClick,
	actualPeriodString,
	periodName,
	isSelected,
	isLast = false,
	isIncremental = false,
	showBorder = true,
	prefixIcon,
	children,
	size,
	isDisabled,
}: PeriodCellProps) {
	const classNames = [classes.periodCell];
	if (isSelected) classNames.push(classes.selected);
	if (isLast) classNames.push(classes.last);
	if (isDisabled) classNames.push(classes.disabled);

	return (
		<button disabled={isDisabled} onClick={onClick} className={showBorder ? classNames.join(' ') : ''}>
			<Tooltip size={'md'} label={!isDisabled && periodName} title={actualPeriodString} marginTop="12px">
				<Flex flexDirection="row" alignItems="center" justifyContent="end" className={showBorder ? classes[size] : ''}>
					{prefixIcon && <Box marginRight="8px">{prefixIcon}</Box>}
					{children || (
						<Box textAlign="left">
							<Typography variant={typographyByIsSelected[size]}>{label}</Typography>
						</Box>
					)}
					<Box>
						{isIncremental && (
							<Box marginStart="8px" position="relative">
								<Increase16 />
								<div className={classes.increase} onClick={onIncrease}></div>
								<div className={classes.decrease} onClick={onDecrease}></div>
							</Box>
						)}
					</Box>
				</Flex>
			</Tooltip>
		</button>
	);
}

const typographyByIsSelected: { [size in PeriodCellSize]: TypographyVariant } = {
	sm: 'DesktopH8Medium',
	md: 'DesktopH7Medium',
};
