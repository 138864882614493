import Select from 'src/common/components/Select';
import { SelectOption } from 'src/common/components/Select/types';
import { useReportEvent } from 'src/services/analytics';

type FlavorSelectorProps = {
	options: SelectOption[];
	selectedOption: SelectOption;
	onChange: (newVal: SelectOption) => void;
};
export function FlavorSelector({ options, selectedOption, onChange }: FlavorSelectorProps) {
	const { reportEvent, wrapWithReport } = useReportEvent();
	return (
		<Select
			options={options}
			selectedOption={selectedOption}
			onMenuOpen={() => {
				reportEvent({
					event: 'flavor-selector-menu-opened',
					metaData: { feature: 'Metric header' },
				});
			}}
			onChange={(newVal) => {
				wrapWithReport(() => onChange(newVal), 'flavor-selector-changed', {
					oldVal: selectedOption,
					newVal,
					feature: 'Metric header',
				})();
			}}
			size="inline"
			isStoppingClickPropagation={false}
		/>
	);
}
