export function downloadBlob(data: BlobPart, fileName: string) {
	const blob = new Blob([data], {
		type: 'octet/stream',
	});
	const blobAsUrl = window.URL.createObjectURL(blob);
	downloadUrl(blobAsUrl, fileName);
}

export function downloadUrl(url: string, fileName: string) {
	const downloadLink = document.createElement('a');
	downloadLink.href = url;
	downloadLink.download = fileName;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	window.URL.revokeObjectURL(url);
	document.body.removeChild(downloadLink);
}

export function convertB64toBlob(b64Data: string, contentType: string | null = null, sliceSize: number | null = null) {
	contentType = contentType || 'image/png';
	sliceSize = sliceSize || 512;
	const byteCharacters = window.atob(b64Data);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	return new Blob(byteArrays, { type: contentType });
}
