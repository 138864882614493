import * as DateFns from 'date-fns';
import TenantConfigType from 'src/types/tenantConfig';

export const getDataType = (value: any) => {
	if (!value) {
		return null;
	}

	const isNAN = isNaN(parseInt(value));
	const isOnlyDigits = /^\d+\.\d+$|^\d+$/.test(value);

	if (isOnlyDigits && !isNAN) {
		return 'number';
	}

	const datesRegexp = new RegExp('([a-zA-Z]{3}, [0-9]{2} [a-zA-Z]{3} [0-9]{4} [0-9]{2}:[0-9]{2}:[0-9]{2} [A-Z]{3})');
	const isValidDate = value?.toString()?.match(datesRegexp);

	if (isValidDate) {
		return 'date';
	}

	if (typeof value === 'string') {
		return 'text';
	}

	return null;
};

export const datesDefaultFormatting = (value: string) => {
	const dateParsed = new Date(value);
	return DateFns.format(dateParsed, 'dd MMM yyyy');
};

export const numbersDefaultFormatting = (value: string | number, decimalDigits: TenantConfigType['decimalDigits']) => {
	const valueAsInt = typeof value == 'string' ? parseFloat(value) : value;
	if (decimalDigits) valueAsInt.toFixed(decimalDigits);
	if (valueAsInt < 1) return toFixedWithoutTrailingZeros(valueAsInt, 3);
	if (valueAsInt < 10) return toFixedWithoutTrailingZeros(valueAsInt, 2);
	return toFixedWithoutTrailingZeros(valueAsInt, 1);
};

export const textDefaultFormatting = (value: string) => {
	return value;
};

function toFixedWithoutTrailingZeros(number: number, decimalPlaces: number) {
	const fixed = number.toFixed(decimalPlaces);
	return fixed.replace(/\.?0+$/, '');
}
