import { Box, Flex, PopoverContent } from '@chakra-ui/react';
import Popover from 'src/common/components/Popover';
import ListItem from 'src/common/components/ListItem';
import { ReorderButton } from './ReorderButton';
import shadows from 'src/style/shadows';
import { useModal } from 'src/common/hooks/ui/useModal';
import Typography from '@components/Typography';
import Divider from '@components/Divider';
import { useSortOrder } from 'src/pages/MetricPage/hooks/useSortOrder';
import { SortOrder, SortOrderInfo, sortOrderToInfo } from 'src/pages/MetricPage/components/LegendsPanel/types';
import { useCallback } from 'react';
import { Selector } from 'src/pages/MetricPage/utils/state.types';

export function ReorderModal(): JSX.Element {
	const { sortOrderSelector, setSortOrder } = useSortOrder();
	const { isOpen, onOpen, onClose } = useModal();
	const onChange = useCallback(
		(newSortOrder: SortOrderInfo | undefined) => {
			setSortOrder(newSortOrder?.label as SortOrder);
		},
		[setSortOrder]
	);

	return (
		<Popover
			placement="bottom-start"
			variant="solid"
			isOpen={isOpen}
			closeOnBlur={true}
			isLazy
			onClose={onClose}
			onOpen={onOpen}
			triggerElement={() => (
				<Box>
					<ReorderButton
						onClick={onOpen}
						isActive={isOpen}
						color={
							[sortOrderToInfo.Default, sortOrderToInfo.Manual].includes(sortOrderSelector.selectedValue)
								? 'gray.600'
								: 'gray.800'
						}
						icon={sortOrderSelector.selectedValue.icon}
					/>
				</Box>
			)}
		>
			<PopoverContent
				width={'260px'}
				padding={'8px 0px'}
				flexDirection={'column'}
				alignItems={'flex-start'}
				boxShadow={shadows.lg}
				borderRadius={'4px'}
				border="1px solid"
				borderColor={'gray.300'}
			>
				<Flex paddingTop={'4px'} paddingBottom={'4px'} paddingRight={'16px'} paddingLeft={'16px'}>
					<Typography variant={'DesktopH10Medium'} color={'gray.600'}>
						Sort by
					</Typography>
				</Flex>
				<SortOrderListItems onClose={onClose} onChange={onChange} sortOrderSelector={sortOrderSelector} />
				<Box w="100%">
					<Divider mb="8px" mt="8px" color="gray.300" mr="12px" ml="12px" direction="horizontal" />
				</Box>
				<Flex key={'Reset to Default'} flexWrap="nowrap" width="100%">
					<ListItem
						size={'sm'}
						label={'Reset to default'}
						onClick={() => {
							onClose();
							onChange(undefined);
						}}
					/>
				</Flex>
			</PopoverContent>
		</Popover>
	);
}

type SortOrderListItemsProps = {
	onClose: () => void;
	onChange: (newSortOrder?: SortOrderInfo) => void;
	sortOrderSelector: Selector<SortOrderInfo>;
};

function SortOrderListItems({ onClose, onChange, sortOrderSelector }: SortOrderListItemsProps) {
	const optionalSortOrders = sortOrderSelector.optionalValues.map((option) => {
		return (
			<Flex
				key={option.label}
				flexWrap="nowrap"
				width="100%"
				backgroundColor={option.label == sortOrderSelector.selectedValue?.label ? 'blue.200' : 'white'}
			>
				<ListItem
					size={'sm'}
					label={option.label}
					prefixIcon={option.icon}
					onClick={() => {
						onClose();
						onChange(option);
					}}
				/>
			</Flex>
		);
	});
	return (
		<Flex flexWrap="nowrap" width="100%" direction={'column'}>
			{optionalSortOrders}
		</Flex>
	);
}
