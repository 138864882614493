import * as DateFns from 'date-fns';

const datetimeRegexp = new RegExp('(\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{3}Z)');

export const isDatetime = (value: any) => {
	const isValidDate = value?.toString()?.match(datetimeRegexp);
	return isValidDate;
};

export const formatDatetime = (value: any) => {
	const dateParsed = new Date(value);
	return DateFns.format(dateParsed, 'MMM dd, yyyy');
};
