import { Box, GridItem } from '@chakra-ui/react';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useRef, useState } from 'react';
import { ResponsiveGrid } from 'src/common/components/ResponsiveGrid/ResponsiveGrid';
import { useEntityProfileReportEvent } from 'src/layout/EntityProfileDrawer/hooks';
import useNavigation from 'src/services/useNavigation';
import shadows from 'src/style/shadows';
import { DataHygieneTableProps, NameFieldValueProps } from '../types';
import { columnRenderer } from './';
import './DataHygieneTable.scss';

const tableColDefs: ColDef[] = [
	{
		field: 'name',
		headerName: 'Name',
		cellRenderer: columnRenderer.name,
		sortable: true,
		comparator: (
			{ object_name: object_name_a }: NameFieldValueProps,
			{ object_name: object_name_b }: NameFieldValueProps
		) => {
			if (!object_name_a || !object_name_b) return 0;
			if (object_name_a == object_name_b) return 0;
			return object_name_a > object_name_b ? 1 : -1;
		},
		filter: 'agTextColumnFilter',
		filterParams: {
			defaultOption: 'containsText',
			valueGetter: ({ data: { name } }: { data: { name: NameFieldValueProps } }) => name.object_name,
		},
		maxWidth: 650,
	},
	{
		field: 'detection_status',
		headerName: 'Status',
		cellRenderer: columnRenderer.detection_status,
		suppressSizeToFit: true,
		flex: 1,
	},
	{
		field: 'object_type',
		headerName: 'Object',
		cellRenderer: columnRenderer.object_type,
		sortable: true,
		flex: 1,
	},
	{
		field: 'dimension',
		headerName: 'Affected dimensions',
		cellRenderer: columnRenderer.dimension,
		sortable: true,
		flex: 1,
	},
	{
		field: 'object_current_value',
		headerName: 'Value',
		cellRenderer: columnRenderer.object_current_value,
		sortable: true,
		flex: 1,
	},
	{
		headerName: '',
		field: 'actions',
		cellRenderer: columnRenderer.actions,
		width: 72,
		maxWidth: 72,
		menuTabs: [],
		onCellClicked: () => null,
	},
];

export function DataHygieneTable({ rowsData }: DataHygieneTableProps) {
	const { reportOpenEntityDrawer } = useEntityProfileReportEvent();
	const gridRef = useRef<AgGridReact | null>(null);
	const { navigate } = useNavigation();
	const [calculatedTableColDefs, setCalculatedTableColDefs] = useState(tableColDefs);

	const resizeColumns = () => {
		const columnApi = gridRef?.current?.columnApi;

		if (!columnApi) return;

		columnApi.autoSizeAllColumns();

		setCalculatedTableColDefs((prev) => {
			return prev.map((column) => {
				const minWidth = columnApi?.getColumn(column.field)?.getActualWidth();
				return { ...column, minWidth };
			});
		});
	};

	return (
		<Box flexGrow={1} justifyContent="stretch">
			<ResponsiveGrid height={'100%'}>
				<GridItem className="tableContainer" boxShadow={shadows['xl']} colSpan={12} colStart={0}>
					<Box className={'tableWrapper'} height="100%">
						<AgGridReact
							ref={gridRef}
							className="ag-theme-alpine"
							rowData={rowsData}
							columnDefs={calculatedTableColDefs}
							defaultColDef={{
								resizable: true,
								filter: true,
								onCellClicked: (e) => {
									if (!e?.data?.object_id_on_graph) return;

									const nodeId = e.data.object_id_on_graph;
									reportOpenEntityDrawer({ origin: 'data-hygiene', id: nodeId });
									navigate({ path: `node/${nodeId}`, isNewTab: true });
								},
							}}
							getRowHeight={() => 76}
							onFirstDataRendered={resizeColumns}
						/>
					</Box>
				</GridItem>
			</ResponsiveGrid>
		</Box>
	);
}
