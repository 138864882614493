import { useInternalCalcMetric, useInternalCalcMetricStatistics } from '@hooks/fetching/useMetricApi';
import { MetricPeriod, PeriodRange } from '@sightfull/period-ranges';
import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { buildMetricInfo, buildPeriodResultFromResponses } from 'src/common/utils/bizapiParsers';
import { isProductionMode } from 'src/config';
import { OperationsAPIParamsType } from 'src/lib/metricRules/statisticOperations/types';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import {
	DimensionKey,
	FilterRequestsOptions,
	MetricCalcMultiResult,
	MetricCalcResult,
	MetricPostBody,
} from 'src/types/metric';

type ResponseType = 'CALC' | 'STATISTIC';
export type RawResponse = { period: MetricPeriod; response: AxiosResponse; responseType: ResponseType };

export interface CalcMetricApiOptions {
	metricName: string;
	periodRange: PeriodRange;
	filterBy?: FilterRequestsOptions;
	groupBy?: readonly DimensionKey[];
	statisticsOptions?: OperationsAPIParamsType[];
}

export function useCalcMetricApi() {
	const calcMetric = useInternalCalcMetric();
	const calcMetricStatistics = useInternalCalcMetricStatistics();
	const [calcResult, setCalcResult] = useState<MetricCalcResult | null>(null);

	const executeCalcMetric = useCallback(
		({ metricName, periodRange, filterBy, groupBy, statisticsOptions }: CalcMetricApiOptions) => {
			const requestBody: MetricPostBody = {
				filter_by: filterBy,
				group_by: groupBy,
			};

			const calcMetricPeriods = async (allPeriods: MetricPeriod[], metricName: string, requestBody: MetricPostBody) => {
				const metricResult: MetricCalcMultiResult =
					(await calcMetric(
						metricName,
						allPeriods.map((period) => period.id),
						requestBody
					)) ?? {};
				return Object.entries(metricResult).map(([period, result]) => ({
					period: MetricPeriod.fromIdString(period, fiscalYearOffset()),
					response: {
						data: result,
					},
					responseType: 'CALC',
				}));
			};

			const fetchData = async () => {
				const allPeriods = periodRange.asAbsoluteRange.toMetricPeriods();
				const allPeriodsWithStats = statisticsOptions
					? allPeriods.map((period) => statisticsOptions.map((stat) => [period, stat] as const)).flat()
					: null;
				const allPeriodsCalcRequests = await calcMetricPeriods(allPeriods, metricName, requestBody);

				const allPeriodsStatsRequests =
					allPeriodsWithStats?.map(async ([period, currentStat]) => ({
						period,
						response: await calcMetricStatistics(metricName, period.id, currentStat.statisticsOperation, {
							...requestBody,
							operation_info: currentStat.operationInfo,
						}),
						responseType: 'STATISTIC',
					})) ?? [];

				const allResponses = await Promise.allSettled([...allPeriodsCalcRequests, ...allPeriodsStatsRequests]);
				const onlyFulfilledResponses = allResponses
					.map((res) => (res.status === 'fulfilled' ? res.value : null))
					.filter((res) => res) as RawResponse[];

				const firstExistingMetricResponseData = onlyFulfilledResponses
					.map((r) => r.response?.data?.metric)
					.find((m) => m != undefined);

				try {
					const fullResult: MetricCalcResult = {
						info: buildMetricInfo(firstExistingMetricResponseData),
						results: buildPeriodResultFromResponses(onlyFulfilledResponses),
					};
					setCalcResult(fullResult);
					return fullResult;
				} catch (e) {
					if (!isProductionMode) console.log('Metric Calc failed with error:', e);
				}
			};

			return fetchData();
		},
		[calcMetric, calcMetricStatistics]
	);

	return [calcResult, executeCalcMetric] as const;
}

export default useCalcMetricApi;
