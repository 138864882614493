import {
	Account20,
	Campaign20,
	Contact20,
	Departments20,
	Entity20,
	GenericEntity20,
	Lead20,
	Opportunity20,
	OpportunityHistory20,
	Product20,
	Transactions20,
} from '@icons/index';
import { EntityTypeOrDefault } from 'src/layout/EntityProfileDrawer/types';
import Flex from '@components/Flex';

const IconByEntityType: { [key: string]: JSX.Element } = {
	Account: <Account20 />,
	Campaign: <Campaign20 />,
	Contact: <Contact20 />,
	Departments: <Departments20 />,
	Entity: <Entity20 />,
	Lead: <Lead20 />,
	Opportunity: <Opportunity20 />,
	OpportunityHistory: <OpportunityHistory20 />,
	OpportunityLineItem: <Product20 />,
	Transactions: <Transactions20 />,
};

export function LeadIcon({ entityType }: { entityType: EntityTypeOrDefault }) {
	return (
		<Flex width="32px" height="32px" justifyContent="center" alignItems="center">
			<Flex
				flexShrink={0}
				width="32px"
				height="32px"
				borderRadius="100px"
				background="blue.100"
				justifyContent="center"
				alignItems="center"
			>
				{IconByEntityType[entityType] ?? <GenericEntity20 />}
			</Flex>
		</Flex>
	);
}
