import { usePermissionCheck } from 'src/stores/environment';
import { AccessControllerProps } from './types';

function AccessController({ permission, children, noAccessChild, logic = 'AND' }: AccessControllerProps) {
	const { isHavingOneOfPermissions, isHavingAllOfPermissions } = usePermissionCheck();
	const permissions = Array.isArray(permission) ? permission : [permission];
	const hasPermissions = logic == 'OR' ? isHavingOneOfPermissions(permissions) : isHavingAllOfPermissions(permissions);
	return <>{hasPermissions ? children : noAccessChild}</>;
}

export default AccessController;
