import classes from '@components/TopNavigation/AskSightfullAI.module.scss';
import Flex from '@components/Flex';
import { AskAIGray24, AskSightfullAILogo26 } from '@icons/index';
import Typography from '@components/Typography';
import { isMacOperatingSystem } from 'src/common/utils/utils';
import classnames from 'classnames';

export const AskSightfullAI = ({ enabled }: { enabled: boolean }) => {
	return (
		<Flex
			alignItems={'center'}
			justifyContent={'space-between'}
			className={classnames(classes.askSightfullAI, { [classes.withHover]: enabled })}
		>
			<Flex alignItems={'center'} gap={'4px'}>
				{enabled ? <AskAIGray24 /> : <AskSightfullAILogo26 />}
				<Typography variant={'DesktopH8Regular'} color={'gray.600'}>
					Ask Sightfull AI
				</Typography>
			</Flex>
			{enabled ? (
				<Flex alignItems={'center'} justifyContent={'center'} paddingRight={'4px'}>
					<Typography variant={'DesktopH8Regular'} color={'gray.600'}>
						{isMacOperatingSystem ? '⌘' : 'Ctrl'}+K
					</Typography>
				</Flex>
			) : (
				<Flex alignItems={'center'} justifyContent={'center'} className={classes.comingSoonBadge}>
					<Typography variant={'Paragraph12M'} color={'gray.700'}>
						Coming soon
					</Typography>
				</Flex>
			)}
		</Flex>
	);
};
