import { IMarkdownString, languages, Range } from 'monaco-editor';
import { EntityWithMetrics, isRawSQLDataSource } from '../semanticTypes';
import { AUTO_COMPLETE_KINDS } from '../types';
import { buildDiv, buildLink, buildSectionColumn } from './baseBuilders';
import { getCssCompletionTooltip } from './cssTooltip';

export function getEntityCompletionItem(
	entity: EntityWithMetrics,
	replacementRange: Range,
	insertText: string,
	isTriggeringSuggestAfter?: boolean
): languages.CompletionItem {
	return {
		label: {
			description: entity.meta?.display_name ?? entity.name,
			label: entity.name,
		},
		insertText,
		sortText: `0_${entity.name}`,
		kind: AUTO_COMPLETE_KINDS['entity'],
		documentation: getEntityCssCompletionTooltip(entity, insertText),
		range: replacementRange,
		command: isTriggeringSuggestAfter ? { title: 'trigger', id: 'editor.action.triggerSuggest' } : undefined,
	};
}
export function getEntityCssCompletionTooltip(
	entity: EntityWithMetrics,
	insertText: string
): IMarkdownString | undefined {
	const source = !isRawSQLDataSource(entity.data_source) && entity.data_source?.schema?.meta?.source;
	const sourceIcon = source ? source.replaceAll(' ', '-') : 'fallback';

	const additionalInformationRows = [];

	const entitySection = buildSectionColumn('', 'Entity', entity.name);
	const openInTabIcon = buildDiv('open-in-tab-icon');
	const linkToEntity = `${window.location.origin}/ontology/${entity.name}`;
	additionalInformationRows.push(
		buildLink(linkToEntity, buildDiv('no-flow-layout-space-between', `${entitySection}${openInTabIcon}`))
	);

	additionalInformationRows.push(buildSectionColumn('', 'Primary keys', entity.primary_keys.join(', ')));

	return getCssCompletionTooltip(
		{
			title: entity.meta?.display_name ?? entity.name,
			subtitle: 'Entity',
			description: entity.meta?.description,
			additionalInformationRows,
			image: {
				name: ` source-icon-${sourceIcon}`,
				color: 'gray',
			},
		},
		insertText
	);
}
