import { FeedSignal } from 'src/types/spaces';

export const isSignalAnInsight = (signal: FeedSignal) => {
	return !!signal.signal.insight_type;
};

export const isSignalANotification = (signal: FeedSignal, myReasonsRegexp: RegExp) => {
	if (isSignalAnInsight(signal) || !signal.reason) return false;

	return isString(signal.reason)
		? !myReasonsRegexp.test(signal.reason)
		: !myReasonsRegexp.test(signal.reason[signal.reason.length - 1]);
};
export const isString = (val: unknown): val is string => typeof val == 'string';

export const isSignalValid = (signal: FeedSignal, myReasonsRegexp: RegExp) => {
	return isSignalAnInsight(signal) || isSignalANotification(signal, myReasonsRegexp);
};
