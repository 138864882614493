import { Box, Flex } from '@chakra-ui/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import ToggleButton from 'src/common/components/ToggleButton';
import { usePreventReload } from 'src/common/hooks/usePreventReload';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { OnModalOpenType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import { useReportEvent } from 'src/services/analytics';
import useNavigationBlock from 'src/services/useNavigationBlock';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { useMetricEditorState } from '../../hooks/useMetricEditorState';
import { PANEL_NAMES } from '../../utils/consts';
import EditPanel from '../CalculatePanel';
import { EditorFeature } from '../CalculatePanel/EditPanel';
import { InvestigatePanel } from '../InvestigatePanel';
import classes from './DrillDownPanel.module.scss';

type Props = {
	isShowingFullSize: boolean;
	isEditMetricEnabled?: boolean;
	isEditMode?: boolean;
	setEditMode?: (arg: number) => void;
	onModalOpen: OnModalOpenType;
	setExpanded?: () => void;
	calculationRunState?: string;
	isPreviewEnabled?: boolean;
	hasUnsavedChanges?: boolean;
	setHasEditorErrors?: (s: boolean) => void;
	toggleGenAIPopover: VoidFunction;
	selectedFeature?: EditorFeature;
	setSelectedFeature?: (feature: EditorFeature) => void;
	shouldPreventToggle?: boolean;
};

export function DrillDownPanel({
	isShowingFullSize,
	isEditMetricEnabled,
	isEditMode,
	setEditMode,
	onModalOpen,
	setExpanded,
	isPreviewEnabled,
	setHasEditorErrors,
	toggleGenAIPopover,
	selectedFeature = 'YAML Builder',
	setSelectedFeature,
	shouldPreventToggle,
}: Props) {
	const { metricNameWithFlavor: metricName, errorMessage, isFullyDefined } = useMetricDerivedState();

	const { reportEvent } = useReportEvent({ metricName });
	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	const { hasChangesFromSavedValue } = useMetricEditorState();
	const { setIsNavigationBlocked } = useNavigationBlock();
	const [isUnloadModalVisible, setIsUnloadModalVisible] = useState(false);

	const { clearState } = useBuilderDerivedState();

	usePreventReload({ dependency: !!hasChangesFromSavedValue });

	useEffect(
		() => setIsNavigationBlocked({ isBlocked: hasChangesFromSavedValue && hasEditPermission }),
		[hasChangesFromSavedValue, hasEditPermission, setIsNavigationBlocked]
	);

	const handleToggleChange = () => {
		setEditMode?.(0);
		setExpanded?.();
		clearState();
	};

	const RealDrillDownPanel = (
		<>
			{isEditMode ? (
				<EditPanel
					setIsUnloadModalVisible={setIsUnloadModalVisible}
					isUnloadModalVisible={isUnloadModalVisible}
					shouldPreventToggle={shouldPreventToggle}
					selectedFeature={selectedFeature}
					setSelectedFeature={setSelectedFeature}
					handleToggleChange={handleToggleChange}
					isPreviewEnabled={isPreviewEnabled}
					setEditorErrors={(errors) => setHasEditorErrors?.(errors.length > 0)}
				/>
			) : (
				<InvestigatePanel
					isDisabled={!!errorMessage}
					isShowingFullSize={isShowingFullSize}
					onModalOpen={onModalOpen}
					toggleGenAIPopover={toggleGenAIPopover}
				/>
			)}
		</>
	);

	return (
		<Flex direction={'column'} height="100%">
			{isEditMetricEnabled && isFullyDefined && (
				<Box
					className={`${classes.buttonsWrapper} ${!isShowingFullSize ? classes.isHidden : ''}`}
					height={isEditMetricEnabled && isShowingFullSize ? 'auto' : 0}
				>
					<ToggleButton
						size="lg"
						fullWidth
						shouldFlexContent
						options={[
							<Flex key={PANEL_NAMES[0]} direction={'row'} alignItems="center" gap="8px">
								{PANEL_NAMES[0]}
							</Flex>,
							<Flex key={PANEL_NAMES[1]} direction={'row'} alignItems="center" gap="8px">
								{PANEL_NAMES[1]}
							</Flex>,
						]}
						intercomDataAttributes={[
							{ area: 'metric', type: 'button', target: PANEL_NAMES[0] },
							{ area: 'metric', type: 'button', target: PANEL_NAMES[1] },
						]}
						isDisabled={false}
						selectedColor="white"
						selectedIndex={isEditMode ? 1 : 0}
						onChildClicked={(index) => {
							if (!!index === isEditMode) return;
							if (hasChangesFromSavedValue && hasEditPermission) {
								setIsUnloadModalVisible(true);
								return;
							}
							setEditMode?.(index);
							setExpanded?.();
							if (!hasEditPermission) {
								clearState();
							}
							reportEvent({
								event: 'metric-page-mode-toggle',
								metaData: { feature: 'Metric Builder', newState: PANEL_NAMES[index], metric: metricName },
							});
						}}
					/>
				</Box>
			)}

			<Box overflowY={!isShowingFullSize ? 'unset' : 'auto'} flex={1}>
				<Box className={classNames({ [classes.panelWrapper]: isEditMode })}>{RealDrillDownPanel}</Box>
			</Box>
		</Flex>
	);
}
