import { editor, languages, Position } from 'monaco-editor';
import { CompletionProvider } from '../completionProvider';
import { EnrichedSemanticDefinitions } from '../semanticTypes';
import { findYamlKey } from '../utils/findYamlKey';
import { getWordRangeAtPosition, rangeFromPosition } from '../utils/utils';
import { getEntityCompletionItem } from '../widgetBuilder/entityCompletionItem';

export const MonacoEntityCompletionItemProviderBuilder = (
	semanticDefinitions: EnrichedSemanticDefinitions
): languages.CompletionItemProvider => ({
	provideCompletionItems: buildMonacoEntityCompletionProvider(semanticDefinitions),
	triggerCharacters: ['.', ' ', '('],
});

const buildMonacoEntityCompletionProvider =
	(semanticDefinitions: EnrichedSemanticDefinitions) => (model: editor.ITextModel, position: Position) => {
		const partRange =
			getWordRangeAtPosition(model, position, CompletionProvider.EXPRESSION_PART) ?? rangeFromPosition(position);

		const yamlKey = findYamlKey(model.getValue(), model.getOffsetAt(position));
		if (yamlKey !== 'entity') {
			return;
		}

		const completionItems = semanticDefinitions.entities
			.filter((entity) => entity.isDefined)
			.map((entity) => {
				return getEntityCompletionItem(entity, partRange, entity.name);
			});

		return {
			suggestions: completionItems,
		};
	};
