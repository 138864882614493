import { Box, Flex } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import { useRef } from 'react';
import Button from 'src/common/components/Button';
import { Check16, CloseRound16 } from 'src/common/components/Icons';
import { MessageSlider } from 'src/common/components/MessageSlider';
import { ResizePanel } from 'src/common/components/ResizePanel';
import { MonacoYamlEditor } from 'src/pages/MetricPage/components/MonacoEditor/MonacoYamlEditor';
import { YAMLEditorDescription } from 'src/pages/MetricPage/components/YAMLEditorDescription/YAMLEditorDescription';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { writePartialOntologyState } from '../../atoms/OntologyState';
import classes from './EditorPanel.module.scss';
import { PanelProps } from './types';

export function EditorPanel({
	hasUnsavedChanges,
	editorValue,
	isEditable,
	requestMessage,
	entityName,
	editorLoading = false,
	onButtonClick,
	isSaveAndRunButtonDisabled,
}: PanelProps) {
	const editorWrapperRef = useRef(null);
	const { reportEvent } = useReportEvent();
	const setPartialOntologyState = useSetAtom(writePartialOntologyState);
	const isMessageSuccess = requestMessage === 'SUCCESS';
	const isMessageOpen = requestMessage !== 'NONE';

	return (
		<ResizePanel minWidth={372} width={window.innerWidth * 0.4} position="left">
			<Flex className={classes.panelWrapper}>
				<Flex ref={editorWrapperRef} className={classes.itemsWrapper} flexDirection={'column'}>
					<Box paddingLeft="16px" paddingTop={'8px'}>
						<YAMLEditorDescription
							isEntity
							onClick={() =>
								reportEvent({
									event: 'entity-edit-YAML-help-clicked',
									metaData: { feature: 'YAML Editor', entityName, editMode: 'YAML' },
								})
							}
						/>
					</Box>
					<MessageSlider
						isOpen={isMessageOpen}
						text={isMessageSuccess ? 'Successfully saved!' : 'SQL syntax error.'}
						icon={isMessageSuccess ? <Check16 color={colors.green[600]} /> : <CloseRound16 color={colors.red[700]} />}
					/>
					<Box position={'relative'} marginTop={isMessageOpen ? '-8px' : 0} flex={1}>
						<MonacoYamlEditor
							editorLoading={editorLoading}
							schemaType="normalization"
							onFocus={() => {
								reportEvent({
									event: 'ontology-object-yaml-focus-clicked',
									metaData: { objectType: 'full entity', parentEntity: entityName, objectName: entityName },
								});
							}}
							onHelpClicked={() =>
								reportEvent({
									event: 'entity-edit-YAML-help-clicked',
									metaData: { feature: 'YAML Editor', entity: entityName, editMode: 'YAML' },
								})
							}
							isEditable={isEditable}
							hasUnsavedChanges={hasUnsavedChanges}
							value={editorValue}
							onChange={(value) => {
								setPartialOntologyState({ editorYaml: value });
							}}
							onErrorsChange={(errors) => {
								setPartialOntologyState({ hasYamlErrors: errors.length > 0 });
							}}
						/>
					</Box>
				</Flex>
				{onButtonClick && (
					<Flex padding="16px 24px" justifyContent="end" alignItems="center" boxShadow={shadows.borderTop}>
						<Button
							onClick={onButtonClick}
							isDisabled={isSaveAndRunButtonDisabled}
							size="small"
							colorScheme="black"
							variant="solid"
						>
							Save & Run
						</Button>
					</Flex>
				)}
			</Flex>
		</ResizePanel>
	);
}
