import Avatar, { AvatarProps } from '@components/Avatar/Avatar';
import useUser from '@hooks/stores/useUser';

export type AvatarContainerProps = Omit<AvatarProps, 'name' | 'imageUrl'>;

const AvatarContainer = ({ width, height, shape }: AvatarContainerProps) => {
	const [{ picture, firstName, lastName }] = useUser();
	const fullName = `${firstName} ${lastName}`;

	return <Avatar width={width} height={height} shape={shape} imageUrl={picture} name={fullName} />;
};

export default AvatarContainer;
