import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonEditorPanel.module.scss';
import { Box, Flex } from '@chakra-ui/react';
import colors from 'src/style/colors';
import Divider from '@components/Divider';

const { titleSkeleton, editorSkeleton, descriptioneleton } = classes;

function SkeletonEditor() {
	return (
		<Flex padding={'18px 24px'} flex={1}>
			<Skeleton className={editorSkeleton} />
		</Flex>
	);
}

export function SkeletonEditorPanel() {
	return (
		<Flex flexDirection={'column'} borderLeft={`1px solid ${colors.gray[300]}`} minW={'372px'}>
			<Flex padding={'12px 20px'} gap={'12px'}>
				<Skeleton className={titleSkeleton} />
				<Skeleton className={titleSkeleton} />
			</Flex>
			<Box>
				<Divider direction={'horizontal'} />
			</Box>
			<Flex padding={'18px 24px'}>
				<Skeleton className={descriptioneleton} />
			</Flex>
			<SkeletonEditor />
		</Flex>
	);
}
