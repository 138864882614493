import { useModal } from '@hooks/ui/useModal';
import { useRef, useState } from 'react';
import { Filter } from '../../utils/state.types';
import { FILTER_MODEL_TYPE } from './consts';
import { FiltersAndBreakdownResponseType, ListType, OnModalCloseType, OnModalOpenType } from './types';

export type FiltersAndBreakdownsStateType = {
	isOpen: boolean;
	type: ListType;
	initialFilter?: Filter;
};

export type FiltersAndBreakdownsActionsType = {
	onClose: () => void;
	onOpen: OnModalOpenType;
	onAddItems: (items: FiltersAndBreakdownResponseType) => void;
};

type ReturnValue = [FiltersAndBreakdownsStateType, FiltersAndBreakdownsActionsType];

function useFiltersAndBreakdown(): ReturnValue {
	const { isOpen, onClose, onOpen } = useModal();
	const OnModalCloseRef = useRef<OnModalCloseType | null>(null);

	const [type, setType] = useState<ListType>(FILTER_MODEL_TYPE);
	const [initialFilter, setInitialFilter] = useState<Filter>();

	const onAddItems = (response: FiltersAndBreakdownResponseType) => {
		if (OnModalCloseRef.current) OnModalCloseRef.current(response);
	};

	const onModalOpen = (requestedType: ListType, onModalClose: OnModalCloseType, initialFilter?: Filter) => {
		setType(requestedType);
		setInitialFilter(initialFilter);
		OnModalCloseRef.current = onModalClose;
		onOpen();
	};

	return [
		{ type, isOpen, initialFilter },
		{ onClose, onOpen: onModalOpen, onAddItems },
	];
}

export default useFiltersAndBreakdown;
