import { useCallback } from 'react';
import { selectors as featureFlagsSelectors, useFeatureFlagsStore } from 'src/stores/featureFlags';
import { selectors as userSelectors, useUserStore } from 'src/stores/user';
import { UserType } from 'src/types/user';

import { logout as authLogout } from 'src/services/auth';

type ActionsType = {
	logout: () => void;
};

type ReturnType = [UserType, ActionsType];

function useUser(): ReturnType {
	const user = useUserStore(userSelectors.getUser);
	const resetUserStore = useUserStore(userSelectors.reset);
	const resetFeatureFlagsStore = useFeatureFlagsStore(featureFlagsSelectors.reset);

	const logout = useCallback(() => {
		resetFeatureFlagsStore();
		resetUserStore();
		authLogout();
	}, [resetUserStore, resetFeatureFlagsStore]);

	const actions = {
		logout,
	};

	return [user, actions];
}

export default useUser;
