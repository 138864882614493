import { Box, Flex, InputLeftElement as ChakraInputLeftElement } from '@chakra-ui/react';
import * as CSS from 'csstype';
import { ReactNode } from 'react';

type Props = {
	component?: ReactNode;
	borderRightColor?: CSS.Property.Color;
	leftComponentLeftPadding?: string;
};

function Left({ component, borderRightColor, leftComponentLeftPadding }: Props): JSX.Element | null {
	return component ? (
		<ChakraInputLeftElement
			width={'fit-content'}
			justifyContent="start"
			pl={leftComponentLeftPadding}
			pr={'8px'}
			alignItems={'center'}
			color="gray.700"
		>
			<Flex justifyContent={'space-between'} alignItems="center" gap={'8px'}>
				<Flex height={'fit-content'} justifyContent="center">
					{component}
				</Flex>
				{borderRightColor ? <Box height={'16px'} borderRight={`1px solid`} borderColor={borderRightColor} /> : null}
			</Flex>
		</ChakraInputLeftElement>
	) : null;
}

export default Left;
