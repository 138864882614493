import { Box, Flex } from '@chakra-ui/react';
import { ICellRendererParams } from 'ag-grid-community';
import Divider from 'src/common/components/Divider';
import IconButton from 'src/common/components/IconButton';
import { DotsSolid16 } from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import Popover from 'src/common/components/Popover';
import { useReportEvent } from 'src/services/analytics';
import { ItemActionProps, ObjectSource, TabsStatuses } from '../types';

export const ItemActions = ({ value: { issueActions, objectSource, detection_status } }: ICellRendererParams) => {
	const { reportEvent, wrapWithReport } = useReportEvent();
	const source_status = detection_status as TabsStatuses;
	const actions = issueActions as ItemActionProps;
	const { icon, object_datasource, object_source_url } = objectSource as ObjectSource;
	return (
		<Flex alignItems={'center'} py={'22px'}>
			<Popover
				variant="solid"
				closeOnBlur={true}
				trigger="click"
				isLazy
				triggerElement={(isOpen) => (
					<Flex height={'32px'} alignItems={'center'}>
						<IconButton size="xs" icon={<DotsSolid16 />} colorScheme="black" isActive={isOpen} />
					</Flex>
				)}
			>
				<Box py={'8px'}>
					{actions.map(({ label, icon, onClick }) => (
						<ListItem
							p={'0px 16px'}
							key={label}
							size={'sm'}
							prefixIcon={icon}
							label={label}
							onClick={wrapWithReport(onClick, 'data-hygiene-click-on-action', {
								source_status,
								target_status: label?.split(' ')?.[0],
							})}
						/>
					))}
				</Box>
				<Divider direction="horizontal" />
				<Box py={'8px'}>
					<ListItem
						p={'0px 16px'}
						size={'sm'}
						prefixIcon={icon}
						label={`View on ${object_datasource}`}
						onClick={() => {
							reportEvent({
								event: 'data-hygiene-go-to-issue-object-url',
								metaData: { object_datasource, object_source_url },
							});
							if (object_source_url) window?.open(object_source_url);
						}}
					/>
				</Box>
			</Popover>
		</Flex>
	);
};
