import Box from '@components/Box';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import useSearchParams from '@hooks/navigation/useSearchParams';
import { useAtomValue, useSetAtom } from 'jotai';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'src/common/components/Button';
import { Ellipse16 } from 'src/common/components/Icons';
import { ResizePanel } from 'src/common/components/ResizePanel';
import Scrollable from 'src/common/components/Scrollable';
import { useKeyPress } from 'src/common/hooks/interaction/useKeyPress';
import { useSemanticDefinitionsForEntity } from 'src/common/hooks/stores/useSemanticDefinitions';
import useToast from 'src/common/hooks/ui/useToast';
import useDebouncedCallback from 'src/common/hooks/useDebouncedCallback';
import { checkForMatch } from 'src/common/utils/format';
import { EntityCatalogQuery, useRelationshipsAndDimensionsQuery } from 'src/generated/graphql';
import { LoadedDimension, LoadedRelationship } from 'src/lib/completions/semanticTypes';
import { PropertyUpdate } from 'src/pages/OntologyPage/utils/updateYaml';
import { useReportEvent } from 'src/services/analytics';
import { OntologyStateAtomDerived, upsertPropertyOntologyState } from '../../atoms/OntologyState';
import {
	CreateNewDimensionPropertyName,
	CreateNewRelationshipPropertyName,
	usePropertyNameParam,
	useSetOntologyLocation,
} from '../../hooks/useOntologySearchParams';
import { OntologyDimensionInfo, OntologyRelationshipInfo } from '../../utils/normalizeYaml';
import { OntologyPropertyType } from '../../utils/types';
import { getAffectedMetricsList } from '../../utils/utils';
import DeleteItemsModal from './DeleteItemsModal';
import DimensionsList from './DimensionsList';
import { PropertyPanel } from './PropertyPanel/PropertyPanel';
import RelationshipsList from './RelationshipsList';

export default function BuilderPanel({
	entityDisplayName,
	entities,
	onSubmit,
	isEditable = true,
}: {
	entityDisplayName: string;
	entities: EntityCatalogQuery['entityCatalog'];
	onSubmit: (value?: string[], additionalTableColumns?: string[]) => void;
	isEditable?: boolean;
}) {
	const [propertyToDelete, setPropertyToDelete] = useState<OntologyDimensionInfo | OntologyRelationshipInfo>();
	const [selectedTab, setSelectedTab] = useState<OntologyPropertyType>('dimensions');
	const [dimensionsList, setDimensionsList] = useState<OntologyDimensionInfo[]>([]);
	const [relationshipList, setRelationshipList] = useState<OntologyRelationshipInfo[]>([]);
	const [searchValue, setSearchValue] = useState<string>();
	const selectedPropertyName = usePropertyNameParam();
	const updateOntologyLocation = useSetOntologyLocation();
	const { reportEvent } = useReportEvent();
	const upsertProperty = useSetAtom(upsertPropertyOntologyState);
	const toast = useToast();
	//TODO-Ontology: Consolidate states and atoms
	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const { data: relationshipsAndDimensions } = useRelationshipsAndDimensionsQuery({
		variables: { entities: [ontologyState.loading ? '' : ontologyState.entityName] },
		skip: ontologyState.loading,
	});
	const [urlSearchParams] = useSearchParams();
	const builderTab = urlSearchParams.get('builderTab');
	const [isAdvancedMode, setIsAdvancedMode] = useState(false);

	const entitySemanticDefinition = useSemanticDefinitionsForEntity(
		ontologyState.loading ? undefined : ontologyState.entityName
	);

	useEffect(() => {
		setSelectedTab((builderTab as OntologyPropertyType) || 'dimensions');
	}, [builderTab]);

	const dimensions = useMemo(
		() => (ontologyState.loading ? [] : ontologyState?.parsedYaml?.dimensions),
		[ontologyState]
	);

	const relationships = useMemo(
		() => (ontologyState.loading ? [] : ontologyState.parsedYaml.relationships),
		[ontologyState]
	);

	useEffect(() => {
		if (selectedTab === 'dimensions') setDimensionsList(_.sortBy(dimensions, 'name'));
		if (selectedTab === 'relationships') setRelationshipList(_.sortBy(relationships, 'name'));
	}, [dimensions, relationships, selectedTab, ontologyState.loading]);

	const setDimensionsListWithSort = (value: string) => {
		if (!value) {
			setDimensionsList(_.sortBy(dimensions, 'name'));
			return;
		}
		setDimensionsList(
			_.sortBy(
				dimensions?.filter(
					(el) =>
						checkForMatch({ subject: el.meta?.display_name, value: value || '' }) ||
						checkForMatch({ subject: el.name, value: value || '' })
				),
				'name'
			)
		);
	};

	const setRelationshipsListWithSort = (value: string) => {
		if (!value) {
			setRelationshipList(_.sortBy(relationships, 'name'));
			return;
		}
		setRelationshipList(
			_.sortBy(
				relationships.filter(
					(el) => checkForMatch({ subject: el.meta?.display_name, value }) || checkForMatch({ subject: el.name, value })
				),
				'name'
			)
		);
	};

	const onSearchType = (value: string) => {
		if (value)
			reportEvent({
				event: 'ontology-object-search-type',
				metaData: { objectType: selectedTab, searchTerm: value },
			});
		if (selectedTab === 'dimensions') setDimensionsListWithSort(value);
		if (selectedTab === 'relationships') setRelationshipsListWithSort(value);
	};

	const debounceSearch = useDebouncedCallback(onSearchType, 500);

	const debounceEscape = useDebouncedCallback(() => {
		if (searchValue !== undefined) {
			if (selectedTab === 'dimensions') setDimensionsListWithSort('');
			if (selectedTab === 'relationships') setRelationshipsListWithSort('');
			setSearchValue(undefined);
		}
	}, 500);

	useKeyPress(['Escape'], debounceEscape);

	const onSearch = useCallback(
		(val?: string) => {
			setSearchValue(val);
			debounceSearch(val);
		},
		[debounceSearch]
	);

	const entityRelationshipAndDimensionInfo = useMemo(() => {
		if (!relationshipsAndDimensions?.relationshipsAndDimensions.entities.length) return;
		return relationshipsAndDimensions.relationshipsAndDimensions.entities[0];
	}, [relationshipsAndDimensions]);

	const findItemWithUndefinedProperty = (list?: LoadedDimension[] | LoadedRelationship[]) =>
		list?.find((dimension) => dimension.isDefined === false);

	const hasNotFullyDefinedDimension = useMemo(
		() => findItemWithUndefinedProperty(entitySemanticDefinition?.dimensions),
		[entitySemanticDefinition?.dimensions]
	);

	const hasNotFullyDefinedRelationship = useMemo(
		() => findItemWithUndefinedProperty(entitySemanticDefinition?.relationships),
		[entitySemanticDefinition?.relationships]
	);

	const affectedMetricsList = useMemo(
		() =>
			propertyToDelete && entityRelationshipAndDimensionInfo
				? getAffectedMetricsList(
						propertyToDelete,
						selectedTab === 'dimensions'
							? entityRelationshipAndDimensionInfo?.dimensions
							: entityRelationshipAndDimensionInfo?.relationships
				  )
				: [],
		[entityRelationshipAndDimensionInfo, propertyToDelete, selectedTab]
	);

	if (ontologyState.loading) return;

	const onCreateNewClick = () => {
		reportEvent({
			event: `ontology-create-new-object-clicked`,
			metaData: { objectType: selectedTab, parentEntity: ontologyState.entityName },
		});
		updateOntologyLocation({
			ontologyType: 'entity',
			ontologyName: ontologyState.entityName,
			propertyName: selectedTab === 'dimensions' ? CreateNewDimensionPropertyName : CreateNewRelationshipPropertyName,
		});
	};

	const onSelect = (val: OntologyRelationshipInfo | OntologyDimensionInfo) => {
		updateOntologyLocation({ ontologyType: 'entity', ontologyName: ontologyState.entityName, propertyName: val.name });
		reportEvent({
			event: `ontology-object-clicked`,
			metaData: { objectType: selectedTab, parentEntity: ontologyState.entityName, objectName: val.name },
		});
	};

	const onDeleteModalSubmit = () => {
		if (!propertyToDelete) return;
		const PropertyUpdate: PropertyUpdate = {
			currentPropertyName: propertyToDelete?.name,
			ontologyPropertyType: selectedTab,
			propertyDefinition: null,
		};
		try {
			upsertProperty({
				...PropertyUpdate,
				result: (result) => {
					onSubmit(result.yaml.split('\n'));
					PropertyUpdate?.result?.(result);
				},
			});
		} catch (e) {
			if (e instanceof Error) {
				toast({ variant: 'error', message: e.message });
			}
		}
		setPropertyToDelete(undefined);
	};

	const TabButton = ({
		label,
		newState,
		isSelected,
		hasWarning,
	}: {
		label: string;
		newState: OntologyPropertyType;
		isSelected: boolean;
		hasWarning?: LoadedDimension | LoadedRelationship;
	}) => (
		<Button
			onClick={() => {
				reportEvent({
					event: 'ontology-dimensions-relationships-toggle',
					metaData: { objectName: ontologyState.entityName, newState },
				});
				setSelectedTab(newState);
				onSearch?.(undefined);
			}}
			variant="outline"
			colorScheme={isSelected ? 'black' : 'gray'}
			backgroundColor={isSelected ? 'gray.200' : 'transparent'}
			size="small"
		>
			<Flex>
				<Typography variant="DesktopH8Medium">{label}</Typography>
				{hasWarning && (
					<Box color="yellow.700">
						<Ellipse16 />
					</Box>
				)}
			</Flex>
		</Button>
	);

	const FeatureSwitcher = () => {
		const isSelectedDimensionsTab = selectedTab === 'dimensions';
		const isSelectedRelationshipsTab = selectedTab === 'relationships';
		return (
			<Flex borderBottom={'1px solid'} gap={'8px'} borderColor={'gray.300'} padding={'12px 20px'}>
				<TabButton
					label="Dimensions"
					newState="dimensions"
					isSelected={isSelectedDimensionsTab}
					hasWarning={hasNotFullyDefinedDimension}
				/>
				<TabButton
					label="Relationships"
					newState="relationships"
					isSelected={isSelectedRelationshipsTab}
					hasWarning={hasNotFullyDefinedRelationship}
				/>
			</Flex>
		);
	};

	return (
		<ResizePanel isResizeBlocked={!isAdvancedMode} minWidth={372} width={372} position="left">
			<Flex flexDir="column" h="100%" w="100%" transition={'0.2s'}>
				{selectedPropertyName ? (
					<PropertyPanel
						isAdvancedMode={isAdvancedMode}
						setIsAdvancedMode={setIsAdvancedMode}
						entities={entities}
						onMoveBack={() => {
							updateOntologyLocation({ ontologyType: 'entity', ontologyName: ontologyState.entityName });
							onSearch(searchValue);
						}}
						onSubmit={onSubmit}
						isEditable={isEditable}
					/>
				) : (
					<>
						{<FeatureSwitcher />}
						<Flex
							flex={1}
							overflowY={'auto'}
							flexDir={'column'}
							alignItems={'flex-start'}
							gap={'24px'}
							alignSelf={'stretch'}
						>
							<Scrollable>
								<Flex paddingX={'24px'} paddingY={'16px'} flexDir={'column'} gap={'12px'} alignSelf={'stretch'}>
									{selectedTab === 'dimensions' ? (
										<DimensionsList
											setPropertyToDelete={setPropertyToDelete}
											searchValue={searchValue}
											onSearch={onSearch}
											list={dimensionsList}
											isEditable={isEditable}
											onCreateNewClick={onCreateNewClick}
											onSelect={(val: OntologyDimensionInfo) => onSelect(val)}
											dimensionsFromQuery={entityRelationshipAndDimensionInfo?.dimensions}
										/>
									) : selectedTab === 'relationships' ? (
										<RelationshipsList
											setPropertyToDelete={setPropertyToDelete}
											searchValue={searchValue}
											onSearch={onSearch}
											list={relationshipList}
											isEditable={isEditable}
											entities={entities}
											onCreateNewClick={onCreateNewClick}
											onSelect={(val: OntologyRelationshipInfo | OntologyDimensionInfo) => onSelect(val)}
											entityDisplayName={entityDisplayName}
										/>
									) : (
										<Typography variant="Paragraph12R" color={'gray.700'}>
											Error: Unknown tab selected
										</Typography>
									)}
								</Flex>
							</Scrollable>
						</Flex>
					</>
				)}
			</Flex>
			<DeleteItemsModal
				type={selectedTab}
				onSubmit={onDeleteModalSubmit}
				propertyToDelete={propertyToDelete}
				affectedMetricsList={affectedMetricsList}
				onClose={() => setPropertyToDelete(undefined)}
			/>
		</ResizePanel>
	);
}
