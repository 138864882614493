import { useAtom } from 'jotai';
import { TableRefAtom } from '../../atoms/TableRef';
import { useGridTableApi, useIsGridTableV2Enabled } from '@components/GridTable';

export function useTableColumnStateAsSearchParam() {
	const isGridTableV2 = useIsGridTableV2Enabled();
	const gridTableApiV2 = useGridTableApi().gridTableApi;
	const [tableRefApi] = useAtom(TableRefAtom);
	const tableApi = isGridTableV2 ? gridTableApiV2 : tableRefApi;

	const getColumnStateSearchParam = () => {
		if (!tableApi?.columnApi?.getColumnState) return '';

		const tableColumnState = tableApi.columnApi.getColumnState();
		return JSON.stringify(tableColumnState);
	};

	return { getColumnStateSearchParam };
}
