import Box from '@components/Box';
import Button from '@components/Button';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import { CreateMetricModal } from '@components/MetricView/MetricMissingDependencies/CreateMetricModal';
import classes from '@components/MetricView/MetricMissingDependencies/MissingDependencyCard/MissingDependencyCard.module.scss';
import Typography from '@components/Typography';
import { useModal } from '@hooks/ui/useModal';
import { ArrowRight16, ErrorYellow16 } from '@icons/index';
import { OntologyPagePath } from '@pages/OntologyPage/OntologyBuilderPage';
import { useReportEvent } from '@services/analytics';
import useNavigation from '@services/useNavigation';
import { useState } from 'react';
import { MetricMissingDependency, MetricMissingDependencyType } from 'src/types/metric';

type MissingDependencyCardProps = {
	missingDependency: MetricMissingDependency;
	metricName: string;
	metricEntity: string;
};

export const MissingDependencyCard = ({ missingDependency, metricName, metricEntity }: MissingDependencyCardProps) => {
	const { navigate } = useNavigation();
	const { reportEvent } = useReportEvent();
	const { type, numOfDependencies, description, entityName, name } = missingDependency;
	const [missingMetricName, setMissingMetricName] = useState('');
	const {
		isOpen: isCreateMetricModalOpen,
		onOpen: onCreateMetricModalOpen,
		onClose: onCreateMetricModalClose,
	} = useModal({ defaultIsOpen: false });

	const handleOnClick = (dependencyType: MetricMissingDependencyType, missingDependencyName: string) => {
		reportEvent({ event: 'missing-dependency-click', metaData: { dependencyType, metric: metricName } });

		switch (dependencyType) {
			case 'metrics':
				setMissingMetricName(missingDependencyName);
				return onCreateMetricModalOpen();
			case 'entities':
				return navigate({
					path: `${OntologyPagePath}/${missingDependencyName}`,
					isNewTab: true,
				});
			case 'dimensions':
			case 'relationships': {
				const additionalSearchParams = new URLSearchParams();
				additionalSearchParams.append('builderTab', dependencyType);

				return navigate({
					path: `${OntologyPagePath}/${metricEntity}`,
					additionalSearchParams,
					isNewTab: true,
				});
			}
		}
	};

	return (
		<>
			<Flex
				p={'12px 16px 14px 16px'}
				justifyContent={'space-between'}
				alignItems={'center'}
				borderRadius={'8px'}
				backgroundColor={'gray.100'}
				gap={'16px'}
				mr={'12px'}
				className={classes.dependencyCard}
				maxWidth={'320px'}
			>
				<Flex gap={'16px'} alignItems={'center'} minW={'0'} height={'42px'}>
					<Box width={'16px'} height={'16px'}>
						<ErrorYellow16 />
					</Box>
					<Divider direction="vertical" color={'gray.300'} />
					<Flex flexDirection={'column'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} overflow={'hidden'}>
						<Typography variant={'Paragraph12M'} color={'gray.950'} noOfLines={1} className={classes.cardText}>
							{type === 'metrics' ? 'Missing metric' : `Missing ${type} (${numOfDependencies})`}
						</Typography>
						<Typography variant={'Paragraph12M'} color={'gray.950'} noOfLines={1} className={classes.cardText}>
							{description}
						</Typography>
					</Flex>
				</Flex>
				<Button
					variant={'outline'}
					size={'xxs'}
					colorScheme={'gray'}
					isIconOnly={true}
					cursor={'pointer'}
					className={classes.dependencyCardArrowButton}
					width={'24px'}
					height={'24px'}
					onClick={() => handleOnClick(type, entityName || name)}
				>
					<ArrowRight16 />
				</Button>
			</Flex>
			<CreateMetricModal
				isOpen={isCreateMetricModalOpen}
				onClose={onCreateMetricModalClose}
				missingMetricName={missingMetricName}
				metricName={metricName}
			/>
		</>
	);
};
