import Drawer from 'antd/es/drawer';
import { useEffect, useMemo, useState } from 'react';
import Box from 'src/common/components/Box';
import Button from 'src/common/components/Button';
import Flex from 'src/common/components/Flex';
import { Report16 } from 'src/common/components/Icons';
import PageHeader from 'src/common/components/PageHeader';
import { TabProps } from 'src/common/components/Tabs/types';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import Page from 'src/layout/Page';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import { SubscriptionPagePath } from '../SubscriptionPage/SubscriptionPage';
import { CommentSidebar } from '../WorkspacePage/CommentSidebar/CommentSidebar';
import { EmptyFeed } from './components/EmptyFeed';
import { FeedList } from './components/FeedList';

import { RootPath } from '../routes';
import { useFeed } from './hooks/useFeed';

export const FeedPagePath = 'feed';
export function FeedPage() {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const { navigate } = useNavigation();

	useEffect(() => {
		if (isSightfull2) {
			navigate({ path: RootPath, replace: true });
		}
	});

	return (
		<Page page="Feed Page">
			<FeedPageGuard />
		</Page>
	);
}

export function FeedPageGuard() {
	const { reportEvent } = useReportEvent();
	const { navigate } = useNavigation();
	const [tabPosition, setTabPosition] = useState(0);
	const {
		fetchMoreItems,
		areAllItemsFetched,
		feeds: { all, insights, notifications },
		isLoading,
	} = useFeed();

	const [commentsPanelSignalId, setCommentsPanelSignalId] = useState<string>();
	const commentsPanelSignal = useMemo(
		() => all.find((signal) => signal.signal_id == commentsPanelSignalId),
		[commentsPanelSignalId, all]
	);

	const unresolvedNotificationsCount = useMemo(
		() => notifications.filter((signal) => !signal.resolved).length,
		[notifications]
	);

	const relevantItems = [all, insights, notifications][tabPosition];
	const tabs: TabProps[] = [
		{ text: 'All' },
		{ text: 'Insights', isDisabled: insights.length == 0 },
		{
			text: `Notifications ${unresolvedNotificationsCount ? ` (${unresolvedNotificationsCount})` : ''}`,
			isDisabled: notifications.length == 0,
		},
	];

	return (
		<Flex direction={'column'} height="100%" width={'100%'}>
			<PageHeader
				title={'Feed'}
				onChangeTab={(pos) => {
					setTabPosition(pos);
					reportEvent({ event: 'tab-position-change', metaData: { newPosition: tabs[pos].text } });
				}}
				tabIndex={tabPosition}
				tabs={tabs}
				hasBorderBottom
			>
				<Button
					leftIcon={<Report16 />}
					colorScheme="gray"
					variant="ghost"
					size="small"
					onClick={() => {
						reportEvent({ event: 'manage-report-click' });
						navigate({ path: SubscriptionPagePath });
					}}
				>
					Manage subscriptions
				</Button>
			</PageHeader>
			<Box flex={1}>
				{all.length > 0 || isLoading ? (
					<FeedList
						allLoadedSignalsCount={all.length}
						relevantItems={relevantItems}
						fetchMoreItems={fetchMoreItems}
						areAllItemsFetched={areAllItemsFetched}
						key={`feed-index-${tabPosition}`}
						onOpenSignalComment={setCommentsPanelSignalId}
					/>
				) : (
					<EmptyFeed />
				)}
			</Box>
			<Drawer
				open={!!commentsPanelSignal}
				onClose={() => setCommentsPanelSignalId(undefined)}
				width={'420px'}
				title=""
				closable={false}
				bodyStyle={{ padding: 0 }}
				maskStyle={{ opacity: 0.5 }}
			>
				{commentsPanelSignal && (
					<CommentSidebar signal={commentsPanelSignal} onClose={() => setCommentsPanelSignalId(undefined)} />
				)}
			</Drawer>
		</Flex>
	);
}
