import Box from '@components/Box';
import Flex from '@components/Flex';
import { ChevronLeftSolid16 } from '@components/Icons';
import { SelectOption } from '@components/Select/types';
import Typography from '@components/Typography';
import Select from 'src/common/components/Select';
import { useNormalizedPropsSelectedOption } from 'src/common/hooks/useNormalizedPropsSelectedOption';
import styles from './FiltersAndBreakdowns.module.scss';
import { FiltersAndBreakdownItemType } from './types';

type Props = {
	path: FiltersAndBreakdownItemType[];
	traverseToSpecificPath: (item?: FiltersAndBreakdownItemType) => void;
	hasBaseNode: boolean;
	setNormalizedPropsSelectedOption: (option: SelectOption) => void;
	normalizedPropsSelectedOption: SelectOption;
	displayBackArrow?: boolean;
	displayShowNormalizedSelection?: boolean;
};

const SubHeaderSection = ({
	path,
	traverseToSpecificPath,
	normalizedPropsSelectedOption,
	setNormalizedPropsSelectedOption,
	hasBaseNode,
	displayBackArrow = true,
	displayShowNormalizedSelection = true,
}: Props) => {
	const minimumPathLength = hasBaseNode ? 1 : 0;
	const shouldDisplayBackArrow = path.length > minimumPathLength && displayBackArrow;
	const subTitle = path.length >= 1 ? path[path.length - 1].label : null;

	const { normalizedPropsOptions } = useNormalizedPropsSelectedOption();

	return (
		<Box className={styles.upperTitleContainer}>
			<Flex justifyContent="space-between" w="100%">
				<Flex alignItems="center">
					{shouldDisplayBackArrow && (
						<ChevronLeftSolid16 className={styles.backButton} onClick={() => traverseToSpecificPath()} />
					)}

					<Typography ml={shouldDisplayBackArrow ? '8px' : '0px'} variant="Paragraph16SB">
						{subTitle}
					</Typography>
				</Flex>
				{displayShowNormalizedSelection && (
					<Select
						options={normalizedPropsOptions}
						selectedOption={normalizedPropsSelectedOption}
						onChange={setNormalizedPropsSelectedOption}
						size="inline"
					/>
				)}
			</Flex>
		</Box>
	);
};

export default SubHeaderSection;
