import * as monaco from 'monaco-editor';
import { useEffect, useRef } from 'react';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import { Context } from '../completionProvider';
import { JoinKeysTextFieldCompletionItemProviderBuilder } from '../completionProvider/joinKeysTextFieldCompletionProvider';
import {
	CustomTextFieldCompletionItem,
	CustomTextFieldCompletionItemProviderBuilder,
	TextFieldCompletionItemProviderBuilder,
} from '../completionProvider/textFieldCompletionProvider';
import { TextFieldHoverProviderBuilder } from '../hoverProvider/textFieldHoverProvider';
import { SemanticTokens, buildMonacoSemanticProvider } from '../tokenProvider/monacoSemanticProvider';

export function useSharedMonacoTextFieldProviders({ entity, metric }: { entity: string; metric: string }) {
	const semanticTokensProviderRef = useRef<null | monaco.IDisposable>(null);
	const hoverProviderRef = useRef<null | monaco.IDisposable>(null);
	const { semanticDefinitions } = useSemanticDefinitions();

	useEffect(() => {
		if (!semanticDefinitions) return;
		semanticTokensProviderRef.current?.dispose();
		semanticTokensProviderRef.current = monaco.languages.registerDocumentSemanticTokensProvider('sql', {
			getLegend: () => ({
				tokenTypes: SemanticTokens,
				tokenModifiers: ['declaration'],
			}),
			provideDocumentSemanticTokens: buildMonacoSemanticProvider(
				{
					entity,
					metric,
				},
				semanticDefinitions
			),

			releaseDocumentSemanticTokens: () => {
				return;
			},
		});

		hoverProviderRef.current?.dispose();
		hoverProviderRef.current = monaco.languages.registerHoverProvider('sql', {
			provideHover: TextFieldHoverProviderBuilder({ entity, metric }, semanticDefinitions),
		});
		return () => {
			semanticTokensProviderRef.current?.dispose();
			hoverProviderRef.current?.dispose();
		};
	}, [entity, metric, semanticDefinitions]);
}

export function useMonacoTextFieldProviders(fieldId: string, contextSettings?: SuggestionsContextSettings) {
	const completionProviderRef = useRef<null | monaco.IDisposable>(null);
	const { semanticDefinitions } = useSemanticDefinitions();

	useEffect(() => {
		if (!contextSettings || !semanticDefinitions) return;

		completionProviderRef.current?.dispose();
		completionProviderRef.current = monaco.languages.registerCompletionItemProvider(
			'sql',
			TextFieldCompletionItemProviderBuilder(contextSettings, semanticDefinitions, fieldId)
		);

		return () => {
			completionProviderRef.current?.dispose();
		};
	}, [contextSettings, fieldId, semanticDefinitions]);
}

export function useJoinKeyTextFieldProviders(fieldId: string, entity: string, referenced_entity: string) {
	const completionProviderRef = useRef<null | monaco.IDisposable>(null);
	const { semanticDefinitions } = useSemanticDefinitions();

	useEffect(() => {
		if (!semanticDefinitions) return;

		completionProviderRef.current?.dispose();
		completionProviderRef.current = monaco.languages.registerCompletionItemProvider(
			'sql',
			JoinKeysTextFieldCompletionItemProviderBuilder(entity, referenced_entity, semanticDefinitions, fieldId)
		);

		return () => {
			completionProviderRef.current?.dispose();
		};
	}, [entity, fieldId, referenced_entity, semanticDefinitions]);
}

export function useCustomMonacoTextFieldProviders(
	fieldId: string,
	items: CustomTextFieldCompletionItem[],
	source: string
) {
	const completionProviderRef = useRef<null | monaco.IDisposable>(null);

	useEffect(() => {
		completionProviderRef.current?.dispose();
		completionProviderRef.current = monaco.languages.registerCompletionItemProvider(
			'sql',
			CustomTextFieldCompletionItemProviderBuilder(items, source, fieldId)
		);

		return () => {
			completionProviderRef.current?.dispose();
		};
	}, [fieldId, items, source]);
}
export type SuggestionsContextSettings = {
	entity: string;
	metric: string;
	includeTypes?: Context['type'][];
	isIncludingMultitraversal?: boolean;
	resultsFilter?: (item: Context) => boolean;
};
