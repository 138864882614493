import { $convertFromMarkdownString } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { markdownTransformers } from 'src/common/components/TextEditor/consts';

export function UpdateValuePlugin({ value }: { value: string }) {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		editor.update(() => {
			$convertFromMarkdownString(value, markdownTransformers);
		});
	}, [editor, value]);

	return null;
}
