import useMentionsState from 'src/common/hooks/stores/useMentionsState';

const mentionRegExp = new RegExp(/\[(?<a>@.*?)\]\((?<B>[A-Za-z0-9-]+?)\)/);
export function useFindUserFromMention(input: string) {
	const mentions = useMentionsState();
	const [mentionText, linkName, userId] = input.match(mentionRegExp) ?? ['', '', ''];
	const user = mentions.find((mention) => mention.id == userId);
	const userName = user?.name || linkName.replace('@', '');

	return { userName, ...user, mentionText };
}
