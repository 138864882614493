import { useCallback } from 'react';
import { CacheMetricParamsMutationVariables, useCacheMetricParamsMutation } from 'src/generated/graphql';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { v4 as uuidv4 } from 'uuid';

export const useCacheMetricParams = () => {
	const { metricYamlEditorMetricDefinitionJson } = useMetricDerivedState();
	const [cacheMetricParams] = useCacheMetricParamsMutation();

	const handleCacheMetricParams = useCallback(async () => {
		if (metricYamlEditorMetricDefinitionJson == null) {
			return;
		}

		const uuid = uuidv4();
		const variables: CacheMetricParamsMutationVariables = {
			uuid,
			userMetricDefinitions: metricYamlEditorMetricDefinitionJson,
		};
		try {
			await cacheMetricParams({ variables });
			return uuid;
		} catch (err) {
			if (err instanceof Error) {
				throw new Error(`Can't cache metric params: ${err.message}`);
			}
			throw new Error("Can't cache metric params");
		}
	}, [cacheMetricParams, metricYamlEditorMetricDefinitionJson]);

	return { cacheMetricParams: handleCacheMetricParams };
};
