import { useCallback, useEffect, useState } from 'react';
import classes from './BlinkingCursor.module.scss';
const DEFAULT_TYPING_INTERVAL = 100;
const DEFAULT_INCREMENT = 20;

export type LoadingTextProps = {
	text: string;
	component: (text: string) => JSX.Element;
	isLoading: boolean;
	onDone?: () => void;
};

export function LoadingText({ text, component, isLoading, onDone }: LoadingTextProps): JSX.Element {
	const [textIndex, setTextIndex] = useState(0);
	const visibleText = text.slice(0, textIndex);
	const shouldShowCursor = textIndex < text.length || isLoading;

	const TextComponent = useCallback(() => component(visibleText), [component, visibleText]);

	useEffect(() => {
		const isFullyVisible = textIndex >= text.length;

		if (isFullyVisible) {
			onDone?.();
			return;
		}

		const textInterval = setTimeout(() => {
			setTextIndex(textIndex + DEFAULT_INCREMENT);
		}, DEFAULT_TYPING_INTERVAL);

		return () => {
			clearTimeout(textInterval);
		};
	}, [text, textIndex, onDone]);

	return <span className={shouldShowCursor ? classes.blinkingCursor : undefined}>{<TextComponent />}</span>;
}
