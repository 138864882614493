import { useCallback, useState } from 'react';

export type ComponentVisibility = {
	chartTypeSelect: boolean;
	periodPicker: boolean;
	decimalPopover: boolean;
};

function useComponentVisibility(initialStates: ComponentVisibility) {
	const [componentVisibility, setComponentVisibility] = useState<ComponentVisibility>(initialStates);

	const onOpen = useCallback((component: keyof ComponentVisibility) => {
		setComponentVisibility((prev) => ({ ...prev, [component]: true }));
	}, []);

	const onClose = useCallback((component: keyof ComponentVisibility) => {
		setComponentVisibility((prev) => ({ ...prev, [component]: false }));
	}, []);

	const closeAll = useCallback(() => {
		setComponentVisibility({
			chartTypeSelect: false,
			periodPicker: false,
			decimalPopover: false,
		});
	}, []);

	return { componentVisibility, onOpen, onClose, closeAll };
}

export default useComponentVisibility;
