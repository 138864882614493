import { Flex } from '@chakra-ui/react';
import { Provider } from 'jotai';
import { extractMetricNameAndSearchParams } from 'src/common/utils/signalUtils';
import { getInitialDerivedStateAtom } from 'src/pages/MetricPage/atoms/DerivedState';
import {
	IsMetricPageURLBasedAtom,
	MetricPageSearchParamsAtom,
} from 'src/pages/MetricPage/atoms/MetricPageSearchParams';
import { TableRefAtom } from 'src/pages/MetricPage/atoms/TableRef';
import RulesEngineRunner from 'src/pages/MetricPage/components/RulesEngineRunner';
import { SignalHeader } from 'src/pages/WorkspacePage/SignalWidget/Header/SignalHeader';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import { FeedSignal } from 'src/types/spaces';
import { SignalBody } from './SignalBody';
import classes from './WorkspaceSignalWidget.module.scss';

type WorkspaceSignalWidgetProps = {
	signal: FeedSignal;
	onOpenSignalComment: (signalId: FeedSignal['signal_id']) => void;
	hasBorder?: boolean;
	hasEditActions?: boolean;
	onAttachmentClick?: VoidFunction;
};

export function WorkspaceSignalWidget({
	signal,
	onOpenSignalComment,
	hasBorder = true,
	hasEditActions = true,
	onAttachmentClick,
}: WorkspaceSignalWidgetProps) {
	const attachmentProperties = signal.signal.attachment
		? extractMetricNameAndSearchParams(decodeURIComponent(signal.signal.attachment))
		: undefined;
	const isValidMetricAttachment = !!attachmentProperties?.metricName;

	return (
		<Provider
			initialValues={[
				[MetricPageSearchParamsAtom, attachmentProperties],
				getInitialDerivedStateAtom(),
				[IsMetricPageURLBasedAtom, false],
				[TableRefAtom, null],
			]}
		>
			{isValidMetricAttachment ? (
				<RulesEngineRunner>
					<WorkspaceSignalWidgetWithAttachment
						onOpenSignalComment={onOpenSignalComment}
						signal={signal}
						hasBorder={hasBorder}
						hasEditActions={hasEditActions}
						onAttachmentClick={onAttachmentClick}
					/>
				</RulesEngineRunner>
			) : (
				<WorkspaceSignalWidgetWithProvider
					onOpenSignalComment={onOpenSignalComment}
					signal={signal}
					hasBorder={hasBorder}
					hasEditActions={hasEditActions}
					onAttachmentClick={onAttachmentClick}
				/>
			)}
		</Provider>
	);
}

function WorkspaceSignalWidgetWithAttachment({
	signal,
	onOpenSignalComment,
	hasBorder,
	hasEditActions,
	onAttachmentClick,
}: WorkspaceSignalWidgetProps) {
	return (
		<WorkspaceSignalWidgetWithProvider
			onOpenSignalComment={onOpenSignalComment}
			signal={signal}
			hasBorder={hasBorder}
			hasEditActions={hasEditActions}
			onAttachmentClick={onAttachmentClick}
		/>
	);
}

function WorkspaceSignalWidgetWithProvider({
	signal,
	onOpenSignalComment,
	hasBorder,
	hasEditActions,
	onAttachmentClick,
}: WorkspaceSignalWidgetProps) {
	const { wrapWithReport } = useReportEvent();

	return (
		<Flex
			alignItems={'stretch'}
			direction="column"
			border={hasBorder ? `1px solid ${colors.gray[300]}` : undefined}
			width="100%"
			borderRadius="4px"
			className={classes.workspaceSignalWidget}
		>
			<SignalHeader
				onCommentClick={wrapWithReport(() => onOpenSignalComment(signal.signal_id), 'signal-show-comments', {
					signalId: signal.signal_id,
					workspaceId: signal.feed_id,
				})}
				signal={signal}
				hasEditActions={hasEditActions}
			/>
			<SignalBody signal={signal} hasEditActions={hasEditActions} onAttachmentClick={onAttachmentClick} />
		</Flex>
	);
}
