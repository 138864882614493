import { Box } from '@chakra-ui/react';
import Divider from 'src/common/components/Divider';
import { BinSolid16, DuplicateSolid16, Link16 } from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';

export function SignalMoreMenu({
	duplicateSignal,
	onDeleteSignal,
	onCopyLink,
}: {
	duplicateSignal: () => void;
	onDeleteSignal: () => void;
	onCopyLink?: VoidFunction;
}) {
	return (
		<Box py="8px">
			<ListItem
				color="gray.1000"
				label="Duplicate"
				size="sm"
				prefixIcon={<DuplicateSolid16 />}
				onClick={duplicateSignal}
			/>
			<Box paddingX={'12px'}>
				<Divider direction={'horizontal'} marginY="8px" />
			</Box>
			{onCopyLink && (
				<ListItem color="gray.1000" label="Copy link" size="sm" prefixIcon={<Link16 />} onClick={onCopyLink} />
			)}
			<ListItem color="gray.1000" label="Delete" size="sm" prefixIcon={<BinSolid16 />} onClick={onDeleteSignal} />
		</Box>
	);
}
