import Flex from '@components/Flex';
import { ReactNode } from 'react';
import Typography from 'src/common/components/Typography';
import classes from './CustomPeriodPickerDialog.module.scss';

export function TabItem({
	isSelected,
	onTabClicked,
	children,
	isDisabled,
}: {
	isSelected: boolean;
	onTabClicked: () => void;
	children: ReactNode;
	isDisabled: boolean;
}) {
	const classNames = [classes.tabLabel];
	if (isSelected) classNames.push(classes.selected);
	if (isDisabled) classNames.push(classes.disabled);
	return (
		<Flex className={classNames.join(' ')}>
			<button onClick={onTabClicked} style={{ cursor: isDisabled ? 'default' : 'pointer' }}>
				<Typography variant="DesktopH8Medium" textAlign="center">
					{children}
				</Typography>
			</button>
		</Flex>
	);
}
