import { Box } from '@chakra-ui/react';
import Input from 'src/common/components/Input';
import { LEXICAL_CONTENT_EDITABLE_ID } from 'src/common/components/TextEditor/components/ContentEditable';
import Typography from 'src/common/components/Typography';

export function TitleInputFieldPlugin({
	title,
	onTitleChange,
	onFocus,
	onBlur,
}: {
	title: string;
	onTitleChange: (title: string) => void;
	onFocus: VoidFunction;
	onBlur: VoidFunction;
}) {
	return (
		<Box position={'sticky'} paddingLeft="12px" paddingTop="12px">
			<Typography variant="DesktopH6Medium" color={'gray.1000'} width="100%">
				<Input
					value={title}
					onChange={onTitleChange}
					variant={'ghost'}
					placeholder="Enter Title (Required)"
					size="lg"
					autoFocus={true}
					onFocus={onFocus}
					onBlur={onBlur}
					onEnter={() => {
						setTimeout(() => {
							document.getElementById(LEXICAL_CONTENT_EDITABLE_ID)?.focus();
						}, 0);
					}}
				/>
			</Typography>
		</Box>
	);
}
