import { useCallback } from 'react';
import { useMetricDerivedState } from './useMetricDerivedState';
import { useMetricPageSearchParams } from './useMetricPageSearchParams';

export function useCollectedProps() {
	const { collectedProps } = useMetricDerivedState();
	const { setSearchParams, searchParams } = useMetricPageSearchParams();
	const addCollectedProps = (collectedProp: string[]) => {
		setSearchParams({
			...searchParams,
			collectProps: Object.fromEntries([...collectedProps, ...collectedProp].map((e) => [e, null])),
		});
	};

	const removeCollectedProps = (collectedProp: string[]) => {
		setSearchParams({
			...searchParams,
			collectProps: Object.fromEntries(collectedProps.filter((o) => collectedProp.includes(o)).map((e) => [e, null])),
		});
	};

	const removeCollectedProp = useCallback(
		(propToRemove: string) => {
			setSearchParams({
				...searchParams,
				collectProps: Object.fromEntries(
					collectedProps.filter((prop) => prop !== propToRemove).map((prop) => [prop, null])
				),
			});
		},
		[collectedProps, searchParams, setSearchParams]
	);

	const addCollectedPropsCallback = useCallback(addCollectedProps, [setSearchParams, searchParams, collectedProps]);

	const removeCollectedPropsCallback = useCallback(removeCollectedProps, [
		setSearchParams,
		searchParams,
		collectedProps,
	]);

	return {
		collectedProps,
		addCollectedProps: addCollectedPropsCallback,
		removeCollectedProps: removeCollectedPropsCallback,
		removeCollectedProp,
	};
}
