import Button from 'src/common/components/Button';
import { Layout } from 'src/common/components/Icons';
import Tooltip from 'src/common/components/Tooltip';
import colors from 'src/style/colors';

export function LayersButton({
	onClick,
	isActive,
	isDisabled,
}: {
	onClick: () => any;
	isActive: boolean;
	isDisabled?: boolean;
}) {
	return (
		<Tooltip size={'md'} label={!isDisabled && 'Data Formatting'} variant="fluid" placement={'bottom'}>
			<Button
				isIconOnly
				colorScheme={'gray'}
				size={'small'}
				border={`1px ${colors.gray['400']} solid`}
				_hover={{ backgroundColor: colors.gray['100'] }}
				onClick={onClick}
				isActive={isActive}
				isDisabled={isDisabled}
			>
				<Layout />
			</Button>
		</Tooltip>
	);
}
