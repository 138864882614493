import Box from '@components/Box';
import Typography from '@components/Typography';
import Divider from '@components/Divider';
import { FilterOutline16 } from '@components/Icons';
import { FilterDrilldownCard, AddDrillDownCard } from '@components/LeftExpandCollapsePanel/Drilldown';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { NOT_AVAILABLE_VALUE_STRING } from 'src/common/utils/consts';
import { useReportEvent } from '@services/analytics';
import useDimensionsState from '../InvestigatePanel/useDimensionsState';
import { OnModalCloseType, OnModalOpenType } from './types';
import { Title } from '../PanelTitle/Title';
import * as CONSTS from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import classes from '../InvestigatePanel/InvestigatePanel.module.scss';

export function FiltersPanel({
	isShowingFullSize,
	isDisabled,
	onModalOpen,
	onModalClose,
}: {
	isShowingFullSize: boolean;
	isDisabled?: boolean;
	onModalOpen: OnModalOpenType;
	onModalClose: OnModalCloseType;
}) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const [{ filters }, { removeAllParamsByType, removeParamByKey }] = useDimensionsState();
	const { wrapWithReport } = useReportEvent();

	return (
		<>
			<Box whiteSpace="nowrap" height={isShowingFullSize ? 'min-content' : '0'} opacity={isShowingFullSize ? '1' : '0'}>
				<Title onAction={() => removeAllParamsByType('filterBy')} text={'Filters'} showAction={!!filters?.length} />
				{!isDisabled &&
					filters
						.map((filter) => ({ ...filter, values: filter.values.map((value) => value ?? NOT_AVAILABLE_VALUE_STRING) }))
						.map((filter) => (
							<FilterDrilldownCard
								key={filter.key + filter.values.join('')}
								filter={filter}
								onRemove={wrapWithReport(
									() => removeParamByKey('filterBy', filter.key),
									'metric-filters-delete-clicked'
								)}
								onClick={
									isSightfull2
										? () => void 0
										: wrapWithReport(
												() => onModalOpen(CONSTS.FILTER_MODEL_TYPE, onModalClose, filter),
												'edit-metric-filters-clicked'
										  )
								}
							/>
						))}
				<AddDrillDownCard
					isDisabled={isDisabled}
					type={CONSTS.FILTER_MODEL_TYPE}
					onClick={wrapWithReport(() => onModalOpen(CONSTS.FILTER_MODEL_TYPE, onModalClose), 'metric-filters-clicked')}
					dataIntercomTarget={'Add filter'}
				/>
			</Box>
			{!isShowingFullSize && (
				<Box
					display="flex"
					width="32px"
					height="32px"
					paddingTop={'15px'}
					position="relative"
					justifyContent="center"
					alignItems="center"
				>
					<FilterOutline16 />
					{!!filters.length && (
						<Box className={classes.badgeNumber}>
							<Typography variant="Paragraph12M">{filters.length}</Typography>
						</Box>
					)}
				</Box>
			)}
			<Box paddingY={isShowingFullSize ? '24px' : '8px'}>
				<Divider direction={'horizontal'}></Divider>
			</Box>
		</>
	);
}
