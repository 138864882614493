export const colors = {
	transparent: 'transparent',
	black: '#000',
	white: '#fff',
	green: {
		100: '#F2FFF9',
		200: '#D7FFEB',
		300: '#8FE7B8',
		400: '#66E1A0',
		500: '#2FD181',
		600: '#1DB469',
		700: '#11A75C',
		800: '#0A9952',
		900: '#048746',
		1000: '#046A37',
		1100: '#02542B',
	},
	silver: {
		200: '#DEE1E5',
		600: '#88909F',
		300: '#C9CDD3',
		1100: '#1A1D21',
	},
	slate: {
		300: '#BCCAD9',
		500: '#6989AA',
		600: '#446C95',
		700: '#365677',
	},
	blue: {
		100: '#F2F5FF',
		200: '#E8EDFF',
		300: '#B4C4FF',
		400: '#94ABFF',
		500: '#6E8EFF',
		600: '#4A72FF',
		700: '#1B4DFF',
		800: '#0F3CD9',
		900: '#042BB2',
		1000: '#042185',
		1100: '#00114A',
	},
	red: {
		100: '#FFF5FA',
		200: '#FFDCDA',
		300: '#FFC5C1',
		400: '#FFA19B',
		500: '#FF7A72',
		600: '#FF574D',
		700: '#FF3838',
		800: '#E92215',
		900: '#D21A0E',
		1000: '#BE170C',
		1100: '#AB0A00',
	},
	gray: {
		100: '#F9FAFA',
		200: '#F4F5F6',
		300: '#DEE1E5',
		400: '#C9CDD3',
		500: '#B3B8C2',
		600: '#9DA4B0',
		700: '#88909F',
		800: '#727C8D',
		900: '#606977',
		950: '#4F5662',
		1000: '#3D434C',
		1100: '#1A1D21',
	},
	yellow: {
		100: '#FFF9DF',
		200: '#FFF2C4',
		300: '#FFE99D',
		400: '#FFE176',
		500: '#FFD84E',
		600: '#FFD027',
		700: '#FFC700',
		800: '#D8A800',
		900: '#B18A00',
		1000: '#896B00',
		1100: '#624D00',
	},
	orange: {
		100: '#FFF6ED',
		200: '#FFEBD8',
		300: '#FFD8B4',
		400: '#FFC793',
		500: '#FFAD62',
		600: '#FC8E28',
		700: '#FC8415',
		800: '#EB7101',
		900: '#D26500',
		1000: '#B45600',
		1100: '#884202',
	},
	fuchsia: {
		100: '#FBEDFD',
		200: '#F4C9FA',
		300: '#ECA5F7',
		400: '#E581F4',
		500: '#DE5DF1',
		600: '#D638EE',
		700: '#CF14EB',
		800: '#AF11C7',
		900: '#8F0EA2',
		1000: '#6F0B7E',
		1100: '#50085A',
	},
	purple: {
		100: '#F5ECFE',
		200: '#E2C7FC',
		300: '#CFA1FB',
		400: '#BC7CF9',
		500: '#A956F7',
		600: '#9631F5',
		700: '#820BF4',
		800: '#6E0ACE',
		900: '#5A08A9',
		1000: '#460683',
		1100: '#32045E',
	},
	rose: {
		100: '#FEECEF',
		200: '#FFDADF',
		300: '#FFC2CB',
		400: '#FF9CAA',
		500: '#FF8596',
		600: '#F6697D',
		700: '#F54C64',
		800: '#EB2743',
		900: '#DB1733',
		1000: '#C10F29',
		1100: '#A00017',
	},
	pink: {
		100: '#FFF3F8',
		200: '#FFDEEC',
		300: '#FFBED9',
		400: '#FFA0C8',
		500: '#FB6CA7',
		600: '#E9538D',
		700: '#E42F75',
		800: '#CF195F',
		900: '#BC0F51',
		1000: '#99053E',
		1100: '#780E2E',
	},
	violet: {
		100: '#F1ECFE',
		200: '#D4C7FD',
		300: '#B8A1FB',
		400: '#9B7BF9',
		500: '#7F56F8',
		600: '#6230F6',
		700: '#460BF4',
		800: '#3B09CF',
		900: '#3007A9',
		1000: '#250684',
		1100: '#1B045E',
	},
	indigo: {
		100: '#F4F5FF',
		200: '#DDDFFF',
		300: '#C6CAFF',
		400: '#B7BDFF',
		500: '#9CA5FD',
		600: '#7D88F4',
		700: '#636DD3',
		800: '#4C56BB',
		900: '#3C449F',
		1000: '#303783',
		1100: '#242967',
	},
	sky: {
		100: '#F3FAFF',
		200: '#E4F4FF',
		300: '#C2E6FF',
		400: '#B0E3FF',
		500: '#6AC9FF',
		600: '#3CAAFA',
		700: '#2497EB',
		800: '#1580CD',
		900: '#1177C0',
		1000: '#0D5E99',
		1100: '#033F6B',
	},
	cyan: {
		100: '#EDFBFE',
		200: '#C9F4FB',
		300: '#A4EDF8',
		400: '#80E5F5',
		500: '#5CDEF2',
		600: '#38D6EF',
		700: '#13CFEC',
		800: '#10AFC7',
		900: '#0D8FA3',
		1000: '#0A6F7F',
		1100: '#07505B',
	},
	teal: {
		100: '#E8FDF9',
		200: '#D3F8F2',
		300: '#B0F5EB',
		400: '#88EFE0',
		500: '#5EEAD5',
		600: '#1EECC5',
		700: '#27CAB5',
		800: '#1DB19F',
		900: '#159383',
		1000: '#0E786B',
		1100: '#065A50',
	},
	emerald: {
		100: '#EDFBF7',
		200: '#DBF6EE',
		300: '#ADEBD9',
		400: '#75DEBF',
		500: '#49D3AA',
		600: '#1BC895',
		700: '#16A077',
		800: '#107759',
		900: '#0D6047',
		1000: '#0A4836',
		1100: '#053A2B',
	},
	lime: {
		100: '#F4FFE1',
		200: '#E4FFB8',
		300: '#D5FD95',
		400: '#C5FB6E',
		500: '#AFEF47',
		600: '#A3E635',
		700: '#94DA22',
		800: '#81C515',
		900: '#73B10F',
		1000: '#5E9409',
		1100: '#497305',
	},
	natural: {
		200: '#DEE1E5',
		300: '#CECECE',
		400: '#BABABA',
		600: '#939393',
		800: '#6C6C6C',
		1000: '#454545',
	},
	data: {
		beige: { 1: '#F6F3EE', 2: '#F6F3EE', 3: '#D4C2AB', 4: '#C2AA89', 5: '#B19168' },
	},
	purpleGradient: 'linear-gradient(77deg, #5055EF 0%, #7741CC 98.16%)',
	grayGradient: 'linear-gradient(77deg, #3D4A5C 0%, #5E718D 98.16%)',
	selectedPanelItemColor: 'rgba(222, 225, 229, 0.32)',
	hoverPanelItemColor: 'rgba(222, 225, 229, 0.5)',
	buttonPanelItemColor: 'rgba(222, 225, 229, 0.60)',
} as const;
export default colors;
