import Box from '@components/Box';
import { $convertToMarkdownString } from '@lexical/markdown';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { EditorState } from 'lexical';
import { useCallback, useRef } from 'react';
import { markdownTransformers } from 'src/common/components/TextEditor/consts';
import useDebouncedCallback from 'src/common/hooks/useDebouncedCallback';
import { useReportEvent } from 'src/services/analytics';
import { Mention } from 'src/types/mention';
import ContentEditable from './components/ContentEditable';
import LexicalComposer from './components/LexicalComposer';
import MentionPlugin from './plugins/mention/Mention';
import { transformMentions } from './plugins/mention/utils';
import ToolbarPlugin from './plugins/toolbar/Toolbar';
import './styles/global.scss';
import classes from './styles/TextEditor.module.scss';
import { transformEmptyLinks } from './utils';

import Typography from '../Typography';
import { FocusPlugin } from './plugins/focus/Focus';
import { UpdateValuePlugin } from './plugins/UpdateValuePlugin';
import './styles/global.scss';

export default function TextEditor({
	value,
	isEditMode,
	mentions,
	onChange,
	onFocus,
	onBlur,
	placeholder = 'Write about your data....',
	isIncludingToolbar = true,
	onChangeDebounceDuration = 2000,
}: {
	value: string;
	isEditMode: boolean;
	mentions: Mention[];
	onChange?: (markdown: string) => void;
	placeholder?: string;
	isIncludingToolbar?: boolean;
	onFocus?: VoidFunction;
	onBlur?: VoidFunction;
	onChangeDebounceDuration?: number;
}) {
	const { reportEvent } = useReportEvent();
	const chosenMentions = useRef<string[]>([]);

	const handleSaveState = useHandleEditorStateChange({ mentions, onChange, chosenMentions });

	const handleEditorStateChange = useDebouncedCallback(handleSaveState, onChangeDebounceDuration);
	const isShowingToolbar = isEditMode && isIncludingToolbar;

	return (
		<LexicalComposer value={value}>
			<Box className={`textEditorContainer ${classes.editorContainer}`}>
				{isShowingToolbar && <ToolbarPlugin />}
				<Box className={classes.editorInner}>
					<TablePlugin />
					<RichTextPlugin
						contentEditable={<ContentEditable isEditMode={isEditMode} />}
						placeholder={<Placeholder text={placeholder} />}
						ErrorBoundary={LexicalErrorBoundary}
					/>
					<ListPlugin />
					<LinkPlugin
						validateUrl={(url) => {
							reportEvent({
								event: 'link-clicked-in-post',
								metaData: { isPulseLink: new RegExp('^https://app.[a-zA-Z0-9]+.sightfull.com/').test(url) },
							});
							return true;
						}}
					/>
					<HistoryPlugin />
					<MentionPlugin mentions={mentions} chosenMentions={chosenMentions} isEditMode={isEditMode} />
					<OnChangePlugin onChange={handleEditorStateChange} ignoreSelectionChange />
					<FocusPlugin onFocus={onFocus} onBlur={onBlur} />
					{!isEditMode && <UpdateValuePlugin value={value} />}
				</Box>
			</Box>
		</LexicalComposer>
	);
}

function Placeholder({ text }: { text: string }) {
	return (
		<Box className={classes.editorPlaceholder}>
			<Typography variant="Paragraph14R">{text}</Typography>
		</Box>
	);
}

export function useHandleEditorStateChange({
	mentions,
	onChange,
	chosenMentions,
}: {
	mentions: Mention[];
	onChange?: (markdown: string) => void;
	chosenMentions: React.MutableRefObject<string[]>;
}) {
	return useCallback(
		(editorState: EditorState) => {
			editorState.read(() => {
				const markdown = $convertToMarkdownString(markdownTransformers);
				const stripTrailingLine = markdown.endsWith('\\') ? markdown.slice(0, -1) : markdown;
				const withValidLinks = transformEmptyLinks(stripTrailingLine);
				const withMentions = transformMentions(withValidLinks, mentions, chosenMentions.current);

				onChange?.(withMentions);
			});
		},
		[chosenMentions, mentions, onChange]
	);
}
