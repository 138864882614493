import debounce from 'lodash/debounce';
import { useEffect, useRef } from 'react';
import { formatMetricFullname } from 'src/lib/metricRules/utils';
import { MetricCatalogCategory } from 'src/pages/MetricCatalog/MetricCatalogCategory';
import { NoResults } from 'src/pages/MetricCatalog/NoResults';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import { Category, MetricMetadata } from 'src/types/metric';
import { MetricPagePath } from '../MetricPage/pageRoutesPaths';
import { CheckForTerm } from './utils';

export function MetricCatalogBody({
	metricCategories,
	searchTerm,
	headerTopHeight,
	activeCategory,
	handleCategoryClick,
}: {
	metricCategories: Category[];
	searchTerm: string;
	headerTopHeight: number;
	handleCategoryClick?: (category: string) => void;
	activeCategory?: string;
}) {
	const { reportEvent } = useReportEvent();
	const { navigate } = useNavigation();

	const reportSearchTerm = (isFound: boolean, currentSearchTerm: string) => {
		const event = isFound ? 'metric-catalog-search' : 'metric-catalog-search-not-found';
		reportEvent({ event: event, metaData: { currentSearchTerm } });
	};

	const debouncedReportRef = useRef(debounce(reportSearchTerm, 500));

	function handleMetricClick(metric: MetricMetadata, selectedFlavor: string | undefined) {
		const { name: metricName, defaultChartType: chartType } = metric;
		const encodedFullMetricName = encodeURIComponent(formatMetricFullname(metricName, selectedFlavor));
		const additionalSearchParams = new URLSearchParams();
		if (chartType) additionalSearchParams.append('chartType', chartType);
		navigate({ path: `/${MetricPagePath}/${encodedFullMetricName}`, additionalSearchParams });
	}

	function filterMetricsFromCategoryBySearchTerm(category: Category) {
		const metricCategory = category;
		return {
			...metricCategory,
			metrics: metricCategory.metrics.filter((metric: MetricMetadata) => {
				const doesMetricNameContainsSearchTerm = (metric.displayName ?? metric.name)
					?.toLowerCase()
					.includes(searchTerm.toLowerCase());
				const doesMetricOneLinerContainsSearchTerm = CheckForTerm(metric.oneliner || '', searchTerm);
				const doesCategoryContainsSearchTerm = CheckForTerm(metricCategory.name, searchTerm);

				return (
					doesMetricNameContainsSearchTerm || doesMetricOneLinerContainsSearchTerm || doesCategoryContainsSearchTerm
				);
			}),
		};
	}

	const categoriesWithFilteredMetrics = metricCategories.map(filterMetricsFromCategoryBySearchTerm);

	const notEmptyCategories = categoriesWithFilteredMetrics.filter((c) => c.metrics.length > 0);

	const isNoResults = notEmptyCategories.length == 0;
	const handleClick = (category: string) => handleCategoryClick?.(category);

	useEffect(() => {
		const currentRef = debouncedReportRef.current;
		if (searchTerm) {
			currentRef(!isNoResults, searchTerm);
		}
		return () => currentRef.cancel();
	}, [searchTerm, isNoResults]);

	if (isNoResults) {
		return <NoResults searchTerm={searchTerm} />;
	} else {
		const metricCategories = notEmptyCategories.map((category, index) => (
			<MetricCatalogCategory
				activeCategory={activeCategory}
				handleCategoryClick={handleClick}
				key={category.name}
				metricCategory={category}
				isLastCategory={notEmptyCategories.length - 1 === index}
				onMetricClick={handleMetricClick}
				headerTopHeight={headerTopHeight}
				metricIndex={index}
			/>
		));

		return <>{metricCategories}</>;
	}
}
