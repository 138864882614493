import { cssVar } from '@chakra-ui/react';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';

const $translateX = cssVar('switch-thumb-x');

export default {
	sizes: {
		sm: {
			track: {
				height: '12px',
				width: '24px',
			},
			thumb: {
				height: '12px',
				width: '12px',
			},
			container: {
				[$translateX.variable]: '12px',
			},
		},
		md: {
			track: {
				height: '20px',
				width: '38px',
			},
			thumb: {
				height: '20px',
				width: '20px',
			},
			container: {
				[$translateX.variable]: '18px',
			},
		},
	},
	baseStyle: {
		track: {
			borderRadius: 'full',
			bg: colors.gray[600],
			p: '0.5',
			_hover: {
				bg: `gray.800`,
			},
			_focusVisible: {
				boxShadow: 'outline',
			},
			_disabled: {
				opacity: 0.4,
				cursor: 'not-allowed',
			},
			_checked: {
				bg: `blue.600`,
				_hover: {
					bg: `blue.800`,
				},
			},
		},
		thumb: {
			bg: colors.white,
			transitionProperty: 'transform',
			transitionDuration: '50ms',
			borderRadius: 'inherit',
			boxShadow: shadows['a-22'],
			_checked: {
				boxShadow:
					'0px 3px 7px rgba(0, 0, 0, 0.16), 0px 0.37px 4.42626px rgba(0, 0, 0, 0.16), 0px 0.14px 2.29266px rgba(0, 0, 0, 0.16)',
			},
		},
	},
};
