import { Center, Flex } from '@chakra-ui/react';
import Typography from '@components/Typography';
import Button from 'src/common/components/Button';
import useNavigation from 'src/services/useNavigation';

export function NotFoundPage({ title = "We couldn't find the dashboard." }: { title?: string }) {
	const { goBack } = useNavigation();
	return (
		<Center height={'100%'}>
			<Flex flexGrow={1} direction="column" alignItems={'center'}>
				<Flex paddingBottom={'4px'}>
					<Typography variant={'DesktopH4'}>{title}</Typography>
				</Flex>
				<Typography paddingBottom="25px" color="gray.700" variant={'DesktopH7Regular'}>
					Go back and try again.
				</Typography>
				<Button
					variant="solid"
					colorScheme="gray"
					size="small"
					onClick={() => {
						goBack();
						const minimumUrlSetTime = 5;
						setTimeout(() => location.reload(), minimumUrlSetTime);
					}}
				>
					Go back
				</Button>
			</Flex>
		</Center>
	);
}
