import { FronteggAppOptions } from '@frontegg/react';
import { ContextHolder } from '@frontegg/rest-api';
import { ITeamUserTenant } from '@frontegg/rest-api/teams/interfaces';
import { useEffect, useState } from 'react';
import { useFronteggAuth } from 'src/common/hooks/useFronteggLogin';
import { isTenantIDAutoGenerated } from 'src/common/utils/tenantId';
import { isProductionEnv } from 'src/config';
import { DATA_PERMISSIONS, EnvironmentState, Permission, Permissions } from 'src/types/environment';

export const fronteggDevContextOptions: FronteggAppOptions['contextOptions'] = {
	baseUrl: 'https://authnco.sightfull.com',
	clientId: 'c0c12523-efb8-4621-8a5d-bb96fab74aa6',
	tenantResolver: () => {
		const searchParams = new URLSearchParams(window.location.search);
		const requestedTenant = searchParams.get('_tenant');

		return {
			tenant: requestedTenant === '19' ? 'E2E-Staging-2-0' : undefined,
		};
	},
};

export const fronteggProdContextOptions = {
	baseUrl: 'https://authn.sightfull.com',
	clientId: 'dee922e5-c919-4b4a-81dd-9ac1a3924918',
};

export function getFronteggOptions() {
	const isProdEnv = isProductionEnv || window.location.hostname === 'local.app.sightfull.com';
	return isProdEnv ? fronteggProdContextOptions : fronteggDevContextOptions;
}

export function getBearerToken(accessToken: string) {
	return `Bearer ${accessToken}`;
}

// this is due to a bug in the types of frontegg
interface TenantsAddedType {
	tenants?: ITeamUserTenant[];
}

export function useAuthEnv(): EnvironmentState | null {
	const { user: rawUser, tenantsState } = useFronteggAuth();
	const user = rawUser as any as typeof rawUser & TenantsAddedType; // this is due to a bug in the types of frontegg
	const [authEnv, setAuthEnv] = useState<EnvironmentState | null>(null);

	useEffect(() => {
		setAuthEnv(null);
		if (!user) {
			return;
		}

		const selectedTenant = parseInt(user.tenantId);
		if (isNaN(selectedTenant)) {
			return;
		}

		const selectedTenantMetadataRaw = tenantsState.tenants.find((tenant) => tenant.tenantId == user.tenantId)
			?.metadata as string | undefined;
		if (!selectedTenantMetadataRaw) {
			return;
		}

		const parsedMetadata = JSON.parse(selectedTenantMetadataRaw);
		if (!parsedMetadata?.stamp) {
			return;
		}

		const allowedSuperuser = !!user.permissions.find((perm) => perm.key == Permissions.hasuraSuperuser);

		const hasuraDataPermissionsIDs = () => {
			return DATA_PERMISSIONS.map((dataPermToFindId) => {
				return user.permissions.find((perm) => perm.key == dataPermToFindId)?.id ?? null;
			}) as (string | null)[];
		};

		const isTenantWithHasuraPermissions = (tenantId: string) => {
			const tenant = user.tenants?.find((tenant) => tenant.tenantId == tenantId);
			if (!tenant) return false;

			const dataReadPermissions = hasuraDataPermissionsIDs();

			const isRoleAllowedRead = (role: typeof tenant.roles[0]) => {
				return role.permissions.filter((permId) => dataReadPermissions.includes(permId)).length > 0;
			};
			return !!tenant.roles.find(isRoleAllowedRead);
		};

		const availableTenants = tenantsState.tenants.map((tenant) => ({
			id: tenant.tenantId,
			name: tenant.name,
			isReadAllowed: isTenantWithHasuraPermissions(tenant.tenantId),
		}));

		const feRoles = user.roles.toSorted((roleA) => (roleA.isDefault ? -1 : 1)).map((role) => role.key);

		setAuthEnv({
			tenant: selectedTenant,
			availableTenants: availableTenants,
			role: allowedSuperuser ? 'superuser' : 'user',
			feRoles,
			stamp: parsedMetadata.stamp as string,
			isSecure: true,
			permissions: user.permissions.map((perm) => perm.key as Permission),
			isMultiTenant:
				availableTenants.filter((tenant) => tenant.isReadAllowed && !isTenantIDAutoGenerated(tenant.id)).length > 1,
			activeRole: feRoles[0],
		});
	}, [user, tenantsState]);

	return authEnv;
}

export function logout() {
	const baseUrl = ContextHolder.getContext().baseUrl;
	window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
}

export function createAuthHeader(headers: Record<string, string> = {}, accessToken: string): { [p: string]: any } {
	return {
		headers: {
			Authorization: getBearerToken(accessToken),
			...headers,
		},
	};
}
