import { Flex } from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { BLUR_COMMAND, CLEAR_EDITOR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical';
import { useEffect, useState } from 'react';
import IconButton from 'src/common/components/IconButton';
import { Mention16, Send16 } from 'src/common/components/Icons';
import { ADD_MENTION_NODE } from 'src/common/components/TextEditor/plugins/mention/Mention';
import { useKeyPress } from 'src/common/hooks/interaction/useKeyPress';

export function SubmitButtonPlugin({
	onSubmit,
	isInsertLoading,
	isDisabled,
	isClearingOnSubmit,
}: {
	onSubmit: () => Promise<void>;
	isInsertLoading: boolean;
	isDisabled: boolean;
	isClearingOnSubmit: boolean;
}) {
	const [isFocused, setIsFocused] = useState(false);
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		editor.registerCommand(
			BLUR_COMMAND,
			() => {
				setIsFocused(false);
				return false;
			},
			COMMAND_PRIORITY_LOW
		);
		editor.registerCommand(
			FOCUS_COMMAND,
			() => {
				setIsFocused(true);
				return false;
			},
			COMMAND_PRIORITY_LOW
		);
	}, [editor]);
	const submit = () => {
		onSubmit().then(() => editor.focus());
		if (isClearingOnSubmit) editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
	};
	useKeyPress(['Enter'], isFocused ? submit : () => void 0, { isWithoutShiftKey: true });

	return (
		<Flex
			position={'absolute'}
			bottom="0"
			right="0"
			gap="8px"
			justifyContent={'end'}
			padding="4px"
			margin="4px"
			borderRadius="4px"
			backgroundColor={'white'}
		>
			<IconButton
				size={'xs28'}
				onClick={() => {
					console.log('add mention');
					editor.dispatchCommand(ADD_MENTION_NODE, '');
				}}
				icon={<Mention16 />}
				colorScheme="gray"
				variant="regular"
			/>
			<IconButton
				onClick={submit}
				size={'xs28'}
				icon={<Send16 />}
				colorScheme="blue-filled"
				variant="regular"
				backgroundColor={!isFocused ? 'blue.300' : undefined}
				isLoading={isInsertLoading}
				isDisabled={isDisabled}
			/>
		</Flex>
	);
}
