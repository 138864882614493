import { Box, Flex, Grid, GridItem, ModalContent, ModalOverlay } from '@chakra-ui/react';
import Button from 'src/common/components/Button';
import IconButton from 'src/common/components/IconButton';
import { CloseLarge16 } from 'src/common/components/Icons';
import Modal from 'src/common/components/Modal';
import Typography from 'src/common/components/Typography';

export const ApproveModal = ({
	isOpen,
	onClose,
	onApprove,
	description,
}: {
	isOpen: boolean;
	onClose: () => void;
	onApprove: () => void;
	description: string;
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />

			<ModalContent minWidth={'500px'}>
				<Box p={'16px 0px 0px 16px'}>
					<IconButton
						icon={
							<Box color="gray.800">
								<CloseLarge16 width="16px" height="16px" />
							</Box>
						}
						onClick={onClose}
					/>
				</Box>

				<Box>
					<Grid pt="16px" gridTemplateColumns="repeat(12, [col-start] 1fr)" p={'8px 22px 48px 22px'} width={'500px'}>
						<GridItem colSpan={12} textAlign="center">
							<Typography variant="DesktopH5">Save changes?</Typography>
						</GridItem>
						<GridItem colSpan={12} textAlign="center" p={'16px 30px 32px 30px'}>
							<Typography variant="Paragraph14R">{description}</Typography>
						</GridItem>
						<GridItem colSpan={12}>
							<Flex justifyContent="center" alignItems="center" gap={'10px'}>
								<Button
									size={'medium'}
									variant="outline"
									colorScheme="gray"
									onClick={onClose}
									eventName="go-back-property-details-modal"
								>
									Go Back
								</Button>
								<Button
									size={'medium'}
									variant="solid"
									colorScheme="gray"
									eventName="approve-change-property-details-modal"
									onClick={() => {
										onApprove();
										onClose();
									}}
								>
									Yes, I understand
								</Button>
							</Flex>
						</GridItem>
					</Grid>
				</Box>
			</ModalContent>
		</Modal>
	);
};
