import { useCallback } from 'react';
import useNavigation from 'src/services/useNavigation';
import { isValueInOptionalValues } from '../utils/stateHelpers';
import { useMetricDerivedState } from './useMetricDerivedState';
import { useMetricPageSearchParams } from './useMetricPageSearchParams';
import { Selector } from 'src/pages/MetricPage/utils/state.types';

export function useMetricFlavor(): [Selector<string> | undefined, (flavor: string) => void] {
	const { flavor } = useMetricDerivedState();
	const { metricNameWithoutFlavor } = useMetricPageSearchParams();
	const { navigate } = useNavigation();
	const setFlavor = useCallback(
		(newFlavor: string) => {
			if (!flavor) {
				throw new Error(`Invalid Flavor Exception: This metric does not have support for a flavor`);
			}
			if (!isValueInOptionalValues(flavor, newFlavor)) {
				throw new Error(`Invalid Flavor Exception: ${flavor?.optionalValues} does not include ${newFlavor}`);
			}

			const newMetricRoute = encodeURIComponent(`${metricNameWithoutFlavor} (${newFlavor})`);
			navigate({ path: `metric/${newMetricRoute}`, willIncludeAllSearchParams: true });
		},
		[flavor, metricNameWithoutFlavor, navigate]
	);

	return [flavor, setFlavor];
}
