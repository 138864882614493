import { Box, Center, Flex } from '@chakra-ui/react';
import Typography from '@components/Typography';
import { ReactNode } from 'react';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import classes from './NavigationAvatarMenuItem.module.scss';

type Props = {
	leftIcon: ReactNode;
	text: string;
	additionalText?: ReactNode;
	onClick: () => void;
	withIndicator?: boolean;
	testId?: string;
};

function NavigationAvatarMenuItem({ leftIcon, text, onClick, additionalText, withIndicator, testId }: Props) {
	const { wrapWithReport } = useReportEvent();
	return (
		<Flex
			data-testid={testId}
			className={classes.navMenuItem}
			onClick={wrapWithReport(onClick, 'navigation-avatar-menu-item-clicked', { text, additionalText })}
			justifyContent="space-between"
		>
			<Center>
				{leftIcon}
				<Center pos="relative">
					<Typography ml="12px" variant="DesktopH8Regular" color="gray.1000">
						{text}
					</Typography>
					{withIndicator && (
						<Box
							h="8px"
							w="8px"
							borderRadius="100%"
							backgroundColor={colors.blue[700]}
							pos="absolute"
							right="-12px"
							top="0"
						/>
					)}
				</Center>
			</Center>
			{additionalText && additionalText}
		</Flex>
	);
}

export default NavigationAvatarMenuItem;
