import { Flex } from '@chakra-ui/react';
import Box from '@components/Box';
import Divider from '@components/Divider';
import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonDrillDownPanel.module.scss';

const { titleSkeleton, addDrillDownSkeleton, buttonSkeleton, iconSkeleton } = classes;

function SkeletonDrillDownPanelPart() {
	return (
		<Box>
			<Skeleton className={titleSkeleton} />
			<Skeleton className={addDrillDownSkeleton} />
		</Box>
	);
}

function SkeletonDrillDownPanelButtons() {
	return (
		<>
			<Flex justifyContent={'space-between'}>
				<Skeleton className={buttonSkeleton} />
				<Skeleton className={buttonSkeleton} />
			</Flex>
			<Box padding={'12px 0px'}>
				<Divider direction={'horizontal'} />
			</Box>
		</>
	);
}

function SkeletonDrillDownPanelIcons() {
	return (
		<>
			<Skeleton className={iconSkeleton} />
			<Box padding={'12px 0px'}>
				<Divider direction={'horizontal'} />
			</Box>
			<Skeleton className={iconSkeleton} />
		</>
	);
}

type SkeletonProps = {
	isWithToggle?: boolean;
	isShowingFullSize?: boolean;
};

export function SkeletonDrillDownPanel({ isWithToggle, isShowingFullSize = true }: SkeletonProps) {
	return isShowingFullSize ? (
		<>
			{isWithToggle && <SkeletonDrillDownPanelButtons />}
			<SkeletonDrillDownPanelPart />
			<Box padding={'24px 0px'}>
				<Divider direction={'horizontal'} />
			</Box>
			<SkeletonDrillDownPanelPart />
		</>
	) : (
		<>
			<SkeletonDrillDownPanelIcons />
		</>
	);
}
