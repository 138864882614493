export function extractMetricNameAndSearchParams(attachment: string): {
	metricName: string;
	searchParams: URLSearchParams;
} {
	const parsedUrl = new URL(attachment, 'http://localhost');
	const { pathname, searchParams } = parsedUrl;
	const metricName = decodeURIComponent(pathname.split('/')[2]);

	return { metricName, searchParams: new URLSearchParams(searchParams) };
}
