import { Flex } from '@chakra-ui/react';
import { BrokenChart140, BrokenChart90 } from '../Icons';
import Typography from '../Typography';
import { ReactNode } from 'react';

type PlaceholderProps = {
	isBigIcon?: boolean;
	description?: string | ReactNode;
};

export function BrokenChartPlaceholder({ isBigIcon, description }: PlaceholderProps) {
	return (
		<Flex flexDirection={'column'} alignItems={'center'} justifyContent="center">
			{isBigIcon ? <BrokenChart140 /> : <BrokenChart90 />}

			<Typography
				width={'100%'}
				textAlign={'center'}
				variant={isBigIcon ? 'DesktopH7Bold' : 'DesktopH8Medium'}
				color="gray.1000"
				marginTop={'16px'}
				marginBottom={'8px'}
			>
				Metric is broken.
			</Typography>

			{description}
		</Flex>
	);
}
