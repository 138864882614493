import { useAuthActions } from '@frontegg/react';
import useUrlTenant from '@hooks/navigation/useUrlTenant';
import { useCallback, useMemo } from 'react';
import { EnvironmentConfig, EnvironmentState, EnvironmentStore, Permission } from 'src/types/environment';
import { create } from 'zustand';

const backend = import.meta.env.PULSE_LOCAL && `127.0.0.1:8000`;

export const useEnvironmentStore = create<EnvironmentStore>((set) => ({
	setEnvironment: (environment: EnvironmentState) => set({ environment }),
	environment: {
		tenant: 0,
		stamp: '',
		role: '',
		isSecure: true,
		permissions: [],
		isMultiTenant: false,
		feRoles: [],
		activeRole: '',
	},
}));

export function normalizedEnvConfig(env: EnvironmentState) {
	const isSecure = backend ? false : env.isSecure;
	const wsScheme = isSecure ? 'wss' : 'ws';
	const httpScheme = isSecure ? 'https' : 'http';
	const apiStamp = backend || `api.${env.stamp}.sightfull.com`;

	const envConfig: EnvironmentConfig = {
		...env,
		wsGqlUrl: `${wsScheme}://${apiStamp.replace('api', 'wss')}/v1/graphql`,
		httpGqlUrl: `${httpScheme}://${apiStamp}/v1/graphql`,
		apolloRouterGqlUrl: `${httpScheme}://${apiStamp}/v2/graphql`,
		apiUrl: `${httpScheme}://${apiStamp}`,
	};
	return envConfig;
}

export function useEnvironment() {
	const env = useEnvironmentStore(envSelectors.getAll);
	return useMemo(() => normalizedEnvConfig(env), [env]);
}

export function usePermissionCheck(): {
	isHavingPermission: (permission: Permission) => boolean;
	isHavingOneOfPermissions: (permissions: Array<Permission>) => boolean;
	isHavingAllOfPermissions: (permissions: Array<Permission>) => boolean;
} {
	const hasPemissionChecker = useEnvironmentStore(envSelectors.hasPermission);
	return {
		isHavingPermission: hasPemissionChecker,
		isHavingOneOfPermissions: (perms: Array<Permission>) => perms.some((p) => hasPemissionChecker(p)),
		isHavingAllOfPermissions: (perms: Array<Permission>) => perms.every((p) => hasPemissionChecker(p)),
	};
}

export function useSwitchTenant() {
	const { switchTenant } = useAuthActions();
	const [, setUrlTenant] = useUrlTenant();

	return useCallback(
		(tenantId: string) => {
			setUrlTenant(tenantId);
			switchTenant({ tenantId });
		},
		[setUrlTenant, switchTenant]
	);
}

export const envSelectors = {
	getAll: (state: EnvironmentStore): EnvironmentState => state.environment,
	hasPermission:
		(state: EnvironmentStore) =>
		(perm: Permission): boolean =>
			state.environment.permissions.some((p) => {
				const permissionRegExp = new RegExp(`^(${p})$`);
				return permissionRegExp.test(perm);
			}),
};

export const testEnvironment: EnvironmentState = {
	permissions: [],
	tenant: 2,
	role: 'user',
	stamp: 'staging',
	isSecure: true,
	isMultiTenant: false,
	feRoles: [],
	activeRole: 'user',
};
