import { editor, Position } from 'monaco-editor';
import { CompletionProvider } from '../completionProvider';
import { EnrichedSemanticDefinitions } from '../semanticTypes';
import { AUTO_COMPLETE_YAML_KEYS } from '../types';
import { findYamlKey } from '../utils/findYamlKey';
import { getEntity, getWordRangeAtPosition, rangeFromPosition } from '../utils/utils';
import { getContextCompletionTooltip } from '../widgetBuilder/contextTooltip';

export const OntologyBuilderYamlHoverProviderBuilder =
	(semanticDefinitions: EnrichedSemanticDefinitions, entity: string) =>
	(model: editor.ITextModel, position: Position) => {
		const rangeFromPositionValue = rangeFromPosition(position);
		const partRange =
			getWordRangeAtPosition(model, position, CompletionProvider.EXPRESSION_PART) ?? rangeFromPositionValue;
		const part = model.getValueInRange(partRange);
		const expressionRange =
			getWordRangeAtPosition(model, position, CompletionProvider.EXPRESSION) ?? rangeFromPositionValue;
		const expression = expressionRange ? model.getValueInRange(expressionRange) : '';

		const completion = new CompletionProvider(semanticDefinitions);

		const tokenPath = findYamlKey(model.getValue(), model.getOffsetAt(position));
		if (!tokenPath) {
			console.error('Failed to get document path', expression, model.getValue(), model.getOffsetAt(position));
			return;
		}
		const lastYamlKey = tokenPath.split('.').reverse()[0];
		const isYamlKeySuitableForAutocomplete = AUTO_COMPLETE_YAML_KEYS.includes(lastYamlKey);
		if (!isYamlKeySuitableForAutocomplete) {
			console.info('Refusing to hover based on key', lastYamlKey);
			return;
		}

		const enrichedEntity = getEntity(semanticDefinitions, entity);
		const contexts = completion.createContexts({
			entity: enrichedEntity,
		});

		const hoverContexts = completion.walkContextForCompletions(
			contexts,
			expression,
			partRange.getEndPosition().column - expressionRange.getStartPosition().column,
			false
		);
		const hoveredContext = hoverContexts.find((c) => c.keyword === part);

		if (!hoveredContext) {
			return { contents: [], range: partRange };
		}

		const tooltip = getContextCompletionTooltip(hoveredContext, `$${part}`);

		return {
			contents: tooltip ? [tooltip] : [],
			range: partRange,
		};
	};
