import { IMarkdownString } from 'monaco-editor';
import { stringify } from 'yaml';
import { Context, ParameterContext } from '../completionProvider';
import { AvailableParameter, isRawSQLDataSource } from '../semanticTypes';
import { ListValues, RangeValues } from '../semanticTypes/parameters.schema';
import { DESCRIPTION_BY_TYPE, DimensionTypes } from '../types';
import { buildDiv, buildDivChildren, buildLink, buildSectionColumn, buildYamlCodeBlock } from './baseBuilders';
import { getCssCompletionTooltip } from './cssTooltip';
import { getEntityCssCompletionTooltip } from './entityCompletionItem';
import { buildSourceFooter } from './sourceFooter';

export function getContextCompletionTooltip(context: Context, insertText: string): IMarkdownString | undefined {
	if (context.type == 'entity') return getEntityCssCompletionTooltip(context.entity, insertText); // TODO: because of historical changes there is both the context.type "entity" and a separate entity tooltip

	const isSourceDimension =
		context.location && 'isSourceDimension' in context.location && context.location['isSourceDimension'];

	const image = { color: contextTypeColor[context.type], name: context.type };
	const title = context.display_name ?? context.name;
	let subtitleText = DESCRIPTION_BY_TYPE[context.type];
	if (DimensionTypes.includes(context.type)) {
		subtitleText = (isSourceDimension ? 'Source ' : 'Ontology ') + subtitleText;
	}
	const description = isSourceDimension
		? `This source dimension is obtained from the source system.`
		: context.description;
	const openMetricLink =
		context.type === 'metric' || context.type === 'formula_metric'
			? `${window.location.origin}/metric/${context.metric.name}?pageMode=edit`
			: undefined;

	const additionalInformationRows = [];
	if (context.type === 'relationship') {
		additionalInformationRows.push(buildRelationshipPath(context));
	}

	if (context.type === 'relationship') {
		const relationshipJoinKeysSection = buildRelationshipJoinKeysSection(context);
		if (relationshipJoinKeysSection) {
			additionalInformationRows.push(relationshipJoinKeysSection);
		}
	}

	if (DimensionTypes.includes(context.type)) {
		const dimensionSourceSection = buildDimensionSourceSection(context);
		if (dimensionSourceSection) {
			additionalInformationRows.push(dimensionSourceSection);
		}
	}

	if (context.type === 'parameter') {
		const defaultValueSection = buildParameterDefaultValueSection(context);
		additionalInformationRows.push(defaultValueSection);

		const valuesSection = buildParameterValuesSection(context);
		if (valuesSection) {
			additionalInformationRows.push(valuesSection);
		}
	}

	const getSourceFooter = () => {
		if (context.type === 'relationship' || !context.parent_entity) {
			return '';
		}
		const dataSource = context.parent_entity.data_source;
		const isRawSql = isRawSQLDataSource(dataSource);
		const sourceName = isRawSql ? 'Raw SQL' : dataSource.schema?.meta?.source;

		return buildSourceFooter({
			source: sourceName ?? 'Unknown Source',
			entityLink: `${window.location.origin}/ontology/${context?.parent_entity?.name}`,
			isWithSourceIcon: !isRawSql && !!sourceName,
			entityName: context?.parent_entity?.meta?.display_name ?? context.parent_entity?.name,
		});
	};

	return getCssCompletionTooltip(
		{
			title,
			image,
			subtitle: subtitleText,
			description,
			additionalInformationRows,
			openMetricLink,
			sourceFooter: getSourceFooter(),
		},
		insertText
	);
}

function buildRelationshipJoinKeysSection(context: Context): string | undefined {
	if (context.location && 'on' in context.location && context.location['on']) {
		const yamlCodeBlock = buildYamlCodeBlock(stringify({ on: context.location['on'] }));
		return buildSectionColumn('full-width', 'Join keys', yamlCodeBlock);
	}
}
function buildDimensionSourceSection(context: Context): string | undefined {
	if (context.location && 'sources' in context.location && context.location['sources']) {
		const yamlCodeBlock = buildYamlCodeBlock(stringify(context.location['sources']));

		return buildSectionColumn('full-width', 'Sources', yamlCodeBlock);
	} else if (context.location && 'raw_sql' in context.location && context.location['raw_sql']) {
		const yamlCodeBlock = buildYamlCodeBlock(stringify({ raw_sql: context.location['raw_sql'] }));

		return buildSectionColumn('full-width', 'Raw Sql', yamlCodeBlock);
	}
}

function buildRelationshipPath(context: Context): string {
	const parentEntity = buildLink(
		`${window.location.origin}/ontology/${context?.parent_entity?.name}`,
		buildDiv(
			'relationship-path-parent-entity',
			context.parent_entity?.meta?.display_name ?? context.parent_entity?.name
		)
	);
	const relationship = buildDiv('relationship-path-relationship', context.display_name ?? context.name);
	const childEntity = buildLink(
		`${window.location.origin}/ontology/${context?.entity?.name}`,
		buildDiv('relationship-path-child-entity', context.entity?.meta?.display_name ?? context.entity?.name)
	);
	const pathIcon = buildDiv('relationship-path-icon');
	return buildSectionColumn(
		'',
		'Relationship Path',
		buildDivChildren('relationship-path-layout', parentEntity, pathIcon, relationship, pathIcon, childEntity)
	);
}

function buildParameterDefaultValueSection(context: ParameterContext): string {
	const parameter = context.location;

	return buildSectionColumn('', 'Default value', parameter.default.toString());
}

function buildParameterValuesSection(context: ParameterContext): string | undefined {
	const parameter = context.location;
	const valuesAsString = getValuesAsString(parameter);

	if (!valuesAsString) {
		return undefined;
	}

	return buildSectionColumn('', 'Values', valuesAsString);
}

function isAnyValue(parameter: AvailableParameter, values?: ListValues | RangeValues): values is undefined {
	return parameter.value_types === 'any';
}

function isValueFromList(parameter: AvailableParameter, values?: ListValues | RangeValues): values is ListValues {
	return parameter.value_types === 'list';
}

function isValueFromRange(parameter: AvailableParameter, values?: ListValues | RangeValues): values is RangeValues {
	return parameter.value_types === 'range';
}

function getValuesAsString(parameter: AvailableParameter) {
	const values = parameter.values;

	if (isAnyValue(parameter, values)) {
		return 'Any value';
	} else if (isValueFromList(parameter, values)) {
		return values.map((v) => v.value).join(', ');
	} else if (isValueFromRange(parameter, values)) {
		return `${values.from} to ${values.to}`;
	}

	return undefined;
}

const contextTypeColor: Record<Context['type'], string> = {
	relationship: 'cyan',
	entity: 'cyan',
	parameter: 'blue',
	parameter_store: 'blue',
	dimension: 'blue',
	dimension_numeric: 'blue',
	metric: 'violet',
	formula_metric: 'violet',
	join: 'pink',
	function: 'green',
	dimension_date: 'blue',
	dimension_boolean: 'blue',
};
