import { Box, Flex, GridItem } from '@chakra-ui/react';
import { PAGE_WIDTH } from 'src/pages/SearchResultsPage/consts';
import colors from 'src/style/colors';
import { ResponsiveGrid } from '../ResponsiveGrid/ResponsiveGrid';
import Tabs, { Tab } from '../Tabs';
import { TabProps } from '../Tabs/types';
import Typography from '../Typography';
import { PageHeaderProps } from './types';

const PageHeader = ({ tabs, title, tabIndex, onChangeTab, children, testId, hasBorderBottom }: PageHeaderProps) => {
	return (
		<ResponsiveGrid>
			<GridItem colSpan={{ base: 2, xs: 12 }}>
				<Box data-testid={testId} pt={'32px'}>
					<Flex
						direction={{ base: 'column', sm: 'row' }}
						alignItems={{ base: 'start', sm: 'center' }}
						justify="space-between"
						pb={'12px'}
					>
						<Typography variant="Paragraph24M" color={colors.gray[800]}>
							{title}
						</Typography>
						{children && <Box pt={{ base: '12px', sm: '0px' }}>{children}</Box>}
					</Flex>

					{tabs && onChangeTab && (
						<Flex pt={'12px'} width={`${PAGE_WIDTH}px`}>
							<Tabs
								selectedIndex={tabIndex ?? 0}
								onClick={onChangeTab}
								tabsData={tabs}
								hasBorderBottom={false}
								renderItem={(tabData: TabProps, isSelected: boolean) => <Tab {...tabData} isSelected={isSelected} />}
							/>
						</Flex>
					)}

					{hasBorderBottom && (
						<Box
							width={`${PAGE_WIDTH}px`}
							position={'relative'}
							top={'-1px'}
							height={'1px'}
							boxShadow={'borderBottom'}
						/>
					)}
				</Box>
			</GridItem>
		</ResponsiveGrid>
	);
};

export default PageHeader;
