import sortBy from 'lodash/sortBy';
import { GetMetricByNameQuery } from 'src/generated/graphql';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { RulesInput } from '../utils';

export function calcFlavor(
	{ metricFlavor }: RulesInput,
	metricsMetadataByNameResult: GetMetricByNameQuery
): Pick<MetricDerivedState, 'flavor'> {
	const optionalFlavors = sortBy(metricsMetadataByNameResult.metrics, (m) => m.order)
		.map((metric) => metric.flavor)
		.filter((x) => !!x);
	const selectedFlavor = optionalFlavors.find((f) => f == metricFlavor) ?? optionalFlavors[0];

	const flavor = selectedFlavor ? { selectedValue: selectedFlavor, optionalValues: optionalFlavors } : undefined;

	return { flavor };
}
