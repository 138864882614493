import { Flex } from '@chakra-ui/react';
import { Check16 } from '../Icons';
import Typography from '../Typography';
import colors from 'src/style/colors';
import classes from './MessageSlider.module.scss';
import { ReactNode } from 'react';

export type SliderProps = {
	text: string;
	icon: ReactNode;
	isOpen: boolean;
};

export function MessageSlider({ text, isOpen = false, icon = <Check16 color={colors.green[600]} /> }: SliderProps) {
	return (
		<>
			<Flex className={`${classes.editorMessage} ${isOpen ? classes.isVisible : ''}`}>
				{icon}
				<Typography marginLeft={'12px'} variant="DesktopH10Medium" textAlign="start">
					{text}
				</Typography>
			</Flex>
		</>
	);
}
