import { FolderSolid16 } from '@components/Icons';
import ListItem from '@components/ListItem';
import { FiltersAndBreakdownItemType } from './types';

import { Flex } from '@chakra-ui/react';
import Typography from 'src/common/components/Typography';
import styles from './FiltersAndBreakdowns.module.scss';
import { getFirstObjectType } from './NodeScheme/utils';
import { filterStringByTerm } from 'src/common/utils/utils';

const Relationships = ({ relationships, searchTerm, onRelationshipClick }: any) => {
	const filteredRealtionships = relationships.filter((relationship: FiltersAndBreakdownItemType) =>
		filterStringByTerm(searchTerm, relationship?.label)
	);

	return filteredRealtionships.map((relationship: FiltersAndBreakdownItemType) => {
		const objectTypeIndicator =
			relationship.key.includes('.') &&
			((isHovering: boolean) => (
				<Flex dir="row" justifyContent={'end'} flex={1}>
					<Typography variant="Paragraph12R" color={isHovering ? 'blue.700' : 'gray.700'}>
						{getFirstObjectType(relationship.key)}
					</Typography>
				</Flex>
			));
		return (
			<ListItem
				size="sm"
				key={relationship.key}
				label={relationship.label}
				prefixIcon={<FolderSolid16 className={styles.listItemLeftIcon} />}
				suffixComponent={objectTypeIndicator}
				hasChevronRight={true}
				onClick={() => onRelationshipClick(relationship)}
			/>
		);
	});
};

export default Relationships;
