import { SelectOption } from 'src/common/components/Select/types';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';
import { StatisticOperation } from 'src/pages/MetricPage/utils/state.types';

type ReturnType = [
	StatisticOperation[],
	{
		toggleOperationState: (item: StatisticOperation) => void;
		handleOperationInfoChange: (item: StatisticOperation, option: SelectOption) => void;
		clearStatisticAndDisplayUnits: () => void;
	}
];

const useStatisticsOperations = (): ReturnType => {
	const { searchParams, setSearchParams } = useMetricPageSearchParams();
	const { statisticsOperations, chartType } = useMetricDerivedState();

	const getStateAsParams = (): { [key: string]: string } => {
		return statisticsOperations.reduce((params, { name, isChecked, options, selectedOptionIndex }) => {
			if (!isChecked) {
				return params;
			}

			return {
				...params,
				[name]: options?.[selectedOptionIndex].value,
			};
		}, {});
	};

	const toggleOperationState = (item: StatisticOperation) => {
		const statisticsParams = getStateAsParams();

		const shouldToggleOff = !!statisticsParams[item.name];
		let statisticsOperations: Record<string, any> | undefined = {};

		if (shouldToggleOff) {
			delete statisticsParams[item.name];
			const hasParams = !!Object.keys(statisticsParams).length;
			statisticsOperations = hasParams ? statisticsParams : undefined;
		} else {
			statisticsOperations = {
				...statisticsParams,
				[item.name]: item.options?.[item.selectedOptionIndex].value,
			};
		}

		setSearchParams({ ...searchParams, ...statisticsParams, statisticsOperations });
	};

	const clearStatisticOperations = () => {
		setSearchParams({ ...searchParams, statisticsOperations: undefined, displayUnits: [] });
	};

	const handleOperationInfoChange = (item: StatisticOperation, option: SelectOption) => {
		const statisticsParams = getStateAsParams();

		const statisticsOperations = {
			...statisticsParams,
			[item.name]: option.value,
		};

		setSearchParams({ ...searchParams, ...statisticsParams, statisticsOperations });
	};

	const cleanStatisticsOperations = (rawStatisticOperation: StatisticOperation[]): StatisticOperation[] => {
		if (chartType.selectedValue == 'pie') {
			return [];
		}

		return rawStatisticOperation;
	};

	const cleanedStatisticsOperations = cleanStatisticsOperations(statisticsOperations);

	return [
		cleanedStatisticsOperations,
		{ toggleOperationState, handleOperationInfoChange, clearStatisticAndDisplayUnits: clearStatisticOperations },
	];
};

export default useStatisticsOperations;
