import { Box, Flex, FormControl } from '@chakra-ui/react';
import { TextArea } from 'src/common/components/Textarea';
import Typography from 'src/common/components/Typography';
import { FieldsProps, ModalFieldValue } from '../types';
import { modalFieldByType } from './modalFieldByType';

export const Fields = ({ isHasEditPermission, fieldName, fieldType, onChange, newValue }: FieldsProps) => {
	const FieldComponent = modalFieldByType?.[fieldType];

	return (
		<FormControl>
			<Flex pb={'24px'} direction={'column'}>
				<Typography variant="DesktopH7Bold" pb={'8px'}>
					{fieldName}
				</Typography>
				<FieldComponent
					value={newValue.value}
					onChange={(newValue: ModalFieldValue | null) => newValue != null && onChange('value', newValue)}
					isDisabled={!isHasEditPermission}
				/>
			</Flex>

			{isHasEditPermission && (
				<Box pb={'24px'}>
					<ModalLabel text={'Description'} optional />
					<TextArea
						counter={500}
						width={'100%'}
						height={'120px'}
						value={newValue.comment}
						onChange={(newValue) => onChange('comment', newValue)}
						placeholder={"Describe why you're changing this value"}
					/>
				</Box>
			)}
		</FormControl>
	);
};

const ModalLabel = ({ text, optional = false }: { text: string; optional?: boolean }) => {
	return (
		<Box>
			<Typography variant="DesktopH7Bold" pr={'4px'} pb={'8px'}>
				{text}
			</Typography>
			{optional && <Typography variant="DesktopH7Regular">(Optional)</Typography>}
		</Box>
	);
};
