import { DimensionType } from 'src/lib/completions/semanticTypes/normalization.schema';
import { isRecord } from 'src/models/MetricDefinition/normalizeMetricState';
import YAML from 'yaml';

export type OntologyDimensionInfo = {
	name: string;
	type: DimensionType;
	sources: { sql: string }[] | { raw_sql: string }[];
	meta?: {
		display_name?: string;
		description?: string;
	};
};

export type OntologyRelationshipInfo = {
	name: string;
	referenced_entity: string;
	type: 'one_to_one' | 'one_to_many';
	meta?: {
		display_name?: string;
		description?: string;
	};
	on: string;
};

export type OntologyNormalized = {
	dimensions: OntologyDimensionInfo[];
	relationships: OntologyRelationshipInfo[];
};

export function normalizeOntologyYaml(yaml: string): OntologyNormalized {
	let dimensions = [];
	let relationships = [];
	try {
		const rawOntology = YAML.parse(yaml);
		dimensions = rawOntology?.dimensions || [];
		relationships = rawOntology?.relationships || [];
	} catch (e) {
		console.log(e);
	}

	return {
		dimensions,
		relationships,
	};
}

export const isValidYamlEntity = (entityDataRaw: unknown) => {
	if (!isRecord(entityDataRaw)) return false;
	if (!('name' in entityDataRaw || typeof entityDataRaw['name'] !== 'string')) return false;
	if (!('data_source' in entityDataRaw)) return false;
	if (!('primary_keys' in entityDataRaw || !Array.isArray(entityDataRaw['primary_keys']))) return false;
	return true;
};
