import { MutableRefObject, useEffect, useLayoutEffect, useRef, useState, ReactNode, useCallback } from 'react';
import { XS_BREAKPOINT } from 'src/pages/DashboardPage/constants';

interface ResponsiveHeaderProps {
	renderItem: (isTiny: boolean) => ReactNode;
}

export function ResponsiveHeader({ renderItem }: ResponsiveHeaderProps) {
	const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const [isTiny, setIsTiny] = useState(false);

	const updateDimensions = useCallback(() => {
		const width = ref?.current?.clientWidth ?? 0;
		setIsTiny(width < XS_BREAKPOINT);
	}, []);

	useEffect(() => {
		window.addEventListener('resize', updateDimensions);

		return () => {
			window.removeEventListener('resize', updateDimensions);
		};
	}, [updateDimensions]);
	useLayoutEffect(() => {
		updateDimensions();
	}, [updateDimensions]);

	return <div ref={ref}>{renderItem(isTiny)}</div>;
}
