import useSearchParams, { extractPrivateSearchParams } from './useSearchParams';
import { useCallback } from 'react';

function usePrivateSearchParams(): [Record<string, string>, (key: string, value: string | null) => void] {
	const [searchParams, setSearchParams] = useSearchParams();

	const appPrivateSearchParams: Record<string, string> = extractPrivateSearchParams(searchParams);

	const setAppPrivateSearchParam = useCallback(
		(key: string, value: string | null) => {
			const privateKey = `_${key}`;
			if (value) {
				searchParams.set(privateKey, value);
			} else {
				searchParams.delete(privateKey);
			}
			setSearchParams(searchParams);
		},
		[searchParams, setSearchParams]
	);

	return [appPrivateSearchParams, setAppPrivateSearchParam];
}

export default usePrivateSearchParams;
