import { useEffect, useRef, useState } from 'react';

type UsePersistentStateOptions<T> = {
	serialize?: (value: T) => string;
	deserialize?: (value: string) => T;
	storeType?: 'local' | 'session';
};

type supportedStorageKeys = 'metricPage.openDataOverview' | 'metricPage.normalizedPropsSelectedOption';

function isFunction<T>(value: T | (() => T)): value is () => T {
	return typeof value === 'function';
}

// WARN: limitations: will not work if used in multiple locations with the same key,
// 				SHOULD NOT be used instead of global state
export function usePersistentState<T>(
	key: supportedStorageKeys,
	defaultValue: T | (() => T),
	{ serialize = JSON.stringify, deserialize = JSON.parse, storeType = 'local' }: UsePersistentStateOptions<T> = {}
) {
	const store = storeType === 'local' ? window.localStorage : window.sessionStorage;

	const [state, setState] = useState<T>(() => {
		const valueInLocalStorage = store.getItem(key);
		if (valueInLocalStorage) {
			try {
				return deserialize(valueInLocalStorage);
			} catch {
				store.removeItem(key);
			}
		}

		return isFunction(defaultValue) ? defaultValue() : defaultValue;
	});

	const prevKeyRef = useRef(key);

	useEffect(() => {
		const prevKey = prevKeyRef.current;
		if (prevKey !== key) {
			store.removeItem(prevKey);
		}
		prevKeyRef.current = key;
		store.setItem(key, serialize(state));
	}, [key, state, serialize, store]);

	return [state, setState] as const;
}
