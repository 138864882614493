import Box from '@components/Box';
import { DashboardSpace20 } from 'src/common/components/Icons';

export function DashboardBadge() {
	return (
		<Box cursor={'default'} marginRight={'8px'}>
			<DashboardSpace20 />
		</Box>
	);
}
