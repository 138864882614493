import { Flex } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import Loader from 'src/common/components/Loader';
import PageHeader from 'src/common/components/PageHeader';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import Page from 'src/layout/Page';
import { useReportEvent } from 'src/services/analytics';
import { NoResultsFoundComponent } from './components/NoResultsFoundComponent';
import { PageTitle } from './components/PageTitle';
import { SearchResults } from './components/SearchResults';
import { useSearchResultsV1, useSearchResultsV2 } from './hooks/useSearchResults';
import { SearchResultsHookReturnType } from './types';
import { resultsPageMetadata } from './utils';

export default function SearchResultsPage() {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	return (
		<Page page="Search Results Page">{isSightfull2 ? <SearchResultsPageGuardV2 /> : <SearchResultsPageGuardV1 />}</Page>
	);
}

function SearchResultsPageGuardV1() {
	const {
		searchText,
		searchObjects,
		isAllSearch,
		isLoading,
		queryResult,
		resultObjects,
		tabsNames,
		noResultsFound,
		shouldShowTabs,
	} = useSearchResultsV1();

	return (
		<SearchResultsPageGuard
			searchText={searchText}
			searchObjects={searchObjects}
			isAllSearch={isAllSearch}
			isLoading={isLoading}
			queryResult={queryResult}
			resultObjects={resultObjects}
			tabsNames={tabsNames}
			noResultsFound={noResultsFound}
			shouldShowTabs={shouldShowTabs}
		/>
	);
}

function SearchResultsPageGuardV2() {
	const {
		searchText,
		searchObjects,
		isAllSearch,
		isLoading,
		queryResult,
		resultObjects,
		tabsNames,
		noResultsFound,
		shouldShowTabs,
		entityNameToDisplay,
	} = useSearchResultsV2();

	return (
		<SearchResultsPageGuard
			searchText={searchText}
			searchObjects={searchObjects}
			isAllSearch={isAllSearch}
			isLoading={isLoading}
			queryResult={queryResult}
			resultObjects={resultObjects}
			tabsNames={tabsNames}
			noResultsFound={noResultsFound}
			shouldShowTabs={shouldShowTabs}
			entityNameToDisplay={entityNameToDisplay}
		/>
	);
}

export function SearchResultsPageGuard({
	searchText,
	searchObjects,
	isAllSearch,
	isLoading,
	queryResult,
	resultObjects,
	tabsNames,
	noResultsFound,
	shouldShowTabs,
	entityNameToDisplay,
}: SearchResultsHookReturnType) {
	const { reportEvent } = useReportEvent();

	const [chosenObjectIndex, setChosenObjectIndex] = useState<number>(0);

	const [searchTerm, setSearchTerm] = useState('');

	const tabs = tabsNames.map((tabName, index) => {
		return { text: tabName, isSelected: index == chosenObjectIndex };
	});

	const onTabChange = useCallback(
		(option: number) => {
			reportEvent({
				event: 'search-tab-navigation',
				metaData: {
					...resultsPageMetadata(isAllSearch),
					postState: tabsNames[option],
					preState: tabsNames[chosenObjectIndex],
				},
			});

			setChosenObjectIndex(option);
			setSearchTerm('');
		},
		[reportEvent, isAllSearch, tabsNames, chosenObjectIndex]
	);

	const displayNames = entityNameToDisplay ?? {};

	return (
		<Flex direction="column" height="100%">
			<PageHeader
				title={
					<PageTitle
						searchText={searchText}
						isAllSearch={isAllSearch}
						searchSingleObject={displayNames[searchObjects?.[0]]}
					/>
				}
				tabs={shouldShowTabs ? tabs : undefined}
				onChangeTab={onTabChange}
				tabIndex={chosenObjectIndex}
				hasBorderBottom={shouldShowTabs}
			/>

			{isLoading ? (
				<Loader isCenter />
			) : noResultsFound ? (
				<NoResultsFoundComponent />
			) : (
				<SearchResults
					isAllSearch={isAllSearch}
					chosenObjectIndex={chosenObjectIndex}
					resultObjects={resultObjects}
					queryResult={queryResult}
					setChosenObjectIndex={setChosenObjectIndex}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					entityNameToDisplay={displayNames}
				/>
			)}
		</Flex>
	);
}
