import Flex from '@components/Flex';
import { AddColumn16 } from 'src/common/components/Icons';
import Button from '../../common/components/Button';
import Pagination from '../../common/components/Pagination';
import Typography from '../../common/components/Typography';

type RecordProfileSectionHeaderProps = {
	headerName: string;
	suffix: string;
	totalCount: number;
	limit: number;
	onNext: () => void;
	onPrevious: () => void;
	onAddColumn?: () => void;
};

export function RecordProfileSectionHeader({
	headerName,
	totalCount,
	limit,
	onNext,
	onPrevious,
	suffix,
	onAddColumn,
}: RecordProfileSectionHeaderProps) {
	return (
		<Flex alignItems={'center'} gap="12px" alignSelf="strech" justifyContent={'flex-start'} width={'100%'}>
			<Typography variant="DesktopH7Medium" color={'gray.800'}>
				{headerName}
			</Typography>
			<Pagination onNext={onNext} onPrevious={onPrevious} limit={limit} totalCount={totalCount} suffix={suffix} />
			{onAddColumn && (
				<Button marginLeft={'auto'} isIconOnly size="xs" variant="outline" colorScheme="black" onClick={onAddColumn}>
					<AddColumn16 style={{ cursor: 'pointer' }} />
				</Button>
			)}
		</Flex>
	);
}
