import Box from '@components/Box';
import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonBuilder.module.scss';
import Divider from 'src/common/components/Divider';

const { entitySelect, label } = classes;

function EntitySelect() {
	return (
		<>
			<Skeleton className={label} />
			<Skeleton className={entitySelect} />
		</>
	);
}

function Formula() {
	return (
		<>
			<Skeleton className={label} />
			<Skeleton className={label} />
			<Skeleton className={entitySelect} />
		</>
	);
}

export function SkeletonBuilder() {
	return (
		<Box width={'100%'} height={'100vh'}>
			<EntitySelect />
			<Divider marginY={'28px'} direction="horizontal" />
			<Formula />
		</Box>
	);
}
