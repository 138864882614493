import { Global } from '@emotion/react';

const FontFaces = () => (
	<Global
		styles={`
			@font-face {
				font-family: 'Inter';
				font-style: normal;
				font-weight: 700;
				font-display: swap;
				src: url('/assets/fonts/Inter-Bold.otf') format('opentype');			
			}
	
			@font-face {
				font-family: 'Inter';
				font-style: italic;
				font-weight: 700;
				font-display: swap;
				src: url('/assets/fonts/Inter-BoldItalic.otf') format('opentype');
			}
	
			@font-face {
				font-family: 'Inter';
				font-style: italic;
				font-weight: 400;
				font-display: swap;
				src: url('/assets/fonts/Inter-Italic.otf') format('opentype');
			}
	
			@font-face {
				font-family: 'Inter';
				font-style: normal;
				font-weight: 400;
				font-display: swap;
				src: url('/assets/fonts/Inter-Regular.otf') format('opentype');
			}
	
			@font-face {
				font-family: 'Inter';
				font-style: normal;
				font-weight: 600;
				font-display: swap;
				src: url('/assets/fonts/Inter-SemiBold.otf') format('opentype');
			}
	
			@font-face {
				font-family: 'Inter';
				font-style: italic;
				font-weight: 600;
				font-display: swap;
				src: url('/assets/fonts/Inter-SemiBoldItalic.otf') format('opentype');
			}
			
			@font-face {
				font-family: 'Source Code Pro';
				font-style: normal;
				font-weight: 400;
				font-display: swap;
				src: url('/assets/fonts/SourceCodePro-Regular.ttf') format('truetype');			
			}
		`}
	/>
);

export default FontFaces;
