export const getPersistantStore = (storeName: string, defaultStore: Record<string, any>) => {
	try {
		return JSON.parse(localStorage.getItem(storeName) || '');
	} catch {
		return defaultStore;
	}
};

const collectionsNavDrawer = ['dashboard', 'workspace'];

export const collectionsNavigationDrawer = { dashboard: 'dashboard', workspace: 'workspace' };
export const metricsNavigationDrawer = 'metrics';
export const possibleNavDrawerViewValues = [...Object.values(collectionsNavigationDrawer), metricsNavigationDrawer];
export type NavDrawerView = typeof possibleNavDrawerViewValues[number];

export const storePersistOverrides = {
	serialize: (state: Record<string, any>) => window.btoa(JSON.stringify(state)),
	deserialize: (str: string) => JSON.parse(window.atob(str)),
};

export function getIsNavDrawerViewCollection(view: NavDrawerView) {
	return collectionsNavDrawer.includes(view);
}

export function getIsValidDrawerView(view: string): boolean {
	return possibleNavDrawerViewValues.includes(view as NavDrawerView);
}
