import { useCallback, useMemo } from 'react';
import {
	InitiateMetricInteractiveSessionMutationVariables,
	useInitiateMetricInteractiveSessionMutation,
} from 'src/generated/graphql';
import { getFiltersForQuery } from 'src/lib/metricRules/utils';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';

export const useInitiateMetricInteractiveSession = () => {
	const {
		metricNameWithoutFlavor,
		breakdowns,
		periodRange,
		filters,
		flavor,
		metricDisplayName,
		metricExplanationOneliner,
	} = useMetricDerivedState();
	const [initiateMetricInteractiveSession] = useInitiateMetricInteractiveSessionMutation();

	const hasMetricOpenPeriod = useMemo(() => periodRange.asAbsoluteRange.endPeriod.isOpen, [periodRange]);

	const variables = useMemo(() => {
		const variables: InitiateMetricInteractiveSessionMutationVariables = {
			metric_data: {
				periods: periodRange.asAbsoluteRange.toMetricPeriods().map((e) => e.id),
				filterBy: filters.length > 0 ? getFiltersForQuery(filters) : undefined,
				openPeriod: hasMetricOpenPeriod ? periodRange.asAbsoluteRange.endPeriod.id : undefined,
				metricName: metricNameWithoutFlavor,
				flavor: flavor?.selectedValue,
				metricDisplayName: metricDisplayName,
				oneLiner: metricExplanationOneliner,
				groupBy: breakdowns.values.map((e) => e.key),
			},
		};
		return variables;
	}, [
		metricNameWithoutFlavor,
		breakdowns,
		periodRange,
		hasMetricOpenPeriod,
		filters,
		flavor,
		metricDisplayName,
		metricExplanationOneliner,
	]);

	const handleInitiateMetricInteractiveSession = useCallback(async () => {
		try {
			const res = await initiateMetricInteractiveSession({ variables });
			const sessionId: string = res.data?.generative_initiate_metric_interactive?.response.requestId;
			if (!sessionId) {
				throw new Error(`Session ID not found in response: ${JSON.stringify(res)}`);
			}

			return sessionId;
		} catch (err) {
			throw new Error(`Failed to initiate interactive session: ${err instanceof Error ? err.message : err}`);
		}
	}, [variables, initiateMetricInteractiveSession]);

	return { handleInitiateMetricInteractiveSession };
};
