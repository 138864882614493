import { DocumentNode, TypedDocumentNode, useMutation as useMutationApollo } from '@apollo/client';
import { ApolloCache, DefaultContext, OperationVariables } from '@apollo/client/core';
import { MutationHookOptions, MutationTuple } from '@apollo/client/react/types/types';
import { isProductionMode } from 'src/config';

export function useMutation<
	TData = any,
	TVariables extends OperationVariables = OperationVariables,
	TContext = DefaultContext,
	TCache extends ApolloCache<any> = ApolloCache<any>
>(
	mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
	options?: MutationHookOptions<TData, TVariables, TContext>
): MutationTuple<TData, TVariables, TContext, TCache> {
	const [mutate, obj] = useMutationApollo<TData, TVariables, TContext, TCache>(mutation, options);
	if (obj.error) {
		if (!isProductionMode) {
			// TODO: implement error snackbar or something
			console.log('an error occurred');
			console.dir(obj.error);
		}
	}
	return [mutate, obj];
}

export default useMutation;
