import { nodeSchema } from 'src/assets/NodeSchema';
import { DataModelDimension } from 'src/common/hooks/fetching/useGlobalFiltersApi';
import { NOT_AVAILABLE_VALUE_STRING } from 'src/common/utils/consts';
import { capitalizedFirstLetter, spaceBeforeCapitalLetters } from 'src/common/utils/format';
import { textSorting } from 'src/common/utils/sort';
import { FiltersAndBreakdownItemType } from '../types';
import { NodeSchemaCard } from './types';

const charsOnlyRegExp = /[(a-z) (A-Z) (0-9)]/g;
const relationshipSignRegExp = /[<>]/g;

export const getSchemaCardsByType = (type: string): NodeSchemaCard[] => {
	try {
		return nodeSchema.nodes[type as keyof typeof nodeSchema.nodes].cards as unknown as NodeSchemaCard[];
	} catch {
		return [];
	}
};

export const getDimensionNameByKey = (key: string, getDisplayName = false) => {
	if (!key) {
		return '';
	}

	const [node, label] = key.split('.');
	for (const card of getSchemaCardsByType(node)) {
		const property = card.properties.find((p) => !p.relationship && p.field === label);
		if (property) return getDisplayName ? property.display_name : property.field;
	}

	return label;
};

export const getRelationshipNameByKey = (key: string) => {
	const keyParts = key.split('.');
	const lastRelationshipKey = keyParts.reverse().find((e) => e.includes('>')); // reversing here because of: https://github.com/microsoft/TypeScript/issues/48829
	if (!lastRelationshipKey) return '';

	keyParts.reverse(); // and reversing again, to restore the old order

	for (const card of getSchemaCardsByType(keyParts[0])) {
		const property = card.properties.find((p) => p.relationship && p.relationship === lastRelationshipKey);
		if (property) return property.display_name;
	}

	return key;
};

export function getBreadcrumbs(
	key: string,
	options: { onlyIncludeLastRelationship?: boolean } = { onlyIncludeLastRelationship: false }
): string[] {
	const pathParts = key.split('.');
	const objectName = pathParts[0];
	const dimensionName = getDimensionNameByKey(
		`${pathParts[pathParts.length - 2]}.${pathParts[pathParts.length - 1]}`,
		true
	);
	const relationships = [];
	const relationshipQueryStart = options.onlyIncludeLastRelationship ? Math.max(pathParts.length - 2, 1) : 1;

	for (let i = relationshipQueryStart; i < pathParts.length - 1; i++) {
		const previousObjectName = pathParts[i - 1].split('>').reverse()[0];
		relationships.push(getRelationshipNameByKey(`${previousObjectName}.${pathParts[i]}`));
	}

	return [objectName, ...relationships, dimensionName];
}

export const parseRelationships = (
	relationships: string[],
	type: string,
	shouldIncludeNotNormalizedProps?: boolean
): FiltersAndBreakdownItemType[] => {
	const elements: FiltersAndBreakdownItemType[] = [];
	const cardElements = getSchemaCardsByType(type);
	let nonNormalizedRelationships = [...relationships];

	for (const card of cardElements) {
		for (const relationship of relationships) {
			for (const property of card.properties) {
				if (property.relationship === relationship) {
					elements.push({
						type: 'relationship',
						key: property.relationship || '',
						label: `${property.display_name}`,
					});

					if (shouldIncludeNotNormalizedProps) {
						nonNormalizedRelationships = nonNormalizedRelationships.filter(
							(relationship: string) => property.relationship !== relationship
						);
					}
				}
			}
		}
	}

	if (shouldIncludeNotNormalizedProps && nonNormalizedRelationships.length) {
		for (const relationship of nonNormalizedRelationships) {
			elements.push({
				type: 'relationship',
				key: relationship || '',
				label: `${formatNonNormalizedRelationship(relationship)}`,
			});
		}
	}

	return elements;
};

export const parseGlobalRelationships = ({
	relationships,
	type,
	shouldIncludeNotNormalizedProps,
	isIncludingTypePrefix: includeTypePrefix,
}: {
	relationships: DataModelDimension[];
	type: string;
	shouldIncludeNotNormalizedProps?: boolean;
	isIncludingTypePrefix: boolean;
}): FiltersAndBreakdownItemType[] => {
	const elements: FiltersAndBreakdownItemType[] = [];
	const cardElements = getSchemaCardsByType(type);
	let nonNormalizedRelationships = [...relationships];

	for (const card of cardElements) {
		for (const relationship of relationships) {
			for (const property of card.properties) {
				if (property.relationship === relationship.dimension) {
					elements.push({
						type: 'relationship',
						key: includeTypePrefix ? `${type}.${property.relationship || ''}` : property.relationship,
						label: `${property.display_name}`,
					});

					if (shouldIncludeNotNormalizedProps) {
						nonNormalizedRelationships = nonNormalizedRelationships.filter(
							(relationship: DataModelDimension) => property.relationship !== relationship.dimension
						);
					}
				}
			}
		}
	}

	if (shouldIncludeNotNormalizedProps && nonNormalizedRelationships.length) {
		for (const relationship of nonNormalizedRelationships) {
			elements.push({
				type: 'relationship',
				key: includeTypePrefix ? `${type}.${relationship.dimension || ''}` : relationship.dimension,
				label: `${formatNonNormalizedRelationship(relationship.dimension)}`,
			});
		}
	}

	return elements;
};

export const formatNonNormalizedRelationship = (relationship: string) => {
	const notValidValue = !relationship || typeof relationship !== 'string';

	if (notValidValue || !relationship.match(relationshipSignRegExp)) {
		return relationship;
	}

	return spaceBeforeCapitalLetters(relationship.split(relationshipSignRegExp)[0]);
};

export const formatDimensionLabel = (label: string, datasourceName = 'source'): string => {
	if (!label || typeof label !== 'string') {
		return '';
	}

	const isNormalizedLabel = !label.startsWith('_');
	const regExpRes = label.replaceAll('_', ' ').trim().match(charsOnlyRegExp);

	const formattedLabel = capitalizedFirstLetter(spaceBeforeCapitalLetters(regExpRes?.join('') || ''));

	if (isNormalizedLabel) {
		return formattedLabel;
	}

	return `${formattedLabel} (${datasourceName})`;
};

export const parseDimensions = (dimensions: Record<string, any>): FiltersAndBreakdownItemType[] =>
	Object.keys(dimensions || {}).map(
		(dimension: string): FiltersAndBreakdownItemType => ({
			key: dimension,
			label: formatDimensionLabel(dimension),
			type: 'dimension',
		})
	);

export const parseGlobalDimensions = ({
	dimensions,
	shouldIncludeNotNormalizedProps,
	isIncludingTypePrefix: includeTypePrefix,
}: {
	dimensions: DataModelDimension[];
	shouldIncludeNotNormalizedProps: boolean;
	isIncludingTypePrefix: boolean;
}): FiltersAndBreakdownItemType[] =>
	dimensions
		.filter((d) => !d.is_deleted)
		.filter((d) => shouldIncludeNotNormalizedProps || d.is_normalized)
		.map(
			({ dimension, object }): FiltersAndBreakdownItemType => ({
				key: includeTypePrefix ? `${object}.${dimension}` : dimension,
				label: `${formatDimensionLabel(dimension)}`,
				type: 'dimension',
			})
		);

export const sortElements = (elements: FiltersAndBreakdownItemType[]) =>
	elements.sort((a, b) => textSorting(b.label, a.label));

export const normalizeValues = (
	values: (string | boolean | null)[],
	isExcludingNullValues = false
): FiltersAndBreakdownItemType[] =>
	(values || [])
		.filter((value) => !isExcludingNullValues || value != null)
		.map((value) => ({
			isSelectable: true,
			isSelected: false,
			value: value ?? undefined,
			key: `${value}`,
			label: `${value ?? NOT_AVAILABLE_VALUE_STRING}`,
		}));

export function getFirstObjectType(key: string) {
	const firstSegment = key.split('.')[0];
	return firstSegment.startsWith('$') ? firstSegment.substring(1) : firstSegment;
}
