import {
	Box,
	Input as ChakraInput,
	InputGroup as ChakraInputGroup,
	InputLeftElement,
	InputRightElement,
} from '@chakra-ui/react';
import * as CSS from 'csstype';
import { LegacyRef, useState, RefObject } from 'react';
import { Search16, SearchSmall16 } from '@icons/index';
import Typography from '@components/Typography';
import borderRadiuses from 'src/style/borderRadiuses';
import colors from 'src/style/colors';
import useDebouncedCallback from 'src/common/hooks/useDebouncedCallback';

export type SearchProps = {
	initialValue?: string;
	placeholder?: string;
	width?: CSS.Property.Width;
	onChange: (newSearchTerm: string) => void;
	isTransparent?: boolean;
	isAutoFocused?: boolean;
	isDeBounced?: boolean;
	height?: string;
	fontSize?: string;
	initialFocusRef?: RefObject<{ focus(): void }>;
};

export default function Search({
	initialValue = '',
	placeholder = '',
	width,
	onChange,
	isTransparent = false,
	isAutoFocused = false,
	isDeBounced = true,
	height = '48px',
	fontSize = '16px',
	initialFocusRef,
}: SearchProps) {
	const [isActive, setIsActive] = useState(false);
	const borderColor = isTransparent ? 'transparent' : isActive ? colors.gray[800] : colors.gray[400];
	const debouncedValueUpdate = useDebouncedCallback((value: string) => onChange(value), 500);

	return (
		<ChakraInputGroup
			display="flex"
			alignItems="center"
			h={height}
			minW={'min-content'}
			w={width}
			p={`8px 8px 8px ${isTransparent ? 28 : 24}px`}
			border="1px"
			borderColor={borderColor}
			borderRadius="32px"
			_hover={{ borderColor: isTransparent ? 'transparent' : 'gray.500' }}
		>
			{isTransparent && (
				<InputLeftElement top={'50%'} transform={'translateY(-50%)'} h="min-content" w="min-content">
					<Box color="gray.600">
						<Search16 />
					</Box>
				</InputLeftElement>
			)}
			<Typography variant="Paragraph14R" width={'100%'}>
				<ChakraInput
					ref={initialFocusRef as LegacyRef<HTMLInputElement>}
					autoFocus={isAutoFocused}
					onFocus={() => setIsActive(true)}
					onBlur={() => setIsActive(false)}
					ml="0"
					pl="0"
					border="none"
					defaultValue={initialValue}
					focusBorderColor="none"
					onChange={(e) => {
						if (isDeBounced) {
							debouncedValueUpdate(e.target.value);
						} else {
							onChange(e.target.value);
						}
					}}
					placeholder={placeholder}
					color={isTransparent ? 'gray.1100' : 'inherit'}
					_hover={{ _placeholder: { color: isTransparent && 'gray.700' } }}
					_focus={{ _placeholder: { color: isTransparent && 'gray.600' } }}
					_focusVisible={{ _placeholder: { color: isTransparent && 'gray.600' } }}
					_placeholder={{ color: 'gray.600' }}
					fontSize={fontSize}
					onClick={(e) => {
						e.stopPropagation();
					}}
				/>
			</Typography>
			{!isTransparent && (
				<InputRightElement
					w="min-content"
					h="min-content"
					display="flex"
					alignItems="center"
					justifyContent="center"
					mr="8px"
					mt="8px"
					mb="8px"
				>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						w="32px"
						h="32px"
						bgColor="blue.700"
						borderRadius={borderRadiuses.round}
						_hover={{ bg: 'blue.600' }}
					>
						<SearchSmall16 color="white" />
					</Box>
				</InputRightElement>
			)}
		</ChakraInputGroup>
	);
}
