import { CircularProgress } from '@chakra-ui/react';

interface SpinnerProps {
	size?: string | number | undefined;
	color?: string;
	trackColor?: string;
	thickness?: string;
}

function Spinner({ size = '40px', color = 'blue.700', trackColor = 'transparent', thickness = '10px' }: SpinnerProps) {
	return (
		<CircularProgress
			thickness={thickness}
			size={size}
			isIndeterminate
			color={color}
			capIsRound
			trackColor={trackColor}
		/>
	);
}

export default Spinner;
