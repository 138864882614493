import { Abc16, Calendar216, HashMark16 } from '@icons/index';
import { DimensionType } from 'src/pages/MetricPage/utils/state.types';

export function IconByType({ dimensionType }: { dimensionType: DimensionType }) {
	switch (dimensionType) {
		case 'text':
			return <Abc16 />;
		case 'date':
			return <Calendar216 />;
		case 'number':
			return <HashMark16 />;
	}
}

export const shouldLegendPanelExpand = () => window.innerWidth >= 899;
