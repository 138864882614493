import usePrivateSearchParams from '@hooks/navigation/usePrivateSearchParams';
import { useCallback } from 'react';

export const EmbedUrlParam = 'embed' as const;
export type EmbedMode = 'widget' | 'full' | null;

function IsEmbedSetting(embed: string | null): embed is EmbedMode {
	return embed === 'widget' || embed === 'full' || embed === null;
}

function useEmbedMode(): [EmbedMode, (newEmbedSetting: EmbedMode) => void] {
	const [{ [EmbedUrlParam]: embed }, setAppPrivateSearchParam] = usePrivateSearchParams();

	const setEmbedSetting = useCallback(
		(newTenant: string | null) => {
			setAppPrivateSearchParam(EmbedUrlParam, newTenant);
		},
		[setAppPrivateSearchParam]
	);

	if (!IsEmbedSetting(embed)) {
		return [null, setEmbedSetting];
	}

	return [embed, setEmbedSetting];
}

export default useEmbedMode;
