export type EnvironmentStore = {
	setEnvironment: (env: EnvironmentState) => void;
	environment: EnvironmentState;
};

export type EnvironmentState = {
	tenant: number;
	availableTenants?: { id: string; name: string; isReadAllowed: boolean }[];
	role: string;
	stamp: string;
	isSecure: boolean;
	permissions: Permission[];
	feRoles: string[];
	isMultiTenant: boolean;
	activeRole: string;
};

type InnerEnvironmentConfig = {
	wsGqlUrl: string;
	httpGqlUrl: string;
	apolloRouterGqlUrl: string;
	apiUrl: string;
};

export type EnvironmentConfig = EnvironmentState & InnerEnvironmentConfig;

export const Permissions = {
	hasuraSuperuser: 'sf.hasura.role.superuser',
	hasuraUser: 'sf.hasura.role.user',
	createCollection: 'sf.collection.create',
	deleteCollection: 'sf.collection.delete',
	updateCollection: 'sf.collection.update',
	createUserApiToken: 'fe.secure.write.userApiTokens',
	updateConfiguration: 'sf.configuration.update',
	readConfiguration: 'sf.configuration.read',
	crudMySignals: 'sf.signal.own.*',
	updateAllSignals: 'sf.signal.all.update',
	readAllSignals: 'sf.signal.all.read',
	crudMyComments: 'sf.comment.own.*',
	deleteAllComments: 'sf.comment.all.delete',
	readAllComments: 'sf.comment.all.read',
	overrideEdit: 'sf.data.override.create',
	overrideReset: 'sf.data.override.delete',
	overrideRead: 'sf.data.override.read',
	crudOverride: 'sf.data.override.*',
	readEntities: 'sf.ontology.read',
	writeEntities: 'sf.ontology.write',
	deleteEntities: 'sf.ontology.delete',
	writeMetric: 'sf.metric.write',
	deleteMetric: 'sf.metric.delete',
} as const;
type PermissionKey = keyof typeof Permissions;
export type Permission = typeof Permissions[PermissionKey];

export const DATA_PERMISSIONS = [Permissions.hasuraSuperuser, Permissions.hasuraUser] as const;
