import { useCallback, useMemo } from 'react';
import { Parameter } from '../types/parameter';
import { useMetricDerivedState } from '../pages/MetricPage/hooks/useMetricDerivedState';

export function useParameters() {
	const { parameters } = useMetricDerivedState();

	const parametersMap = useMemo(() => {
		return parameters.reduce((out, param) => {
			out.set(param.definition.name, param);
			return out;
		}, new Map<string, Parameter>());
	}, [parameters]);

	const getParameterDisplayName = useCallback(
		(name: string) => {
			const parameter = parametersMap.get(name);
			if (!parameter) return;
			return parameter.definition.label ?? parameter.definition.name;
		},
		[parametersMap]
	);

	const getParameterDisplayValue = useCallback(
		(name: string, value?: string) => {
			const parameter = parametersMap.get(name);
			if (!parameter) return value;
			const paramValue = value ?? parameter.value ?? parameter.definition.default;
			const { values } = parameter.definition;
			if (values?.__typename === 'ListValues') {
				const valueInList = values.listValues.find((val) => val.value === paramValue);
				if (valueInList) {
					return valueInList.label;
				}
			}

			return paramValue;
		},
		[parametersMap]
	);

	const getParameterDisplayNameWithValue = useCallback(
		(name: string, value?: string) => {
			return `${getParameterDisplayName(name)} = ${getParameterDisplayValue(name, value)}`;
		},
		[getParameterDisplayName, getParameterDisplayValue]
	);

	return { getParameterDisplayValue, getParameterDisplayName, getParameterDisplayNameWithValue, parametersMap };
}
