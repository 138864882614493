import { Flex } from '@chakra-ui/react';
import Button from 'src/common/components/Button';
import { ChevronLeftSolid16, CloseLarge16, SightfullAIChatLogo } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import colors from 'src/style/colors';

export function Header({ onCloseClick, onBackClick }: { onCloseClick: () => void; onBackClick?: () => void }) {
	return (
		<Flex
			borderTopRadius={'4px'}
			background="white"
			padding="12px"
			flexGrow="1"
			alignItems={'center'}
			justifyContent={'space-between'}
			shadow="0px -1px 0px 0px #E9EFF6 inset"
		>
			<Flex direction="row" alignItems={'center'} gap="8px">
				{onBackClick && (
					<Button size="xs" variant="ghost" border="none" isIconOnly onClick={onBackClick} colorScheme="lightGray">
						<ChevronLeftSolid16 />
					</Button>
				)}
				<SightfullAIChatLogo />
				<Flex
					borderRadius="2px"
					boxSizing="border-box"
					background={colors.gray[200]}
					padding="2px 8px"
					alignItems="center"
					justifyContent="center"
				>
					<Typography color={colors.gray[700]} variant="Paragraph12M">
						Beta
					</Typography>
				</Flex>
			</Flex>
			<Button size="xs" variant="ghost" border="none" colorScheme="lightGray" isIconOnly onClick={onCloseClick}>
				<CloseLarge16 />
			</Button>
		</Flex>
	);
}
