import { Box, Grid } from '@chakra-ui/react';
import * as CSS from 'csstype';
import { ReactNode } from 'react';

export function ResponsiveGrid({ children, height }: { children: ReactNode; height?: CSS.Property.Height }) {
	return (
		<Box marginX={{ base: '24px', sm: '32px', lg: '0px' }} height={height}>
			<Grid
				gridTemplateColumns={{
					base: 'repeat(2, 1fr [col-start])',
					xs: 'repeat(12, 1fr [col-start])',
					lg: 'repeat(12, 82px [col-start])',
				}}
				columnGap={{ base: '12px', sm: '16px', lg: '32px' }}
				justifyContent={{ base: 'stretch', lg: 'center' }}
				width="100%"
				height={height}
			>
				{children}
			</Grid>
		</Box>
	);
}
