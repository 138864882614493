import Box from '@components/Box';
import Typography from '@components/Typography';
import React from 'react';

import classes from './MenuIndicator.module.scss';

export type MenuIndicatorProps = {
	text: string;
	testId?: string;
	isSelected?: boolean;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	dataIntercomArea?: string;
	dataIntercomSequence?: number;
};

export function MenuIndicator({
	text,
	testId,
	isSelected = false,
	onClick,
	dataIntercomArea,
	dataIntercomSequence,
}: MenuIndicatorProps) {
	const indicatorTestId = `${testId}_indicator`;

	return (
		<Box
			className={`${classes.menuIndicator} ${isSelected ? classes.selected : ''}`}
			data-testid={testId}
			onClick={onClick}
			data-intercom-area={dataIntercomArea}
			data-intercom-type={'button'}
			data-intercom-target={text}
			data-intercom-sequence={dataIntercomSequence}
		>
			<Box className={classes.selectedIndicator} data-testid={indicatorTestId} />
			<Typography className={classes.text} mb="2px" variant="Paragraph14R">
				{text}
			</Typography>
		</Box>
	);
}
