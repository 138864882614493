import Box from '@components/Box';
import Divider from '@components/Divider';
import { ParameterDrilldownCard } from '@components/LeftExpandCollapsePanel/Drilldown';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { Title } from '../PanelTitle/Title';

function DividerLine({ isShowingFullSize }: { isShowingFullSize?: boolean }) {
	return (
		<Box paddingY={isShowingFullSize ? '24px' : '8px'}>
			<Divider direction={'horizontal'}></Divider>
		</Box>
	);
}

export function ParametersPanel({
	isShowingFullSize,
	isDisabled,
	divider,
}: {
	isShowingFullSize?: boolean;
	isDisabled?: boolean;
	divider: 'top' | 'bottom';
}) {
	const { parameters } = useMetricDerivedState();

	if (isDisabled || parameters.length === 0) return null;

	return (
		<>
			{divider === 'top' && <DividerLine isShowingFullSize={isShowingFullSize} />}
			<Box whiteSpace="nowrap" height={isShowingFullSize ? 'min-content' : '0'} opacity={isShowingFullSize ? '1' : '0'}>
				<Title text={'Parameters'} />
				{parameters?.map((parameter) => (
					<ParameterDrilldownCard key={parameter.definition.name} parameter={parameter} />
				))}
			</Box>
			{divider === 'bottom' && <DividerLine isShowingFullSize={isShowingFullSize} />}
		</>
	);
}
