import { ApolloError } from '@apollo/client';
import { WorkspaceGetSingleQuery } from 'src/generated/graphql';
import { WorkspaceGetSingle, WorkspaceGetSingleByOrder } from 'src/queries/workspaces';
import useUser from '../stores/useUser';
import { useHasuraSubscriptionWithCache } from './useSubscription';

export function useGetWorkspacesSubscription({
	id,
}: {
	id: string;
}): [WorkspaceGetSingleQuery | undefined, ApolloError | undefined, boolean] {
	const [{ id: my_id }] = useUser();
	const document = id ? WorkspaceGetSingle : WorkspaceGetSingleByOrder;
	const variables = id ? { id, my_id } : { offset: 0, my_id };
	const { data, error, loading } = useHasuraSubscriptionWithCache(document, { variables });

	return [data, error, loading];
}
