import Flex from '@components/Flex';
import Divider from 'src/common/components/Divider';
import useMutation from 'src/common/hooks/fetching/useMutation';
import { DeleteDashboardFeedSignal } from 'src/queries/dashboards';
import { useReportEvent } from 'src/services/analytics';
import { FeedSignal } from 'src/types/spaces';
import { Widget, WidgetProps } from '../Widget';
import { DividerWidgetHeader } from './DividerWidgetHeader';

export type DashboardDividerWidgetProps = {
	feedSignal: FeedSignal;
} & Pick<WidgetProps, 'isEditMode'>;
export function DashboardDividerWidget({ feedSignal, isEditMode }: DashboardDividerWidgetProps) {
	const { reportEvent } = useReportEvent();
	const [deleteSelf, { loading: isDeletionLoading }] = useMutation(DeleteDashboardFeedSignal);

	const removeWidget = () => {
		reportEvent({
			event: 'signal-three-dots-menu-remove-from-workspace',
			metaData: { 'widget-type': 'divider', 'collection-type': 'dashboard' },
		});
		deleteSelf({
			variables: {
				feed_id: feedSignal.feed_id,
				signal_id: feedSignal.signal_id,
			},
		});
	};

	return (
		<Widget isEditMode={isEditMode} hasBorder={false}>
			<Flex direction="column" justifyContent={'center'} height="100%">
				{isEditMode ? (
					<DividerWidgetHeader
						isEditMode={isEditMode}
						onRemoveClicked={removeWidget}
						isLoading={isDeletionLoading}
						feedSignal={feedSignal}
					/>
				) : (
					<Divider direction={'horizontal'} mt="16px" mb="16px" />
				)}
			</Flex>
		</Widget>
	);
}
