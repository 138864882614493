import { MetricPeriod } from '@sightfull/period-ranges';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { getExportAPIUrl } from 'src/lib/api/export';
import { convertFiltersToRawDimensionsDict, MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export function buildExportLiveUrlFromUrl(
	metricPageSearchParams: {
		metricNameWithFlavor: string;
		searchParams: MetricSearchParams;
	},
	apiUrl: string
): string | null {
	const {
		metricNameWithFlavor,
		searchParams: { from, to, groupBy, filterBy, selectedPeriod, selectedXAxisElements, collectProps },
	} = metricPageSearchParams;

	const isSelected = !!selectedPeriod || !!selectedXAxisElements;
	const collectPropsText = Object.keys(collectProps ?? {}).join(',');
	const expandedGroupBy = !isSelected ? Object.keys(groupBy ?? {}) : ['id', 'Name', ...Object.keys(groupBy ?? {})];
	const [filterByText, groupByText] = [JSON.stringify(filterBy ?? {}), expandedGroupBy.join(',')];

	if (!from) return null;

	const baseApiRequestUrl = getExportAPIUrl({
		apiUrl,
		metricNameWithFlavor,
		from: from,
		to: to,
		groupByText,
		filterByText,
		collectPropsText,
	});
	return baseApiRequestUrl;
}

export function buildExportLiveUrlFromState(
	{
		periodRange,
		breakdowns,
		filters,
		collectedProps,
		tableType,
		metricNameWithFlavor,
		selectedXAxisElements,
	}: Pick<
		MetricDerivedState,
		| 'periodRange'
		| 'breakdowns'
		| 'filters'
		| 'collectedProps'
		| 'tableType'
		| 'metricNameWithFlavor'
		| 'selectedXAxisElements'
	>,
	apiUrl: string
): string {
	const shouldAddIdNameBreakdowns = tableType == 'MultiPeriodDetailedTable' || tableType == 'DetailedTable';
	const breakdownsKeys = [...(shouldAddIdNameBreakdowns ? ['id', 'Name'] : []), ...breakdowns.values.map((b) => b.key)];
	const rawFilters = convertFiltersToRawDimensionsDict(filters);
	const absolutePeriodRange = periodRange.asAbsoluteRange;

	const currentSelectedPeriod =
		selectedXAxisElements.length == 1 && selectedXAxisElements[0] instanceof MetricPeriod
			? selectedXAxisElements[0]
			: undefined;

	const [from, to] = currentSelectedPeriod
		? [currentSelectedPeriod.id, currentSelectedPeriod.id]
		: [absolutePeriodRange.startPeriod.id, absolutePeriodRange.endPeriod.id];

	return getExportAPIUrl({
		apiUrl,
		from,
		to,
		collectPropsText: collectedProps.join(','),
		groupByText: breakdownsKeys.join(','),
		filterByText: JSON.stringify(rawFilters),
		metricNameWithFlavor,
	});
}

export function buildGoogleSheetsFormula({
	shouldUseNameRanges,
	baseApiRequestUrl,
}: {
	shouldUseNameRanges: boolean;
	baseApiRequestUrl: string;
}) {
	if (shouldUseNameRanges) {
		const concatenate = `CONCATENATE("${baseApiRequestUrl}","&clientId=",IFERROR(ClientId, "[ClientID]"),"&secret=", IFERROR(SecretKey, "[SecretKey]"))`;
		return `=IMPORTDATA(${concatenate})`;
	} else {
		return `=IMPORTDATA("${baseApiRequestUrl}&clientId=[ClientID]&secret=[SecretKey]")`;
	}
}

export function buildExcelPowerQueryFile(baseApiRequestUrl: string) {
	return `let
	Source = Csv.Document(Web.Contents("${baseApiRequestUrl}")),
	#"Promoted headers" = Table.PromoteHeaders(Source, [PromoteAllScalars = true])
in
	#"Promoted headers"`;
}
