import { DisplayUnitType } from '../utils/state.types';
import { useMetricDerivedState } from './useMetricDerivedState';
import { useMetricPageSearchParams } from './useMetricPageSearchParams';
import xor from 'lodash/xor';
import { getCheckedDisplayUnits } from 'src/lib/metricRules/utils';

export function useDisplayUnits() {
	const { displayUnits } = useMetricDerivedState();
	const { setPartialSearchParams } = useMetricPageSearchParams();
	const toggleDisplayUnit = (displayUnit: DisplayUnitType) => {
		setPartialSearchParams({ displayUnits: xor(getCheckedDisplayUnits(displayUnits), [displayUnit]) });
	};

	return [displayUnits, toggleDisplayUnit] as const;
}
