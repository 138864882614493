import { Flex } from '@chakra-ui/react';
import Typography from '../Typography';
import { ReactNode } from 'react';

type BrokenThumbnailProps = {
	icon: ReactNode;
	title: string;
	subtitle: string;
};

export function BrokenThumbnail({ icon, title, subtitle }: BrokenThumbnailProps) {
	return (
		<Flex padding={'16px'}>
			<Flex alignItems={'center'}>
				<Flex flexDirection={'column'} alignItems={'center'} gap={'8px'}>
					<Flex alignItems={'center'}>{icon}</Flex>
					<Flex gap={'4px'} flexDirection={'column'} alignItems={'center'}>
						<Typography variant={'DesktopH8Medium'} color={'gray.700'} textAlign={'center'}>
							{title}
						</Typography>
						<Typography variant={'DesktopH10Regular'} color={'gray.600'} textAlign={'center'}>
							{subtitle}
						</Typography>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
}
