import { CoreReaderFiltersCallback } from 'src/common/hooks/fetching/useCoreReaderFiltersApi';
import { CalculateMetricCoreReaderQuery } from 'src/generated/graphql';
import { removeDollarSigns } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { getFirstObjectType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/utils';

import { Filter, MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export type FilterInput = { key: string; values: string[] };

export async function calcCoreValidFilters(
	filtersFromQuery: NonNullable<CalculateMetricCoreReaderQuery['calcMetricV2']['chartOptions']>['filters'],
	filtersInput: FilterInput[],
	{ objectsTypes }: Pick<MetricDerivedState, 'objectsTypes'>,
	filterOptionsQuery: CoreReaderFiltersCallback
): Promise<Pick<MetricDerivedState, 'filters'>> {
	if (!filtersInput || !objectsTypes) return { filters: [] };

	const filterOptions = (await filterOptionsQuery(objectsTypes)) || [];

	const filterDimensions = filterOptions.flatMap((option) => {
		return [
			...option.dimensions.map((dimension) => {
				return removeDollarSigns(dimension.identifier);
			}),
		];
	});

	const filterRelationshipsIndentifiers = filterOptions.flatMap((option) => {
		return [
			...option.relationships.map((relationship) => {
				return removeDollarSigns(relationship.identifier);
			}),
		];
	});

	const filterIdentifiers = [...filterDimensions, ...filterRelationshipsIndentifiers];
	const isFilterObjectValid = ({ key }: { key: string }) => filterIdentifiers.includes(getFirstObjectType(key));

	const filters = filtersInput.filter(isFilterObjectValid).map(
		({ key, values }: FilterInput) =>
			({
				key,
				label: filtersFromQuery.find((filterFromQuery) => filterFromQuery.key == key)?.label ?? key,
				values,
				type: 'text',
			} as Filter)
	);

	return { filters };
}
