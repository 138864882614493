import { IField, IFields } from '../interfaces';

export const getIsDisabledPerField = (
	fields: IFields,
	values: { [key: string]: Partial<IField> }
): { [key: string]: boolean } => {
	return Object.entries(fields).reduce((acc, [key, fieldData]) => {
		const isDisabled = fieldData?.disabledOn
			? Object.entries(fieldData.disabledOn).some(([fieldName, { selectedValue }]) => {
					const curSeletedValue = values[fieldName]?.selectedValue ?? fields[fieldName]?.selectedValue;
					return curSeletedValue === selectedValue;
			  })
			: false;

		return { ...acc, [key]: isDisabled };
	}, {});
};
