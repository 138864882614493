import { useCallback, useMemo } from 'react';
import Box from 'src/common/components/Box';
import Card from 'src/common/components/Card';
import Flex from 'src/common/components/Flex';
import { Abc16, Calendar216, HashMark16 } from 'src/common/components/Icons';
import Popover from 'src/common/components/Popover';
import Typography from 'src/common/components/Typography';
import FiltersAndBreakdownsModal from 'src/pages/MetricPage/components/FiltersAndBreakdown/FiltersAndBreakdownsModal';
import { useGlobalNodeScheme } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useGlobalNodeScheme';
import { FiltersAndBreakdownResponseType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import useFiltersAndBreakdown, {
	FiltersAndBreakdownsActionsType,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/useFiltersAndBreakdown';
import { Filter } from 'src/pages/MetricPage/utils/state.types';
import { colors } from 'src/style/colors';
import { IField } from '../../interfaces';
import { IFieldValue, IModal } from '../../interfaces/fields';
import { ModalSingleValue } from '../../interfaces/types';
import { normalizeField } from '../../utilities';
import { getDimensionDetails } from '../../utilities/modalCommon';
import { ModalValuesLiner } from './ModalValuesLiner';

const dimensionTypeFrontendToBackend = {
	string: ['string'],
	number: ['int', 'float'],
	date: ['datetime'],
	boolean: ['bool'],
};

function useValues(normalizedField: IModal | null, value: IFieldValue, isSingleValueSelectable: boolean) {
	const values = useMemo(() => {
		if (!value || normalizedField?.modalType != 'values') {
			return undefined;
		}

		let values: ModalSingleValue[];
		if (isSingleValueSelectable) {
			values = [value] as ModalSingleValue[];
		} else {
			values = value as ModalSingleValue[];
		}

		return values.map((v: ModalSingleValue) => v.toString()).sort((v1: string, v2: string) => v1.localeCompare(v2));
	}, [isSingleValueSelectable, normalizedField?.modalType, value]);

	return values;
}

function parseSelectedValuesCallback(
	result: FiltersAndBreakdownResponseType,
	normalizedField: IModal | null,
	fieldKey: string,
	handleChange: (key: string, value: IFieldValue) => void,
	isSingleValueSelectable: boolean
) {
	let modalResult: IFieldValue;
	if (normalizedField?.modalType == 'dimension') {
		const dimensionParts = result.items?.[0].key.split('.');
		modalResult = dimensionParts[dimensionParts.length - 1];
	} else {
		const chosenValues: ModalSingleValue[] = result.items.map((item) => item?.value ?? '');
		if (isSingleValueSelectable) {
			modalResult = chosenValues[0];
		} else {
			modalResult = chosenValues;
		}
	}
	handleChange(fieldKey, modalResult);
}

function getDimensionDisplayName(normalizedField: IModal | null, dimensionLabel: string) {
	return normalizedField?.modalType == 'values' && normalizedField.dimensionDisplayName
		? normalizedField.dimensionDisplayName
		: dimensionLabel;
}

function getValuesIcon(dimensionType: string) {
	switch (dimensionType) {
		case 'boolean':
			return <Abc16 color={colors.blue[600]} />;
		case 'number':
			return <HashMark16 color={colors.blue[600]} />;
		case 'date':
			return <Calendar216 color={colors.blue[600]} />;
		default:
			return <Abc16 color={colors.blue[600]} />;
	}
}

function DimensionCard({
	normalizedField,
	value,
	isDisabled,
	isEditable,
	parseSelectedValues,
	modalActions,
	valuesIcon,
	dimensionDisplayName,
	isSingleValueSelectable,
}: {
	normalizedField: IModal | null;
	value: IFieldValue;
	isDisabled: boolean;
	isEditable: boolean;
	parseSelectedValues: (result: FiltersAndBreakdownResponseType) => void;
	modalActions: FiltersAndBreakdownsActionsType;
	valuesIcon: JSX.Element;
	dimensionDisplayName: string;
	isSingleValueSelectable: boolean;
}) {
	const values = useValues(normalizedField, value, isSingleValueSelectable);

	return (
		normalizedField && (
			<Card
				isDisabled={isDisabled}
				size="md"
				bgColor={isEditable ? undefined : 'gray.100'}
				onClick={
					isEditable
						? () => {
								return modalActions.onOpen(
									normalizedField.modalType == 'dimension' ? 'breakdown' : 'filter',
									parseSelectedValues
								);
						  }
						: undefined
				}
			>
				<Flex
					direction="column"
					maxWidth="100%"
					gap={normalizedField.modalType == 'values' && values?.length != 0 ? '4px' : '0'}
				>
					<Flex direction="row" gap="8px" alignItems="center">
						<Box>{valuesIcon}</Box>
						<Typography variant="DesktopH8Medium" noOfLines={1} wordBreak={'break-all'} _hover={{ cursor: 'pointer' }}>
							{dimensionDisplayName}
						</Typography>
					</Flex>
					{normalizedField.modalType == 'values' && (
						<ModalValuesLiner
							normalizedField={normalizedField}
							value={value}
							variant={'Paragraph12R'}
							maxWidth={'260px'}
						/>
					)}
				</Flex>
			</Card>
		)
	);
}

function DimensionCardTooltip({
	normalizedField,
	value,
	valuesIcon,
	dimensionDisplayName,
	isSingleValueSelectable,
}: {
	normalizedField: IModal | null;
	value: IFieldValue;
	valuesIcon: JSX.Element;
	dimensionDisplayName: string;
	isSingleValueSelectable: boolean;
}) {
	const values = useValues(normalizedField, value, isSingleValueSelectable);

	return (
		normalizedField && (
			<Flex direction="column" px="16px" pb="8px" width="300px">
				<Flex direction="row" py="12px" gap="8px" alignItems="center" alignSelf="stretch">
					{valuesIcon}
					<Typography variant="DesktopH8Medium" color="gray.800">
						{dimensionDisplayName}
					</Typography>
				</Flex>
				{normalizedField.modalType == 'values' && values && values.length > 0 && (
					<Flex py="8px" gap="4px" direction="column">
						<Typography variant="Paragraph12M" color="gray.600">
							Values
						</Typography>
						<Box backgroundColor="gray.200" px="12px" py="10px" borderRadius="2px">
							<Typography variant="Paragraph12R" color="gray.800">
								{values.map((v) => v.toString()).join(', ')}
							</Typography>
						</Box>
					</Flex>
				)}
				<Flex direction="column" py="8px" gap="4px" alignItems="flex-start" alignSelf="stretch">
					<Typography variant="Paragraph12M" color="gray.600">
						Object
					</Typography>
					<Typography variant="Paragraph12R" color="gray.800">
						{normalizedField.object}
					</Typography>
				</Flex>
			</Flex>
		)
	);
}

export function ModalField({
	field,
	value,
	fieldKey,
	isDisabled,
	isEditable,
	handleChange,
}: {
	field: IField;
	fieldKey: string;
	isDisabled: boolean;
	value: IFieldValue;
	isEditable: boolean;
	handleChange: (key: string, value: IFieldValue) => void;
}) {
	const normalizedField = normalizeField.modal(field);
	const isSingleValueSelectable = normalizedField?.isSingleValueSelectable ?? false;

	const [modalState, modalActions] = useFiltersAndBreakdown();
	const nodeScheme = useGlobalNodeScheme(
		[normalizedField?.object ?? ''],
		true,
		dimensionTypeFrontendToBackend[normalizedField?.dimensionType || 'string']
	);

	const parseSelectedValues = useCallback(
		(result: FiltersAndBreakdownResponseType) =>
			parseSelectedValuesCallback(result, normalizedField, fieldKey, handleChange, isSingleValueSelectable),
		[normalizedField, fieldKey, handleChange, isSingleValueSelectable]
	);

	const values = useValues(normalizedField, value, isSingleValueSelectable);

	const { dimensionKey, dimensionLabel } = getDimensionDetails(normalizedField, value.toString());
	const dimensionDisplayName = getDimensionDisplayName(normalizedField, dimensionLabel);

	const valuesIcon = useMemo(
		() => getValuesIcon(normalizedField?.dimensionType ?? ''),
		[normalizedField?.dimensionType]
	);

	const dimensionCard = useMemo(
		() => (
			<DimensionCard
				normalizedField={normalizedField}
				value={value}
				isDisabled={isDisabled}
				isEditable={isEditable}
				parseSelectedValues={parseSelectedValues}
				modalActions={modalActions}
				isSingleValueSelectable={isSingleValueSelectable}
				valuesIcon={valuesIcon}
				dimensionDisplayName={dimensionDisplayName}
			/>
		),
		[
			dimensionDisplayName,
			isDisabled,
			isEditable,
			modalActions,
			normalizedField,
			parseSelectedValues,
			value,
			valuesIcon,
			isSingleValueSelectable,
		]
	);

	if (!normalizedField) {
		return <></>;
	}

	return (
		<>
			{isDisabled ? (
				<span>{dimensionCard}</span>
			) : (
				<Popover triggerElement={dimensionCard} trigger="hover" placement="left">
					<DimensionCardTooltip
						normalizedField={normalizedField}
						value={value}
						valuesIcon={valuesIcon}
						dimensionDisplayName={dimensionDisplayName}
						isSingleValueSelectable={isSingleValueSelectable}
					/>
				</Popover>
			)}
			{isEditable && (
				<FiltersAndBreakdownsModal
					type={modalState.type}
					isOpen={modalState.isOpen}
					onClose={modalActions.onClose}
					onAddItems={modalActions.onAddItems}
					nodeScheme={nodeScheme}
					hasBaseNode={true}
					initialFilter={
						normalizedField.modalType == 'values'
							? ({
									label: dimensionDisplayName,
									key: dimensionKey,
									values: values,
							  } as Filter)
							: undefined
					}
					displayBackArrow={false}
					displayShowNormalizedSelection={normalizedField.modalType == 'dimension'}
					overridePickedName="value"
					overrideTitle={`Pick ${normalizedField.modalType == 'values' ? 'values' : 'dimension'}`}
					showRelationships={false}
					isSingleValueSelectable={isSingleValueSelectable}
				/>
			)}
		</>
	);
}
