import { mergeDeep } from '@apollo/client/utilities';
import { ChartType, SeriesDataPointObject } from '@components/Chart/types';
import { DataLabelsFormatterCallbackFunction, Point, PointLabelObject } from 'highcharts';
import { useEffect } from 'react';
import { TooltipProps } from 'react-jsx-highcharts';
import { NOT_AVAILABLE_VALUE_STRING } from 'src/common/utils/consts';
import { percentageFormatter } from 'src/lib/metricRules/valueFormatters';
import colors from 'src/style/colors';
import { MultiToolTipPositionType } from './Chart';

export const HIGHCHARTS_XAXIS_CLASSNAME = 'sf-highcharts-xaxis-0';

export function getDisplayUnitLabels(
	seriesData: Point & Pick<SeriesDataPointObject, 'custom'>,
	decimalDigits: number | undefined
): string[] {
	const displayUnitLabels = [];

	const count = seriesData?.custom?.count;
	if (count) {
		displayUnitLabels.push(`(N=${count})`);
	}

	const percentage = seriesData?.custom?.percentage;
	if (percentage) {
		displayUnitLabels.push(`(${percentageFormatter(percentage, decimalDigits)})`);
	}

	const percentagePrev = seriesData?.custom?.percentagePrev;
	if (percentagePrev) {
		displayUnitLabels.push(`(Prev: ${percentageFormatter(percentagePrev, decimalDigits)})`);
	}

	const percentageFirst = seriesData?.custom?.percentageFirst;
	if (percentageFirst) {
		displayUnitLabels.push(`(1st: ${percentageFormatter(percentageFirst, decimalDigits)})`);
	}
	return displayUnitLabels;
}

const labelFormatterBuilder: (
	formatOptions: 'key' | 'y' | 'pie',
	decimalDigits: number | undefined
) => DataLabelsFormatterCallbackFunction = function (formatOptions, decimalDigits) {
	return function (this: PointLabelObject) {
		if (this.point.shapeArgs && this.point.shapeArgs.height < 16) {
			return '';
		}
		const pointName = this.point.name;
		const seriesData = this.series?.data?.find((d) => d.name == pointName) as Point &
			Pick<SeriesDataPointObject, 'custom'>;
		const displayUnitLabels = getDisplayUnitLabels(seriesData, decimalDigits);

		const seriesDataPointYFormatter = this.series?.options?.custom?.seriesDataPointYFormatter;
		const rawY = this.y;
		const rawKey = (this.point as any)?.custom?.value;
		const formattedY = seriesDataPointYFormatter?.(rawY) ?? rawY;
		const formattedKey = seriesDataPointYFormatter?.(rawKey) ?? rawKey;
		const formattingByFormatOption: { [key in typeof formatOptions]: string } = {
			key: formattedKey,
			y: formattedY,
			pie: `${this.point.name}: ${formattedY}`,
		};

		return [formattingByFormatOption[formatOptions], ...displayUnitLabels].join('<br/>');
	};
};

export function useMetricChartGeneralConfig(
	{ onColumnClick }: { onColumnClick: (xIndex: number) => void },
	decimalDigits: number | undefined,
	chartType: ChartType,
	isEntityPage?: boolean | false,
	isTooltipEnabled?: boolean
) {
	return {
		chart: {
			style: {
				fontFamily: 'Inter, serif',
			},
			animation: {
				duration: 300,
			},
			alignTicks: true,
		},
		title: {
			// https://api.highcharts.com/highcharts/title
			text: undefined,
		},
		plotOptions: {
			series: {
				enableMouseTracking: isTooltipEnabled,
				stickyTracking: false,
				cursor: isEntityPage ? 'default' : 'pointer',
				animation: {
					duration: 100,
				},
				point: {
					events: {
						click: function (e: any) {
							onColumnClick(e.point.x);
						},
					},
				},
				dataLabels: {
					textOutline: 'none',
					enabled: true,
					allowOverlap: false,
					style: {
						color: '#fff',
						textOutline: '0px',
						fontSize: '12px',
						fontWeight: 'normal',
					},
					formatter: labelFormatterBuilder(chartType == 'pie' ? 'pie' : 'y', decimalDigits),
				},
				states: {
					hover: {
						enabled: false,
					},
				},
			},
			bubble: {
				maxSize: 45,
				dataLabels: {
					textOutline: 'none',
					enabled: true,
					formatter: labelFormatterBuilder('key', decimalDigits),
					color: colors.gray[1000],
				},
				animation: {
					defer: 150,
					duration: 300,
				},
				z: 100,
			},
		},
		dataLabels: {
			animation: {
				defer: 2000,
			},
		},
		accessibility: { enabled: false },
	};
}

export const tooltipConfig: TooltipProps = {
	hideDelay: 100,
	useHTML: true,
	shape: undefined,
	shadow: false,
	distance: 50,
	split: false,
	style: {
		color: colors.black,
		backgroundColor: 'none',
	},
	padding: 0,
	valueDecimals: 0,
	outside: false,
	borderColor: 'none',
	followPointer: false,
};

export function useXAxisConfig({
	onMouseOver,
	onMouseLeave,
	visible,
	chartRef,
}: {
	onMouseOver: (value: string, position: MultiToolTipPositionType) => void;
	onMouseLeave: () => void;
	visible: boolean;
	chartRef: React.RefObject<HTMLDivElement>;
}) {
	useEffect(() => {
		const customMouseOver = (e: MouseEvent) => {
			const element = e.currentTarget as HTMLElement;
			const elementText = element?.querySelector('title')?.textContent || element?.textContent;

			if (!(element instanceof SVGElement)) return;

			const rect = element.getBoundingClientRect();
			const position: MultiToolTipPositionType = {
				x: rect.right - 25,
				y: rect.bottom - 25,
			};
			element.style.fill = colors.gray[1000];

			onMouseOver(elementText ?? '', position);
		};

		const customMouseLeave = (e: MouseEvent) => {
			const element = e.currentTarget;
			if (!(element instanceof SVGElement)) return;

			element.style.fill = 'rgb(102, 102, 102)';
			if (e.relatedTarget instanceof HTMLDivElement && e.relatedTarget.id === '#multiToolTip') return;

			onMouseLeave();
		};

		if (!chartRef.current) return;

		const elements = chartRef.current.getElementsByClassName(`${HIGHCHARTS_XAXIS_CLASSNAME} highcharts-xaxis-labels`);
		if (elements.length === 0) return;
		if (elements.length > 1) {
			console.error('Multiple x-axis elements found');
		}
		const element = elements[0];
		if (!element || !(element instanceof SVGElement)) {
			console.error('x-axis element is not found');
			return;
		}

		element.childNodes.forEach((child) => {
			if (!(child instanceof SVGElement)) return;

			child.addEventListener('mouseover', customMouseOver);
			child.addEventListener('mouseleave', customMouseLeave);
		});

		return () => {
			element.childNodes.forEach((child) => {
				if (!(child instanceof SVGElement)) return;

				child.removeEventListener('mouseover', customMouseOver);
				child.removeEventListener('mouseleave', customMouseLeave);
			});
		};
	}, [onMouseOver, onMouseLeave, chartRef]);

	const xAxisConfig = {
		lineWidth: 0,
		className: HIGHCHARTS_XAXIS_CLASSNAME,
		labels: {
			style: {
				fontSize: '11px',
				lineHeight: '16px',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				pointerEvent: 'none',
			},
			autoRotation: undefined,
		},
		tickPosition: 'inside',
		visible,
	};

	return {
		xAxisConfig,
	};
}

const baseColumnConfig = {
	borderRadius: 4,
	borderWidth: 0,
	minPointLength: 5,
	dataLabels: {
		style: {
			textOutline: '0px',
			fontSize: '12px',
			fontWeight: 'normal',
		},
	},
};

const stackedColumnConfig = mergeDeep(baseColumnConfig, {
	stacking: 'normal',
	pointPadding: -0.3,
	groupPadding: 0.24,
	dataLabels: {
		enabled: true,
		style: {
			color: '#fff',
		},
	},
});

const columnConfig = mergeDeep(baseColumnConfig, {
	stacking: undefined,
	pointPadding: 0.1,
	groupPadding: 0.15,
	dataLabels: {
		enabled: true,
		style: {
			color: colors.gray['800'],
		},
	},
});

const waterfallConfig = {
	borderWidth: 0,
	dashStyle: 'dash',
	pointPadding: 0,
	minPointLength: 1,
};

const pieConfig = {
	clip: true,
	borderWidth: 0,
	animation: {
		duration: 0,
	},
	linecap: 'round',
	dataLabels: {
		connectorColor: '#c7c9d9',
		textOutline: 'none',
		enabled: true,
		style: {
			color: '#333',
			textOutline: '0px',
			fontSize: '12px',
			fontWeight: 'normal',
		},
		className: 'highcharts-datalabel-element',
		connectorShape: 'straight',
	},
	marker: {
		lineColor: colors.gray['800'],
	},
	states: {
		inactive: {
			animation: {
				duration: 0,
			},
			opacity: 0.3,
		},
		normal: {
			animation: false,
		},
		hover: {
			animation: {
				duration: 0,
			},
			brightness: 0,
			halo: {
				opacity: 1,
			},
		},
	},
};

const lineConfig = {
	lineWidth: 2,
	dashStyle: 'dash',
	dataLabels: {
		style: {
			color: colors.gray['800'],
			fontSize: '12px',
		},
	},
	marker: {
		symbol: 'circle',
		width: 6,
		height: 6,
		fillColor: '#fff',
		lineColor: undefined,
		lineWidth: 2,
	},
	states: {
		normal: {
			lineWidth: 1,
		},
	},
};

const attainmentConfig = {
	lineWidth: 0,
	dataLabels: {
		verticalAlign: 'middle',
		align: 'left',
		padding: 10,
		allowOverlap: false,
		formatter: function (this: PointLabelObject): any {
			if (this.point?.options?.custom?.label != undefined) {
				return this.point.options.custom.label;
			}
			return NOT_AVAILABLE_VALUE_STRING;
		},
		style: {
			color: colors.gray['800'],
			fontSize: '12px',
		},
	},
	marker: {
		symbol: 'circle',
		height: 12,
		fillColor: '#4A72FF',
		lineColor: '#4A72FF',
		lineWidth: 1,
	},
};

export const chartConfig = {
	pie: pieConfig,
	stackedColumn: stackedColumnConfig,
	column: columnConfig,
	waterfall: waterfallConfig,
	line: lineConfig,
	number: 'number',
	attainment: attainmentConfig,
};
