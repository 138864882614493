import Tooltip from '@components/Tooltip';
import React, { ReactNode } from 'react';

import Box from '@components/Box';
import * as CSS from 'csstype';
import classes from './NavigationItemFrame.module.scss';

export type NavigationItemFrameVariants = 'header' | 'footer' | 'avatar';

export interface NavigationItemFrameProps {
	testId?: string;
	icon: ReactNode;
	variant?: NavigationItemFrameVariants;
	isActive?: boolean;
	hasMarker?: boolean;
	tooltipLabel?: ReactNode;
	markAsHover?: boolean;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	padding?: CSS.Property.Padding;
	borderRadius?: CSS.Property.BorderRadius;
	dataIntercomArea?: string;
	dataIntercomType?: string;
	dataIntercomTarget?: string;
	hasExternalIconStyles?: boolean;
}

function NavigationItemFrame({
	testId,
	tooltipLabel,
	icon,
	variant = 'header',
	isActive = false,
	markAsHover = false,
	onClick,
	padding,
	borderRadius,
	dataIntercomArea,
	dataIntercomType,
	dataIntercomTarget,
	hasExternalIconStyles = false,
}: NavigationItemFrameProps) {
	const variantsToClassName = {
		header: classes.headerContainer,
		footer: classes.footerContainer,
		avatar: classes.avatarContainer,
	};
	const activeClass = isActive ? classes.active : '';
	const containerTestId = testId ? `${testId}-container` : null;
	const iconFrameClass = hasExternalIconStyles
		? ''
		: `${classes.container} ${variantsToClassName[variant]} ${activeClass} ${markAsHover && classes.forceHover}`;

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<Tooltip label={tooltipLabel} placement="bottom" size="md" marginTop="7px" hasArrow={false}>
			<Box
				data-testid={testId}
				className={classes.frame}
				onClick={onClick}
				role="button"
				tabIndex={0}
				data-intercom-area={dataIntercomArea}
				data-intercom-type={dataIntercomType}
				data-intercom-target={dataIntercomTarget}
			>
				<Box data-testid={containerTestId} padding={padding} className={iconFrameClass} borderRadius={borderRadius}>
					{icon}
				</Box>
			</Box>
		</Tooltip>
	);
}

export default NavigationItemFrame;
