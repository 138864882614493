import Box from '@components/Box';
import classes from './Card.module.scss';
import classNames from 'classnames';
import { MouseEventHandler } from 'react';

export type CardSize = 'sm' | 'md' | 'int' | 'lg';
export type CardProps = {
	children: React.ReactNode;
	size: CardSize;
	testId?: string;
	onClick?: MouseEventHandler<HTMLDivElement>;
	isDisabled?: boolean;
	bgColor?: string;
	radius?: number;
	padding?: number;
	noHoverEffects?: boolean;
};

export default function Card({
	children,
	size,
	onClick,
	testId,
	isDisabled = false,
	bgColor,
	radius = 4,
	padding,
	noHoverEffects,
}: CardProps) {
	const sizeClassNames = {
		sm: classes.cardSmall,
		md: classes.cardMiddle,
		int: classes.cardIntermediate,
		lg: classes.cardLarge,
	};
	const className = sizeClassNames[size];

	return (
		<Box
			borderRadius={radius}
			cursor={onClick && 'pointer'}
			onClick={onClick}
			backgroundColor={bgColor}
			data-testid={testId}
			className={classNames(classes.card, className, {
				[classes.noHoverEffects]: noHoverEffects,
			})}
			opacity={!isDisabled ? '100%' : '60%'}
			pointerEvents={!isDisabled ? 'auto' : 'none'}
			style={{ padding }}
		>
			{children}
		</Box>
	);
}
