import { Grid } from '@chakra-ui/react';
import Flex from '@components/Flex';
import { useOnOverflow } from '@hooks/ui/useOnOverflow';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DuplicateThin16 } from 'src/common/components/Icons';
import useTenantConfig from 'src/common/hooks/stores/useTenantConfig';
import useToast from 'src/common/hooks/ui/useToast';
import { formatValue } from 'src/common/utils/valueFormatting';
import { GetRecordProfileByIdQuery } from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import Button from '../../common/components/Button';
import Tooltip from '../../common/components/Tooltip';
import Typography from '../../common/components/Typography';
import { useEntityProfileDrawer, useEntityProfileReportEvent } from '../EntityProfileDrawer/hooks';
import { getRecordsEntityName } from './utils';
import { RecordProfileSectionHeader } from './RecordProfileSectionHeader';
import { RecordProfileRelationTableV2 } from './RecordProfileRelationTableV2';
import { RecordProfileRelationTable } from './RecordProfileRelationTable';
import { useIsGridTableV2Enabled } from '@components/GridTable';

const DIMENSIONS_PAGE_SIZE = 18;

type RecordProfileCellProps = {
	title: string;
	value: string;
	isLink: boolean;
	link?: string;
	isSource: boolean;
};

export function RecordProfileCell({
	title,
	value: rawValue,
	isLink: rawIsLink,
	link,
	isSource,
}: RecordProfileCellProps) {
	const tenantConfig = useTenantConfig();
	const value = formatValue(title, rawValue, tenantConfig);
	const toast = useToast();
	const { wrapWithReport } = useReportEvent();
	const { reportOpenEntityDrawer } = useEntityProfileReportEvent();
	const { pushEntity } = useEntityProfileDrawer();
	const [isCellHovered, setIsCellHovered] = useState(false);
	const titleRef = useRef<HTMLDivElement | null>(null);
	const valueRef = useRef<HTMLDivElement | null>(null);
	const isTitleOverflow = useOnOverflow(titleRef, [title], undefined);
	const isValueOverflow = useOnOverflow(valueRef, [value], undefined);
	const isLink = rawIsLink && !!link;

	const onClick = isLink
		? () => {
				reportOpenEntityDrawer({
					origin: 'record-profile-many-to-one',
					id: link,
					metaData: {
						fromCell: true,
					},
				});
				pushEntity(link);
		  }
		: undefined;

	return (
		<Flex
			flexDir={'column'}
			alignItems={'flex-start'}
			alignSelf={'stretch'}
			padding={'13px 15px 13px 15px'}
			borderRadius={'8px'}
			borderWidth={'1px'}
			borderStyle={'solid'}
			borderColor={'gray.300'}
			overflow={'hidden'}
			onMouseEnter={() => setIsCellHovered(true)}
			onMouseLeave={() => setIsCellHovered(false)}
		>
			<Flex width={'100%'} alignItems={'flex-start'} justifyContent={'space-between'} alignSelf={'strech'}>
				<Tooltip
					wrapperStyle={{ width: 'fit-content', maxHeight: '26px' }}
					label={isTitleOverflow && title}
					size="md"
					variant="primary"
					background="black"
					hasArrow
					placement="top"
					maxWidth={180}
				>
					<Typography noOfLines={1} wordBreak={'break-word'} ref={titleRef} variant="Paragraph12R" color={'gray.700'}>
						{`${title}${isSource ? ' (Source)' : ''}`}
					</Typography>
				</Tooltip>

				<Button
					isIconOnly
					opacity={isCellHovered ? 1 : 0}
					size="xxs"
					variant="outline"
					colorScheme="black"
					onClick={wrapWithReport(
						() => {
							navigator.clipboard.writeText(rawValue);
							toast({ variant: 'ok', message: 'Value copied to clipboard' });
						},
						'record-profile-click-copy-value',
						{ fieldCopied: title }
					)}
				>
					<DuplicateThin16 style={{ cursor: 'pointer' }} />
				</Button>
			</Flex>
			<Tooltip
				wrapperStyle={{ width: 'fit-content' }}
				label={isValueOverflow && value}
				size="md"
				variant="primary"
				background="black"
				hasArrow
				placement="top"
				maxWidth={180}
			>
				<Typography
					variant="DesktopH8Medium"
					as={isLink ? 'a' : 'span'}
					color={isLink ? 'blue.700' : 'gray.800'}
					cursor={isLink ? 'pointer' : 'auto'}
					_hover={isLink ? { color: 'blue.500' } : {}}
					noOfLines={2}
					wordBreak={'break-word'}
					ref={valueRef}
					onClick={onClick}
				>
					{value}
				</Typography>
			</Tooltip>
		</Flex>
	);
}

export type RecordProfileBodyProps = {
	recordProfile: GetRecordProfileByIdQuery['recordProfile'];
	searchValue: string;
};

export function RecordProfileBody({ recordProfile, searchValue }: RecordProfileBodyProps) {
	const isGridTableV2 = useIsGridTableV2Enabled();

	const [dimensionsOffset, setDimensionsOffset] = useState(0);
	const startDimensionsSlice = useMemo(() => {
		return dimensionsOffset * DIMENSIONS_PAGE_SIZE;
	}, [dimensionsOffset]);

	const dimensionsSortedAndFiltered = useMemo(
		() =>
			recordProfile.dimensions
				.filter((dimension) => JSON.stringify(dimension).toLowerCase().includes(searchValue.toLowerCase()))
				.sort((a, b) => {
					if (a.isSource == b.isSource) return (a.displayName ?? a.name).localeCompare(b.displayName ?? b.name);

					return a.isSource ? 1 : -1;
				}),
		[recordProfile.dimensions, searchValue]
	);
	useEffect(() => {
		if (dimensionsSortedAndFiltered.length < startDimensionsSlice) {
			setDimensionsOffset(0);
		}
	}, [dimensionsSortedAndFiltered.length, startDimensionsSlice]);

	return (
		<Flex paddingY={'20px'} paddingX={'24px'} flexDir={'column'} gap={'36px'} flex={1}>
			<Flex flexDir={'column'} alignItems={'flexStart'} gap={'12px'} alignSelf={'stretch'}>
				<RecordProfileSectionHeader
					suffix=""
					headerName={`${getRecordsEntityName(recordProfile)} details`}
					totalCount={dimensionsSortedAndFiltered.length}
					limit={DIMENSIONS_PAGE_SIZE}
					onPrevious={() => setDimensionsOffset(dimensionsOffset - 1)}
					onNext={() => setDimensionsOffset(dimensionsOffset + 1)}
				/>
				<Grid templateColumns="repeat(3, minmax(0, 1fr))" gridColumnGap={'8px'} gridRowGap={'8px'}>
					{dimensionsSortedAndFiltered
						.slice(startDimensionsSlice, startDimensionsSlice + DIMENSIONS_PAGE_SIZE)
						.map((dimension) => {
							return (
								<RecordProfileCell
									key={dimension.identifier}
									title={dimension.displayName ?? dimension.name}
									value={dimension.value}
									isSource={dimension.isSource}
									isLink={dimension.isLink}
									link={dimension.linkSightId ?? undefined}
								/>
							);
						})}
				</Grid>
			</Flex>
			<Flex flexDir={'column'} gap={'12px'} flex={1}>
				{recordProfile.relations
					.filter((relation) => relation.rows.length > 0)
					.map((relation) =>
						isGridTableV2 ? (
							<RecordProfileRelationTableV2
								key={relation.name}
								entityName={relation.relatedEntity}
								relationsRows={relation.rows}
								searchValue={searchValue}
								headerName={relation.displayName ?? relation.name}
								relationName={relation.name}
								currentRecordId={recordProfile.sightId}
							/>
						) : (
							<RecordProfileRelationTable
								key={relation.name}
								entityName={relation.relatedEntity}
								relationsRows={relation.rows}
								searchValue={searchValue}
								headerName={relation.displayName ?? relation.name}
								relationName={relation.name}
								currentRecordId={recordProfile.sightId}
							/>
						)
					)}
			</Flex>
		</Flex>
	);
}
