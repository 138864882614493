import Box from '@components/Box';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import IconButton from 'src/common/components/IconButton';
import { ChevronLeftSolid16 } from 'src/common/components/Icons';
import { CollectionType } from 'src/types/signal';

interface ModalHeaderProps {
	onBack?: () => void;
	collectionType?: CollectionType;
}

export function FillDetailsHeader({ onBack, collectionType }: ModalHeaderProps) {
	return (
		<Box shadow={'borderBottom'} marginX={'-16px'}>
			<Flex justifyContent={'space-between'} paddingBottom={'16px'} paddingX={'16px'}>
				<Flex alignItems={'center'} gap="8px">
					{onBack && <IconButton icon={<ChevronLeftSolid16 />} colorScheme="black" onClick={onBack} />}
					<Typography variant={'DesktopH6'}>Save to {collectionType}</Typography>
				</Flex>
			</Flex>
		</Box>
	);
}
