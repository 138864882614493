import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { MetricPeriod } from '@sightfull/period-ranges';
import { ReactNode } from 'react';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useReportEvent } from 'src/services/analytics';

export interface Props {
	title: string;
	subTitle: string | ReactNode;
	icon: ReactNode;
	onClick: () => any;
	isDisabled?: boolean;
	isItemFaded?: boolean;
}
export function ExportListItem({ title, subTitle, icon, onClick, isDisabled = false, isItemFaded = false }: Props) {
	const { reportEvent } = useReportEvent();
	const metricDerivedState = useMetricDerivedState();
	const { metricNameWithFlavor, selectedXAxisElements, tableType } = metricDerivedState;
	const hasSelectedPeriods = selectedXAxisElements.length > 0 && selectedXAxisElements[0] instanceof MetricPeriod;
	const currentPeriod = hasSelectedPeriods ? (selectedXAxisElements[0] as MetricPeriod) : undefined;

	return (
		<button
			onClick={() => {
				if (isDisabled) return;
				const formattedTitle = title.toLowerCase().replaceAll(' ', '-');
				onClick();
				reportEvent({
					event: `export-live-data-${formattedTitle}-clicked`,
					metaData: {
						metricName: metricNameWithFlavor,
						tableType,
						currentPeriod: currentPeriod?.id,
					},
				});
			}}
			style={{ width: '100%' }}
		>
			<Flex
				borderRadius={'4px'}
				pb={'12px'}
				cursor={isDisabled ? 'default' : 'pointer'}
				opacity={isItemFaded ? 0.3 : 1}
				direction={'row'}
				_hover={{ backgroundColor: !isDisabled && 'blue.100' }}
				paddingX={'16px'}
				pt={'12px'}
			>
				<Flex
					borderRadius={'4px'}
					backgroundColor={'blue.100'}
					color={'blue.600'}
					maxW={'40px'}
					maxH={'40px'}
					minW={'40px'}
					minH={'40px'}
					justifyContent={'center'}
					alignItems={'center'}
				>
					{icon}
				</Flex>
				<Flex flex={1}>
					<Flex direction={'column'} alignItems={'start'} pl={'12px'} flex={1}>
						<Typography
							cursor={isDisabled ? 'default' : 'pointer'}
							variant={'DesktopH8Medium'}
							pb={'2px'}
							color={'gray.1000'}
						>
							{title}
						</Typography>
						<Typography
							cursor={isDisabled ? 'default' : 'pointer'}
							variant={'Paragraph12R'}
							color={'gray.700'}
							textAlign={'start'}
						>
							{subTitle}
						</Typography>
					</Flex>
				</Flex>
			</Flex>
		</button>
	);
}
