import { Flex } from '@chakra-ui/react';
import { GenAIChip } from 'src/common/components/GenAIChip';
import { ActionIcon } from 'src/common/components/MetricView/InteractiveMetricChat/components/ActionIcon';
import {
	ActionVariants,
	PromptAction,
	promptReactionMapping,
	promptTemplateMapping,
} from 'src/common/components/MetricView/InteractiveMetricChat/normalizeHistory';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';

export function ActionChips({
	variant,
	onChipClick,
}: {
	variant: ActionVariants;
	onChipClick: (value: string, chipDisplayName: string) => void;
}) {
	const { isFormula } = useMetricDerivedState();
	const promptMap = variant === 'templates' ? promptTemplateMapping(isFormula) : promptReactionMapping;

	return (
		<Flex direction="row" flexWrap={'wrap'} gap="8px">
			{Object.entries(promptMap).map(([action, { displayPrompt, prompt, icon }]: [string, PromptAction]) => {
				return (
					<GenAIChip
						key={`${variant}-${action}`}
						startIcon={icon ?? <ActionIcon isFallbackAvatar={false} action={action} />}
						onClick={() => onChipClick(prompt, displayPrompt)}
					>
						{displayPrompt}
					</GenAIChip>
				);
			})}
		</Flex>
	);
}

export function DynamicChips({
	chips,
	onChipClick,
}: {
	chips: PromptAction[];
	onChipClick: (value: string, chipDisplayName: string) => void;
}) {
	return (
		<Flex direction="row" flexWrap={'wrap'} gap="8px">
			{chips.map(({ displayPrompt, prompt, icon }: PromptAction) => {
				return (
					<GenAIChip
						key={`dynamic-chip-${displayPrompt}`}
						startIcon={icon ?? <ActionIcon isFallbackAvatar={false} />}
						onClick={() => onChipClick(prompt, displayPrompt)}
					>
						{displayPrompt}
					</GenAIChip>
				);
			})}
		</Flex>
	);
}
