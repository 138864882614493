import { default as Box, default as Flex } from '@components/Flex';
import Select from '@components/Select';
import Typography from '@components/Typography';
import { useState } from 'react';
import useUrlTenant from 'src/common/hooks/navigation/useUrlTenant';
import { useEnvironment, useSwitchTenant } from 'src/stores/environment';
import Button from '../Button';
import Checkbox from '../Checkbox';

export function TenantPicker({
	onClose,
	isDisclosingTenantNamesDefault = true,
}: {
	onClose?: VoidFunction;
	isDisclosingTenantNamesDefault?: boolean;
}) {
	const { availableTenants, tenant: activeTenant } = useEnvironment();
	const switchTenant = useSwitchTenant();
	const [, setUrlTenant] = useUrlTenant();
	const [isDisclosingTenantNames, setIsDisclosingTenantNames] = useState(isDisclosingTenantNamesDefault);

	if (!availableTenants) {
		return <></>;
	}

	const activeTenantName = isDisclosingTenantNames
		? availableTenants.find((tenant) => +tenant.id == activeTenant)?.name ?? 'unknown'
		: `${activeTenant}`;

	return (
		<Box backgroundColor={'white'} padding="24px">
			<Flex m="auto" p="16px" direction={'column'} alignItems="start" gap={'24px'}>
				<Typography variant="DesktopH5" color={'black'}>
					Pick a tenant
				</Typography>
				<Select
					width={'100%'}
					isSearchable={true}
					options={availableTenants
						.filter((tenant) => tenant.isReadAllowed && +tenant.id != activeTenant)
						.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
						.map((tenant) => ({ label: isDisclosingTenantNames ? tenant.name : tenant.id, value: tenant.id }))}
					size={'large'}
					onChange={(e) => {
						onClose?.();
						switchTenant(e.value);
					}}
					selectedOption={{ label: activeTenantName, value: `${activeTenant}` }}
				/>
				<Checkbox
					onChange={setIsDisclosingTenantNames}
					label="Show tenant name"
					isChecked={isDisclosingTenantNames}
					size="md"
				/>
				{!onClose && (
					<Button
						width={'100%'}
						variant="solid"
						colorScheme={'blue'}
						size="large"
						onClick={() => {
							setUrlTenant(`${activeTenant}`);
						}}
					>
						Continue
					</Button>
				)}
			</Flex>
		</Box>
	);
}
