import { Grid, GridItem } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { createMergedClassName } from 'src/common/utils/style';
import { EntityTypeOrDefault } from 'src/layout/EntityProfileDrawer/types';
import { TableCellProps } from '../TableCell/types';
import { LeadIcon } from './LeadIcon';
import classes from './TableRow.module.scss';

export type TableRowVariant = 'drawer' | 'search';

export type TableRowProps = {
	cells: TableCellProps[];
	variant?: TableRowVariant;
	isDisabled?: boolean;
	isFirstRow?: boolean;
	isLastRow?: boolean;
	entityType: EntityTypeOrDefault;
	renderCell: (cellProps: TableCellProps) => ReactNode;
	onClick?: () => void;
};

const createClassName = ({
	isDisabled,
	variant,
	isFirstRow,
	isLastRow,
}: {
	isDisabled?: boolean;
	variant?: string;
	isFirstRow?: boolean;
	isLastRow?: boolean;
}) => {
	return createMergedClassName([
		classes.row,
		isDisabled ? classes.disabled : classes.regular,
		...(variant ? [classes[`${variant}Row`]] : []),
		...(isFirstRow ? [classes.firstRow] : []),
		...(isLastRow ? [classes.lastRow] : []),
	]);
};

export function TableRow({
	cells,
	variant,
	isDisabled,
	isFirstRow = false,
	isLastRow = false,
	entityType,
	renderCell,
	onClick,
}: TableRowProps) {
	const className = createClassName({ isDisabled, variant, isFirstRow, isLastRow });

	return (
		<Grid
			gridTemplateColumns={`repeat(${cells.length}, [col-start] 1fr)`}
			className={className}
			onClick={onClick}
			width="100%"
		>
			{cells.map((cell: TableCellProps, index) => {
				const cellProps = {
					...cell,
					isDisabled: isDisabled,
					shouldShowDivider: false,
					classNames: ['rowCell'],
					width: index == 0 ? '200px' : undefined,
					leadIcon: index == 0 ? <LeadIcon entityType={entityType} /> : undefined,
					paddingX: '16px',
				};
				return <GridItem key={`cell-item-${cell.key}`}>{renderCell(cellProps)}</GridItem>;
			})}
		</Grid>
	);
}
