import { CoralogixRum } from '@coralogix/browser';
import { isLocalDevelopment, isProductionEnv } from 'src/config';
import { AnalyticsMetadata } from './analytics';

export function initCoralogixRum(metadata: AnalyticsMetadata) {
	CoralogixRum.init({
		public_key: 'cxtp_wMHLnQtZlAMIhr1lfPsTockCye48OW',
		application: 'Pulse',
		version: isLocalDevelopment ? 'local' : import.meta.env.PULSE_GIT_FULL_COMMIT_ID ?? 'local-unknown',
		coralogixDomain: 'US1',
		debug: isLocalDevelopment,
		environment: isLocalDevelopment ? 'local' : isProductionEnv ? 'production' : 'stage',
		user_context: {
			user_id: metadata.id,
			user_name: metadata.name,
			user_email: metadata.email,
			user_metadata: metadata,
		},
	});
}

export function identifyCoralogixRum(metadata: AnalyticsMetadata) {
	if (isLocalDevelopment) return;
	initCoralogixRum(metadata);
}

export function trackCoralogixRumEvent(event: string, metaData?: object) {
	CoralogixRum.info(event, metaData);
}
