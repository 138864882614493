import Box from '@components/Box';
import Flex from '@components/Flex';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { AdvancedSelect } from 'src/common/components/AdvancedSelect';
import BuilderTextInput from 'src/common/components/BuilderTextInput';
import { ArrowDown14, CloseTiny16 } from 'src/common/components/Icons';
import { DimensionType } from 'src/lib/completions/semanticTypes/normalization.schema';
import {
	LabelWithIcon,
	SelectLabel,
	TooltipIcon,
} from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';
import DescriptionField from 'src/pages/OntologyPage/components/DescriptionField';
import { KeysType } from 'src/pages/OntologyPage/hooks/useDimensionBuilderDerivedState';
import { DIMENSION_TYPES } from 'src/pages/OntologyPage/utils/consts';
import { OntologyDimensionInfo } from 'src/pages/OntologyPage/utils/normalizeYaml';
import { getIconByType } from 'src/pages/OntologyPage/utils/utils';
import { useReportEvent } from 'src/services/analytics';
import { DimensionsFormulaTooltip } from '../../Tooltips/DimensionsFormulaTooltip';

export function DimensionsForm({
	entityName,
	propertyDefinitionValue,
	onChange,
	isEditable = true,
	isEditDimesnionFlow,
}: {
	entityName: string;
	propertyDefinitionValue: OntologyDimensionInfo;
	onChange: (key: KeysType[0]['key'], value: KeysType[0]['value']) => void;
	isEditable?: boolean;
	isEditDimesnionFlow?: boolean;
}) {
	const { reportEvent } = useReportEvent({});

	const { sources = [], meta, type } = propertyDefinitionValue;
	const { display_name, description } = meta || {};

	const isHavingSQL = (el: OntologyDimensionInfo['sources'][0]) => 'sql' in el && el.sql;

	const typeOptions = useMemo(
		() =>
			DIMENSION_TYPES?.map((el: DimensionType) => ({
				value: el,
				label: capitalize(el),
				icon: getIconByType(el, 'small'),
			})),
		[]
	);

	const typeControlledValue = useMemo(() => typeOptions.find((el) => el.value === type), [type, typeOptions]);
	const isWithoutEmptyValues = useMemo(() => sources?.length && !sources.some((val) => !isHavingSQL(val)), [sources]);

	const sourcesArrray = useMemo(() => {
		return sources.map((el, i) => (
			<Flex alignItems={'start'} marginBottom={'8px'} key={`formula-${i}`}>
				{i > 0 && (
					<Box marginTop={'10px'} marginRight={'8px'}>
						<ArrowDown14 />
					</Box>
				)}
				<BuilderTextInput
					reportFields={{
						name: 'ontology-object-edit-UI-input-provided',
						fields: {
							objectName: propertyDefinitionValue?.meta?.display_name,
							objectType: 'dimensions',
							parentEntity: entityName,
							fieldName: 'formula',
							type: 'text-input',
							flow: isEditDimesnionFlow ? 'edit' : 'create',
						},
					}}
					fieldName={`formula-${i}`}
					placeholder={'Add formula'}
					validation={'None'}
					validationText={'Validation text'}
					readOnly={!isEditable}
					initialState={isHavingSQL(el) || i === 0 ? 'Idle' : 'Focus'}
					contextSettings={{
						entity: entityName,
						metric: '',
						includeTypes: [
							'dimension',
							'dimension_boolean',
							'function',
							'dimension_date',
							'dimension_numeric',
							'join',
							'relationship',
						],
						resultsFilter(item) {
							return item.keyword != propertyDefinitionValue.name;
						},
						isIncludingMultitraversal: true,
					}}
					text={isHavingSQL(el) || ''}
					id={`formula-${i}`}
					onBlur={(inputText) => {
						const newSources = sources.map((el, index) => (index === i ? { sql: inputText?.trim() || '' } : el));
						const filteredSources = newSources.filter((el) => 'sql' in el && el.sql !== '');
						if (!inputText && i > 0) {
							onChange('sources', filteredSources);
						} else {
							onChange('sources', newSources);
						}
					}}
				/>
			</Flex>
		));
	}, [
		sources,
		propertyDefinitionValue.meta?.display_name,
		propertyDefinitionValue.name,
		entityName,
		isEditDimesnionFlow,
		isEditable,
		onChange,
	]);

	return (
		<Box width={'100%'}>
			<Flex direction={'column'}>
				<Flex marginBottom={'8px'}>
					<SelectLabel
						color={'gray.1100'}
						text="Formula"
						trailingIcon={
							<TooltipIcon
								tooltipBody={
									<DimensionsFormulaTooltip
										onHelpClicked={() =>
											reportEvent({
												event: 'ontology-object-help-clicked',
												metaData: {
													component: 'Formula',
												},
											})
										}
									/>
								}
							/>
						}
					/>
				</Flex>
				{sourcesArrray}
				<Flex justifyContent={isWithoutEmptyValues ? 'space-between' : 'flex-end'}>
					{isWithoutEmptyValues && isEditable && (
						<LabelWithIcon
							isFieldsDisabled={false}
							onClick={() => {
								reportEvent({
									event: 'dimension-edit-add-fallback',
									metaData: {
										component: 'Formula',
									},
								});
								onChange('sources', [...sources, { sql: '' }]);
							}}
							text="Add fallback"
						/>
					)}
					{sources.length > 1 && isEditable && (
						<LabelWithIcon
							icon={<CloseTiny16 />}
							isFieldsDisabled={false}
							onClick={() => onChange('sources', [...sources.slice(0, -1)])}
							text="Remove"
						/>
					)}
				</Flex>
			</Flex>
			<Box marginTop={'32px'}>
				<SelectLabel marginBottom={'8px'} color={'gray.1100'} text="Type" />
				<AdvancedSelect
					entityName={entityName}
					onReportEvent={(input) =>
						reportEvent({
							event: 'ontology-object-edit-UI-input-provided',
							metaData: {
								objectType: 'dimensions',
								objectName: propertyDefinitionValue?.meta?.display_name,
								fieldname: 'type',
								input,
								parentEntity: entityName,
								flow: isEditDimesnionFlow ? 'edit' : 'create',
							},
						})
					}
					isDisabled={!isEditable}
					onChange={(newValue) => onChange('type', newValue.value)}
					placeholder="Select type"
					options={typeOptions}
					controlledValue={typeControlledValue}
					dataIntercomTarget="type"
				/>
			</Box>
			<Box marginTop={'32px'}>
				<DescriptionField
					placeholder={'Describe this dimension'}
					onChange={(description: string) =>
						onChange('meta', {
							display_name,
							description: description || undefined,
						})
					}
					onBlur={() =>
						reportEvent({
							event: 'ontology-object-edit-UI-input-provided',
							metaData: {
								objectType: 'dimensions',
								objectName: propertyDefinitionValue?.meta?.display_name,
								fieldname: 'description',
								input: description,
								parentEntity: entityName,
								flow: isEditDimesnionFlow ? 'edit' : 'create',
							},
						})
					}
					value={description}
					isDisabled={!isEditable}
				/>
			</Box>
		</Box>
	);
}
