import Input from '@components/Input';
import colors from 'src/style/colors';
import Box from '@components/Box';
import { Search16 } from '@icons/index';
import { useEffect, useRef } from 'react';
import { useReportEvent } from '@services/analytics';
import debounce from 'lodash/debounce';
export function DataConnectorsModalSearch({
	onSearchTermChange,
	value,
	hasSearchTerm,
	isSearchWithBottomShadow,
}: {
	onSearchTermChange: (value: string) => void;
	value: string;
	hasSearchTerm: boolean;
	isSearchWithBottomShadow: boolean;
}) {
	const { reportEvent } = useReportEvent();
	const reportSearch = (searchValue: string) => {
		reportEvent({
			event: 'connectors-add-connector-search',
			metaData: { searchTerm: searchValue },
		});
	};
	const debouncedReportEventRef = useRef(debounce(reportSearch, 500));

	useEffect(() => {
		const currentReport = debouncedReportEventRef.current;
		if (hasSearchTerm && value != '') {
			currentReport(value);
		}

		return () => currentReport.cancel();
	}, [hasSearchTerm, value]);

	return (
		<Box
			position={'sticky'}
			backgroundColor={'white'}
			top={'0px'}
			padding={`24px 0px 32px 0px`}
			boxShadow={isSearchWithBottomShadow ? '0 4px 2px -2px rgba(26, 29, 33, 0.05)' : ''}
		>
			<Input
				onKeyDown={(keyDownEvent) => {
					if (keyDownEvent.key == 'Escape') {
						onSearchTermChange('');
					}
				}}
				value={value}
				borderColor={colors.gray['300']}
				isErasable={hasSearchTerm}
				leftComponent={<Search16 />}
				size="md"
				placeholder={`Search data source`}
				onChange={onSearchTermChange}
			/>
		</Box>
	);
}
