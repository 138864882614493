import { useTenantConfigStore } from 'src/stores/tenantConfig';

export function fiscalYearOffset(): number {
	const fiscalYearStartValue = useTenantConfigStore.getState().fiscalYearStart;
	if (isNaN(+fiscalYearStartValue))
		throw Error(
			`Error: There is no valid fiscalYearStart defined. This is a required tenantConfig for most of the Metric Period calculations. Value received: '${fiscalYearStartValue}'`
		);

	return +fiscalYearStartValue - 1;
}
