import { MetricPeriod } from '@sightfull/period-ranges';
import { TableError } from '../../../../common/components/TableError/TableError';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { useSelectedXAxisElements } from '../../hooks/useSelectedXAxisElement';
import { OnModalOpenType } from '../FiltersAndBreakdown/types';
import { SkeletonTableLayout } from '../SkeletonComponents';
import { DetailedTable } from './DetailedTable';
import { MetricTable } from './MetricTable';
import { MultiPeriodDetailedTable } from './MultiPeriodDetailedTable';

export function TableLayout({
	onModalOpen,
	isLoading,
	isThumbnail = false,
}: {
	onModalOpen?: OnModalOpenType;
	isLoading: boolean;
	isThumbnail?: boolean;
}) {
	const [selectedXAxisElements] = useSelectedXAxisElements();
	const { tableType, isPartiallyLoadingTable, detailedTableError } = useMetricDerivedState();

	if (isLoading || isPartiallyLoadingTable) {
		return <SkeletonTableLayout />;
	}

	if (
		tableType == 'DetailedTable' &&
		selectedXAxisElements.length > 0 &&
		selectedXAxisElements[0] instanceof MetricPeriod
	) {
		if (detailedTableError) {
			return <TableError detailedTableError={detailedTableError} />;
		}

		return (
			<DetailedTable onModalOpen={onModalOpen} selectedPeriod={selectedXAxisElements[0]} isThumbnail={isThumbnail} />
		);
	} else if (tableType == 'MetricTable') return <MetricTable isThumbnail={isThumbnail} />;
	else if (tableType == 'MultiPeriodDetailedTable')
		return <MultiPeriodDetailedTable isThumbnail={isThumbnail} onModalOpen={onModalOpen} />;

	return null;
}
