import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { InstallingTutorial } from 'src/pages/MetricCatalog/TemplateOnboardingModal/components/InstallingTutorial';
import Progress from '@components/Progress';
import { SupportLink } from 'src/pages/MetricCatalog/TemplateOnboardingModal/components/SupportLink';
import { ProcessStatus } from 'src/pages/MetricCatalog/TemplateOnboardingModal/types';

export const InstallingStep = ({
	processStatus,
	progressValue,
}: {
	processStatus: ProcessStatus;
	progressValue: number;
}) => {
	const getProgressText = () => {
		switch (processStatus) {
			case 'installing':
				return 'Installing...';
			case 'success':
				return 'Completed!';
			case 'installationFailed':
				return (
					<>
						Installation failed, <SupportLink />.
					</>
				);
		}
	};

	return (
		<>
			<Flex p={'28px 52px 56px 52px'} flexDirection={'column'} alignItems={'flex-start'}>
				<Typography variant={'DesktopH8Regular'} color="gray.1100" mb={'8px'}>
					{getProgressText()}
				</Typography>
				<Progress
					colorScheme={processStatus === 'installationFailed' ? 'red.700' : 'blue.700'}
					bgColor={'gray.200'}
					borderRadius={'30px'}
					height={'8px'}
					width={'100%'}
					value={processStatus === 'installationFailed' ? 100 : progressValue}
				/>
			</Flex>
			<Flex
				flexDirection={'column'}
				alignItems={'center'}
				p={'24px 0 32px 0'}
				gap={'16px'}
				backgroundColor={'gray.100'}
				borderRadius={'12px'}
			>
				<Typography variant={'DesktopH8Medium'} color="gray.1000">
					What&apos;s next?
				</Typography>
				<InstallingTutorial />
			</Flex>
		</>
	);
};
