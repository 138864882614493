import { Box, Flex } from '@chakra-ui/react';
import colors from 'src/style/colors';
import Typography from '../Typography';
import { TAB_PADDING } from './Tabs';
import classes from './Tabs.module.scss';
import { getTextColor } from './tabStyles';
import { TabProps } from './types';

export const Tab = ({ text, testId, notification, isDisabled = false, isSelected = false }: TabProps) => {
	return (
		<Box
			data-testid={testId}
			className={`${classes.tab} ${isSelected ? classes.selected : undefined} ${
				isDisabled ? classes.disabled : undefined
			}`}
		>
			<TabData notification={notification} text={text} isDisabled={isDisabled} isSelected={isSelected} />
			<HoverIndicator isShowNotification={!!notification} />
		</Box>
	);
};

const TabData = ({ text, isSelected, isDisabled, notification }: TabProps) => {
	const textColor = getTextColor({
		disabled: isDisabled ?? false,
		selected: isSelected ?? false,
	});

	return (
		<Flex direction={'row'} alignItems={'flex-start'} pr={`${TAB_PADDING}px`}>
			<Box p={'8px 0px'}>
				<Typography color={textColor} variant="DesktopH8Medium" as={!isDisabled ? 'button' : undefined}>
					{text}
				</Typography>
			</Box>

			{notification && (
				<Flex
					width={'15px'}
					color={'white'}
					height={'15px'}
					fontWeight={500}
					textAlign="center"
					alignItems={'center'}
					borderRadius={'40px'}
					bg={colors.blue['700']}
					justifyContent={'center'}
				>
					<Typography variant="Tags10B">{notification}</Typography>
				</Flex>
			)}
		</Flex>
	);
};

const HoverIndicator = ({ isShowNotification }: { isShowNotification: boolean }) => {
	return (
		<Box
			zIndex={1}
			mr={isShowNotification ? `${TAB_PADDING + 16}px` : `${TAB_PADDING}px`}
			height={'2px'}
			borderRadius={'10px'}
			transition={'all 0.2s ease'}
			className={classes.hoverIndicator}
		/>
	);
};
