import Box from '@components/Box';
import Card from '@components/Card';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import React, { MouseEventHandler, useEffect, useMemo, useRef } from 'react';
import Tooltip from 'src/common/components/Tooltip';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import {
	IsMetricPageURLBasedAtom,
	MetricPageSearchParamsAtom,
} from 'src/pages/MetricPage/atoms/MetricPageSearchParams';
import colors from 'src/style/colors';

import { Provider } from 'jotai';
import Chart from 'src/common/components/Chart';
import { getInitialDerivedStateAtom } from '../MetricPage/atoms/DerivedState';
import { TableRefAtom } from '../MetricPage/atoms/TableRef';
import RulesEngineRunner from '../MetricPage/components/RulesEngineRunner';
import { useMetricDerivedState } from '../MetricPage/hooks/useMetricDerivedState';
import { SkeletonCardPreview } from './SkeletonComponents/SkeletonCardPreview/SkeletonCardPreview';

import { Center, Img } from '@chakra-ui/react';
import { CardSize } from '@components/Card/Card';
import { Label } from '@components/Labels';
import classnames from 'classnames';
import PendingSetupMetricPreview from 'src/assets/images/pending-setup-metric-preview.svg';
import { BrokenChart70, Ellipse14, EyeView16 } from 'src/common/components/Icons';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useSemanticDefinitionsForEntity } from 'src/common/hooks/stores/useSemanticDefinitions';
import { getSourcesInfo } from 'src/common/hooks/useSourceInfo';
import { MetricCardMoreButton } from 'src/pages/MetricCatalog/MetricCardMoreButton';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import classes from './MetricCardV2.module.scss';
import { useCategoriesWithBrokenMetrics } from './hooks/useCategoriesWithBrokenMetric';

type MetricCardProps = {
	metricName: string;
	metricDescription?: string;
	metricDisplayName?: string;
	metricEntity?: string;
	metricSource?: string;
	onClick?: () => void;
	testId?: string;
	categoryName?: string;
	withError?: boolean;
	hidden?: boolean;
	dataIntercomSequence?: number;
	imageUrl?: string;
	cardSize?: CardSize;
	thumbnail?: React.ReactNode;
	noHoverEffects?: boolean;
	shouldShowMoreButton?: boolean;
	metricId?: string;
	metricCategoryId?: string;
	isFullyDefined?: boolean;
	isPendingSetupCategory?: boolean;
};

const maxDescriptionLines = 2;

export function MetricCardV2({
	metricDisplayName,
	metricName,
	metricDescription,
	onClick,
	testId,
	metricEntity,
	metricSource,
	categoryName,
	withError = false,
	hidden = false,
	dataIntercomSequence,
	cardSize = 'int',
	imageUrl,
	thumbnail,
	noHoverEffects = false,
	shouldShowMoreButton = true,
	metricId,
	metricCategoryId,
	isFullyDefined = true,
	isPendingSetupCategory = false,
}: MetricCardProps) {
	const descriptionRef = useRef(null);
	const nameRef = useRef(null);
	const isOverflowingDescription = useOnOverflow(descriptionRef, [metricDescription], undefined, false);
	const isOverflowingName = useOnOverflow(nameRef, [metricName], undefined, false);
	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	const entityDisplayName = useSemanticDefinitionsForEntity(metricEntity)?.meta?.display_name ?? metricEntity;
	const { metricCard, moreButton } = classes;

	const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
		e.preventDefault();
		onClick?.();
	};

	const searchParams = new URLSearchParams([]);
	const showCardsPreview = useFeatureFlag('pulse.react.metricCatalog.showCardsPreview');
	const { updateBrokenCategories } = useCategoriesWithBrokenMetrics();

	const sourceInfo = useMemo(() => getSourcesInfo({ source: metricSource }), [metricSource]);
	const SourceLogo = useMemo(() => sourceInfo?.BigLogo, [sourceInfo?.BigLogo]);

	const MetricChartGuard = () => {
		const { isLoading: isMetricLoading, errorMessage = '' } = useMetricDerivedState();

		useEffect(() => {
			if (errorMessage && categoryName) updateBrokenCategories(categoryName, metricName);
		}, [errorMessage]);

		const isMetricBroken = withError || !!errorMessage;
		return isMetricLoading && !withError ? (
			<SkeletonCardPreview />
		) : (
			<Flex
				maxH={'156px'}
				minH={'156px'}
				position={'relative'}
				borderRadius="7px 7px 0px 0px"
				backgroundColor={errorMessage || hidden ? 'gray.100' : 'blue.100'}
				padding={isFullyDefined ? '20px' : '0'}
			>
				<Flex
					boxShadow={errorMessage && !imageUrl ? 'none' : 'panelShadow'}
					backgroundColor={errorMessage ? 'gray.100' : 'white'}
					w={'100%'}
					h={imageUrl || !isFullyDefined ? 'auto' : '124px'}
					maxH={imageUrl ? '124px' : 'auto'}
					borderRadius="6px"
					overflow={'hidden'}
					paddingBottom={errorMessage || !isFullyDefined ? '0' : '8px'}
					paddingTop={errorMessage || !isFullyDefined ? '0' : '12px'}
					opacity={hidden ? '0.3' : '1'}
				>
					{!!thumbnail && (
						<Box position={'absolute'} top={'12px'} left={'12px'}>
							{thumbnail}
						</Box>
					)}
					{returnPreview(isMetricBroken)}
				</Flex>
				{!imageUrl && (
					<Flex top={'12px'} left={'12px'} position={'absolute'}>
						{renderLabel(isMetricBroken)}
					</Flex>
				)}
			</Flex>
		);
	};

	const returnPreview = (errorMessage: boolean) => {
		if (imageUrl) {
			return <img width={'100%'} height={'100%'} src={imageUrl} alt="chart" />;
		}

		if (!isFullyDefined) {
			return <Img width={'100%'} src={PendingSetupMetricPreview} objectFit="cover" />;
		}

		if (errorMessage)
			return (
				<Center width={'100%'} height="128px">
					<BrokenChart70 />
				</Center>
			);

		return (
			<Box width="100%" marginTop="-14px">
				<Chart isTooltipEnabled={false} height={'128px'} isEntityPage xAxisLabelsVisible={false} />
			</Box>
		);
	};

	const renderLabel = (isMetricBroken: boolean) => {
		const labelCommonProps = {
			size: 'small' as const,
			variant: 'filled' as const,
			style: 'strong' as const,
		};

		if (hidden) {
			return (
				<Label {...labelCommonProps} colorScheme={'neutral'} startIcon={<EyeView16 />}>
					Hidden
				</Label>
			);
		}

		if (!isFullyDefined) {
			return (
				<Label {...labelCommonProps} colorScheme={'warning'}>
					Pending setup
				</Label>
			);
		}

		if (isMetricBroken) {
			return (
				<Label {...labelCommonProps} colorScheme={'error'}>
					Broken metric
				</Label>
			);
		}
	};

	function CardPreview() {
		return (
			<Provider
				initialValues={[
					[MetricPageSearchParamsAtom, { metricName, searchParams }],
					getInitialDerivedStateAtom(),
					[IsMetricPageURLBasedAtom, false],
					[TableRefAtom, null],
				]}
			>
				<RulesEngineRunner>
					<MetricChartGuard />
				</RulesEngineRunner>
			</Provider>
		);
	}

	const YellowDot = () => {
		return (
			<Box
				position={'absolute'}
				top={'0'}
				right={isOverflowingName ? '0' : '-10px'}
				display={'inline-flex'}
				color={'yellow.700'}
			>
				<Ellipse14 />
			</Box>
		);
	};

	return (
		<Card padding={0} radius={8} testId={testId} size={cardSize} onClick={handleClick} noHoverEffects={noHoverEffects}>
			<Flex direction="column" alignItems={'flex-end'} position="relative" width={'100%'} className={metricCard}>
				<Box
					data-intercom-area={'catalog'}
					data-intercom-type={'metric'}
					data-intercom-target={metricDisplayName ?? metricName}
					data-intercom-sequence={dataIntercomSequence}
					width={'100%'}
				>
					{showCardsPreview && !isPendingSetupCategory && <CardPreview />}
					<Flex padding={'16px'} flexDirection="column" justifyContent="space-between">
						<Box marginBottom={'8px'} height={isPendingSetupCategory ? 'auto' : '40px'}>
							<Tooltip size="md" variant="fluid" label={isOverflowingName && (metricDisplayName ?? metricName)}>
								<Typography
									minLines={isPendingSetupCategory ? 1 : 2}
									color={'gray.1000'}
									noOfLines={isPendingSetupCategory ? 1 : maxDescriptionLines}
									variant="DesktopH8Medium"
								>
									<Box
										ref={nameRef}
										className={classnames(classes.metricName, {
											[classes.isPendingSetupCategory]: isPendingSetupCategory && isOverflowingName,
										})}
									>
										{metricDisplayName ?? metricName}
										{!isFullyDefined && isPendingSetupCategory && <YellowDot />}
									</Box>
								</Typography>
							</Tooltip>
						</Box>

						<Flex flexDirection="column" justifyContent={'space-between'}>
							<Tooltip size="md" variant="fluid" label={isOverflowingDescription && metricDescription}>
								<Typography minLines={2} noOfLines={maxDescriptionLines} variant={'Paragraph12R'} color="gray.600">
									<Box ref={descriptionRef}>{metricDescription}</Box>
								</Typography>
							</Tooltip>
							<Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
								<Flex
									marginTop={isPendingSetupCategory ? '16px' : '20px'}
									width={'fit-content'}
									alignItems={'center'}
									borderRadius={'2px'}
									padding={'2px 8px'}
									backgroundColor={metricEntity ? colors.gray[200] : 'transparent'}
								>
									{
										<Typography
											color={metricEntity ? colors.gray[700] : 'transparent'}
											cursor={'inherit'}
											variant="Paragraph12M"
										>
											{metricEntity ? entityDisplayName : 'Placeholder'}
										</Typography>
									}
								</Flex>

								{SourceLogo && (
									<Tooltip label={sourceInfo?.name} size="sm">
										<SourceLogo />
									</Tooltip>
								)}
							</Flex>
						</Flex>
					</Flex>
				</Box>
				{shouldShowMoreButton && hasEditPermission && (
					<MetricCardMoreButton
						hidden={hidden}
						metricDisplayName={metricDisplayName}
						metricName={metricName}
						className={moreButton}
						metricId={metricId}
						metricCategoryId={metricCategoryId}
						metricCategoryName={categoryName}
						isPendingSetupCategory={isPendingSetupCategory}
					/>
				)}
			</Flex>
		</Card>
	);
}
