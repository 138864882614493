import Flex from 'src/common/components/Flex';
import IconButton from 'src/common/components/IconButton';
import { CollapsedSidebar16, ExpandedSidebar16 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import shadows from 'src/style/shadows';

export type ExpandCollapseProps = {
	isExpanded: boolean;
	setIsExpanded: (isExpanded: boolean) => void;
};

export function ExpandCollapseFooter({ isExpanded, setIsExpanded }: ExpandCollapseProps) {
	return (
		<Flex
			flexDir={'row'}
			height="48px"
			paddingY="8px"
			paddingX={isExpanded ? '8px' : '16px'}
			alignItems={'center'}
			gap="8px"
			as="button"
			marginLeft={'1px'}
			boxShadow={shadows.borderTop}
			onClick={() => {
				setIsExpanded(!isExpanded);
			}}
			overflowX="hidden"
			_hover={{ backgroundColor: 'gray.200' }}
			position="relative"
			left={0}
			bottom={0}
			right={0}
		>
			<IconButton icon={isExpanded ? <ExpandedSidebar16 /> : <CollapsedSidebar16 />} colorScheme="black" as={'div'} />

			<Typography variant="DesktopH8Regular" color={'gray.800'} noOfLines={1}>
				{isExpanded ? 'Collapse panel' : 'Expand panel'}
			</Typography>
		</Flex>
	);
}
