import { languages } from 'monaco-editor';
import { Context } from './completionProvider';

export const SORT_ORDER: { [key in Context['type']]: string } = {
	relationship: '1',
	parameter_store: '2',
	parameter: '3',
	metric: '3',
	formula_metric: '4',
	dimension: '2',
	dimension_numeric: '2',
	join: '5',
	function: '6',
	dimension_date: '2',
	dimension_boolean: '2',
	entity: '0',
};

export const AUTO_COMPLETE_KINDS: { [key in Context['type']]: languages.CompletionItemKind } = {
	metric: languages.CompletionItemKind.Operator,
	formula_metric: languages.CompletionItemKind.Operator,
	relationship: languages.CompletionItemKind.Interface,
	entity: languages.CompletionItemKind.Property,
	dimension: languages.CompletionItemKind.Constructor,
	dimension_numeric: languages.CompletionItemKind.Module,
	join: languages.CompletionItemKind.Constant,
	function: languages.CompletionItemKind.Function,
	dimension_date: languages.CompletionItemKind.Event,
	dimension_boolean: languages.CompletionItemKind.Issue,
	parameter: languages.CompletionItemKind.Variable,
	parameter_store: languages.CompletionItemKind.Variable,
};

export const DESCRIPTION_BY_TYPE: { [key in Context['type']]: string } = {
	metric: 'Aggregate Metric',
	formula_metric: 'Formula Metric',
	relationship: 'Relationship',
	entity: 'Entity',
	dimension: 'Dimension',
	dimension_numeric: 'Dimension',
	join: 'Join',
	function: 'Function',
	dimension_date: 'Dimension',
	dimension_boolean: 'Dimension',
	parameter: 'Parameter',
	parameter_store: 'Parameter',
};

export const relationshipTypeToNames = {
	one_to_many: 'One to Many',
	many_to_one: 'Many to One',
};

export const AUTO_COMPLETE_YAML_KEYS: string[] = ['sql', 'on', 'formula', 'measure', 'select'];
export type AutoCompleteYamlKey = typeof AUTO_COMPLETE_YAML_KEYS[number];

export const DimensionTypes = ['dimension', 'dimension_numeric', 'dimension_date', 'dimension_boolean'];
