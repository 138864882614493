import { ScrollEvent, UnknownEvent } from '@components/Scrollable/types';

export function isEvent(unknownEvent: unknown): unknownEvent is UnknownEvent {
	return (
		unknownEvent != null &&
		typeof unknownEvent == 'object' &&
		'target' in unknownEvent &&
		unknownEvent.target != null &&
		typeof unknownEvent.target == 'object'
	);
}

export function isScrollEvent(scrollEvent: UnknownEvent): scrollEvent is ScrollEvent {
	return (
		'scrollTop' in scrollEvent.target &&
		scrollEvent.target.scrollTop != null &&
		typeof scrollEvent.target.scrollTop == 'number'
	);
}
