import _ from 'lodash';
import { editor, languages, Position, Range } from 'monaco-editor';
import { addPrefixDollarSign } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { CompletionProvider, DimensionContextTypes } from '../completionProvider';
import { EnrichedSemanticDefinitions, EntityWithMetrics } from '../semanticTypes';
import { findYamlKey } from '../utils/findYamlKey';
import { getEntity, getPartAndExpressionRanges, rangeToPreviousCharacter } from '../utils/utils';
import { getContextCompletionItem } from '../widgetBuilder/completionContextTooltip';
import { getEntityCompletionItem } from '../widgetBuilder/entityCompletionItem';

export const MonacoRelationshipCompletionItemProviderBuilder = (
	semanticDefinitions: EnrichedSemanticDefinitions,
	entity: string
): languages.CompletionItemProvider => ({
	provideCompletionItems: buildMonacoRelationshipCompletionProvider(semanticDefinitions, entity),
	triggerCharacters: ['.', ' ', '('],
});

const buildMonacoRelationshipCompletionProvider =
	(semanticDefinitions: EnrichedSemanticDefinitions, entity: string) =>
	(model: editor.ITextModel, position: Position) => {
		const { partRange, expressionRange, expression } = getPartAndExpressionRanges({ model, position });
		const hasDollarBeforeExpression = model.getValueInRange(rangeToPreviousCharacter(expressionRange)) == '$';

		const yamlKey = findYamlKey(model.getValue(), model.getOffsetAt(position));
		if (yamlKey == 'referenced_entity') {
			return buildReferencedEntitySuggestions(semanticDefinitions, partRange);
		}
		if (yamlKey == 'on') {
			return buildOnExpressionSuggestions(
				semanticDefinitions,
				model,
				partRange,
				expression,
				hasDollarBeforeExpression,
				entity
			);
		}
	};

function buildReferencedEntitySuggestions(semanticDefinitions: EnrichedSemanticDefinitions, partRange: Range) {
	const completionItems = semanticDefinitions.entities
		.filter((e) => e.isDefined)
		.map((entity) => {
			return getEntityCompletionItem(entity, partRange, entity.name);
		});

	return {
		suggestions: completionItems,
	};
}

function buildOnExpressionSuggestions(
	semanticDefinitions: EnrichedSemanticDefinitions,
	model: editor.ITextModel,
	partRange: Range,
	expression: string,
	hasDollarBeforeExpression: boolean,
	entity: string
) {
	const completion = new CompletionProvider(semanticDefinitions);

	const referenced_entity = completion.findDocumentValueByKeyFirstLevel(model.getValue(), 'referenced_entity');
	return buildJoinKeySuggestions(
		semanticDefinitions,
		partRange,
		expression,
		hasDollarBeforeExpression,
		entity,
		referenced_entity ?? ''
	);
}

export function buildJoinKeySuggestions(
	semanticDefinitions: EnrichedSemanticDefinitions,
	partRange: Range,
	expression: string,
	hasDollarBeforeExpression: boolean,
	entity: string,
	referenced_entity: string
) {
	const referencedEntity = getEntity(semanticDefinitions, referenced_entity);

	if (!referencedEntity) {
		console.error('Failed to get referenced_entity', expression);
		return;
	}

	const firstExpressionPart = expression.match(CompletionProvider.EXPRESSION_PART)?.[0];
	const isReferencedEntityFirstPart = firstExpressionPart == referencedEntity.name;

	if (isReferencedEntityFirstPart) {
		const expressionWithoutFirstPart = expression.slice(referencedEntity.name.length);
		const completionItemsForReferencedEntity = buildDimensionCompletionItemsForEntity(
			semanticDefinitions,
			partRange,
			expressionWithoutFirstPart,
			hasDollarBeforeExpression,
			referencedEntity
		);
		return { suggestions: completionItemsForReferencedEntity };
	}

	const parentEntity = getEntity(semanticDefinitions, entity);
	if (!parentEntity) {
		console.error('Failed to get parent entity', entity);
		return;
	}

	const completionItemsForParentEntity = buildDimensionCompletionItemsForEntity(
		semanticDefinitions,
		partRange,
		expression,
		hasDollarBeforeExpression,
		parentEntity
	);

	const referencedEntityCompletionItem = buildReferencedEntityCompletionItem(
		referencedEntity,
		partRange,
		hasDollarBeforeExpression
	);

	return {
		suggestions: [referencedEntityCompletionItem, ...completionItemsForParentEntity],
	};
}

function buildDimensionCompletionItemsForEntity(
	semanticDefinitions: EnrichedSemanticDefinitions,
	partRange: Range,
	expression: string,
	hasDollarBeforeExpression: boolean,
	entity: EntityWithMetrics
) {
	const completion = new CompletionProvider(semanticDefinitions);
	const startingContext = completion.createContexts({
		entity: entity,
	});

	const completionContexts = completion.walkContextForCompletions(
		startingContext,
		expression,
		expression.length,
		false
	);

	const dimensionsOnlyContexts = completionContexts.filter((context) =>
		_.includes(DimensionContextTypes, context.type)
	);

	const completionItems = dimensionsOnlyContexts.map((context) =>
		getContextCompletionItem(context, partRange, hasDollarBeforeExpression ? '' : '$', false)
	);

	return completionItems;
}

export function buildReferencedEntityCompletionItem(
	referencedEntity: EntityWithMetrics,
	partRange: Range,
	hasDollarBeforeExpression: boolean
) {
	return getEntityCompletionItem(
		referencedEntity,
		partRange,
		`${hasDollarBeforeExpression ? referencedEntity.name : addPrefixDollarSign(referencedEntity.name)}.`,
		true
	);
}
