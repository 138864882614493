import { editor, languages, Position } from 'monaco-editor';
import { textFieldIdToFileUri } from 'src/common/components/BuilderTextInput/BuilderTextInputField';
import { EnrichedSemanticDefinitions } from '../semanticTypes';
import { getPartAndExpressionRanges, rangeToPreviousCharacter } from '../utils/utils';
import { buildJoinKeySuggestions } from './monacoRelationshipCompletionProvider';

export const JoinKeysTextFieldCompletionItemProviderBuilder = (
	entity: string,
	referenced_entity: string,
	semanticDefinitions: EnrichedSemanticDefinitions,
	id: string
): languages.CompletionItemProvider => ({
	provideCompletionItems: buildJoinKeysTextFieldCompletionProvider(entity, referenced_entity, semanticDefinitions, id),
	triggerCharacters: ['.', '$', '('],
});

const buildJoinKeysTextFieldCompletionProvider =
	(entity: string, referenced_entity: string, semanticDefinitions: EnrichedSemanticDefinitions, id: string) =>
	(model: editor.ITextModel, position: Position) => {
		if (model.uri.toString() != textFieldIdToFileUri(id)) {
			return;
		}

		const { partRange, expressionRange, expression } = getPartAndExpressionRanges({ model, position });
		const hasDollarBeforeExpression = model.getValueInRange(rangeToPreviousCharacter(expressionRange)) == '$';

		return buildJoinKeySuggestions(
			semanticDefinitions,
			partRange,
			expression,
			hasDollarBeforeExpression,
			entity,
			referenced_entity
		);
	};
