import { MissingDependencies, MissingObject, MissingSemantic } from 'src/generated/graphql';
import { MetricMissingDependency, MetricMissingDependencyType } from 'src/types/metric';
import { removeUnderscoresAndCapitalize } from 'src/normalize';

const missingDependencyTypeOrderingValue: Record<string, number> = {
	entities: 1,
	dimensions: 2,
	relationships: 3,
	metrics: 4,
};

const isMissingSemantic = (dependency: MissingSemantic | MissingObject): dependency is MissingSemantic => {
	return (dependency as MissingSemantic).entityName !== undefined;
};

export const calcMissingDependencies = (missingDependencies: MissingDependencies): MetricMissingDependency[] => {
	const extendedDependencyObject = (
		dependencyType: string,
		dependencies: MissingSemantic[] | MissingObject[]
	): MetricMissingDependency => {
		const firstDependency = dependencies[0];
		const description = dependencies
			.map((dependency) => removeUnderscoresAndCapitalize(dependency.displayName))
			.join(', ');

		return {
			entityName: isMissingSemantic(firstDependency) ? firstDependency.entityName : '',
			displayName: removeUnderscoresAndCapitalize(firstDependency.displayName),
			name: firstDependency.name,
			type: dependencyType as MetricMissingDependencyType,
			numOfDependencies: dependencies.length,
			description,
			orderingValue: missingDependencyTypeOrderingValue[dependencyType],
		};
	};

	return Object.entries(missingDependencies)
		.filter(([key]) => key !== '__typename')
		.flatMap(([dependencyType, dependencies]) => {
			if (!Array.isArray(dependencies) || dependencies.length === 0) {
				return [];
			}

			if (dependencyType === 'metrics') {
				return dependencies.map((metric) => ({
					displayName: removeUnderscoresAndCapitalize(metric.displayName),
					name: metric.name,
					type: dependencyType as MetricMissingDependencyType,
					numOfDependencies: 1,
					description: removeUnderscoresAndCapitalize(metric.displayName),
					orderingValue: missingDependencyTypeOrderingValue[dependencyType],
				}));
			}

			return [extendedDependencyObject(dependencyType, dependencies)];
		})
		.sort((dependency1, dependency2) => (dependency1.orderingValue < dependency2.orderingValue ? -1 : 1));
};
