import { Box, Center, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { default as Checkmark } from 'src/assets/images/c-check-1.svg?component';
import { default as SightfullLoading } from 'src/assets/images/sightfull-loading.svg?component';
import { LogoBlack } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import useRequest from 'src/common/hooks/fetching/useRequest';
import useToast from 'src/common/hooks/ui/useToast';
import { downloadBlob } from 'src/common/utils/download';
import { getExportAPIUrl } from 'src/lib/api/export';
import { useExportMetricSearchParams } from 'src/pages/ExportMetricPage/hooks/useExportMetricSearchParams';
import { useReportEvent } from 'src/services/analytics';
import { useEnvironment } from 'src/stores/environment';

export default function ExportMetricPage() {
	const { reportEvent } = useReportEvent();
	const {
		metricName,
		from,
		to = from,
		filterByText: filterBy,
		groupByText: groupBy,
		collectPropsText: collectProps,
	} = useExportMetricSearchParams();
	const { apiUrl } = useEnvironment();
	const toast = useToast();
	const [isLoading, setIsLoading] = useState(true);
	const [, { refetch: fetchAxios }] = useRequest({
		method: 'GET',
		timeoutms: 5 * 60 * 1000,
		options: { manual: true, autoCancel: false },
	});

	useEffect(() => {
		const exportUrl = getExportAPIUrl({
			apiUrl: `${apiUrl}/v2/metric/calculate/`,
			metricNameWithFlavor: metricName,
			from,
			to,
			filterByText: filterBy,
			groupByText: groupBy,
			collectPropsText: collectProps,
		});
		fetchAxios({ url: exportUrl })
			.then((result) => {
				setIsLoading(false);
				downloadBlob(result.data, `${metricName}.csv`);
			})
			.catch((error) => {
				reportEvent({
					event: 'export-event-error',
					metaData: { exportUrl, error },
				});
				toast({
					variant: 'error',
					message: 'Something went wrong. try again later.',
					duration: null,
				});
			});
	}, [apiUrl, collectProps, fetchAxios, filterBy, from, groupBy, metricName, reportEvent, to, toast]);

	return (
		<Box backgroundColor={'white'} zIndex={99} position={'absolute'} height={'100vh'} width="100vw">
			<Box position={'absolute'} top="24px" left="24px">
				<LogoBlack />
			</Box>
			<Center textAlign={'center'} height={'100vh'} width="100vw" mt="-10vh">
				<Flex width="450px" direction={'column'}>
					{isLoading ? <ExportLoading /> : <ExportLoaded />}
				</Flex>
			</Center>
		</Box>
	);
}

function ExportLoading() {
	return (
		<>
			{/* TODO: Change svg to animated */}
			<Center pb="32px">
				<SightfullLoading />
			</Center>
			<Typography pb="12px" variant="DesktopH4" isLoadingAnimation={true}>
				Your download will start shortly
			</Typography>
			<Typography variant="Paragraph16R">Please keep this tab open until it does.</Typography>
		</>
	);
}

function ExportLoaded() {
	return (
		<>
			<Center pb="32px">
				<Checkmark />
			</Center>
			<Typography pb="12px" variant="DesktopH4">
				Download has started successfully.
			</Typography>
			<Typography variant="Paragraph16R">You can now close this tab.</Typography>
		</>
	);
}
