import { MetricPeriod } from '@sightfull/period-ranges';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';
import { MetricDerivedState, SelectedXAxisElements } from '../utils/state.types';
import { useMetricDerivedState } from './useMetricDerivedState';

export type UseSelectedXAxisElement = [
	MetricDerivedState['selectedXAxisElements'],
	({ xAxisElements, isAllSelected }: { xAxisElements?: SelectedXAxisElements; isAllSelected?: boolean }) => void
];
export function useSelectedXAxisElements(): UseSelectedXAxisElement {
	const { selectedXAxisElements } = useMetricDerivedState();
	const { setPartialSearchParams } = useMetricPageSearchParams();

	function setSelectedXAxisElements({
		xAxisElements,
		isAllSelected = false,
	}: {
		xAxisElements?: SelectedXAxisElements;
		isAllSelected?: boolean;
	}) {
		const selectedXAxisElements: MetricSearchParams['selectedXAxisElements'] = normalizeSelectedXAxisElements(
			xAxisElements,
			isAllSelected
		);

		setPartialSearchParams({
			selectedXAxisElements,
			selectedPeriod: undefined,
			selectedDimensionName: undefined,
			tableColumnState: undefined,
		});
	}

	return [selectedXAxisElements, setSelectedXAxisElements];
}

export function normalizeSelectedXAxisElements(
	xAxisElements: SelectedXAxisElements | undefined,
	isAllSelected?: boolean
) {
	const xAxisElementsString = xAxisElements?.map((e) => (e instanceof MetricPeriod ? e.id : e));
	const isXAxisElementsDefined = !!xAxisElements && xAxisElements.length > 0;
	const possiblyXAxisElement = isXAxisElementsDefined ? xAxisElementsString : undefined;

	const selectedXAxisElements: MetricSearchParams['selectedXAxisElements'] = isAllSelected
		? 'all'
		: possiblyXAxisElement;
	return selectedXAxisElements;
}
