import { Box } from '@chakra-ui/react';
import MetricDataOverview from '@components/MetricView/MetricDataOverview';
import useIsDataOverviewEnabled from 'src/common/components/MetricView/MetricDataOverview/hooks/useIsDataOverviewEnabled';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { convertModalResultToValidCoreValues } from '../FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { OnModalOpenType } from '../FiltersAndBreakdown/types';
import useDimensionsState from './useDimensionsState';
import { ParametersPanel } from '../Parameters/ParametersPanel';
import { FiltersPanel } from '../FiltersAndBreakdown/FiltersPanel';
import { BreakdownsPanel } from '../FiltersAndBreakdown/BreakdownsPanel';

type Props = {
	isShowingFullSize: boolean;
	onModalOpen: OnModalOpenType;
	isDisabled?: boolean;
	toggleGenAIPopover: VoidFunction;
};

export function InvestigatePanel({ isShowingFullSize, onModalOpen, isDisabled, toggleGenAIPopover }: Props) {
	const [, { addDimensionsParamsByType }] = useDimensionsState();
	const { objectsTypes } = useMetricDerivedState();
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const isDataOverviewEnabled = useIsDataOverviewEnabled();

	const onModalClose = (result: any) => {
		addDimensionsParamsByType(
			result.type,
			isSightfull2 ? convertModalResultToValidCoreValues(result, objectsTypes[0]) : result.items
		);
	};

	return (
		<Box marginTop="16px" marginX="16px">
			{isShowingFullSize && isDataOverviewEnabled && <MetricDataOverview toggleGenAIPopover={toggleGenAIPopover} />}

			<ParametersPanel divider={'bottom'} isShowingFullSize={isShowingFullSize} />

			<FiltersPanel
				isDisabled={isDisabled}
				isShowingFullSize={isShowingFullSize}
				onModalOpen={onModalOpen}
				onModalClose={onModalClose}
			/>

			<BreakdownsPanel
				isDisabled={isDisabled}
				isShowingFullSize={isShowingFullSize}
				onModalOpen={onModalOpen}
				onModalClose={onModalClose}
			/>
		</Box>
	);
}
