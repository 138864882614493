import { Box } from '@chakra-ui/react';
import Flex from '@components/Flex';
import { Provider } from 'jotai';
import { useEffect, useState } from 'react';
import SomethingWentWrongError from 'src/common/components/ErrorWentWrong/SomethingWentWrongError';
import LeftExpandCollapsePanel from 'src/common/components/LeftExpandCollapsePanel';
import MetricViewLayout from 'src/common/components/MetricView';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { usePreventReload } from 'src/common/hooks/usePreventReload';
import { MetricType } from 'src/common/types/common';
import Page from 'src/layout/Page';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { UNTITLED_METRIC_NAME, isUntitledMetric, useMetricBuilder } from 'src/lib/metricRules/builder/useMetricBuilder';
import { TableRefAtom } from 'src/pages/MetricPage/atoms/TableRef';
import { useReportEvent } from 'src/services/analytics';
import useNavigationBlock from 'src/services/useNavigationBlock';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { getInitialDerivedStateAtom } from '../MetricPage/atoms/DerivedState';
import { IsMetricPageURLBasedAtom, MetricPageSearchParamsAtom } from '../MetricPage/atoms/MetricPageSearchParams';
import EditPanel from '../MetricPage/components/CalculatePanel';
import { EditorFeature } from '../MetricPage/components/CalculatePanel/EditPanel';
import FiltersAndBreakdownsModal from '../MetricPage/components/FiltersAndBreakdown/FiltersAndBreakdownsModal';
import { useCoreNodeScheme } from '../MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import useFiltersAndBreakdown from '../MetricPage/components/FiltersAndBreakdown/useFiltersAndBreakdown';
import { LegendPanel } from '../MetricPage/components/LegendsPanel';
import { ReadOnlyBar } from '../MetricPage/components/ReadOnlyBar';
import RulesEngineRunner from '../MetricPage/components/RulesEngineRunner';
import { useMetricDerivedState } from '../MetricPage/hooks/useMetricDerivedState';
import { useMetricEditorState } from '../MetricPage/hooks/useMetricEditorState';
import { shouldLegendPanelExpand } from '../MetricPage/utils';
import { Header, MetricBodyProgress } from './components';
import { MetricBuilderSteps } from './components/MetricBodyProgress';

export const CreateNewMetricPagePath = 'create-new-metric';
export const ExploreNewMetricPagePath = 'explore';

type CreateNewMetricProps = {
	metricKind?: MetricType;
};

type EditorStateProps = {
	selectedFeature: EditorFeature;
};

function CreateNewMetricPageGuard({ metricKind }: CreateNewMetricProps) {
	const isMetricPageEnabled = useFeatureFlag('pulse.sightfull2.createNewMetric.enable');

	if (!isMetricPageEnabled) {
		return <SomethingWentWrongError />;
	}
	return <CreateNewMetricContent metricKind={metricKind} />;
}

function CreateNewMetricContent({ metricKind }: CreateNewMetricProps) {
	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	const [selectedFeature, setSelectedFeature] = useState<EditorFeature>('YAML Builder');
	useMetricBuilder({ emptyMetricKind: metricKind });

	const [derivedActiveStep, setDerivedActiveStep] = useState<MetricBuilderSteps>('entity');

	const [hasEditorErrors, setHasEditorErrors] = useState(false);
	const [shouldBlockNavigate, setSetShouldBlockNavigate] = useState(false);
	const { hasChangesFromSavedValue, hasChangesToPreview, isSaveAllowed } = useMetricEditorState();
	const { metricBuilderState: builderState, resetYAMLValue, isCalculatingPreview } = useBuilderDerivedState();

	const { metricBuilderState, onPreview } = useBuilderDerivedState();

	const { setIsNavigationBlocked } = useNavigationBlock();

	const isPreviewEnabled = hasChangesToPreview && !hasEditorErrors;
	const isPreviewButtonVisible = selectedFeature === 'YAML Editor';
	const isYAMLBuilderSelected = selectedFeature === 'YAML Builder';

	useEffect(() => {
		const getActiveStep = (): MetricBuilderSteps => {
			if (metricBuilderState?.type == 'formula') {
				if (!metricBuilderState?.formula) {
					return 'formula';
				}
			} else if (metricBuilderState?.type == 'aggregate') {
				const hasRequiredFields =
					metricBuilderState?.operation && (metricBuilderState?.measure || metricBuilderState?.operation == 'count');
				if (!hasRequiredFields) {
					return 'formula';
				}
			}
			return 'preview';
		};
		const activeStep = getActiveStep();
		if (activeStep !== derivedActiveStep) {
			if (
				activeStep === 'preview' &&
				!hasEditorErrors &&
				!isCalculatingPreview &&
				hasChangesToPreview &&
				isYAMLBuilderSelected
			) {
				onPreview();
			}
			if (metricBuilderState?.entity && isYAMLBuilderSelected) setDerivedActiveStep(activeStep);
		}
	}, [
		derivedActiveStep,
		hasChangesToPreview,
		hasEditorErrors,
		isCalculatingPreview,
		metricBuilderState,
		onPreview,
		isYAMLBuilderSelected,
	]);

	const isSaveButtonEnabled = isSaveAllowed && !isUntitledMetric(builderState);

	usePreventReload({ dependency: hasEditPermission ? hasChangesFromSavedValue : false });

	useEffect(
		() => setIsNavigationBlocked({ isBlocked: hasEditPermission ? hasChangesFromSavedValue : false }),
		[hasChangesFromSavedValue, setIsNavigationBlocked, hasEditPermission]
	);

	useEffect(() => resetYAMLValue(), [resetYAMLValue]);

	return (
		<>
			<Flex overflowY={'auto'} direction={'column'} height={'100%'}>
				<Flex transition={'0.2s'} grow={1} height={'0'} justify={'space-between'}>
					<LeftExpandCollapsePanel
						minWidth={400}
						width={isYAMLBuilderSelected ? 400 : window.innerWidth * 0.4}
						isResizeBlocked={isYAMLBuilderSelected}
						isCollapsable
						renderItem={() => (
							<Flex direction={'column'} height="100%">
								<Box overflowY={'auto'} flex={1}>
									<EditPanel
										setDerivedActiveStep={setDerivedActiveStep}
										shouldPreventToggle={shouldBlockNavigate}
										selectedFeature={selectedFeature}
										setSelectedFeature={setSelectedFeature}
										isCreateNewPage
										isPreviewEnabled={hasChangesToPreview && !hasEditorErrors}
										setEditorErrors={(errors) => {
											const errorsToBlockNavigate = errors.filter(
												(error) => !error.content.match(/(entity: "")|(formula: "")/)
											);
											setSetShouldBlockNavigate(errorsToBlockNavigate.length > 0);
											setHasEditorErrors(errors.length > 0);
										}}
									/>
								</Box>
							</Flex>
						)}
					/>
					<Flex width={'100%'} flexDirection={'column'}>
						<Header selectedFeature={selectedFeature} isSaveAllowed={isSaveButtonEnabled} />
						{derivedActiveStep !== 'preview' ? (
							<MetricBodyProgress selectedFeature={selectedFeature} type={metricKind} activeStep={derivedActiveStep} />
						) : (
							<InnerMetricBody selectedFeature={selectedFeature} metricKind={metricKind} />
						)}
					</Flex>
				</Flex>
			</Flex>
			<ReadOnlyBar
				isReadOnlyBarShown={!hasEditPermission}
				isPreviewButtonVisible={isPreviewButtonVisible}
				onClick={onPreview}
				metricName={'Untitled'}
				isEnabled={isPreviewEnabled}
			/>
		</>
	);
}

export function InnerMetricBody({ metricKind, selectedFeature }: CreateNewMetricProps & EditorStateProps) {
	const { isLoading: isMetricStateLoading, errorMessage, objectsTypes } = useMetricDerivedState();
	const { isCalculatingPreview } = useBuilderDerivedState();
	const [isLegendPanelExpanded, setIsLegendPanelExpanded] = useState(shouldLegendPanelExpand());
	const { reportEvent } = useReportEvent();
	const [modalState, modalActions] = useFiltersAndBreakdown();
	const coreNodeScheme = useCoreNodeScheme({
		objectsTypes,
		readyToFetch: !isMetricStateLoading,
	});

	return (
		<Flex overflowY={'auto'} width={'100%'}>
			<Flex
				position={'relative'}
				grow={1}
				flexDirection="column"
				paddingTop="24px"
				data-intercom-area={'metric'}
				data-intercom-type={'main'}
				data-intercom-target={'main'}
			>
				{isMetricStateLoading || isCalculatingPreview ? (
					<MetricBodyProgress type={metricKind} activeStep="preview" />
				) : (
					<MetricViewLayout
						selectedFeature={selectedFeature}
						isEditMode
						isLeftPanelExpanded={false}
						onModalOpen={modalActions.onOpen}
						isCreateMetricPage
					/>
				)}
			</Flex>
			{!errorMessage && (
				<LegendPanel
					isLoading={isMetricStateLoading}
					isExpanded={isLegendPanelExpanded}
					setIsExpanded={(isExpanded) => {
						reportEvent({ event: 'legend-panel-toggled', metaData: { expanded: isExpanded } });
						setIsLegendPanelExpanded(isExpanded);
					}}
				/>
			)}
			<FiltersAndBreakdownsModal
				type={modalState.type}
				isOpen={modalState.isOpen}
				onClose={modalActions.onClose}
				onAddItems={modalActions.onAddItems}
				nodeScheme={coreNodeScheme}
				initialFilter={modalState.initialFilter}
			/>
		</Flex>
	);
}

export function CreateNewMetricPage({ metricKind }: CreateNewMetricProps) {
	return (
		<Provider
			initialValues={[
				[MetricPageSearchParamsAtom, { metricName: UNTITLED_METRIC_NAME, searchParams: '' }],
				getInitialDerivedStateAtom(),
				[IsMetricPageURLBasedAtom, false],
				[TableRefAtom, null],
			]}
		>
			<RulesEngineRunner>
				<Page page="Create New Metric Page">
					<CreateNewMetricPageGuard metricKind={metricKind} />
				</Page>
			</RulesEngineRunner>
		</Provider>
	);
}
