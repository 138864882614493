import classes from 'src/pages/MetricCatalog/TemplateOnboardingModal/TemplateOnboardingModal.module.scss';
import { toggleIntercom } from 'src/services/intercom';

export const SupportLink = () => {
	return (
		<span className={classes.supportLink} onClick={toggleIntercom}>
			reach out to support
		</span>
	);
};
