import Box from '@components/Box';
import Flex from '@components/Flex';
import * as CSS from 'csstype';
import shadows from 'src/style/shadows';
import IconButton from '../IconButton';
import { ChevronLeftSolid16, CloseLarge16 } from '../Icons';
import Typography from '../Typography';
import { TypographyVariant } from '../Typography/types';

type HeaderSize = 'sm' | 'md' | 'lg';

export type ModalHeaderProps = {
	size: HeaderSize;
	title?: string;
	onBack?: () => void;
	onClose?: () => void;
};

export default function ModalHeader({ size, title, onBack, onClose }: ModalHeaderProps) {
	const paddingLeft = onBack ? '16px' : '24px';
	const backIcon =
		size == 'sm' ? <CloseLarge16 width="16px" height="16px" /> : <ChevronLeftSolid16 width="16px" height="16px" />;

	return (
		<Flex
			paddingTop={paddingTopBySize[size]}
			paddingBottom={paddingBottomBySize[size]}
			paddingLeft={paddingLeft}
			paddingRight="16px"
			justifyContent="space-between"
			alignItems="center"
			height={heightBySize[size]}
			boxShadow={shadowBySize[size]}
		>
			<Flex flexDirection="row" gap="8px" alignItems="center">
				{onBack && <IconButton icon={<Box color="gray.800">{backIcon}</Box>} onClick={onBack} />}
				<Typography variant={typographyVariantBySize[size]}>{title}</Typography>
			</Flex>
			{onClose && size != 'sm' && (
				<IconButton
					icon={
						<Box color="gray.800">
							<CloseLarge16 width="16px" height="16px" />
						</Box>
					}
					onClick={onClose}
				/>
			)}
		</Flex>
	);
}
const paddingTopBySize: { [key in HeaderSize]: CSS.Property.PaddingTop } = {
	lg: '20px',
	md: '16px',
	sm: '16px',
};
const paddingBottomBySize: { [key in HeaderSize]: CSS.Property.PaddingBottom } = {
	lg: '20px',
	md: '16px',
	sm: '0',
};
const heightBySize: { [key in HeaderSize]: CSS.Property.Height } = {
	lg: '72px',
	md: '64px',
	sm: '48px',
};
const typographyVariantBySize: { [key in HeaderSize]: TypographyVariant } = {
	lg: 'DesktopH4',
	md: 'Paragraph18SB',
	sm: 'Paragraph18SB',
};
const shadowBySize: { [key in HeaderSize]: CSS.Property.BoxShadow } = {
	lg: shadows.borderBottom,
	md: shadows.borderBottom,
	sm: '0',
};
