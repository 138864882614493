import usePrivateSearchParams from '@hooks/navigation/usePrivateSearchParams';
import { useCallback } from 'react';
import { removeBadFronteggParamFromTenantID } from 'src/common/utils/tenantId';

function useUrlTenant(): [string | undefined, (newTenant: string | null) => void] {
	const [{ tenant: urlTenant }, setAppPrivateSearchParam] = usePrivateSearchParams();

	const setUrlTenant = useCallback(
		(newTenant: string | null) => {
			setAppPrivateSearchParam('tenant', newTenant);
		},
		[setAppPrivateSearchParam]
	);
	const cleanedURLTenant = removeBadFronteggParamFromTenantID(urlTenant);
	return [cleanedURLTenant, setUrlTenant];
}

export default useUrlTenant;
