import { categoryDefaultValue, selectors, useEntitySearchStore } from 'src/stores/entitySearch';
import { EntitySearchActions, EntitySearchState } from 'src/types/entitySearch';

type ReturnType = [EntitySearchState, EntitySearchActions];

function useEntitySearch(): ReturnType {
	const state = useEntitySearchStore(selectors.getState);
	const setValue = useEntitySearchStore(selectors.setValue);
	const setCategory = useEntitySearchStore(selectors.setCategory);

	const reset = () => {
		setValue('');
		setCategory(categoryDefaultValue);
	};

	const actions = {
		setValue,
		setCategory,
		reset,
	};

	return [state, actions];
}

export default useEntitySearch;
