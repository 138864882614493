import {
	AutomaticSortOrders,
	MultiPeriodSortOrders,
	SortOrder,
} from 'src/pages/MetricPage/components/LegendsPanel/types';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export function calcAvailableSortOrders(
	{ periodRange }: Pick<MetricDerivedState, 'periodRange'>,
	sortOrder?: SortOrder,
	orderedComponents?: string[]
): Pick<MetricDerivedState, 'sortOrder'> {
	const availableSortOptions = periodRange.isSinglePeriod ? AutomaticSortOrders : MultiPeriodSortOrders;
	return {
		sortOrder: {
			selectedValue: sortOrder || (orderedComponents ? 'Manual' : 'Default'),
			optionalValues: [...availableSortOptions],
		},
	};
}
