import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical';

import { useEffect } from 'react';

export function FocusPlugin({ onFocus, onBlur }: { onFocus?: VoidFunction; onBlur?: VoidFunction }) {
	const [editor] = useLexicalComposerContext();

	useEffect(
		() =>
			editor.registerCommand(
				BLUR_COMMAND,
				() => {
					onBlur?.();
					return false;
				},
				COMMAND_PRIORITY_LOW
			),
		[editor, onBlur]
	);

	useEffect(
		() =>
			editor.registerCommand(
				FOCUS_COMMAND,
				() => {
					onFocus?.();
					return false;
				},
				COMMAND_PRIORITY_LOW
			),
		[editor, onFocus]
	);

	return null;
}
