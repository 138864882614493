import { SystemStyleObject } from '@chakra-ui/theme-tools';
import colors from 'src/style/colors';

export const RadioTheme: SystemStyleObject = {
	sizes: {
		md: {
			control: {
				w: '18px',
				h: '18px',
				color: 'gray.500',
				border: `1px solid ${colors.gray['500']}`,
				_hover: { borderColor: 'gray.800' },
				_checked: {
					_hover: {
						bg: 'white',
						borderColor: 'gray.800',
					},
					_before: {
						padding: '4px',
						content: `""`,
						display: 'inline-block',
						pos: 'relative',
						w: '0',
						h: '0',
						borderRadius: '50%',
						bg: 'gray.800',
						borderColor: 'gray.800',
					},
					bg: 'white',
					border: '1px solid black',
				},
			},
		},
	},
};
