import reduce from 'lodash/reduce';
import { useMemo } from 'react';
import Flex from 'src/common/components/Flex';
import Typography from 'src/common/components/Typography';
import { InsightRow } from './InsightRow';
import { Insight, InsightTag } from './types';

export function TagSection({ tag }: { tag: InsightTag }) {
	const unifiedInsightTypes = useMemo(
		() =>
			reduce<Insight, Insight[]>(
				tag.insight_types,
				(groupedInsights, insight) => {
					const hasTimeframeDefined = insight.insight_type?.display_name.includes('|');
					if (!hasTimeframeDefined || !insight.insight_type) return [...groupedInsights, insight];

					const splitDisplayName = insight.insight_type.display_name.split('|');
					const displayName: string = splitDisplayName[0];
					const timeframe = splitDisplayName[1];

					const insightGroup: Insight = groupedInsights.find(
						(insight) => displayName == insight.insight_type?.display_name
					) ?? {
						insight_type: {
							display_name: displayName,
							id: insight.insight_type.id,
							description: insight.insight_type.description,
						},
						timeframeOptions: [],
					};
					insightGroup.timeframeOptions?.push({
						id: insight.insight_type.id,
						name: timeframe,
						my_subscriptions: insight.insight_type.my_subscriptions,
					});
					return [...groupedInsights.filter((v) => displayName != v.insight_type?.display_name), insightGroup];
				},
				[]
			),
		[tag.insight_types]
	);
	return (
		<Flex
			direction={'column'}
			padding="24px"
			borderColor="gray.300"
			borderWidth={'1px'}
			borderStyle="solid"
			borderRadius={'4px'}
			marginBottom="28px"
		>
			<Typography variant={'DesktopH7Medium'} color="gray.800">
				{tag.id}
			</Typography>
			{unifiedInsightTypes.map((insight, index) => {
				return (
					<InsightRow
						key={insight.insight_type?.id}
						insight={insight}
						isLast={index == unifiedInsightTypes.length - 1}
					/>
				);
			})}
		</Flex>
	);
}
