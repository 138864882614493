import { Formatter, Property } from '../../types';
import {
	formatBooleanToDisplay,
	formatDateToDisplay,
	formatDollarToDisplay,
	formatStringToDisplay,
} from './displayStringFormatters';
import { getLeadTextValue } from './formatters';

export const propertyTableFormattersByType: {
	[key in Formatter | 'string' | 'boolean']: (value: any) => string;
} = {
	date: formatDateToDisplay,
	$: formatDollarToDisplay,
	string: formatStringToDisplay,
	boolean: formatBooleanToDisplay,
};

export const filterCells = (properties: Property[], searchTerm: string): Property[] => {
	const lowerCaseSearchTerm = searchTerm.toLowerCase();
	return properties.filter(isCellHasValue).filter((property) => searchFilter(property, lowerCaseSearchTerm));
};

const getFormatterToDisplay = (value: string | number | boolean, formatter?: Formatter | 'string' | 'boolean') => {
	if (formatter) {
		return formatter;
	}
	if (typeof value == 'boolean') {
		return 'boolean';
	}

	return 'string';
};

export const formattedDisplayValue = (
	{ overrideProps, value }: Pick<Property, 'value' | 'overrideProps'>,
	nodeSchemaFormatter?: Formatter
): string | null => {
	const displayValue = getLeadTextValue({ overrideProps, value });

	if (displayValue == null) {
		return null;
	}

	const formatter = getFormatterToDisplay(displayValue, nodeSchemaFormatter);

	return propertyTableFormattersByType[formatter](displayValue);
};

const isCellHasValue = (property: Property) => {
	const { overrideProps, value } = property;
	const valueToDisplay = overrideProps?.source_value ?? overrideProps?.value ?? value;
	return valueToDisplay != null;
};

const searchFilter = ({ displayName, value }: Property, searchTerm: string) =>
	searchTerm.length < 1 ||
	displayName.toLowerCase().includes(searchTerm) ||
	String(value).toLowerCase().includes(searchTerm);
