import { Box, Flex, RadioGroup } from '@chakra-ui/react';
import Input from '@components/Input';
import ModalFooter from '@components/ModalFooter';
import Select from '@components/Select';
import { TextArea } from '@components/Textarea';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { useModal } from '@hooks/ui/useModal';
import { AddSmall16, CQuestion16 } from '@icons/index';
import { useEffect, useMemo, useState } from 'react';
import Divider from 'src/common/components/Divider';
import ListItem from 'src/common/components/ListItem';
import { Radio } from 'src/common/components/Radio';
import { useCollectionsSubscriptions } from 'src/common/hooks/fetching/useCollectionsSubscriptions';
import { GetCollectionsSubscription } from 'src/generated/graphql';
import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { FillDetailsHeader } from 'src/pages/MetricPage/components/ShareSignalModal/FillDetailsHeader';
import { useReportEvent } from 'src/services/analytics';
import { CollectionType } from 'src/types/signal';
import classes from './ShareSignalModal.module.scss';

export type ModalDetails = {
	title: string;
	collectionType?: CollectionType;
	collectionName: string;
	description?: string;
	publishAs: 'graph' | 'table';
};

export function FillDetailsModal({
	metricTitle,
	description,
	onSubmit,
	collectionType,
	rawCollections,
	onClose,
	onBack,
	isLoading,
	isShowingPublishAs,
	submitButtonText = 'Create',
	isShowingDialogTitle = true,
	shouldShowSelectCollection = false,
}: {
	shouldShowSelectCollection?: boolean;
	metricTitle: string;
	description?: string;
	onSubmit: (details: ModalDetails, rawCollections?: GetCollectionsSubscription) => void;
	collectionType: CollectionType;
	rawCollections?: GetCollectionsSubscription;
	onClose: () => void;
	onBack?: () => void;
	isLoading: boolean;
	isShowingPublishAs: boolean;
	submitButtonText?: string;
	isShowingDialogTitle?: boolean;
}) {
	const { reportEvent } = useReportEvent();
	const [modalData, setModalData] = useState<ModalDetails>({
		collectionType,
		publishAs: 'graph',
		title: metricTitle,
		description: description ?? '',
		collectionName: '',
	});

	const { rawDashboardsList, rawWorkspacesList } = useCollectionsSubscriptions();
	const updatedRawCollections: GetCollectionsSubscription | undefined = useMemo(() => {
		if (rawCollections != null) {
			return rawCollections;
		}

		return {
			workspace: rawWorkspacesList,
			dashboard: rawDashboardsList,
		}[collectionType];
	}, [collectionType, rawCollections, rawWorkspacesList, rawDashboardsList]);

	useEffect(() => {
		setModalData((d) => ({ ...d, description: description ?? '', title: metricTitle ?? '' }));
	}, [description, metricTitle]);
	const {
		isOpen: isOpenUpsertCollection,
		onOpen: onOpenUpsertCollection,
		onClose: onCloseUpsertCollection,
	} = useModal();
	const [isCollectionSelectOpen, setIsCollectionSelectOpen] = useState(false);

	const shouldAllowSubmit = !shouldShowSelectCollection || !!modalData.collectionName || !updatedRawCollections;
	const isHavingSelectedOption = !!modalData?.collectionName;
	const selectedCollectionOption = isHavingSelectedOption ? { value: modalData.collectionName } : undefined;
	const smallScreenProps =
		window.innerHeight < 740 ? ({ maxHeight: `${window.innerHeight - 280}px`, overflowY: 'scroll' } as const) : {};

	return (
		<Box>
			{isShowingDialogTitle && <FillDetailsHeader onBack={onBack} collectionType={collectionType} />}
			<Flex
				direction={'column'}
				paddingTop={'21px'}
				paddingBottom="21px"
				paddingX={'8px'}
				gap={'32px'}
				{...smallScreenProps}
			>
				<Input
					size={'md'}
					label={'Title'}
					value={modalData.title}
					autoFocus={true}
					onChange={(title: string) => setModalData({ ...modalData, title })}
				/>
				<div>
					<Box mb={'8px'}>
						<Typography variant={'Paragraph16SB'} color={'gray.800'}>
							Description &nbsp;
						</Typography>
						<Typography variant={'Paragraph16R'} color={'gray.700'}>
							(Optional)
						</Typography>
					</Box>
					<TextArea
						placeholder={'Describe this signal'}
						height={'120px'}
						value={modalData.description}
						onChange={(d) => setModalData({ ...modalData, description: d })}
						onSubmit={() => onSubmit(modalData, updatedRawCollections)}
					/>
				</div>
				{shouldShowSelectCollection && (
					<Flex direction={'column'}>
						<Typography variant={'Paragraph16SB'} color={'gray.800'} mb={'8px'}>
							Pick a {collectionType}
						</Typography>
						<Select
							size={'medium'}
							placeholder={'Select'}
							options={
								updatedRawCollections?.workspaces?.map((collection) => ({
									value: collection.name,
									label: collection.name,
								})) ?? []
							}
							menuIsOpen={isCollectionSelectOpen}
							onMenuOpen={() => setIsCollectionSelectOpen(true)}
							onMenuClose={() => setIsCollectionSelectOpen(false)}
							width={'100%'}
							selectedOption={selectedCollectionOption}
							onChange={(option) => setModalData({ ...modalData, collectionName: option.value })}
							isSearchable={true}
							stickyFooter={
								<CreateCollectionStickyFooter
									collectionType={collectionType}
									onOpenUpsertCollection={() => {
										setIsCollectionSelectOpen(false);
										onOpenUpsertCollection();
									}}
								/>
							}
							maxMenuHeight={200}
						/>
					</Flex>
				)}

				{isShowingPublishAs && (
					<Flex direction={'column'}>
						<Flex alignItems={'center'} pb={'8px'}>
							<Typography variant={'Paragraph16SB'} color={'gray.800'} paddingRight={'8px'}>
								Publish as
							</Typography>
							<Tooltip
								size={'md'}
								label={"Publish either the Chart or the Table that you're looking at"}
								placement={'top'}
								cursor={'pointer'}
							>
								<CQuestion16 className={classes.questionMark} />
							</Tooltip>
						</Flex>
						<RadioGroup
							onChange={(value: 'graph' | 'table') => setModalData({ ...modalData, publishAs: value })}
							value={modalData.publishAs}
						>
							<Flex gap={'24px'}>
								<Radio value={'graph'} isChecked>
									Chart (default)
								</Radio>
								<Radio value={'table'}> Table </Radio>
							</Flex>
						</RadioGroup>
					</Flex>
				)}
			</Flex>
			<Flex flexDirection="row" justifyContent={'end'} shadow={'borderTop'} marginX={'-16px'}>
				<ModalFooter
					size={'md'}
					primaryButtonLabel={submitButtonText}
					onCancel={() => {
						onClose();
						setModalData((d) => ({ ...d, description: description ?? '', title: metricTitle ?? '' }));
					}}
					onPrimaryClick={() => onSubmit(modalData, updatedRawCollections)}
					isPrimaryEnabled={shouldAllowSubmit}
					isPrimaryLoading={isLoading}
					paddingY={0}
				/>
			</Flex>
			<CollectionUpsertModal
				stopRedirect
				isOpen={isOpenUpsertCollection}
				onClose={(args?: { name: string; id?: string }) => {
					const name = args?.name;

					if (name) {
						reportEvent({
							event: 'new-collection-from-signal-draft',
							metaData: { collectionName: name, collectionType: collectionType },
						});
						setModalData({ ...modalData, collectionName: name });
					}
					onCloseUpsertCollection();
				}}
				collectionType={collectionType}
			/>
		</Box>
	);
}

function CreateCollectionStickyFooter({
	collectionType,
	onOpenUpsertCollection,
}: {
	collectionType: CollectionType;
	onOpenUpsertCollection: () => void;
}) {
	return (
		<Flex direction="column">
			<Divider direction="horizontal" ml="16px" mr="16px" mb="8px" />
			<ListItem
				size="sm"
				label={`Create new ${collectionType}`}
				prefixIcon={
					<Box color="black">
						<AddSmall16 />
					</Box>
				}
				onClick={onOpenUpsertCollection}
			/>
		</Flex>
	);
}
