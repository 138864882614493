import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Typography from 'src/common/components/Typography';

export function ExampleText({
	marginTop,
	marginBottom,
	text,
}: {
	marginTop: string;
	marginBottom?: string;
	text: ReactNode;
}) {
	return (
		<Box
			marginBottom={marginBottom}
			marginTop={marginTop}
			backgroundColor={'gray.1000'}
			padding={'2px 8px'}
			borderRadius={'2px'}
		>
			<Typography color={'lime.700'} variant="Paragraph12M">
				{text}
			</Typography>
		</Box>
	);
}
