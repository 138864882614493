import { useCallback } from 'react';
import Flex from '@components/Flex';
import Divider from '@components/Divider';
import Tooltip from '@components/Tooltip';
import Button from '@components/Button';
import { AddColumn16, ChevronLeftSolid16, DownloadSolid16, FilterOutline16 } from '@components/Icons';
import { GridTableApi, useGridTableApi } from './GridTableHooks';

export type GridTableHeaderIconActionProps = {
	icon: React.ReactElement;
	text?: string;
	tooltip?: React.ReactNode;
	onClick?: (gridTableApi: GridTableApi) => void;
	children?: React.ReactNode | React.ReactNode[];
};

export function GridTableHeaderIconAction({ icon, text, tooltip, onClick, children }: GridTableHeaderIconActionProps) {
	const { gridTableApi } = useGridTableApi();

	const onClickWithApi = useCallback(() => {
		onClick?.(gridTableApi);
	}, [gridTableApi, onClick]);

	return (
		<>
			<Tooltip size={'md'} label={tooltip}>
				<Button
					variant={'outline'}
					isIconOnly={!text}
					size={'small'}
					colorScheme={'lightGray'}
					onClick={onClickWithApi}
					leftIcon={text ? icon : undefined}
				>
					{text ?? icon}
				</Button>
			</Tooltip>
			{children}
		</>
	);
}

export function GridTableHeaderActions({ children }: { children: React.ReactNode | React.ReactNode[] }) {
	return (
		<Flex alignItems={'center'} justifyContent={'flex-start'} paddingLeft={'16px'}>
			{children}
		</Flex>
	);
}

export function GridTableHeaderActionDivider() {
	return <Divider color={'gray.300'} direction={'vertical'} paddingY={'10px'} marginX={'8px'} />;
}

export function GridTableHeaderActionAddColumn({ onClick }: Pick<GridTableHeaderIconActionProps, 'onClick'>) {
	return <GridTableHeaderIconAction tooltip={'Add column'} icon={<AddColumn16 />} onClick={onClick} />;
}

export function GridTableHeaderActionFilter({ onClick }: Pick<GridTableHeaderIconActionProps, 'onClick'>) {
	return <GridTableHeaderIconAction tooltip={'Filter columns'} icon={<FilterOutline16 />} onClick={onClick} />;
}

export function GridTableHeaderActionExport({ onClick }: Pick<GridTableHeaderIconActionProps, 'onClick'>) {
	return <GridTableHeaderIconAction tooltip={'Export table'} icon={<DownloadSolid16 />} onClick={onClick} />;
}

export function GridTableHeaderActionBack({ onClick }: Pick<GridTableHeaderIconActionProps, 'onClick'>) {
	return <GridTableHeaderIconAction tooltip={'Back'} icon={<ChevronLeftSolid16 />} onClick={onClick} />;
}

export type GridTableHeaderActions = React.ReactNode;
