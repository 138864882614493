import { Box } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import Flex from 'src/common/components/Flex';
import { DimensionsGroup90, Search32 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import { useSemanticDefinitionsForEntity } from 'src/common/hooks/stores/useSemanticDefinitions';
import { RelationshipsAndDimensionsQuery } from 'src/generated/graphql';

import { OntologyStateAtomDerived } from '../../atoms/OntologyState';
import { OntologyDimensionInfo, OntologyRelationshipInfo } from '../../utils/normalizeYaml';
import DimensionItem from './DimensionItem';
import ListHeader from './ListHeader';
import NoResults from './NoResults';

import { getAffectedMetricsList } from '../../utils/utils';

export default function DimensionsList({
	onSelect,
	onCreateNewClick,
	isEditable = true,
	dimensionsFromQuery,
	searchValue,
	list,
	onSearch,
	setPropertyToDelete,
}: {
	onSelect: (val: OntologyDimensionInfo) => void;
	onCreateNewClick: VoidFunction;
	isEditable?: boolean;
	dimensionsFromQuery?: RelationshipsAndDimensionsQuery['relationshipsAndDimensions']['entities'][0]['dimensions'];
	searchValue?: string;
	list: OntologyDimensionInfo[];
	onSearch: (val?: string) => void;
	setPropertyToDelete: (val: OntologyDimensionInfo | OntologyRelationshipInfo) => void;
}) {
	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const entitySemanticDefinition = useSemanticDefinitionsForEntity(
		ontologyState.loading ? undefined : ontologyState.entityName
	);
	const isActiveSearch = searchValue != undefined;
	const isListHeaderVisible = list.length || isActiveSearch;

	const dimensionItemsList = useMemo(() => {
		return list.map((el, i) => (
			<Box onClick={() => onSelect(el)} key={i}>
				<DimensionItem
					isEditable={isEditable}
					onDelete={() => setPropertyToDelete(el)}
					item={el}
					isFullyDefined={
						entitySemanticDefinition?.dimensions.find((dimension) => dimension.name == el.name)?.isDefined
					}
					affectedMetricsList={dimensionsFromQuery && getAffectedMetricsList(el, dimensionsFromQuery)}
				/>
			</Box>
		));
	}, [entitySemanticDefinition?.dimensions, list, onSelect, isEditable, setPropertyToDelete, dimensionsFromQuery]);

	return (
		<Flex flexDir={'column'} gap={'24px'}>
			<Typography variant="Paragraph12R" color={'gray.700'}>
				Dimensions are columns in an entity&apos;s table that may be used across metrics.
			</Typography>
			<Flex alignSelf={'stretch'} flexDir={'column'}>
				{isListHeaderVisible && (
					<ListHeader
						isEditable={isEditable}
						searchValue={searchValue}
						onAdd={onCreateNewClick}
						onSearch={onSearch}
						total={list.length}
						type="dimensions"
					/>
				)}
				<Box marginTop={'12px'}>
					{!list.length ? (
						<Box marginTop={'110px'}>
							{searchValue ? (
								<NoResults
									icon={<Search32 />}
									title="No dimensions found."
									description="Try adjusting your search to find the dimension you're looking for."
								/>
							) : (
								!isActiveSearch && (
									<NoResults
										buttonLabel={'Create a dimension'}
										onClick={onCreateNewClick}
										icon={<DimensionsGroup90 />}
										title="No dimensions yet."
										description="Add a simple mapping dimension or create a more complex calculated dimension."
									/>
								)
							)}
						</Box>
					) : (
						dimensionItemsList
					)}
				</Box>
			</Flex>
		</Flex>
	);
}
