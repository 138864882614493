import Typography from '@components/Typography';
import pluralize from 'pluralize';

export const ToastMessage = ({ metricsAdded }: { metricsAdded: number }) => {
	return (
		<Typography variant="Paragraph14R" color="gray.800">
			Template was successfully installed:&nbsp;
			<Typography variant="Paragraph14SB">
				{metricsAdded} new {pluralize('metric', metricsAdded)}&nbsp;
			</Typography>
			were added.
		</Typography>
	);
};
