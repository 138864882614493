import { PrimitiveAtom, atom, useAtom } from 'jotai';
import { useAskAIBusyState } from './useAskAIQueryRunState';
import { useCallback } from 'react';
import { AskAIChatMessage } from '../types';

const globalScope = Symbol();
export const ChatMessagesAtom = atom<PrimitiveAtom<AskAIChatMessage>[]>([]);

export function useAskAIChatMessages() {
	const [chatMessages, setChatMessages] = useAtom(ChatMessagesAtom, globalScope);

	const [, setAskAIBusyState] = useAskAIBusyState();

	const addChatMessage = useCallback(
		(message: Omit<AskAIChatMessage, 'messageKey'>) => {
			setAskAIBusyState(true);
			const chatMessageAtom = atom({ ...message, messageKey: `${Date.now()}-${Math.random()}` });
			setChatMessages((prev) => [...prev, chatMessageAtom]);
		},
		[setAskAIBusyState, setChatMessages]
	);

	const addNewUserPrompt = useCallback(
		(userPrompt: string) => {
			if (userPrompt.length === 0) return;
			addChatMessage({
				userPrompt,
			});
		},
		[addChatMessage]
	);

	const resetChatMessages = useCallback(() => {
		setAskAIBusyState(false);
		setChatMessages(() => []);
	}, [setChatMessages, setAskAIBusyState]);

	return { chatMessages, addNewUserPrompt, addChatMessage, resetChatMessages };
}
