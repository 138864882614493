import { AbsolutePeriodRange, MetricPeriod } from '@sightfull/period-ranges';
import { DetailedTableIdGroupByV1, DetailedTableIdGroupByV2 } from 'src/common/utils/bizapiParsers';
import { useEntityProfileDrawer } from 'src/layout/EntityProfileDrawer/hooks';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { COLUMN_MODEL_TYPE } from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import { OnModalOpenType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import Table from 'src/pages/MetricPage/components/Table/RawTable';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import useFeatureFlag from '../../../../common/hooks/stores/useFeatureFlag';
import { useSelectedXAxisElements } from '../../hooks/useSelectedXAxisElement';
import { RawMetricTableV2 } from './RawTable/RawMetricTableV2';
import { useIsGridTableV2Enabled } from '../../../../common/components/GridTable';
import { useAddAndRemoveTableColumns } from '../../hooks/useAddAndRemoveTableColumns';

export function MultiPeriodDetailedTable({
	onModalOpen,
	isThumbnail = false,
}: {
	isThumbnail?: boolean;
	onModalOpen?: OnModalOpenType;
}) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const { tableRowsData, selectedXAxisElements, metricNameWithoutFlavor } = useMetricDerivedState();
	const [, setSelectedXAxisElement] = useSelectedXAxisElements();
	const { handleAddColumns, removableTableColDefs } = useAddAndRemoveTableColumns();
	const { pushEntity } = useEntityProfileDrawer();

	const isGridTableV2 = useIsGridTableV2Enabled();
	const RawTableImpl = isGridTableV2 ? RawMetricTableV2 : Table;

	const clearSelectedXAxisElements = () => {
		setSelectedXAxisElement({ xAxisElements: [] });
	};

	const selectedAxisIds = selectedXAxisElements.map((e) => (e instanceof MetricPeriod ? e.id : e));
	let subtitle = '';
	if (selectedAxisIds.length > 1)
		subtitle = AbsolutePeriodRange.fromIdStrings(
			selectedAxisIds[0],
			selectedAxisIds[selectedAxisIds.length - 1],
			fiscalYearOffset()
		).prettyString;

	return (
		<RawTableImpl
			title={metricNameWithoutFlavor}
			subtitle={subtitle}
			data={tableRowsData}
			columnDefs={removableTableColDefs}
			onCloseClicked={clearSelectedXAxisElements}
			onAddColumn={() => onModalOpen?.(COLUMN_MODEL_TYPE, handleAddColumns)}
			onRowClicked={(e) => {
				if (!e.data) return;
				const nodeId = isSightfull2 ? e.data[DetailedTableIdGroupByV2] : e.data[DetailedTableIdGroupByV1];
				pushEntity(nodeId);
			}}
			onSelectAllPeriodsClicked={clearSelectedXAxisElements}
			isThumbnail={isThumbnail}
			shouldSuppressDotNotation={true} // TODO: We currently use dot notation in the onject in multipperiod table, deltet this when we fix it
			dataIntercomTarget="detailed-table"
		/>
	);
}
