import { editor, languages, Position } from 'monaco-editor';
import { CompletionProvider, DimensionContextTypes } from '../completionProvider';
import { EnrichedSemanticDefinitions } from '../semanticTypes';
import { AUTO_COMPLETE_YAML_KEYS } from '../types';
import { findYamlKey } from '../utils/findYamlKey';
import { getEntity, getPartAndExpressionRanges, rangeToPreviousCharacter } from '../utils/utils';
import { getContextCompletionItem } from '../widgetBuilder/completionContextTooltip';

export const OntologyBuilderYamlCompletionItemProviderBuilder = (
	semanticDefinitions: EnrichedSemanticDefinitions,
	entity: string
): languages.CompletionItemProvider => ({
	provideCompletionItems: buildMonacoCompletionProvider(semanticDefinitions, entity),
	triggerCharacters: ['.', '$', '('],
});

const buildMonacoCompletionProvider =
	(semanticDefinitions: EnrichedSemanticDefinitions, entity: string) =>
	(model: editor.ITextModel, position: Position) => {
		const { partRange, expressionRange, expression } = getPartAndExpressionRanges({ model, position });
		const hasDollarBeforeExpression = model.getValueInRange(rangeToPreviousCharacter(expressionRange)) == '$';

		const completion = new CompletionProvider(semanticDefinitions);

		const tokenPath = findYamlKey(model.getValue(), model.getOffsetAt(position));
		if (!tokenPath) {
			console.error('Failed to get document path', expression, model.getValue(), model.getOffsetAt(position));
			return;
		}
		const lastYamlKey = tokenPath.split('.').reverse()[0];
		const isYamlKeySuitableForAutocomplete = AUTO_COMPLETE_YAML_KEYS.includes(lastYamlKey);
		if (!isYamlKeySuitableForAutocomplete) {
			console.info('Refusing to autocomplete based on key', lastYamlKey);
			return;
		}

		const enrichedEntity = getEntity(semanticDefinitions, entity);
		const context = completion.createContexts({
			entity: enrichedEntity,
			includingTypes: [...DimensionContextTypes, 'relationship'],
		});

		const completionContexts = completion.walkContextForCompletions(
			context,
			expression,
			expression.length,
			false,
			true
		);

		const completionItems = completionContexts.map((context) =>
			getContextCompletionItem(context, partRange, hasDollarBeforeExpression ? '' : '$', false)
		);

		return {
			suggestions: completionItems,
		};
	};
