import Box from '@components/Box';
import { BubbleSeries, ChartSeries } from '@components/Chart/types';
import Flex from '@components/Flex';
import { forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Popover from 'src/common/components/Popover';
import Typography from 'src/common/components/Typography';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import { DisplayLegendItems } from 'src/pages/MetricPage/utils/state.types';
import {
	ENTIRE_LEGEND_NAME_MAX_WIDTH,
	ENTIRE_LEGEND_NAME_MIN_WIDTH,
	LEGEND_BAR_HEIGHT,
	LEGEND_BAR_PADDING_X,
	LEGEND_BAR_PLUS_MORE_WIDTH,
	LEGEND_NAME_GAP,
	LEGEND_NAME_MAX_WIDTH,
} from '../../constants';
import { LegendColorSquare } from './LegendColorSquare';

const MAXIMUM_WIDTH_POSSIBLE = '100%';

const LegendName = forwardRef(
	(
		props: { series: ChartSeries | BubbleSeries; isOverflowing?: boolean; maxWidth?: string },
		ref?: React.Ref<HTMLDivElement>
	) => {
		const maxWidth = props?.maxWidth ?? MAXIMUM_WIDTH_POSSIBLE;
		return (
			<Flex flexDirection="row" alignItems="center">
				<LegendColorSquare color={props.series.color} />
				<Typography
					variant="Paragraph12R"
					color="gray.700"
					_hover={{ color: props.isOverflowing ? 'gray.1000' : 'gray.700' }}
					cursor={'default'}
					maxWidth={maxWidth}
					whiteSpace={maxWidth == MAXIMUM_WIDTH_POSSIBLE ? 'nowrap' : undefined}
					noOfLines={1}
					wordBreak="break-all"
				>
					<div ref={ref}>{props.series.name}</div>
				</Typography>
			</Flex>
		);
	}
);

LegendName.displayName = 'LegendName';

interface LegendNameRef {
	getWidth: () => number | undefined;
}

const LegendNameWithTooltip = forwardRef(
	(props: { series: ChartSeries | BubbleSeries; maxWidth: string }, ref: React.Ref<LegendNameRef>) => {
		const textRef = useRef<HTMLDivElement | null>(null);
		const legendRef = useRef<HTMLDivElement | null>(null);
		const isOverflowing = useOnOverflow(textRef, []);

		useImperativeHandle(ref, () => ({
			getWidth() {
				return legendRef.current?.scrollWidth;
			},
		}));

		let legendName = (
			<LegendName series={props.series} ref={textRef} isOverflowing={isOverflowing} maxWidth={props.maxWidth} />
		);

		if (isOverflowing) {
			legendName = (
				<Popover triggerElement={legendName} trigger="hover" placement="bottom">
					<Box paddingX="16px" paddingY="10px">
						<LegendName series={props.series} />
					</Box>
				</Popover>
			);
		}

		return <Box ref={legendRef}>{legendName}</Box>;
	}
);

LegendNameWithTooltip.displayName = 'LegendNameWithTooltip';

export function HorizontalLegend(props: {
	series: ChartSeries[];
	bubbles?: BubbleSeries[];
	displayedLegendItems?: DisplayLegendItems;
}) {
	const horizontalRef = useRef<HTMLDivElement | null>(null);
	const legendNamesToRefs = useRef<Record<string, LegendNameRef>>({});

	const orderedSeries = useMemo(() => {
		const mainSeries = props.series.filter((series) => series.custom.seriesType == 'main');
		const componentsSeries = props.series.filter((series) => series.custom.seriesType == 'component');

		const metricSeries = [...mainSeries, ...componentsSeries].filter((series) =>
			(props.displayedLegendItems?.selectedValues ?? []).includes(series.name)
		);
		const statisticsSeries = props.series.filter((series) => series.custom.seriesType == 'statistic');
		const bubblesSeries = props.bubbles ?? [];

		return [...metricSeries, ...statisticsSeries, ...bubblesSeries];
	}, [props]);

	const initialLegendState = useMemo(() => {
		return {
			legendMaxWidth: MAXIMUM_WIDTH_POSSIBLE,
			shownLegendsCount: orderedSeries.length,
		};
	}, [orderedSeries]);

	const [horizontalLegendState, setHorizontalLegendState] = useState(initialLegendState);

	const seriesToShow = orderedSeries.slice(0, horizontalLegendState.shownLegendsCount);
	const seriesOnTooltip = orderedSeries.slice(horizontalLegendState.shownLegendsCount, orderedSeries.length);
	const isShowingMorePopover = seriesOnTooltip.length > 0;

	const horizontalLegendWidth = horizontalRef?.current?.clientWidth;
	const [currentHorizontalWidth, setCurrentHorizontalWidth] = useState<number | undefined>();

	useEffect(() => {
		if (!horizontalLegendWidth) return;

		const seriesNames = orderedSeries.map((series) => series.name);
		const legendNamesRefs = Object.keys(legendNamesToRefs.current)
			.sort((legend1, legend2) => seriesNames.indexOf(legend1) - seriesNames.indexOf(legend2))
			.map((legendName) => legendNamesToRefs.current[legendName]);

		if (legendNamesRefs.length != orderedSeries.length) return;

		const legendWidths = legendNamesRefs.map((legendNameRef) => legendNameRef?.getWidth() ?? -1);
		if (legendWidths.includes(-1)) return;

		const totalHorizontalPadding = LEGEND_BAR_PADDING_X * 2;
		const widthSum = legendWidths.reduce(
			(width1: number, width2: number) => width1 + width2 + LEGEND_NAME_GAP,
			LEGEND_NAME_GAP
		);
		let totalWidthOfLegends = totalHorizontalPadding + widthSum;

		if (totalWidthOfLegends <= horizontalLegendWidth) {
			return;
		}

		const calculateLegendsCount = (withMoreBox: boolean) => {
			let legendsCount;
			totalWidthOfLegends = totalHorizontalPadding + (withMoreBox ? LEGEND_BAR_PLUS_MORE_WIDTH : 0);
			for (legendsCount = 0; legendsCount < legendNamesRefs.length; legendsCount++) {
				const legendNameRef = legendNamesRefs[legendsCount];

				let width = legendNameRef.getWidth() ?? 0 + LEGEND_NAME_GAP;
				width = Math.max(width, ENTIRE_LEGEND_NAME_MIN_WIDTH);
				width = Math.min(width, ENTIRE_LEGEND_NAME_MAX_WIDTH);

				totalWidthOfLegends += width;

				if (totalWidthOfLegends > horizontalLegendWidth) {
					break;
				}
			}

			return legendsCount;
		};

		let legendsCount = calculateLegendsCount(false);
		if (legendsCount < legendNamesRefs.length) {
			legendsCount = calculateLegendsCount(true);
		}

		const newMaxWidth = `${LEGEND_NAME_MAX_WIDTH}px`;
		const newMaximumLegendsToShow = legendsCount;

		const newState = {
			legendMaxWidth: newMaxWidth,
			shownLegendsCount: newMaximumLegendsToShow,
		};

		setHorizontalLegendState(newState);
	}, [horizontalLegendWidth, orderedSeries, currentHorizontalWidth, initialLegendState]);

	const seriesToShowNodes: ReactNode[] = useMemo(
		() =>
			seriesToShow.map((series) => {
				return (
					<div key={series.name}>
						<LegendNameWithTooltip
							series={series}
							ref={(element: LegendNameRef) => {
								if (element != null) {
									legendNamesToRefs.current[series.name] = element;
								}
							}}
							maxWidth={horizontalLegendState.legendMaxWidth}
						/>
					</div>
				);
			}),
		[seriesToShow, horizontalLegendState]
	);

	const morePopover: ReactNode = useMemo(() => {
		return (
			<Popover
				triggerElement={
					<Flex>
						<Typography
							variant="Paragraph12R"
							color="gray.700"
							textOverflow="ellipsis"
							_hover={{ color: 'gray.1000' }}
							cursor="default"
							width={`${LEGEND_BAR_PLUS_MORE_WIDTH}px`}
						>
							{`+${seriesOnTooltip.length} more`}
						</Typography>
					</Flex>
				}
				trigger="hover"
				placement="bottom"
			>
				<Box paddingX="16px" paddingY="10px">
					<Flex flexDirection="column" justifyContent="center">
						{seriesOnTooltip.map((series) => {
							return <LegendName series={series} key={series.name} />;
						})}
					</Flex>
				</Box>
			</Popover>
		);
	}, [seriesOnTooltip]);

	return (
		<Flex
			flexDirection="row"
			justifyContent="flex-start"
			alignItems="center"
			textAlign="start"
			gap={`${LEGEND_NAME_GAP}px`}
			height={`${LEGEND_BAR_HEIGHT}px`}
			width="100%"
			backgroundColor="white"
			ref={(ref) => {
				const horizontalLegendWidth = ref?.clientWidth;
				if (horizontalLegendWidth != currentHorizontalWidth && !horizontalRef.current) {
					legendNamesToRefs.current = {};
					setCurrentHorizontalWidth(horizontalLegendWidth);
					setHorizontalLegendState(initialLegendState);
					horizontalRef.current = ref;
				}
			}}
		>
			{seriesToShowNodes}
			{isShowingMorePopover && morePopover}
		</Flex>
	);
}
