import { Box, Flex } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import Input from 'src/common/components/Input';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import { TestIDs } from 'src/common/types/test-ids';
import { capitalizedFirstLetter } from 'src/common/utils/format';
import { UNTITLED_METRIC_DISPLAY } from 'src/lib/metricRules/builder/useMetricBuilder';
import { DashboardBadge } from 'src/pages/DashboardPage/Header/DashboardBadge';
import { WorkspaceBadge } from 'src/pages/WorkspacePage/Badge';
import { CollectionType } from 'src/types/signal';
import { CollectionMetadata, collectionNames } from 'src/types/spaces';

export function TitleAndDescription({
	title,
	description,
	isTiny,
	isEditMode = false,
	isWorkspaceTitle = false,
	isEditBlocked = false,
	collectionType,
	onSaved,
}: CollectionMetadata & {
	isEditMode?: boolean;
	isWorkspaceTitle?: boolean;
	isTiny: boolean;
	isEditBlocked: boolean;
	onSaved: ({ title, description }: CollectionMetadata) => void;
	collectionType: CollectionType;
}) {
	const [formValues, setFormValues] = useState({ title, description });
	const [inputState, setInputState] = useState({ isActiveEdit: false, isEditTitle: true });
	const [hasRrror, setHasError] = useState(false);
	const collectionName: string = collectionNames[collectionType];
	const descriptionRef = useRef(null);
	const isOverflowingDescription = useOnOverflow(descriptionRef, [description], undefined, false);

	function onSubmit() {
		if (inputState.isActiveEdit) {
			if (!formValues.title) {
				setHasError(true);
				return;
			}

			onSaved({ title: capitalizedFirstLetter(formValues.title), description: formValues.description });
			setInputState({ ...inputState, isActiveEdit: false });
		}
	}

	const AddDescription = () => {
		if (isEditBlocked) {
			return;
		}
		return (
			<Box color="gray.500" transition={'all 0.2s ease'} _hover={{ color: 'gray.600' }}>
				<Typography
					cursor={'pointer'}
					variant={isTiny ? 'DesktopH8Regular' : 'DesktopH7Regular'}
					noOfLines={1}
					textOverflow="ellipsis"
					onClick={() => setInputState({ ...inputState, isActiveEdit: true, isEditTitle: false })}
				>
					Add description
				</Typography>
			</Box>
		);
	};

	const { isActiveEdit, isEditTitle } = inputState;

	return (
		<Flex width={'100%'} alignItems="center" gap="8px" flexDirection={'row'}>
			{isActiveEdit ? (
				<Input
					data-testid={TestIDs.SPACES_HEADER_TITLE_INPUT}
					isInvalid={hasRrror}
					autoFocus
					size="sm"
					width={'320px'}
					placeholder={isEditTitle ? `${collectionName} name` : `Describe this ${collectionName.toLowerCase()}`}
					value={isEditTitle ? formValues.title : formValues.description}
					onEnter={onSubmit}
					onBlur={onSubmit}
					onChange={(val: string) => {
						setHasError(false);
						if (isEditTitle) setFormValues({ ...formValues, title: val });
						else setFormValues({ ...formValues, description: val.replaceAll('\n', '') });
					}}
				/>
			) : (
				<Flex width={'100%'} direction="column" gap={'4px'}>
					<Flex direction="row" gap="12px" alignContent={'center'} alignItems={'center'}>
						<Flex
							maxWidth={'100%'}
							transition={'all 0.2s ease'}
							_hover={{ color: 'blue.700' }}
							alignItems={'center'}
							color="gray.1000"
						>
							{!isTiny && (isWorkspaceTitle ? <WorkspaceBadge /> : <DashboardBadge />)}

							<Typography
								onClick={() => {
									if (isEditBlocked) return;
									setInputState({ ...inputState, isActiveEdit: true, isEditTitle: true });
								}}
								display={'inline-block'}
								cursor={isEditBlocked ? 'default' : 'pointer'}
								variant={isTiny ? 'DesktopH8Medium' : 'DesktopH7Medium'}
								noOfLines={1}
								maxWidth={'100%'}
								textOverflow="ellipsis"
								data-testid={TestIDs.SPACES_HEADER_TITLE}
							>
								{title || UNTITLED_METRIC_DISPLAY}
							</Typography>
						</Flex>

						<Flex
							flex={1}
							transition={'all 0.2s ease'}
							_hover={{ color: 'gray.800' }}
							alignItems={'center'}
							color="gray.700"
							data-testid={'header-description'}
						>
							{!description && (isEditMode || isWorkspaceTitle) && AddDescription()}
							{description && (
								<Tooltip
									wrapperStyle={{ width: 'fit-content', maxHeight: '20px' }}
									backgroundColor={'black'}
									label={isOverflowingDescription && description}
									placement="bottom"
									size="md"
									marginLeft="4px"
									hasArrow
									maxWidth={260}
								>
									<Typography
										onClick={() => {
											if (isEditBlocked) return;
											setInputState({ ...inputState, isActiveEdit: true, isEditTitle: false });
										}}
										cursor={isEditBlocked ? 'default' : 'pointer'}
										textAlign={'start'}
										variant={isTiny ? 'DesktopH8Regular' : 'DesktopH7Regular'}
									>
										<Box noOfLines={1} ref={descriptionRef} textOverflow="ellipsis">
											{description}
										</Box>
									</Typography>
								</Tooltip>
							)}
						</Flex>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
}
