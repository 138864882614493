import Box from 'src/common/components/Box';
import Flex from 'src/common/components/Flex';
import Pagination from 'src/common/components/Pagination';
import Typography from 'src/common/components/Typography';

export function EntityTableHeaderV2({
	entityDisplayName,
	onNext,
	onPrevious,
	totalRows,
	tablePaginationLimit,
}: {
	entityDisplayName: string;
	onNext?: () => void;
	onPrevious?: () => void;
	totalRows: number;
	tablePaginationLimit: number;
}) {
	return (
		<Box>
			<Flex width="100%" height="28px" gap="12px" alignItems="center">
				<Typography variant="DesktopH7Medium" color="gray.1000">
					{entityDisplayName}
				</Typography>
				<Pagination onNext={onNext} onPrevious={onPrevious} limit={tablePaginationLimit} totalCount={totalRows} />
			</Flex>
		</Box>
	);
}
