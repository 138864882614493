import { Box, Flex } from '@chakra-ui/react';
import Chart, { ChartComponentRef } from '@components/Chart/Chart';
import { ExportPngHeader } from '@components/MetricView/ExportPng/PngContentHeader';
import Popover from '@components/Popover';
import Tooltip from '@components/Tooltip';
import { useKeyPress } from '@hooks/interaction/useKeyPress';
import { useModal } from '@hooks/ui/useModal';
import useToast from '@hooks/ui/useToast';
import { Camera16, E20 } from '@icons/index';
import { toPng } from 'html-to-image';
import debounce from 'lodash/debounce';
import { useCallback, useRef } from 'react';
import { useOnCopy } from 'src/common/hooks/interaction/useOnCopy';
import { convertB64toBlob, downloadUrl } from 'src/common/utils/download';
import { keyByOperatingSystem } from 'src/common/utils/utils';
import { useDisplayedLegendState } from 'src/pages/MetricPage/components/LegendsPanel/useDisplayedLegendState';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import Button from '../../Button';

export function ExportPngButton({ isDisabled = false }: { isDisabled?: boolean }) {
	const { reportEvent, wrapWithReport } = useReportEvent();
	const { isOpen, onOpen, onClose } = useModal();
	const keypressEvent = (metaData?: object) => {
		return () => {
			const event = 'export-png-keypress';
			reportEvent({ event, metaData });
			onOpen();
		};
	};

	useKeyPress(['e'], keypressEvent({ isWithMetaKey: true }), { isWithMetaKey: true });
	useKeyPress(['e'], keypressEvent({ isWithCtrlKey: true }), { isWithCtrlKey: true });

	return (
		<Tooltip
			size={'md'}
			label={
				!isDisabled && (
					<Flex alignItems={'center'}>
						Chart Snapshot
						<Box as={'span'} pr={'4px'} pl={'12px'}>
							{keyByOperatingSystem}
						</Box>
						<E20 />
					</Flex>
				)
			}
			variant="fluid"
			placement={'bottom'}
		>
			<Box>
				<Button
					isIconOnly
					isDisabled={isDisabled}
					colorScheme={'gray'}
					size={'small'}
					border={`1px ${colors.gray['400']} solid`}
					_hover={{ backgroundColor: colors.gray['100'] }}
					onClick={wrapWithReport(onOpen, 'export-png-button-clicked')}
				>
					<Camera16 />
				</Button>
				<ExportPopover isOpen={isOpen} onClose={onClose} />
			</Box>
		</Tooltip>
	);
}

function ExportPopover({ isOpen, onClose }: { isOpen: boolean; onClose: () => any }) {
	const toast = useToast();
	const { onCopy } = useOnCopy();
	const [{ components, mainSeries }] = useDisplayedLegendState();
	const { periodRange, metricNameWithFlavor, statisticsOperations, metricDisplayName } = useMetricDerivedState();
	const metricLegendComponents = [...mainSeries, ...statisticsOperations, ...components].filter((m) => m.isChecked);
	const chartContainerRef = useRef(null);

	const tryExportingPng = useCallback(
		(pngWrapper: ChartComponentRef | null) => {
			try {
				if (!pngWrapper || !chartContainerRef.current) return;

				toPng(chartContainerRef.current)
					.then(function (b64Data) {
						downloadUrl(b64Data, 'Sightfull' + metricNameWithFlavor);

						const item = new ClipboardItem({
							'image/png': convertB64toBlob(b64Data.replace('data:image/png;base64,', ''), 'image/png', 512),
						});

						onCopy({ contentToCopy: [item], variant: 'ok', message: 'Successfully downloaded & copied to clipboard.' });
					})
					.finally(onClose);
			} catch {
				toast({ variant: 'error', message: 'Failed exporting image.' });
			}
		},
		[metricNameWithFlavor, onClose, onCopy, toast]
	);
	const tryExportingPngDebounced = debounce(tryExportingPng, 200, { trailing: true, leading: false });

	return (
		<Popover isOpen={isOpen} onClose={onClose} styleConfig={{ top: `${window.innerHeight * 3}px` }}>
			<Box style={{ padding: '60px 0' }} width={window.innerWidth * 0.7} ref={chartContainerRef}>
				<>
					<ExportPngHeader
						metricLegendComponents={metricLegendComponents}
						metricDisplayName={metricDisplayName ?? metricNameWithFlavor}
						periodRange={periodRange}
					/>
					<Chart onColumnClick={() => void 0} ref={tryExportingPngDebounced} />
				</>
			</Box>
		</Popover>
	);
}
