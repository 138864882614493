import Menu from 'src/layout/Menu/Menu';
import TrialBarContainer from 'src/layout/TrialBar/TrialBarContainer';
import routes from 'src/pages/routes';

import { Provider } from 'jotai';
import Flex from 'src/common/components/Flex';
import useMentionsFetch from 'src/common/hooks/fetching/useMentionsFetch';
import useEmbedMode from 'src/common/hooks/navigation/useEmbedSetting';
import { EntityProfileScope } from 'src/layout/EntityProfileDrawer/atoms';
import { useInitSemantics } from 'src/stores/semantics';
import classes from './Page.module.scss';

function AppLayout() {
	const [embedSetting] = useEmbedMode();
	useMentionsFetch();
	useInitSemantics();

	return (
		<Flex direction={'column'} width="100vw" maxH="100vh" height="100vh" className={classes.appLayout}>
			<TrialBarContainer />
			<Provider scope={EntityProfileScope}>
				{embedSetting !== 'widget' && <Menu />}
				{routes}
			</Provider>
		</Flex>
	);
}

export default AppLayout;
