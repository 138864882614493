import {
	Modal as ChakraModal,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	ModalContent,
	ModalOverlay,
	SpaceProps,
} from '@chakra-ui/react';
import * as CSS from 'csstype';
import { ReactNode } from 'react';
import shadows from 'src/style/shadows';

type ModalProps = {
	children: ReactNode;
	isOpen: boolean;
	onClose: () => void;
	hasOverlay?: boolean;
	closeOnOverlayClick?: boolean;
	onOverlayClick?: () => void;
	isCentered?: boolean;
	maxWidth?: CSS.Property.MaxWidth;
	minHeight?: CSS.Property.MinHeight;
	maxHeight?: CSS.Property.MaxHeight;
	zIndex?: number | string;
	borderRadius?: CSS.Property.BorderRadius;
	blockScrollOnMount?: boolean;
	overlayColor?: CSS.Property.Color;
	modalPosition?: 'floating' | 'docked';
	closeOnEsc?: boolean;
} & SpaceProps;

function Modal({
	children,
	isOpen,
	onClose,
	hasOverlay = true,
	closeOnOverlayClick = false,
	onOverlayClick,
	isCentered = false,
	maxWidth = '600px',
	minHeight,
	maxHeight,
	borderRadius = '8px',
	blockScrollOnMount,
	overlayColor = 'blue.1100',
	modalPosition = 'floating',
	closeOnEsc,
	...props
}: ModalProps) {
	const shadow = hasOverlay ? shadows['g-05'] : shadows['a-22'];
	const overlayOpacity = hasOverlay ? '20%' : '0';
	return modalPosition === 'floating' ? (
		<ChakraModal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={closeOnOverlayClick}
			onOverlayClick={onOverlayClick}
			motionPreset="none"
			isCentered={isCentered}
			blockScrollOnMount={blockScrollOnMount}
			closeOnEsc={closeOnEsc}
		>
			<ModalOverlay zIndex={props.zIndex} bg={overlayColor} opacity={overlayOpacity} />
			<ModalContent
				containerProps={{
					zIndex: props.zIndex,
				}}
				maxWidth={maxWidth}
				minHeight={minHeight}
				maxHeight={maxHeight}
				borderRadius={borderRadius}
				boxShadow={shadow}
				overflow="hidden"
				top={isCentered ? '0' : '6rem'}
				{...props}
			>
				{children}
			</ModalContent>
		</ChakraModal>
	) : (
		<Drawer
			isOpen={isOpen}
			placement={'right'}
			onClose={onClose}
			size={'lg'}
			closeOnOverlayClick={closeOnOverlayClick}
			onOverlayClick={onOverlayClick}
			blockScrollOnMount={blockScrollOnMount}
		>
			<DrawerOverlay bg={overlayColor} opacity={overlayOpacity} motionProps={{}} />
			<DrawerContent maxWidth={maxWidth} boxShadow={shadow} motionProps={{}} {...props}>
				{children}
			</DrawerContent>
		</Drawer>
	);
}

export default Modal;
