import { Box, Flex } from '@chakra-ui/react';
import Button from 'src/common/components/Button';
import { CloseMedium16 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
type ErrorProps = {
	onClose?: () => void;
	errorMessage?: string;
};

export function KeySensitiveError({ onClose, errorMessage = '' }: ErrorProps) {
	return (
		<Box
			borderRadius={'8px'}
			wordBreak={'break-all'}
			padding={'16px'}
			paddingTop={'10px'}
			maxHeight={'525px'}
			maxWidth={'272px'}
			backgroundColor={'gray.1000'}
			whiteSpace={'break-spaces'}
		>
			<Flex justifyContent={'space-between'} alignItems={'center'} marginBottom={'8px'}>
				<Typography variant={'DesktopH10Bold'} color={'white'}>
					Error details
				</Typography>
				<Button onClick={onClose} isIconOnly size="xs" variant="outline" colorScheme="darkGray">
					<CloseMedium16 />
				</Button>
			</Flex>

			<Typography variant={'DesktopH10Regular'} color={'white'}>
				{errorMessage}
			</Typography>
		</Box>
	);
}
