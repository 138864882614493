import { FromScratch74, FromTemplate52 } from 'src/common/components/Icons';
import { Box } from '@chakra-ui/react';
import Flex from 'src/common/components/Flex';
import Typography from '@components/Typography';
import shadows from 'src/style/shadows';

export const NewMetricCard = ({
	type,
	isSelected,
	onClick,
	isDisabled = false,
}: {
	type: string;
	isSelected: boolean;
	onClick: VoidFunction;
	isDisabled?: boolean;
}) => {
	const isTemplate = type === 'template';
	const TopCardContent = (
		<Flex
			borderTopLeftRadius={'4px'}
			borderTopRightRadius={'4px'}
			width={'100%'}
			height={'148px'}
			py="35px"
			justifyContent={'center'}
			flexDirection={'column'}
			alignItems={'center'}
			backgroundColor={'gray.200'}
		>
			{isTemplate ? <FromTemplate52 /> : <FromScratch74 />}
		</Flex>
	);

	const ScratchCardText = (
		<>
			<Typography color={'gray.1100'} variant="DesktopH7Medium">
				Start from scratch.
			</Typography>
			<Typography color={'gray.900'} variant="DesktopH10Regular">
				Create your own custom metric.
			</Typography>
		</>
	);

	const TemplateCardText = (
		<>
			<Typography color={'gray.1100'} variant="DesktopH7Medium">
				Use a template.
			</Typography>
			<Typography color={'gray.900'} variant="DesktopH10Regular">
				Explore our Metric Library for best practices.
			</Typography>
		</>
	);

	const BottomCardContent = (
		<Box textAlign={'center'} p="16px">
			{isTemplate ? TemplateCardText : ScratchCardText}
		</Box>
	);

	return (
		<Box
			onClick={isDisabled ? undefined : onClick}
			minW={'288px'}
			transition={'0.1s'}
			opacity={isDisabled ? 0.5 : 1}
			cursor={isDisabled ? 'not-allowed' : 'pointer'}
			_hover={{ borderColor: isSelected ? 'gray.800' : isDisabled ? 'gray.600' : 'gray.500' }}
			border={'1px solid'}
			borderColor={isSelected ? 'gray.800' : isDisabled ? 'gray.600' : 'gray.400'}
			boxShadow={isSelected ? shadows.focus.medium.gray[100] : 'none'}
			width={'100%'}
			borderRadius={'4px'}
		>
			{TopCardContent}
			{BottomCardContent}
		</Box>
	);
};
