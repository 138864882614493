import Box from '@components/Box';
import Flex from '@components/Flex';
import IconButton from '@components/IconButton';
import classes from './AICard.module.scss';
import Spinner from '@components/Spinner';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { useOnOverflow } from '@hooks/ui/useOnOverflow';
import {
	ChevronRight16,
	ChevronLeftSmall,
	ChevronRightSmall,
	CloseMedium16,
	SparksFullWhite,
	SparksFullColor,
	GenAILogo,
} from '@icons/index';
import { useRef, useMemo } from 'react';
import colors from 'src/style/colors';
import borderRadiuses from 'src/style/borderRadiuses';
import { useReportEvent } from 'src/services/analytics';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import Button from '@components/Button';
import classNames from 'classnames';

type AICardProps = {
	isLoading: boolean;
	isDataOverviewOpen: boolean;
	currentMessage: {
		position: number;
		text?: string;
	};
	messagesLength: number;
	onExpandCollapseClick: VoidFunction;
	onPrevMessage: VoidFunction;
	onNextMessage: VoidFunction;
	toggleGenAIPopover: VoidFunction;
};

type CardHeaderProps = {
	isDataOverviewOpen: boolean;
	isLoading: boolean;
	onExpandCollapseClick: VoidFunction;
};

type CardBodyProps = {
	text?: string;
	isLoading: boolean;
	currentMessage?: {
		position: number;
		text?: string;
	};
	messagesLength?: number;
	onPrevMessage?: VoidFunction;
	onNextMessage?: VoidFunction;
	toggleGenAIPopover?: VoidFunction;
};

type ToggleAIChatButtonProps = {
	toggleGenAIPopover: VoidFunction;
};

function AILogo() {
	return (
		<Box height={'16px'} width={'98px'} className={classes.logo}>
			<GenAILogo />
		</Box>
	);
}

function CardHeader({ isDataOverviewOpen, isLoading, onExpandCollapseClick }: CardHeaderProps) {
	return (
		<Flex
			flexDirection={'row'}
			p="12px"
			justifyContent={'space-between'}
			alignItems={'center'}
			alignSelf={'stretch'}
			minHeight={'40px'}
			className={classNames(classes.cardHeader, {
				[classes.isCollapsed]: !isDataOverviewOpen,
			})}
			onClick={onExpandCollapseClick}
		>
			<Flex>
				<AILogo />
				{isLoading && (
					<Flex
						justifyContent={'center'}
						alignItems={'center'}
						alignSelf={'stretch'}
						width={'100%'}
						marginLeft={'16px'}
					>
						<Spinner size={'16px'} />
					</Flex>
				)}
			</Flex>
			<Tooltip
				hasArrow
				label={isDataOverviewOpen ? 'Close' : 'Expand'}
				size={'sm'}
				placement={'top'}
				openDelay={500}
				gutter={14}
			>
				<Button isIconOnly size="xxs" variant="ghost" border="none" colorScheme="gray" mixBlendMode={'multiply'}>
					{isDataOverviewOpen ? <CloseMedium16 /> : <ChevronRight16 />}
				</Button>
			</Tooltip>
		</Flex>
	);
}

function ToggleAIChatButton({ toggleGenAIPopover }: ToggleAIChatButtonProps) {
	const { wrapWithReport } = useReportEvent();
	const { metricNameWithoutFlavor, flavor, breakdowns, filters } = useMetricDerivedState();

	const toggleGenAIPopoverEventMetadata = useMemo(
		() => ({
			metric: metricNameWithoutFlavor,
			flavor: flavor?.selectedValue,
			breakdowns: breakdowns.values.map((e) => e.key),
			filterKeys: filters.map((e) => e.key),
			feature: 'Generative Virtual Assistant',
		}),
		[metricNameWithoutFlavor, flavor?.selectedValue, breakdowns.values, filters]
	);

	return (
		<Flex
			justifyContent={'center'}
			alignItems={'center'}
			className={classes.triggerCircle}
			borderRadius={borderRadiuses.round}
			onClick={wrapWithReport(toggleGenAIPopover, 'genai-chat-state-changed', {
				...toggleGenAIPopoverEventMetadata,
				action: 'open',
			})}
			as="button"
		>
			<IconButton
				as="div"
				className={classes.triggerAnimation}
				colorScheme={'lightGrayNoActive'}
				icon={
					<Box alignItems="center" justifyContent="center" width="16px" height="16px">
						<SparksFullWhite />
						<SparksFullColor />
					</Box>
				}
			/>
		</Flex>
	);
}

function CardBody({
	isLoading,
	text,
	messagesLength,
	currentMessage,
	onPrevMessage,
	onNextMessage,
	toggleGenAIPopover,
}: CardBodyProps) {
	const typographyRef = useRef<HTMLDivElement | null>(null);
	const isOverflowing = useOnOverflow(typographyRef, [text]);
	const numberContainerWidth = messagesLength && messagesLength < 10 ? 30 : 40;
	const numberItemWidth = messagesLength && messagesLength < 10 ? 10 : 20;

	return (
		<Flex padding="0 12px 12px 12px" flexDirection={'column'} alignItems={'flex-start'} gap="16px">
			<Tooltip size={'md'} label={isOverflowing ? text : ''} placement={'bottom'} maxWidth={'500px'}>
				<Typography variant={'Paragraph12R'} color={colors.gray[700]}>
					<div ref={typographyRef}>{text}</div>
				</Typography>
			</Tooltip>
			{!isLoading && messagesLength && messagesLength > 1 && (
				<Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
					<Flex alignItems={'center'}>
						<ChevronLeftSmall className={classes.chevronButton} onClick={onPrevMessage} />
						<Typography
							variant={'DesktopH10Regular'}
							color={colors.gray[800]}
							margin={'0 4px'}
							width={numberContainerWidth}
							display={'inline-flex'}
							textAlign={'center'}
						>
							<Box width={numberItemWidth} textAlign={'center'}>
								{currentMessage?.position}
							</Box>
							/
							<Box width={numberItemWidth} textAlign={'center'}>
								{messagesLength}
							</Box>
						</Typography>
						<ChevronRightSmall className={classes.chevronButton} onClick={onNextMessage} />
					</Flex>

					<Tooltip hasArrow label={'Generate in AI'} size={'sm'} openDelay={500} gutter={14}>
						<ToggleAIChatButton toggleGenAIPopover={toggleGenAIPopover as VoidFunction} />
					</Tooltip>
				</Flex>
			)}
		</Flex>
	);
}

export default function AICard({
	isLoading: rawIsLoading,
	isDataOverviewOpen,
	onExpandCollapseClick,
	currentMessage,
	messagesLength,
	onPrevMessage,
	onNextMessage,
	toggleGenAIPopover,
}: AICardProps) {
	const isLoading = rawIsLoading || !currentMessage.text;
	return (
		<div className={classes.AICard}>
			<Box borderRadius="4px" backgroundColor={colors.gray[100]}>
				<Flex flexDirection={'column'} alignItems={'flex-start'}>
					<CardHeader
						isLoading={isLoading}
						isDataOverviewOpen={isDataOverviewOpen}
						onExpandCollapseClick={onExpandCollapseClick}
					/>
					{isDataOverviewOpen && !isLoading && (
						<CardBody
							isLoading={isLoading}
							text={currentMessage.text}
							messagesLength={messagesLength}
							currentMessage={currentMessage}
							onPrevMessage={onPrevMessage}
							onNextMessage={onNextMessage}
							toggleGenAIPopover={toggleGenAIPopover}
						/>
					)}
				</Flex>
			</Box>
		</div>
	);
}

export function AIErrorCard() {
	const errorMessage = 'Something went wrong, please try again later.';
	return (
		<div className={classes.AICard}>
			<Box borderRadius="4px" backgroundColor={colors.gray[100]}>
				<Flex flexDirection={'column'} alignItems={'flex-start'}>
					<Flex
						flexDirection={'row'}
						p="8px 8px 0 16px"
						justifyContent={'space-between'}
						alignItems={'center'}
						alignSelf={'stretch'}
						minHeight={'40px'}
					>
						<AILogo />
					</Flex>
					<CardBody text={errorMessage} isLoading={false} />
				</Flex>
			</Box>
		</div>
	);
}
