import { Grid, GridItem } from '@chakra-ui/react';
import Box from 'src/common/components/Box';
import Flex from 'src/common/components/Flex';
import Switch from 'src/common/components/Switch';
import Typography from 'src/common/components/Typography';
import { showIntercomArticle } from 'src/services/intercom';

export type HeaderProps = {
	isEnabled: boolean;
	alwaysEnabled?: boolean;
	displayName: string;
	description: string | undefined;
	documentation: string | undefined;
	hasEditPermission: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Header = ({
	displayName,
	documentation,
	description,
	hasEditPermission,
	isEnabled,
	alwaysEnabled,
	onChange,
}: HeaderProps) => {
	return (
		<>
			<GridItem colSpan={12}>
				<Flex direction="column" gap="6px">
					<Grid pt={'32px'} gridTemplateColumns="repeat(12, [col-start] 1fr)">
						<GridItem colSpan={{ base: 12, sm: 6 }}>
							<Flex direction="row" gap="8px">
								<Typography variant="DesktopH6Medium" color="gray.1000">
									{displayName}
								</Typography>
								{hasEditPermission ? null : (
									<Box backgroundColor="blue.100" padding="2px 8px" borderRadius="2px">
										<Typography variant="Paragraph12M" color="blue.600">
											View only
										</Typography>
									</Box>
								)}
							</Flex>
						</GridItem>
						<GridItem
							display={'flex'}
							alignItems={hasEditPermission ? 'center' : 'start'}
							justifyContent={{
								base: 'flex-start',
								sm: 'flex-end',
							}}
							pt={{
								base: '16px',
								sm: '0px',
							}}
							colSpan={{ base: 12, sm: 6 }}
						>
							{hasEditPermission && !alwaysEnabled && <Switch size="md" isChecked={isEnabled} onChange={onChange} />}
						</GridItem>
					</Grid>
					<Typography
						variant="Paragraph12R"
						color="gray.700"
						maxWidth="560px"
						onClick={documentation ? () => showIntercomArticle(documentation) : undefined}
						cursor={documentation ? 'pointer' : undefined}
					>
						{description}
					</Typography>
				</Flex>
			</GridItem>
		</>
	);
};

export default Header;
