import Typography from 'src/common/components/Typography';
import {
	LearnMore,
	LearnMoreProps,
} from 'src/pages/MetricPage/components/CalculatePanel/Builder/components/BuilderTooltips/common/LearnMore';
import { RELATIONSHIPS_SEMANTIC_LINK } from '../../utils/consts';

export function ReferencedEntityTooltip({ onHelpClicked }: { onHelpClicked: LearnMoreProps['onClick'] }) {
	return (
		<Typography color={'white'} variant="Paragraph14R">
			The entity that this relationship connects to.&nbsp;
			<LearnMore onClick={onHelpClicked} link={RELATIONSHIPS_SEMANTIC_LINK} />.
		</Typography>
	);
}
