import difference from 'lodash/difference';
import groupBy from 'lodash/groupBy';
import { ChartOptions, ChartSeries, SeriesType } from 'src/common/components/Chart/types';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import colors from 'src/style/colors';
import { StatisticalOperation } from 'src/types/metric';
import {
	ComponentsSeriesColors,
	MainSeriesColors,
	ORDERED_STATISTIC_OPERATIONS,
} from '../statisticOperations/constants';
import { ChartColoring, getSeriesInManualSortOrder } from '../utils';

export function calcColoredSeries(
	{ chartOptions }: Required<Pick<MetricDerivedState, 'chartOptions'>>,
	tenantGraphColor: string[],
	orderedComponents?: string[]
): Pick<MetricDerivedState, 'chartOptions'> {
	const isSpecificGraphColorsExist = tenantGraphColor.length > 2;
	const tenantSpecificMainSeriesColors = isSpecificGraphColorsExist
		? [tenantGraphColor[0], ...MainSeriesColors.slice(1)]
		: MainSeriesColors;

	const tenantSpecificComponentSeriesColors = isSpecificGraphColorsExist
		? tenantGraphColor.slice(1)
		: ComponentsSeriesColors;

	const chartColoring: ChartColoring = {
		mainSeriesColors: tenantSpecificMainSeriesColors,
		componentSeriesColors: tenantSpecificComponentSeriesColors,
	};

	const isWaterfall = !!chartOptions.series.find((s) => s.chartType == 'waterfall');
	if (isWaterfall) {
		return calcColoredSeriesForWaterfall({ chartOptions, ...chartColoring });
	}

	return calcDefaultColoredSeries({ chartOptions, ...chartColoring }, orderedComponents);
}

function colorSeriesGroup(colors: string[], series?: ChartSeries[], offset = 0): ChartSeries[] {
	if (!series) {
		return [];
	}
	return series.map((s, index) => {
		return { ...s, color: colors[(index + offset) % colors.length] };
	});
}

function colorOrderedComponents(
	colors: string[],
	orderedSeries?: ChartSeries[],
	orderedComponents?: string[]
): [ChartSeries[], number] {
	if (!orderedSeries || !orderedComponents) {
		return [[], 0];
	}

	const orderedColors = orderedComponents.map((s, index) => {
		return { name: s, color: colors[index % colors.length] };
	});

	const lastColoredIndex = orderedSeries.length
		? orderedComponents.findIndex((comp) => comp == orderedSeries.slice(-1)[0].name)
		: 0;
	return [
		orderedSeries.map((s) => {
			return { ...s, color: orderedColors?.find((nameToColor) => nameToColor.name == s.name)?.color };
		}),
		lastColoredIndex + 1,
	];
}

function calcColoredSeriesForWaterfall({
	chartOptions,
	mainSeriesColors,
}: Pick<MetricDerivedState, 'chartOptions'> & ChartColoring): Pick<MetricDerivedState, 'chartOptions'> {
	const mainSeries = chartOptions.series.filter((s) => s.custom.seriesType == 'main');
	const coloredMainSeries = colorSeriesGroup(mainSeriesColors, mainSeries);

	const componentSeries = chartOptions.series.filter((s) => s.custom.seriesType != 'main');
	const coloredSeries = componentSeries.map((series) => {
		const value = series.data.at(0)?.y;
		const getColorByValue = (value: number | undefined) => {
			if (value === undefined) {
				return colors.gray['500'];
			}
			return value < 0 ? colors.red['600'] : colors.emerald['600'];
		};
		return {
			...series,
			color: getColorByValue(value),
		};
	});

	return {
		chartOptions: {
			...chartOptions,
			series: [...coloredSeries, ...coloredMainSeries],
		},
	};
}

function calcDefaultColoredSeries(
	{
		chartOptions,
		mainSeriesColors,
		componentSeriesColors,
	}: Required<Pick<MetricDerivedState, 'chartOptions'>> & ChartColoring,
	orderedComponents?: string[]
): Pick<MetricDerivedState, 'chartOptions'> {
	const groupedSeries = groupBy(chartOptions.series, (s) => s.custom.seriesType) as Record<SeriesType, ChartSeries[]>;
	const mappedMainGroup = colorSeriesGroup(mainSeriesColors, groupedSeries?.main);
	const mappedStatisticGroup =
		groupedSeries.statistic?.map((s) => {
			return { ...s, color: ORDERED_STATISTIC_OPERATIONS[s.name as StatisticalOperation].color };
		}) ?? [];

	const hasComponents = groupedSeries?.component?.length;
	let coloredSeries;
	if (hasComponents) {
		const componentsInManualSortOrder = getSeriesInManualSortOrder(groupedSeries?.component, orderedComponents);
		const [orderedComponentsColored, lastColoredIndex] = colorOrderedComponents(
			componentSeriesColors,
			componentsInManualSortOrder,
			orderedComponents
		);
		const mappedComponentGroup = colorSeriesGroup(
			componentSeriesColors,
			difference(groupedSeries.component, componentsInManualSortOrder),
			lastColoredIndex
		);
		const allComponentsColored = (orderedComponentsColored ?? []).concat(mappedComponentGroup ?? []);
		coloredSeries = [...mappedMainGroup, ...allComponentsColored, ...mappedStatisticGroup];
	} else {
		coloredSeries = [...mappedMainGroup, ...mappedStatisticGroup];
	}
	return {
		chartOptions: {
			...chartOptions,
			series: coloredSeries,
		},
	};
}

export function calcColoredBubbles(chartOptions: ChartOptions): Pick<MetricDerivedState, 'chartOptions'> {
	const coloredBubbles = chartOptions.bubbles?.map((bubble) => {
		const color = ORDERED_STATISTIC_OPERATIONS[bubble.name as StatisticalOperation].color;
		return { ...bubble, color };
	});

	return { chartOptions: { ...chartOptions, bubbles: coloredBubbles } };
}
