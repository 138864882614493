import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import Alert from 'src/common/components/Alert';
import Avatar from 'src/common/components/Avatar/Avatar';
import Button from 'src/common/components/Button';
import Divider from 'src/common/components/Divider';
import Typography from 'src/common/components/Typography';
import colors from 'src/style/colors';
import { iconForStatus } from '../utilities/iconForStatus';
import { ChangeStatus, CurrentPropertyLastChangeProps } from '../types';
import { ApproveModal } from './ApproveModal';

export const CurrentPropertyLastChange = ({
	overrideProps,
	lastChangeProps,
	onReset,
	isHasResetPermission,
	fieldName,
	source,
}: CurrentPropertyLastChangeProps) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	const statusIcon = iconForStatus?.[overrideProps?.status as ChangeStatus];

	const shouldShowResetAlert = isHasResetPermission && overrideProps?.value != null;

	return (
		<>
			<Divider direction="horizontal" />
			<Box py={'24px'}>
				{shouldShowResetAlert && (
					<Box pb={'24px'}>
						<Alert
							padding={'12px 16px'}
							status="neutral"
							DescriptionComponent={() => (
								<Box>
									<Typography variant="Paragraph14R" pr={'4px'}>
										Original value is
									</Typography>
									<Typography variant="Paragraph14SB" testId="original-value">
										{lastChangeProps.sourceValue}
									</Typography>
								</Box>
							)}
						>
							{isHasResetPermission && (
								<Button
									m={0}
									p={0}
									height={'24px'}
									size="small"
									variant="outline"
									backgroundColor={'transparent'}
									testId="reset-button"
									eventName="click-reset-property-details-modal"
									eventMetadata={{ lastChangeProps, overrideProps }}
									onClick={onOpen}
									color={'gray'}
									_hover={{
										backgroundColor: 'transparent',
										borderColor: colors.gray['800'],
										color: colors.gray['800'],
									}}
									_active={{ color: colors.gray['900'] }}
								>
									<Typography variant={'Paragraph14SB'} cursor="pointer">
										Reset
									</Typography>
								</Button>
							)}
						</Alert>
					</Box>
				)}

				<Typography variant={'DesktopH7Bold'} pb={'8px'}>
					Last change
				</Typography>

				<Flex gap={'10px'} py={'16px'} boxShadow={'borderBottom'} justifyContent={'space-between'}>
					<Flex>
						<Avatar shape="round" imageUrl={overrideProps?.last_modified_by_user.picture ?? ''} />
						<Box pl={'10px'}>
							<Box>
								<Typography variant="DesktopH8Bold" pr={'4px'}>
									{overrideProps?.last_modified_by_user.first_name} {overrideProps?.last_modified_by_user.last_name}
								</Typography>
								{overrideProps?.value ? (
									<>
										<Typography variant="DesktopH8Regular" pr={'4px'}>
											changed to
										</Typography>
										<Typography testId="last-chagne-value" variant="DesktopH8Bold">
											{lastChangeProps.lastChangeValue}
										</Typography>
									</>
								) : (
									<Typography variant="DesktopH8Regular" pr={'4px'}>
										reset the value
									</Typography>
								)}
							</Box>

							{overrideProps?.comment && (
								<Box>
									<Typography variant="Paragraph14R">{overrideProps?.comment}</Typography>
								</Box>
							)}

							<Box>
								<Typography pt={'4px'} variant="Paragraph12R">
									{lastChangeProps.editedAt}
								</Typography>
							</Box>
						</Box>
					</Flex>

					<Box h="16px" w="16px">
						{statusIcon}
					</Box>
				</Flex>
			</Box>

			<ApproveModal
				isOpen={isOpen}
				onClose={onClose}
				onApprove={onReset}
				description={`By approving this, the value of ${fieldName} will be taken from ${source}. It will go into effect by the next business day.`}
			/>
		</>
	);
};
