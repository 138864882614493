import { SelectOption } from 'src/common/components/Select/types';

export const searchEntityOptions: SelectOption[] = [
	{ label: 'Accounts', value: 'Account' },
	{ label: 'Opportunities', value: 'Opportunity' },
	{ label: 'Opportunities line items', value: 'OpportunityLineItem' },
	{ label: 'Products', value: 'Product2' },
	{ label: 'Users', value: 'User' },
	{ label: 'Transaction lines', value: 'TransactionLines' },
	{ label: 'Departments', value: 'Departments' },
	{ label: 'Transactions', value: 'Transactions' },
	{ label: 'Entities', value: 'Entity' },
	{ label: 'Financial units', value: 'SightfullFinancialUnit' },
	{ label: 'Campaigns', value: 'Campaign' },
	{ label: 'Leads', value: 'Lead' },
	{ label: 'Opportunity Stages', value: 'OpportunityStage' },
];

export const analyticsMetadata = {
	feature: 'Entity search',
};
