import { Img } from '@chakra-ui/react';
import AppleStoreSvg16 from 'src/assets/images/apple-store-16.svg';
import JiraSvg16 from 'src/assets/images/jira-16.svg';
import GitHubSvg16 from 'src/assets/images/github-16.svg';
import BigQuerySvg16 from 'src/assets/images/bigquery-16.svg';
import GongSvg16 from 'src/assets/images/gong-16.svg';
import GoogleAnalyticsSvg16 from 'src/assets/images/google-analytics-16.svg';
import GooglePlaySvg16 from 'src/assets/images/google-play-16.svg';
import GoogleSheetsSvg16 from 'src/assets/images/google-sheets-16.svg';
import HubspotSvg16 from 'src/assets/images/hubspot-16.svg';
import LinkedinSvg16 from 'src/assets/images/linkedin-16.svg';
import MixpanelSvg16 from 'src/assets/images/mixpanel-16.svg';
import QuickBooksSvg16 from 'src/assets/images/quickbooks-16.svg';
import SalesforceSvg16 from 'src/assets/images/salesforce-16.svg';
import ShopifySvg16 from 'src/assets/images/shopify-16.svg';
import { useEntitySourceInfoQuery } from 'src/generated/graphql';
import {
	AppleStore20,
	BigQuery20,
	GitHub20,
	Gong20,
	GoogleAnalytics20,
	GooglePlay20,
	GoogleSheets20,
	Hubspot20,
	Jira20,
	Linkedin20,
	Mixpanel20,
	QuickBooks20,
	Salesforce20,
	Shopify20,
} from '../components/Icons';

export type PrimaryKeys = { [primaryKey: string]: string };

type SourceInfo = {
	name: string;
	Logo: null | React.FC;
	BigLogo: null | React.FC;
	urlFormatter: (baseUrl: string, primaryKeys: PrimaryKeys) => string | null;
};

// When adding a new Source here, make sure to also add it in the MonacoEditor.scss file
// The icons that are used by thr MonacoEditor.scss need to be imported as static assets and not as react components
export const SOURCES_INFO: Record<string, SourceInfo> = {
	Salesforce: {
		name: 'Salesforce',
		Logo: () => <Img src={SalesforceSvg16} />,
		BigLogo: Salesforce20,
		urlFormatter: (baseUrl: string, primaryKeys: PrimaryKeys) => {
			try {
				return new URL(Object.values(primaryKeys)[0], baseUrl).href;
			} catch {
				return null;
			}
		},
	},
	Hubspot: {
		name: 'Hubspot',
		Logo: () => <Img src={HubspotSvg16} />,
		BigLogo: Hubspot20,
		urlFormatter: () => null,
	},
	QuickBooks: {
		name: 'QuickBooks',
		Logo: () => <Img src={QuickBooksSvg16} />,
		BigLogo: QuickBooks20,
		urlFormatter: () => null,
	},
	Gong: {
		name: 'Gong',
		Logo: () => <Img src={GongSvg16} />,
		BigLogo: Gong20,
		urlFormatter: () => null,
	},
	LinkedIn: {
		name: 'LinkedIn',
		Logo: () => <Img src={LinkedinSvg16} />,
		BigLogo: Linkedin20,
		urlFormatter: () => null,
	},
	GoogleSheets: {
		name: 'Google Sheets',
		Logo: () => <Img src={GoogleSheetsSvg16} />,
		BigLogo: GoogleSheets20,
		urlFormatter: () => null,
	},
	GoogleAnalytics: {
		name: 'Google Analytics',
		Logo: () => <Img src={GoogleAnalyticsSvg16} />,
		BigLogo: GoogleAnalytics20,
		urlFormatter: () => null,
	},
	BigQuery: {
		name: 'BigQuery',
		Logo: () => <Img src={BigQuerySvg16} />,
		BigLogo: BigQuery20,
		urlFormatter: () => null,
	},
	Mixpanel: {
		name: 'Mixpanel',
		Logo: () => <Img src={MixpanelSvg16} />,
		BigLogo: Mixpanel20,
		urlFormatter: () => null,
	},
	GooglePlay: {
		name: 'Google Play',
		Logo: () => <Img src={GooglePlaySvg16} />,
		BigLogo: GooglePlay20,
		urlFormatter: () => null,
	},
	AppleStore: {
		name: 'Apple Store',
		Logo: () => <Img src={AppleStoreSvg16} />,
		BigLogo: AppleStore20,
		urlFormatter: () => null,
	},
	Jira: {
		name: 'Jira',
		Logo: () => <Img src={JiraSvg16} />,
		BigLogo: Jira20,
		urlFormatter: () => null,
	},
	GitHub: {
		name: 'GitHub',
		Logo: () => <Img src={GitHubSvg16} />,
		BigLogo: GitHub20,
		urlFormatter: () => null,
	},
	Shopify: {
		name: 'Shopify',
		Logo: () => <Img src={ShopifySvg16} />,
		BigLogo: Shopify20,
		urlFormatter: () => null,
	},
	default: {
		name: 'Source',
		Logo: null,
		BigLogo: null,
		urlFormatter: () => null,
	},
};

export type SourceInfoType = ReturnType<typeof getSourcesInfo>;
export function getSourcesInfo({
	source,
	baseUrl,
	primaryKeys,
}: {
	source?: string;
	baseUrl?: string;
	primaryKeys?: PrimaryKeys;
}) {
	if (!source) return null;
	const { name, Logo, BigLogo, urlFormatter } = SOURCES_INFO[source] ?? SOURCES_INFO.default;

	if (!baseUrl || !primaryKeys) return { name, Logo, BigLogo, url: null };
	return { name, Logo, BigLogo, url: urlFormatter(baseUrl, primaryKeys) };
}

export function useSourceInfo({ entityName, primaryKeys }: { entityName: string; primaryKeys?: PrimaryKeys }) {
	const { data, loading, error } = useEntitySourceInfoQuery({ variables: { entity: entityName } });
	const sourceInfo = data?.entityInfo.schema;
	if (loading || error || !sourceInfo) return null;

	return getSourcesInfo({ source: sourceInfo.sourceDisplayName, baseUrl: sourceInfo.sourceURL, primaryKeys });
}
