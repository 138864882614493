import { gql } from '@apollo/client';

export const GetNodesByText = gql`
	query GetNodesByText($query: String!, $limit: Int!, $labels: [String!]!) {
		nodesTextSearch(query: $query, limit: $limit, labels: $labels) {
			result
		}
	}
`;

export const NodeOverridesSubscription = gql`
	query NodeOverridesSubscription($object_id: String!) {
		data_overrides(where: { object_id: { _eq: $object_id } }) {
			comment
			dimension
			edited_at
			last_modified_by
			last_modified_by_user {
				first_name
				last_name
				picture
			}
			object_id
			source_value
			status
			value
		}
	}
`;

export const UpsertDataOverride = gql`
	mutation UpsertDataOverride($comment: String!, $dimension: String!, $object_id: String!, $value: jsonb) {
		insert_data_overrides_one(
			object: { comment: $comment, dimension: $dimension, object_id: $object_id, value: $value }
			on_conflict: { constraint: data_overrides_pkey, update_columns: [comment, value] }
		) {
			value
		}
	}
`;

export const GetDataModelDimensions = gql`
	query GetDataModelDimensions($object: String!) {
		data_model_dimensions(where: { object: { _eq: $object } }) {
			editable
			object
			type
			dimension
			description
		}
	}
`;
