import { Box } from '@chakra-ui/react';
import Typography from 'src/common/components/Typography';

const AlertDescriptionComponent = () => {
	return (
		<Box display="inline-block" wordBreak={'break-word'} height={'3px'}>
			<Typography mr={'4px'} variant="Paragraph16SB">
				Not updated yet.
			</Typography>
			<Typography variant="Paragraph16R">Will be updated in the next 24 hours.</Typography>
		</Box>
	);
};

export default AlertDescriptionComponent;
