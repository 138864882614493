import Box from '@components/Box';
import Divider from '@components/Divider';
import ListItem from '@components/ListItem';
import MenuListItem from '@components/MenuListItem';
import { BinSolid16, EditSolid16, NewTabSolid16 } from '@icons/index';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AccessController } from 'src/common/components/AccessController';
import MenuIndicator from 'src/common/components/MenuIndicator';
import { useCollectionsSubscriptions } from 'src/common/hooks/fetching/useCollectionsSubscriptions';
import { useModal } from 'src/common/hooks/ui/useModal';

import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { Collection } from 'src/layout/Menu/NavigationDrawer/types';

import { useReportEvent } from 'src/services/analytics';
import useNavigation, { NavigationOptions } from 'src/services/useNavigation';
import { collectionsNavigationDrawer, getIsNavDrawerViewCollection, metricsNavigationDrawer } from 'src/stores/utils';
import { Permissions } from 'src/types/environment';
import DeleteCollectionModal from './DeleteCollectionModal';
import NavigationDrawerInner from './NavigationDrawerInner';
import { useMetricCategories } from 'src/pages/MetricCatalog/hooks/useMetricCategories';
import { TestIDs } from 'src/common/types/test-ids';

type NavigationDrawerProps = {
	currentDrawerView?: string;
	handleCategoryClick?: (category: string) => void;
	activeCategory?: string;
	isShowingFullSize: boolean;
};

// TODO: add error boundary
function NavigationDrawer({
	currentDrawerView,
	handleCategoryClick,
	activeCategory,
	isShowingFullSize,
}: NavigationDrawerProps) {
	const { reportEvent } = useReportEvent();

	const location = useLocation();
	const [selectedMenuItemId, setSelectedItem] = useState();
	const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useModal();
	const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useModal();
	const [editedItem, setEditedItem] = useState<Collection | null>(null);
	const [deletedItem, setDeletedItem] = useState<Collection | null>(null);
	const { navigate } = useNavigation();
	const [metricCategories] = useMetricCategories();

	const navigateToCollection = useCallback(
		(
			collectionType: string,
			collectionId: string,
			collectionName: string,
			navigate: (navOptions: NavigationOptions) => void,
			isNewTab?: boolean
		) => {
			reportEvent({
				event: 'collection-tiles-navigate-to-collection',
				metaData: {
					// String names for backwards-compatible with previous analytics
					'collection-name': collectionName,
					'collection-type': collectionType,
					collectionId,
					isNewTab,
				},
			});
			const URL = `${collectionType.toLowerCase()}/${collectionId}`;

			navigate({ path: URL, isNewTab });
		},
		[reportEvent]
	);

	const { rawDashboardsList, rawWorkspacesList } = useCollectionsSubscriptions();

	const rawCollectionsListItems =
		currentDrawerView == collectionsNavigationDrawer.dashboard ? rawDashboardsList : rawWorkspacesList;

	// TAF: Changed to URL params
	const isItemSelected = (id: any, collectionType: any) =>
		id === selectedMenuItemId || location.pathname === `/${collectionType.toLowerCase()}/${id}`;

	const handleDelete = (collection: Collection) => {
		onDeleteModalOpen();
		setDeletedItem(collection);
	};

	const handleRename = (collection: Collection) => {
		setEditedItem(collection);
		onEditModalOpen();
	};

	const handleMenuItemClick = (collection: any) => {
		navigateToCollection(collection.collection_type, collection.id, collection.name, navigate, false);
		setSelectedItem(collection.id);
	};

	function buildSpacesListItems(currentDrawerView: string) {
		return rawCollectionsListItems?.workspaces?.map((collection, index) => (
			<MenuListItem
				key={collection.id}
				isSelected={isItemSelected(collection.id, collection.collection_type)}
				text={collection.name}
				menuItems={
					<PopoverMenuItems
						onNavigate={() => {
							reportEvent({ event: 'open-collection-in-new-tab' });
							navigateToCollection(collection.collection_type, collection.id, collection.name, navigate, true);
						}}
						onRename={() => handleRename(collection)}
						onDelete={() => handleDelete(collection)}
					/>
				}
				onClick={() => handleMenuItemClick(collection)}
				dataIntercomArea={`${currentDrawerView}-sidebar`}
				dataIntercomSequence={index + 1}
				testId={TestIDs.SIDEBAR_ITEM(currentDrawerView)}
			/>
		));
	}

	function buildMetricsListItems() {
		return metricCategories.map(({ name: categoryName }, index) => (
			<MenuIndicator
				key={categoryName}
				text={categoryName}
				onClick={() => {
					handleCategoryClick?.(categoryName);
					reportEvent({ event: 'drawer-metric-catalog-category-clicked', metaData: { categoryName } });
				}}
				isSelected={categoryName == activeCategory}
				dataIntercomArea={`${currentDrawerView}-sidebar`}
				dataIntercomSequence={index + 1}
			/>
		));
	}

	function getCollectionItems() {
		if (!currentDrawerView) return;
		if (getIsNavDrawerViewCollection(currentDrawerView)) return buildSpacesListItems(currentDrawerView);
		if (currentDrawerView == metricsNavigationDrawer) return buildMetricsListItems();
	}

	const collectionItems = getCollectionItems();

	return (
		<Box height="100%" left="0" bottom="0" position="relative">
			{currentDrawerView && (
				<NavigationDrawerInner
					isVisible={isShowingFullSize}
					currentDrawerView={currentDrawerView}
					navigationDrawerView={currentDrawerView}
					collectionItems={collectionItems}
				/>
			)}

			{deletedItem && (
				<DeleteCollectionModal
					isOpen={isDeleteModalOpen}
					onClose={onDeleteModalClose}
					collection={deletedItem}
					isCollectionInView={false}
				/>
			)}
			{editedItem && (
				<CollectionUpsertModal
					isOpen={isEditModalOpen}
					onClose={onEditModalClose}
					collection={editedItem}
					collectionType={editedItem.collection_type}
				/>
			)}
		</Box>
	);
}

type PopoverMenuItemsProps = {
	onRename: () => void;
	onDelete: () => void;
	onNavigate: () => void;
};

const PopoverMenuItems = ({ onNavigate, onRename, onDelete }: PopoverMenuItemsProps) => (
	<>
		<ListItem
			size="sm"
			label="Open in new Tab"
			prefixIcon={<NewTabSolid16 />}
			onClick={() => {
				onNavigate();
			}}
		/>
		<AccessController permission={Permissions.updateCollection}>
			<Divider direction="horizontal" mt="8px" mb="8px" ml="12px" mr="12px" />
			<ListItem size="sm" label="Rename board" prefixIcon={<EditSolid16 />} onClick={onRename} />
		</AccessController>
		<AccessController permission={Permissions.deleteCollection}>
			<ListItem size="sm" label="Delete" prefixIcon={<BinSolid16 />} onClick={onDelete} />
		</AccessController>
	</>
);

export default NavigationDrawer;
