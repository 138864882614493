import { gql } from '@apollo/client';

export const GetGenerativeExplainBeta = gql`
	query GenerativeExplainBeta($metricData: JSON!) {
		generative_explain_beta(metricData: $metricData) {
			response
		}
	}
`;

export const SendMetricInteractivePrompt = gql`
	mutation SendMetricInteractivePrompt($user_prompt: String!, $request_id: String!) {
		generative_metric_interactive(userPrompt: $user_prompt, requestId: $request_id)
	}
`;

export const GetMetricInteractiveSession = gql`
	query GetMetricInteractiveSession($session_id: uuid!, $user_id: uuid!) {
		generative_sessions(where: { _and: { id: { _eq: $session_id }, user_id: { _eq: $user_id } } }) {
			completions
			status
		}
	}
`;

export const InitiateMetricInteractiveSession = gql`
	mutation InitiateMetricInteractiveSession($metric_data: JSON!) {
		generative_initiate_metric_interactive(metricData: $metric_data) {
			response
		}
	}
`;

export const InitiateMetricInteractiveSessionV2 = gql`
	mutation InitiateMetricInteractiveSessionV2($metric_data: JSON!) {
		generative_initiate_metric_interactive_v2(metricData: $metric_data) {
			response
		}
	}
`;

export const GetGenerativeExplainBetaV2 = gql`
	query GenerativeExplainBetaV2($metricData: JSON!) {
		generative_explain_beta_v2(metricData: $metricData) {
			response
		}
	}
`;

export const GenerativeMetricOverview = gql`
	query GenerativeMetricOverview($metricData: JSON!) {
		generative_metric_overview(metricData: $metricData) {
			response
		}
	}
`;

export const GenerativeDiscoveryPerform = gql`
	query GenerativeDiscoveryPerform($userPrompt: String!) {
		generative_discovery_perform(userPrompt: $userPrompt) {
			response
		}
	}
`;
