import Box from '@components/Box';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { ChevronRightSolid16, CQuestion16, NavDashboardsOutline24, NavWorkspacesOutline24 } from '@icons/index';
import capitalize from 'lodash/capitalize';
import { MouseEventHandler, ReactNode } from 'react';
import Tooltip from 'src/common/components/Tooltip';
import { useReportEvent } from 'src/services/analytics';
import { CollectionType, collectionTypes } from 'src/types/signal';
import classes from './ShareSignalModal.module.scss';

const icons: { [key in CollectionType]: ReactNode } = {
	workspace: (
		<Box>
			<NavWorkspacesOutline24 />
		</Box>
	),
	dashboard: (
		<Box>
			<NavDashboardsOutline24 />
		</Box>
	),
};

const subtitle: { [key in CollectionType]: string } = {
	workspace: 'Share insights and collaborate with colleagues',
	dashboard: 'Build real time dashboards to monitor performance',
};

export function ChooseSpaceModal({
	onSpaceClick,
	headerName,
}: {
	onSpaceClick: (space: CollectionType) => any;
	headerName: string;
}) {
	const { wrapWithReport } = useReportEvent();
	return (
		<Flex direction="column">
			{collectionTypes.map((collection) => (
				<SpaceCard
					key={collection}
					type={collection}
					subtitle={subtitle[collection]}
					onClick={wrapWithReport(() => onSpaceClick(collection), `signal-draft-selected-${collection}`, {
						feature: `${headerName} header`,
					})}
				/>
			))}
		</Flex>
	);
}

function SpaceCard({
	type,
	subtitle,
	onClick,
}: {
	type: CollectionType;
	subtitle: string;
	onClick?: MouseEventHandler;
}) {
	return (
		<button className={classes.card} onClick={onClick}>
			<Flex alignItems="center" justifyContent="space-between">
				<Flex alignItems={'center'} gap="8px">
					{icons[type]}
					<Typography variant={'Paragraph16SB'} paddingLeft="4px" paddingBottom={'0px'} color={'gray.800'}>
						{capitalize(type)}
					</Typography>
					<Tooltip size={'md'} label={subtitle} placement={'bottom'} cursor={'pointer'} marginTop="8px">
						<CQuestion16 className={classes.questionMark} />
					</Tooltip>
				</Flex>
				<Box color="gray.600">
					<ChevronRightSolid16 />
				</Box>
			</Flex>
		</button>
	);
}
