import { Box, Flex } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { TableCell } from 'src/common/components/TableCell/TableCell';
import { TableCellProps } from 'src/common/components/TableCell/types';
import { TableRow } from 'src/common/components/TableRow/TableRow';
import colors from 'src/style/colors';
import { useEntityProfileDrawer, useEntityProfileReportEvent } from '../hooks';
import {
	EntityTypeOrDefault,
	NormalizedRelatedEntities,
	RelatedEntities,
	RelatedEntitiesColumns,
	RelationshipDisplayName,
} from '../types';
import { normalizeRelatedEntitiesToDisplay } from '../utilities';
import { TableFooter } from './TableComponents/TableFooter';
import { TableHeader } from './TableComponents/TableHeader';

export const RelatedEntitiesTable = ({
	header,
	relatedEntities,
	columns,
	currentEntity,
	entityType,
	nodeObjectName,
}: {
	nodeObjectName: string;
	header: RelationshipDisplayName | string;
	currentEntity: string;
	pushEntity?: (entityId: string) => void;
	relatedEntities: RelatedEntities;
	columns: RelatedEntitiesColumns;
	entityType: EntityTypeOrDefault;
}) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const { reportEvent } = useEntityProfileReportEvent('related-entities-table');

	const pageSize = columns.display.config.first;
	const isPaginationAvailable = relatedEntities?.length > pageSize;

	const normalizedRows = useMemo(
		() => normalizeRelatedEntitiesToDisplay(columns, searchTerm, relatedEntities),
		[columns, relatedEntities, searchTerm]
	);

	const currentCells = useMemo(
		() => normalizedRows.slice((currentPage - 1) * pageSize, currentPage * pageSize),
		[currentPage, normalizedRows, pageSize]
	);

	if (!searchTerm && normalizedRows?.length == 0) {
		return <></>;
	}

	return (
		<Box pb={'16px'}>
			<Flex
				border={`1px solid ${colors.gray[300]}`}
				borderRadius={'4px'}
				backgroundColor="white"
				direction="column"
				alignSelf="stretch"
			>
				<TableHeader
					header={header}
					searchValue={searchTerm}
					onChange={setSearchTerm}
					shouldAllowSearch={isPaginationAvailable}
				/>

				<Content
					normalizedRows={currentCells}
					currentEntity={currentEntity}
					entityType={entityType}
					nodeObjectName={nodeObjectName}
				/>
				{isPaginationAvailable && (
					<TableFooter
						currentPage={currentPage}
						totalItems={normalizedRows?.length}
						pageSize={pageSize}
						onChange={(newPage) => {
							setCurrentPage(newPage);
							reportEvent({
								event: 'entity-table-navigation',
								metaData: {
									from: currentPage,
									to: newPage,
								},
							});
						}}
					/>
				)}
			</Flex>
		</Box>
	);
};

const Content = ({
	normalizedRows,
	currentEntity,
	entityType,
	nodeObjectName,
}: {
	nodeObjectName: string;
	normalizedRows: NormalizedRelatedEntities;
	currentEntity: string;
	entityType: EntityTypeOrDefault;
}) => {
	const { pushEntity } = useEntityProfileDrawer();
	const { reportOpenEntityDrawer } = useEntityProfileReportEvent('related-entities-table', nodeObjectName);
	return (
		<Box p="16px">
			{normalizedRows.map(({ columns, sightfullId }, index) => {
				return (
					<TableRow
						key={`row-${currentEntity}-${columns.length}-${sightfullId}`}
						cells={columns.map((column) => {
							return {
								...column,
								key: `column-${currentEntity}-${column.text}-${column.supportingText}-${sightfullId}`,
								variant: 'textAndsupportingText',
								cursor: 'pointer',
							};
						})}
						variant="drawer"
						renderCell={(cellProps: TableCellProps) => <TableCell {...cellProps} />}
						onClick={() => {
							pushEntity(sightfullId);
							reportOpenEntityDrawer({
								origin: 'related-entities-table',
								id: sightfullId,
							});
						}}
						isFirstRow={index == 0}
						isLastRow={index == normalizedRows.length - 1}
						entityType={entityType}
					/>
				);
			})}
		</Box>
	);
};
