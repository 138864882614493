import { useEffect, useState } from 'react';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { UNTITLED_METRIC_DISPLAY, UNTITLED_METRIC_NAME } from './useMetricBuilder';

export type MetricMeta = {
	name: string;
	display_name: string;
	description?: string;
};

export const useMetricMeta = () => {
	const [metricMeta, setMetricMeta] = useState<MetricMeta>({
		name: UNTITLED_METRIC_NAME,
		display_name: UNTITLED_METRIC_DISPLAY,
	});
	const { metricBuilderState: builderState } = useBuilderDerivedState();

	useEffect(() => {
		if (!builderState) return;
		const { name: metaName, display_name: metaDisplayName, description: metaDescription } = metricMeta || {};
		const { name = metaName, meta } = builderState || {};
		const { display_name = metaDisplayName, description = metaDescription } = meta || {};
		if (metaName === name && metaDisplayName === display_name && metaDescription === description) return;
		setMetricMeta({
			name,
			display_name,
			description,
		});
	}, [builderState, metricMeta]);

	return { metricMeta };
};

export function useCachedMeasure() {
	const { metricBuilderState } = useBuilderDerivedState();
	const [measure, setMeasure] = useState<string>('');

	useEffect(() => {
		if (!metricBuilderState || metricBuilderState.type !== 'aggregate') return;
		if (metricBuilderState.measure !== undefined && measure !== metricBuilderState.measure) {
			setMeasure(metricBuilderState.measure);
		}
	}, [measure, metricBuilderState, setMeasure]);

	return { measure, setMeasure };
}
