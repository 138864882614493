import colors from 'src/style/colors';
import { AlertStatueses, ButtonVariants } from './Alert';
import * as CSS from 'csstype';

type SelectorProps = {
	color?: CSS.Property.Color;
	borderColor?: CSS.Property.BackgroundColor;
	backgroundColor?: CSS.Property.BackgroundColor;
};

type PropsForVariant = {
	color?: CSS.Property.Color;
	border?: CSS.Property.Border;
	textDecoration?: 'underline';
	borderColor?: CSS.Property.BackgroundColor;
	backgroundColor?: CSS.Property.BackgroundColor;
	_hover: SelectorProps;
	_active: SelectorProps;
};

export type AlertButtonStyles = { [status in AlertStatueses]: { [variant in ButtonVariants]: PropsForVariant } };

export const alertButtonStyles: AlertButtonStyles = {
	success: {
		solid: {
			backgroundColor: colors.green['700'],
			_hover: { backgroundColor: colors.green['800'] },
			_active: { backgroundColor: colors.green['900'] },
		},
		outline: {
			border: `1px solid`,
			color: colors.green['700'],
			backgroundColor: 'transparent',
			borderColor: colors.green['700'],
			_hover: { backgroundColor: 'transparent', borderColor: colors.green['800'], color: colors.green['800'] },
			_active: { backgroundColor: colors.green['200'], borderColor: colors.green['900'], color: colors.green['900'] },
		},
		link: {
			color: colors.green['1000'],
			textDecoration: 'underline',
			_hover: { backgroundColor: 'transparent', borderColor: colors.green['800'], color: colors.green['800'] },
			_active: { color: colors.green['900'] },
		},
	},

	error: {
		solid: {
			backgroundColor: colors.red['700'],
			_hover: { backgroundColor: colors.red['800'] },
			_active: { backgroundColor: colors.red['900'] },
		},
		outline: {
			border: '1px solid',
			color: colors.red['700'],
			backgroundColor: 'transparent',
			borderColor: colors.red['700'],
			_hover: { backgroundColor: 'transparent', borderColor: colors.red['800'], color: colors.red['800'] },
			_active: { backgroundColor: colors.red['200'], borderColor: colors.red['900'], color: colors.red['900'] },
		},
		link: {
			color: colors.red['1000'],
			textDecoration: 'underline',
			_hover: { backgroundColor: 'transparent', borderColor: colors.red['800'], color: colors.red['800'] },
			_active: { color: colors.red['900'] },
		},
	},

	info: {
		solid: {
			backgroundColor: colors.blue['800'],
			_hover: { backgroundColor: colors.blue['800'] },
			_active: { backgroundColor: colors.blue['900'] },
		},
		outline: {
			border: `1px solid`,
			color: colors.blue['800'],
			backgroundColor: 'transparent',
			borderColor: colors.blue['800'],
			_hover: { backgroundColor: 'transparent', borderColor: colors.blue['800'], color: colors.blue['800'] },
			_active: { backgroundColor: colors.blue['200'], borderColor: colors.blue['900'], color: colors.blue['900'] },
		},
		link: {
			color: colors.blue['1000'],
			textDecoration: 'underline',
			_hover: { backgroundColor: 'transparent', borderColor: colors.blue['800'], color: colors.blue['800'] },
			_active: { color: colors.blue['900'] },
		},
	},

	warning: {
		solid: {
			backgroundColor: colors.orange['700'],
			_hover: { backgroundColor: colors.orange['800'] },
			_active: { backgroundColor: colors.orange['900'] },
		},
		outline: {
			border: `1px solid`,
			color: colors.orange['700'],
			backgroundColor: 'transparent',
			borderColor: colors.orange['700'],
			_hover: { backgroundColor: 'transparent', borderColor: colors.orange['800'], color: colors.orange['800'] },
			_active: {
				backgroundColor: colors.orange['200'],
				borderColor: colors.orange['900'],
				color: colors.orange['900'],
			},
		},
		link: {
			color: colors.orange['1000'],
			textDecoration: 'underline',
			_hover: { backgroundColor: 'transparent', borderColor: colors.orange['800'], color: colors.orange['800'] },
			_active: { color: colors.orange['900'] },
		},
	},

	neutral: {
		solid: {
			backgroundColor: colors.gray['800'],
			_hover: { backgroundColor: colors.gray['800'] },
			_active: { backgroundColor: colors.gray['900'] },
		},
		outline: {
			border: `1px solid`,
			color: colors.gray['800'],
			backgroundColor: 'transparent',
			borderColor: colors.gray['800'],
			_hover: { backgroundColor: 'transparent', borderColor: colors.gray['800'], color: colors.gray['800'] },
			_active: { backgroundColor: colors.gray['200'], borderColor: colors.gray['900'], color: colors.gray['900'] },
		},
		link: {
			color: colors.gray['1000'],
			textDecoration: 'underline',
			_hover: { backgroundColor: 'transparent', borderColor: colors.gray['800'], color: colors.gray['800'] },
			_active: { color: colors.gray['900'] },
		},
	},
};
