import { IMarkdownString } from 'monaco-editor';
import Box from 'src/common/components/Box';

export function MonacoTooltipComponent({ tooltipMarkdownString }: { tooltipMarkdownString?: IMarkdownString }) {
	return (
		<Box className="monaco-with-css-tooltips">
			<Box className="monaco-suggest-tooltip-wrapper">
				<Box
					className="suggest-details"
					dangerouslySetInnerHTML={{
						__html: removeCssTooltipMarkdown(tooltipMarkdownString?.value || ''),
					}}
				/>
			</Box>
		</Box>
	);
}

export const removeCssTooltipMarkdown = (text: string) => text.replace(/```yaml/g, '').replace(/```/g, '');
