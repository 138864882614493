import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';

export default function useIsDataOverviewEnabled(): boolean {
	const isDataOverviewEnabled = useFeatureFlag('pulse.react.generative.dataOverview.enable');

	const { isLoading } = useMetricDerivedState();

	return !isLoading && isDataOverviewEnabled;
}
