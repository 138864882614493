import { useCallback } from 'react';
import { StatisticOperation } from 'src/pages/MetricPage/utils/state.types';
import colors from 'src/style/colors';
import Box from '../Box';
import { BubbleSeries } from '../Chart/types';
import Flex from '../Flex';
import { TriangleDownSolid16, TriangleUpSolid16 } from '../Icons';
import { Label } from '../Labels';
import { useObserveElementWidth } from '../MetricView/MetricChart/MetricChart';
import Typography from '../Typography';
import styles from './styles.module.scss';

export type BigBoldNumberProps = {
	title?: string;
	value: string;
	growth?: BubbleSeries;
	delta?: BubbleSeries;
	targetValue?: string;
	growthPeriod?: StatisticOperation;
	deltaPeriod?: StatisticOperation;
	countData?: {
		countValue: number;
		countName: string;
	};
};

export function BigBoldNumber({
	title,
	value,
	growth,
	delta,
	deltaPeriod,
	targetValue,
	growthPeriod,
	countData,
}: BigBoldNumberProps) {
	const { ref, width } = useObserveElementWidth<HTMLDivElement>();

	const calculateFonts = useCallback(() => {
		if (width <= 248) {
			return {
				lineHeight: '51px',
				fontSize: '38px',
			};
		}

		if (width <= 295) {
			return {
				lineHeight: '60px',
				fontSize: '47px',
			};
		}

		if (width <= 348) {
			return {
				lineHeight: '69px',
				fontSize: '56px',
			};
		}

		return {
			lineHeight: '85px',
			fontSize: '72px',
		};
	}, [width]);

	const deltaValue = delta?.dataPoints?.[0].label ? +delta?.dataPoints?.[0].label : 0;
	const growthValue = growth?.dataPoints?.[0].label ? +growth?.dataPoints?.[0].label : 0;
	const isDeltaPositive = deltaValue > 0;
	const isGrowthPositive = growthValue > 0;
	const deltaLabel = delta?.custom?.seriesDataPointYFormatter?.(deltaValue);
	const growthLabel = growth?.custom?.seriesDataPointYFormatter?.(growthValue);
	const growthSelectedPeriod = growthPeriod?.options[growthPeriod.selectedOptionIndex].value;
	const deltaSelectedPeriod = deltaPeriod?.options[deltaPeriod.selectedOptionIndex].value;
	return (
		<Flex
			color={colors.gray[1000]}
			paddingX="24px"
			paddingBottom="24px"
			ref={ref}
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
			height="100%"
			width="100%"
		>
			{title && (
				<Box marginBottom="4px" whiteSpace="nowrap">
					<Typography variant="DesktopH8Medium">{title}</Typography>
				</Box>
			)}
			<Box color={colors.gray[1000]} fontWeight={600} {...calculateFonts()} marginBottom="12px">
				{value}
			</Box>
			{(deltaLabel || growthLabel) && (
				<Flex justifyContent="center" flexWrap="wrap" marginBottom="8px" gap="8px">
					{!!growthValue && (
						<Label
							size="small"
							startIcon={isGrowthPositive ? <TriangleUpSolid16 /> : <TriangleDownSolid16 />}
							colorScheme={isGrowthPositive ? 'success' : 'error'}
							variant="filled"
						>
							{`${growthLabel} ${growthSelectedPeriod}`}
						</Label>
					)}
					{!!deltaValue && (
						<Label
							size="small"
							startIcon={isDeltaPositive ? <TriangleUpSolid16 /> : <TriangleDownSolid16 />}
							colorScheme={isDeltaPositive ? 'success' : 'error'}
							variant="filled"
						>
							{`${deltaLabel} ${deltaSelectedPeriod}`}
						</Label>
					)}
				</Flex>
			)}

			{(targetValue || countData) && (
				<Flex flexWrap="wrap">
					{targetValue && (
						<Typography className={styles.rightBorder} color={colors.gray[800]} variant="Paragraph12M">
							{targetValue}&nbsp;
							<Typography color={colors.gray[700]} variant="Paragraph12R">
								Target
							</Typography>
						</Typography>
					)}

					{!!countData && (
						<Typography className={styles.rightBorder} color={colors.gray[700]} variant="Paragraph12M">
							#{countData.countValue} {countData.countName}
						</Typography>
					)}
				</Flex>
			)}
		</Flex>
	);
}
