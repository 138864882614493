import Link from '@components/Link';
import Typography from '@components/Typography';
import pluralize from 'pluralize';
import {
	dimensionLink,
	metricLink,
	ontologyLink,
	parametersLink,
	relationshipLink,
} from '../YAMLEditorDescription/YAMLEditorDescription';
import { SchemaType } from './MonacoYamlEditor';

export function EditorHelpTooltip({
	onClick,
	schemaType = 'normalization',
	isReadonly = false,
}: {
	onClick: VoidFunction;
	isEntity?: boolean;
	schemaType?: SchemaType;
	isReadonly?: boolean;
}) {
	const defaultMeta = {
		title: 'metric',
		link: metricLink,
	};

	const ontologyMeta = {
		title: 'entity',
		link: ontologyLink,
	};

	const tooltipMeta: { [key in SchemaType]: { title: string; link: string } } = {
		normalization_dimension: {
			title: 'dimension',
			link: dimensionLink,
		},
		normalization_relationship: {
			title: 'relationship',
			link: relationshipLink,
		},
		normalization: ontologyMeta,
		normalization_raw_dimension: ontologyMeta,
		metric_agg: defaultMeta,
		metric_formula: defaultMeta,
		metric_file: defaultMeta,
		'base-entity': ontologyMeta,
		parameters: {
			title: 'parameter',
			link: parametersLink,
		},
	};

	return (
		<Typography
			onClick={(e) => {
				onClick();
				e.stopPropagation();
			}}
			color={'white'}
			variant="DesktopH8Regular"
		>
			{isReadonly
				? `Learn more about defining ${pluralize(tooltipMeta[schemaType].title)} in the `
				: `Edit your ${tooltipMeta[schemaType].title} in YAML format. For more information,
			please `}
			<Link textDecoration={'underline'} color={'blue.500'} target={'_blank'} href={tooltipMeta[schemaType].link}>
				{isReadonly ? 'Sightfull docs' : 'visit our help center'}
			</Link>
			.
		</Typography>
	);
}
