import { useMemo } from 'react';
import { Layout } from 'react-grid-layout';
import { SortOrder, SortOrderInfo, sortOrderToInfo } from 'src/pages/MetricPage/components/LegendsPanel/types';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';
import { Selector } from 'src/pages/MetricPage/utils/state.types';
import { useReportEvent } from 'src/services/analytics';
import { splitMetricComponentKey } from '../components/LegendsPanel/utils';
import { useMetricDerivedState } from './useMetricDerivedState';
import { useUpdateTableColumnState } from './useUpdateTableColumnState';

export function useSortOrder(): {
	sortOrderSelector: Selector<SortOrderInfo>;
	setSortOrder: (sortOrder?: SortOrder) => void;
	manualReorder: (layout: Layout[]) => void;
} {
	const { sortOrder } = useMetricDerivedState();
	const { setPartialSearchParams } = useMetricPageSearchParams();
	const { reportEvent } = useReportEvent();
	const { getColumnState } = useUpdateTableColumnState(false);

	const sortOrderOptions: SortOrderInfo[] = useMemo(() => {
		return sortOrder.optionalValues.map((order) => {
			return sortOrderToInfo[order];
		});
	}, [sortOrder.optionalValues]);

	const selectedSortOrder: SortOrderInfo = useMemo(() => {
		return sortOrderToInfo[sortOrder.selectedValue];
	}, [sortOrder.selectedValue]);

	function setSortOrder(sortOrder: SortOrder | undefined) {
		reportEvent({
			event: 'select-sort-order',
			metaData: { newSortOrder: sortOrder },
		});
		setPartialSearchParams({
			sortOrder,
			orderedComponents: undefined,
		});
	}

	const maximumManualSortOrderComponents = 20;
	const manualReorder = (layout: Layout[]) => {
		reportEvent({
			event: 'select-sort-order',
			metaData: { newSortOrder: 'Manual' },
		});
		const orderedComponents = layout
			.sort((a, b) => a.y - b.y)
			.map((comp) => splitMetricComponentKey(comp.i).componentDisplayLegendValue)
			.slice(0, maximumManualSortOrderComponents);
		setPartialSearchParams({
			orderedComponents: Object.values(orderedComponents),
			sortOrder: undefined,
			tableColumnState: getColumnState(),
		});
	};

	return {
		sortOrderSelector: { optionalValues: sortOrderOptions, selectedValue: selectedSortOrder },
		setSortOrder,
		manualReorder,
	} as const;
}
