import { Popover, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import Box from '@components/Box';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import IconButton from '@components/IconButton';
import Switch from '@components/Switch';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import useToast from '@hooks/ui/useToast';
import { CloseLarge16, DuplicateSolid16, Link16, More16, NewTabSolid16 } from '@icons/index';
import { ReactElement } from 'react';
import { Avatar } from 'src/layout/EntityProfileDrawer/components';
import { EntityProfile } from 'src/layout/EntityProfileDrawer/types';
import { dataSourceIcons } from 'src/pages/DataHygiene/constants';
import { SupportedDataSources } from 'src/pages/DataHygiene/types';
import useNavigation from 'src/services/useNavigation';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { useEntityProfileReportEvent } from '../hooks';
import classes from './DrawerHeader.module.scss';

function DrawerHeaderButtons({ icon, label, onClick }: { icon: ReactElement; label: string; onClick: VoidFunction }) {
	return (
		<Tooltip size={'md'} label={label} placement={'top'}>
			<IconButton size="xs" icon={icon} colorScheme="gray" ariaLabel={'copy'} pr={'4px'} onClick={onClick} />
		</Tooltip>
	);
}

export function DrawerHeader({
	header,
	onDrawerClose,
	onShowRawToggle,
	shouldShowRawData,
	source,
	shouldShowTopHeader = true,
}: {
	onDrawerClose: VoidFunction;
	header: EntityProfile['header'];
	onShowRawToggle: (shouldShowRawData: boolean) => void;
	shouldShowRawData: boolean;
	source: SupportedDataSources;
	shouldShowTopHeader?: boolean;
}) {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const toast = useToast();
	const { reportEvent, wrapWithReport } = useEntityProfileReportEvent('drawer-header');
	const { navigate } = useNavigation();
	if (!header) return null;
	const { originId, originSourceEntityType, entityType, sourceLink, entityProfileLink, title, entityProfileFullLink } =
		header;

	const DataSourceIcon = dataSourceIcons[source] ?? <></>;

	const MoreActionsPopover = (
		<Popover placement="bottom-end" variant="solid" isOpen={isOpen} onClose={onClose}>
			<PopoverTrigger>
				<Flex position={'relative'}>
					<IconButton
						size="xs"
						icon={<More16 />}
						colorScheme="gray"
						ariaLabel={'more'}
						onClick={onOpen}
						isActive={isOpen}
					/>
				</Flex>
			</PopoverTrigger>
			<PopoverContent>
				<Flex direction={'column'} py="20px" px="16px">
					<Flex direction={'row'} pb={'4px'} alignItems={'center'}>
						<Switch
							size={'sm'}
							onChange={(e) => {
								onShowRawToggle(e.target.checked);
								reportEvent({
									event: 'change-show-raw-data-toggle',
									metaData: { value: e.target.checked },
								});
								setTimeout(onClose, 800);
							}}
							isChecked={shouldShowRawData}
						/>
						<Typography variant={'DesktopH7Medium'} pl={'12px'} color={'gray.800'}>
							Show raw {source} properties
						</Typography>
					</Flex>
					<Typography variant={'Paragraph12R'} color={'gray.700'} pl={'40px'}>
						Show all origin fields from the source system.
					</Typography>
				</Flex>
			</PopoverContent>
		</Popover>
	);

	const isOriginSourceTypeIdentical = !originSourceEntityType || originSourceEntityType == entityType;

	const entityTypeSubtitle = (
		<Flex>
			<Typography variant={'DesktopH8Regular'} color={'gray.800'}>
				{entityType}
			</Typography>
			{!isOriginSourceTypeIdentical && (
				<Typography
					variant={'DesktopH8Regular'}
					color={'gray.800'}
					pl={'4px'}
					textDecoration={'underline dotted'}
					textUnderlineOffset={'2px'}
				>
					<Tooltip size={'md'} label={`${source}'s object name`} placement={'bottom'}>
						({originSourceEntityType})
					</Tooltip>
				</Typography>
			)}
		</Flex>
	);

	const openInSourcePlatform = wrapWithReport(() => window.open(sourceLink, '_blank'), 'click-open-in-source', {
		link: sourceLink,
	});

	const topHeader = (
		<Flex justifyContent={'space-between'} boxShadow={shadows.borderBottom} py={'8px'} px={'24px'}>
			<IconButton icon={<CloseLarge16 />} onClick={onDrawerClose} colorScheme={'black'} />
			<Flex alignItems={'center'} cursor={'pointer'} _hover={{ bg: 'gray.200' }} borderRadius="4px">
				{!!sourceLink && (
					<button onClick={openInSourcePlatform}>
						<Flex flexDirection={'row'} alignItems={'center'} px={'12px'}>
							<Box pr={'10px'}>{DataSourceIcon}</Box>
							<Typography variant={'DesktopH8Medium'} cursor={'pointer'}>
								Open in {source.toLowerCase()}
							</Typography>
						</Flex>
					</button>
				)}
			</Flex>
		</Flex>
	);

	const entityInformation = (
		<Flex direction={'column'}>
			<Typography pb={'12px'} variant={'DesktopH4'} color={'gray.1000'}>
				{title}
			</Typography>

			<Flex direction={'row'} alignItems={'center'}>
				<Tooltip label={`${source}’s object name`} placement="top" size="md" marginLeft="4px" hasArrow={false}>
					{entityTypeSubtitle}
				</Tooltip>
				<Flex px={'12px'} height={'16px'}>
					<Divider direction={'vertical'} color={'gray.500'} />
				</Flex>
				{!shouldShowTopHeader && (
					<>
						<Typography
							variant={'DesktopH8Regular'}
							color={'gray.800'}
							className={classes.copyLink}
							onClick={openInSourcePlatform}
						>
							Open in {source}
						</Typography>
						<Flex px={'12px'} height={'16px'}>
							<Divider direction={'vertical'} color={'gray.500'} />
						</Flex>
					</>
				)}
				<button
					onClick={wrapWithReport(
						() => {
							navigator.clipboard.writeText(originId);
							toast({ variant: 'ok', message: 'Copied ID to clipboard' });
						},
						'click-copy-source-id',
						{ id: originId }
					)}
				>
					<Flex alignItems={'center'} className={classes.copyLink}>
						<DuplicateSolid16 color={colors.gray['800']} />

						<Typography variant={'DesktopH8Regular'} color={'gray.800'} pl={'8px'}>
							Copy {source} ID
						</Typography>
					</Flex>
				</button>
			</Flex>
		</Flex>
	);

	return (
		<Flex flexDirection={'column'}>
			{shouldShowTopHeader && topHeader}
			<Box p={'24px'}>
				<Flex>
					<Flex pr={'16px'}>
						<Avatar entityType={entityType} />
					</Flex>
					<Flex justifyContent={'space-between'} grow={1}>
						{entityInformation}
						<Flex pl={'16px'}>
							<DrawerHeaderButtons
								icon={<NewTabSolid16 />}
								label={'Open in new tab'}
								onClick={wrapWithReport(
									() => {
										navigate({ path: entityProfileLink, isNewTab: true });
									},
									'open-in-new-tab-entity-profile',
									{ id: entityProfileLink }
								)}
							/>
							<DrawerHeaderButtons
								icon={<Link16 />}
								label={'Copy link'}
								onClick={wrapWithReport(
									() => {
										navigator.clipboard.writeText(entityProfileFullLink);
										toast({ variant: 'ok', message: 'Copied to clipboard' });
									},
									'click-copy-entity-profile-link',
									{ id: entityProfileLink }
								)}
							/>
							<Box>{MoreActionsPopover}</Box>
						</Flex>
					</Flex>
				</Flex>
			</Box>
		</Flex>
	);
}
