import Box from '@components/Box';
import Button from '@components/Button';
import Loader from '@components/Loader/Loader';
import Modal from '@components/Modal';
import { useEffect } from 'react';
import { useReportEvent } from 'src/services/analytics';
import Dimensions from './Dimensions';
import HeaderSection from './HeaderSection';
import Relationships from './Relationships';
import * as CONSTS from './consts';
import { FiltersAndBreakdownItemType, FiltersAndBreakdownResponseType, ListType } from './types';
import useFiltersAndBreakdownsLogic from './useFiltersAndBreakdownLogic';

import ModalHeader from 'src/common/components/ModalHeader';
import { Filter } from '../../utils/state.types';
import styles from './FiltersAndBreakdowns.module.scss';
import { NodeSchemeReturnType } from './NodeScheme/types';
import { getBreadcrumbs } from './NodeScheme/utils';

type Props = {
	type: ListType;
	isOpen: boolean;
	onClose: () => void;
	onAddItems: (result: FiltersAndBreakdownResponseType) => void;
	nodeScheme: NodeSchemeReturnType;
	hasBaseNode?: boolean;
	initialFilter?: Filter;
	displayBackArrow?: boolean;
	overridePickedName?: string;
	overrideTitle?: string;
	showRelationships?: boolean;
	isSingleValueSelectable?: boolean;
	displayShowNormalizedSelection?: boolean;
};

function FiltersAndBreakdownsModal({
	type,
	isOpen,
	onClose,
	onAddItems,
	nodeScheme,
	initialFilter,
	hasBaseNode = true,
	displayBackArrow = true,
	overridePickedName,
	showRelationships = true,
	overrideTitle,
	isSingleValueSelectable = false,
	displayShowNormalizedSelection = true,
}: Props) {
	const { reportEvent } = useReportEvent();
	const [
		{
			isLoading,
			isAllSelected,
			relationships,
			path,
			searchTerm,
			dimensions,
			selectedDimensionsCount,
			normalizedPropsSelectedOption,
		},
		{
			reset,
			traverseFiltersAndBreakdowns,
			traverseToSpecificPath,
			handleDimensionClick,
			handleSelectAllDimensions,
			handleDimensionSelection,
			handleSubmit,
			onSearchTermChange,
			setNormalizedPropsSelectedOption,
			setPath,
		},
	] = useFiltersAndBreakdownsLogic({ type, onClose, onAddItems, nodeScheme, hasBaseNode });
	const shouldHideFooter = [CONSTS.BREAKDOWN_MODEL_TYPE].includes(type);

	useEffect(() => {
		onSearchTermChange('');
		if (!isOpen) {
			reset();
			return;
		}

		if (initialFilter) {
			const breadcrumbs = getBreadcrumbs(initialFilter.key);
			setPath(
				initialFilter.key.split('.').map((e, index) => ({
					key: e,
					label: breadcrumbs[index],
				})),
				initialFilter.values
			);
			return;
		}

		traverseFiltersAndBreakdowns('init');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const filtersContainerClass = path.length > 1 ? styles.filtersWithBreadcrumbs : styles.filters;
	const breakdownsContainerClass = path.length > 1 ? styles.breakdownsWithBreadcrumbs : styles.breakdowns;

	const getAddButtonLabel = () => {
		const countText = `(${selectedDimensionsCount})`;
		switch (type) {
			case CONSTS.FILTER_MODEL_TYPE: {
				const pickedName = overridePickedName || 'filter';
				const filterText = selectedDimensionsCount > 1 ? `${pickedName}s` : pickedName;
				const fullText = selectedDimensionsCount ? `${filterText} ${countText}` : pickedName;
				return `Add ${fullText}`;
			}

			default:
				return `Add to table ${countText}`;
		}
	};

	function handleFiltersSubmit() {
		handleSubmit();
		reportSearchTermSelected();
	}

	const reportSearchTermSelected = () => {
		const dimensionsDerivedFromSearchTerm = dimensions.filter(
			(dimension) => dimension.isValueDerivedFromSearchTerm && dimension.isSelected
		);
		if (dimensionsDerivedFromSearchTerm.length !== 0) {
			reportEvent({
				event: 'dimension-derived-from-search-term-selected',
				metaData: {
					dimensions: dimensionsDerivedFromSearchTerm.map((dimension) => dimension.label),
				},
			});
		}
	};

	const footer = !shouldHideFooter && (
		<Box className={styles.footerContainer}>
			<Box mr="12px">
				<Button size="large" variant="outline" colorScheme="gray" onClick={onClose}>
					Cancel
				</Button>
			</Box>
			<Button
				size="large"
				variant="solid"
				colorScheme="blue"
				onClick={() => handleFiltersSubmit()}
				data-intercom-area={'metric'}
				data-intercom-type={'button'}
				data-intercom-target={`investigate-add-${type}-button`}
			>
				{getAddButtonLabel()}
			</Button>
		</Box>
	);

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} maxWidth="744px" minHeight="582px" hasOverlay={true} isCentered>
				<div data-intercom-area={'metric'} data-intercom-type={'modal'} data-intercom-target={`${type}s-modal`}>
					<ModalHeader size={'md'} onClose={onClose} title={overrideTitle || `Add ${type}`} />
					<Box className={styles.contentContainer}>
						<div className={styles.leftPanel}>
							<HeaderSection
								hasBaseNode={hasBaseNode}
								path={path}
								searchTerm={searchTerm}
								onSearchTermChange={onSearchTermChange}
								traverseToSpecificPath={(item?: FiltersAndBreakdownItemType) => {
									reportEvent({
										event: `metric-add-${type}-top-path-clicked`,
										metaData: {
											oldPath: path,
											traverseTo: item,
										},
									});
									onSearchTermChange('');
									traverseToSpecificPath(item);
								}}
								setNormalizedPropsSelectedOption={(newVal) => {
									reportEvent({
										event: `metric-${type}-modal-show-all-dimension-change`,
										metaData: {
											newValue: newVal.label,
											oldValue: normalizedPropsSelectedOption.label,
											modalType: type,
											path,
										},
									});
									setNormalizedPropsSelectedOption(newVal);
								}}
								normalizedPropsSelectedOption={normalizedPropsSelectedOption}
								displayBackArrow={displayBackArrow}
								displayShowNormalizedSelection={displayShowNormalizedSelection}
							/>
							<div
								className={`${styles.listItemsContainer} ${
									shouldHideFooter ? breakdownsContainerClass : filtersContainerClass
								}`}
							>
								{isLoading ? (
									<Loader isCenter />
								) : (
									<>
										{showRelationships && (
											<Relationships
												relationships={relationships}
												onRelationshipClick={function (item: any) {
													reportEvent({
														event: `metric-add-${type}-relationship-clicked`,
														metaData: {
															relationshipClicked: item,
															oldPath: path,
														},
													});
													onSearchTermChange('');
													return traverseFiltersAndBreakdowns('push', item);
												}}
												searchTerm={searchTerm}
											/>
										)}
										<Dimensions
											type={type}
											dimensions={dimensions}
											handleDimensionSelection={handleDimensionSelection}
											handleSelectAllDimensions={handleSelectAllDimensions}
											handleDimensionClick={(item) => {
												reportEvent({
													event: `metric-add-${type}-dimension-clicked`,
													metaData: {
														dimensionDisplayName: item.label,
														dimensionName: item.key,
														path,
													},
												});
												onSearchTermChange('');
												return handleDimensionClick(item);
											}}
											isAllSelected={isAllSelected}
											searchTerm={searchTerm}
											isSingleValueSelectable={isSingleValueSelectable}
										/>
									</>
								)}
							</div>
						</div>
					</Box>
					{footer}
				</div>
			</Modal>
		</>
	);
}

export default FiltersAndBreakdownsModal;
