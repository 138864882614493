import { Flex } from '@chakra-ui/react';
import { Skeleton } from 'antd';
import capitalize from 'lodash/capitalize';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import { AdvancedSelect } from 'src/common/components/AdvancedSelect';
import BuilderTextInput from 'src/common/components/BuilderTextInput';
import Divider from 'src/common/components/Divider';
import { SelectOption } from 'src/common/components/Select/types';
import { useModal } from 'src/common/hooks/ui/useModal';
import { TestIDs } from 'src/common/types/test-ids';
import { filterToSqlFilter } from 'src/common/utils/sqlFormatting';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { useCachedMeasure } from 'src/lib/metricRules/builder/useCachedBuilderProperties';
import { PeriodFilter } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components/PeriodFilter';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { AGGREGATE_METRIC_OPERATIONS } from 'src/pages/MetricPage/utils/editor.types';
import { formatBuilderFeatureEditMode } from 'src/pages/MetricPage/utils/eventMetadata';
import { useReportEvent } from 'src/services/analytics';
import FiltersAndBreakdownsModal from '../../FiltersAndBreakdown/FiltersAndBreakdownsModal';
import {
	convertModalResultToValidCoreValues,
	useCoreNodeScheme,
} from '../../FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { FiltersAndBreakdownResponseType } from '../../FiltersAndBreakdown/types';
import { EditorFeature } from '../EditPanel';
import { JoinsTooltip, MeasureTooltip, SelectLabel, TooltipIcon } from './components';
import { AggregateFilters } from './components/AggregateFilters';
import { JoinCard } from './components/JoinCard';
import { BigSelectLabel } from './components/SelectLabel';

function useBuilderFilterModal({ objectTypes, readyToFetch }: { objectTypes: string[]; readyToFetch: boolean }) {
	const { isOpen, onClose, onOpen } = useModal();

	const nodeScehme = useCoreNodeScheme({
		objectsTypes: objectTypes,
		readyToFetch: readyToFetch,
	});

	return { isOpen, onClose, onOpen, nodeScehme };
}

export function AggregateBuilder({
	selectedEntity,
	isFieldsDisabled,
	selectedFeature,
}: {
	selectedEntity?: SelectOption;
	isFieldsDisabled?: boolean;
	selectedFeature: EditorFeature;
}) {
	const { metricBuilderState, upsertYAMLProperty, upsertYAMLProperties } = useBuilderDerivedState();
	const {
		isLoading: isMetricStateLoading,
		metricNameWithoutFlavor,
		objectsTypes: metricObjectTypes,
	} = useMetricDerivedState();
	const { measure: cachedMeasure } = useCachedMeasure();
	const { reportEvent } = useReportEvent({
		metricName: metricNameWithoutFlavor,
		feature: 'Metric Builder',
		editMode: formatBuilderFeatureEditMode(selectedFeature),
	});

	const objectTypes = selectedEntity ? [selectedEntity.value] : metricObjectTypes;

	const { isOpen, onClose, onOpen, nodeScehme } = useBuilderFilterModal({
		objectTypes: objectTypes,
		readyToFetch: selectedEntity !== undefined || !isMetricStateLoading,
	});
	if (!metricBuilderState || metricBuilderState.type !== 'aggregate') return <Skeleton />;

	const filters = metricBuilderState.filters?.length ? metricBuilderState.filters : [];

	function appendFilter(selectedItems: FiltersAndBreakdownResponseType) {
		if (metricBuilderState && metricBuilderState.type !== 'aggregate') return;

		const normalizedFilters = convertModalResultToValidCoreValues(selectedItems, objectTypes[0]);
		const groupedFilters = mapValues(groupBy(normalizedFilters, 'key'), (filter) => filter.map((f) => f.value));
		const newFilters = Object.entries(groupedFilters).map(([filterKey, filterValues]) =>
			filterToSqlFilter(filterKey, filterValues)
		);
		const joinedFilters = [...filters, ...newFilters].filter((v) => v.sql.length > 0);

		upsertYAMLProperty('filters', joinedFilters, {
			shouldPreviewAfter: true,
		});
	}

	if (!metricBuilderState || metricBuilderState.type !== 'aggregate') return null;

	return (
		<Flex direction={'column'} gap={'12px'}>
			{metricBuilderState.joins?.length && (
				<SelectLabel
					text="Joins"
					isOptional
					trailingIcon={
						<TooltipIcon
							tooltipBody={
								<JoinsTooltip
									onHelpClicked={() =>
										reportEvent({
											event: 'metric-edit-help-clicked',
											metaData: {
												component: 'Joins',
											},
										})
									}
								/>
							}
						/>
					}
				/>
			)}

			{metricBuilderState.joins?.map((join, index) => (
				<JoinCard key={index} join={join} />
			))}
			<FiltersAndBreakdownsModal
				type={'filter'}
				isOpen={isOpen}
				onClose={onClose}
				onAddItems={(items) => appendFilter(items)}
				nodeScheme={nodeScehme}
			/>
			<AggregateFilters
				onAddFilter={onOpen}
				selectedFeature={selectedFeature}
				selectedEntity={selectedEntity?.value || objectTypes[0]}
				isFieldsDisabled={isFieldsDisabled}
			/>

			<Divider marginY="20px" direction="horizontal" />

			<BigSelectLabel text="Value (Y Axis)" />

			<SelectLabel
				text="Measure"
				trailingIcon={
					<TooltipIcon
						tooltipBody={
							<MeasureTooltip
								onHelpClicked={() =>
									reportEvent({
										event: 'metric-edit-help-clicked',
										metaData: {
											component: 'Measure',
										},
									})
								}
							/>
						}
					/>
				}
				paddingTop={'8px'}
			/>
			<BuilderTextInput
				testId={TestIDs.BUILDER_MEASURE_INPUT}
				fieldName="measure"
				placeholder={'Add measure'}
				validation={'None'}
				validationText={'Validation text'}
				readOnly={isFieldsDisabled || metricBuilderState.measure == undefined}
				contextSettings={{
					entity: selectedEntity?.value || objectTypes[0],
					metric: metricNameWithoutFlavor,
					includeTypes: [
						'dimension',
						'dimension_boolean',
						'function',
						'dimension_date',
						'dimension_numeric',
						'metric',
						'formula_metric',
						'join',
						'relationship',
						'parameter',
						'parameter_store',
					],
				}}
				text={metricBuilderState.measure}
				id={'measure'}
				onBlur={(newValue) => {
					upsertYAMLProperty('measure', newValue, { shouldPreviewAfter: true });
				}}
			/>

			<SelectLabel text="Operation" paddingTop={'20px'} />
			<AdvancedSelect
				isRequired
				onChange={(entityOption: SelectOption) => {
					reportEvent({
						event: 'metric-edit-UI-input-provided',
						metaData: {
							feature: 'Metric Builder',
							fieldName: 'operation',
							entity: selectedEntity,
							metric: metricNameWithoutFlavor,
							hasError: false,
							input: entityOption.value,
						},
					});
					if (entityOption.value === metricBuilderState.operation) return;
					const upsertProperties: { key: string; value: string | undefined }[] = [
						{ key: 'operation', value: entityOption.value },
					];

					if (entityOption.value === 'count') {
						upsertProperties.push({ key: 'measure', value: undefined });
					} else if (metricBuilderState.measure === undefined) {
						upsertProperties.push({ key: 'measure', value: cachedMeasure });
					}
					upsertYAMLProperties(upsertProperties, {
						shouldPreviewAfter:
							metricBuilderState.measure !== undefined || entityOption.value === 'count' || cachedMeasure !== '',
					});
				}}
				placeholder="Select operation"
				options={AGGREGATE_METRIC_OPERATIONS.map((el) => ({ label: capitalize(el), value: el }))}
				controlledValue={{ label: capitalize(metricBuilderState.operation), value: metricBuilderState.operation }}
				initialValue={{ label: capitalize(metricBuilderState.operation), value: metricBuilderState.operation }}
				dataIntercomTarget="aggregate-operation"
			/>

			<Divider marginY="20px" direction="horizontal" />

			<PeriodFilter selectedFeature={selectedFeature} selectedEntity={selectedEntity} isDisabled={isFieldsDisabled} />
		</Flex>
	);
}
