import { SelectOption } from 'src/common/components/Select/types';
import { StatisticOperationsParams } from 'src/common/utils/MetricSearchParams';
import { MetricDerivedState, StatisticOperation } from 'src/pages/MetricPage/utils/state.types';
import { OrderedAllStatistics, StatisticalOperation, StatisticalOperationInfo } from 'src/types/metric';
import { ORDERED_STATISTIC_OPERATIONS } from './constants';
import { ItemOptionsReturnType, OperationsAPIParamsType, StatisticItemOption } from './types';

type StatisticOperationPartialStateInput = Pick<MetricDerivedState, 'periodRange' | 'metricOperator' | 'hasGroupBy'>;

const shouldIncludeGrowthLikeStatisticRule = (derivedState: StatisticOperationPartialStateInput) => {
	return !derivedState.metricOperator.isConcatable || !derivedState.periodRange.isSinglePeriod;
};

const shouldIncludeAverageStatistic = (derivedState: StatisticOperationPartialStateInput) => {
	return (
		shouldIncludeGrowthLikeStatisticRule(derivedState) &&
		(!derivedState.periodRange.isSinglePeriod || derivedState.hasGroupBy)
	);
};

const OPERATION_VALIDATION_MAPPING: {
	[key in StatisticalOperation]: (derivedState: StatisticOperationPartialStateInput) => boolean;
} = {
	Average: shouldIncludeAverageStatistic,
	Growth: shouldIncludeGrowthLikeStatisticRule,
	Delta: shouldIncludeGrowthLikeStatisticRule,
};

const calcAvailableOperations = (derivedState: StatisticOperationPartialStateInput) => {
	if (!derivedState.periodRange.periodUnit) {
		console.error(`Calc available statistic operations: no {periodUnit} `);
		return [];
	}

	return OrderedAllStatistics.filter((key) => {
		const isOperationValid = OPERATION_VALIDATION_MAPPING[key];
		return isOperationValid(derivedState);
	}).map((key) => {
		const statisticOperation = ORDERED_STATISTIC_OPERATIONS[key];
		const durationOptions = durationOptionsByStatName[key];

		const { options, selectedOptionIndex } = durationOptions[derivedState.periodRange.periodUnit];
		statisticOperation.options = options;
		statisticOperation.selectedOptionIndex = selectedOptionIndex;

		return statisticOperation;
	});
};

export const getOperationInfo = (operation: StatisticOperation, value: string): OperationsAPIParamsType => {
	return {
		statisticsOperation: operation.name,
		operationInfo: {
			[operation.operationInfoKey]: value,
		} as StatisticalOperationInfo,
	};
};

const calcOperationsState = ({
	operations,
	statisticsOperationsParams,
}: {
	operations: StatisticOperation[];
	statisticsOperationsParams: StatisticOperationsParams;
}) => {
	const statisticsOperationsState = operations.map((operation) => {
		const value = (statisticsOperationsParams || {})[operation.name];
		const isChecked = !!value;
		const selectedOptionIndex = operation.options.findIndex((item) => item.value === value);
		const shouldSetIndex = selectedOptionIndex > -1;

		const operationStateItem = {
			...operation,
			selectedOptionIndex: shouldSetIndex ? selectedOptionIndex : operation.selectedOptionIndex,
			isChecked,
		};

		return operationStateItem;
	});

	return statisticsOperationsState;
};

export function calcStatisticOperations(
	statisticsOperationsParams: StatisticOperationsParams | undefined,
	currentState: StatisticOperationPartialStateInput
): Pick<MetricDerivedState, 'statisticsOperations'> {
	if (currentState.metricOperator.isConcatable) {
		return { statisticsOperations: [] };
	}

	if (!statisticsOperationsParams) {
		return { statisticsOperations: calcAvailableOperations(currentState) };
	}

	const operations = calcAvailableOperations(currentState);
	const statisticsOperations = calcOperationsState({ operations, statisticsOperationsParams });

	return { statisticsOperations };
}

const getItemData = (item: StatisticItemOption) => {
	let value;
	let label;

	if (Array.isArray(item)) {
		[value, label] = item;
	} else {
		value = item;
	}

	return {
		value,
		label,
	};
};

const calcItemOptions = (options: StatisticItemOption[], selectedValue: string): ItemOptionsReturnType => {
	let selectedOptionIndex = 0;

	const newOptions = options.map((item, i) => {
		const { value, label } = getItemData(item);

		const option: SelectOption = { value };

		if (value === selectedValue) {
			selectedOptionIndex = i;
		}

		if (label) {
			option.label = label;
		}

		return option;
	});

	return {
		options: newOptions,
		selectedOptionIndex,
	};
};

const UNUSED_PERIOD_UNITS_OPTIONS = {
	day: {
		options: [],
		selectedOptionIndex: -1,
	},
	cquarter: {
		options: [],
		selectedOptionIndex: -1,
	},
	custom: {
		options: [],
		selectedOptionIndex: -1,
	},
};

const GROWTH_LIKE_OPTIONS = {
	...UNUSED_PERIOD_UNITS_OPTIONS,
	week: calcItemOptions(['WoW'], 'WoW'),
	fweek: calcItemOptions(['QoQ', 'YoY'], 'QoQ'),
	month: calcItemOptions(['MoM', 'QoQ', 'YoY'], 'MoM'),
	quarter: calcItemOptions(['QoQ', 'YoY'], 'QoQ'),
	fquarter: calcItemOptions(['QoQ', 'YoY'], 'QoQ'),
	fyear: calcItemOptions(['YoY'], 'YoY'),
};

const AVERAGE_OPTIONS = {
	...UNUSED_PERIOD_UNITS_OPTIONS,
	week: calcItemOptions(
		[
			['2', '2 weeks'],
			['3', '3 weeks'],
			['4', '4 weeks'],
			['12', '12 weeks'],
		],
		'2'
	),
	fweek: calcItemOptions(
		[
			['2', '2 weeks'],
			['3', '3 weeks'],
			['4', '4 weeks'],
			['12', '12 weeks'],
		],
		'2'
	),
	month: calcItemOptions(
		[
			['3', '3 months'],
			['6', '6 months'],
			['12', '12 months'],
		],
		'3'
	),
	quarter: calcItemOptions(
		[
			['4', '4 quarters'],
			['8', '8 quarters'],
		],
		'4'
	),
	fquarter: calcItemOptions(
		[
			['4', '4 quarters'],
			['8', '8 quarters'],
		],
		'4'
	),
	fyear: calcItemOptions(
		[
			['2', '2 years'],
			['3', '3 years'],
		],
		'2'
	),
};

type OptionsByPeriod = {
	day: ItemOptionsReturnType;
	week: ItemOptionsReturnType;
	fweek: ItemOptionsReturnType;
	month: ItemOptionsReturnType;
	quarter: ItemOptionsReturnType;
	fquarter: ItemOptionsReturnType;
	cquarter: ItemOptionsReturnType;
	fyear: ItemOptionsReturnType;
	custom: ItemOptionsReturnType;
};

const durationOptionsByStatName: { [key in StatisticalOperation]: OptionsByPeriod } = {
	Average: AVERAGE_OPTIONS,
	Growth: GROWTH_LIKE_OPTIONS,
	Delta: GROWTH_LIKE_OPTIONS,
};
