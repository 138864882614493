import React from 'react';
import Box from '@components/Box';
import Typography from '@components/Typography';

type NavigationDrawerHeaderProps = {
	testId?: string;
	children?: React.ReactNode;
};

function NavigationDrawerHeader({ testId, children }: NavigationDrawerHeaderProps) {
	return (
		<Box data-testid={testId}>
			<Box px="24px" py="28px" pb={'12px'}>
				<Typography as="div" variant="Paragraph16R">
					{children}
				</Typography>
			</Box>
		</Box>
	);
}

export default NavigationDrawerHeader;
