import { Flex } from '@chakra-ui/react';
import Typography from '../../Typography';
import { cellTextColor } from '../tableCellStyles';
import { TextComponentProps, TextComponentVariants } from '../types';

const textVariant = {
	lightText: 'DesktopH8Regular',
	regularText: 'DesktopH8Medium',
} as const;

export const TextComponent = ({
	text,
	variant,
	isDisabled,
}: TextComponentProps & { variant: TextComponentVariants }) => {
	const color = isDisabled ? cellTextColor.disabled : cellTextColor.regular;

	return (
		<Flex justifyContent={'center'} alignItems={'center'} width={'100%'}>
			<Typography variant={textVariant[variant]} color={color}>
				{text}
			</Typography>
		</Flex>
	);
};
