import { MetricPeriod } from '@sightfull/period-ranges';
import { XAxisPlotBandsOptions } from 'highcharts';
import set from 'lodash/set';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import colors from 'src/style/colors';

export const plotBandId = 'plotBandId';

export function calcPlotBands({
	selectedXAxisElements,
	chartOptions,
	periodRange,
}: Pick<MetricDerivedState, 'selectedXAxisElements' | 'chartOptions' | 'periodRange' | 'breakdowns'>): Pick<
	MetricDerivedState,
	'chartOptions'
> {
	const isSinglePeriod = periodRange.isSinglePeriod;

	const plotBands = selectedXAxisElements
		.map<XAxisPlotBandsOptions | undefined>((e) => {
			if (!(e instanceof MetricPeriod)) return undefined;
			const selectedPlotBandIndex =
				chartOptions?.xAxis.values.findIndex((v) => (v instanceof MetricPeriod ? v?.id == e.id : false)) ?? -1;
			return {
				from: isSinglePeriod ? -0.5 : selectedPlotBandIndex - 0.5,
				to: isSinglePeriod ? chartOptions.series.length : selectedPlotBandIndex + 0.5,
				color: colors.blue['100'],
				id: plotBandId,
			};
		})
		.filter((e): e is XAxisPlotBandsOptions => e !== undefined);

	return { chartOptions: set(chartOptions, 'xAxis.plotBands', plotBands) };
}
