import { Box } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Badge from 'src/common/components/Badge';
import { Breakdown12 } from 'src/common/components/Icons';
import Popover from 'src/common/components/Popover';
import Typography from 'src/common/components/Typography';
import { WidgetHeaderProps } from './types';

export function BreakdownsBadge({ breakdowns }: Pick<WidgetHeaderProps, 'breakdowns'>) {
	const length = breakdowns?.values.length ?? 0;
	const breakdownLabels = breakdowns?.values.map((b) => (
		<Typography variant="Disclaimer12SB" key={b.key}>
			{b.label}
		</Typography>
	));
	const breakdownLabelsPadded = breakdownLabels?.map((breakdownLabel, i) => {
		return (
			<div key={`${i}wrapper`}>
				<Box key={`${i}label`} display="inline-block">
					{breakdownLabel}
				</Box>
				{i < breakdownLabels.length - 2 && (
					<Typography variant="Paragraph12R" key={`${i}comma`}>
						,&nbsp;
					</Typography>
				)}
				{i == breakdownLabels.length - 2 && (
					<Typography variant="Paragraph12R" key={`${i}and`}>
						&nbsp;and&nbsp;
					</Typography>
				)}
			</div>
		);
	});
	return (
		<Popover
			triggerElement={
				<Badge
					icon={<Breakdown12 />}
					iconColor="gray.700"
					color="transparent"
					hoverColor="blue.200"
					hoverIconColor="blue.700"
				/>
			}
			trigger="hover"
		>
			<Flex paddingX={'16px'} paddingY="10px" direction="column" gap="4px">
				<Typography variant="Paragraph12R" color={'gray.600'}>
					Breakdown{!!(length - 1) && 's'} ({length}):
				</Typography>
				<Flex direction={'row'}>{breakdownLabelsPadded}</Flex>
			</Flex>
		</Popover>
	);
}
