import { Flex } from '@chakra-ui/react';
import Box from '@components/Box';
import { ConfirmationModal } from '@components/ConfirmationModal';
import Typography from '@components/Typography';
import useMutation from '@hooks/fetching/useMutation';
import useToast from '@hooks/ui/useToast';
import { Fragment } from 'react';
import { TestIDs } from 'src/common/types/test-ids';
import {
	DeleteMetricMutation,
	DeleteMetricMutationVariables,
	MetricCatalogV2Query,
	MetricLineageInfo,
	useGetMetricLineageInfoQuery,
} from 'src/generated/graphql';
import { MetricCatalogPath } from 'src/pages/MetricPage/pageRoutesPaths';
import { DeleteMetric } from 'src/queries/core-manager';
import { MetricCatalogV2 } from 'src/queries/core-reader';
import { useReportEvent } from 'src/services/analytics';
import { useInvalidateCache } from 'src/services/apollo';
import useNavigation from 'src/services/useNavigation';
import { OriginPage } from './types';
import useNavigationBlock from 'src/services/useNavigationBlock';

function AffectedMetricLink({
	name,
	displayName,
	metricToDelete,
}: {
	name: string;
	displayName: string;
	metricToDelete: string;
}) {
	const { reportEvent } = useReportEvent();
	return (
		<a
			href={`${window.location.origin}/#/metric/${name}`}
			target="_blank"
			rel="noreferrer"
			style={{ textDecoration: 'underline' }}
			onClick={() => {
				reportEvent({
					event: 'affected-metric-clicked-on-deletion-modal',
					metaData: { deletedMetric: metricToDelete, metric: name },
				});
			}}
		>{`${displayName}`}</a>
	);
}

function MetricsLineageInfoParagraph({
	metricLineageInfo,
	metricToDelete,
}: {
	metricLineageInfo?: MetricLineageInfo;
	metricToDelete: string;
}) {
	if (!metricLineageInfo || metricLineageInfo.affects.metrics.length == 0) {
		return <></>;
	}

	const metrics = metricLineageInfo.affects.metrics;
	return (
		<Box>
			This action will affect&nbsp;
			<Box display={'inline-block'} fontWeight={'600'}>
				{`${metrics.length} other metrics: `}
			</Box>
			&nbsp;
			{metrics.map(({ name, displayName }, index) => (
				<Fragment key={`link-to-metric-${name}-in-delete-modal-of-${metricToDelete}`}>
					<AffectedMetricLink name={name} displayName={displayName} metricToDelete={metricToDelete} />
					{index < metrics.length - 1 ? `, ` : '.'}
				</Fragment>
			))}
		</Box>
	);
}

export function DeleteMetricModal({
	isOpen,
	originPage,
	metricName,
	metricDisplayName,
	onClose,
}: {
	isOpen: boolean;
	originPage: OriginPage;
	metricName: string;
	metricDisplayName?: string;
	onClose: () => void;
}) {
	const { reportEvent } = useReportEvent();
	const { navigate } = useNavigation();
	const { invalidateCache } = useInvalidateCache();
	const { setIsNavigationBlocked } = useNavigationBlock();
	const [deleteMetric, { loading: isDeleteLoading }] = useMutation<DeleteMetricMutation, DeleteMetricMutationVariables>(
		DeleteMetric
	);
	const { data: metricLineageInfo, loading: isLineageLoading } = useGetMetricLineageInfoQuery({
		variables: { metricName },
		skip: !isOpen,
	});
	const toast = useToast();
	const reportModalChoice = ({ action }: { action: 'confirm' | 'cancel' }) => {
		reportEvent({
			event: 'metric-delete-modal',
			metaData: { metricName, action, page: originPage },
		});
	};
	const onDeleteMetric = () => {
		reportModalChoice({ action: 'confirm' });
		deleteMetric({
			variables: { metricName },
			update: (cache) => {
				const catalogFromCache = cache.readQuery<MetricCatalogV2Query>({
					query: MetricCatalogV2,
				});

				if (catalogFromCache) {
					const newCatalogCategories = catalogFromCache.metricCatalogV2.categories.map((category) => {
						return {
							...category,
							metrics: category.metrics.filter((metric) => {
								return metric.metricName != metricName;
							}),
						};
					});

					cache.writeQuery({
						query: MetricCatalogV2,
						data: {
							metricCatalogV2: {
								categories: newCatalogCategories,
							},
						},
					});
				}
			},
		})
			.then(() => {
				toast({
					variant: 'ok',
					message: (
						<>
							<Typography variant="Paragraph14SB" color="gray.1000">
								{metricDisplayName + ' '}
								<Typography variant="Paragraph14R" color="gray.1000">
									was successfully deleted.
								</Typography>
							</Typography>
						</>
					),
				});
				if (originPage == 'metric page') {
					setIsNavigationBlocked({ isBlocked: false });
					return navigate({ path: MetricCatalogPath });
				}
			})
			.catch(() => {
				invalidateCache();
				toast({
					variant: 'error',
					message: (
						<>
							<Typography variant="Paragraph14R" color="gray.1000">
								{'Failed to delete '}
								<Typography variant="Paragraph14SB" color="gray.1000">
									{metricDisplayName}
								</Typography>
								.
							</Typography>
						</>
					),
				});
			})
			.finally(() => {
				onClose();
			});
	};

	const onCancelDelete = () => {
		onClose();
		reportModalChoice({ action: 'cancel' });
	};

	return (
		<ConfirmationModal
			primaryButtonLabel={'Delete'}
			isOpen={isOpen}
			modalText={
				<Flex data-testid={TestIDs.DELETE_METRIC_MODAL} direction={'column'} gap="10px">
					<Box>
						The&nbsp;
						<Box display={'inline-block'} fontWeight={'600'}>
							{metricDisplayName}
						</Box>
						&nbsp;metric will be permanently deleted from your catalog and associated dashboards.
					</Box>
					<MetricsLineageInfoParagraph
						metricLineageInfo={metricLineageInfo?.metricLineageInfo}
						metricToDelete={metricName}
					/>
				</Flex>
			}
			modalTitle={'Delete metric.'}
			onSubmit={onDeleteMetric}
			onClose={onCancelDelete}
			isLoading={isDeleteLoading}
			isWaitingForContent={isLineageLoading}
			submitColorScheme={'red'}
			minimumModalHeight="300px"
		/>
	);
}
