import { Box } from '@chakra-ui/react';
import React from 'react';
import Button from 'src/common/components/Button';
import { Send16 } from 'src/common/components/Icons';
import classes from 'src/common/components/Typography/LoadingThreeDots/LoadingThreeDots.module.scss';
import { styleByVariant } from 'src/common/components/Typography/Typography';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import colors from 'src/style/colors';
import { AutoResizeTextarea } from './components/AutoResizeTextarea';

export function ChatInput({
	inputValue,
	onUpdateInputValue,
	onSubmit,
	isEnabled,
	inputRef,
}: {
	inputRef?: React.RefObject<HTMLTextAreaElement>;
	inputValue: string;
	onUpdateInputValue: (e: string) => void;
	onSubmit: (event: 'enter' | 'submit') => (value: string) => void;
	isEnabled: boolean;
}) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const onEnterSubmit = onSubmit('enter');
	const onSubmitClick = onSubmit('submit');
	const { metricDisplayName, flavor } = useMetricDerivedState();
	const metricName = flavor ? `${metricDisplayName} (${flavor.selectedValue})` : metricDisplayName;

	const placeholder = `Ask me about ${isSightfull2 ? 'this metric' : metricName}...`;

	const { fontSize, fontStyle, fontWeight, lineHeight } = styleByVariant['Paragraph16R'];
	const textProps = { fontSize, fontStyle, fontWeight, lineHeight };
	const border = `1px solid ${colors.gray['400']}`;
	const focusBorder = `1px solid ${colors.gray[500]}`;

	return (
		<Box>
			<AutoResizeTextarea
				onSubmit={onEnterSubmit}
				ref={inputRef}
				{...textProps}
				maxHeight="200px"
				paddingY="10px"
				paddingLeft={'16px'}
				paddingRight={'48px'}
				background="white"
				_focusVisible={{ border: focusBorder }}
				border={border}
				color={colors.gray['800']}
				value={inputValue}
				placeholder={placeholder}
				_placeholder={{ color: colors.gray['600'] }}
				onChange={(e) => onUpdateInputValue(e.currentTarget.value)}
			/>
			<Box padding="10px" position="absolute" right="10px" bottom="10px">
				{isEnabled ? (
					<Button
						size="xs"
						variant="ghost"
						border="none"
						colorScheme="lightGray"
						isIconOnly
						isDisabled={inputValue == ''}
						margin="2px"
						onClick={() => onSubmitClick(inputValue)}
					>
						<Send16 />
					</Button>
				) : (
					<Box color="gray.600" paddingRight="20px" paddingY={'6px'} fontSize={'24px'} className={classes.loading} />
				)}
			</Box>
		</Box>
	);
}
