import { PageMode } from 'src/common/utils/MetricSearchParams';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';

export function useMetricPageMode() {
	const { searchParams, setPartialSearchParams } = useMetricPageSearchParams();

	function setMetricPageMode(pageMode: PageMode) {
		setPartialSearchParams({
			pageMode,
		});
	}

	return { setMetricPageMode, pageModeParam: searchParams.pageMode };
}
