export function getExportAPIUrl({
	apiUrl,
	from,
	to,
	metricNameWithFlavor,
	groupByText,
	filterByText,
	collectPropsText,
}: {
	apiUrl: string;
	from: string;
	to?: string;
	metricNameWithFlavor: string;
	groupByText?: string;
	filterByText?: string;
	collectPropsText?: string;
}) {
	const params = {
		from,
		to: to ?? from,
		groupBy: groupByText,
		filterBy: filterByText,
		collectProps: collectPropsText,
		output: 'value',
		format: 'CSV',
	};

	const validParams = Object.entries(params).filter((entry): entry is [string, string] => !!entry[1]);
	const formattedParams = validParams.map(([key, value]) => `${key}=${value}`).join('&');

	const encodedURI = encodeURI(`${apiUrl}${metricNameWithFlavor}?${formattedParams}`).replace('#', '%23');

	return encodedURI;
}
