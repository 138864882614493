import { ApolloError, QueryResult, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import {
	GetAllDimensionValuesQuery,
	GetAllDimensionValuesQueryVariables,
	GetGlobalFiltersBreakdownsOptionsQuery,
	GetGlobalFiltersBreakdownsOptionsQueryVariables,
} from 'src/generated/graphql';
import { GetAllDimensionValues, GetGlobalFiltersOptions } from 'src/queries/metrics';
import useFeatureFlag from '../stores/useFeatureFlag';

export type GlobalFiltersCallback = (objects: string[]) => Promise<GlobalFiltersResult | undefined>;

export function useGlobalFiltersApi(): [
	{
		loading: boolean;
		data?: GetGlobalFiltersBreakdownsOptionsQuery;
		error?: ApolloError;
	},
	GlobalFiltersCallback
] {
	const [lazyCallApi, { data, loading, error }] = useLazyQuery<
		GetGlobalFiltersBreakdownsOptionsQuery,
		GetGlobalFiltersBreakdownsOptionsQueryVariables
	>(GetGlobalFiltersOptions);
	const verboseQuery = useFeatureFlag('pulse.metrics.sendVerboseQueriesToBizapits');

	const callApi = useCallback(
		(objects: string[]): Promise<GlobalFiltersResult | undefined> => {
			return lazyCallApi({
				variables: {
					objects,
					verbose: verboseQuery,
				},
			}).then((res) => res.data?.globalFiltersBreakdownsOptions[0].result as GlobalFiltersResult);
		},
		[lazyCallApi, verboseQuery]
	);

	return [{ data, loading, error }, callApi];
}

export type DataModelDimension = {
	dimension: string;
	object: string;
	is_deleted: boolean;
	is_normalized?: boolean;
	editable: boolean;
	examples: string[] | number[];
	type: 'string' | 'datetime' | 'float';
};
export type GlobalFiltersResult = {
	[objectName in string]: {
		dimensions: DataModelDimension[];
		relationships: DataModelDimension[];
	};
};

export type GetAllDimensionValuesCallback = (object: string, dimension: string) => Promise<any[]>;

export function useGetAllDimensionValuesApi(): [
	Pick<QueryResult<GetAllDimensionValuesQuery, GetAllDimensionValuesQueryVariables>, 'data' | 'loading' | 'error'>,
	GetAllDimensionValuesCallback
] {
	const [lazyCallApi, { data, loading, error }] = useLazyQuery<
		GetAllDimensionValuesQuery,
		GetAllDimensionValuesQueryVariables
	>(GetAllDimensionValues);
	const verboseQuery = useFeatureFlag('pulse.metrics.sendVerboseQueriesToBizapits');

	const callApi = useCallback(
		(object: string, dimension: string): Promise<any> => {
			return lazyCallApi({
				variables: {
					object,
					dimension,
					verbose: verboseQuery,
				},
			}).then((res) => res.data?.allDimensionValues[0].result.values);
		},
		[lazyCallApi, verboseQuery]
	);

	return [{ data, loading, error }, callApi];
}
