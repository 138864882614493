import { ErrorYellow16, ManyToOne20, OneToMany20 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { OntologyRelationshipInfo } from '../../utils/normalizeYaml';
import ItemCard from './ItemCard';
import ThreeDotsButton from './ThreeDotsButton';

export interface ListHeaderProps {
	item: OntologyRelationshipInfo;
	entityDisplayName: string;
	referencedEntityDisplayName: string;
	isFullyDefined?: boolean;
	onDelete: VoidFunction;
	isEditable: boolean;
}

export default function RelationshipItem({
	item,
	entityDisplayName,
	referencedEntityDisplayName,
	isFullyDefined,
	onDelete,
	isEditable,
}: ListHeaderProps) {
	const icon = item.type === 'one_to_many' ? <OneToMany20 /> : <ManyToOne20 />;

	return (
		<ItemCard
			icon={icon}
			title={item.meta?.display_name || removeUnderscoresAndCapitalize(item.name)}
			description={
				<Typography cursor={'pointer'} display={'box'} variant="DesktopH10Regular">
					From&nbsp;
					<Typography cursor={'pointer'} display={'inline-block'} variant="DesktopH10Medium">
						{entityDisplayName}
					</Typography>
					&nbsp;to&nbsp;
					<Typography cursor={'pointer'} display={'inline-block'} variant="DesktopH10Medium">
						{referencedEntityDisplayName}
					</Typography>
				</Typography>
			}
			cardActions={<ThreeDotsButton type="relationships" onDelete={onDelete} isEditable={isEditable} />}
			suffixIcon={!isFullyDefined && <ErrorYellow16 />}
		/>
	);
}
