import useSearchParams from '@hooks/navigation/useSearchParams';
import { useSetAtom } from 'jotai';
import { EntityDrawerIdsAtom, EntityId, EntityProfileScope } from 'src/layout/EntityProfileDrawer/atoms';
import { useEntityProfileReportEvent } from './useEntityProfileReportEvent';

export function useEntityProfileDrawer():
	| { pushEntity: () => void; popEntity: () => void }
	| {
			pushEntity: (entityId: EntityId) => void;
			popEntity: () => void;
	  } {
	const { reportEvent } = useEntityProfileReportEvent();
	const setEntityDrawerIds = useSetAtom(EntityDrawerIdsAtom, EntityProfileScope);
	const [urlSearchParams, setUrlSearchParams] = useSearchParams();

	const popEntity = () =>
		setEntityDrawerIds((currentIds) => {
			const newIds = currentIds.slice(0, -1);
			if (newIds.length == 0) {
				urlSearchParams.delete(openEntitiesSearchParam);
			} else {
				urlSearchParams.set(openEntitiesSearchParam, newIds.toString());
			}
			setUrlSearchParams(urlSearchParams);

			reportEvent({ event: 'entity-drawer-close', metaData: { entityId: currentIds[currentIds.length - 1] } });
			return newIds;
		});
	const pushEntity = (entityId: EntityId) =>
		setEntityDrawerIds((currentIds) => {
			const newIds = [...currentIds, entityId];
			urlSearchParams.set(openEntitiesSearchParam, newIds.toString());
			setUrlSearchParams(urlSearchParams);

			return newIds;
		});

	return { popEntity, pushEntity };
}

export const openEntitiesSearchParam = 'OpenedEntities';
