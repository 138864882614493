import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import useUser from 'src/common/hooks/stores/useUser';
import {
	SignalsPersonalFeedQuery,
	SignalsPersonalFeedQueryVariables,
	usePersonalFeedLengthSubscription,
} from 'src/generated/graphql';
import { SignalsPersonalFeed } from 'src/queries/signals';
import { FeedSignal } from 'src/types/spaces';
import { isSignalAnInsight, isSignalANotification, isSignalValid } from '../utils';
import { useMyReasonsRegexp } from './useMyReasonsRegexp';

export const INFINITE_SCROLL_BATCH_SIZE = 10;
export function useFeed() {
	const [{ id: my_id }] = useUser();
	const myReasonsRegexp = useMyReasonsRegexp();
	const {
		loading: isDataLoading,
		data,
		fetchMore,
		refetch,
	} = useQuery<SignalsPersonalFeedQuery, SignalsPersonalFeedQueryVariables>(SignalsPersonalFeed, {
		variables: { offset: 0, limit: INFINITE_SCROLL_BATCH_SIZE, my_id },
		notifyOnNetworkStatusChange: true,
		refetchWritePolicy: 'overwrite',
	});

	useEffect(() => {
		refetch();
	}, [refetch]);

	const { data: feedLengthData } = usePersonalFeedLengthSubscription({
		variables: { my_id },
	});
	const feedTotalLength = feedLengthData?.feeds_by_pk?.feed_signals_aggregate.aggregate?.count ?? 0;

	const feedItems = useMemo(
		() => (data?.feeds_by_pk?.feed_signals ?? []) as FeedSignal[],
		[data?.feeds_by_pk?.feed_signals]
	);

	const fetchMoreItems = useCallback(async () => {
		fetchMore({
			variables: {
				offset: data?.feeds_by_pk?.feed_signals.length,
			},
		});
	}, [data?.feeds_by_pk?.feed_signals.length, fetchMore]);

	const insights = useMemo(() => feedItems.filter(isSignalAnInsight), [feedItems]);
	const notifications = useMemo(
		() => feedItems.filter((signal) => isSignalANotification(signal, new RegExp(myReasonsRegexp))),
		[feedItems, myReasonsRegexp]
	);
	const all = useMemo(
		() => feedItems.filter((signal) => isSignalValid(signal, new RegExp(myReasonsRegexp))),
		[feedItems, myReasonsRegexp]
	);

	const areAllItemsFetched = useMemo(() => feedTotalLength <= feedItems.length, [feedItems.length, feedTotalLength]);

	return {
		fetchMoreItems,
		feeds: { all, insights, notifications },
		isLoading: isDataLoading,
		areAllItemsFetched,
	};
}
