import Modal from '../Modal/Modal';
import { TenantPicker } from './TenantPicker';

interface TenantPickerProps {
	isOpen: boolean;
	onClose: () => void;
}

export function TenantPickerModal({ isOpen, onClose }: TenantPickerProps) {
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} maxWidth={'474px'} closeOnOverlayClick={true}>
				<TenantPicker onClose={onClose} />
			</Modal>
		</>
	);
}
