import { RawResponse } from '@hooks/fetching/useCalcMetricApi';
import { MetricPeriod } from '@sightfull/period-ranges';
import set from 'lodash/set';
import { NOT_AVAILABLE_VALUE_STRING } from 'src/common/utils/consts';
import { MetricOperator } from 'src/models/MetricOperator';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import {
	DetailedTable,
	MetricCalcInfo,
	MetricCalcPeriodResult,
	MetricCalcRawResult,
	MetricCalcResult,
	MetricCalcSeriesResult,
	MetricUnit,
	TotalSeriesName,
} from 'src/types/metric';

function parseTotalFromBucket(bucket: any, statsForPeriod: RawResponse[], responseMetric: any): MetricCalcSeriesResult {
	return {
		value: bucket.breakdown[TotalSeriesName],
		type: 'TOTAL',
		count: bucket.count[TotalSeriesName],
		statistics: statsForPeriod.map(({ response }) => ({
			name: response.data.request.operation,
			value: response.data.result,
		})),
		targets: Object.entries(bucket.targets ?? {}).map(([targetName, targetBucket]: [string, any]) => ({
			name: targetName,
			value: targetBucket.breakdown[TotalSeriesName],
		})),
		...overrideMetricInfo(responseMetric),
		name: TotalSeriesName, // overriding the name for total to user everywhere.
	};
}

function parseNullBucketComponents(
	nullBucket: any,
	componentName: string,
	responseMetric: any
): MetricCalcSeriesResult {
	if (componentName === TotalSeriesName) {
		throw 'Should not parse totals in components parser';
	}

	return {
		value: nullBucket.breakdown[componentName],
		type: 'COMPONENT',
		count: nullBucket.count[componentName],
		...overrideMetricInfo(responseMetric, componentName),
	};
}

function parseBucketsGroups(
	[bucketNames, bucketPayload]: [string[], any],
	responseMetric: any
): MetricCalcSeriesResult {
	if (bucketNames === null) {
		throw 'null bucket should be parsed in the null bucket parser';
	}

	return {
		name: bucketNames.join(', '),
		value: bucketPayload.breakdown[TotalSeriesName],
		type: 'GROUP',
		groupsNames: bucketNames,
		count: bucketPayload.count[TotalSeriesName],
		op: new MetricOperator(responseMetric.op),
		unit: responseMetric.unit,
	};
}

function overrideMetricInfo(
	responseMetric: any,
	component?: string
): { op: MetricOperator; unit?: MetricUnit; name: string; rawName?: string } {
	const metricCalcInfo = buildMetricInfo(responseMetric);
	const componentMetricInfo = metricCalcInfo.components?.find((componentInfo: any) => componentInfo.id === component);

	if (!component || !componentMetricInfo) {
		return {
			rawName: componentMetricInfo?.rawName,
			op: new MetricOperator(responseMetric.op),
			unit: responseMetric.unit,
			name: responseMetric.displayName,
		};
	}

	return {
		rawName: componentMetricInfo.rawName,
		op: componentMetricInfo.op,
		unit: componentMetricInfo.unit,
		name: componentMetricInfo.legendName,
	};
}

export function buildMetricInfo(responseMetric: any | undefined): MetricCalcInfo {
	if (!responseMetric) throw new Error('invalid responseMetric');

	return {
		op: new MetricOperator(responseMetric.op),
		unit: responseMetric.unit,
		name: responseMetric.displayName,
		period: MetricPeriod.fromUnitAndStartDate(
			new Date(responseMetric.start),
			null,
			responseMetric.duration,
			fiscalYearOffset()
		),
		legendName: responseMetric.legendName,
		components: responseMetric.components.map((componentInfo: any) => ({
			op: new MetricOperator(componentInfo.op),
			unit: componentInfo.unit,
			name: componentInfo.displayName,
			rawName: componentInfo.name,
			id: componentInfo.id,
			period: MetricPeriod.fromUnitAndStartDate(
				new Date(componentInfo.start),
				null,
				componentInfo.duration,
				fiscalYearOffset()
			),
			legendName: componentInfo.legendName,
		})),
	};
}

export function buildPeriodResultFromResponses(allResponses: RawResponse[]): MetricCalcResult['results'] {
	const calcResponses = allResponses.filter(({ responseType }) => responseType === 'CALC');
	const statsResponses = allResponses.filter(({ responseType }) => responseType === 'STATISTIC');

	return calcResponses.map(({ period, response }) => {
		if (!response.data) {
			return {
				period,
				series: [],
			};
		}

		const buckets = response.data.result;
		const responseMetric = response.data.metric;
		const nullBucket = buckets.find((result: any[]) => result[0] === null)[1];
		const statsForCurrentPeriod = statsResponses.filter(({ period: testedPeriod }) => testedPeriod.id === period.id);

		const isGroupBy = buckets.length > 1;

		const total = parseTotalFromBucket(nullBucket, statsForCurrentPeriod, responseMetric);

		let parsedSeries: MetricCalcSeriesResult[] = [total];
		if (isGroupBy) {
			const filteredBuckets = buckets.filter(([bucketName]: any) => bucketName !== null);
			const groupBySeries: MetricCalcSeriesResult[] = filteredBuckets.map((buckets: any) =>
				parseBucketsGroups(buckets, responseMetric)
			);
			parsedSeries = [...parsedSeries, ...groupBySeries];
		} else {
			const componentsNames = Object.keys(nullBucket.breakdown).filter((name) => name !== TotalSeriesName);
			const componentsSeries: MetricCalcSeriesResult[] = componentsNames.map((componentName) =>
				parseNullBucketComponents(nullBucket, componentName, responseMetric)
			);
			parsedSeries = [...parsedSeries, ...componentsSeries];
		}

		return {
			period,
			series: parsedSeries,
		} as MetricCalcPeriodResult;
	});
}

export const DetailedTableIdCol = 'Name';
export const DetailedTableIdGroupByV1 = ':id';
export const DetailedTableIdGroupByV2 = 'id';
export const DetailedTableIdCollectedProps = '_collectedProps';

const getCellValue = (possibleValue: any) => {
	const value = possibleValue.length > 1 ? possibleValue : possibleValue[0];

	switch (typeof value) {
		case 'boolean': {
			return String(value);
		}

		case 'undefined': {
			return NOT_AVAILABLE_VALUE_STRING;
		}

		default:
			return value;
	}
};

export function buildDetailedTableFromResponse(response: MetricCalcRawResult): DetailedTable {
	const rawRows = response.result;

	const idToName = response.metric.components.reduce(
		(acc: Record<string, string>, component: Record<string, string>) => {
			acc[component.id] = component.legendName;
			return acc;
		},
		{}
	);

	return rawRows
		.filter((rawRow): rawRow is [string, any] => rawRow[0] !== null)
		.map((rawRow) => {
			return {
				[DetailedTableIdGroupByV1]: rawRow[0][0],
				[DetailedTableIdCol]: rawRow[1].collected.Name[0],
				[TotalSeriesName]: { value: rawRow[1].breakdown[TotalSeriesName], count: rawRow[1].count[TotalSeriesName] },
				[DetailedTableIdCollectedProps]: Object.entries(rawRow[1].collected).reduce(
					(accumulator, [key, possibleValue]: [string, any]) => {
						const value = getCellValue(possibleValue);
						return set(accumulator, key, value);
					},
					{}
				),
				...Object.fromEntries(
					Object.entries(rawRow[1].breakdown)
						.filter(([key]) => key !== DetailedTableIdCol && key !== TotalSeriesName)
						.map(([key, value]: [string, any]) => [idToName[key], { value, count: rawRow[1].count[key] }])
				),
			};
		});
}
