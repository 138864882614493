import { Box, Flex } from '@chakra-ui/react';
import colors from 'src/style/colors';
import Typography from '../../Typography';
import { cellTextColor } from '../tableCellStyles';
import { TextAndSupportingTextProps } from '../types';
import { icons } from './SuffixIcons';

const leadTextStyles = {
	link: {
		color: colors.blue['700'],
		_hover: { textDecoration: 'underline', color: colors.blue['700'] },
	},
	disabled: {
		color: colors.gray['500'],
	},
	regular: {
		color: colors.gray['800'],
	},
};

export const TextAndSupportingTextComponent = ({
	text,
	supportingText,
	customSuffixIcons,
	link,
	isDisabled,
	leadIcon,
	suffixIcon,
	cursor,
	renderSuffixIcon,
}: TextAndSupportingTextProps) => {
	const shouldShowTextContent = text || supportingText;

	const getLeadTextStyle = () => {
		if (isDisabled) return leadTextStyles.disabled;
		if (link) return leadTextStyles.link;
		return leadTextStyles.regular;
	};

	const color = isDisabled ? cellTextColor.disabled : cellTextColor.regular;
	const shouldShowSuffixArea = (customSuffixIcons && renderSuffixIcon) || suffixIcon;
	const contentToCopy = text ?? supportingText ?? '';

	return shouldShowTextContent ? (
		<Flex direction="row" gap="16px" alignItems="center" w={'100%'}>
			{leadIcon && <Box>{leadIcon}</Box>}
			<Flex direction={'column'} gap={'4px'} w={'100%'}>
				<Flex direction={'row'}>
					{text?.length != 0 && (
						<Box flexGrow={1}>
							<Typography
								cursor={cursor}
								variant={'DesktopH8Medium'}
								{...getLeadTextStyle()}
								as={link ? 'a' : undefined}
								noOfLines={1}
								textOverflow="ellipsis"
								wordBreak="break-all"
							>
								{text}
							</Typography>
						</Box>
					)}

					{shouldShowSuffixArea && (
						<Flex gap={'4px'}>
							{suffixIcon?.map((icon) => {
								const CurIcon = icons[icon];

								return (
									<Box key={`suffix-icon-${icon}`}>
										<CurIcon contentToCopy={contentToCopy} />
									</Box>
								);
							})}

							{customSuffixIcons &&
								renderSuffixIcon &&
								customSuffixIcons.map((icon) => renderSuffixIcon(icon, isDisabled ?? false))}
						</Flex>
					)}
				</Flex>
				{supportingText?.length != 0 && (
					<Typography
						variant="DesktopH10Regular"
						color={color}
						cursor={cursor}
						noOfLines={1}
						textOverflow="ellipsis"
						wordBreak="break-all"
						maxWidth="210px"
					>
						{supportingText}
					</Typography>
				)}
			</Flex>
		</Flex>
	) : null;
};
