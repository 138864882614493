import Flex from '../Flex';
import colors from 'src/style/colors';
import Typography from '../Typography';
import { ReactNode } from 'react-markdown/lib/react-markdown';

type GenAIChipProps = {
	children: ReactNode;
	startIcon: ReactNode;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export function GenAIChip({ children, startIcon, onClick }: GenAIChipProps) {
	return (
		<Flex
			_hover={{
				background: colors.gray[100],
			}}
			flexWrap="nowrap"
			width="fit-content"
			alignItems="center"
			background={colors.white}
			border={`1px solid ${colors.gray[300]}`}
			borderRadius="2px"
			padding="3px 7px"
			gap="8px"
			as="button"
			onClick={onClick}
		>
			{startIcon}
			<Typography cursor="pointer" color={colors.gray[800]} variant="Paragraph14R">
				{children}
			</Typography>
		</Flex>
	);
}
