import { useDisclosure } from '@chakra-ui/react';
import Box from '@components/Box';
import Flex from '@components/Flex';
import { formatDistance } from 'date-fns';
import Avatar from 'src/common/components/Avatar/Avatar';
import IconButton from 'src/common/components/IconButton';
import { BinSolid16, DotsSolid16, EditFull16 } from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import Popover from 'src/common/components/Popover';
import Typography from 'src/common/components/Typography';
import useMentionsState from 'src/common/hooks/stores/useMentionsState';
import { CommentTextEditor } from 'src/pages/WorkspacePage/components/CommentTextEditor/CommentTextEditor';
import colors from 'src/style/colors';
import { UserType } from 'src/types/user';
import TextEditor from '../TextEditor';
import classes from './Comment.module.scss';

export type CommentProps = {
	author: Pick<UserType, 'firstName' | 'lastName' | 'picture'>;
	text: string;
	createdAt: Date;
	onDelete?: VoidFunction;
	onEditClick?: VoidFunction;
	isEditMode: boolean;
	onTextChange: (text: string) => void;
	onSubmit: () => Promise<void>;
	isEditLoading: boolean;
};

export default function Comment({
	author,
	createdAt,
	text,
	onDelete,
	onEditClick,
	isEditMode,
	onSubmit,
	onTextChange,
	isEditLoading,
}: CommentProps) {
	const authorFullName = `${author.firstName} ${author.lastName}`;
	const mentions = useMentionsState();

	const { isOpen, onOpen, onClose } = useDisclosure();

	const ActionsContent = (onEditClick || onDelete) && (
		<Popover
			isOpen={isOpen}
			onClose={onClose}
			placement="bottom-end"
			triggerElement={(isOpen) => (
				<Box className={isOpen ? '' : classes.commentActions}>
					<IconButton onClick={onOpen} size="xs" icon={<DotsSolid16 />} colorScheme="black" isActive={isOpen} />
				</Box>
			)}
		>
			<Box paddingY="8px">
				{onEditClick && (
					<ListItem
						prefixIcon={<EditFull16 />}
						label="Edit comment"
						size="sm"
						onClick={() => {
							onEditClick();
							onClose();
						}}
					/>
				)}
				{onDelete && (
					<ListItem
						prefixIcon={<BinSolid16 />}
						label="Delete"
						size="sm"
						onClick={() => {
							onDelete();
							onClose();
						}}
					/>
				)}
			</Box>
		</Popover>
	);

	return (
		<Box padding="12px">
			<Flex
				gap="8px"
				flexDirection="row"
				alignItems="start"
				justifyContent="start"
				padding="12px"
				className={classes.comment}
				borderRadius="4px"
			>
				<Avatar shape={'round'} height="24px" width="24px" imageUrl={author.picture} name={authorFullName} />

				<Flex flexDirection="column" flex={1}>
					<Flex justifyContent="space-between">
						<Flex flexDirection="column">
							<Typography variant={'DesktopH8Bold'} color={colors.gray['800']}>
								{authorFullName}
							</Typography>
							<Typography variant={'Paragraph12R'} color={colors.gray['700']} paddingBottom="8px">
								{formatDistance(createdAt, new Date(), { addSuffix: true })}
							</Typography>
						</Flex>
						<Box>{ActionsContent}</Box>
					</Flex>

					{isEditMode ? (
						<CommentTextEditor
							isClearingOnSubmit={false}
							value={text}
							isEditMode={true}
							onChange={onTextChange}
							isInsertLoading={isEditLoading}
							onSubmit={onSubmit}
						/>
					) : (
						<TextEditor value={text} isEditMode={false} mentions={mentions} />
					)}
				</Flex>
			</Flex>
		</Box>
	);
}
