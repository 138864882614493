import { Mention } from 'src/types/mention';
import { create } from 'zustand';

type MentionsStore = {
	mentions: Mention[];
	initialized: boolean;
	setMentions: (mentions: Mention[]) => void;
	setInitialized: (isInitialized: boolean) => void;
	reset: () => void;
};

export const useMentionsStore = create<MentionsStore>((set) => ({
	mentions: [],
	initialized: false,
	setMentions: (mentions: Mention[]) => set({ mentions }),
	setInitialized: (isInitialized: boolean) => set({ initialized: isInitialized }),
	reset: () => set({ mentions: [] }),
}));

export const selectors = {
	getMentions: (state: MentionsStore) => state.mentions,
	getInitialized: (state: MentionsStore) => state.initialized,
	setInitialized: (state: MentionsStore) => state.setInitialized,
	setMentions: (state: MentionsStore) => state.setMentions,
	reset: (state: MentionsStore) => state.reset,
};
