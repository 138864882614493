import { gql } from '@apollo/client';

export const MetricFragment = gql`
	fragment MetricFragment on metrics {
		category
		display_name
		flavor
		last_tool_synced_ts
		name
		oneliner
		op
		order
		relevant_period_ranges
		unit
		hide
		default_chart_type
		default_relative_period
		article_id
		should_metric_run
	}
`;

export const GetMetricByName = gql`
	${MetricFragment}
	query GetMetricByName($name: String!) {
		metrics(where: { name: { _eq: $name } }) {
			...MetricFragment
		}
	}
`;

export const GetAllMetrics = gql`
	${MetricFragment}
	query GetAllMetrics {
		metrics {
			...MetricFragment
		}
	}
`;

export const GetFiltersBreakdownsOptions = gql`
	query GetFiltersBreakdownsOptions(
		$metric: String!
		$periods: [String!]!
		$prefixPath: String = ""
		$verbose: Boolean
		$shouldIncludeNotNormalizedProps: Boolean
	) {
		filtersBreakdownsOptions(
			metric: $metric
			periods: $periods
			prefixPath: $prefixPath
			verbose: $verbose
			shouldIncludeNotNormalizedProps: $shouldIncludeNotNormalizedProps
		) {
			result
		}
	}
`;

export const GetDimensionValues = gql`
	query GetDimensionValues(
		$metric: String!
		$periods: [String!]!
		$prefixPath: String = ""
		$dimension: String!
		$verbose: Boolean
	) {
		dimensionValues(
			metric: $metric
			periods: $periods
			prefixPath: $prefixPath
			dimension: $dimension
			verbose: $verbose
		) {
			result
		}
	}
`;

export const MetricNodeTypes = gql`
	query MetricNodeTypes($metric: String!, $verbose: Boolean) {
		getMetricNodeTypes(metric: $metric, verbose: $verbose) {
			result
		}
	}
`;

export const CalculateMetric = gql`
	query CalculateMetric(
		$metricName: String!
		$period: String!
		$filterBy: String
		$groupBy: [String!]
		$collectProps: [String!]
		$responseFormat: String = "v1"
		$verbose: Boolean = false
		$limit: Int
	) {
		calculateMetric(
			metricName: $metricName
			period: $period
			filterBy: $filterBy
			groupBy: $groupBy
			responseFormat: $responseFormat
			collectedProps: $collectProps
			verbose: $verbose
			limit: $limit
		) {
			result
			metric
		}
	}
`;

export const CalculateMetricMulti = gql`
	query CalculateMetricMulti(
		$metricName: String!
		$periods: [String!]!
		$filterBy: String
		$groupBy: [String!]
		$collectProps: [String!]
		$responseFormat: String = "v1"
		$verbose: Boolean = false
		$limit: Int
	) {
		calculateMetricMulti(
			metricName: $metricName
			periods: $periods
			filterBy: $filterBy
			groupBy: $groupBy
			responseFormat: $responseFormat
			collectedProps: $collectProps
			verbose: $verbose
			limit: $limit
		) {
			result
		}
	}
`;

export const GetGlobalFiltersOptions = gql`
	query GetGlobalFiltersBreakdownsOptions($objects: [String!]!, $verbose: Boolean) {
		globalFiltersBreakdownsOptions(objects: $objects, verbose: $verbose) {
			result
		}
	}
`;

export const GetAllDimensionValues = gql`
	query GetAllDimensionValues($object: String!, $dimension: String!, $verbose: Boolean) {
		allDimensionValues(dimension: $dimension, limit: 2000, object: $object, verbose: $verbose) {
			result
		}
	}
`;
