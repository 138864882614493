import { Box, Button, Flex } from '@chakra-ui/react';
import Divider from 'src/common/components/Divider';
import useNavigation from 'src/services/useNavigation';

export function Footer() {
	const { goBack } = useNavigation();
	return (
		<Box width={'100%'} bg="white" flexShrink={1}>
			<Divider direction={'horizontal'} />
			<Flex
				py={'16px'}
				direction="row"
				justifyContent={'flex-end'}
				height={'10%'}
				gap={'12px'}
				pr={{
					base: '32px',
					xl: '176px',
				}}
			>
				<Button variant="ghost" colorScheme="gray" onClick={goBack} size="md">
					Close
				</Button>
			</Flex>
		</Box>
	);
}
