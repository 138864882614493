import Box from '@components/Box';
import Flex from '@components/Flex';
import Divider from 'src/common/components/Divider';
import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonLegendPanel.module.scss';

const { sectionElementBox, elementCheckboxSkeleton, elementTextSkeleton, sectionTitleSkeleton, legendsTitleSkeleton } =
	classes;

function SectionElement() {
	return (
		<Flex flexDirection="row" alignItems="center" className={sectionElementBox}>
			<Skeleton className={elementCheckboxSkeleton} />
			<Skeleton className={elementTextSkeleton} />
		</Flex>
	);
}

type SectionProps = {
	numberOfElements: number;
	withDivider: boolean;
};

function LegendSection({ numberOfElements, withDivider }: SectionProps) {
	const sectionElements = new Array(numberOfElements).fill(null).map((v, i) => <SectionElement key={i} />);
	return (
		<Box>
			<Skeleton className={sectionTitleSkeleton} />
			{sectionElements}
			<Box height="27px" />
			{withDivider ? <Divider mb="30px" direction="horizontal" /> : <></>}
		</Box>
	);
}

function LegendsTitle() {
	return <Skeleton className={legendsTitleSkeleton} />;
}

export function SkeletonLegendPanel() {
	return (
		<Box height={'100vh'}>
			<LegendsTitle />
			<LegendSection numberOfElements={2} withDivider={true} />
			<LegendSection numberOfElements={3} withDivider={true} />
			<LegendSection numberOfElements={5} withDivider={false} />
		</Box>
	);
}
