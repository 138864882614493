import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonDrawer.module.scss';
import { Flex } from '@chakra-ui/react';
import Divider from 'src/common/components/Divider';
import { Fragment } from 'react';

const { titleSkeleton, categorySkeleton } = classes;
const REPEAT_CATEGORIES_NUMBER = 14;

function SkeletongCategories() {
	return (
		<Flex flexDirection={'column'}>
			<Skeleton className={categorySkeleton} />
			{[...Array(REPEAT_CATEGORIES_NUMBER)].map((_, index) => (
				<Fragment key={index}>
					<Skeleton className={categorySkeleton} />
				</Fragment>
			))}
		</Flex>
	);
}

export function SkeletonDrawer() {
	return (
		<Flex flexDirection={'column'}>
			<Flex maxH={'59px'}>
				<Skeleton className={titleSkeleton} />
			</Flex>
			<Divider direction={'horizontal'} />
			<SkeletongCategories />
		</Flex>
	);
}
