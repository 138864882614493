import { useHasuraSubscriptionWithCache } from 'src/common/hooks/fetching/useSubscription';
import useUser from 'src/common/hooks/stores/useUser';
import { GetMetricInteractiveSession } from 'src/queries/genai';

export function useChatSession(sessionId: string) {
	const [{ id: userId }] = useUser();

	return useHasuraSubscriptionWithCache(GetMetricInteractiveSession, {
		variables: {
			session_id: sessionId,
			user_id: userId,
		},
	});
}
