import { useBreakpoint as UseChakraBreakpoint } from '@chakra-ui/react';

const breakpointType = ['base', 'sm', 'md', 'lg', 'xl', '2xl'];
export type BreakpointType = typeof breakpointType[number];
export type BreakpointObject = { [key in BreakpointType]: any };

export default function useBreakpoint(): BreakpointType {
	const breakpoint = UseChakraBreakpoint();
	if (breakpointType.includes(breakpoint)) {
		return breakpoint as BreakpointType;
	}

	return 'base';
}
