import { Box, ModalHeader } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { CloseLarge16 } from '@icons/index';
import Button from 'src/common/components/Button';
import Modal from 'src/common/components/Modal';
import ModalFooter from 'src/common/components/ModalFooter';

type ConfirmationModallProps = {
	isOpen: boolean;
	onClose: (name?: string) => void;
	onSubmit: () => void;
	collectionTitle: string;
};

export function ConfirmationDeleteModal({ isOpen, onClose, onSubmit, collectionTitle }: ConfirmationModallProps) {
	return (
		<Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} maxWidth="492px" isCentered>
			<>
				<ModalHeader color="gray.1000" p="16px">
					<Flex justifyContent="start" alignItems="center">
						<Button variant="outline" isIconOnly onClick={() => onClose()} size="small" colorScheme="black">
							<CloseLarge16 />
						</Button>
					</Flex>
				</ModalHeader>
				<Flex
					justifyContent={'center'}
					flexDirection={'column'}
					alignItems={'center'}
					paddingX="32px"
					paddingBottom={'16px'}
				>
					<Box textAlign={'center'} maxWidth={'400px'}>
						<Typography marginTop={'24px'} marginBottom={'8px'} variant="DesktopH5" color="gray.1000">
							Are you sure?
						</Typography>
						<Typography variant="Paragraph14R" color="gray.800">
							{`You're about to delete this signal from the `}
							<Typography variant="Paragraph14SB">{collectionTitle}</Typography>
							{` space`}.
						</Typography>
					</Box>
				</Flex>
				<Box paddingBottom={'32px'}>
					<ModalFooter
						color="black"
						placement="center"
						size={'sm'}
						primaryButtonLabel="Delete"
						isPrimaryLoading={false}
						onPrimaryClick={onSubmit}
						onCancel={onClose}
					/>
				</Box>
			</>
		</Modal>
	);
}
