import { BoxProps, Flex } from '@chakra-ui/react';
import Typography from 'src/common/components/Typography';

export const SelectLabel = ({
	text,
	isOptional,
	trailingIcon,
	optionalText,
	...props
}: { text: string; isOptional?: boolean; trailingIcon?: JSX.Element; optionalText?: string } & BoxProps) => (
	<Flex gap={'6px'} direction="row" alignItems={'center'} {...props}>
		<Typography cursor={'inherit'} variant="DesktopH10Regular" textAlign="start" color={'gray.1000'}>
			{text}
			{isOptional && (
				<Typography color={'gray.800'} variant="DesktopH10Regular">
					&nbsp;(Optional)
				</Typography>
			)}

			{optionalText && (
				<Typography color={'gray.800'} variant="DesktopH10Regular">
					&nbsp;({optionalText})
				</Typography>
			)}
		</Typography>
		{trailingIcon}
	</Flex>
);

export const BigSelectLabel = ({ text, ...props }: { text: string } & BoxProps) => (
	<Flex {...props}>
		<Typography color={'gray.1100'} variant="DesktopH8Medium">
			{text}
		</Typography>
	</Flex>
);
