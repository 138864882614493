import { Editor } from '@monaco-editor/react';
import * as monacoEditor from 'monaco-editor';
import { useEffect, useRef } from 'react';
import Box from 'src/common/components/Box';
import GITHUB_MONACO_THEME from 'src/theme/monaco-theme.json';
import { editorOptions, SIGHTFULL_MONACO_THEME_NAME } from '.';

export type MonacoSQLEditorProps = {
	isEditable?: boolean;
	value: string;
	onChange?: (value: string) => void;
};

export function MonacoSQLEditor({ value, isEditable = false, onChange }: MonacoSQLEditorProps) {
	const editorRef = useRef<null | monacoEditor.editor.IStandaloneCodeEditor>(null);

	const onMount = (editor: monacoEditor.editor.IStandaloneCodeEditor) => {
		editorRef.current = editor;

		monacoEditor.editor.defineTheme(SIGHTFULL_MONACO_THEME_NAME, GITHUB_MONACO_THEME as any);
		monacoEditor.editor.setTheme(SIGHTFULL_MONACO_THEME_NAME);
	};

	useEffect(() => {
		if (editorRef.current?.getValue() !== value) editorRef.current?.setValue(value);
	}, [value, editorRef]);

	return (
		<Box
			border={'1px solid'}
			borderColor="gray.300"
			borderRadius="8px"
			backgroundColor={'gray.100'}
			h="0"
			overflow={'clip'}
			flexGrow={1}
		>
			<Editor
				language="sql"
				options={{
					...editorOptions,
					readOnly: !isEditable,
					scrollbar: { vertical: 'visible', verticalScrollbarSize: 2, useShadows: false },
					padding: { top: 15, bottom: 10 },
					folding: true,
					automaticLayout: true,
				}}
				onChange={(value, ev) => {
					if (ev.isFlush) return;
					if (value) onChange?.(value);
				}}
				onMount={onMount}
				defaultValue={value}
			/>
		</Box>
	);
}
