import { Box, Flex } from '@chakra-ui/react';
import IconButton from 'src/common/components/IconButton';
import { SparksBW32, SparksColor32 } from 'src/common/components/Icons';
import borderRadiuses from 'src/style/borderRadiuses';
import classes from './FloatingTriggerButton.module.scss';

type InteractiveMetricChatTriggerProps = { onClick: VoidFunction; bottomBarGap: number };

export function FloatingTriggerButton({ onClick, bottomBarGap }: InteractiveMetricChatTriggerProps) {
	return (
		<>
			<Box className={classes.triggerShadow} position={'fixed'} bottom={`${30 + bottomBarGap}px`} right="30px" />
			<Flex
				position={'fixed'}
				bottom={`${32 + bottomBarGap}px`}
				right="32px"
				justifyContent={'center'}
				alignItems={'center'}
				className={classes.triggerCircle}
				borderRadius={borderRadiuses.round}
				onClick={onClick}
				as="button"
			>
				<IconButton
					as="div"
					className={classes.triggerAnimation}
					colorScheme={'lightGrayNoActive'}
					icon={
						<Box alignItems="center" justifyContent="center" width="32px" height="32px">
							<SparksBW32 />
							<SparksColor32 />
						</Box>
					}
				/>
			</Flex>
		</>
	);
}
