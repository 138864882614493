import { Grid } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { BigBanner } from 'src/common/components/BigBanner';
import { MetricLibrary } from 'src/common/components/Icons';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useDebouncedCallback from 'src/common/hooks/useDebouncedCallback';
import { useReportEvent } from 'src/services/analytics';
import { CategoryV2, MetricMetadataV2 } from 'src/types/metric';
import { MetricCatalogCategoryV2 } from './MetricCatalogCategoryV2';
import { NoResultsCatalog } from './NoResultsCatalog';
import { METRIC_LIBRARY_URL, NUMBERS_OF_CATEGORIES_TO_LOAD, openInSourcePlatform } from './utils';
import Box from '@components/Box';
import useNavigation from 'src/services/useNavigation';
import { MetricPagePath } from 'src/pages/MetricPage/pageRoutesPaths';
import { PendingSetupCategory } from '@pages/MetricCatalog/PendingSetupCategory';

export function MetricCatalogBodyV2({
	cache,
	categoriesWithFilteredMetrics,
	searchTerm,
	listRef,
	pendingSetupMetrics,
}: {
	cache: CellMeasurerCache;
	categoriesWithFilteredMetrics: CategoryV2[];
	searchTerm: string;
	listRef: React.RefObject<List>;
	pendingSetupMetrics: MetricMetadataV2[];
}) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const { reportEvent, wrapWithReport } = useReportEvent();
	const reportSearchTerm = (isFound: boolean, currentSearchTerm: string) => {
		const event = isFound ? 'metric-catalog-search' : 'metric-catalog-search-not-found';
		reportEvent({ event: event, metaData: { currentSearchTerm } });
	};

	const showBigBanner = useFeatureFlag('pulse.react.metricCatalog.showLibraryBanner');

	const debouncedReportRef = useRef(debounce(reportSearchTerm, 500));

	const isNoResults = categoriesWithFilteredMetrics.length == 0;

	useEffect(() => {
		const currentRef = debouncedReportRef.current;
		if (searchTerm) {
			currentRef(!isNoResults, searchTerm);
		}
		return () => currentRef.cancel();
	}, [searchTerm, isNoResults]);

	const debouncedResize = useDebouncedCallback(() => cache?.clearAll(), 500);

	const { navigate, getHref } = useNavigation();

	const getMetricPathObject = useCallback(
		(metric: MetricMetadataV2) => {
			const additionalSearchParams = new URLSearchParams();
			const isEditMetricEnabled = isSightfull2;
			const { name: metricName, defaultChartType: chartType, isFullyDefined } = metric;

			if (chartType) additionalSearchParams.append('chartType', chartType);
			if (isEditMetricEnabled && !isFullyDefined) additionalSearchParams.append('pageMode', 'edit');

			return {
				path: `/${MetricPagePath}/${metricName}`,
				additionalSearchParams,
			};
		},
		[isSightfull2]
	);

	const getMetricHref = useCallback(
		(metric: MetricMetadataV2) => {
			return getHref(getMetricPathObject(metric));
		},
		[getHref, getMetricPathObject]
	);

	const handleMetricClick = useCallback(
		(metric: MetricMetadataV2) => {
			navigate(getMetricPathObject(metric));
		},
		[getMetricPathObject, navigate]
	);

	if (isNoResults) {
		return <NoResultsCatalog />;
	} else {
		const onResize = () => debouncedResize();

		const ListRow = ({ index }: { index: number }) => {
			const category = categoriesWithFilteredMetrics[index];
			const hasPendingSetupMetrics = pendingSetupMetrics.length > 0;

			return (
				<>
					{index === 0 && (
						<>
							{showBigBanner && (
								<Box pr={'32px'}>
									<BigBanner
										onClick={wrapWithReport(openInSourcePlatform, 'open-website-metrics-library', {
											link: METRIC_LIBRARY_URL,
										})}
										buttonText="Take me there"
										title="Explore our Metrics Library."
										description="Includes hundreds of easy-to-setup Metrics & Templates based on best practices."
										bannerIcon={<MetricLibrary />}
									/>
								</Box>
							)}
							{hasPendingSetupMetrics && (
								<PendingSetupCategory
									pendingSetupMetrics={pendingSetupMetrics}
									getMetricHref={getMetricHref}
									handleMetricClick={handleMetricClick}
								/>
							)}
						</>
					)}
					<Grid
						gridTemplateColumns={'repeat(12, 1fr [col-start])'}
						columnGap="16px"
						pt={!hasPendingSetupMetrics ? '20px' : '0'}
						mt={!hasPendingSetupMetrics ? '0' : '-16px'}
						pr={'32px'}
						rowGap="16px"
						data-intercom-area={'catalog'}
						data-intercom-type={'category'}
						data-intercom-target={category?.name}
						data-intercom-sequence={index + 1}
					>
						<MetricCatalogCategoryV2
							metricCategory={category}
							getMetricHref={getMetricHref}
							handleMetricClick={handleMetricClick}
						/>
					</Grid>
				</>
			);
		};

		return (
			<AutoSizer onResize={onResize}>
				{({ width, height }) => (
					<List
						ref={listRef}
						width={width}
						height={height}
						rowHeight={cache.rowHeight}
						deferredMeasurementCache={cache}
						rowCount={categoriesWithFilteredMetrics.length}
						overscanRowCount={NUMBERS_OF_CATEGORIES_TO_LOAD}
						scrollToAlignment={'start'}
						rowRenderer={({ key, index, style, parent }) => {
							return (
								<CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
									{({ registerChild }) => (
										<div style={style} ref={(element) => registerChild?.(element as HTMLDivElement)}>
											<ListRow index={index} />
										</div>
									)}
								</CellMeasurer>
							);
						}}
					/>
				)}
			</AutoSizer>
		);
	}
}
