import { SearchQueryResultEntity } from '../types';
import { EntityTableV2 } from './EntityTable';
import { EntityGridTable } from './EntityGridTable';
import { useIsGridTableV2Enabled } from '../../../common/components/GridTable';

export function EntityTableWrapper({
	entityName,
	entityDisplayName,
	resultEntities,
	isAllSearch,
	isAllChosen,
}: {
	entityName: string;
	entityDisplayName: string;
	resultEntities: SearchQueryResultEntity[];
	isAllSearch: boolean;
	isAllChosen: boolean;
}) {
	const isGridTableV2 = useIsGridTableV2Enabled();

	return isGridTableV2 ? (
		<EntityGridTable
			entityName={entityName}
			entityDisplayName={entityDisplayName}
			resultEntities={resultEntities}
			isAllSearch={isAllSearch}
			isAllChosen={isAllChosen}
		/>
	) : (
		<EntityTableV2
			entityName={entityName}
			entityDisplayName={entityDisplayName}
			resultEntities={resultEntities}
			isAllSearch={isAllSearch}
			isAllChosen={isAllChosen}
		/>
	);
}
