import { Flex } from '@chakra-ui/react';
import TableCell from 'src/common/components/TableCell';
import { TableCellProps } from 'src/common/components/TableCell/types';
import { TableRow } from 'src/common/components/TableRow/TableRow';
import { labelPretty } from 'src/common/utils/searchEntity';
import { useEntityProfileDrawer } from 'src/layout/EntityProfileDrawer/hooks';
import { EntityType, NormalizedRelatedEntities } from 'src/layout/EntityProfileDrawer/types';
import { useReportEvent } from 'src/services/analytics';
import { resultsPageMetadata } from '../utils';

export function EntityTableRows({
	normalizedRows,
	pageSize,
	entityType,
	isAllSearch,
	isAllChosen,
}: {
	normalizedRows: NormalizedRelatedEntities;
	pageSize: number;
	entityType: EntityType;
	isAllSearch: boolean;
	isAllChosen: boolean;
}) {
	const { pushEntity } = useEntityProfileDrawer();
	const { wrapWithReport } = useReportEvent();

	return (
		<Flex direction="column" alignItems="start" gap="8px" alignSelf="stretch">
			{normalizedRows.slice(0, pageSize).map((row, index) => {
				return (
					<TableRow
						key={`search-results-row-${entityType}-${row.sightfullId}`}
						cells={row.columns.map((cell) => {
							return {
								...cell,
								key: `search-results-column-${entityType}-${row.sightfullId}-${cell.supportingText}-${cell.text}`,
								variant: 'textAndsupportingText',
								cursor: 'pointer',
							};
						})}
						variant="search"
						renderCell={(cellProps: TableCellProps) => <TableCell {...cellProps} />}
						onClick={wrapWithReport(() => pushEntity(row.sightfullId), 'record-drawer-opened', {
							...resultsPageMetadata(isAllSearch),
							id: row.sightfullId,
							object: entityType,
							rowIndex: index,
							tab: isAllChosen ? 'All' : labelPretty(entityType),
							tableName: labelPretty(entityType),
						})}
						entityType={entityType}
					/>
				);
			})}
		</Flex>
	);
}
