import { tenantConfigSelectors, useTenantConfigStore } from 'src/stores/tenantConfig';

export type TrialStatusType = string | null;

export function useTrial() {
	const trialStore = useTenantConfigStore(tenantConfigSelectors.getAll);
	const isTrial = useTenantConfigStore(tenantConfigSelectors.isTrial);
	const trialBarHeight = isTrial ? 56 : 0; // TAF: Hook this up to the actual rendering of the trial bar

	const trialEnd = trialStore.trialEnd;

	return { trialEnd, isTrial, trialBarHeight };
}
export default useTrial;
