import Box from '@components/Box';
import Flex from '@components/Flex';
import Divider from 'src/common/components/Divider';
import IconButton from 'src/common/components/IconButton';
import { BinSolid16, DotsSolid16, DuplicateSolid16 } from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import Popover from 'src/common/components/Popover';
import TextEditor from 'src/common/components/TextEditor';
import useMutation from 'src/common/hooks/fetching/useMutation';
import useMentionsState from 'src/common/hooks/stores/useMentionsState';
import useUser from 'src/common/hooks/stores/useUser';
import { useShareTextWidgetApi } from 'src/common/hooks/useShareSignalApi';
import { DeleteDashboardFeedSignal } from 'src/queries/dashboards';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { FeedSignal } from 'src/types/spaces';
import { Widget, WidgetProps } from '../Widget';
import { TextWidgetHeader } from './TextWidgetHeader';

export type TextWidgetProps = {
	feedSignal: FeedSignal;
} & Pick<WidgetProps, 'isEditMode'>;
export function DashboardTextWidget({ isEditMode, feedSignal }: TextWidgetProps) {
	const { reportEvent } = useReportEvent();
	const mentions = useMentionsState();
	const [{ id: userId }] = useUser();
	const [upsertSignal, { isLoading: isUpsertLoading }] = useShareTextWidgetApi();
	const [deleteSelf, { loading: isDeletionLoading }] = useMutation(DeleteDashboardFeedSignal);
	const isLoading = isDeletionLoading || isUpsertLoading;

	const { isHavingPermission } = usePermissionCheck();
	const isMyWidget = feedSignal?.signal.author_id == userId;
	const canUpdateAllSignals = isHavingPermission(Permissions.updateAllSignals);
	const canCrudMySignals = isHavingPermission(Permissions.crudMySignals);
	const canCrudThisSignal = isMyWidget && canCrudMySignals;
	const canEditTextSignal = canUpdateAllSignals || canCrudThisSignal;

	const saveWidgetState = async (markdown: string) => {
		await upsertSignal({
			collectionToPin: [],
			collectionToUnPin: [],
			message: markdown,
			signalId: feedSignal.signal_id,
		});
	};

	const onDuplicateWidget = canCrudMySignals
		? () => {
				reportEvent({
					event: 'signal-three-dots-menu-duplicate',
					metaData: { 'widget-type': 'text', 'collection-type': 'dashboard' },
				});
				upsertSignal({
					attachmentContentURL: feedSignal?.signal?.attachment ?? '',
					collectionToPin: [feedSignal.feed_id],
					collectionToUnPin: [],
					message: feedSignal.signal.message,
					sentiment: feedSignal.display_options?.sentiment,
					title: feedSignal.signal.title,
				}); // TODO: actually copy all properties
		  }
		: undefined;

	const onDeleteWidget = canEditTextSignal
		? () => {
				reportEvent({
					event: 'signal-three-dots-menu-remove-from-workspace',
					metaData: { 'widget-type': 'text', 'collection-type': 'dashboard' },
				});
				deleteSelf({
					variables: {
						feed_id: feedSignal.feed_id,
						signal_id: feedSignal.signal_id,
					},
				});
		  }
		: undefined;

	return (
		<Widget isEditMode={isEditMode} hasBorder={false} hasShadow={true}>
			<Flex direction="column" width="100%" height="100%">
				{isEditMode && (
					<Flex shrink={0}>
						<TextWidgetHeader
							isEditMode={isEditMode}
							isLoading={isLoading}
							onDuplicate={onDuplicateWidget}
							onDelete={onDeleteWidget}
						/>
					</Flex>
				)}
				<Box textAlign="start" flexGrow={1} overflowY="auto" padding={isEditMode ? undefined : '16px'}>
					<TextEditor
						mentions={mentions}
						value={feedSignal.signal.message}
						onChange={saveWidgetState}
						isEditMode={isEditMode && canEditTextSignal}
					/>
				</Box>
				{!isEditMode && !isLoading && (onDuplicateWidget || onDeleteWidget) && (
					<Box position={'absolute'} top="16px" right="16px">
						<Popover
							placement="bottom-end"
							triggerElement={(isOpen) => (
								<Box className={!isOpen ? 'react-grid-item-actions' : ''} backgroundColor="white" borderRadius={'4px'}>
									<IconButton size="xs" icon={<DotsSolid16 />} colorScheme="black" isActive={isOpen} />
								</Box>
							)}
						>
							<Box paddingY="8px" minWidth={'184px'}>
								{onDuplicateWidget && (
									<ListItem
										color="gray.1000"
										label="Duplicate"
										size="sm"
										prefixIcon={<DuplicateSolid16 />}
										onClick={onDuplicateWidget}
									/>
								)}
								{onDeleteWidget && (
									<>
										<Box paddingX={'12px'}>
											<Divider direction={'horizontal'} marginY="8px" />
										</Box>
										<ListItem
											color="gray.1000"
											label="Delete"
											size="sm"
											prefixIcon={<BinSolid16 />}
											onClick={onDeleteWidget}
										/>
									</>
								)}
							</Box>
						</Popover>
					</Box>
				)}
			</Flex>
		</Widget>
	);
}
