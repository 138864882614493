import { Center, Flex, Spinner } from '@chakra-ui/react';
import Box from '@components/Box';
import * as CSS from 'csstype';
import { useEffect, useState } from 'react';
import { Check16, Loading16 } from 'src/common/components/Icons';
import Popover from 'src/common/components/Popover';
import Progress from 'src/common/components/Progress';
import Typography from 'src/common/components/Typography';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import NavigationIcon from '../Navigation/NavigationIcon';
import classes from '../Navigation/NavigationItemFrame.module.scss';
import { TaskData, TaskStatusEnum, calcProgressByStartDate, formatTaskDate, useGetTasks } from './TasksStatusData';

export interface TasksStatusProps {
	testId?: string;
	icon: React.ReactElement;
	activeIcon: React.ReactElement;
}

interface VisProps {
	color: CSS.Property.Color;
	statusString: string;
	showBar: boolean;
}

const visPropsByStatus: { [key in TaskStatusEnum]: VisProps } = {
	Done: {
		color: 'green.700',
		statusString: 'Completed',
		showBar: false,
	},
	Running: {
		color: 'gray.700',
		statusString: 'Uploading...',
		showBar: true,
	},
	Pending: {
		color: 'gray.600',
		statusString: 'Pending',
		showBar: false,
	},
	Failed: {
		color: 'red.700',
		statusString: 'Failed',
		showBar: false,
	},
};

function ProgressBar({ task }: { task: TaskData }) {
	const [progress, setProgress] = useState<number>(() => calcProgressByStartDate(task.startedAt));

	useEffect(() => {
		const interval = setInterval(() => {
			setProgress(calcProgressByStartDate(task.startedAt));
		}, 1000);
		return () => clearInterval(interval);
	}, [task.startedAt]);

	return <Progress colorScheme="blue.700" bgColor="gray.400" borderRadius="40px" h="7px" value={progress} />;
}

function TaskStatus({ task, isBottomTask = false }: { task: TaskData; isBottomTask: boolean }) {
	const visProps = visPropsByStatus[task.status];

	return (
		<Flex
			shadow={isBottomTask ? undefined : 'borderBottom'}
			flexDirection="column"
			px="16px"
			py={visProps.showBar ? '16px' : '20px'}
			gap="12px"
		>
			<Flex flexDirection="column" gap="4px" p="0">
				<Typography variant="DesktopH8Bold">{task.metric}</Typography>
				<span>
					<Typography color="gray.600" variant="DesktopH8Regular">
						{formatTaskDate(task.createdAt)}&nbsp;&#183;
					</Typography>
					<Typography color={visProps.color} variant="DesktopH8Regular">
						&nbsp;{visProps.statusString}
					</Typography>
				</span>
			</Flex>
			{visProps.showBar ? <ProgressBar task={task} /> : undefined}
		</Flex>
	);
}

const syncingIcon = (
	<Center height={'38px'} width={'38px'}>
		<Loading16 color={'white'} />
	</Center>
);
const activeSyncingIcon = (
	<Center height={'38px'} width={'38px'}>
		<Loading16 color={'white'} />
	</Center>
);

const doneIcon = (
	<Center height={'38px'} width={'38px'}>
		<Check16 className={classes.hoverGreen} color={colors.white} />
	</Center>
);

const activeDoneIcon = (
	<Center height={'38px'} width={'38px'}>
		<Check16 color={'white'} />
	</Center>
);

export function TasksStatus() {
	const { wrapWithReport } = useReportEvent();
	const { tasks, isLoading, error } = useGetTasks();
	const { isOpen, onToggle, onClose } = useModal();

	if (isLoading || error || !tasks || tasks.length === 0) return null;

	const tasksWidgets = tasks.map((task, index) => {
		return <TaskStatus task={task} isBottomTask={index === tasks.length - 1} key={`${task.createdAt.getTime()}`} />;
	});

	const areAllTasksDone = tasks.every(
		(task) => task.status === TaskStatusEnum.Done || task.status === TaskStatusEnum.Failed
	);
	const icon = areAllTasksDone ? doneIcon : syncingIcon;
	const activeIcon = areAllTasksDone ? activeDoneIcon : activeSyncingIcon;
	const spinnerElementIfNeeded = areAllTasksDone ? null : (
		<Spinner
			thickness="4px"
			speed="1.2s"
			size="lg"
			color="blue.500"
			style={{ position: 'absolute', height: '38px', width: '38px', pointerEvents: 'none' }}
		></Spinner>
	);
	return (
		<Popover
			placement="bottom-end"
			isOpen={isOpen}
			onClose={onClose}
			triggerElement={
				<>
					{spinnerElementIfNeeded}
					<NavigationIcon
						tooltipLabel={`Sync Status`}
						padding={'0'}
						borderRadius={areAllTasksDone ? undefined : '999px'}
						icon={icon}
						activeIcon={activeIcon}
						isActiveOverride={isOpen}
						overrideOnClick={wrapWithReport(() => onToggle(), 'top-navigation-icon-clicked', {
							item: 'override-tasks',
							feature: 'Main Navigation Bar',
						})}
					/>
				</>
			}
			popoverContentProps={{
				boxShadow: 'a-22',
				borderRadius: '4px',
				marginTop: '7px',
			}}
		>
			<Box flexGrow={1} width={'308px'}>
				<Box shadow={'borderBottom'}>
					<Typography padding="16px" variant={'DesktopH7Medium'}>
						Applying Overrides
					</Typography>
				</Box>
				<Box overflowY="auto" maxHeight="380px">
					{tasksWidgets}
				</Box>
			</Box>
		</Popover>
	);
}

export default TasksStatus;
