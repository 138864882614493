import { Flashlight16, Memo16, Scissors16 } from 'src/common/components/Icons';

export type ChatMessage = { role: 'assistant' | 'user'; content: string };
export type ChatSessionInteraction = {
	assistant: string;
	user: string;
	userAction?: PromptActions;
};
export type ActionVariants = 'templates' | 'reactions';

export type PromptActions = PromptTemplates | PromptReactions;
export type PromptReactions = keyof typeof promptReactionMapping;
export type PromptTemplates = keyof ReturnType<typeof promptTemplateMapping>;

export type PromptAction = { prompt: string; displayPrompt: string; icon?: JSX.Element };
export type PromptMapping = { [key: string]: PromptAction };

const explainCalculation = (isFormula?: boolean): PromptMapping | undefined => {
	if (isFormula == null) {
		return;
	}

	const prompt = isFormula
		? `Act as my experienced, dedicated analyst whose primary objective is helping me understand metric calculation based on the YAML schema provided above.
		This metric calculation uses other metrics. Explain this equation in natural language and direct me to drill-down into the components to understand their specific calculations.		
		“$metric__x” in the YAML should be referenced simply as “X” in natural language		
		Your explanation should be formatted as follows:		
		Be concise, provide the shortest possible explanation. Do not add any information that isn't directly informing me how the metric is calculated.		
		Use bullet points where necessary.		
		Make use of bold text for any references to the YAML.		
		Helpful tone speaking directly with me.		
		Proceed.`
		: `Act as my experienced, dedicated analyst whose primary objective is helping me understand metric calculation based on the above YAML schema. Rely only on the provided YAML and ignore all other provided information such as the data CSV
		Your explanation should be well organized into 3 sections; Data, Y-axis, X-axis.	
		Data : what is the relevant subset of data the metric relates to?	
		Y-axis : what operation does the metric perform and - where applicable - using what measure?	
		X-axis : help me understand how the metric value for each period is calculated. For example, the following YAML means the metric will calculate and display results over time based on entities' "created_date":
		'''
		x_axis:
		  period:
			- sql: $created_date BETWEEN $period.start AND $period.end
		'''	
		Your explanation should be formatted as follows:	
		Be parsimonious, do not add any information that isn't directly informing me how the metric is calculated.	
		Be concise, use short, simple sentences and avoid quoting the YAML directly.	
		Use bullet points as necessary.	
		Emphasize anything preceded by '$' in the YAML with bold text (wrapped in **)	
		Helpful tone speaking directly with me.	
		Proceed.`;

	return { explainCalculation: { displayPrompt: 'Explain calculation', icon: <>🤓</>, prompt } };
};

export const promptTemplateMapping = (isFormula?: boolean): PromptMapping => {
	return {
		summarize: {
			prompt:
				'Summarize this METRIC VIEW for me in a way that clearly communicates the essential things to know regarding our performance. Focus on creating a report that is as clear, informative, insightful and actionable as possible. This summary should be concise and never exceed 5 sentences / 200 words.',
			displayPrompt: 'Summarize current view',
			icon: <Memo16 />,
		},
		analyze: {
			prompt:
				'Given this METRIC VIEW, what are the main trends and changes I should know about? Please clearly communicate up to 3 trends I should be aware of and explain their significance. For example, highlight the most significant growth / decline, seasonality or fluctuation that can be seen in the data.',
			displayPrompt: 'Analyze trends',
			icon: <>📈</>,
		},
		'generate-insights': {
			prompt:
				'Extract the most important insights in the current METRIC VIEW. Output 3 insights in bullet points and use bold text to highlight specific values, metric components, groups or periods and fiscal periods. These insights are the main takeaways and should be clear, concise and informative. Each insight is one sentence that should act a potential heading. It is imperative you are brief and provide really valuable insights that are formatted perfectly.',
			displayPrompt: 'Generate insights',
			icon: <Flashlight16 />,
		},
		'what-can-i-ask': {
			prompt:
				"I'm not sure what I can and cannot ask you. Considering the report and information provided to you thus far regarding this METRIC VIEW, what would be the best questions to ask? Output a bullet list of the top 3 example business questions you can help me answer. Pay close attention to the specific data you have in context to avoid confusion.",
			displayPrompt: 'What can I ask?',
			icon: <>❓</>,
		},
		...explainCalculation(isFormula),
	};
};

export const promptReactionMapping: PromptMapping = {
	'improve-it': {
		displayPrompt: 'Improve it',
		prompt: 'Improve this, pretiffy, add markdown and bullets.',
		icon: <Memo16 />,
	},
	shorter: {
		displayPrompt: 'Shorter',
		prompt: 'Shorten this. Please make it shorter.',
		icon: <Scissors16 />,
	},
	rewrite: {
		displayPrompt: 'What else can I ask?',
		prompt:
			"I'm not sure what else I can and cannot ask you. Considering the report and information provided to you thus far regarding this METRIC VIEW, what would be the best questions to ask? Output a bullet list of the top 3 example business questions you can help me answer. Pay close attention to the specific data you have in context to avoid confusion.",
		icon: <Memo16 />,
	},
};

export const promptMapping = (
	isFormula?: boolean
): {
	templates: PromptMapping;
	reactions: PromptMapping;
} => ({
	templates: {
		...promptTemplateMapping(isFormula),
	},
	reactions: {
		...promptReactionMapping,
	},
});

export function convertChatHistoryToConversation(
	chatHistory: ChatMessage[],
	isFormula?: boolean
): ChatSessionInteraction[] {
	const conversation = chatHistory.reduce<ChatSessionInteraction[]>((acc, item) => {
		if (item.role == 'user') {
			const { action, userPrompt } = mapPromptToAction(item, isFormula);
			acc.push({
				user: userPrompt,
				assistant: '',
				userAction: action,
			});
			return acc;
		}

		if (acc.length == 0) acc.push({ user: '', assistant: '' });
		acc[acc.length - 1].assistant = item.content;

		return acc;
	}, []);
	return conversation;
}

function mapPromptToAction(
	chatMessage: ChatMessage,
	isFormula?: boolean
): { action?: PromptActions; userPrompt: string } {
	for (const [, promptMappings] of Object.entries(promptMapping(isFormula))) {
		const entry = Object.entries(promptMappings).find(([, prompt]) => prompt.prompt == chatMessage.content);
		if (entry) {
			const [action, prompt] = entry;
			const mappedAction = { action: action as PromptActions, userPrompt: prompt.displayPrompt };
			return mappedAction;
		}
	}
	const unmappedAction = { userPrompt: chatMessage.content };
	return unmappedAction;
}

export function getActionMapping(action?: PromptActions, isFormula?: boolean): PromptAction | null {
	if (!action || isFormula == null) return null;
	const mappedPrompts = promptMapping(isFormula);
	if (action in mappedPrompts) {
		const templateAction: PromptTemplates = action;
		return mappedPrompts.templates[templateAction];
	} else if (action in mappedPrompts.reactions) {
		const reactionAction: PromptReactions = action;
		return mappedPrompts.reactions[reactionAction];
	}

	return null;
}
