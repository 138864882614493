import { useRelationshipBuilderYamlProviders } from 'src/lib/completions/hooks/useOntologyBuilderYamlProviders';
import { BorderedMonacoYamlEditor } from 'src/pages/MetricPage/components/MonacoEditor/BorderedMonacoEditor';

export function RelationshipYAMLEditor({
	value,
	onChange,
	onErrorsChange,
	isEditable,
	hasUnsavedChanges,
	onFocus,
	entity,
}: {
	value: string;
	onChange: (val: string) => void;
	onErrorsChange: (val: boolean) => void;
	isEditable?: boolean;
	hasUnsavedChanges: boolean;
	onFocus: () => void;
	entity: string;
}) {
	useRelationshipBuilderYamlProviders(entity);
	return (
		<BorderedMonacoYamlEditor
			value={value}
			schemaType={'normalization_relationship'}
			onChange={onChange}
			onErrorsChange={onErrorsChange}
			isEditable={isEditable}
			hasUnsavedChanges={hasUnsavedChanges}
			onFocus={onFocus}
		/>
	);
}
