import { Box, Flex } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import useWindowResize from 'src/common/hooks/ui/useWindowResize';
import { Indicator } from './Indicator';
import { IndicatorProps, TabProps, TabsProps } from './types';

export const TAB_PADDING = 24;

export default function Tabs({
	testId,
	onClick,
	selectedIndex = 0,
	renderItem,
	tabsData,
	hasBorderBottom = true,
}: TabsProps) {
	const [indicatorProps, setIndicatorProps] = useState<IndicatorProps>({
		left: undefined,
		width: undefined,
	});

	const parentRef = useRef<HTMLDivElement | null>(null);
	const childRef = useRef<HTMLDivElement | null>(null);

	const calcIndicatorProps = useCallback(() => {
		if (!childRef.current || !parentRef?.current) {
			return;
		}

		const parentLeft = parentRef.current.getBoundingClientRect().left;
		const childLeft = childRef.current.getBoundingClientRect().left;

		if (!childLeft) {
			return;
		}

		const width = childRef?.current.querySelector('div')?.querySelector('div')?.querySelector('div')?.offsetWidth;
		const left = childLeft - parentLeft;

		setIndicatorProps({
			left: `${left}px`,
			width: `${width}px`,
		});
	}, []);

	useEffect(() => {
		calcIndicatorProps();
	}, [calcIndicatorProps, selectedIndex, tabsData]);

	useWindowResize(calcIndicatorProps);

	return (
		<Flex direction={'column'} ref={parentRef}>
			<Flex data-testid={testId} mb={'-2px'}>
				{tabsData.map((tab: TabProps, index: number) => {
					const isSelected = selectedIndex == index;

					return (
						<Box
							boxShadow={hasBorderBottom ? 'borderBottom' : undefined}
							key={`opt-ref-${index}`}
							ref={isSelected ? childRef : undefined}
							onClick={() => !tab?.isDisabled && onClick(index)}
						>
							{renderItem(tab, isSelected)}
						</Box>
					);
				})}
			</Flex>
			<Indicator left={indicatorProps?.left} width={indicatorProps?.width} />
		</Flex>
	);
}
