import { useCallback } from 'react';
import { useGetSemanticsLazyQuery } from 'src/generated/graphql';
import { EntityWithMetrics } from 'src/lib/completions/semanticTypes';
import { useSemanticsStore } from 'src/stores/semantics';

export function useSemanticDefinitions() {
	const { setSemantics, semantics } = useSemanticsStore();
	const [fetchSemantics] = useGetSemanticsLazyQuery();

	const reloadSemantics = useCallback(() => {
		fetchSemantics({}).then((result) => setSemantics(result.data?.getSemantics || []));
	}, [fetchSemantics, setSemantics]);

	return { semanticDefinitions: semantics, reloadSemantics };
}

export function useSemanticDefinitionsForEntity(entity?: string): EntityWithMetrics | undefined {
	const { semanticDefinitions } = useSemanticDefinitions();

	return semanticDefinitions?.entities.find((e) => e.name === entity);
}

export function useSemanticsGetMetricDisplayName(): (metricName: string) => string {
	const { semanticDefinitions } = useSemanticDefinitions();

	const getMetricDisplayName = useCallback(
		(metricName: string) => {
			const metric = semanticDefinitions?.metrics.find((m) => m.name === metricName);
			return metric?.meta?.display_name || metricName;
		},
		[semanticDefinitions]
	);

	return getMetricDisplayName;
}
