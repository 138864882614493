import Box from '@components/Box';
import Flex from '@components/Flex';
import { TopNavigation } from 'src/common/components/TopNavigation/TopNavigation';
import { TestIDs } from 'src/common/types/test-ids';
import { EntityProfileDrawer } from 'src/layout/EntityProfileDrawer/EntityProfileDrawer';
import { AnalyticsPageContext } from 'src/services/analytics';

export const TOP_NAVIGATION_HEIGHT = '60px';

function Menu() {
	return (
		<Box data-testid={TestIDs.TOP_NAVIGATION} width="100%" zIndex="2" height={TOP_NAVIGATION_HEIGHT}>
			<AnalyticsPageContext page={'Menu'}>
				<Flex>
					<TopNavigation />
					<TopLevelComponents />
				</Flex>
			</AnalyticsPageContext>
		</Box>
	);
}

function TopLevelComponents() {
	return (
		<AnalyticsPageContext page={'Entity Profile Drawer'}>
			<EntityProfileDrawer />
		</AnalyticsPageContext>
	);
}

export default Menu;
