import Table from 'src/pages/MetricPage/components/Table/RawTable';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useSelectedXAxisElements } from '../../hooks/useSelectedXAxisElement';
import { RawMetricTableV2 } from './RawTable/RawMetricTableV2';
import { useIsGridTableV2Enabled } from '../../../../common/components/GridTable';

export function MetricTable({ isThumbnail = false }: { isThumbnail?: boolean }) {
	const { periodRange, tableRowsData, tableColDefs } = useMetricDerivedState();
	const [, setSelectedXAxisElement] = useSelectedXAxisElements();

	const isGridTableV2 = useIsGridTableV2Enabled();
	const RawTableImpl = isGridTableV2 ? RawMetricTableV2 : Table;

	return (
		<RawTableImpl
			title={'Metric Table'}
			subtitle={`By ${periodRange.periodUnit}`}
			data={tableRowsData}
			columnDefs={tableColDefs}
			onRowClicked={(e) => {
				if (!e.data) return;
				setSelectedXAxisElement({ xAxisElements: [e.data.xAxisValue] });
			}}
			onSelectAllPeriodsClicked={() => {
				setSelectedXAxisElement({ isAllSelected: true });
			}}
			isGroupable={false}
			isThumbnail={isThumbnail}
			dataIntercomTarget="metric-table"
		/>
	);
}
