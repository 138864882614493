import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import ActionTooltip from 'src/common/components/ActionTooltip';
import { ConfirmationModal } from 'src/common/components/ConfirmationModal';
import Flex from 'src/common/components/Flex';
import { Info12 } from 'src/common/components/Icons';
import Skeleton from 'src/common/components/Skeleton';
import { TinySwitch } from 'src/common/components/TinySwitch/TinySwitch';
import Typography from 'src/common/components/Typography';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { useReportEvent } from 'src/services/analytics';
import { useMetricEditorState } from '../../hooks/useMetricEditorState';
import { formatBuilderFeatureEditMode } from '../../utils/eventMetadata';
import { EditorFeature } from './EditPanel';
import classes from './EditPanel.module.scss';

const { toggleSkeleton } = classes;

const metricsLink = 'https://docs.sightfull.com/cloud/metrics/metric_builder/';

export function EditToggle({
	setSelectedFeature,
	metricName,
	selectedFeature,
	isToggleVisible,
	isDisabled = false,
	shouldPreventToggle = false,

	clearFormulaOnSubmit,
}: {
	setSelectedFeature?: (selectedFeaure: EditorFeature) => void;
	metricName: string;
	selectedFeature: EditorFeature;
	isToggleVisible: boolean;
	isDisabled?: boolean;
	shouldPreventToggle?: boolean;
	clearFormulaOnSubmit?: VoidFunction;
}) {
	const { reportEvent, wrapWithReport } = useReportEvent({
		metricName: metricName,
		feature: 'Metric Builder',
		editMode: formatBuilderFeatureEditMode(selectedFeature),
	});
	const { metricEditorLoadedState } = useMetricEditorState();
	const [newFeatureState, setNewFeatureState] = useState(false);
	const { isOpen: isWarningModalOpen, onOpen: onWarningModalOpen, onClose: onWarningModalClose } = useModal();
	const { isCalculatingPreview } = useBuilderDerivedState();

	const onWarningModalSubmit = () => {
		setNewFeature();
		clearFormulaOnSubmit?.();
		onWarningModalClose();
	};

	const setNewFeature = ({ newState }: { newState?: boolean } = {}) => {
		const feature: EditorFeature = newState ?? newFeatureState ? 'YAML Editor' : 'YAML Builder';
		setSelectedFeature?.(feature);
	};

	if (!metricEditorLoadedState) {
		return <Skeleton />;
	}

	function onHelpClicked() {
		if (isDisabled) return;
		reportEvent({
			event: 'metric-edit-help-clicked',
			metaData: {
				component: metricEditorLoadedState?.kind === 'formula' ? 'Formula' : 'Aggregate',
				editMode: formatBuilderFeatureEditMode(selectedFeature),
			},
		});
		window.open(
			`${metricsLink}${metricEditorLoadedState ? metricEditorLoadedState?.kind : 'formula'}_metric_builder`,
			'_blank'
		);
	}

	return (
		<Flex
			opacity={isDisabled ? 0.3 : 1}
			cursor={isDisabled ? 'not-allowed' : 'pointer'}
			paddingX="20px"
			flexDir={'row'}
			justifyContent={'space-between'}
			alignItems={'center'}
			paddingTop={'18px'}
			paddingBottom={'8px'}
		>
			<ActionTooltip
				label={
					(metricEditorLoadedState?.kind === 'formula'
						? 'Perform calculations based on existing metrics.'
						: 'Calculate values based on your data.') + ' For more information, please'
				}
				buttonLabel="visit our help center."
				isLinkButton
				onClick={onHelpClicked}
				placement="bottom-start"
				marginTop="5px"
				marginLeft="-2px"
				hasArrow
				isBlack
				size="xs"
			>
				<Flex color={'gray.1100'} flexDirection="row">
					<Typography variant="DesktopH8Medium">
						{metricEditorLoadedState?.kind && metricEditorLoadedState.kind === 'formula' ? 'Formula' : 'Aggregate'}{' '}
						Metric
					</Typography>
					<Box marginLeft={'4px'}>
						<Info12 />
					</Box>
				</Flex>
			</ActionTooltip>
			{isToggleVisible &&
				(isCalculatingPreview ? (
					<Skeleton className={toggleSkeleton} />
				) : (
					<ToggleAdvanced
						isChecked={selectedFeature === 'YAML Editor'}
						onChange={(newState) => {
							if (isDisabled) return;
							reportEvent({
								event: 'metric-edit-mode-toggle',
								metaData: {
									newState: newState ? 'YAML' : 'UI',
									feature: 'YAML Editor',
									metricName,
								},
							});

							if (shouldPreventToggle) {
								onWarningModalOpen();
								setNewFeatureState(newState);
								return;
							}
							setNewFeature({ newState });
						}}
					/>
				))}
			<ConfirmationModal
				primaryButtonLabel="Leave"
				submitColorScheme="blue"
				isOpen={isWarningModalOpen}
				onSubmit={wrapWithReport(onWarningModalSubmit, 'metric-edit-leave-with-error-modal', { action: 'confirm' })}
				modalTitle="Leave advanced mode"
				modalText="Leaving the advanced mode with errors will clear your input."
				onClose={wrapWithReport(onWarningModalClose, 'metric-edit-leave-with-error-modal', { action: 'cancel' })}
			/>
		</Flex>
	);
}

export function ToggleAdvanced({ isChecked, onChange }: { isChecked: boolean; onChange: (newState: boolean) => void }) {
	return (
		<Flex paddingY="1px">
			<TinySwitch isEnabled={isChecked} onClick={onChange} text="Advanced" />
		</Flex>
	);
}
