import { normalBaseSchema } from '@components/Input/colorSchema';

export const TextareaTheme = {
	variants: {
		normal: (props: any) => {
			return {
				padding: '8px 12px',
				border: '1px solid',
				borderColor: 'gray.400',
				borderRadius: '4px',
				fontFamily: 'inter',
				color: props.isDisabled ? normalBaseSchema.disabledPlaceholderColor : normalBaseSchema.normalTextColor,
				_hover: {
					borderColor: 'gray.500',
				},
				_focus: {
					borderColor: 'gray.800',
				},
				_placeholder: {
					color: 'gray.600',
				},
			};
		},
	},
};
