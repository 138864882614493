import { Flex } from '@chakra-ui/react';
import { NOT_AVAILABLE_VALUE_STRING } from 'src/common/utils/consts';
import { Filter } from 'src/generated/graphql';
import { serializeFilterKey } from 'src/pages/OntologyPage/utils/utils';
import Button from '../Button';
import { CloseSmall12 } from '../Icons';
import Tooltip from '../Tooltip';
import Typography from '../Typography';

const FILTERS_CHIPS_MAX = 20;

export function FilterBadgesArray({ filters, onClose }: { filters: Filter[]; onClose?: (val: string) => void }) {
	if (!filters.length) return null;
	const FilterBadge = ({ filter }: { filter: Filter }) => {
		const filterKey = serializeFilterKey(filter?.key);
		return (
			<Flex
				width={'fit-content'}
				cursor={'pointer'}
				justifyContent={'space-between'}
				alignItems={'center'}
				gap={'8px'}
				paddingY={'2px'}
				paddingRight={'4px'}
				paddingLeft={'12px'}
				backgroundColor={'gray.200'}
				borderRadius={'4px'}
			>
				{filter.values.length > 1 ? (
					<Tooltip size="md" placement={'top'} maxWidth={260} label={getValuesLabel(filter.values)} marginY={'12px'}>
						<Flex alignItems={'center'}>
							<Typography variant={'DesktopH10Medium'} color={'gray.700'}>
								{filterKey}
							</Typography>
							&nbsp;
							<Typography cursor={'pointer'} variant={'DesktopH10Regular'} color={'gray.700'}>
								({filter.values.length} values)
							</Typography>
						</Flex>
					</Tooltip>
				) : (
					<>
						<Typography variant={'DesktopH10Medium'} color={'gray.700'}>
							{filterKey}
							<Typography variant={'DesktopH10Regular'}>&nbsp;is&nbsp;</Typography>
							{filter.values[0]}
						</Typography>
					</>
				)}

				<Button
					w={'20px'}
					h={'20px'}
					minH={'20px'}
					minW={'20px'}
					_active={{ backgroundColor: 'gray.300' }}
					_hover={{ backgroundColor: 'gray.300' }}
					onClick={() => onClose?.(filter.key)}
					isIconOnly
					cursor={'pointer'}
					size="xs"
					variant="outline"
					colorScheme="lightGray"
				>
					<Tooltip size="md" placement={'top'} label={'Remove filter'} marginY={'12px'}>
						<CloseSmall12 />
					</Tooltip>
				</Button>
			</Flex>
		);
	};

	const getValuesLabel = (values: (string | null)[]) => {
		const labelValues = values.slice(0, FILTERS_CHIPS_MAX);
		const text = labelValues.map((val) => val ?? NOT_AVAILABLE_VALUE_STRING).join(', ');
		return values.length > FILTERS_CHIPS_MAX ? text + `, + ${values.length - labelValues.length} more` : text;
	};

	return (
		<Flex flexWrap={'wrap'} gap={'8px'} paddingTop={'6px'} paddingBottom={'16px'}>
			{filters.map((el: Filter) => (
				<FilterBadge filter={el} key={el.key} />
			))}
		</Flex>
	);
}
