import { SetStateAction, useAtom } from 'jotai';
import { MetricYamlEditorHashAtom } from 'src/pages/MetricPage/atoms/previewHashState';
import { MetricYamlEditorHashState } from 'src/pages/MetricPage/utils/state.types';

export function useUpdatedPreviewHashState(): {
	metricYamlEditorHashState: MetricYamlEditorHashState;
	setMetricYamlEditorHashState: (update: SetStateAction<MetricYamlEditorHashState>) => void;
} {
	const [metricYamlEditorHashState, setMetricYamlEditorHashState] = useAtom(MetricYamlEditorHashAtom);

	return { metricYamlEditorHashState, setMetricYamlEditorHashState };
}
