import { useMemo } from 'react';
import Avatar from 'src/common/components/Avatar/Avatar';
import { Memo16 } from 'src/common/components/Icons';
import {
	getActionMapping,
	PromptActions,
} from 'src/common/components/MetricView/InteractiveMetricChat/normalizeHistory';
import useUser from 'src/common/hooks/stores/useUser';

const DefaultIcon = Memo16;

export function ActionIcon({
	isFallbackAvatar = true,
	action,
}: {
	action?: PromptActions;
	isFallbackAvatar?: boolean;
}): JSX.Element {
	const [{ picture }] = useUser();
	const actionIcon = useMemo(() => {
		const actionMapping = getActionMapping(action);
		if (!actionMapping) {
			return null;
		}
		return actionMapping.icon;
	}, [action]);

	if (actionIcon) return actionIcon;
	if (isFallbackAvatar) {
		return <Avatar width={'24px'} height={'24px'} imageUrl={picture} shape="round" />;
	}
	return <DefaultIcon />;
}
