import Button from 'src/common/components/Button';
import { ChevronDown16 } from 'src/common/components/Icons';
import classes from './Header.module.scss';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { TestIDs } from 'src/common/types/test-ids';

export function ShareSignalButton({ onClick }: { onClick: () => any }) {
	const { errorMessage } = useMetricDerivedState();
	return (
		<Button
			isDisabled={!!errorMessage}
			variant="solid"
			onClick={onClick}
			size="inline"
			eventName="start-signal-draft"
			rightIcon={<ChevronDown16 />}
			eventMetadata={{ feature: 'Metric header' }}
			className={classes.blueButton}
			data-intercom-area={'metric'}
			data-intercom-type={'button'}
			data-intercom-target={'Publish'}
			testId={TestIDs.PUBLISH_METRIC_BUTTON}
		>
			Publish
		</Button>
	);
}
