import { Box, ModalHeader } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { CloseLarge16 } from '@icons/index';
import Button from 'src/common/components/Button';
import Modal from 'src/common/components/Modal';

type StillInProgressModalProps = {
	isOpen: boolean;
	onClose: VoidFunction;
};

export function StillInProgressModal({ isOpen, onClose }: StillInProgressModalProps) {
	return (
		<Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} maxWidth="492px" isCentered>
			<>
				<ModalHeader color="gray.1000" p="16px">
					<Flex justifyContent="start" alignItems="center">
						<Button variant="outline" isIconOnly onClick={() => onClose()} size="small" colorScheme="black">
							<CloseLarge16 />
						</Button>
					</Flex>
				</ModalHeader>
				<Flex
					justifyContent={'center'}
					flexDirection={'column'}
					alignItems={'center'}
					paddingX="32px"
					paddingBottom={'16px'}
				>
					<Box textAlign={'center'} maxWidth={'400px'}>
						<Typography marginTop={'24px'} marginBottom={'8px'} variant="DesktopH5" color="gray.1000">
							Saving in progress
						</Typography>
						<Typography variant="Paragraph14R" color="gray.800">
							Your changes are still being applied, please wait until they are finished.
						</Typography>
					</Box>
				</Flex>
				<Box paddingBottom={'32px'}>
					<Flex flexDirection="row" paddingX="24px" paddingY="16px" justifyContent={'center'} alignItems="center">
						<Flex flexDirection="row" gap="12px">
							<Button size={'small'} variant="solid" colorScheme={'blue'} onClick={onClose}>
								Close
							</Button>
						</Flex>
					</Flex>
				</Box>
			</>
		</Modal>
	);
}
