import Box from '@components/Box';
import Center from '@components/Center';
import Image from '@components/Image';
import InitialsBox from '@components/InitialsBox';
import borderRadiuses from 'src/style/borderRadiuses';
import { InitialsBadge } from '../InitialsBadge';
import classes from './Avatar.module.scss';
import classnames from 'classnames';
import colors from 'src/style/colors';

export type AvatarProps = {
	imageUrl: string;
	name?: string;
	width?: number | string;
	height?: number | string;
	shape?: keyof typeof borderRadiuses;
	isTopNavigationAvatar?: boolean;
	isActive?: boolean;
};

const Avatar = ({
	imageUrl,
	name = '',
	width = '32px',
	height = '32px',
	shape = 'square',
	isTopNavigationAvatar,
	isActive,
}: AvatarProps) => {
	return (
		<Box
			className={classnames(classes.avatar, {
				[classes.topNavigationAvatar]: isTopNavigationAvatar,
				[classes.imageAvatar]: imageUrl,
				[classes.initialsBadge]: !imageUrl,
				[classes.active]: isActive,
			})}
			borderRadius={shape ? borderRadiuses[shape] : 'initial'}
		>
			{imageUrl ? (
				<Image
					fallbackStrategy="onError"
					fallback={
						<Box w={width} h={height}>
							<Center h="100%">
								<InitialsBox
									size="lg"
									borderRadius={borderRadiuses[shape]}
									color="gray.600"
									bgColor="gray.400"
									text={name}
								/>
							</Center>
						</Box>
					}
					borderRadius={borderRadiuses[shape]}
					w={width}
					maxW={width}
					maxH={height}
					minWidth={width}
					h={height}
					src={imageUrl}
					alt={`${name} avatar`}
				/>
			) : (
				<InitialsBadge
					fontVariant="DesktopH10Medium"
					bgColor={isActive ? 'gray.1000' : 'transparent'}
					textColor={isActive ? 'gray.200' : 'gray.1000'}
					border={isTopNavigationAvatar ? `1.5px solid ${colors.gray[1000]}` : 'initial'}
					size={width}
					name={name}
					shape={shape}
				/>
			)}
		</Box>
	);
};

export default Avatar;
