import { relatedEntitiesSorter } from './relatedEntitiesSorter';
import {
	NormalizedRelationshipTableColumns,
	NormalizedColumn,
	NormalizedRelatedEntities,
	RawRelationshipTableColumns,
	RelatedEntities,
} from '../../types';

const filterRowBySearch = ({ text, supportingText }: NormalizedColumn, searchTerm: string) => {
	const lowerCaseSearchTerm = searchTerm.toLowerCase();
	return (
		searchTerm.length < 1 ||
		text.toLowerCase().includes(lowerCaseSearchTerm) ||
		supportingText.toLowerCase().includes(lowerCaseSearchTerm)
	);
};

type Cell = { field: string; displayName: string };

const normalizeCell = (
	cell: Cell,
	entity: {
		[key: string]: string | number;
	}
): NormalizedColumn => {
	const leadText = entity[cell.field] ? String(entity[cell.field]) : '';

	const supportingText = (cell.displayName || cell.field || '').replaceAll(':', ''); // TAF: remove this ugly replacement
	return { text: leadText, supportingText, field: cell.field };
};

const normalizeColumns = (cells: Cell[], entity: { [key: string]: string | number }): NormalizedColumn[] => {
	return cells.map((cell) => {
		return normalizeCell(cell, entity);
	});
};

const filterColumnNames = (columnNames: string[], relatedEntities: RelatedEntities): string[] => {
	const isColumnInAtLeastOneEntity = (column: string) => relatedEntities.some((entity) => entity[column]);
	return columnNames.filter(isColumnInAtLeastOneEntity);
};

const reduceRelatedEntitiesBySearchTerm = (
	cells: Cell[],
	searchTerm: string,
	relatedEntities: RelatedEntities
): NormalizedRelatedEntities => {
	return relatedEntities.reduce<NormalizedRelatedEntities>((acc, entity) => {
		const normalizedColumns = normalizeColumns(cells, entity);
		const isSearchFound = normalizedColumns.some((column) => column && filterRowBySearch(column, searchTerm));

		return isSearchFound
			? [
					{
						sourceSystemId: String(entity.id),
						sightfullId: String(entity['T.id']),
						columns: normalizedColumns,
					},
					...acc,
			  ]
			: acc;
	}, []);
};

const filterColumnsFromEntity = (
	cells: Cell[],
	relatedEntities: RelatedEntities,
	foundEntities: NormalizedRelatedEntities
) => {
	const columnNames = cells.map((cell) => cell.field);
	const filteredColumnNames = filterColumnNames(columnNames, relatedEntities);
	const filteredColumnsIndexes = filteredColumnNames.map((columnName) => columnNames.indexOf(columnName));

	return foundEntities.map((entity) => {
		return { ...entity, columns: filteredColumnsIndexes.map((index) => entity.columns[index]) };
	});
};

export const normalizeRelatedEntitiesToDisplay = (
	columns: NormalizedRelationshipTableColumns | RawRelationshipTableColumns,
	searchTerm: string,
	relatedEntities: RelatedEntities
): NormalizedRelatedEntities => {
	const { title, subtitles } = columns.display.config;
	const subtitleCells = subtitles?.map((subtitle) => ({ field: subtitle.field, displayName: subtitle.prefix })) ?? [];
	const status = columns.display.config?.status;
	const badges = status
		? [
				{
					field: status?.field,
					displayName: status.displayName,
				},
		  ]
		: [];

	const cells = [{ field: title, displayName: title }, ...subtitleCells, ...badges];

	const foundEntitiesBySearchTerm = reduceRelatedEntitiesBySearchTerm(cells, searchTerm, relatedEntities);
	const entitiesWithFilteredColumns = filterColumnsFromEntity(cells, relatedEntities, foundEntitiesBySearchTerm);

	const sortedRelatedEntities = columns.sort
		? relatedEntitiesSorter(entitiesWithFilteredColumns, columns.sort)
		: entitiesWithFilteredColumns;

	return sortedRelatedEntities;
};
