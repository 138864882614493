import useParams from 'src/common/hooks/navigation/useParams';
import useSearchParams from 'src/common/hooks/navigation/useSearchParams';

export const ScrollToBottomSearchParam = 'scrollToBottom';

export function useDashboardPageSearchParams() {
	const { dashboardId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();

	return {
		dashboardId,
		isScrollingToBottom: !!searchParams.get(ScrollToBottomSearchParam),
		setSearchParams,
	};
}
