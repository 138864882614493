import without from 'lodash/without';
import { useMemo } from 'react';
import { useEntityProfileDrawer, useEntityProfileReportEvent } from 'src/layout/EntityProfileDrawer/hooks';
import { SearchQueryResultEntity } from '../types';
import { GridTable, GridTableBody, GridTableHeader } from '@components/GridTable';

const nameDimension = 'name';

export function EntityGridTable({
	entityName,
	entityDisplayName,
	resultEntities,
	isAllSearch,
	isAllChosen,
}: {
	entityName: string;
	entityDisplayName: string;
	resultEntities: SearchQueryResultEntity[];
	isAllSearch: boolean;
	isAllChosen: boolean;
}) {
	const rowData = useMemo(() => resultEntities.map((entity) => entity.node), [resultEntities]);

	const dimensions = without(Object.keys(rowData[0]), 'sight_id');
	const hasNameDimension = dimensions.includes(nameDimension) && !!rowData.find((row) => !!row[nameDimension]);
	const dimensionsWithoutName = without(dimensions, nameDimension);
	const sortedDimensions = hasNameDimension ? [nameDimension, ...dimensionsWithoutName] : dimensionsWithoutName;

	const columnDefs = sortedDimensions.map((dimension) => {
		return {
			field: dimension,
		};
	});

	const { pushEntity } = useEntityProfileDrawer();
	const { reportOpenEntityDrawer } = useEntityProfileReportEvent();

	const onRowClicked = (event: any) => {
		const recordId = event?.data?.sight_id;

		reportOpenEntityDrawer({
			origin: 'search-results',
			id: recordId,
			entity: entityName,
			metaData: {
				isSpecificObjectSearch: !isAllSearch,
				tab: isAllChosen ? 'All' : entityDisplayName,
				tableName: entityDisplayName,
			},
		});

		pushEntity(recordId);
	};

	return (
		<GridTable padding={'0 12px'}>
			<GridTableHeader
				title={entityDisplayName}
				subtitle={`showing ${rowData.length} record${rowData.length !== 1 ? 's' : ''}`}
			/>
			<GridTableBody maxRowsToShow={6} data={rowData} columnDefs={columnDefs} onRowClicked={onRowClicked} />
		</GridTable>
	);
}
