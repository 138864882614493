import {
	DataModelDimension,
	useGetAllDimensionValuesApi,
	useGlobalFiltersApi,
} from '@hooks/fetching/useGlobalFiltersApi';
import { FiltersAndBreakdownItemType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import { FetchNodeArguments, GetFiltersAndBreakdownsReturnType, NodeSchemeReturnType } from './types';
import { normalizeValues, parseGlobalDimensions, parseGlobalRelationships, sortElements } from './utils';

export function useGlobalNodeScheme(
	globalFilterNodeTypes: string[],
	isExcludingNullValues = false,
	dimensionTypes?: string[]
): NodeSchemeReturnType {
	const [filtersGlobalState, fetchGlobal] = useGlobalFiltersApi();
	const [allDimensionValuesState, fetchAllDimensionValues] = useGetAllDimensionValuesApi();
	const isLoading = allDimensionValuesState.loading || filtersGlobalState.loading;

	async function getFiltersAndBreakdowns({
		prefixPath,
		shouldIncludeNotNormalizedProps,
	}: FetchNodeArguments): GetFiltersAndBreakdownsReturnType {
		try {
			let filterObjects = globalFilterNodeTypes;
			if (prefixPath) {
				filterObjects = [prefixPath.split('.').reverse()[0].split('>').reverse()[0]];
			}

			const response = await fetchGlobal(filterObjects);
			if (!response) {
				return null;
			}
			const entries = Object.entries(response);

			const relationships: FiltersAndBreakdownItemType[] = [];
			const dimensions: DataModelDimension[] = [];

			for (const [objectType, value] of entries) {
				const parsedRelationships = parseGlobalRelationships({
					relationships: value.relationships.filter((r) => !r.is_deleted),
					type: objectType,
					shouldIncludeNotNormalizedProps,
					isIncludingTypePrefix: true,
				});
				relationships.push(...parsedRelationships);
				dimensions.push(
					...value.dimensions.filter((dimension) => !dimensionTypes || dimensionTypes?.includes(dimension.type))
				);
			}
			const parsedDimensions = parseGlobalDimensions({
				dimensions,
				shouldIncludeNotNormalizedProps,
				isIncludingTypePrefix: true,
			});

			return {
				prefixPath,
				relationships,
				dimensions: parsedDimensions,
				type: filterObjects[0],
			};
		} catch (e) {
			console.log('Error: getGlobalFiltersAndBreakdowns: ', e);
			return null;
		}
	}

	async function getAllDimensionValues({
		prefixPath,
		dimensionLabel = '',
	}: Pick<FetchNodeArguments, 'prefixPath' | 'dimensionLabel'>): Promise<FiltersAndBreakdownItemType[]> {
		const object = prefixPath.split('.').reverse()[0].split('>').reverse()[0];
		const response = await fetchAllDimensionValues(object, dimensionLabel);

		return sortElements(normalizeValues(response, isExcludingNullValues));
	}

	const actions = {
		getFiltersAndBreakdowns,
		getAllDimensionValues,
	};

	return [{ isLoading, schemeType: 'global' }, actions];
}
