import { Flex, InputProps, Textarea as ChakraTextarea } from '@chakra-ui/react';
import FormControl from '@components/FormControl';
import FormLabel from '@components/FormLabel';
import colors from 'src/style/colors';
import Typography from '../Typography';
import { ReactNode } from 'react';
import * as CSS from 'csstype';

type Props = {
	testId?: string;
	onChange?: (value: string) => void;
	onSubmit?: () => void;
	placeholder?: string;
	isInvalid?: boolean;
	isSuccessful?: boolean;
	errorMessage?: string;
	label?: string | ReactNode;
	isDisabled?: boolean;
	value?: string;
	type?: string;
	isErasable?: boolean;
	counter?: number;
	rightComponentTestId?: string;
	shouldShowAutocomplete?: boolean;
	borderRadius?: string;
	borderColor?: CSS.Property.Color;
	padding?: CSS.Property.Padding;
	fontFamily?: CSS.Property.FontFamily;
	fontWeight?: CSS.Property.FontWeight;
	fontSize?: CSS.Property.FontSize;
	lineHeight?: CSS.Property.LineHeight;
} & Pick<
	InputProps,
	| 'width'
	| 'autoFocus'
	| 'margin'
	| 'padding'
	| 'paddingTop'
	| 'paddingBottom'
	| 'paddingLeft'
	| 'paddingRight'
	| 'resize'
	| 'height'
	| 'variant'
	| 'colorScheme'
	| 'size'
	| 'noOfLines'
	| 'borderRadius'
	| 'borderColor'
	| 'fontWeight'
	| 'fontSize'
	| 'lineHeight'
	| 'color'
>;

function TextArea({
	placeholder,
	label,
	resize,
	height,
	size,
	noOfLines,
	value,
	variant = 'normal',
	colorScheme = 'regular',
	counter,
	onChange = () => void 0,
	onSubmit,
	...props
}: Props) {
	return (
		<FormControl>
			{label && <FormLabel>{label}</FormLabel>}
			<ChakraTextarea
				onKeyDown={(e) => e.key == 'Enter' && !e.shiftKey && onSubmit?.()}
				value={value}
				size={size}
				resize={resize || 'none'}
				variant={variant}
				colorScheme={colorScheme}
				placeholder={placeholder}
				height={height}
				onSubmit={onSubmit}
				onChange={(e) => onChange(e.target.value)}
				noOfLines={noOfLines}
				_selection={{ background: colors.indigo[200] }}
				{...props}
			/>
			{counter && (
				<Flex pt={'8px'} direction={'row'} justifyContent={'end'}>
					<Typography variant="Paragraph14R">
						{value?.length ?? 0}/{counter}
					</Typography>
				</Flex>
			)}
		</FormControl>
	);
}

export default TextArea;
