import { TABLE } from 'src/common/components/TextEditor/nodes/table';

import { Transformer, TRANSFORMERS } from '@lexical/markdown';
import { EOL_SLASH } from 'src/common/components/TextEditor/nodes/eol';

export const LowPriority = 1;

export const supportedBlockTypes = new Set(['paragraph', 'h1', 'h2']);

export const blockTypeToBlockName = {
	paragraph: 'Text',
	h1: 'Headings 1',
	h2: 'Headings 2',
};

export const markdownTransformers: Array<Transformer> = [EOL_SLASH, TABLE, ...TRANSFORMERS];
