// @ts-ignore
import chmln from '@chamaeleonidae/chmln';
import { isProductionMode } from 'src/config';
import { AnalyticsMetadata } from 'src/services/analytics';
import { UserType } from 'src/types/user';

chmln.init('SdeD2qYMjBlxfoevZDYAyMudGn0FxljlBxV7p8lWbtBEm-1QsFuW-EzJRw72ahxRu7W9I', {
	fastUrl: 'https://fast.chameleon.io/',
});

// NOTE: workaround from chmln to prevent focusout event from bubbling up if the related target is a Chameleon element
function preventChameleonFocusOut(event: any) {
	const relatedTarget = event.relatedTarget;

	// Check if the related target is a Chameleon element
	if (relatedTarget && (relatedTarget.classList.contains('chmln') || relatedTarget.id.includes('chmln'))) {
		event.stopPropagation();
	}
}

document.addEventListener('focusout', preventChameleonFocusOut, true);

export const chmInitUserInfo = (user: UserType, metadata: AnalyticsMetadata) => {
	chmln.identify(user.email, metadata);
};

export const chmlnTrack = (eventName: string) => {
	try {
		chmln.track(eventName);
	} catch {
		if (!isProductionMode) {
			console.log('error', 'chmln track event failed');
		}
	}
};
