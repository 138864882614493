import { ToastVariants } from 'src/common/components/Toast/Toast';
import useToast from '../ui/useToast';

export const useOnCopy = () => {
	const toast = useToast();
	const onCopy = ({
		contentToCopy,
		variant = 'ok',
		message = 'Copied to clipboard',
	}: {
		contentToCopy: ClipboardItems;
		variant?: ToastVariants;
		message?: string;
	}) => {
		return navigator.clipboard.write(contentToCopy).then(() => toast({ variant, message }));
	};

	const onCopyText = ({
		contentToCopy,
		variant = 'ok',
		message = 'Copied to clipboard',
	}: {
		contentToCopy: string;
		variant?: ToastVariants;
		message?: string;
	}) => {
		return navigator.clipboard.writeText(contentToCopy).then(() => toast({ variant, message }));
	};
	return { onCopy, onCopyText };
};
