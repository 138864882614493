import Button from 'src/common/components/Button';
import Tooltip from 'src/common/components/Tooltip';
import colors from 'src/style/colors';
import * as CSS from 'csstype';

export function ReorderButton({
	onClick,
	isActive,
	icon,
	color,
}: {
	onClick: () => any;
	isActive: boolean;
	icon: React.ReactNode;
	color: CSS.Property.Color;
}) {
	return (
		<Tooltip size={'sm'} label={'Sort'} variant="fluid" placement={'bottom'}>
			<Button
				isIconOnly
				color={color}
				size={'xs'}
				border={`none`}
				_hover={{ backgroundColor: colors.gray['200'] }}
				onClick={onClick}
				isActive={isActive}
			>
				{icon}
			</Button>
		</Tooltip>
	);
}
