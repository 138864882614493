import { useEffect } from 'react';

const useOutsideClick = (ref: any, action: () => void) => {
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (ref && ref.current && !ref.current.contains(event.target)) action();
		}

		document.addEventListener('mouseup', handleClickOutside);

		return () => {
			document.removeEventListener('mouseup', handleClickOutside);
		};
	}, [ref, action]);
};

export default useOutsideClick;
