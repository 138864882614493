import { Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { createMergedClassName } from 'src/common/utils/style';
import { TextAndSupportingTextComponent, TextComponent } from './components';
import classes from './tableCell.module.scss';
import { TableCellProps } from './types';
import Tooltip from '@components/Tooltip';

const createClassName = ({ isDisabled, classNames }: { isDisabled?: boolean; classNames: string[] }) => {
	return createMergedClassName([
		classes.tableCell,
		isDisabled ? classes.disabled : classes.regular,
		...classNames.map((className) => classes[className]),
	]);
};

export const TableCell = ({
	width,
	height,
	testId,
	isDisabled,
	shouldShowDivider = true,
	classNames = [],
	cursor,
	paddingX = '0',
	...props
}: TableCellProps) => {
	const finalCursor = isDisabled ? 'default' : cursor;
	const contentComponent = useMemo(() => {
		const componentProps = { isDisabled, cursor: finalCursor, ...props };

		switch (componentProps.variant) {
			case 'textAndsupportingText':
				return <TextAndSupportingTextComponent {...componentProps} />;
			default:
				return <TextComponent {...componentProps} />;
		}
	}, [isDisabled, props, finalCursor]);

	const className = createClassName({ isDisabled, classNames });

	return (
		<Flex
			cursor={finalCursor}
			minHeight={'76px'}
			minWidth={'100px'}
			maxWidth="210px"
			py={'16px'}
			px={paddingX}
			height={height}
			width={width}
			direction={'row'}
			data-testid={testId}
			alignItems={'start'}
			boxShadow={shouldShowDivider ? 'borderBottom' : undefined}
			className={className}
		>
			<Tooltip
				size={'md'}
				variant="fluid"
				label={'supportingText' in props ? props.supportingText : undefined}
				marginTop="4px"
				title={props.text}
				wrapperStyle={{ width: '100%' }}
			>
				{contentComponent}
			</Tooltip>
		</Flex>
	);
};
