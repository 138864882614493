/*
 * https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 */

import { waitForElementToAppear } from 'src/common/utils/domElements';
import { isProductionMode } from 'src/config';
import { AnalyticsMetadata } from './analytics';

const globalWindow = window as any;
const globalDocument = document as any;

export const trackEvent = (eventName: string, metadata?: object) => {
	try {
		globalWindow.Intercom('trackEvent', eventName, metadata);
	} catch {
		if (!isProductionMode) {
			console.log('error', 'Intercom reportEvent failed');
		}
	}
};

export const updateIntercom = (metadata: AnalyticsMetadata & { user_hash: string }) => {
	globalWindow.Intercom('update', metadata);
};

export const showIntercom = () => {
	if (!globalWindow.isIntercomShown) {
		globalWindow.Intercom('show');
	}
};

export const toggleIntercom = () => {
	if (globalWindow.isIntercomShown) {
		globalWindow.Intercom('hide');
	} else {
		globalWindow.Intercom('show');
	}
};

export const showIntercomArticle = (articleId: string) => {
	try {
		globalWindow.Intercom('showArticle', articleId);
	} catch {
		if (!isProductionMode) {
			console.log('error', 'Intercom showArticle');
		}
	}
};

export const showIntercomWithMessage = ({
	message,
	shouldSendMessage,
}: {
	message: string;
	shouldSendMessage: boolean;
}) => {
	globalWindow.Intercom('showNewMessage', message);
	if (shouldSendMessage) sendIntercomMessage();
};

export const sendIntercomMessage = () => {
	const intercomButtonGetter = () => {
		return (Array.from(globalDocument.body.querySelectorAll('iframe')) as HTMLIFrameElement[])
			?.find((iframe) => iframe?.name == 'intercom-messenger-frame')
			?.contentDocument?.querySelector('button.intercom-composer-send-button');
	};

	const onIntercomButtonAppear = (intercomButton: any) => {
		intercomButton.click();
	};

	const timeoutMs = 10000;

	waitForElementToAppear(intercomButtonGetter, onIntercomButtonAppear, timeoutMs);
};
