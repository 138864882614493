import { CircularProgress } from '@chakra-ui/progress';
import Box from '@components/Box';
import Flex from '@components/Flex';
import { useMemo } from 'react';
import { AccessController } from 'src/common/components/AccessController';
import Button from 'src/common/components/Button';
import CopyLinkButton from 'src/common/components/CopyLinkButton';
import Divider from 'src/common/components/Divider';
import {
	BinSolid16,
	DotsSolid16,
	DuplicateSolid16,
	EditFull16,
	Eye2Solid16,
	FilterOutline16,
} from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import Popover from 'src/common/components/Popover';
import ToggleButton from 'src/common/components/ToggleButton';
import useToast from 'src/common/hooks/ui/useToast';
import { useDuplicateDashboard } from 'src/common/hooks/useDuplicateDashboard';
import { TitleAndDescription } from 'src/pages/Spaces/common/TitleAndDescription';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import shadows from 'src/style/shadows';
import { Permissions } from 'src/types/environment';
import { AddWidgetButton } from './AddWidgetButton/AddWidgetButton';
import { HeaderProps } from './types';
import { TestIDs } from 'src/common/types/test-ids';

export function Header({
	isEditMode,
	isTiny = false,
	onChangeMode,
	onUpdateMetadata = () => void 0,
	onDelete,
	onAddedWidget,
	paddingX,
	isShowingSelectorsBar,
	onToggleIsShowingSelectorsBar,
	globalSelectorsCount,
	dashboard,
}: HeaderProps) {
	const { reportEvent } = useReportEvent();
	const [duplicateDashboard, isDuplicateDashboardLoading] = useDuplicateDashboard();
	const showToast = useToast();

	const { navigate } = useNavigation();

	const filtersButtonString = useMemo(() => {
		if (isShowingSelectorsBar) return 'Hide filters';
		if (!globalSelectorsCount) return 'Add Filters';
		return `Show filters (${globalSelectorsCount})`;
	}, [globalSelectorsCount, isShowingSelectorsBar]);

	const onDuplicate = () => {
		reportEvent({ event: 'duplicate-dashboard', metaData: { dashboard: dashboard.id } });
		duplicateDashboard(dashboard, dashboard.name + ' (copy)')
			.then((dashboardId) => {
				navigate({ path: `/dashboard/${dashboardId}` });
			})
			.catch(() => {
				showToast({ variant: 'error', message: 'Failed to duplicate dashboard' });
			});
	};

	const copyLink = () => {
		reportEvent({
			event: 'copy-link-clicked',
		});
	};

	return (
		<Flex
			boxShadow={shadows.borderBottom}
			direction="row"
			alignItems={'center'}
			overflow="hidden"
			justifyContent="space-between"
			paddingX={`${paddingX}px`}
		>
			<Title isTiny={isTiny} dashboard={dashboard} onUpdateMetadata={onUpdateMetadata} isEditMode={isEditMode} />

			<Flex direction="row" gap={'12px'} alignItems="center" paddingY={'14px'}>
				{!isEditMode && (
					<>
						<Flex gap="8px" direction={'row'} alignItems="center">
							{onToggleIsShowingSelectorsBar && (
								<Box
									data-intercom-area={'dashboard'}
									data-intercom-type={'button'}
									data-intercom-target={'Add Filters'}
								>
									{isTiny ? (
										<Button
											isIconOnly
											onClick={onToggleIsShowingSelectorsBar}
											size="inline"
											variant="outline"
											colorScheme="black"
										>
											<FilterOutline16 />
										</Button>
									) : (
										<Button
											leftIcon={<FilterOutline16 />}
											colorScheme="black"
											backgroundColor={isShowingSelectorsBar ? 'blue.100' : undefined}
											color={isShowingSelectorsBar ? 'blue.700' : undefined}
											variant="outline"
											size="inline"
											onClick={onToggleIsShowingSelectorsBar}
										>
											{filtersButtonString}
										</Button>
									)}
								</Box>
							)}
							<CopyLinkButton onClick={copyLink} dataIntercomArea={'dashboard'} />
						</Flex>

						<AccessController
							permission={[
								Permissions.crudMySignals,
								Permissions.updateAllSignals,
								Permissions.deleteCollection,
								Permissions.createCollection,
							]}
							logic={'OR'}
						>
							<Box height={'24px'}>
								<Divider direction={'vertical'} color="gray.400" />
							</Box>
						</AccessController>
					</>
				)}
				<AccessController permission={Permissions.crudMySignals}>
					{isEditMode && <AddWidgetButton isTiny={isTiny} onAddedWidget={onAddedWidget} dashboard={dashboard} />}
				</AccessController>
				<AccessController permission={[Permissions.updateAllSignals, Permissions.crudMySignals]} logic={'OR'}>
					<ResponsiveToggleButton isEditMode={isEditMode} isTiny={isTiny} onChangeMode={onChangeMode} />
				</AccessController>
				<AccessController permission={[Permissions.deleteCollection, Permissions.createCollection]} logic={'OR'}>
					<div data-intercom-area={'dashboard'} data-intercom-type={'button'} data-intercom-target={'ThreeDotMenu'}>
						<Popover
							placement="bottom-end"
							triggerElement={(isOpen) => (
								<Button
									size="inline"
									variant="outline"
									colorScheme="black"
									isIconOnly
									isActive={isOpen}
									testId={TestIDs.DASHBOARD_THREE_DOT_MENU}
								>
									<DotsSolid16 />
								</Button>
							)}
						>
							<Box paddingY="8px" width={'184px'}>
								<AccessController permission={Permissions.createCollection}>
									<ListItem
										size={'sm'}
										label={'Duplicate'}
										prefixIcon={
											isDuplicateDashboardLoading ? (
												<CircularProgress isIndeterminate color="black" size="16px" />
											) : (
												<DuplicateSolid16 />
											)
										}
										onClick={onDuplicate}
									/>
								</AccessController>
								<AccessController permission={Permissions.deleteCollection}>
									<ListItem
										size={'sm'}
										label={'Delete'}
										prefixIcon={<BinSolid16 />}
										onClick={onDelete}
										testId={TestIDs.DASHBOARD_THREE_DOT_MENU_DELETE}
									/>
								</AccessController>
							</Box>
						</Popover>
					</div>
				</AccessController>
			</Flex>
		</Flex>
	);
}

function Title({
	dashboard: { name: title, description },
	onUpdateMetadata,
	isEditMode,
	isTiny,
}: Pick<HeaderProps, 'dashboard' | 'onUpdateMetadata' | 'isEditMode'> & { isTiny: boolean }) {
	const DashboardTitleAndDescription: React.FC<{ isEditBlocked?: boolean }> = ({ isEditBlocked }) => (
		<TitleAndDescription
			title={title ?? ''}
			description={description ?? ''}
			onSaved={onUpdateMetadata}
			collectionType="dashboard"
			isEditBlocked={isEditBlocked || false}
			isTiny={isTiny}
			isEditMode={isEditMode}
		/>
	);

	return (
		<Box maxWidth={'60%'} wordBreak={'break-all'}>
			<AccessController
				permission={Permissions.updateCollection}
				noAccessChild={
					<Flex
						textAlign="start"
						borderRadius={'4px'}
						alignItems="center"
						gap="21px"
						flexDirection={'row'}
						justifyContent="space-between"
						paddingRight="12px"
					>
						<DashboardTitleAndDescription isEditBlocked />
					</Flex>
				}
			>
				<Flex
					as={'button'}
					textAlign="start"
					borderRadius={'4px'}
					alignItems="center"
					gap="21px"
					flexDirection={'row'}
					minWidth={'292px'}
					width="100%"
					justifyContent="space-between"
					paddingRight="12px"
					color="transparent"
					transition={'all 0.2s'}
					_hover={{ color: 'gray.800' }}
				>
					<DashboardTitleAndDescription />
				</Flex>
			</AccessController>
		</Box>
	);
}

function ResponsiveToggleButton({
	isTiny,
	isEditMode,
	onChangeMode,
}: Pick<HeaderProps, 'isTiny' | 'isEditMode' | 'onChangeMode'>) {
	return (
		<ToggleButton
			options={isTiny ? [<Eye2Solid16 key="eye" />, <EditFull16 key="edit" />] : ['View', 'Edit']}
			intercomDataAttributes={[
				{ area: 'dashboard', type: 'button', target: 'View' },
				{ area: 'dashboard', type: 'button', target: 'Edit' },
			]}
			size="xs"
			selectedIndex={isEditMode ? 1 : 0}
			onChildClicked={(index) => onChangeMode(index == 1)}
		/>
	);
}
