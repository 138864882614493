import Typography from 'src/common/components/Typography';
import { ExampleText, LearnMore } from './common';
import { LearnMoreProps } from './common/LearnMore';

const JOINS_LINK = 'https://docs.sightfull.com/core/metrics/syntax/joins';

export function JoinsTooltip({ onHelpClicked }: { onHelpClicked: LearnMoreProps['onClick'] }) {
	return (
		<Typography color={'white'} variant="Paragraph14R">
			Enables the utilization of entity relationships (links) to related entities, defining how data is combined for the
			metric.&nbsp;
			<LearnMore onClick={onHelpClicked} link={JOINS_LINK} />.
			<ExampleText
				marginTop={'16px'}
				marginBottom={'4px'}
				text={
					<>
						from: account
						<br />
						select: $id
						<br />
						alias: active_accounts
						<br />
						filters:
						<br /> - sql: $is_active = true
						<br />
						join_type: inner_join
					</>
				}
			/>
		</Typography>
	);
}
