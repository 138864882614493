import { useEffect, useState } from 'react';

export default function useGraceTimeout() {
	const [isGraceDone, setGraceDone] = useState(false);

	useEffect(() => {
		setTimeout(() => setGraceDone(true), 5000);
	}, []);

	return isGraceDone;
}
