import { Box, Flex, GridItem } from '@chakra-ui/react';
import { useEffect, useMemo, useRef } from 'react';
import { ResponsiveGrid } from 'src/common/components/ResponsiveGrid/ResponsiveGrid';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { EntityType } from 'src/layout/EntityProfileDrawer/types';
import { SearchQueryResult } from '../types';
import { EntityTable } from './EntityTable';
import { EntityTableWrapper } from './EntityTableWrapper';
import { useIsGridTableV2Enabled } from '../../../common/components/GridTable';

export function SearchResults({
	isAllSearch,
	chosenObjectIndex,
	resultObjects,
	queryResult,
	setChosenObjectIndex,
	searchTerm,
	setSearchTerm,
	entityNameToDisplay,
}: {
	isAllSearch: boolean;
	chosenObjectIndex: number;
	resultObjects: string[];
	queryResult: SearchQueryResult;
	setChosenObjectIndex: (index: number) => void;
	searchTerm: string;
	setSearchTerm: (term: string) => void;
	entityNameToDisplay: { [key: string]: string };
}) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const isGridTableV2 = useIsGridTableV2Enabled();

	const isAllChosen = useMemo(() => {
		return chosenObjectIndex == 0 && isAllSearch;
	}, [chosenObjectIndex, isAllSearch]);

	const chosenObjectLabel = useMemo(
		() => (isAllChosen ? 'All' : resultObjects[chosenObjectIndex - 1]),
		[chosenObjectIndex, isAllChosen, resultObjects]
	);

	const resultsRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!resultsRef.current) {
			return;
		}
		resultsRef.current.scrollTo(0, 0);
	}, [chosenObjectIndex]);

	const tablesToShow = Object.entries(queryResult)
		.sort(([entityType1], [entityType2]) => resultObjects.indexOf(entityType1) - resultObjects.indexOf(entityType2))
		.filter(([entityType]) => !isAllSearch || isAllChosen || entityType == chosenObjectLabel);

	return (
		<Box overflowX="hidden" width="100%" height="100%" py="40px" ref={resultsRef}>
			<ResponsiveGrid>
				<GridItem
					colStart={{ base: 0, xs: isSightfull2 ? 0 : 3 }}
					colSpan={{ base: isGridTableV2 ? 11 : 2, xs: isSightfull2 ? 0 : 8 }}
				>
					<Flex direction="column" gap="44px" alignItems="flex-start">
						{tablesToShow.map(([entityType, resultEntities]) => {
							return isSightfull2 ? (
								<EntityTableWrapper
									key={`single-entity-table-${entityType}`}
									entityName={entityType}
									entityDisplayName={entityNameToDisplay[entityType]}
									resultEntities={resultEntities}
									isAllSearch={isAllSearch}
									isAllChosen={isAllChosen}
								/>
							) : (
								<EntityTable
									key={`single-entity-table-${entityType}`}
									entityType={entityType as EntityType}
									isAllSearch={isAllSearch}
									isAllChosen={isAllChosen}
									resultEntities={resultEntities}
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									onViewAllClick={() => {
										setChosenObjectIndex((resultObjects.indexOf(entityType) ?? -1) + 1);
									}}
								/>
							);
						})}
					</Flex>
				</GridItem>
			</ResponsiveGrid>
		</Box>
	);
}
