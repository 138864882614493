import { Box, Flex } from '@chakra-ui/react';
import Alert from 'src/common/components/Alert';
import Button from 'src/common/components/Button';
import Markdown from 'src/common/components/Markdown';
import Typography from 'src/common/components/Typography';
import shadows from 'src/style/shadows';

export const ModalFooter = ({
	onCancel,
	onSave,
	isEditMode,
	isDisabled,
	shouldShowChangeAlert,
}: {
	isDisabled: boolean;
	onCancel: () => void;
	onSave: () => void;
	isEditMode: boolean;
	shouldShowChangeAlert: boolean;
}) => {
	return (
		<Box>
			{shouldShowChangeAlert && (
				<Box>
					<Box h={'1px'} boxShadow={shadows.borderTop} />
					<Alert
						padding={'12px 16px'}
						status={'warning'}
						DescriptionComponent={() => (
							<Typography variant="Paragraph14R">
								<Markdown
									message={
										'**The update has not been applied yet.** It may take up to 24 hours for the changes to be reflected in the metrics.'
									}
								/>
							</Typography>
						)}
					/>
				</Box>
			)}
			<Flex flexDirection="row" paddingX="24px" paddingY="16px" justifyContent="end" alignItems="center">
				{isEditMode ? (
					<Flex gap={'12px'}>
						<Button
							size={'medium'}
							variant="outline"
							colorScheme={'gray'}
							onClick={onCancel}
							eventName={`click-cancel-changes-property-details-modal`}
						>
							Cancel
						</Button>

						<Button
							size={'medium'}
							variant="solid"
							colorScheme={'blue'}
							onClick={onSave}
							isDisabled={isDisabled}
							testId={'save-changes-button'}
							eventName={`click-save-changes-property-details-modal`}
						>
							Save changes
						</Button>
					</Flex>
				) : (
					<Button
						size={'medium'}
						variant="solid"
						colorScheme={'gray'}
						onClick={onCancel}
						eventName={`click-cancel-changes-property-details-modal`}
					>
						Close
					</Button>
				)}
			</Flex>
		</Box>
	);
};
