import React, { useCallback, useRef, useState } from 'react';
import { useOnOverflow } from '@hooks/ui/useOnOverflow';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import Box from '@components/Box';
import classes from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesNavigationDrawer.module.scss';
import Flex from '@components/Flex';
import classNames from 'classnames';
import { SpaceMoreButton } from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesListDrawer/SpaceListItem/SpaceMoreButton';
import { Collection } from '@layout/Menu/NavigationDrawer/types';
import useNavigation, { NavigationOptions } from '@services/useNavigation';
import { useReportEvent } from '@services/analytics';
import { useLocation } from 'react-router-dom';
import DeleteCollectionModal from '@layout/Menu/NavigationDrawer/DeleteCollectionModal';
import { useModal } from '@hooks/ui/useModal';

export const SpaceListItem = ({
	currentDrawerView,
	collection,
	index,
	setIsPanelActingLikeOnHover,
	onCreateEditModalOpen,
	setEditedItem,
	testId,
	selectedMenuItemId,
	setSelectedItem,
}: {
	currentDrawerView: string;
	collection: Collection;
	index: number;
	setIsPanelActingLikeOnHover: (isOpen: boolean) => void;
	onCreateEditModalOpen: VoidFunction;
	setEditedItem: (collection: Collection) => void;
	testId: string;
	selectedMenuItemId: number | null;
	setSelectedItem: (id: number | null) => void;
}) => {
	const { reportEvent } = useReportEvent();
	const location = useLocation();
	const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useModal();
	const [deletedItem, setDeletedItem] = useState<Collection>();
	const { navigate } = useNavigation();

	const navigateToCollection = useCallback(
		(
			collectionType: string,
			collectionId: string,
			collectionName: string,
			navigate: (navOptions: NavigationOptions) => void,
			isNewTab?: boolean
		) => {
			reportEvent({
				event: 'collection-tiles-navigate-to-collection',
				metaData: {
					// String names for backwards-compatible with previous analytics
					'collection-name': collectionName,
					'collection-type': collectionType,
					collectionId,
					isNewTab,
				},
			});
			const URL = `${collectionType.toLowerCase()}/${collectionId}`;

			navigate({ path: URL, isNewTab });
		},
		[reportEvent]
	);

	// TAF: Changed to URL params
	const isItemSelected = (id: number, collectionType: string, index: number) => {
		const rootPath =
			location.pathname === '/' || location.pathname === '/dashboard' || location.pathname === '/workspace';

		if (rootPath && index === 0) return true;

		return id === selectedMenuItemId || location.pathname === `/${collectionType.toLowerCase()}/${id}`;
	};

	const handleMenuItemClick = (collection: Collection) => {
		navigateToCollection(collection.collection_type, collection.id, collection.name, navigate, false);
		setSelectedItem(collection.id);
	};

	const SpaceName = ({ categoryName }: { categoryName: string }) => {
		const nameRef = useRef(null);
		const isOverflowingCategoryName = useOnOverflow(nameRef, [categoryName], undefined, false);
		return (
			<Tooltip
				size="md"
				variant="fluid"
				placement={'right'}
				marginLeft={'38px'}
				label={isOverflowingCategoryName && categoryName}
			>
				<Typography
					noOfLines={1}
					cursor={'pointer'}
					variant="DesktopH8Regular"
					color={'gray.1000'}
					wordBreak={'break-all'}
				>
					<Box ref={nameRef} data-testid={`${testId}-text`}>
						{categoryName}
					</Box>
				</Typography>
			</Tooltip>
		);
	};

	return (
		<>
			<Flex
				className={classNames(classes.menuIndicator, {
					[classes.selected]: isItemSelected(collection.id, collection.collection_type, index),
				})}
				onClick={() => handleMenuItemClick(collection)}
				data-testid={testId}
				data-intercom-area={`${currentDrawerView}-sidebar`}
				data-intercom-type={'button'}
				data-intercom-sequence={index + 1}
				justifyContent={'space-between'}
			>
				<SpaceName categoryName={collection.name} />
				<SpaceMoreButton
					collection={collection}
					setIsPanelActingLikeOnHover={setIsPanelActingLikeOnHover}
					onDeleteModalOpen={onDeleteModalOpen}
					onCreateEditModalOpen={onCreateEditModalOpen}
					setEditedItem={setEditedItem}
					setDeletedItem={setDeletedItem}
					navigateToCollection={navigateToCollection}
				/>
			</Flex>

			{deletedItem && (
				<DeleteCollectionModal
					isOpen={isDeleteModalOpen}
					onClose={onDeleteModalClose}
					collection={deletedItem}
					isCollectionInView={false}
				/>
			)}
		</>
	);
};
