import { useCallback, useEffect, useRef, useState } from 'react';

export function useScrollableFiltersBar() {
	const [scrollState, setScrollState] = useState({
		isScrolling: false,
		isStartShadowShown: false,
		isEndShadowShown: false,
	});
	const scrollComponentRef = useRef<HTMLDivElement>(null);

	const calculateScrollState = useCallback(() => {
		if (!scrollComponentRef.current) return;
		const scrollPosition = scrollComponentRef.current.scrollLeft;
		const scrollChildSize = scrollComponentRef.current.scrollWidth;
		const scrollContainerSize = scrollComponentRef.current.clientWidth;

		const isScrolling = scrollContainerSize < scrollChildSize;
		const isAtEnd = scrollContainerSize == scrollChildSize - scrollPosition && isScrolling;
		const isAtStart = scrollPosition == 0 && isScrolling;

		setScrollState({ isScrolling, isStartShadowShown: !isAtStart, isEndShadowShown: !isAtEnd });
	}, [scrollComponentRef]);

	useEffect(() => {
		window.addEventListener('resize', calculateScrollState);
		return () => {
			window.removeEventListener('resize', calculateScrollState);
		};
	}, [calculateScrollState]);

	return {
		scrollState,
		calculateScrollState,
		scrollComponentRef,
	};
}
