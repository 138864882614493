import useCalcMetricApi from '@hooks/fetching/useCalcMetricApi';
import useMetricApi, { useInternalCalcMetric } from '@hooks/fetching/useMetricApi';
import { useSetAtom } from 'jotai';
import omit from 'lodash/omit';
import { useCallback, useEffect } from 'react';
import useTenantConfig from 'src/common/hooks/stores/useTenantConfig';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { GetMetricByNameQuery } from 'src/generated/graphql';

import { DerivedStateAtom, loadingDerivedState } from 'src/pages/MetricPage/atoms/DerivedState';
import { useRulesEngineInput } from 'src/pages/MetricPage/hooks/useRulesEngineInput';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import {
	calcAvailableChartTypes,
	calcAvailablePeriodRanges,
	calcAvailableRangePresets,
	calcAxes,
	calcBreakdowns,
	calcChartOptionsSeries,
	calcCollectedProps,
	calcColoredBubbles,
	calcColoredSeries,
	calcDecimalDigits,
	calcDisplayedLegendItems,
	calcDisplayUnits,
	calcDisplayUnitsSeries,
	calcFilters,
	calcFlavor,
	calcFormatting,
	calcIsExecutiveView,
	calcMetricOp,
	calcOverrideChartType,
	calcPeriodRange,
	calcPieSeriesVisibility,
	calcPlotBands,
	calcSelectedXAxisElement,
	calcSeriesVisibility,
	calcStatisticFormatting,
	calcStatistics,
	calcStatisticsParams,
	calcTable,
	calcTableColumnState,
	calcTableType,
	calcTargetsChartOptions,
	calcVizInputRules,
} from './DerivedStateCalculators';

import { calcAvailableSortOrders } from 'src/lib/metricRules/DerivedStateCalculators/calcAvailableSortOrders';
import { DataLabelFormatConfig } from './statisticOperations/types';
import { calcStatisticOperations } from './statisticOperations/utils';
import { getFiltersForQuery, RawMetricMetadata } from './utils';

export function useRulesEngine() {
	const { rulesInput } = useRulesEngineInput();
	const setDerivedState = useSetAtom(DerivedStateAtom);

	const { decimalDigits: decimalDigitsTenantConfig, graphColors } = useTenantConfig();

	const {
		getMetricMetadata: [{ data: metricsMetadataByNameResult }, executeMetricMetadataRequest],
		getMetricObjectsTypes: [{ data: metricsObjectsTypesResult }, executeMetricObjectsTypesRequest],
	} = useMetricApi();
	const [, executeCalcMetric] = useCalcMetricApi();
	const executeInternalCalcMetric = useInternalCalcMetric();
	const fetchDataFromApis = () => {
		executeMetricMetadataRequest(rulesInput.metricNameWithoutFlavor, '').catch(console.error);
		executeMetricObjectsTypesRequest(rulesInput.metricNameWithoutFlavor).catch(console.error);
	};

	const clearStateOnExit = useCallback(() => setDerivedState(loadingDerivedState), [setDerivedState]);
	useEffect(() => clearStateOnExit, [clearStateOnExit]);

	useEffect(fetchDataFromApis, [
		executeMetricMetadataRequest,
		executeMetricObjectsTypesRequest,
		rulesInput.metricNameWithoutFlavor,
	]);

	useEffect(() => {
		setDerivedState((s) => ({ ...s, isPartiallyLoadingTable: true }));
	}, [setDerivedState, rulesInput.searchParams.selectedXAxisElements, rulesInput.searchParams.collectProps]);

	useEffect(
		() => setDerivedState((s) => ({ ...s, isPartiallyLoadingChart: true })),
		[rulesInput.searchParams.statisticsOperations, setDerivedState]
	);

	const nonFullyReRenderingSearchParams: (keyof MetricSearchParams)[] = [
		'statisticsOperations',
		'hiddenLegendNames',
		'displayUnits',
		'selectedPeriod',
		'selectedXAxisElements',
		'decimalDigits',
	];

	const stringifiedFullyReRenderingSearchParams = JSON.stringify(
		omit(rulesInput.searchParams, nonFullyReRenderingSearchParams)
	);
	useEffect(
		() => setDerivedState((s) => ({ ...s, isLoading: true })),
		[stringifiedFullyReRenderingSearchParams, setDerivedState, rulesInput.metricNameWithFlavor]
	);

	useEffect(() => {
		(async () => {
			if (!metricsMetadataByNameResult || metricsMetadataByNameResult.metrics.length == 0) return;
			setDerivedState((s) => ({ ...s, isRulesEngineRunning: true }));
			const withFlavor = calcFlavor(rulesInput, metricsMetadataByNameResult);
			const metadata = getRawMetadataByFlavor(withFlavor, metricsMetadataByNameResult);
			const initialState = {
				metricExplanationOneliner: metadata.oneliner ?? '',
				metricNameWithoutFlavor: rulesInput.metricNameWithoutFlavor,
				metricNameWithFlavor: rulesInput.metricNameWithFlavor,
				metricExplanationArticleId: metadata.article_id ?? undefined,
				metricDisplayName: metadata.display_name ?? rulesInput.metricNameWithoutFlavor,
				...withFlavor,
			};

			const withAvailablePeriodRange = {
				...initialState,
				...calcAvailablePeriodRanges(metadata.relevant_period_ranges),
			};
			const withPeriodRange = {
				...withAvailablePeriodRange,
				...calcPeriodRange(rulesInput, metadata, withAvailablePeriodRange.availablePeriodRanges),
			};
			const withRelevantRangePresets = {
				...withPeriodRange,
				...calcAvailableRangePresets(withPeriodRange),
			};
			const withMetricOp = { ...withRelevantRangePresets, ...calcMetricOp(metadata) };
			const withTableColumnState = {
				...withMetricOp,
				...calcTableColumnState(rulesInput.searchParams.tableColumnState),
			};
			const isGroupByAvailableInSearchParams = Object.keys(rulesInput.searchParams.groupBy ?? {}).length != 0;
			const withStaticOperations = {
				...withTableColumnState,
				...calcStatisticOperations(rulesInput.searchParams.statisticsOperations, {
					...withTableColumnState,
					hasGroupBy: isGroupByAvailableInSearchParams,
				}),
			};

			const withBreakdowns = {
				...withStaticOperations,
				...calcBreakdowns(rulesInput.searchParams, withStaticOperations),
			};

			const withObjectsTypes = {
				...withBreakdowns,
				objectsTypes: metricsObjectsTypesResult?.getMetricNodeTypes?.result?.objectsTypes ?? [],
			};

			const withFilters = { ...withObjectsTypes, ...calcFilters(rulesInput.searchParams, withObjectsTypes) };

			const withCollectProps = { ...withFilters, ...calcCollectedProps(rulesInput.searchParams) };

			setDerivedState((s) => ({ ...s, ...withCollectProps }));

			const metricCalcResult = await executeCalcMetric({
				metricName: rulesInput.metricNameWithFlavor,
				periodRange: withCollectProps.periodRange,
				groupBy: withCollectProps.breakdowns?.values.map((breakdown) => breakdown.key),
				filterBy: getFiltersForQuery(withCollectProps.filters),
				statisticsOptions: calcStatisticsParams(withCollectProps),
			});
			if (!metricCalcResult) {
				setDerivedState((s) => ({
					...s,
					errorMessage: 'Metric could not be calculated',
				}));
				return;
			}

			const withIsGranularDataForAllPeriodsButtonEnabled = {
				...withCollectProps,
				isGranularDataForAllPeriodsButtonEnabled: !metricCalcResult.info.op.isConcatable,
			};

			const withVizRulesInput = {
				...withIsGranularDataForAllPeriodsButtonEnabled,
				metricInfo: metricCalcResult.info,
				...calcVizInputRules(metricCalcResult.results),
			};

			const withAvailableSortOrders = {
				...withVizRulesInput,
				...calcAvailableSortOrders(
					withVizRulesInput,
					rulesInput.searchParams.sortOrder,
					rulesInput.searchParams.orderedComponents
				),
			};
			const withChartOptionsSeries = {
				...withAvailableSortOrders,
				...calcChartOptionsSeries(metricCalcResult, withAvailableSortOrders, rulesInput.searchParams.orderedComponents),
			};

			const withChartType = {
				...withChartOptionsSeries,
				...calcAvailableChartTypes(withChartOptionsSeries, rulesInput.searchParams.chartType),
			};

			const withCorrectChartType = {
				...withChartType,
				...calcOverrideChartType(withChartType),
			};

			const withDisplayUnits = {
				...withCorrectChartType,
				...calcDisplayUnits(rulesInput.searchParams.displayUnits, withCorrectChartType),
			};

			const withAxis = {
				...withDisplayUnits,
				...calcAxes(withCorrectChartType.chartOptions, withCorrectChartType.periodRange),
			};

			const withSelectedXAxisElement = {
				...withAxis,
				...calcSelectedXAxisElement(rulesInput.searchParams, withAxis),
			};

			const withPlotBands = {
				...withSelectedXAxisElement,
				...calcPlotBands(withSelectedXAxisElement),
			};

			const withStatistics = {
				...withPlotBands,
				...calcStatistics(metricCalcResult, withPlotBands.chartOptions),
			};

			const withTargets = {
				...withStatistics,
				...calcTargetsChartOptions(metricCalcResult, withStatistics.chartOptions),
			};

			const withDisplayedLegendItems = {
				...withTargets,
				...calcDisplayedLegendItems(withTargets, rulesInput),
			};

			const withSeriesVisibility = {
				...withDisplayedLegendItems,
				...calcSeriesVisibility(withDisplayedLegendItems),
			};

			const withPieChartSeriesVisibility = {
				...withSeriesVisibility,
				...calcPieSeriesVisibility(withSeriesVisibility),
			};

			const withColoredSeries = {
				...withPieChartSeriesVisibility,
				...calcColoredSeries(withPieChartSeriesVisibility, graphColors, rulesInput.searchParams.orderedComponents),
			};
			const withColoredBubbles = { ...withColoredSeries, ...calcColoredBubbles(withColoredSeries.chartOptions) };

			const withDisplayUnitsSeries = {
				...withColoredBubbles,
				...calcDisplayUnitsSeries(metricCalcResult, withColoredBubbles),
			};

			const withTableType = {
				...withDisplayUnitsSeries,
				...calcTableType(withDisplayUnitsSeries),
			};

			const withDecimalDigits = {
				...withTableType,
				...calcDecimalDigits({
					searchParamsDecimalDigits: rulesInput.searchParams.decimalDigits,
					tenantConfigDecimalDigits: decimalDigitsTenantConfig,
				}),
			};

			const withFormatting = {
				...withDecimalDigits,
				...calcFormatting(withDecimalDigits.chartOptions, {
					decimalDigits: withDecimalDigits.decimalDigits,
					displayUnits: withDecimalDigits.displayUnits,
				}),
			};

			const formatConfig: DataLabelFormatConfig = {
				decimalDigits: withFormatting.decimalDigits,
				displayUnits: withFormatting.displayUnits,
			};

			const withStatisticFormatting = {
				...withFormatting,
				...calcStatisticFormatting(withFormatting.chartOptions, formatConfig),
			};

			const withIsExecutiveView = { ...withStatisticFormatting, ...calcIsExecutiveView(rulesInput.searchParams) };
			const withIsLoading = { ...withIsExecutiveView, isLoading: false, isPartiallyLoadingChart: false };

			setDerivedState((s) => ({ ...s, ...withIsLoading }));

			const withTable = {
				...withIsLoading,
				...(await calcTable(withIsLoading, formatConfig, executeInternalCalcMetric)),
			};

			setDerivedState({
				...withTable,
				isRulesEngineRunning: false,
				isPartiallyLoadingChart: false,
				doesMetricExist: true,
				isFullyDefined: true,
				missingDependencies: [],
				parameters: [],
			});
		})().finally(() => setDerivedState((s) => ({ ...s, isRulesEngineRunning: false })));
	}, [
		executeCalcMetric,
		metricsMetadataByNameResult,
		metricsObjectsTypesResult,
		rulesInput,
		setDerivedState,
		executeInternalCalcMetric,
		graphColors,
		decimalDigitsTenantConfig,
	]);
}

function getRawMetadataByFlavor(
	{ flavor }: Pick<MetricDerivedState, 'flavor'>,
	metricsMetadataByNameResult: GetMetricByNameQuery
): RawMetricMetadata {
	return (
		metricsMetadataByNameResult.metrics.find((m) => m.flavor == flavor?.selectedValue) ??
		metricsMetadataByNameResult.metrics[0]
	);
}
