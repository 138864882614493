import { GridApi, ColumnApi } from 'ag-grid-community';
import { atom, useAtom } from 'jotai';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';

export type GridTableApi = { api?: GridApi; columnApi?: ColumnApi };

export const GridTableAtom = atom<GridTableApi>({});
export const GridTableScope = Symbol();

export function useGridTableApi() {
	const [gridTableApi, setGridTableApi] = useAtom(GridTableAtom);
	return { gridTableApi, setGridTableApi };
}

export function useIsGridTableV2Enabled() {
	const sightfullV2 = useFeatureFlag('pulse.sightfull2.enable');
	const gridTableV2 = useFeatureFlag('pulse.gridtable.v2');
	return sightfullV2 && gridTableV2;
}
