import { InputRightElement as ChakraInputRightElement } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = {
	testId?: string;
	component?: ReactNode;
	onClick?: React.MouseEventHandler<HTMLInputElement>;
	rightComponentPadding?: string;
};

function Right({ component, testId, onClick, rightComponentPadding }: Props): JSX.Element | null {
	return component ? (
		<ChakraInputRightElement
			data-testid={testId}
			width="fit-content"
			pr={rightComponentPadding ?? '16px'}
			pl="4px"
			color="gray.700"
			onClick={onClick}
		>
			{component}
		</ChakraInputRightElement>
	) : null;
}

export default Right;
