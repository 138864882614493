import Box from '@components/Box';
import Checkbox from '@components/Checkbox';
import { DragItem9, NewTabThin16, SlidersVertical16 } from '@components/Icons';
import ListItem from '@components/ListItem';
import Tooltip from '@components/Tooltip';
import { MetricComponent } from './types';
import { Flex } from '@chakra-ui/react';
import Button from '@components/Button';
import { DRAG_HANDLE_CLASS } from './constants';
import { ComponentNameWithParamsTooltipContent } from '../Parameters/ComponentNameWithParamsTooltip';
import { useMemo } from 'react';

export type LegendComponentProps = {
	component: MetricComponent;
	onLinkClick?: (componentName: string) => void;
	onCheckChange: () => void;
	isIntermediate?: boolean;
	isFluidCheckBox?: boolean;
	isDraggable?: boolean;
	isDragActive?: boolean;
};

export function LegendComponent({
	component,
	onLinkClick,
	onCheckChange,
	isIntermediate = false,
	isFluidCheckBox = false,
	isDraggable = false,
	isDragActive = false,
}: LegendComponentProps) {
	const hasAppliedParameters = useMemo(
		() => component.appliedParameters && component.appliedParameters.length > 0,
		[component.appliedParameters]
	);

	return (
		<ListItem
			onClick={onCheckChange}
			hasRoundedCorners
			hoverColor={'blue.100'}
			prefixComponent={(isHovered: boolean) => (
				<Tooltip label={<ComponentNameWithParamsTooltipContent component={component} />} size={'sm'}>
					<Flex alignItems={'center'}>
						{isDraggable && isHovered && (
							<Box
								cursor={isDragActive ? 'grabbing' : 'grab'}
								transition={'0.1s'}
								_hover={{ color: 'blue.700' }}
								color={'gray.600'}
								position={'absolute'}
								left={'5px'}
								className={DRAG_HANDLE_CLASS}
							>
								<DragItem9 />
							</Box>
						)}
						<Checkbox
							isFluid={isFluidCheckBox}
							isIntermediate={isIntermediate}
							size={'sm'}
							checkboxColor={component.color}
							label={component.label ?? component.value}
							isChecked={component.isChecked}
							onChange={onCheckChange}
							pointerEvents={'none'}
							labelColor={'gray.1000'}
						/>
					</Flex>
				</Tooltip>
			)}
			suffixComponent={(isHovered: boolean) => (
				<Flex alignItems={'center'} gap={'4px'}>
					{isHovered && onLinkClick && (
						<Tooltip label="Open in new tab" placement="left" size={'md'}>
							<Button
								isIconOnly={true}
								color={'gray.1000'}
								variant="outline"
								colorScheme="blue"
								onClick={(event) => {
									onLinkClick(component.value);
									event.stopPropagation();
								}}
								size="xxxs"
								blendMode={'multiply'}
							>
								<NewTabThin16 />
							</Button>
						</Tooltip>
					)}
					{hasAppliedParameters && (
						<Tooltip
							label={
								<ComponentNameWithParamsTooltipContent component={{ appliedParameters: component.appliedParameters }} />
							}
							placement="left"
							size={'md'}
						>
							<Button
								isIconOnly={true}
								color={'gray.1000'}
								variant="outline"
								colorScheme="blue"
								size="xxxs"
								blendMode={'multiply'}
							>
								<SlidersVertical16 />
							</Button>
						</Tooltip>
					)}
				</Flex>
			)}
			label=""
			size={'sm'}
		/>
	);
}

export type LegendSelectAllProps = {
	isChecked: boolean;
	isIntermediate: boolean;
	onCheckChange: () => void;
};

export function LegendSelectAll({ isChecked, isIntermediate, onCheckChange }: LegendSelectAllProps) {
	return (
		<LegendComponent
			component={{
				isChecked,
				value: 'Select all',
				color: undefined,
			}}
			isIntermediate={isIntermediate}
			onCheckChange={onCheckChange}
		/>
	);
}
