import * as CSS from 'csstype';
import { useState } from 'react';
import Flex from '../Flex';
import { ChevronRight16, ChevronRightSolid16 } from '../Icons';
import Typography from '../Typography';
import { TypographyVariant } from '../Typography/types';
import classes from './ListItem.module.scss';
import { ListItemProps, ListItemSize } from './types';

export const ListItem = ({
	state = 'enabled',
	size,
	label,
	prefixIcon,
	prefixComponent,
	suffixComponent,
	hasChevronLeft = false,
	hasChevronRight = false,
	isChevronLeftExpanded = false,
	testId,
	onClick,
	borderRadius,
	hasRoundedCorners = false,
	color,
	hoverColor,
	isHovered,
	id = '',
	onMouseEnter,
	onMouseLeave,
	noOfLines,
	isOntologyPanel = false,
	...spaceProps
}: ListItemProps) => {
	const iconColor = state === 'disabled' ? 'gray.400' : undefined;
	const [isHovering, setIsHovering] = useState(false);

	const prefix = typeof prefixComponent == 'function' ? prefixComponent?.(isHovering) : prefixComponent;
	const suffix = typeof suffixComponent == 'function' ? suffixComponent?.(isHovering) : suffixComponent;

	const classNames = [classes.listItem];
	if (state === 'selected') classNames.push(classes.selected);
	if (state === 'dragging') classNames.push(classes.dragging);
	if (size === 'lg') classNames.push(classes.large);
	if (isOntologyPanel) classNames.push(classes.ontology);

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		e.preventDefault();
		onClick?.();
	};

	return (
		<Flex
			//@ts-ignore
			disabled={state === 'disabled'}
			id={id}
			className={classNames.join(' ')}
			padding={paddingBySize[size]}
			height={heightBySize[size]}
			data-testid={testId}
			onClick={handleClick}
			onMouseEnter={() => {
				onMouseEnter?.();
				setIsHovering(true);
			}}
			onMouseLeave={() => {
				onMouseLeave?.();
				setIsHovering(false);
			}}
			borderRadius={borderRadius ? borderRadius : hasRoundedCorners ? '4px' : '0'}
			backgroundColor={isHovered ? hoverColor : ''}
			_hover={hoverColor ? { backgroundColor: hoverColor } : {}}
			{...spaceProps}
		>
			<>
				<Flex direction="row" alignItems="center" minWidth={0}>
					{hasChevronLeft && (
						<Flex className={!color ? classes.chevronLeft : ''} color={color ?? iconColor} paddingRight="12px">
							<div className={isChevronLeftExpanded ? classes.expanded : classes.notExpanded}>
								<ChevronRight16 width="16px" height="16px" />
							</div>
						</Flex>
					)}
					{prefixIcon && (
						<Flex className={!color ? classes.icon : ''} paddingRight="12px" color={color ?? iconColor}>
							{prefixIcon}
						</Flex>
					)}
					{prefix && <Flex paddingRight="8px">{prefix}</Flex>}
					<Typography
						color={color || undefined}
						className={!color ? classes.label : ''}
						variant={typographyBySize[size]}
						overflow={'hidden'}
						textOverflow={'ellipsis'}
						noOfLines={noOfLines}
						wordBreak={'break-all'}
					>
						{label}
					</Typography>
				</Flex>

				{suffix}
				{hasChevronRight && (
					<Flex
						className={!color ? classes.chevronRight : ''}
						color={color ?? iconColor}
						_hover={{ color: size === 'lg' ? 'gray.700' : undefined }}
					>
						<ChevronRightSolid16 />
					</Flex>
				)}
			</>
		</Flex>
	);
};

const paddingBySize: { [key in ListItemSize]: CSS.Property.Padding } = {
	sm: '8px 16px',
	md: '12px 16px',
	lg: '12px 16px',
};

const heightBySize: { [key in ListItemSize]: CSS.Property.Height } = {
	sm: '36px',
	md: '44px',
	lg: '44px',
};

const typographyBySize: { [key in ListItemSize]: TypographyVariant } = {
	sm: 'DesktopH8Regular',
	md: 'DesktopH8Regular',
	lg: 'DesktopH7Regular',
};
