import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { singleMetricToFullYaml, upsertYAMLKey, upsertYAMLObjectKey } from 'src/models/YamlUtils/yamlUtils';
import { useMetricEditorState } from 'src/pages/MetricPage/hooks/useMetricEditorState';
import { useUpdatedPreviewHashState } from 'src/pages/MetricPage/hooks/useUpdatedPreviewHashAtom';
import { MetricDefinitionState } from 'src/pages/MetricPage/utils/editor.types';
import { hashMetricYamlEditorValueForPreview } from 'src/pages/MetricPage/utils/stateHelpers';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import YAML from 'yaml';
import { normalizeMetricState } from '../../../models/MetricDefinition/normalizeMetricState';
import { PeriodXAxis } from '../../completions/semanticTypes/metrics.schema';
import {
	AGGREGATE_METRIC_EMPTY_YAML,
	FORMULA_METRIC_EMPTY_YAML,
	UNTITLED_METRIC_DISPLAY,
	UNTITLED_METRIC_NAME,
} from './useMetricBuilder';

const DEFAULT_FORMULA_YAML_VALUE = FORMULA_METRIC_EMPTY_YAML.join('\n');
const DEFAULT_AGGREGATE_YAML_VALUE = AGGREGATE_METRIC_EMPTY_YAML.join('\n');

export function useBuilderDerivedState() {
	const { metricYamlEditorHashState, setMetricYamlEditorHashState } = useUpdatedPreviewHashState();
	const {
		metricEditorLoadedState,
		latestEditorValue = '',
		setMetricEditorState,
		hasChangesToPreview,
	} = useMetricEditorState();

	const kind = metricEditorLoadedState?.kind;

	const updateMetricEditorState = useCallback(
		({ shouldPreviewAfter = false, userValue }: { shouldPreviewAfter?: boolean; userValue: string }) => {
			if (!(shouldPreviewAfter && kind)) {
				setMetricEditorState((s) => ({ ...s, userDefinedValue: userValue }));
				return;
			}

			const fullMetricYaml = singleMetricToFullYaml(userValue, kind);
			setMetricYamlEditorHashState((s) => ({
				...s,
				requestedPreviewHash: hashMetricYamlEditorValueForPreview(fullMetricYaml),
			}));

			setMetricEditorState((s) => ({ ...s, previewValue: fullMetricYaml, userDefinedValue: userValue }));
		},
		[kind, setMetricEditorState, setMetricYamlEditorHashState]
	);

	//TODO: Key should be all string values / support for nested keys
	const upsertYAMLProperties = useCallback(
		(
			keys: { key: Parameters<typeof upsertYAMLKey>[1]; value?: Parameters<typeof upsertYAMLKey>[2] }[],
			options?: { shouldPreviewAfter?: boolean }
		) => {
			const userValue = keys.reduce(
				(editorValue, keyVal) => upsertYAMLKey(editorValue, keyVal.key, keyVal.value),
				latestEditorValue
			);
			updateMetricEditorState({ shouldPreviewAfter: options?.shouldPreviewAfter || false, userValue });
		},
		[latestEditorValue, updateMetricEditorState]
	);

	const upsertYAMLProperty = useCallback(
		(
			key: Parameters<typeof upsertYAMLProperties>[0][0]['key'],
			value?: Parameters<typeof upsertYAMLProperties>[0][0]['value'],
			options?: Parameters<typeof upsertYAMLProperties>[1]
		) => {
			upsertYAMLProperties([{ key, value }], options);
		},
		[upsertYAMLProperties]
	);

	const upsertYAMLObjectProperties = useCallback(
		(
			keys: { key: string; value: string | { key: string; value: string | PeriodXAxis[] }[] }[],
			options?: { shouldPreviewAfter?: boolean }
		) => {
			const userValue = keys.reduce(
				(editorValue, keyVal) => upsertYAMLObjectKey(editorValue, keyVal.key, keyVal.value),
				latestEditorValue
			);

			updateMetricEditorState({ shouldPreviewAfter: options?.shouldPreviewAfter, userValue });
		},
		[latestEditorValue, updateMetricEditorState]
	);

	const resetYAMLObjectProperties = useCallback(
		(keys: { key: string; value: string | { key: string; value: string }[] }[]) => {
			const userValue = keys
				.reduce(
					(editorValue, keyVal) => upsertYAMLObjectKey(editorValue, keyVal.key, keyVal.value),
					kind === 'formula' ? DEFAULT_FORMULA_YAML_VALUE : DEFAULT_AGGREGATE_YAML_VALUE
				)
				.trim();

			setMetricEditorState((s) => ({ ...s, userDefinedValue: userValue }));
		},
		[setMetricEditorState, kind]
	);

	const onPreview = useCallback(() => {
		if (!latestEditorValue || !kind || !hasChangesToPreview) return;
		const fullMetricYaml = singleMetricToFullYaml(latestEditorValue, kind);

		setMetricYamlEditorHashState((s) => ({
			...s,
			requestedPreviewHash: hashMetricYamlEditorValueForPreview(fullMetricYaml),
		}));

		setMetricEditorState((s) => ({ ...s, previewValue: fullMetricYaml }));
	}, [latestEditorValue, kind, hasChangesToPreview, setMetricYamlEditorHashState, setMetricEditorState]);

	const replaceNameAndDisplayName = (yamlString: string, newName: string, newDisplayName: string) => {
		const yamlObject = YAML.parse(yamlString);
		yamlObject.name = newName;
		yamlObject.meta.display_name = newDisplayName;
		const newYamlString = YAML.stringify(yamlObject);
		return newYamlString;
	};

	const clearWithNameSave = useCallback(
		(newName: string, newDisplayName: string) => {
			if (metricEditorLoadedState?.savedValue) {
				const updatedValue = replaceNameAndDisplayName(metricEditorLoadedState?.savedValue, newName, newDisplayName);
				setMetricEditorState((s) => ({
					...s,
					previewValue: '',
					userDefinedValue: updatedValue,
				}));
			}
		},
		[setMetricEditorState, metricEditorLoadedState]
	);

	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	const location = useLocation();
	const shouldUseDraftName = !hasEditPermission && location.pathname.includes('create-new-metric');

	const resetYAMLValue = useCallback(
		({
			name = shouldUseDraftName ? 'untitled_metric' : UNTITLED_METRIC_NAME,
			display_name = shouldUseDraftName ? 'Untitled metric' : UNTITLED_METRIC_DISPLAY,
		}: {
			name?: string;
			display_name?: string;
		} = {}) => {
			const commonValues = [
				{ key: 'name', value: name },
				{ key: 'entity', value: '' },
			];
			const meta = {
				key: 'meta',
				value: [
					{
						key: 'display_name',
						value: display_name,
					},
				],
			};
			if (kind === 'formula') resetYAMLObjectProperties([...commonValues, { key: 'formula', value: '' }, meta]);
			if (kind === 'aggregate')
				resetYAMLObjectProperties([...commonValues, { key: 'operation', value: 'count' }, meta]);
		},
		[resetYAMLObjectProperties, kind, shouldUseDraftName]
	);

	const clearState = useCallback(() => {
		setMetricEditorState((s) => ({
			...s,
			previewValue: '',
			userDefinedValue: '',
		}));
		setMetricYamlEditorHashState(() => ({
			requestedPreviewHash: '',
			calculatedRulesEngineHash: '',
		}));
	}, [setMetricEditorState, setMetricYamlEditorHashState]);

	const onSave = useCallback(() => {
		setMetricEditorState((s) => {
			if (s.isLoading || !s.userDefinedValue) return s;
			return {
				...s,
				savedValue: s.userDefinedValue,
				previewValue: '',
			};
		});
	}, [setMetricEditorState]);

	const metricBuilderState: MetricDefinitionState | null = useMemo<MetricDefinitionState | null>(() => {
		try {
			if (!latestEditorValue || !kind) return null;
			const metricDataRaw: unknown = YAML.parse(latestEditorValue || '');
			return normalizeMetricState(metricDataRaw, kind);
		} catch (e) {
			console.log('Error parsing YAML', e);
			return null;
		}
	}, [kind, latestEditorValue]);

	const isCalculatingPreview = useMemo(() => {
		return metricYamlEditorHashState?.requestedPreviewHash !== metricYamlEditorHashState?.calculatedRulesEngineHash;
	}, [metricYamlEditorHashState]);

	return {
		metricBuilderState,
		isCalculatingPreview,
		onPreview,
		clearState,
		clearWithNameSave,
		onSave,
		upsertYAMLProperty,
		upsertYAMLProperties,
		upsertYAMLObjectProperties,
		resetYAMLObjectProperties,
		resetYAMLValue,
	};
}
