import { useCallback, useMemo } from 'react';
import { GridTableColumnHeaderRendererProps } from '../../../common/components/GridTable/GridTableColumnHeaderRenderer';
import { convertModalResultToValidCoreValues } from '../components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { FiltersAndBreakdownResponseType } from '../components/FiltersAndBreakdown/types';
import { useCollectedProps } from './useCollectedProps';
import { useReportEvent } from '../../../services/analytics';
import useFeatureFlag from '../../../common/hooks/stores/useFeatureFlag';
import { useMetricDerivedState } from './useMetricDerivedState';

export function useAddAndRemoveTableColumns() {
	const { tableColDefs, metricNameWithFlavor, objectsTypes } = useMetricDerivedState();
	const { collectedProps, addCollectedProps, removeCollectedProp } = useCollectedProps();
	const { reportEvent } = useReportEvent();
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	const handleAddColumns = useCallback(
		(result: FiltersAndBreakdownResponseType) => {
			reportEvent({ event: 'datagrid-add-column', metaData: { metricNameWithFlavor, ...result } });
			if (isSightfull2)
				addCollectedProps(convertModalResultToValidCoreValues(result, objectsTypes[0]).map((e) => e.key));
			else addCollectedProps(result.items.map((e) => e.key));
		},
		[addCollectedProps, isSightfull2, metricNameWithFlavor, objectsTypes, reportEvent]
	);

	const onRemoveColumn = useCallback(
		(colId: string) => {
			removeCollectedProp(colId);
		},
		[removeCollectedProp]
	);

	const removableTableColDefs = useMemo(() => {
		return tableColDefs.map((colDef) => {
			const isColumnRemovable = colDef.field && collectedProps.includes(colDef.field);
			const headerComponentParams: GridTableColumnHeaderRendererProps = {
				...colDef.headerComponentParams,
				removable: isColumnRemovable,
				onRemoveColumn: isColumnRemovable ? onRemoveColumn : undefined,
			};
			return {
				...colDef,
				headerComponentParams,
			};
		});
	}, [collectedProps, onRemoveColumn, tableColDefs]);

	return { handleAddColumns, onRemoveColumn, removableTableColDefs };
}
