import { Box, Flex } from '@chakra-ui/react';
import { ReactNode, useRef, useState } from 'react';

import classes from 'src/common/components/TextEditor/styles/TextEditor.module.scss';
import useMentionsState from 'src/common/hooks/stores/useMentionsState';

import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import * as CSS from 'csstype';
import ContentEditable from 'src/common/components/TextEditor/components/ContentEditable';
import LexicalComposer from 'src/common/components/TextEditor/components/LexicalComposer';
import { FocusPlugin } from 'src/common/components/TextEditor/plugins/focus/Focus';
import MentionPlugin from 'src/common/components/TextEditor/plugins/mention/Mention';
import 'src/common/components/TextEditor/styles/global.scss';
import { useHandleEditorStateChange } from 'src/common/components/TextEditor/TextEditor';
import Typography from 'src/common/components/Typography';
import { SubmitButtonPlugin } from './SubmitButtonPlugin';

export function CommentTextEditor({
	value,
	isEditMode,
	onChange,
	onSubmit,
	isInsertLoading,
	maxHeightOfTextArea,
	prefixComponent,
	isClearingOnSubmit,
}: {
	value: string;
	isEditMode: boolean;
	onChange: (markdown: string) => void;
	onSubmit: () => Promise<void>;
	isInsertLoading: boolean;
	maxHeightOfTextArea?: CSS.Property.MaxHeight;
	prefixComponent?: ReactNode;
	isClearingOnSubmit: boolean;
}) {
	const mentions = useMentionsState();
	const chosenMentions = useRef<string[]>([]);
	const handleEditorStateChange = useHandleEditorStateChange({ mentions, onChange, chosenMentions });
	const [isFocused, setIsFocused] = useState(false);

	return (
		<Flex
			position={'relative'}
			maxHeight={'120px'}
			height="120px"
			direction={'row'}
			border="1px solid"
			borderColor={isFocused ? 'gray.800' : 'gray.400'}
			borderRadius={'4px'}
			overflow="hidden"
			_hover={isFocused ? undefined : { borderColor: 'gray.500' }}
			transition="all 0.2s"
		>
			{prefixComponent}
			<Box flex={1}>
				<LexicalComposer value={value}>
					<Flex direction={'column'} height="100%" bgColor={'white'}>
						<Box
							className={'textEditorContainer'}
							color="black"
							position="relative"
							lineHeight="20px"
							textAlign={'left'}
							flex={1}
							overflow="auto"
							maxHeight={maxHeightOfTextArea}
							mb="44px"
						>
							<RichTextPlugin
								contentEditable={<ContentEditable isEditMode={isEditMode} />}
								placeholder={
									<Box className={classes.editorPlaceholder}>
										<Typography variant="Paragraph14R">Write about your data....</Typography>
									</Box>
								}
								ErrorBoundary={LexicalErrorBoundary}
							/>
							<MentionPlugin mentions={mentions} chosenMentions={chosenMentions} isEditMode={isEditMode} />
							<OnChangePlugin onChange={handleEditorStateChange} ignoreSelectionChange />
							<FocusPlugin onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} />
							<ClearEditorPlugin />
						</Box>
						<SubmitButtonPlugin
							onSubmit={onSubmit}
							isInsertLoading={isInsertLoading}
							isDisabled={!value}
							isClearingOnSubmit={isClearingOnSubmit}
						/>
					</Flex>
				</LexicalComposer>
			</Box>
		</Flex>
	);
}
