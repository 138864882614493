import Button from '@components/Button/button.theme';
import SwitchTheme from '@components/Switch/Switch.theme';
import Checkbox from '@components/Checkbox/Checkbox.theme';
import FormError from '@components/FormErrorMessage/formErrorMessage.theme';
import FormLabel from '@components/FormLabel/formLabel.theme';
import Input from 'src/common/components/Input/input.theme';
import { RadioTheme } from '@components/Radio/radio.theme';
import { progressTheme } from '@components/Progress/Progress.theme';
import { TextareaTheme } from '@components/Textarea/textarea.theme';
import Tooltip from '@components/Tooltip/tooltip.theme';
import PopoverTheme from 'src/common/components/Popover/Popover.theme';

export default {
	Button,
	Tooltip,
	Input,
	NumberInput: Input,
	FormLabel,
	FormError,
	Checkbox,
	Textarea: TextareaTheme,
	Radio: RadioTheme,
	Switch: SwitchTheme,
	Popover: PopoverTheme,
	Progress: progressTheme,
};
