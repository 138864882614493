import { FetchPolicy } from '@apollo/client';
import {
	useCoreReaderDimensionValuesApi,
	useCoreReaderFiltersApi,
} from 'src/common/hooks/fetching/useCoreReaderFiltersApi';
import {
	FiltersAndBreakdownItemType,
	FiltersAndBreakdownResponseType,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import { MetricDerivedState } from '../../../utils/state.types';
import {
	FetchNodeArguments,
	GetAllDimensionValuesReturnType,
	GetFiltersAndBreakdownsReturnType,
	NodeSchemeReturnType,
	NodeSchemeType,
} from './types';
import { getFirstObjectType, normalizeValues, sortElements } from './utils';

export function useCoreNodeScheme({
	objectsTypes,
	schemeType = 'regular',
	fetchPolicy = 'cache-first',
	isIncludingTypePrefix = false,
	readyToFetch = true,
}: {
	objectsTypes: string[];
	schemeType?: NodeSchemeType;
	fetchPolicy?: FetchPolicy;
	isIncludingTypePrefix?: boolean;
	readyToFetch?: boolean;
}): NodeSchemeReturnType {
	const [filtersState, fetchRelationshipsAndDimensions] = useCoreReaderFiltersApi(fetchPolicy);
	const [getDimensionValuesState, fetchDimensionValues] = useCoreReaderDimensionValuesApi();
	const isLoading = getDimensionValuesState.loading || filtersState.loading;

	async function getFiltersAndBreakdowns({
		prefixPath,
		shouldIncludeNotNormalizedProps,
	}: FetchNodeArguments): GetFiltersAndBreakdownsReturnType {
		try {
			if (!readyToFetch) {
				return null;
			}

			const filterObjects = prefixPath ? [prefixPath.split('.').reverse()[0].split('>').reverse()[0]] : objectsTypes;

			const response = await fetchRelationshipsAndDimensions(filterObjects);
			if (!response) {
				return null;
			}

			const relationshipsParsed: FiltersAndBreakdownItemType[] = [];
			const dimensionsParsed: FiltersAndBreakdownItemType[] = [];

			const isFirstLevel = prefixPath.split('.').length === 1;

			Object.entries(response).forEach(([, { relationships, dimensions, entityName }]) => {
				if (isFirstLevel) {
					relationshipsParsed.push(
						...relationships
							.filter((relationship) => relationship.is_normalized || shouldIncludeNotNormalizedProps)
							.map((relationship): FiltersAndBreakdownItemType => {
								return {
									type: 'relationship',
									key: isIncludingTypePrefix
										? `${entityName}.${relationship.identifier}>${relationship.entity}`
										: `${relationship.identifier}>${relationship.entity}`,
									label: relationship.is_normalized ? relationship.name : `${relationship.name} (Source)`,
									isSelectable: false,
									isSelected: false,
								};
							})
					);
				}
				dimensionsParsed.push(
					...dimensions
						.filter((dimension) => dimension.is_normalized || shouldIncludeNotNormalizedProps)
						.map((dimension): FiltersAndBreakdownItemType => {
							return {
								type: 'dimension',
								key: isIncludingTypePrefix ? `${entityName}.${dimension.identifier}` : dimension.identifier,
								label: dimension.is_normalized ? dimension.name : `${dimension.name} (Source)`,
								isSelectable: false,
								isSelected: false,
							};
						})
				);
			});

			return {
				prefixPath,
				relationships: relationshipsParsed,
				dimensions: dimensionsParsed,
				type: filterObjects[0],
			};
		} catch (e) {
			console.log('Error: getGlobalFiltersAndBreakdowns: ', e);
			return null;
		}
	}

	async function getAllDimensionValues({
		prefixPath,
		dimensionLabel = '',
	}: FetchNodeArguments): Promise<GetAllDimensionValuesReturnType> {
		if (!readyToFetch) {
			return null;
		}

		const entity = prefixPath.includes('>')
			? prefixPath.split('.').reverse()[0].split('>').reverse()[0]
			: isIncludingTypePrefix
			? getFirstObjectType(prefixPath)
			: objectsTypes[0];
		const response = await fetchDimensionValues(entity, removeDollarSigns(dimensionLabel));

		return sortElements(normalizeValues(response));
	}

	const actions = {
		getFiltersAndBreakdowns,
		getAllDimensionValues,
	};

	return [{ isLoading, schemeType }, actions];
}

export function convertModalResultToValidCoreValues(
	result: FiltersAndBreakdownResponseType,
	objectsType: string
): FiltersAndBreakdownItemType[] {
	return result.items.map((item) => {
		return {
			...item,
			key: addPrefixDollarSign(
				removeDollarSigns(
					item.key
						.replace(`${objectsType}.`, '')
						.split('.')
						.map((part) => part.split('>')[0])
						.join('.')
				)
			),
		};
	});
}

export function removeDollarSigns(input: string) {
	return input.replaceAll('$', '');
}

export function buildFilters(filters: MetricDerivedState['filters']) {
	return filters.map(({ key, values }) => ({
		key: addPrefixDollarSignIfNeeded(key),
		values,
	}));
}

export function addPrefixDollarSign(input: string) {
	return `$${input}`;
}

export function addPrefixDollarSignIfNeeded(input: string) {
	return input.startsWith('$') ? input : addPrefixDollarSign(input);
}
