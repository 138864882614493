import * as monaco from 'monaco-editor';

const NEW_LINE = '\n';

export function insertNewLineAtCursorPosition(editor: monaco.editor.IStandaloneCodeEditor) {
	const position = editor.getPosition();
	if (!position) return;

	const range = new monaco.Range(position.lineNumber, position.column, position.lineNumber, position.column);
	const id = { major: 1, minor: 1 };
	const op = { identifier: id, range: range, text: NEW_LINE, forceMoveMarkers: true };
	editor.executeEdits('', [op]);
}
