import Box from '@components/Box';
import Button from '@components/Button';
import Tooltip from '@components/Tooltip';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useModal } from '@hooks/ui/useModal';
import useToast from '@hooks/ui/useToast';
import { DownloadSolid16 } from '@icons/index';
import { MetricPeriod } from '@sightfull/period-ranges';
import { DETAILS_TABLE_LIMIT } from 'src/lib/api/constants';
import { isDetailedTable } from 'src/lib/metricRules/DerivedStateCalculators';
import { buildExportLiveUrlFromState } from 'src/pages/MetricPage/components/Header/ExportTableModal/buildExportLiveUrl';
import { ExportPopover } from 'src/pages/MetricPage/components/Header/ExportTableModal/ExportPopover';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import { useCoreReaderDetailedExport } from './useCoreReaderExport';

export function ExportIcon({ exportTableDataAsCSV }: { exportTableDataAsCSV: () => any }) {
	const { reportEvent, wrapWithReport } = useReportEvent();
	const toast = useToast();
	const { navigate } = useNavigation();
	const metricDerivedState = useMetricDerivedState();
	const { startDownload } = useCoreReaderDetailedExport();
	const { metricNameWithFlavor, selectedXAxisElements, tableRowsData, tableType } = metricDerivedState;
	const { isOpen, onOpen, onClose } = useModal();
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const maxRowsApproximation = 10;
	const isTableShowingMaxRows = tableRowsData.length >= DETAILS_TABLE_LIMIT - maxRowsApproximation;

	const hasSelectedPeriods = selectedXAxisElements.length > 0 && selectedXAxisElements[0] instanceof MetricPeriod;
	const currentPeriod = hasSelectedPeriods ? (selectedXAxisElements[0] as MetricPeriod) : undefined;

	const downloadCSVInNewTab = () => {
		const exportPagePath = buildExportLiveUrlFromState(metricDerivedState, 'export/');
		navigate({ isNewTab: true, path: exportPagePath });
	};

	const onExportCSV = () => {
		reportEvent({
			event: 'seriesgrid-csv-export',
			metaData: {
				metricName: metricNameWithFlavor,
				tableType,
				currentPeriod: currentPeriod?.id,
			},
		});
		if (isSightfull2 && isDetailedTable(metricDerivedState.tableType)) {
			startDownload();
			toast({ variant: 'ok', message: 'Download successfully started.', isCloseable: false });
			return;
		}

		const shouldExportFromApi = isTableShowingMaxRows && hasSelectedPeriods;
		if (!shouldExportFromApi) {
			exportTableDataAsCSV();
			toast({ variant: 'ok', message: 'Download successfully started.', isCloseable: false });
			return;
		}
		downloadCSVInNewTab();
	};

	return (
		<Tooltip label="Export table" size="md" variant="fluid" hasArrow={true} marginTop="12px">
			<Box>
				<Button
					variant="outline"
					isIconOnly
					colorScheme={'lightGray'}
					size={'small'}
					onClick={wrapWithReport(onOpen, 'export-icon-clicked')}
					isActive={isOpen}
				>
					<DownloadSolid16 />
				</Button>
				<ExportPopover isOpen={isOpen} onCsvExport={onExportCSV} onClose={onClose} />
			</Box>
		</Tooltip>
	);
}
