import { Buffer } from 'buffer';
import * as yaml from 'js-yaml';
import omit from 'lodash/omit';
import { MetricsSchema } from 'src/lib/completions/semanticTypes/metrics.schema';
import { MultiSelector, Selector } from 'src/pages/MetricPage/utils/state.types';

export function isAllValuesInOptionalValues<T>(multiSelect: MultiSelector<T>, values: T[]) {
	return values.every((label) => multiSelect.optionalValues.includes(label));
}

export function isValueInOptionalValues<T>(selector: Selector<T>, value: T) {
	return selector.optionalValues.includes(value);
}

export function hashMetricYamlEditorValueForPreview(fullYamlValue: string) {
	if (!fullYamlValue) return fullYamlValue;

	const loadedYaml = yaml.load(fullYamlValue) as MetricsSchema;
	const cleanedPreviewYaml = omit(loadedYaml, 'metrics[0].meta');
	const cleanedYaml = yaml.dump(cleanedPreviewYaml);
	const hashValue = Buffer.from(cleanedYaml, 'binary').toString('base64');

	return hashValue;
}
