import { useMemo } from 'react';
import {
	NORMALIZED_PROPS_OPTIONS,
	NORMALIZED_PROPS_OPTIONS_V2,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import useFeatureFlag from './stores/useFeatureFlag';

export const useNormalizedPropsSelectedOption = () => {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	const normalizedPropsOptions = useMemo(() => {
		return isSightfull2 ? NORMALIZED_PROPS_OPTIONS_V2 : NORMALIZED_PROPS_OPTIONS;
	}, [isSightfull2]);

	return { normalizedPropsOptions };
};
