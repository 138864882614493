import { Center, Flex } from '@chakra-ui/react';
import Box from '@components/Box';
import Typography from '@components/Typography';
import Button from 'src/common/components/Button';
import { useModal } from 'src/common/hooks/ui/useModal';
import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { AddSmall16 } from '../Icons';
import { TestIDs } from 'src/common/types/test-ids';

export function CollectionEmptyPage({ collectionType }: { collectionType: string }) {
	const { isOpen, onOpen, onClose } = useModal();

	return (
		<Center height={'100%'}>
			<Flex direction="column" alignItems={'center'}>
				<Box paddingBottom={'20px'} data-testid={TestIDs.EMPTY_COLLECTION_PAGE_TITLE(collectionType)}>
					<Typography color={'gray.1000'} variant={'DesktopH5S'}>
						Create your first {collectionType}.
					</Typography>
				</Box>
				<Button leftIcon={<AddSmall16 />} variant="solid" colorScheme="blue" size="small" onClick={onOpen}>
					Add new {collectionType}
				</Button>
			</Flex>
			<CollectionUpsertModal isOpen={isOpen} onClose={onClose} collectionType={collectionType} />
		</Center>
	);
}
