import {
	insertMapInSequence,
	isMapNameExistInSequence,
	isSequenceExist,
	replaceMapInSequence,
	deleteMapFromSequence,
} from 'src/models/YamlUtils/yamlUtils';
import YAML from 'yaml';
import { OntologyPropertyType } from './types';

export type PropertyUpdateResult = {
	yaml: string;
	operation: 'create' | 'update' | 'rename' | 'delete';
	newName: string;
};
export type PropertyUpdate = {
	currentPropertyName: string;
	propertyDefinition: string | null;
	ontologyPropertyType: OntologyPropertyType;
	result?: (result: PropertyUpdateResult) => void;
};

export function upsertOntologyProperty({
	yaml,
	propertyDefinition,
	currentPropertyName,
	ontologyPropertyType,
}: {
	yaml: string;
} & PropertyUpdate): PropertyUpdateResult {
	if (propertyDefinition === null) {
		console.info(`Deleting property ${currentPropertyName}`);
		return {
			yaml: deleteMapFromSequence(yaml, ontologyPropertyType, currentPropertyName),
			operation: 'delete',
			newName: currentPropertyName,
		};
	}

	const metricNameInDefinition = getNameFromDefinition({
		definition: propertyDefinition,
	});
	const isRenameRequired = metricNameInDefinition != currentPropertyName;
	const isPropertyTypeSequenceExist = isSequenceExist(yaml, ontologyPropertyType);
	const doesNewPropertyNameAlreadyExist =
		isPropertyTypeSequenceExist && isMapNameExistInSequence(yaml, ontologyPropertyType, metricNameInDefinition);
	const doesCurrentPropertyNameExist =
		isPropertyTypeSequenceExist && isMapNameExistInSequence(yaml, ontologyPropertyType, currentPropertyName);

	if (isRenameRequired && doesNewPropertyNameAlreadyExist && doesCurrentPropertyNameExist) {
		const errorMessage = `Can't rename ${currentPropertyName} to name: ${metricNameInDefinition} - property already exists`;
		console.error(errorMessage);
		throw new Error(errorMessage);
	} else if (isRenameRequired && doesNewPropertyNameAlreadyExist && !doesCurrentPropertyNameExist) {
		const errorMessage = `Can't create ${metricNameInDefinition}: ${metricNameInDefinition} - property already exists`;
		console.error(errorMessage);
		throw new Error(errorMessage);
	} else if (isRenameRequired && !doesNewPropertyNameAlreadyExist && doesCurrentPropertyNameExist) {
		console.info(`Renaming property ${currentPropertyName} to ${metricNameInDefinition}`);
		return {
			yaml: replaceMapInSequence(yaml, ontologyPropertyType, propertyDefinition, currentPropertyName),
			operation: 'rename',
			newName: metricNameInDefinition,
		};
	} else if (!isRenameRequired && doesNewPropertyNameAlreadyExist && doesCurrentPropertyNameExist) {
		console.info(`Updating property definition ${metricNameInDefinition}`);
		return {
			yaml: replaceMapInSequence(yaml, ontologyPropertyType, propertyDefinition, currentPropertyName),
			operation: 'update',
			newName: metricNameInDefinition,
		};
	} else if (!doesNewPropertyNameAlreadyExist && !doesCurrentPropertyNameExist) {
		console.info(`Creating new property ${metricNameInDefinition}`);
		return {
			yaml: insertMapInSequence(yaml, ontologyPropertyType, propertyDefinition),
			operation: 'create',
			newName: metricNameInDefinition,
		};
	}

	throw new Error("Couldn't decide action for metric upsert");
}
export function getNameFromDefinition({ definition }: { definition: string }): string {
	const parsed = YAML.parse(definition);
	if (parsed != null && 'name' in parsed) {
		return parsed['name'];
	}

	throw new Error(`Name not found in property definition ${definition}`);
}
