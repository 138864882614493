import { extendTheme } from '@chakra-ui/react';
/* Rules */
import colors from 'src/style/colors';
import fontSizes from 'src/style/fontsize';
import breakpoints from 'src/style/breakpoints';
import fonts from 'src/style/fonts';
import fontWeight from 'src/style/fontWeight';
import shadows from 'src/style/shadows';
import components from 'src/style/components';

const theme = extendTheme({
	fonts,
	fontWeight,
	breakpoints,
	colors,
	components,
	fontSizes,
	shadows,
});

export { ChakraProvider } from '@chakra-ui/react';
export default theme;
