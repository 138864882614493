import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { MetricCalcResult, MetricCalcSeriesResult } from 'src/types/metric';

export function calcVizInputRules(
	metricData: MetricCalcResult['results']
): Pick<MetricDerivedState, 'hasComponents' | 'hasGroupBy' | 'isAllComponentSameUnit'> {
	return {
		hasComponents: isAnySeriesContains(metricData, (series) => series.type == 'COMPONENT'),
		hasGroupBy: isAnySeriesContains(metricData, (series) => series.type == 'GROUP'),
		isAllComponentSameUnit: isAllComponentSameUnit(metricData),
	};
}

function isAnySeriesContains(
	metricData: MetricCalcResult['results'],
	testfn: (value: MetricCalcSeriesResult) => boolean
): boolean {
	return metricData.some((periodResult) => periodResult.series.some(testfn));
}

function isAllComponentSameUnit(metricData: MetricCalcResult['results']): boolean {
	return metricData.some((periodResult) => {
		const allComponentsUnits = periodResult.series
			.filter((series) => series.type == 'COMPONENT')
			.map((series) => series.unit);
		return allComponentsUnits.every((unit) => unit == allComponentsUnits[0]);
	});
}
