import Box from '@components/Box';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { useRef, useState } from 'react';
import ContentEditable from 'src/common/components/TextEditor/components/ContentEditable';
import LexicalComposer from 'src/common/components/TextEditor/components/LexicalComposer';
import MentionPlugin from 'src/common/components/TextEditor/plugins/mention/Mention';
import ToolbarPlugin from 'src/common/components/TextEditor/plugins/toolbar/Toolbar';
import 'src/common/components/TextEditor/styles/global.scss';
import classes from 'src/common/components/TextEditor/styles/TextEditor.module.scss';
import { useHandleEditorStateChange } from 'src/common/components/TextEditor/TextEditor';
import Typography from 'src/common/components/Typography';
import useMentionsState from 'src/common/hooks/stores/useMentionsState';
import { useReportEvent } from 'src/services/analytics';
import { TitleInputFieldPlugin } from './TitleInputFieldPlugin';

export default function TextEditor({
	value,
	onChange,
	title,
	onTitleChange,
}: {
	value: string;
	onChange?: (markdown: string) => void;
	title: string;
	onTitleChange: (title: string) => void;
}) {
	const { reportEvent } = useReportEvent();
	const mentions = useMentionsState();
	const chosenMentions = useRef<string[]>([]);
	const handleSaveState = useHandleEditorStateChange({ mentions, onChange, chosenMentions });
	const [isTextEditorFocused, setIsTextEditorFocused] = useState(false);

	return (
		<LexicalComposer value={value}>
			<Box
				className={`textEditorContainer`}
				height="100%"
				color="black"
				position="relative"
				lineHeight="20px"
				fontWeight={400}
				textAlign={'left'}
			>
				<ToolbarPlugin isDisabled={!isTextEditorFocused} />
				<TitleInputFieldPlugin
					title={title}
					onTitleChange={onTitleChange}
					onFocus={() => setIsTextEditorFocused(false)}
					onBlur={() => setIsTextEditorFocused(true)}
				/>
				<Box position="relative" paddingX={'12px'}>
					<RichTextPlugin
						contentEditable={<ContentEditable isEditMode />}
						placeholder={
							<Box className={classes.editorPlaceholder} paddingLeft="12px">
								<Typography variant="Paragraph14R">Describe your insight</Typography>
							</Box>
						}
						ErrorBoundary={LexicalErrorBoundary}
					/>
					<ListPlugin />
					<LinkPlugin
						validateUrl={(url) => {
							reportEvent({
								event: 'link-clicked-in-post',
								metaData: { isPulseLink: new RegExp('^https://app.[a-zA-Z0-9]+.sightfull.com/').test(url) },
							});
							return true;
						}}
					/>
					<MentionPlugin mentions={mentions} chosenMentions={chosenMentions} isEditMode />
					<OnChangePlugin onChange={handleSaveState} ignoreSelectionChange />
				</Box>
			</Box>
		</LexicalComposer>
	);
}
