import { Box, Flex } from '@chakra-ui/react';
import Spinner from 'src/common/components/Spinner';
import Typography from 'src/common/components/Typography';
import { useHasuraSubscriptionWithCache } from 'src/common/hooks/fetching/useSubscription';
import useUser from 'src/common/hooks/stores/useUser';
import Page from 'src/layout/Page';
import { GetAllInsightsByTags } from 'src/queries/subscriptions';
import { Footer } from './Footer';
import { TagSection } from './TagSection';
import { InsightsByTags } from './types';

export const SubscriptionPagePath = 'settings';

export function SubscriptionPage() {
	return (
		<Page page="Subscription Page">
			<SubscriptionPageGuard />
		</Page>
	);
}
function SubscriptionPageGuard() {
	const [{ id: my_id }] = useUser();
	const { data, loading, error } = useHasuraSubscriptionWithCache(GetAllInsightsByTags, { variables: { my_id } });

	if (loading) return <LoadingSubscriptionPage />;

	if (!data || error) return <ErrorSubscriptionPage />;

	const insightsByTags: InsightsByTags = data;

	return (
		<Flex direction={'column'} height="100%">
			<Box
				py={'60px'}
				px={{
					base: '32px',
					sm: '32px',
					md: '126px',
					lg: '146px',
					xl: '296px',
				}}
				flex="1"
				overflowY={'auto'}
			>
				<Flex direction="column" gap="4px" paddingBottom={'48px'}>
					<Typography variant="Paragraph24R" color={'gray.1000'}>
						Manage subscriptions
					</Typography>
					<Typography variant="DesktopH7Medium" color={'gray.700'}>
						Manage the insights that interest you the most.
					</Typography>
				</Flex>
				{insightsByTags?.tags
					.filter((tag) => tag.insight_types.length > 0)
					.map((tag) => (
						<TagSection tag={tag} key={tag.id} />
					))}
			</Box>
			<Footer />
		</Flex>
	);
}

function LoadingSubscriptionPage() {
	return (
		<Flex height={'100%'} width="100%" justifyContent={'center'} alignItems="center">
			<Spinner />
		</Flex>
	);
}

function ErrorSubscriptionPage() {
	return (
		<Flex height={'100%'} width="100%" justifyContent={'center'} alignItems="center">
			Error loading your subscriptions
		</Flex>
	);
}
