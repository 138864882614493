import useSearchParams from '@hooks/navigation/useSearchParams';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AddTemplatePath } from 'src/pages/MetricPage/pageRoutesPaths';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';

// need this one to fix frontegg issue with urlParams
function getSearchParamFromName(searchParams: URLSearchParams, searchParamName: string) {
	const rawSearchParam = searchParams.get(searchParamName) || undefined;
	if (!rawSearchParam) return undefined;

	const parsedSearchParamArr = rawSearchParam.match(/^(.*?)\?/);
	const searchParam = parsedSearchParamArr ? rawSearchParam[1] : rawSearchParam;

	return searchParam;
}

export const useTemplateOnboardingModal = () => {
	const location = useLocation();
	const isAddTemplatePage = location.pathname === `/${AddTemplatePath}`;
	const [searchParams] = useSearchParams();
	const referralPageName = getSearchParamFromName(searchParams, 'referral_page_name');
	const templatesTag = getSearchParamFromName(searchParams, 'templates_tag');
	const templateName = getSearchParamFromName(searchParams, 'template') ?? '';

	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	const [isTemplateOnboardingModalOpen, setIsTemplateOnboardingModalOpen] = useState(
		hasEditPermission && isAddTemplatePage && !!templateName
	);
	const [isInstallTemplateMissingPermissionsModal, setIsInstallTemplateMissingPermissionsModal] = useState(
		!hasEditPermission && isAddTemplatePage && !!templateName
	);

	const onTemplateOnboardingModalClose = () => {
		setIsTemplateOnboardingModalOpen(false);
	};

	const onInstallTemplateMissingPermissionsModalClose = () => {
		setIsInstallTemplateMissingPermissionsModal(false);
	};

	return {
		referralPageName,
		templateName,
		templatesTag,
		isTemplateOnboardingModalOpen,
		onTemplateOnboardingModalClose,
		isInstallTemplateMissingPermissionsModal,
		onInstallTemplateMissingPermissionsModalClose,
	};
};
