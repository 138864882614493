import { ReactNode } from 'react';
import Tooltip from '../../Tooltip';
import Rectangle from '../icons/rectangle.svg?component';
import classes from '../styles/ToolbarButton.module.scss';

type ToolbarButtonVariant = 'button' | 'select';

type Props = {
	children: ReactNode;
	onClick: () => void;
	isSelected?: boolean;
	isActive?: boolean;
	variant?: ToolbarButtonVariant;
	tooltipLabel?: string;
};

const ToolbarButton = ({ children, onClick, variant = 'button', isActive, isSelected, tooltipLabel }: Props) => {
	const variantClass = getVariantClass(variant);
	const isSelect = variant === 'select';

	return (
		<button
			onClick={onClick}
			className={`${classes.toolbarButton} ${isActive ? classes.isActive : ''} ${variantClass} ${
				isSelected ? classes.isButtonSelected : ''
			}`}
		>
			<Tooltip label={tooltipLabel} size="sm" marginTop="20px">
				<>{children}</>
			</Tooltip>
			{isSelect && <Rectangle className={classes.dropDownArrow} />}
		</button>
	);
};

const getVariantClass = (variant: ToolbarButtonVariant) => {
	switch (variant) {
		case 'select':
			return classes.variantSelect;
	}

	return '';
};

export default ToolbarButton;
