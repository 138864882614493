import { Breakdown } from 'src/generated/graphql';
import { BreakdownOutline16 } from '../../Icons';
import { DrilldownCard } from './DrillDownCard';

export function BreakdownDrilldownCard({
	breakdown,
	onRemove,
	onClick,
}: {
	breakdown: Breakdown;
	onRemove: VoidFunction;
	onClick?: VoidFunction;
}) {
	return (
		<DrilldownCard
			icon={<BreakdownOutline16 />}
			tooltip={breakdown.label}
			onRemove={onRemove}
			removeTooltip={'Remove breakdown'}
			title={breakdown.label}
			onClick={onClick}
		/>
	);
}
