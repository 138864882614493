import type { Spread } from 'lexical';
import {
	DOMConversionMap,
	DOMConversionOutput,
	DOMExportOutput,
	EditorConfig,
	LexicalNode,
	NodeKey,
	SerializedTextNode,
	TextNode,
} from 'lexical';
import { LowPriority } from '../consts';
import classes from '../styles/mention.module.scss';

export type SerializedMentionNode = Spread<
	{
		mentionName: string;
		type: 'mention';
		version: 1;
	},
	SerializedTextNode
>;

type MentionsProperties = {
	name: string;
	id: string;
	email: string;
	avatarURL: string;
};

function convertMentionElement(domNode: HTMLElement): DOMConversionOutput | null {
	const textContent = domNode.textContent;

	if (textContent !== null) {
		const node = $createMentionNode({ name: textContent, id: '', email: '', avatarURL: '' });
		return { node };
	}

	return null;
}

const createTooltip = (mention: MentionNode) => {
	const tooltipSpan = document.createElement('div');
	tooltipSpan.className = classes.mentionTooltip;

	const nameAndEmailColumn = document.createElement('div');
	nameAndEmailColumn.className = classes.mentionTooltipColumn;

	const nameSpan = document.createElement('div');
	nameSpan.className = classes.mentionName;
	nameSpan.textContent = mention.__mention;

	const emailSpan = document.createElement('div');
	emailSpan.className = classes.mentionEmail;
	emailSpan.textContent = mention.__email;

	[nameSpan, emailSpan].map((e) => nameAndEmailColumn.appendChild(e));

	const avatarSpan = document.createElement('img');
	avatarSpan.className = classes.mentionAvatar;
	avatarSpan.src = mention.__avatarURL;

	[avatarSpan, nameAndEmailColumn].map((e) => tooltipSpan.appendChild(e));

	return tooltipSpan;
};

export class MentionNode extends TextNode {
	__id: string;
	__mention: string;
	__email: string;
	__avatarURL: string;

	static getType(): string {
		return 'mention';
	}

	static clone(node: MentionNode): MentionNode {
		return new MentionNode(node.__mention, node.__id, node.__email, node.__avatarURL, node.__key);
	}
	static importJSON(serializedNode: SerializedMentionNode): MentionNode {
		const node = $createMentionNode({ name: serializedNode.mentionName, id: '', email: '', avatarURL: '' });

		node.setTextContent(serializedNode.text);
		node.setFormat(serializedNode.format);
		node.setDetail(serializedNode.detail);
		node.setMode(serializedNode.mode);
		node.setStyle(serializedNode.style);
		return node;
	}

	constructor(name: string, id: string, email: string, avatarURL: string, key?: NodeKey) {
		super(name, key);
		this.__mention = name;
		this.__id = id;
		this.__email = email;
		this.__avatarURL = avatarURL;
	}

	exportJSON(): SerializedMentionNode {
		return {
			...super.exportJSON(),
			mentionName: this.__mention,
			type: 'mention',
			version: 1,
		};
	}

	createDOM(config: EditorConfig): HTMLElement {
		const dom = super.createDOM(config);
		dom.className = classes.mentionStyle;

		const tooltip = createTooltip(this);
		dom.appendChild(tooltip);

		return dom;
	}

	exportDOM(): DOMExportOutput {
		const element = document.createElement('span');
		element.setAttribute('data-lexical-mention', 'true');
		element.textContent = this.__text;
		return { element };
	}

	static importDOM(): DOMConversionMap | null {
		return {
			span: (domNode: HTMLElement) => {
				if (!domNode.hasAttribute('data-lexical-mention')) {
					return null;
				}
				return {
					conversion: convertMentionElement,
					priority: LowPriority,
				};
			},
		};
	}

	isTextEntity(): true {
		return true;
	}
}

export function $createMentionNode(mention: MentionsProperties): MentionNode {
	const mentionNode = new MentionNode(mention.name, mention.id, mention.email, mention.avatarURL);
	mentionNode.setMode('segmented').toggleDirectionless();

	return mentionNode;
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
	return node instanceof MentionNode;
}
