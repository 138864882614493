import Box from '@components/Box';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { ErrorRed16 } from '@icons/index';
import colors from 'src/style/colors';

const ERROR_DIVISION_BY_ZERO = 'division by zero';

export const TableError = ({ detailedTableError }: { detailedTableError: string }) => {
	const isDivisionByZeroError = detailedTableError === ERROR_DIVISION_BY_ZERO;

	return (
		<Box p={'24px 32px 0 32px'} mt={'16px'} width={'100%'}>
			<Flex
				padding={'16px 16px 20px 16px'}
				borderRadius={'8px'}
				backgroundColor={'red.100'}
				gap={'16px'}
				justifyContent={'flex-start'}
			>
				<Box pt={'4px'}>
					<ErrorRed16 />
				</Box>
				<Flex flexDirection={'column'} gap={'12px'}>
					<Typography variant={'Paragraph14M'} color={'gray.1000'}>
						{`Data table can not be displayed because ${detailedTableError} occurred in the calculation.`} &nbsp;
						{isDivisionByZeroError && (
							<Typography variant={'Paragraph14R'} color={'gray.1000'}>
								Use NULLIF() to handle zero denominators.
							</Typography>
						)}
					</Typography>
					{isDivisionByZeroError && (
						<Flex flexDirection={'column'} gap={'8px'}>
							<Typography variant={'Paragraph14R'} color={'gray.1000'}>
								Here&apos;s an example of how to fix it:
							</Typography>
							<Flex
								alignItems={'center'}
								p={'8px 12px'}
								borderRadius={'8px'}
								border={`1px solid ${colors.gray[400]}`}
								backgroundColor={'gray.100'}
								mixBlendMode={'multiply'}
								width={'fit-content'}
							>
								<Box
									fontFamily={'Source Code Pro'}
									fontSize={'14px'}
									fontStyle={'normal'}
									fontWeight={'400'}
									lineHeight={'20px'}
									color={'gray.1000'}
								>
									$metric__nominator / NULLIF($metric__denominator,0)
								</Box>
							</Flex>
						</Flex>
					)}
				</Flex>
			</Flex>
		</Box>
	);
};
