import Box from '@components/Box';
import Flex from '@components/Flex';
import { Link } from '@chakra-ui/react';
import Typography from '@components/Typography';
import { METRIC_LIBRARY_URL, openInSourcePlatform } from './utils';
import { useReportEvent } from 'src/services/analytics';
import { CardsIcon } from 'src/common/components/Icons';

export function NoResultsCatalog() {
	const { wrapWithReport } = useReportEvent();
	return (
		<Flex minH={'100%'} direction={'column'} alignItems={'center'} paddingTop={'140px'}>
			<CardsIcon />
			<Box paddingTop={'25px'} paddingBottom={'8px'}>
				<Typography textAlign={'center'} color={'gray.900'} variant={'Paragraph20S'}>
					Metric not found.
				</Typography>
			</Box>
			<Typography maxWidth={'350px'} textAlign={'center'} variant={'Paragraph14RS'} color={'gray.700'}>
				Refine your search, explore our{' '}
				<Link
					textDecoration={'underline'}
					onClick={wrapWithReport(openInSourcePlatform, 'open-website-metrics-library', {
						link: METRIC_LIBRARY_URL,
					})}
				>
					Metric Library
				</Link>
				, or try Metric Builder for a custom solution.
			</Typography>
		</Flex>
	);
}
