import { Box, Popover, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import Flex from '@components/Flex';
import { ReactNode } from 'react';
import { Divider16, Type16 } from 'src/common/components/Icons';
import { WidgetType } from '../../types/DashboardFeedSignal';
import { AddWidgetListItem } from './AddWidgetListItem';

export function AddWidgetPopover({
	children,
	isModalOpen,
	onCloseDialog,
}: {
	children: ReactNode;
	isModalOpen: boolean;
	onCloseDialog: (widgetType?: WidgetType) => void;
}) {
	const { isOpen, onClose: onHookClose } = useDisclosure();

	const onClose = (widgetType?: WidgetType) => {
		onHookClose();
		onCloseDialog(widgetType);
	};

	return (
		<Popover placement="bottom-end" variant="strong" isOpen={isOpen || isModalOpen} onClose={onClose}>
			<PopoverTrigger>
				<Box>{children}</Box>
			</PopoverTrigger>
			<PopoverContent>
				<Flex direction={'column'} paddingY="8px" width={'292px'}>
					<AddWidgetListItem
						icon={<Type16 />}
						title="Text Widget"
						subtitle="Start writing with a text widget."
						onClick={() => {
							onClose('text');
						}}
					/>
					<AddWidgetListItem
						icon={<Divider16 />}
						title="Divider"
						subtitle="Visually divide blocks"
						onClick={() => {
							onClose('divider');
						}}
					/>
				</Flex>
			</PopoverContent>
		</Popover>
	);
}
