import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

function Link({ href, children, ...props }: ChakraLinkProps) {
	return (
		<ChakraLink as={ReactRouterLink} to={href} {...props}>
			{children}
		</ChakraLink>
	);
}

export default Link;
