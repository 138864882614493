import { NotDefinedMetric } from '@icons/index';
import Typography from '@components/Typography';
import Flex from '@components/Flex';

export const NotDefinedMetricPlaceholder = () => {
	return (
		<Flex flexDirection={'column'} alignItems={'center'} justifyContent="center">
			<NotDefinedMetric />

			<Typography
				width={'100%'}
				textAlign={'center'}
				variant={'DesktopH7Regular'}
				color="gray.1000"
				marginTop={'28px'}
				marginBottom={'6px'}
			>
				This metric has missing requirements.
			</Typography>
			<Typography width={'100%'} textAlign={'center'} variant={'DesktopH8Regular'} color="gray.1000">
				Follow the instructions above to complete the setup.
			</Typography>
		</Flex>
	);
};
