import { Box } from '@chakra-ui/react';
import Button from 'src/common/components/Button';
import { BinSolid16, DotsSolid16 } from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import Popover from 'src/common/components/Popover';
import { WorkspaceGetSingleQuery } from 'src/generated/graphql';
import DeleteCollectionModal from 'src/layout/Menu/NavigationDrawer/DeleteCollectionModal';
import { useReportEvent } from 'src/services/analytics';

export function ActionsMenu({
	onDeleteModalOpen,
	workspaceId,
	isDeleteModalOpen,
	onDeleteModalClose,
	workspace,
}: {
	onDeleteModalOpen: VoidFunction;
	workspaceId: string;
	isDeleteModalOpen: boolean;
	onDeleteModalClose: VoidFunction;
	workspace: WorkspaceGetSingleQuery['workspaces'][0];
}) {
	const { wrapWithReport } = useReportEvent();
	return (
		<Box data-intercom-area={'workspace'} data-intercom-type={'button'} data-intercom-target={'ThreeDotMenu'}>
			<Popover
				placement="bottom-end"
				triggerElement={(isOpen) => (
					<Button isIconOnly isActive={isOpen} size="inline" variant="outline" colorScheme="black">
						<DotsSolid16 />
					</Button>
				)}
			>
				<Box paddingY="8px" width={'184px'}>
					<ListItem
						size={'sm'}
						label={'Delete'}
						prefixIcon={<BinSolid16 />}
						onClick={wrapWithReport(onDeleteModalOpen, 'workspace-delete-collection', { workspaceId: workspaceId })}
					/>
				</Box>
			</Popover>
			<DeleteCollectionModal
				isOpen={isDeleteModalOpen}
				onClose={onDeleteModalClose}
				collection={{
					name: workspace.name ?? '',
					id: workspaceId,
					collection_type: 'workspace',
					order: workspace.order,
				}}
				isCollectionInView={true}
			/>
		</Box>
	);
}
