import { ApolloError } from '@apollo/client';
import { utcNow } from '@sightfull/period-ranges';
import useSubscription from 'src/common/hooks/fetching/useSubscription';
import useUser from 'src/common/hooks/stores/useUser';
import {
	GetApplyOverrideTasksSubscription,
	GetApplyOverrideTasksSubscriptionVariables,
	GetLastSyncSubscription,
	GetLastSyncSubscriptionVariables,
} from 'src/generated/graphql';
import { GetApplyOverrideTasks, GetLastSync } from 'src/queries/tasks';

interface GraphQLTask {
	__typename?: 'tasks';
	parameters: string;
	status: string;
	created_at: string;
	started_at?: string;
}

export enum TaskStatusEnum {
	Running = 'Running',
	Pending = 'Pending',
	Done = 'Done',
	Failed = 'Failed',
}

export interface TaskData {
	metric: string;
	status: TaskStatusEnum;
	createdAt: Date;
	startedAt?: Date;
}

const totalExpectedSeconds = 5 * 60;
const defaultMaxPerventageStillRunning = 97;

export function useGetTasks(): { tasks: TaskData[]; isLoading: boolean; error: ApolloError | undefined } {
	const [{ id: userId }] = useUser();
	const {
		data: lastSyncData,
		loading: isLastSyncLoading,
		error: lastSyncError,
	} = useSubscription<GetLastSyncSubscription, GetLastSyncSubscriptionVariables>(GetLastSync);
	const {
		data,
		error,
		loading: isLoading,
	} = useSubscription<GetApplyOverrideTasksSubscription, GetApplyOverrideTasksSubscriptionVariables>(
		GetApplyOverrideTasks,
		{
			variables: { my_id: userId, last_sync: lastSyncData?.tenants?.[0]?.last_etl_synced ?? utcNow() },
			skip: !userId,
		}
	);
	const tasks: TaskData[] = (data?.tasks ?? []).map((graphlq_task: GraphQLTask) => ({
		metric: graphlq_task.parameters,
		status: graphlq_task.status as TaskStatusEnum,
		createdAt: new Date(graphlq_task.created_at),
		startedAt: graphlq_task.started_at ? new Date(graphlq_task.started_at) : undefined,
	}));

	tasks.sort(compareTasks);
	return { tasks: tasks, isLoading: isLastSyncLoading || isLoading, error: lastSyncError || error };
}

export const formatTaskDate = (date: Date) =>
	date.toLocaleTimeString('en-us', {
		month: 'short',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
	});

export function calcProgressByStartDate(startDate: Date | undefined): number {
	let progress = 0;
	if (!startDate) {
		progress = 0;
	} else {
		const runningSeconds = (Date.now() - startDate.getTime()) / 1000;
		progress = (100 * runningSeconds) / totalExpectedSeconds;
		if (progress > defaultMaxPerventageStillRunning) {
			progress = defaultMaxPerventageStillRunning;
		}
	}
	return progress;
}

function compareTasks(taskA: TaskData, taskB: TaskData): number {
	const aIsSmaller = -1;
	const aIsBigger = 1;

	if (taskA.status === TaskStatusEnum.Running && taskB.status !== TaskStatusEnum.Running) {
		return aIsSmaller;
	}
	if (taskA.status !== TaskStatusEnum.Running && taskB.status === TaskStatusEnum.Running) {
		return aIsBigger;
	}
	return taskB.createdAt.getTime() - taskA.createdAt.getTime();
}
