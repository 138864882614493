import { Box } from '@chakra-ui/react';
import { AccessController } from 'src/common/components/AccessController';
import Divider from 'src/common/components/Divider';
import IconButton from 'src/common/components/IconButton';
import { DuplicateSolid16 } from 'src/common/components/Icons';
import Tooltip from 'src/common/components/Tooltip';
import classes from 'src/pages/WorkspacePage/SignalWidget/WorkspaceSignalWidget.module.scss';
import { Permissions } from 'src/types/environment';

export function NoOwnerPermissionActions({ onDuplicateClick }: { onDuplicateClick: () => void }) {
	return (
		<AccessController permission={Permissions.crudMySignals}>
			<Tooltip variant="fluid" size="md" label="Duplicate">
				<IconButton colorScheme="black" ariaLabel="Duplicate" icon={<DuplicateSolid16 />} onClick={onDuplicateClick} />
			</Tooltip>
			<Box height="24px" paddingX="8px" className={classes.workspaceSignalWidgetActions}>
				<Divider direction={'vertical'} />
			</Box>
		</AccessController>
	);
}
