import { atom, useAtom, useAtomValue } from 'jotai';
import * as yaml from 'js-yaml';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { FormulaMetric, Metric, MetricsSchema } from 'src/lib/completions/semanticTypes/metrics.schema';
import { singleMetricToFullYaml } from 'src/models/YamlUtils/yamlUtils';
import { MetricEditorStateAtom } from '../atoms/metricYamlEditorState';
import { MetricEditorLoadedState } from '../utils/editor.types';
import { useMetricDerivedState } from './useMetricDerivedState';

const loadedAtom = atom<MetricEditorLoadedState | null>((get) => {
	const editorAtom = get(MetricEditorStateAtom);
	return !editorAtom.isLoading ? editorAtom : null;
});

const hasChangesFromSavedValueAtom = atom<boolean>((get) => {
	const editorAtom = get(loadedAtom);
	if (!editorAtom || !editorAtom.userDefinedValue) return false;
	const { userDefinedValue, savedValue } = editorAtom;
	return userDefinedValue !== savedValue && savedValue != '';
});

//TODO: Fix preview to add the metric or formula key
const hasChangesToPreviewAtom = atom<boolean>((get) => {
	const editorAtom = get(loadedAtom);
	const hasChangedFromSavedValue = get(hasChangesFromSavedValueAtom);
	if (!editorAtom || !editorAtom.userDefinedValue) return false;
	const { userDefinedValue } = editorAtom;

	const { previewValue, kind } = editorAtom;
	if (previewValue == '' && hasChangedFromSavedValue) return true;
	if (previewValue != '' && singleMetricToFullYaml(userDefinedValue, kind) != previewValue) return true;

	return false;
});

const isSaveAllowedAtom = atom<boolean>((get) => {
	const hasChangesFromSavedValue = get(hasChangesFromSavedValueAtom);
	const hasChangesToPreview = get(hasChangesToPreviewAtom);

	return hasChangesFromSavedValue && !hasChangesToPreview;
});

const latestEditorValueAtom = atom<string | undefined>((get) => {
	const editorAtom = get(loadedAtom);
	return editorAtom?.userDefinedValue || editorAtom?.savedValue;
});

const previewValueAtom = atom<string>((get) => {
	const editorAtom = get(loadedAtom);
	return editorAtom?.previewValue ?? '';
});

const previewValueForCalculationOnlyAtom = atom<MetricsSchema | undefined>((get) => {
	// This is done to make sure we do not send to the server the meta field
	const previewValue = get(previewValueAtom);
	const editorAtom = get(loadedAtom);
	if (!editorAtom || !previewValue) return undefined;

	const { savedValue } = editorAtom;

	const loadedYaml = yaml.load(previewValue) as MetricsSchema;
	const savedYaml = yaml.load(savedValue) as Metric | FormulaMetric;

	const cleanedPreviewYaml = omit(loadedYaml, 'metrics[0].meta');
	const cleanedSavedYaml = omit(savedYaml, 'meta');
	if (isEqual(cleanedPreviewYaml.metrics?.at(0), cleanedSavedYaml)) return undefined;

	return cleanedPreviewYaml;
});

export function useMetricEditorState() {
	const [metricEditorState, setMetricEditorState] = useAtom(MetricEditorStateAtom);
	const hasChangesFromSavedValue = useAtomValue(hasChangesFromSavedValueAtom);
	const hasChangesToPreview = useAtomValue(hasChangesToPreviewAtom);
	const isSaveAllowed = useAtomValue(isSaveAllowedAtom);
	const metricEditorLoadedState = useAtomValue(loadedAtom);
	const previewValueForCalculationOnly = useAtomValue(previewValueForCalculationOnlyAtom);
	const latestEditorValue = useAtomValue(latestEditorValueAtom);
	const previewValue = useAtomValue(previewValueAtom);
	const { errorMessage } = useMetricDerivedState();

	return {
		metricEditorState,
		metricEditorLoadedState,
		setMetricEditorState,
		hasChangesFromSavedValue,
		hasChangesToPreview,
		isSaveAllowed: isSaveAllowed && !errorMessage,
		latestEditorValue,
		previewValue,
		previewValueForCalculationOnly,
	};
}
