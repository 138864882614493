import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { OperationsAPIParamsType } from '../statisticOperations/types';
import { getOperationInfo } from '../statisticOperations/utils';

export const calcStatisticsParams = ({
	statisticsOperations,
}: Pick<MetricDerivedState, 'statisticsOperations'>): OperationsAPIParamsType[] => {
	return statisticsOperations
		.map((statisticOperation) =>
			statisticOperation.isChecked
				? getOperationInfo(statisticOperation, statisticOperation.options[statisticOperation.selectedOptionIndex].value)
				: undefined
		)
		.filter((value) => value != undefined);
};
