import { ApolloError, FetchPolicy, QueryResult, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import {
	EntityDimensionValuesQuery,
	EntityDimensionValuesQueryVariables,
	RelationshipsAndDimensionsQuery,
	RelationshipsAndDimensionsQueryVariables,
} from 'src/generated/graphql';
import { EntityDimensionValues, RelationshipsAndDimensions } from 'src/queries/core-reader';

export type CoreReaderFiltersCallback = (objects: string[]) => Promise<CoreReaderEntityResult[] | undefined>;

export function useCoreReaderFiltersApi(fetchPolicy: FetchPolicy): [
	{
		loading: boolean;
		data?: RelationshipsAndDimensionsQuery;
		error?: ApolloError;
	},
	CoreReaderFiltersCallback
] {
	const [lazyCallApi, { data, loading, error }] = useLazyQuery<
		RelationshipsAndDimensionsQuery,
		RelationshipsAndDimensionsQueryVariables
	>(RelationshipsAndDimensions);

	const callApi = useCallback(
		(objects: string[]): Promise<CoreReaderEntityResult[] | undefined> => {
			return lazyCallApi({
				variables: {
					entities: objects,
				},
				fetchPolicy,
			}).then((res) => res.data?.relationshipsAndDimensions.entities as CoreReaderEntityResult[]);
		},
		[lazyCallApi, fetchPolicy]
	);

	return [{ data, loading, error }, callApi];
}

export type CoreReaderDataModelDimension = {
	name: string;
	description: string;
	identifier: string;
	is_normalized?: boolean;
	entity: string;
	type: 'string' | 'datetime' | 'float' | 'relationship';
};
export type CoreReaderEntityResult = {
	dimensions: CoreReaderDataModelDimension[];
	relationships: CoreReaderDataModelDimension[];
	entityName: string;
};

export type CoreReaderDimensionValuesApiCallback = (entity: string, dimension: string) => Promise<(string | null)[]>;

const FilterValuesMax = 110000;
export function useCoreReaderDimensionValuesApi(): [
	Pick<QueryResult<EntityDimensionValuesQuery, EntityDimensionValuesQueryVariables>, 'data' | 'loading' | 'error'>,
	CoreReaderDimensionValuesApiCallback
] {
	const [lazyCallApi, { data, loading, error }] = useLazyQuery<
		EntityDimensionValuesQuery,
		EntityDimensionValuesQueryVariables
	>(EntityDimensionValues);

	const callApi = useCallback(
		(entity: string, dimension: string): Promise<any> => {
			return lazyCallApi({
				variables: {
					entity,
					dimension,
					itemsPerPage: FilterValuesMax,
				},
			}).then((res) => res.data?.entityDimensionValues ?? []);
		},
		[lazyCallApi]
	);

	return [{ data, loading, error }, callApi];
}
