import { categoriesWithBrokenMetrics } from '../types';
import { useMemo } from 'react';
import { AddBadgeDrawer } from '../utils';

export const useCategoriesWithBrokenMetrics = () => {
	const [categoriesWithBrokenMetrics, updateBrokenCategories] = useMemo(() => {
		const categoriesWithBrokenMetrics: categoriesWithBrokenMetrics[] = [];
		const updateBrokenCategories = (categoryName: string, metricName: string) => {
			if (
				categoriesWithBrokenMetrics.some(
					(el) => el.categoryName === categoryName && el.metricNames.includes(metricName)
				)
			) {
				return;
			}
			const categoryIndex = categoriesWithBrokenMetrics.findIndex((el) => el?.categoryName === categoryName);
			if (categoryIndex !== -1) {
				categoriesWithBrokenMetrics[categoryIndex].metricNames.push(metricName);
			} else {
				categoriesWithBrokenMetrics.push({ categoryName, metricNames: [metricName] });
			}
			AddBadgeDrawer(categoryName);
		};
		return [categoriesWithBrokenMetrics, updateBrokenCategories];
	}, []);

	return { categoriesWithBrokenMetrics, updateBrokenCategories };
};
