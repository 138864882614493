import Box from '@components/Box';
import Typography from 'src/common/components/Typography';

export function TypeTooltip() {
	return (
		<Box maxW={'260px'}>
			<Typography marginBottom={'8px'} color={'white'} variant="Paragraph14R">
				The relationship type:
			</Typography>
			<Box paddingLeft={'20px'}>
				<Typography display={'list-item'} color={'white'} variant="Paragraph14R">
					A Many-to-one relationship means that multiple rows in this entity can point to the same row in the reference
					entity.
				</Typography>
				<Typography display={'list-item'} color={'white'} variant="Paragraph14R">
					A One-to-many relationship means that one row in this entity can map to multiple rows in the reference entity.
				</Typography>
			</Box>
		</Box>
	);
}
