import { analyticsMetadata } from 'src/common/types/searchEntity';
import { TABLE_PAGINATION_LIMIT, TABLE_PAGINATION_LIMIT_ALL_TAB } from './consts';

export function resultsPageMetadata(isAllSearch: boolean) {
	return { ...analyticsMetadata, isSpecificObjectSearch: !isAllSearch };
}

export function getTablePaginationLimit(isAllChosen: boolean) {
	return isAllChosen ? TABLE_PAGINATION_LIMIT_ALL_TAB : TABLE_PAGINATION_LIMIT;
}
