import { CircularProgress } from '@chakra-ui/react';
import { isAfter } from 'date-fns';
import { useCallback, useMemo } from 'react';
import Button from 'src/common/components/Button';
import { Loading16 } from 'src/common/components/Icons';
import useToast from 'src/common/hooks/ui/useToast';
import {
	useApplyOverridesToMetricMutation,
	useCanApplyOverridesToMetricOverridesSubscription,
	useCanApplyOverridesToMetricTasksSubscription,
} from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';
import { tenantConfigSelectors, useTenantConfigStore } from 'src/stores/tenantConfig';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';

export function ApplyOverridesButton() {
	const { reportEvent } = useReportEvent();
	const { metricNameWithoutFlavor } = useMetricDerivedState();
	const showToast = useToast();
	const lastEtlSync = useTenantConfigStore(tenantConfigSelectors.getLastEtlSync);
	const [applyOverridesMutation, { loading }] = useApplyOverridesToMetricMutation();
	const { data: overridesResponse } = useCanApplyOverridesToMetricOverridesSubscription();
	const { data: tasksResponse } = useCanApplyOverridesToMetricTasksSubscription({
		variables: { metric_name: metricNameWithoutFlavor, last_sync: lastEtlSync },
	});
	const { isHavingOneOfPermissions } = usePermissionCheck();

	const canApplyOverrides = useMemo(() => {
		const lastPendingOverride = overridesResponse?.pending_overrides?.aggregate?.max?.updated_at;
		const lastMetricTask = tasksResponse?.metric_tasks?.aggregate?.max?.created_at;
		if (!lastPendingOverride) return false;

		if (!lastMetricTask) return true;

		return isAfter(new Date(lastPendingOverride), new Date(lastMetricTask));
	}, [overridesResponse?.pending_overrides, tasksResponse?.metric_tasks?.aggregate?.max?.created_at]);

	const applyOverrides = useCallback(() => {
		if (loading) return;
		reportEvent({
			event: 'metric-apply-overrides-pressed',
			metaData: {
				metric: metricNameWithoutFlavor,
			},
		});
		applyOverridesMutation({ variables: { metric_name: metricNameWithoutFlavor } }).then((value) => {
			const isDone = value.data?.apply_overrides?.msg == 'Done';
			if (isDone) {
				showToast({ message: 'A new upload has begun.', variant: 'info' });
			} else {
				showToast({ message: 'Something went wrong.', variant: 'error' });
			}
		});
	}, [applyOverridesMutation, loading, metricNameWithoutFlavor, reportEvent, showToast]);

	if (!canApplyOverrides || !isHavingOneOfPermissions(['sf.data.override.delete', 'sf.data.override.create']))
		return null;

	if (loading)
		return (
			<CircularProgress isIndeterminate color="black" size="20px" padding="6px" key="text-signal-loading-indicator" />
		);

	return (
		<Button
			variant="outline"
			colorScheme={'black'}
			size="inline"
			onClick={applyOverrides}
			leftIcon={<Loading16 />}
			mr="12px"
		>
			Apply overrides
		</Button>
	);
}
