import Typography from 'src/common/components/Typography';
import { ExampleText } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components/BuilderTooltips/common';
import {
	LearnMore,
	LearnMoreProps,
} from 'src/pages/MetricPage/components/CalculatePanel/Builder/components/BuilderTooltips/common/LearnMore';
import { METRIC_TYPES_LINK } from '../../utils/consts';

export function FilterTooltip({ onHelpClicked }: { onHelpClicked: LearnMoreProps['onClick'] }) {
	return (
		<Typography color={'white'} variant="Paragraph14R">
			Select a specific subset of data from the source table for the entity.&nbsp;
			<LearnMore onClick={onHelpClicked} link={METRIC_TYPES_LINK} />.
			<ExampleText marginTop={'16px'} text={'$src__is_test_account = false'} />
			<ExampleText marginTop={'8px'} marginBottom={'4px'} text={'$src__is_deleted = false'} />
		</Typography>
	);
}
