import { Box, Center } from '@chakra-ui/react';
import { LogoBlack } from '../Icons';
import { TenantPicker } from './TenantPicker';

export function TenantPickerPage() {
	return (
		<Box backgroundColor={'white'}>
			<Box position={'absolute'} top="24px" left="24px">
				<LogoBlack />
			</Box>
			<Center height={'100vh'} width="100vw">
				<Box width={'474px'} borderRadius="4px" border={'1px solid'} borderColor="gray.400" overflow="clip">
					<TenantPicker isDisclosingTenantNamesDefault={false} />
				</Box>
			</Center>
		</Box>
	);
}
