import Flex from '@components/Flex';
import Button from '@components/Button';
import { AddLarge, AppPanel } from '@icons/index';
import Tooltip from '@components/Tooltip';
import colors from 'src/style/colors';
import { SpacesNavigationDrawer } from '@components/LeftSidePanelV2/SpacesNavigationDrawer';
import { useReportEvent } from 'src/services/analytics';
import { useModal } from 'src/common/hooks/ui/useModal';
import { TestIDs } from 'src/common/types/test-ids';

export function DashboardWorkspacePanel({
	handleCollapsePanelClick,
	isCollapsed = false,
	setIsPanelActingLikeOnHover,
}: {
	handleCollapsePanelClick: VoidFunction;
	isCollapsed?: boolean;
	setIsPanelActingLikeOnHover: (isOpen: boolean) => void;
}) {
	const { reportEvent } = useReportEvent();
	const { isOpen: isCreateModalOpen, onOpen: onCreateModalOpen, onClose: onCreateModalClose } = useModal();
	const isWorkspacePage = location.pathname.includes('/workspace');
	const isSpacesPage = location.pathname === '/' || location.pathname.includes('/dashboard') || isWorkspacePage;

	const getAddButtonTestId = () => {
		if (isSpacesPage) {
			const collectionType = isWorkspacePage ? 'workspace' : 'dashboard';

			return TestIDs.SIDEBAR_FOOTER_ADD_NEW_BUTTON(collectionType);
		}
	};

	return (
		<>
			<Flex justifyContent={'space-between'} mb={'8px'}>
				<Tooltip size="md" variant="fluid" label={'Create new'} placement={'right'} marginLeft={'6px'}>
					<Button
						size={'small'}
						variant={'outline'}
						colorScheme={'gray'}
						onClick={() => {
							reportEvent({ event: 'left-panel-add-button-clicked' });
							onCreateModalOpen();
						}}
						isIconOnly
						_hover={{ bgColor: colors.buttonPanelItemColor }}
						mixBlendMode={'multiply'}
						data-testid={getAddButtonTestId()}
					>
						<AddLarge color={colors.gray['900']} />
					</Button>
				</Tooltip>

				<Tooltip
					size="md"
					variant="fluid"
					label={isCollapsed ? 'Expand' : 'Collapse'}
					placement={'right'}
					marginLeft={'6px'}
				>
					<Button
						size={'small'}
						variant={'outline'}
						colorScheme={'gray'}
						onClick={handleCollapsePanelClick}
						isIconOnly={true}
						bgColor={!isCollapsed ? 'gray.200' : 'transparent'}
						_hover={{ bgColor: colors.buttonPanelItemColor }}
						mixBlendMode={'multiply'}
					>
						<AppPanel color={isCollapsed ? colors.gray['900'] : colors.gray['1000']} />
					</Button>
				</Tooltip>
			</Flex>

			<Flex flexDirection={'column'} flex={1} overflowY={'auto'}>
				{isSpacesPage && (
					<SpacesNavigationDrawer
						isCreateEditModalOpen={isCreateModalOpen}
						onCreateEditModalOpen={onCreateModalOpen}
						onCreateEditModalClose={onCreateModalClose}
						setIsPanelActingLikeOnHover={setIsPanelActingLikeOnHover}
						isParentPanelCollapsed={isCollapsed}
					/>
				)}
			</Flex>
		</>
	);
}
