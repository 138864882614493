import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Flex from 'src/common/components/Flex';
import Typography from 'src/common/components/Typography';
import { TypographyVariant } from 'src/common/components/Typography/types';
import { useOnOverflow } from 'src/common/hooks/ui/useOnOverflow';
import { IFieldValue, IModal } from '../../interfaces/fields';
import { ModalSingleValue } from '../../interfaces/types';

export function ModalValuesLiner({
	normalizedField,
	value,
	variant,
	maxWidth,
}: {
	normalizedField: IModal;
	value: IFieldValue;
	variant: TypographyVariant;
	maxWidth: string;
}) {
	const [values, setValues] = useState<ModalSingleValue[]>([]);
	const valuesRef = useRef<HTMLDivElement | null>(null);
	const isOverflowing = useOnOverflow(valuesRef, []);

	const [shownValuesCount, setShownValuesCount] = useState<number>(0);

	useEffect(() => {
		if (value === '') {
			return;
		}

		const values = Array.isArray(value) ? (value as ModalSingleValue[]) : ([value] as ModalSingleValue[]);
		setValues(values);
		setShownValuesCount(values.length);
	}, [value, normalizedField?.modalType]);

	useLayoutEffect(() => {
		if (isOverflowing) {
			setShownValuesCount(shownValuesCount - 1);
		}
	}, [isOverflowing, shownValuesCount]);

	return (
		<Flex direction="row" gap="6px">
			<Typography variant={variant} maxWidth={maxWidth} noOfLines={1} color={'gray.700'} _hover={{ cursor: 'pointer' }}>
				<div ref={valuesRef}>
					{values
						.slice(0, shownValuesCount)
						.map((v) => v.toString())
						.join(', ')}
					{shownValuesCount < values.length && ` +${values.length - shownValuesCount} more`}
				</div>
			</Typography>
		</Flex>
	);
}
