import { editor, Position, Range } from 'monaco-editor';
import { CompletionProvider } from '../completionProvider';
import { FormulaMetric, LoadedEntities, Metric, SemanticDefinitions } from '../semanticTypes';

export function rangeToPreviousCharacter(range: Range): Range {
	return new Range(range.startLineNumber, Math.max(1, range.startColumn - 1), range.endLineNumber, range.startColumn);
}

export function rangeFromPosition(position: Position): Range {
	return new Range(position.lineNumber, position.column, position.lineNumber, position.column);
}

export function getWordRangeAtPosition(model: editor.ITextModel, position: Position, regex: RegExp): Range | undefined {
	const zeroBasedColumn = position.column - 1;
	let value = model.getLineContent(position.lineNumber);
	let offset = 0;
	let match;
	while ((match = regex.exec(value)) !== null) {
		if (zeroBasedColumn > offset + match.index && zeroBasedColumn <= offset + match.index + match[0].length) {
			return new Range(
				position.lineNumber,
				offset + match.index + 1,
				position.lineNumber,
				offset + match.index + match[0].length + 1
			);
		}
		offset += match.index + match[0].length;
		value = value.substring(match.index + match[0].length);
	}
}

export function getPartAndExpressionRanges({ model, position }: { model: editor.ITextModel; position: Position }) {
	const partRange =
		getWordRangeAtPosition(model, position, CompletionProvider.EXPRESSION_PART) ?? rangeFromPosition(position);
	const expressionRange =
		getWordRangeAtPosition(model, position, CompletionProvider.EXPRESSION) ?? rangeFromPosition(position);
	const expression = expressionRange ? model.getValueInRange(expressionRange) : '';
	return { partRange, expressionRange, expression };
}

// Shamelessly copied from sightfull-core ..
export function getEntity<TSchema extends LoadedEntities | SemanticDefinitions>(
	schema: TSchema,
	entityName: string
): TSchema['entities'][number] | undefined {
	const entity = schema.entities.find((e) => e.name === entityName);

	if (!entity) {
		console.error(`Entity ${entityName} not found`);
	}

	return entity;
}

export function getMetricByNameFromList<T extends Metric | FormulaMetric>(
	metrics: T[] | undefined,
	metricName: string
): T | undefined {
	const matchingMetrics = metrics?.filter((m) => m.name === metricName) ?? [];

	if (matchingMetrics.length > 1) {
		throw new Error(
			`More than one metric definition was found ${JSON.stringify({
				metric: metricName,
				matchingMetrics,
			})}`
		);
	}

	if (matchingMetrics.length == 0) {
		return undefined;
	}

	return matchingMetrics[0];
}
