import { useMemo } from 'react';
import IconButton from 'src/common/components/IconButton';
import { EditFull16 } from 'src/common/components/Icons';
import Tooltip from 'src/common/components/Tooltip';
import { useModal } from 'src/common/hooks/ui/useModal';
import colors from 'src/style/colors';
import { FeedSignal } from 'src/types/spaces';
import { EditWorkspaceSignalModal } from '../../components/EditWorkspaceSignalModal/EditWorkspaceSignalModal';
import { migrateSignalTitle } from './utils';

export function SignalEdit({ signal }: { signal: FeedSignal }) {
	const { isOpen, onOpen, onClose } = useModal();
	const migratedSignalTitle = useMemo(
		() => migrateSignalTitle({ title: signal.signal.title, message: signal.signal.message }) ?? '',
		[signal.signal.message, signal.signal.title]
	);

	return (
		<>
			<Tooltip bg="black" color={colors.gray[100]} variant="fluid" size="md" label="Edit">
				<IconButton colorScheme="black" onClick={onOpen} icon={<EditFull16 />} ariaLabel="Edit" />
			</Tooltip>
			<EditWorkspaceSignalModal
				isOpen={isOpen}
				onClose={onClose}
				signal={signal.signal}
				workspaceId={signal.feed_id}
				intialTitle={migratedSignalTitle}
			/>
		</>
	);
}
