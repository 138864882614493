import { ColumnState } from 'ag-grid-community';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

export const defaultColumnState: ColumnState = {
	colId: '',
	hide: false,
	width: 0,
	flex: null,
	sort: null,
	sortIndex: null,
	aggFunc: null,
	pivot: false,
	pivotIndex: null,
	pinned: null,
	rowGroup: false,
	rowGroupIndex: null,
};

export function calcTableColumnState(
	searchParamsTableColumnState: MetricSearchParams['tableColumnState']
): Pick<MetricDerivedState, 'tableColumnState'> {
	return {
		tableColumnState: searchParamsTableColumnState?.map((columnState) => ({
			...defaultColumnState,
			...columnState,
		})),
	};
}
