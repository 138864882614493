import { ApolloError } from '@apollo/client';
import { DashboardGetSingleQuery } from 'src/generated/graphql';
import { DashboardGetSingle, DashboardGetSingleByOrder } from 'src/queries/dashboards';
import useUser from '../stores/useUser';
import { useHasuraSubscriptionWithCache } from './useSubscription';

export function useGetDashboardSubscription({
	id,
}: {
	id: string | undefined;
}): [DashboardGetSingleQuery | undefined, ApolloError | undefined, boolean] {
	const [{ id: my_id }] = useUser();

	const document = id ? DashboardGetSingle : DashboardGetSingleByOrder;
	const variables = id ? { id, my_id } : { offset: 0, my_id };

	const { data, error, loading } = useHasuraSubscriptionWithCache(document, {
		variables,
	});

	return [data, error, loading];
}
