import { Box, Flex } from '@chakra-ui/react';
import Avatar from 'src/common/components/Avatar/Avatar';
import { ChevronRightSolid16 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import styles from './Notification.module.scss';

type NotificationProps = {
	isNew?: boolean;
	name: string;
	actionText?: string;
	subtitle: string;
	imageUrl?: string;
	onClick?: VoidFunction;
};

export function Notification({ isNew, name, actionText, subtitle, imageUrl, onClick }: NotificationProps) {
	return (
		<button onClick={onClick} style={{ width: '100%' }}>
			<Box className={`${styles.container} ${isNew && styles.new}`}>
				<Flex>
					<Box marginRight="12px">
						<Avatar imageUrl={imageUrl ?? ''} name={name} shape="round" />
					</Box>

					<Flex alignItems={'start'} direction="column">
						<Typography color="gray.800" variant="DesktopH8Regular">
							<Typography color="gray.1000" variant="DesktopH8Medium">
								{name}
							</Typography>
							&nbsp;
							{actionText}
						</Typography>
						<Box>
							<Typography variant="Paragraph12R" color="gray.700">
								{subtitle}
							</Typography>
						</Box>
					</Flex>
				</Flex>
				{onClick && <ChevronRightSolid16 />}
			</Box>
		</button>
	);
}
