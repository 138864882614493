import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import useStatisticsOperations from 'src/pages/MetricPage/hooks/useStatisticsOperations';
import { DisplayLegendItems } from 'src/pages/MetricPage/utils/state.types';
import * as CSS from 'csstype';
import Box from '../Box';
import Center from '../Center';
import { ChartLoader } from '../Chart/ChartLoader';
import { BubbleSeries, ChartOptions, ChartSeries } from '../Chart/types';
import { BigBoldNumber } from './BigBoldNumber';
import { BigBoldAttainmentNumber } from './BigBoldAttainmentNumber';
import { isMetricUnitType } from 'src/types/metric';

function extractTargetAttainment(
	chartOptions: ChartOptions,
	targetAttainmentSeries?: ChartSeries
): {
	targetAttainmentValue: string;
	actualValue: string;
} {
	if (!(chartOptions?.series.length >= 2) || targetAttainmentSeries == undefined) {
		return { targetAttainmentValue: '', actualValue: '' };
	}

	const targetAttainmentValue = targetAttainmentSeries?.custom.seriesDataPointYFormatter?.(
		targetAttainmentSeries?.data[0].y
	);

	const actualSeries = chartOptions.series[1];
	const actualValue = actualSeries.custom.seriesDataPointYFormatter?.(actualSeries.data[0].y);

	return {
		targetAttainmentValue: targetAttainmentValue ?? '',
		actualValue: actualValue ?? '',
	};
}

export function BigBoldNumberChart({
	displayedLegendItems,
	growth,
	delta,
	height,
}: {
	displayedLegendItems: DisplayLegendItems;
	growth?: BubbleSeries;
	delta?: BubbleSeries;
	height?: CSS.Property.Height;
}) {
	const [statisticOperationState] = useStatisticsOperations();
	const { objectsTypes, isPartiallyLoadingChart, chartOptions } = useMetricDerivedState();

	const target = chartOptions.series.find((option) => option.custom.isTarget && option.visible);
	const targetValue = target?.custom.seriesDataPointYFormatter?.(target.data?.[0]?.y);

	const targetAttainmentSeries = chartOptions.series.find((series) => series.chartType == 'attainment');
	const isTargetAttainment = targetAttainmentSeries != undefined;
	const { targetAttainmentValue, actualValue } = extractTargetAttainment(chartOptions, targetAttainmentSeries);

	const countValue = chartOptions.series?.[0].data?.[0].custom?.count;

	const value = chartOptions.series[0]?.data?.find((item) => item)?.y;
	const option = chartOptions.series.find((item) => item);
	let convertedValue = option?.custom?.seriesDataPointYFormatter?.(value) || 'No data';

	if (option?.name && !displayedLegendItems.selectedValues.includes(option.name)) {
		return null;
	}

	if (convertedValue === '0') {
		const unit = isMetricUnitType(option?.custom.unit) ? option?.custom.unit : '';
		convertedValue = `${unit}0`;
	}

	if (option?.custom.unit === '#') {
		convertedValue = `#${convertedValue}`;
	}
	return (
		<>
			{isPartiallyLoadingChart && (
				<Center position={'absolute'} zIndex={1} margin={'auto'} left={0} right={0} top={0} bottom={100}>
					<ChartLoader />
				</Center>
			)}
			<Box
				opacity={isPartiallyLoadingChart ? '20%' : '100%'}
				height={height}
				width={'100%'}
				transition={'all 0.15s ease-in-out'}
			>
				{isTargetAttainment ? (
					<BigBoldAttainmentNumber targetValue={targetAttainmentValue} title={option?.name} actualValue={actualValue} />
				) : (
					<BigBoldNumber
						countData={
							countValue
								? {
										countValue,
										countName: objectsTypes?.[0],
								  }
								: undefined
						}
						deltaPeriod={statisticOperationState.find((stat) => stat.name === 'Delta')}
						growthPeriod={statisticOperationState.find((stat) => stat.name === 'Growth')}
						targetValue={targetValue}
						growth={growth}
						delta={delta}
						title={option?.name}
						value={convertedValue}
					/>
				)}
			</Box>
		</>
	);
}
