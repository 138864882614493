import { fontFamily, styleByVariant } from 'src/common/components/Typography/Typography';
import colors from 'src/style/colors';

export default {
	fontFamily: fontFamily,
	sizes: {
		inline: {
			field: {
				height: '32px',
				...styleByVariant['DesktopH8Regular'],
			},
			element: {
				height: '32px',
				...styleByVariant['DesktopH8Regular'],
			},
		},
		sm: {
			field: {
				height: '36px',
				...styleByVariant['DesktopH8Regular'],
			},
			element: {
				height: '36px',
				...styleByVariant['DesktopH8Regular'],
			},
		},
		md: {
			field: {
				height: '42px',
				...styleByVariant['DesktopH7Regular'],
			},
			element: {
				height: '42px',
				...styleByVariant['DesktopH7Regular'],
			},
		},
		lg: {
			field: {
				height: '48px',
				...styleByVariant['Paragraph20R'],
			},
			element: {
				height: '48px',
				...styleByVariant['Paragraph20R'],
			},
		},
	},
	variants: {
		normal: {
			field: {
				color: colors.gray['1000'],
				borderRadius: '8px',
				border: `1px solid ${colors.gray['400']}`,
				_hover: {
					borderColor: colors.gray['500'],
				},
				_placeholder: {
					color: colors.gray['600'],
				},
				_focus: {
					_placeholder: {
						color: colors.gray['500'],
					},
					borderColor: colors.gray['600'],
				},
				_focusVisible: {
					boxShadow: 'none',
					borderColor: colors.gray['600'],
				},
			},
			element: {
				color: colors.gray['700'],
			},
		},
	},
};
