import { useReportEvent } from 'src/services/analytics';

export const openEntityDrawerOrigins = [
	'detailed-table',
	'related-entities-table',
	'property-table',
	'data-hygiene',
	'search-results',
	'ontology-table',
	'record-profile-one-to-many',
	'record-profile-many-to-one',
] as const;
export type OpenEntityDrawerOrigins = typeof openEntityDrawerOrigins[number];

export const useEntityProfileReportEvent = (section?: string, nodeLabel?: string) => {
	const { reportEvent, wrapWithReport } = useReportEvent({
		feature: 'Entity Drawer',
		...(nodeLabel && { nodeLabel }),
		...(section && { section }),
	});

	const reportOpenEntityDrawer = ({
		origin,
		id,
		entity,
		metaData,
	}: {
		origin: OpenEntityDrawerOrigins;
		id: string;
		entity?: string;
		metaData?: object;
	}) => {
		reportEvent({ event: 'record-drawer-opened', metaData: { origin, id, entity, ...metaData } });
	};

	return { reportEvent, wrapWithReport, reportOpenEntityDrawer };
};
