import { ReactNode } from 'react';
import NavigationAvatarMenu from './NavigationAvatarMenu';
import NavigationItemFrame from './NavigationItemFrame';

function NavigationAvatar({
	icon,
	isTopNavigationMenuOpen,
	setIsTopNavigationMenuOpen,
}: {
	icon: ReactNode;
	isTopNavigationMenuOpen: boolean;
	setIsTopNavigationMenuOpen: (value: boolean) => void;
}) {
	return (
		<NavigationAvatarMenu
			isTopNavigationMenuOpen={isTopNavigationMenuOpen}
			setIsTopNavigationMenuOpen={setIsTopNavigationMenuOpen}
		>
			<NavigationItemFrame variant="avatar" icon={icon} />
		</NavigationAvatarMenu>
	);
}

export default NavigationAvatar;
