import Flex from '@components/Flex';
import Button from '@components/Button';
import { CloseLarge16 } from '@icons/index';
import colors from 'src/style/colors';
import Typography from '@components/Typography';
import shadows from 'src/style/shadows';

export function DataConnectorsModalHeader({ onClose }: { onClose: () => void }) {
	return (
		<Flex
			padding={'16px'}
			display={'flex'}
			alignItems={'center'}
			alignSelf={'stretch'}
			justifyContent={'center'}
			height={'64px'}
			boxShadow={shadows.borderBottom}
		>
			<Flex position={'absolute'} left={'16px'}>
				<Button isIconOnly={true} onClick={onClose} variant="outline" size={'small'} colorScheme={'gray'}>
					<CloseLarge16 color={colors.gray['900']} />
				</Button>
			</Flex>
			<Flex>
				<Typography variant={'DesktopH7Medium'} color={colors.gray['1000']} textAlign={'center'}>
					Add new data source
				</Typography>
			</Flex>
		</Flex>
	);
}
