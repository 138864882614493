import { useMemo } from 'react';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import { useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';

export interface rulesEngineInput {
	metricNameWithFlavor: string;
	metricNameWithoutFlavor: string;
	metricFlavor?: string;
	searchParams: MetricSearchParams;
	dangerousRawSearchParams: URLSearchParams;
}

export function useRulesEngineInput(): { rulesInput: rulesEngineInput } {
	const { searchParams, metricNameWithoutFlavor, metricFlavor, metricNameWithFlavor, dangerousRawSearchParams } =
		useMetricPageSearchParams();
	const stringifiedSearchParams = JSON.stringify(searchParams);

	// TODO: fix the searchparams problem that cases renders instead of memo the value
	const rulesInput = useMemo(
		() => {
			return { searchParams, metricNameWithFlavor, metricNameWithoutFlavor, metricFlavor, dangerousRawSearchParams };
		},

		// We use stringifiedSearchParams instead of searchParams for tighter checking
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[metricFlavor, metricNameWithFlavor, metricNameWithoutFlavor, stringifiedSearchParams]
	); // TODO: this is probably not needed anymore
	return { rulesInput };
}
