import Box from '@components/Box';
import Typography from '@components/Typography';
import { useParameters } from '@services/useParameters';
import { AppliedParameterValue } from '../../../../common/components/Chart/types';
import { useMemo } from 'react';

export function ComponentNameWithParamsTooltipContent({
	component,
}: {
	component: { label?: string; value?: string; appliedParameters?: AppliedParameterValue[] };
}) {
	const { getParameterDisplayNameWithValue } = useParameters();
	const hasAppliedParameters = useMemo(
		() => component.appliedParameters && component.appliedParameters.length > 0,
		[component.appliedParameters]
	);
	const componentText = useMemo(() => component.label ?? component.value, [component]);
	const { titleColor, bodyColor } = useMemo(() => {
		return {
			titleColor: componentText ? 'white' : 'gray.500',
			bodyColor: componentText ? 'gray.500' : 'white',
		};
	}, [componentText]);
	const showParametersHeader = !componentText && hasAppliedParameters;

	return (
		<>
			{showParametersHeader && (
				<Box paddingLeft={'4px'}>
					<Typography variant={'Tags10B'} color={titleColor}>
						Parameters ({component.appliedParameters?.length}):
					</Typography>
				</Box>
			)}
			<Typography variant={'Paragraph14R'} padding={'4px'}>
				{componentText && (
					<Box color={titleColor} maxWidth={'256px'}>
						{componentText}
					</Box>
				)}
				{component.appliedParameters?.map((param) => (
					<Box color={bodyColor} key={param.key}>
						{getParameterDisplayNameWithValue(param.key, param.value)}
					</Box>
				))}
			</Typography>
		</>
	);
}
