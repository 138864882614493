import Box from 'src/common/components/Box';
import Flex from 'src/common/components/Flex';
import { ManyToOne16 } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';
import { Join } from 'src/pages/MetricPage/utils/editor.types';

export function JoinCard({ join }: { join: Join }) {
	return (
		<Box borderRadius={'8px'} border={'1px'} borderColor={'gray.300'} padding="12px">
			<Flex direction={'row'} color="black" gap="12px">
				<ManyToOne16 />
				<Flex direction={'column'} gap={'6px'}>
					<Typography variant="DesktopH8Medium" color={'gray.1000'}>
						{join.alias}
					</Typography>
					<Flex direction={'row'} alignItems={'center'} gap={'4px'}>
						<Typography variant="DesktopH10Regular" color={'gray.700'}>
							{join.from}
						</Typography>
						<Box width="2px" height="2px" borderRadius="100%" backgroundColor={'gray.700'} />
						<Typography variant="DesktopH10Regular" color={'gray.700'}>
							{join.select}
						</Typography>
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
}
