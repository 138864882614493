import Typography from 'src/common/components/Typography';
import { ExampleText } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components/BuilderTooltips/common';
import {
	LearnMore,
	LearnMoreProps,
} from 'src/pages/MetricPage/components/CalculatePanel/Builder/components/BuilderTooltips/common/LearnMore';
import { METRIC_TYPES_LINK } from '../../utils/consts';

export function PrimaryKeysTooltip({ onHelpClicked }: { onHelpClicked: LearnMoreProps['onClick'] }) {
	return (
		<Typography color={'white'} variant="Paragraph14R">
			An entity column with unique IDs. This is important for creating accurate relationships. If no single column
			represents a unique key, you can combine several columns to create a unique key.&nbsp;
			<LearnMore onClick={onHelpClicked} link={METRIC_TYPES_LINK} />.
			<ExampleText marginTop={'16px'} text={'$src__id'} />
		</Typography>
	);
}
