import Axios from 'axios';
import LRU from 'lru-cache';
import { configure } from 'axios-hooks';
import { normalizedEnvConfig } from 'src/stores/environment';
import { EnvironmentState } from 'src/types/environment';

export function configureAxios(envState: EnvironmentState, bearerToken: string) {
	const env = normalizedEnvConfig(envState);
	const headers = {
		'X-Role': env.role,
		'X-Tenant-ID': env.tenant.toString(),
		Authorization: bearerToken,
	};

	const axiosInstance = Axios.create({
		baseURL: env.apiUrl,
		headers,
	});

	const cache = new LRU({ max: 25 });

	configure({ axios: axiosInstance, cache });
}
