import { Box } from '@chakra-ui/react';
import { Property as CSSProperty } from 'csstype';
import { ReactElement, ReactNode } from 'react';
import colors from 'src/style/colors';
import Divider from '../Divider';
import Flex from '../Flex';
import { Info16, OkCircle16, Warning16 } from '../Icons';
import Typography from '../Typography';
import classes from './Toast.module.scss';

export type ToastVariants = 'error' | 'ok' | 'info';

export type ToastProps = {
	variant: ToastVariants;
	message: string | ReactNode;
	duration?: number | null;
	link?: string;
	onClick?: () => void;
	isCloseable?: boolean;
};
export default function Toast({ variant, message, link, onClick }: ToastProps) {
	return (
		<Flex className={classes.toast} color={getColorFromVariant[variant]} flexDirection="row" alignItems="center">
			<Box>{getIconFromVariant[variant]}</Box>
			<Box>
				{typeof message === 'string' ? (
					<Typography variant="Paragraph14R" color="gray.1000">
						{message}
					</Typography>
				) : (
					message
				)}
			</Box>
			{link && (
				<Flex direction={'row'}>
					<Box ml={'2px'} mr={'12px'}>
						<Divider direction="vertical" color={colors.gray['400']} />
					</Box>

					<Typography
						variant="DesktopH8Medium"
						color="black"
						as={'button'}
						onClick={onClick}
						textDecoration={'underline'}
					>
						{link}
					</Typography>
				</Flex>
			)}
		</Flex>
	);
}
const getColorFromVariant: { [key in ToastVariants]: CSSProperty.Color } = {
	error: 'red.800',
	ok: 'green.800',
	info: 'blue.700',
};
const getIconFromVariant: { [key in ToastVariants]: ReactElement } = {
	error: <Warning16 />,
	ok: <OkCircle16 />,
	info: <Info16 />,
};
