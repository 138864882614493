import Box from '@components/Box';
import { LinkNode } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalTypeaheadMenuPlugin } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import {
	$createParagraphNode,
	$createTextNode,
	$getSelection,
	$insertNodes,
	$isRangeSelection,
	createCommand,
	LexicalCommand,
	TextNode,
} from 'lexical';
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import Image from 'src/common/components/Image';
import { Mention } from 'src/types/mention';
import { validate as validateUUID } from 'uuid';
import { $createMentionNode, MentionNode } from '../../nodes/mention';
import { SUGGESTION_LIST_LENGTH_LIMIT } from './consts';
import MentionTypeaheadOption from './MentionTypeaheadOption';
import { getPossibleQueryMatch, useMentionLookup } from './utils';

import Popover from 'src/common/components/Popover';
import { useReportEvent } from 'src/services/analytics';
import { LowPriority } from '../../consts';
import classes from '../../styles/mention.module.scss';

export const ADD_MENTION_NODE: LexicalCommand<string> = createCommand();

function MentionPlugin({
	isEditMode,
	mentions,
	chosenMentions,
}: {
	isEditMode: boolean;
	mentions: Mention[];
	chosenMentions: React.MutableRefObject<string[]>;
}): JSX.Element | null {
	const { reportEvent } = useReportEvent();
	const [editor] = useLexicalComposerContext();
	const [queryString, setQueryString] = useState<string | null>(null);
	const results = useMentionLookup(queryString, mentions);
	const suggestionList = useMemo(
		() => results.map((result) => new MentionTypeaheadOption(result)).slice(0, SUGGESTION_LIST_LENGTH_LIMIT),
		[results]
	);

	useEffect(() => {
		return editor.registerNodeTransform(MentionNode, (mentionNode) => {
			const mention = mentionNode.getTextContent();
			const isNotAlreadyExist = !chosenMentions.current.includes(mention);
			if (isNotAlreadyExist) {
				chosenMentions.current = [...chosenMentions.current, mention];
			}
		});
	}, [chosenMentions, editor]);

	useEffect(() => {
		return editor.registerNodeTransform(LinkNode, (linkNode) => {
			const uuid = linkNode.getURL();
			const isUUID = validateUUID(uuid);

			if (isUUID && mentions.length) {
				const mention = mentions.find((mention) => mention.id == uuid);
				const mentionNode = $createMentionNode(mention as MentionTypeaheadOption);

				if (linkNode) {
					linkNode.replace(mentionNode);
				}
				mentionNode.select();
			}
		});
	}, [editor, mentions]);

	useEffect(() => {
		return editor.registerCommand(
			ADD_MENTION_NODE,
			() => {
				const selection = $getSelection();
				if ($isRangeSelection(selection)) {
					const textNode = $createTextNode(' @');
					try {
						$insertNodes([textNode]);
					} catch {
						const paragraph = $createParagraphNode();
						paragraph.append(textNode);
						$insertNodes([paragraph]);
					}
				}

				return true;
			},
			LowPriority
		);
	}, [editor]);

	const onSelectOption = useCallback(
		(mention: MentionTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
			reportEvent({ event: 'mention-clicked' });
			editor.update(() => {
				const mentionNode = $createMentionNode(mention);
				if (nodeToReplace) {
					nodeToReplace.replace(mentionNode);
				}
				mentionNode.select();
				closeMenu();
			});
		},
		[editor, reportEvent]
	);

	const checkForMentionMatch = (text: string) => {
		if (!text.includes('@')) {
			return null;
		}

		const mentionMatch = getPossibleQueryMatch(text);

		return mentionMatch;
	};

	const renderSuggestionList = (
		anchorElementRef: MutableRefObject<HTMLElement | null>,
		{
			selectedIndex,
			selectOptionAndCleanUp,
			setHighlightedIndex,
		}: {
			selectedIndex: number | null;
			selectOptionAndCleanUp: (option: MentionTypeaheadOption) => void;
			setHighlightedIndex: (index: number) => void;
		}
	) => {
		if (!anchorElementRef.current) return null;

		const anchorRect = anchorElementRef.current.getBoundingClientRect();

		return (
			<Popover
				isOpen={isEditMode && !!suggestionList.length}
				triggerElement={
					<Box
						position={'absolute'}
						top={anchorRect.top}
						left={anchorRect.left}
						right={anchorRect.right}
						bottom={anchorRect.bottom}
					/>
				}
				placement="auto-start"
				autoFocus={false}
			>
				{suggestionList.map((option, i: number) => (
					<MentionsTypeaheadMenuItem
						isSelected={selectedIndex === i}
						onClick={() => {
							setHighlightedIndex(i);
							selectOptionAndCleanUp(option);
						}}
						onMouseEnter={() => setHighlightedIndex(i)}
						key={option.id}
						option={option}
					/>
				))}
			</Popover>
		);
	};

	return (
		<LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
			onQueryChange={setQueryString}
			onSelectOption={onSelectOption}
			triggerFn={checkForMentionMatch}
			options={suggestionList}
			menuRenderFn={renderSuggestionList}
		/>
	);
}

export default MentionPlugin;

function MentionsTypeaheadMenuItem({
	isSelected,
	onClick,
	onMouseEnter,
	option,
}: {
	isSelected: boolean;
	onClick: () => void;
	onMouseEnter: () => void;
	option: MentionTypeaheadOption;
}) {
	const className = `${classes.mentionSuggestionsListItem} ${isSelected ? classes.selectedElement : ''}`;

	return (
		<Box
			key={option.key}
			tabIndex={-1}
			className={className}
			ref={option.setRefElement}
			role="option"
			aria-selected={isSelected}
			onMouseEnter={onMouseEnter}
			onClick={onClick}
		>
			<Image src={option.avatarURL} className={classes.mentionAvatar} alt="avatar" />
			<Box className={classes.mentionName}>{option.name}</Box>
			<br />
			<Box className={classes.mentionEmail}>{option.email}</Box>
		</Box>
	);
}
