import { SparksWhite12 } from '@components/Icons';
import Flex from '@components/Flex';
import Typography from '@components/Typography';

export type AskAIInputEcho = {
	value?: string;
};

export function AskAIInputEcho({ value }: AskAIInputEcho) {
	return (
		<Flex alignItems={'center'}>
			<Flex
				direction={'row'}
				backgroundColor={'blue.600'}
				borderRadius={'4px'}
				padding={'4px 8px 4px 8px'}
				gap={'6px'}
				alignItems={'center'}
			>
				<SparksWhite12 />
				<Typography variant={'DesktopH10Medium'} color={'white'}>
					Ask AI
				</Typography>
			</Flex>
			<Typography variant={'DesktopH8Regular'} textAlign="start" paddingLeft={'12px'} testId={`ask-ai-input-echo`}>
				“{value}”
			</Typography>
		</Flex>
	);
}
