import Box from 'src/common/components/Box';
import { useGetParameterDefinitionQuery } from 'src/generated/graphql';
import { MonacoYamlEditor } from 'src/pages/MetricPage/components/MonacoEditor/MonacoYamlEditor';
import { NormalizedOntology } from '../../hooks/useOntologyPageState';
import { BuilderHeader } from '../Header';
import { SkeletonHeader } from '../SkeletonComponents/SkeletonTable/SkeletonTable';
import { PageContentStructure } from './PageContentStructure';

export function ParametersOntologyPageContent({
	isLoading,
	selectedOntology,
}: {
	isLoading: boolean;
	selectedOntology: NormalizedOntology | null;
}) {
	const { data, loading: isDefinitionLoading } = useGetParameterDefinitionQuery({
		variables: { parameterName: selectedOntology?.name ?? '' },
		skip: selectedOntology == null,
	});
	const parameterDefinition = data?.getParameterDefinition?.parameterDefinition.join('\n');

	return (
		<PageContentStructure>
			{isLoading || selectedOntology == null ? (
				<SkeletonHeader />
			) : (
				<BuilderHeader ontologyType={'parameter'} ontology={selectedOntology} isEditable={false} />
			)}
			<Box padding="24px" height={'100%'}>
				<MonacoYamlEditor
					editorLoading={isDefinitionLoading}
					value={parameterDefinition ?? ''}
					onHelpClicked={() => {}}
					isEditable={false}
					hasUnsavedChanges={false}
					schemaType="parameters"
					border="1px"
					borderColor="gray.300"
					borderRadius="8px"
				/>
			</Box>
		</PageContentStructure>
	);
}
