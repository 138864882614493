import { Box, ModalHeader } from '@chakra-ui/react';
import Flex from '@components/Flex';
import IconButton from '@components/IconButton';
import Input from '@components/Input';
import Typography from '@components/Typography';
import useMutation from '@hooks/fetching/useMutation';
import { CloseLarge16 } from '@icons/index';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import Modal from 'src/common/components/Modal';
import ModalFooter from 'src/common/components/ModalFooter';
import { TestIDs } from 'src/common/types/test-ids';
import { GetCollectionsSubscription } from 'src/generated/graphql';
import { InsertCollectionAndFeed, UpdateCollection } from 'src/queries/collections';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import shadows from 'src/style/shadows';

type OnCloseArgs = { name: string; id?: string };

type CollectionUpsertModalProps = {
	isOpen: boolean;
	onClose: (params?: OnCloseArgs) => void;
	collectionType: string;
	collection?: GetCollectionsSubscription['workspaces'][0];
	stopRedirect?: boolean;
};

const duplicateNameError =
	'Uniqueness violation. duplicate key value violates unique constraint "workspaces_tid_name_collection_type_key"';

const MODAL_Z_INDEX = 'var(--chakra-zIndices-popover)';

// TODO: change the terminology to collection (no space!)

export function CollectionUpsertModal({
	isOpen,
	onClose,
	collectionType,
	collection,
	stopRedirect,
}: CollectionUpsertModalProps) {
	const { reportEvent } = useReportEvent();
	const [spaceName, setSpaceName] = useState<string>(collection?.name || '');
	const modalState = collection ? 'Edit' : 'Create';
	const [error, setError] = useState('');
	const isError = !!error;
	const submitMessage = collection ? 'Save' : 'Create';
	const collectionLabel = capitalize(collectionType); // todo: this is too specific for workpspace and dashboards
	const [collectionMutate, { error: mutationError, loading: isLoading }] = useMutation(
		collection ? UpdateCollection : InsertCollectionAndFeed
	);

	useEffect(() => {
		setSpaceName(collection?.name || '');
	}, [collection?.name]);

	const isFormValid = () => {
		const isSpaceNameValid = !spaceName;

		if (isSpaceNameValid) {
			setError(`Please choose a name for your ${collectionLabel}`);
		}

		return !isSpaceNameValid;
	};

	const asyncErrorHandler = () => {
		if (!mutationError?.message) return;

		switch (mutationError.message) {
			case duplicateNameError:
				setError(`A ${collectionLabel} with this name already exists, please choose a different name`);
				break;
			default:
				// TODO: add a 'critical' level log
				setError('Error occurred');
		}
	};

	useEffect(asyncErrorHandler, [mutationError, collectionLabel]);
	const { navigate } = useNavigation();
	function submit() {
		const isValid = isFormValid();

		if (!isValid) return;

		if (!collection) {
			reportEvent({
				event: 'add-collection',
				metaData: { title: spaceName, collectionType: collectionType },
			});
			collectionMutate({ variables: { name: spaceName, collection_type: collectionType } }).then((res) => {
				const collectionId = res?.data?.insert_workspaces_one?.id;

				if (!stopRedirect) navigate({ path: `/${collectionType}/${collectionId}` });
				onClose({ name: spaceName, id: collectionId });
			});
		} else {
			reportEvent({
				event: 'update-collection-name',
				metaData: { title: spaceName, dashboardId: collection.id, collectionType: collectionType },
			});
			collectionMutate({
				variables: { id: collection.id, name: spaceName, order: collection.order, description: collection.description },
			}).then(() => {
				onClose({ name: spaceName });
			});
		}
	}

	return (
		<Modal zIndex={MODAL_Z_INDEX} isOpen={isOpen} onClose={() => onClose()} closeOnOverlayClick={false} isCentered>
			<ModalHeader py="16px" paddingLeft="24px" paddingRight="16px" boxShadow={shadows.borderBottom}>
				<Flex justifyContent="space-between" alignItems="center">
					<Typography variant="DesktopH6">
						{modalState} {collectionType?.toLowerCase()}
					</Typography>
					<IconButton
						icon={<CloseLarge16 />}
						ariaLabel="Close"
						variant="regular"
						colorScheme="black"
						onClick={() => onClose()}
					/>
				</Flex>
			</ModalHeader>
			<Box padding="24px">
				<Input
					autoFocus
					size="md"
					isInvalid={isError}
					label={`${collectionLabel} name`}
					placeholder={`New ${collectionType}`}
					errorMessage={error}
					onChange={setSpaceName}
					value={spaceName}
					onEnter={submit}
					data-testid={TestIDs.NEW_COLLECTION_NAME_INPUT(collectionType)}
				/>
			</Box>
			<Box boxShadow={shadows.borderTop}>
				<ModalFooter
					size={'lg'}
					primaryButtonLabel={submitMessage}
					isPrimaryLoading={isLoading}
					onPrimaryClick={submit}
					onCancel={() => onClose()}
					color={'blue'}
					cancelButtonColor={'gray'}
					testId={TestIDs.NEW_COLLECTION_MODAL_FOOTER(collectionType)}
				/>
			</Box>
		</Modal>
	);
}
