import { getBreadcrumbs } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/utils';
import { IModal } from '../interfaces/fields';

export function getDimensionDetails(normalized: IModal | null, dimension?: string) {
	const dimensionKey = `${normalized?.object}.${
		normalized?.modalType == 'dimension' ? dimension : normalized?.dimension
	}`;
	const breadcrumbs = getBreadcrumbs(dimensionKey);
	const objectLabel = breadcrumbs[0];
	const dimensionLabel = breadcrumbs[breadcrumbs.length - 1];

	return { objectLabel, dimensionKey, dimensionLabel };
}
