import { PopoverContent, useDisclosure } from '@chakra-ui/react';
import Box from '@components/Box';
import Button from '@components/Button';
import { DeleteCategoryModal } from '@components/DeleteCategoryModal';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import ListItem from '@components/ListItem';
import Popover from '@components/Popover';
import { useModal } from '@hooks/ui/useModal';
import { DeleteTrash16, EditFull16, More16 } from '@icons/index';
import { CreateEditCategoryModal } from '@pages/MetricCatalog/CreateEditCategoryModal/CreateEditCategoryModal';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';
import colors from 'src/style/colors';
import { Permissions } from 'src/types/environment';
import { CategoryV2 } from 'src/types/metric';

export function CategoryMoreButton({
	className,
	isDeleteOptionVisible,
	category,
}: {
	className: string;
	isDeleteOptionVisible: boolean;
	category: CategoryV2;
}) {
	const { name: categoryName } = category;
	const { isOpen, onClose, onOpen } = useDisclosure();
	const {
		isOpen: isEditCategoryModalOpen,
		onOpen: onEditCategoryModalOpen,
		onClose: onEditCategoryModalClose,
	} = useModal({ defaultIsOpen: false });
	const {
		isOpen: isDeleteCategoryModalOpen,
		onOpen: onDeleteCategoryModalOpen,
		onClose: onDeleteCategoryModalClose,
	} = useModal({ defaultIsOpen: false });
	const { reportEvent } = useReportEvent();
	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);

	return (
		<Box padding="12px" opacity={'0'} className={className}>
			<Popover
				placement="bottom-start"
				variant="solid"
				isOpen={isOpen}
				onClose={onClose}
				triggerElement={
					<Flex position="relative">
						<Button
							size="xs"
							variant="outline"
							colorScheme="gray"
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								if (isOpen) onClose();
								else onOpen();
							}}
							isActive={isOpen}
							isIconOnly={true}
							color={'gray.900'}
							_hover={{ bgColor: isOpen ? 'transparent' : 'gray.200' }}
							blendMode={'multiply'}
						>
							<More16 />
						</Button>
					</Flex>
				}
				popoverContentProps={{
					borderRadius: '8px',
					border: `1px solid ${colors.gray[300]}`,
				}}
			>
				<PopoverContent>
					<Flex alignSelf="center" direction={'column'} py="8px" width="170px" borderRadius="4px">
						{hasEditPermission && (
							<ListItem
								color="gray.1000"
								hoverColor={'blue.100'}
								label="Edit"
								size="sm"
								prefixIcon={<EditFull16 />}
								onClick={() => {
									reportEvent({
										event: 'category-edit-clicked',
										metaData: { categoryName, page: 'metric catalog' },
									});
									onEditCategoryModalOpen();
								}}
							/>
						)}
						{isDeleteOptionVisible && (
							<>
								{hasEditPermission && (
									<Box paddingX={'12px'} paddingY={'8px'}>
										<Divider direction={'horizontal'}></Divider>
									</Box>
								)}
								<ListItem
									color="gray.1000"
									hoverColor={'blue.100'}
									label={'Delete'}
									size="sm"
									prefixIcon={<DeleteTrash16 />}
									onClick={() => {
										reportEvent({
											event: 'category-delete-clicked',
											metaData: { categoryName, page: 'metric catalog' },
										});
										onDeleteCategoryModalOpen();
									}}
								/>
							</>
						)}
					</Flex>
				</PopoverContent>
			</Popover>
			{isEditCategoryModalOpen && (
				<CreateEditCategoryModal
					isOpen={isEditCategoryModalOpen}
					onClose={onEditCategoryModalClose}
					category={category}
				/>
			)}
			<DeleteCategoryModal
				isOpen={isDeleteCategoryModalOpen}
				category={category}
				onClose={onDeleteCategoryModalClose}
			/>
		</Box>
	);
}
