import { useQuery } from '@apollo/client';
import { Box, Grid, GridItem, useDisclosure } from '@chakra-ui/react';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Alert from 'src/common/components/Alert';
import Flex from 'src/common/components/Flex';
import Loader from 'src/common/components/Loader';
import PageHeader from 'src/common/components/PageHeader';
import { ResponsiveGrid } from 'src/common/components/ResponsiveGrid/ResponsiveGrid';
import useLazyQuery from 'src/common/hooks/fetching/useLazyQuery';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { GetAllConfigQuery, GetConfigByNameQuery, GetConfigByNameQueryVariables } from 'src/generated/graphql';
import Page from 'src/layout/Page';
import { GetAllConfigs, GetConfigByName } from 'src/queries/self-service-configs';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';
import { tenantConfigSelectors, useTenantConfigStore } from 'src/stores/tenantConfig';
import { Permissions } from 'src/types/environment';
import { AlertDescriptionComponent, Fields, Footer, Header, SaveModal } from './components';
import { IConfig, IField, IFieldValue } from './interfaces';

export default function ConfigurationPage() {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const hasPermission = usePermissionCheck().isHavingOneOfPermissions([
		Permissions.updateConfiguration,
		Permissions.readConfiguration,
	]);

	if (isSightfull2 || !hasPermission) return null;

	return (
		<Page page="Configuration Page">
			<ConfigurationPageGuard />
		</Page>
	);
}

export function ConfigurationPageGuard() {
	const { reportEvent } = useReportEvent();
	const [config, setConfig] = useState<IConfig | null>(null);
	const [isEnabled, setIsEnabled] = useState<boolean>(true);

	const [fieldsSelectedValues, setFieldsSelectedValues] = useState<{ [key: string]: Partial<IField> } | null>(
		config?.fields ?? null
	);
	const [originalFieldSelectedValues, setOriginalFieldSelectedValues] = useState<{
		[key: string]: Partial<IField>;
	} | null>(config?.fields ?? null);
	const [getConfigByName, { data: curConfig, loading: loadingConfigByName }] = useLazyQuery<
		GetConfigByNameQuery,
		GetConfigByNameQueryVariables
	>(GetConfigByName, { fetchPolicy: 'no-cache' });

	// TODO: Get rid of this when migration is done
	const showPipelineStagesConfiguration = useFeatureFlag('pulse.react.showPipelineStagesConfiguration');

	const { data: allConfigs, loading: loadingConfigs } = useQuery<GetAllConfigQuery>(GetAllConfigs);
	const configs: IConfig[] = useMemo(
		() => allConfigs?.get_all_configs?.response?.slice(0, showPipelineStagesConfiguration ? 2 : 1) || [],
		[allConfigs, showPipelineStagesConfiguration]
	);

	const [selectedConfigIndex, setSelectedConfigIndex] = useState<number>(0);
	const configName = useMemo(() => configs[selectedConfigIndex]?.name, [configs, selectedConfigIndex]);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const lastEtlSync = useTenantConfigStore(tenantConfigSelectors.getLastEtlSync) || null;

	const isSynced = useMemo(() => {
		if (!config?.edited_at || !lastEtlSync) {
			return true;
		}

		const editedAtDate = new Date(config.edited_at);

		const editedAtUtcFormat = new Date(editedAtDate.getTime() + editedAtDate.getTimezoneOffset() * 60 * 1000);
		const lastEtlSyncUtcFormat = new Date(lastEtlSync.getTime() + lastEtlSync.getTimezoneOffset() * 60 * 1000);

		return lastEtlSyncUtcFormat.getTime() >= editedAtUtcFormat.getTime();
	}, [config, lastEtlSync]);

	const userConfigurationPermission = usePermissionCheck().isHavingPermission(Permissions.updateConfiguration);

	const onChange = (key: string, value: IFieldValue | undefined) => {
		reportEvent({
			event: 'self-service-config-changed-value',
			metaData: { config: configName, field: key, newValue: value?.toString() },
		});
		setFieldsSelectedValues((prev) => ({ ...prev, [key]: { selectedValue: value } }));
	};

	const getSpecificConfig = useCallback(() => {
		if (!configName) {
			return;
		}

		getConfigByName({
			variables: { name: configName },
		});
	}, [getConfigByName, configName]);

	useEffect(() => {
		getSpecificConfig();
	}, [getSpecificConfig]);

	useEffect(() => {
		if (!curConfig) {
			return;
		}

		const config = curConfig.get_config?.response as IConfig;

		if (!config) {
			return;
		}
		setConfig(config);
		setFieldsSelectedValues(null);
		setIsEnabled(config?.alwaysEnabled || config?.isEnabled);
	}, [curConfig]);

	useEffect(() => {
		const isMutableValuesStateInitiated = fieldsSelectedValues && Object.keys(fieldsSelectedValues).length !== 0;
		if (!config?.fields || isMutableValuesStateInitiated) {
			return;
		}

		const selectedValues = Object.entries(config.fields).reduce(
			(acc, [key, { selectedValue, default: defaultValue }]) => {
				const initValue = selectedValue ?? defaultValue;
				return { ...acc, [key]: { selectedValue: initValue } };
			},
			{}
		);
		setFieldsSelectedValues(selectedValues);
		setOriginalFieldSelectedValues(selectedValues);
	}, [config?.fields, fieldsSelectedValues]);

	const isConfigChanged = useMemo(
		() => !isEqual(originalFieldSelectedValues, fieldsSelectedValues) || isEnabled !== config?.isEnabled,
		[config, isEnabled, originalFieldSelectedValues, fieldsSelectedValues]
	);

	const shouldShowAlert = isEnabled && !isSynced;
	const shouldShowFields = config?.fields && isEnabled;

	return loadingConfigs ? (
		<Loader isCenter />
	) : (
		<Flex direction={'column'} height="100%" justifyContent={'space-between'}>
			<PageHeader
				title="Configurations"
				tabs={configs.map((config) => {
					return { text: config.displayName, testId: config.name, isSelected: config.name == configName };
				})}
				tabIndex={configs.findIndex((config) => config.name == configName)}
				onChangeTab={(newConfigIndex: number) => {
					reportEvent({
						event: 'self-service-config-switch-category',
						metaData: { config: configs[newConfigIndex]?.name, previousConfig: configName },
					});
					setSelectedConfigIndex(newConfigIndex);
				}}
				testId="ConfigurationPageHeader"
				hasBorderBottom={true}
			/>
			{loadingConfigByName ? (
				<Loader isCenter />
			) : (
				<Box overflowX="hidden" height={'100%'}>
					<ResponsiveGrid height={'100%'}>
						<GridItem colSpan={{ base: 2, xs: 12 }} colStart={0}>
							<Grid gridTemplateColumns="repeat(12, [col-start] 1fr)" columnGap="16px" rowGap="16px" pb={'8px'}>
								{config && (
									<GridItem colSpan={12}>
										<Header
											isEnabled={isEnabled}
											alwaysEnabled={config.alwaysEnabled}
											displayName={config.displayName}
											description={config.description}
											documentation={config.documentation}
											hasEditPermission={userConfigurationPermission}
											onChange={(e) => {
												reportEvent({
													event: 'self-service-config-changed-value',
													metaData: { config: configName, field: 'isEnabled', newValue: e.target.checked.toString() },
												});
												setIsEnabled(e.target.checked);
											}}
										/>
									</GridItem>
								)}
								{shouldShowAlert && (
									<GridItem colSpan={12}>
										<Alert status="warning" DescriptionComponent={AlertDescriptionComponent} />
									</GridItem>
								)}
							</Grid>
							{shouldShowFields && (
								<Fields
									isHasEditPermission={userConfigurationPermission ? 'Edit' : 'View'}
									fields={config.fields}
									values={fieldsSelectedValues}
									configName={configName}
									onChange={onChange}
								/>
							)}
						</GridItem>
					</ResponsiveGrid>
				</Box>
			)}
			{!loadingConfigByName && (
				<Footer
					configName={configName}
					handleSave={onOpen}
					handleCancel={getSpecificConfig}
					isDisabled={!isConfigChanged}
					permission={userConfigurationPermission ? 'Edit' : 'View'}
				/>
			)}
			<SaveModal
				isEnabled={isEnabled}
				fieldsSelectedValues={fieldsSelectedValues}
				configName={configName}
				isOpen={isOpen}
				onClose={onClose}
				onSaveDone={getSpecificConfig}
			/>
		</Flex>
	);
}
