import { formatNumber } from '../formatters';
import { NormalizedRelatedEntities, Sort, SortDirection, SortParsers } from '../../types';

export function relatedEntitiesSorter(
	normalizedRelatedEntities: NormalizedRelatedEntities,
	sortOptions: Sort
): NormalizedRelatedEntities {
	const sortedRows = normalizedRelatedEntities.sort((a, b) => {
		const { field, direction = 'asc', parser } = sortOptions;

		const valueA = a.columns.find((column) => field == column.field)?.text;
		const valueB = b.columns.find((column) => field == column.field)?.text;

		if (!valueA || !valueB) {
			return 0;
		}

		return sortersByType[parser](valueA, valueB, direction);
	});

	return sortedRows;
}

const dateSorter = (valueA: string, valueB: string, direction: SortDirection): number => {
	const dateA = new Date(valueA).getTime();
	const dateB = new Date(valueB).getTime();
	return direction === 'asc' ? dateA - dateB : dateB - dateA;
};

const floatSorter = (valueA: string, valueB: string, direction: SortDirection): number => {
	const numA = formatNumber(valueA);
	const numB = formatNumber(valueB);
	return direction === 'asc' ? numA - numB : numB - numA;
};
const stringSorter = (valueA: string, valueB: string, direction: SortDirection): number => {
	return direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
};

const sortersByType: {
	[key in SortParsers]: (valueA: string, valueB: string, direction: SortDirection) => number;
} = {
	date: dateSorter,
	float: floatSorter,
	string: stringSorter,
};
