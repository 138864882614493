import { IHeaderParams } from 'ag-grid-community';
import Typography from 'src/common/components/Typography';

const ColumnHeader = (columnObj: IHeaderParams) => {
	return (
		<Typography variant="DesktopH10Medium" color="gray.800" textOverflow="ellipsis" overflow="hidden">
			{columnObj.displayName}
		</Typography>
	);
};

export default ColumnHeader;
