import { GetDataModelDimensionsQuery, NodeOverridesSubscriptionQuery } from 'src/generated/graphql';
import { DataModelDimensionsMap, DataOverridesMap } from '../types';

export function createDimensionOverridesMap(
	dataOverrides: NodeOverridesSubscriptionQuery['data_overrides']
): DataOverridesMap {
	return createDimensionMap(dataOverrides) as DataOverridesMap;
}

export function createDimensionModelMap(
	modelDimensions: GetDataModelDimensionsQuery['data_model_dimensions']
): DataModelDimensionsMap {
	return createDimensionMap(modelDimensions) as DataModelDimensionsMap;
}

type DataOverrides =
	| NodeOverridesSubscriptionQuery['data_overrides']
	| GetDataModelDimensionsQuery['data_model_dimensions'];

function createDimensionMap(dataOverrides: DataOverrides): DataOverridesMap | DataModelDimensionsMap {
	const map = new Map();

	dataOverrides?.forEach(({ dimension, ...rest }) => map.set(dimension, rest));
	return map;
}
