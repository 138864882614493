import { ManualFetchingNoAutoCancel } from '@hooks/fetching/useMetricApi';
import useRequest from '@hooks/fetching/useRequest';
import { useCallback, useEffect, useState } from 'react';
import { nodeSchema } from 'src/assets/NodeSchema';
import { EntityId } from 'src/layout/EntityProfileDrawer/atoms';
import { EntityType } from 'src/layout/EntityProfileDrawer/types';
import { SupportedDataSources } from 'src/pages/DataHygiene/types';

export type RawNodeProperties = {
	'T.label': EntityType;
	'T.id': string;
	id: string;
	source: SupportedDataSources;
	labels: string[] | string;
	[key: Exclude<string, 'labels' | 'T.label' | 'source'>]: RawNodePropValue;
};

export type RawNodeRelationship = {
	direction: '<' | '>';
	nodes: { [key: string]: string | number; id: string; 'T.id': string }[];
	other_label: string;
	relationship_label: string;
};

export type RawNodePropValue = number | string | number[] | string[] | boolean;

export type RawNode = {
	properties: RawNodeProperties;
	relationships: RawNodeRelationship[];
};

export function useEntityFetchApi(entityId?: EntityId): RawNode | undefined {
	const [entity, setEntity] = useState<RawNode | undefined>(undefined);
	const [, { refetch }] = useRequest<{ node: RawNode }>({
		method: 'POST',
		options: ManualFetchingNoAutoCancel,
		body: { collect_props: allPropsFromSchema },
	});

	const fetchEntity = useCallback(
		async (id: EntityId): Promise<{ node: RawNode }> => {
			const response = await refetch({
				url: `/node/${encodeURIComponent(id)}`,
			});
			return response?.data;
		},

		[refetch]
	);

	useEffect(() => {
		const isEntityTheSameObject =
			(entity && entity.properties['T.id'] == entityId) || `id:${entity?.properties['id']}` == entityId;

		if (!isEntityTheSameObject) setEntity(undefined);
		if (entityId && !entity) {
			fetchEntity(entityId).then((res) => setEntity(res.node));
		}
	}, [entity, entityId, fetchEntity]);

	return entity;
}

const allPropsFromSchema = Object.values(nodeSchema.nodes)
	.map((n) => n.cards.map((c) => c.properties.map((p) => p.field)))
	.flat(2);
