import { Box, Flex, Grid, GridItem, ModalContent, ModalOverlay } from '@chakra-ui/react';
import Button from 'src/common/components/Button';
import Divider from 'src/common/components/Divider';
import IconButton from 'src/common/components/IconButton';
import { CloseLarge16 } from 'src/common/components/Icons';
import Markdown from 'src/common/components/Markdown';
import Modal from 'src/common/components/Modal';
import Typography from 'src/common/components/Typography';

const LongDescriptionModal = ({
	isOpen,
	onClose,
	title,
	description,
}: {
	title: string;
	isOpen: boolean;
	onClose: () => void;
	description: string;
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />

			<ModalContent minWidth={'476px'}>
				<Box>
					<Grid gridTemplateColumns="repeat(12, [col-start] 1fr)" width={'476px'}>
						<GridItem colSpan={12} textAlign="center">
							<Flex justifyContent={'space-between'} p={'16px 24px'} alignItems={'center'}>
								<Typography variant="Paragraph18SB">{title}</Typography>
								<Box>
									<IconButton
										icon={
											<Box color="gray.800">
												<CloseLarge16 width="16px" height="16px" />
											</Box>
										}
										onClick={onClose}
									/>
								</Box>
							</Flex>
							<Divider direction={'horizontal'} />
						</GridItem>
						<GridItem colSpan={12} textAlign="left" p={'40px'}>
							<Typography variant="Paragraph16R">
								<Markdown message={description} />
							</Typography>
						</GridItem>
						<GridItem colSpan={12}>
							<Divider direction={'horizontal'} />
							<Flex justifyContent="center" alignItems="center" gap={'10px'} p={'16px 24px'}>
								<Button size={'medium'} variant="solid" color={'white'} onClick={onClose} width={'100%'}>
									Got it!
								</Button>
							</Flex>
						</GridItem>
					</Grid>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default LongDescriptionModal;
