import Box from '@components/Box';
import Flex from '@components/Flex';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { AdvancedSelect } from 'src/common/components/AdvancedSelect';
import { CloseTiny16 } from 'src/common/components/Icons';
import { SelectOption } from 'src/common/components/Select/types';
import Skeleton from 'src/common/components/Skeleton';
import Typography from 'src/common/components/Typography';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import { CompletionProvider } from 'src/lib/completions/completionProvider';
import { EnrichedSemanticDefinitions } from 'src/lib/completions/semanticTypes';
import { getEntity } from 'src/lib/completions/utils/utils';
import { MonacoTooltipComponent } from 'src/lib/completions/widgetBuilder/MonacoTooltipComponent';
import { getContextCompletionTooltip } from 'src/lib/completions/widgetBuilder/contextTooltip';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { LabelWithIcon, SelectLabel } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';
import { removeDollarSigns } from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { useReportEvent } from 'src/services/analytics';
import { OntologyStateAtomDerived } from '../../atoms/OntologyState';
import { findKeyValue } from '../../utils/utils';

export function JoinKeysDropdowns({
	isEditRelationshipFlow = true,
	isEditable = true,
	value,
	fromKeyOptions,
	toKeyOptions,
	referencedEntity,
	onChange,
	indexNumber,
	isMultiple = false,
	onRemove,
	loading = false,
}: {
	isEditRelationshipFlow?: boolean;
	isEditable?: boolean;
	value: string;
	fromKeyOptions: SelectOption[];
	toKeyOptions: SelectOption[];
	referencedEntity: string;
	onChange: (value: string) => void;
	indexNumber: number;
	isMultiple: boolean;
	onRemove: VoidFunction;
	loading?: boolean;
}) {
	const { semanticDefinitions } = useSemanticDefinitions();
	const ontologyState = useAtomValue(OntologyStateAtomDerived);
	const parentEntity = ontologyState.loading ? '' : ontologyState.entityName;
	const flow = isEditRelationshipFlow ? 'edit' : 'create';

	const { reportEvent } = useReportEvent({
		feature: 'Join keys',
		objectType: 'relationships',
		objectName: parentEntity,
		parentEntity,
		flow,
	});

	const fromKeyControlledValue = useMemo(() => {
		const fromKey = findKeyValue(value, false);
		return (
			fromKeyOptions.find((el) => el.value === fromKey) ||
			(fromKey ? { value: fromKey || '', label: fromKey || '' } : undefined)
		);
	}, [fromKeyOptions, value]);

	const toKeyControlledValue = useMemo(() => {
		const toKey = findKeyValue(value, true);
		return (
			toKeyOptions?.find((el) => `$${referencedEntity}.${removeDollarSigns(el.value)}` === toKey) ||
			(toKey
				? {
						value: toKey,
						label: toKey,
				  }
				: undefined)
		);
	}, [toKeyOptions, value, referencedEntity]);

	const isLoading = loading || !fromKeyOptions.length || (referencedEntity && !toKeyOptions.length);

	const prettfiedEntityName = useMemo(
		() => removeUnderscoresAndCapitalize(ontologyState.loading ? '' : ontologyState?.entityName),
		[ontologyState]
	);

	const prettifiedReferencedEntityName = useMemo(
		() => referencedEntity && removeUnderscoresAndCapitalize(referencedEntity),
		[referencedEntity]
	);

	const hoverTooltipBuilder = useCallback(
		(selectOption: SelectOption, entityName: string) => {
			if (!selectOption || !semanticDefinitions) return;
			const context = resolveContextFromSemanticsAndName(semanticDefinitions, entityName, selectOption.value);
			if (!context) return;
			return (
				<MonacoTooltipComponent tooltipMarkdownString={getContextCompletionTooltip(context, selectOption.value)} />
			);
		},
		[semanticDefinitions]
	);

	if (ontologyState.loading) return;

	const TopContent = (
		<Flex marginBottom={'10px'} justifyContent={'space-between'} alignItems={'center'}>
			<Flex
				display={'inline-flex'}
				alignItems={'center'}
				padding={'1px 8px'}
				borderRadius={'6px'}
				backgroundColor={'blue.600'}
			>
				<Typography color={'white'} variant="Paragraph12M">
					{indexNumber + 1}
				</Typography>
			</Flex>
			<LabelWithIcon icon={<CloseTiny16 />} isFieldsDisabled={false} onClick={onRemove} text="Remove" />
		</Flex>
	);

	const Dropdowns = (
		<>
			<Box marginBottom={'24px'}>
				<SelectLabel optionalText={prettfiedEntityName} marginBottom={'8px'} color={'gray.1100'} text="From key" />
				{isLoading ? (
					<Skeleton height={'42px'} />
				) : (
					<AdvancedSelect
						entityName={ontologyState.entityName}
						onReportEvent={(input) =>
							reportEvent({
								event: 'ontology-object-edit-UI-input-provided',
								metaData: {
									fieldname: 'From key',
									input,
								},
							})
						}
						isDisabled={!isEditable}
						onChange={(newValue) => {
							const fromKey = findKeyValue(value, false);
							if (value && fromKey) {
								onChange(value?.replace(fromKey, `${newValue.value}`));
							} else {
								onChange(`${newValue.value} = ${value || ''}`);
							}
						}}
						placeholder="Select"
						options={fromKeyOptions}
						controlledValue={fromKeyControlledValue}
						dataIntercomTarget="fromKey"
						hoverTooltipBuilder={(selectOption) => hoverTooltipBuilder(selectOption, parentEntity)}
					/>
				)}
			</Box>
			<Box>
				<SelectLabel
					optionalText={prettifiedReferencedEntityName}
					marginBottom={'8px'}
					color={'gray.1100'}
					text="To key"
				/>
				{isLoading ? (
					<Skeleton height={'42px'} />
				) : (
					<AdvancedSelect
						entityName={ontologyState.entityName}
						onReportEvent={(input) =>
							reportEvent({
								event: 'ontology-object-edit-UI-input-provided',
								metaData: {
									fieldname: 'To Key',
									input,
								},
							})
						}
						isDisabled={!isEditable}
						onChange={(newValue) => {
							const toKey = findKeyValue(value, true) || '';
							if (value && toKey) {
								onChange(value?.replace(toKey, `$${referencedEntity}.${removeDollarSigns(newValue.value)}`));
							} else {
								onChange(`${value || ''} $${referencedEntity}.${removeDollarSigns(newValue.value)}`);
							}
						}}
						placeholder="Select"
						options={toKeyOptions}
						controlledValue={toKeyControlledValue}
						dataIntercomTarget="toKey"
						hoverTooltipBuilder={(selectOption) => hoverTooltipBuilder(selectOption, referencedEntity)}
					/>
				)}
			</Box>
		</>
	);

	return (
		<Box
			marginTop={indexNumber > 0 ? '12px' : 0}
			border={isMultiple ? '1px solid' : 'none'}
			borderColor={'gray.300'}
			padding={isMultiple ? '12px' : 0}
			borderRadius={'8px'}
		>
			{isMultiple && TopContent}
			{Dropdowns}
		</Box>
	);
}

const resolveContextFromSemanticsAndName = (
	semanticDefinitions: EnrichedSemanticDefinitions,
	entity: string,
	name: string
) => {
	const completionProvider = new CompletionProvider(semanticDefinitions);
	const enrichedEntity = getEntity(semanticDefinitions, entity);
	const contexts = completionProvider.createContexts({
		entity: enrichedEntity,
	});
	const context = contexts.find((context) => context.name === removeDollarSigns(name));
	return context;
};
