import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { atom } from 'jotai';

export const AskAIBusyStateAtom = atom({ busy: false });

export function useAskAIBusyState(): [boolean, (running: boolean) => void] {
	const [state, setBusyState] = useAtom(AskAIBusyStateAtom);

	const setAskAIBusyState = useCallback(
		(busy: boolean) => {
			setBusyState(() => ({ busy }));
		},
		[setBusyState]
	);

	return [state.busy, setAskAIBusyState];
}
