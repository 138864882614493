import useSubscription from '@hooks/fetching/useSubscription';
import { GetCollectionsSubscription, GetCollectionsSubscriptionVariables } from 'src/generated/graphql';
import { GetCollections } from 'src/queries/collections';
import { collectionsNavigationDrawer } from 'src/stores/utils';

export function useCollectionsSubscriptions() {
	const { data: rawDashboardsList } = useSubscription<GetCollectionsSubscription, GetCollectionsSubscriptionVariables>(
		GetCollections,
		{
			variables: {
				collection_type: collectionsNavigationDrawer.dashboard.toLowerCase(),
			},
		}
	);

	const { data: rawWorkspacesList } = useSubscription<GetCollectionsSubscription, GetCollectionsSubscriptionVariables>(
		GetCollections,
		{
			variables: {
				collection_type: collectionsNavigationDrawer.workspace.toLowerCase(),
			},
		}
	);

	return { rawDashboardsList, rawWorkspacesList };
}
