import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import TenantConfigType from 'src/types/tenantConfig';

export function calcDecimalDigits({
	searchParamsDecimalDigits,
	tenantConfigDecimalDigits,
}: {
	searchParamsDecimalDigits: MetricSearchParams['decimalDigits'];
	tenantConfigDecimalDigits: TenantConfigType['decimalDigits'];
}): Pick<MetricDerivedState, 'decimalDigits'> {
	searchParamsDecimalDigits = Math.trunc(Math.abs(Number(searchParamsDecimalDigits)));
	searchParamsDecimalDigits = Number.isNaN(searchParamsDecimalDigits) ? undefined : searchParamsDecimalDigits;

	const decimalDigits = searchParamsDecimalDigits ?? tenantConfigDecimalDigits;
	if (decimalDigits == undefined) {
		return { decimalDigits: undefined };
	}

	return { decimalDigits: Math.min(decimalDigits, 3) };
}
