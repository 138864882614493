import { Box, Flex } from '@chakra-ui/react';
import ButtonBar from 'src/common/components/ButtonBar';
import PageHeader from 'src/common/components/PageHeader';
import Select from 'src/common/components/Select';
import { SelectOption } from 'src/common/components/Select/types';
import { TabProps } from 'src/common/components/Tabs/types';
import Typography from 'src/common/components/Typography';
import colors from 'src/style/colors';

export function Header({
	tabs,
	selectedSeverity,
	selectOptions,
	filterOptions,
	tabIndex,
	onChangeTab,
	filterIndexValues,
	onChangeSelect,
	onChangeFilter,
}: {
	selectedSeverity: string;
	tabs?: Array<TabProps>;
	filterIndexValues: number[];
	tabIndex: number;
	selectOptions?: SelectOption[];
	filterOptions?: Array<string>;
	onChangeSelect?: (option: SelectOption) => void;
	onChangeTab?: (option: number) => void;
	onChangeFilter?: (option: number) => void;
}) {
	return (
		<Box pb={'32px'}>
			<PageHeader title="Data hygiene" tabIndex={tabIndex} tabs={tabs} onChangeTab={onChangeTab} hasBorderBottom>
				<Flex alignItems={'center'}>
					{filterOptions && onChangeFilter && (
						<>
							<Typography variant="DesktopH8Regular" color={colors.gray[700]} pr={'10px'}>
								Filter by
							</Typography>
							<Box pr={'24px'}>
								<ButtonBar selectedIndexes={filterIndexValues} onChildClicked={onChangeFilter}>
									{filterOptions.map((option) => (
										<Flex key={option} alignItems={'center'} height={'36px'} px={'16px'}>
											<Typography variant="DesktopH8Medium">{option}</Typography>
										</Flex>
									))}
								</ButtonBar>
							</Box>
						</>
					)}

					{selectOptions && onChangeSelect && selectedSeverity && (
						<Select
							width={'156px'}
							options={selectOptions}
							size={'small'}
							onChange={onChangeSelect}
							selectedOption={selectOptions.find((opt) => opt.value == selectedSeverity) ?? selectOptions[0]}
						/>
					)}
				</Flex>
			</PageHeader>
		</Box>
	);
}
