import classes from 'src/layout/Menu/NavigationDrawer/NavigationDrawerV2.module.scss';

export const METRIC_LIBRARY_URL = 'https://www.sightfull.com/metrics-library/browse/';

export const CheckForTerm = (name: string, term: string) => name.toLowerCase().includes(term.toLowerCase());

export const AddBadgeDrawer = (category: string) => {
	const categoryDrawer = document.getElementById(`${category}-drawer`);
	categoryDrawer?.classList.add(classes.badgeError);
};

export const NUMBERS_OF_CATEGORIES_TO_LOAD = 2;

export const openInSourcePlatform = () => window.open(METRIC_LIBRARY_URL, '_blank');
