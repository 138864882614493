import { Flex, GridItem } from '@chakra-ui/react';
import { NoResultsFound } from 'src/common/components/Icons';
import { ResponsiveGrid } from 'src/common/components/ResponsiveGrid/ResponsiveGrid';
import Typography from 'src/common/components/Typography';

export function NoResultsFoundComponent() {
	return (
		<ResponsiveGrid>
			<GridItem colStart={{ base: 0, xs: 3 }} colSpan={{ base: 2, xs: 8 }}>
				<Flex direction="column" px="110px" gap="20px" alignItems="center" pt="160px">
					<NoResultsFound />
					<Flex direction="column" gap="8px" alignItems="center">
						<Typography variant="DesktopH5" color="gray.800">
							No results found.
						</Typography>
						<Typography variant="DesktopH7Regular" color="gray.600">
							Try using another term
						</Typography>
					</Flex>
				</Flex>
			</GridItem>
		</ResponsiveGrid>
	);
}
