import { Box, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { AccessController } from 'src/common/components/AccessController';
import Button from 'src/common/components/Button';
import Modal from 'src/common/components/Modal';
import ModalHeader from 'src/common/components/ModalHeader';
import useToast from 'src/common/hooks/ui/useToast';
import { useShareSignalApi } from 'src/common/hooks/useShareSignalApi';
import { cleanSearchParams, useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';

import useUser from 'src/common/hooks/stores/useUser';
import { FillDetailsModal, ModalDetails } from 'src/pages/MetricPage/components/ShareSignalModal/FillDetailsModal';
import { useTableColumnStateAsSearchParam } from 'src/pages/MetricPage/components/ShareSignalModal/useTableColumnStateAsSearchParam';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import { Permission, Permissions } from 'src/types/environment';
import { CollectionType, Signal } from 'src/types/signal';
import { useSignal } from '../../hooks/useSignal';
import { SaveButton } from '../SaveButton';

export function SaveSignalButton({
	onCloseClicked,
	onSaveAsNew,
	title,
	description,
	isDuplicateLoading,
	isDuplicateSignal,
	isParamsChanged,
	collectionType,
}: {
	title: string;
	description: string;
	onCloseClicked: VoidFunction;
	onSaveAsNew: VoidFunction;
	isDuplicateLoading: boolean;
	isDuplicateSignal?: boolean;
	isParamsChanged: boolean;
	collectionType: string;
}) {
	const { reportEvent } = useReportEvent();
	const [shareSignal, { isLoading }] = useShareSignalApi();
	const { periodRange } = useMetricDerivedState();
	const { metricNameWithFlavor, dangerousRawSearchParams } = useMetricPageSearchParams();
	const showToast = useToast();
	const signal = useSignal();
	const { getColumnStateSearchParam } = useTableColumnStateAsSearchParam();

	const [{ id: userId }] = useUser();
	const oneOfPermissionsIsMatched: Permission[] = [Permissions.updateAllSignals];
	if (signal?.author_id == userId) oneOfPermissionsIsMatched.push(Permissions.crudMySignals);

	function onSaveClicked() {
		const metricName = metricNameWithFlavor;
		const searchParams = new URLSearchParams(dangerousRawSearchParams);

		cleanSearchParams(searchParams);
		const isRelative = periodRange.isRelativeSupported && collectionType == 'dashboard';
		const range = isRelative ? periodRange.asRelativeRange : periodRange.asAbsoluteRange;

		searchParams.set('periodRange', JSON.stringify(range.toUrlParams().periodRange));

		searchParams.delete('tableColumnState');
		if (searchParams.get('chartType') == 'table') {
			searchParams.set('tableColumnState', getColumnStateSearchParam());
		} else {
			searchParams.delete('selectedXAxisElements');
		}

		const attachment = encodeURI(`metric/${metricName}?${decodeURI(searchParams.toString())}`);

		reportEvent({
			event: 'submit-signal-edit',
			metaData: { feature: 'Signal header', message: signal?.message, userAttachment: attachment },
		});
		shareSignal({
			attachmentContentURL: attachment,
			message: description || signal?.message,
			collectionToPin: [],
			collectionToUnPin: [],
			title: title || (signal?.title ?? metricNameWithFlavor),
			signalId: signal?.id,
		}).then(() => {
			showToast({ variant: 'ok', message: 'Signal Saved' });
			onCloseClicked();
		});
	}

	const viewAccessOnlyChip = (
		<Button
			color={'gray.1000'}
			colorScheme="gray"
			variant="ghost"
			onClick={() => {
				reportEvent({
					event: 'signal-page-close-clicked',
					metaData: { feature: 'Signal header' },
				});
				onCloseClicked();
			}}
			size="inline"
		>
			Close
		</Button>
	);

	return (
		<AccessController permission={Permissions.crudMySignals} logic="OR" noAccessChild={viewAccessOnlyChip}>
			<SaveButton
				isDuplicateSignal={isDuplicateSignal}
				description={description}
				title={title}
				metricNameWithFlavor={metricNameWithFlavor}
				oneOfPermissionsIsMatched={oneOfPermissionsIsMatched}
				isParamsChanged={isParamsChanged}
				isDuplicateLoading={isDuplicateLoading}
				onSaveClicked={onSaveClicked}
				isLoading={isLoading}
				onSaveAsNew={onSaveAsNew}
				onCloseClicked={() => {
					reportEvent({
						event: 'signal-page-close-clicked',
						metaData: { feature: 'Signal header' },
					});
					onCloseClicked();
				}}
			/>
		</AccessController>
	);
}

export function EditSignalModal({
	signal,
	metricDisplayName,
	collectionType,
	isModalOpen,
	onModalClose = () => void 0,
}: {
	signal: Signal;
	metricDisplayName: string;
	collectionType: CollectionType;
	isModalOpen: boolean;
	onModalClose: () => void;
}) {
	const { isOpen, onClose: onHookClose } = useDisclosure();
	const [shareSignal, { mutationError, isLoading }] = useShareSignalApi(signal);
	const showToast = useToast();

	useEffect(() => {
		if (mutationError) showToast({ variant: 'error', message: `Error sharing signal: ${mutationError.message}` });
	}, [mutationError, showToast]);

	const onClose = () => {
		onHookClose();
		onModalClose?.();
	};
	function onSubmit({ description, title }: ModalDetails) {
		shareSignal({
			attachmentContentURL: signal.attachment ?? '',
			message: description,
			collectionToPin: [],
			collectionToUnPin: [],
			title: title,
			signalId: signal.id,
		}).then(onClose);
	}

	return (
		<Box>
			<Modal isOpen={isOpen || isModalOpen} onClose={onClose} hasOverlay isCentered maxWidth={'420px'}>
				<ModalHeader size={'md'} title="Rename signal" />
				<Box paddingX="16px" _selection={{ background: colors.indigo[100] }}>
					<FillDetailsModal
						metricTitle={signal.title || metricDisplayName}
						description={signal.message}
						onSubmit={onSubmit}
						collectionType={collectionType}
						isLoading={isLoading}
						onClose={onClose}
						isShowingPublishAs={false}
						submitButtonText="Save"
						isShowingDialogTitle={false}
					/>
				</Box>
			</Modal>
		</Box>
	);
}
