import Box from '@components/Box';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode, HeadingNode } from '@lexical/rich-text';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
	$getSelection,
	$isRangeSelection,
	$isRootNode,
	$setSelection,
	CAN_REDO_COMMAND,
	CAN_UNDO_COMMAND,
	CLICK_COMMAND,
	FORMAT_TEXT_COMMAND,
	SELECTION_CHANGE_COMMAND,
	TextFormatType,
} from 'lexical';
import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Divider from 'src/common/components/Divider';
import { REMOVE_TEXT_SELECTION } from '../../commands';
import FloatingLinkEditor from '../../components/FloatingLinkEditor';
import TextTypeSelector from '../../components/TextTypeSelector';
import ToolbarButton from '../../components/ToolbarButton';
import { LowPriority } from '../../consts';
import { Bold, Highlight, Italic, Mention } from '../../icons';
import { BlockType } from '../../types';
import { getSelectedNode } from '../../utils';
import { ADD_MENTION_NODE } from '../mention/Mention';

import { Link16 } from 'src/common/components/Icons';
import classes from '../../styles/TextEditor.module.scss';

function ToolbarPlugin({ isDisabled = false }: { isDisabled?: boolean }) {
	const [editor] = useLexicalComposerContext();
	const [, setCanUndo] = useState(false);
	const [, setCanRedo] = useState(false);
	const [blockType, setBlockType] = useState<BlockType>('paragraph');
	const [isLink, setIsLink] = useState(false);
	const [isBold, setIsBold] = useState(false);
	const [isItalic, setIsItalic] = useState(false);
	const [isHighlight, setIsHighlight] = useState(false);

	const updateToolbar = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();
			const element = $isRootNode(anchorNode) ? anchorNode : anchorNode.getTopLevelElementOrThrow();
			const elementKey = element.getKey();
			const elementDOM = editor.getElementByKey(elementKey);
			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType(anchorNode, ListNode);
					const type: BlockType = parentList ? parentList.getTag() : element.getTag();
					setBlockType(type);
				} else {
					const parentHeading = $getNearestNodeOfType(anchorNode, HeadingNode) ?? anchorNode;
					const type: BlockType = $isHeadingNode(parentHeading)
						? parentHeading.getTag()
						: (parentHeading.getType() as BlockType);
					setBlockType(type);
				}
			}

			// Update text format
			setIsBold(selection.hasFormat('bold'));
			setIsItalic(selection.hasFormat('italic'));
			setIsHighlight(selection.hasFormat('highlight'));

			// Update links
			const node = getSelectedNode(selection);
			const parent = node.getParent();
			const isLink = $isLinkNode(parent) || $isLinkNode(node);

			setIsLink(isLink);
		}
	}, [editor]);

	const focusLinkNode = useCallback(
		(el: EventTarget | null) => {
			const selection = $getSelection();

			if ($isRangeSelection(selection)) {
				if (!isLink) {
					return;
				}

				const range = document.createRange();
				range.selectNodeContents(el as Node);

				const sel = window.getSelection();
				sel?.removeAllRanges();
				sel?.addRange(range);
			}
		},
		[isLink]
	);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateToolbar();
				});
			}),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				() => {
					updateToolbar();
					return false;
				},
				LowPriority
			),
			editor.registerCommand(
				REMOVE_TEXT_SELECTION,
				() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						if (selection.getTextContent()) {
							$setSelection(null);
						}
					}

					return true;
				},
				LowPriority
			),

			editor.registerCommand(
				CLICK_COMMAND,
				(e: MouseEvent) => {
					focusLinkNode(e.target);
					return false;
				},
				LowPriority
			),
			editor.registerCommand(
				CAN_UNDO_COMMAND,
				(canUndo) => {
					setCanUndo(canUndo);
					return false;
				},
				LowPriority
			),
			editor.registerCommand(
				CAN_REDO_COMMAND,
				(canRedo) => {
					setCanRedo(canRedo);
					return false;
				},
				LowPriority
			)
		);
	}, [editor, focusLinkNode, updateToolbar]);

	const insertLink = useCallback(() => {
		if (isDisabled) return;
		editor.dispatchCommand(TOGGLE_LINK_COMMAND, !isLink ? '' : null);
	}, [editor, isDisabled, isLink]);

	const afterLinkInsertion = () => {
		setIsLink(false);
	};

	const insertMention = useCallback(() => {
		if (isDisabled) return;
		editor.dispatchCommand(ADD_MENTION_NODE, '');
	}, [editor, isDisabled]);

	const formatText = (formatType: TextFormatType) => {
		if (isDisabled) return;
		editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
	};

	return (
		<Box className={classes.toolbarContainer} opacity={isDisabled ? '0.3' : '1'}>
			<TextTypeSelector blockType={blockType} editor={editor} isDisabled={isDisabled} />
			<Divider direction="vertical" ml="8px" mr="8px" />

			<ToolbarButton isActive={isBold} onClick={() => formatText('bold')} tooltipLabel="Bold">
				<Bold />
			</ToolbarButton>

			<ToolbarButton isActive={isItalic} onClick={() => formatText('italic')} tooltipLabel="Italic">
				<Italic />
			</ToolbarButton>

			<ToolbarButton
				isActive={isHighlight}
				onClick={() => {
					formatText('highlight');
					editor.dispatchCommand(REMOVE_TEXT_SELECTION, '');
				}}
				tooltipLabel="Highlight"
			>
				<Highlight />
			</ToolbarButton>

			<Divider direction="vertical" ml="8px" mr="8px" />

			<ToolbarButton isActive={isLink} onClick={insertLink} tooltipLabel="Add link">
				<Link16 />
			</ToolbarButton>
			{isLink && createPortal(<FloatingLinkEditor editor={editor} onEdit={afterLinkInsertion} />, document.body)}

			<ToolbarButton onClick={insertMention} tooltipLabel="Add mention">
				<Mention />
			</ToolbarButton>
		</Box>
	);
}

export default ToolbarPlugin;
