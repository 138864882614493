import { Flex } from '@chakra-ui/react';
import Typography from 'src/common/components/Typography';

export function EmptyFeed() {
	return (
		<Flex width={'100%'} alignItems="center" marginTop={'64px'} direction="column" gap="8px">
			<Typography variant="DesktopH5" color={'gray.800'}>
				All caught up!
			</Typography>
			<Typography variant="Paragraph12R" color={'gray.700'}>
				No insights or notifications made it to your feed yet
			</Typography>
		</Flex>
	);
}
