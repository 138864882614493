import { ReactNode } from 'react';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useCoreReaderRulesEngine } from 'src/lib/metricRules/useCoreReaderRulesEngine';
import { useRulesEngine } from 'src/lib/metricRules/useRulesEngine';

export default function RulesEngineRunner({ children }: { children: ReactNode }) {
	const isRunningSightfullV2 = useFeatureFlag('pulse.sightfull2.enable');
	const RulesEngineComponent = isRunningSightfullV2 ? CoreReaderRulesEngineRunner : BizApiRulesEngineRunner;

	return <RulesEngineComponent>{children}</RulesEngineComponent>;
}

function BizApiRulesEngineRunner({ children }: { children: ReactNode }) {
	useRulesEngine();

	return <>{children}</>;
}
function CoreReaderRulesEngineRunner({ children }: { children: ReactNode }) {
	useCoreReaderRulesEngine();

	return <>{children}</>;
}
