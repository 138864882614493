import Box from '@components/Box';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import Skeleton from 'src/common/components/Skeleton';
import colors from 'src/style/colors';
import classes from './SkeletonViewDivider.module.scss';

const { buttonsBox, buttonSkeleton, edgeButtonSkeleton } = classes;

export function SkeletonViewDivider() {
	return (
		<Flex flexDirection="row" alignItems="center">
			<Flex grow={1}>
				<Box width="100%">
					<Divider direction="horizontal" />
				</Box>
			</Flex>
			<Flex flexDirection="row">
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					className={buttonsBox}
					borderColor={colors.gray[300]}
				>
					<Flex flexDirection="row">
						<Skeleton className={`${buttonSkeleton} ${edgeButtonSkeleton}`} />
						<Skeleton className={buttonSkeleton} />
						<Skeleton className={`${buttonSkeleton} ${edgeButtonSkeleton}`} />
					</Flex>
				</Box>
			</Flex>
			<Flex grow={1}>
				<Box width="100%">
					<Divider direction="horizontal" />
				</Box>
			</Flex>
		</Flex>
	);
}
