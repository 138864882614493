import { AbsolutePeriodRange } from '@sightfull/period-ranges';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';

function getAvailablePeriodRangeStart() {
	return sub(new Date(), { years: 2 });
}
function getAvailablePeriodRangeEnd() {
	return add(new Date(), { years: 2 });
}
export function getAvailablePeriodRange() {
	const start = getAvailablePeriodRangeStart();
	const end = getAvailablePeriodRangeEnd();
	return {
		fyear: AbsolutePeriodRange.fromUnitAndDates('fyear', start, end, fiscalYearOffset()),
		month: AbsolutePeriodRange.fromUnitAndDates('month', start, end, fiscalYearOffset()),
		fquarter: AbsolutePeriodRange.fromUnitAndDates('fyear', start, end, fiscalYearOffset()),
		week: AbsolutePeriodRange.fromUnitAndDates('fyear', start, end, fiscalYearOffset()),
		custom: AbsolutePeriodRange.fromUnitAndDates('custom', start, end, fiscalYearOffset()),
	};
}
