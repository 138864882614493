import { ReactNode } from 'react';
import colors from 'src/style/colors';
import Box from '../Box';
import Flex from '../Flex';
import Typography from '../Typography';

export type LabelsProps = {
	variant: 'filled' | 'outlined' | 'text';
	size?: 'small' | 'medium';
	colorScheme: 'primary' | 'neutral' | 'warning' | 'success' | 'error' | 'gray';
	children: ReactNode;
	startIcon?: ReactNode;
	endIcon?: ReactNode;
	style?: 'strong' | 'light';
};

const labelsVariantStyles = {
	text: {
		background: 'none',
		outline: 'unset',
	},
	outlined: {
		background: '#FFF',
		borderRadius: '2px',
		outline: `1px solid`,
	},
	filled: {
		borderRadius: '4px',
	},
};

const labelsLightColorScheme = {
	primary: {
		outlineColor: colors.blue[600],
		color: colors.blue[600],
		background: colors.blue[100],
	},
	neutral: {
		outlineColor: colors.gray[400],
		color: colors.gray[700],
		background: colors.gray[200],
	},
	warning: {
		outlineColor: colors.yellow[400],
		color: colors.yellow[800],
		background: colors.yellow[100],
	},
	success: {
		outlineColor: colors.green[300],
		color: colors.green[700],
		background: colors.green[100],
	},
	error: {
		outlineColor: colors.red[300],
		color: colors.red[700],
		background: colors.red[100],
	},
	gray: {
		outlineColor: colors.gray[300],
		color: colors.white,
		background: colors.gray[700],
	},
};

const labelsStrongColorScheme = {
	primary: {
		outlineColor: colors.blue[700],
		color: colors.white,
		background: colors.blue[700],
	},
	neutral: {
		outlineColor: colors.gray[700],
		color: colors.white,
		background: colors.gray[700],
	},
	warning: {
		outlineColor: colors.yellow[700],
		color: colors.white,
		background: colors.yellow[700],
	},
	success: {
		outlineColor: colors.green[700],
		color: colors.white,
		background: colors.green[700],
	},
	error: {
		outlineColor: colors.red[700],
		color: colors.white,
		background: colors.red[700],
	},
	gray: {
		outlineColor: colors.gray[300],
		color: colors.white,
		background: colors.gray[700],
	},
};

const labelsSizes = {
	small: {
		padding: '2px 8px',
	},
	medium: {
		padding: '4px 8px',
	},
};

export function Label({
	variant,
	size = 'medium',
	colorScheme,
	children,
	startIcon,
	endIcon,
	style = 'light',
}: LabelsProps) {
	const colorStyles =
		style === 'light' ? { ...labelsLightColorScheme[colorScheme] } : { ...labelsStrongColorScheme[colorScheme] };

	return (
		<Flex
			{...colorStyles}
			{...labelsVariantStyles[variant]}
			{...labelsSizes[size]}
			width="min-content"
			alignItems="center"
			gap="6px"
			flexWrap="nowrap"
		>
			{startIcon && <Box>{startIcon}</Box>}
			<Typography whiteSpace="nowrap" variant={size === 'small' ? 'Paragraph12M' : 'Paragraph14M'}>
				{children}
			</Typography>
			{endIcon && <Box>{endIcon}</Box>}
		</Flex>
	);
}
