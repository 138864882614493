import colors from 'src/style/colors';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { DataConnectorInfo } from '@components/TopNavigation/DataConnectorsModal/types';
import Image from '@components/Image';
import Tooltip from '@components/Tooltip';

export function DataConnectorCard({
	icon,
	name,
	onClick,
	isSelected,
}: Pick<DataConnectorInfo, 'icon' | 'name'> & {
	onClick: () => void;
	isSelected: boolean;
}) {
	return (
		<Flex border={'2px solid '} borderColor={isSelected ? colors.gray['900'] : 'transparent'} borderRadius={'12px'}>
			<Flex
				maxWidth={'129px'}
				width={'129px'}
				height={'116px'}
				padding={'0px 12px'}
				flexDirection={'column'}
				justifyContent={'start'}
				alignContent={'center'}
				alignItems={'center'}
				gap={'12px'}
				borderRadius={'12px'}
				border={`1px solid`}
				borderColor={isSelected ? 'transparent' : colors.gray['300']}
				onClick={onClick}
				_hover={{ borderColor: !isSelected && colors.gray['500'], cursor: 'pointer' }}
				transitionProperty="border-color"
				transitionDuration={isSelected ? '250ms' : ''}
				userSelect={'none'}
				pt={'24px'}
			>
				<Flex width={'40px'} height={'40px'} justifyContent={'center'} alignItems={'center'}>
					<Image src={icon} height={'40px'} width={'100%'} />
				</Flex>
				<Tooltip size={'sm'} label={name}>
					<Typography
						noOfLines={2}
						variant={'DesktopH10Regular'}
						textAlign={'center'}
						color={colors.gray['1000']}
						maxWidth={'105px'}
					>
						{name}
					</Typography>
				</Tooltip>
			</Flex>
		</Flex>
	);
}
