import mixpanel from 'mixpanel-browser';
import { isLocalDevelopment, isProductionMode } from 'src/config';

/*
 * https://developer.mixpanel.com/docs/javascript-quickstart
 */

export const initMixpanel = () => {
	mixpanel.init(window.webConfig?.mixpanelProjectToken, {
		debug: isLocalDevelopment,
		persistence: 'localStorage',
		// api_host: 'http://analytics.app.sightfull.com',
	});
	// The below is for hotjar to use
	(window as any).mixpanel = mixpanel;
};

export const track = (eventName: string, eventMetadata?: object) => {
	try {
		mixpanel.track(eventName, eventMetadata);
	} catch {
		if (!isProductionMode) {
			console.log('error', 'Mixpanel track event failed');
		}
	}
};

export const identifyMixpanel = (distinctId: string) => {
	try {
		mixpanel.identify(distinctId);
	} catch {
		if (!isProductionMode) {
			console.log('error', 'Mixpanel identify failed');
		}
	}
};

export const setProfilePropertiesMixpanel = (properties: object) => {
	try {
		Object.entries(properties).forEach(([property, value]) => {
			mixpanel.people.set(property, value);
		});
	} catch (e) {
		if (!isProductionMode) {
			console.log('error', 'Mixpanel setProperties failed', e);
		}
	}
};

export const registerSuperPropertiesMixpanel = (properties: object) => {
	try {
		mixpanel.register(properties);
	} catch (e) {
		if (!isProductionMode) {
			console.log('error', 'Mixpanel register failed', e);
		}
	}
};
