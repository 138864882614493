import BuilderTextInput from 'src/common/components/BuilderTextInput';
import Flex from 'src/common/components/Flex';
import { SelectOption } from 'src/common/components/Select/types';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { formatBuilderFeatureEditMode } from 'src/pages/MetricPage/utils/eventMetadata';
import { useReportEvent } from 'src/services/analytics';
import { EditorFeature } from '../EditPanel';
import { FormulaTooltip, SelectLabel, TooltipIcon } from './components';
import { BigSelectLabel } from './components/SelectLabel';

export function FormulaBuilder({
	selectedEntity,
	isFieldsDisabled,
	selectedFeature,
}: {
	selectedEntity?: SelectOption;
	isFieldsDisabled?: boolean;
	selectedFeature: EditorFeature;
}) {
	const { metricNameWithoutFlavor, objectsTypes } = useMetricDerivedState();
	const { metricBuilderState, upsertYAMLProperty } = useBuilderDerivedState();
	const { reportEvent } = useReportEvent({
		metricName: metricNameWithoutFlavor,
		feature: 'Metric Builder',
		editMode: formatBuilderFeatureEditMode(selectedFeature),
	});

	if (!metricBuilderState || metricBuilderState.type !== 'formula') return null;

	return (
		<>
			<BigSelectLabel text="Value" paddingBottom={'20px'} paddingTop={'12px'} />
			<Flex direction={'column'}>
				<Flex marginBottom={'12px'} align={'center'}>
					<SelectLabel
						color={'gray.1100'}
						text="Formula"
						trailingIcon={
							<TooltipIcon
								tooltipBody={
									<FormulaTooltip
										onHelpClicked={() =>
											reportEvent({
												event: 'metric-edit-help-clicked',
												metaData: {
													component: 'Formula',
												},
											})
										}
									/>
								}
							/>
						}
					/>
				</Flex>
				<BuilderTextInput
					fieldName="formula"
					placeholder={'Add formula'}
					validation={'None'}
					validationText={'Validation text'}
					readOnly={isFieldsDisabled}
					contextSettings={{
						entity: selectedEntity?.value || objectsTypes[0],
						metric: metricNameWithoutFlavor,
						includeTypes: ['formula_metric', 'metric', 'parameter', 'parameter_store'],
					}}
					text={metricBuilderState?.formula || ''}
					id={'formula'}
					onBlur={(newValue) => upsertYAMLProperty('formula', newValue, { shouldPreviewAfter: true })}
				/>
			</Flex>
		</>
	);
}
