import { Flex } from '@chakra-ui/react';
import BuilderTextInputButton from '@components/BuilderTextInput/BuilderTextInputButton';
import BuilderTextInputField from '@components/BuilderTextInput/BuilderTextInputField';
import { ComponentState, ValidationState } from '@components/BuilderTextInput/types';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { SuggestionsContextSettings } from 'src/lib/completions/hooks/useMonacoTextFieldProviders';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';

export type BuilderTextInputProps = {
	placeholder: string;
	validation: ValidationState;
	validationText: string;
	readOnly?: boolean;
	onChange?: (value: string) => void;
	contextSettings?: SuggestionsContextSettings;
	id: string;
	text?: string;
	onBlur?: (value?: string) => void;
	rightComponent?: ReactNode;
	rightIcon?: ReactNode;
	fieldName: string;
	initialState?: ComponentState;
	reportFields?: Record<string, any>;
	testId?: string;
};

export const BuilderTextInput = ({
	placeholder,
	validation,
	validationText,
	readOnly = false,
	onChange,
	contextSettings,
	id,
	text = '',
	onBlur,
	rightComponent,
	rightIcon,
	fieldName,
	initialState = 'Idle',
	reportFields,
	testId,
}: BuilderTextInputProps) => {
	const [isButton, setIsButton] = useState(initialState == 'Idle');
	const [inputText, setInputText] = useState(text);
	const [componentState, setComponentState] = useState<ComponentState>(initialState);
	const { metricBuilderState: builderState } = useBuilderDerivedState();

	const fieldsToReport = reportFields
		? reportFields?.fields
		: { fieldName, type: 'text-input', metric: builderState?.name };

	const { wrapWithReport } = useReportEvent({ ...fieldsToReport });

	useEffect(() => {
		if (inputText?.trim() != '') {
			setIsButton(false);
			return;
		}
		if (componentState != 'Focus' && componentState != 'Active') {
			setIsButton(true);
		}
	}, [inputText, setIsButton, componentState]);

	useEffect(() => {
		if (contextSettings?.entity) setInputText(text);
	}, [contextSettings?.entity, text]);

	const onClick = useCallback(() => {
		if (readOnly) {
			return;
		}
		setIsButton(false);
		setComponentState('Focus');
	}, [setIsButton, readOnly]);

	const borderColor = ({ isHover }: { isHover: boolean }) => {
		if (validation === 'Error' && !isButton) {
			return colors.red['400'];
		} else if (componentState === 'Focus' || componentState === 'Active') {
			return colors.gray['600'];
		} else if (isHover && !readOnly) {
			return colors.gray['500'];
		} else {
			return colors.gray['400'];
		}
	};

	const backgroundColor = useMemo(() => {
		return validation == 'Error' && !isButton ? colors.red['100'] : readOnly ? 'gray.100' : 'white';
	}, [validation, isButton, readOnly]);

	return (
		<Flex
			data-testid={testId}
			display={'flex'}
			alignItems={'center'}
			width={'100%'}
			gap={'10px'}
			data-intercom-area={'metric'}
			data-intercom-type={'main'}
			data-intercom-target={`builder-text-input-${fieldName}`}
		>
			{isButton ? (
				<BuilderTextInputButton
					placeholder={placeholder}
					validation={validation}
					onClick={onClick}
					borderColor={borderColor}
					backgroundColor={backgroundColor}
					rightContent={rightComponent}
					rightIcon={rightIcon}
				/>
			) : (
				<BuilderTextInputField
					readOnly={readOnly}
					onBlur={wrapWithReport(
						() => !readOnly && onBlur?.(inputText),
						reportFields?.name || 'metric-edit-UI-input-provided',
						{
							inputText: inputText,
						}
					)}
					onChange={onChange}
					validation={validation}
					inputText={inputText}
					setInputText={setInputText}
					componentState={componentState}
					setComponentState={setComponentState}
					borderColor={borderColor}
					backgroundColor={backgroundColor}
					validationText={validationText}
					contextSettings={contextSettings}
					rightContent={rightComponent}
					id={id}
					rightIcon={rightIcon}
				/>
			)}
		</Flex>
	);
};

export default BuilderTextInput;
