import { Box, Popover, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import { useCollectionsSubscriptions } from '@hooks/fetching/useCollectionsSubscriptions';
import { ReactNode, useEffect, useState } from 'react';
import useToast from 'src/common/hooks/ui/useToast';
import { useShareSignalApi } from 'src/common/hooks/useShareSignalApi';

import useDebouncedCallback from 'src/common/hooks/useDebouncedCallback';
import { GetCollectionsSubscription } from 'src/generated/graphql';
import { ScrollToBottomSearchParam } from 'src/pages/DashboardPage/hooks/useDashboardPageSearchParams';
import { ChooseSpaceModal } from 'src/pages/MetricPage/components/ShareSignalModal/ChooseSpaceModal';
import { FillDetailsModal, ModalDetails } from 'src/pages/MetricPage/components/ShareSignalModal/FillDetailsModal';
import { useTableColumnStateAsSearchParam } from 'src/pages/MetricPage/components/ShareSignalModal/useTableColumnStateAsSearchParam';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import { CollectionType, HeaderName } from 'src/types/signal';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { cleanSearchParams, useMetricPageSearchParams } from '../../hooks/useMetricPageSearchParams';
import classes from './ShareSignalModal.module.scss';

export function ShareSignalModal({
	metricTitle,
	description = '',
	isModalOpen,
	onModalClose = () => void 0,
	children,
	headerName,
}: {
	metricTitle: string;
	description?: string;
	isModalOpen: boolean;
	onModalClose: () => void;
	children: ReactNode;
	headerName: HeaderName;
}) {
	const { reportEvent } = useReportEvent();
	const { isOpen, onClose: onHookClose } = useDisclosure();
	const { rawDashboardsList, rawWorkspacesList } = useCollectionsSubscriptions();
	const [collectionType, setCollectionType] = useState<CollectionType>();
	const { navigate } = useNavigation();
	const { getColumnStateSearchParam } = useTableColumnStateAsSearchParam();

	const rawCollections: GetCollectionsSubscription | undefined =
		collectionType &&
		{
			workspace: rawWorkspacesList,
			dashboard: rawDashboardsList,
		}[collectionType];
	const [shareSignal, { mutationError, isLoading }] = useShareSignalApi();
	const showToast = useToast();
	const { metricNameWithFlavor, dangerousRawSearchParams } = useMetricPageSearchParams();
	const { periodRange, chartType } = useMetricDerivedState();

	const [, setPopoverHeight] = useState(0); // For popover responsive reinitialization when performing 'resize'.

	const debouncedResize = useDebouncedCallback(() => setPopoverHeight(window.innerHeight), 500);

	useEffect(() => {
		window.addEventListener('resize', debouncedResize);
		return () => {
			window.removeEventListener('resize', debouncedResize);
		};
	}, [debouncedResize]);

	useEffect(() => {
		if (mutationError) showToast({ variant: 'error', message: `Error sharing signal: ${mutationError.message}` });
	}, [mutationError, showToast]);

	const onClose = () => {
		onHookClose();
		onModalClose?.apply(null);
		setCollectionType(undefined);
		reportEvent({ event: 'signal-draft-discarded', metaData: { feature: `${headerName} header` } });
	};
	const onBack = () => {
		setCollectionType(undefined);
	};
	function onSubmit(
		{ collectionName, publishAs, description, title }: ModalDetails,
		rawCollectionsParam?: GetCollectionsSubscription
	) {
		const collections = rawCollections ?? rawCollectionsParam;
		const selectedCollectionIds = collections?.workspaces.filter((e) => e.name == collectionName)?.map((e) => e.id);
		if (!selectedCollectionIds?.length) return;

		const metricName = metricNameWithFlavor;
		const searchParams = new URLSearchParams(dangerousRawSearchParams);
		cleanSearchParams(searchParams);

		if (collectionType == 'dashboard' && periodRange.isRelativeSupported) {
			const relativeRange = periodRange.asRelativeRange;
			searchParams.set('periodRange', JSON.stringify(relativeRange.toUrlParams().periodRange));
		} else {
			const absoluteRange = periodRange.asAbsoluteRange;
			searchParams.set('from', absoluteRange.startPeriod.id);
			searchParams.set('to', absoluteRange.endPeriod.id);
			searchParams.set('periodRange', JSON.stringify(absoluteRange.toUrlParams().periodRange));
		}

		if (publishAs == 'table') {
			searchParams.set('chartType', 'table');
			searchParams.set('tableColumnState', getColumnStateSearchParam());
		} else {
			searchParams.set('chartType', chartType.selectedValue);
			searchParams.delete('selectedXAxisElements');
		}

		const encodedMetricName = encodeURIComponent(metricName);
		const currentMetricURL = encodeURI(`metric/${encodedMetricName}?${decodeURI(searchParams.toString())}`);

		shareSignal({
			attachmentContentURL: currentMetricURL,
			message: description,
			collectionToPin: selectedCollectionIds ?? [],
			collectionToUnPin: [],
			title: title,
		}).then(() => {
			reportEvent({
				event: 'submit-signal-draft',
				metaData: { message: description, userAttachment: currentMetricURL },
			});
			showToast({ variant: 'ok', message: 'Signal Saved' });
			navigate({
				path: `${collectionType}/${selectedCollectionIds}`,
				willIncludeAllSearchParams: false,
				additionalSearchParams: new URLSearchParams({ [ScrollToBottomSearchParam]: 'true' }),
			});
		});
	}

	const isChooseCollectionModal = !collectionType;
	const className = isChooseCollectionModal ? classes.chooseCollectionType : classes.fillDetailsModal;
	const modalContent = isChooseCollectionModal ? (
		<ChooseSpaceModal
			headerName={headerName}
			onSpaceClick={(space) => {
				reportEvent({
					event: 'signal-draft-pad-collection-chosen',
					metaData: { chosenCollection: space, feature: `${headerName} header` },
				});
				setCollectionType(space);
			}}
		/>
	) : (
		<FillDetailsModal
			metricTitle={metricTitle}
			description={description}
			onSubmit={onSubmit}
			collectionType={collectionType}
			shouldShowSelectCollection
			rawCollections={rawCollections}
			isLoading={isLoading}
			onClose={onClose}
			onBack={onBack}
			isShowingPublishAs
		/>
	);
	return (
		<>
			<Popover
				isOpen={isOpen || isModalOpen}
				onClose={onClose}
				placement="bottom-end"
				variant="strong"
				closeOnBlur={isChooseCollectionModal}
			>
				<PopoverTrigger>
					<Box>{children}</Box>
				</PopoverTrigger>
				<PopoverContent
					w={'420px'}
					boxShadow={'g-05'}
					borderRadius={'16px'}
					right="0px"
					border="1px solid gray"
					borderColor={'gray.200'}
				>
					<Box className={className} borderRadius={'16px'}>
						{modalContent}
					</Box>
				</PopoverContent>
			</Popover>
		</>
	);
}
