import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import TableCell from 'src/common/components/TableCell';
import { SuffixIcon } from 'src/common/components/TableCell/types';
import colors from 'src/style/colors';
import { useEntityProfileDrawer, useEntityProfileReportEvent } from '../../hooks';
import { ChangeStatus, Property, PropertyCard } from '../../types';
import { chooseIconForTableCell, filterCells, formattedDisplayValue } from '../../utilities';
import { TableFooter } from '../TableComponents/TableFooter';
import { TableHeader } from '../TableComponents/TableHeader';

export const PropertyTable = ({
	header,
	properties,
	pageSize = 9,
	onClick,
	testId,
	currentEntity,
	nodeObjectName,
}: {
	testId?: string;
	nodeObjectName: string;
	currentEntity: string;
	pageSize?: number;
	onClick: (property: Omit<Property, 'relationshipProperties'>) => void;
} & PropertyCard) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const { reportEvent } = useEntityProfileReportEvent('property-table');

	const isPaginationAvailable = properties?.length > pageSize;

	const filteredCells = useMemo(() => filterCells(properties, searchTerm), [properties, searchTerm]);

	const currentCells = useMemo(
		() => filteredCells?.slice((currentPage - 1) * pageSize, currentPage * pageSize),
		[currentPage, filteredCells, pageSize]
	);

	if (properties?.filter((p) => p.value != undefined)?.length == 0) {
		return <></>;
	}

	return (
		<Box pb={'16px'} data-testid={testId}>
			<Box border={`1px solid ${colors.gray[300]}`} borderRadius={'4px'} backgroundColor="white">
				<TableHeader
					header={header}
					searchValue={searchTerm}
					onChange={setSearchTerm}
					shouldAllowSearch={isPaginationAvailable}
				/>
				<Content
					properties={currentCells}
					onClick={onClick}
					currentEntity={currentEntity}
					nodeObjectName={nodeObjectName}
				/>
				{isPaginationAvailable && (
					<TableFooter
						currentPage={currentPage}
						totalItems={filteredCells?.length}
						pageSize={pageSize}
						onChange={(newPage) => {
							setCurrentPage(newPage);
							reportEvent({
								event: 'entity-table-navigation',
								metaData: {
									from: currentPage,
									to: newPage,
								},
							});
						}}
					/>
				)}
			</Box>
		</Box>
	);
};

const Content = ({
	properties,
	onClick,
	currentEntity,
	nodeObjectName,
}: {
	nodeObjectName: string;
	properties: Property[];
	onClick: (property: Omit<Property, 'relationshipProperties'>) => void;
	currentEntity: string;
}) => {
	const { reportOpenEntityDrawer, reportEvent } = useEntityProfileReportEvent('property-table', nodeObjectName);
	const { pushEntity } = useEntityProfileDrawer();

	function openRelatedEntity({ sightfullId }: { sightfullId: string; sourceSystemId: string }) {
		pushEntity(sightfullId);
		reportOpenEntityDrawer({
			origin: 'property-table',
			id: sightfullId,
		});
	}

	function openEntityOverrideModal(property: Property, cellLeadText: string, cellSupportingText: string) {
		onClick(property);
		reportEvent({
			event: 'open-property-details-modal',
			metaData: {
				supportingText: cellSupportingText,
			},
		});
	}

	return (
		<Box pb={'24px'} pt="16px" px={'24px'}>
			<Grid gridTemplateColumns="repeat(12, [col-start] 1fr)" gap={'16px'}>
				{properties.map((property, index) => {
					const {
						displayName: cellSupportingText,
						value,
						formatter: nodeSchemaFormatter,
						overrideProps,
						relationshipProperties,
					} = property;

					const cellLeadText = formattedDisplayValue({ overrideProps, value }, nodeSchemaFormatter) ?? '';
					const icon = chooseIconForTableCell(overrideProps?.status as ChangeStatus);
					const suffixIcons: SuffixIcon[] = icon ? ['copy', icon] : ['copy'];

					return (
						<GridItem
							data-testid={`property-${property.rawName}`}
							key={`${currentEntity}-${cellSupportingText}-${cellLeadText}-${index}`}
							colSpan={4}
							onClick={() =>
								relationshipProperties
									? openRelatedEntity(relationshipProperties)
									: openEntityOverrideModal(property, cellLeadText, cellSupportingText)
							}
							cursor={'pointer'}
						>
							<TableCell
								height={'100%'}
								cursor={'pointer'}
								shouldShowDivider={false}
								variant="textAndsupportingText"
								text={cellLeadText}
								supportingText={cellSupportingText}
								suffixIcon={suffixIcons}
								link={relationshipProperties?.sightfullId ?? undefined}
							/>
							<Box height={'1px'} boxShadow={'borderBottom'} />
						</GridItem>
					);
				})}
			</Grid>
		</Box>
	);
};
