import { useCallback } from 'react';
import colors from 'src/style/colors';
import Box from '../Box';
import Flex from '../Flex';
import { useObserveElementWidth } from '../MetricView/MetricChart/MetricChart';
import Typography from '../Typography';
import styles from './styles.module.scss';

export type BigBoldAttainmentNumberProps = {
	title?: string;
	actualValue: string;
	targetValue?: string;
};

export function BigBoldAttainmentNumber({ title, actualValue, targetValue }: BigBoldAttainmentNumberProps) {
	const { ref, width } = useObserveElementWidth<HTMLDivElement>();

	const calculateFonts = useCallback(() => {
		if (width <= 248) {
			return {
				lineHeight: '51px',
				fontSize: '38px',
			};
		}

		if (width <= 295) {
			return {
				lineHeight: '60px',
				fontSize: '47px',
			};
		}

		if (width <= 348) {
			return {
				lineHeight: '69px',
				fontSize: '56px',
			};
		}

		return {
			lineHeight: '85px',
			fontSize: '72px',
		};
	}, [width]);

	return (
		<Flex
			color={colors.gray[1000]}
			paddingX="24px"
			paddingBottom="24px"
			ref={ref}
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
			height="100%"
			width="100%"
		>
			{title && (
				<Box marginBottom="4px" whiteSpace="nowrap">
					<Typography variant="DesktopH8Medium">{title}</Typography>
				</Box>
			)}
			<Box color={colors.gray[1000]} fontWeight={600} {...calculateFonts()} marginBottom="12px">
				{actualValue}
			</Box>

			{targetValue && (
				<Flex flexWrap="wrap">
					{targetValue && (
						<Typography className={styles.rightBorder} color={colors.gray[800]} variant="Paragraph12M">
							{targetValue}&nbsp;
							<Typography color={colors.gray[700]} variant="Paragraph12R">
								Target
							</Typography>
						</Typography>
					)}
				</Flex>
			)}
		</Flex>
	);
}
