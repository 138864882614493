import { Box, Popover, PopoverArrow, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import { CQuestion14 } from 'src/common/components/Icons';
import colors from 'src/style/colors';

export function TooltipIcon({
	tooltipBody,
	icon = (
		<Box color={colors.gray[800]} _hover={{ color: colors.gray[1000] }} transition={'0.2s'}>
			<CQuestion14 />
		</Box>
	),
}: {
	tooltipBody: JSX.Element;
	icon?: JSX.Element;
}) {
	return (
		<Popover placement={'right'} variant="solid" arrowPadding={16} closeOnBlur={true} trigger="hover" isLazy>
			<PopoverTrigger>
				<Box cursor={'pointer'}>{icon}</Box>
			</PopoverTrigger>
			<Portal>
				<PopoverContent backgroundColor={'black'} padding={'10px 12px'} maxWidth={'324px'} borderRadius={'8px'}>
					<PopoverArrow backgroundColor={'black'} height={'32px'} width={'32px'} />
					{tooltipBody}
				</PopoverContent>
			</Portal>
		</Popover>
	);
}
