import useParams from 'src/common/hooks/navigation/useParams';

export function useSignalPageSearchParams() {
	const { dashboardId, workspaceId, signalId } = useParams();
	if (!dashboardId && !workspaceId) throw Error('No collectionId found in the url');
	if (!signalId) throw Error('No signalId found in the url');

	return {
		dashboardId,
		workspaceId,
		signalId,
	};
}
