import Button from '@components/Button';
import Center from '@components/Center';
import Typography from '@components/Typography';
import { default as SomethingWentWrong } from 'src/assets/images/something-went-wrong.svg?component';

function SomethingWentWrongError({ title, subtitle }: { title?: string; subtitle?: string }) {
	return (
		<Center
			height="calc(100vh + 50px)"
			width="100%"
			flexDirection="column"
			marginTop="-50px"
			position="fixed"
			background="white"
		>
			<SomethingWentWrong />
			<Typography paddingTop="28px" paddingBottom="10px" variant={'DesktopH4'}>
				{title ?? 'Oh no. Something went wrong.'}
			</Typography>
			<Typography paddingBottom="25px" color="gray.700" variant={'DesktopH7Regular'}>
				{subtitle ?? 'Go back and try again.'}
			</Typography>
			<Button
				variant="solid"
				colorScheme="gray"
				size="small"
				onClick={() => {
					history.back();
					const minimumUrlSetTime = 5;
					setTimeout(() => location.reload(), minimumUrlSetTime);
				}}
			>
				Go back
			</Button>
		</Center>
	);
}

export default SomethingWentWrongError;
