import { ChartSeries, SeriesDataPointObject } from '@components/Chart/types';
import { sortBySortOrder } from '@components/Chart/useSeries';
import set from 'lodash/set';
import { TransposedMetricData, transposeMetricData } from 'src/lib/metricRules/utils';
import { DisplayUnit, MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { MetricCalcResult } from 'src/types/metric';

function getPercentageRates({
	series,
	periodPrettified,
	currentSeries,
	yValue,
	displayUnits,
}: {
	series: ChartSeries[];
	periodPrettified: string;
	currentSeries: ChartSeries;
	yValue: number;
	displayUnits: MetricDerivedState['displayUnits'];
}): { percentage?: number; percentageFirst?: number; percentagePrev?: number } {
	let percentage;
	let percentageFirst;
	let percentagePrev;

	const mainSeriesValue = series
		.find((s) => s.custom.seriesType == 'main')
		?.data.find((o) => o.name == periodPrettified)?.y;
	const visibleSortedSeries = series
		.filter((s) => (s.custom.seriesOrder ?? -1) >= 0 && !!s.visible)
		.sort(sortBySortOrder);
	const currentSeriesVisibleIndex = visibleSortedSeries.findIndex(
		(s) => s.custom.seriesOrder == currentSeries.custom.seriesOrder
	);

	if (
		currentSeriesVisibleIndex > 1 ||
		(!displayUnits[DisplayUnit.percentagePrev].value && currentSeriesVisibleIndex > 0) ||
		currentSeries.custom.seriesOrder == -1
	) {
		const firstVisibleSeriesValue = visibleSortedSeries[0]?.data.find((o) => o.name == periodPrettified)?.y;
		percentageFirst = firstVisibleSeriesValue ? yValue / firstVisibleSeriesValue : undefined;
	}

	if (currentSeriesVisibleIndex > 0) {
		const prevSeriesValue = visibleSortedSeries[currentSeriesVisibleIndex - 1]?.data.find(
			(o) => o.name == periodPrettified
		)?.y;
		percentagePrev = prevSeriesValue ? yValue / prevSeriesValue : undefined;
	}

	if (mainSeriesValue && currentSeries.custom.seriesType != 'main') {
		percentage = yValue / mainSeriesValue;
	} else if (mainSeriesValue && currentSeries.custom.isTarget) {
		percentage = mainSeriesValue / yValue;
	}

	return { percentage, percentageFirst, percentagePrev };
}

function matchRawDataToSeries(rawData: TransposedMetricData[number], series: ChartSeries) {
	const rawName = rawData.rawName ?? rawData.name;
	const seriesName = series.custom.rawName ?? series.name;

	if (series.custom.seriesType == 'main' && rawData.type == 'TOTAL') {
		return true;
	}
	return rawName == seriesName;
}

function setDisplayUnitsSeries({
	series,
	metricCalcResult,
	displayUnits,
}: {
	series: ChartSeries[];
	metricCalcResult: MetricCalcResult;
	displayUnits: MetricDerivedState['displayUnits'];
}): ChartSeries[] {
	const transposedMetricData = transposeMetricData(metricCalcResult);

	const setSeriesDisplayUnits = (s: ChartSeries) => {
		const rawSeriesFromServer = transposedMetricData.find((rawData) => matchRawDataToSeries(rawData, s));
		const setDisplayUnitsInPoint = (data: SeriesDataPointObject) => {
			const matchingRawResult = rawSeriesFromServer?.results.find((r) => r.period.pretty == data.name);
			const { percentage, percentagePrev, percentageFirst } = getPercentageRates({
				series,
				periodPrettified: data.name,
				currentSeries: s,
				yValue: data.y ?? 0,
				displayUnits,
			});
			set(data, 'custom.percentage', displayUnits[DisplayUnit.percentage].value ? percentage : undefined);
			set(data, 'custom.percentagePrev', displayUnits[DisplayUnit.percentagePrev].value ? percentagePrev : undefined);
			set(
				data,
				'custom.percentageFirst',
				displayUnits[DisplayUnit.percentageFirst].value ? percentageFirst : undefined
			);

			return set(data, 'custom.count', displayUnits[DisplayUnit.count].value ? matchingRawResult?.count : undefined);
		};

		const data = s.data.map(setDisplayUnitsInPoint);

		return {
			...s,
			data,
		};
	};
	return series.map(setSeriesDisplayUnits);
}

export function calcDisplayUnitsSeries(
	metricData: MetricCalcResult,
	{ chartOptions, displayUnits }: Required<Pick<MetricDerivedState, 'chartOptions' | 'displayUnits'>>
): Pick<MetricDerivedState, 'chartOptions'> {
	const withDisplayUnitsSeries = setDisplayUnitsSeries({
		series: chartOptions.series,
		metricCalcResult: metricData,
		displayUnits,
	});

	return {
		chartOptions: {
			...chartOptions,
			series: withDisplayUnitsSeries,
		},
	};
}
