import { RadioGroup } from '@chakra-ui/react';
import ListItem from '@components/ListItem';
import Checkbox from 'src/common/components/Checkbox';
import Divider from 'src/common/components/Divider';
import { Radio } from 'src/common/components/Radio';
import Typography from 'src/common/components/Typography';
import { COLUMN_MODEL_TYPE, DIMENSIONS_RENDER_TYPES } from './consts';
import { getFirstObjectType } from './NodeScheme/utils';
import { FiltersAndBreakdownItemType, ListType } from './types';
import { filterStringByTerm } from 'src/common/utils/utils';

type DimensionsListProps = {
	dimensions: FiltersAndBreakdownItemType[];
	filteredDimensionsLength?: number;
	isSingleValueSelectable?: boolean;
	handleDimensionSelection: (
		dimension: FiltersAndBreakdownItemType,
		isSelected: boolean,
		isSingleValueSelectable: boolean
	) => void;
	handleListItemClick: (dimension: FiltersAndBreakdownItemType) => void;
};

function DimensionsList({
	dimensions,
	filteredDimensionsLength,
	handleDimensionSelection,
	handleListItemClick,
	isSingleValueSelectable = false,
}: DimensionsListProps) {
	return (
		<>
			{dimensions.map((dimension: FiltersAndBreakdownItemType, index) => {
				const label = dimension.isValueDerivedFromSearchTerm ? (
					<>
						{"Free text: '"}
						<span style={{ fontWeight: 'bold' }}>{dimension.label}</span>
						{"'"}
					</>
				) : (
					dimension.label
				);

				const objectTypeIndication =
					dimension.key.includes('.') &&
					!dimension.isSelectable &&
					((isHovering: boolean) => (
						<Typography variant={'Paragraph12R'} color={isHovering ? 'blue.700' : 'gray.700'}>
							{getFirstObjectType(dimension.key)}
						</Typography>
					));

				return (
					<ListItem
						size="sm"
						key={dimension.label + dimension.key}
						prefixComponent={
							dimension.isSelectable ? (
								isSingleValueSelectable ? (
									<Radio isChecked={dimension.isSelected} value={dimension.label} />
								) : (
									<Checkbox
										checkboxColor="blue.700"
										onChange={() => {
											handleDimensionSelection(dimension, !dimension.isSelected, false);
										}}
										isChecked={dimension.isSelected}
									/>
								)
							) : undefined
						}
						label={label}
						suffixComponent={objectTypeIndication}
						onClick={() => handleListItemClick(dimension)}
						testId={`dimension-${filteredDimensionsLength ? index + filteredDimensionsLength : index}`}
					/>
				);
			})}
		</>
	);
}

type Props = {
	type: ListType;
	dimensions: FiltersAndBreakdownItemType[];
	searchTerm: string;
	isAllSelected: boolean;
	isSingleValueSelectable?: boolean;
	handleDimensionSelection: (
		dimension: FiltersAndBreakdownItemType,
		isSelected: boolean,
		isSingleValueSelectable: boolean
	) => void;
	handleSelectAllDimensions: (isSelected: boolean) => void;
	handleDimensionClick: (dimension: FiltersAndBreakdownItemType) => void;
};

const Dimensions = ({
	type,
	dimensions,
	searchTerm,
	isAllSelected,
	isSingleValueSelectable = false,
	handleDimensionSelection,
	handleSelectAllDimensions,
	handleDimensionClick,
}: Props) => {
	const frontEndValuesListLengthLimit = 1000;
	const isColumn = type === COLUMN_MODEL_TYPE;
	const isSelectableItems = dimensions.length > 1 ? dimensions[0]?.isSelectable : false;
	const shouldDisplaySelectAll = !isColumn && isSelectableItems && !isSingleValueSelectable;
	const filteredDimensions = dimensions
		.filter((dimension: FiltersAndBreakdownItemType) => !dimension.isValueDerivedFromSearchTerm)
		.filter((dimension: FiltersAndBreakdownItemType) => {
			if (!DIMENSIONS_RENDER_TYPES.includes(typeof dimension.label)) {
				return false;
			}
			return filterStringByTerm(searchTerm, dimension.label);
		})
		.slice(0, frontEndValuesListLengthLimit);

	const searchTermDimensions = dimensions.filter(
		(dimension: FiltersAndBreakdownItemType) => dimension.isValueDerivedFromSearchTerm && dimension.label === searchTerm
	);
	const allDimensions: FiltersAndBreakdownItemType[] = [...filteredDimensions, ...searchTermDimensions];
	const shouldDisplayDivider = searchTermDimensions.length != 0;

	const handleListItemClick = (dimension: FiltersAndBreakdownItemType) => {
		if (dimension.isSelectable) handleDimensionSelection(dimension, !dimension.isSelected, isSingleValueSelectable);
		else handleDimensionClick(dimension);
	};

	const dimensionsList = (
		<>
			<DimensionsList
				dimensions={filteredDimensions}
				handleDimensionSelection={handleDimensionSelection}
				handleListItemClick={handleListItemClick}
				isSingleValueSelectable={isSingleValueSelectable}
			/>
			{shouldDisplayDivider && <Divider direction="horizontal" mt="8px" mb="8px" />}
			<DimensionsList
				dimensions={searchTermDimensions}
				handleDimensionSelection={handleDimensionSelection}
				handleListItemClick={handleListItemClick}
				filteredDimensionsLength={filteredDimensions.length}
				isSingleValueSelectable={isSingleValueSelectable}
			/>
		</>
	);

	return (
		<>
			{shouldDisplaySelectAll && (
				<ListItem
					size="sm"
					label="Select all"
					mb="16px"
					prefixComponent={
						<Checkbox
							checkboxColor="blue.700"
							onChange={(isSelected: boolean) => handleSelectAllDimensions(!isSelected)}
							isChecked={isAllSelected}
						/>
					}
					onClick={() => handleSelectAllDimensions(!isAllSelected)}
				/>
			)}
			{isSingleValueSelectable ? (
				<RadioGroup value={allDimensions.find((dimension) => dimension.isSelected)?.label}>{dimensionsList}</RadioGroup>
			) : (
				dimensionsList
			)}
		</>
	);
};

export default Dimensions;
