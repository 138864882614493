import { CircularProgress } from '@chakra-ui/react';
import Box from '@components/Box';
import Flex from '@components/Flex';
import * as DateFns from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { AccessController } from 'src/common/components/AccessController';
import Avatar from 'src/common/components/Avatar/Avatar';
import Button from 'src/common/components/Button';
import CopyLinkButton from 'src/common/components/CopyLinkButton';
import Divider from 'src/common/components/Divider';
import { BinSolid16, DashboardSpace16, DotsSolid16, DuplicateSolid16, Link16 } from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import Popover from 'src/common/components/Popover';
import TitleWithBreadcrumbs from 'src/common/components/TitleWithBreadcrumbs';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import useMutation from 'src/common/hooks/fetching/useMutation';
import { useOnCopy } from 'src/common/hooks/interaction/useOnCopy';
import useSearchParams from 'src/common/hooks/navigation/useSearchParams';
import useUser from 'src/common/hooks/stores/useUser';
import { useModal } from 'src/common/hooks/ui/useModal';
import useToast from 'src/common/hooks/ui/useToast';
import { useShareSignalApi } from 'src/common/hooks/useShareSignalApi';
import { SignalUpsertMutation, SignalUpsertMutationVariables } from 'src/generated/graphql';
import { UNTITLED_METRIC_DISPLAY } from 'src/lib/metricRules/builder/useMetricBuilder';
import { useTableColumnStateAsSearchParam } from 'src/pages/MetricPage/components/ShareSignalModal/useTableColumnStateAsSearchParam';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { cleanSearchParams, useMetricPageSearchParams } from 'src/pages/MetricPage/hooks/useMetricPageSearchParams';
import { DeleteDashboardFeedSignal } from 'src/queries/dashboards';
import { SignalUpsert } from 'src/queries/signals';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import { usePermissionCheck } from 'src/stores/environment';
import { tenantConfigSelectors, useTenantConfigStore } from 'src/stores/tenantConfig';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { Permission, Permissions } from 'src/types/environment';
import { CollectionMetadata } from 'src/types/spaces';
import { v4 as uuidv4 } from 'uuid';
import { SaveSignalButton } from '../../components/EditSignalModal/EditSignalModal';
import { useSignal } from '../../hooks/useSignal';
import { useSignalPageSearchParams } from '../../hooks/useSignalPageSearchParams';
import { ConfirmationDeleteModal } from '../ConfirmationDeleteModal';

export function Header({
	onCloseClicked,
	collectionTitle,
	collectionId,
	collectionType,
	isTiny,
	isParamsChanged,
	navigateToMetricPage,
	isActionsDisabled,
}: {
	onCloseClicked: VoidFunction;
	collectionTitle?: string;
	collectionId?: string;
	collectionType: string;
	isTiny?: boolean;
	isParamsChanged: boolean;
	navigateToMetricPage?: VoidFunction;
	isActionsDisabled?: boolean;
}) {
	const signal = useSignal();

	const [shareSignal, { isLoading: shareLoading, mutationError }] = useShareSignalApi(signal);
	const [upsertSignal, { loading: isLoading }] = useMutation<SignalUpsertMutation, SignalUpsertMutationVariables>(
		SignalUpsert
	);
	const [{ id: userId }] = useUser();
	const { metricNameWithFlavor, dangerousRawSearchParams } = useMetricPageSearchParams();
	const toast = useToast();
	const { reportEvent } = useReportEvent();
	const { periodRange } = useMetricDerivedState();
	const { getColumnStateSearchParam } = useTableColumnStateAsSearchParam();
	const { signalId } = useSignalPageSearchParams();
	const [searchParams] = useSearchParams();
	const isDuplicateSignal = searchParams.get('isDuplicateSignal') === 'true';

	const [signalValue, setSignalValue] = useState({
		...signal,
		title: isDuplicateSignal ? 'Duplicate of ' + (signal?.title || UNTITLED_METRIC_DISPLAY) : signal?.title,
	});

	const permissionsToCheck: Permission[] = [Permissions.updateAllSignals];
	if (signal?.author_id == userId) permissionsToCheck.push(Permissions.crudMySignals);
	const hasEditPermission = usePermissionCheck().isHavingOneOfPermissions(permissionsToCheck);

	const [deleteSelf, { loading: isDeletionLoading }] = useMutation(DeleteDashboardFeedSignal);
	const { navigate } = useNavigation();

	useEffect(() => {
		if (mutationError) toast({ variant: 'error', message: `Error sharing signal: ${mutationError.message}` });
	}, [mutationError, toast]);

	const onSaveAsNew = () => {
		if (collectionId) {
			const signalId = uuidv4();
			const metricName = metricNameWithFlavor;
			const searchParams = new URLSearchParams(dangerousRawSearchParams);

			cleanSearchParams(searchParams);
			const isRelative = periodRange.isRelativeSupported && collectionType == 'dashboard';
			const range = isRelative ? periodRange.asRelativeRange : periodRange.asAbsoluteRange;

			searchParams.set('periodRange', JSON.stringify(range.toUrlParams().periodRange));

			searchParams.delete('tableColumnState');
			if (searchParams.get('chartType') == 'table') {
				searchParams.set('tableColumnState', getColumnStateSearchParam());
			} else {
				searchParams.delete('selectedXAxisElements');
			}

			const attachment = encodeURI(`metric/${metricName}?${decodeURI(searchParams.toString())}`);
			reportEvent({
				event: 'signal-save-as-new-clicked',
				metaData: { 'widget-type': 'signal', 'collection-type': collectionType, feature: 'Signal header' },
			});
			if (collectionType === 'dashboard')
				shareSignal({
					attachmentContentURL: attachment,
					collectionToPin: [collectionId],
					collectionToUnPin: [],
					message: signalValue.message,
					title: (signalValue.title ?? metricNameWithFlavor) + ' (copy)',
				}).then((res) => redirectToNewSignal(res));
			else
				upsertSignal({
					variables: {
						attachment: attachment,
						message: signalValue.message,
						my_id: userId,
						title: signalValue.title + ' (copy)',
						signal_id: signalId,
						feed_insert_objects: [{ feed_id: collectionId, signal_id: signalId }],
						feed_delete_ids: [],
					},
				}).then((res) => redirectToNewSignal(res));
		}
	};

	const deleteSignal = () => {
		reportEvent({
			event: `signal-delete-confirm-clicked`,
			metaData: { feature: 'Signal header', 'widget-type': 'signal', 'collection-type': collectionType },
		});
		deleteSelf({
			variables: {
				feed_id: collectionId,
				signal_id: signalId,
			},
		}).then(() => {
			toast({ variant: 'ok', message: `Successfully deleted.` });
			onCloseClicked();
		});
	};

	const onSaved = ({ title, description: message }: CollectionMetadata) => {
		shareSignal({
			attachmentContentURL: signal?.attachment ?? '',
			message,
			collectionToPin: [],
			collectionToUnPin: [],
			title,
			signalId: signal?.id,
		}).then(() =>
			setSignalValue({
				...signalValue,
				message,
				title,
			})
		);
	};

	const redirectToNewSignal = (res: any) => {
		const signal_id = res.data?.insert_signals_one?.id;
		const additionalSearchParams = new URLSearchParams('isNewSaved=true');
		navigate({
			path: `${collectionType}/${collectionId}/signal/${signal_id}`,
			additionalSearchParams,
		});
	};

	const navigateToDuplicateSignal = () => {
		if (!isActionsDisabled) {
			reportEvent({
				event: 'signal-duplicate-signal-clicked',
				metaData: {
					feature: 'Signal header',
					signalId: signal?.id,
					collectionType,
					attachment: signal?.attachment,
					isSameUserSignal: signal?.author_id == userId,
					isSightfullSignal: signal?.author.email?.includes('@sightfull'),
				},
			});
			const additionalSearchParams = new URLSearchParams('isDuplicateSignal=true');
			navigate({
				path: `${collectionType}/${collectionId}/signal/${signalId}`,
				additionalSearchParams,
				isNewTab: true,
			});
		}
	};

	return (
		<header>
			{collectionTitle && (
				<Flex
					paddingX="32px"
					flexDirection="row"
					justifyContent="space-between"
					boxShadow={shadows.borderBottom}
					alignItems="center"
					maxHeight="66px"
				>
					<Flex maxW={'45%'}>
						<TitleWithBreadcrumbs
							onSubmit={({ component, title, description, previousTitle, previousDescription }) => {
								reportEvent({
									event: `signal-${component}-edited`,
									metaData: {
										feature: 'Signal header',
										previousTitle: previousTitle,
										currentTitle: title,
										previousDescription: previousDescription,
										currentDescription: description,
									},
								});
							}}
							isTiny={isTiny}
							title={signalValue?.title ?? ''}
							description={signalValue?.message ?? ''}
							onSignalSaved={({ description, title }) =>
								isDuplicateSignal
									? setSignalValue({
											message: description,
											title,
									  })
									: onSaved({ description, title })
							}
							isWithDescription={collectionType === 'dashboard' && !isTiny}
							breadcrumb={isDuplicateSignal ? '' : collectionTitle}
							onRedirect={() => {
								reportEvent({
									event: 'signal-navigate-to-space',
									metaData: { feature: 'Signal header', collectionId },
								});
								onCloseClicked();
							}}
							isEditBlocked={!hasEditPermission}
							isLoading={isDeletionLoading || shareLoading || isLoading}
						/>
					</Flex>
					<HeaderActions
						isActionsDisabled={isActionsDisabled}
						navigateToMetricPage={navigateToMetricPage}
						isLoading={isDeletionLoading || shareLoading || isLoading}
						title={signalValue?.title ?? ''}
						description={signalValue?.message ?? ''}
						isDuplicateLoading={shareLoading || isLoading}
						onSaveAsNew={onSaveAsNew}
						onCloseClicked={onCloseClicked}
						isTiny={isTiny}
						deleteSignal={deleteSignal}
						collectionTitle={collectionTitle}
						navigateToDuplicateSignal={navigateToDuplicateSignal}
						isDuplicateSignal={isDuplicateSignal}
						isParamsChanged={isParamsChanged}
						collectionType={collectionType}
					/>
				</Flex>
			)}
		</header>
	);
}

function HeaderActions({
	onCloseClicked,
	onSaveAsNew,
	title,
	description,
	isDuplicateLoading,
	isTiny,
	deleteSignal,
	isLoading,
	collectionTitle,
	navigateToDuplicateSignal,
	isDuplicateSignal,
	isParamsChanged,
	collectionType,
	navigateToMetricPage,
	isActionsDisabled,
}: {
	title: string;
	description: string;
	onCloseClicked: VoidFunction;
	onSaveAsNew: VoidFunction;
	navigateToDuplicateSignal: VoidFunction;
	isDuplicateLoading: boolean;
	isTiny?: boolean;
	deleteSignal: VoidFunction;
	isLoading?: boolean;
	collectionTitle: string;
	isDuplicateSignal?: boolean;
	isParamsChanged: boolean;
	collectionType: string;
	navigateToMetricPage?: VoidFunction;
	isActionsDisabled?: boolean;
}) {
	const { reportEvent } = useReportEvent();
	const signal = useSignal();
	const [{ id: userId }] = useUser();
	const name = `${signal?.author.first_name} ${signal?.author.last_name}`;
	const createdDateString = DateFns.format(new Date(signal?.created_at ?? 0), 'MMM dd, yyyy hh:mm a');
	const { metricNameWithFlavor } = useMetricDerivedState();
	const permissionsToCheck: Permission[] = [Permissions.updateAllSignals];
	if (signal?.author_id == userId) permissionsToCheck.push(Permissions.crudMySignals);
	const lastEtlSync = useTenantConfigStore(tenantConfigSelectors.getLastEtlSync);
	const memoCurrentDate = useMemo(() => new Date(), []);
	const { onCopyText } = useOnCopy();
	const { isOpen, onOpen, onClose } = useModal();

	function copyLink() {
		reportEvent({
			event: 'copy-url-clicked',
			metaData: { feature: 'Signal header', metricName: metricNameWithFlavor },
		});
		const currentURL = window.location.href;
		onCopyText({ contentToCopy: currentURL });
	}

	return (
		<>
			<Flex paddingY="12px" flexDirection="row-reverse" alignItems="center">
				<SaveSignalButton
					isParamsChanged={isParamsChanged}
					title={title}
					description={description}
					onSaveAsNew={onSaveAsNew}
					isDuplicateLoading={isDuplicateLoading}
					onCloseClicked={onCloseClicked}
					isDuplicateSignal={isDuplicateSignal}
					collectionType={collectionType}
				/>

				<>
					<Box height="28px">
						<Divider direction="vertical" color={colors.gray[400]} mr="12px" ml="12px" />
					</Box>

					<Popover
						popoverContentProps={{
							boxShadow: 'panelShadow',
							border: '1px solid',
							borderColor: 'gray.300',
						}}
						placement="bottom-start"
						triggerElement={(isOpen) => (
							<Box marginLeft={'4px'}>
								<Button isActive={isOpen} size="inline" isIconOnly variant="outline" colorScheme="black">
									<DotsSolid16 />
								</Button>
							</Box>
						)}
					>
						{isLoading ? (
							<Flex
								flexDirection={'column'}
								alignItems={'center'}
								justifyContent={'center'}
								width={'224px'}
								height={'240px'}
							>
								<CircularProgress
									isIndeterminate
									color="black"
									size="20px"
									padding="6px"
									key="text-signal-loading-indicator"
								/>
							</Flex>
						) : (
							<Box width={'224px'}>
								<Box paddingY="8px">
									{isTiny && (
										<ListItem color="gray.1000" size="sm" onClick={copyLink} prefixIcon={<Link16 />} label="Copy URL" />
									)}
									{!isDuplicateSignal && (
										<AccessController permission={permissionsToCheck} logic="OR">
											<ListItem
												state={isActionsDisabled ? 'disabled' : 'enabled'}
												color={isActionsDisabled ? 'gray.400' : 'gray.1000'}
												size="sm"
												onClick={navigateToDuplicateSignal}
												prefixIcon={<DuplicateSolid16 />}
												label="Duplicate"
											/>
										</AccessController>
									)}
									<ListItem
										color="gray.1000"
										size="sm"
										onClick={navigateToMetricPage}
										prefixIcon={<DashboardSpace16 />}
										label="Explore metric"
									/>
									{!isDuplicateSignal && (
										<AccessController permission={permissionsToCheck} logic="OR">
											<Box paddingY={'8px'} paddingX="12px">
												<Divider direction="horizontal" color={'gray.300'} />
											</Box>
											<ListItem
												color="gray.1000"
												size="sm"
												onClick={() => {
													reportEvent({
														event: 'signal-delete-clicked',
														metaData: {
															'widget-type': 'signal',
															'collection-type': collectionType,
															feature: 'Signal header',
														},
													});
													onOpen();
												}}
												prefixIcon={<BinSolid16 />}
												label="Delete"
											/>
										</AccessController>
									)}
								</Box>
								<Divider direction="horizontal" color={'gray.300'} />
								<Box color={'gray.800'} paddingX="16px">
									{lastEtlSync && (
										<Box color={'gray.700'} paddingY="16px">
											{isTiny && (
												<>
													<Typography marginBottom={'4px'} display={'block'} variant={'DesktopH10Medium'}>
														Created by {name}
													</Typography>
													<Typography marginBottom={'4px'} display={'block'} variant={'DesktopH10Regular'}>
														{createdDateString}
													</Typography>
												</>
											)}
											<Typography display={'block'} variant={'DesktopH10Regular'}>
												Last sync: {DateFns.formatDistance(memoCurrentDate, lastEtlSync)} ago
											</Typography>
										</Box>
									)}
								</Box>
							</Box>
						)}
					</Popover>
					<ConfirmationDeleteModal
						isOpen={isOpen}
						onSubmit={() => {
							deleteSignal();
							onClose();
						}}
						onClose={onClose}
						collectionTitle={collectionTitle}
					/>
				</>

				{!isTiny && (
					<>
						<CopyLinkButton
							onClick={() => {
								reportEvent({
									event: 'copy-url-clicked',
									metaData: { feature: 'Signal header', metricName: metricNameWithFlavor },
								});
							}}
						/>

						{!isDuplicateSignal && (
							<Flex marginRight="4px" gap={'16px'} flexDirection="row-reverse" alignItems="center">
								<Tooltip
									backgroundColor={'black'}
									size={'md'}
									variant="fluid"
									title={name}
									marginTop="4px"
									label={`Created on ${createdDateString}`}
								>
									<Box
										width={'32px'}
										height="32px"
										border="1px solid transparent"
										borderRadius="100%"
										borderStyle="solid"
										padding="3px"
										transition={'0.2s'}
										_hover={{ border: `1px solid ${colors.blue[700]}` }}
									>
										<Avatar
											width="24px"
											height="24px"
											imageUrl={signal?.author.picture ?? ''}
											name={name}
											shape="round"
										/>
									</Box>
								</Tooltip>
							</Flex>
						)}
					</>
				)}
			</Flex>
		</>
	);
}
