import Flex from '@components/Flex';
import { METRIC_COPIES } from '@pages/CreateNewMetricPage/copy';
import { ExploreAggregateMetric, ExploreFormulaMetric, ExploreAIMetric } from '@icons/index';
import Typography from '@components/Typography';
import Box from '@components/Box';
import Divider from '@components/Divider';
import { useReportEvent } from '@services/analytics';
import useNavigation from '@services/useNavigation';
import { MetricType } from 'src/common/types/common';
import { ReactNode } from 'react';
import { Label } from '@components/Labels';
import { useLocation } from 'react-router-dom';
import { ExploreNewMetricPagePath } from '@pages/CreateNewMetricPage/CreateNewMetricPage';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';

export type MenuItemProps = {
	icon: ReactNode;
	title: string;
	description: string;
	type?: MetricType;
	isComingSoon?: boolean;
};

export const ExploreMenu = ({ onClose }: { onClose: VoidFunction }) => {
	const { reportEvent } = useReportEvent();
	const { navigate } = useNavigation();
	const location = useLocation();
	const isCreateSightfullAIMetricOptionEnabled = useFeatureFlag(
		'pulse.sightfull2.createSightfullAIMetricOption.enable'
	);

	const getLink = (type?: MetricType) => {
		return `${ExploreNewMetricPagePath}/${type}`;
	};

	const onClick = (type?: MetricType) => {
		if (!type) return;

		reportEvent({
			event: 'create-new-metric-type-modal',
			metaData: { action: 'Create', feature: 'Explore', page: 'Menu', metricType: type },
		});
		navigate({
			path: getLink(type),
		});
		onClose();
	};

	const MenuItem = ({ icon, title, description, type, isComingSoon = false }: MenuItemProps) => {
		const isCurrentPage = location.pathname.includes(getLink(type));

		return (
			<Flex
				alignItems={'flex-start'}
				padding={'13px 16px'}
				gap={'12px'}
				borderRadius={'4px'}
				backgroundColor={isCurrentPage ? 'gray.200' : 'transparent'}
				_hover={{
					backgroundColor: isComingSoon ? 'transparent' : 'blue.100',
					cursor: isComingSoon ? 'default' : 'pointer',
				}}
				onClick={() => onClick(type)}
				data-intercom-area={'explore-menu'}
				data-intercom-type={'button'}
				// TODO: remove when AI page is enabled
				data-intercom-target={type || 'ai'}
			>
				<Box>{icon}</Box>
				<Flex flexDirection={'column'} gap={'8px'}>
					<Flex flexDirection={'column'} gap={'2px'}>
						<Typography variant="DesktopH8Medium" color={isComingSoon ? 'gray.600' : 'gray.1000'}>
							{title}
						</Typography>
						<Typography variant="Paragraph12R" color={isComingSoon ? 'gray.600' : 'gray.700'}>
							{description}
						</Typography>
					</Flex>
					{isComingSoon && (
						<Label size={'small'} style={'light'} variant={'filled'} colorScheme={'primary'}>
							Coming soon
						</Label>
					)}
				</Flex>
			</Flex>
		);
	};

	return (
		<Flex
			alignSelf="center"
			direction={'column'}
			p="8px"
			width="300px"
			borderRadius="8px"
			data-intercom-area={'topbar'}
			data-intercom-type={'menu'}
			data-intercom-target={'Explore'}
		>
			<MenuItem icon={<ExploreAggregateMetric width={'40px'} height={'40px'} />} {...METRIC_COPIES[0]} />
			<Box paddingX={'16px'}>
				<Divider color="gray.300" direction="horizontal" marginY="8px" />
			</Box>
			<MenuItem icon={<ExploreFormulaMetric width={'40px'} height={'40px'} />} {...METRIC_COPIES[1]} />
			{isCreateSightfullAIMetricOptionEnabled && (
				<>
					<Box paddingX={'16px'}>
						<Divider color="gray.300" direction="horizontal" marginY="8px" />
					</Box>
					<MenuItem icon={<ExploreAIMetric width={'40px'} height={'40px'} />} {...METRIC_COPIES[2]} />
				</>
			)}
		</Flex>
	);
};
