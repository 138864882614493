import Typography from 'src/common/components/Typography';
import { ExampleText, LearnMore } from './common';
import { LearnMoreProps } from './common/LearnMore';

const PERIOD_MACRO_LINK = 'https://docs.sightfull.com/core/metrics/syntax/x-axis#using-macros';

export function PeriodBasicTooltip({ onHelpClicked }: { onHelpClicked: LearnMoreProps['onClick'] }) {
	return (
		<Typography color={'white'} variant="Paragraph14R">
			Determines how entities are anchored to the time series X-axis, controlling when they contribute to the metric.
			Select a single date dimension that is within the period.&nbsp;
			<LearnMore onClick={onHelpClicked} link={PERIOD_MACRO_LINK} />.
			<ExampleText marginTop={'16px'} text={'$close_date'} />
			<ExampleText marginTop={'8px'} marginBottom={'4px'} text={'$created_date'} />
		</Typography>
	);
}
