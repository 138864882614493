import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { MetricPeriod } from '@sightfull/period-ranges';
import Typography from 'src/common/components/Typography';
import { DetailedTableIdGroupByV1, DetailedTableIdGroupByV2 } from 'src/common/utils/bizapiParsers';
import { useEntityProfileDrawer, useEntityProfileReportEvent } from 'src/layout/EntityProfileDrawer/hooks';
import { getDetailedTableMaxRows } from 'src/lib/metricRules/DerivedStateCalculators';
import { COLUMN_MODEL_TYPE } from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import { OnModalOpenType } from 'src/pages/MetricPage/components/FiltersAndBreakdown/types';
import Table from 'src/pages/MetricPage/components/Table/RawTable';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useIsGridTableV2Enabled } from '../../../../common/components/GridTable';
import { useAddAndRemoveTableColumns } from '../../hooks/useAddAndRemoveTableColumns';
import { useSelectedXAxisElements } from '../../hooks/useSelectedXAxisElement';
import { RawMetricTableV2 } from './RawTable/RawMetricTableV2';

type Props = {
	onModalOpen?: OnModalOpenType;
	selectedPeriod: MetricPeriod;
	isThumbnail?: boolean;
};

export function DetailedTable({ onModalOpen, selectedPeriod, isThumbnail = false }: Props) {
	const { reportOpenEntityDrawer } = useEntityProfileReportEvent();
	const { pushEntity } = useEntityProfileDrawer();
	const { tableRowsData, metricNameWithFlavor, breakdowns, tableType, tableColumnState } = useMetricDerivedState();
	const [, setSelectedXAxisElement] = useSelectedXAxisElements();

	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	const { handleAddColumns, removableTableColDefs } = useAddAndRemoveTableColumns();

	const isGridTableV2 = useIsGridTableV2Enabled();
	const RawTableImpl = isGridTableV2 ? RawMetricTableV2 : Table;

	return (
		<RawTableImpl
			title={`${selectedPeriod.pretty},`}
			subtitle={
				<>
					Showing{' '}
					<Typography as="span" variant="DesktopH8Medium">
						{tableRowsData.length.toLocaleString()}
						{tableRowsData.length === getDetailedTableMaxRows(tableType, breakdowns, tableColumnState) ? '+' : ''} rows
					</Typography>
				</>
			}
			data={tableRowsData}
			columnDefs={removableTableColDefs}
			onAddColumn={() => onModalOpen?.(COLUMN_MODEL_TYPE, handleAddColumns)}
			onRowClicked={(e) => {
				if (!e.data) return;
				const nodeId = isSightfull2 ? e.data[DetailedTableIdGroupByV2] : e.data[DetailedTableIdGroupByV1];
				reportOpenEntityDrawer({
					origin: 'detailed-table',
					id: nodeId,
					metaData: {
						metric: metricNameWithFlavor,
					},
				});
				pushEntity(nodeId);
			}}
			onCloseClicked={() => setSelectedXAxisElement({ xAxisElements: [] })}
			isThumbnail={isThumbnail}
			dataIntercomTarget="detailed-table"
		/>
	);
}
