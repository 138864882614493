import Box from '@components/Box';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import colors from 'src/style/colors';

export function NoResults({ searchTerm }: { searchTerm: string }) {
	return (
		<Flex minH={'100%'} direction={'column'} alignItems={'center'} paddingTop={'20%'}>
			<Box paddingBottom={'4px'}>
				<Typography variant={'DesktopH4'}>
					{"We couldn't find "}
					<Box as={'span'} color={colors.blue['700']}>
						‘{searchTerm}’
					</Box>
					.
				</Typography>
			</Box>
			<Typography variant={'Paragraph16R'} color={colors.gray['700']}>
				Try searching with another term.
			</Typography>
		</Flex>
	);
}
