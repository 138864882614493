import { DocumentNode, TypedDocumentNode, useQuery, useSubscription as useSubscriptionApollo } from '@apollo/client';
import { gql, OperationVariables } from '@apollo/client/core';
import { SubscriptionHookOptions } from '@apollo/client/react/types/types';
import { useEffect } from 'react';
import { isProductionMode } from 'src/config';

export function useSubscription<TData = any, TVariables extends OperationVariables = OperationVariables>(
	subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
	options?: SubscriptionHookOptions<TData, TVariables>
) {
	const { data, loading, error } = useSubscriptionApollo<TData, TVariables>(subscription, options);
	if (error) {
		if (!isProductionMode) {
			// TODO: implement error snackbar or something
			console.log('an error occurred');
			console.dir(error);
		}
	}
	return { data, loading, error };
}

export default useSubscription;

// https://github.com/apollographql/apollo-client/issues/5267
export const useHasuraSubscriptionWithCache = (queryDocument: DocumentNode, options?: any) => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const queryString = queryDocument.loc!.source.body;
	const subscriptionDocument = gql(queryString.replace('query', 'subscription'));

	const queryDocumentResult = useQuery(queryDocument, {
		variables: options?.variables,
	});

	useEffect(() => {
		if (queryDocumentResult?.subscribeToMore) {
			const unsubscribe = queryDocumentResult.subscribeToMore({
				document: subscriptionDocument,
				updateQuery: (_, curr) => {
					return curr.subscriptionData.data;
				},
				variables: options?.variables,
			});
			return () => unsubscribe();
		}
	}, [options?.variables, queryDocumentResult, subscriptionDocument]);

	return queryDocumentResult;
};
