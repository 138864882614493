import { Box, Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { AccessController } from 'src/common/components/AccessController';
import ModalHeader from 'src/common/components/ModalHeader';
import shadows from 'src/style/shadows';
import { Permissions } from 'src/types/environment';
import { FeedSignal } from 'src/types/spaces';
import { WorkspaceComment } from '../components/Comment/Comment';
import { CreateCommentTextArea } from '../components/CommentTextEditor/CreateCommentTextArea';

export function CommentSidebar({ signal, onClose }: { signal: FeedSignal; onClose: () => void }) {
	const { comments, title } = signal.signal;
	const commentComponents = useMemo(() => {
		return [...comments]
			.sort((c1, c2) => new Date(c2.created_at).getTime() - new Date(c1.created_at).getTime())
			?.map((comment) => <WorkspaceComment signal={signal} key={comment.id} comment={comment}></WorkspaceComment>);
	}, [comments, signal]);

	return (
		<Flex minWidth="420px" maxWidth="420px" direction={'column'} boxShadow={shadows.borderLeft} height="100%">
			<ModalHeader onClose={onClose} title={title || 'Comments'} size="md" />
			<Flex flexDir={'column-reverse'} grow={1} overflowY="auto" overflowX={'hidden'}>
				{commentComponents}
			</Flex>

			<AccessController permission={Permissions.crudMyComments} noAccessChild={null}>
				<Box p="12px" height={'144px'}>
					<CreateCommentTextArea signal={signal} />
				</Box>
			</AccessController>
		</Flex>
	);
}
