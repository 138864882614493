import Box from '@components/Box';
import Flex from '@components/Flex';
import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonPeriodPicker.module.scss';

const { periodCell, first, selectorSkeleton, arrowSkeleton } = classes;

function SkeletonPeriodCell() {
	return (
		<Flex flexDirection="row" alignItems="center" justifyContent="end" overflow="hidden" className={periodCell}>
			<Box>
				<Skeleton className={classes.presetSkeleton} />
			</Box>
		</Flex>
	);
}

function SkeletonPeriodCells() {
	return (
		<>
			<SkeletonPeriodCell />
			<SkeletonPeriodCell />
			<SkeletonPeriodCell />
		</>
	);
}

function SkeletonPeriodSelector() {
	return (
		<Flex
			flexDirection="row"
			alignItems="center"
			justifyContent="end"
			overflow="hidden"
			className={`${periodCell} ${first}`}
		>
			<Box width="110px" height="42px">
				<Skeleton className={selectorSkeleton} />
			</Box>
			<Box>
				<Skeleton className={arrowSkeleton} />
			</Box>
		</Flex>
	);
}

export function SkeletonPeriodPicker() {
	return (
		<Flex width="254px" height="42px">
			<SkeletonPeriodSelector />
			<SkeletonPeriodCells />
		</Flex>
	);
}
