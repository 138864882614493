import { Box } from '@chakra-ui/react';
import Typography from '@components/Typography';
import { useMemo } from 'react';
import Flex from 'src/common/components/Flex';
import { CombinedMetric48, SightfullAI48, SimpleMetric48 } from 'src/common/components/Icons';
import { MetricType } from 'src/common/types/common';
import shadows from 'src/style/shadows';
import { METRIC_COPIES } from '../copy';

export const NewMetricType = ({
	type,
	isSelected,
	onClick,
	isComingSoon,
	isDisabled,
}: {
	type?: MetricType;
	isSelected: boolean;
	onClick: VoidFunction;
	isComingSoon?: boolean;
	isDisabled?: boolean;
}) => {
	const isFormula = type === 'formula';
	const metricCopy = useMemo(() => METRIC_COPIES.find((metric) => metric.type === type), [type]);
	const isActive = !isDisabled && !isComingSoon;

	const img = useMemo(() => {
		if (isComingSoon) return <SightfullAI48 />;
		if (isFormula) {
			return <CombinedMetric48 />;
		} else {
			return <SimpleMetric48 />;
		}
	}, [isComingSoon, isFormula]);

	const cursor = useMemo(() => {
		if (isComingSoon) return 'initial';
		if (isDisabled) {
			return 'not-allowed';
		} else {
			return 'pointer';
		}
	}, [isComingSoon, isDisabled]);

	const hoverBorderColor = useMemo(() => {
		if (isComingSoon) return 'gray.300';
		if (isSelected) return 'gray.900';
		if (isDisabled) {
			return 'gray.600';
		} else {
			return 'gray.500';
		}
	}, [isComingSoon, isSelected, isDisabled]);

	const CardText = (
		<>
			<Typography color={isComingSoon ? 'natural.600' : 'gray.1100'} marginBottom={'8px'} variant="DesktopH7Medium">
				{metricCopy?.title}
			</Typography>
			<Typography color={isComingSoon ? 'natural.600' : 'gray.1100'} variant="DesktopH8Regular">
				{metricCopy?.description}
			</Typography>
			{metricCopy?.subDescription && (
				<Typography color={'gray.900'} marginTop={'12px'} variant="DesktopH10Regular">
					{metricCopy?.subDescription}
				</Typography>
			)}
		</>
	);

	const CardContent = (
		<Flex position={'relative'}>
			<Box>{img}</Box>
			<Flex marginLeft={'16px'} flexDirection={'column'}>
				{CardText}
			</Flex>
			{isComingSoon && (
				<Flex
					backgroundColor={'blue.100'}
					padding={'4px 12px'}
					justifyContent={'center'}
					alignItems={'center'}
					borderBottomLeftRadius={'8px'}
					borderTopRightRadius={'8px'}
					position={'absolute'}
					right={'-16px'}
					top={'-16px'}
				>
					<Typography variant={'Paragraph14M'} color={'blue.600'}>
						Coming soon
					</Typography>
				</Flex>
			)}
		</Flex>
	);

	return (
		<Box
			opacity={isDisabled ? 0.3 : 1}
			cursor={cursor}
			onClick={() => isActive && onClick()}
			transition={'0.1s'}
			_hover={{ borderColor: hoverBorderColor }}
			border={'1px solid'}
			borderColor={isSelected ? 'gray.900' : isDisabled ? 'gray.600' : 'gray.300'}
			width={'100%'}
			boxShadow={isSelected ? shadows.focus.medium.gray[100] : 'none'}
			borderRadius={'8px'}
			p={'16px'}
			data-intercom-area={'main'}
			data-intercom-type={'main'}
			data-intercom-target={`new-metric-type-${type ?? 'new-metric-type'}`}
		>
			{CardContent}
		</Box>
	);
};
