import { Flex, Modal, ModalContent } from '@chakra-ui/react';
import Divider from '@components/Divider';
import Input from '@components/Input';
import Select from '@components/Select';
import { useKeyPress } from '@hooks/interaction/useKeyPress';
import useEntitySearch from '@hooks/stores/useEntityState';
import { Search16 } from '@icons/index';
import { useEffect } from 'react';
import { SelectOption } from 'src/common/components/Select/types';
import { analyticsMetadata, searchEntityOptions } from 'src/common/types/searchEntity';
import { SearchResultsPath } from 'src/pages/SearchResultsPage';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import colors from 'src/style/colors';
import classes from './Searchbox.module.scss';
import { useSearchOptions } from './useSearchOptions';

export interface SearchBoxProps {
	isOpen: boolean;
	onClose: () => void;
	onOpen: () => void;
	testId?: string;
}

interface InnerSearchBoxProps extends SearchBoxProps {
	searchOptions: SelectOption[];
}

export function SearchBoxV1({ isOpen, onClose, onOpen, testId }: SearchBoxProps) {
	const v1Options: SelectOption[] = [
		{ label: 'All data', value: searchEntityOptions.map((v) => v.value).join(',') },
		...searchEntityOptions,
	];

	return <SearchBox isOpen={isOpen} onClose={onClose} onOpen={onOpen} testId={testId} searchOptions={v1Options} />;
}

export function SearchBoxV2({ isOpen, onClose, onOpen, testId }: SearchBoxProps) {
	const { v2Options } = useSearchOptions(isOpen);
	return <SearchBox isOpen={isOpen} onClose={onClose} onOpen={onOpen} testId={testId} searchOptions={v2Options} />;
}

function SearchBox({ isOpen, onClose, onOpen, testId, searchOptions }: InnerSearchBoxProps) {
	const { reportEvent } = useReportEvent();
	const toggleModal = isOpen ? onClose : onOpen;
	const keypressEvent = (metaData: any) => {
		return () => {
			const event = 'search-data-modal-keypress-toggle';
			reportEvent({ event, metaData: { ...analyticsMetadata, metaData } });
			toggleModal();
		};
	};

	useKeyPress(['k'], keypressEvent({ isWithMetaKey: true }), { isWithMetaKey: true });
	useKeyPress(['k'], keypressEvent({ isWithCtrlKey: true }), { isWithCtrlKey: true });

	const { navigate } = useNavigation();
	const [{ selectedCategory, value: searchValue }, { setValue, setCategory, reset }] = useEntitySearch();

	const filteredOptions = searchOptions.filter((v) => v != selectedCategory);

	useEffect(() => {
		const isStateEmpty = !selectedCategory.label && !selectedCategory.value;

		if (isStateEmpty) {
			setCategory(searchOptions[0]);
		}
	}, [searchOptions, selectedCategory, setCategory]);

	const onEnter = (newValue?: SelectOption) => {
		const category = newValue ?? (selectedCategory.value != '' ? selectedCategory : searchOptions[0]);
		if (!searchValue || !category.value) return;

		reportEvent({
			event: 'global-search-enter',
			metaData: { ...analyticsMetadata, category: category.value, searchTerm: searchValue },
		});
		onClose();
		const searchPath = `${SearchResultsPath}/${encodeURIComponent(searchValue)}/${category.value}`;
		navigate({ path: searchPath });
		reset();
	};

	const onSelectChange = (newOption: SelectOption) => {
		setCategory(newOption);
		if (newOption && searchValue) onEnter(newOption);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
			<ModalContent className={classes.modal} top={'176px'}>
				<div className={classes.searchBox}>
					<Flex grow={1}>
						<Input
							placeholder={'Search for anything'}
							size={'lg'}
							shouldShowAutocomplete={false}
							onEnter={() => onEnter()}
							isErasable
							variant={'ghost'}
							leftComponent={<Search16 />}
							onChange={setValue}
							value={searchValue}
							testId={`${testId}-searchbox`}
						/>
					</Flex>
					<Divider direction={'vertical'} color={colors.gray['800']} />
					<Select
						menuStyles={{ margin: '16px 0px 0px 0px' }}
						width="250px"
						size={'large'}
						options={filteredOptions}
						onChange={onSelectChange}
						selectedOption={selectedCategory}
						testId={`${testId}-select`}
						isGhost
					/>
				</div>
			</ModalContent>
		</Modal>
	);
}

export default SearchBox;
