const sqlString = (v: string): string => {
	const escaped = v.replace(/'/g, "''");
	return `'${escaped}'`;
};

export function formatSqlString(v: unknown): string {
	if (Array.isArray(v)) {
		return `(${v.map(formatSqlString).join(', ')})`;
	}

	if (v === null || v === undefined) {
		return sqlString('');
	}

	if (typeof v === 'string') {
		return sqlString(v);
	}
	if (v instanceof Date) {
		return sqlString(v.toISOString());
	}
	if (typeof v === 'boolean') {
		return sqlString(v ? 'true' : 'false');
	}

	if (typeof v === 'object') {
		throw new Error(`Unimplemented error: ${typeof v} is not supported.`);
	}
	return sqlString(v.toString());
}

export function filterToSqlFilter(filterKey: string, filterValues: (string | number | boolean | undefined)[]) {
	if (Array.isArray(filterValues) && filterValues.length > 1) {
		return { sql: `${filterKey} IN ${formatSqlString(filterValues)}` };
	}
	if (filterValues.length === 0) {
		return { sql: '' };
	}

	return { sql: `${filterKey} = ${formatSqlString(filterValues[0])}` };
}
