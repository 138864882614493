import { gql } from '@apollo/client';
import { SignalFields } from './signals';

export const DashboardCollectionFragment = gql`
	fragment DashboardCollectionFragment on dashboards {
		id
		name
		order
		collection_type
	}
`;

export const DashboardsList = gql`
	${DashboardCollectionFragment}
	subscription DashboardsList {
		dashboards(order_by: [{ order: asc }, { created_at: asc }]) {
			...DashboardCollectionFragment
		}
	}
`;

export const DashboardSignalFragment = gql`
	${SignalFields}
	fragment DashboardSignalFragment on feed_signals {
		signal_id
		feed_id
		signal {
			...SignalFields
		}
		order
		resolved
		reason
		signal_height
		signal_width
		display_options
	}
`;
export const DashboardFragment = gql`
	${DashboardSignalFragment}
	fragment DashboardFragment on dashboards {
		id
		name
		description
		order
		layout
		feed {
			feed_signals {
				...DashboardSignalFragment
			}
		}
	}
`;
export const DashboardGetSingle = gql`
	${DashboardFragment}
	query DashboardGetSingle($id: uuid, $my_id: uuid) {
		dashboards(where: { id: { _eq: $id } }) {
			...DashboardFragment
		}
	}
`;

export const DashboardGetSingleByOrder = gql`
	${DashboardFragment}
	query DashboardGetSingleByOrder($offset: Int!, $my_id: uuid) {
		dashboards(limit: 1, offset: $offset, order_by: [{ order: asc }, { created_at: asc }]) {
			...DashboardFragment
		}
	}
`;

export const UpdateDashboardLayout = gql`
	mutation UpdateDashboardLayout($layout: jsonb!, $id: uuid!) {
		update_workspaces_by_pk(pk_columns: { id: $id }, _set: { layout: $layout }) {
			layout
		}
	}
`;

export const UpdateDashboardSignalWidgetType = gql`
	mutation UpdateDashboardSignalWidgetType($id: uuid = "", $widget_type: String = "") {
		update_signals_by_pk(pk_columns: { id: $id }, _set: { widget_type: $widget_type }) {
			widget_type
			id
		}
	}
`;

export const MigrateDashboardFeedSignalDisplayOptions = gql`
	mutation MigrateDashboardFeedSignalDisplayOptions(
		$feed_id: uuid = ""
		$signal_id: uuid = ""
		$display_options: jsonb = ""
		$message: String = ""
	) {
		update_feed_signals_by_pk(
			pk_columns: { feed_id: $feed_id, signal_id: $signal_id }
			_set: { display_options: $display_options }
		) {
			display_options
			feed_id
			signal_id
		}

		update_signals_by_pk(pk_columns: { id: $signal_id }, _set: { message: $message }) {
			message
			id
		}
	}
`;

export const DeleteDashboardFeedSignal = gql`
	mutation DeleteDashboardFeedSignal($feed_id: uuid = "", $signal_id: uuid = "") {
		delete_feed_signals_by_pk(feed_id: $feed_id, signal_id: $signal_id) {
			signal_id
			feed_id
		}
	}
`;
