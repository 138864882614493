import { useCallback } from 'react';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricPageSearchParams } from '../../hooks/useMetricPageSearchParams';
import { Breakdowns, Filter } from '../../utils/state.types';
import { addPrefixDollarSignIfNeeded } from '../FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { FiltersAndBreakdownItemType } from '../FiltersAndBreakdown/types';

type Actions = {
	addDimensionsParamsByType: (
		type: ParamType,
		items: Required<Pick<FiltersAndBreakdownItemType, 'key' | 'value'>>[]
	) => void;
	removeParamByKey: (type: ParamType, key: string) => void;
	removeAllParamsByType: (type: ParamType) => void;
};

type State = {
	filters: Filter[];
	breakdowns: Breakdowns;
};

type ReturnType = [State, Actions];

type ParamType = 'filterBy' | 'groupBy';

export const getFilterParams = (items: FiltersAndBreakdownItemType[]) => {
	const { key } = items?.[0] || '';
	const filtersParams: Record<string, (string | number | boolean | undefined)[]> = { [key]: [] };

	for (const item of items) {
		filtersParams[key].push(item.value);
	}

	return filtersParams;
};

const getBreakdownParams = (items: FiltersAndBreakdownItemType[]) =>
	items.reduce((obj, { key }) => ({ ...obj, [key]: null }), {});

function useDimensionsState(): ReturnType {
	const { filters, breakdowns } = useMetricDerivedState();
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	const { searchParams, setSearchParams } = useMetricPageSearchParams();

	const buildParams = useCallback((type: ParamType, items: FiltersAndBreakdownItemType[]) => {
		switch (type) {
			case 'filterBy':
				return getFilterParams(items);
			case 'groupBy':
				return getBreakdownParams(items);
			default:
				break;
		}
	}, []);

	const addDimensionsParamsByType = useCallback(
		(type: ParamType, items: Required<Pick<FiltersAndBreakdownItemType, 'key' | 'value'>>[]) => {
			if (!Array.isArray(items)) {
				return;
			}
			const newParams = buildParams(type, items) || {};
			const currentParams = searchParams[type] || {};

			setSearchParams({
				...searchParams,
				[type]: { ...{ orderedComponents: undefined, sortOrder: undefined }, ...currentParams, ...newParams },
				orderedComponents: undefined,
				sortOrder: undefined,
				tableColumnState: undefined,
			});
		},
		[buildParams, searchParams, setSearchParams]
	);

	const removeAllParamsByType = useCallback(
		(type: ParamType) => {
			setSearchParams({ ...searchParams, [type]: undefined });
		},
		[searchParams, setSearchParams]
	);

	const removeParamByKey = useCallback(
		(type: ParamType, key: string) => {
			const paramsByType = searchParams[type];
			let params = { ...paramsByType };

			if (isSightfull2) {
				params = Object.fromEntries(Object.entries(params).map(([k, v]) => [addPrefixDollarSignIfNeeded(k), v]));
			}

			if (key in params) {
				delete params[key];
			}

			const hasParams = Object.keys(params).length;
			setSearchParams({
				...searchParams,
				[type]: hasParams ? params : undefined,
				orderedComponents: undefined,
				sortOrder: undefined,
				tableColumnState: undefined,
			});
		},
		[isSightfull2, searchParams, setSearchParams]
	);

	return [
		{ filters, breakdowns },
		{ addDimensionsParamsByType, removeParamByKey, removeAllParamsByType },
	];
}

export default useDimensionsState;
