import { AbsolutePeriodRange, PeriodUnit, RelativePeriodRange } from '@sightfull/period-ranges';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';

const DEFAULT_LOOKBACK_MONTHS = 24;
const FUTURE_LOOKAHEAD_MONTHS = 24;

export function calcAvailablePeriods({
	lookbackMonths,
}: {
	lookbackMonths?: number;
}): Pick<MetricDerivedState, 'availablePeriodRanges'> {
	lookbackMonths = lookbackMonths ?? DEFAULT_LOOKBACK_MONTHS;
	return {
		availablePeriodRanges: {
			fyear: getPeriodRangeShiftedByLookbackMonths(lookbackMonths, 'fyear'),
			fquarter: getPeriodRangeShiftedByLookbackMonths(lookbackMonths, 'fquarter'),
			month: getPeriodRangeShiftedByLookbackMonths(lookbackMonths, 'month'),
			fweek: getPeriodRangeShiftedByLookbackMonths(lookbackMonths, 'fweek'),
			week: getPeriodRangeShiftedByLookbackMonths(lookbackMonths, 'week'),
			custom: AbsolutePeriodRange.fromUnitAndDates(
				'custom',
				getPeriodRangeShiftedByLookbackMonths(lookbackMonths, 'day').startDate,
				getPeriodRangeShiftedByLookbackMonths(lookbackMonths, 'day').endDate,
				fiscalYearOffset()
			),
		},
	};
}

function getPeriodRangeShiftedByLookbackMonths(lookbackMonths: number, unit: PeriodUnit) {
	return new RelativePeriodRange(
		{ startShift: -lookbackMonths, startUnit: 'month', endShift: FUTURE_LOOKAHEAD_MONTHS, endUnit: 'month' },
		unit,
		fiscalYearOffset()
	).asAbsoluteRange;
}
