import { AbsolutePeriodRange, MetricPeriod, PeriodUnit } from '@sightfull/period-ranges';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { RawRelevantPeriodRanges } from '../utils';

export function calcAvailablePeriodRanges(
	rawRelevantPeriodRanges: RawRelevantPeriodRanges
): Pick<MetricDerivedState, 'availablePeriodRanges'> {
	type relevantPeriodEntry = [PeriodUnit, [MetricPeriod, MetricPeriod]];

	const relevantPeriodRangesEntries = Object.entries(rawRelevantPeriodRanges).map(
		([periodUnit, [startIdString, endIdString]]) =>
			[
				periodUnit as PeriodUnit,
				[
					MetricPeriod.fromIdString(startIdString, fiscalYearOffset()),
					MetricPeriod.fromIdString(endIdString, fiscalYearOffset()),
				],
			] as relevantPeriodEntry
	);

	const availablePeriodRanges = Object.fromEntries(
		relevantPeriodRangesEntries.map(([key, value]) => {
			return [key == 'quarter' ? 'fquarter' : key, new AbsolutePeriodRange(value[0], value[1], fiscalYearOffset())];
		})
	);

	return { availablePeriodRanges };
}
