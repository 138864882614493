import { MetricComponent } from 'src/pages/MetricPage/components/LegendsPanel/types';

const METRIC_COMPONENT_KEY_SEPERATOR = '-SEPERATOR-';

export function buildMetricComponentKey(component: MetricComponent): string {
	return `${component.value}${METRIC_COMPONENT_KEY_SEPERATOR}${component.label}`;
}

export function splitMetricComponentKey(metricComponentKey: string) {
	const [componentRawNameUriEncoded, componentDisplayLegendValue] =
		metricComponentKey.split(METRIC_COMPONENT_KEY_SEPERATOR);
	return { componentRawNameUriEncoded, componentDisplayLegendValue };
}
