import { Flex } from '@chakra-ui/react';
import Typography from 'src/common/components/Typography';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { EntityLabel, labelPretty } from 'src/common/utils/searchEntity';

export function PageTitle({
	searchText = '',
	isAllSearch,
	searchSingleObject,
}: {
	searchText?: string;
	isAllSearch: boolean;
	searchSingleObject: EntityLabel;
}) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	const searchCategoryV1 = isAllSearch ? 'All data' : labelPretty(searchSingleObject);
	const searchCategoryV2 = isAllSearch ? 'All' : searchSingleObject;
	const searchCategory = isSightfull2 ? searchCategoryV2 : searchCategoryV1;

	return (
		<Flex direction="column" gap="12px" pb="8px">
			<Typography variant="DesktopH4Medium" color="gray.1000">
				{'Search results'}
			</Typography>
			<Typography variant="DesktopH8Regular" color="gray.800">
				{'Results for '}
				<Typography variant="DesktopH8Medium" color="gray.800">
					{`'${searchText}'`}
				</Typography>
				{' in '}
				<Typography variant="DesktopH8Medium" color="gray.800">
					{searchCategory}
				</Typography>
			</Typography>
		</Flex>
	);
}
