import { Flex } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { ActionChips, DynamicChips } from 'src/common/components/MetricView/InteractiveMetricChat/ActionChips';
import { ChatCard } from 'src/common/components/MetricView/InteractiveMetricChat/components/ChatCard';
import { useChatSession } from 'src/common/components/MetricView/InteractiveMetricChat/hooks/useChatSession';
import { useDynamicChips } from 'src/common/components/MetricView/InteractiveMetricChat/hooks/useDynamicChips';
import {
	ActionVariants,
	ChatMessage,
	PromptAction,
	PromptActions,
	convertChatHistoryToConversation,
} from 'src/common/components/MetricView/InteractiveMetricChat/normalizeHistory';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { default as cardClasses } from './components/ChatCard.module.scss';

export function InteractiveChatMessages({
	chatSessionId,
	setLoadingStatus,
	onDislikeClick,
	onLikeClick,
	onChipClick,
	onCopyClick,
}: {
	onChipClick: (value: string, chipKind: ActionVariants, chipDisplayName: PromptActions) => void;
	chatSessionId: string;
	setLoadingStatus: (loadingState: boolean) => void;
	onDislikeClick: (index: number) => void;
	onLikeClick: (index: number) => void;
	onCopyClick: (index: number) => void;
}) {
	const { isFormula } = useMetricDerivedState();
	const { data: sessionData, loading: sessionLoading, error: sessionError } = useChatSession(chatSessionId);
	const chatSession = sessionData?.generative_sessions[0];

	const [getChips, { data: chipsData, loading: chipsLoading }] = useDynamicChips(chatSessionId);
	const isDynamicChipsEnabled = useFeatureFlag('pulse.generative.interactiveMetric.dynamicChips.enable');

	const chatHistory: ChatMessage[] = useMemo(() => chatSession?.completions ?? [], [chatSession?.completions]);

	const chatHistoryLength = chatHistory.length;
	const completionStatus = chatSession?.status;

	const isLoading = sessionLoading || !['completed', 'failed'].includes(completionStatus);

	const chips: PromptAction[] = useMemo(() => {
		const rawDynamicChips: { displayText: string; prompt: string }[] =
			chipsData?.metric_interactive_chips?.response?.['chips'] ?? [];

		return rawDynamicChips.map(
			(chip): PromptAction => ({
				displayPrompt: chip.displayText,
				prompt: chip.prompt,
				icon: undefined,
			})
		);
	}, [chipsData]);

	useEffect(() => {
		if (isLoading || !isDynamicChipsEnabled) return;
		getChips();
	}, [isLoading, isDynamicChipsEnabled, getChips]);

	useEffect(() => {
		if (isLoading) return;
		setLoadingStatus(false);
	}, [setLoadingStatus, isLoading, chatHistoryLength]);

	const conversation = useMemo(
		() => convertChatHistoryToConversation(chatHistory, isFormula),
		[chatHistory, isFormula]
	);
	const hasErrors = useMemo(() => !!sessionError || completionStatus == 'failed', [sessionError, completionStatus]);

	const chatCards = useMemo(() => {
		return conversation
			.slice()
			.reverse()
			.map((item, i) => {
				const originalIndex = chatHistoryLength - i - 1;
				return (
					<ChatCard
						isLast={i == 0}
						onDislikeClick={() => onDislikeClick(i)}
						onLikeClick={() => onLikeClick(i)}
						onCopyClick={() => onCopyClick(i)}
						key={`${chatSessionId}-${originalIndex}}`}
						query={item.user}
						response={item.assistant}
						action={item.userAction}
						isLoading={i == 0 && completionStatus == 'pending'}
						isError={hasErrors}
					/>
				);
			});
	}, [
		onCopyClick,
		chatHistoryLength,
		chatSessionId,
		completionStatus,
		conversation,
		onDislikeClick,
		onLikeClick,
		hasErrors,
	]);
	return (
		<Flex
			maxHeight="452px"
			marginX="12px"
			bgColor="white"
			borderColor={'gray.300'}
			overflowY="auto"
			backgroundColor="inherit"
			direction={'column-reverse'}
			pt={chatCards.length > 0 ? '8px' : undefined}
		>
			{conversation.length > 0 &&
				!isLoading &&
				!chipsLoading &&
				!hasErrors &&
				(chips.length > 0 ? (
					<DynamicChips
						onChipClick={(value: string, chipDisplayName: string) => onChipClick(value, 'reactions', chipDisplayName)}
						chips={chips}
					/>
				) : (
					<ActionChips
						variant="reactions"
						onChipClick={(value: string, chipDisplayName: string) => onChipClick(value, 'reactions', chipDisplayName)}
					/>
				))}
			<Flex className={cardClasses.chatParent} flexDirection="column-reverse">
				{chatCards}
			</Flex>
		</Flex>
	);
}
