import { MetricSearchParams } from 'src/common/utils/MetricSearchParams';
import {
	getDimensionNameByKey,
	getFirstObjectType,
} from 'src/pages/MetricPage/components/FiltersAndBreakdown/NodeScheme/utils';
import { Filter, MetricDerivedState } from 'src/pages/MetricPage/utils/state.types';
import { getDimensionKey } from '../utils';

export function calcFilters(
	{ filterBy }: MetricSearchParams,
	{ objectsTypes }: Pick<MetricDerivedState, 'objectsTypes'>
): Pick<MetricDerivedState, 'filters'> {
	if (!filterBy) return { filters: [] };

	const filterEntries = Object.entries(filterBy);
	const isFilterObjectValid = ([key]: [string, any]) =>
		!objectsTypes.length || objectsTypes.includes(getFirstObjectType(key));

	const filters = filterEntries.filter(isFilterObjectValid).map(
		([key, values]) =>
			({
				key,
				label: getDimensionNameByKey(getDimensionKey(key)),
				values,
				type: 'text',
			} as Filter)
	);

	return { filters };
}
