import Typography from 'src/common/components/Typography';
import { ExampleText, LearnMore } from './common';
import { LearnMoreProps } from './common/LearnMore';

const METRIC_TYPES_LINK = 'https://docs.sightfull.com/core/metrics/metric_types';

export function FormulaTooltip({ onHelpClicked }: { onHelpClicked: LearnMoreProps['onClick'] }) {
	return (
		<Typography color={'white'} variant="Paragraph14R">
			The formula used to calculate the metric, based on existing metrics.&nbsp;
			<LearnMore onClick={onHelpClicked} link={METRIC_TYPES_LINK} />.
			<ExampleText marginTop={'16px'} text={'$metric__won_opportunities + $metric__lost_opportunities'} />
			<ExampleText marginTop={'8px'} marginBottom={'4px'} text={'$metric__bookings / $metric__bookings_count'} />
		</Typography>
	);
}
