import { Box, Flex, Popover, PopoverAnchor, PopoverContent } from '@chakra-ui/react';
import { RangePicker } from '@components/DatePicker';
import {
	AbsolutePeriodRange,
	BuiltInPeriodRangePresets,
	CalendarWeekPeriod,
	FiscalQuarterPeriod,
	FiscalYearPeriod,
	MetricPeriod,
	MonthPeriod,
	PeriodRange,
	PeriodUnit,
	addTimezoneOffset,
	dateToUTC,
	removeTimezoneOffset,
} from '@sightfull/period-ranges';
import classNames from 'classnames';
import * as DateFns from 'date-fns';
import { useState } from 'react';
import Divider from 'src/common/components/Divider';
import { Calendar216 } from 'src/common/components/Icons';
import ModalFooter from 'src/common/components/ModalFooter';
import Typography from 'src/common/components/Typography';
import { useModal } from 'src/common/hooks/ui/useModal';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { useReportEvent } from 'src/services/analytics';
import shadows from 'src/style/shadows';
import { useMetricDerivedState } from '../../../hooks/useMetricDerivedState';
import { MetricDerivedState } from '../../../utils/state.types';
import { PeriodCell, PeriodCellSize } from '../PeriodCell';
import classes from '../PeriodPicker.module.scss';
import classesCustomPeriodPickerDialog from './CustomPeriodPickerDialog.module.scss';
import { SelectionPreview } from './SelectionPreview';
import { TabBar } from './TabBar';

export type DateRange = {
	dates: [Date, Date];
	dateStrings: [string, string];
};
export type RangePickerTypes = 'time' | 'week' | 'month' | 'quarter' | 'year' | 'date' | 'custom' | undefined;

export type CustomPeriodPickerDialogProps = {
	onCancel: () => void;
	periodRange?: PeriodRange;
	onApplyPeriodRange: (periodRange: PeriodRange) => void;
	isCustomPickerTypeInfered: boolean;
	size: PeriodCellSize;
	isEnding: boolean;
	isDisabled?: boolean;
} & Pick<MetricDerivedState, 'availablePeriodRanges'>;

export function CustomPeriodPickerDialog({
	onCancel,
	isCustomPickerTypeInfered,
	availablePeriodRanges,
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	periodRange: initialSelectedPeriod = BuiltInPeriodRangePresets.byKey('CM', fiscalYearOffset())!,
	onApplyPeriodRange,
	size,
	isEnding,
	isDisabled,
}: CustomPeriodPickerDialogProps) {
	const { reportEvent } = useReportEvent();
	const {
		isOpen: isDialogOpen,
		onToggle: onToggleDialog,
		onClose: onCloseDialog,
	} = useModal({ defaultIsOpen: !isCustomPickerTypeInfered });
	const [selectedPeriodRange, setSelectedPeriodRange] = useState<AbsolutePeriodRange>(
		tabPositionByPeriodUnit[initialSelectedPeriod.periodUnit] == -1
			? AbsolutePeriodRange.fromUnitAndDates(
					'week',
					initialSelectedPeriod.startDate,
					initialSelectedPeriod.endDate,
					fiscalYearOffset()
			  )
			: initialSelectedPeriod.asAbsoluteRange
	);

	const { metricNameWithFlavor, metricDisplayName } = useMetricDerivedState();

	const isApplyButtonEnabled = selectedPeriodRange != null;
	const tabPosition = tabPositionByPeriodUnit[selectedPeriodRange.periodUnit];
	const pickerType = rangePickerTypesByTabPosition[tabPosition];
	const pickerFormat = rangePickerFormatsByTabPosition[tabPosition];
	const fiscalPeriods = ['fyear', 'fquarter', 'quarter'];
	const isFiscalPeriod = fiscalPeriods.includes(selectedPeriodRange.periodUnit);
	const availableRange = availablePeriodRanges[selectedPeriodRange.periodUnit];
	const [isHovered, setIsHovered] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	function setSelectedPeriodUnitByTab(newPosition: number) {
		if (newPosition == tabPosition) return;
		const selectedUnit = periodUnitByTabPosition[newPosition];
		const isAvailable = !!availablePeriodRanges[selectedUnit];
		if (!isAvailable && selectedUnit !== 'custom') return;
		const rangeToSlice = AbsolutePeriodRange.fromUnitAndDates(
			selectedUnit,
			selectedPeriodRange?.startDate ?? initialSelectedPeriod.startDate,
			selectedPeriodRange?.endDate ?? initialSelectedPeriod.endDate,
			fiscalYearOffset()
		);
		setSelectedPeriodRange(availablePeriodRanges[selectedUnit]?.calculateBestFitRange(rangeToSlice) ?? rangeToSlice);
	}
	function onRangeSelectionChanged(dates: [Date, Date]) {
		setSelectedPeriodRange(convertPickerResultDatesToRange(dates, isFiscalPeriod, selectedPeriodRange.periodUnit));
	}
	function onCancelButtonClicked() {
		onCancel();
		onToggleDialog();
	}
	function onApplyButtonClicked() {
		if (!selectedPeriodRange) return;
		reportEvent({
			event: 'metric-custom-date-selected',
			metaData: {
				metricName: metricNameWithFlavor,
				metricDisplayName: metricDisplayName,
				periodType: selectedPeriodRange.periodUnit,
				startPeriod: selectedPeriodRange.startPeriod.id,
				endPeriod: selectedPeriodRange.endPeriod.id,
			},
		});
		onToggleDialog();
		onApplyPeriodRange(selectedPeriodRange);
	}

	function isDateDisabled(date: Date): boolean {
		if (!availableRange) return false;
		const availableRangeStartDate = addTimezoneOffset(
			isFiscalPeriod
				? getAdjustedDateBasedOnFiscalYearOffset(availableRange.startDate, { isRemovingFiscalYearOffset: true })
				: availableRange.startDate
		);
		const availableRangeEndDate = addTimezoneOffset(
			isFiscalPeriod
				? getAdjustedDateBasedOnFiscalYearOffset(availableRange.endDate, { isRemovingFiscalYearOffset: true })
				: availableRange.endDate
		);
		const currentAsPeriod = MetricPeriod.fromUnitAndStartDate(
			date,
			date,
			selectedPeriodRange.periodUnit,
			fiscalYearOffset()
		);
		return !(
			currentAsPeriod.startDate.getTime() > availableRangeStartDate.getTime() &&
			currentAsPeriod.endDate.getTime() < availableRangeEndDate.getTime()
		);
	}

	const fiscalAdjustedStartDate = isFiscalPeriod
		? getAdjustedDateBasedOnFiscalYearOffset(selectedPeriodRange.startDate, { isRemovingFiscalYearOffset: true })
		: selectedPeriodRange.startDate;
	const defaultStartDate = addTimezoneOffset(fiscalAdjustedStartDate);

	const fiscalAdjustedEndDate = isFiscalPeriod
		? getAdjustedDateBasedOnFiscalYearOffset(selectedPeriodRange.endDate, { isRemovingFiscalYearOffset: true })
		: selectedPeriodRange.endDate;
	const defaultEndDate = addTimezoneOffset(fiscalAdjustedEndDate);

	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);
	const handleFocus = () => {
		setIsHovered(false);
		setIsFocused(true);
	};
	const handleBlur = () => {
		setIsHovered(false);
		setIsFocused(false);
	};
	const getBorderColor = () => {
		if (isFocused) return '800';
		if (isHovered) return '600';
		return '400';
	};

	return (
		<Box
			className={classNames(classes.periodCell, classes.selected, {
				[classes.disabled]: isDisabled,
				[classes.last]: isEnding,
			})}
		>
			<Popover
				placement="bottom-start"
				variant="solid"
				onClose={onCloseDialog}
				isOpen={isDialogOpen}
				closeOnBlur={false}
				isLazy
			>
				<PopoverAnchor>
					<Flex wordBreak={'break-all'} className={classes[size]}>
						<PeriodCell
							size={size}
							showBorder={false}
							actualPeriodString={initialSelectedPeriod.actualDatesString}
							periodName={initialSelectedPeriod.prettyString}
							isSelected={isDialogOpen}
							isLast={true}
							onClick={() => {
								if (isDisabled) return;
								onToggleDialog();
								reportEvent({
									event: 'metric-custom-period-clicked',
									metaData: { prettyString: selectedPeriodRange.prettyString },
								});
							}}
							prefixIcon={
								<Box color="gray.600">
									<Calendar216 />
								</Box>
							}
						>
							<Typography variant={'Paragraph14SB'} textOverflow="ellipsis" noOfLines={1} textAlign="start" pt={'2px'}>
								{initialSelectedPeriod.asAbsoluteRange.startPeriod.pretty}
								{!initialSelectedPeriod.isSinglePeriod && (
									<>
										&nbsp;to&nbsp;
										{initialSelectedPeriod.asAbsoluteRange.endPeriod.pretty}
									</>
								)}
							</Typography>
						</PeriodCell>
					</Flex>
				</PopoverAnchor>
				<PopoverContent
					borderRadius={'8px'}
					border={'1px solid'}
					borderColor={'gray.300'}
					boxShadow={shadows.lg}
					marginLeft={'-12px'}
					marginTop="12px"
				>
					<Flex direction="column" width={'440px'}>
						<TabBar
							tabPosition={tabPosition}
							onTabClicked={setSelectedPeriodUnitByTab}
							disabledPositions={[
								!availablePeriodRanges.week,
								!availablePeriodRanges.month,
								!availablePeriodRanges.fquarter,
								!availablePeriodRanges.fyear,
								!availablePeriodRanges.custom,
							]}
						/>
						<Box padding="24px" textAlign="start" borderBottom={'1px solid var(--chakra-colors-gray-300)'}>
							<RangePicker
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}
								onFocus={handleFocus}
								onBlur={handleBlur}
								key={pickerType}
								picker={pickerType === 'custom' ? 'date' : pickerType}
								disabledDate={isDateDisabled}
								popupClassName={classesCustomPeriodPickerDialog.rangePickerPopup}
								className={classesCustomPeriodPickerDialog.rangePickerInput}
								style={{
									cursor: 'pointer',
									border: `1px solid var(--chakra-colors-gray-${getBorderColor()})`,
									borderRadius: '4px',
									width: '100%',
									font: 'inter',
									height: '42px',
									boxShadow: 'none',
								}}
								size="large"
								format={pickerFormat}
								separator={
									<Box height="24px" marginEnd="12px">
										<Divider direction="vertical" />
									</Box>
								}
								suffixIcon={null}
								onChange={onRangeSelectionChanged as any} // We are not using DATA FNS types so it's a must here
								defaultValue={[defaultStartDate, defaultEndDate]}
								allowClear={false}
							/>
							{selectedPeriodRange && (
								<SelectionPreview
									typeString={selectedPeriodRange.durationWithUnitString}
									startString={DateFns.format(selectedPeriodRange?.startPeriod.startDate, 'MMM dd, yyyy')}
									endString={DateFns.format(selectedPeriodRange?.endPeriod.endDate, 'MMM dd, yyyy')}
								/>
							)}
						</Box>
						<ModalFooter
							color="blue"
							cancelButtonColor="gray"
							primaryButtonLabel="Apply"
							size="md"
							onCancel={onCancelButtonClicked}
							onPrimaryClick={onApplyButtonClicked}
							isPrimaryEnabled={isApplyButtonEnabled}
						/>
					</Flex>
				</PopoverContent>
			</Popover>
		</Box>
	);
}

export function getAdjustedDateBasedOnFiscalYearOffset(
	date: Date,
	{ isRemovingFiscalYearOffset }: { isRemovingFiscalYearOffset: boolean }
): Date {
	let months = date.getUTCMonth() + fiscalYearOffset();
	if (isRemovingFiscalYearOffset) months = date.getUTCMonth() - fiscalYearOffset();
	return new Date(Date.UTC(date.getUTCFullYear(), months));
}

export function convertPickerResultDatesToRange(dates: [Date, Date], isFiscalPeriod: boolean, periodUnit: PeriodUnit) {
	const dateCopy = dates.map((e) => removeTimezoneOffset(new Date(e)));
	if (isFiscalPeriod) {
		dateCopy[0] = getAdjustedDateBasedOnFiscalYearOffset(dateCopy[0], {
			isRemovingFiscalYearOffset: false,
		});
		dateCopy[1] = getAdjustedDateBasedOnFiscalYearOffset(dateCopy[1], {
			isRemovingFiscalYearOffset: false,
		});
	}
	return AbsolutePeriodRange.fromUnitAndDates(
		periodUnit,
		dateToUTC(dateCopy[0]),
		dateToUTC(dateCopy[1]),
		fiscalYearOffset()
	);
}

const customPrettyFormat = 'DD-MM-YYYY';

const tabPositionByPeriodUnit: { [key in PeriodUnit]: number } = {
	day: 0,
	week: 1,
	month: 2,
	quarter: 3,
	fyear: 4,
	fweek: -1,
	fquarter: 3,
	cquarter: 3,
	custom: 5,
};

const periodUnitByTabPosition: { [num in number]: PeriodUnit } = {
	1: 'week',
	2: 'month',
	3: 'fquarter',
	4: 'fyear',
	5: 'custom',
};

const rangePickerFormatsByTabPosition: { [num in number]: string } = {
	1: CalendarWeekPeriod.prettyFormat,
	2: MonthPeriod.prettyFormat,
	3: FiscalQuarterPeriod.prettyFormat,
	4: FiscalYearPeriod.prettyFormat,
	5: customPrettyFormat,
};

const rangePickerTypesByTabPosition: { [num in number]: RangePickerTypes } = {
	1: 'week',
	2: 'month',
	3: 'quarter',
	4: 'year',
	5: 'custom',
};
