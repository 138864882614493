import Divider from '@components/Divider';
import React from 'react';
import { SpaceSelector } from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpaceSelector';
import { SpacesListDrawer } from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesListDrawer';

export const SpacesNavigationDrawer = ({
	isCreateEditModalOpen,
	onCreateEditModalOpen,
	onCreateEditModalClose,
	setIsPanelActingLikeOnHover,
	isParentPanelCollapsed,
}: {
	isCreateEditModalOpen: boolean;
	onCreateEditModalOpen: VoidFunction;
	onCreateEditModalClose: VoidFunction;
	setIsPanelActingLikeOnHover: (isOpen: boolean) => void;
	isParentPanelCollapsed: boolean;
}) => {
	const isDashboardsPage = location.pathname.includes('dashboard') || location.pathname === '/';

	return (
		<>
			<SpaceSelector isDashboardsPage={isDashboardsPage} setIsPanelActingLikeOnHover={setIsPanelActingLikeOnHover} />
			<Divider marginY="12px" color="natural.200" direction="horizontal" />
			<SpacesListDrawer
				isCreateEditModalOpen={isCreateEditModalOpen}
				onCreateEditModalOpen={onCreateEditModalOpen}
				onCreateEditModalClose={onCreateEditModalClose}
				isDashboardsPage={isDashboardsPage}
				setIsPanelActingLikeOnHover={setIsPanelActingLikeOnHover}
				isParentPanelCollapsed={isParentPanelCollapsed}
			/>
		</>
	);
};
