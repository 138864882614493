import { Box, useDisclosure } from '@chakra-ui/react';
import Flex from '@components/Flex';
import useParams from '@hooks/navigation/useParams';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useMemo, useState } from 'react';
import { EntityProfileLoader } from 'src/layout/EntityProfileDrawer/EntityProfileDrawer';
import { DrawerHeader } from 'src/layout/EntityProfileDrawer/components/DrawerHeader';
import { EntityProfileInner } from 'src/layout/EntityProfileDrawer/components/EntityProfileInner';
import { useEntityProfileDrawer } from 'src/layout/EntityProfileDrawer/hooks';
import { useNormalizedData } from 'src/layout/EntityProfileDrawer/hooks/useNormalizedData';
import Page from 'src/layout/Page';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { useGetRecordProfileByIdQuery } from '../../generated/graphql';
import { RecordProfileBody, RecordProfileHeader } from '../../layout/RecordProfileDrawer';

export const EntityProfilePagePath = 'node';

export function EntityProfilePage() {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');

	return (
		<Page page="Entity Profile Page">
			<Flex justifyContent={'center'} maxHeight={'100%'} overflowY={'auto'}>
				<Box width={'700px'}>{isSightfull2 ? <EntityProfilePageGuardedV2 /> : <EntityProfilePageGuardedV1 />}</Box>
			</Flex>
		</Page>
	);
}

export function EntityProfilePageGuardedV2() {
	const { entityId } = useParams();
	const { data, loading, error } = useGetRecordProfileByIdQuery({
		variables: { id: entityId ?? '' },
	});
	const [searchValue, setSearchValue] = useState('');

	if (!data || loading) return <EntityProfileLoader />;
	if (error) return <div>Error occurred</div>;

	return (
		<Flex width={'700px'} paddingY="24px" flexDir={'column'} alignItems={'flex-start'}>
			<RecordProfileHeader
				recordProfile={data.recordProfile}
				searchValue={searchValue}
				onSearchValueChange={(newVal) => setSearchValue(newVal)}
			/>
			<RecordProfileBody recordProfile={data.recordProfile} searchValue={searchValue} />
		</Flex>
	);
}

export function EntityProfilePageGuardedV1() {
	const hasPropertyModalPermission = usePermissionCheck().isHavingOneOfPermissions([
		Permissions.overrideReset,
		Permissions.overrideEdit,
		Permissions.overrideRead,
		Permissions.crudOverride,
	]);

	const { entityId } = useParams();
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { popEntity } = useEntityProfileDrawer();
	const [shouldShowRawData, setShouldShowRawData] = useState(false);
	const entityIds = useMemo(() => [entityId ?? ''], [entityId]);

	const { currentEntityId, node, rest } = useNormalizedData(entityIds, shouldShowRawData);

	if (!currentEntityId || !node) return <EntityProfileLoader />;

	return (
		<>
			<DrawerHeader
				header={node.header}
				onDrawerClose={popEntity}
				source={node.source}
				shouldShowRawData={shouldShowRawData}
				onShowRawToggle={setShouldShowRawData}
				shouldShowTopHeader={false}
			/>
			<EntityProfileInner
				isOpen={isOpen}
				onClose={onClose}
				onOpen={onOpen}
				currentEntityId={currentEntityId}
				hasPropertyModalPermission={hasPropertyModalPermission}
				node={node}
				rest={rest}
			/>
		</>
	);
}
