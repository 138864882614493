import { Center } from '@chakra-ui/react';
import Box from '@components/Box';
import { ChartLoader } from '@components/Chart/ChartLoader';
import { ChartOptions, ChartSeries } from '@components/Chart/types';
import { getTooltipContent } from '@components/Chart/utils';
import * as CSS from 'csstype';
import Highcharts, {
	ExtremesObject,
	Chart as InstanceType,
	TooltipFormatterContextObject,
	XAxisOptions,
} from 'highcharts';
import HC_MORE from 'highcharts/highcharts-more';
import React, { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Chart, HighchartsChart, HighchartsProvider, Tooltip, XAxis, YAxis } from 'react-jsx-highcharts';
import useWindowResize from 'src/common/hooks/ui/useWindowResize';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import './highchartsOverrides.scss';
import useBubbles from './useBubbles';
import {
	getDisplayUnitLabels,
	tooltipConfig,
	useMetricChartGeneralConfig,
	useXAxisConfig,
} from './useMetricChartGeneralConfig';
import useSeries from './useSeries';

import { isSinglePeriodView, plotBandId } from 'src/lib/metricRules/DerivedStateCalculators';
import { useDisplayedLegendState } from 'src/pages/MetricPage/components/LegendsPanel/useDisplayedLegendState';
import { BigBoldNumberChart } from '../BigBoldNumber/BigBoldNumberChart';
import { MultiToolTip } from '../MultiTooltip/MultiTooltip';
import { useParameters } from '@services/useParameters';
import { TestIDs } from '../../types/test-ids';
import { ErrorBoundary } from 'react-error-boundary';
import { useReportEvent } from 'src/services/analytics';
import Flex from '@components/Flex';
import { BrokenChart70 } from '@icons/index';

HC_MORE(Highcharts);

type SelectedPeriodType = {
	selectedPeriodName: string;
	selectedPeriodPosition: MultiToolTipPositionType | null;
};

type Props = {
	width?: number;
	height?: CSS.Property.Height;
	onColumnClick?: (xIndex: number) => void;
	isEntityPage?: boolean;
	layoutState?: string;
	isTooltipEnabled?: boolean;
	xAxisLabelsVisible?: boolean;
	spacing?: Array<number>;
	fallBackComponent?: React.ReactElement;
};

export type MultiToolTipPositionType = {
	x: number;
	y: number;
};

export interface ChartComponentRef {
	reflow: () => void;
	setHeight: (height: number) => void;
}

const CHART_PLOT_CLICKED_EVENT = 'on-chart-plot-clicked';

// the default according to highcharts documentation https://api.highcharts.com/highcharts/chart.spacing
const HIGHCHARTS_SPACING_DEFAULT = [10, 10, 15, 10];

const ChartComponent = forwardRef(function (
	{
		width,
		height,
		spacing,
		onColumnClick,
		isEntityPage,
		layoutState,
		fallBackComponent,
		isTooltipEnabled = true,
		xAxisLabelsVisible = true,
	}: Props,
	ref: Ref<ChartComponentRef>
) {
	const chartContainer = useRef<HTMLDivElement>(null);
	const [legendState] = useDisplayedLegendState();
	const { getParameterDisplayNameWithValue } = useParameters();
	const [maxYValue, setMaxYValue] = useState<number | null>(null);
	const maxYValueRef = useRef(0);
	const [chartInstance, setChartInstance] = useState<InstanceType>();
	const { reportEvent } = useReportEvent();

	const {
		chartOptions,
		displayedLegendItems,
		isPartiallyLoadingChart,
		periodRange,
		breakdowns,
		chartType,
		decimalDigits,
	} = useMetricDerivedState();

	const isSinglePeriod = isSinglePeriodView(periodRange, breakdowns, chartOptions.series);
	const categories = useMemo(() => {
		if (isSinglePeriod) {
			const mainMetricName = chartOptions.series.find((s) => s.custom.seriesType == 'main')?.name;
			const componentNames = chartOptions.series
				.filter((s) => s.custom.seriesType != 'main' && displayedLegendItems.selectedValues.includes(s.name))
				.map((series) => series?.name);
			return mainMetricName ? [...componentNames, mainMetricName] : componentNames;
		}
		const mainMetricPeriods = chartOptions.series
			.find((s) => s.custom.seriesType == 'main')
			?.data.map((point) => point.name);
		return mainMetricPeriods;
	}, [chartOptions.series, isSinglePeriod, displayedLegendItems.selectedValues]);

	const rePlotBands = () => {
		const plotBands = chartOptions.xAxis.plotBands.slice();
		chartInstance?.xAxis?.[0].removePlotBand(plotBandId);

		if (chartInstance?.xAxis?.[0].addPlotBand) {
			plotBands.forEach((pb) => chartInstance.xAxis[0].addPlotBand(pb));
		}
		if (categories) chartInstance?.xAxis?.[0].setCategories(categories, true);
	};

	useEffect(rePlotBands, [
		chartInstance?.xAxis,
		chartOptions.xAxis.plotBands,
		chartOptions.series,
		isSinglePeriod,
		categories,
	]);
	useEffect(() => {
		const chartPlotClickedListener = (event: any) => {
			const index = event.detail.index;
			onColumnClick?.(index);
		};
		document.addEventListener(CHART_PLOT_CLICKED_EVENT, chartPlotClickedListener);
		return () => {
			document.removeEventListener(CHART_PLOT_CLICKED_EVENT, chartPlotClickedListener);
		};
	}, [onColumnClick]);

	useImperativeHandle(ref, () => ({
		reflow() {
			chartInstance?.reflow();
		},
		setHeight(height: number) {
			chartInstance?.setSize(undefined, height);
		},
	}));
	const handleSetExtremes = useCallback(
		(newExtremes: ExtremesObject) => {
			if (newExtremes.dataMax > maxYValueRef.current) {
				setMaxYValue(newExtremes.dataMax);
			}
			maxYValueRef.current = Math.max(maxYValueRef.current, newExtremes.dataMax);
		},
		[maxYValueRef]
	);

	const seriesWithoutColumnZeroValues = useMemo(() => removeColumnZeroValue(chartOptions), [chartOptions]);
	const SeriesComponents = useSeries(
		seriesWithoutColumnZeroValues,
		handleSetExtremes,
		maxYValueRef.current,
		displayedLegendItems,
		isSinglePeriod
	);
	const BubblesComponents = useBubbles(chartOptions, maxYValue, displayedLegendItems, isSinglePeriod);
	const setChartHeight = useCallback(() => {
		const height = chartContainer.current?.parentElement?.offsetHeight;
		chartInstance?.setSize?.(undefined, height);
	}, [chartInstance]);

	const [selectedPeriod, setSelectedPeriod] = useState<SelectedPeriodType | null>(null);

	const onMouseOverXAxis = (value: string, position: MultiToolTipPositionType) =>
		setSelectedPeriod({
			selectedPeriodName: value,
			selectedPeriodPosition: position,
		});

	const onMouseLeaveXAxis = () => setSelectedPeriod(null);

	const { xAxisConfig } = useXAxisConfig({
		onMouseOver: onMouseOverXAxis,
		onMouseLeave: onMouseLeaveXAxis,
		visible: xAxisLabelsVisible,
		chartRef: chartContainer,
	});

	const generalConfig = useMetricChartGeneralConfig(
		{
			onColumnClick: (index: number) => {
				if (isEntityPage) {
					return;
				}
				document.dispatchEvent(new CustomEvent(CHART_PLOT_CLICKED_EVENT, { detail: { index } }));
			},
		},
		decimalDigits,
		chartType.selectedValue,
		isEntityPage,
		isTooltipEnabled
	);

	useEffect(() => {
		chartInstance?.reflow();
	}, [chartInstance]);

	useWindowResize(setChartHeight);

	function tooltipFormatter(this: TooltipFormatterContextObject) {
		const { name, value, xSeriesValue, appliedParameters } = getTooltipContent(this, seriesWithoutColumnZeroValues);
		const tooltipValue = [value, ...getDisplayUnitLabels(this.point, decimalDigits)].join(' ');

		return `
			<div class='highcharts-tooltip ${xSeriesValue ? 'highcharts-tooltip-big' : 'highcharts-tooltip-small'}'>
				${xSeriesValue ? `<div class='highcharts-tooltip-point-x'>${xSeriesValue}</div>` : ''}
				<div class="highcharts-tooltip-point-body">
					<div class='highcharts-tooltip-point-color' style='background-color:${this.point.color}'></div>
					<div class='highcharts-tooltip-point-main'>
						<div class='highcharts-tooltip-point-name'>${name}</div>
						<div class='highcharts-tooltip-point-value'>${tooltipValue}</div>
					</div>
					<div class='highcharts-tooltip-point-parameters'>
					${
						appliedParameters
							?.map(
								(parameter) =>
									`<div class='highcharts-tooltip-point-parameter'>
									${getParameterDisplayNameWithValue(parameter.key, parameter.value)}
								</div>`
							)
							.join('') ?? ''
					}
					</div>
				</div>
			</div>
		`;
	}

	const onChartClick = (e: any) => onColumnClick?.(Math.abs(Math.round(e.xAxis[0].value)));

	const bubbles = chartOptions.bubbles?.map((bubble) => {
		const currentBubble = bubble.dataPoints.find((innerItem) => innerItem.id === selectedPeriod?.selectedPeriodName);
		return {
			value: bubble.custom.seriesDataPointYFormatter?.(currentBubble ? +currentBubble.label : 0),
			color: bubble.color,
			name: bubble.name,
		};
	});

	const series = chartOptions.series
		.filter((item) => item.visible)
		.map((series) => {
			const currentSeries = series.data.find((innerItem) => innerItem.name === selectedPeriod?.selectedPeriodName);

			return {
				value: series.custom.seriesDataPointYFormatter?.(currentSeries?.y),
				color: series.color,
				name: series.name,
				appliedParameters: series.custom.appliedParameters,
			};
		});
	const mainTitles = legendState.mainSeries.map((state) => state.value);

	if (chartType.selectedValue === 'number' && layoutState !== 'top-collapsed') {
		return (
			<BigBoldNumberChart
				height={isEntityPage ? '100%' : height}
				delta={chartOptions.bubbles?.find((bubble) => bubble.name === 'Delta')}
				growth={chartOptions.bubbles?.find((bubble) => bubble.name === 'Growth')}
				displayedLegendItems={displayedLegendItems}
			/>
		);
	}

	return (
		<Box ref={chartContainer} position="relative" height="100%" width="100%">
			{isPartiallyLoadingChart && (
				<Center position={'absolute'} zIndex={1} margin={'auto'} left={0} right={0} top={0} bottom={100}>
					<ChartLoader />
				</Center>
			)}
			<Flex
				data-testid={TestIDs.CHART_CONTAINER}
				opacity={isPartiallyLoadingChart ? '20%' : '100%'}
				height={'100%'}
				transition={'all 0.15s ease-in-out'}
				alignItems={'center'}
				justifyContent={'center'}
			>
				<ErrorBoundary
					onError={(error) => {
						setChartInstance(undefined);
						reportEvent({
							event: 'highcharts-error-boundary-on-error',
							metaData: { feature: 'Metric chart', error: error },
						});
					}}
					fallbackRender={() => {
						if (fallBackComponent != undefined) {
							return fallBackComponent;
						}
						return (
							<Flex alignItems={'center'} justifyContent={'center'} direction={'column'}>
								<BrokenChart70 />
							</Flex>
						);
					}}
				>
					<HighchartsProvider Highcharts={Highcharts}>
						<HighchartsChart
							{...generalConfig}
							yAxis={chartOptions.yAxis}
							containerProps={{ style: { height: '100%', width: '100%' } }}
							xAxis={
								{
									...xAxisConfig,
									plotBands: chartOptions.xAxis?.plotBands,
									type: 'category',
									categories: categories,
								} as XAxisOptions
							}
						>
							<Chart
								onAddSeries={(chart) => setChartInstance(chart.target)}
								width={width}
								height={height}
								onClick={onChartClick}
								spacing={spacing ?? HIGHCHARTS_SPACING_DEFAULT}
							/>
							<XAxis>
								<YAxis visible={false} height="45%">
									{BubblesComponents}
								</YAxis>
								{SeriesComponents}
							</XAxis>
							{isTooltipEnabled && (
								<Tooltip
									{...tooltipConfig}
									formatter={tooltipFormatter}
									style={{
										border: 'none',
										backgroundColor: 'unset',
										height: 48,
									}}
								/>
							)}
						</HighchartsChart>
					</HighchartsProvider>
				</ErrorBoundary>
			</Flex>
			{isTooltipEnabled && selectedPeriod && selectedPeriod?.selectedPeriodPosition && (
				<Box
					zIndex={50}
					position={'fixed'}
					w="min-content"
					h="min-content"
					bottom={document.body.clientHeight - selectedPeriod.selectedPeriodPosition.y - 25}
					left={selectedPeriod?.selectedPeriodPosition?.x}
				>
					{!isSinglePeriod && (
						<MultiToolTip
							mainTitles={mainTitles}
							onMouseLeave={onMouseLeaveXAxis}
							bubbles={bubbles}
							currPeriodData={series}
							currPeriod={selectedPeriod.selectedPeriodName}
						/>
					)}
				</Box>
			)}
		</Box>
	);
});
ChartComponent.displayName = 'Chart';

export default ChartComponent;

// https://stackoverflow.com/questions/37858807/how-to-dynamically-set-the-minpointlength-except-0
function removeColumnZeroValue(chartOptions: ChartOptions): ChartOptions {
	function removeZerosFromSeries(chartSeries: ChartSeries) {
		return ['column', 'stackedColumn'].includes(chartSeries.chartType)
			? {
					...chartSeries,
					data: chartSeries.data.map((dataPoint) => (dataPoint.y == 0 ? { ...dataPoint, y: undefined } : dataPoint)),
			  }
			: chartSeries;
	}

	return {
		...chartOptions,
		series: chartOptions.series.map(removeZerosFromSeries),
	};
}
