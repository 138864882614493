import TenantConfigType from 'src/types/tenantConfig';

export const isNumber = (value: any) => {
	const isNAN = isNaN(Number(value));

	return !isNAN && (typeof value === 'number' || typeof value === 'bigint');
};

export function formatNumber(value: any, tenantConfig: Pick<TenantConfigType, 'decimalDigits'>) {
	if (!value) return '0';

	const formatter = Intl.NumberFormat('en-US', {
		notation: 'compact',
		minimumFractionDigits: 0,
		maximumFractionDigits: tenantConfig.decimalDigits ?? 1,
	});

	return formatter.format(value);
}
