import { editor, Position } from 'monaco-editor';

import { CompletionProvider } from '../completionProvider';
import { SuggestionsContextSettings } from '../hooks/useMonacoTextFieldProviders';
import { EnrichedSemanticDefinitions } from '../semanticTypes';
import { getEntity, getMetricByNameFromList, getWordRangeAtPosition, rangeFromPosition } from '../utils/utils';
import { getContextCompletionTooltip } from '../widgetBuilder/contextTooltip';

export const TextFieldHoverProviderBuilder =
	({ entity, metric }: SuggestionsContextSettings, semanticDefinitions: EnrichedSemanticDefinitions) =>
	(model: editor.ITextModel, position: Position) => {
		const rangeFromPositionValue = rangeFromPosition(position);
		const partRange =
			getWordRangeAtPosition(model, position, CompletionProvider.EXPRESSION_PART) ?? rangeFromPositionValue;
		const part = model.getValueInRange(partRange);
		const expressionRange =
			getWordRangeAtPosition(model, position, CompletionProvider.EXPRESSION) ?? rangeFromPositionValue;
		const expression = expressionRange ? model.getValueInRange(expressionRange) : '';

		const completion = new CompletionProvider(semanticDefinitions);

		const enrichedEntity = getEntity(semanticDefinitions, entity);
		const enrichedMetric = getMetricByNameFromList(semanticDefinitions.metrics, metric);
		const contexts = completion.createContexts({
			entity: enrichedEntity,
			metric: enrichedMetric,
		});

		const hoverContexts = completion.walkContextForCompletions(
			contexts,
			expression,
			partRange.getEndPosition().column - expressionRange.getStartPosition().column,
			true
		);
		const hoveredContext = hoverContexts.find((c) => c.keyword === part);

		if (!hoveredContext) {
			return { contents: [], range: partRange };
		}

		const tooltip = getContextCompletionTooltip(hoveredContext, `$${part}`);
		return {
			contents: tooltip ? [tooltip] : [],
			range: partRange,
		};
	};
