import Box from '@components/Box';
import * as CSS from 'csstype';
import colors from 'src/style/colors';
import classes from './Divider.module.scss';
import { SpaceProps } from '@chakra-ui/react';

type Directions = keyof typeof DirectionToClass;

type Props = {
	direction: Directions;
	mb?: CSS.Property.MarginBottom;
	mt?: CSS.Property.MarginTop;
	ml?: CSS.Property.MarginLeft;
	mr?: CSS.Property.MarginRight;
	color?: string;
	opacity?: CSS.Property.Opacity;
	width?: CSS.Property.Width;
} & SpaceProps;
const DirectionToClass = {
	horizontal: classes.horizontal,
	vertical: classes.vertical,
};
const Divider = ({
	direction,
	mb,
	mt,
	ml,
	mr,
	color = colors.gray[300],
	opacity,
	width = direction === 'horizontal' ? '100%' : '0px',
	...spaceProps
}: Props) => {
	const border = `1px solid`;
	const borderTop = direction === 'horizontal' ? border : '0px';
	const borderLeft = direction === 'vertical' ? border : '0px';

	return (
		<Box
			borderTop={borderTop}
			borderLeft={borderLeft}
			borderColor={color}
			opacity={opacity}
			as="hr"
			ml={ml}
			mr={mr}
			mb={mb}
			mt={mt}
			className={`${classes.divider} ${DirectionToClass[direction]}`}
			width={width}
			{...spaceProps}
		/>
	);
};

export default Divider;
