import { Search16 } from '@components/Icons';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import Select from '@components/Select';
import { SelectOption } from '@components/Select/types';
import { useCallback, useEffect } from 'react';
import useEntitySearch from '@hooks/stores/useEntityState';

export type AskAISearchData = {
	value?: string;
	searchOptions: SelectOption[];
};

export function AskAISearchData({ value, searchOptions }: AskAISearchData) {
	const [{ selectedCategory }, { setCategory }] = useEntitySearch();
	const filteredOptions = searchOptions.filter((v) => v !== selectedCategory);

	useEffect(() => {
		const isStateEmpty = !selectedCategory.label && !selectedCategory.value;
		if (isStateEmpty) {
			setCategory(searchOptions[0]);
		}
	}, [searchOptions, selectedCategory, setCategory]);

	const onSelectChange = useCallback(
		(newOption: SelectOption) => {
			setCategory(newOption);
		},
		[setCategory]
	);

	return (
		<Typography variant={'DesktopH8Regular'}>
			<Flex alignItems={'center'}>
				<Search16 />
				<Typography variant={'DesktopH8Regular'} paddingLeft={'12px'} testId={`ask-ai-search-data-echo`}>
					“{value}”
				</Typography>
				<Typography variant={'DesktopH8Bold'} paddingLeft={'12px'}>
					In
				</Typography>
				<Select
					color={'gray.1000'}
					menuStyles={{ margin: '16px 0px 0px 0px' }}
					size={'inline'}
					paddingLeft={'12px'}
					options={filteredOptions}
					onChange={onSelectChange}
					selectedOption={selectedCategory}
					backgroundColor={'none'}
					testId={`ask-ai-search-data-select-category`}
					isGhost={true}
				/>
			</Flex>
		</Typography>
	);
}
