import { Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { AdvancedSelect } from 'src/common/components/AdvancedSelect';
import Divider from 'src/common/components/Divider';
import { SelectOption } from 'src/common/components/Select/types';
import Skeleton from 'src/common/components/Skeleton';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import { getSourcesInfo } from 'src/common/hooks/useSourceInfo';
import { useEntityCatalogQuery } from 'src/generated/graphql';
import { useSharedMonacoTextFieldProviders } from 'src/lib/completions/hooks/useMonacoTextFieldProviders';
import { getEntity } from 'src/lib/completions/utils/utils';
import { MonacoTooltipComponent } from 'src/lib/completions/widgetBuilder/MonacoTooltipComponent';
import { getEntityCssCompletionTooltip } from 'src/lib/completions/widgetBuilder/entityCompletionItem';
import { useBuilderDerivedState } from 'src/lib/metricRules/builder/useBuilderDerivedState';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { useMetricEditorState } from 'src/pages/MetricPage/hooks/useMetricEditorState';
import { formatBuilderFeatureEditMode } from 'src/pages/MetricPage/utils/eventMetadata';
import { useReportEvent } from 'src/services/analytics';
import { ParametersPanel } from '../../Parameters/ParametersPanel';
import { SkeletonBuilder } from '../../SkeletonComponents';
import { EditorFeature } from '../EditPanel';
import { AggregateBuilder } from './AggregateBuilder';
import classes from './BuilderPanel.module.scss';
import { FormulaBuilder } from './FormulaBuilder';
import { SelectLabel } from './components/SelectLabel';

export function BuilderPanel({ selectedFeature }: { selectedFeature: EditorFeature }) {
	return (
		<Flex width={'100%'} height={'100%'}>
			<BuilderContent selectedFeature={selectedFeature} />
		</Flex>
	);
}

function BuilderContent({ selectedFeature }: { selectedFeature: EditorFeature }) {
	const { metricBuilderState: builderState, upsertYAMLProperty } = useBuilderDerivedState();
	const { metricEditorState } = useMetricEditorState();
	const { data, loading: isLoadingEntities = true } = useEntityCatalogQuery();
	const entities: SelectOption[] = useMemo(
		() =>
			data?.entityCatalog.map((el) => {
				const sourceInfo = getSourcesInfo({ source: el.schema?.sourceDisplayName });
				return {
					label: el.displayName ?? el.entityName,
					value: el.entityName,
					icon: sourceInfo?.Logo && <sourceInfo.Logo />,
				};
			}) ?? [],
		[data?.entityCatalog]
	);

	function setSelectedEntityHandler(entityOption: SelectOption) {
		if (entityOption) {
			upsertYAMLProperty('entity', entityOption.value);
		}
	}

	const selectedEntity = useMemo(() => {
		if (!builderState?.entity) return;
		return (
			entities.find((el) => el.value === builderState.entity) || {
				label: builderState.entity,
				value: builderState.entity,
			}
		);
	}, [builderState?.entity, entities]);

	return isLoadingEntities || metricEditorState.isLoading ? (
		<Flex flexDirection={'column'} p={'20px'} width={'100%'}>
			<Skeleton className={classes.headerSkeleton} />
			<SkeletonBuilder />
		</Flex>
	) : (
		<BuilderBody
			selectedFeature={selectedFeature}
			setSelectedEntityHandler={setSelectedEntityHandler}
			isFieldsDisabled={!selectedEntity}
			entities={entities}
			selectedEntity={selectedEntity}
		/>
	);
}

type BuilderProps = {
	setSelectedEntityHandler: (selectedEntity: SelectOption) => void;
	isFieldsDisabled?: boolean;
	entities: SelectOption[];
	selectedEntity?: SelectOption;
	selectedFeature: EditorFeature;
};
export function BuilderBody({
	setSelectedEntityHandler,
	isFieldsDisabled,
	entities,
	selectedEntity,
	selectedFeature,
}: BuilderProps) {
	const { metricBuilderState: builderState, upsertYAMLProperties } = useBuilderDerivedState();
	const { metricNameWithFlavor, objectsTypes, parameters } = useMetricDerivedState();
	const { reportEvent } = useReportEvent({
		feature: 'Metric Builder',
		metricName: metricNameWithFlavor,
		editMode: formatBuilderFeatureEditMode(selectedFeature),
	});
	const { semanticDefinitions } = useSemanticDefinitions();

	useSharedMonacoTextFieldProviders({ entity: selectedEntity?.value ?? objectsTypes[0], metric: metricNameWithFlavor });

	if (!builderState) return <Skeleton />;

	const isWarningModalEnabledAggregate =
		builderState?.type == 'aggregate' &&
		(!!builderState?.measure?.length || !!builderState.filters?.length || !!builderState?.x_axis);
	const isWarningModalEnabled =
		builderState?.type === 'formula' ? !!builderState?.formula?.length : isWarningModalEnabledAggregate;

	function clearOnSelect(entityOption: SelectOption) {
		setSelectedEntityHandler(entityOption);
		const clearFunction = builderState?.type === 'formula' ? clearFormulaOnSelect : clearAggregateOnSelect;
		clearFunction(entityOption);
	}

	function clearFormulaOnSelect(entityOption: SelectOption) {
		upsertYAMLProperties([
			{ key: 'entity', value: entityOption.value },
			{ key: 'formula', value: '' },
		]);
	}

	function clearAggregateOnSelect(entityOption: SelectOption) {
		upsertYAMLProperties(
			[
				{ key: 'entity', value: entityOption.value },
				{ key: 'x_axis', value: undefined },
				{ key: 'joins', value: undefined },
				{ key: 'measure', value: undefined },
				{ key: 'filters', value: undefined },
				{ key: 'operation', value: 'count' },
			],
			{ shouldPreviewAfter: true }
		);
	}

	const onSelectEntity = (entityOption: SelectOption) => {
		clearOnSelect(entityOption);
		reportEvent({
			event: 'metric-edit-UI-input-provided',
			metaData: { fieldName: 'entity', input: entityOption.value },
		});
	};

	return (
		<Flex width={'100%'} direction="column" overflowY={'auto'} height="100%" overflowX={'visible'} padding={'20px'}>
			<SelectLabel marginBottom={'10px'} color={'gray.1100'} text="Entity" />
			<AdvancedSelect
				label="Entity"
				isWarningModalEnabled={isWarningModalEnabled}
				onChange={onSelectEntity}
				placeholder="Select entity"
				options={entities}
				controlledValue={selectedEntity}
				initialValue={selectedEntity}
				dataIntercomTarget="entity"
				hoverTooltipBuilder={(entity) => {
					if (!semanticDefinitions || !entity) return;
					const enrichedEntity = getEntity(semanticDefinitions, entity.value);
					if (!enrichedEntity) return;
					return (
						<MonacoTooltipComponent
							tooltipMarkdownString={getEntityCssCompletionTooltip(enrichedEntity, enrichedEntity.name)}
						/>
					);
				}}
			/>

			<Flex opacity={isFieldsDisabled ? 0.3 : 1} direction={'column'} paddingTop={'12px'}>
				{builderState.type === 'formula' ? (
					<>
						<Divider marginY="20px" direction="horizontal" />
						<FormulaBuilder
							isFieldsDisabled={isFieldsDisabled}
							selectedEntity={selectedEntity}
							selectedFeature={selectedFeature}
						/>
					</>
				) : (
					<>
						<AggregateBuilder
							isFieldsDisabled={isFieldsDisabled}
							selectedEntity={selectedEntity}
							selectedFeature={selectedFeature}
						/>
					</>
				)}
				{parameters && <ParametersPanel divider={'top'} isShowingFullSize={true} />}
			</Flex>
		</Flex>
	);
}
