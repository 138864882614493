import { useEffect } from 'react';

type Props = {
	dependency: boolean;
};

export function usePreventReload({ dependency = false }: Props) {
	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (dependency) {
				const confirmationMessage = '!'; // browsers will ignore it!
				event.returnValue = confirmationMessage;
				return confirmationMessage;
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [dependency]);
}
