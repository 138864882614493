import { ApolloError } from '@apollo/client';
import { Center } from '@chakra-ui/react';
import Box from '@components/Box';
import Flex from '@components/Flex';
import { LeftSidePanelV2 } from '@components/LeftSidePanelV2';
import useToast from '@hooks/ui/useToast';
import { PeriodRange } from '@sightfull/period-ranges';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CollectionEmptyPage } from 'src/common/components/CollectionEmptyPage';
import DashboardWorkspacePanel from 'src/common/components/DashboardWorkspacePanel';
import Spinner from 'src/common/components/Spinner';
import { useGetDashboardSubscription } from 'src/common/hooks/fetching/useDashboards';
import useMutation from 'src/common/hooks/fetching/useMutation';
import useEmbedMode from 'src/common/hooks/navigation/useEmbedSetting';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useUser from 'src/common/hooks/stores/useUser';
import { useModal } from 'src/common/hooks/ui/useModal';
import { TestIDs } from 'src/common/types/test-ids';
import { sleep } from 'src/common/utils/utils';
import { DashboardGetSingleQuery } from 'src/generated/graphql';
import DeleteCollectionModal from 'src/layout/Menu/NavigationDrawer/DeleteCollectionModal';
import Page from 'src/layout/Page';
import { UpdateCollection } from 'src/queries/collections';
import { DashboardGetSingle } from 'src/queries/dashboards';
import { useReportEvent } from 'src/services/analytics';
import { CollectionMetadata, FeedSignal } from 'src/types/spaces';
import FiltersAndBreakdownsModal from '../MetricPage/components/FiltersAndBreakdown/FiltersAndBreakdownsModal';
import { convertModalResultToValidCoreValues } from '../MetricPage/components/FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';
import { getFirstObjectType } from '../MetricPage/components/FiltersAndBreakdown/NodeScheme/utils';
import { FILTER_MODEL_TYPE } from '../MetricPage/components/FiltersAndBreakdown/consts';
import ResponsiveHeader from '../Spaces/common';
import classes from './DashboardPage.module.scss';
import { GridLayout } from './GridLayout/GridLayout';
import { Header } from './Header/Header';
import { SelectorsBar } from './Header/SelectorsBar';
import { NotFoundPage } from './NotFoundPage';
import { BreakpointName, GRID_CONTAINER_PADDING } from './constants';
import { useDashboardMigration } from './hooks/useDashboardMigration';
import { useDashboardPageSearchParams } from './hooks/useDashboardPageSearchParams';
import { useGlobalFilters } from './hooks/useGlobalFilters';

export function DashboardPage() {
	const { dashboardId: selectedElementId } = useDashboardPageSearchParams();
	const [dashboardsQueryData, dashboardsQueryError, isDashboardsQueryLoading] = useGetDashboardSubscription({
		id: selectedElementId,
	});
	const [embed] = useEmbedMode();

	return (
		<Page page="Dashboard">
			<Flex direction={'row'} height={'100%'}>
				{embed !== 'widget' && (
					<LeftSidePanelV2
						renderItem={(handleCollapsePanelClick, isCollapsed, setIsPanelActingLikeOnHover) => (
							<DashboardWorkspacePanel
								isCollapsed={isCollapsed}
								handleCollapsePanelClick={handleCollapsePanelClick}
								setIsPanelActingLikeOnHover={setIsPanelActingLikeOnHover}
							/>
						)}
					/>
				)}
				<Box flex={1} className={classes.pageWrapper}>
					<DashboardPageContent
						dashboardsQueryData={dashboardsQueryData}
						dashboardsQueryError={dashboardsQueryError}
						isDashboardsQueryLoading={isDashboardsQueryLoading}
					/>
				</Box>
			</Flex>
		</Page>
	);
}

export function DashboardPageContent({
	dashboardsQueryData,
	isDashboardsQueryLoading,
	dashboardsQueryError,
}: {
	dashboardsQueryData?: DashboardGetSingleQuery;
	isDashboardsQueryLoading: boolean;
	dashboardsQueryError?: ApolloError;
}) {
	const { reportEvent } = useReportEvent();
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const { dashboardId: selectedElementId, isScrollingToBottom, setSearchParams } = useDashboardPageSearchParams();

	const dashboardId = selectedElementId || dashboardsQueryData?.dashboards?.[0]?.id;
	const dashboardIdRef = useRef(dashboardId);

	const [isEditMode, setIsEditMode] = useState(false);
	const [isShowingSelectorsBar, setIsShowingSelectorsBar] = useState(false);
	const [currentBreakpoint, setCurrentBreakpoint] = useState<BreakpointName>('lg');

	const [{ id: my_id }] = useUser();
	const gridBottom = useRef<HTMLDivElement>(null);
	const toast = useToast();
	const [collectionMutate] = useMutation(UpdateCollection);
	const [migrateWidgetType, migrateLayout, migrateSentiment] = useDashboardMigration();
	const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useModal();

	useEffect(() => {
		const dashboard = dashboardsQueryData?.dashboards[0];
		if (!dashboard) return;
		dashboard.feed?.feed_signals.forEach((feedSignal) => {
			migrateWidgetType(feedSignal as FeedSignal);
			migrateSentiment(feedSignal as FeedSignal);
		});
	}, [dashboardsQueryData, migrateSentiment, migrateWidgetType]);

	const [
		[globalFiltersModalState, globalFiltersModalActions, nodeScheme, filterNodeTypes, globalFilters],
		{ onAddFilterObjects, resetGlobalFilters, addGlobalFilter, removeGlobalFilter },
	] = useGlobalFilters();

	useEffect(() => {
		const previousDashboardId = dashboardIdRef.current;
		if (previousDashboardId === dashboardId) return;
		dashboardIdRef.current = dashboardId;

		if (previousDashboardId === undefined) return;

		setIsEditMode(false);
		setIsShowingSelectorsBar(false);
		resetGlobalFilters({ isResetingFilterNodeTypes: true });
		setGlobalPeriodRange(undefined);
	}, [dashboardId, resetGlobalFilters]);

	const scrollToBottom = useCallback(() => {
		gridBottom.current?.scrollIntoView({ behavior: 'smooth' });
		setSearchParams(new URLSearchParams());
	}, [setSearchParams]);

	useLayoutEffect(() => {
		if (isScrollingToBottom && !isDashboardsQueryLoading) sleep(1000).then(scrollToBottom);
	}, [isScrollingToBottom, isDashboardsQueryLoading, scrollToBottom]);

	const [globalPeriodRange, setGlobalPeriodRange] = useState<PeriodRange | undefined>();

	if (isDashboardsQueryLoading)
		return (
			<Center height={'100%'}>
				<Spinner />
			</Center>
		);

	if (dashboardsQueryError) return <NotFoundPage />;

	if (!dashboardsQueryData?.dashboards?.length) return <CollectionEmptyPage collectionType="dashboard" />;

	const dashboard = dashboardsQueryData.dashboards[0];
	const feedSignals = (dashboard.feed?.feed_signals ?? []) as FeedSignal[];

	const updateCollectionMetadata = (name: string, description?: string) => {
		reportEvent({
			event: 'update-dashboard-metadata',
			metaData: { title: name, description: description, dashboardId: dashboardId },
		});
		collectionMutate({
			variables: { id: dashboardId, name: name, description: description, order: dashboard.order },
			optimisticResponse: {
				update_workspaces_by_pk: {
					name: name,
					description: description,
					id: dashboardId,
					__typename: 'dashboard',
				},
			},
			update: (cache) => {
				const dashboardFromCache = cache.readQuery<DashboardGetSingleQuery>({
					query: DashboardGetSingle,
					variables: {
						id: dashboardId,
						my_id,
					},
				});

				if (dashboardFromCache)
					cache.writeQuery({
						query: DashboardGetSingle,
						data: { dashboards: [{ ...dashboardFromCache.dashboards[0], name, description }] },
					});
			},
		}).catch(
			(error) =>
				error.message.includes('Uniqueness violation') &&
				toast({ variant: 'error', message: 'Title must be unique. Please choose a different title and try again.' })
		);
	};

	const toggleIsShowingSelectorsBar = () => {
		reportEvent({
			event: 'dashboard-toggle-selectors-bar',
			metaData: { dashboard: dashboard.id, isShowingSelectorsBar: !isShowingSelectorsBar },
		});
		setIsShowingSelectorsBar(!isShowingSelectorsBar);
	};

	return (
		<>
			<Flex w={'100%'} height={'100%'} direction="column" data-testid={TestIDs.DASHBOARD_PAGE_HEADER}>
				<ResponsiveHeader
					renderItem={(isTiny: boolean) => (
						<Header
							isTiny={isTiny}
							dashboard={dashboard}
							isEditMode={isEditMode}
							onChangeMode={(isEditMode) => {
								reportEvent({
									event: 'dashboard-edit-button',
									metaData: { dashboard: dashboard.id, editMode: isEditMode },
								});
								setIsEditMode(isEditMode);
							}}
							onUpdateMetadata={({ title: name, description }: CollectionMetadata) => {
								if (name != dashboard.name || description != dashboard.description)
									updateCollectionMetadata(name, description);
							}}
							onDelete={onDeleteModalOpen}
							paddingX={GRID_CONTAINER_PADDING[currentBreakpoint][0]}
							onAddedWidget={scrollToBottom}
							isShowingSelectorsBar={isShowingSelectorsBar}
							onToggleIsShowingSelectorsBar={toggleIsShowingSelectorsBar}
							globalSelectorsCount={globalFilters.length + (globalPeriodRange ? 1 : 0)}
						/>
					)}
				/>
				<SelectorsBar
					onDialogOpen={() => {
						globalFiltersModalActions.onOpen(FILTER_MODEL_TYPE, (result) => {
							if (isSightfull2)
								addGlobalFilter(convertModalResultToValidCoreValues(result, getFirstObjectType(result.items[0].key)));
							else addGlobalFilter(result.items);
						});
					}}
					isDialogOpen={globalFiltersModalState.isOpen}
					onRemoveFilter={removeGlobalFilter}
					filterObjects={Array.from(filterNodeTypes)}
					globalFilters={globalFilters}
					paddingXInPx={GRID_CONTAINER_PADDING[currentBreakpoint][0]}
					selectedPeriodRange={globalPeriodRange}
					onSelectPeriodRange={setGlobalPeriodRange}
					isVisible={!isEditMode && isShowingSelectorsBar}
				/>
				<Box
					height={'100%'}
					width={'100%'}
					overflowY="auto"
					className={isEditMode ? classes.dots : ''}
					data-intercom-area={'dashboard'}
					data-intercom-type={'main'}
					data-intercom-target={'main'}
				>
					<GridLayout
						layout={migrateLayout(feedSignals, dashboard.layout)}
						dashboardId={dashboard.id}
						isEditMode={isEditMode}
						feedSignals={feedSignals}
						onSignalWidgetLoaded={onAddFilterObjects}
						globalFilters={isEditMode ? [] : globalFilters}
						globalPeriodRange={isEditMode ? undefined : globalPeriodRange}
						currentBreakpoint={currentBreakpoint}
						setCurrentBreakpoint={setCurrentBreakpoint}
					/>
					<div style={{ float: 'left', clear: 'both' }} ref={gridBottom} />
				</Box>
			</Flex>
			<DeleteCollectionModal
				isOpen={isDeleteModalOpen}
				onClose={onDeleteModalClose}
				collection={{
					name: dashboard.name ?? '',
					id: dashboard.id,
					collection_type: 'dashboard',
					order: dashboard.order,
				}}
				isCollectionInView={true}
			/>
			<FiltersAndBreakdownsModal
				hasBaseNode={false}
				type={globalFiltersModalState.type}
				isOpen={globalFiltersModalState.isOpen}
				onClose={globalFiltersModalActions.onClose}
				onAddItems={globalFiltersModalActions.onAddItems}
				nodeScheme={nodeScheme}
			/>
		</>
	);
}
