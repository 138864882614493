import { AvailablePeriodRanges, BuiltInPeriodRangePresets } from '@sightfull/period-ranges';
import { fiscalYearOffset } from 'src/models/MetricPeriod/fiscalYear';
import { RawMetricMetadata, RulesInput } from '../../utils';
import { tryUsePeriodRangeFromDefaultRelativePeriod, tryUsePeriodRangeFromSearchParams } from '../calcPeriodRange';

export function calcCorePeriodRange(
	{ searchParams }: RulesInput,
	metricMetadata: Pick<RawMetricMetadata, 'default_relative_period'>,
	availablePeriodRanges: AvailablePeriodRanges
) {
	const { default_relative_period: defaultRelativePeriod } = metricMetadata;

	const periodRangeFromSearchParams = tryUsePeriodRangeFromSearchParams(searchParams, availablePeriodRanges);
	if (periodRangeFromSearchParams) return periodRangeFromSearchParams;

	const periodRangeFromDefaultRelativePeriod = tryUsePeriodRangeFromDefaultRelativePeriod(
		availablePeriodRanges,
		defaultRelativePeriod
	);
	if (periodRangeFromDefaultRelativePeriod) return periodRangeFromDefaultRelativePeriod;

	const periodRangeByRelevantPeriodRanges = BuiltInPeriodRangePresets.byKey('4Q', fiscalYearOffset());
	return { periodRange: periodRangeByRelevantPeriodRanges };
}
