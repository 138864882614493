import { TypeaheadOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { Mention } from 'src/types/mention';

class MentionTypeaheadOption extends TypeaheadOption {
	id: string;
	email: string;
	name: string;
	avatarURL: string;

	constructor(mention: Mention) {
		super(mention.name || '');
		this.id = mention.id || '';
		this.email = mention.email || '';
		this.name = mention.name || '';
		this.avatarURL = mention.avatarURL || '';
	}
}

export default MentionTypeaheadOption;
