import { Layout } from 'react-grid-layout';
import { FeedSignal } from 'src/types/spaces';
import {
	COLUMN_COUNT,
	DIVIDER_WIDGET_H,
	LEGEND_BAR_HEIGHT,
	ROW_HEIGHT_PX,
	SIGNAL_WIDGET_MIN_H,
	SIGNAL_WIDGET_MIN_W,
	TEXT_WIDGET_MIN_H,
	TEXT_WIDGET_MIN_W,
	WIDGET_HEADER_HEIGHT,
} from '../constants';
import { WidgetType } from '../types/DashboardFeedSignal';

export function getSignalWidgetThumbnailHeight(height: number, gridItemMargin: number) {
	const widgetHeightPx = height * ROW_HEIGHT_PX + gridItemMargin * (height - 1);
	const widgetAfterHeaderHeight = widgetHeightPx - WIDGET_HEADER_HEIGHT;
	const widgetAfterLegendNamesHeight = widgetAfterHeaderHeight - LEGEND_BAR_HEIGHT;

	return widgetAfterLegendNamesHeight;
}

function copyLayout(layout: Layout): Layout {
	return {
		w: layout.w,
		h: layout.h,
		i: layout.i,
		x: layout.x,
		y: layout.y,
	};
}
export function cleanLayoutArray(layout: Layout[]) {
	return layout.map(copyLayout);
}

const DIVIDER_WIDGET_LIMITS = {
	maxH: DIVIDER_WIDGET_H,
	minH: DIVIDER_WIDGET_H,
	h: DIVIDER_WIDGET_H,
	minW: COLUMN_COUNT,
	maxW: COLUMN_COUNT,
};
const TEXT_WIDGET_LIMITS = {
	minH: TEXT_WIDGET_MIN_H,
	minW: TEXT_WIDGET_MIN_W,
};
const SIGNAL_WIDGET_LIMITS = {
	minH: SIGNAL_WIDGET_MIN_H,
	minW: SIGNAL_WIDGET_MIN_W,
};
export function addLayoutLimitations(layouts: Layout[], feedSignals: FeedSignal[]): Layout[] {
	const widgetTypeById = Object.fromEntries(feedSignals.map((signal) => [signal.signal_id, signal.signal.widget_type]));

	const copyAndLimitByWidgetType = (layout: Layout, widgetType?: string): Layout => {
		if (widgetType == 'divider') {
			return {
				...copyLayout(layout),
				...DIVIDER_WIDGET_LIMITS,
				isResizable: false,
			};
		}
		if (widgetType == 'text') {
			return {
				...copyLayout(layout),
				...TEXT_WIDGET_LIMITS,
			};
		}
		return {
			...copyLayout(layout),
			...SIGNAL_WIDGET_LIMITS,
		};
	};

	return layouts.map((layout) => {
		const widgetType = widgetTypeById[layout.i];
		return copyAndLimitByWidgetType(layout, widgetType);
	});
}

export function implyWidgetType(feedSignal: FeedSignal): WidgetType | undefined {
	if (!feedSignal.signal.attachment) return 'text';
	return 'signal';
}
export function findBottomOfLayout(layout: Layout[]): number {
	const lowestItem = layout.sort((a, b) => a.y + a.h - (b.y + b.h)).reverse()[0];
	return lowestItem.y + lowestItem.h;
}
