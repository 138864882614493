import { SystemStyleFunction } from '@chakra-ui/theme-tools';

const variantGhostIcon: SystemStyleFunction = () => {
	return {
		color: 'gray.600',
		_hover: {
			color: 'gray.700',
		},
		_active: {
			color: 'gray.900',
		},
	};
};

const variantRegularIcon: SystemStyleFunction = (props) => {
	const { colorScheme } = props;

	switch (colorScheme) {
		case 'lightGray':
			return {
				color: 'gray.600',
				_hover: {
					mixBlendMode: 'multiply',
					bg: 'gray.200',
					color: 'gray.800',
				},
				_active: {
					mixBlendMode: 'multiply',
					bg: 'gray.300',
					color: 'black',
				},
			};

		case 'lightGrayNoActive':
			return {
				color: 'gray.600',
			};
		case 'gray':
			return {
				color: 'gray.800',
				_hover: {
					mixBlendMode: 'multiply',
					bg: 'gray.200',
					color: 'gray.1000',
				},
				_active: {
					mixBlendMode: 'multiply',
					bg: 'gray.300',
					color: 'gray.1000',
				},
			};

		case 'black':
			return {
				color: 'gray.800',
				_hover: {
					mixBlendMode: 'multiply',
					bg: 'gray.200',
				},
				_active: {
					mixBlendMode: 'multiply',
					bg: 'gray.300',
				},
				_disabled: {
					color: 'gray.400',
					opacity: 1,
					cursor: 'default',
				},
			};

		case 'blue':
			return {
				color: 'gray.600',
				_hover: {
					bg: 'blue.200',
					color: 'blue.700',
				},
				_active: {
					bg: 'blue.200',
					color: 'blue.900',
				},
			};

		case 'darkblue':
			return {
				bg: 'blue.1000',
				color: 'white',
				_hover: {
					bg: 'blue.900',
					color: 'white',
				},
				_active: {
					bg: 'blue.1100',
					color: 'white',
				},
			};

		case 'blue-filled':
			return {
				bg: 'blue.700',
				color: 'white',
				_hover: {
					bg: 'blue.800',
					color: 'white',
				},
				_disabled: {
					bg: 'blue.300',
					color: 'white',
					pointerEvents: 'none',
				},
			};

		case 'red':
			return {
				color: 'gray.600',
				_hover: {
					bg: 'red.100',
					color: 'red.700',
				},
				_active: {
					bg: 'red.100',
					color: 'red.900',
				},
			};

		case 'green':
			return {
				color: 'gray.600',
				_hover: {
					bg: 'green.100',
					color: 'green.700',
				},
				_active: {
					bg: 'green.100',
					color: 'green.900',
				},
			};

		default:
			return {
				bg: 'red',
				color: 'red',
				display: 'inline',
				lineHeight: 'inherit',
				m: 0,
				p: 0,
			};
	}
};

export const iconButtonTheme: Record<string, any> = {
	ghostIcon: variantGhostIcon,
	regularIcon: variantRegularIcon,
};
