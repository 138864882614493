import { Flex } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { MAX_ROWS_COUNT } from 'src/common/utils/consts';
import { useReportEvent } from 'src/services/analytics';
import Button from '../Button';
import { ChevronLeft16, ChevronRight16 } from '../Icons';
import Typography from '../Typography';
import { PaginationProps } from './types';

const Pagination = ({ limit = 50, totalCount = 130, onNext, onPrevious, suffix = 'rows' }: PaginationProps) => {
	const { reportEvent } = useReportEvent();
	const [currentPage, setCurrentPage] = useState(1);
	const [from, setFrom] = useState(1);
	const [to, setTo] = useState(limit);

	const onClick = (isBack?: boolean) => {
		reportEvent({ event: 'pagination-clicked', metaData: { isBack } });
		const newFrom = isBack ? from - limit : from + limit;
		setFrom(newFrom);
		setTo(isBack ? to - limit : to + limit);
		setCurrentPage(isBack ? currentPage - 1 : currentPage + 1);
		if (isBack) onPrevious?.();
		else onNext?.();
	};

	const totalNumber = useMemo(() => {
		const formattedNumber: string =
			totalCount >= MAX_ROWS_COUNT ? MAX_ROWS_COUNT.toLocaleString() : totalCount.toLocaleString();
		return totalCount >= MAX_ROWS_COUNT ? `${formattedNumber}+` : formattedNumber;
	}, [totalCount]);

	useEffect(() => {
		if (from > totalCount) {
			setFrom(1);
		}
	}, [from, totalCount]);

	useEffect(() => {
		setFrom(Math.min(1, totalCount));
		setTo(Math.min(limit, totalCount));
	}, [limit, totalCount]);

	return (
		<Flex alignItems={'center'}>
			<Typography color={'gray.800'} marginRight={'4px'} variant={'DesktopH8Medium'}>
				{totalCount / limit <= 1
					? `${to > totalCount ? totalCount.toLocaleString() : to.toLocaleString()}`
					: `${from.toLocaleString()}-${to > totalCount ? totalCount.toLocaleString() : to.toLocaleString()}`}
			</Typography>
			<Typography marginRight={'4px'} color={'gray.800'} variant={'DesktopH8Regular'}>
				of {totalNumber}
				{suffix ? ` ${suffix}` : ''}
			</Typography>

			<Button
				outline={'none'}
				cursor={'pointer'}
				variant="outline"
				isIconOnly
				size="xs"
				isDisabled={from <= 1}
				colorScheme="lightGray"
				onClick={() => onClick(true)}
			>
				<ChevronLeft16 />
			</Button>
			<Button
				isDisabled={to >= totalCount}
				onClick={() => onClick()}
				color={'gray.800'}
				variant="outline"
				isIconOnly
				size="xs"
				colorScheme="lightGray"
			>
				<ChevronRight16 />
			</Button>
		</Flex>
	);
};

export default Pagination;
